import React from 'react';
import { filterDistinctArray } from '../../utils/helpers';
import { Buttons } from '../customcontrols';
import OrderSummmery from './ordersummery';
import { PaymentType } from '../../types/payments';
interface PropsTypes {
  orderContinue?: any;
  subscriptions?: any;
  cancelPayment?: any;
  payment: PaymentType;
}

const ConfirmSubscription = (props: PropsTypes) => {
  const { orderContinue, cancelPayment, subscriptions, payment } = props;
  const {
    complimentary = false,
    calculatedPrice = 0,
    allProducts = [],
    products = [],
    subscribedStates = [],
    subscribedCounties = [],
    subscribedNational = [],
  } = subscriptions;
  const { cardinfo, card_token = '' } = payment;
  const { details = {}, nonce } = card_token;
  const { cardType = '', cardNumberMask = '' } = cardinfo;
  const nationalProduct = allProducts.find((item: any) => item.productType === 'NA') || {};
  const allStateAgencies = allProducts.filter((f: any) => f.productType === 'AG');
  const selectedStates = products.filter((item: any) => subscribedStates.includes(item.productId));
  let selectedCounties = allProducts.filter((item: any) =>
    subscribedCounties.includes(item.productId),
  );
  selectedCounties = filterDistinctArray(selectedCounties, 'productId');
  const loadNationalContent = () => {
    return (
      <>
        <p>
          ${nationalProduct.price}/year {nationalProduct.productName} ({allStateAgencies.length}{' '}
          government Agencies)
        </p>
        <ul>
          {products.map((item: any, index: number) => {
            const countyIds = item.products.map((item: any) => item.productId);
            const stateAgencies = allProducts.filter(
              (f: any) =>
                f.productType === 'AG' &&
                (f.parentType === 'ST' ||
                  (f.parentType === 'CT' && countyIds.includes(f.parentId))),
            );
            return (
              <li key={index}>
                {item.productName} ({stateAgencies.length} Agencies) - ${item.price}/year
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const loadCountiesContent = () => {
    return (
      <>
        <ul>
          {selectedStates.map((item: any, index: number) => {
            const countyIds = item.products.map((item: any) => item.productId);
            const stateAgencies = allProducts.filter(
              (f: any) =>
                f.productType === 'AG' &&
                (f.parentType === 'ST' ||
                  (f.parentType === 'CT' && countyIds.includes(f.parentId))),
            );
            return (
              <li key={index}>
                {item.productName}, ({stateAgencies.length} agencies) - ${item.price}/year
                <ul>
                  {item.products.map((pItem: any, pIndex: number) => {
                    const countyAgencies = allProducts.filter(
                      (f: any) =>
                        f.productType === 'AG' &&
                        (f.parentType === 'ST' ||
                          (f.parentType === 'CT' && f.parentId === pItem.productId)),
                    );
                    return (
                      <li key={pIndex}>
                        {pItem.productName} ({countyAgencies.length} agencies)
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
          {selectedCounties.map((item: any, index: number) => {
            const countyAgencies = allProducts.filter(
              (f: any) =>
                f.productType === 'AG' &&
                (f.parentType === 'ST' || (f.parentType === 'CT' && f.parentId === item.productId)),
            );
            return (
              <li key={index}>
                {item.productName} ({countyAgencies.length} agencies) - ${item.price}/year
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <>
      <div className='row confirmSubWrapper'>
        <div className='col-md-12 col-lg-7'>
          <p>
            Your card will be charged ${calculatedPrice} for subscriptions to the following areas:
          </p>
          <div className='confirmProdList'>
            {subscribedNational.length > 0 ? loadNationalContent() : loadCountiesContent()}
          </div>
        </div>
        <div className='col-md-12 col-lg-5'>
          <div className='clearfix'>
            {!complimentary ? (
              <>
                <h6>Payment from</h6>
                {card_token && nonce && details ? (
                  <p>
                    {details.cardType} card ending with {details.lastFour}
                  </p>
                ) : (
                  <>
                    <p>
                      {cardType} card ending with {cardNumberMask}
                    </p>
                  </>
                )}
              </>
            ) : null}
            <OrderSummmery {...props} complimentary={complimentary} />
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-between mt-4'>
        <Buttons type='button' onClick={cancelPayment} classNames='bttn-secondary' text='Cancel' />
        <Buttons
          type='button'
          classNames='bttn-primary'
          text={complimentary ? 'Make It Complementary' : 'Make Payment'}
          onClick={orderContinue}
        />
      </div>
    </>
  );
};

export default ConfirmSubscription;
