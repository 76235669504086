import React, { memo, useEffect } from 'react';
import { Table } from 'reactstrap';
import imgsCdn from '../../assets/images/imagescdn';
import { AcceptedFormat, GroupedFileFormat } from '../../types/publications';
import { Buttons } from '../customcontrols';
import { useDispatch } from 'react-redux';
import { getaccountinforequiredDocs } from '../../store/actions';
import { useDSSelector } from '../../store/reducers';
import { maxUploadInText } from '../../utils/constants';

const docformats: any = imgsCdn.docFormats;

interface AcceptedFileFormatsProps {
  closePopup: () => void;
}

const ShowIMG = (item: GroupedFileFormat) => {
  const icon: string = docformats[item.icon.substring(0, item.icon.indexOf('.'))];
  return <img width='30px' alt='logo' src={icon} className='d-inline-block' />;
};

const AcceptedFileFormats = (props: AcceptedFileFormatsProps) => {
  const dispatch = useDispatch();
  const data = useDSSelector(state => state.accountinfo.agencydocs.acceptedFormats);
  const { closePopup } = props || {};
  const groupedFileFormats: GroupedFileFormat[] = [];

  // this updates the state with account information. It might be better to do this once when the app first starts up, but I'm following precedent for now.
  useEffect(() => {
    if (!data || !data.length) {
      dispatch(getaccountinforequiredDocs());
    }
  }, [data, dispatch]);

  data.forEach(item => {
    let parentProgram = item.parentProgram;
    let docFormatType = item.docFormatType;
    if (!parentProgram) {
      const splitted = item.description.split('(*');
      parentProgram = splitted[0].trimEnd();
    }

    if (!docFormatType) {
      // well, hopefully we never hit this. We should just have docFormatType.
      // and we shouldn't have docFormat.
      docFormatType = (item as any).docFormat;
    }

    const parentIndex = groupedFileFormats.findIndex(Fitems => Fitems.title === parentProgram);
    if (parentIndex > -1) {
      groupedFileFormats[parentIndex] = {
        ...groupedFileFormats[parentIndex],
        formatItems: [...groupedFileFormats[parentIndex].formatItems, `*.${docFormatType}`],
      };
    } else {
      groupedFileFormats.push({
        title: parentProgram,
        icon: item.icon,
        formatItems: [`*.${docFormatType}`],
      });
    }
  });

  const finalGroupedItems = groupedFileFormats.map(item => {
    return {
      ...item,
      formatItems: item.formatItems.sort((a, b) => a.localeCompare(b)),
    };
  });

  const acceptedFile1 = finalGroupedItems.slice(0, Math.round(data.length / 3.8));
  const acceptedFile2 = finalGroupedItems.slice(
    Math.round(data.length / 3.8),
    Math.round(data.length / 3.8) * 2,
  );
  const acceptedFile3 = finalGroupedItems.slice(Math.round(data.length / 3.8) * 2, data.length);

  const renderDataTable = (contentData: GroupedFileFormat[]) => {
    return (
      <Table className='tableHData w-auto float-left mb-0'>
        {contentData.map((item: GroupedFileFormat, index: number) => (
          <tr key={index}>
            <td>
              {ShowIMG(item)}
              <span className='flex1 ml-1'>
                {item.title} ({item.formatItems.join(', ')})
              </span>
            </td>
          </tr>
        ))}
      </Table>
    );
  };

  return (
    <>
      <div className='noteImportant clearfix m-0'>
        <div className=' d-md-block d-lg-flex justify-content-between'>
          {
            data.length > 0 ? (
              <>
                {renderDataTable(acceptedFile1)}
                {renderDataTable(acceptedFile2)}
                {renderDataTable(acceptedFile3)}
              </>
            ) : null
            //   <NoResult message={internalLoader ? loadingMsg : 'No Required Documents Available'} />
          }
        </div>
      </div>

      <div className='d-block my-4'>
        <h6 className='float-left'>Maximum file size: {maxUploadInText} MB </h6>
        <Buttons
          classNames='bttn-primary float-right'
          text='Close'
          title='Close'
          type='button'
          onClick={closePopup}
        />
      </div>
    </>
  );
};

export default memo(AcceptedFileFormats);
