import moment from 'moment';

import * as actionTypes from '../actionTypes';
import {
  activeprogramsType,
  AddBidInfo,
  BidDocument,
  bidresponseitemsParamType,
  BidType,
  broadcastListItemType,
  DocStatus,
  DocTypeValue,
  supplimentalSuppliersItemParamType,
} from '../../types/addbid';
import { Awardee, BidWriter, Planholder, supplierItemFields } from '../../types/biddetail';
import { currentYear, generateKey } from '../../utils/helpers';
import { WizardPage, WizardStatus } from '../../types/wizard';
import { BidExternalStatusType } from '../../utils/constants';
import { payloadTypes } from '../../types/actiontypedef';

export interface AddBidState<T = BidDocument> {
  actionType?: string;
  activeprograms: activeprogramsType[];
  activeprogramsapi: boolean;
  addAwardConfirmPopup?: boolean;
  addbidinfo: AddBidInfo;
  addbidwizard: WizardPage[];
  addbidwizardpartial: boolean;
  additionalBroadcastModal?: boolean;
  addpublicationmodal: boolean;
  addsupplementalmodal: boolean;
  addsupplementalvalues: {
    city: string;
    contactName: string;
    email: string;
    phone: string;
    state: string;
    supplierName: string;
  };
  addSupplierBulkUploadModal: boolean;
  approvedDocuments?: number[];
  approvedocumentdetails?: T;
  approvedocumentmodal: boolean;
  approvePending?: boolean;
  awardeeIds?: number[];
  awardeelist: Awardee[]; //TODO: Change to awardeeList or awardees
  bidId?: number | string;
  bidpublications: any[];
  bidresponseitems?: bidresponseitemsParamType[];
  bidStatusList: [{ value: BidExternalStatusType; label: string }]; // more specific label: 'Upcoming'
  bidtypes?: BidType[];
  bidwriters: BidWriter[];
  blueprintStatusType: string; // more specific: 'EL';
  broadcastinglist: broadcastListItemType[];
  broadcastFilter: {
    filterCity: string;
    filterDeclaration: string;
    filterPrograms: string;
    filterState: string;
  };
  broadcastListId: string;
  broadcastLists: broadcastListItemType[];
  broadcastListssearch: broadcastListItemType[]; //TODO: fix casing.
  broadcastListssearchShow: boolean; //TODO: fix casing.
  broadcastListsShow: boolean;
  broadcastModal?: boolean;
  broadcastSupplimentalSupplier?: supplimentalSuppliersItemParamType[];
  canFinishBid: boolean;
  columnData?: Array<{ column: string; data: []; mapping: any }>;
  columns?: Array<string>;
  commodities: any[];
  commodityAlert?: boolean;
  configurationInstruction?: boolean;
  confirmChanges?: boolean;
  confirmReBroadcast?: boolean;
  conversionTypes: any[];
  currentBroadcastPage?: string;
  currentScreen?: number;
  deleteDocumentDetails?: T;
  deletedocumentmodal: boolean;
  docId?: string;
  doctypes: { value: string; label: any }[];
  documentslist: T[];
  documentsoptionsmodal: boolean;
  docValidation?: false;
  duplicatebidmodal?: boolean;
  EbidAlertModal?: boolean;
  ebidinfo: { addbidresponseitems: any[]; viewsupplierinfortabulation: string };
  editable: 'all' | 'active' | null;
  editbiddata: boolean;
  emailAddress?: {
    companyName: string;
    contactName: string;
    email: string;
    id: number;
    isIncluded: boolean;
    isValid: boolean;
  }[];
  errorMsg?: '';
  errorType?: number;
  existssupplierlist: supplierItemFields[];
  filteredPreBuiltList: broadcastListItemType[];
  finishbiderrormodal: boolean;
  finishBidErrorMsg?: string[];
  focusbroadcasttimefield?: boolean;
  halfWayCancel: boolean;
  halfWayCancelAllow: boolean;
  isConstruction: boolean;
  isExistsSupplier: string;
  leftbtndisable?: boolean;
  legalads: {
    legalAdAdditional: string;
    legalAdClosing: string;
    legalAdCostInfo: string;
    legalAdDueDateTime: string;
    legalAdIntro: string;
  };
  loadNewCommodities: boolean;
  memberbidpublications: any[];
  modalNotifyPreBid?: boolean;
  multipleSheet?: boolean;
  newDocAdded: boolean;
  newlistmodal: boolean;
  newlistname: string;
  permissions: { canAdd: boolean; canApprove: boolean; canDelete: boolean; canView: boolean };
  plandetails: { planname: string; planemail: string; plancost: string; plannotes: string };
  plandistributedby: string; // more specific: 'DM';
  planEmailError?: boolean;
  planerror?: boolean;
  planHoldersList?: Planholder[];
  prebidconference: { pbcMemo: string; preBidMandatory: boolean };
  preBidMandatory: boolean;
  preBuiltBroadCastList: broadcastListItemType[];
  prebuiltmodal: boolean;
  priceError?: boolean;
  processingInterval?: number;
  processInterrupt?: boolean;
  progress?: number;
  publicationslist: { title: string; date1: string; date2: string }[];
  ResetState: boolean;
  rightbtndisable?: boolean;
  saveAndAdd?: boolean;
  savebroadcastlist: string;
  savelistExistmodal: boolean;
  savelistmodal: boolean;
  selectedBuiltLists: any[];
  selectedListIds?: string;
  selectedSheet?: string;
  sheetName?: string;
  sheets?: Array<string>;
  showSkippedColumns?: boolean;
  skippedColumns?: Array<string>;
  statelist: any[];
  supplementalsupplierlist: supplimentalSuppliersItemParamType[];
  supplierCount: string;
  supplierCountShow: boolean;
  totalProcessingTime?: number;
  unmatchedColumns?: number;
  upcomingBidPageFor: string;
  updatedConferenceMessage?: boolean;
  upload_progress: boolean;
  uploadlistmodal: boolean;
  uploadProgress?: boolean;
  wizardcurrentpage: number;
  newbidNumber: string;
}

export const addbidwizardfull: WizardPage[] = [
  { id: 1, name: 'Bid Information', status: WizardStatus.Current },
  { id: 2, name: 'eBidding Info', status: WizardStatus.Unavailable },
  { id: 3, name: 'Legal Ads', status: WizardStatus.Unavailable },
  { id: 4, name: 'Pre-Bid Conference', status: WizardStatus.Unavailable },
  { id: 5, name: 'Publications', status: WizardStatus.Unavailable },
  { id: 6, name: 'Build Broadcast List', status: WizardStatus.Unavailable },
  { id: 7, name: 'Supplemental Suppliers', status: WizardStatus.Unavailable },
  { id: 8, name: 'Manage Documents', status: WizardStatus.Unavailable },
];
export const addbidwizardfullplan: WizardPage[] = [
  { id: 1, name: 'Bid Information', status: WizardStatus.Current },
  { id: 2, name: 'eBidding Info', status: WizardStatus.Unavailable },
  { id: 3, name: 'Legal Ads', status: WizardStatus.Unavailable },
  { id: 4, name: 'Pre-Bid Conference', status: WizardStatus.Unavailable },
  { id: 5, name: 'Publications', status: WizardStatus.Unavailable },
  { id: 6, name: 'Build Broadcast List', status: WizardStatus.Unavailable },
  { id: 7, name: 'Supplemental Suppliers', status: WizardStatus.Unavailable },
  { id: 8, name: 'Plan/Blueprint Information', status: WizardStatus.Unavailable },
  { id: 9, name: 'Manage Documents', status: WizardStatus.Unavailable },
];
export const addbidwizardplan: WizardPage[] = [
  { id: 1, name: 'Bid Information', status: WizardStatus.Current },
  { id: 2, name: 'Legal Ads', status: WizardStatus.Unavailable },
  { id: 3, name: 'Pre-Bid Conference', status: WizardStatus.Unavailable },
  { id: 4, name: 'Publications', status: WizardStatus.Unavailable },
  { id: 5, name: 'Build Broadcast List', status: WizardStatus.Unavailable },
  { id: 6, name: 'Supplemental Suppliers', status: WizardStatus.Unavailable },
  { id: 7, name: 'Plan/Blueprint Information', status: WizardStatus.Unavailable },
  { id: 8, name: 'Manage Documents', status: WizardStatus.Unavailable },
];
export const initialaddbidwizard: WizardPage[] = [
  { id: 1, name: 'Bid Information', status: WizardStatus.Current },
  { id: 2, name: 'Legal Ads', status: WizardStatus.Unavailable },
  { id: 3, name: 'Pre-Bid Conference', status: WizardStatus.Unavailable },
  { id: 4, name: 'Publications', status: WizardStatus.Unavailable },
  { id: 5, name: 'Build Broadcast List', status: WizardStatus.Unavailable },
  { id: 6, name: 'Supplemental Suppliers', status: WizardStatus.Unavailable },
  { id: 7, name: 'Manage Documents', status: WizardStatus.Unavailable },
];

export const broadcastDateTimeinitital = { value: '11:00', label: '11:00' };
export const broadcastDateTimeZoneinitital = { value: 'PM', label: 'PM' };
/* FIXME: PM is not a timezone? Is this a bad value or a bad name?
If we're talking AM vs PM,
this should not be a separate value from the rest of the date/time object.
*/

const initialaddbidinfo: AddBidInfo = {
  broadcastDate: '',
  bidName: '',
  bidBond: '',
  eBidding: 'yes',
  displayBudgetToSuppliers: false,
  projectEstimatedBudget: '',
  scopeOfWork: '',
  bidYear: {
    label: currentYear(),
    value: currentYear(),
  },
  bidNumber: '',
  openedText: '',
  dueDate: '',
  dueTime: { value: '11:00', label: '11:00' },
  dueTimeZone: { value: 'PM', label: 'PM' },
  broadcastDateTime: broadcastDateTimeinitital,
  broadcastDateTimeZone: broadcastDateTimeZoneinitital,
  bidstatus: { value: BidExternalStatusType.Upcoming, label: 'Upcoming' },
  bidWriterAccountId: '',
  memberBidType: '',
};

export const initialFilter = {
  filterState: '',
  filterCity: '',
  filterDeclaration: '',
  filterPrograms: '',
};
export const initialplandetails = { planname: '', planemail: '', plancost: '', plannotes: '' };

export const initialDocumentList: BidDocument = {
  bidDocId: '',
  bidId: '',
  docconversion: false,
  docext: '',
  docfile: '',
  doctitle: '',
  doctype: '',
  docType: DocTypeValue.None,
  id: generateKey('doc'),
  isExist: false,
  modifiedDate: moment().format(),
  progress: 0,
  statusType: DocStatus.None,
  uploadprocess: 'notstarted',
};

export const initialAddBidsState: AddBidState = {
  ResetState: false,
  addbidwizard: initialaddbidwizard,
  addbidinfo: initialaddbidinfo,
  ebidinfo: { addbidresponseitems: [], viewsupplierinfortabulation: '' },
  publicationslist: [],
  prebuiltmodal: false,
  newlistmodal: false,
  uploadlistmodal: false,
  bidtypes: [],
  bidwriters: [],
  addbidwizardpartial: false,
  editbiddata: false,
  upload_progress: false,
  bidpublications: [],
  memberbidpublications: [],
  prebidconference: { pbcMemo: '', preBidMandatory: false },
  legalads: {
    legalAdAdditional: '',
    legalAdClosing: '',
    legalAdCostInfo: '',
    legalAdDueDateTime: '',
    legalAdIntro: '',
  },
  bidresponseitems: [],
  bidStatusList: [{ value: BidExternalStatusType.Upcoming, label: 'Upcoming' }],
  broadcastinglist: [],
  preBuiltBroadCastList: [],
  filteredPreBuiltList: [],
  selectedBuiltLists: [],
  broadcastFilter: initialFilter,
  documentslist: [initialDocumentList],
  broadcastLists: [],
  activeprograms: [],
  activeprogramsapi: true,
  broadcastListsShow: false,
  blueprintStatusType: 'EL',
  supplierCount: '',
  supplierCountShow: false,
  savelistmodal: false,
  savelistExistmodal: false,
  doctypes: [],
  isConstruction: false,
  conversionTypes: [],
  wizardcurrentpage: 1,
  plandistributedby: 'DM',
  plandetails: initialplandetails,
  addsupplementalmodal: false,
  addsupplementalvalues: {
    supplierName: '',
    contactName: '',
    email: '',
    phone: '',
    state: '',
    city: '',
  },
  supplementalsupplierlist: [],
  statelist: [],
  isExistsSupplier: '',
  awardeelist: [],
  existssupplierlist: [],
  editable: 'all',
  addpublicationmodal: false,
  documentsoptionsmodal: false,
  approvedocumentmodal: false,
  savebroadcastlist: '',
  permissions: { canAdd: false, canApprove: false, canDelete: false, canView: false },
  broadcastListssearch: [],
  broadcastListssearchShow: false,
  finishbiderrormodal: false,
  halfWayCancel: false,
  halfWayCancelAllow: false,
  canFinishBid: false,
  commodities: [],
  newDocAdded: false,
  deletedocumentmodal: false,
  deleteDocumentDetails: undefined,
  preBidMandatory: false,
  loadNewCommodities: true,
  broadcastListId: '',
  upcomingBidPageFor: '',
  newlistname: '',
  addSupplierBulkUploadModal: false,
  broadcastSupplimentalSupplier: [],
  newbidNumber: '',
};

export const reducer = (state = initialAddBidsState, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.SET_ADD_BID_DETAILS.TRIGGER:
      if (payload.ResetState) {
        state = {
          ...initialAddBidsState,
          addbidwizard: [
            //TODO: Why isn't this just a part of initialAddBidsState?
            { id: 1, name: 'Bid Information', status: WizardStatus.Current },
            { id: 2, name: 'Legal Ads', status: WizardStatus.Unavailable },
            { id: 3, name: 'Pre-Bid Conference', status: WizardStatus.Unavailable },
            { id: 4, name: 'Publications', status: WizardStatus.Unavailable },
            { id: 5, name: 'Build Broadcast List', status: WizardStatus.Unavailable },
            { id: 6, name: 'Manage Documents', status: WizardStatus.Unavailable },
          ],
        };
      } else {
        state = { ...state, ...payload, newDocAdded: false }; //TODO: What is the purpose of newDocAdded: false?
      }
      break;
    case actionTypes.GET_ADD_BID_BID_TYPES.SUCCESS:
      state = { ...state, bidtypes: payload };
      break;
    case actionTypes.GET_ADD_BID_BID_WRITERS.SUCCESS:
      state = { ...state, bidwriters: payload };
      break;
    case actionTypes.SUBMIT_ADD_BID_INFO.SUCCESS:
    case actionTypes.SET_ADD_BID_INITIAL_DATA.SUCCESS:
    case actionTypes.SUBMIT_ADD_BID_ADD_LIST.SUCCESS:
    case actionTypes.SUBMIT_ADD_BID_DELETE_LIST.SUCCESS:
    case actionTypes.SUBMIT_ADD_BID_SEARCH_VENDORS.SUCCESS:
    case actionTypes.SUBMIT_ADD_BID_IS_FILTER_STATUS.SUCCESS:
    case actionTypes.SUBMIT_ADD_BID_UPDATE_PLAN.SUCCESS:
    case actionTypes.SUBMIT_ADD_BID_COMPLETE.SUCCESS:
    case actionTypes.SET_ADD_BID_COMMODITY_LOAD.TRIGGER:
    case actionTypes.SUBMIT_ADD_BID_MOVE_NEXT.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_ADD_BID_RESPONSE_ITEMS.SUCCESS:
      state = {
        ...state,
        ebidinfo: { ...state.ebidinfo, addbidresponseitems: payload.responseItem },
        bidresponseitems: payload.responseItem,
      };
      break;
    case actionTypes.GET_ADD_BID_PUBLICATIONS.SUCCESS:
      state = {
        ...state,
        bidpublications: payload.bidpublications,
        memberbidpublications: payload.memberbidpublications,
      };
      break;
    case actionTypes.GET_ADD_BID_LEGAL.SUCCESS:
      state = { ...state, legalads: payload };
      break;
    case actionTypes.SUBMIT_ADD_BID_GET_PREBUILT_LIST.SUCCESS:
      state = { ...state, preBuiltBroadCastList: payload, filteredPreBuiltList: payload };
      break;
    case actionTypes.SUBMIT_ADD_BID_SET_PREBUILT_LIST.TRIGGER:
      state = { ...state, filteredPreBuiltList: payload };
      break;
    case actionTypes.SUBMIT_ADD_BID_USE_PREBUILT_LIST.SUCCESS:
      state = {
        ...state,
        broadcastinglist: payload,
        broadcastListsShow: false,
        prebuiltmodal: false,
        halfWayCancel: true,
      };
      break;
    case actionTypes.GET_ADD_BID_ACTIVE_PROGRAMS.SUCCESS:
      state = { ...state, activeprograms: payload, activeprogramsapi: false };
      break;
    case actionTypes.GET_ADD_BID_BROADCAST_LIST_ID.SUCCESS:
      state = { ...state, broadcastListId: payload };
      break;
    case actionTypes.SUBMIT_ADD_BID_SUPPLIER_COUNT.SUCCESS:
      state = { ...state, supplierCount: payload, supplierCountShow: true };
      break;
    case actionTypes.SUBMIT_ADD_BID_SAVE_FILTER.SUCCESS:
      state = {
        ...state,
        newlistname: payload.newlistname,
        savelistmodal: payload.savelistmodal,
        halfWayCancel: true,
      };
      break;
    case actionTypes.GET_ADD_BID_DOC_TYPES.SUCCESS:
      state = { ...state, doctypes: payload };
      break;
    case actionTypes.GET_ADD_BID_IS_CONSTRUCTION.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_ADD_BID_CONVERSION_TYPES.SUCCESS:
      state = { ...state, conversionTypes: payload };
      break;
    case actionTypes.SUBMIT_ADD_BID_DOCUMENT_DELETE.SUCCESS:
      state = {
        ...state,
        documentslist: payload,
        deletedocumentmodal: false,
        deleteDocumentDetails: undefined,
      };
      break;
    case actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS.SUCCESS:
    case actionTypes.ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS.SUCCESS:
      state = { ...state, supplementalsupplierlist: payload };
      break;
    case actionTypes.GET_ADD_BID_IS_PERMISSIONS.SUCCESS:
      state = { ...state, permissions: payload };
      break;
    case actionTypes.SUBMIT_ADD_BID_DOCUMENT_APPROVE.SUCCESS:
      state = { ...state, documentslist: payload };
      break;
    case actionTypes.RESET_ADD_BID.TRIGGER:
      state = initialAddBidsState;
      break;
    default:
      break;
  }
  return state;
};
