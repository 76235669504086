import styled from 'styled-components';

/**
 * The FileInput control is hidden and a button is shown instead.
 */
export const FileInput = styled.input`
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
