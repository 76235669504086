import React, { memo, useEffect, useState } from 'react';
import { pageSize } from '../../../utils/constants';
import { checkPaginationCallFn } from '../../../utils/helpers';
import ActivitiesSearch from './search';
import ActivitiesSearchresult from './searchresult';
import ActivitiesList from './list';
import ActivitiesStatus from './status';
import { activitiessearchTypes, activitiesTypes } from '../../../types';

interface propsTypes extends activitiessearchTypes {
  activities: activitiesTypes;
  setBreadcrumb: any;
  getActivitiesEbidders: any;
  setActivitiesDetails: any;
}

function Ebidproposal(props: propsTypes) {
  const { setBreadcrumb, activities, getActivitiesEbidders, setActivitiesDetails } = props;
  const {
    activities_ebidders,
    ebidders_internalLoader,
    activitiesSearch,
    activities_ebidders_search,
    ebidproposalStatus,
  } = activities;
  const { /*page,*/ result: ebidderslist, /*size,*/ total } = activities_ebidders;
  const {
    /*page,*/ result: ebidderslist_search,
    /*size,*/ total: total_search,
  } = activities_ebidders_search;

  const totalCount = activitiesSearch ? total_search : total;

  useEffect(() => {
    setBreadcrumb({
      page: 'Activities',
      altname: 'eBid Responses',
      title: 'eBid Responses',
      name: 'eBid Responses',
      menuactive: 'activities',
    });
  }, [setBreadcrumb]);

  // Logic for displaying pagination
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const onPagingClick = (value: number) => {
    if (value !== currentPages) {
      if (checkPaginationCallFn(value, listPerPages, ebidderslist, total)) {
        getActivitiesEbidders({
          page: Math.ceil(value / listPerPages) + 1,
          size: 100,
          pagination: true,
        });
      }
      setCurrentPages(Number(value));
    }
  };

  const onSelectChange = (name: any, value: any) => {
    setActivitiesDetails({ ebidproposalStatus: value });
  };

  const finalebidderslist = ebidderslist.slice(indexOfFirstLists, indexOfLastLists) || [];

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='arrowWrapper'>
            eBid Response{totalCount > 1 ? 's' : ''} ({totalCount})
          </h2>
          <ActivitiesStatus
            {...props}
            pagefor='ebidresponse'
            onSelectChange={onSelectChange}
            status={ebidproposalStatus}
            setCurrentPages={setCurrentPages}
          />
        </div>
      </div>
      <div className='row'>
        <ActivitiesSearch {...props} pagefor='ebidresponse' setCurrentPages={setCurrentPages} />
      </div>
      {activitiesSearch ? (
        <ActivitiesSearchresult
          {...props}
          activitieslist={ebidderslist_search}
          total={total_search}
          pagefor='ebidresponse'
        />
      ) : (
        <ActivitiesList
          {...props}
          pagefor='ebidresponse'
          InternalLoader={ebidders_internalLoader}
          loadermsg='No eBid Responses Available'
          list={ebidderslist}
          finallist={finalebidderslist}
          total={total}
          currentPages={currentPages}
          onPagingClick={onPagingClick}
          listPerPages={listPerPages}
        />
      )}
    </>
  );
}

export default memo(Ebidproposal);
