import React from 'react';

interface PropsTypes {
  reduxform?: any;
  input?: any;
  checked?: boolean;
  disabled?: boolean;
  onChange?: any;
  label?: any;
  value?: any;
  classNames?: string;
  meta?: any;
  title?: any;
  name?: any;
  options?: any;
  parentClass?: any;
}

const ToogleBtn = (props: PropsTypes) => {
  const { reduxform, input, checked, disabled = false, onChange } = props;

  return (
    <div className='switchWrapper'>
      {props.label}
      <label className='switch'>
        {reduxform ? (
          <input
            {...input}
            onChange={event => input.onChange(event.target.checked)}
            checked={input.value}
            type='checkbox'
            disabled={disabled}
          />
        ) : (
          <input type='checkbox' checked={checked} onChange={event => onChange(event)} />
        )}
        <span className='slider round' />
      </label>
    </div>
  );
};

export default ToogleBtn;
