import { authTypes } from '../types/auth';
import jwt from 'jsonwebtoken';
import { constants } from './settings';

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname: string, value: any, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; domain=${constants.cookieDomain}; expires=${date.toUTCString()}`;
  }
  document.cookie = cname + '=' + (value || '') + expires + '; path=/';
};

export const deleteCookie = (name: string) => {
  const expDate: any = new Date();
  expDate.setTime(expDate.getTime() - 86400000); //-1 day
  // FIXME: Property 'toGMTString' does not exist on type 'Date'. Did you mean 'toUTCString'
  const value = `""; domain=${constants.cookieDomain}; expires=${expDate.toUTCString()};path=/`;
  document.cookie = name + '=' + value;
};

export const validateCookie = (name: string) => {
  const token = getCookie(name);
  const tokenData: authTypes | any = jwt.decode(token);
  const { mi, mt } = tokenData;
  return mi && mt ? true : false;
};
