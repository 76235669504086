import React, { PropsWithChildren } from 'react';
import { Loadable } from 'recoil';

import { ErrorPanel } from './ErrorPanel';
import { LoadingPanel } from './LoadingPanel';

export type LoadableProps = PropsWithChildren<{
  loadable: Loadable<unknown>;
  loadingMessage?: string;
  errorMessage?: string;
}>;

export function LoadableWrapper(props: LoadableProps) {
  const { loadable, children, loadingMessage, errorMessage } = props;

  switch (loadable.state) {
    case 'loading':
      return <LoadingPanel message={loadingMessage} />;
    case 'hasValue':
      return <>{children}</>;
    case 'hasError':
      return <ErrorPanel message={errorMessage} />;
  }
}
