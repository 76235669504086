import React, { memo } from 'react';
import { QuoteSummaryType } from '../../../../../../types/quotedetailstypedef';

interface PropsTypes {
  quoteSummary: QuoteSummaryType;
}

const HeaderStatistics = (props: PropsTypes) => {
  const { quoteSummary } = props;

  const { statistics = '' } = quoteSummary;

  return (
    <>
      {statistics ? (
        <div className='row countsWrapper'>
          <div className='col text-center'>
            <h2>{statistics.broadcasts}</h2>
            <p>Broadcast to</p>
          </div>
          <div className='col text-center'>
            <h2>{statistics.quotesViewed}</h2>
            <p>Viewed Quotes</p>
          </div>
          <div className='col text-center'>
            <h2>{statistics.submittedQuotes}</h2>
            <p>Submitted Quote</p>
          </div>
          <div className='col text-center'>
            <h2>{statistics.notQuotes}</h2>
            <p>Not Quoting</p>
          </div>
          <div className='col text-center'>
            <h2>{statistics.quoteButLater}</h2>
            <p>Quoting But Later</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(HeaderStatistics);
