import { integerVal } from '../../../utils/constants';

export const validate = (values = {} as any) => {
  const errors = {} as any;

  const optional = ['additionalSupplierInfo', 'delivery'];
  const numbers = [] as any;
  const checknumber =
    Object.keys(values)
      .filter(items => numbers.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];
  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];
  const errorarr = required.filter(items => !items.value) || [];
  errorarr.forEach(items => (errors[items.name] = 'Required'));
  const errornumarr =
    checknumber.filter(items => items.value && !integerVal.test(items.value)) || [];
  errornumarr.forEach(items => (errors[items.name] = 'Invalid'));

  if (values.delivery && !integerVal.test(values.delivery)) {
    errors.delivery = 'Invalid';
  }

  const linewiresErrors = [] as any;
  values.linewires &&
    values.linewires.length &&
    values.linewires.forEach((item: any, index: number) => {
      const Errors = {} as any;
      /* if (item.unitPrice === '') {
			Errors.unitPrice = 'Required'
		} */

      const priceData = item.unitPrice.toString().split('.');

      if (item.unitPrice && priceData.length > 1 && priceData[1].length > 3) {
        Errors.unitPrice = 'Maximum 3 decimal places are allowed';
        errors[`linewires[${index}].unitPrice`] = 'Maximum 3 decimal places are allowed';
      }

      if (
        item.unitPrice &&
        !/^(0|[0-9]\d*)$/.test(item.unitPrice) &&
        !/^[-+]?[0-9]+\.[0-9]+$/.test(item.unitPrice)
      ) {
        const ItemPrice = item.unitPrice
          .toString()
          .replace(/ /g, '')
          .replace('$', '')
          .replace(/,/g, '');
        if (!/^(0|[0-9]\d*)$/.test(ItemPrice) && !/^[-+]?[0-9]+\.[0-9]+$/.test(ItemPrice)) {
          Errors.unitPrice = 'Invalid';
          errors[`linewires[${index}].unitPrice`] = 'Invalid';
        }
      }
      if (Object.keys(Errors).length > 0) linewiresErrors[index] = Errors;
      return Errors;
    });
  if (linewiresErrors.length) {
    errors.linewires = linewiresErrors;
  }
  return errors;
};
