/** TODO: Consider bringing in the consistent height
 * from NotificationFormRow to this component.
 */
import React, { PropsWithChildren, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Colors, Padding } from '../../shared/styles';
import { EditIcon } from '../common/icons';

const PaddedWrapper = styled.div`
  display: inline-block;
  padding-left: ${Padding.base};
`;

type ToggleEditDisplayProps = PropsWithChildren<{
  value: string;
  display?: ReactNode;
  isValid?: (value: string) => boolean;
}>;

export const ToggleEditDisplay = (props: ToggleEditDisplayProps) => {
  const { children, value, display } = props;

  const isValid = props.isValid ? props.isValid(value) : !!value;
  const [editable, setEditable] = useState(!isValid);

  function toggleEditable() {
    setEditable(!editable);
  }

  return (
    <span>
      {editable ? (
        <>
          {children}
          <span />
          {isValid && (
            <PaddedWrapper>
              <EditIcon
                onClick={toggleEditable}
                height={16}
                width={16}
                color={Colors.action}
              ></EditIcon>
            </PaddedWrapper>
          )}
        </>
      ) : (
        <span>
          {display || value}
          <PaddedWrapper>
            <EditIcon
              onClick={toggleEditable}
              height={16}
              width={16}
              color={Colors.action}
            ></EditIcon>
          </PaddedWrapper>
        </span>
      )}
    </span>
  );
};
