import React from 'react';

import { ButtonProps } from './Button';
import { EditIcon } from '../../icons';
import { SubmitButtonSmall } from './SubmitButtonSmall';

/**
 * Small Submit Button with Edit Icon
 */
export function EditButton(props: ButtonProps) {
  return <SubmitButtonSmall {...props} icon={EditIcon} />;
}
