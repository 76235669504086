import React from 'react';

import {
  ActionMessage,
  PanelItemWrapper,
  PanelListItem,
  SolicitationTitle,
} from '../../../../shared/styles';

import { BidResponseLabels } from '../../../../shared/constants/bids';
import { EBidResponse } from '../../../../types/suppliers';
import { displayDate } from '../../../../utils/helpers';
import { Paginate } from '../../../../components/common/paginate';

export interface BidResponseListPanelViewProps {
  responses?: EBidResponse[];
  handleItemClick(bidId: number, responseId: number): void;
  label: string;
  selectedBidId?: number;
}

export function BidResponseListPanelView(props: BidResponseListPanelViewProps) {
  const { responses, handleItemClick, selectedBidId } = props;

  function renderResponse(response: EBidResponse, index: number) {
    const { bidId, name, responseDate, responseId, status } = response;
    return (
      <PanelListItem
        key={responseId}
        onClick={() => handleItemClick(Number(bidId), responseId)}
        title={`${name}`}
        selected={bidId === selectedBidId}
      >
        <SolicitationTitle>{name}</SolicitationTitle>
        <ActionMessage>{`${BidResponseLabels.responseDate}: ${displayDate(
          responseDate,
        )}`}</ActionMessage>
        <ActionMessage>{`${BidResponseLabels.status}: ${status}`}</ActionMessage>
      </PanelListItem>
    );
  }

  return (
    <>
      <PanelItemWrapper>
        <Paginate data={responses || []} pageSize={4}>
          {data => data.map(renderResponse)}
        </Paginate>
      </PanelItemWrapper>
    </>
  );
}
