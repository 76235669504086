import React, { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import Notification from './notifications';
import Bidwatch from './bidwatch';
import Ebidproposal from './ebidproposal';
import Planholder from './planholder';
import Quotes from './quotes';
import ActivityReport from './reports';
import {
  memberTypes,
  reportotherbidStatus,
  reportotherquoteStatus,
  usaDateFormat,
} from '../../../utils/constants';
import { activitiessearchTypes, activitiesTypes, memberinfoTypes } from '../../../types';
import { authTypes } from '../../../types/auth';
import moment from 'moment-timezone';

interface propsTypes extends PropsFromRedux {
  history: any;
  auth: authTypes;
  match: any;
  activities: activitiesTypes;
  memberinfo: memberinfoTypes;
}

type CombinedProps = propsTypes & activitiessearchTypes;

function Activities(props: CombinedProps) {
  const {
    resetActivitiesDetails,
    getActivitiesNotifications,
    getActivitiesWatchs,
    history,
    getActivitiesPlanholders,
    getActivitiesEbidders,
    getActivitiesNotificationsMember,
    getActivitiesQuotes,
    setActivitiesDetails,
    getBidActivityReport,
    getQuoteActivityReport,
    auth,
  } = props;
  const { mt: memberType, mi = '' } = auth || {};
  const Tab = props?.match?.params?.Tab;
  const [activeTab, setActiveTab] = useState(Tab ? Tab : 'notifications');

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      history.push(
        `/${memberType === memberTypes.agencyBuyer ? 'buyers' : 'suppliers'}/activities/${tab}`,
      );
    }
  };

  useEffect(() => {
    setActiveTab(Tab);
  }, [Tab, history]);

  useEffect(() => {
    getActivitiesNotifications({ page: 1, size: 100, pagination: false });
  }, [getActivitiesNotifications]);

  useEffect(() => {
    getActivitiesNotificationsMember({ page: 1, size: 500, pagination: false });
  }, [getActivitiesNotificationsMember]);

  useEffect(() => {
    getActivitiesWatchs({ page: 1, size: 100, pagination: false });
  }, [getActivitiesWatchs]);

  useEffect(() => {
    getActivitiesPlanholders({ page: 1, size: 100, pagination: false });
  }, [getActivitiesPlanholders]);

  useEffect(() => {
    getActivitiesEbidders({ page: 1, size: 100, pagination: false });
  }, [getActivitiesEbidders]);

  useEffect(() => {
    getActivitiesQuotes({ page: 1, size: 100, pagination: false });
  }, [getActivitiesQuotes]);

  useEffect(() => {
    setActivitiesDetails({ bidreport_internalLoader: true, activities_bidreports_search: false });
    const startDate = moment().subtract(1, 'months').format(usaDateFormat);
    const endDate = moment().format(usaDateFormat);
    getBidActivityReport({
      memberId: mi,
      startDate,
      endDate,
      statusList: reportotherbidStatus,
      search: false,
    });
  }, [getBidActivityReport, mi, setActivitiesDetails]);

  useEffect(() => {
    setActivitiesDetails({
      quotereport_internalLoader: true,
      activities_quotereports_search: false,
    });
    const startDate = moment().subtract(1, 'months').format(usaDateFormat);
    const endDate = moment().format(usaDateFormat);
    getQuoteActivityReport({
      memberId: mi,
      startDate,
      endDate,
      statusList: reportotherquoteStatus,
      search: false,
    });
  }, [getQuoteActivityReport, mi, setActivitiesDetails]);

  useEffect(() => {
    return () => {
      resetActivitiesDetails();
    };
  }, [resetActivitiesDetails]);

  let navList = [
    { name: 'Notifications', value: 'notifications', component: <Notification {...props} /> },
    { name: 'Watch List', value: 'watchlist', component: <Bidwatch {...props} /> },
    { name: 'Planholders', value: 'planholders', component: <Planholder {...props} /> },
    { name: 'eBid Responses', value: 'ebidresponses', component: <Ebidproposal {...props} /> },
    { name: 'Quotes', value: 'quotes', component: <Quotes {...props} /> },
  ];

  if (memberType === memberTypes.agencyBuyer) {
    navList = [
      ...navList,
      {
        name: 'Solicitation Reports',
        value: 'solicitationreports',
        component: <ActivityReport {...props} />,
      },
    ];
  }

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <Nav tabs>
              {navList.map((item: { value: string; name: string }, index: number) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === item.value })}
                    onClick={() => toggle(item.value)}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {navList.map((item: { value: string; component: any }, index: number) => (
                <TabPane key={index} tabId={item.value}>
                  {activeTab === item.value && item.component}
                </TabPane>
              ))}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
}

const connector = connect(
  (state: any) => ({
    auth: state.auth,
    activities: state.activities,
    memberinfo: state.memberinfo,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Activities));
