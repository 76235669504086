import React from 'react';

interface Props {
  handleChange: any;
  name?: string;
  checked?: boolean;
  indeterminate?: boolean;
  value?: string | boolean;
  id?: string;
  disabled?: boolean | string;
  disabledText?: string;
  parentClass?: string;
  title?: string;
  field: any;
}

// per spec value should be string, but we use boolean
// all over the place and rely on react or the browser
// to convert it for us. Let's abstract that away for
// now and eventually remove this when we can fix the
// many erroneous instances
function convertToString(val: any): string {
  if (typeof val === 'string') return val;
  if (val === true) return 'true';
  if (val === false) return 'false';
  return '';
}

// similar issue here--should be boolean
export function convertToBoolean(val: string | boolean | undefined): boolean {
  if (typeof val === 'boolean') return val;
  if (val === 'true') return true;
  return false;
}

function checkbox(props: Props) {
  const {
    id,
    value,
    indeterminate = false,
    checked,
    handleChange,
    disabled,
    parentClass,
    title,
    disabledText,
    field,
  } = props;
  const { name = '' } = field;

  const onChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.checked;
    handleChange(name, value);
  };

  return (
    <label className={'checkControl ' + (parentClass ? parentClass : '')}>
      {title}
      <input
        type='checkbox'
        onChange={onChange}
        id={id}
        key={id}
        value={convertToString(field.value)}
        name={name}
        checked={field.value}
        ref={element => element && (element.indeterminate = indeterminate)}
        disabled={convertToBoolean(disabled)}
      />
      <span className='checkmark' title={disabled ? disabledText : ''} />
      {indeterminate && <span className='indeterminate' />}
    </label>
  );
}

export default checkbox;
