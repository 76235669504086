import React, { memo } from 'react';
import { TabPane } from 'reactstrap';
import BidInfo from './bidinfo';
import Contact from './contactinfo';
import Documents from './documents';
import ViewResponseHistory from '../../viewresponsehistory';
import { documentItemParamType, ebidResponseItemParamType } from '../../../../../types/biddetail';

interface PropsTypes {
  pagefor?: string;
  data: ebidResponseItemParamType;
  mId: number;
  bidsResponseDocumentDownload?: any;
  supplementalDocuments?: documentItemParamType[];
  responseDocuments?: documentItemParamType[];
}

const EBidResponseComponent = (props: PropsTypes) => {
  const { pagefor } = props;
  return (
    <TabPane tabId='3'>
      {pagefor !== 'supplier-home-responses' ? (
        <h3 className='arrowWrapper'>eBid Response Submission Confirmation</h3>
      ) : null}
      <h4>
        {pagefor !== 'supplier-home-responses' ? 'eBid Response Details' : 'Response Details'}
      </h4>
      <div className='innerColIndent'>
        <ViewResponseHistory {...props} responseId={props.data.responseId} buyers={false} />
        <BidInfo {...props} data={props.data} />
      </div>
      <h4>Contact Information</h4>
      <div className='innerColIndent'>
        <Contact data={props.data} />
      </div>
      <Documents
        data={props.data.requiredDocuments}
        bidID={props.data.bidId}
        documentTitle='Agency Required Documents'
        documentType='1'
        bidsResponseDocumentDownload={props.bidsResponseDocumentDownload}
        responseId=''
        downloadAllDocument=''
      />
      <Documents
        data={props.data.supplementalDocuments}
        bidID={props.data.bidId}
        documentTitle='Supplemental Documents'
        documentType='1'
        bidsResponseDocumentDownload={props.bidsResponseDocumentDownload}
        responseId=''
        downloadAllDocument=''
      />
      <Documents
        data={props.data.responseDocuments}
        bidsResponseDocumentDownload={props.bidsResponseDocumentDownload}
        documentType='2'
        documentTitle='Uploaded Documents'
        bidID={props.data.bidId}
        responseId=''
        downloadAllDocument=''
      />
    </TabPane>
  );
};

export const MyEBidResponse = memo(EBidResponseComponent);
