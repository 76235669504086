import React, { memo, useCallback } from 'react';
import AddressReduxForm from '../../../common/addressreduxform';
import { bidssummaryParamType } from '../../../../types/biddetail';
interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  addNewSupplier?: any;
  changeAddSupplierActive?: any;
}
function AddNewSupplier(props: PropsTypes) {
  const { bidssummary, addNewSupplier, changeAddSupplierActive } = props;
  const { supplierFields = {} } = bidssummary || {};

  const onsubmit = useCallback(
    (formData = {}) => {
      addNewSupplier({ type: 1, formData });
    },
    [addNewSupplier],
  );

  const onsubmitNext = useCallback(
    (formData = {}) => {
      addNewSupplier({ type: 2, formData });
      document.getElementsByClassName('modal')[0].scrollTo(0, 0);
    },
    [addNewSupplier],
  );

  const handleCancel = useCallback(() => {
    changeAddSupplierActive({ addSupplierActive: 'search' });
    const IDName = 'addSupplierRadio1' as string;
    const object = document.getElementById(IDName);
    if (object) object.click();
  }, [changeAddSupplierActive]);

  return (
    <div className='row justify-content-center'>
      <div className='col-12 col-md-12 col-lg-10'>
        <AddressReduxForm
          {...props}
          initialValues={supplierFields}
          onSubmit={onsubmit}
          onSubmitNext={onsubmitNext}
          breadcrumbs={false}
          pagevaluespagefor='planholders-addsupplier'
          pagevaluessavename='Save'
          pagevaluessaveandtextname='Save & Add New'
          pagevaluescancel={handleCancel} // memoized with useCallback
          pagevaluescancelname='Cancel'
        />
      </div>
    </div>
  );
}

export default memo(AddNewSupplier);
