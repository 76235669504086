import { Route } from '../../types/routes';

// Routes of View Components

import AccountRecovery from '../../components/limited/accountrecovery';
import AgencyLanding from '../../components/limited/agencylanding';
import AnonymousAgency from '../../components/anonymous/index';
import ASPAgency from '../../components/limited/aspagency';
import BidLimitExceeded from '../../components/common/splash';
import DownloadErrorModal from '../../components/common/documenterror';
import EmailRecovery from '../../components/limited/emailrecovery';
import LimitedBidsDetails from '../../components/limited/bids/details';
import Login from '../../components/common/login/login';
import LoginFromCookie from '../../components/common/login/loginfromcookie';
import MailValidator from '../../components/common/emailvalidator';
import Pagenotfound from '../../components/common/pagenotfound';
import Registration from '../../components/limited/registration';
import ResetPassword from '../../components/limited/resetpassword';
import WappRegistration from '../../components/limited/wappregistration';

export const limitedRoutes: Route[] = [
  {
    path: '/login',
    component: Login,
    page: 'Login',
    breadcrumb: { Home: '' },
  },
  {
    path: '/loginbytoken',
    component: LoginFromCookie,
    page: 'Login from Cookie',
    breadcrumb: { Home: '' },
  },
  {
    path: '/bidlimitexceeded',
    component: BidLimitExceeded,
    page: 'Bid Limit Exceed',
    breadcrumb: { Home: '' },
  },
  {
    path: '/pagenotfound',
    component: Pagenotfound,
    page: 'Page not found',
    breadcrumb: { Home: '' },
  },
  {
    path: '/registeragency',
    component: AnonymousAgency,
    page: 'Agency Registration',
    breadcrumb: { Home: '' },
  },
  {
    path: '/document/:msg/:id/:docId',
    component: DownloadErrorModal,
    page: 'Document Error Modal',
    breadcrumb: { Home: '' },
  },
  {
    path: '/welcome/:accountId',
    component: MailValidator,
    page: 'Mail Validation',
    breadcrumb: { Home: '' },
  },
  {
    path: '/limited/bids/:bidId/:Tab',
    component: LimitedBidsDetails,
    page: 'Limited Bid Details',
    breadcrumb: { Home: '' },
  },
  {
    path: '/agencies/:StateName/:agencyName/procurement-opportunities/:Id',
    component: AgencyLanding,
    page: 'Limited Agency Landing',
    breadcrumb: { Home: '' },
  },
  {
    path: '/wapp/registration',
    component: WappRegistration,
    page: 'Wapp Registration',
    breadcrumb: { Home: '' },
  },
  {
    path: '/passwordrecovery',
    component: AccountRecovery,
    page: 'Account Recovery',
    breadcrumb: { Home: '' },
  },
  {
    path: '/accountrecovery',
    component: EmailRecovery,
    page: 'Account Recovery',
    breadcrumb: { Home: '' },
  },
  {
    path: '/passwordreset/:ID',
    component: ResetPassword,
    page: 'Account Recovery',
    breadcrumb: { Home: '' },
  },
  {
    path: '/bidvisitlimitexceeded',
    component: BidLimitExceeded,
    page: 'BidLimitExceeded',
    breadcrumb: { Home: '' },
  },
  {
    path: '/registration',
    component: Registration,
    page: 'Registration',
    breadcrumb: { Home: '' },
  },
  {
    path: '/registration/:memberId',
    component: Registration,
    page: 'Registration',
    breadcrumb: { Home: '' },
  },
  {
    path: '/aspagency',
    component: ASPAgency,
    page: 'ASP AGENCIES',
    breadcrumb: { Home: '' },
  },
];
