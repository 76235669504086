import React, { memo } from 'react';
// Component
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import Braintree from '../../common/braintree';
import { appKeys } from '../../../utils/constants';
import { Buttons } from '../../customcontrols/index';
import { PaymentType } from '../../../types/payments';
interface propsTypes {
  payment: PaymentType;
  checkcard: boolean;
  handeClick: any;
  bidName?: string;
  through: string;
  modalopen: any;
  onCardSubmit: any;
}
const PaymentMethod = (props: propsTypes) => {
  const { payment, handeClick, modalopen, through, onCardSubmit, checkcard, bidName } = props;
  const { cardinfo, card_token = '' } = payment;
  const { details = {}, nonce } = card_token;
  const { cardType = '', cardNumberMask = '' } = cardinfo;
  const buttonname = !checkcard && through !== 'changecard' ? 'Make Purchase' : 'Accept';
  const header = `${appKeys.headers.paymentMethod} - ${bidName} - ${appKeys.name}`;
  return (
    <>
      <h4>Payment Method</h4>
      <div className='innerColIndent d-flex align-items-stretch flex-column h-75'>
        {card_token && nonce && details ? (
          <p className='d-block mb-auto'>
            {details.cardType} ( ************{details.lastFour} )
          </p>
        ) : (
          <>
            {cardinfo && cardNumberMask ? (
              <p className='d-block mb-auto'>
                {cardType} ( {cardNumberMask} )
              </p>
            ) : (
              <p className='d-block mb-auto'>Choose a Payment Method</p>
            )}
          </>
        )}
        <Buttons text='Change' classNames='bttn-secondary small my-4 w-50' onClick={handeClick} />
      </div>
      <ModalPopUp title={'Payment Method'} size='md' closeModal={handeClick} isOpen={modalopen}>
        <Braintree
          onSubmitPayment={onCardSubmit}
          cancelClick={handeClick}
          buttonname={buttonname}
          title={header}
        />
      </ModalPopUp>
    </>
  );
};

export default memo(PaymentMethod);
