import React from 'react';

import { BoldText, SpanSpacerLeft, SpanSpacerRight } from '../../../../shared/styles';
import { vendorDocLabels } from './constants';

export function getVendorDocumentUploadMessage(
  vendorName: string = vendorDocLabels.theSelectedAwardee,
) {
  return (
    <>
      <SpanSpacerRight>{'Documents you upload will be associated with'}</SpanSpacerRight>
      <BoldText>{vendorName}</BoldText>
      <SpanSpacerRight>
        {'. Other awardees will not be able to see these. DemandStar will notify'}
      </SpanSpacerRight>
      <BoldText>{vendorName}</BoldText>
      <SpanSpacerLeft>{'when new documents have been uploaded.'}</SpanSpacerLeft>
    </>
  );
}
