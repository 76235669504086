import React from 'react';

import { Footer, Header } from '../layout';

export const Default = (props: any) => {
  return <>{props.children}</>;
};

export const LoggedIn = (props: any) => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
};
