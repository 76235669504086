import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { limitedactionTypes } from '../../types/limited';
import { MenuKeys } from '../../types/routes';

export interface SetBreadcrumbPayload {
  altname?: string;
  component?: JSX.Element | '';
  id?: string | number;
  menuactive?: MenuKeys;
  name: string;
  page: string;
  title: string;
}

export function setBreadcrumb(payload: SetBreadcrumbPayload) {
  return action(actionTypes.SET_BREADCRUMB, payload);
}

export function resetBreadcrumb(payload: limitedactionTypes) {
  return action(actionTypes.RESET_BREADCRUMB, payload);
}
