import * as React from 'react';

import { AmplitudeContext, useAmplitudeContext } from './AmplitudeProvider';

type AmplitudeProps = {
  children: (({ logEvent, instrument }: any) => void) | React.ReactNode;
  eventProperties?: ((computed: unknown) => void) | Record<string, unknown>;
  instanceName?: string;
  userProperties?: Record<string, unknown>;
};

export type AmplitudeEventProps = {
  eventName: string;
  eventProps?: Record<string, unknown>;
};

export function useAmplitude(
  eventProperties: ((computed: unknown) => void) | Record<string, unknown> = {},
  instanceName = '$default_instance',
): any {
  const { amplitudeInstance, eventProperties: inheritedProperties } = useAmplitudeContext();

  return React.useMemo(() => {
    function logEvent(
      eventType: string,
      eventPropertiesIn: ((computed: unknown) => void) | Record<string, unknown> = {},
      callback?: any,
    ) {
      if (!amplitudeInstance) {
        return;
      }

      let computed = inheritedProperties;
      if (typeof eventProperties === 'function') {
        computed = eventProperties(computed);
      } else {
        computed = { ...computed, ...(eventProperties || {}) };
      }
      if (typeof eventPropertiesIn === 'function') {
        computed = eventPropertiesIn(computed);
      } else {
        computed = { ...computed, ...(eventPropertiesIn || {}) };
      }

      amplitudeInstance.logEvent(eventType, computed, callback);
    }

    function setUserProperties(userProperties: Record<string, unknown> = {}) {
      if (!amplitudeInstance) {
        return;
      }

      amplitudeInstance.setUserProperties(userProperties);
    }

    function instrument<T extends () => unknown>(eventType: string, func: T): T {
      function fn(...params: []) {
        const retVal = func ? func(...params) : undefined;
        logEvent(eventType);
        return retVal;
      }
      return fn as any;
    }

    return {
      amplitudeInstance: amplitudeInstance,
      eventProperties: inheritedProperties,
      instrument: instrument,
      logEvent: logEvent,
      setUserProperties: setUserProperties,
    };
  }, [eventProperties, amplitudeInstance, inheritedProperties]);
}

type AmplitudeRelayHook = {
  eventName: string;
  eventProps: Record<string, unknown>;
  relayEvent: (events: AmplitudeEventProps[], event: AmplitudeEventProps) => void;
  setEventDetails: (eventName: string, event: Record<string, unknown>) => void;
};

/**
 * @name useAmplitudeRelay
 * @description Amplitude Events Relay Hook
 * @description Takes an optional array of events with an event and relays the total data to Amplitude
 * @example const { relayEvent } = useAmplitudeRelay();
 * @example relayEvent([], { eventName: 'Example Event', eventProps: { example: true } })
 * @example relayEvent([{ eventName: 'Event', eventProps: { new: 1 }}], { eventName: 'Event', eventProps: { old: 0 } })
 * @returns {String} eventName - The name of the event that was relayed
 * @returns {Object} eventProps - The final event that was relayed
 * @returns {Function} relayEvent - Function to relay a new event
 * @returns {Function} setEventDetails - Function to set hook event details (used for testing)
 */
export function useAmplitudeRelay(): AmplitudeRelayHook {
  const { logEvent } = useAmplitude();
  const [eventProps, setEventProps] = React.useState({});
  const [eventName, setEventName] = React.useState('');

  const setEventDetails = React.useCallback(
    (eventName: string, eventProps: Record<string, unknown>) => {
      setEventProps(eventProps);
      setEventName(eventName);
    },
    [],
  );

  return React.useMemo(() => {
    const relayEvent = (events: AmplitudeEventProps[] = [], event: AmplitudeEventProps) => {
      const eventIndex = events.findIndex(i => i.eventName === event.eventName);
      if (events[eventIndex]) {
        setEventDetails(event.eventName, {
          ...event.eventProps,
          ...events[eventIndex].eventProps,
        });
      } else {
        setEventDetails(event.eventName, {
          ...event.eventProps,
        });
      }
    };

    logEvent(eventName, eventProps);

    return {
      eventName,
      eventProps,
      relayEvent,
      setEventDetails,
    };
  }, [eventProps, eventName, logEvent, setEventDetails]);
}

export function Amplitude(props: AmplitudeProps) {
  const { logEvent, instrument, eventProperties, amplitudeInstance } = useAmplitude(
    undefined,
    props.instanceName,
  );

  // This is API compatible with Amplitude's API, but weird when you think about it
  React.useMemo(
    () => () => {
      if (props.userProperties && amplitudeInstance) {
        amplitudeInstance.setUserProperties(props.userProperties);
      }
    },
    [props.userProperties, amplitudeInstance],
  )();

  // If we're not providing any additional properties, just get out of the way and call the component
  if (!eventProperties) {
    return typeof props.children === 'function'
      ? props.children({ logEvent, instrument })
      : props.children || null;
  }

  return (
    <AmplitudeContext.Provider
      value={{
        eventProperties: { ...eventProperties, ...(props.eventProperties || {}) },
        amplitudeInstance,
      }}
    >
      {typeof props.children === 'function'
        ? props.children({ logEvent, instrument })
        : props.children || null}
    </AmplitudeContext.Provider>
  );
}
