import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable, useRecoilValue } from 'recoil';

import {
  allProductsState,
  cartTotalState,
  initialAgencyProduct,
  initialCountyProduct,
  initialNationalProduct,
  initialStateProduct,
  parentStateState,
  selectedProductsState,
  upsellProductsState,
} from '../../store/recoil/productState';
import { ProductApiResponse, SubscriptionProducts } from '../../types/products';
import { PrepopulatedProducts } from '../../types/subscriptions';
import { prepopulatedProductsState } from '../../store/recoil/subscriptionState';
import { RegistrationUpsellCardData } from '../../types/registration';
import { SelectBox } from '../customcontrols';

import { getParentProduct, getStates, getUpsellProducts, isParentStateSelected } from './helpers';
import { productBundles } from './bundles';
import { SelectBundle } from './SelectBundle';
import SelectCounty from './SelectCounty';

interface SelectStateProps {
  prepopulatedState?: string;
}
export interface StateDropdownItem {
  key: number;
  label: string;
  title: string;
  value: string;
}

const SelectState = (props: SelectStateProps) => {
  const { prepopulatedState } = props;
  const [allProducts] = useRecoilStateLoadable(allProductsState);

  const [parentState, setParentState] = useRecoilState<ProductApiResponse>(parentStateState);
  const [prepopulatedProducts, setPrepopulatedProducts] = useRecoilState<PrepopulatedProducts>(
    prepopulatedProductsState,
  );
  const [selectedProducts, setSelectedProducts] = useRecoilState<SubscriptionProducts>(
    selectedProductsState,
  );
  const [upsellProducts, setUpsellProducts] = useRecoilState<RegistrationUpsellCardData[]>(
    upsellProductsState,
  );

  const cartTotal = useRecoilValue<number>(cartTotalState);

  const [allProductsLoaded, setAllProductsLoaded] = useState([initialNationalProduct]);
  const [hasBundle, setHasBundle] = useState(false);
  const [selectedState, setSelectedState] = useState({
    label: prepopulatedState,
    value: '',
  });
  const [stateProducts, setStateProducts] = useState([initialStateProduct]);

  const handleSelect = (name: string, value: StateDropdownItem) => {
    const dropdownState = stateProducts.filter(state => state.productName === value.label);

    getUpsellProducts(
      dropdownState[0].productId,
      allProductsLoaded,
      setUpsellProducts,
      upsellProducts,
      'popular',
    );

    setSelectedState({ label: value.label, value: value.value });
    setParentState(dropdownState[0]);
  };

  /**
   * Setting up the page with allProductsLoaded, stateProducts, and UpsellCards
   */
  useEffect(() => {
    let needProducts = cartTotal === 0 ? true : false;
    if (allProducts.state === 'hasValue' && needProducts) {
      setAllProductsLoaded(allProducts.contents);

      getUpsellProducts(10156, allProducts.contents, setUpsellProducts, upsellProducts, 'national');

      const states = allProducts.contents.filter(product => product.productType === 'ST');
      setStateProducts(states);

      let parentCounty = initialCountyProduct;
      if (selectedProducts.agency?.parentId && selectedProducts.agency?.parentId > 0) {
        parentCounty = getParentProduct(selectedProducts.agency, allProductsLoaded);
      }

      if (
        parentCounty === initialCountyProduct &&
        selectedProducts.county &&
        selectedProducts.county?.length > 0 &&
        selectedProducts.county[0] !== initialCountyProduct
      ) {
        parentCounty = selectedProducts.county[0];
      }

      let parentStateSelected = false;
      if (selectedProducts.state && selectedProducts.state?.length > 0) {
        parentStateSelected = isParentStateSelected(selectedProducts.state, parentCounty);
      }

      /* istanbul ignore else */
      if (parentCounty !== initialCountyProduct && !parentStateSelected) {
        const countyUpgradeCardPromise = new Promise<void>((resolve, reject) => {
          /* istanbul ignore else */
          if (
            !selectedProducts.county?.find(
              product => product?.productId === parentCounty?.productId,
            ) &&
            selectedProducts.agency?.parentId &&
            selectedProducts.agency?.parentId > 0
          ) {
            resolve(
              getUpsellProducts(
                selectedProducts.agency?.parentId,
                allProducts.contents,
                setUpsellProducts,
                upsellProducts,
                'parent',
                selectedProducts.agency?.productName,
              ),
            );
          } else resolve();
        });

        const stateUpgradeCard = () => {
          /* istanbul ignore else */
          if (
            parentCounty &&
            parentCounty !== initialCountyProduct &&
            !selectedProducts.state?.includes(getParentProduct(parentCounty, allProductsLoaded)) &&
            parentCounty.parentId
          ) {
            if (selectedProducts.agency && selectedProducts.agency !== initialAgencyProduct) {
              getUpsellProducts(
                parentCounty.parentId,
                allProducts.contents,
                setUpsellProducts,
                upsellProducts,
                'parent',
                selectedProducts.agency?.productName,
              );
            } else {
              getUpsellProducts(
                parentCounty.parentId,
                allProducts.contents,
                setUpsellProducts,
                upsellProducts,
                'parent',
                parentCounty.productName,
              );
            }
          }
        };

        const setDropdown = () => {
          if (selectedState.label === '' && parentCounty) {
            setParentState(getParentProduct(parentCounty, allProductsLoaded));
            setSelectedState({
              label: getParentProduct(parentCounty, allProductsLoaded).productName,
              value: getParentProduct(parentCounty, allProductsLoaded).productId.toString(),
            });
          }
        };

        countyUpgradeCardPromise.then(stateUpgradeCard).then(setDropdown);
      }
    }

    return () => {
      needProducts = false;
    };
  }, [
    allProducts,
    allProductsLoaded,
    cartTotal,
    selectedProducts,
    selectedState,
    selectedState.label,
    setParentState,
    setSelectedProducts,
    setSelectedState,
    setStateProducts,
    setUpsellProducts,
    upsellProducts,
  ]);

  useEffect(() => {
    if (selectedState) {
      const stateName = selectedState.label;
      if (stateName && productBundles.find(bundleCategory => bundleCategory.title === stateName)) {
        setHasBundle(true);
      } else setHasBundle(false);
    }
  }, [setHasBundle, selectedState]);

  // can prepopulated move out of a useEffect? Initial value of selectedState should come from prepopulate
  /*   useEffect(() => {
    if (
      allProducts.state === 'hasValue' &&
      prepopulatedProducts.state !== 0 &&
      selectedState.label === ''
    ) {
      const states = allProducts.contents.filter(product => product.productType === 'ST');
      console.log('allproducts states', states)
      const prepopulatedState = states?.find(
        state => state.productId === prepopulatedProducts.state,
      ) || {
        productName: '',
      };
      setSelectedState({
        label: prepopulatedState.productName,
      });
    }
  }, [allProducts, prepopulatedProducts, selectedState.label]); */

  return (
    <form data-testid='select-product'>
      <h4>Please select a state to begin</h4>
      <div data-testid='div-select-product-state'>
        <SelectBox
          dataTestId='select-product-state'
          handleSelect={(name: string, value: StateDropdownItem) => handleSelect(name, value)}
          label='State'
          options={getStates(stateProducts)}
          reactselect={true}
          value={selectedState}
        />
      </div>

      {hasBundle && selectedState.label && (
        <div>
          <SelectBundle isRegistration={true} parentState={parentState} />
        </div>
      )}

      {selectedState.label && (
        <div>
          <SelectCounty isRegistration={true} parentState={parentState} hasBundle={hasBundle} />
        </div>
      )}
    </form>
  );
};

export default SelectState;
