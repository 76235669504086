import React, { memo } from 'react';
// import helpImgs from '../../../../assets/images/help'

import imgsCdn from '../../../../assets/images/imagescdn';

const helpImgs = imgsCdn.helpImgs;

function UpgradeSubscription() {
  // helpImgs = helpImgs[this.icon.substring(0, this.icon.indexOf('.'))]
  return (
    <>
      <p>
        Upgrading your DemandStar account is one of the easiest ways to get in front of more bids –
        and more opportunities on the DemandStar network. Here’s how to upgrade:{' '}
      </p>
      <ol>
        <li>
          Log-in and click on your name in the upper right-hand corner
          <img src={helpImgs.picture1} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Click on Subscriptions
          <img src={helpImgs.picture2} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Click on Renew/Upgrade and follow the steps to add agencies to your account. You can
          choose the State you like and drill down to add counties.{' '}
        </li>
      </ol>
    </>
  );
}

export default memo(UpgradeSubscription);
