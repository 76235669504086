import React, { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import Suspenseloader from '../../common/splash/suspenseloader';

type PropsTypes = PropsFromRedux;

function LoginFromCookie(props: PropsTypes) {
  const { loginUsingCookie } = props;

  useEffect(() => {
    loginUsingCookie({});
  }, [loginUsingCookie]);

  return <Suspenseloader />;
}

const connector = connect(null, dispatch => bindActionCreators({ ...actionCreators }, dispatch));

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(LoginFromCookie));
