import { createNoLoadActionTypes, createActionTypes } from './utils';

export const RESET_LIMITED_DETAILS = createNoLoadActionTypes('RESET_LIMITED_DETAILS');
export const SET_LIMITED_DETAILS = createNoLoadActionTypes('SET_LIMITED_DETAILS');
export const GET_LIMITED_AGENCY_SUMMARY = createActionTypes('GET_LIMITED_AGENCY_SUMMARY');
export const GET_LIMITED_AGENCY_DOCUMENTS = createNoLoadActionTypes('GET_LIMITED_AGENCY_DOCUMENTS');
export const GET_LIMITED_AGENCY_LEGAL = createNoLoadActionTypes('GET_LIMITED_AGENCY_LEGAL');
export const GET_LIMITED_AGENCY_PLANHOLDERS = createNoLoadActionTypes(
  'GET_LIMITED_AGENCY_PLANHOLDERS',
);
export const GET_LIMITED_AGENCY_AWARDS = createNoLoadActionTypes('GET_LIMITED_AGENCY_AWARDS');
export const GET_LIMITED_AGENCY_COMMODITIES = createNoLoadActionTypes(
  'GET_LIMITED_AGENCY_COMMODITIES',
);
export const GET_LIMITED_AGENCY_SELF_DECLARATIONS = createNoLoadActionTypes(
  'GET_LIMITED_AGENCY_SELF_DECLARATIONS',
);
export const GET_LIMITED_STATES_LIST = createNoLoadActionTypes('GET_LIMITED_STATES_LIST');
export const GET_LIMITED_COUNTIES_LIST = createNoLoadActionTypes('GET_LIMITED_COUNTIES_LIST');
export const GET_LIMITED_TIMEZONE_LIST = createNoLoadActionTypes('GET_LIMITED_TIMEZONE_LIST');
export const GET_LIMITED_METRO_LIST = createNoLoadActionTypes('GET_LIMITED_METRO_LIST');
export const SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT = createActionTypes(
  'SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT',
);
export const GET_LIMITED_WAPP_AGENCIES = createActionTypes('GET_LIMITED_WAPP_AGENCIES');
export const SUBMIT_LIMITED_FORGOT_PASSWORD = createActionTypes('SUBMIT_LIMITED_FORGOT_PASSWORD');
export const SUBMIT_LIMITED_FORGOT_EMAIL = createActionTypes('SUBMIT_LIMITED_FORGOT_EMAIL');
export const SUBMIT_LIMITED_RESET_PASSWORD = createActionTypes('SUBMIT_LIMITED_RESET_PASSWORD');
export const GET_LIMITED_VERIFY_SECURITY_CODE = createActionTypes(
  'GET_LIMITED_VERIFY_SECURITY_CODE',
);
export const GET_LIMITED_AGENCY_LANDING = createActionTypes('GET_LIMITED_AGENCY_LANDING');
export const GET_LIMITED_AGENCY_DETAILS = createActionTypes('GET_LIMITED_AGENCY_DETAILS');
