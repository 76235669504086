import React, { memo, Suspense, useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable, useRecoilValue, useSetRecoilState } from 'recoil';
import { toNumber } from 'lodash';

import {
  allProductsState,
  countyZeroState,
  initialAgencyProduct,
  parentStateState,
  selectedProductsState,
  upsellProductsState,
} from '../../store/recoil/productState';
import {
  incompleteRegistrationState,
  recoilRegistrationDataState,
} from '../../store/recoil/registrationState';
import { ProductApiResponse, SubscriptionProducts } from '../../types/products';
import { agenciesByProductState } from '../../store/recoil/agencyState';
import { Buttons } from '../customcontrols';
import { initialRegistrationData } from '../../store/reducers/registration';
import { ModalPopUp } from '../common/modals/ModalPopUp';
import { PrepopulatedProducts } from '../../types/subscriptions';
import { prepopulatedProductsState } from '../../store/recoil/subscriptionState';
import { registrationComponent } from '../../utils/constants';
import { RegistrationUpsellCardData } from '../../types/registration';

import AgencyList from './AgencyList';
import Cart from './Cart';
import { getProduct } from './helpers';
import RegistrationUpsellCard from './callouts/RegistrationUpsellCard';
import SelectState from './SelectState';

interface SelectProductsProps {
  isRegistration: boolean;
  setRegistrationData?: any;
}

const SelectProducts = (props: SelectProductsProps) => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const [selectedProducts, setSelectedProducts] = useRecoilState<SubscriptionProducts>(
    selectedProductsState,
  );
  const [agenciesByProduct, setAgenciesByProduct] = useRecoilState<ProductApiResponse[]>(
    agenciesByProductState,
  );
  const checkboxZero = useRecoilValue<boolean>(countyZeroState);
  const parentState = useRecoilValue<ProductApiResponse>(parentStateState);
  const prepopulatedProducts = useRecoilValue<PrepopulatedProducts>(prepopulatedProductsState);
  const upsellProducts = useRecoilValue<RegistrationUpsellCardData[]>(upsellProductsState);

  const [registrationDataLoadable] = useRecoilStateLoadable(recoilRegistrationDataState);
  const setRecoilRegistrationData = useSetRecoilState(incompleteRegistrationState);

  const [confirmationPopup, toggleConfirmationPopup] = useState(false);
  const [registrationData, setPageRegistrationData] = useState(initialRegistrationData);
  const [showAgencyModal, setShowAgencyModal] = useState(false);
  const [showCart, setShowCart] = useState(false);

  const { setRegistrationData } = props;

  let prepopulatedState = prepopulatedProducts.stateName;
  /* istanbul ignore else */
  if (selectedProducts.state) {
    prepopulatedState = selectedProducts.state[selectedProducts.state.length - 1]
      ? selectedProducts.state[selectedProducts.state.length - 1].productName
      : prepopulatedProducts.stateName;
  }

  const checkout = () => {
    if (showCart) {
      setRegistrationData({
        ...registrationData,
        currentComponent: registrationComponent.CompleteProfile,
      });
    } else toggleConfirmationPopup(true);
  };

  const closeModal = () => {
    setAgenciesByProduct([]);
    setShowAgencyModal(false);
  };

  const goBack = () => {
    setRegistrationData({
      ...registrationData,
      currentComponent: registrationComponent.ChooseAgency,
    });
  };

  useEffect(() => {
    let needModal = true;
    /* istanbul ignore else */
    if (agenciesByProduct.length > 0 && needModal) {
      setShowAgencyModal(true);
    }

    return () => {
      needModal = false;
    };
  }, [agenciesByProduct]);

  useEffect(() => {
    let needCart = true;
    if (
      (needCart && selectedProducts.national === 10156) ||
      (selectedProducts.state && selectedProducts.state.length > 0) ||
      (selectedProducts.county && selectedProducts.county?.length > 0)
    ) {
      setShowCart(true);
    } else {
      setShowCart(false);
    }

    return () => {
      needCart = false;
    };
  }, [selectedProducts, setShowCart]);

  useEffect(() => {
    let needData = true;
    /* istanbul ignore else */
    if (needData && registrationDataLoadable.state === 'hasValue') {
      /* istanbul ignore else */
      if (allProducts.state === 'hasValue') {
        if (registrationData.freeAgency && selectedProducts.agency === initialAgencyProduct) {
          const agency = getProduct(toNumber(registrationData.freeAgency), allProducts.contents);
          setSelectedProducts({ ...selectedProducts, agency: agency });
        }

        if (
          registrationDataLoadable.contents.subscriptionData &&
          registrationDataLoadable.contents.subscriptionData.length > 0 &&
          !checkboxZero
        ) {
          setShowCart(true);
        }
      }
      setPageRegistrationData(registrationDataLoadable.contents);
    }

    return () => {
      needData = false;
    };
  }, [
    allProducts,
    checkboxZero,
    registrationData.freeAgency,
    registrationDataLoadable,
    selectedProducts,
    setPageRegistrationData,
    setSelectedProducts,
  ]);

  useEffect(() => {
    let checkFromEmail = true;

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');

    if (checkFromEmail && email && registrationData === initialRegistrationData) {
      // getIncompleteRegistration() is needed to massage the registrationData before it can proceed to CompleteProfile.
      // That method is called from Cart component, but then Cart will be removed by other logic on this page.
      // For more info: https://demandstar.visualstudio.com/DemandStar%202.0/_workitems/edit/5543
      setShowCart(true);
      setRecoilRegistrationData({ ...registrationData, acceptTerms: true, emailAddress: email });
    }

    return () => {
      checkFromEmail = false;
    };
  }, [registrationData, setRecoilRegistrationData]);

  /**
   * This scrolls to the top of the page on load.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row regWrapper'>
          <div className='col-lg-12'>
            <div className='colWrapper subscriptionWrapper'>
              <div className='row'>
                <div className='col col-9'>
                  <h2 className='arrowWrapper'>2 of 3: Choose Subscriptions</h2>
                  {selectedProducts.agency && selectedProducts.agency !== initialAgencyProduct ? (
                    <>
                      <p className='reg-intro no-bottom-margin'>
                        Now that you've chosen&nbsp;
                        <a onClick={() => goBack()}>{selectedProducts.agency?.productName}</a> as
                        your free agency, add more counties to grow your network of potential
                        clients.
                      </p>
                      <p className='reg-intro no-top-padding'>
                        County subscriptions start at $25 per year.
                      </p>
                    </>
                  ) : (
                    <p className='reg-intro no-top-padding'>
                      Build your network by adding county subscriptions, starting at $25 a year. Or,{' '}
                      <a onClick={() => goBack()}>go back and add a single agency for free</a>!
                    </p>
                  )}
                  <SelectState prepopulatedState={prepopulatedState} />
                  {showCart && !checkboxZero ? (
                    <>
                      <Suspense fallback={<></>}>
                        <Cart />
                      </Suspense>
                      <div>
                        <Buttons
                          classNames='bttn bttn-secondary m-auto'
                          onClick={goBack}
                          text={'Go Back'}
                        />
                        <Buttons
                          classNames='bttn bttn-accent m-auto bttn-continue'
                          onClick={checkout}
                          text={'Next'}
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <Buttons
                        classNames='bttn bttn-secondary m-auto'
                        onClick={goBack}
                        text={'Go Back'}
                      />
                      <Buttons
                        classNames='bttn bttn-accent m-auto bttn-continue'
                        onClick={checkout}
                        text={'Continue without adding subscriptions'}
                      />
                    </div>
                  )}
                </div>
                <div className='col col-3'>
                  {selectedProducts.national === 0 &&
                    upsellProducts.map(product => {
                      return (
                        <RegistrationUpsellCard
                          key={product.product.productId}
                          baseProductName={product.baseProductName}
                          product={product.product}
                          totalAgencies={product.totalAgencies}
                          upsellType={product.upsellType}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp size='lg' title='Agency List' closeModal={closeModal} isOpen={showAgencyModal}>
        <AgencyList agencies={agenciesByProduct} />
      </ModalPopUp>

      <ModalPopUp
        size='lg'
        title={`${selectedProducts.agency?.productName ? 'Notice' : 'Warning'}`}
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        {selectedProducts.agency?.productName ? (
          <>
            <p>
              By not subscribing to any counties or states, you risk missing out on opportunities
              from other agencies near you.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency, not just <b>{selectedProducts.agency?.productName}</b>, in those
              counties posts a new bid.
            </p>
          </>
        ) : (
          <>
            <p>
              To receive proactive notifications about opportunities near you, you must subscribe to
              a geographic region.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency in those counties posts a new bid.
            </p>
          </>
        )}
        <p>Make sure you never miss out on a government contract again!</p>

        <Buttons
          classNames='bttn-secondary mt-2'
          text='Skip getting notifications'
          title='Skip getting notifications'
          type='button'
          onClick={() => {
            toggleConfirmationPopup(false);
            setRegistrationData({
              ...registrationData,
              currentComponent: registrationComponent.CompleteProfile,
            });
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='I want to subscribe to counties!'
          title='I want to subscribe to counties!'
          type='button'
          onClick={() => {
            toggleConfirmationPopup(false);
          }}
        />
      </ModalPopUp>
    </>
  );
};

export default memo(SelectProducts);
