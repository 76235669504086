import { FieldEmail } from '@demandstar/components/field-email/FieldEmail';
import { FieldText } from '@demandstar/components/field-text/FieldText';
import React from 'react';
import { useForm } from 'react-hook-form';

import {
  AwardBidEventName,
  useAwardBidEvents,
} from '../../../../shared/hooks/amplitude/awardBid.events';
import { SubmitButton } from '../../../common/controls/buttons/SubmitButton';
import { useSavePlanholder } from '../../../../shared/hooks';

interface AddNewAwardeeProps {
  closeModal: () => void;
}

interface AddAwardeeForm {
  supplierName: string;
  email: string;
}

/**
 * A pop-up which allows the user to add a planholder and automatically
 * assign that planholder as an awardee.
 */
export const AddNewAwardee = (props: AddNewAwardeeProps) => {
  const { closeModal } = props;

  // Hooks
  const { logEvent } = useAwardBidEvents();
  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<AddAwardeeForm>({ mode: 'onTouched' });

  const savePlanholder = useSavePlanholder();

  async function saveNewSupplier() {
    logAddSupplierAmplitude();
    await savePlanholder({ name: getValues().supplierName, email: getValues().email }, true);
  }

  async function saveAwardeeAndClose() {
    await saveNewSupplier();
    closeModal();
  }

  function logAddSupplierAmplitude() {
    logEvent({
      eventName: AwardBidEventName.NonDemandStarSupplier,
      eventProps: {
        'Awardee Name': getValues().supplierName,
        'Email Address': getValues().email,
      },
    });
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 col-md-12 col-lg-10'>
        <div>
          <FieldText
            name='supplierName'
            label='Supplier Name'
            ref={register({ required: true })}
            error={errors.supplierName}
          />
          <FieldEmail name='email' label='Email' error={errors.email} register={register} />
        </div>
        <SubmitButton disabled={!isValid} onClick={saveAwardeeAndClose}>
          Save Supplier
        </SubmitButton>
      </div>
    </div>
  );
};
