import React, { memo } from 'react';
import { useRecoilState } from 'recoil';

import {
  countyZeroState,
  selectedProductsState,
  upsellProductsState,
} from '../../../store/recoil/productState';
import { compareObjectsByKey } from '../../../utils/sort';
import RegistrationUpsell from './RegistrationUpsell';
import { RegistrationUpsellCardData } from '../../../types/registration';
import { SubscriptionProducts } from '../../../types/products';

const RegistrationUpsellCard = (props: RegistrationUpsellCardData) => {
  const { baseProductName, product, totalAgencies, upsellType } = props;
  const { price, productName, productType } = product;

  const [checkboxZero, setCheckboxZero] = useRecoilState<boolean>(countyZeroState);
  const [selectedProducts, setSelectedProducts] = useRecoilState<SubscriptionProducts>(
    selectedProductsState,
  );
  const [upsellProducts, setUpsellProducts] = useRecoilState<RegistrationUpsellCardData[]>(
    upsellProductsState,
  );

  const handleClick = () => {
    if (checkboxZero) setCheckboxZero(false);

    const newUpsellProducts = upsellProducts.filter(
      (product: RegistrationUpsellCardData) =>
        !(
          product.product.productId === props.product.productId ||
          product.product.parentId === props.product.productId
        ),
    );

    setUpsellProducts(newUpsellProducts);

    switch (productType) {
      case 'CT':
        setSelectedProducts({
          ...selectedProducts,
          county: selectedProducts.county
            ?.concat(props.product)
            .sort(compareObjectsByKey('productName')),
        });
        break;
      case 'ST':
        setSelectedProducts({
          ...selectedProducts,
          state: selectedProducts.state
            ?.concat(props.product)
            .sort(compareObjectsByKey('productName')),
        });
        break;
      case 'NA':
        setSelectedProducts({
          ...selectedProducts,
          national: 10156,
        });
        break;
    }
  };

  let header = `${productName} Subscription`;
  let linkText = `Subscribe to ${productName} for $${price}/year`;
  const level = productType;
  let message = '';

  switch (upsellType) {
    case 'parent':
      message = `${baseProductName} is in ${productName}. Subscribe and get notifications from ${totalAgencies} agencies.`;
      break;
    case 'nearby':
      message = `${baseProductName} is near ${productName}. Subscribe and get notifications from ${totalAgencies} agencies.`;
      break;
    case 'popular':
      message = `You might also like ${productName}. Subscribe and get notifications from ${totalAgencies} agencies.`;
      break;
    case 'national':
      header = 'National Subscription';
      linkText = `Subscribe Nationwide for $${price}/year`;
      message = `Choose a national subscription and get notifications from ${totalAgencies} agencies.`;
      break;
  }

  return (
    <RegistrationUpsell
      linkText={linkText}
      header={header}
      level={level}
      message={message}
      handleClick={handleClick}
    />
  );
};

export default memo(RegistrationUpsellCard);
