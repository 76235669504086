/* TOREFACTOR
  - Exceeds 500 lines.
  - Rewrite ALL moment as DayJS with simpler logic.
  - Replace inline lambda functions
*/
import moment from 'moment-timezone';
import React, { memo, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../controls/inputs/text';
import renderSelect from '../controls/inputs/selectbox';
import renderTextarea from '../controls/inputs/textarea';
import renderCheckbox from '../controls/inputs/checkbox';
import { DeprecatedFormikRadio } from '../controls/inputs/DeprecatedFormikRadio';
import DatepickerField from '../controls/inputs/datepicker';
import { addbidParamType } from '../../../types/addbid';
import { authTypes } from '../../../types/auth';
import { bidItemParamType } from '../../../types/biddetail';
import { memberinfoParamType } from '../../../types/bids';
import { WizardPage } from '../../../types/wizard';
import { accountinfoType } from '../../../types/paymentdetails';
import { sharedTypes } from '../../../types/shared';
import { BidExternalStatusType, usaDateFormat } from '../../../utils/constants';
import { deprecatedGetTimeZoneDateTime, toastFn } from '../../../utils/helpers';
import { eBidsHeadingText, eBidslearnmoreText } from '../../../utils/texts';
import { DeprecatedInput } from '../../customcontrols/input';
import TimePicker from '../controls/inputs/timepicker';
import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import { ModalPopUp } from '../modals/ModalPopUp';
import { validate } from './form/validations';
import AddBidWizardButtons from './AddBidWizardButtons';
import DuplicateBid from './modals/duplicatebid';
import { AddBidInfo } from '../../../types/addbid';
import { useDispatch } from 'react-redux';
import { getaccountinforequiredDocs, submitAddBidInfo } from '../../../store/actions';
import { BidSummaryState } from '../../../store/reducers/bidssummary';
import history from '../../../utils/history';

interface BidInformationProps {
  handleSubmit?: any;
  change?: any;
  setAddBidDetails?: any;
  addbid?: addbidParamType;
  setBreadcrumb?: any;
  currentwizard?: WizardPage;
  memberinfo: memberinfoParamType;
  addbidbidinforeduxForm?: any;
  shared: sharedTypes;
  bidId: number;
  bidssummary?: BidSummaryState;
  currentBidName?: string;
  getaccountinforequiredDocs?: any;
  accountinfo?: accountinfoType;
  auth?: authTypes;
  SkipNext?: any;
  initialValues: AddBidInfo;
}

function BidInformation(props: BidInformationProps) {
  const dispatch = useDispatch();
  const {
    initialValues,
    setAddBidDetails,
    addbid,
    setBreadcrumb,
    currentwizard,
    memberinfo,
    addbidbidinforeduxForm = {},
    shared,
    bidId,
    bidssummary,
    currentBidName,
    accountinfo,
    auth,
  } = props;
  
  const {
    bidtypes,
    bidwriters,
    duplicatebidmodal = false,
    bidStatusList,
    editable,
    focusbroadcasttimefield = false,
  } = addbid || {};
  const { fiscalYear } = shared || {};
  const { results } = bidssummary || {};
  const { bidExternalStatusType = BidExternalStatusType.None, tzfn: bidTimeZone } =
    (results as bidItemParamType) || {};
  const { agencydocs } = accountinfo || {};
  const { opi = '' } = auth || {};
  const { tzfn: memberTimeZone } = memberinfo || {};
  const tzfn = bidTimeZone || memberTimeZone;

  const eBidsRadiobtnyesText = [
    <strong key={1}>Yes</strong>,
    ', allow suppliers to submit responses electronically at no additional cost.',
  ];

  const eBidsRadiobtnnoText = [
    <strong key={1}>No</strong>,
    ', do not allow suppliers to submit responses through DemandStar.',
  ];

  useEffect(() => {
    setTimeout(() => {
      if (focusbroadcasttimefield) {
        const Element = document.getElementById('focusbroadcasttimefieldRef');
        if (Element) {
          Element.scrollIntoView({ behavior: 'smooth' });
        }
        setAddBidDetails({ focusbroadcasttimefield: false });
      }
    }, 500);
  }, [focusbroadcasttimefield, setAddBidDetails]);

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  // TODO: Make grammar consistent. Update Bid + Create Bid OR Bid Update + Bid Create
  useEffect(() => {
    if (currentwizard) {
      setBreadcrumb({
        component: <AddBidControl bidId={bidId} />,
        page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
        title: currentBidName
          ? `Update Bid - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        altname: currentBidName
          ? `Update Bid - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        name: currentBidName ? currentBidName : 'Bid Creation',
        id: bidId,
        menuactive: 'bids',
      });
    }
  }, [bidId, currentBidName, currentwizard, setBreadcrumb]);

  const { syncErrors = {} } = addbidbidinforeduxForm;
  const { eBidding: eBidError = '' } = syncErrors;

  useEffect(() => {
    dispatch(getaccountinforequiredDocs());
  }, [dispatch]);

  const goToPage = () => {
    // TODO: Move to external file.
    window.open(
      'http://network.demandstar.com/wp-content/uploads/2021/01/eBid-Demonstration.mp4',
      '_blank',
    );
  };

  let addBidWriters = bidwriters;

  if (
    !bidExternalStatusType ||
    [BidExternalStatusType.Active, BidExternalStatusType.Upcoming].includes(bidExternalStatusType)
  ) {
    addBidWriters = bidwriters && bidwriters.filter(item => item.ac === true);
  }

  //This triggers on 'Save & Exit'
  const saveAndExit = (data: AddBidInfo) => {
    if (data) {
      dispatch(submitAddBidInfo({ data }));
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={saveAndExit}
      >
        {formikProps => {
          const { handleSubmit, setFieldValue } = formikProps;

          const {
            displayBudgetToSuppliers = false,
            bidstatus = '',
            projectEstimatedBudget = '',
            broadcastDate = '',
            dueDate = '',
            eBidding = 'yes',
          } = (formikProps.values as any) || {};

          return (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <div className='innerColIndent'>
                        <DeprecatedInput
                          label='Member'
                          type='text'
                          classNames='class'
                          placeholder={(memberinfo && memberinfo.mn) || ''}
                          value={(memberinfo && memberinfo.mn) || ''}
                          disabled={'true'}
                        />
                        <Field
                          name='memberBidType'
                          label='Bid Type'
                          type='select'
                          component={renderSelect}
                          options={bidtypes}
                          onChange={setFieldValue}
                          disabled={editable === 'all' ? false : true}
                        />
                      </div>
                    </div>
                    <div className='col-md-11 col-lg-12 col-xl-11'>
                        <div className='innerColIndent'>
                          <div className='col-12 border rounded pt-4 bg-sidebarGray border-secondary formItemSpace'>
                            <h6 data-testid='addbid.bidinformation.ebidsection.head'>
                              {eBidsHeadingText}
                            </h6>
                            <Field
                              name='eBidding'
                              title={eBidsRadiobtnyesText}
                              label={eBidsRadiobtnyesText}
                              component={DeprecatedFormikRadio}
                              type='radio'
                              value='yes'
                              parentClass='d-inline-block mr-4 mt-2'
                              disabled={
                                editable === 'all' &&
                                agencydocs && agencydocs.requiredDocuments.length > 0 &&
                                agencydocs.requiredDocuments[0].responseItemId !== 0
                                  ? false
                                  : true
                              }
                              defaultChecked={eBidding === 'yes' && agencydocs && agencydocs.requiredDocuments.length > 0 ? true : false}
                              handleSelect={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              data-testid='addbid.bidinformation.ebidsection.radio.yes'
                            />
                            {
                              (agencydocs && agencydocs.requiredDocuments.length === 0)?
                                <>
                                  <div className='text-red200 mb-3'>
                                    <i>To enable this option, press the button below and add one or more eBid document types.</i>
                                  </div>
                                  <div className="text-center">
                                    <button 
                                      className="bttn-primary mt-2 mb-3" 
                                      type="button"
                                      data-testid='addbid.bidinformation.ebidsection.conEbidDoc'
                                      onClick={ () => history.push("/buyers/account/configuration/ebiddinginfo")}
                                    >Configure eBidding Documents</button>
                                  </div>
                                </>
                              :null
                            }
                            
                            <Field
                              name='eBidding'
                              title={eBidsRadiobtnnoText}
                              label={eBidsRadiobtnnoText}
                              component={DeprecatedFormikRadio}
                              type='radio'
                              value='no'
                              parentClass='d-inline-block mt-2'
                              disabled={
                                editable === 'all'
                                  ? false
                                  : true
                              }
                              defaultChecked={eBidding === 'no' || (agencydocs &&
                                agencydocs.requiredDocuments.length === 0) ? true : false}
                              handleSelect={(name: string, value: string) =>
                                setFieldValue(name, value)
                              }
                              data-testid='addbid.bidinformation.ebidsection.radio.no'
                            />
                            {eBidding.touched === true && eBidError ? (
                              <div className='text-red200 mb-3'>
                                <b>{eBidError}</b>
                              </div>
                            ) : null}
                            <p className='text-white form-alert mt-1 mb-4 p-3 text-center bg-purple300'>
                              <span
                                className='cursorPointer'
                                onClick={goToPage}
                                data-testid='addbid.bidinformation.ebidsection.url'
                              >
                                {eBidslearnmoreText}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                    {/* agencydocs &&
                    agencydocs.requiredDocuments.length > 0 &&
                    agencydocs.requiredDocuments[0].responseItemId !== 0 ? (
                      
                    ) : null */}
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <div className='innerColIndent'>
                        <Field
                          label='Bid Number'
                          type='text'
                          classNames='class'
                          placeholder='Bid Number'
                          name='bidNumber'
                          component={renderDeprecatedTextField}
                          disabled={editable === 'all' ? false : true}
                          onChange={setFieldValue}
                          maxLength={50}
                        />
                        <div className='row'>
                          <div className='col-lg-6'>
                            <Field
                              name='bidYear'
                              label='Fiscal Year'
                              type='select'
                              component={renderSelect}
                              options={fiscalYear}
                              disabled={editable === 'all' ? false : true}
                              parentClass='my-0'
                              onChange={setFieldValue}
                            />
                          </div>
                        </div>
                        <Field
                          name='bidWriterAccountId'
                          label='Bid Writer'
                          type='select'
                          component={renderSelect}
                          options={addBidWriters}
                          disabled={editable === 'all' ? false : true}
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Bid Name'
                          type='text'
                          classNames='class'
                          placeholder='Bid Name'
                          name='bidName'
                          component={renderDeprecatedTextField}
                          disabled={editable === 'all' ? false : true}
                          maxLength={255}
                          showMaxLength={true}
                          onChange={setFieldValue}
                        />
                        <Field
                          name='bidstatus'
                          label='Bid Status'
                          type='select'
                          component={renderSelect}
                          options={bidStatusList}
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Bid Status Text'
                          type='text'
                          classNames='class'
                          placeholder='Bid Status Text'
                          name='openedText'
                          component={renderTextarea}
                          onChange={setFieldValue}
                          optional='optional'
                        />
                        <div className='row mt-4' id='focusbroadcasttimefieldRef'>
                          <div
                            className='col-lg-6'
                            onClick={() => {
                              if (
                                editable === 'all' &&
                                bidExternalStatusType === BidExternalStatusType.Upcoming
                              ) {
                                if (
                                  moment(moment(dueDate).format(usaDateFormat)).isSameOrBefore(
                                    deprecatedGetTimeZoneDateTime(tzfn, usaDateFormat),
                                  )
                                ) {
                                  setFieldValue('dueDate', '');
                                }
                              }
                            }}
                          >
                            <Field
                              label='Broadcast Date'
                              classNames='class'
                              name='broadcastDate'
                              minDate={moment(
                                deprecatedGetTimeZoneDateTime(tzfn, usaDateFormat),
                              ).subtract(0, 'days')}
                              maxDate={moment(moment(dueDate).format(usaDateFormat)).subtract(
                                0,
                                'days',
                              )}
                              onChange={(value: any) => {
                                setFieldValue('broadcastDate', value);
                              }}
                              disabled={editable === 'all' ? false : true}
                              parentClass='my-1'
                              component={DatepickerField}
                              optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                            />
                          </div>
                          <div className='col-lg-6'>
                            <TimePicker
                              component={renderSelect}
                              timeFieldName='broadcastDateTime'
                              zoneFieldName='broadcastDateTimeZone'
                              parentClass='my-1'
                              label='Broadcast Time'
                              interval={30}
                              setFieldValue={(name: string, value: any) =>
                                setFieldValue(name, value)
                              }
                              disabled={editable === 'all' ? false : true}
                            />
                          </div>
                        </div>

                        {broadcastDate ? (
                          <div className='row mt-4'>
                            <div className='col-lg-6'>
                              <Field
                                label='Due Date'
                                classNames='class'
                                name='dueDate'
                                minDate={moment(moment(broadcastDate).format(usaDateFormat)).add(
                                  1,
                                  'days',
                                )}
                                disabled={
                                  editable === 'all' ||
                                  editable === 'active' ||
                                  (opi && bidstatus.value === BidExternalStatusType.Active)
                                    ? false
                                    : true
                                }
                                onChange={() => (name: string, value: any) =>
                                  setFieldValue(name, value)}
                                parentClass='my-1'
                                component={DatepickerField}
                                optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                              />
                            </div>
                            <div className='col-lg-6'>
                              <TimePicker
                                component={renderSelect}
                                disabled={
                                  editable === 'all' ||
                                  editable === 'active' ||
                                  (opi && bidstatus.value === BidExternalStatusType.Active)
                                    ? false
                                    : true
                                }
                                setFieldValue={(name: string, value: any) =>
                                  setFieldValue(name, value)
                                }
                                timeFieldName='dueTime'
                                zoneFieldName='dueTimeZone'
                                parentClass='my-1'
                                label='Due Time'
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='row mt-4'>
                            <div
                              className='col-lg-6'
                              onClick={() =>
                                toastFn(
                                  'error',
                                  'Please Select Broadcast Date',
                                  'select broadcast date before duedate',
                                )
                              }
                            >
                              <Field
                                label='Due Date'
                                classNames='class'
                                name='dueDate'
                                disabled={true}
                                parentClass='my-1'
                                component={DatepickerField}
                                optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                                onChange={() => (name: string, value: any) =>
                                  setFieldValue(name, value)}
                              />
                            </div>
                            <div
                              className='col-lg-6'
                              onClick={() =>
                                toastFn(
                                  'error',
                                  'Please Select Broadcast Date',
                                  'select broadcast date before duedate',
                                )
                              }
                            >
                              <TimePicker
                                component={renderSelect}
                                disabled={true}
                                timeFieldName='dueTime'
                                zoneFieldName='dueTimeZone'
                                parentClass='my-1'
                                label='Due Time'
                                setFieldValue={(name: string, value: any) =>
                                  setFieldValue(name, value)
                                }
                              />
                            </div>
                          </div>
                        )}

                        <Field
                          label='Bid Bond'
                          type='text'
                          classNames='class'
                          placeholder='Bid Bond'
                          name='bidBond'
                          component={renderDeprecatedTextField}
                          disabled={editable === 'all' ? false : true}
                          optional='optional'
                          parentClass='mt-4'
                          onChange={setFieldValue}
                          maxLength={50}
                        />
                        <Field
                          label='Project Estimated Budget'
                          type='text'
                          classNames='class'
                          placeholder='Hidden From Suppliers By Default'
                          name='projectEstimatedBudget'
                          component={renderDeprecatedTextField}
                          disabled={editable === 'all' ? false : true}
                          optional='optional'
                          onChange={setFieldValue}
                          maxLength={100}
                        />

                        {projectEstimatedBudget ? (
                          <Field
                            title='Display budget in bid details to suppliers'
                            type='text'
                            classNames='class'
                            name='displayBudgetToSuppliers'
                            component={renderCheckbox}
                            defaultChecked={displayBudgetToSuppliers}
                            disabled={editable === 'all' ? false : true}
                            parentClass='formItemSpace'
                            handleChange={setFieldValue}
                          />
                        ) : null}

                        <Field
                          label='Scope of Work'
                          type='text'
                          classNames='class'
                          parentClass='mt-4'
                          placeholder='Scope of Work'
                          name='scopeOfWork'
                          component={renderTextarea}
                          disabled={editable === 'all' ? false : true}
                          optional='optional'
                          onChange={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                  <AddBidWizardButtons {...props} />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>

      <ModalPopUp title='Duplicate Bid' closeModal={false} isOpen={duplicatebidmodal}>
        <DuplicateBid />
      </ModalPopUp>
    </>
  );
}

export default memo(BidInformation);
