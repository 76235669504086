import React, { memo } from 'react';
import { Formik } from 'formik';
import { RegistrationWizardButtons } from './RegistrationWizardButtons';
import PageSettingsForm from '../../common/pagesettingsform';
import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import { WizardPage } from '../../../types/wizard';

interface PageSettingsProps {
  handleSubmit?: any;
  agencyregistration: agencyRegistrationParamType;
  history?: any;
  updateAgencyPageSettings?: any;
  match?: any;
  currentwizard?: WizardPage;
  createBuyer?: any;
}

function PageSettings(props: PageSettingsProps) {
  const { createBuyer } = props;

  const onSubmitFn = (values: any) => {
    createBuyer();
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
            {formikProps => {
              const { values: any, handleSubmit } = formikProps;

              return (
                <form onSubmit={handleSubmit}>
                  <h2 className='lineHR'>Agency Page Settings</h2>

                  <div className='row'>
                    <div className='col-12'>
                      <PageSettingsForm {...props} />
                    </div>
                  </div>

                  <RegistrationWizardButtons {...props} />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default memo(PageSettings);
