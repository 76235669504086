import React, { memo, useEffect } from 'react';
import { Buttons } from '../../customcontrols';
import CompanyAddressForm from '../../common/companyaddressform';
import { registrationComponent } from '../../../utils/constants';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';
import { limitedbiddetailsTypes } from '../../../types/limited';
import { supplierRegistrationTypes } from '../../../types/supplierregistration';

interface propsTypes extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  registration: supplierRegistrationTypes;
  getStateList: any;
}

const CompanyAddressInfo = (props: propsTypes) => {
  const { setSharedDetails, shared, registration, subscriptions, setSubscriptionDetails } = props;
  const { registrationData } = registration || {};
  const { addressInitialForm = {} } = subscriptions || {};
  const { countrieslist = [], sharedFormInitialValues, formPage } = shared;

  useEffect(() => {
    const companyAddressData = { ...registrationData.companyAddress };

    if (!companyAddressData.country) {
      companyAddressData.country = countrieslist.find((item: any) => item.value === 1);
      companyAddressData.countryId = 1;
    }

    const companyInformation = {
      ...registrationData.companyInfo,
      contactName: registrationData.companyName,
    };

    setSubscriptionDetails({
      addressInitialForm: { ...companyAddressData, ...companyInformation, isRegistration: true },
    });
  }, [setSubscriptionDetails, registrationData, countrieslist]);

  useEffect(() => {
    if (formPage && formPage === 'companyAddressForm') {
      setSubscriptionDetails({ addressInitialForm: sharedFormInitialValues });
      setSharedDetails({ formPage: '' });
    }
  }, [formPage, setSharedDetails, setSubscriptionDetails, sharedFormInitialValues]);

  return (
    <>
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <h3 className='arrowWrapper'>Company Contact Information</h3>
                <div className='row d-flex justify-content-center'>
                  <div className='col-12 col-md-8 col-lg-7'>
                    <p>
                      Tell us about your business! When you download documents or respond to a bid,
                      we’ll share this information with the buyer so they can see who’s interested
                      in their solicitation.
                    </p>

                    <CompanyAddressForm
                      pagefor='registration'
                      submitBtn={true}
                      initialValues={addressInitialForm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    limited: state.limited,
    registration: state.registration,
    shared: state.shared,
    accountinfo: state.accountinfo,
    subscriptions: state.subscriptions,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(CompanyAddressInfo));
