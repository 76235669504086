import React, { memo } from 'react';
import { Table } from 'reactstrap';
import {
  deprecatedCheckCountDown,
  formattedPhoneNumber,
  deprecatedGetDateTimeZoneConverted,
} from '../../../utils/helpers';
import CountDownTimer from '../../common/countdowntimer';
import { SummaryType } from '../../../types/quotedetailstypedef';

const tdWidth = { width: '220px' };

interface PropsTypes {
  summary: SummaryType;
}
const QuoteInfo = (props: PropsTypes) => {
  const { summary } = props;

  const {
    quoteName = '',
    buyerName = '',
    id = '',
    dueDateTime = '',
    status = '',
    insuranceReqs = '',
    termsandConditions = '',
    specifications = '',
    contactName = '',
    contactDepartment = '',
    contactAddress1 = '',
    contactCity = '',
    contactStateAbbr = '',
    contactPostalCode = '',
    contactCountryName = '',
    contactEmail = '',
    contactPhone = '',
    // shipName = '',
    // shipDepartment = '',
    // shipAddress1 = '',
    // shipCity = '',
    // shipStateAbbr = '',
    // shipPostalCode = '',
    // shipCountryName = '',
    shipVia = '',
    // shipPhone = '',
    deliveryARO = '',
    tzn = '',
    tzfn = '',
  } = summary;

  // const contactInformation = `${contactName}, ${contactDepartment}, ${contactAddress1}, ${contactCity}, ${contactStateAbbr} ${contactPostalCode}, ${contactCountryName} Email: ${contactEmail}`
  // const shippingInformation = `${shipName}, ${shipDepartment}, ${shipAddress1}, ${shipCity}, ${shipStateAbbr} ${shipPostalCode}, ${shipCountryName} Phone: ${shipPhone}`

  const dueDate: Date = new Date(dueDateTime);

  return (
    <>
      <h3 className='d-flex align-items-center'>
        <label className='flex1 mr-5 mb-0'>
          <span className='arrowWrapper'>{quoteName}</span>{' '}
        </label>
        <i className='tag status'>{status}</i>
      </h3>
      <div className='innerColIndent'>
        <p className='mb-2'>{buyerName}</p>
        <p className='mb-2'>{id}</p>
        <p className='mb-2'>
          Quote Due Date: {deprecatedGetDateTimeZoneConverted(dueDateTime, tzfn)}{' '}
          {dueDateTime ? tzn : ''}
        </p>
        {!['closed', 'notstarted'].includes(
          deprecatedCheckCountDown(dueDateTime, 'supplierquote'),
        ) ? (
          <p className='text-red200 mb-2'>{<CountDownTimer tzfn={tzfn} dueDate={dueDate} />}</p>
        ) : null}
        <p className='textYellow'>This is not a purchase order.</p>
      </div>
      <h4>Quote Information</h4>
      <div className='innerColIndent'>
        <Table borderless className='tableData'>
          <tbody>
            <tr>
              <th style={tdWidth}>Additional Specifications:</th>
              <td>{specifications}</td>
            </tr>
            <tr>
              <th>Insurance Requirements:</th>
              <td>{insuranceReqs}</td>
            </tr>
            <tr>
              <th>Status Notes:</th>
              <td>-</td>
            </tr>
            <tr>
              <th>Terms & Conditions:</th>
              <td>{termsandConditions}</td>
            </tr>
            <tr>
              <th>Contact Information</th>
              <td>
                <span className='row'>
                  <span className='col-sm-4 col-md-2'>
                    <small>Name</small>
                  </span>
                  <span className='col-sm-8 col-md-10'>{contactName}</span>
                  <span className='col-sm-4 col-md-2'>
                    <small>Department</small>
                  </span>
                  <span className='col-sm-8 col-md-10'>{contactDepartment}</span>
                  <span className='col-sm-4 col-md-2'>
                    <small>Address</small>
                  </span>
                  <span className='col-sm-8 col-md-10'>
                    {contactAddress1}, {contactCity}, {contactStateAbbr}, {contactPostalCode}
                  </span>
                  <span className='col-sm-4 col-md-2'>
                    <small>Country</small>
                  </span>
                  <span className='col-sm-8 col-md-10'>{contactCountryName}</span>
                  <span className='col-sm-4 col-md-2'>
                    <small>Email</small>
                  </span>
                  <span className='col-sm-8 col-md-10'>{contactEmail}</span>
                  <span className='col-sm-4 col-md-2'>
                    <small>Phone</small>
                  </span>
                  <span className='col-sm-8 col-md-10'>
                    {contactPhone ? formattedPhoneNumber(contactPhone) : null}
                  </span>
                  {/* {contactInformation} */}
                </span>
              </td>
            </tr>
            {/* <tr>
							<th>Shipping Information:</th>
							<td>{shippingInformation}</td>
						</tr> */}
            <tr>
              <th>Delivery:</th>
              <td>{deliveryARO}</td>
            </tr>
            <tr>
              <th>Shipping Notes:</th>
              <td>{shipVia}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default memo(QuoteInfo);
