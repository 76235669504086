import React, { memo, useState } from 'react';
import { registrationComponent } from '../../../utils/constants';
import { AgencySelection } from '../../shared/accountinfo/subscriptions/agency-selection';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { Buttons } from '../../customcontrols/index';
import { supplierRegistrationTypes } from '../../../types/supplierregistration';
import { actionPayloadTypes } from '../../../types/actiontypedef';
import { ReduxAction } from '../../../store/actions/utils';
import { SubscriptionDetails } from '../../../types/subscriptions';
import { useAmplitude } from '../../amplitude';

interface SelectFreeAgencyProps {
  addInCompleteRegistrationData: (payload: actionPayloadTypes) => ReduxAction;
  getSubscriptionState: (payload: actionPayloadTypes) => ReduxAction;
  setRegistrationData: (payload: actionPayloadTypes) => ReduxAction;
  setAccountInfoDetails: (payload: actionPayloadTypes) => ReduxAction;
  registration: supplierRegistrationTypes;
  UpdateSupplierFreeAgencies: (payload: actionPayloadTypes) => ReduxAction;
}

const SelectFreeAgency = (props: SelectFreeAgencyProps) => {
  const { logEvent } = useAmplitude();
  const { setRegistrationData, addInCompleteRegistrationData, registration } = props;
  const { registrationData } = registration;
  const [confirmationPopup, toggleConfirmationPopup] = useState(false);
  const [freeAgencyInfo, setFreeAgencyInfo] = useState({
    page: '',
    freeAgency: '',
    agencyName: '',
  });

  const updateInCompleteRegistration = (page: string, freeAgency: any, agencyName: string) => {
    const inCompletePayload = {
      email: registrationData.emailAddress,
      JsonData: {
        regData: {
          currentComponent: page,
          registrationData: { ...registrationData, freeAgency, selectedAgencyName: agencyName },
        },
      },
    };
    addInCompleteRegistrationData(inCompletePayload);
  };

  const movetoNextPage = (
    page: string,
    agencies: any = [],
    type = true,
    agencyName = '',
    agencyData: { agencyName: string; agencyState: string; agencyCounty: string },
  ) => {
    let freeAgency = '';
    if (agencies.length > 0) {
      freeAgency = agencies[0];
      if (type) {
        updateInCompleteRegistration(page, freeAgency, agencyName);
      }
      setRegistrationData({
        currentComponent: page,
        registrationData: { ...registrationData, freeAgency, freeAgencyInfo: agencyData },
      });
      logEvent('registration (supplier) - select agency', {
        Description: 'Select a free agency',
        'Free Agency': agencyName,
        'State (Agency)': agencyData.agencyState,
        'County (Agency)': agencyData.agencyCounty,
      });
    } else {
      toggleConfirmationPopup(true);
      setFreeAgencyInfo({ page, freeAgency, agencyName });
    }
  };

  return (
    <>
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center regWrapper'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <h2 className='arrowWrapper'>1 of 3: Choose your free agency</h2>
                <div className='row d-flex justify-content-center regWrapper'>
                  <div className='col-12 col-md-10 col-lg-8'>
                    <AgencySelection
                      userType={'supplier'}
                      products={registrationData.freeAgency ? [registrationData.freeAgency] : []}
                      toggleAgencySelection={() => {}}
                      pageFor='registration'
                      movetoNextPage={movetoNextPage}
                      registration={props.registration}
                      UpdateSupplierFreeAgencies={props.UpdateSupplierFreeAgencies}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        size='lg'
        title='Are you sure?'
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        <p>
          To receive proactive notifications from DemandStar, you must select an agency. Selecting a
          single agency on this step is free.
        </p>
        <p>
          Filter by State and County to see what’s available near you, then select an agency. The
          moment this agency posts a new bid, we’ll send you an email with the details.
        </p>
        <p>Make sure you never miss out on a government contract again!</p>

        <Buttons
          classNames='bttn-secondary mt-2'
          text='Skip getting free notifications'
          title='Skip getting free notifications'
          type='button'
          onClick={() => {
            logEvent('registration (supplier) - skip agency', {
              Description:
                'Clicks the button in the modal to confirm they want to skip adding a free agency',
            });
            updateInCompleteRegistration(
              freeAgencyInfo.page,
              freeAgencyInfo.freeAgency,
              freeAgencyInfo.agencyName,
            );
            setRegistrationData({
              currentComponent: freeAgencyInfo.page,
              registrationData: {
                ...registrationData,
                freeAgency: freeAgencyInfo.freeAgency,
                freeAgencyInfo: {},
              },
            });
            toggleConfirmationPopup(false);
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Choose a free agency now'
          title='Choose a free agency now'
          type='button'
          onClick={() => {
            toggleConfirmationPopup(false);
          }}
        />
      </ModalPopUp>
    </>
  );
};

export default memo(SelectFreeAgency);
