/**
 * Extension of `Array.sort()` that rearranges an indexed array of objects
 * based on a key of the object.
 * @param key the key of the object on which to sort
 * @type T - generic type
 * @returns sorted array of objects
 * @example
 *    myArrayOfObjects = [{id: 1, name:'Parker'},{id: 2, name:'Reece'},{id: 3, name:'Cameron'}]
 *    myArrayOfObjects.sort(compareObjectsByKey('name'))
 *    result: myArrayOfObjects = [{id: 3, name:'Cameron'},{id: 1, name:'Parker'},{id: 2, name:'Reece'}]
 */
export function compareObjectsByKey<T>(key: keyof T, ascending = true) {
  return function innerSort(objectA: T, objectB: T) {
    const sortValue = objectA[key] > objectB[key] ? 1 : objectA[key] < objectB[key] ? -1 : 0;
    return ascending ? sortValue : -1 * sortValue;
  };
}
