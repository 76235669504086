import * as actionTypes from '../actionTypes';
import moment from 'moment-timezone';
import { payloadTypes } from '../../types/actiontypedef';
import { contactInfoParamType, initialEbidsTypes } from '../../types/ebids';
import { docParamType, ebidResponseItemParamType } from '../../types/biddetail';
import { EBidResponse } from '../../types/suppliers';
import { WizardPage, WizardStatus } from '../../types/wizard';

export interface EBidsState {
  // FIXME: Remove '' typings.
  action_buttons: boolean;
  checkvalidation: boolean;
  contactinfo: contactInfoParamType;
  currentEbidResponse: ebidResponseItemParamType | '';
  DocAllowedExt: Array<string>;
  docs: docParamType;
  dueDate: '' | Date;
  ebidResponse: EBidResponse[] | ebidResponseItemParamType[];
  files_response_error: string | Record<string, unknown> | '';
  files_response: string | Record<string, unknown> | '';
  filetypes: [];
  getEbitsResponseDetailsAPICall: boolean;
  pagehasValues: boolean;
  paymentresponse: '' | Record<string, unknown>;
  progressvalue: number;
  responsedetails: {
    requiredDocuments: any[];
    supplementalDocuments: any[];
    supplierId?: string | number;
  };
  selectedEbidResponses: ebidResponseItemParamType[];
  selectoptions: any;
  submit_response_error: string | Record<string, unknown> | '';
  submit_response: string | Record<string, unknown> | '';
  submitted_value: string | Record<string, unknown> | '';
  upload_progress: [];
  wizard: Array<{ status: string; name: string }>;
}

const wizard: WizardPage[] = [
  { name: 'Contact Information', status: WizardStatus.Current, id: 1 },
  { name: 'Documents Upload', status: WizardStatus.Unavailable, id: 2 },
  { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
];

const contactinfo = {
  companyname: '',
  address1: '',
  address2: '',
  city: '',
  postalCode: '',
  phoneNumber: '',
  phoneExtension: '',
  faxNumber: '',
  faxExtension: '',
  state: '',
  country: '',
  totalPrice: ' ',
  altTotalPrice: '',
  notes: '',
  buyerresponsestatus: { label: 'Updated', value: 'UP' },
  buyerresponsedate: moment(),
  buyerdueTime: { value: '11:00', label: '11:00' },
  buyerdueTimeZone: { value: 'PM', label: 'PM' }, // FIXME: These are not timezones.
} as any; // FIXME: Get the typing on this sorted out.

const docs = {
  reqdocs: [],
  supplementaldocs: [],
  ebidResponse: [],
};

const selectoptions = [
  { title: 'None', value: 'None', id: 0 },
  { title: 'Online/Electronic', value: 'EL', id: 1 },
  { title: 'Offline/Manual', value: 'MN', id: 2 },
  { title: 'Not Submitting', value: 'NS', id: 3 },
];

export const initialStateeBids: initialEbidsTypes = {
  wizard,
  contactinfo,
  docs,
  selectoptions,
  filetypes: [],
  progressvalue: 25,
  checkvalidation: false,
  submit_response: '',
  submit_response_error: '',
  submitted_value: '',
  responsedetails: { requiredDocuments: [], supplementalDocuments: [] },
  files_response: '',
  files_response_error: '',
  action_buttons: true,
  upload_progress: [],
  paymentresponse: {},
  ebidResponse: [],
  currentEbidResponse: '',
  selectedEbidResponses: [],
  dueDate: '',
  getEbitsResponseDetailsAPICall: true,
  pagehasValues: false,
  DocAllowedExt: [],
};

export const reducer = (state = initialStateeBids, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.CHANGE_EBID_PAGE.TRIGGER:
      state = {
        ...state,
        wizard: payload.wizard,
        progressvalue: payload.progressvalue,
      };
      break;
    case actionTypes.SET_REQUIRED_DOCS.TRIGGER:
      state = {
        ...state,
        docs: { ...state.docs, ...payload },
      };
      break;
    case actionTypes.GET_EBIDS_FILE_FORMATS.SUCCESS:
      state = {
        ...state,
        filetypes: payload,
        DocAllowedExt: payload.map((item: any) =>
          item.docFormat.trimEnd().trimStart().toLowerCase(),
        ),
      };
      break;
    case actionTypes.GET_EBIDS_RESPONSE_DETAILS.SUCCESS:
      state = {
        ...state,
        responsedetails: payload.value,
        contactinfo: payload.contactinfo,
        docs: { ...state.docs, reqdocs: payload.docs1, supplementaldocs: payload.docs2 },
      };
      break;
    case actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.SUCCESS:
      state = {
        ...state,
        // submit_response: { status: payload.success, for: payload.for },
        // wizard: payload.wizard || state.wizard
      };
      break;
    case actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS.FAILURE:
      state = {
        ...state,
        submit_response_error: { status: payload.error, for: payload.for },
      };
      break;
    case actionTypes.SUBMIT_EBIDS_FILES_DOCS.SUCCESS:
      state = {
        ...state,
        files_response: payload.success,
        wizard: payload.wizard || state.wizard,
      };
      break;
    case actionTypes.SUBMIT_EBIDS_FILES_DOCS.FAILURE:
      state = {
        ...state,
        files_response_error: payload.error,
      };
      break;
    case actionTypes.SET_EBIDS_DETAILS.TRIGGER:
      state = {
        ...state,
        ...payload,
      };
      break;
    case actionTypes.GET_EBID_RESPONSE.SUCCESS:
      state = {
        ...state,
        ebidResponse: payload,
        currentEbidResponse: '',
        selectedEbidResponses: [],
      };
      break;
    case actionTypes.SET_EBID_SUMMARY.SUCCESS:
      state = {
        ...state,
        currentEbidResponse: payload,
        selectedEbidResponses: [...state.selectedEbidResponses, payload],
      };
      break;
    case actionTypes.SET_SELECTED_EBID_SUMMARY.TRIGGER:
      state = {
        ...state,
        currentEbidResponse: payload,
      };
      break;
    case actionTypes.RESET_EBIDS.TRIGGER:
      state = initialStateeBids;
      break;
    default:
      break;
  }
  return state;
};
