import React, { memo, useEffect } from 'react';
import { Field, Formik } from 'formik';
import SelfDeclarationControl from '../../../common/breadcrumb/accountinfo/selfdeclaration';
import { Buttons } from '../../../customcontrols';
import { NoResult } from '../../../customcontrols/index';
import { loadingMsg } from '../../../../utils/texts';
import { sharedTypes } from '../../../../types/shared';
import renderCheckbox from '../../../common/controls/inputs/checkbox';

interface PropsTypes {
  memberId: number;
  GoBackToPage: () => void;
  key?: number;
  handleSubmit?: any;
  saveSelfDeclarationsResult?: any;
  resetAccountInfoState: any;
  setBreadcrumb: any;
  shared: sharedTypes;
  pristine?: any;
  initialValues: any;
  onSubmit: any;
}

function SelfDeclaration(props: PropsTypes) {
  const {
    handleSubmit,
    GoBackToPage,
    saveSelfDeclarationsResult,
    resetAccountInfoState,
    setBreadcrumb,
    shared,
    pristine,
    initialValues,
    onSubmit,
  } = props;
  const { selfdeclaredattributes, internalLoader } = shared;

  useEffect(() => {
    setBreadcrumb({
      component: (
        <SelfDeclarationControl
          submit={() => document.getElementById('selfDeclSubmitBtn')?.click()}
          pristine={pristine}
          cancel={GoBackToPage}
        />
      ),
      page: 'Account Info',
      title: 'Self Declarations',
      altname: 'Self Declarations',
      name: 'Self Declarations',
      menuactive: '',
    });
    //eslint-disable-next-line
  }, [setBreadcrumb, pristine]); // TOREFACTOR: Don't ignore this.

  if (saveSelfDeclarationsResult) {
    //GoBackToPage()
    resetAccountInfoState({ saveSelfDeclarationsResult: undefined });
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {formikProps => {
        const {
          values: any,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValidating,
          validateForm,
          isValid,
          dirty,
        } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-5'>
                <h3 className='arrowWrapper'>Self Declared Attributes</h3>
                <div className='row'>
                  <div className='col-12'>
                    <p>
                      You are encouraged to self-declare any of the attributes that apply to your
                      business, as agencies may perform searches to actively seek out businesses
                      meeting this criteria.
                    </p>
                  </div>
                  <div className='col-12 col-md-6'>
                    {selfdeclaredattributes.length > 0 ? (
                      <>
                        {selfdeclaredattributes.map((item: any, index: any) => (
                          <Field
                            name={item.attributeType}
                            component={renderCheckbox}
                            title={item.attributeName}
                            classNames='class'
                            key={index}
                            checked={
                              formikProps.values[`${item.attributeType}`] === true ? true : false
                            }
                            handleChange={setFieldValue}
                          />
                        ))}
                      </>
                    ) : (
                      <NoResult
                        message={
                          internalLoader ? loadingMsg : 'No Self Declared Attributes Available'
                        }
                      />
                    )}
                  </div>
                </div>
                <div className='d-block mt-3'>
                  <Buttons
                    text='Save Changes'
                    title='Save Changes'
                    type='submit'
                    classNames='bttn bttn-primary float-right'
                    id='selfDeclSubmitBtn'
                  />
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default memo(SelfDeclaration);
