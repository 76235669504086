import styled from 'styled-components';

import { FontWeight, Margin, Padding, TextColor } from '../styles';

export const PaginationContainer = styled.div`
  text-align: center;
  width: 100%;

  ul {
    margin: ${Margin.None};
    padding: ${Padding.None};
  }

  li {
    color: ${TextColor.Action};
    display: inline-block;
    text-align: center;
    width: 2rem;
  }

  li.selected {
    color: ${TextColor.Action};
    a {
      font-weight: ${FontWeight.Bold};
    }
  }

  li.previous,
  li.next {
    a {
      font-weight: ${FontWeight.Bold};
    }
  }

  li.previous.disabled,
  li.next.disabled {
    a {
      font-weight: ${FontWeight.Base};
    }
  }

  span.pagination-label {
    display: block;
    padding-bottom: ${Padding.Base};
  }
`;
