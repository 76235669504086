import React, { memo, useEffect } from 'react';
import { Buttons } from '../../customcontrols';
import { toastFn } from '../../../utils/helpers';
import { bidssummaryParamType } from '../../../types/biddetail';
import { PaymentType } from '../../../types/payments';
interface PropsTypes {
  history: any;
  setPaymentFormDetails: any;
  submitEbitPaymentCard: any;
  submitEbitPaymentMethod: any;
  payment: PaymentType;
  bidssummary: bidssummaryParamType;
  match: any;
  checkcard: boolean;
  handeClick: any;
}
const OrderSummery = (props: PropsTypes) => {
  const {
    submitEbitPaymentMethod,
    payment,
    setPaymentFormDetails,
    checkcard,
    handeClick,
    history,
    bidssummary,
  } = props;
  const { cardinfo, card_token = '', payment_error = '', fullName } = payment;
  const { results } = bidssummary;
  const { fee = '5.00' } = results;
  const bidID = props.match.params.bidId || '';

  useEffect(() => {
    if (payment_error) {
      setPaymentFormDetails({ payment_error: '' });
      toastFn('error', payment_error || 'Payment Not Successfull', 'payment success error save');
    }
  }, [payment_error, setPaymentFormDetails]);

  const submitPayment = () => {
    if ((cardinfo && cardinfo.cardNumberMask) || (card_token && card_token.nonce)) {
      const data = { bidId: bidID };
      submitEbitPaymentMethod({
        data: data,
        card_token,
        cardinfo,
        results,
        fullName,
      });
    } else {
      toastFn('error', 'Please Add Payment Method', 'add payment info save--');
    }
  };

  const buttonname = checkcard ? 'Make Purchase' : 'Place Order';

  return (
    <>
      <h4>Order Summary</h4>
      <div className='innerColIndent'>
        <div className='row border-bottom'>
          <span className='col-8 pl-0'>Order Total</span>
          <span className='col-4 pr-0 text-right'>${fee}</span>
        </div>
        {/* <div className='row border-bottom py-3'>
						<span className='col-8 pl-0'>Total befor tax:</span>
						<span className='col-4 pr-0 text-right'>$5.00</span>
						<span className='col-8 pl-0'>Estimated tax:</span>
						<span className='col-4 pr-0 text-right'>$5.00</span>
					</div> */}
        {/* <div className='row '>
						<span className='col-8'>Order Total:</span>
						<span className='col-4 text-right'>$5.00</span>
					</div> */}
        {(cardinfo && cardinfo.cardNumberMask) || (card_token && card_token.nonce) ? (
          <Buttons
            classNames='bttn bttn-primary mt-4 w-100'
            onClick={submitPayment}
            text={buttonname}
          />
        ) : (
          <Buttons
            classNames='bttn bttn-primary mt-4 w-100'
            onClick={handeClick}
            text={buttonname}
          />
        )}
        <Buttons
          classNames='bttn bttn-secondary mt-4 w-100'
          text='Cancel'
          onClick={() => history.goBack()}
        />
      </div>
    </>
  );
};

export default memo(OrderSummery);
