import { FontFamily } from '@demandstar/components/styles';
import styled from 'styled-components';

import { Colors } from '../../../shared/styles';
import { FormFieldContainer } from '../../forms/styled/FormFieldStyled';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0;
  width: 100%;
`;

export const WidgetContainer = styled.div`
  padding: 0.5rem 0;
`;

export const WidgetInputWrapper = styled(FormFieldContainer)`
  display: block;
`;

export const WidgetLabel = styled.label`
  border-radius: 0.125rem 0.125rem 0 0;
  position: relative;
  top: 0.85rem;
  z-index: 1;
  background: ${Colors.white};
  color: ${Colors.grayDark};
  font-family: ${FontFamily.Header};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  left: 1.1875rem;
  margin-bottom: 0;
  padding: 0.125rem 0.75rem;
`;

export const WidgetSetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const WidgetSetTitle = styled.div`
  font-family: ${FontFamily.Header};
  font-size: 1.5rem;
`;
export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FileInputWidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const FileInputWrapper = styled.div`
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  position: relative;
  top: 1.5rem;
`;

interface FileNameWrapperProps {
  hasValue?: boolean;
}

export const FileNameWrapper = styled.div<FileNameWrapperProps>`
  background: ${Colors.white};
  color: ${Colors.grayInputBorder};
  cursor: pointer;
  ${({ hasValue }) =>
    hasValue &&
    `border-color: ${Colors.grayInputBorder};
    border-radius: 0.25rem;
    border: 0.0625rem solid;`}
  height: 2.5rem;
  margin: 1.575rem 1rem 0 -0.75rem;
  max-width: 20rem;
  min-width: 12rem;
  overflow-x: hidden;
  overflow: hidden;
  padding: 0.5rem 0.5rem 0.25rem 1.25rem;
  text-overflow: ellipsis;
  user-select: none;
  word-break: break-all;
`;
