import React, { memo } from 'react';
import { Alert } from '@demandstar/components/alert';
import { Status } from '@demandstar/components/constants';
import { useRecoilState } from 'recoil';

import {
  initialStateProduct,
  selectedProductsState,
  upsellProductsState,
} from '../../../store/recoil/productState';
import { ProductApiResponse, SubscriptionProducts } from '../../../types/products';
import { compareObjectsByKey } from '../../../utils/sort';
import { RegistrationUpsellCardData } from '../../../types/registration';

interface RegistrationInfoAlertProps {
  agenciesInCart?: number;
  agenciesWithUpgrade?: number;
  product?: ProductApiResponse;
  type: 'county' | 'national' | 'nationalUpgrade' | 'stateUpgrade';
}

const RegistrationInfoAlert = (props: RegistrationInfoAlertProps) => {
  const { agenciesInCart, agenciesWithUpgrade, product = initialStateProduct, type } = props;

  const [selectedProducts, setSelectedProducts] = useRecoilState<SubscriptionProducts>(
    selectedProductsState,
  );
  const [upsellProducts, setUpsellProducts] = useRecoilState<RegistrationUpsellCardData[]>(
    upsellProductsState,
  );

  let handleClick = () => { };
  let header = '';
  let linkText = '';
  let message = '';
  let moreAgencies = 0;

  if (agenciesWithUpgrade && agenciesInCart) {
    moreAgencies = agenciesWithUpgrade - agenciesInCart;
  }

  switch (type) {
    case 'county':
      header = 'Where did the county subscriptions go?';
      message =
        'Because you added a state subscription, we’ve hidden the counties in that state. They’re included in your state subscription.';
      break;
    case 'national':
      header = 'Where did the other subscriptions go?';
      message =
        'Because you added a national subscription, we’ve hidden all the others. They’re included in your national subscription.';
      break;
    case 'nationalUpgrade':
      handleClick = () => {
        setSelectedProducts({ ...selectedProducts, national: 10156 });
      };
      let messageEnding = `get notifications from ${moreAgencies} more agencies.`;
      switch (moreAgencies) {
        case 0:
          messageEnding = 'save';
          break;
        case 1:
          messageEnding = `get notifications from ${moreAgencies} more agency.`;
          break;
      }
      header = 'Upgrade to a national subscription';
      linkText = `Subscribe to nationwide for $${product.price} / year`;
      message = `Upgrade to a national subscription for $${product.price} a year, and ${messageEnding}`;
      break;
    case 'stateUpgrade':
      handleClick = () => {
        const newUpsellProducts = upsellProducts.filter(
          (state: RegistrationUpsellCardData) => state.product !== product,
        );
        setUpsellProducts(newUpsellProducts);

        setSelectedProducts({
          ...selectedProducts,
          state: selectedProducts.state?.concat(product).sort(compareObjectsByKey('productName')),
        });
      };
      header = `Upgrade to a ${product.productName} subscription`;
      linkText = `Subscribe to ${product.productName} for $${product.price} / year`;
      message = `Get all notifications from all governments in ${product.productName} and save by upgrading to a state subscription.`;
      break;
  }

  return (
    <Alert
      handleClick={handleClick}
      header={header}
      linkText={linkText}
      message={message}
      type={Status.Warning}
    />
  );
};
export default memo(RegistrationInfoAlert);
