import React, { memo } from 'react';

interface PropsTypes {
  optional?: any;
  meta?: any;
  handleChange?: any;
  parentClass?: any;
  label?: any;
  reduxform?: boolean;
  input?: any;
  classNames?: string;
  name?: string;
  placeholder?: string;
  maxLength?: any;
  disabled?: any;
  value?: any;
  remainShow?: any;
  type?: any;
}

function TextArea(props: PropsTypes) {
  const { optional = '' } = props;
  // let inputClass = []
  // if (!props.reduxform) {
  // 	inputClass = ['mdi mdi-close-circle-outline']
  // 	if (props.value !== '') {
  // 		inputClass.push('focus1')
  // 	}
  // }
  const { touched, error } = props.meta || {};

  const onInputChange = (event: any) => {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'file'
        ? target.files
        : target.value;
    const name = target.name;
    props.handleChange(name, value);
  };

  return (
    <div
      className={
        'inputWrapper clearfix ' + props.parentClass + (touched && error ? ' errorWrapper' : '')
      }
    >
      <div>
        <label>{props.label}</label>
        {/*<i className={inputClass.join(' ')} />*/}
        {props.reduxform ? (
          <textarea
            {...props.input}
            className={props.classNames}
            name={props.name}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            disabled={props.disabled === 'true' ? true : false}
          />
        ) : (
          <textarea
            className={props.classNames}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            maxLength={props.maxLength}
            disabled={props.disabled === 'true' ? true : false}
            onChange={onInputChange}
          />
        )}
      </div>

      {props.remainShow &&
        props.remainShow === true &&
        (props.reduxform ? (
          <span className='textArea'>
            {props.maxLength - props.input.value.length} Characters Remaining
            {optional === true ? '   (optional)' : optional ? `  (${optional}) ` : ''}
          </span>
        ) : (
          <span className='textArea'>
            {props.maxLength - props.value.length} Characters Remaining
          </span>
        ))}

      {optional === true && !props.remainShow ? (
        <span>(optional)</span>
      ) : optional && !props.remainShow ? (
        <span>({optional})</span>
      ) : (
        ''
      )}

      <div className='errorMsg textArea'>
        {touched && error ? <>{touched && error && <span>{error}</span>}</> : null}
      </div>
    </div>
  );
}

export default memo(TextArea);
