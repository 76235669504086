import React, { memo, useState } from 'react';
import { NoResult } from '../customcontrols';
import { loadingMsg } from '../../utils/texts';
import { commodityItemsParamType } from '../../types/commoditycodes';
import { sharedTypes } from '../../types/shared';

interface PropsTypes {
  commodities?: commodityItemsParamType[];
  shared: sharedTypes;
}

const initialcount = 5;

const CommodityExpand = (props: PropsTypes) => {
  const { commodities = [], shared } = props;
  const { internalLoader } = shared || {};
  const [commodityListCount, setCommodityListCount] = useState(initialcount);

  return (
    <>
      {commodities.length > 0 ? (
        <>
          {commodities
            .map((commodity, id) => (
              <p key={id} className='mb-3'>
                <i className='staticTxt'>
                  [
                  {commodity.formattedCode ||
                    commodity.fullCode ||
                    commodity.FormattedCode ||
                    commodity.FullCode}
                  ]
                </i>{' '}
                {commodity.commodityDescription || commodity.CommodityDescription}
              </p>
            ))
            .slice(0, commodityListCount)}
          {commodities.length > initialcount && commodityListCount < commodities.length ? (
            <span
              className='float-left staticLink clearfix'
              title='Show More'
              onClick={() => setCommodityListCount(commodities.length)}
            >
              Show More <i className='mdi mdi-chevron-double-down' />
            </span>
          ) : null}
          {commodities.length > initialcount && commodities.length === commodityListCount ? (
            <span
              className='float-left staticLink clearfix'
              title='Show Less'
              onClick={() => setCommodityListCount(initialcount)}
            >
              Show Less <i className='mdi mdi-chevron-double-up' />
            </span>
          ) : null}
        </>
      ) : (
        <NoResult message={internalLoader ? loadingMsg : 'No Commodities Available'} />
      )}
    </>
  );
};

export default memo(CommodityExpand);
