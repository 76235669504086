import React, { useCallback, useEffect, useState } from 'react';
import { toastFn } from '../../../utils/helpers';
import AddressReduxForm from '../../common/addressreduxform';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { Buttons } from '../../customcontrols/index';
import { PaymentType } from '../../../types/payments';
import { sharedTypes } from '../../../types/shared';
import { accountinfoType } from '../../../types/paymentdetails';
import { useDispatch } from 'react-redux';
import { getCountiesList } from '../../../store/actions';
interface BillingAddressProps {
  payment: PaymentType;
  memberinfo?: any;
  getAcoountinfoMemberdetails?: any;
  submitEbitBillingAddress?: any;
  setPaymentFormDetails?: any;
  shared: sharedTypes;
  form?: any;
  breadcrumbs?: boolean;
  pagefor?: string;
  bidID?: any;
  bidName?: string;
  accountinfo: accountinfoType;
}
const BillingAddress = (props: BillingAddressProps) => {
  const dispatch = useDispatch();

  const {
    getAcoountinfoMemberdetails,
    submitEbitBillingAddress,
    setPaymentFormDetails,
    shared,
    payment,
    accountinfo,
  } = props;
  const { stateslist = [], countieslist = [], countrieslist = [] } = shared;
  const { paymentinfo, save_address_status = false, paymentvalidation = false } = payment;

  const { accountinfo: accountInfo } = accountinfo || {};
  const { memberInfo = [] } = accountInfo;

  let addressData: any = memberInfo.find((item: any) => item.addressType === 'BA');
  if (!addressData) {
    addressData = memberInfo.find((item: any) => item.addressType === 'MA');
  }

  const [modalopen, setModalopen] = useState(false);
  let billingMemberAddress = {} as any;
  if (memberInfo && memberInfo.length > 0) {
    billingMemberAddress = memberInfo.find((item: any) => item.addressType === 'BA');
    if (!billingMemberAddress) {
      billingMemberAddress = memberInfo.find((item: any) => item.addressType === 'MA');
    }
  }

  // getting card tabs from api
  useEffect(() => {
    getAcoountinfoMemberdetails();
  }, [getAcoountinfoMemberdetails]);

  useEffect(() => {
    if (addressData && addressData.stateId) {
      dispatch(getCountiesList(addressData.stateId));
    }
  }, [addressData, dispatch]);

  // Check validation and go to next page
  useEffect(() => {
    if (paymentvalidation) {
      setPaymentFormDetails({ paymentvalidation: false });
      toastFn('error', 'Please enter required fields', 'payment info card input');
    }
  }, [paymentvalidation, save_address_status, setPaymentFormDetails]);

  const handeClick = useCallback(() => {
    setModalopen(!modalopen);
  }, [setModalopen, modalopen]);

  useEffect(() => {
    if (save_address_status) {
      setPaymentFormDetails({ save_address_status: false });
      setModalopen(false);
      toastFn('success', 'Saved', 'payment info ex address save');
    }
  }, [setModalopen, save_address_status, setPaymentFormDetails]);

  let initialValues = {
    ...paymentinfo,
    address1: addressData ? addressData.address1 : '',
    address2: addressData ? addressData.address2 : '',
    city: addressData ? addressData.city : '',
    state: stateslist && addressData && stateslist.find(item => item.value === addressData.stateId),
    country:
      countrieslist &&
      addressData &&
      countrieslist.find((item: { value: any }) => item.value === addressData.countryId),
    county:
      countieslist && addressData && countieslist.find(item => item.value === addressData.countyId),
    postalCode: addressData ? addressData.postalCode : '',
    pagefor: 'billingaddress',
  } as any;

  const changebilling = (formData = {}) => {
    submitEbitBillingAddress({ data: formData });
    initialValues = { ...formData };
  };

  const {
    address1 = '',
    address2 = '',
    city = '',
    postalCode = '',
    countryId = '',
    stateName: state = {},
    countyName: county = {},
  } = addressData || {};

  return (
    <>
      <h4>Billing Address</h4>
      <div className='innerColIndent d-flex align-items-stretch flex-column h-75'>
        {addressData ? (
          <>
            <p className='d-block mb-auto'>
              {address1} {address2 ? `, ${address2}` : ''}
              <br />
              {`${city}`}
              {`,${county}`}
              <br />
              {`${state},`}
              {countrieslist.find((item: { value: any }) => item.value === countryId).label}
              {`, ${postalCode}`}
            </p>
            <Buttons
              text='Change'
              classNames='bttn-secondary small my-4 w-50'
              onClick={handeClick}
            />
          </>
        ) : (
          <p>Same as mailing address</p>
        )}
      </div>
      <ModalPopUp title={'Billing Address'} size={'md'} closeModal={handeClick} isOpen={modalopen}>
        <AddressReduxForm
          {...props}
          initialValues={initialValues}
          onSubmit={changebilling}
          breadcrumbs={false}
          pagevaluespagefor='orderpackage-billingaddress'
          pagevaluescancel={handeClick} // memoized with useCallback
          pagevaluescancelname='Cancel'
          pagevaluessavename='Update'
          pagefor='orderbidpackage'
        />
      </ModalPopUp>
    </>
  );
};

export default BillingAddress;
