import React, { useEffect } from 'react';

import { bidAwardeeListState, selectedBidAwardeeState } from '../../../store/recoil/award-bid';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { Awardee } from '../../../types/biddetail';
import { defaultNumericId } from '../../../shared/constants';
import { generateSelectOptions } from '../../../utils/helpers';
import { getLoadableContents } from '../../../utils';
import { PanelText } from '../../../shared/styles';
import { Selectable } from '../../../types/common';
import { SelectBox } from '../../customcontrols';
import { useResetOnUnmount } from '../../../shared/hooks/useResetOnUnmount';
import { useSupplierMemberId } from '../../../shared/hooks/useMemberInfo';
import { vendorDocLabels } from '../../buyer/awardbid/add-vendor-docs/constants';

export function BidAwardeeSelect() {
  /// Application state
  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const awardeeList = getLoadableContents(useRecoilValueLoadable(bidAwardeeListState)) || [];
  const [selectedBidAwardee, setSelectedBidAwardee] = useRecoilState(selectedBidAwardeeState);
  const { setSupplierMemberId, supplierMemberId } = useSupplierMemberId();

  // Awardee select vars
  const awardeeOptions = generateSelectOptions<Awardee>(
    awardeeList,
    'supplierName',
    'awardedToMemberId',
  );

  const selectedAwardeeOption = {
    label: selectedBidAwardee?.supplierName || '',
    value: selectedBidAwardee?.awardedToMemberId,
  };

  /**
   * Select a default `selectedBidAwardee` and `selectedmemberId`.
   */
  useEffect(() => {
    if (!selectedBidAwardee && awardeeList[0]) {
      setSelectedBidAwardee(awardeeList[0]);
      setSupplierMemberId(awardeeList[0].awardedToMemberId);
    }
  }, [
    awardeeList,
    selectedBidAwardee,
    setSelectedBidAwardee,
    setSupplierMemberId,
    supplierMemberId,
  ]);

  // Reset `selectedBidAwardee` on unmount.
  useResetOnUnmount(selectedBidAwardeeState);

  function handleAwardeeSelection(name: string, value: Selectable<number>) {
    const selectedAwardee =
      awardeeList.find(awardee => awardee.awardedToMemberId === value.value) || selectedBidAwardee;
    setSelectedBidAwardee(selectedAwardee);
    setSupplierMemberId(selectedAwardee?.awardedToMemberId || defaultNumericId);
  }

  if (awardeeList.length > 1) {
    return (
      <>
        <PanelText>{vendorDocLabels.documentListDescription}</PanelText>
        <SelectBox
          handleSelect={handleAwardeeSelection}
          label='Awardee'
          name='awardedToMemberId'
          options={awardeeOptions}
          reactselect
          value={selectedAwardeeOption}
        />
      </>
    );
  }
  return <></>;
}
