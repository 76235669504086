import React, { memo } from 'react';
// import helpImgs from '../../../../assets/images/help'
import imgsCdn from '../../../../assets/images/imagescdn';

const helpImgs = imgsCdn.helpImgs;

function AddCommoditycode() {
  return (
    <>
      <p>
        Commodity codes are standard classification codes for products and services used by
        procurement officers. All bids on DemandStar are classified into commodity codes. DemandStar
        uses these codes to send notices to suppliers who have elected to receive bids for these
        products and services. So having as many commodity codes - and the correct ones on your
        account – is really important.{' '}
      </p>
      <p>Here is how you add Commodity Codes to your DemandStar account.</p>
      <ol>
        <li>
          Log in to your DemandStar account.
          <img src={helpImgs.picture8} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Click on the drop-down menu below your user name in the upper right corner and select
          “Commodity Codes”.
          <img src={helpImgs.picture9} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Click on “Edit”, then Use single words in the search field, for example: “hose” (Tip:
          Think of this like you would a keyword search in Google®)
          <img src={helpImgs.picture10} alt='images' className='d-block mt-2 img-fluid' />
          <img src={helpImgs.picture11} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Select the codes that you want to add by checking the boxes. They will appear in the
          bottom of the box.
          <img src={helpImgs.picture12} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          When you have finished selecting codes click on “Submit”.
          <img src={helpImgs.picture13} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          You’ll see that the codes have been updated. Repeat this process for other single words in
          the search field.
          <img src={helpImgs.picture14} alt='images' className='d-block mt-2 img-fluid' />
        </li>
      </ol>
    </>
  );
}

export default memo(AddCommoditycode);
