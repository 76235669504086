import React, { memo } from 'react';

function DetailsPage() {
  return (
    <>
      <p>You’ve done it. You can now review a summary of your completed eBid. </p>
      <p>
        On the “Bid Details” review the information you’ve selected/input for the solicitation. You
        may open to check the documents you uploaded by hovering over them and clicking on them.{' '}
      </p>
      <p>
        When you are ready click the “A” with the checkmark to APPROVE the documents you uploaded
        (unless you chose the “auto-approve” option).{' '}
      </p>
      img
      <p>
        Once you APPROVE the documents, they will say “Complete” and, at the top right corner a
        “Finish Bid” button will appear. To see the document before approving it, simply hover over
        the document name and you can click to open it.{' '}
      </p>
      <p>
        Click on “Finish Bid” button and your solicitation will broadcast on the date you have
        scheduled
      </p>
      img
      <p>
        <b>
          <i className='mdi mdi-information-outline mdi-24px mr-1' />
        </b>
        <br />
        Bid broadcast countdown is live and showing at top of Bid Details page.
      </p>
      img
    </>
  );
}

export default memo(DetailsPage);
