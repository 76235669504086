import { AllStates } from '../../../../store/recoil/addressState';
import { getCountiesList } from '../../../../store/services';
export interface AddressLookupCounties {
  id: number;
  name: string;
  countryId: number;
}

export const getAddressStates = (states: AllStates[]) => {
  return states.map(state => ({
    label: state.name,
    value: state.id,
  }));
};

export const getAddressCounties = async (stateId: string): Promise<AddressLookupCounties[]> => {
  const stateCounties = await getCountiesList(stateId);
  return stateCounties?.data?.result.map((county: { id: number; name: string }) => ({
    key: county.id,
    label: county.name,
    title: county.name,
    value: county.id,
  }));
};

export const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
