import React, { memo } from 'react';
import { BidCard } from '../BidPlans/BidPlans';
import PurchasePlanCard from '../PurchasePlanCard/PurchasePlanCard';
import history from '../../../utils/history';

interface BasicUserPlansProps {
  toggleModal: () => void;
  productPrices: { countyPrice: number; statePrice: number; nationalPrice: number };
}

export const BasicUserPlans = (props: BasicUserPlansProps) => {
  const { toggleModal, productPrices } = props;

  const subscriptionPage = () => {
    history.push({
      pathname: '/subscription/renewal',
    });
  };

  const cards: BidCard[] = [
    {
      name: 'freeAgency',
      title: 'Free Agency Account',
      subtitle: 'Subscribe to one government agency and receive:',
      bullets: [
        'Free bid documents from that agency',
        'Instant e-mail notifications for future bid opportunities',
        'Notifications tailored to your business',
      ],
      action: 'Choose your Agency',
      price: 0,
      handleClick: toggleModal,
    },
    {
      name: 'countyGeneric',
      title: 'County Subscription',
      subtitle: 'Subscribe to all governments in a county and receive:',
      bullets: [
        'Free access to all bid documents from any government in that county',
        'Instant e-mail notifications for future bid opportunities',
        'Notifications customized to your business',
      ],
      action: 'Subscribe & Save',
      price: productPrices ? (productPrices.countyPrice / 12).toFixed(2) : '',
      message: 'per month, billed annually',
      handleClick: subscriptionPage,
    },
  ];

  return (
    <div className='row card-row'>
      {cards.map((card, idx) => (
        <PurchasePlanCard key={card.name} card={card} isFeatured={idx + 1 === cards.length} />
      ))}
    </div>
  );
};
