import React, { memo } from 'react';
import { Formik } from 'formik';
import { Table } from 'reactstrap';
import { bidTypeFields } from '../../../utils/constants';
import { Buttons, DeprecatedInput } from '../../customcontrols';
import { setWizardStatus, toastFn } from '../../../utils/helpers';
import { RegistrationWizardButtons } from './RegistrationWizardButtons';
import CommonDataTable from './commondatatable';
import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import { WizardPage } from '../../../types/wizard';
import { useDSSelector } from '../../../store/reducers';
import { useDispatch } from 'react-redux';
import { setAgencyRegistrationDetails } from '../../../store/actions';

interface PropsTypes {
  addAgencyBidTypes?: any;
  agencyregistration?: agencyRegistrationParamType;
  handleSubmit?: any;
  setAgencyRegistrationDetails?: any;
  deleteBidTypes?: any;
  match?: any;
  currentwizard?: WizardPage;
}

function BidTypes(props: PropsTypes) {
  const dispatch = useDispatch();
  const { agencyregistration } = props;
  const { agBidTypes = [], bidType = '', description = '' } = agencyregistration || {};

  const agencyRegistrationWizard = useDSSelector(
    state => state.agencyregistration.agencyRegistrationWizard,
  );

  //const memberId = props.match.params.memberId

  const AddNewBidTypes = () => {
    const checkExists = agBidTypes.filter(item => item.bidType === bidType);
    if (checkExists.length === 0) {
      const agencyBidTypes = [...agBidTypes, { bidType: bidType.trim(), bidTypeDesc: description }];
      dispatch(
        setAgencyRegistrationDetails({ bidType: '', description: '', agBidTypes: agencyBidTypes }),
      );
    } else {
      toastFn('error', 'Already Exists', 'add agency bid types');
    }
  };

  const deleteAgencyBidTypes = (data: any) => {
    const filteredBidTypes = agBidTypes.filter(item => item.bidType !== data.bidType);
    dispatch(setAgencyRegistrationDetails({ agBidTypes: filteredBidTypes }));
  };

  const onSubmitFn = () => {
    if (agBidTypes.length > 0) {
      dispatch(
        setAgencyRegistrationDetails({
          agencyRegistrationWizard: setWizardStatus(agencyRegistrationWizard, 4),
          wizardcurrentpage: 4,
        }),
      );
    } else {
      toastFn('error', 'At least one bid type is required', 'create_agency_bid_types');
    }
  };

  return (
    <>
      <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-12'>
                  <h2 className='lineHR'>Bid Types</h2>
                  <Table className='tableHData'>
                    <tr>
                      <td>
                        <DeprecatedInput
                          label='Name'
                          type='text'
                          name='bidtypesType'
                          handleChange={(name: string, value: string) =>
                            dispatch(setAgencyRegistrationDetails({ bidType: value }))
                          }
                          value={bidType}
                          placeholder='Type'
                          maxLength={4}
                        />
                      </td>
                      <td>
                        <DeprecatedInput
                          label='Name'
                          type='text'
                          name='bidtypesDesc'
                          handleChange={(name: string, value: string) =>
                            dispatch(setAgencyRegistrationDetails({ description: value }))
                          }
                          value={description}
                          placeholder='Description'
                          maxLength={50}
                        />
                      </td>
                      <td width='30px'>
                        <Buttons
                          classNames='bttn-primary small mb-1'
                          text='Save'
                          title='Save'
                          type='button'
                          onClick={AddNewBidTypes}
                          disable={!bidType.trim()}
                        />
                      </td>
                      <td width='30px' />
                    </tr>
                  </Table>
                  {agBidTypes.length > 0 && (
                    <CommonDataTable
                      {...props}
                      actions={['delete']}
                      data={agBidTypes}
                      fields={bidTypeFields}
                      deletedata={(data: any) => deleteAgencyBidTypes(data)}
                    />
                  )}
                </div>
              </div>
              <RegistrationWizardButtons {...props} />
            </form>
          );
        }}
      </Formik>
    </>
  );
}
export default memo(BidTypes);
