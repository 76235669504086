import { fork, put, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import { toastFn } from '../../utils/helpers';

const errorHandleSagaToastID = '5e2ad766-9a29-4f92-999a-6e20672d9c19';
function* watchInitiatorSaga() {
  yield takeEvery(actionTypes.HANDLE_SAGA_EXCEPTION.TRIGGER, InitiateSaga);
}

function* InitiateSaga(action: { payload: { action: { TRIGGER: string }; payload: any } }) {
  const { payload = { action: { TRIGGER: '' }, payload: {} } } = action;
  try {
    yield put({ type: payload.action.TRIGGER, payload: payload.payload });
    yield put({ type: actionTypes.HANDLE_SAGA_EXCEPTION.SUCCESS });
  } catch (Error) {
    yield put({
      type: actionTypes.HANDLE_SAGA_EXCEPTION.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

export function* exceptionHandlerSaga() {
  yield fork(watchInitiatorSaga);
}
