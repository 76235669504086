import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { sortDirections } from '../../../../utils/constants';
import { loadingMsg } from '../../../../utils/texts';
import { displayDate, SortingFn } from '../../../../utils/helpers';
import { NoResult } from '../../../customcontrols';
import { documentListTypes } from '../../../../types/limited';

const tdWidth = {
  type: {
    width: '25%',
  },
  date: {
    width: '18%',
  },
  status: {
    width: '20%',
  },
  action: {
    width: '5%',
  },
};
interface propsTypes {
  limited: { documents_internalloader: boolean; bidsdocuments: any[]; signinmodal: boolean };
  setLimitedDetails?: any;
}

const LimitedBidsDocuments = (props: propsTypes) => {
  const { limited, setLimitedDetails } = props;
  const { documents_internalloader, bidsdocuments, signinmodal } = limited;
  const documentsList =
    bidsdocuments.length > 0
      ? SortingFn(bidsdocuments, sortDirections.DESC, 'modifiedDate')
      : bidsdocuments;

  // const bidId = parseInt(props.match.params.bidId) || ''

  return (
    <>
      <Table className='tableFixed tableHData' data-testid='limited.bids.documents'>
        {documentsList && documentsList.length > 0 ? (
          <tbody>
            <tr>
              <th>Filename</th>
              <th style={tdWidth.type}>Type</th>
              <th style={tdWidth.date}>Date Modified</th>
              <th style={tdWidth.status}>Status</th>
              <th style={tdWidth.action}></th>
            </tr>
            {documentsList.map((document: documentListTypes) => (
              <tr key={document.bidDocID}>
                <td className='text-truncate'>
                  <span
                    className='staticLink underLine'
                    onClick={event => {
                      if (!signinmodal)
                        setLimitedDetails({ signinmodal: true, orderBidPackage: true });
                      sessionStorage.setItem('selectedBidDocId', document.bidDocID);
                      // sessionStorage.setItem(limitedloginPreviousUrlName, `/bids/${bidId}/details`)
                      // window.location.href = '/'
                    }}
                    title={document.fileName || document.FileName}
                  >
                    {document.fileName || document.FileName}
                  </span>
                </td>
                <td>{document.type || document.Type}</td>
                <td>{displayDate(document.modifiedDate || document.ModifiedDate)}</td>
                <td>{document.status || document.Status}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <NoResult message={documents_internalloader ? loadingMsg : 'No Documents Available'} />
        )}
      </Table>
    </>
  );
};
export default memo(LimitedBidsDocuments);
