import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { HTMLProps } from 'react';
import styled from 'styled-components';

export const AntDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

interface BaseTextInputProps extends HTMLProps<HTMLInputElement> {
  error?: boolean;
  inputHeight?: string;
}

/**
 * This is the base Input control, intended to be themed and extended
 * by specific implementations, e.g. TextInput, PasswordInput.
 */
export const BaseDatePicker = styled(AntDatePicker)<BaseTextInputProps>``;
