// TOREFACTOR

import * as actionCreators from '../../../store/actions';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { RegistrationData, supplierRegistrationTypes } from '../../../types/supplierregistration';
import {
  addInCompleteRegistrationData,
  getStateList,
  getSubscriptionMemberDetails,
  loadPreInCompleteRegistration,
  setRegistrationData,
} from '../../../store/actions';
import { promptSkipPages, registrationComponent } from '../../../utils/constants';
import AccountConfirmation from './accountconfirmation';
import ChooseSubscription from '../../subscription/choosesubscription';
import CompanyAddressInfo from './companyaddressinfo';
import CompanyMainContact from './companymaincontact';
import CompleteProfile from '../../products/CompleteProfile';
import CreateAccount from './createaccount';
import EmailAccountDetect from './emailaccouuntdetect';
import LimitedFooter from '../footer';
import LimitedHeader from '../header';
import OrderConfirmation from '../../subscription/orderconfirmation';
import PrintReceipt from '../../subscription/printreceipt';
import { Prompt } from 'react-router-dom';
import SelectProducts from '../../products';
import ReviewOrder from '../../products/revieworder';
import SelectFreeAgency from './selectfreeagency';
import { bindActionCreators } from 'redux';
import { initialRegistrationData } from '../../../store/reducers/registration';
import { limitedbiddetailsTypes } from '../../../types/limited';
import { trackRegistrationPage } from '../../../utils/telemetry';
import { DSState } from '../../../store/reducers';
import { SubscriptionDetails } from '../../../types/subscriptions';

interface RegistrationProps extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  registration: supplierRegistrationTypes;
  match: any;
  location: Location;
  subscriptions: SubscriptionDetails;
  getAllProducts: any;
}
const Registration = (props: RegistrationProps) => {
  const dispatch = useDispatch();

  const { registration, getDynamicProductPrices, getAllProducts, subscriptions } = props;
  const { currentComponent, registrationData = {} } = registration;
  const { products = [] } = subscriptions;
  const memberId = props.match.params.memberId;

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  /* TODO: Are we actually using nextLocation anywhere?
  We set it, but are we actually doing anything with it? */
  const [nextLocation, setNextLocation] = useState('' as any);

  useEffect(() => {
    dispatch(getStateList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDynamicProductPrices());
  }, [dispatch, getDynamicProductPrices]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const email = query.get('email');
    if (email) {
      dispatch(loadPreInCompleteRegistration({ email }));
    }
  }, [dispatch, props.location.search]);

  useEffect(() => {
    if (memberId) {
      dispatch(getSubscriptionMemberDetails({ memberId, pagefor: 'registration' }));
    }
  }, [dispatch, memberId]);

  useEffect(() => {
    return () => {
      dispatch(
        setRegistrationData({
          memberId: '',
          currentComponent: registrationComponent.CreateAccount,
          registrationData: initialRegistrationData,
          isRegistrationCompleted: false,
        }),
      );
    };
  }, [dispatch]);

  const handleBlockedNavigation = (nextLocation: any) => {
    if (confirmModalVisible || promptSkipPages.includes(currentComponent)) {
      return true;
    } else {
      setConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  useEffect(() => {
    if (products.length === 0) {
      getAllProducts();
    }
  }, [getAllProducts, products.length]);

  return (
    <>
      <Prompt when={true} message={handleBlockedNavigation} />
      <LimitedHeader {...props} pagefor='accountrecovery' />

      {currentComponent === registrationComponent.CreateAccount ? (
        <CreateAccount {...props} />
      ) : currentComponent === registrationComponent.EmailAccount ? (
        <EmailAccountDetect {...props} />
      ) : currentComponent === registrationComponent.ChooseAgency ? (
        <SelectFreeAgency
          addInCompleteRegistrationData={props.addInCompleteRegistrationData}
          getSubscriptionState={props.getSubscriptionState}
          setRegistrationData={props.setRegistrationData}
          registration={props.registration}
          setAccountInfoDetails={props.setAccountInfoDetails}
          UpdateSupplierFreeAgencies={props.UpdateSupplierFreeAgencies}
        />
      ) : currentComponent === registrationComponent.ChooseSubscription ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              {/* <ChooseSubscription {...props} pagefor='registration' /> */}
              <SelectProducts
                isRegistration={true}
                setRegistrationData={props.setRegistrationData}
              />
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.Company ||
        currentComponent === registrationComponent.AboutCompany ? (
        <CompanyAddressInfo />
      ) : currentComponent === registrationComponent.MainContactInfo ? (
        <CompanyMainContact {...props} />
      ) : currentComponent === registrationComponent.ReviewOrder ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              <ReviewOrder setRegistrationData={props.setRegistrationData} />
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.CompleteProfile ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              <CompleteProfile setRegistrationData={props.setRegistrationData} />
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.PrintReceipt ? (
        <div className='unAuthWrapper'>
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center'>
              <PrintReceipt {...props} pagefor='registration' />
            </div>
          </div>
        </div>
      ) : currentComponent === registrationComponent.AccountConfirmation ? (
        <AccountConfirmation />
      ) : null}
      <LimitedFooter pagefor='registration' currentComponent={currentComponent} />
    </>
  );
};

const connector = connect(
  (state: DSState) => ({
    limited: state.limited,
    registration: state.registration,
    shared: state.shared,
    accountinfo: state.accountinfo,
    subscriptions: state.subscriptions,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Registration));
