import React from 'react';

import { SortControlGroupWrapper, SortControlLabel, SortControlsWrapper } from './styles';
import { SelectOption, SortProps, useSort } from '../../../shared/hooks/useSort';

import { ControlLabels } from '../../../shared/constants';
import { Selectable } from '../../../types/common';
import { SelectBox } from '../../customcontrols';
import { SortButton } from '../controls/buttons/SortButton';
import { sortDirections } from '../../../utils/constants';

export const sortDirectionToggleTestId = 'sortDirectionToggleTestId';
export const sortKeySelectTestId = 'sortKeySelectTestId';

/**
 * @description Sorts the supplied data with types provided
 * according to the given `sortOptions`.
 * `T` - generic data type
 * @param props: SortProps<T>
 */
export function SortControlGroup<T>(props: SortProps<T>) {
  const { sortOptions } = props;

  // Pass props to the custom sort hook to get sort functionality.
  const { handleKeyChange, handleDirectionToggle, sortDirection, sortKey } = useSort(props);

  function handleSelectChange(key: keyof T, label: string) {
    handleKeyChange(key);
  }

  /**
   * @description Format options as `react-select` change handler expects.
   * @param sortOptions
   * @param selectedKey
   * @returns Array of formatted option objects to match
   *          the current implementation of `react-select`.
   */
  function getSortByOptions(sortOptions: SelectOption<T>[], selectedKey: keyof T) {
    const selectControlOptions = sortOptions.map(({ label, value }, index) => {
      return {
        id: index,
        title: label,
        label: label,
        value: label,
        selected: value === selectedKey ? true : false,
        key: value,
      };
    });
    return selectControlOptions;
  }

  function renderSortDirectionIcon() {
    const sortButtonDirection =
      sortDirection === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC;
    const buttonTitle =
      sortDirection === sortDirections.ASC
        ? `${ControlLabels.sortBy} ${sortKey} ${ControlLabels.descendingSuffix}`
        : `${ControlLabels.sortBy} ${sortKey} ${ControlLabels.ascendingSuffix}`;
    return (
      <SortButton
        data-testid={sortDirectionToggleTestId}
        direction={sortButtonDirection}
        onClick={handleDirectionToggle}
        title={buttonTitle}
      />
    );
  }

  /**
   * @description Implement `react-select` Control
   * @returns select control or "not found" text.
   * TODO: convert styles to use `styled-components`
   *       or update select control.
   */
  function renderSortSelect() {
    const selectControls = sortOptions?.length ? (
      <SortControlGroupWrapper>
        <div className='headerFilter clearfix d-flex align-items-center justify-content-end'>
          <div className={'sortWrapper'}>
            <SelectBox
              dataTestId={sortKeySelectTestId}
              label={ControlLabels.sortBy}
              onChange={handleSelectChange}
              options={getSortByOptions(sortOptions, sortKey)}
            />
            {renderSortDirectionIcon()}
          </div>
        </div>
      </SortControlGroupWrapper>
    ) : (
      <span>(No sort options were found)</span>
    );

    return <SortControlsWrapper>{selectControls}</SortControlsWrapper>;
  }

  return (
    <>
      <SortControlGroupWrapper>
        <SortControlLabel>{ControlLabels.sortBy}</SortControlLabel>
        {renderSortSelect()}
      </SortControlGroupWrapper>
    </>
  );
}
