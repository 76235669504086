import { FieldError } from 'react-hook-form';

// Required
export const requiredErrorMsg = 'This field is required';

// Email
export const emailVal = /^([A-Za-z0-9_\-.])+@[A-Za-z0-9_\-.]+\.([A-Za-z]{1,})$/;
export const emailValErrorMsg = 'Invalid email address';
export const emailMaxLength = 100;

export function isValidEmail(value?: string) {
  return !!(value && emailVal.test(value));
}

// General Helper Functions
/**
 * Returns a default error message for basic error types like 'required'.
 * Can be modified to return additional defaults for 'maxLength', etc.
 * @param error
 * @returns string
 */
export function getErrorMessage(error?: FieldError) {
  if (!error) {
    return '';
  }
  if (error.message) {
    return error.message;
  }
  switch (error.type) {
    case 'required':
      return requiredErrorMsg;
    default:
      return 'This field is invalid';
  }
}
