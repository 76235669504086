import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { RecoilRoot } from 'recoil';

import ErrorBoundary from './components/common/splash/errorboundary';
import history from './utils/history';
import Root from './containers/Root';
import { Router } from 'react-router-dom';

function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time = console.timeEnd = console.assert = console.profile = function () {};
  }

  return (
    <ErrorBoundary>
      <RecoilRoot>
        <Router history={history}>
          <Root />
        </Router>
      </RecoilRoot>
    </ErrorBoundary>
  );
}

export default App;
