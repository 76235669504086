/**
 * Navigation path constants, by feature
 */
export const supplierPaths = {
  base: '/suppliers',
  bids: '/suppliers/bids',
  quotes: '/suppliers/quotes',
  responses: '/suppliers/responses',
};

export const buyerPaths = {
  base: '/buyers',
  bids: '/buyers/bids',
  quotes: '/buyers/quotes',
};

export enum SubPaths {
  activities = 'activities',
  details = 'details',
  management = 'management',
  bidResponses = 'bid-responses',
  myResponses = 'my-responses',
  notifications = 'notifications',
  planHolders = 'planholders',
  requiredActions = 'required-actions',
  responses = 'responses',
  similarbids = 'similarbids',
}

export const navigationPaths = {
  suppliers: supplierPaths,
  buyers: buyerPaths,
  subPaths: SubPaths,
};
