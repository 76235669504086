import React, { memo } from 'react';

function TabulationForm() {
  return (
    <>
      <p>
        To publish a Tabulation Form, you click on the Tabulation Sheet tab to see the table of
        responses, including the total amount that they may have indicated when they submitted their
        proposal. Then you may click on “Export PDF” to get that document automatically loaded into
        your list of documents for that solicitation.{' '}
      </p>
      img
      <p>
        Alternatively, you may manually create a Tabulation Sheet in another format, and upload that
        instead.{' '}
      </p>
      img
    </>
  );
}

export default memo(TabulationForm);
