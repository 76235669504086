import { fork } from 'redux-saga/effects';
import { addBidSaga } from './addbid';
import { addQuotesSaga } from './addquotes';
import { bidListSaga } from './bids';
import { quoteListSaga } from './quotes';
import { authSaga } from './auth';
import { bidsSummarySaga } from './bidssummary';
import { sharedSaga } from './shared';
import { accountInfoSaga } from './accountinfo';
import { commodityCodesSaga } from './commoditycodes';
import { memberInfo } from './memberinfo';
import { paymentSaga } from './payment';
import { eBitsSaga } from './ebids';
import { quoteSummarySaga } from './quoteSummary';
import { programsSaga } from './programs';
import { agencyRegistrationSaga } from './agencyregistration';
import { activitiesSaga } from './activities';
import { limitedSaga } from './limited';
import { broadcastListsSaga } from './broadcastlists';
import { ebidCampaignSaga } from './ebidcampaign';
import { toursSaga } from './tours';
import { subscriptionsSaga } from './subscriptions';
import { registrationSaga } from './registration';
import { exceptionHandlerSaga } from './exceptionhandler';

export default function* rootSaga() {
  yield fork(quoteListSaga);
  yield fork(quoteSummarySaga);
  yield fork(accountInfoSaga);
  yield fork(activitiesSaga);
  yield fork(addBidSaga);
  yield fork(addQuotesSaga);
  yield fork(agencyRegistrationSaga);
  yield fork(authSaga);
  yield fork(bidListSaga);
  yield fork(bidsSummarySaga);
  yield fork(broadcastListsSaga);
  yield fork(commodityCodesSaga);
  yield fork(eBitsSaga);
  yield fork(ebidCampaignSaga);
  yield fork(limitedSaga);
  yield fork(memberInfo);
  yield fork(paymentSaga);
  yield fork(programsSaga);
  yield fork(sharedSaga);
  yield fork(toursSaga);
  yield fork(subscriptionsSaga);
  yield fork(registrationSaga);
  yield fork(exceptionHandlerSaga);
}
