import styled from 'styled-components';

import { Colors } from '../../../shared/styles';

export const PaginationContainer = styled.div`
  text-align: center;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    color: ${Colors.action};
    display: inline-block;
    text-align: center;
    width: 2rem;
  }

  li.selected {
    color: ${Colors.action};
    a {
      font-weight: bold;
    }
  }

  li.previous,
  li.next {
    a {
      font-weight: bold;
    }
  }

  li.previous.disabled,
  li.next.disabled {
    a {
      font-weight: normal;
    }
  }

  span.pagination-label {
    display: block;
    padding-bottom: 1rem;
  }
`;
