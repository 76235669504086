import React, { memo } from 'react';

function SetUpCertification() {
  return (
    <>
      <p>
        DemandStar provides a way for you to easily select suppliers based on their ownership
        status, as well as to manage their certifications. To set-up certifications, pull down the
        account management menu to “Certifications”.
      </p>
      img
      <p>
        The first tab, “Manage Certifications” is for internal reporting purposes. It allows you to
        report on what companies you have certified and how you’ve grouped/described those
        certifications.{' '}
      </p>
      <p>
        The second tab, “Manage Suppliers”, is where you view those who have submitted their company
        for certification, what their status is based on your receipt of certifications documents,
        and, for you to search for companies across DemandStar that meet your certification
        requirements.{' '}
      </p>
    </>
  );
}

export default memo(SetUpCertification);
