import React from 'react';

function LogoDS(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 278.578 48'>
      <g id='DemandStar_-_Logo' data-name='DemandStar - Logo' transform='translate(139.289 24)'>
        <g id='Group_29' data-name='Group 29' transform='translate(-139.289 -24)'>
          <g id='Group_26' data-name='Group 26' transform='translate(55.898 11.21)'>
            <path
              id='Path_99'
              data-name='Path 99'
              className='img-fluid'
              d='M109.2,47.36c-.308,0-.484-.174-.484-.52V23.13c0-.346.174-.519.484-.519h8.587c5.746,0,9.343,3.078,9.343,9.136v6.581c0,5.987-3.6,9.034-9.343,9.034Zm3.7-3.913h4.881c3.324,0,5.02-1.626,5.02-5.09V31.712c0-3.563-1.7-5.258-5.02-5.258H112.9Z'
              transform='translate(-108.714 -22.195)'
            />
            <path
              id='Path_100'
              data-name='Path 100'
              className='img-fluid'
              d='M158.213,43.545h11.106c.351,0,.522.176.522.486v2.8a.46.46,0,0,1-.522.52H154.508c-.313,0-.485-.174-.485-.52V23.125c0-.346.172-.519.485-.519h14.328a.46.46,0,0,1,.52.519v2.8c0,.309-.174.484-.52.484H158.213v6.472h9.725a.463.463,0,0,1,.52.521v2.77a.46.46,0,0,1-.52.519h-9.725Z'
              transform='translate(-130.726 -22.19)'
            />
            <path
              id='Path_101'
              data-name='Path 101'
              className='img-fluid'
              d='M193.954,47.356c-.308,0-.485-.174-.485-.52V23.126c0-.346.174-.519.485-.519h3.774a.545.545,0,0,1,.553.381l4.222,7.857c.935,1.73,2.391,4.847,2.944,6.091h.139c.553-1.244,2.008-4.361,2.941-6.091l4.187-7.857a.622.622,0,0,1,.59-.381h3.739a.458.458,0,0,1,.518.519v23.71a.459.459,0,0,1-.518.52h-3.151c-.308,0-.483-.174-.483-.52V39.877c0-3.253.138-7.477.275-10.868l-.1-.038c-.831,1.8-1.973,4.119-3.115,6.4L207.419,41.3a.605.605,0,0,1-.588.377H204.2a.6.6,0,0,1-.59-.377l-3.05-5.92c-1.14-2.285-2.282-4.6-3.112-6.4l-.1.038c.138,3.39.277,7.615.277,10.868v6.957a.46.46,0,0,1-.52.52Z'
              transform='translate(-149.89 -22.19)'
            />
            <path
              id='Path_102'
              data-name='Path 102'
              className='img-fluid'
              d='M246.7,47.36c-.377,0-.52-.209-.415-.59l8.307-23.744a.551.551,0,0,1,.552-.415h3.948a.545.545,0,0,1,.553.415l8.307,23.745c.106.38-.067.59-.411.59h-3.391a.565.565,0,0,1-.589-.417l-1.7-5.226h-9.487l-1.7,5.226a.592.592,0,0,1-.588.417Zm6.856-9.417h7.13l-3.5-10.767h-.138Z'
              transform='translate(-175.531 -22.194)'
            />
            <path
              id='Path_103'
              data-name='Path 103'
              className='img-fluid'
              d='M294.932,47.356c-.309,0-.482-.174-.482-.52V23.125c0-.346.172-.519.482-.519h3.5a.72.72,0,0,1,.622.346L305.8,34.719c1.183,2.079,2.5,4.4,3.466,6.266l.1-.032c-.209-2.945-.308-6.473-.308-9.383V23.125a.457.457,0,0,1,.518-.519h3.117a.459.459,0,0,1,.519.519v23.71a.46.46,0,0,1-.519.52h-3.5a.643.643,0,0,1-.59-.348l-6.542-10.833c-1.281-2.078-2.735-4.777-3.7-6.818l-.1.031c.169,2.977.309,6.581.309,9.521v7.926c0,.346-.173.52-.484.52Z'
              transform='translate(-198.949 -22.19)'
            />
            <path
              id='Path_104'
              data-name='Path 104'
              className='img-fluid'
              d='M342.464,47.36c-.313,0-.486-.174-.486-.52V23.13c0-.346.173-.519.486-.519h8.582c5.747,0,9.346,3.078,9.346,9.136v6.581c0,5.987-3.6,9.034-9.346,9.034Zm3.7-3.913h4.88c3.322,0,5.021-1.626,5.021-5.09V31.712c0-3.563-1.7-5.258-5.021-5.258h-4.88Z'
              transform='translate(-222.039 -22.195)'
            />
            <path
              id='Path_105'
              data-name='Path 105'
              className='img-fluid'
              d='M393.753,47.381a13.315,13.315,0,0,1-8.828-3.324.507.507,0,0,1-.1-.726l1.73-2.424a.463.463,0,0,1,.693-.105,11.818,11.818,0,0,0,6.68,2.633c2.526,0,5.019-1.074,5.019-3.427,0-2.494-2.594-3.115-5.608-3.739-3.634-.76-8.413-2.423-8.413-7.579,0-4.815,4.261-6.89,9.243-6.89a13.542,13.542,0,0,1,8.135,2.733.451.451,0,0,1,.139.692l-1.662,2.391c-.171.276-.447.276-.726.1a11.95,11.95,0,0,0-6.195-2.005c-2.977,0-4.709,1.178-4.709,3.048,0,2.217,2.217,2.8,5.262,3.5,4.05.9,8.827,2.216,8.827,7.683C403.239,45.131,398.807,47.381,393.753,47.381Z'
              transform='translate(-242.802 -21.801)'
            />
            <path
              id='Path_106'
              data-name='Path 106'
              className='img-fluid'
              d='M442.323,22.608a.472.472,0,0,1,.514.519v2.838a.463.463,0,0,1-.514.486h-6.96V46.837c0,.346-.171.52-.484.52H431.7c-.312,0-.484-.174-.484-.52V26.451h-6.993a.467.467,0,0,1-.52-.486V23.127a.474.474,0,0,1,.52-.519Z'
              transform='translate(-261.74 -22.192)'
            />
            <path
              id='Path_107'
              data-name='Path 107'
              className='img-fluid'
              d='M458.62,47.36c-.381,0-.52-.209-.414-.59l8.31-23.745a.547.547,0,0,1,.553-.415h3.947a.548.548,0,0,1,.553.415l8.305,23.746c.1.381-.07.59-.415.59h-3.391a.565.565,0,0,1-.59-.417l-1.7-5.226H464.3l-1.7,5.226a.594.594,0,0,1-.589.417Zm6.856-9.417H472.6L469.11,27.176h-.139Z'
              transform='translate(-278.488 -22.194)'
            />
            <path
              id='Path_108'
              data-name='Path 108'
              className='img-fluid'
              d='M520.256,47.36a.642.642,0,0,1-.589-.348l-6.059-8.687H510.53V46.84c0,.346-.173.52-.484.52h-3.185c-.314,0-.483-.174-.483-.52V23.13c0-.346.169-.519.483-.519h8.029c5.676,0,9,2.94,9,7.892a7.179,7.179,0,0,1-5.746,7.371l6.333,8.862c.244.38.071.624-.309.624ZM510.53,26.454v8.132h4.359c3.22,0,4.743-1.28,4.743-4.049,0-2.8-1.523-4.082-4.743-4.082Z'
              transform='translate(-301.908 -22.195)'
            />
          </g>
          <g id='Group_27' data-name='Group 27' transform='translate(0 11.353)'>
            <path
              id='Path_109'
              data-name='Path 109'
              className='img-fluid'
              d='M30.361,29.029a2.61,2.61,0,0,1-2.336,1.622,2.29,2.29,0,0,1-1.645-.72c-1.229-1.279-5.446-6.432-4.354-7.61a.838.838,0,0,1,.609-.24c1.617,0,5.879,3.089,7.19,4.4A2.256,2.256,0,0,1,30.361,29.029Z'
              transform='translate(-10.614 -22.08)'
            />
            <path
              id='Path_110'
              data-name='Path 110'
              className='img-fluid'
              d='M17.307,44.357A2.334,2.334,0,0,1,14.934,46.8c-.5,0-4.151-.134-7.864-.509C.732,45.647,0,44.879,0,44.357s.732-1.285,7.069-1.923c3.7-.38,7.359-.514,7.864-.514a2.33,2.33,0,0,1,2.373,2.437Z'
              transform='translate(0 -31.719)'
            />
          </g>
          <g id='Group_28' data-name='Group 28' transform='translate(11.362)'>
            <path
              id='Path_111'
              data-name='Path 111'
              className='img-fluid'
              d='M63.163,22.722c0,1.617-3.093,5.877-4.4,7.182a2.3,2.3,0,0,1-1.641.724,2.373,2.373,0,0,1-.9-.189,2.608,2.608,0,0,1-1.62-2.336,2.265,2.265,0,0,1,.72-1.639c.617-.622,5.269-4.538,7.065-4.543a.771.771,0,0,1,.547.19.823.823,0,0,1,.238.612Z'
              transform='translate(-37.885 -10.649)'
            />
            <path
              id='Path_112'
              data-name='Path 112'
              className='img-fluid'
              d='M30.665,57.084a2.288,2.288,0,0,1-.72,1.645c-.618.626-5.276,4.538-7.071,4.538a.735.735,0,0,1-.537-.187.811.811,0,0,1-.239-.606c0-1.626,3.09-5.881,4.4-7.187a2.279,2.279,0,0,1,1.645-.723,2.431,2.431,0,0,1,.9.184A2.606,2.606,0,0,1,30.665,57.084Z'
              transform='translate(-22.097 -26.509)'
            />
            <path
              id='Path_113'
              data-name='Path 113'
              className='img-fluid'
              d='M46.812,62.063c0,.5-.133,4.16-.511,7.867C45.661,76.264,44.892,77,44.374,77s-1.285-.737-1.931-7.071c-.375-3.67-.505-7.353-.505-7.867a2.439,2.439,0,0,1,4.875,0Z'
              transform='translate(-31.736 -29.001)'
            />
            <path
              id='Path_114'
              data-name='Path 114'
              className='img-fluid'
              d='M63.028,62.913a.832.832,0,0,1-.611.237c-1.623,0-5.879-3.091-7.188-4.4a2.239,2.239,0,0,1-.533-2.541,2.605,2.605,0,0,1,2.335-1.624,2.3,2.3,0,0,1,1.645.714C59.947,56.636,64.109,61.745,63.028,62.913Z'
              transform='translate(-37.843 -26.517)'
            />
            <path
              id='Path_115'
              data-name='Path 115'
              className='img-fluid'
              d='M76.989,44.386c0,.512-.738,1.272-7.074,1.909-3.693.376-7.353.508-7.858.508a2.441,2.441,0,0,1,0-4.881c2.731,0,13.471.722,14.722,1.984a.613.613,0,0,1,.21.481Z'
              transform='translate(-40.354 -20.366)'
            />
            <path
              id='Path_116'
              data-name='Path 116'
              className='img-fluid'
              d='M46.812,14.934a2.439,2.439,0,0,1-4.875,0c0-.514.131-4.2.505-7.867C43.087.73,43.853,0,44.374,0S45.659.73,46.3,7.067C46.68,10.768,46.812,14.427,46.812,14.934Z'
              transform='translate(-31.736 0)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogoDS;
