import { memo, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  loginPreviousUrlName,
  loginPreviousUrlSearch,
  memberTypes,
  Pages,
} from '../../utils/constants';
import Configurations from '../../settings';
import { authTypes } from '../../types/auth';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/actions';

interface PropsTypes extends PropsFromRedux {
  auth: authTypes;
  history: any;
}

const Pagenotfound = (props: PropsTypes) => {
  const { auth, history } = props;
  const { tk = '', mt = '', mi = '' } = auth;

  useEffect(() => {
    if (auth && tk && mt && mi) {
      const path = mt === memberTypes.agencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;
      history.replace(path);
    } else if (!auth || !tk || !mt || !mi) {
      sessionStorage.setItem(loginPreviousUrlName, props.history.location.pathname);
      sessionStorage.setItem(loginPreviousUrlSearch, props.history.location.search);
      const regEx = /^\/(buyers|suppliers)\/(bids)\/[0-9]+/;
      if (regEx.test(props.history.location.pathname)) {
        const limitedBidId = props.history.location.pathname.split('/')[3];
        window.location.href = `/app/limited/bids/${limitedBidId}/details`;
      } else {
        window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
      }
    }
  });

  return null;
};

const connector = connect(
  (state: any) => ({ auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(Pagenotfound));
