import React, { memo, useCallback, useState } from 'react';
import { Field, Formik } from 'formik';
import renderCheckboxField from '../../common/controls/inputs/checkbox';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import { DeprecatedFormikRadio } from '../../common/controls/inputs/DeprecatedFormikRadio';
import renderSelect from '../../common/controls/inputs/selectbox';
// Components
import { Buttons } from '../../customcontrols';
import DropIn from 'braintree-web-drop-in-react';
import Configurations from '../../../settings';
import { listParamType, paymentFormValueFieldType } from '../../../types/payments';

// Validation Method provided to redux form
interface PropsTypes {
  handleSubmit?: any;
  onSubmit?: any;
  paymentvalues?: paymentFormValueFieldType;
  GoToPrevious?: any;
  cardinfo?: { cardNumberMask: string };
  countrieslist?: listParamType[];
  stateslist?: listParamType[];
  countylist?: listParamType[];
  getCountyList?: any;
  setPaymentFormDetails?: any;
  initialValues: any;
}

const PaymentForm = (props: PropsTypes) => {
  const clientToken = Configurations.REACT_APP_BTKey;
  const [instance, setInstance] = useState('' as any);
  const {
    handleSubmit,
    onSubmit,
    paymentvalues,
    GoToPrevious,
    cardinfo,
    countrieslist = [],
    stateslist = [],
    countylist = [],
    getCountyList,
    setPaymentFormDetails,
    initialValues,
  } = props;

  // Redux form fn and check validation and go to next page fn

  const onSubmitFn = async (data: any) => {
    const token = await instance.requestPaymentMethod();
    if (token && token.nonce) {
      await handleSubmit();
      setPaymentFormDetails({ card_token: token });
      setPaymentFormDetails({ paymentvalidation: true });
      onSubmit({ data });
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitFn}>
      {formikProps => {
        const { handleSubmit } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name='card'
              title={`Use card on file: V1(${(cardinfo && cardinfo.cardNumberMask) || '*******'})`}
              component={DeprecatedFormikRadio}
              type='radio'
              value='existingcard'
              disabled={cardinfo && !cardinfo.cardNumberMask}
            />
            <Field
              name='card'
              title='Use New Card'
              component={DeprecatedFormikRadio}
              type='radio'
              value='newcard'
            />
            {paymentvalues && paymentvalues.card === 'newcard' ? (
              <>
                {clientToken ? (
                  <div className='mt-2'>
                    <DropIn
                      options={{ authorization: clientToken }}
                      onInstance={instance => setInstance(instance)}
                    />
                  </div>
                ) : (
                  <div>
                    <h1>Loading...</h1>
                  </div>
                )}
                <Field
                  label='Cardholder Name'
                  type='text'
                  classNames='class'
                  placeholder='Cardholder Name'
                  name='cardholdername'
                  component={renderDeprecatedTextField}
                />
                <Field
                  label='Billing address 1'
                  type='text'
                  classNames='class'
                  placeholder='Address'
                  name='address1'
                  component={renderDeprecatedTextField}
                  maxLength={50}
                />
                <Field
                  label='Billing address 2'
                  type='text'
                  classNames='class'
                  placeholder='Address'
                  name='address2'
                  component={renderDeprecatedTextField}
                  maxLength={50}
                />
                <Field
                  label='Billing Zip/Postal Code'
                  type='text'
                  classNames='class'
                  placeholder='Zip'
                  parentClass='mb-3'
                  name='postalCode'
                  component={renderDeprecatedTextField}
                  maxLength={30}
                />
                <Field
                  label='Billing City'
                  type='text'
                  classNames='class'
                  placeholder='City'
                  name='city'
                  component={renderDeprecatedTextField}
                  maxLength={50}
                />
                <Field
                  name='country'
                  label='Billing Country'
                  type='select'
                  component={renderSelect}
                  parentClass=''
                  options={countrieslist}
                />
                <Field
                  name='state'
                  label='Billing State/Province'
                  type='select'
                  component={renderSelect}
                  parentClass=''
                  onChange={(e: any) => {
                    e.value && getCountyList(e.value);
                  }}
                  options={stateslist}
                />
                <Field
                  name='county'
                  label='Billing County'
                  type='select'
                  component={renderSelect}
                  parentClass=''
                  options={countylist}
                  clear={true}
                />

                <Field
                  label='Replace card on file with this new card'
                  title='Replace card on file with this new card'
                  type='checkbox'
                  classNames='class'
                  placeholder='Replace card on file with this new card'
                  parentClass='mb-3'
                  name='canReplace'
                  component={renderCheckboxField}
                  disabled={cardinfo && !cardinfo.cardNumberMask}
                />
              </>
            ) : null}
            <br />
            <div className='mt-4 clearfix'>
              <Buttons
                classNames='bttn bttn-secondary float-right mb-3 ml-2'
                text='Cancel'
                onClick={GoToPrevious}
              />
              <Buttons classNames='bttn bttn-primary float-right mb-3' type='submit' text='Next' />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default memo(PaymentForm);
