import * as React from 'react';

import { AmplitudeEventProps, useAmplitudeRelay } from '../amplitude';
import { useDSSelector } from '../../store/reducers';

const useSubscriptionEvents = () => {
  const { relayEvent } = useAmplitudeRelay();
  const subscriptions = useDSSelector(state => state.subscriptions);

  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const events = [
    {
      /**
       * @name Registration (Supplier) - Select Product
       * @property MemberType - Redux Prop
       * @property ProductID - Component Prop
       */
      eventName: 'registration (supplier) - select product',
      eventProps: { MemberType: subscriptions.memberType },
    },
  ];

  return React.useMemo(() => {
    return {
      logEvent: (event: AmplitudeEventProps) => relayEvent(events, event),
    };
  }, [events, relayEvent]);
};

export default useSubscriptionEvents;
