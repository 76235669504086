import React from 'react';
import { Table } from 'reactstrap';
import { Buttons, DeprecatedInput } from '../../../customcontrols';
const tdWidth = {
  bType: {
    width: '250px',
  },
  action: {
    width: '30px',
  },
};
function AddEditBidType() {
  return (
    <>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-8'>
            <div className='colWrapper'>
              <h1 className='arrowWrapper'>Add Bid Types</h1>
              {/* Add Bid */}
              <Table className='tableHData'>
                <tr>
                  <th style={tdWidth.bType}>Bid Type</th>
                  <th>Bid Description</th>
                  <th style={tdWidth.action}></th>
                </tr>
                <tr>
                  <td>
                    <DeprecatedInput label='Bid Type' type='text' placeholder='Bid Type' />
                  </td>
                  <td>
                    <DeprecatedInput
                      label='Bid Description'
                      type='text'
                      placeholder='Bid Description'
                    />
                  </td>
                  <td>
                    <i
                      className='mdi mdi-delete-forever-outline staticLink'
                      title='Delete Bid Type'
                    ></i>
                  </td>
                </tr>
              </Table>
              <span className='staticLink' title='Add Bid Type'>
                <i className='mdi mdi-plus'></i> Add Another
              </span>

              {/* Edit Type */}
              {/* <DeprecatedInput label='Bid Type' type='text' placeHolder='Bid Type' value='Bid Type' />
							<DeprecatedInput label='Bid Description' type='text' placeHolder='Bid Description' value='Bid Description' /> */}

              <div className='d-block my-4'>
                <Buttons classNames='bttn-secondary  float-left' text='Cancel' type='button' />
                <Buttons classNames='bttn-primary float-right' text='Save Changes' type='button' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditBidType;
