import React, { memo, useCallback, useEffect, useState } from 'react';
import { deprecatedGetDate, scrollToTop } from '../../../../utils/helpers';
import { pageSize } from '../../../../utils/constants';
import { loadingMsg } from '../../../../utils/texts';
import NoResult from '../../../customcontrols/noresult';
import { Buttons, Paging } from '../../../customcontrols';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import GenerateNewList from './modals/generatenewlist';
import SaveList from './modals/savelist';
import { ConfirmModal } from '../../../common/modals/ConfirmModal';
import SupplierList from './supplierlist';
import {
  broadcastlistTypes,
  generatenewTypes,
  preBuiltBroadCastListTypes,
  sharedTypes,
} from '../../../../types/broadcastlist';
import { authTypes } from '../../../../types/auth';

interface propsTypes {
  shared: sharedTypes;
  pagetitle?: string;
  auth: authTypes;
  broadcastList: broadcastlistTypes;
  getBroadcastListGetLists?: any;
  getBroadcastListBroadcastMembers?: any;
  setBroadcastListDetails: any;
  submitBroadcastListAddList?: any;
  submitBroadcastListDeleteList?: any;
  resetCommodityCodes?: any;
  setBreadcrumb?: any;
  GoBackToPage: any;
  SetSelectedCommodity: any;
  getBroadcastListSearchVendors: any;
}

type combinedTypes = propsTypes & generatenewTypes;

function BroadcastSupplierList(props: combinedTypes) {
  const {
    getBroadcastListGetLists,
    getBroadcastListBroadcastMembers,
    shared,
    broadcastList,
    setBroadcastListDetails,
    submitBroadcastListAddList,
    submitBroadcastListDeleteList,
    auth,
    resetCommodityCodes,
    pagetitle = 'Broadcast List',
  } = props;
  const {
    preBuiltBroadCastList = [],
    supplierslist,
    newlistmodal,
    savelistmodal,
    newlistname,
    savelistExistmodal,
    newlistmodalfor,
    selectedbroadcastlist = '',
    confirmDelete,
  } = broadcastList;
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;
  const { internalLoader = false } = shared;
  const { setBreadcrumb } = props;
  const { mc, us } = auth;

  const [currentPagesSupplier, setCurrentPagesSupplier] = useState(1);
  const listPerPagesSupplier = pageSize.supplierslist;
  const indexOfLastListsSupplier = currentPagesSupplier * listPerPagesSupplier;
  const indexOfFirstListsSupplier = indexOfLastListsSupplier - listPerPagesSupplier;

  useEffect(() => {
    getBroadcastListGetLists({ type: 'List' });
  }, [getBroadcastListGetLists]);

  const approveAction = () => {
    submitBroadcastListDeleteList({ listId: selectedbroadcastlist?.listId });
  };

  const toggleListModal = useCallback(
    (modalfor = '') => {
      setBroadcastListDetails({ newlistmodalfor: modalfor, newlistmodal: !newlistmodal });
      setCurrentPagesSupplier(1);
      setCurrentPages(1);
    },
    [newlistmodal, setBroadcastListDetails],
  );

  useEffect(() => {
    const control = (
      <>
        <li>
          <span className='staticLink addBid' onClick={toggleListModal}>
            <i className='mdi mdi-plus mr-1' /> Add List
          </span>
        </li>
      </>
    );
    setBreadcrumb({
      component: control,
      page: 'Account Info',
      title: pagetitle,
      altname: pagetitle,
      name: pagetitle,
      menuactive: '',
    });
  }, [setBreadcrumb, toggleListModal, pagetitle]);

  const Setsavelistmodal = () => {
    setBroadcastListDetails({ newlistname: '', savelistmodal: !savelistmodal });
  };

  const SetSelectedListFn = (item: preBuiltBroadCastListTypes) => {
    if (item.listId !== selectedbroadcastlist?.listId) {
      scrollToTop();
      setBroadcastListDetails({ selectedbroadcastlist: item });
      getBroadcastListBroadcastMembers({ id: item.listId });
      setCurrentPagesSupplier(1);
    }
  };

  useEffect(() => {
    return () => {
      resetCommodityCodes();
    };
  }, [resetCommodityCodes]);

  function toggleConfirmDeleteModal() {
    setBroadcastListDetails({ confirmDelete: !confirmDelete });
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <h3 className='arrowWrapper'>{pagetitle}</h3>
          {preBuiltBroadCastList.length > 0 ? (
            <>
              {mc || us === selectedbroadcastlist?.accountId ? (
                <>
                  <Buttons
                    text='Delete List'
                    title='Delete List'
                    onClick={() => {
                      setCurrentPagesSupplier(1);
                      setBroadcastListDetails({ confirmDelete: true });
                    }}
                    classNames='bttn bttn-secondary small float-right'
                  />
                  <span> </span>
                  <Buttons
                    text='Edit List'
                    title='Edit List'
                    onClick={() => {
                      setCurrentPagesSupplier(1);
                      setCurrentPages(1);
                      toggleListModal('edit');
                    }}
                    classNames='bttn bttn-secondary small float-right mr-3'
                  />
                </>
              ) : null}
            </>
          ) : (
            <Buttons
              text='Add List'
              onClick={toggleListModal}
              classNames='bttn bttn-secondary small float-right mr-3'
            />
          )}
        </div>
        {preBuiltBroadCastList.length > 0 ? (
          <>
            <div className='col-lg-4'>
              <div className='ulListWrapper'>
                <ul className='list-unstyled clearfix'>
                  {preBuiltBroadCastList
                    .map((item: preBuiltBroadCastListTypes, index: number) => (
                      <li
                        key={index}
                        className={selectedbroadcastlist?.listId === item.listId ? 'active' : ''}
                        onClick={() => SetSelectedListFn(item)}
                      >
                        <h6 className='mw-100 text-truncate'>{item.name}</h6>
                        <span>
                          <i>Created by: </i>
                          {item.createdBy}
                        </span>
                        <span>
                          <i>Date: </i>
                          {deprecatedGetDate(item.lastUpdatedOn)}
                        </span>
                      </li>
                    ))
                    .slice(indexOfFirstLists, indexOfLastLists)}
                </ul>
                <Paging
                  totalRecords={preBuiltBroadCastList.length}
                  currentPage={currentPages}
                  onPagingClick={(value: number) => {
                    if (value !== currentPages) setCurrentPages(Number(value));
                  }}
                  limitmsg={false}
                  pageLimit={listPerPages}
                />
              </div>
            </div>
            <div className='col-lg-8'>
              <SupplierList
                {...props}
                internallisting={supplierslist}
                currentPagesSupplier={currentPagesSupplier}
                setCurrentPagesSupplier={setCurrentPagesSupplier}
                indexOfLastListsSupplier={indexOfLastListsSupplier}
                indexOfFirstListsSupplier={indexOfFirstListsSupplier}
                listPerPagesSupplier={listPerPagesSupplier}
              />
              <div className='d-block mt-3'>
                <Buttons
                  text='Add List'
                  onClick={toggleListModal}
                  classNames='bttn-primary float-right'
                />
              </div>
            </div>
          </>
        ) : (
          <div className='col-12'>
            <NoResult
              message={internalLoader ? loadingMsg : 'No Broadcast Supplier List Available'}
            />
          </div>
        )}
      </div>
      <ConfirmModal
        confirmMessage={'Are you sure want to delete this List?'}
        approveAction={approveAction}
        title='Confirm Delete'
        closeModal={toggleConfirmDeleteModal}
        isOpen={confirmDelete}
        size='md'
      />
      <ModalPopUp
        title='Save Broadcast List'
        closeModal={Setsavelistmodal}
        isOpen={savelistmodal}
        backdrop='static'
        size='md'
      >
        <SaveList
          setBroadcastListDetails={setBroadcastListDetails}
          newlistname={newlistname}
          Setsavelistmodal={Setsavelistmodal}
          submitBroadcastListAddList={submitBroadcastListAddList}
          savelistExistmodal={savelistExistmodal}
          pagefor='BroadcastList'
        />
      </ModalPopUp>
      <ModalPopUp
        size='xxl'
        title={
          newlistmodalfor === 'edit'
            ? 'Edit List - ' + selectedbroadcastlist?.name
            : 'Add List - Select Commodity Code'
        }
        closeModal={toggleListModal}
        isOpen={newlistmodal}
        backdrop='static'
        className='selectBroadcast'
        // what does this class name do? consider using a style component
        // and removing from here and from ModalPopUp.
      >
        <GenerateNewList
          {...props}
          Setnewlistmodal={toggleListModal}
          pagefor='addbroadcastlist'
          listpagefor='accountSaveBroadcastList'
        />
      </ModalPopUp>
    </>
  );
}

export default memo(BroadcastSupplierList);
