import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { setBreadcrumb, SetBreadcrumbPayload } from '../../store/actions';

export function useSetBreadcrumb(breadcrumbConfig: SetBreadcrumbPayload) {
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(setBreadcrumb(breadcrumbConfig));
  }, [breadcrumbConfig, dispatch]);
}
