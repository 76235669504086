import React, { memo, useEffect } from 'react';
import { supplierRegistrationTypes } from '../../../../types/supplierregistration';

// import CreateAccountDefault from './createaccountdefault';
// import CreateAccountPriceMonth from './createaccountpricemonth';
import CreateAccountPriceYear from './createaccountpriceyear';

// interface propsTypes {
//   registration: supplierRegistrationTypes;
// }

const CreateAccount = (props: any) => {
  // const { registration } = props;
  // const { version } = registration;

  // const getCurrentComponent = () => {
  //   if (version === 1) {
  //     return <CreateAccountDefault {...props} />;
  //   } else if (version === 3) {
  //     return <CreateAccountPriceMonth {...props} />;
  //   } else {
  //     return <CreateAccountPriceYear {...props} />;
  //   }
  // };
  return (
    <>
      {/* {getCurrentComponent()} */}
      <CreateAccountPriceYear {...props} />
    </>
  );
};

export default memo(CreateAccount);
