import { phoneVal } from '../../../utils/constants';
import { emailValidation } from '../../../utils/helpers';

export const Validations = (values: any) => {
  const errors: any = {};

  const optional = ['address2', 'countyName', 'fax', 'website'];
  let requiredFields = ['address1', 'city', 'stateName', 'postalCode', 'country'];

  if (values.isRegistration) {
    requiredFields = [...requiredFields, 'contactName', 'contactPhone'];
  }

  const required =
    Object.keys(values)
      .filter(items => requiredFields.includes(items) && !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  required.forEach(items => {
    if (!items.value.trim()) {
      errors[items.name] = 'Required';
    }
  });

  if (
    values.country &&
    values.country.value === 1 &&
    values.postalCode &&
    !/(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/.test(values.postalCode.trimStart().trimEnd())
  ) {
    errors.postalCode = 'Invalid';
  }
  if (
    values.country &&
    values.country.value === 5 &&
    values.postalCode &&
    !/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(
      values.postalCode.trimStart().trimEnd(),
    )
  ) {
    errors.postalCode = 'Invalid';
  }

  if (values.contactPhone && !phoneVal.test(values.contactPhone)) {
    errors.contactPhone = 'Invalid';
  }

  if (values.fax && !phoneVal.test(values.fax)) {
    errors.fax = 'Invalid';
  }

  if (
    values.website &&
    !/^((http|https|HTTP|HTTPS):\/\/)?(WWW.|www.)?(?!.*(http|https|HTTP|HTTPS|WWW.|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
      values.website.trimStart().trimEnd().toLowerCase(),
    )
  ) {
    errors.website = 'Invalid';
  }

  return errors;
};

export const informationValidation = (values: any) => {
  const errors: any = {};

  const optional = ['fax', 'website'];
  const requiredFields = ['contactName', 'contactPhone'];

  const required =
    Object.keys(values)
      .filter(items => requiredFields.includes(items) && !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  required.forEach(items => {
    if (!items.value.trim()) {
      errors[items.name] = 'Required';
    }
  });

  if (values.contactPhone && !phoneVal.test(values.contactPhone)) {
    errors.contactPhone = 'Invalid';
  }

  if (values.fax && !phoneVal.test(values.fax)) {
    errors.fax = 'Invalid';
  }

  if (
    values.website &&
    !/^((http|https|HTTP|HTTPS):\/\/)?(WWW.|www.)?(?!.*(http|https|HTTP|HTTPS|WWW.|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
      values.website.trimStart().trimEnd().toLowerCase(),
    )
  ) {
    errors.website = 'Invalid';
  }

  return errors;
};

export const mainContactValidation = (values: any) => {
  const errors: any = {};

  const optional = [] as any;
  const requiredFields = ['firstName', 'lastName', 'phone', 'email'];

  const required =
    Object.keys(values)
      .filter(items => requiredFields.includes(items) && !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  required.forEach(items => {
    if (!items.value.trim()) {
      errors[items.name] = 'Required';
    }
  });

  if (values.phone && !phoneVal.test(values.phone)) {
    errors.phone = 'Invalid';
  }

  if (emailValidation(values.email)) {
    errors.email = 'Invalid';
  }

  return errors;
};
