import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import history from '../../utils/history';
import * as actionTypes from '../actionTypes';
import * as Api from '../services';
import * as sharedApi from '../services/shared';
import * as bidApi from '../services/bids';
import * as broadcastListsApi from '../services/broadcastlists';
import {
  commlistTypes,
  commoditieslistTypes,
  commoditypayloadTypes,
  selectedparentTypes,
} from '../../types/commodity';
import { userEvent } from '../../utils/constants';
import { toastFn } from '../../utils/helpers';
// inirial load and get commodities
function* watchGetCommodityCodesAsync() {
  yield takeLatest(actionTypes.LOAD_COMMODITY_CODES.TRIGGER, getcommodityCodesAsync);
}

function* getcommodityCodesAsync(action: { payload: commoditypayloadTypes }): any {
  yield put({ type: actionTypes.LOAD_COMMODITY_CODES.REQUEST, meta: action.payload });

  try {
    const { pagefor = '', clearselected = false, reBroadcast = false } = action.payload;
    // let response = yield call(Api.getCommodityCodes, action.payload)
    const commoditycodes = yield select(state => state.commoditycodes);
    const { commoditieslist: cList = [] } = commoditycodes;
    let response = [];
    if (cList.length > 0) {
      response = cList;
    } else {
      response = yield call(sharedApi.getCommodities);
      response = response.data.result || [];
    }

    const commlist = yield response.map((item: commlistTypes) => {
      const code = item.formattedCode.split('-');

      return {
        commodityCategory: code[1],
        commodityCode: code[2],
        commodityGroup: code[0],
        commodityDescription: item.commodityDescription,
        commodityId: item.commodityId,
        fullCode: item.fullCode,
        formattedCode: item.formattedCode,
        isSelected: clearselected ? false : item.isSelected,
        contains: item.contains,
        isExisting: item.isSelected === true ? true : false,
        isDisplay: item.isSelected === true ? true : false,
        isPartial: false,
      };
    });

    // let selectedCommodityCodes = yield select(state => (state.bidssummary && state.bidssummary.commodities) || [])
    let selectedCommodityCodes = [];
    if (pagefor === 'addbid') {
      const bidssummary = yield select(state => state.bidssummary);
      const { results = {}, bidCommodity = [] } = bidssummary;
      const bidId = results.bidID;
      selectedCommodityCodes = bidCommodity;
      if (bidId && (bidCommodity.length === 0 || reBroadcast === true)) {
        const response = yield call(bidApi.getBidCommodityByType, { bidId: bidId, type: 'bid' });
        selectedCommodityCodes = response.data.result;
        yield put({
          type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
          payload: { bidCommodity: response.data.result },
        });
      }
    }

    if (pagefor === 'addquotes') {
      selectedCommodityCodes = yield select(state => state.quoteSummary.summary.commodities || []);
    }

    if (pagefor === 'broadcastlist') {
      const auth = yield select(state => state.auth);
      const broadcastList = yield select(state => state.broadcastList);
      const { selectedbroadcastlist /*, newlistmodalfor*/ } = broadcastList;
      const finaldata = {
        MemberId: auth && auth?.mi,
        ListID: selectedbroadcastlist?.listId,
      };
      const response = yield call(
        broadcastListsApi.getSelectedCommoditiesFiltersforBL,
        finaldata,
      ) || '';
      const data = response.data.result || '';
      selectedCommodityCodes = data?.commodityList || [];
      selectedCommodityCodes = selectedCommodityCodes ? selectedCommodityCodes.split(',') : [];
      selectedCommodityCodes =
        selectedCommodityCodes.length > 0
          ? selectedCommodityCodes.map((item: any) => ({ commodityId: Number(item) }))
          : [];

      const shared = yield select(state => state.shared);
      const addbid = yield select(state => state.addbid);
      const { activeprograms = [] } = addbid;
      const { selfdeclaredattributes = [], stateslist = [] } = shared;
      const { stateList, cityList, minorityList, programList } = data;
      const state = (stateList && stateList.split(',')) || [];
      const declaration = (minorityList && minorityList.split(',')) || [];
      const programs = (programList && programList.split(',')) || [];
      const stated =
        stateslist.length > 0 && state.length > 0
          ? stateslist.filter((item: { abbr: string }) => state.includes(item.abbr))
          : [];
      const selfdeclarAttr =
        selfdeclaredattributes.map((item: { attributeName: string; attributeType: string }) => ({
          label: item.attributeName,
          value: item.attributeType,
        })) || [];
      const selfdeclaration: [] = [];
      if (selfdeclarAttr.length > 0 && declaration.length > 0) {
        selfdeclarAttr.filter((item: { value: string; attributeType: string }) =>
          declaration.includes(item.value || item.attributeType),
        );
      }
      /*			stateslist = stateslist
				.map(items => ({ ...items, label: items.name || items.title, value: items.id }))
				.filter(item => item.countryId === ci)*/
      const program: [] = [];
      if (activeprograms.length > 0 && programs.length > 0) {
        activeprograms.filter((item: { value: { toString: () => any } }) =>
          programs.includes(item.value.toString()),
        );
      }
      yield put({
        type: actionTypes.SET_BROADCAST_LIST_DETAILS.TRIGGER,
        payload: {
          broadcastFilter: {
            filterState: stated || [],
            filterCity: (cityList && cityList.split(',')) || [],
            filterDeclaration: selfdeclaration || [],
            filterPrograms: program || [],
          },
        },
      });
    }

    const existscode = yield selectedCommodityCodes.map(
      (item: { commodityId: number }) => item.commodityId,
    );

    const commoditieslist = yield commlist.map((item: commoditieslistTypes) => {
      if (existscode.includes(item.commodityId)) {
        item.isSelected = true;
        item.isExisting = true;
        item.isDisplay = true;
        item.isPartial = false;
      }
      return item;
    });

    const selectedparent = yield commoditieslist
      .filter(
        (item: selectedparentTypes) =>
          item.commodityCategory !== '000' &&
          item.commodityGroup !== '000' &&
          item.commodityCode === '00' &&
          item.isSelected,
      )
      .map((item: { commodityCategory: string }) => item.commodityCategory);

    const partialparent = yield [
      ...Array.from(
        new Set(
          commoditieslist
            .filter(
              (item: selectedparentTypes) =>
                item.commodityCategory !== '000' &&
                item.commodityGroup !== '000' &&
                item.commodityCode !== '00' &&
                item.isSelected,
            )
            .map((item: { commodityCategory: string }) => item.commodityCategory),
        ),
      ),
    ];

    yield commoditieslist.map((item: commoditieslistTypes) => {
      if (selectedparent.includes(item.commodityCategory) && item.commodityCode !== '00') {
        item.isSelected = true;
      }
      if (partialparent.includes(item.commodityCategory) && item.commodityCode === '00') {
        item.isPartial = true;
        item.isSelected = false;
      }
      return item;
    });

    yield put({
      type: actionTypes.LOAD_COMMODITY_CODES.SUCCESS,
      payload: { commoditieslist: commoditieslist || [] },
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_COMMODITY_CODES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

// select comodities fn
function* watchSetSelectedCommodityCodes() {
  yield takeLatest(actionTypes.SET_SELECTED_COMMODITY_CODES.TRIGGER, setSelectedCommodity);
}
function* setSelectedCommodity(action: {
  payload: {
    commoditieslist: [];
    value: boolean | string;
    items: any;
    type: string;
    reBroadcast: boolean;
  };
}): any {
  yield put({ type: actionTypes.SET_SELECTED_COMMODITY_CODES.REQUEST, meta: action.payload });

  try {
    const { commoditieslist, value, items, type, reBroadcast = false } = action.payload;

    if (type === 'commodityCodes') {
      const list = commoditieslist.map((item: commoditieslistTypes) => {
        if (item.commodityId === items.commodityId) {
          item.isSelected = value;
          item.isDisplay = value;
        }
        return item;
      });

      const allcodes = list.filter(
        item =>
          item.commodityCategory === items.commodityCategory &&
          item.commodityGroup === items.commodityGroup &&
          item.commodityCode !== '00',
      );
      const selectedcodes = list.filter(
        item =>
          item.commodityCategory === items.commodityCategory &&
          item.commodityGroup === items.commodityGroup &&
          item.commodityCode !== '00' &&
          item.isSelected,
      );
      let finallist = list;

      if (allcodes.length === selectedcodes.length) {
        finallist = list.map(item => {
          if (item.commodityCategory === items.commodityCategory && item.commodityCode === '00') {
            item.isSelected = true;
            item.isDisplay = true;
            item.isPartial = false;
          }
          if (item.commodityCategory === items.commodityCategory && item.commodityCode !== '00') {
            item.isDisplay = false;
          }
          return item;
        });
      } else {
        finallist = list.map(item => {
          if (item.commodityCategory === items.commodityCategory && item.commodityCode === '00') {
            item.isSelected = false;
            item.isDisplay = false;
            item.isPartial = true;
          }
          if (
            item.commodityCategory === items.commodityCategory &&
            item.commodityCode === '00' &&
            selectedcodes.length === 0
          ) {
            item.isPartial = false;
          }
          if (item.commodityCategory === items.commodityCategory && item.commodityCode !== '00') {
            item.isDisplay = true;
          }
          return item;
        });
      }
      yield put({
        type: actionTypes.SET_COMMODITY_CODES.TRIGGER,
        payload: { commoditieslist: finallist },
      });
    } else if (type === 'commodityCategory') {
      let existList: any = [];
      if (reBroadcast) {
        existList = commoditieslist
          .filter((item: commoditieslistTypes) => {
            return (
              (item.isExisting && item.commodityCategory === items.commodityCategory) ||
              item.commodityId === items.commodityId
            );
          })
          .map((citem: commoditieslistTypes) => citem.commodityId);
      }
      const list = yield commoditieslist.map((item: commoditieslistTypes) => {
        if (item.commodityId === items.commodityId) {
          item.isSelected = value;
          item.isDisplay = value;
          item.isPartial = value;
        }
        return item;
      });

      const finallist = list.map((item: commoditieslistTypes) => {
        if (
          item.commodityCategory === items.commodityCategory &&
          item.commodityCode !== '00' &&
          !existList.includes(item.commodityId)
        ) {
          item.isSelected = value;
          item.isDisplay = false;
        }
        return item;
      });

      yield put({
        type: actionTypes.SET_COMMODITY_CODES.TRIGGER,
        payload: { commoditieslist: finallist },
      });
    }
    //yield put({ type: actionTypes.ADD_BID_SUPPLIER_COUNT_RESET.TRIGGER })
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_COMMODITY_CODES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}
// commodity search fn
function* watchSearchCommodityCodesAsync() {
  yield takeLatest(actionTypes.SEARCH_COMMODITY_CODES.TRIGGER, searchCommodityCodesAsync);
}
function* searchCommodityCodesAsync(action: { payload: { searchText: string } }): any {
  yield put({ type: actionTypes.SEARCH_COMMODITY_CODES.REQUEST, meta: action.payload });

  try {
    let response = yield call(Api.searchCommodityCodes, action.payload);
    response = response.data.result;

    const commoditieslist = [...(yield select(state => state.commoditycodes.commoditieslist))];

    const commlist = yield response.map((item: { commodityId: number }) => item.commodityId);

    const finalsearchresult = commoditieslist.filter(item => commlist.includes(item.commodityId));

    const payload = {
      searchedCommodityCodes: finalsearchresult,
      searchText: action.payload.searchText,
    };

    yield put({ type: actionTypes.SEARCH_COMMODITY_CODES.SUCCESS, payload: payload });
    yield delay(600);
  } catch (error) {
    yield put({
      type: actionTypes.SEARCH_COMMODITY_CODES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

// commodity submit fn
function* watchUpdateCommodityCodesAsync() {
  yield takeLatest(actionTypes.UPDATE_COMMODITY_CODES.TRIGGER, updateCommodityCodesAsync);
}
function* updateCommodityCodesAsync(action: { payload: any }): any {
  yield put({ type: actionTypes.UPDATE_COMMODITY_CODES.REQUEST, meta: action.payload });

  try {
    const shared = yield select(state => state.shared);
    const { pathfromdashboard } = shared;
    const { redirectPath = '' } = action.payload || {};
    let path = '/suppliers/account/commoditycodes';
    if (pathfromdashboard) {
      path = '/suppliers/dashboard';
    }
    if (redirectPath) {
      path = redirectPath;
    }

    const memberId = yield select(state => state.memberinfo.mi);
    const commoditieslist = yield select(state => state.commoditycodes.commoditieslist);

    const submitlist = commoditieslist
      .filter(
        (item: { isDisplay: boolean; isSelected: boolean }) => item.isDisplay && item.isSelected,
      )
      .map((items: { commodityId: number }) => items.commodityId);
    const existingList = commoditieslist
      .filter((item: { isExisting: boolean }) => item.isExisting)
      .map((items: { commodityId: number }) => items.commodityId);
    const data = {
      MemberId: memberId,
      CommodityCodeIds: submitlist.join(','),
    };

    let response = yield call(Api.updateCommodityCodes, data);
    response = yield response.data.result || '';

    if (response) {
      const payloadEvent = {
        eventId: userEvent.CommodityCodeChange,
        parentId: memberId,
        value: existingList.join(','),
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });

      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { restartcommoditycodes: true, pathfromdashboard: false },
      });
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO_COMMODITY.TRIGGER, payload: {} });
      yield put({ type: actionTypes.GET_TOP_COMMODITIES.TRIGGER, payload: { type: 'member' } });
      yield put({
        type: actionTypes.UPDATE_COMMODITY_CODES.SUCCESS,
        payload: { halfWayCancel: false, halfWayCancelAllow: true },
      });
      history.push(path);
      toastFn(
        'success',
        'Your Commodity Codes have been saved',
        'Your Commodity Codes have been saved form info input',
      );
    } else {
      yield put({ type: actionTypes.UPDATE_COMMODITY_CODES.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_COMMODITY_CODES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* commodityCodesSaga() {
  yield fork(watchGetCommodityCodesAsync);
  yield fork(watchSetSelectedCommodityCodes);
  yield fork(watchSearchCommodityCodesAsync);
  yield fork(watchUpdateCommodityCodesAsync);
}
