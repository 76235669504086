export interface TourConfig {
  selector: string;
  content: string;
  position?: 'left' | 'top' | number[];
  action?: (node: { focus: () => void }) => void;
}

export const bidSearchConfig: TourConfig[] = [
  {
    selector: '.rt_BS_stpe1',
    content:
      "We've enhanced our Search functionality with some powerful new features. Let us give you a quick tour of them",
  },
  {
    selector: '.rt_BS_stpe2',
    content:
      'By default, we\'ll search for "Only show my bids" matching bids in the DemandStar system. But you can filter your results by clicking one or more of these.',
    position: 'left',
    action: (node: { focus: () => void }): void => {
      node.focus();
    },
  },
  {
    selector: '.rt_BS_stpe3',
    content:
      "Search for bids from a specific government agency here. As you type, we'll suggest some options - click on the agency you're looking for when you see it.",
    // position: 'top'
  },
  {
    selector: '.rt_BS_stpe3BS',
    content:
      'You can filter bids by their status. The default is to only show Active bids, but you can look for bids Under Evaluation, Awarded, or more. You can also hit the X to search all bids, regardless of status.',
    // position: 'top'
    action: (node: { focus: () => void }): void => {
      node.focus();
    },
  },
  {
    selector: '.rt_BS_stpe4',
    content: 'Click anywhere here to go into our Advanced Search features. Give it a try!',
    // position: [320, 360]
  },
  {
    selector: '.rt_BS_stpe5',
    content:
      "Here are more options to help you narrow down your search and find what you're looking for. If you don't remember the full Name or Bid Identifier, try just searching with the parts you remember.",
  },
  {
    selector: '.rt_BS_stpe6',
    content: 'Here you can search for bids due on a specific date or a range of dates.',
    action: (node: { focus: () => void }): void => {
      node.focus();
    },
  },
  {
    selector: '.rt_BS_stpe7',
    content:
      "Want to hide the advanced options? Just clicking here. Don't worry - we'll still save anything you wrote in there. Give it a try!",
  },
  {
    selector: '.rt_BS_stpe8',
    content: "When you're all done, click the Search button to see your results. Happy searching.",
  },
];

export const bidSearchConfig2: TourConfig[] = [
  {
    selector: '.rt_BS_stpe1',
    content:
      "We've enhanced our Search functionality with some powerful new features. Let us give you a quick tour of them",
  },
  {
    selector: '.rt_BS_stpe2',
    content:
      'By default, we\'ll search for "All bids in System" matching bids in the DemandStar system. But you can filter your results by clicking one or more of these.',
    position: 'left',
    action: (node: { focus: () => void }): void => {
      node.focus();
    },
  },
  {
    selector: '.rt_BS_stpe3',
    content:
      "Search for bids from a specific government agency here. As you type, we'll suggest some options - click on the agency you're looking for when you see it.",
    // position: 'top'
  },
  {
    selector: '.rt_BS_stpe3BS',
    content:
      'You can filter bids by their status. The default is to only show Active bids, but you can look for bids Under Evaluation, Awarded, or more. You can also hit the X to search all bids, regardless of status.',
    // position: 'top'
    action: (node: { focus: () => void }): void => {
      node.focus();
    },
  },
  {
    selector: '.rt_BS_stpe4',
    content: 'Click anywhere here to go into our Advanced Search features. Give it a try!',
    // position: [320, 360]
  },
  {
    selector: '.rt_BS_stpe5',
    content:
      "Here are more options to help you narrow down your search and find what you're looking for. If you don't remember the full Name or Bid Identifier, try just searching with the parts you remember.",
  },
  {
    selector: '.rt_BS_stpe6',
    content: 'Here you can search for bids due on a specific date or a range of dates.',
    action: (node: { focus: () => void }): void => {
      node.focus();
    },
  },
  {
    selector: '.rt_BS_stpe7',
    content:
      "Want to hide the advanced options? Just clicking here. Don't worry - we'll still save anything you wrote in there. Give it a try!",
  },
  {
    selector: '.rt_BS_stpe8',
    content: "When you're all done, click the Search button to see your results. Happy searching.",
  },
];

export const bulkUploadConfig: TourConfig[] = [
  {
    selector: '[data-tut="reactour__agencyadd_1"]',
    content:
      'Instead of adding supplemental suppliers one by one, you can now upload a list of them with an Excel file. Add hundreds of supplemental suppliers in just seconds. Give it a try!',
  },
];
