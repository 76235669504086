import React, { memo } from 'react';
import { commodityParamType } from '../../../../types/commoditycodes';

interface PropsTypes {
  updateCommodityCodes: any;
  cancel: any;
  commoditycodes: commodityParamType;
}

const Commoditycodeedit = (props: PropsTypes) => {
  const { updateCommodityCodes, cancel, commoditycodes } = props;

  const { commoditieslist = [] } = commoditycodes || {};

  const selectedcomodity =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => !item.isExisting && item.isSelected && item.isDisplay)) ||
    [];
  const existingcomodity =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => item.isExisting && item.isDisplay && item.isSelected)) ||
    [];

  return (
    <>
      {selectedcomodity.length === 0 && existingcomodity.length === 0 ? (
        <li className='disabled-bttn'>
          <span className='staticLink'>
            <i className='mdi mdi-content-save-outline mr-1' /> Submit
          </span>
        </li>
      ) : (
        <li title='Submit'>
          <span className='staticLink' onClick={() => updateCommodityCodes()}>
            <i className='mdi mdi-content-save-outline mr-1' /> Submit
          </span>
        </li>
      )}

      <li title='Cancel'>
        <span className='staticLink' onClick={() => cancel()}>
          <i className='mdi mdi-close mr-1' />
          Cancel
        </span>
      </li>
    </>
  );
};

export default memo(Commoditycodeedit);
