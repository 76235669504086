import React, { memo, useState } from 'react';

interface PropsTypes {
  name?: string;
  handleChange?: any;
  value?: any;
  label?: string;
  parentClass?: string;
  placeholder?: string;
  touched?: any;
  error?: any;
  disabled?: any;
}

function TagsInput(props: PropsTypes) {
  const {
    name = '',
    handleChange = () => {},
    value: items = [],
    label = '',
    parentClass = '',
    placeholder = '',
    touched = false,
    error = '',
    disabled = false,
  } = props;
  const [input, setInput] = useState('');

  const handleInputChange = (evt: any) => {
    setInput(evt.target.value);
  };

  const handleInputKeyDown = (evt: any) => {
    if (evt.keyCode === 13) {
      const { value } = evt.target;
      const finalvalue = [...items, value];
      handleChange(name, finalvalue);
      setInput('');
    }

    if (items.length && evt.keyCode === 8 && !input.length) {
      const finalvalue = items.slice(0, items.length - 1);
      handleChange(name, finalvalue);
    }
  };

  const handleInputBlur = (evt: any) => {
    const { value } = evt.target;
    if (value) {
      const finalvalue = [...items, value];
      handleChange(name, finalvalue);
      setInput('');
    }
  };

  const handleRemoveItem = (index: number) => {
    return () => {
      const finalvalue = items.filter((item: any, i: number) => i !== index);
      handleChange(name, finalvalue);
    };
  };

  const styles = {
    container: {
      border: 'none',
      padding: '5px',
      borderRadius: '5px',
      width: '100%',
      height: '100%',
      margin: 0,
    },

    items: {
      padding: '3px 6px',
      backgroundColor: 'hsl(0,0%,90%)',
      borderRadius: '2px',
      display: 'inline',
      margin: '2px',
      marginBottom: '4px',
    },

    input: {
      margin: '0px 10px',
      outline: 'none',
      border: 'none',
    },
  };

  return (
    <>
      <div
        className={
          'selectWrapper clearfix ' + parentClass + (touched && error ? ' errorWrapper' : '')
        }
      >
        <div>
          <label>{label}</label>
          <ul style={styles.container}>
            {items &&
              items.length > 0 &&
              items.map((item: any, i: number) => (
                <li key={i} style={styles.items} onClick={handleRemoveItem(i)}>
                  {item}
                  <span className='mdi mdi-close pl-2 font-weight-bold' title='Remove' />
                </li>
              ))}
            <input
              style={styles.input}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder={placeholder}
              onBlur={handleInputBlur}
              disabled={disabled}
            />
          </ul>
        </div>
        <div className='errorMsg'>
          {touched && error ? <>{touched && error && <span>{error}</span>}</> : null}
        </div>
      </div>
    </>
  );
}
export default memo(TagsInput);
