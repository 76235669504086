import React, { memo } from 'react';

function BroadcastQuote() {
  return (
    <>
      <p>
        Finally, review your Quote entry, then click on “Post Quote” in the upper right corner. Your
        quote will be broadcast that same day.{' '}
      </p>
      img
      <h5>Viewing Quote Responses </h5>
      <p>Click on the tab “Quotes” and locate the quote for which you’d like to see results.</p>
      img
      <p>
        Across the top you will see five tabs – click on “Line Items”. When you click on each line
        item, you will see all the suppliers’ responses and be able to compare pricing.{' '}
      </p>
      img
      <p>
        You may assign an award per line item by simply clicking on the button next to the
        vendor/price that you have chosen as best for you.{' '}
      </p>
      img
      <p>
        You can then send a Quote Award notification email to the vendor who won the line item by
        filling out the field and pressing “Send this Award Email” at the bottom right.{' '}
      </p>
    </>
  );
}

export default memo(BroadcastQuote);
