import { CustomNotificationRecipient, EmailInfo } from '../../types/awardBid';
import { isValidEmail, isValidPhone } from '.';
import { RequiredDocument } from '../../store/services';
import { RequiredDocumentResponseGetAPIResponse } from '../../types';

export function isValidEmailInfo(emailInfo: EmailInfo) {
  const {
    customMessage,
    subject,
    introduction,
    contactName,
    contactTitle,
    contactPhone,
    contactEmail,
    recipients,
  } = emailInfo;
  return !!(
    customMessage &&
    subject &&
    introduction &&
    contactName &&
    contactTitle &&
    isValidPhone(contactPhone) &&
    isValidEmail(contactEmail) &&
    hasValidRecipients(recipients)
  );
}

function hasValidRecipients(recipients: CustomNotificationRecipient[]) {
  return !recipients.some(recipient => {
    return !isValidEmail(recipient.email) || (!recipient.contactName && !recipient.memberName);
  });
}

export function convertResponseToRequiredDoc(
  response: RequiredDocumentResponseGetAPIResponse,
): RequiredDocument {
  const { bidAwardRequiredDocId, docTitle, dueDate, isDelete } = response;
  return {
    bidAwardRequiredDocId,
    docTitle,
    dueDate,
    isDelete,
    docName: '',
    docDescription: '',
  };
}
