import { atom, GetRecoilValue, selector } from 'recoil';

import {
  CustomAwardNotification,
  CustomMessageType,
  EmailInfo,
  NewSupplier,
} from '../../../types/awardBid';
import {
  getCustomAwardNotification,
  GetCustomAwardNotificationPayload,
} from '../../services/awardBid';
import { Assert } from '../../../utils/helpers';
import { authState } from '../../../shared/hooks/useAuth';
import { MemberInfoApiResponse } from '../../../types';
import { memberInfoState } from './../memberState';
import { notifyAwardeeTexts } from '../../../utils/texts/agency/awardBid';
import { selectedBidIdState } from './../bidState';
import { track } from '../../../utils/telemetry';

/*************************************************
 * Notify Awardees
 ************************************************/

export function defaultEmailInfo(
  response: CustomAwardNotification,
  memberInfo: MemberInfoApiResponse,
  fullName: string,
  defaultSubject: string,
  defaultCustomMessage: string,
) {
  return {
    subject: response.subject || defaultSubject,
    introduction: response.introduction || notifyAwardeeTexts.notificationDefaults.introduction,
    customMessage: response.customMessage || defaultCustomMessage,
    contactName: response.contactName || fullName,
    contactPhone: response.contactPhone || memberInfo.p || '',
    contactTitle: response.contactTitle || memberInfo.jt || '',
    contactEmail: response.contactEmail || memberInfo.eml || '',
    recipients: response.recipients,
  };
}

async function getEmailInfo(
  get: GetRecoilValue,
  customMessageType: CustomMessageType,
  defaultSubject: string,
  defaultCustomMessage: string,
  errorTrack: string,
) {
  const bidId = get(selectedBidIdState);
  const memberInfo = get(memberInfoState);
  const auth = get(authState);
  // Re-run this selector whenever the selectedBidId changes.
  const requestParams: GetCustomAwardNotificationPayload = {
    bidId,
    customMessageType,
  };
  try {
    // API call
    const response = await getCustomAwardNotification(requestParams);
    Assert(!!memberInfo, 'memberInfo should be defined.');
    return defaultEmailInfo(
      response,
      memberInfo,
      auth.fullName,
      defaultSubject,
      defaultCustomMessage,
    );
  } catch (error: any) {
    track(`${errorTrack} -> getCustomAwardNotification(${bidId}) ERROR:`, {
      error,
      method: 'POST',
      errorMessage: error.message,
    });
    throw new Error(error.message);
  }
}
/**
 *
 */
const notifyAwardeesSelector = selector<EmailInfo>({
  key: 'notifyAwardeesSelector',
  get: async ({ get }) => {
    return getEmailInfo(
      get,
      CustomMessageType.Awardees,
      notifyAwardeeTexts.notificationDefaults.subject.awardees,
      notifyAwardeeTexts.notificationDefaults.customMessage.awardees,
      'notifyAwardeesSelector',
    );
  },
});

export const notifyAwardeesState = atom<EmailInfo>({
  key: 'notifyAwardeesState',
  default: notifyAwardeesSelector,
});

/*************************************************
 * Notify Unselected Bidders
 ************************************************/

export const newUnselectedPlanholders = atom<NewSupplier[]>({
  key: 'newUnselectedPlanholders',
  default: [],
});

const notifyUnselectedSelector = selector<EmailInfo>({
  key: 'notifyUnselectedSelector',
  get: async ({ get }) => {
    return getEmailInfo(
      get,
      CustomMessageType.NonAwardees,
      notifyAwardeeTexts.notificationDefaults.subject.unselected,
      notifyAwardeeTexts.notificationDefaults.customMessage.unselected,
      'notifyUnselectedSelector',
    );
  },
});

export const notifyUnselectedState = atom<EmailInfo>({
  key: 'notifyUnselectedState',
  default: notifyUnselectedSelector,
});

/*************************************************
 * Notify Colleagues
 ************************************************/

const notifyColleaguesSelector = selector<EmailInfo>({
  key: 'notifyColleaguesSelector',
  get: async ({ get }) => {
    return getEmailInfo(
      get,
      CustomMessageType.Colleagues,
      notifyAwardeeTexts.notificationDefaults.subject.colleagues,
      notifyAwardeeTexts.notificationDefaults.customMessage.colleagues,
      'notifyColleaguesSelector',
    );
  },
});

export const notifyColleaguesState = atom<EmailInfo>({
  key: 'notifyColleaguesState',
  default: notifyColleaguesSelector,
});
