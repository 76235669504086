import React, { memo, useEffect } from 'react';
import { Table } from 'reactstrap';
import { quoteBreakdownStatus } from '../../../../../../utils/constants';
import QuoteBreakdown from '../../../../../common/quotebreakdown';
import SupplierDetails from '../suppliers/details';
import { QuoteSummaryType } from '../../../../../../types/quotedetailstypedef';
import { addbidParamType } from '../../../../../../types/addbid';
import { sharedTypes } from '../../../../../../types/shared';

const tdWidth = {
  width: '210px',
};

interface PropsTypes {
  quoteSummary: QuoteSummaryType;
  setQuoteSummaryDetails: any;
  shared: sharedTypes;
  addbid: addbidParamType;
}

const Supplierdetails = (props: PropsTypes) => {
  const { quoteSummary, setQuoteSummaryDetails } = props;
  const { lineItems, currentSupplier, summary } = quoteSummary;

  const { breakDownlist = [] } = currentSupplier || {};

  useEffect(() => {
    return () => {
      if (breakDownlist.length > 0) {
        setQuoteSummaryDetails({
          selectedbreakdownlist: [],
          breakDownlist: breakDownlist.map((item: any) => ({ ...item, selected: false })),
        });
      }
    };
  }, [breakDownlist, setQuoteSummaryDetails]);

  return (
    <>
      <SupplierDetails {...props} supplier={currentSupplier} lineitemLength={lineItems.length} />

      {currentSupplier.responseType && quoteBreakdownStatus.includes(currentSupplier.responseType) && (
        <>
          <QuoteBreakdown pagefor='suppliers' breakDownlist={breakDownlist} {...props} />
          {currentSupplier.breakDownlist && currentSupplier.breakDownlist.length > 0 ? (
            <>
              <h4>Additional Supplier Information</h4>
              <div className='innerColIndent'>
                <Table borderless className='tableData'>
                  <tbody>
                    <tr>
                      <th style={tdWidth}>Delivery Details</th>
                      <td>{`${currentSupplier.breakDownlist[0].deliveryARO.replace(
                        'aro',
                        '',
                      )} After Receipt of Order (ARO)`}</td>
                    </tr>
                    <tr>
                      <th style={tdWidth}>Additional Details</th>
                      <td>{currentSupplier.breakDownlist[0].paymentTerms}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          ) : null}
          <h4>Buyer Information</h4>
          <div className='innerColIndent'>
            <Table borderless className='tableData' style={{ tableLayout: 'fixed' }}>
              <tbody>
                <tr>
                  <th style={tdWidth}>Additional Specifications</th>
                  <td>{summary.specifications ? summary.specifications : 'None'}</td>
                </tr>
                <tr>
                  <th style={tdWidth}>Insurance and Additional Requirements</th>
                  <td style={{ wordBreak: 'break-word' }}>
                    {summary.insuranceReqs ? summary.insuranceReqs : 'None'}
                  </td>
                </tr>
                <tr>
                  <th style={tdWidth}>Terms and Conditions</th>
                  <td>{summary.termsandConditions ? summary.termsandConditions : 'None'}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default memo(Supplierdetails);
