import React, { memo, useState } from 'react';
import { ModalPopUp } from '../../../../../common/modals/ModalPopUp';
import LegalAdContent from './legaladcontent';
import { bidItemParamType, bidssummaryParamType } from '../../../../../../types/biddetail';

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  getWriterInfo?: any;
  title?: string | undefined;
  pageFor?: string | undefined;
  data: bidItemParamType;
  bidsLegalAd?: any;
}

const LegalAd = (props: PropsTypes) => {
  const [clicked, setClicked] = useState(false);

  const { bidssummary, getWriterInfo, bidsLegalAd, title = '' } = props;

  const { results = {}, writerInfo, legalAd } = bidssummary || {};
  const { bidWriterAccountID, memberID, bidID: bidId } = results as bidItemParamType;
  const { bidId: legalBidId = '' } = legalAd || {};

  const handleClick = (e: any) => {
    e.preventDefault();
    if (bidId && bidWriterAccountID && memberID && !clicked && !writerInfo) {
      getWriterInfo({ writerAccountID: bidWriterAccountID, memberID: memberID });
    }
    if (bidId !== legalBidId) bidsLegalAd({ bidId });
    setClicked(!clicked);
  };

  return (
    <>
      <span className='staticLink' title={title} onClick={handleClick}>
        {title}
      </span>
      {clicked ? (
        <ModalPopUp title={'Legal Ad'} closeModal={handleClick} isOpen={clicked}>
          <LegalAdContent {...props} handleClick={handleClick} />
        </ModalPopUp>
      ) : null}
    </>
  );
};
export default memo(LegalAd);
