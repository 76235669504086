/* TOREFACTOR
  Exceeds 500 lines.
*/

import moment from 'moment-timezone';
import React, { memo, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { setWizardStatus, toastFn } from '../../../utils/helpers';
import TimePicker from '../controls/inputs/timepicker';
import AddQuotesControl from '../breadcrumb/buyer/addquotescontrol';
import { validate } from './form/validations';
import Formbuttons from './formbuttons';
import renderCheckbox from '../controls/inputs/checkbox';
import renderDateField from '../controls/inputs/datepicker';
import { renderDeprecatedTextField } from '../controls/inputs/text';
import renderSelect from '../controls/inputs/selectbox';
import renderTextarea from '../controls/inputs/textarea';
import { addquoteParamType } from '../../../types/addquote';
import { WizardPage } from '../../../types/wizard';
import { sharedParamType } from '../../../types/commoditycodes';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';
import { usaDateFormat } from '../../../utils/constants';

interface PropsTypes {
  match?: any;
  handleSubmit?: any;
  addquotes?: addquoteParamType;
  currentwizard?: WizardPage;
  setAddQuotesDetails?: any;
  shared?: sharedParamType;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  setBreadcrumb?: any;
  currentQuotesName?: string;
  getFiscalYear?: any;
  change?: any;
  submitAddQuotesInfo?: any;
  getAddQuotesInfo?: any;
  getAddQuoteNumbers?: any;
  quoteSummary?: QuoteSummaryType;
  addQuotegetMemberInfo?: any;
  initialValues?: any;
  quotesId?: number | string;
  populateMetrodetails?: any;
  setSharedDetails?: any;
}

function QuotesInformation(props: PropsTypes) {
  const {
    handleSubmit,
    addquotes,
    currentwizard,
    setAddQuotesDetails,
    shared,
    valid,
    pristine,
    submitting,
    setBreadcrumb,
    //quotesId,
    currentQuotesName,
    getFiscalYear,
    change,
    submitAddQuotesInfo,
    getAddQuotesInfo,
    getAddQuoteNumbers,
    quoteSummary,
    addQuotegetMemberInfo,
    initialValues,
    populateMetrodetails,
    setSharedDetails,
  } = props;
  const { quotedueDate = '' } = initialValues;
  const { addquoteswizard = [], halfWayCancel, addquotesinfo } = addquotes || {};
  const {
    fiscalYear,
    countrieslist,
    stateslist,
    sharedFormInitialValues = {},
    formPage = '',
  } = shared || {};
  const { quoteNo = '' } = addquotesinfo || {};
  const quotesId = parseInt(props.match.params.bidId) || '';

  const { summary } = quoteSummary || {};
  const { quoteNumber = '' } = summary || {};
  const [forminitialValues, setFormInitialValues] = useState(initialValues);

  /*useEffect(() => {
		if (stateslist.length === 0) getStateList()
	}, [getStateList, stateslist.length])*/

  /* useEffect(() => {
    if (quotesId) getAddQuotesInfo({ quotesId });
  }, [getAddQuotesInfo, quotesId]); */

  useEffect(() => {
    if (!quotesId) addQuotegetMemberInfo();
  }, [addQuotegetMemberInfo, quotesId]);

  useEffect(() => {
    getAddQuoteNumbers({ quoteNumber: quoteNo ? quoteNo : '' });
  }, [getAddQuoteNumbers, quoteNo]);

  useEffect(() => {
    setBreadcrumb({
      component: (
        <AddQuotesControl
          valid={valid}
          pristine={pristine}
          submitting={submitting}
          quotesId={quotesId}
        />
      ),
      page: currentQuotesName ? 'Agency Quote Update' : 'Agency Quote Creation',
      title: currentQuotesName
        ? `Edit Quote - ${currentwizard && currentwizard.name}`
        : `Quote Creation - ${currentwizard && currentwizard.name}`,
      altname: currentQuotesName
        ? `Edit Quote - ${currentwizard && currentwizard.name}`
        : `Quote Creation - ${currentwizard && currentwizard.name}`,
      name: currentQuotesName ? currentQuotesName : 'Quote Creation',
      id: quotesId,
      menuactive: 'quotes',
    });
  }, [currentQuotesName, currentwizard, pristine, quotesId, setBreadcrumb, submitting, valid]);

  useEffect(() => {
    return () => {
      setAddQuotesDetails({
        halfWayCancel: false,
        halfWayCancelAllow: false,
      });
    };
  }, [setAddQuotesDetails]);

  useEffect(() => {
    if (formPage && formPage === 'Agency Quote Creation') {
      setFormInitialValues(sharedFormInitialValues);
      setSharedDetails({ formPage: '' });
    }
  }, [formPage, setFormInitialValues, setSharedDetails, sharedFormInitialValues]);

  const prePopulatePostalCodeData = (postalCode: string, formData: any, fieldType: string) => {
    if (postalCode.length > 4) {
      populateMetrodetails({
        zip: postalCode,
        formData,
        formPage: 'Agency Quote Creation',
        fieldType,
      });
    }
  };
  const onSubmitFn = (data: any) => {
    if (data) {
      if (new Date(data.quotedueDate) < new Date()) {
        toastFn(
          'error',
          'Quote due date must be in the future.',
          'createquotes info form info input',
        );
      } else {
        submitAddQuotesInfo({ quotesId, data });
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={forminitialValues}
        validate={(values: any) => validate(values, props)}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const {
            values: any,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValidating,
            validateForm,
            isValid,
            dirty,
          } = formikProps;

          const {
            sameascontactinformation,
            shipCountryId,
            contactName,
            contactDepartment,
            contactAddress1,
            contactAddress2,
            contactCountryId,
            contactStateId,
            contactCity,
            contactPostalCode,
            contactPhone,
          } = formikProps.values;

          let filterstateslist = stateslist;
          if (contactCountryId && contactCountryId.value) {
            filterstateslist =
              stateslist && stateslist.filter(item => item.countryId === contactCountryId.value);
          }

          let filterstateslist2 = stateslist;
          if (shipCountryId && shipCountryId.value) {
            filterstateslist2 =
              stateslist && stateslist.filter(item => item.countryId === shipCountryId.value);
          }

          return (
            <form onSubmit={handleSubmit}>
              <div className='row innerColIndent'>
                <div className='col-md-9 col-lg-10 col-xl-8'>
                  <h3 className='mt-1 mb-4'>Scope of Work</h3>
                  <Field
                    label='Quote Name'
                    type='text'
                    name='quoteTitle'
                    classNames='class'
                    placeholder='Quote Name'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    maxLength={255}
                  />
                  <Field
                    label='Quote Number'
                    type='text'
                    classNames='class'
                    placeholder='Quote Number'
                    name='quoteNo'
                    maxLength={50}
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                  />
                  <Field
                    name='quoteYear'
                    label='Fiscal Year'
                    type='select'
                    component={renderSelect}
                    options={fiscalYear}
                    parentClass='my-0'
                    onChange={setFieldValue}
                  />
                  <div className='row'>
                    <div className='col-lg-6'>
                      <Field
                        label='Quote Due Date'
                        classNames='class'
                        name='quotedueDate'
                        minDate={
                          quotedueDate && new Date(quotedueDate) < new Date()
                            ? moment(quotedueDate)
                            : moment()
                        }
                        parentClass='my-1'
                        component={renderDateField}
                        optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                        onChange={setFieldValue}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <TimePicker
                        component={renderSelect}
                        timeFieldName='quotedueTime'
                        zoneFieldName='quotedueTimeZone'
                        parentClass='my-1'
                        optional='Eastern'
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  <Field
                    label='Days to deliver after receipt of order'
                    type='text'
                    classNames='class'
                    placeholder='Days to deliver after receipt of order'
                    name='deliveryARO'
                    component={renderDeprecatedTextField}
                    optional='Specify Days A.R.O. (optional)'
                    onChange={setFieldValue}
                    maxLength={50}
                  />
                  <Field
                    label='Shipping Notes'
                    type='text'
                    classNames='class'
                    parentClass='mt-4'
                    placeholder='Shipping Notes'
                    name='shipVia'
                    component={renderTextarea}
                    optional='optional'
                    onChange={setFieldValue}
                    maxLength={500}
                  />
                  <h3 className='mt-4 mb-4'>Confirm Contact Information</h3>
                  <Field
                    label='Name'
                    type='text'
                    classNames='class'
                    placeholder='Name'
                    name='contactName'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    maxLength={80}
                  />
                  <Field
                    label='Agency/Department'
                    type='text'
                    classNames='class'
                    placeholder='Agency/Department'
                    name='contactDepartment'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    maxLength={100}
                  />
                  <Field
                    label='Address'
                    type='text'
                    classNames='class'
                    placeholder='Address'
                    name='contactAddress1'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    maxLength={50}
                  />
                  <Field
                    label='Address2'
                    type='text'
                    classNames='class'
                    placeholder='Address2'
                    name='contactAddress2'
                    component={renderDeprecatedTextField}
                    optional={'optional'}
                    onChange={setFieldValue}
                    maxLength={50}
                  />

                  <div className='row clearfix'>
                    <div className='col-lg-6'>
                      <Field
                        label='Postal Code'
                        type='text'
                        classNames='class'
                        //parentClass='w-48'
                        placeholder='Postal Code'
                        name='contactPostalCode'
                        component={renderDeprecatedTextField}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                          if (value)
                            prePopulatePostalCodeData(
                              value,
                              { ...formikProps.values, contactPostalCode: value },
                              'contact',
                            );
                        }}
                        maxLength={30}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Field
                        label='City'
                        type='text'
                        classNames='class'
                        placeholder='City'
                        name='contactCity'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className='row clearfix'>
                    <div className='col-lg-6'>
                      <Field
                        name='contactStateId'
                        label='State'
                        type='select'
                        component={renderSelect}
                        options={filterstateslist}
                        onChange={setFieldValue}
                        // parentClass='my-0'
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Field
                        name='contactCountryId'
                        label='Country'
                        type='select'
                        component={renderSelect}
                        options={countrieslist}
                        //parentClass='my-0'
                        onChange={(name: string, value: any) => {
                          setFieldValue(name, value);
                          if (value) {
                            if (value && value.value !== contactCountryId.value) {
                              setFieldValue('contactStateId', '');
                              setFieldValue('contactPostalCode', '');
                              setFieldValue('contactCity', '');
                            }
                          }
                        }}
                      />
                    </div>
                  </div>

                  <Field
                    label='Phone'
                    type='text'
                    classNames='class'
                    placeholder='Phone'
                    name='contactPhone'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    maxLength={17}
                  />
                  <Field
                    label='Email'
                    type='text'
                    classNames='class'
                    placeholder='Email'
                    name='contactEmail'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    maxLength={100}
                  />
                  <h3 className='my-4'>Shipping Information</h3>
                  <Field
                    title='Same as Contact information'
                    type='text'
                    classNames='class'
                    // parentClass='mb-4'
                    name='sameascontactinformation'
                    component={renderCheckbox}
                    defaultChecked={sameascontactinformation}
                    handleChange={(name: string, value: any) => {
                      if (value.checked !== sameascontactinformation) {
                        if (value.checked) {
                          setFieldValue('shipName', contactName);
                          setFieldValue('shipDepartment', contactDepartment);
                          setFieldValue('shipAddress1', contactAddress1);
                          setFieldValue('shipAddress2', contactAddress2);
                          setFieldValue('shipCountryId', contactCountryId);
                          setFieldValue('shipStateId', contactStateId);
                          setFieldValue('shipCity', contactCity);
                          setFieldValue('shipPostalCode', contactPostalCode);
                          setFieldValue('shipPhone', contactPhone);
                        } else {
                          setFieldValue('shipName', '');
                          setFieldValue('shipDepartment', '');
                          setFieldValue('shipAddress1', '');
                          setFieldValue('shipAddress2', '');
                          setFieldValue('shipCountryId', '');
                          setFieldValue('shipStateId', '');
                          setFieldValue('shipCity', '');
                          setFieldValue('shipPostalCode', '');
                          setFieldValue('shipPhone', '');
                        }
                      }
                      setFieldValue('sameascontactinformation', value);
                    }}
                  />
                  {sameascontactinformation ? null : (
                    <>
                      <Field
                        label='Name'
                        type='text'
                        classNames='class'
                        placeholder='Name'
                        name='shipName'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={80}
                      />
                      <Field
                        label='Agency/Department'
                        type='text'
                        classNames='class'
                        placeholder='Agency/Department'
                        name='shipDepartment'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={100}
                      />
                      <Field
                        label='Address'
                        type='text'
                        classNames='class'
                        placeholder='Address'
                        name='shipAddress1'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={50}
                      />
                      <Field
                        label='Address2'
                        type='text'
                        classNames='class'
                        placeholder='Address2'
                        name='shipAddress2'
                        component={renderDeprecatedTextField}
                        optional={'optional'}
                        onChange={setFieldValue}
                        maxLength={50}
                      />

                      <div className='row clearfix'>
                        <div className='col-lg-6'>
                          <Field
                            label='Postal Code'
                            type='text'
                            classNames='class'
                            parentClass='my-0'
                            placeholder='Postal Code'
                            name='shipPostalCode'
                            component={renderDeprecatedTextField}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                              if (value)
                                prePopulatePostalCodeData(
                                  value,
                                  { ...formikProps.values, shipPostalCode: value },
                                  'ship',
                                );
                            }}
                            maxLength={30}
                          />
                        </div>
                        <div className='col-lg-6'>
                          <Field
                            label='City'
                            type='text'
                            classNames='class'
                            placeholder='City'
                            name='shipCity'
                            component={renderDeprecatedTextField}
                            onChange={setFieldValue}
                            parentClass='my-0'
                            maxLength={50}
                          />
                        </div>
                      </div>
                      <div className='row clearfix'>
                        <div className='col-lg-6'>
                          <Field
                            name='shipStateId'
                            label='State'
                            type='select'
                            component={renderSelect}
                            options={filterstateslist2}
                            parentClass='my-0'
                            onChange={setFieldValue}
                          />
                        </div>
                        <div className='col-lg-6'>
                          <Field
                            name='shipCountryId'
                            label='Country'
                            type='select'
                            component={renderSelect}
                            options={countrieslist}
                            parentClass='my-0'
                            onChange={(name: string, value: any) => {
                              setFieldValue(name, value);
                              if (value) {
                                if (value && value.value !== shipCountryId.value) {
                                  setFieldValue('shipStateId', '');
                                  setFieldValue('shipPostalCode', '');
                                  setFieldValue('shipCity', '');
                                }
                              }
                            }}
                          />
                        </div>
                      </div>

                      <Field
                        label='Phone'
                        type='text'
                        classNames='class'
                        placeholder='Phone'
                        name='shipPhone'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={17}
                      />
                    </>
                  )}
                </div>
              </div>
              <Formbuttons {...props} />
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(QuotesInformation);
