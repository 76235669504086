import React, { Component } from 'react';

import { pageSize } from '../../utils/constants';
import { range, scrollToTop } from '../../utils/helpers';

const LEFT_ARROW = 'LEFT';
const RIGHT_ARROW = 'RIGHT';

interface PropsTypes {
  pageLimit?: number;
  totalRecords?: number;
  currentPage: number;
  pagefor?: string;
  onPagingClick?: any;
  limitmsg?: boolean;
  directpage?: boolean;
}

interface State {
  pageLimit: any;
}

class Paging extends Component<PropsTypes, State> {
  private pageLimit: number;
  private pageNeighbours: number;
  private jump: number;
  private totalPages: number;

  constructor(props: PropsTypes) {
    super(props);

    const { pageLimit, totalRecords = 0 } = props;
    this.pageLimit = pageLimit ? pageLimit : pageSize.default;
    this.pageNeighbours = 2;
    this.jump = 0;
    this.totalPages = Math.ceil(totalRecords / this.pageLimit) || 1;
    this.state = {
      pageLimit: pageLimit ? pageLimit : pageSize.default,
    };
  }

  componentDidUpdate(prevProps: PropsTypes) {
    if (this.props.pageLimit !== prevProps.pageLimit) {
      this.setState({ pageLimit: this.props.pageLimit });
    }
  }

  getPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.props.currentPage;
    const pageNeighbours = this.pageNeighbours;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    /**
     * if condition used in totalNumbers due to pagination UI length problem in small left tab
     */
    const totalNumbers =
      this.props.pagefor === 'planholders-supplier'
        ? this.pageNeighbours
        : this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage) as any;

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_ARROW, ...extraPages, ...pages];
          this.jump = 4 - totalPages + currentPage;
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_ARROW];
          this.jump = 5 - currentPage;
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_ARROW, ...pages, RIGHT_ARROW];
          this.jump = 0;
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  onPageClick = (pageNumber: number) => () => {
    scrollToTop();
    const currentPage =
      pageNumber <= 0 ? 1 : pageNumber > this.totalPages ? this.totalPages : pageNumber;
    this.props.onPagingClick(currentPage);
  };

  onMoveClick = (page: any) => () => {
    let pageNumber = 1;
    if (page === LEFT_ARROW) {
      pageNumber = this.props.currentPage - this.pageNeighbours * 2 - 1 - this.jump;
    } else {
      pageNumber = this.props.currentPage + this.pageNeighbours * 2 + 1 + this.jump;
    }
    this.onPageClick(pageNumber)();
  };

  render() {
    const { currentPage, totalRecords = 0, limitmsg = true, directpage = true } = this.props;
    this.totalPages = Math.ceil(totalRecords / this.state.pageLimit) || 1;
    if (!totalRecords || this.totalPages === 1) return null;
    const pages = this.getPageNumbers();
    const startRecord = (currentPage - 1) * this.state.pageLimit + 1;
    const endRecord = currentPage * this.state.pageLimit;

    return (
      <>
        <div className='pagingWrapper d-flex'>
          <ul>
            {pages &&
              pages.map((page: any, index: number) => {
                if (page === LEFT_ARROW)
                  return (
                    <li key={index} onClick={this.onMoveClick(LEFT_ARROW)}>
                      <i className='mdi mdi-chevron-left' />
                    </li>
                  );
                if (page === RIGHT_ARROW)
                  return (
                    <li key={index} onClick={this.onMoveClick(RIGHT_ARROW)}>
                      <i className='mdi mdi-chevron-right' />
                    </li>
                  );
                if (index + 1 === pages.length && !directpage && pages.includes(RIGHT_ARROW)) {
                  return null;
                }
                return (
                  <li
                    key={index}
                    onClick={this.onPageClick(page)}
                    className={currentPage === page ? 'active' : ''}
                  >
                    {page}
                  </li>
                );
              })}
          </ul>
          <span>
            Showing {startRecord}-{endRecord > totalRecords ? totalRecords : endRecord} of{' '}
            {totalRecords}
          </span>
        </div>
        {totalRecords >= 500 && limitmsg ? (
          <div className='d-flex justify-content-center pb-2 pagingNote'>
            Your search returned the maximum number of items allowed. Narrow your search criteria to
            find more specific information.
          </div>
        ) : null}
      </>
    );
  }
}

export default Paging;
