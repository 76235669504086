import Api, { axiosRequestAuthenticated } from './api';

import {
  GetEBidResponseDetailsParameters,
  GetEBidResponseListParameters,
  GetResponseEventsParameters,
  ResponseApiResponse,
} from '../../types/ebids';

import { ebidResponseItemParamType } from '../../types/biddetail';

import { constants } from '../../utils/settings';
import { EBidResponse } from '../../types/suppliers';
import { Paths } from '../../utils/constants';

//-----EBids API calls-------//
/**
 * Typed EBidResponseList request
 * @param requestParams: GetEBidderActivitiesParameters
 */
export function getEBidResponseList(requestParams: GetEBidResponseListParameters) {
  return axiosRequestAuthenticated<EBidResponse[]>({
    baseURL: constants.api.url,
    data: requestParams,
    method: 'POST',
    url: Paths.getEBidResponses,
  });
}
/**
 * Typed EBidResponseList request
 * @param requestParams: GetEBidderActivitiesParameters
 */
export function getEBidResponseDetails(requestParams: GetEBidResponseDetailsParameters) {
  return axiosRequestAuthenticated<ResponseApiResponse>({
    baseURL: constants.api.url,
    data: { ...requestParams },
    method: 'POST',
    url: Paths.getresponse,
  });
}

/**
 * Typed bid response event request
 * @param data: ResponseId
}
 *
 */
export function getResponseEventsById(requestParams: GetResponseEventsParameters) {
  return axiosRequestAuthenticated<ebidResponseItemParamType>({
    baseURL: constants.api.trackUrl,
    data: {
      ...requestParams,
    },
    method: 'POST',
    url: Paths.responseEvents,
  });
}

export function getBidsResponseDetails(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getresponse, data, {
    baseURL: constants.api.url,
  });
}

export function getEBitsFileFormats(data = {}) {
  return Api.postRequestWithAuthentication(Paths.responseformats, data, {
    baseURL: constants.api.url,
  });
}

export function submitEbitFilesUpload(data: any = {}, config = {}) {
  const formData = new FormData();
  formData.append('bidId', data.bidId);
  formData.append('memberResponseItemId', data.memberResponseItemId);
  formData.append('title', data.title);
  formData.append('uploadFile', data.uploadFile[0]);
  if (data.responseMemberId) formData.append('responseMemberId', data.responseMemberId);
  return Api.postRequestWithAuthentication(Paths.ebidupload, formData, config);
}

export function submitEbitResponse(data = {}) {
  return Api.postRequestWithAuthentication(Paths.ebitupdate, data, {
    baseURL: constants.api.url,
  });
}

export function getEbidResponse(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getEBidResponses, data, {
    baseURL: constants.api.url,
  });
}

export function getEbidResponseSummary(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getresponse, data, {
    baseURL: constants.api.url,
  });
}

//-----EBids API calls-------//
