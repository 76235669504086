import classnames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../store/actions';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import AddNewTemplate from './addtemplate';
import LegalAd from './legalad';
import TemplateList from './templatelist';

interface navLink {
  name: string;
  id: string;
  active: string;
}
interface propsTypes extends PropsFromRedux {
  setAccountInfoDetails: any;
  getSavedLegal: any;
  match: any;
  accountinfo: {
    addTemplateModal: boolean;
    selectedTemplateItem: any;
    savedLegalData: any;
    updateType: number;
    memberinfo: {
      qintro: string;
      qins: string;
      qclose: string;
      mi: string;
    };
  };
}
const navLinks: navLink[] = [
  {
    name: 'Legal Ad',
    id: '2',
    active: 'legalad',
  },
];

const LegalTemplates = (props: propsTypes) => {
  const { setAccountInfoDetails, getSavedLegal, accountinfo } = props;

  const {
    addTemplateModal = false,
    selectedTemplateItem = {},
    savedLegalData = '',
    updateType = 1,
  } = accountinfo;

  const Tab: string = props.match.params.Tab;
  const [activeTab, setActiveTab] = useState(Tab ? Tab : 'legalad');

  useEffect(() => {
    let savedLegalAPICALL = true;
    if (!savedLegalData && savedLegalAPICALL) getSavedLegal();
    return () => {
      savedLegalAPICALL = false;
    };
  }, [getSavedLegal, savedLegalData]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <Nav tabs>
              {navLinks.map((item: navLink, index: number) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === item.active,
                    })}
                    onClick={() => {
                      toggle(item.active);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='templates'>
                {activeTab === 'templates' && <TemplateList {...props} />}
              </TabPane>
              <TabPane tabId='legalad'>
                {activeTab === 'legalad' && savedLegalData && <LegalAd {...props} />}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>

      <ModalPopUp
        title={updateType === 1 ? 'Add Template' : 'Edit Legal Ad'}
        size='lg'
        isOpen={addTemplateModal}
        closeModal={() => setAccountInfoDetails({ addTemplateModal: !addTemplateModal })}
      >
        <AddNewTemplate {...props} initialValues={selectedTemplateItem} />
      </ModalPopUp>
    </>
  );
};
const connector = connect(
  (state: any) => ({
    accountinfo: state.accountinfo,
    memberinfo: state.memberinfo,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(LegalTemplates));
