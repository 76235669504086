import React, { memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../store/actions';
import { displayDate, displayToday, filterDistinctArray } from '../../utils/helpers';
import { Buttons } from '../customcontrols';
import { registrationComponent } from '../../utils/constants';

const PrintReceipt = (props: any) => {
  const {
    subscriptions,
    registration,
    accountinfo,
    shared,
    setRegistrationData,
    setSubscriptionDetails,
    pagefor = '',
  } = props;
  const {
    transaction = {},
    OrderNumber,
    subscribedCounties = [],
    subscribedStates = [],
    subscribedNational = [],
    allProducts = [],
  } = subscriptions;
  const { accountinfo: accountInfo } = accountinfo || {};
  const { memberInfo = [], contacts = [] } = accountInfo || {};
  const { countrieslist = [] } = shared;
  const { registrationData = {} } = registration || {};

  let memberAddress = '' as any;
  let billingAddress = '' as any;
  let firstName = '';
  let lastName = '';
  let email = '';
  let phoneNumber = '';
  if (pagefor === 'registration') {
    const Address =
      registrationData.billingAddress && registrationData.billingAddress.city
        ? registrationData.billingAddress
        : registrationData.companyAddress;
    memberAddress = {
      ...registrationData.companyAddress,
      countyName:
        registrationData.companyAddress && registrationData.companyAddress.countyName
          ? registrationData.companyAddress.countyName.label
          : '',
      abbreviation:
        registrationData.companyAddress && registrationData.companyAddress.stateName
          ? registrationData.companyAddress.stateName.label
          : '',
    };
    billingAddress = {
      ...Address,
      countyName: Address && Address.countyName ? Address.countyName.label : '',
      abbreviation: Address && Address.stateName ? Address.stateName.label : '',
    };
    firstName = registrationData.mainContact.firstName;
    lastName = registrationData.mainContact.lastName;
    email = registrationData.mainContact.email;
    phoneNumber = registrationData.mainContact.phone;
  } else {
    memberAddress = memberInfo.find((item: any) => item.addressType === 'MA');
    billingAddress = memberInfo.find((item: any) => item.addressType === 'BA');

    if (billingAddress && !memberAddress) {
      memberAddress = billingAddress;
    }

    if (memberAddress && !billingAddress) {
      billingAddress = memberAddress;
    }
    firstName = contacts[0].firstName;
    lastName = contacts[0].lastName;
    email = contacts[0].email;
    phoneNumber = contacts[0].phoneNumber;
  }

  const memberCountryData = countrieslist.find(
    (item: any) => item.value === memberAddress.countryId,
  );
  const billingCountryData = countrieslist.find(
    (item: any) => item.value === billingAddress.countryId,
  );

  const nationalList = allProducts.filter((item: any) =>
    subscribedNational.includes(item.productId),
  );
  const statesList = allProducts.filter((item: any) => subscribedStates.includes(item.productId));
  let countiesList = allProducts.filter((item: any) => subscribedCounties.includes(item.productId));
  countiesList = filterDistinctArray(countiesList, 'productId');

  return (
    <>
      <div className='container'>
        <div className='row justify-content-center subPrintWrapper'>
          <div className='col-lg-12'>
            <div className='colWrapper'>
              <h2 className='arrowWrapper'>Your Receipt</h2>
              <div className='row'>
                <div className='col-md-12 col-lg-8'>
                  <h5 className='lineHR'>Transaction Information</h5>
                  <ul>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Merchant</span>
                      <span className='col-6 pr-0'>DemandStar</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Amount</span>
                      <span className='col-6 pr-0'>
                        $
                        {transaction.TransactionAmount
                          ? parseFloat(transaction.TransactionAmount).toFixed(2)
                          : 0.0}
                      </span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Transaction Date</span>
                      <span className='col-6 pr-0'>
                        {transaction.TransactionDate
                          ? displayDate(transaction.TransactionDate)
                          : displayToday()}
                      </span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Purchase Order Number</span>
                      <span className='col-6 pr-0'>{OrderNumber}</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Authorization Code</span>
                      <span className='col-6 pr-0'>{transaction.AuthorizationCode}</span>
                    </li>
                  </ul>
                  <h5 className='lineHR'>Payment Information</h5>
                  <ul>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Payment Type</span>
                      <span className='col-6 pr-0'>
                        {transaction.TransactionAmount ? 'Credit Card' : 'Complimentary'}
                      </span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Card Type</span>
                      <span className='col-6 pr-0'>{transaction.CardTypeName}</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Cardholder Name</span>
                      <span className='col-6 pr-0'>{transaction.CardholderName}</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Credit Card Ends With</span>
                      <span className='col-6 pr-0'>{transaction.CardLastFour}</span>
                    </li>
                  </ul>
                  <h5 className='lineHR'>Customer Information</h5>
                  <ul>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Name</span>
                      <span className='col-6 pr-0'>{`${firstName} ${lastName}`}</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Email</span>
                      <span className='col-6 pr-0'>{email}</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Phone</span>
                      <span className='col-6 pr-0'>{phoneNumber}</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Billing Address</span>
                      <span className='col-6 pr-0'>
                        {billingAddress.shortName}
                        <br />
                        {billingAddress.address1}
                        <br />
                        {billingAddress.address2}
                        {billingAddress.address2 ? <br /> : null}
                        {billingAddress.countyName ? `${billingAddress.countyName},` : null}{' '}
                        {billingAddress.abbreviation}, {billingAddress.postalCode}
                        <br />
                        {billingCountryData.title}
                      </span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Shipping Address</span>
                      <span className='col-6 pr-0'>
                        {memberAddress.shortName}
                        <br />
                        {memberAddress.address1}
                        <br />
                        {memberAddress.address2}
                        {memberAddress.address2 ? <br /> : null}
                        {memberAddress.countyName ? `${memberAddress.countyName},` : null}{' '}
                        {memberAddress.abbreviation}, {memberAddress.postalCode}
                        <br />
                        {memberCountryData.title}
                      </span>
                    </li>
                  </ul>
                  <h5 className='lineHR'>Subscriptions</h5>
                  <ul>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Subscription Type</span>
                      <span className='col-6 pr-0'>{transaction.SubscriptionPlan}</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Subscription Duration</span>
                      <span className='col-6 pr-0'>Annual</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Automatic Renewal</span>
                      <span className='col-6 pr-0'>Yes</span>
                    </li>
                    <li className='row'>
                      <span className='col-6 pl-0 sourceSemiBold'>Subscriptions</span>
                      <span className='col-6 pr-0'>
                        <div className='cartBody'>
                          <ul className='cartBodyItems row pl-0 list-unstyled'>
                            {nationalList.map((item: any, index: number) => (
                              <li key={index} className='col-md-6'>
                                <label>{item.productName}</label>
                              </li>
                            ))}
                            {nationalList.length === 0
                              ? statesList.map((item: any, index: number) => (
                                  <li key={index} className='col-md-6'>
                                    <label>{item.productName}</label>
                                  </li>
                                ))
                              : null}
                            {nationalList.length === 0
                              ? countiesList.map((item: any, index: number) => (
                                  <li key={index} className='col-md-6'>
                                    <label>{item.productName}</label>
                                  </li>
                                ))
                              : null}
                          </ul>
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='d-flex justify-content-between mt-4'>
                <Buttons
                  type='button'
                  onClick={() => {
                    if (pagefor === 'registration') {
                      setRegistrationData({
                        currentComponent: registrationComponent.OrderConfirmation,
                      });
                    } else setSubscriptionDetails({ currentStep: 3 });
                  }}
                  classNames='bttn-secondary'
                  text='Back'
                />
                <Buttons
                  type='button'
                  onClick={() => window.print()}
                  classNames='bttn-primary'
                  text='Print'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    subscriptions: state.subscriptions,
    accountinfo: state.accountinfo,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

export default connector(memo(PrintReceipt));
