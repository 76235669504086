import React, { useEffect } from 'react';
import { SelectBox, TagsInput } from '../../customcontrols';
import { addbidParamType } from '../../../types/addbid';
import { sharedParamType } from '../../../types/commoditycodes';

interface PropsTypes {
  addbid?: addbidParamType;
  shared?: sharedParamType;
  getSelfDeclarations?: any;
  getAddBidActivePrograms?: any;
  getStateList?: any;
  setAddBidDetails?: any;
  bidId?: number;
  reBroadcast?: boolean;
}

function Filtersuppliearlist(props: PropsTypes) {
  const {
    addbid,
    shared,
    getSelfDeclarations,
    getAddBidActivePrograms,
    getStateList,
    setAddBidDetails,
    reBroadcast = false,
  } = props;
  const { activeprograms = [], broadcastFilter, activeprogramsapi = true } = addbid || {};
  const { selfdeclaredattributes = [], stateslist = [] } = shared || {};

  useEffect(() => {
    if (activeprograms.length === 0 && activeprogramsapi)
      getAddBidActivePrograms({ isCurrentMember: true });
  }, [activeprograms, activeprogramsapi, getAddBidActivePrograms]);

  const { filterState = '', filterCity = '', filterDeclaration = '', filterPrograms = '' } =
    broadcastFilter || {};

  const onChangeFn = (name: string, value: any) => {
    setAddBidDetails({ broadcastFilter: { ...broadcastFilter, [name]: value } });
  };

  return (
    <>
      <h4 className='text-left mt-0'>Filter</h4>
      <SelectBox
        reactselect={true}
        label='State'
        name='filterState'
        options={stateslist}
        value={filterState}
        // parentClass='w-95'
        handleSelect={onChangeFn}
        mutli={true}
        clear={false}
        disabled={reBroadcast ? 'true' : 'false'}
      />
      <TagsInput
        label='City'
        name='filterCity'
        placeholder='Enter City...'
        handleChange={onChangeFn}
        value={filterCity}
        disabled={reBroadcast}
      />
      <SelectBox
        reactselect={true}
        label='Self Declaration'
        name='filterDeclaration'
        options={selfdeclaredattributes.map(item => ({
          ...item,
          label: item.attributeName,
          value: item.attributeType,
        }))}
        value={filterDeclaration}
        // parentClass='w-95'
        handleSelect={onChangeFn}
        mutli={true}
        clear={false}
        disabled={reBroadcast ? 'true' : 'false'}
      />
      <SelectBox
        reactselect={true}
        label='Certifications'
        name='filterPrograms'
        options={activeprograms}
        value={filterPrograms}
        // parentClass='w-95'
        handleSelect={onChangeFn}
        mutli={true}
        clear={false}
        disabled={reBroadcast ? 'true' : 'false'}
      />
    </>
  );
}

export default Filtersuppliearlist;
