import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';
import Api from './api';

export function getQuotes(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quotes, data, {
    baseURL: constants.api.url,
  });
}

export function contactList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.contacts, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteStatus, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteSummary(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteSummary, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteLineItems(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteLineItems, data, {
    baseURL: constants.api.url,
  });
}

export function getStatusOptions(data = {}) {
  return Api.postRequestWithAuthentication(Paths.statusOptions, data, {
    baseURL: constants.api.url,
  });
}

export function changeQuoteStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.changeStatus, data, {
    baseURL: constants.api.url,
  });
}

export function changeQuoteDueDate(data = {}) {
  return Api.postRequestWithAuthentication(Paths.changeDuedate, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteResponses(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteResponse, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteSupplierInfo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteResponder, data, {
    baseURL: constants.api.url,
  });
}

export function getSupplierBreakdown(data = {}) {
  return Api.postRequestWithAuthentication(Paths.supplierQuote, data, {
    baseURL: constants.api.url,
  });
}

export function DeleteQuote(data = {}) {
  return Api.postRequestWithAuthentication(Paths.deleteQuote, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteResponsesStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.responsesStatus, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteStatistics(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteStatistics, data, {
    baseURL: constants.api.url,
  });
}

export function getQuotelineItemSuppliers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.lineItemSuppliers, data, {
    baseURL: constants.api.url,
  });
}

export function awardQuote(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteaward, data, {
    baseURL: constants.api.url,
  });
}

export function notSubmitQuote(data = {}) {
  return Api.postRequestWithAuthentication(Paths.notSubmitQuote, data, {
    baseURL: constants.api.url,
  });
}

export function submitQuoteReply(data = {}) {
  return Api.postRequestWithAuthentication(Paths.submitQuoteReply, data, {
    baseURL: constants.api.url,
  });
}

export function submitQuoteLater(data = {}) {
  return Api.postRequestWithAuthentication(Paths.submitQuoteLater, data, {
    baseURL: constants.api.url,
  });
}

export function getQuoteView(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quotesview, data, {
    baseURL: constants.api.url,
  });
}

export function deleteLineItem(data = {}) {
  return Api.postRequestWithAuthentication(Paths.deleteLineItem, data, {
    baseURL: constants.api.url,
  });
}
