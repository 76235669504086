import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import React, { memo, useEffect, useState } from 'react';

import { bidItemParamType, bidssummaryParamType } from '../../../../types/biddetail';
import { broadcasthistorySortFields, pageSize, sortDirections } from '../../../../utils/constants';
import {
  deprecatedGetDateTimeZoneConverted,
  Searchingfn,
  SortingFn,
} from '../../../../utils/helpers';
import { DeprecatedInput, Paging, Sort } from '../../../customcontrols';
import { addbidParamType } from '../../../../types/addbid';
import { authTypes } from '../../../../types/auth';
import { loadingMsg } from '../../../../utils/texts';
import NoResult from '../../../customcontrols/noresult';
import { QuoteSummaryType } from '../../../../types/quotedetailstypedef';
import { sharedTypes } from '../../../../types/shared';
import SortingIcon from '../../../common/sortingIcon';

const tdWidth = {
  // TODO: Consider using rems instead.
  width: '180px',
};

interface PropsTypes {
  getBidsBroadcastHistory?: any;
  setBidSummaryDetails?: any;
  pagefor?: string;
  bidID?: number;
  quoteId?: any;
  bidssummary?: bidssummaryParamType;
  quoteSummary?: QuoteSummaryType;
  setSharedDetails?: any;
  shared?: sharedTypes;
  exportBroadcastList?: any;
  exportSupplementalSuppliers?: any;
  getAddBidsupplementalsuppliers?: any;
  addbid?: addbidParamType;
  auth?: authTypes;
}

function BroadcastHistory(props: PropsTypes) {
  const {
    getBidsBroadcastHistory,
    setBidSummaryDetails,
    pagefor,
    bidID = '',
    quoteId = '',
    bidssummary,
    quoteSummary,
    setSharedDetails,
    shared,
    exportBroadcastList,
    exportSupplementalSuppliers,
    getAddBidsupplementalsuppliers,
    addbid,
    auth,
  } = props;
  let { broadcasthistory = [] } = bidssummary || {};
  const { recipients = [], currentrecipients = [], currentbroadcast = '', stats, results } =
    bidssummary || {};
  const { memberID: bidMemberId = '', tzfn: btzfn, tzn: btzn } =
    (results as bidItemParamType) || {};
  const { mi: memberId = 0 } = auth || {};
  const { broadcasts: bidBroadcasts, supplimentalSuppliers } = stats || {};
  const { statistics, summary } = quoteSummary || {};
  const { tzfn: qtzfn, tzn: qtzn } = summary || {};

  const { broadcasts: quoteBroadcasts } = statistics || {};
  const { supplementalsupplierlist = [] } = addbid || {};
  const { internalLoader } = shared || {};

  const broadcasts = pagefor === 'addquotes' ? quoteBroadcasts : bidBroadcasts;
  const supplementalSuppliersCount =
    pagefor === 'addquotes' ? supplementalsupplierlist.length : supplimentalSuppliers;

  const id = pagefor === 'addquotes' ? quoteId : bidID;
  const pagetype = pagefor === 'addquotes' ? 'Quote' : 'Bid';

  const tzfn = pagetype === 'Quote' ? qtzfn : btzfn;

  const tzn = pagetype === 'Quote' ? qtzn : btzn;

  let currentrecipientslist = currentrecipients;

  useEffect(() => {
    if (pagetype === 'Quote') {
      if (id) getAddBidsupplementalsuppliers({ id, type: pagetype });
    }
  }, [id, getAddBidsupplementalsuppliers, pagetype]);

  useEffect(() => {
    if (id) {
      setSharedDetails({ internalLoader: true });
      getBidsBroadcastHistory({ id, type: pagetype });
    }
  }, [id, getBidsBroadcastHistory, pagetype, setSharedDetails]);

  useEffect(() => {
    if (!currentbroadcast && broadcasthistory && broadcasthistory.length > 0) {
      setSearchlist([]);
      setSearchvalue('');
      const value = broadcasthistory[0];
      const data =
        (recipients.length > 0 &&
          recipients
            .filter(item => item.broadCastId === value.id)
            .sort((a, b) => a.name.localeCompare(b.name))) ||
        [];
      setBidSummaryDetails({ currentbroadcast: value, currentrecipients: data });
    }
  }, [currentbroadcast, broadcasthistory, setBidSummaryDetails, recipients]);

  useEffect(() => {
    return () => {
      setBidSummaryDetails({ currentbroadcast: '', currentrecipients: [] });
      setSearchlist([]);
      setSearchvalue('');
    };
  }, [setBidSummaryDetails]);

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const [sortSuppliersvalue, setSortSuppliersvalue] = useState('date');
  const [sortSuppliers, setSortSuppliers] = useState(sortDirections.DESC);
  const changeSortSelectBox = (key: string, value: any) => {
    if (value !== sortSuppliersvalue) {
      const data = SortingFn(broadcasthistory, sortSuppliers, sortSuppliersvalue);
      setBidSummaryDetails({ broadcasthistory: data });
      setSortSuppliersvalue(value);
    }
  };
  const changeSortOrder = () => {
    const order =
      sortSuppliers === sortDirections.DESC
        ? sortDirections.ASC
        : sortSuppliers === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort;
    broadcasthistory = SortingFn(broadcasthistory, order, sortSuppliersvalue);
    setSortSuppliers(order);
  };

  const {
    name = '',
    type = '',
    date = '',
    contact = '',
    declaredAttributes = '',
  } = currentbroadcast;

  // Sort
  const [sortvalue, setSortvalue] = useState('name');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);

  // Logic for displaying pagination
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  // Search Logic
  const [searchlist, setSearchlist] = useState([]);
  const [searchvalue, setSearchvalue] = useState('');
  const changesearching = (name: string, search: any) => {
    const value = search.trimStart();
    if (value) {
      setSearchlist(
        Searchingfn(currentrecipients, ['email', 'name', 'contact', 'status'], value) as any,
      );
    } else {
      const data =
        (recipients.length > 0 &&
          recipients
            .filter(item => item.broadCastId === currentbroadcast.id)
            .sort((a, b) => a.name.localeCompare(b.name))) ||
        [];
      setBidSummaryDetails({ currentrecipients: data });
      setSortvalue('name');
      setSortorder(sortDirections.ASC);
      setSearchlist([]);
    }
    setCurrentPages(1);
    setSearchvalue(value);
  };

  if (searchlist.length > 0) {
    currentrecipientslist = searchlist;
  }

  const handleExportClick = (e: any) => {
    e.preventDefault();
    exportBroadcastList({ id, type: pagetype });
  };

  const handleSSExportClick = (e: any) => {
    e.preventDefault();
    exportSupplementalSuppliers({ id, type: pagetype });
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h3 className='mb-3 arrowWrapper'>Broadcast History</h3>
          {((broadcasts && broadcasts > 0) ||
            (supplementalSuppliersCount && supplementalSuppliersCount > 0)) &&
          bidMemberId === memberId ? (
            <UncontrolledDropdown>
              <DropdownToggle
                title='Export'
                className='bttn bttn-secondary btnDropDown small float-right ml-3'
              >
                Export
              </DropdownToggle>
              <DropdownMenu>
                {broadcasts && broadcasts > 0 ? (
                  <DropdownItem onClick={handleExportClick}>Broadcast List</DropdownItem>
                ) : null}
                {supplementalSuppliersCount && supplementalSuppliersCount > 0 ? (
                  <DropdownItem onClick={handleSSExportClick}>Supplemental Suppliers</DropdownItem>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : null}{' '}
        </div>
        {broadcasthistory && broadcasthistory.length > 0 ? (
          <>
            <div className='col-lg-4'>
              <div className='ulListWrapper'>
                <h4>
                  {broadcasthistory.length > 1 ? 'Broadcasts ' : 'Broadcast '} (
                  {broadcasthistory.length})
                </h4>
                <header className='headerFilter d-flex clearfix pl-0 align-items-center'>
                  <Sort
                    sortFields={broadcasthistorySortFields}
                    selectedFieldName={sortSuppliersvalue}
                    onChange={changeSortSelectBox}
                    onSortClick={changeSortOrder}
                    sortIcon={sortSuppliers}
                  />
                </header>
                <ul className='list-unstyled clearfix'>
                  {broadcasthistory
                    .map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          if (item.id !== currentbroadcast.id) {
                            const data =
                              (recipients.length > 0 &&
                                recipients
                                  .filter(items => items.broadCastId === item.id)
                                  .sort((a, b) => a.name.localeCompare(b.name))) ||
                              [];
                            setBidSummaryDetails({
                              currentbroadcast: item,
                              currentrecipients: data,
                            });
                            setSearchlist([]);
                            setSearchvalue('');
                            setSortvalue('name');
                            setSortorder(sortDirections.ASC);
                            setCurrentPages(1);
                          }
                        }}
                        className={item.id === currentbroadcast.id ? 'active' : ''}
                      >
                        <h6 className='mw-100 text-truncate'>{item.name}</h6>
                        <span>
                          <i>Type :</i> {item.type}
                        </span>
                        <span>
                          <i>Date :</i> {deprecatedGetDateTimeZoneConverted(item.date, tzfn)} ({tzn}
                          )
                        </span>
                      </li>
                    ))
                    .slice(indexOfFirstList, indexOfLastList)}
                </ul>
                <Paging
                  totalRecords={broadcasthistory.length}
                  currentPage={currentPage}
                  pagefor='planholders-supplier'
                  onPagingClick={(value: number) => {
                    if (value !== currentPage) setCurrentPage(Number(value));
                  }}
                  pageLimit={listPerPage}
                />
              </div>
            </div>
            <div className='col-lg-8 responsecontent'>
              <>
                <h4>Broadcast Details</h4>
                {currentbroadcast && currentbroadcast.name ? (
                  <div className='innerColIndent'>
                    <Table borderless className='tableData'>
                      <tbody>
                        <tr>
                          <th style={tdWidth}>Broadcast Name</th>
                          <td>{name}</td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td>{type}</td>
                        </tr>
                        <tr>
                          <th>Date</th>
                          <td>
                            {deprecatedGetDateTimeZoneConverted(date, tzfn)} ({tzn})
                          </td>
                        </tr>
                        <tr>
                          <th>Contact Name</th>
                          <td>{contact}</td>
                        </tr>
                        {declaredAttributes ? (
                          <tr>
                            <th>Self Declarations</th>
                            <td>{declaredAttributes}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult
                    message={internalLoader ? loadingMsg : 'No Recipients Details Available'}
                  />
                )}
              </>
              <h4>Recipients ({currentrecipients.length})</h4>
              {currentrecipients.length > 0 ? (
                <DeprecatedInput
                  type='search'
                  placeholder='Filter'
                  label='Filter'
                  value={searchvalue}
                  handleChange={changesearching}
                  parentClass='w-50'
                />
              ) : null}
              {searchlist.length === 0 && searchvalue.length > 0 ? (
                <NoResult message={internalLoader ? loadingMsg : 'No Recipients Available'} />
              ) : (
                <>
                  {currentrecipientslist.length > 0 ? (
                    <>
                      <div className='innerColIndent'>
                        <Table className='tableHData'>
                          <tbody>
                            <tr>
                              <th>
                                Supplier Name
                                <SortingIcon
                                  sortlist={currentrecipientslist}
                                  initialsortorder={sortorder}
                                  currentsortname={sortvalue}
                                  changesortname={(value: string) => setSortvalue(value)}
                                  sortname='name'
                                  changeSortingFn={(list: any) =>
                                    setBidSummaryDetails({ currentrecipients: list })
                                  }
                                />
                              </th>
                              <th>Contact</th>
                              <th>
                                Email
                                <SortingIcon
                                  sortlist={currentrecipientslist}
                                  initialsortorder={sortorder}
                                  currentsortname={sortvalue}
                                  changesortname={(value: string) => setSortvalue(value)}
                                  sortname='email'
                                  changeSortingFn={(list: any) =>
                                    setBidSummaryDetails({ currentrecipients: list })
                                  }
                                />
                              </th>
                              <th>Status</th>
                            </tr>
                            {currentrecipientslist
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>{item.name}</td>
                                  <td>{item.contact}</td>
                                  <td>{item.email}</td>
                                  <td>{item.status}</td>
                                </tr>
                              ))
                              .slice(indexOfFirstLists, indexOfLastLists)}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoResult message={internalLoader ? loadingMsg : 'No Recipients Available'} />
                  )}
                </>
              )}
              {searchlist.length === 0 && searchvalue.length > 0 ? null : (
                <Paging
                  totalRecords={currentrecipientslist.length}
                  currentPage={currentPages}
                  onPagingClick={(value: number) => {
                    if (value !== currentPages) setCurrentPages(Number(value));
                  }}
                  limitmsg={false}
                  pageLimit={listPerPages}
                />
              )}
            </div>
          </>
        ) : (
          <NoResult message={internalLoader ? loadingMsg : 'No Recipients Available'} />
        )}
      </div>
    </>
  );
}

export default memo(BroadcastHistory);
