import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as ebidCampaignApi from '../services/ebidcampaign';
import { toastFn } from '../../utils/helpers';
import { ebidcampaignsagatypes } from '../../types/ebidcampaign';

const ebidCampaignToastID = 'f67708b4-1883-4d50-b207-6847019d59ee';

function* watchGetCampaignStatus() {
  yield takeLatest(actionTypes.LOAD_CAMPAIGN_STATUS.TRIGGER, getCampaignStatus);
}

function* getCampaignStatus(action: ebidcampaignsagatypes): any {
  yield put({ type: actionTypes.LOAD_CAMPAIGN_STATUS.REQUEST, meta: action.payload });

  try {
    const response = yield call(ebidCampaignApi.getCampaignStatus, action.payload);
    const payload = response.data.result;
    yield put({
      type: actionTypes.LOAD_CAMPAIGN_STATUS.SUCCESS,
      payload: { campaignstatus: payload },
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_CAMPAIGN_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', ebidCampaignToastID);
  }
}

function* watchUpdateCampaignFirstPopup() {
  yield takeLatest(actionTypes.UPDATE_CAMPAIGN_FIRST_POPUP.TRIGGER, updateCampaignFirstPopup);
}

function* updateCampaignFirstPopup(action: ebidcampaignsagatypes): any {
  yield put({ type: actionTypes.UPDATE_CAMPAIGN_FIRST_POPUP.REQUEST, meta: action.payload });

  try {
    const response = yield call(ebidCampaignApi.updateCampaignFirstPopup, action.payload);
    const payload = response.data.result;
    yield put({ type: actionTypes.UPDATE_CAMPAIGN_FIRST_POPUP.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_CAMPAIGN_FIRST_POPUP.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', ebidCampaignToastID);
  }
}

function* watchUpdateCampaignNotNowPopup() {
  yield takeLatest(actionTypes.UPDATE_CAMPAIGN_NOTNOW_POPUP.TRIGGER, updateCampaignNotNowPopup);
}

function* updateCampaignNotNowPopup(action: ebidcampaignsagatypes): any {
  yield put({ type: actionTypes.UPDATE_CAMPAIGN_NOTNOW_POPUP.REQUEST, meta: action.payload });

  try {
    const response = yield call(ebidCampaignApi.updateCampaignNotNowPopup, action.payload);
    const payload = response.data.result;
    yield put({ type: actionTypes.UPDATE_CAMPAIGN_NOTNOW_POPUP.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_CAMPAIGN_NOTNOW_POPUP.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', ebidCampaignToastID);
  }
}

function* watchUpdateCampaignFeedbackPopup() {
  yield takeLatest(actionTypes.UPDATE_CAMPAIGN_FEEDBACK_POPUP.TRIGGER, updateCampaignFeedbackPopup);
}

function* updateCampaignFeedbackPopup(action: ebidcampaignsagatypes): any {
  yield put({ type: actionTypes.UPDATE_CAMPAIGN_FEEDBACK_POPUP.REQUEST, meta: action.payload });

  try {
    const response = yield call(ebidCampaignApi.updateCampaignFeedbackPopup, action.payload);
    const payload = response.data.result;
    yield put({ type: actionTypes.UPDATE_CAMPAIGN_FEEDBACK_POPUP.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_CAMPAIGN_FEEDBACK_POPUP.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', ebidCampaignToastID);
  }
}

export function* ebidCampaignSaga() {
  yield fork(watchGetCampaignStatus);
  yield fork(watchUpdateCampaignFirstPopup);
  yield fork(watchUpdateCampaignNotNowPopup);
  yield fork(watchUpdateCampaignFeedbackPopup);
}
