import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { authKeys, headers, timeOut } from '../../utils/constants';
import { DocumentDownloadIdKey, DocumentDownloadRequestParams } from '../../types/document';
import { clearTokenCookie, isExpiredToken } from '../../utils/helpers';

import { ApiResponse } from '../../types/shared';
import { constants } from '../../utils/settings';
import { downloadMessages } from '../../shared/constants';
import { store } from '../configureStore';
import { track } from '../../utils/telemetry';

const getToken = () => store.getState().auth[authKeys.token];

const axiosCreate = { baseURL: constants.api.url, timeout: timeOut, headers };

export const axiosClient = axios.create(axiosCreate);

export const axiosClientWithAuthentication = axios.create(axiosCreate);
const CancelToken = axios.CancelToken;

// TOREFACTOR - move auth credentials out of code
axiosClientWithAuthentication.interceptors.request.use(
  config => {
    if (config.baseURL && !config.headers.Authorization) {
      const token = getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        /* if (isExpiredToken(token)) {
          logOutFn();
          return {
            ...config,
            cancelToken: new CancelToken(cancel => cancel('Token Expired')),
          };
        } else {

        } */
      }
    }

    // return config

    if (config.timeout === undefined || config.timeout === 0) {
      return config;
    }

    const source = axios.CancelToken.source();

    setTimeout(() => {
      source.cancel(
        `Cancelled request. Took longer than ${config.timeout}ms to get complete response.`,
      );
    }, config.timeout);

    // If caller configures cancelToken, preserve cancelToken behaviour.
    if (config.cancelToken) {
      config.cancelToken.promise.then(cancel => {
        source.cancel(cancel.message);
      });
    }
    return { ...config, cancelToken: source.token };
  },
  error => Promise.reject(error),
);

axiosClientWithAuthentication.interceptors.response.use(undefined, error => {
  const { response, message = '' } = error;
  if (message === `Cancelled request. Took longer than ${timeOut}ms to get complete response.`) {
    const abortController = new AbortController();
    abortController.abort();
  }
  if (response && response.status === 401) {
    clearTokenCookie();
  }
  return Promise.reject(error);
});

const getRequest = (requestUrl = '', userOptions = {}) => {
  try {
    return axiosClient({ method: 'GET', url: requestUrl, ...userOptions });
  } catch (error) {
    track('API_ERROR', { error, method: 'GET', requestUrl, userOptions });
    return null;
  }
};

export type AxiosRequestOptions = Record<string, unknown>;
/**
 * Direct Axios request with typing enforced.
 * TODO: response should be raw. create a base request,
 *  or update the hard-coded expectation of an `ApiResponse` typed result?
 * @param config: AxiosRequestConfig
 * @param useAuthentication: boolean
 * @returns a typed Promise
 */
export async function axiosRequest<T>(config: AxiosRequestConfig, useAuthentication = false) {
  const axiosInstance: AxiosInstance =
    useAuthentication === true ? axiosClientWithAuthentication : axiosClient;
  try {
    const response = await axiosInstance.request<ApiResponse<T>>(config);
    return response?.data?.result;
  } catch (error: any) {
    track('API_ERROR', {
      error,
      method: config?.method || 'GET',
      axiosRequestConfig: config?.url,
      config,
    });
    // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
    console.error(`axiosRequest ERROR: \n${error}`);
    throw new Error(error);
    // return {};
  }
}

/**
 * Helper fn for authenticated requests.
 * @param config
 * @returns a typed Promise
 */
export async function axiosRequestAuthenticated<T>(config: AxiosRequestConfig) {
  return axiosRequest<T>(config, true);
}

/**
 * Helper fn for authenticated POST requests.
 * @param config: AxiosRequestConfig
 * @returns a typed Promise
 */
export async function axiosPostAuthenticated<T>(config: AxiosRequestConfig) {
  return axiosRequestAuthenticated<T>({ ...config, method: 'POST' });
}

/**
 * Helper fn for authenticated download requests.
 * @param config
 */
export async function axiosRequestDownloadAuthenticated<T>(config: AxiosRequestConfig) {
  return axiosRequest<T>(config, true);
}

const postRequest = (requestUrl = '', data = {}, userOptions = {}) => {
  try {
    return axiosClient({
      method: 'POST',
      url: requestUrl,
      ...userOptions,
      data,
    });
  } catch (error) {
    track('API_ERROR', {
      error,
      method: 'POST',
      requestUrl,
      data,
      userOptions,
    });
    return undefined;
  }
};

const getRequestWithAuthentication = (requestUrl = '', userOptions = {}) => {
  try {
    const token = getToken();
    if (isExpiredToken(token)) {
      clearTokenCookie();
      return undefined;
    } else {
      return axiosClientWithAuthentication({
        method: 'GET',
        url: requestUrl,
        ...userOptions,
      });
    }
  } catch (error) {
    track('API_ERROR', { error, method: 'GET', requestUrl, userOptions });
    return undefined;
  }
};

const postRequestWithAuthentication = (requestUrl = '', data = {}, userOptions = {}) => {
  try {
    const token = getToken();
    if (isExpiredToken(token)) {
      clearTokenCookie();
      return undefined;
    } else {
      return axiosClientWithAuthentication({
        method: 'POST',
        url: requestUrl,
        ...userOptions,
        data,
      });
    }
  } catch (error) {
    track('API_ERROR', {
      error,
      method: 'POST',
      requestUrl,
      data,
      userOptions,
    });
    return undefined;
  }
};

/**
 * Typed, authenticated Axios download GET request.
 * This also adds, programmatically clicks, then removes an `<a href.../>` anchor.
 * Auth token is sent via the header rather than appended to the URL.
 * @param requestParams: DocumentDownloadRequestParams
 */
export async function downloadRequestAuthenticated(requestParams: DocumentDownloadRequestParams) {
  const { baseUrl, docIdParams: data, requestUrl } = requestParams;
  const docIdKeys = Object.keys(data);
  const queryString = docIdKeys
    .map(key => {
      const value = data[key as DocumentDownloadIdKey] || '';
      return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    })
    .join('&');

  const downloadUrl = `${baseUrl}${requestUrl}?${queryString}`;

  // Request the document.
  try {
    const response = await axios.request({
      url: downloadUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      responseType: 'blob',
    });

    const responseData = response.data;

    let fileName = '';

    // Parse the response header content-disposition for the actual file name.
    const contentDisposition = response?.headers['content-disposition'] || '';
    if (contentDisposition !== '') {
      const contentDispositionAttributes = contentDisposition.split(';');
      fileName = contentDispositionAttributes.find(
        (cont: string) => cont.trim().indexOf('filename=') >= 0,
      );
      fileName = fileName.replace('filename=', '').replace(/"/g, '').trim();
    }

    // Throw an error if the fileName can't be determined.
    if (!fileName) {
      throw new Error(downloadMessages.fileNameNotFound);
    }

    // Create a blob URL representing the data.
    const blob = new Blob([responseData], { type: response?.data?.type || '' });
    const windowUrl = window.URL.createObjectURL(blob);

    // Attach the blob URL to anchor element with download attribute
    const anchor = document.createElement('a');
    anchor.setAttribute('href', windowUrl);
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('download', fileName);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(windowUrl);
  } catch (error) {
    console.error(error);
  }
}

const downloadFileWithAuthentication = (requestUrl = '', data = {} as any, baseUrl = '') => {
  try {
    const docIdKeys = Object.keys(data);
    const queryString = docIdKeys
      .map((key: string) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
      })
      .join('&');

    const link = document.createElement('a');
    link.href = `${baseUrl}${requestUrl}?${queryString}${queryString && '&'}tk=${getToken()}`;

    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    track('API_ERROR', { error, requestUrl, data, baseUrl });
    return undefined;
  }
};

const api = {
  downloadFileWithAuthentication,
  getRequest,
  getRequestWithAuthentication,
  postRequest,
  postRequestWithAuthentication,
};

export default api;
