import dayjs, { Dayjs } from 'dayjs';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { convertToICANN, DeprecatedFullTimeZone, TimeZone } from '../constants';
import { Assert } from './testing';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);

dayjs.updateLocale('en', {
  formats: {
    // abbreviated format options allowing localization
    LTS: 'h:mm:ssa',
    LT: 'h:mma',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mma',
    LLLL: 'dddd, MMMM D, YYYY h:mma',
    // lowercase/short, optional formats for localization
    l: 'M/D/YYYY',
    ll: 'MMM D, YYYY',
    lll: 'MMM D, YYYY h:mma',
    llll: 'ddd, MMM D, YYYY h:mma',
  },
});

export enum DateFormat {
  Base = 'll',
  DateTime = 'lll',
  DateTimeWithZone = 'lll (z)',
  Url = 'MM-DD-YYYY',
  IsoUtc = 'YYYY-MM-DDTHH:mm:ss[Z]',
}

function isDeprecatedTimeZone(timeZone?: string): timeZone is DeprecatedFullTimeZone {
  return !!(timeZone && Object.keys(convertToICANN).includes(timeZone));
}

function safeTimeZone(timeZone?: string) {
  if (isDeprecatedTimeZone(timeZone)) {
    return convertToICANN[timeZone];
  }
  return timeZone || dayjs.tz.guess();
}

/**
 * @param date - any date format that DayJS can convert
 * @param timeZone - deprecated time zone. To be replaced with ICANN timezone.
 * @param format - optional format
 * @returns date formatted 'MMM D, YYYY' (in America)
 * @example displayDate('2021-07-06T15:47:46Z') // Jul 6, 2021
 */
export function displayDate(date: dayjs.ConfigType, timeZone?: string, format = DateFormat.Base) {
  timeZone = safeTimeZone(timeZone);
  const dateTimeUTC = dayjs.utc(date);
  const dateTimeWithZone = dayjs.tz(dateTimeUTC, timeZone);
  return dateTimeUTC.isValid() ? dateTimeWithZone.format(format) : '';
}

/**
 * @param date - any date format that DayJS can convert
 * @param timeZone - deprecated time zone. To be replaced with ICANN timezone.
 * @param format - optional format
 * @returns date formatted 'MMM D, YYYY h:mma' (in America)
 * @example displayDateTime('2021-07-06T15:47:46Z', TimeZone.Pacific) // Jul 6, 2021 3:47pm (PDT)
 */
export function displayDateTime(
  date: dayjs.ConfigType,
  timeZone: string,
  format = DateFormat.DateTimeWithZone,
) {
  timeZone = safeTimeZone(timeZone);
  const dateTimeUTC = dayjs.utc(date);
  const dateTimeWithZone = dayjs.tz(dateTimeUTC, timeZone);
  return dateTimeUTC.isValid() ? dateTimeWithZone.format(format) : '';
}

/**
 * @param format - optional format
 * @returns today's date in the default date format
 * @example displayToday() // Jul 8, 2021
 */
export function displayToday(format = DateFormat.Base) {
  return dayjs().format(format);
}

/**
 * @param format - optional format
 * @returns right now in ISO format with UTC offset
 * @example nowISO() // 2020-04-02T08:02:17+00:00
 */
export function nowISO() {
  const now = dayjs.utc();
  Assert(now.utcOffset() === 0, 'The offset should be 0.');
  return now.format(DateFormat.IsoUtc);
}

/**
 * @param date - any date format that DayJS can convert
 * @param format - optional format
 * @returns ISO formatted date
 * @example formatAsUTC('2021-07-04T21:00:00-07:00') // '2021-07-05T04:00:00+00:00'
 */
export function formatAsUTC(date: dayjs.ConfigType, format?: DateFormat) {
  return dayjs(date).isValid() ? dayjs.tz(date, TimeZone.UTC).format(format) : '';
}

/** gets the current year
 * @returns number
 * @example currentYear() // 2021
 */
export function currentYear() {
  return dayjs().year();
}

export function isTodayOrEarlier(date: Dayjs) {
  return date && date < dayjs().endOf('day');
}
