import React from 'react';

import { displayDate } from '../../../utils/helpers';
import { memberTypes } from '../../../utils/constants';
import { useDSSelector } from '../../../store/reducers';

interface CartTotalProps {
  disabled?: boolean;
  isRenewal?: boolean;
  isReview?: boolean;
  totalCartItems?: number;
  totalCartPrice?: number;
}

const CartTotal = (props: CartTotalProps) => {
  const {
    disabled = false,
    isRenewal = false,
    isReview = false,
    totalCartItems = 0,
    totalCartPrice = 0,
  } = props;
  const calculatedPrice = useDSSelector(state => state.subscriptions.calculatedPrice || 0);
  const memberSubscriptionInfo = useDSSelector(
    state => state.subscriptions.memberSubscriptionInfo || '',
  );
  const currentSubscribedNational = useDSSelector(
    state => state.subscriptions.currentSubscribedNational || [],
  );
  const currentSubscribedCounties = useDSSelector(
    state => state.subscriptions.currentSubscribedCounties || [],
  );
  const currentSubscribedStates = useDSSelector(
    state => state.subscriptions.currentSubscribedStates || [],
  );
  const subscribedCounties = useDSSelector(state => state.subscriptions.subscribedCounties || []);
  const subscribedNational = useDSSelector(state => state.subscriptions.subscribedNational || []);
  const subscribedStates = useDSSelector(state => state.subscriptions.subscribedStates || []);
  const expiryDate = useDSSelector(state => state.subscriptions.expiryDate);

  const oldProducts = [
    ...currentSubscribedCounties,
    ...currentSubscribedStates,
    ...currentSubscribedNational,
  ];
  const newProducts = [...subscribedStates, ...subscribedCounties, ...subscribedNational];
  let distinctProducts = newProducts.filter((item: number) => !oldProducts.includes(item));
  if (distinctProducts.length === 0) distinctProducts = [...newProducts];

  return (
    <>
      <div className='cartTotal clearfix mb-4'>
        <ul className='list-unstyled d-flex'>
          <li></li>
          <li className='mx-2'></li>
          <li>
            <small>Your Current Rate</small>
          </li>
        </ul>
        {!isReview ? (
          <ul className='list-unstyled d-flex'>
            <li>Total</li>
            <li className='firaMedium mx-2'>
              ({subscribedNational.length > 0 ? 1 : totalCartItems} subscriptions)
            </li>
            <li>${totalCartPrice}/year</li>
          </ul>
        ) : null}

        {calculatedPrice &&
        ((memberSubscriptionInfo.memberDetails &&
          memberSubscriptionInfo.memberDetails.memberType !== memberTypes.basicSupplier) ||
          isReview) ? (
          <>
            <ul className='list-unstyled d-flex'>
              <li>{isReview ? 'Total' : 'Payable Amount'}</li>
              <li className='firaMedium mx-2'>({distinctProducts.length} subscriptions)</li>
              <li>${calculatedPrice}</li>
            </ul>
            {!isReview && expiryDate ? (
              <p className='firaRegular'>
                All subscriptions will expire on {displayDate(expiryDate)}.
              </p>
            ) : null}
          </>
        ) : null}
        {isRenewal && disabled ? (
          <p className='text-center firaRegular'>
            You can upgrade cart. Still wants to downgrade subscription? Please contact DemandStar
            Team.
          </p>
        ) : null}
      </div>
    </>
  );
};

export default CartTotal;
