import { atom, selector } from 'recoil';
import { getStatesList } from '../services';

export interface AllStates {
  abbr: string;
  countryId: number;
  id: number;
  name: string;
}

/**
 * Make a service API call to populate a Recoil Selector.
 * TODO: Implement dynamic updates via WebHooks or explicit component re-instantiation.
 */
export const allStatesSelector = selector<AllStates[]>({
  key: 'allStatesSelector',
  get: async () => {
    try {
      const response = await getStatesList();
      return response?.data?.result || [];
    } catch (error: any) {
      throw new Error(`Error in 'getStatesList() for allStatesSelector': ${error.message}`);
    }
  },
});

/**
 * Initialize the allProducts state with the associated API call.
 */
export const allStatesList = atom<AllStates[]>({
  key: 'allStatesList',
  default: allStatesSelector,
});
