import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';

export function getAuthToken(data = {}) {
  return Api.postRequest(Paths.Authorization, data, {
    baseURL: constants.api.authUrl,
  });
}

export function getRefreshToken(data = {}) {
  return Api.postRequestWithAuthentication(Paths.RefreshToken, data, {
    baseURL: constants.api.authUrl,
  });
}

export function validateEmail(data: any = {}) {
  return Api.getRequest(`${Paths.validateEmail}?id=${data.accountId}`, {
    baseURL: constants.api.userUrl,
  });
}
