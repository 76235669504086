import React, { memo, useState } from 'react';
import { Table } from 'reactstrap';
import { Buttons, DeprecatedInput } from '../../../customcontrols';
import { addbidParamType } from '../../../../types/addbid';
import { emailValidation } from '../../../../utils/helpers';

type propsType = {
  validEmailAddress: Array<{ email: string; id: number; isValid: boolean; isIncluded: boolean }>;
  invalidEmailAddress: Array<{
    email: string;
    id: number;
    isValid: boolean;
    isIncluded: boolean;
    companyName: string;
    contactName: string;
  }>;
  showValidAddresses: any;
  addbid: addbidParamType;
  setAddBidDetails?: any;
  saveUploadedSupplimentalSupplier?: any;
  match?: any;
};

const InvalidEmailAddresses = (props: propsType) => {
  const {
    validEmailAddress = [],
    invalidEmailAddress = [],
    showValidAddresses,
    addbid,
    setAddBidDetails,
    saveUploadedSupplimentalSupplier,
  } = props;
  const emailAddress = addbid.emailAddress as {
    id: number;
    email: string;
    isValid: boolean;
    isIncluded: boolean;
    companyName: string;
    contactName: string;
  }[];
  const companyNameExist = invalidEmailAddress.filter(item => item.companyName);
  const contactNameExist = invalidEmailAddress.filter(item => item.contactName);

  const [editId, setEditId] = useState(null as any);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const bidId = props.match.params.bidId;

  const tdWidth = {
    checkBox: {
      width: '40px',
    },
    action: {
      width: '100px',
    },
  };

  const excludeEmailAddress = (id: number) => {
    const excludedData = emailAddress.map(item => {
      if (item.id === id) {
        return {
          ...item,
          isIncluded: false,
        };
      } else return item;
    });
    setAddBidDetails({ emailAddress: excludedData });
  };

  return (
    <>
      {validEmailAddress.length > 0 ? (
        <>
          <Buttons
            data-testid='addbid.supplier.bulkupload.valid.count'
            text={`Valid Suppliers Found: ${validEmailAddress.length}`}
            classNames='bttn-secondary float-right small'
            onClick={showValidAddresses}
          />
        </>
      ) : null}
      {invalidEmailAddress.length > 0 ? (
        <>
          <h5>Invalid Email Addresses</h5>
          <div className='reviewScroll'>
            <Table className='tableHData'>
              <thead>
                <tr>
                  <th>Email Address</th>
                  <th>Company Name</th>
                  <th>Contact Name</th>
                  <th style={tdWidth.action}>Action</th>
                </tr>
              </thead>
              <tbody>
                {invalidEmailAddress.map((item, index) => (
                  <tr key={index} data-testid='addbid.supplier.bulkupload.invalid.detail'>
                    {editId === item.id ? (
                      <>
                        <td>
                          <DeprecatedInput
                            label='Email'
                            type='text'
                            name='updatedEmail'
                            handleChange={(name: string, value: any) => setUpdatedEmail(value)}
                            value={updatedEmail}
                            placeholder='Email Address'
                            maxLength={100}
                          />
                        </td>
                        <td>
                          {item.companyName}
                          {companyNameExist.length === 0 ? (
                            <i className='text-gray100'>Column Skipped</i>
                          ) : null}
                        </td>
                        <td>
                          {item.contactName}
                          {contactNameExist.length === 0 ? (
                            <i className='text-gray100'>Column Skipped</i>
                          ) : null}
                        </td>
                        <td colSpan={3}>
                          <Buttons
                            classNames='bttn-primary small mb-1'
                            text='Save'
                            title='Save'
                            type='button'
                            onClick={() => {
                              const updatedEmailAddress = emailAddress.map(Eitem => {
                                if (Eitem.id === editId) {
                                  return {
                                    ...Eitem,
                                    email: updatedEmail,
                                    isValid: true,
                                  };
                                } else return Eitem;
                              });
                              setAddBidDetails({ emailAddress: updatedEmailAddress });
                              setEditId(null);
                              setUpdatedEmail('');
                            }}
                            disable={updatedEmail && !emailValidation(updatedEmail) ? false : true}
                          />
                          <Buttons
                            classNames='bttn-secondary small'
                            text='Cancel'
                            title='Cancel'
                            type='button'
                            onClick={() => {
                              setEditId(null);
                              setUpdatedEmail('');
                            }}
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          {item.email ? (
                            item.email
                          ) : (
                            <i className='text-gray100'>No email provided</i>
                          )}
                        </td>
                        <td>
                          {item.companyName}
                          {companyNameExist.length === 0 ? (
                            <i className='text-gray100'>Column Skipped</i>
                          ) : null}
                        </td>
                        <td>
                          {item.contactName}
                          {contactNameExist.length === 0 ? (
                            <i className='text-gray100'>Column Skipped</i>
                          ) : null}
                        </td>
                        <td>
                          <i
                            onClick={() => {
                              setEditId(item.id);
                              setUpdatedEmail(item.email);
                            }}
                            className='mdi mdi mdi-square-edit-outline mdi-24px staticLink mr-3'
                          />
                          <i
                            onClick={() => excludeEmailAddress(item.id)}
                            className='mdi mdi-delete-forever-outline mdi-24px staticLink '
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : null}

      <div className='d-flex justify-content-center mt-3'>
        <Buttons
          data-testid='addbid.supplier.bulkupload.saveall.button'
          text='Save'
          classNames='bttn-primary w-50'
          onClick={() => saveUploadedSupplimentalSupplier({ bidId })}
          disable={invalidEmailAddress.length > 0}
        />
      </div>
    </>
  );
};

export default memo(InvalidEmailAddresses);
