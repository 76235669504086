import { useRecoilValueLoadable } from 'recoil';

import { bidResponseDetailsState } from '../../store/recoil/bidResponseState';
import { deprecatedCheckTimezoneCountDown } from '../../utils/helpers';

/**
 * Helper fn to check the status on the currently selected `bidResponseDetailsState`,
 * @returns
 */
export function useBidResponseStatus() {
  const bidResponseDetails = useRecoilValueLoadable(bidResponseDetailsState);
  const bidResponseStatus = deprecatedCheckTimezoneCountDown(
    bidResponseDetails?.contents?.dueDate,
    bidResponseDetails?.contents?.tzfn,
  );
  return { bidResponseStatus };
}
