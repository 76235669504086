import React, { memo, useEffect, useState } from 'react';
import { pageSize } from '../../../utils/constants';
import { checkPaginationCallFn } from '../../../utils/helpers';
import ActivitiesSearch from './search';
import ActivitiesSearchresult from './searchresult';
import ActivitiesList from './list';
import ActivitiesStatus from './status';
import { activitiessearchTypes, activitiesTypes } from '../../../types';

interface propsTypes extends activitiessearchTypes {
  activities: activitiesTypes;
  setBreadcrumb: any;
  getActivitiesWatchs: any;
  setActivitiesDetails: any;
}

function Bidwatch(props: propsTypes) {
  const { setBreadcrumb, activities, getActivitiesWatchs, setActivitiesDetails } = props;
  const {
    activities_watchs,
    watchs_internalLoader,
    activitiesSearch,
    activities_watchs_search,
    bitWatchStatus,
  } = activities;
  const { /*page,*/ result: watchslist, total } = activities_watchs;
  const { /*page,*/ result: watchslist_search, total: total_search } = activities_watchs_search;

  const totalCount = activitiesSearch ? total_search : total;

  useEffect(() => {
    setBreadcrumb({
      page: 'Activities',
      altname: 'Watch',
      title: 'Watch',
      name: 'Watch',
      menuactive: 'activities',
    });
  }, [setBreadcrumb]);

  // Logic for displaying pagination
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const onPagingClick = (value: number) => {
    if (value !== currentPages) {
      if (checkPaginationCallFn(value, listPerPages, watchslist, total)) {
        getActivitiesWatchs({
          page: Math.ceil(value / listPerPages) + 1,
          size: 100,
          pagination: true,
        });
      }
      setCurrentPages(Number(value));
    }
  };

  const onSelectChange = (name: any, value: any) => {
    setActivitiesDetails({ bitWatchStatus: value });
  };

  const finalwatchslist = watchslist.slice(indexOfFirstLists, indexOfLastLists) || [];

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='arrowWrapper'>
            Watch List{totalCount > 1 ? 's' : ''} ({totalCount})
          </h2>
          <ActivitiesStatus
            {...props}
            pagefor='bidwatch'
            onSelectChange={onSelectChange}
            status={bitWatchStatus}
            setCurrentPages={setCurrentPages}
          />
        </div>
      </div>
      <div className='row'>
        <ActivitiesSearch {...props} pagefor='bidwatch' setCurrentPages={setCurrentPages} />
      </div>
      {activitiesSearch ? (
        <ActivitiesSearchresult
          {...props}
          activitieslist={watchslist_search}
          total={total_search}
          pagefor='bidwatch'
        />
      ) : (
        <ActivitiesList
          {...props}
          pagefor='bidwatch'
          InternalLoader={watchs_internalLoader}
          loadermsg='No Quotes Available'
          list={watchslist}
          finallist={finalwatchslist}
          total={total}
          currentPages={currentPages}
          onPagingClick={onPagingClick}
          listPerPages={listPerPages}
        />
      )}
    </>
  );
}

export default memo(Bidwatch);
