import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

/** Useful for changing state through controlled inputs.
 * @param {RecoilState} state
 */
export function useLocalRecoilState<T>(state: RecoilState<T>): [T, Dispatch<SetStateAction<T>>] {
  const [recoilState, setRecoilState] = useRecoilState(state);
  const [localState, setLocalState] = useState(recoilState);

  useEffect(() => {
    setRecoilState(localState);
  }, [localState, setRecoilState]);

  return [localState, setLocalState];
}
