/** DEPRECATED
 * Use Radio component instead.
 **/

import React from 'react';

interface RadioProps {
  defaultChecked?: boolean;
  disabled?: boolean | 'true' | 'false';
  title?: string;
  handleSelect: (name: string, value: string) => void;
  ID?: string;
  value: any;
  subtitle?: string;
  parentClass?: string;
  field: { name: string };
}

export const DeprecatedFormikRadio = (props: RadioProps) => {
  const {
    defaultChecked = false,
    disabled,
    title = '',
    handleSelect = () => {},
    ID,
    value,
    subtitle = '',
    parentClass = '',
    field,
  } = props;
  const { name = '' } = field;

  const handleSelectFn = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    handleSelect(name, value);
  };

  const disable = disabled === true || disabled === 'true' ? true : false;

  return (
    <label className={'radioControl ' + parentClass}>
      {title}
      <p>{subtitle}</p>
      <input
        type='radio'
        onClick={handleSelectFn}
        id={ID}
        key={ID}
        value={value}
        name={name}
        checked={defaultChecked}
        defaultChecked={defaultChecked}
        disabled={disable}
      />
      <span className='checkmark' />
      {/* why is the className 'checkmark' for a radio?*/}
    </label>
  );
};
