import { FontFamily, FontWeight } from '@demandstar/components/styles';
import React, { HTMLProps } from 'react';
import styled from 'styled-components';

import { ActionColor, Colors, FontSize, Padding } from '../../../../shared/styles';
import { SvgIcon } from '../../../../types/shared';

// Defaults
const ButtonStyleDefaults: Partial<ButtonProps> & Partial<CSSStyleDeclaration> = {
  bgColor: Colors.white,
  bgColorActive: ActionColor.secondaryHighlight,
  bgColorDisabled: Colors.grayLight,
  bgColorHover: ActionColor.secondaryHighlight,
  border: `${ActionColor.secondary} solid 1px`,
  borderRadius: '0.25rem',
  fontFamily: `${FontFamily.Header}`,
  fontSize: FontSize.base,
  fontStyle: 'normal',
  fontWeight: FontWeight.Bold,
  lineHeight: FontSize.base,
  textColor: Colors.action,
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: Padding.button,
};

// Functions passed to styled-component string template literals "...will receive the styled component's props as the first and only argument." (https://styled-components.com/docs/api#taggedtemplateliteral)

/**
 * Please excuse the ternary quagmire.
 * @param props: ButtonProps
 */
function getBgColor({ bgColor, bgColorDisabled, disabled }: ButtonProps) {
  return disabled
    ? bgColorDisabled || ButtonStyleDefaults.bgColorDisabled
    : bgColor || ButtonStyleDefaults.bgColor;
}
function getBgColorHover({ bgColorHover }: ButtonProps) {
  return bgColorHover || ButtonStyleDefaults.bgColorHover;
}
function getBgColorActive({ bgColorActive }: ButtonProps) {
  return bgColorActive || ButtonStyleDefaults.bgColorActive;
}
function getTextColor({ textColor }: ButtonProps) {
  return textColor || ButtonStyleDefaults.textColor;
}
function getCursor({ disabled }: ButtonProps) {
  return disabled ? 'not-allowed' : 'pointer';
}
function getPointerEvents({ disabled }: ButtonProps) {
  return disabled ? 'none' : 'all';
}
function getFontFamily({ fontFamily }: ButtonProps) {
  return fontFamily || ButtonStyleDefaults.fontFamily;
}
function getFontSize({ fontSize }: ButtonProps) {
  return fontSize || ButtonStyleDefaults.fontSize;
}

interface ButtonBaseProps extends HTMLProps<HTMLButtonElement> {
  bgColor: string;
  bgColorActive: string;
  bgColorDisabled: string;
  bgColorHover: string;
  disabled: boolean;
  float?: 'left' | 'right' | 'none' | 'inherit';
  fontFamily: string;
  fontSize: string;
  testId: string;
  textColor: string;
  title: string;
  handleClick(): () => void;
}

// Base button types and optional icon.
export type ButtonProps = Partial<ButtonBaseProps> &
  SvgIcon & {
    dataTestId?: string;
  };

/**
 * Styled button base component.
 * This is intended to be overridden via styled components for other implementations
 *   using optional props directly available in `ButtonBaseProps`, or
 *   using ```const MyButton = styled(Button)`...`;
 * TODO: implement theming via `styled-components` ThemeProvider HOC or custom impl.
 * @param props: ButtonProps
 */
export const StyledButton: React.FunctionComponent<ButtonProps> = styled.button<ButtonProps>`
  background-color: ${getBgColor};
  color: ${getTextColor};
  cursor: ${getCursor};
  pointer-events: ${getPointerEvents};
  font-family: ${getFontFamily};
  height: ${ButtonStyleDefaults.height};
  font-size: ${getFontSize};
  font-weight: ${FontWeight.Bold};
  padding: ${ButtonStyleDefaults.padding};
  border: ${ButtonStyleDefaults.border};
  border-radius: ${ButtonStyleDefaults.borderRadius};
  white-space: ${ButtonStyleDefaults.whiteSpace};
  word-break: ${ButtonStyleDefaults.wordBreak};
  text-overflow: ${ButtonStyleDefaults.textOverflow};
  overflow: ${ButtonStyleDefaults.overflow};
  &:hover {
    background-color: ${getBgColorHover};
  }
  &:active {
    background-color: ${getBgColorActive};
  }
  &:disabled {
    background-color: ${Colors.disabledBackground};
    border-color: ${Colors.disabledText};
    color: ${Colors.disabledText};
  }
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
`;

export function Button(props: ButtonProps): JSX.Element {
  // Optional svg icon.
  // TODO: assumed left abstract icon layout position.
  const IconComponent = props.icon;
  return (
    <StyledButton {...props} data-testid={props.dataTestId}>
      {IconComponent && <IconComponent />}
      {props.children}
    </StyledButton>
  );
}
