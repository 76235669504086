import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function loadCampaignStatus(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_CAMPAIGN_STATUS, payload);
}

export function updateCampaignFirstPopup(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_CAMPAIGN_FIRST_POPUP, payload);
}

export function updateCampaignNotNowPopup(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_CAMPAIGN_NOTNOW_POPUP, payload);
}

export function updateCampaignFeedbackPopup(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_CAMPAIGN_FEEDBACK_POPUP, payload);
}

export function setCampaignDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_CAMPAIGN_DETAILS, payload);
}
