import React, { memo } from 'react';
import { Buttons } from '../../../../customcontrols';

interface PropsTypes {
  program: { programId: number; programName: string; programDescription: string };
  programAttributes: Array<{ attributeName: string }>;
  handleCloseClick: () => void;
}

function ProgramCertificationApplicationConf(props: PropsTypes) {
  const { program, programAttributes = [] } = props;
  const handleCloseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.handleCloseClick();
  };
  return (
    <>
      {/* <p>
					Your application has been sent to the agency. You will be informed of your application's
					status after the agency reviews your request.
				</p> */}
      <h6>{program.programName}</h6>
      <p>{program.programDescription}</p>
      <h6>Attributes</h6>
      <ul>
        {programAttributes && programAttributes.length > 0
          ? programAttributes.map((v: { attributeName: string }, i: any) => (
              <li key={i}>{v.attributeName}</li>
            ))
          : 'None'}
      </ul>
      <div className='d-block mt-3'>
        <Buttons
          text='Close'
          classNames='bttn bttn-secondary float-right'
          onClick={handleCloseClick}
        />
      </div>
    </>
  );
}

export default memo(ProgramCertificationApplicationConf);
