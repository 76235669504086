import React from 'react';
import {
  CheckBox,
  DeprecatedInput,
  DeprecatedRadio,
  SelectBox,
  TextArea,
} from '../../customcontrols';
import { DeprecatedReduxDatepicker } from '../../customcontrols/DeprecatedReduxDatepicker';
import ToogleBtn from '../../customcontrols/togglebtn';

// input method and component to redux form
export const renderField = (fieldvalues: any) => {
  const {
    input,
    placeholder,
    label,
    type,
    classNames,
    parentClass,
    meta,
    optional,
    maxLength,
    disabled,
    minDate = '',
    maxDate = '',
    showMaxLength,
  } = fieldvalues;

  return (
    <DeprecatedInput
      input={input}
      parentClass={parentClass}
      placeholder={placeholder}
      label={label}
      type={type}
      meta={meta}
      maxLength={maxLength}
      handleChange={() => {}}
      classNames={classNames}
      reduxform={true}
      optional={optional}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      showMaxLength={showMaxLength}
    />
  );
};

// input method and component to redux form
export const renderDateField = (fieldvalues: any) => {
  const {
    input,
    placeholder,
    label,
    type,
    classNames,
    parentClass,
    meta,
    optional,
    maxLength,
    disabled,
    isOutsideRange,
    showClearDate,
    minDate = '',
    maxDate = '',
  } = fieldvalues;

  return (
    <DeprecatedReduxDatepicker
      input={input}
      parentClass={parentClass}
      placeholder={placeholder}
      minDate={minDate}
      maxDate={maxDate}
      label={label}
      type={type}
      meta={meta}
      showClearDate={showClearDate}
      maxLength={maxLength}
      handleChange={() => {}}
      classNames={classNames}
      isOutsideRange={isOutsideRange}
      reduxform={true}
      optional={optional}
      disabled={disabled}
    />
  );
};

// input text area method and component to redux form
export const renderTextarea = (fieldvalues: any) => {
  const {
    input,
    placeholder,
    label,
    type,
    classNames,
    parentClass,
    meta,
    optional,
    maxLength,
    disabled,
    remainShow,
  } = fieldvalues;
  return (
    <TextArea
      input={input}
      parentClass={parentClass}
      placeholder={placeholder}
      label={label}
      type={type}
      maxLength={maxLength}
      meta={meta}
      handleChange={() => {}}
      classNames={classNames}
      reduxform={true}
      optional={optional}
      disabled={disabled}
      remainShow={remainShow}
    />
  );
};

// select box method and component to redux form
export const renderSelect = (fieldvalues: any) => {
  const {
    input,
    label,
    parentClass,
    meta,
    options,
    disabled,
    placeholder = '',
    optional,
  } = fieldvalues;
  return (
    <SelectBox
      input={input}
      label={label}
      parentClass={parentClass}
      reduxform={true}
      meta={meta}
      search={true}
      clear={false}
      disabled={disabled}
      options={options}
      placeholder={placeholder}
      optional={optional}
    />
  );
};

export const renderCheckbox = (fieldvalues: any) => {
  const {
    input,
    meta,
    title,
    name,
    options,
    label,
    disabled,
    parentClass,
    classNames,
    defaultChecked,
  } = fieldvalues;
  return (
    <CheckBox
      input={input}
      title={title}
      name={name}
      meta={meta}
      options={options}
      label={label}
      classNames={classNames}
      disabled={disabled}
      parentClass={parentClass}
      reduxform={true}
      defaultChecked={defaultChecked}
      addBidForm={true}
    />
  );
};

export const renderToogleBtn = (fieldvalues: any) => {
  const {
    input,
    meta,
    title,
    name,
    options,
    label,
    disabled,
    parentClass,
    classNames,
  } = fieldvalues;
  return (
    <ToogleBtn
      input={input}
      title={title}
      name={name}
      meta={meta}
      options={options}
      label={label}
      classNames={classNames}
      disabled={disabled}
      parentClass={parentClass}
      reduxform={true}
    />
  );
};

// input method and component to redux form
export const renderRadioField = (fieldvalues: any) => {
  const { input, title, type, meta, disabled, defaultChecked, parentClass } = fieldvalues;
  return (
    <DeprecatedRadio
      input={input}
      title={title}
      type={type}
      handleSelect={() => {}}
      reduxform={true}
      disabled={disabled}
      defaultChecked={defaultChecked}
      parentClass={parentClass}
    />
  );
};

// input method and component to redux form
export const renderDisplayField = (fieldvalues: any) => {
  const { field } = fieldvalues;
  const { value } = field;
  return <span>{value}</span>;
};
