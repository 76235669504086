import styled from 'styled-components';

import { Colors, FontWeight } from '../../../../shared/styles';

/**
 * Light gray helper text.
 * @example <Helper>(optional)</Helper>
 * @example <Helper block>Helper text on a separate line</Helper>
 */
interface HelperProps {
  block?: boolean;
}

export const Helper = styled.span<HelperProps>`
  display: ${props => (props.block ? 'block' : 'inline')};
  color: ${Colors.disabledText};
  font-weight: ${FontWeight.normal};
`;
