// TOREFACTOR: Replace text with constants.
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import history from '../../../../utils/history';
import { useDispatch } from 'react-redux';

import {
  BidExternalStatusType,
  bidFullEditableStatus,
  BidInternalStatusType,
  bidNonEditableStatus,
  TourId,
} from '../../../../utils/constants';
import {
  deleteBid,
  getbidsEbidResponse,
  setAddBidDetails,
  setSharedDetails,
  setToursDetails,
} from '../../../../store/actions';

import { AmplitudeBidInfo } from '../../../../types/amplitude';
import { BidSummaryResult } from '../../../../types/bidssummary';
// TODO: there's now a working Recoil/API BidSummary call in the Supplier Required Actions PR
import { Buttons } from '../../../customcontrols';
import { mapAmplitudeCommodities } from '../../../../utils/helpers';
import { ModalPopUp } from '../../modals/ModalPopUp';
import { TourConfig } from '../../../../utils/tourconfig';
import { useAmplitude } from '../../../amplitude';
import { useDSSelector } from '../../../../store/reducers';

export const BidDetailsControl = () => {
  const dispatch = useDispatch();
  const { logEvent } = useAmplitude();

  const authMemberId = useDSSelector(state => state.auth.mi); // memberId
  const broadcastModal = useDSSelector(state => !!state.addbid.broadcastModal);
  const canFinishBid = useDSSelector(state => state.addbid.canFinishBid);
  const documents = useDSSelector(state => state.addbid.documentslist);
  const eBidAlertModal = useDSSelector(state => !!state.addbid.EbidAlertModal); // TODO: Fix casing on this.
  const opi = useDSSelector(state => state.auth.opi);
  const requiredDocuments = useDSSelector(state => state.accountinfo.agencydocs.requiredDocuments);
  const results = useDSSelector(state => state.bidssummary.results as BidSummaryResult);
  const tourList = useDSSelector(state => state.tours.tourList);

  // Amplitude
  const county = useDSSelector(state => state.memberinfo.cu);
  const planholderCount = useDSSelector(state => state.bidssummary.stats.planholders);
  const responseCount = useDSSelector(state => state.ebids.ebidResponse.length);
  const state = useDSSelector(state => state.memberinfo.st);
  const watcherCount = useDSSelector(state => state.bidssummary.watchers.total);

  const {
    agencyName,
    bidName,
    commodities,
    bidID = '',
    eBidding,
    bidExternalStatusType: bEST = BidExternalStatusType.None,
    bidInternalStatusType = BidInternalStatusType.None,
  } = results;
  const bidMemberId = results.memberID;

  const awardBidButtonTour = !!tourList.find(tour => tour.tourId === TourId.awardBidButton);

  const canAward =
    bEST === BidExternalStatusType.RecommendationOfAward ||
    bEST === BidExternalStatusType.UnderEvaluation;

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    /**  We *shouldn't* need this responseCount === 0,
     * and it would likely be better if we didn't have it.
     * But I'm guessing that something in our Redux pipeline
     * forces a reload and calls this infinitely
     * when that part is removed. So it stays for now. */
    if (bidID && responseCount === 0 && canAward) {
      dispatch(setSharedDetails({ internalLoader: true }));
      dispatch(getbidsEbidResponse({ bidId: bidID, memberId: bidMemberId as number }));
    }
  }, [bidID, dispatch, bidMemberId, responseCount, canAward]);

  // triggers the AwardBid tour
  useEffect(() => {
    if (canAward && awardBidButtonTour) {
      const awardBidTour: TourConfig[] = [
        {
          selector: '.award-bid-button',
          content:
            "DemandStar has simplified the Award Bid process. When you're ready to award a bid, just click this button to select the winning supplier, the award amount, and to upload award documents.",
          position: 'left',
        },
      ];
      dispatch(
        setToursDetails({
          tourStart: true,
          tourConfig: awardBidTour,
          tourId: TourId.awardBidButton,
          currentStep: 0,
          internalConfig: false,
          lastStepNextButton: <button>Got it!</button>,
          nextButton: <button>Got it!</button>,
          prevButton: <></>,
          showButtons: true,
          showCloseButton: true,
        }),
      );
    }
    return () => {
      dispatch(
        setToursDetails({
          tourId: 0,
          currentStep: 0,
        }),
      );
    };
  }, [dispatch, awardBidButtonTour, canAward]);

  const ConfirmDeleteComponent = () => {
    // TODO: Replace this with generic confirm modal box.
    return (
      <>
        <p>Are you sure you want to delete?</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Cancel'
          title='Cancel'
          type='button'
          onClick={toggleDeleteModal}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Ok'
          title='Ok'
          type='button'
          onClick={() => dispatch(deleteBid({ bidId: bidID }))}
        />
      </>
    );
  };

  function setBroadcastModal() {
    if (
      eBidding === 'No' &&
      requiredDocuments.length > 0 &&
      requiredDocuments[0].responseItemId !== 0
    ) {
      setEBidAlertModal();
    } else {
      dispatch(setAddBidDetails({ broadcastModal: !broadcastModal }));
    }
  }

  function setEBidAlertModal() {
    dispatch(setAddBidDetails({ EbidAlertModal: !eBidAlertModal }));
  }

  function toggleDeleteModal() {
    setDeleteModal(!deleteModal);
  }

  function editPage(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, pageto?: string) {
    e.preventDefault();
    if (pageto) {
      dispatch(setAddBidDetails({ upcomingBidPageFor: pageto }));
    }
    history.push(`/buyers/bids/${bidID}/edit`);
  }

  function awardPage() {
    logAwardButtonEvent();
    history.push(`/buyers/bids/${bidID}/award`);
  }

  function logAwardButtonEvent() {
    const eventProperties: AmplitudeBidInfo = {
      Agency: agencyName,
      'Bid Name': bidName as string,
      'Commodity Codes': mapAmplitudeCommodities(commodities),
      County: county,
      State: state,
      'Number of Planholders': planholderCount,
      'Number of Responses': responseCount,
      'Number of Watchers': watcherCount,
    };
    logEvent('award process - click award button', eventProperties);
  }

  return (
    <>
      {authMemberId === bidMemberId ? (
        <>
          {bEST === BidExternalStatusType.Upcoming ? (
            <li title='Delete Bid'>
              <span onClick={toggleDeleteModal} className='staticLink'>
                <i className='mdi mdi-delete-forever-outline mr-1' /> Delete Bid
              </span>
            </li>
          ) : null}
          {!bidNonEditableStatus.includes(bEST) ? (
            <>
              {bidFullEditableStatus.includes(bEST) ? (
                <li title='Update Bid'>
                  <span onClick={editPage} className='staticLink'>
                    <i className='mdi mdi-square-edit-outline mr-1' /> Update Bid
                  </span>
                </li>
              ) : (
                <li title='Update Bid'>
                  <UncontrolledDropdown>
                    <DropdownToggle title='Update Bid'>
                      <span className='staticLink'>
                        <i className='mdi mdi-square-edit-outline mr-1' /> Update Bid
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem tag='a' onClick={e => editPage(e, 'Bid Information')}>
                        Update Status
                      </DropdownItem>
                      <DropdownItem tag='a' onClick={e => editPage(e, 'Manage Documents')}>
                        Manage Documents
                      </DropdownItem>
                      {BidExternalStatusType.Active === bEST ? (
                        <DropdownItem
                          tag='a'
                          onClick={() =>
                            dispatch(setAddBidDetails({ additionalBroadcastModal: true }))
                          }
                        >
                          Add Suppliers
                        </DropdownItem>
                      ) : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              )}
            </>
          ) : (
            <>
              {opi ? (
                <li title='Update Bid'>
                  <span onClick={editPage} className='staticLink'>
                    <i className='mdi mdi-square-edit-outline mr-1' /> Update Bid
                  </span>
                </li>
              ) : null}
            </>
          )}
          {documents.length > 0 &&
          bEST === BidExternalStatusType.Upcoming &&
          bidInternalStatusType === BidInternalStatusType.Pending &&
          canFinishBid === true ? (
            <li title='Finish Bid'>
              <span onClick={setBroadcastModal} className='staticLink'>
                <i className='mdi mdi-content-save mr-1' /> Finish Bid
              </span>
            </li>
          ) : null}
          {canAward ? (
            <li title='Award Bid' className='award-bid-button'>
              <span className='staticLink' onClick={awardPage}>
                <i className='mdi mdi-star mr-1' /> Award Bid
              </span>
            </li>
          ) : (
            ''
          )}
        </>
      ) : null}
      <ModalPopUp
        title='Delete Confirmation'
        closeModal={toggleDeleteModal}
        isOpen={deleteModal}
        size='md'
      >
        <ConfirmDeleteComponent />
      </ModalPopUp>
      <ModalPopUp
        title='eBidding Confirmation'
        size='md'
        isOpen={eBidAlertModal}
        closeModal={setEBidAlertModal}
      >
        <p>{'You have opted out of eBidding. Are you sure you want to continue?'}</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Change'
          title='Change'
          type='button'
          onClick={e => {
            setEBidAlertModal();
            editPage(e, 'Bid Information');
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Continue'
          title='Continue'
          type='button'
          onClick={() => {
            setEBidAlertModal();
            dispatch(setAddBidDetails({ broadcastModal: !broadcastModal }));
          }}
        />
      </ModalPopUp>
    </>
  );
};
