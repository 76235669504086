import React, { memo } from 'react';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../../../common/controls/inputs/text';
import renderTextarea from '../../../common/controls/inputs/textarea';
import { Buttons } from '../../../customcontrols';

interface PropsTypes {
  initialValues: any;
  setAccountInfoDetails?: any;
  getSavedLegal?: any;
  match?: any;
  handleSubmit?: any;
  submitting?: any;
  UpdateNewTemplate?: any;
}

const validate = (values: any) => {
  const errors: any = {};
  const optional = ['quoteIntro', 'quoteClose', 'quoteInsurance'];
  const requiredFields = [
    'legalIntro',
    'legalCostInfo',
    'legalDueDateTime',
    'legalAdditional',
    'legalClosing',
  ];

  const required =
    Object.keys(values)
      .filter(items => requiredFields.includes(items) && !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  required.forEach(items => {
    if (!items.value) {
      errors[items.name] = 'Required';
    }
  });

  return errors;
};

const AddNewTemplate = (props: PropsTypes) => {
  const { setAccountInfoDetails, UpdateNewTemplate, initialValues } = props;

  const onSubmitHandle = (values: any) => {
    if (values) {
      UpdateNewTemplate({ fields: values });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitHandle}
      >
        {formikProps => {
          const { handleSubmit, isSubmitting, setFieldValue } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Field
                label='Introductory Text'
                placeholder='Introductory Text'
                name='legalIntro'
                component={renderTextarea}
                onChange={setFieldValue}
              />
              <Field
                label='Cost information'
                placeholder='Cost information'
                name='legalCostInfo'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
              />
              <Field
                label='Due Date/Time Text'
                placeholder='Due Date/Time Text'
                name='legalDueDateTime'
                component={renderTextarea}
                onChange={setFieldValue}
              />
              <Field
                label='Additional Text'
                placeholder='Additional Text'
                name='legalAdditional'
                component={renderTextarea}
                onChange={setFieldValue}
              />
              <Field
                label='Closing Text'
                placeholder='Closing Text'
                name='legalClosing'
                component={renderTextarea}
                onChange={setFieldValue}
              />
              <Field
                label='Quote Introductory Text'
                placeholder='Quote Introductory Text'
                name='quoteIntro'
                component={renderTextarea}
                onChange={setFieldValue}
                optional='Optional'
              />
              <Field
                label='Quote Terms and Conditions'
                placeholder='Quote Terms and Conditions'
                name='quoteClose'
                component={renderTextarea}
                onChange={setFieldValue}
                optional='Optional'
              />
              <Field
                label='Quote Insurance Requirements'
                placeholder='Quote Insurance Requirements'
                name='quoteInsurance'
                component={renderTextarea}
                onChange={setFieldValue}
                optional='Optional'
              />
              <div className='d-block mt-3'>
                <Buttons
                  text='Cancel'
                  title='Cancel'
                  type='button'
                  classNames='bttn bttn-secondary float-left'
                  onClick={() => setAccountInfoDetails({ addTemplateModal: false })}
                />
                <Buttons
                  text='Save Changes'
                  classNames='bttn bttn-primary float-right'
                  type='submit'
                  disable={isSubmitting}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default memo(AddNewTemplate);
