import React, { memo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

interface PropsTypes {
  display?: string;
  navlinks: Array<{ id: string; name: string }>;
  activeTab: string;
  toggle: any;
}

const Navbars = (props: PropsTypes) => {
  const { display, navlinks, activeTab, toggle } = props;

  const showLink = (display: string | undefined, link: string) => {
    if (
      (display === 'No' || display === 'Yes' || display === 'Submit') &&
      (link === 'My eBid Proposal' || link === 'Bidders(0)')
    ) {
      return 'none';
    } else {
      return '';
    }
  };

  return (
    <>
      <Nav tabs>
        {navlinks.map(navlink => (
          <NavItem
            key={navlink.id}
            style={{
              display: showLink(display, navlink.name),
              // this.state.display === ('No' || 'Submit' || 'Yes') &&
              // (navlink.name === 'My eBid Proposal' || navlink.name === 'Bidders(0)')
              // 	? 'none'
              // 	: ''
            }}
          >
            <NavLink
              className={classnames({ active: activeTab === navlink.id })}
              onClick={() => toggle(navlink.id)}
            >
              {navlink.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </>
  );
};

export default memo(Navbars);

/* class Navbars extends Component {
	state = {
		display: this.props.display
	}

	showLink = (display, link) => {
		if (
			(display === 'No' || display === 'Yes' || display === 'Submit') &&
			(link === 'My eBid Proposal' || link === 'Bidders(0)')
		) {
			return 'none'
		} else {
			return ''
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.display !== prevProps.display) {
			this.setState({ display: this.props.display })
		}
	}

	render() {
		//const navLinkItems1 = ['Bids Details', 'Audit Trail', 'My eBid Proposal']
		const navLinks = this.props.navlinks
		let activeTab = this.props.activeTab

		return (
			<>
				<Nav tabs>
					{navLinks.map(navlink => (
						<NavItem
							key={navlink.id}
							style={{
								display: this.showLink(this.state.display, navlink.name)
								// this.state.display === ('No' || 'Submit' || 'Yes') &&
								// (navlink.name === 'My eBid Proposal' || navlink.name === 'Bidders(0)')
								// 	? 'none'
								// 	: ''
							}}>
							<NavLink
								className={classnames({ active: activeTab === navlink.id })}
								onClick={() => this.props.toggle(navlink.id)}>
								{navlink.name}
							</NavLink>
						</NavItem>
					))}
				</Nav>
			</>
		)
	}
}
 */
