import Configurations from '../settings';

export const constants = {
  appInsightToken: Configurations.REACT_APP_APP_INSIGHTS_KEY, //'c1045fb5-0535-4666-8490-b7d6423bdd04',
  amplitudeToken: Configurations.AMPLITUDE_API_KEY,
  gaToken: Configurations.REACT_APP_APP_GA, //'UA-143522261-2',
  gtmToken: Configurations.REACT_APP_APP_GTM,
  api: {
    url: Configurations.REACT_APP_API_URL,
    authUrl: Configurations.REACT_APP_API_AUTHURL,
    documentUrl: Configurations.REACT_APP_API_DOCUMENTURL,
    userUrl: Configurations.REACT_APP_API_USERURL,
    subscriptionUrl: Configurations.REACT_APP_API_SUBSCRIPTIONURL,
    trackUrl: Configurations.REACT_APP_API_TRACKURL,
    agencyUrl: Configurations.REACT_APP_API_AGENCY_URL,
  },
  contentBlobUrl: Configurations.REACT_APP_CONTENT_BLOB_URL,
  networkUrl: Configurations.REACT_APP_NETWORK_URL,
  imageCdn: Configurations.REACT_APP_IMAGES_CDN,
  cookieDomain: Configurations.COOKIE_DOMAIN,
};
