import { atom, selector } from 'recoil';

import { defaultRequiredAction } from '../../../shared/constants';
import { RequiredAction } from '../../../types/required-action';

/**
 * Track the selected item for routing.
 */
export const selectedRequiredActionState = atom<RequiredAction>({
  key: 'selectedRequiredActionState',
  default: defaultRequiredAction,
});

/**
 * Track the selected item for routing.
 */
export const selectedRequiredActionIdState = atom<string>({
  key: 'selectedRequiredActionIdState',
  default: '',
});

/**
 * Required Actions list.
 */
export const requiredActionListState = atom<RequiredAction[]>({
  key: 'requiredActionListState',
  default: [],
});

/**
 * Required Actions loading flag.
 */
export const requiredActionsLoadingState = atom<boolean>({
  key: 'requiredActionsLoadingState',
  default: false,
});

/**
 * List of incomplete Required Actions.
 */
export const requiredActionIncompleteListState = selector<RequiredAction[]>({
  key: 'requiredActionIncompleteListState',
  get: ({ get }) => {
    return get(requiredActionListState).filter(({ status }) => status !== 'Complete');
  },
});
