import { Cell, Column } from 'react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Assert, convertResponseToRequiredDoc, isTodayOrEarlier } from '../../../../utils/helpers';
import { BoldText, SpanSpacerLeft, SpanSpacerRight } from '../../../../shared/styles';
import {
  commonFieldNames,
  commonLabels,
  ControlLabels,
  documentLabels,
} from '../../../../shared/constants';
import { DocumentDownloadType, RequiredDocumentResponseGetAPIResponse } from '../../../../types';
import { downloadResponseDocument, RequiredDocument } from '../../../../store/services';
import { Id, ReadOnly } from '../../../../types/shared';
import { isDefined, noOpFunction } from '../../../../utils';
import {
  requiredDocumentResponsesState,
  selectedBidAwardeeState,
  selectedRequiredDocumentState,
} from '../../../../store/recoil/award-bid';
import { TableBase, TableRowSelectionProps, TableStyleWrapper } from '../../../common/table';
import { useSupplierMemberId, useSelectedMemberType } from '../../../../shared/hooks/useMemberInfo';

import { useRequiredDocumentResponses, useRequiredDocuments } from '../../../../shared/hooks';

import { ConfirmModal } from '../../../common/modals/ConfirmModal';
import { DateCell } from '../../../common/table/renderers';
import { DatePicker } from '../../../fields/date-picker/DatePicker';
import { DeleteIconCell } from '../../../common/table/renderers/DeleteIconCell';
import { deleteRequiredDocumentResponseFromService } from '../../../../shared/hooks/award-bid/required-document-response';
import { documentDownloadInProgressState } from '../../../../store/recoil/documentState';
import { DownloadLinkTextCell } from '../../../common/table/renderers/DownloadLinkTextCell';
import { requiredDocLabels } from '../add-vendor-docs/constants';
import { RequiredDocumentStatusCell } from '../../../common/table/renderers/RequiredDocumentStatusCell';
import { track } from '../../../../utils/telemetry';
import { useRequiredActions } from '../../../../shared/hooks/award-bid/useRequiredActions';
import { useResetOnUnmount } from '../../../../shared/hooks/useResetOnUnmount';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';

interface RequiredDocumentResponsesTableProps extends ReadOnly {
  deleteEnabled?: boolean;
  editEnabled?: boolean;
  showSingleRow?: boolean;
}

type RequiredDocumentTableRowSelectionProps = TableRowSelectionProps & { showSingleRow?: boolean };

/**
 * Table for the display of Required Documents for all vendors in the Award Bid process
 * @param props: RequiredDocumentResponsesTableProps
 * @returns
 */
export function RequiredDocumentResponsesTable({
  deleteEnabled,
  editEnabled,
  showSingleRow, // Shows a single entry by id
}: RequiredDocumentResponsesTableProps) {
  // Hooks //
  const {
    refreshRequiredDocumentResponses,
    requiredDocumentResponses,
  } = useRequiredDocumentResponses();
  const { deleteRequiredDoc, updateRequiredDocs } = useRequiredDocuments();
  const selectedBidAwardee = useRecoilValue(selectedBidAwardeeState);

  useResetOnUnmount(requiredDocumentResponsesState);

  // Required Actions
  const { refreshRequiredActions, selectedRequiredActionId } = useRequiredActions();

  /// Application state

  const { selectedBidId } = useSelectedBidId();
  const { supplierMemberId } = useSupplierMemberId();

  const { selectedMemberIsAgency } = useSelectedMemberType();

  const [selectedRequiredDocument, setSelectedRequiredDocument] = useRecoilState(
    selectedRequiredDocumentState,
  );
  const resetSelectedRequiredDocument = useResetRecoilState(selectedRequiredDocumentState);
  const [documentDownloading, setDocumentDownloading] = useRecoilState(
    documentDownloadInProgressState,
  );

  // Local state //
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteResponseModalIsOpen, setDeleteResponseModalIsOpen] = useState(false);

  const showDelete = deleteEnabled || showSingleRow;

  // Callbacks //
  const handleDownloadLinkClick = useCallback(
    async ({
      bidId,
      docId,
      type,
    }: {
      bidId: number;
      docId: number;
      type: DocumentDownloadType;
    }) => {
      if (!documentDownloading) {
        setDocumentDownloading(true);
        await downloadResponseDocument({ docId, id: bidId, type });
        setDocumentDownloading(false);
      }
    },
    [documentDownloading, setDocumentDownloading],
  );

  /** Determine delete handler by member type:
   * Agencies can delete Required Docs
   * Suppliers can delete Required Doc Responses */
  const deleteRowHandler = selectedMemberIsAgency() ? showDeleteDocModal : showDeleteResponseModal;

  // react-table configuration
  const columnConfig = getColumns({
    showDelete,
    handleDeleteRowClick: deleteRowHandler,
    handleDownloadClick: handleDownloadLinkClick,
    updateRow: noOpFunction,
  });

  /** For `showSingleRow` display, filter `requiredDocs` to just the selected RequiredDoc. */
  const filteredRequiredDocs = !showSingleRow
    ? requiredDocumentResponses
    : requiredDocumentResponses.filter(
        doc => doc.bidAwardRequiredDocId === selectedRequiredDocument.bidAwardRequiredDocId,
      );

  function handleDueDateChange(id: number, value = '') {
    if (!value) {
      return;
    }
    const selectedDocResponse = requiredDocumentResponses.find(doc => {
      return doc.bidAwardRequiredDocId === id;
    });
    if (selectedDocResponse) {
      const { bidAwardRequiredDocId, docTitle, isDelete } = selectedDocResponse;
      const updatedRequiredDoc: RequiredDocument = {
        dueDate: value,
        bidAwardRequiredDocId,
        docName: '',
        docTitle,
        docDescription: '',
        isDelete,
      };
      updateRequiredDocs([updatedRequiredDoc]);
    }
  }

  // Functions //

  /** Column configurations */
  function getColumns({
    handleDeleteRowClick,
    handleDownloadClick,
    showDelete,
  }: RequiredDocumentTableRowSelectionProps) {
    const handleDeleteClick = handleDeleteRowClick || noOpFunction;
    const handleDownloadLinkClick = handleDownloadClick || noOpFunction;
    const deleteIdField = selectedMemberIsAgency()
      ? 'bidAwardRequiredDocId'
      : 'bidAwardRequiredDocResponseId';

    const columns: Column<RequiredDocumentResponseGetAPIResponse>[] = [
      {
        accessor: 'docName',
        Cell: DownloadLinkTextCell({
          bidIdField: 'bidId',
          docDownloadType: 'Bid',
          docIdField: 'bidAwardRequiredDocId',
          downloadDisabled: documentDownloading,
          downloadIdField: 'bidDocId',
          onClick: handleDownloadLinkClick,
          labelField: 'docName',
        }),
        Header: requiredDocLabels.responseDocumentTitle,
        minWidth: 300,
      },
      {
        accessor: 'docTitle',
        Header: documentLabels.docType,
        minWidth: 300,
      },
      {
        accessor: 'dueDate',
        Cell: editEnabled
          ? (cellData: Cell<RequiredDocumentResponseGetAPIResponse>) => {
              const requiredDoc = { ...cellData.row.original };
              return (
                <DatePicker
                  name=''
                  onChange={value => {
                    handleDueDateChange(requiredDoc?.bidAwardRequiredDocId, value || '');
                  }}
                  disabledDate={isTodayOrEarlier}
                  allowClear={false}
                  value={requiredDoc.dueDate}
                  fullWidth
                />
              );
            }
          : DateCell<RequiredDocumentResponseGetAPIResponse>({
              dateField: 'dueDate',
            }),
        Header: commonFieldNames.dueDate,
        minWidth: 200,
      },
      {
        Header: commonFieldNames.status,
        accessor: 'bidAwardRequiredDocResponseId',
        Cell: RequiredDocumentStatusCell,
        minWidth: 200,
      },
    ];

    if (showDelete) {
      columns.push({
        Header: '',
        accessor: 'isDelete',
        Cell: DeleteIconCell<number, RequiredDocumentResponseGetAPIResponse>({
          idField: deleteIdField,
          labelField: 'docTitle',
          handleClick: handleDeleteClick,
        }),
        maxWidth: 30,
        disableSortBy: true,
      });
    }

    return columns;
  }

  /** Delete row */

  function hideDeleteModal() {
    setDeleteModalIsOpen(false);
    resetSelectedRequiredDocument();
  }

  function hideDeleteResponseModal() {
    setDeleteResponseModalIsOpen(false);
    resetSelectedRequiredDocument();
  }

  function showDeleteDocModal({ id }: Id<number>) {
    const selectedDoc = requiredDocumentResponses.find(doc => {
      return doc?.bidAwardRequiredDocId === id;
    });
    Assert(
      !!selectedDoc,
      'Expected: `selectedDoc` to exist in requiredDocuments (for reqDoc - delete)',
      'src/components/buyer/awardbid/add-vendor-docs/RequiredDocumentResponsesTable.tsx',
    );

    if (selectedDoc) {
      setSelectedRequiredDocument({ ...selectedDoc, isDelete: false });
      setDeleteModalIsOpen(true);
    } else {
      deleteRequiredDocument();
    }
  }

  function showDeleteResponseModal({ id }: Id<number>) {
    const selectedDoc = requiredDocumentResponses.find(doc => {
      return doc.bidAwardRequiredDocResponseId > 0 && doc.bidAwardRequiredDocResponseId === id;
    });
    Assert(
      isDefined(selectedDoc),
      'Expected: `selectedDoc` to exist in requiredDocuments (for reqDocResponse - delete)',
      'src/components/buyer/awardbid/add-vendor-docs/RequiredDocumentResponsesTable.tsx',
    );
    if (selectedDoc) {
      setSelectedRequiredDocument({ ...selectedDoc, isDelete: false });
      setDeleteResponseModalIsOpen(true);
    }
  }

  async function deleteRequiredDocument() {
    if (selectedRequiredDocument) {
      await deleteRequiredDoc(convertResponseToRequiredDoc(selectedRequiredDocument));
    }
    await refreshRequiredActions();
    hideDeleteModal();
  }

  async function deleteRequiredDocResponse() {
    try {
      const deleteResponse = await deleteRequiredDocumentResponseFromService({
        bidId: selectedBidId,
        bidAwardRequiredDocResponseId: selectedRequiredDocument.bidAwardRequiredDocResponseId,
        isDelete: true,
        memberId: supplierMemberId,
      });

      // refresh the requiredDoc state.
      if (deleteResponse?.bidAwardRequiredDocId === 0) {
        await refreshRequiredDocumentResponses();
      }

      // Refresh the view with the updated required Actions.
      // TODO: move deletion handler to a property and/or create separate table components.
      await refreshRequiredActions();
    } catch (error) {
      track('RequiredDocumentResponsesTable -> deleteRequiredDocResponse() ERROR:', {
        error,
        errorMessage: (error as any).message,
      });
      console.error(
        `RequiredDocumentResponsesTable -> deleteRequiredDocResponse() ERROR: \n${error}`,
      );
    }

    hideDeleteResponseModal();
  }

  function getConfirmDeleteMessageComponent() {
    const docDisplayName = selectedRequiredDocument
      ? selectedRequiredDocument.docTitle
      : 'this document';
    return (
      <>
        <SpanSpacerRight>{ControlLabels.confirmDeletePrefix}</SpanSpacerRight>
        <BoldText>{docDisplayName}</BoldText>
        <SpanSpacerLeft>{'?'}</SpanSpacerLeft>
      </>
    );
  }

  function getConfirmDeleteMessageResponseComponent() {
    const docDisplayName = selectedRequiredDocument
      ? `the response for ${selectedRequiredDocument.docName}`
      : 'the response for this document';
    return (
      <>
        <SpanSpacerRight>{ControlLabels.confirmDeletePrefix}</SpanSpacerRight>
        <BoldText>{docDisplayName}</BoldText>
        <SpanSpacerLeft>{'?'}</SpanSpacerLeft>
      </>
    );
  }

  // Effects //
  useEffect(() => {
    if (supplierMemberId > 0) {
      refreshRequiredDocumentResponses();
    }
  }, [
    refreshRequiredDocumentResponses,
    selectedBidAwardee,
    selectedBidId,
    supplierMemberId,
    selectedRequiredActionId,
  ]);

  return (
    <>
      <TableStyleWrapper>
        <TableBase columns={columnConfig} data={filteredRequiredDocs} />
      </TableStyleWrapper>

      {/* Delete Required Document confirmation modal */}
      <ConfirmModal
        confirmMessage={getConfirmDeleteMessageComponent()}
        approveAction={deleteRequiredDocument}
        title={`${commonLabels.confirmDelete} `}
        closeModal={hideDeleteModal}
        isOpen={deleteModalIsOpen}
        size='md'
      />

      {/* Delete Required Document Response confirmation modal */}
      <ConfirmModal
        confirmMessage={getConfirmDeleteMessageResponseComponent()}
        approveAction={deleteRequiredDocResponse}
        title={`${commonLabels.confirmDelete} `}
        closeModal={hideDeleteResponseModal}
        isOpen={deleteResponseModalIsOpen}
      />
    </>
  );
}
