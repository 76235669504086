import { atom } from 'recoil';
import { AgencyStateCounty } from '../../types/agencyinfo';
import { ProductApiResponse } from '../../types/products';

export interface productMinPrice {
  countyPrice: number;
  statePrice: number;
  nationalPrice: number;
}

export const agencyStateCountyState = atom<AgencyStateCounty>({
  key: 'agencyStateCounty',
  default: { county: '', memberId: 0, state: '' },
});

export const productPrices = atom<productMinPrice>({
  key: 'productPrices',
  default: { countyPrice: 0, statePrice: 0, nationalPrice: 0 },
});

export const agenciesByProductState = atom<ProductApiResponse[]>({
  key: 'agenciesByProduct',
  default: [],
});
