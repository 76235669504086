import React, { memo, useEffect } from 'react';
import { Field, Formik } from 'formik';
import AddBidWizardButtons from './AddBidWizardButtons';
import renderTextAreaField from '../controls/inputs/textarea';
import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import { addbidParamType } from '../../../types/addbid';
import { WizardPage } from '../../../types/wizard';

interface PropsTypes {
  handleSubmit?: any;
  addbid?: addbidParamType;
  valid?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  setAddBidDetails?: any;
  pristine?: Record<string, unknown> | undefined;
  currentwizard?: WizardPage;
  bidId?: number;
  setBreadcrumb?: any;
  submitAddBidUpdateLegal?: any;
  getAddBidLegal?: any;
  currentBidName?: string;
  SkipNext?: any;
  initialValues: any;
}

function LegalAds(props: PropsTypes) {
  const {
    addbid,
    valid,
    submitting,
    setAddBidDetails,
    pristine,
    currentwizard,
    bidId,
    setBreadcrumb,
    submitAddBidUpdateLegal,
    getAddBidLegal,
    currentBidName,
    SkipNext,
    initialValues,
  } = props;
  const { editable } = addbid || {};

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  useEffect(() => {
    if (bidId) getAddBidLegal({ bidId });
  }, [bidId, getAddBidLegal]);

  useEffect(() => {
    if (currentwizard) {
      setBreadcrumb({
        component: (
          <AddBidControl
            valid={valid}
            pristine={pristine}
            submitting={submitting}
            bidId={bidId}
            pristineOpt={true}
          />
        ),
        page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
        title: currentBidName
          ? `Bid Update - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        altname: currentBidName
          ? `Bid Update - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        name: currentBidName ? currentBidName : 'Bid Creation',
        id: bidId,
        menuactive: 'bids',
      });
    }
  }, [bidId, currentBidName, currentwizard, pristine, setBreadcrumb, submitting, valid]);

  const onSubmitFn = (data: any) => {
    if (data) {
      if (editable === 'all') {
        submitAddBidUpdateLegal({ bidId, data });
      } else {
        SkipNext();
      }
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitFn}>
      {formikProps => {
        const { values: any, handleSubmit, setFieldValue } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='row'>
                  <div className='col-md-9 col-lg-10 col-xl-8'>
                    <div className='innerColIndent'>
                      <Field
                        label='Introductory Text'
                        classNames='class'
                        placeholder='Notes'
                        parentClass='formItemSpace'
                        name='legalAdIntro'
                        component={renderTextAreaField}
                        disabled={editable !== 'all' ? true : false}
                        remainShow={true}
                        maxLength={1024}
                        optional={true}
                        onChange={setFieldValue}
                      />
                      <Field
                        label='Cost Information'
                        classNames='class'
                        placeholder='Notes'
                        parentClass='formItemSpace'
                        name='legalAdCostInfo'
                        component={renderTextAreaField}
                        disabled={editable !== 'all' ? true : false}
                        remainShow={true}
                        maxLength={1024}
                        optional={true}
                        onChange={setFieldValue}
                      />
                      <Field
                        label='Due Date/Time'
                        classNames='class'
                        placeholder='Notes'
                        parentClass='formItemSpace'
                        name='legalAdDueDateTime'
                        component={renderTextAreaField}
                        disabled={editable !== 'all' ? true : false}
                        remainShow={true}
                        maxLength={1024}
                        optional={true}
                        onChange={setFieldValue}
                      />
                      <Field
                        label='Additional Text'
                        classNames='class'
                        placeholder='Notes'
                        parentClass='formItemSpace'
                        name='legalAdAdditional'
                        component={renderTextAreaField}
                        disabled={editable !== 'all' ? true : false}
                        remainShow={true}
                        maxLength={1024}
                        optional={true}
                        onChange={setFieldValue}
                      />
                      <Field
                        label='Closing Text'
                        placeholder='Closing Text'
                        name='legalAdClosing'
                        component={renderTextAreaField}
                        onChange={setFieldValue}
                        disabled={editable !== 'all' ? true : false}
                        remainShow={true}
                        maxLength={1024}
                        optional={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddBidWizardButtons {...props} />
          </form>
        );
      }}
    </Formik>
  );
}

export default memo(LegalAds);
