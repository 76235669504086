import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { pageSize, sortDirections } from '../../../../utils/constants';
import { loadingMsg } from '../../../../utils/texts';
import { deprecatedGetDateTimeZoneConvertedDate } from '../../../../utils/helpers';
import SortingIcon from '../../../common/sortingIcon';
import { Paging } from '../../../customcontrols';
import NoResult from '../../../customcontrols/noresult';
import { bidssummaryParamType } from '../../../../types/biddetail';
import { MemberInfoState } from '../../../../store/reducers/memberinfo';

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  setBidSummaryDetails?: any;
  memberinfo?: MemberInfoState;
}

const Watchers = (props: PropsTypes) => {
  const { setBidSummaryDetails, bidssummary, memberinfo } = props;
  const { watchers, watchers_internalLoader } = bidssummary || {};
  const { result } = watchers || {};
  const { tzfn } = memberinfo || {};

  const watcherslist = result;

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  //Logic for sort
  const [sortvalue, setSortvalue] = useState('supplierName');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);

  useEffect(() => {
    return () => {
      setSortvalue('supplierName');
      setSortorder(sortDirections.ASC);
    };
  }, []);

  return (
    <>
      <div className='clearfix'>
        <h3 className='mb-3 arrowWrapper' data-testid='biddetails.watchers.head'>
          Watchers List
        </h3>
      </div>
      {watcherslist && watcherslist.length > 0 ? (
        <>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='innerColIndent'>
                <Table size='sm' className='tableHData'>
                  <tbody>
                    <tr data-testid='biddetails.watchers.listhead'>
                      <th>
                        Supplier
                        <SortingIcon
                          sortlist={watcherslist}
                          initialsortorder={sortorder}
                          currentsortname={sortvalue}
                          changesortname={(value: string) => setSortvalue(value)}
                          sortname='supplierName'
                          changeSortingFn={(list: any) =>
                            setBidSummaryDetails({ watchers: { ...watchers, result: list } })
                          }
                        />
                      </th>
                      <th>
                        Contact Person
                        <SortingIcon
                          sortlist={watcherslist}
                          initialsortorder={sortorder}
                          currentsortname={sortvalue}
                          changesortname={(value: string) => setSortvalue(value)}
                          sortname='contactPerson'
                          changeSortingFn={(list: any) =>
                            setBidSummaryDetails({ watchers: { ...watchers, result: list } })
                          }
                        />
                      </th>
                      <th>City, State</th>
                      <th>
                        Watch Date
                        <SortingIcon
                          sortlist={watcherslist}
                          initialsortorder={sortorder}
                          currentsortname={sortvalue}
                          changesortname={(value: string) => setSortvalue(value)}
                          sortname='watchDate'
                          changeSortingFn={(list: any) =>
                            setBidSummaryDetails({ watchers: { ...watchers, result: list } })
                          }
                        />
                      </th>
                      <th>
                        Status
                        <SortingIcon
                          sortlist={watcherslist}
                          initialsortorder={sortorder}
                          currentsortname={sortvalue}
                          changesortname={(value: string) => setSortvalue(value)}
                          sortname='watchStatus'
                          changeSortingFn={(list: any) =>
                            setBidSummaryDetails({ watchers: { ...watchers, result: list } })
                          }
                        />
                      </th>
                    </tr>
                    {watcherslist
                      .map((item, index) => (
                        <tr key={index} data-testid='biddetails.watchers.listbody'>
                          <td>{item.supplierName}</td>
                          <td>{item.contactPerson}</td>
                          <td>
                            {item.city && item.state
                              ? `${item.city}, ${item.state}`
                              : item.city
                              ? `${item.city}`
                              : item.state
                              ? `${item.state}`
                              : ''}
                          </td>
                          <td>{deprecatedGetDateTimeZoneConvertedDate(item.watchDate, tzfn)}</td>
                          <td>{item.watchStatus}</td>
                        </tr>
                      ))
                      .slice(indexOfFirstList, indexOfLastList)}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoResult message={watchers_internalLoader ? loadingMsg : 'No Watchers Available'} />
      )}
      <Paging
        totalRecords={watcherslist && watcherslist.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
};

export default memo(Watchers);
