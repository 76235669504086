import React from 'react';

import { BidDetailsLink } from '../../../common/controls/text/links/BidDetailsLink';
import { bidSummaryState } from '../../../../store/recoil/bidState';
import { defaultNumericId } from '../../../../shared/constants';
import { EmailInfo } from '../../../../types/awardBid';
import { Label } from '../../../common/controls/text/Label';
import { MessagePreviewRow } from '../styled/AwardBidStyled';
import { notifyAwardeeTexts } from '../../../../utils/texts/agency/awardBid';
import { useRecoilValue } from 'recoil';

interface BidEmailPreviewProps {
  emailInfo: EmailInfo;
  addressee: string;
}

export const BidEmailPreview = (props: BidEmailPreviewProps) => {
  const { addressee, emailInfo } = props;
  const bidSummary = useRecoilValue(bidSummaryState);
  const { agencyName, bidWriter, bidNumber, bidName = '', bidID = defaultNumericId } =
    bidSummary || {};

  return (
    bidSummary && (
      <>
        <p>
          {emailInfo.introduction} {addressee},
        </p>
        <p>{emailInfo.customMessage}</p>
        <BidDetailsLink bidId={bidID}>{bidName}</BidDetailsLink>
        <MessagePreviewRow>
          <Label>{notifyAwardeeTexts.labels.agency}:</Label>
          <span>{agencyName}</span>
        </MessagePreviewRow>
        <MessagePreviewRow>
          <Label>{notifyAwardeeTexts.labels.bidWriter}:</Label>
          <span>{bidWriter}</span>
        </MessagePreviewRow>
        <MessagePreviewRow>
          <Label>{notifyAwardeeTexts.labels.bidNumber}:</Label>
          <span>{bidNumber}</span>
        </MessagePreviewRow>
      </>
    )
  );
};
