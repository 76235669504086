// TODO: Delete this. Use library instead.

export enum Margin {
  base = '0.5rem',
  extra = '1rem',
}

export enum Padding {
  base = '0.5rem',
  button = '0.5rem 1rem',
  extra = '1.5rem',
}

export enum Width {
  input = '360px',
  full = '100%',
}

export enum FontWeight {
  bold = '700',
  header = '500',
  normal = '400',
}

export enum FontSize {
  small = '0.9rem',
  base = '1rem',
  header = '1.125rem',
}
