import React, { memo } from 'react';
import { commodityItemsParamType } from '../../../types/commoditycodes';

interface PropsTypes {
  listings?: commodityItemsParamType[];
  SetSelectedCommodity?: any;
  assignedClass?: string;
  reBroadcast?: boolean;
}

const CommodityListItem = (props: PropsTypes) => {
  const { listings = [], SetSelectedCommodity, assignedClass = '', reBroadcast = false } = props;

  return (
    <div className={`innerColIndent ${assignedClass}`}>
      {listings.map((code, index) => (
        <div key={index} className='selectedcommodity'>
          <div className='commodityinfo'>
            <p>
              {code.commodityDescription}, [{code.formattedCode}]
            </p>
          </div>
          {reBroadcast === false ? (
            <div className='deletecommodity'>
              <i
                className='mdi float-right mdi-delete-forever-outline'
                onClick={() =>
                  SetSelectedCommodity(
                    false,
                    code,
                    code.commodityCode !== '00' ? 'commodityCodes' : 'commodityCategory',
                  )
                }
              />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default memo(CommodityListItem);
