import React, { memo, useCallback, useEffect, useState } from 'react';
import { useForceUpdate } from '../../../../utils/helperHooks';
import Commoditylist from '../../commoditycodeedit/commoditylist';
import CommoditySearch from '../../commoditycodeedit/commoditysearch';
import Commodityselected from '../../commoditycodeedit/commodityselected';
import Filtersuppliearlist from '../filtersuppliearlist';
import { Buttons, DeprecatedInput } from '../../../customcontrols';
import { ModalPopUp } from '../../modals/ModalPopUp';
import ConfirmCommodity from './confirmcommodity';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { IsIEFn, Searchingfn } from '../../../../utils/helpers';
import { commodityParamType } from '../../../../types/commoditycodes';
import { addbidParamType } from '../../../../types/addbid';

interface PropsTypes {
  getCommodityCodes?: any;
  setCommodityCodes?: any;
  setSelectedCommodityCodes?: any;
  commoditycodes?: commodityParamType;
  Setnewlistmodal?: any;
  setAddBidDetails?: any;
  ListComponent?: any;
  submitAddBidIsFilterStatus?: any;
  setCommodityFilters?: any;
  bidId?: number;
  addbid?: addbidParamType;
  pagefor?: string;
  reBroadcast?: boolean;
  quotesId?: number | string;
  setCurrentPages?: any;
  checkedit?: any;
}

function GenerateNewList(props: PropsTypes) {
  const forceUpdate = useForceUpdate();
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const {
    getCommodityCodes,
    setCommodityCodes,
    setSelectedCommodityCodes,
    commoditycodes,
    Setnewlistmodal = () => {},
    setAddBidDetails,
    ListComponent,
    submitAddBidIsFilterStatus,
    setCommodityFilters,
    bidId,
    addbid,
    pagefor,
    reBroadcast = false,
    quotesId,
    setCurrentPages,
  } = props;
  const {
    broadcastListssearchShow,
    broadcastListssearch = [],
    confirmChanges = false,
    actionType = '',
    confirmReBroadcast = false,
  } = addbid || {};
  const { commoditieslist = [] } = commoditycodes || {};

  const newSuppliersCount = broadcastListssearch.filter(item => item.broadcasted === false).length;

  const id = pagefor === 'addquotes' ? quotesId : bidId;
  const type = pagefor === 'addquotes' ? 'QuoteWire' : 'BidWire';

  const selectedCommodities =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => item.isExisting && item.isSelected && item.isDisplay)) ||
    [];
  /*TOREFACTOR: The difference between these two is that one is isExisting
      and the other is not. Perhaps there's a better name?
      Suggestion: nonExistingSelectedCommodities */
  const newSelectedCommodities =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => !item.isExisting && item.isSelected && item.isDisplay)) ||
    [];

  const [localCommodity, setLocalCommodity] = useState({
    value: '',
    category: '',
    name: '',
    confirmmodal: false,
  });

  const GoBackToPage = useCallback(() => {
    Setnewlistmodal();
  }, [Setnewlistmodal]);

  useEffect(() => {
    let getCommodityCodesAPICALL = true;
    if (getCommodityCodesAPICALL && commoditieslist.length === 0) {
      setCommodityFilters({ pagefor });
      getCommodityCodes({
        pagefor: pagefor,
        clearselected: quotesId || bidId ? true : false,
        reBroadcast,
      });
    }
    return () => (getCommodityCodesAPICALL = false) as any;
  }, [
    bidId,
    commoditieslist.length,
    getCommodityCodes,
    pagefor,
    quotesId,
    setCommodityFilters,
    reBroadcast,
  ]);

  useEffect(() => {
    return () => {
      setAddBidDetails({
        savebroadcastlist: '',
        // broadcastFilter: initialFilter,
      });
      if (!reBroadcast) {
        setAddBidDetails({
          broadcastListssearchShow: false,
          broadcastListssearch: [],
        });
      }
      setCommodityCodes({ searchedCommodityCodes: [], searchText: '' });
    };
  }, [setAddBidDetails, setCommodityCodes, reBroadcast]);

  const searchCommodityCodes = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (searchText.length >= 3) {
      setShowSearch(true);
      const searchresult = Searchingfn(
        commoditieslist,
        ['commodityDescription', 'formattedCode'],
        searchText,
      );
      setCommodityCodes({ searchedCommodityCodes: searchresult });
    }
    if (!searchText || searchText.length === 0) {
      setShowSearch(false);
      setCommodityCodes({ searchedCommodityCodes: [] });
    }
  };

  const onChangeSearchCommodityCodes = (value: string) => {
    setSearchText(value);
    if (value.length >= 3) {
      setShowSearch(true);
      setCommodityCodes({
        searchedCommodityCodes: Searchingfn(
          commoditieslist,
          ['commodityDescription', 'formattedCode'],
          value,
        ),
      });
    }
    if (!value || value.length === 0) {
      setShowSearch(false);
      setCommodityCodes({ searchedCommodityCodes: [] });
    }
  };

  const SetSelectedCommodity = (value: any, items: any, type: any) => {
    setSelectedCommodityCodes({ commoditieslist, value, items, type, reBroadcast });
    forceUpdate();
  };

  const CancelConfirm = () => {
    setLocalCommodity({
      value: '',
      category: '',
      name: '',
      confirmmodal: false,
    });
  };

  const cancelBroadcastListModal = () => {
    setAddBidDetails({ confirmChanges: false });
  };

  const approveAction = () => {
    cancelBroadcastListModal();
    if (actionType === 'save') setAddBidDetails({ savelistmodal: true });
    else if (actionType === 'continue') submitAddBidIsFilterStatus({ id, type });
  };

  const cancelReBroadcastListModal = () => {
    setAddBidDetails({ confirmReBroadcast: false });
  };

  const broadcastToNewSuppliers = () => {};

  const continueToRebroadcast = () => {
    setAddBidDetails({ confirmReBroadcast: false });
    Setnewlistmodal();
  };

  return (
    <>
      <div className='container m-auto'>
        {broadcastListssearchShow ? (
          <>
            {ListComponent(broadcastListssearch, reBroadcast ? 'newlist' : '')}
            <div className='d-flex justify-content-between'>
              <Buttons
                classNames='bttn-secondary mt-5 float-left'
                text='Go Back'
                title='Go Back'
                onClick={() => {
                  setCurrentPages(1);
                  setAddBidDetails({ broadcastListssearchShow: false, broadcastListssearch: [] });
                }}
                type='button'
              />
              {reBroadcast ? null : (
                <Buttons
                  classNames='bttn-primary mt-5 float-right'
                  text='Save List & Continue'
                  title='Save List & Continue'
                  onClick={() =>
                    selectedCommodities.length > 0 && newSelectedCommodities.length > 0
                      ? setAddBidDetails({ confirmChanges: true, actionType: 'save' })
                      : setAddBidDetails({ savelistmodal: true })
                  }
                  type='button'
                  disable={broadcastListssearch.length === 0}
                />
              )}{' '}
              <Buttons
                classNames='bttn-primary mt-5 float-right'
                text='Continue'
                title='Continue'
                onClick={() => {
                  if (reBroadcast) {
                    Setnewlistmodal();
                  } else {
                    selectedCommodities.length > 0 && newSelectedCommodities.length > 0
                      ? setAddBidDetails({ confirmChanges: true, actionType: 'continue' })
                      : submitAddBidIsFilterStatus({ id, type });
                  }
                }}
                type='button'
                disable={broadcastListssearch.length === 0}
              />
            </div>
          </>
        ) : (
          <div className='row commoditylist generateNewList'>
            <div className='col-lg-3'>
              <div className='sideBarSpacing'>
                <Filtersuppliearlist {...props} reBroadcast={reBroadcast} bidId={bidId} />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='colWrapper'>
                <div className='innerColIndent'>
                  {IsIEFn() ? (
                    <form>
                      <DeprecatedInput
                        type='text'
                        placeholder='Search Commodity Codes ( Enter minimum 3 characters to search )'
                        label='Search'
                        value={searchText}
                        handleChange={(name: string, value: string) => setSearchText(value)}
                        optional={'e.g "Administrative" or "001-000-00"'}
                        parentClass='mb-0'
                      />
                      <div className='d-flex justify-content-end mb-3'>
                        <Buttons
                          classNames='bttn-secondary mt-2 small'
                          text='Clear'
                          title='Clear'
                          type='button'
                          onClick={() => {
                            setShowSearch(false);
                            setSearchText('');
                            setCommodityCodes({ searchedCommodityCodes: [] });
                          }}
                        />
                        <Buttons
                          classNames='bttn-primary mt-2 ml-2 small'
                          text='Search'
                          title='Search'
                          type='submit'
                          onClick={searchCommodityCodes}
                        />
                      </div>
                    </form>
                  ) : (
                    <DeprecatedInput
                      type='search'
                      placeholder='Search Commodity Codes ( Enter minimum 3 characters to search )'
                      label='Search'
                      value={searchText}
                      handleChange={(name: string, value: string) =>
                        onChangeSearchCommodityCodes(value)
                      }
                      optional={'e.g "Administrative" or "001-000-00"'}
                      parentClass='mb-0'
                    />
                  )}
                </div>
                {showSearch ? (
                  <CommoditySearch
                    {...props}
                    SetSelectedCommodity={SetSelectedCommodity}
                    setLocalCommodity={setLocalCommodity}
                  />
                ) : (
                  <Commoditylist
                    {...props}
                    SetSelectedCommodity={SetSelectedCommodity}
                    setLocalCommodity={setLocalCommodity}
                    reBroadcast={reBroadcast}
                  />
                )}
              </div>
            </div>
            <div className='col-lg-3'>
              <Commodityselected
                {...props}
                GoBackToPage={GoBackToPage}
                SetSelectedCommodity={SetSelectedCommodity}
                reBroadcast={reBroadcast}
              />
            </div>
          </div>
        )}
      </div>
      <ModalPopUp
        title='Confirm'
        closeModal={CancelConfirm}
        isOpen={localCommodity.confirmmodal}
        backdrop='static'
        size='md'
      >
        <ConfirmCommodity
          {...props}
          CancelConfirm={CancelConfirm}
          SetSelectedCommodity={SetSelectedCommodity}
          localCommodity={localCommodity}
          commoditieslist={commoditieslist}
        />
      </ModalPopUp>
      <ConfirmModal
        confirmMessage='This will overwrite your previous commodities/suppliers selections. Do you want to proceed now?'
        approveAction={approveAction}
        closeModal={cancelBroadcastListModal}
        title='Confirm Changes'
        isOpen={confirmChanges}
        size='md'
      />
      <ConfirmModal
        confirmMessage={`Would you like to broadcast to these ${newSuppliersCount} new suppliers now?`}
        approveAction={broadcastToNewSuppliers}
        cancelAction={continueToRebroadcast}
        approveText={'Yes, broadcast the bid to them now'}
        cancelText={"No, I'm going to add more"}
        title='Rebroadcast To New Suppliers Now?'
        closeModal={cancelReBroadcastListModal}
        isOpen={confirmReBroadcast}
        size='md'
      />
    </>
  );
}

export default memo(GenerateNewList);
