import React, { memo } from 'react';
import { defaultFilter } from '../../../store/reducers/bids';
import { initialquotesFilters } from '../../../store/reducers/quotes';
import { BidExternalStatusType, memberTypes, quoteExternalStatus } from '../../../utils/constants';
import { encodeFilters, formatNumber } from '../../../utils/helpers';
import CardCount from '../../common/dashboard/cardcount';
import { sharedTypes } from '../../../types/shared';
import { authTypes } from '../../../types/auth';
import { getBidsTypes } from '../../../types/bids';
import { nargsfnTypes } from '../../../types/functions';

interface propsTypes {
  shared: sharedTypes;
  auth: authTypes;
  setBidDetails: any;
  setQuotesDetails: any;
  getBids: getBidsTypes;
  getQuotesList: any;
  history: any;
  setSharedDetails: nargsfnTypes;
}

function RightsideTop(props: propsTypes) {
  const {
    auth,
    getBids,
    getQuotesList,
    history,
    setBidDetails,
    setQuotesDetails,
    setSharedDetails,
    shared,
  } = props;
  const { dashboardData, dashboardCommodities = [] } = shared;
  const { bids = [], quotes = [] } = dashboardData;
  const { mt: memberType } = auth;

  const [activebidsCount = 0] =
    (bids.length > 0 &&
      bids
        .filter(item => item.categoryType === BidExternalStatusType.Active)
        .map(item => item.count)) ||
    [];
  const [respondedidsCount = 0] =
    (bids.length > 0 && bids.filter(item => item.categoryType === 'RS').map(item => item.count)) ||
    [];
  const [activequotesCount = 0] =
    (quotes.length > 0 &&
      quotes
        .filter(item => item.categoryType === quoteExternalStatus.open)
        .map(item => item.count)) ||
    [];
  const [watchBidsCount = 0] =
    (bids.length > 0 && bids.filter(item => item.categoryType === 'WT').map(item => item.count)) ||
    [];

  const gotoPage = async (status: string, Type: string) => {
    const pageType = memberType === memberTypes.agencyBuyer ? 'buyers' : 'suppliers';
    if (Type === 'bids') {
      const bidStatus = status;
      let additionalFilter = { showBids: 'Notified', bidsNotified: true } as any;
      if (memberType === memberTypes.agencyBuyer) {
        additionalFilter = { showBids: 'Mine', myBids: true };
      }
      const searchFilters = { ...defaultFilter, ...additionalFilter, bidStatus };
      await setBidDetails({
        filters: searchFilters,
        initialRequest: false,
        selectedBidIds: '',
        loadedBids: [],
        results: [],
        bidscurrentPage: 1,
      });
      await getBids();
      history.push(`/${pageType}/bids?${encodeFilters(searchFilters)}`);
    } else if (Type === 'responses') {
      history.push(`/${pageType}/responses/my-responses`);
    } else {
      let quoteStatuses: any = '';
      if (status === quoteExternalStatus.open) {
        quoteStatuses = { label: 'Open', value: quoteExternalStatus.open };
      }
      if (status === quoteExternalStatus.awarded) {
        quoteStatuses = { label: 'Awarded', value: quoteExternalStatus.awarded };
      }
      if (status === quoteExternalStatus.cancelled) {
        quoteStatuses = { label: 'Cancelled', value: quoteExternalStatus.cancelled };
      }
      if (status === quoteExternalStatus.closed) {
        quoteStatuses = { label: 'Closed', value: quoteExternalStatus.closed };
      }
      if (status === quoteExternalStatus.incomplete) {
        quoteStatuses = { label: 'Incomplete', value: quoteExternalStatus.incomplete };
      }
      await setQuotesDetails({
        quotesFilters: { ...initialquotesFilters, quoteStatuses },
        quotesinitial: false,
        quotesreload: false,
        quotecurrentPage: 1,
      });
      await getQuotesList({ types: 'search' });
      history.push(`/${pageType}/quotes`);
    }
  };

  const gotoWatchList = async () => {
    await setBidDetails({
      filters: { ...defaultFilter, showBids: 'Watch', watchedBids: true },
      initialRequest: false,
      selectedBidIds: '',
      loadedBids: [],
      results: [],
      bidscurrentPage: 1,
    });
    await getBids();
    history.push('/suppliers/bids?watchedBids=true');
  };

  const handleSubtextClick = () => {
    if (memberType === memberTypes.basicSupplier) {
      history.push('/suppliers/plans');
    } else {
      history.push('/suppliers/account/commoditycodes/edit');
    }
    setSharedDetails({ pathfromdashboard: true });
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-12 col-sm-6 pr-lg-1 mb-4'>
              <CardCount
                title='My Bid Notifications'
                gotoPage={() => gotoPage(BidExternalStatusType.Active, 'bids')}
                count={formatNumber(activebidsCount)}
                classNames='bid'
                count0Text='0 Bid Notifications'
                count0SubText={
                  dashboardCommodities.length === 0
                    ? 'Select Commodity Codes to receive bid notifications'
                    : ''
                }
                handleClick={handleSubtextClick}
              />
            </div>

            {memberType !== memberTypes.basicSupplier && (
              <>
                <div className='col-12 col-sm-6 pr-lg-1 mb-4'>
                  <CardCount
                    title='My Quote Notifications'
                    gotoPage={() => gotoPage(quoteExternalStatus.open, 'quotes')}
                    count={formatNumber(activequotesCount)}
                    classNames='quote'
                    count0Text='0 Quote Notifications'
                    count0SubText={
                      memberType !== memberTypes.basicSupplier && dashboardCommodities.length === 0
                        ? 'Select Commodity Codes to receive quote notifications'
                        : ''
                    }
                    handleClick={handleSubtextClick}
                  />
                </div>
              </>
            )}

            <div
              className={
                'col-12 col-sm-6 pr-lg-1' +
                (memberType !== memberTypes.basicSupplier ? ' mb-sm-0 mb-4' : ' mb-4')
              }
            >
              <CardCount
                title='Bids I Am Watching'
                gotoPage={() => gotoWatchList()}
                count={formatNumber(watchBidsCount)}
                classNames='bid'
                count0Text='No Bids I Am Watching'
              />
            </div>
            <div className='col-12 col-sm-6 pr-lg-1 mb-sm-0 mb-4'>
              <CardCount
                title='Responded Bids'
                gotoPage={() => gotoPage('RS', 'responses')}
                count={formatNumber(respondedidsCount)}
                classNames='quote'
                count0Text='No Responded Bids'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(RightsideTop);
