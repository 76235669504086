import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';
import Api from './api';

type dataTypes = Record<string, unknown> | undefined;

export function getPrograms(data = '') {
  return Api.postRequestWithAuthentication(
    Paths.getPrograms,
    {},
    { baseURL: constants.api.userUrl },
  );
}

export function addUpdateProgram(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.addUpdateProgram, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getSuppliers(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.getSuppliers, data, {
    baseURL: constants.api.userUrl,
  });
}

export function respondCertRequest(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.respondCertRequest, data, {
    baseURL: constants.api.userUrl,
  });
}
