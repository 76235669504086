import { useEffect } from 'react';

import { RecoilState, useResetRecoilState } from 'recoil';

/**
 * Call the Recoil `useResetRecoilState` hook for the supplied RecoilState.
 * @param recoilState
 * @param logMessage
 * @todo Accept an array of RecoilState values.
 */
export function useResetOnUnmount<T>(recoilState: RecoilState<T>, logMessage = '') {
  const resetRecoilState = useResetRecoilState(recoilState);
  useEffect(() => {
    return () => {
      resetRecoilState();
      // For debugging
      if (logMessage) {
        console.log(`${logMessage} - useResetOnUnmount: ${recoilState.key}`);
      }
    };
  }, [logMessage, recoilState.key, resetRecoilState]);
}
