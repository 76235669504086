import { createActionTypes } from './utils';

export const GET_PAYMENT_CARD_DETAILS = createActionTypes('GET_PAYMENT_CARD_DETAILS');
export const SET_PAYMENT_FORM_DETAILS = createActionTypes('SET_PAYMENT_FORM_DETAILS');
export const SUBMIT_EBITS_PAYMENT_CARD = createActionTypes('SUBMIT_EBITS_PAYMENT_CARD');
export const SUBMIT_EBITS_CHANGE_BILLING_ADDRESS = createActionTypes(
  'SUBMIT_EBITS_CHANGE_BILLING_ADDRESS',
);
export const SUBMIT_EBITS_PAYMENT_METHOD = createActionTypes('SUBMIT_EBITS_PAYMENT_METHOD');
export const RESET_BIDS_PAYMENT = createActionTypes('RESET_BIDS_PAYMENT');
export const PROCESS_CREDIT_TRANSACTION = createActionTypes('PROCESS_CREDIT_TRANSACTION');
