import { Cell } from 'react-table';
import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../../shared/styles';
import { commonLabels } from '../../../../shared/constants';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { IdPropFunction } from '../../../../types/shared';

const IconWrapper = styled.div`
  cursor: pointer;
`;

export interface DeleteIconCellProps<IdType, T> {
  idField: keyof T;
  labelField: keyof T;
  titleField?: keyof T;
  handleClick: IdPropFunction<IdType>;
}

/**
 * This cell renders a DeleteIcon with a callback for the given id value.
 * @param value
 */
export function DeleteIconCell<IdType, T>(initialProps: DeleteIconCellProps<IdType, T>) {
  // `props` are typed as `any` from `react-table`
  return (props: Cell<any>) => {
    const { handleClick, idField, labelField, titleField } = initialProps;
    const { row } = props;
    const { original } = row;
    const labelValue = original[labelField] || '';
    const idValue = original[idField] as IdType;
    const titleValue = titleField ? original[titleField] : labelValue;

    const handleDelete = () => {
      // console.log('DeleteCell - delete ID', idValue);
      handleClick({ id: idValue });
    };

    const iconTitle = `${commonLabels.delete} ${titleValue}`;

    return (
      <IconWrapper onClick={handleDelete} title={iconTitle}>
        <DeleteIcon color={Colors.action} />
      </IconWrapper>
    );
  };
}
