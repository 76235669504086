import React, { memo, useState, useCallback } from 'react';
// Component
import { Buttons } from '../../customcontrols/index';
import { PaymentType } from '../../../types/payments';
import { Link } from 'react-router-dom';

interface propsTypes {
  payment: PaymentType;
  handleChangeClicked?: any;
  alignChangeBtn: string;
}
const PaymentMethod = (props: propsTypes) => {
  const { payment, handleChangeClicked, alignChangeBtn } = props;
  const { cardinfo, card_token = '' } = payment;
  const { details = {}, nonce } = card_token;
  const { cardType = '', cardNumberMask = '' } = cardinfo;

  return (
    <>
      <div className={'innerColIndent clearfix ' + alignChangeBtn}>
        {card_token && nonce && details ? (
          <p className='d-block mb-auto'>
            {details.cardType} card ending with {details.lastFour}
          </p>
        ) : (
          <>
            {cardinfo && cardNumberMask ? (
              <p className='d-block mb-auto'>
                {cardType} card ending with {cardNumberMask}
              </p>
            ) : (
              <p className='d-block mb-auto'>Choose a Payment Method</p>
            )}
          </>
        )}
        <Buttons
          type='button'
          classNames={
            'bttn-secondary float-right small ' + (alignChangeBtn !== '' ? 'ml-4' : 'my-4')
          }
          text='Change'
          onClick={handleChangeClicked}
        />
      </div>
    </>
  );
};

export default memo(PaymentMethod);
