import React, { memo, useEffect, useState } from 'react';
import {
  pageSize,
  quoteBreakdownStatus,
  quoteResponsesSortFields,
  quoteSupplierFilter,
  sortDirections,
} from '../../../../../../utils/constants';
import { loadingMsg } from '../../../../../../utils/texts';
import { deprecatedGetDate, SortingFn } from '../../../../../../utils/helpers';
import { Paging, SelectBox, Sort } from '../../../../../customcontrols';
import NoResult from '../../../../../customcontrols/noresult';
import Supplierdetails from '../details/supplierdetails';
import { QuoteSummaryType, ResponsesStatusType } from '../../../../../../types/quotedetailstypedef';
import { authTypes } from '../../../../../../types/auth';
import { addbidParamType } from '../../../../../../types/addbid';
import { sharedTypes } from '../../../../../../types/shared';

interface PropsTypes {
  quoteSummary: QuoteSummaryType;
  setQuoteSummaryDetails: any;
  shared: sharedTypes;
  getSelfDeclarations: any;
  auth: authTypes;
  quoteId: any;
  QuoueSummaryGetCurrentSupplierinfo?: any;
  getQuoteResponses: any;
  getAddBidActivePrograms?: any;
  match: any;
  addbid: addbidParamType;
}

const Suppliers = (props: PropsTypes) => {
  const {
    quoteSummary,
    quoteId,
    setQuoteSummaryDetails,
    QuoueSummaryGetCurrentSupplierinfo,
    getQuoteResponses,
    getSelfDeclarations,
    shared,
    getAddBidActivePrograms,
  } = props;
  const {
    suppliers = [],
    currentSupplier = '',
    responsesStatus = [],
    getcurrentsupplierAPICALL = true,
    response_internalloader,
  } = quoteSummary || {};

  const [currentPage, setCurrentPage] = useState<number>(1);
  const listPerPage: number = pageSize.quoteResponses;
  const indexOfLastList: number = currentPage * listPerPage;
  const indexOfFirstList: number = indexOfLastList - listPerPage;

  const [selectedResponseStatus, setSelectedResponseStatus] = useState(quoteSupplierFilter);

  const [sortvalue, setSortvalue] = useState<string>('supplierName');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);
  const changeSortSelectBox = (key: string, value: string) => {
    if (value !== sortvalue) {
      setSortvalue(value);
      const type = value === 'price' ? 'number' : 'text';
      setQuoteSummaryDetails({ suppliers: SortingFn(suppliers, sortorder, value, type) });
      setCurrentPage(1);
    }
  };
  const changeSortOrder = () => {
    const sort =
      sortorder === sortDirections.DESC
        ? sortDirections.ASC
        : sortorder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort;
    setSortorder(sort);

    const type = sortvalue === 'price' ? 'number' : 'text';
    setQuoteSummaryDetails({ suppliers: SortingFn(suppliers, sort, sortvalue, type) });
    setCurrentPage(1);
  };

  const setCurrentSupplier = (item: any) => {
    if (item.responseId !== currentSupplier.responseId) {
      setQuoteSummaryDetails({ currentSupplier: item, lineitems_supplier_internalLoader: true });
      if (quoteBreakdownStatus.includes(item.responseType)) {
        QuoueSummaryGetCurrentSupplierinfo(item);
      }
      if (item.memberId) {
        const value = { isCurrentMember: false, memberId: item.memberId };
        getAddBidActivePrograms(value);
        getSelfDeclarations(value);
      }
    }
  };

  const changeResponseStatus = (name: string, value: ResponsesStatusType) => {
    if (value !== selectedResponseStatus) {
      setCurrentPage(1);
      setQuoteSummaryDetails({ response_internalloader: true, suppliers: [] });
      getQuoteResponses({ quoteId, responseStatus: value.value });
    }
    setSelectedResponseStatus(value);
  };

  useEffect(() => {
    if (currentSupplier.memberId && getcurrentsupplierAPICALL) {
      const value = { isCurrentMember: false, memberId: currentSupplier.memberId };
      setQuoteSummaryDetails({ getcurrentsupplierAPICALL: false });
      getAddBidActivePrograms(value);
      getSelfDeclarations(value);
    }
  }, [
    currentSupplier.memberId,
    getAddBidActivePrograms,
    getSelfDeclarations,
    getcurrentsupplierAPICALL,
    setQuoteSummaryDetails,
  ]);

  useEffect(() => {
    if (suppliers.length === 0) setQuoteSummaryDetails({ currentSupplier: '' });
  }, [setQuoteSummaryDetails, suppliers.length]);

  useEffect(() => {
    if (suppliers.length > 0 && props.match.params.tab === 'suppliers' && !currentSupplier) {
      setQuoteSummaryDetails({
        currentSupplier: suppliers[0],
        lineitems_supplier_internalLoader: true,
      });
      if (quoteBreakdownStatus.includes(suppliers[0].responseType)) {
        QuoueSummaryGetCurrentSupplierinfo(suppliers[0]);
      }
    }
  }, [
    QuoueSummaryGetCurrentSupplierinfo,
    currentSupplier,
    props.match.params.tab,
    setQuoteSummaryDetails,
    suppliers,
  ]);

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h3 className='mb-3 arrowWrapper'>Suppliers</h3>
        </div>
        <>
          <div className='col-lg-4'>
            <div className='ulListWrapper'>
              <h4>Quote Recipients ({suppliers.length ? suppliers.length : 0})</h4>
              <header className='headerFilter d-flex flex-column align-items-center clearfix pl-0'>
                <SelectBox
                  reactselect={true}
                  label='Category'
                  name='category'
                  options={responsesStatus.map(item => ({
                    ...item,
                    label: item.title || item.label,
                    value: item.value,
                  }))}
                  parentClass='w-100'
                  value={selectedResponseStatus}
                  handleSelect={changeResponseStatus}
                />
                {suppliers && suppliers.length > 0 ? (
                  <Sort
                    sortFields={quoteResponsesSortFields}
                    selectedFieldName={sortvalue}
                    onChange={changeSortSelectBox}
                    onSortClick={changeSortOrder}
                    sortIcon={sortorder}
                    parentClass='w-100'
                  />
                ) : null}
              </header>

              <ul className='list-unstyled clearfix'>
                {suppliers && suppliers.length > 0 ? (
                  <>
                    {suppliers
                      .map((item, index) => (
                        <li
                          key={index}
                          className={currentSupplier.responseId === item.responseId ? 'active' : ''}
                          custom-attribute={
                            'current' + currentSupplier.responseId + ', ' + item.responseId
                          }
                          onClick={() => setCurrentSupplier(item)}
                        >
                          <h6 className='mw-100 text-truncate'>{item.supplierName}</h6>
                          <span>
                            {item.city}
                            {item.city && item.stateAbbr && `, ${item.stateAbbr}`}
                          </span>
                          {['EM', 'PB'].includes(item.responseType) ? null : (
                            <>
                              {item.price >= 0 ? (
                                <span>
                                  <i>Quote Total :</i> ${item.price}
                                </span>
                              ) : null}
                              {item.respondedDate && (
                                <span>
                                  <i>Response Date :</i> {deprecatedGetDate(item.respondedDate)}
                                </span>
                              )}
                              {item.deliveryARO && (
                                <span>
                                  <i>Delivery (days ARO) :</i> {item.deliveryARO}
                                </span>
                              )}
                            </>
                          )}
                        </li>
                      ))
                      .slice(indexOfFirstList, indexOfLastList)}
                  </>
                ) : (
                  <NoResult
                    message={response_internalloader ? loadingMsg : 'No Suppliers Available'}
                  />
                )}
              </ul>
              <Paging
                totalRecords={suppliers.length}
                currentPage={currentPage}
                pagefor='planholders-supplier'
                onPagingClick={(value: number) => {
                  if (value !== currentPage) setCurrentPage(Number(value));
                }}
                pageLimit={listPerPage}
              />
            </div>
          </div>
          <div className='col-lg-8'>{currentSupplier && <Supplierdetails {...props} />}</div>
        </>
      </div>
    </>
  );
};

export default memo(Suppliers);
