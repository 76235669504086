import React, { useCallback } from 'react';

import { DateCell, LinkTextCell } from '../../../../components/common/table/renderers';
import { navigationPaths, SubPaths } from '../../../../shared/constants';
import { requiredActionLabels, tableHeaders } from '../../../../shared/constants/required-action';
import { TableBase, TableStyleWrapper } from '../../../../components/common/table';

import { Column } from 'react-table';
import { getDefaultLoadingMessage } from '../../../../utils/message';
import { LoadingPanel } from '../../../../components/common/loading';
import { NotFoundPanel } from '../../../../components/common/loading/NotFoundPanel';
import { RequiredAction } from '../../../../types/required-action';
import { requiredDocLabels } from '../../../../components/buyer/awardbid/add-vendor-docs/constants';
import { useRequiredActions } from '../../../../shared/hooks/award-bid/useRequiredActions';
import { useRouter } from '../../../../shared/hooks';

interface RequiredActionsTableProps {
  requiredActions: RequiredAction[];
}

/**
 * Container for the Panel displaying Required Actions.
 * TODO: Abstract this logic to shared and synchronize with the Required Actions List view.
 */
export function RequiredActionsTable({ requiredActions }: RequiredActionsTableProps) {
  const { history } = useRouter();
  const { requiredActionsLoading, setSelectedRequiredActionId } = useRequiredActions();

  /**
   * Navigate to action details.
   * @param original: table row data for `actionId` and `bidId`
   * @returns
   */
  const handleActionClick = useCallback(
    (original: RequiredAction) => {
      setSelectedRequiredActionId(original.actionId);
      history.push(
        `${navigationPaths.suppliers.responses}}/${original.bidId}/${SubPaths.management}`,
      );
    },
    [history, setSelectedRequiredActionId],
  );

  /**
   * Render cell text based upon row data.
   * @param original: table row data for `actionId` and `bidId`
   * @returns
   */
  const renderActionLabelPrefix = useCallback((original: RequiredAction) => {
    return original?.bidTitle && original?.type === 'requiredDocument'
      ? `${requiredDocLabels.requiredActionMessageUpload}: `
      : '';
  }, []);

  /**
   * Required Actions - List Table
   */

  const getRequiredActionsTableColumns = useCallback((): Column<RequiredAction>[] => {
    return [
      {
        Header: tableHeaders.solicitationName,
        accessor: 'bidTitle',
        Cell: LinkTextCell({
          baseUrl: navigationPaths.suppliers.bids,
          idField: 'bidId',
          labelField: 'bidTitle',
          subUrl: navigationPaths.subPaths.details,
        }),
        minWidth: 300,
      },
      {
        Header: tableHeaders.requiredAction,
        accessor: 'label',
        Cell: LinkTextCell({
          baseUrl: navigationPaths.suppliers.bids,
          handleClick: handleActionClick,
          idField: 'bidId',
          labelField: 'label',
          renderLabelPrefix: renderActionLabelPrefix,
          subUrl: navigationPaths.subPaths.management,
        }),
        minWidth: 300,
      },
      {
        Header: tableHeaders.dueDate,
        accessor: 'dueDate',
        Cell: DateCell({ dateField: 'dueDate' }),
        minWidth: 200,
      },
    ];
  }, [handleActionClick, renderActionLabelPrefix]);

  return requiredActionsLoading ? (
    <LoadingPanel message={getDefaultLoadingMessage(requiredActionLabels.titlePlural)} />
  ) : requiredActions?.length ? (
    <TableStyleWrapper>
      <TableBase columns={getRequiredActionsTableColumns()} data={requiredActions} />
    </TableStyleWrapper>
  ) : (
    <NotFoundPanel message={requiredActionLabels.notFound} />
  );
}
