import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { Formik } from 'formik';
import { Buttons, DeprecatedInput } from '../../customcontrols';
import { RegistrationWizardButtons } from './RegistrationWizardButtons';
import { setWizardStatus, toastFn, scrollToTop } from '../../../utils/helpers';
import { publicationFields } from '../../../utils/constants';
import CommonDataTable from './commondatatable';
import {
  agencyRegistrationParamType,
  publicationItemType,
} from '../../../types/agencyregistration';
import { WizardPage } from '../../../types/wizard';
import { useDSSelector } from '../../../store/reducers';
import { setAgencyRegistrationDetails } from '../../../store/actions';
import { useDispatch } from 'react-redux';

interface PropsTypes {
  handleSubmit?: any;
  addAgencyPublicationsList?: any;
  setAgencyRegistrationDetails?: any;
  agencyregistration?: agencyRegistrationParamType;
  deletePublication?: any;
  match?: any;
  currentwizard?: WizardPage;
}

function Publications(props: PropsTypes) {
  const dispatch = useDispatch();

  const { agencyregistration } = props;

  const {
    publicationName = '',
    wizardcurrentpage = 0,
    agencypublications = [],
    editEnabled = false,
    publicationId = 0,
    selectedIndex = '',
  } = agencyregistration || {};

  const agencyRegistrationWizard = useDSSelector(
    state => state.agencyregistration.agencyRegistrationWizard,
  );

  const AddPublicationFn = () => {
    const publication: publicationItemType = {
      publicationId: publicationId,
      publication: publicationName.trim(),
    };
    let newAgencyPublications: publicationItemType[] = [];
    let checkExist = agencypublications.filter(item => item.publication === publicationName);
    if (selectedIndex !== '') {
      checkExist = agencypublications.filter(
        (item, index, selectedIndex) =>
          item.publication === publicationName && index !== parseInt(selectedIndex as any),
      );
      newAgencyPublications = [...agencypublications];
      newAgencyPublications[selectedIndex as any] = {
        ...newAgencyPublications[selectedIndex as any],
        publication: publicationName,
      };
    } else {
      newAgencyPublications = [...agencypublications, publication];
    }

    if (checkExist.length === 0) {
      dispatch(
        setAgencyRegistrationDetails({
          agencypublications: newAgencyPublications,
          publicationName: '',
          editEnabled: false,
          selectedIndex: '',
        }),
      );
    } else {
      toastFn('error', 'Already Exist', 'Add Agency publication');
    }
  };

  const editPublication = (data: any, index: number) => {
    scrollToTop();
    dispatch(
      setAgencyRegistrationDetails({
        publicationName: data.publication,
        editEnabled: true,
        selectedIndex: index,
      }),
    );
  };

  const deleteAgencyPublication = (data: any) => {
    const filteredData = agencypublications.filter(item => item.publication !== data.publication);

    dispatch(setAgencyRegistrationDetails({ agencypublications: filteredData }));
  };

  const onSubmitFn = () => {
    dispatch(
      setAgencyRegistrationDetails({
        agencyRegistrationWizard: setWizardStatus(agencyRegistrationWizard, wizardcurrentpage + 1),
        wizardcurrentpage: wizardcurrentpage + 1,
      }),
    );
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
            {formikProps => {
              const { handleSubmit } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <h2 className='lineHR'>Bid Publications ({agencypublications.length})</h2>
                  <Table className='tableHData'>
                    <tr>
                      <td>
                        <DeprecatedInput
                          label='Name'
                          type='text'
                          name='publicationName'
                          handleChange={(name: string, value: string) =>
                            dispatch(setAgencyRegistrationDetails({ publicationName: value }))
                          }
                          value={publicationName}
                          placeholder='Name'
                          maxLength={50}
                        />
                      </td>
                      <td width='30px'>
                        <Buttons
                          classNames='bttn-primary small mb-1'
                          text={editEnabled ? 'Update' : 'Save'}
                          title='Save'
                          type='button'
                          onClick={() => {
                            if (publicationName.length > 0) {
                              AddPublicationFn();
                            }
                          }}
                          disable={!publicationName.trim()}
                        />
                      </td>
                      {editEnabled === true ? (
                        <td width='30px'>
                          <Buttons
                            classNames='bttn-primary small mb-1'
                            text='Cancel'
                            title='Cancel'
                            type='button'
                            onClick={() => {
                              dispatch(
                                setAgencyRegistrationDetails({
                                  publicationName: '',
                                  publicationId: 0,
                                  editEnabled: false,
                                }),
                              );
                            }}
                          />
                        </td>
                      ) : null}
                    </tr>
                  </Table>
                  {agencypublications.length > 0 && (
                    <CommonDataTable
                      {...props}
                      data={agencypublications}
                      fields={publicationFields}
                      actions={['edit', 'delete']}
                      editEnabled={editEnabled}
                      editdata={(data: string, index: number) => editPublication(data, index)}
                      deletedata={deleteAgencyPublication}
                    />
                  )}

                  <RegistrationWizardButtons {...props} />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default memo(Publications);
