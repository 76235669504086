import React, { memo, useEffect } from 'react';
import { BreadcrumbItems } from './BreadcrumbItems';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';
import { ComponentRoutes } from '../../../containers/ComponentRoutes';
import { memberTypes } from '../../../utils/constants';
import { scrollToTop } from '../../../utils/helpers';
import { authTypes } from '../../../types/broadcastlist';
import { breadcrumbTypes } from '../../../types/breadcrumb';
import { Route, RouteSubList } from '../../../types/routes';
import history from '../../../utils/history';
import * as H from 'history';
import { Permission } from '../../../types/auth';

interface BreadcrumbProps extends PropsFromRedux {
  auth: authTypes;
  setBreadcrumb: any;
  history: H.History;
  breadcrumb: breadcrumbTypes;
}

const BreadcrumbComponent = (props: BreadcrumbProps) => {
  const { setBreadcrumb, auth, breadcrumb: breadcrumbData } = props;
  const { page, /*title,*/ id, component } = breadcrumbData;
  const { prms = '', mt: memberType } = auth || {};
  const permissions = prms.replace(/ /g, '').split(',') as Permission[];
  const pathname = history.location.pathname;

  function getBreadcrumbByPage(routes: Route[], page: string) {
    return routes
      .filter(route => route.page === page)
      .map((route: { breadcrumb: any }) => route.breadcrumb);
  }

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    return () => {
      setBreadcrumb({
        component: '',
        page: '',
        title: '',
        altname: '',
        name: '',
        id: '',
        menuactive: '',
      });
    };
  }, [setBreadcrumb]);

  const routeSubList: RouteSubList =
    memberType === memberTypes.agencyBuyer ? ComponentRoutes.buyer : ComponentRoutes.supplier || [];

  let [breadcrumb]: any = getBreadcrumbByPage(routeSubList, page);

  if (!breadcrumb && auth && auth.us && permissions.includes(Permission.Ops)) {
    [breadcrumb] = getBreadcrumbByPage(ComponentRoutes.ops || [], page);
  }

  if (breadcrumb) {
    const regex = /:bidId/g;
    const breadcrumbKeys = Object.keys(breadcrumb);
    breadcrumb = breadcrumbKeys.map(item => ({
      name: item,
      path:
        (breadcrumb[item].match(regex) && breadcrumb[item].replace(regex, id)) || breadcrumb[item],
    }));
  }
  // let pageTitle = title ? `${title} - ${appKeys.name}` : appKeys.name
  // const homePage = window.location.pathname !== '/suppliers/bids' ? 'breadcrumbWrapper' : 'd-none'
  // const quotesPage = window.location.pathname !== '/suppliers/quotes' ? 'breadcrumbWrapper' : 'd-none'

  return (
    <>
      <div className={'container-fluid breadcrumbWrapper reciept-screen'}>
        <div className='row'>
          <div className='col'>
            {breadcrumb && breadcrumb.length > 0 ? (
              <nav aria-label='breadcrumb'>
                <div className='row'>
                  <div className='col-sm-12 col-md-7'>
                    <BreadcrumbItems {...props} breadcrumbs={breadcrumb} />
                  </div>
                  <div className='col-sm-12 col-md-5 px-0 text-right'>
                    {component ? (
                      <ol className='breadcrumb pageControl pr-0'>{component}</ol>
                    ) : null}
                  </div>
                </div>
              </nav>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    breadcrumb: state.breadcrumb,
    auth: state.auth,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const Breadcrumb = connector(memo(BreadcrumbComponent));
