import React, { memo, useEffect } from 'react';
import UploadFile from './uploadfile';
import ColumnCheck from './columncheck';
import ReviewList from './reviewlist';
import { addbidParamType } from '../../../../types/addbid';
import { BulkUploadModalComponent } from '../../../../utils/constants';

type propsTypes = {
  setAddBidDetails: any;
  addbid: addbidParamType;
  reBroadcast?: boolean;
};

const defaultValues = {
  currentScreen: 1,
  emailAddress: [],
  columnData: [],
  skippedColumns: [],
  docId: '',
  unmatchedColumns: 0,
  uploadProgress: false,
  progress: 0,
  columns: [],
  estimatedTime: '',
  multipleSheet: false,
  errorType: '',
  sheets: [],
  selectedSheet: '',
  showSkippedColumns: false,
  configurationInstruction: false,
};

const SupplierBulkUpload = (props: propsTypes) => {
  const { addbid, setAddBidDetails } = props;
  const { currentScreen = 1 } = addbid || {};

  useEffect(() => {
    setAddBidDetails({ ...defaultValues });
  }, [setAddBidDetails]);

  let CurrentComponent = null;

  switch (currentScreen) {
    case BulkUploadModalComponent.UploadFile:
      CurrentComponent = <UploadFile {...props} />;
      break;
    case BulkUploadModalComponent.ColumnCheck:
      CurrentComponent = <ColumnCheck {...props} />;
      break;
    case BulkUploadModalComponent.ReviewList:
      CurrentComponent = <ReviewList {...props} />;
      break;
  }

  return <>{CurrentComponent}</>;
};

export default memo(SupplierBulkUpload);
