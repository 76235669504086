import { constants } from '../../utils/settings';
const { imageCdn } = constants;

const imgsCdn = {
  otherImg: {
    dsWhiteLogo: `${imageCdn}/logo_white.svg`,
    dsLogoResearch: `${imageCdn}/ds_logo_research.png`,
    closeIcon: `${imageCdn}/clsoe-new.png`,
    envelope: `${imageCdn}/envelope.svg`,
    envelopeBar: `${imageCdn}/envelope_withbar.svg`,
    liarrow: `${imageCdn}/liarrow.png`,
    messageCS: `${imageCdn}/messageCS.png`,
    missouricitytexas: `${imageCdn}/missouricitytexas.png`,
    researchCS: `${imageCdn}/researchCS.png`,
  },
  docFormats: {
    default: `${imageCdn}/docicons/default.svg`,
    doc: `${imageCdn}/docicons/word.svg`,
    dwf: `${imageCdn}/docicons/dwg.svg`,
    gif: `${imageCdn}/docicons/gif.svg`,
    htm: `${imageCdn}/docicons/html.svg`,
    jpg: `${imageCdn}/docicons/jpg.svg`,
    mpg: `${imageCdn}/docicons/mpg.svg`,
    pdf: `${imageCdn}/docicons/pdf.svg`,
    ppt: `${imageCdn}/docicons/ppt.svg`,
    rtf: `${imageCdn}/docicons/rtf.svg`,
    tif: `${imageCdn}/docicons/tif.svg`,
    txt: `${imageCdn}/docicons/txt.svg`,
    wip: `${imageCdn}/docicons/wip.svg`,
    wp: `${imageCdn}/docicons/wp.svg`,
    xls: `${imageCdn}/docicons/xls.svg`,
    xml: `${imageCdn}/docicons/xml.svg`,
    zip: `${imageCdn}/docicons/zip.svg`,
    csv: `${imageCdn}/docicons/csv.svg`,
    m4a: `${imageCdn}/docicons/m4a.svg`,
    mov: `${imageCdn}/docicons/mov.svg`,
    mp3: `${imageCdn}/docicons/mp3.svg`,
    ods: `${imageCdn}/docicons/ods.svg`,
    odt: `${imageCdn}/docicons/odt.svg`,
    png: `${imageCdn}/docicons/png.svg`,
    shx: `${imageCdn}/docicons/shx.svg`,
    wma: `${imageCdn}/docicons/wma.svg`,
    wpd: `${imageCdn}/docicons/wpd.svg`,
    xlr: `${imageCdn}/docicons/xlr.svg`,
    xlsb: `${imageCdn}/docicons/xlsb.svg`,
    xlsm: `${imageCdn}/docicons/xlsm.svg`,
    xltx: `${imageCdn}/docicons/xltx.svg`,
  },
  helpImgs: {
    picture1: `${imageCdn}/help/picture1.png`,
    picture2: `${imageCdn}/help/picture2.png`,
    picture3: `${imageCdn}/help/picture3.png`,
    picture4: `${imageCdn}/help/picture4.png`,
    picture5: `${imageCdn}/help/picture5.png`,
    picture6: `${imageCdn}/help/picture6.png`,
    picture7: `${imageCdn}/help/picture7.png`,
    picture8: `${imageCdn}/help/picture8.png`,
    picture9: `${imageCdn}/help/picture9.png`,
    picture10: `${imageCdn}/help/picture10.png`,
    picture11: `${imageCdn}/help/picture11.png`,
    picture12: `${imageCdn}/help/picture12.png`,
    picture13: `${imageCdn}/help/picture13.png`,
    picture14: `${imageCdn}/help/picture14.png`,
    picture15: `${imageCdn}/help/picture15.png`,
    picture16: `${imageCdn}/help/picture16.png`,
    picture17: `${imageCdn}/help/picture17.png`,
    picture18: `${imageCdn}/help/picture18.png`,
    picture19: `${imageCdn}/help/picture19.png`,
    picture20: `${imageCdn}/help/picture20.png`,
    picture21: `${imageCdn}/help/picture21.png`,
    picture22: `${imageCdn}/help/picture22.png`,
    picture23: `${imageCdn}/help/picture23.png`,
    picture24: `${imageCdn}/help/picture24.png`,
    picture25: `${imageCdn}/help/picture25.png`,
  },
};

export default imgsCdn;
