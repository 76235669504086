import classnames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../store/actions';
import { initialStateQuoteSummary } from '../../../../store/reducers/quoteSummary';
import { memberinfoType } from '../../../../types/memberInfo';
import { QuoteSummaryType } from '../../../../types/quotedetailstypedef';
import navHistory from '../../../../utils/history';
import Quotesdetailscontrol from '../../../common/breadcrumb/buyer/quotesdetailscontrol';
import Audittrail from '../../biddetail/tabs/audittrail';
import BroadcastHistory from '../../biddetail/tabs/broadcasthistory';
import Details from './tabs/details/index';
import LineItems from './tabs/lineitems';
import Suppliers from './tabs/suppliers/index';
import { authTypes } from '../../../../types/auth';
import { addbidParamType } from '../../../../types/addbid';
import { sharedTypes } from '../../../../types/shared';

interface PropsTypes extends PropsFromRedux, RouteComponentProps {
  setBreadcrumb: any;
  getQuoteSummary: any;
  memberInfo: any;
  quoteSummary: QuoteSummaryType;
  quoteSummaryGetDocuments: any;
  getSelfDeclarations: any;
  getQuoteResponses: any;
  getQuoteResponseStatus: any;
  loadBidsAuditTrial: any;
  getQuoteStatistics: any;
  getQuoteLineItems: any;
  resetQuoteSummaryDetails: any;
  getQuoteCommoditycodes: any;
  setQuoteSummaryDetails: any;
  shared: sharedTypes;
  memberinfo: memberinfoType;
  match: any;
  auth: authTypes;
  userpagefor: string;
  addbid: addbidParamType;
}

function Quotesdetails(props: PropsTypes) {
  const {
    setBreadcrumb,
    getQuoteSummary,
    quoteSummary,
    quoteSummaryGetDocuments,
    getQuoteResponses,
    getQuoteResponseStatus,
    loadBidsAuditTrial,
    getQuoteStatistics,
    getQuoteLineItems,
    resetQuoteSummaryDetails,
    getQuoteCommoditycodes,
    setQuoteSummaryDetails,
  } = props;
  const { summary } = quoteSummary || {};

  const Tab: string = props?.match?.params?.tab || '';

  const [activeTab, setActiveTab] = useState<string>(Tab ? Tab : 'details');

  const quoteID: number = parseInt(props.match.params.quoteId);

  const { statusType, quoteName = '', quoteId = '' } = summary || '';

  useEffect(() => {
    if (quoteID) getQuoteSummary({ quoteId: quoteID });
  }, [getQuoteSummary, quoteID]);

  useEffect(() => {
    setBreadcrumb({
      component: <Quotesdetailscontrol />,
      page: 'Buyer Quote Details',
      altname: quoteName,
      title: quoteName,
      name: quoteName,
      menuactive: 'quotes',
    });
  }, [quoteName, setBreadcrumb]);

  useEffect(() => {
    return () => {
      resetQuoteSummaryDetails();
    };
  }, [resetQuoteSummaryDetails]);

  /* useEffect(() => {
    let getquoteCommoditycodeCall = true;
    if (getquoteCommoditycodeCall && quoteId /* && summary )
      //getQuoteCommoditycodes({ quoteId: quoteId, type: 'quote' });
    return () => {
      getquoteCommoditycodeCall = false;
    };
  }, [getQuoteCommoditycodes, quoteId]); */

  useEffect(() => {
    if (quoteId) quoteSummaryGetDocuments({ quoteId });
  }, [quoteSummaryGetDocuments, quoteId]);

  useEffect(() => {
    if (quoteId) getQuoteResponseStatus();
  }, [getQuoteResponseStatus, quoteId]);

  useEffect(() => {
    if (quoteID && statusType !== 'IN')
      getQuoteResponses({ quoteId: quoteID, responseStatus: 'RS' });
  }, [quoteID, getQuoteResponses, statusType]);

  useEffect(() => {
    if (quoteId) getQuoteStatistics({ quoteId });
  }, [quoteId, getQuoteStatistics]);

  useEffect(() => {
    if (quoteId && statusType !== 'IN') loadBidsAuditTrial({ id: quoteId, type: 'Quote' });
  }, [quoteId, loadBidsAuditTrial, statusType]);

  useEffect(() => {
    if (quoteID) getQuoteLineItems({ quoteId: quoteID, pagefor: 'quotesummary' });
  }, [quoteID, getQuoteLineItems, statusType]);

  useEffect(() => {
    return () => {
      setQuoteSummaryDetails({ summary: '' });
    };
  }, [setQuoteSummaryDetails]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      navHistory.push(`/buyers/quotes/${quoteId}/${tab}`);
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    return () => {
      setQuoteSummaryDetails(initialStateQuoteSummary);
    };
  }, [setQuoteSummaryDetails]);

  return (
    <div className='container'>
      <div className='row detailViewWrapper'>
        <div className='col'>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 'details',
                })}
                onClick={() => {
                  toggle('details');
                }}
              >
                Quote Details
              </NavLink>
            </NavItem>
            {statusType !== 'IN' ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 'suppliers',
                  })}
                  onClick={() => {
                    toggle('suppliers');
                  }}
                >
                  Suppliers
                </NavLink>
              </NavItem>
            ) : null}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 'lineitems',
                })}
                onClick={() => {
                  toggle('lineitems');
                }}
              >
                Line Items
              </NavLink>
            </NavItem>
            {statusType !== 'IN' ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 'audittrail',
                    })}
                    onClick={() => {
                      toggle('audittrail');
                    }}
                  >
                    Audit Trail
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 'broadcasthistory',
                    })}
                    onClick={() => {
                      toggle('broadcasthistory');
                    }}
                  >
                    Broadcast History
                  </NavLink>
                </NavItem>
              </>
            ) : null}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='details'>
              <div className='row justify-content-center'>
                <div className='col-lg-12'>
                  <Details {...props} quoteId={quoteId} />
                </div>
              </div>
            </TabPane>
            {statusType !== 'IN' ? (
              <>
                <TabPane tabId='suppliers'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <Suppliers {...props} quoteId={quoteId} />
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId='audittrail'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <Audittrail {...props} quoteId={quoteId} pagefor='addquotes' />
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId='broadcasthistory'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <BroadcastHistory {...props} quoteId={quoteId} pagefor='addquotes' />
                    </div>
                  </div>
                </TabPane>
              </>
            ) : null}
            <TabPane tabId='lineitems'>
              <div className='row'>
                <div className='col-lg-12'>
                  <LineItems {...props} quoteId={quoteId} />
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
}

const connector = connect(
  (state: any) => ({
    quoteSummary: state.quoteSummary,
    shared: state.shared,
    memberinfo: state.memberinfo,
    auth: state.auth,
    addbid: state.addbid,
    addquotes: state.addquotes,
    bidssummary: state.bidssummary,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(memo(Quotesdetails)));
