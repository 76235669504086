import * as actionTypes from '../actionTypes';

interface initialStateTypes {
  internalLoaderPages: Array<[]>;
}

const initialState: initialStateTypes = {
  internalLoaderPages: [],
};

export type payloadTypes = {
  type: string;
  payload: any;
};

export const reducer = (state = initialState, { payload, type }: payloadTypes) => {
  switch (type) {
    case actionTypes.UPDATE_INTERNAL_LOADER.ACTION:
      if (payload.type === 'add' && !state.internalLoaderPages.includes(payload.page)) {
        state = {
          internalLoaderPages: [...state.internalLoaderPages, payload.page],
        };
      } else if (payload.type === 'remove') {
        const loadedPages = [...state.internalLoaderPages];
        const index = loadedPages.indexOf(payload.page);
        loadedPages.splice(index, 1);
        state = {
          ...state,
          internalLoaderPages: loadedPages,
        };
      }
      break;
    default:
      break;
  }
  return state;
};
