/**
 * Set a Typescript `enum` because it (or a `const`) can be used as a key/value lookup and also as a `type`.
 */
export enum ProductTypeEnum {
  'AG' = 'Free Agency',
  'CT' = 'County',
  'ST' = 'State',
  'NA' = 'Nation Wide',
}

/**
 * Generates a string union of ProductTypeEnum keys.
 */
export type ProductType = keyof typeof ProductTypeEnum;

/**
 * Type-safe expanded ProductType value.
 * @param key
 */
export function getProductTypeExpanded(key: ProductType): ProductTypeEnum {
  return ProductTypeEnum[key];
}

export type ProductApiResponse = {
  parentId?: number;
  parentName?: string;
  parentPrice?: number;
  parentType?: ProductType;
  price: number;
  productGroupId: number;
  productId: number;
  productName: string;
  productType: ProductType;
  sortOrder: number;
};

/**
 * This can be used with selected or subscribed
 */
export interface SubscriptionProducts {
  agency?: ProductApiResponse;
  county?: ProductApiResponse[];
  state?: ProductApiResponse[];
  national?: 0 | 10156;
}
