import React, { memo } from 'react';
import { DeprecatedRadio } from '../../../customcontrols';
import AddNewSupplier from './addnewsupplier';
import SearchSupplier from './searchsupplier';
import SupplierResult from './supplierresult';
import { bidssummaryParamType } from '../../../../types/biddetail';

interface PropsTypes {
  changeAddSupplierActive?: any;
  getBidsSearchPlanholders?: any;
  bidssummary?: bidssummaryParamType;
  bidId: number;
}

function addSupplier(props: PropsTypes) {
  const {
    changeAddSupplierActive,
    // resetSearchPlanholder,
    getBidsSearchPlanholders,
    bidssummary,
  } = props;
  const { searchPlanholder = [], addSupplierActive, modalType } = bidssummary || {};
  const { ...otherprops } = props;

  return (
    <>
      <div className='row clearfix mb-5'>
        {modalType === 'add' ? (
          <>
            <div className='col-12 d-flex justify-content-around'>
              <DeprecatedRadio
                title='Add Existing Supplier'
                handleSelect={() => {
                  changeAddSupplierActive({ addSupplierActive: 'search' });
                }}
                defaultChecked={addSupplierActive === 'search' ? true : false}
                value='search'
                ID='addSupplierRadio1'
                name='sample1'
              />
              <DeprecatedRadio
                title='Add New Supplier'
                handleSelect={() => {
                  changeAddSupplierActive({ addSupplierActive: 'add' });
                }}
                defaultChecked={addSupplierActive === 'add' ? true : false}
                value='add'
                ID='addSupplierRadio2'
                name='sample1'
              />
            </div>
          </>
        ) : null}
      </div>
      {addSupplierActive === 'search' && (
        <>
          {searchPlanholder.length === 0 ? (
            <SearchSupplier
              {...otherprops}
              onSubmit={getBidsSearchPlanholders}
              initialValues={{
                supplierName: '',
                city: '',
                phoneNumber: '',
                email: '',
                add1: '',
                add2: '',
              }}
            />
          ) : null}
          {searchPlanholder.length > 0 ? (
            <>
              <SupplierResult {...props} pagfor='searchPlanholder' />
            </>
          ) : null}
        </>
      )}

      {addSupplierActive === 'add' && <AddNewSupplier {...props} />}
    </>
  );
}

export default memo(addSupplier);
