import { createActionTypes, createNoLoadActionTypes } from './utils';
import { actionTypeDef } from '../../types/actiontypedef';

export const LOAD_ACCOUNT_INFO = createNoLoadActionTypes('LOAD_ACCOUNT_INFO') as actionTypeDef;
export const LOAD_ACCOUNT_INFO_COMMODITY = createNoLoadActionTypes(
  'LOAD_ACCOUNT_INFO_COMMODITY',
) as actionTypeDef;
export const LOAD_PROFILE_INFO = createNoLoadActionTypes('LOAD_PROFILE_INFO') as actionTypeDef;
export const UPDATE_MEMBER_INFO = createActionTypes('UPDATE_MEMBER_INFO') as actionTypeDef;
export const UPDATE_MEMBER_BILLING_INFO = createActionTypes(
  'UPDATE_MEMBER_BILLING_INFO',
) as actionTypeDef;
export const ADD_UPDATE_USER_ACCOUNT = createActionTypes(
  'ADD_UPDATE_USER_ACCOUNT',
) as actionTypeDef;
export const FORGOT_PASSWORD = createActionTypes('FORGOT_PASSWORD') as actionTypeDef;
export const RESET_ACCOUNTINFO_STATE = createNoLoadActionTypes(
  'RESET_ACCOUNTINFO_STATE',
) as actionTypeDef;
export const SAVE_SELFDECLARATIONS = createActionTypes('SAVE_SELFDECLARATIONS') as actionTypeDef;
export const SEARCH_PROGRAMS = createActionTypes('SEARCH_PROGRAMS') as actionTypeDef;
export const PROGRAMS_PAGE_CHANGE = createNoLoadActionTypes(
  'PROGRAMS_PAGE_CHANGE',
) as actionTypeDef;
export const ADD_MEMBER_PROGRAM_CERTIFICATION = createActionTypes(
  'ADD_MEMBER_PROGRAM_CERTIFICATION',
) as actionTypeDef;
export const GET_PROGRAM_ATTRIBUTES = createNoLoadActionTypes(
  'GET_PROGRAM_ATTRIBUTES',
) as actionTypeDef;
export const UPDATE_AUTO_RENEWAL_SUBSCRIPTION = createActionTypes(
  'UPDATE_AUTO_RENEWAL_SUBSCRIPTION',
) as actionTypeDef;
export const ADD_UPDATE_BT_PROFILE = createActionTypes('ADD_UPDATE_BT_PROFILE') as actionTypeDef;
export const GET_PAYMENT_HISTORY = createNoLoadActionTypes('GET_PAYMENT_HISTORY') as actionTypeDef;
export const GET_USER_ACCOUNTS = createNoLoadActionTypes('GET_USER_ACCOUNTS') as actionTypeDef;
export const GET_PERMISSIONS = createNoLoadActionTypes('GET_PERMISSIONS') as actionTypeDef;
export const REACTIVATE_USER_ACCOUNT = createActionTypes(
  'REACTIVATE_USER_ACCOUNT',
) as actionTypeDef;
export const UNBLOCK_USER_ACCOUNT = createActionTypes('UNBLOCK_USER_ACCOUNT') as actionTypeDef;
export const GET_ACCOUNT_INFO_MEMBER_DETAILS = createNoLoadActionTypes(
  'GET_ACCOUNT_INFO_MEMBER_DETAILS',
) as actionTypeDef;
export const SET_ACCOUNT_INFO_DETAILS = createNoLoadActionTypes(
  'SET_ACCOUNT_INFO_DETAILS',
) as actionTypeDef;
export const GET_WRITER_INFO = createNoLoadActionTypes('GET_WRITER_INFO');
export const GET_ACCOUNT_INFO_PUBLICATIONS = createNoLoadActionTypes(
  'GET_ACCOUNT_INFO_PUBLICATIONS',
) as actionTypeDef;
export const SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD = createActionTypes(
  'SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD',
) as actionTypeDef;
export const SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE = createActionTypes(
  'SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE',
) as actionTypeDef;
export const GET_ACCOUNT_INFO_BIDTYPES = createNoLoadActionTypes(
  'GET_ACCOUNT_INFO_BIDTYPES',
) as actionTypeDef;
export const SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD = createActionTypes(
  'SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD',
) as actionTypeDef;
export const SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE = createActionTypes(
  'SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE',
) as actionTypeDef;
export const GET_TEMPLATES_LIST = createActionTypes('GET_TEMPLATES_LIST') as actionTypeDef;
export const GET_ACCOUNT_INFO_REQUIRED_DOCS = createNoLoadActionTypes(
  'GET_ACCOUNT_INFO_REQUIRED_DOCS',
) as actionTypeDef;
export const UPDATE_NEW_LEGAL_TEMPLATE = createActionTypes(
  'UPDATE_NEW_LEGAL_TEMPLATE',
) as actionTypeDef;
export const SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE = createActionTypes(
  'SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE',
) as actionTypeDef;
export const GET_SAVED_LEGAL_TEMPLATE = createNoLoadActionTypes(
  'GET_SAVED_LEGAL_TEMPLATE',
) as actionTypeDef;
export const GET_ORDER_INFO = createNoLoadActionTypes('GET_ORDER_INFO') as actionTypeDef;
export const GET_ORDERS_LIST = createNoLoadActionTypes('GET_ORDERS_LIST') as actionTypeDef;
export const SET_ORDER_FILTERS = createNoLoadActionTypes('SET_ORDER_FILTERS') as actionTypeDef;
export const SUBMIT_REMOVE_CARD = createActionTypes('SUBMIT_REMOVE_CARD') as actionTypeDef;
export const RESET_USER_PASSWORD = createActionTypes('RESET_USER_PASSWORD') as actionTypeDef;
export const GET_PLANHOLDER_TYPES = createNoLoadActionTypes(
  'GET_PLANHOLDER_TYPES',
) as actionTypeDef;
export const RESET_ACCOUNT_INFO = createNoLoadActionTypes('RESET_ACCOUNT_INFO') as actionTypeDef;
export const TOGGLE_DOC_AUTO_APPROVAL = createActionTypes(
  'TOGGLE_DOC_AUTO_APPROVAL',
) as actionTypeDef;
export const GET_DOC_AUTO_APPROVAL_STATUS = createActionTypes(
  'GET_DOC_AUTO_APPROVAL_STATUS',
) as actionTypeDef;
export const GET_SUBSCRIPTION_STATE = createNoLoadActionTypes(
  'GET_SUBSCRIPTION_STATE',
) as actionTypeDef;
export const GET_SUBSCRIPTION_AGENCIES = createNoLoadActionTypes(
  'GET_SUBSCRIPTION_AGENCIES',
) as actionTypeDef;
export const GET_SUBSCRIPTION_COUNTY = createNoLoadActionTypes(
  'GET_SUBSCRIPTION_COUNTY',
) as actionTypeDef;
export const UPDATE_SUPPLIER_FREE_AGENCIES = createActionTypes(
  'UPDATE_SUPPLIER_FREE_AGENCIES',
) as actionTypeDef;
export const MODIFY_SUBSCRIPTION = createActionTypes('MODIFY_SUBSCRIPTION') as actionTypeDef;
export const RESET_MODIFY_SUBSCRIPTION = createActionTypes(
  'RESET_MODIFY_SUBSCRIPTION',
) as actionTypeDef;
export const CANCEL_SUBSCRIPTION = createActionTypes('CANCEL_SUBSCRIPTION') as actionTypeDef;
export const TOGGLE_SUPP_DOC_DOWNLOAD = createActionTypes(
  'TOGGLE_SUPP_DOC_DOWNLOAD',
) as actionTypeDef;
export const GET_SUPP_DOC_STATUS = createActionTypes('GET_SUPP_DOC_STATUS') as actionTypeDef;
