import React, { memo } from 'react';

interface PropsTypes {
  submit: any;
  pristine: Record<string, unknown> | undefined;
  cancel?: any;
}
const SelfDeclarationControl = (props: PropsTypes) => {
  const { submit, pristine /*, cancel*/ } = props;
  return (
    <>
      {pristine ? (
        <li className='disabled-bttn' title='Save Changes'>
          <span className=''>
            <i className='mdi mdi-content-save-outline mr-1' /> Save Changes
          </span>
        </li>
      ) : (
        <li title='Save Changes'>
          <span className='staticLink' onClick={() => submit()}>
            <i className='mdi mdi-content-save-outline mr-1' /> Save Changes
          </span>
        </li>
      )}

      {/*<li>
				<span className='staticLink' onClick={() => cancel()}>
					<i className='mdi mdi-close mr-1' />
					Cancel
				</span>
			</li>*/}
    </>
  );
};

export default memo(SelfDeclarationControl);
