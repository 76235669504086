import React, { memo, useState } from 'react';
import { Table } from 'reactstrap';
import { Buttons, DeprecatedInput, DeprecatedRadio } from '../../../customcontrols';
import { useDSSelector } from '../../../../store/reducers';
import { useDispatch } from 'react-redux';
import { setAddBidDetails, submitAddBidInfo } from '../../../../store/actions/addbid';

const tdWidth = { width: '150px' };

function DuplicateBid() {
  const dispatch = useDispatch();

  const addbidinfo = useDSSelector(state => state.addbid?.addbidinfo);

  const [duplicatebid, setDuplicatebid] = useState('usenew');
  const [newBidNumber, setNewBidNumber] = useState('');

  const SubmitDuplicate = () => {
    if (duplicatebid) {
      if (duplicatebid === 'usenew') {
        if (newBidNumber) {
          dispatch(setAddBidDetails({ newbidNumber: newBidNumber }));
          dispatch(submitAddBidInfo({ data: addbidinfo }));
        }
      } else if (duplicatebid === 'usecurrent') {
        dispatch(setAddBidDetails({ newbidNumber: '' }));
        dispatch(submitAddBidInfo({ data: addbidinfo }));
      }
    }
  };
  return (
    <>
      <Table borderless>
        <tr>
          <th style={tdWidth}>Bid Name</th>
          <td>{addbidinfo && addbidinfo.bidName}</td>
        </tr>
        <tr>
          <th style={tdWidth}>Fiscal Year</th>
          <td>{addbidinfo && addbidinfo.bidYear && addbidinfo.bidYear.label}</td>
        </tr>
        <tr>
          <th style={tdWidth}>Bid Number</th>
          <td>{addbidinfo && addbidinfo.bidNumber}</td>
        </tr>
        <tr>
          <th style={tdWidth}>&nbsp;</th>
          <td>
            <DeprecatedRadio
              name='duplicatebid'
              title='Re-use Bid Number'
              value='usecurrent'
              classNames='mt-0'
              defaultChecked={duplicatebid === 'usecurrent'}
              handleSelect={(name: string, value: string) => setDuplicatebid(value)}
            />
            <p className='mt-3 ml-3'>
              If re-use Bid Number option is selected, the bid will use the current bid number, and
              increment the sequence number by 1.{' '}
            </p>
          </td>
        </tr>
        <tr>
          <th style={tdWidth}>&nbsp;</th>
          <td>
            <DeprecatedRadio
              name='duplicatebid'
              title='New Bid Number'
              value='usenew'
              defaultChecked={duplicatebid === 'usenew'}
              handleSelect={(name: string, value: string) => setDuplicatebid(value)}
            />
            <p className='mt-3 ml-3'>
              If New Bid Number option is selected, a new bid number is required.{' '}
            </p>
            {duplicatebid === 'usenew' ? (
              <DeprecatedInput
                label='New Bid Number'
                name='newBidNumber'
                placeholder='Enter New Bid Number'
                handleChange={(name: string, value: string) => setNewBidNumber(value)}
                maxLength={50}
              />
            ) : null}
          </td>
        </tr>
      </Table>
      <Buttons
        classNames='bttn-secondary mt-4 float-right'
        text='Next'
        title='Next'
        type='button'
        onClick={SubmitDuplicate}
        disable={!duplicatebid || (duplicatebid === 'usenew' && !newBidNumber)}
      />
    </>
  );
}

export default memo(DuplicateBid);
