import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

export const initialStateBroadcastList = {
  preBuiltBroadCastList: [],
  supplierslist: [],
  newlistmodal: false,
  newlistmodalfor: '',
  broadcastListssearch: [],
  broadcastListssearchShow: false,
  savelistmodal: false,
  newlistname: '',
  savelistExistmodal: false,
  confirmDelete: false,
  action: '',
  allFiltersforBLEdit: [],
  selectedbroadcastlist: '',
  broadcastFilter: { filterState: [], filterCity: [], filterDeclaration: [], filterPrograms: [] },
};

export const reducer = (
  state = initialStateBroadcastList,
  { type, payload }: payloadTypes,
): any => {
  switch (type) {
    case actionTypes.SET_BROADCAST_LIST_DETAILS.TRIGGER:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_BROADCAST_LIST_SAVED_LISTS.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS.SUCCESS:
      state = {
        ...state,
        supplierslist: payload,
      };
      break;
    case actionTypes.GET_BROADCAST_LIST_SEARCH_VENDORS.SUCCESS:
    case actionTypes.SUBMIT_BROADCAST_LIST_ADD_LIST.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.SUBMIT_BROADCAST_LIST_DELETE_LIST.SUCCESS:
      state = {
        ...state,
        preBuiltBroadCastList: payload,
        selectedbroadcastlist: payload.length > 0 ? payload[0] : '',
        confirmDelete: false,
      };
      break;
    default:
      break;
  }
  return state;
};
