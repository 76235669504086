export interface LoaderState {
  isLoading: 0 | 1;
}

const initialState: LoaderState = {
  isLoading: 0,
};

const request = 'REQUEST';

export const reducer = (state: any, action: any) => {
  state = state || JSON.parse(JSON.stringify(initialState));
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)$/.exec(type);
  const noLoader = /^NO_LOADER_(.*)/.exec(type);
  if (!matches || noLoader) return state;
  const [, , requestState] = matches;
  return {
    ...state,
    isLoading: requestState === request ? state.isLoading + 1 : state.isLoading - 1,
  };
};
