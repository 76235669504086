import React, { memo, useEffect, useState } from 'react';
import { RegistrationWizardButtons } from './RegistrationWizardButtons';
import { Field, Formik } from 'formik';
import { generalInformationValidate } from './validations';
import renderDateField from '../../common/controls/inputs/datepicker';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import { DeprecatedFormikRadio } from '../../common/controls/inputs/DeprecatedFormikRadio';
import renderSelect from '../../common/controls/inputs/selectbox';
import { sharedParamType } from '../../../types/commoditycodes';
import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import { accountinfoType } from '../../../types/paymentdetails';
import { listParamType } from '../../../types/payments';
import { WizardPage } from '../../../types/wizard';
import moment from 'moment-timezone';
import { usaDateFormat } from '../../../utils/constants';
import { setWizardStatus } from '../../../utils/helpers';
import { useDispatch } from 'react-redux';
import { setAgencyRegistrationDetails } from '../../../store/actions';
import { useDSSelector } from '../../../store/reducers';

interface GeneralInformationProps {
  change?: any;
  handleSubmit?: any;
  pristine?: Record<string, unknown> | undefined;
  getStateList?: any;
  getCountiesList?: any;
  populateMetrodetails?: any;
  getplanholderTypes?: any;
  setBreadcrumb?: any;
  createBuyer?: any;
  shared?: sharedParamType;
  agencyregistration?: agencyRegistrationParamType;
  accountinfo?: accountinfoType;
  stateslist?: listParamType[];
  currentwizard?: WizardPage;
  match?: any;
  addagencyreduxForm?: any;
  initialValues: any;
  setFormInitialValues: any;
}

const GeneralInformation = (props: GeneralInformationProps) => {
  const dispatch = useDispatch();

  const {
    getStateList,
    getCountiesList,
    populateMetrodetails,
    getplanholderTypes,
    setBreadcrumb,
    shared,
    accountinfo,
    stateslist,
    currentwizard,
    initialValues,
    setFormInitialValues,
  } = props;

  const agencyRegistrationWizard = useDSSelector(
    state => state.agencyregistration.agencyRegistrationWizard,
  );
  const wizardcurrentpage = useDSSelector(state => state.agencyregistration.wizardcurrentpage);

  const { countrieslist = [], countieslist = [], timeZoneList = [] } = shared || {};
  const { planholderTypes = [] } = accountinfo || {};

  const [metroList, setMetroList] = useState([]);
  // const [zipcodeChange, setZipcodeChange] = useState(false)

  useEffect(() => {
    setBreadcrumb({
      component: '',
      page: 'Agency Creation',
      title: `Agency Creation - ${currentwizard && currentwizard.name}`,
      altname: `Agency Creation - ${currentwizard && currentwizard.name}`,
      name: 'Agency Creation',
      id: 'AgCR',
      menuactive: 'Agency',
    });
  }, [setBreadcrumb, currentwizard]);

  useEffect(() => {
    getplanholderTypes();
  }, [getplanholderTypes]);

  useEffect(() => {
    getStateList();
  }, [getStateList]);

  const populateMetro = (name: string, value: any, formData: any) => {
    if (value.length >= 5) {
      populateMetrodetails({ zip: value, formData, formPage: 'agencyregistration' });
      // setZipcodeChange(true)
    }
  };

  const onSubmitFn = (data: any) => {
    if (data) {
      dispatch(
        setAgencyRegistrationDetails({
          generalInfoFormInitialValues: data,
          agencyRegistrationWizard: setWizardStatus(
            agencyRegistrationWizard,
            wizardcurrentpage ? wizardcurrentpage + 1 : 0,
          ),
          wizardcurrentpage: wizardcurrentpage ? wizardcurrentpage + 1 : 0,
        }),
      );
      setFormInitialValues(data);
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={generalInformationValidate}
            onSubmit={onSubmitFn}
          >
            {formikProps => {
              const { handleSubmit, setFieldValue } = formikProps;

              const { memberStatus } = formikProps.values;

              return (
                <form onSubmit={handleSubmit}>
                  <h2 className='lineHR'>General Information</h2>
                  <h5>Create Your Username</h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        label='Login Username (Email)'
                        type='text'
                        classNames='class'
                        placeholder='Enter Login Email'
                        name='loginusername'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={80}
                      />
                    </div>
                  </div>
                  <h5>Main Contact Information </h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        label='First Name'
                        name='firstname'
                        type='text'
                        classNames='class'
                        placeholder='Enter First Name'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={25}
                      />
                      <Field
                        label='Last Name'
                        name='lastname'
                        type='text'
                        classNames='class'
                        placeholder='Enter Last Name'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={25}
                      />
                      <Field
                        label='Phone'
                        name='contactPhone'
                        type='text'
                        classNames='class'
                        placeholder='Enter Phone'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={17}
                      />
                      <Field
                        label='E-mail'
                        name='contactEmail'
                        type='text'
                        classNames='class'
                        placeholder='Enter E-mail'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <h5>Company Information</h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        label='Agency Name'
                        name='agencyname'
                        type='text'
                        classNames='class'
                        placeholder='Enter Agency Name'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={100}
                      />

                      <Field
                        name='timeZone'
                        label='Agency Timezone'
                        type='select'
                        component={renderSelect}
                        options={timeZoneList}
                        onChange={setFieldValue}
                        parentClass=''
                      />

                      <Field
                        name='country'
                        label='Country'
                        type='select'
                        component={renderSelect}
                        options={countrieslist}
                        parentClass='w-50'
                        onChange={(name: string, value: any) => {
                          if (value) {
                            getStateList(value.value);
                            getCountiesList('');
                          }
                          setFieldValue('country', value);
                          setMetroList([]);
                          setFieldValue('state', '');
                          setFieldValue('county', '');
                          setFieldValue('metro', '');
                        }}
                      />

                      <Field
                        label='Address 1'
                        name='address1'
                        type='text'
                        classNames='class'
                        component={renderDeprecatedTextField}
                        placeholder='Enter Address1'
                        onChange={setFieldValue}
                        maxLength={50}
                      />

                      <Field
                        label='Address 2'
                        name='address2'
                        type='text'
                        classNames='class'
                        component={renderDeprecatedTextField}
                        placeholder='Enter Address2'
                        optional={true}
                        onChange={setFieldValue}
                        maxLength={50}
                      />
                      <Field
                        label='Zip/Postal Code'
                        name='zipcode'
                        type='text'
                        classNames='class'
                        placeholder='Enter Zip/Postal Code'
                        component={renderDeprecatedTextField}
                        onChange={(name: any, value: any) => {
                          setFieldValue('zipcode', value);
                          populateMetro(name, value, { ...formikProps.values, zipcode: value });
                        }}
                        parentClass='w-48'
                        maxLength={30}
                      />
                      <div className='d-block clear-both'>
                        <Field
                          label='City'
                          name='city'
                          type='text'
                          classNames='class'
                          placeholder='Enter City'
                          parentClass='w-48 float-left'
                          component={renderDeprecatedTextField}
                          onChange={setFieldValue}
                          maxLength={50}
                        />

                        <Field
                          name='state'
                          label='State/Province'
                          type='select'
                          component={renderSelect}
                          options={stateslist}
                          parentClass='w-48 float-right'
                          onChange={(name: string, value: any) => {
                            setFieldValue('state', value);
                            getCountiesList(value ? value.value : '');
                            setFieldValue('county', '');
                            //setFieldValue('metro', '');
                            //setMetroList([]);
                          }}
                        />
                      </div>
                      <div className='d-block clear-both'>
                        <Field
                          name='county'
                          label='County'
                          type='select'
                          component={renderSelect}
                          options={countieslist}
                          parentClass='w-48 float-left'
                          optional={true}
                          onChange={setFieldValue}
                        />

                        <Field
                          name='metro'
                          label='Metro Area'
                          type='select'
                          component={renderSelect}
                          options={metroList}
                          parentClass='w-48 float-right'
                          disabled={'true'}
                          onChange={setFieldValue}
                        />
                      </div>

                      <Field
                        label='Agency Phone'
                        name='buyerPhone'
                        type='text'
                        classNames='class'
                        placeholder='Enter Agency Phone'
                        parentClass='clear-both'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={17}
                      />

                      <Field
                        label='Website'
                        name='website'
                        type='text'
                        classNames='class'
                        placeholder='Enter Website'
                        component={renderDeprecatedTextField}
                        optional={true}
                        onChange={setFieldValue}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <h5>Operations Functions</h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        name='memberStatus'
                        title='Active'
                        component={DeprecatedFormikRadio}
                        type='radio'
                        value='AC'
                        handleSelect={(name: string, value: boolean) => {
                          setFieldValue('memberStatus', value);
                        }}
                        defaultChecked={memberStatus === 'AC' ? true : false}
                      />

                      <Field
                        name='memberStatus'
                        title='Preliminary Agency'
                        component={DeprecatedFormikRadio}
                        type='radio'
                        ID='addSupplierRadio1'
                        value='PR'
                        handleSelect={(name: string, value: boolean) => {
                          setFieldValue('memberStatus', value);
                        }}
                        defaultChecked={memberStatus === 'PR' ? true : false}
                      />
                      <Field
                        label='Implementation Date'
                        classNames='class'
                        name='initialDate'
                        component={renderDateField}
                        parentClass='clear-both mt-5'
                        optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                        onChange={setFieldValue}
                        minDate=''
                      />

                      <Field
                        name='planholderDisplayType'
                        label='Planholder Display'
                        type='select'
                        component={renderSelect}
                        options={planholderTypes}
                        parentClass=''
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <RegistrationWizardButtons {...props} />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default memo(GeneralInformation);
