import styled from 'styled-components';

import {
  BackgroundColor,
  BorderRadius,
  FontFamily,
  FontWeight,
  LineHeight,
  Margin,
  Padding,
  ParagraphFontSize,
  TextColor,
} from '../styles';

import { Status } from '../constants';

enum PillColor {
  Success = BackgroundColor.Success,
  Warning = BackgroundColor.Warning,
  Error = BackgroundColor.Error,
  Base = BackgroundColor.BaseGray,
}

enum PillTextColor {
  Light = TextColor.Contrast,
  Dark = TextColor.Base,
}

interface StatusColorMap {
  status?: Status;
  color: PillTextColor;
  background: PillColor;
}

const defaultColorMap: StatusColorMap = {
  color: PillTextColor.Dark,
  background: PillColor.Base,
};

const statusColorMaps: StatusColorMap[] = [
  {
    status: Status.Success,
    color: PillTextColor.Light,
    background: PillColor.Success,
  },
  {
    status: Status.Warning,
    color: PillTextColor.Dark,
    background: PillColor.Warning,
  },
  {
    status: Status.Error,
    color: PillTextColor.Light,
    background: PillColor.Error,
  },
];

export const getStatusColorMap = (status?: Status) => {
  const colorMap = statusColorMaps.filter(scm => scm.status === status)[0] || defaultColorMap;

  return {
    ...colorMap,
  };
};

// TODO: TS4 - Additional Styled props need to be typed otherwise they are an implicit any
export const PillContainer = styled.div<any>`
  align-items: center;
  background: ${props => props.background};
  border-radius: ${BorderRadius.Rounded};
  color: ${props => props.color};
  display: inline-flex;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Small};
  font-style: normal;
  font-weight: ${FontWeight.Base};
  height: ${LineHeight.Base};
  justify-content: center;
  letter-spacing: 0em;
  margin: ${Margin.None} ${Margin.Less} ${Margin.None} ${Margin.None};
  min-width: auto;
  padding: ${Padding.None} ${Padding.Less};
  text-align: left;
  vertical-align: top;
  width: auto;

  & span {
    flex-grow: 0;
    flex: none;
    line-height: ${LineHeight.Base};
    margin: ${Margin.None} ${Margin.Less};
    order: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

// TODO: TS4 - Additional Styled props need to be typed otherwise they are an implicit any
export const PillIcon = styled.i<any>`
  cursor: pointer;
  order: ${props => props.order};
`;
