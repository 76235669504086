import React, { memo } from 'react';

function ChooseFreeAgency() {
  return (
    <>
      <h2>ChooseFreeAgency</h2>
      test
    </>
  );
}

export default memo(ChooseFreeAgency);
