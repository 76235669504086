import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import LogoDSLoader from '../../../assets/images/loader';
import { loadingMsg } from '../../../utils/texts';

const loaderElement = document.getElementById('loaderOverlay') as HTMLElement;

function Suspenseloader() {
  return createPortal(
    <div className='overlay'>
      <div className='progressbox'>
        <div>
          <LogoDSLoader alt={loadingMsg} />
        </div>
      </div>
    </div>,
    loaderElement,
  );
}

export default memo(Suspenseloader);
