import React from 'react';
import { Buttons, DeprecatedInput } from '../../customcontrols';
import { Field, Formik } from 'formik';
import { mainContactValidation } from '../../common/companyaddressform/validations';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import { registrationComponent } from '../../../utils/constants';

const CompanyMainContact = (props: any) => {
  const {
    registration,
    subscriptions,
    addInCompleteRegistrationData,
    addMemberAccount,
    setRegistrationData,
  } = props;
  const { registrationData = {} } = registration;
  const { mainContact = {} } = registrationData;
  const { subscribedCounties = [], subscribedStates = [], subscribedNational = [] } = subscriptions;

  const onSubmitFn = (values: any, form: any) => {
    setRegistrationData({ registrationData: { ...registrationData, mainContact: values } });
    addMemberAccount();
  };

  return (
    <>
      <div className='unAuthWrapper'>
        <div className='container pt-5 regWrapper'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <h2 className='arrowWrapper'>Your Contact Information</h2>
                <div className='row d-flex justify-content-center'>
                  <div className='col-12 col-md-8 col-lg-7'>
                    <p className='mb-4'>
                      Bid responses can be a team effort, so we let you add multiple users to
                      DemandStar at no extra cost. The user you’re creating now will be the
                      administrator for your company's account. Creating individual users instead of
                      sharing an account lets you set permissions, identify who at your company took
                      certain actions, and lets us know who to talk to if there are any questions.
                    </p>
                    <Formik
                      enableReinitialize
                      initialValues={{ ...mainContact, email: registrationData.emailAddress }}
                      validate={mainContactValidation}
                      onSubmit={onSubmitFn}
                    >
                      {formikProps => {
                        const {
                          values,
                          // errors,
                          // touched,
                          // handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          // setFieldTouched,
                          // setSubmitting,
                          // resetForm,
                          isValidating,
                          // setFieldError,
                          validateForm,
                          /* and other goodies */
                        } = formikProps;

                        return (
                          <form onSubmit={handleSubmit}>
                            <Field
                              label='First Name'
                              name='firstName'
                              placeholder='First Name'
                              component={renderDeprecatedTextField}
                              onChange={setFieldValue}
                              maxLength={25}
                            />
                            <Field
                              label='Last Name'
                              name='lastName'
                              placeholder='Last Name'
                              component={renderDeprecatedTextField}
                              onChange={setFieldValue}
                              maxLength={25}
                            />
                            <Field
                              label='Phone Number'
                              name='phone'
                              placeholder='Phone Number'
                              component={renderDeprecatedTextField}
                              onChange={setFieldValue}
                              maxLength={17}
                            />
                            <Field
                              label='Email Address'
                              name='email'
                              placeholder='Email Address'
                              component={renderDeprecatedTextField}
                              onChange={setFieldValue}
                              maxLength={100}
                            />
                            <div className='d-flex justify-content-between clear-both pt-3'>
                              <Buttons
                                type='button'
                                onClick={() =>
                                  setRegistrationData({
                                    currentComponent: registrationComponent.Company,
                                  })
                                }
                                classNames='bttn-secondary'
                                text='Back'
                              />
                              <Buttons type='submit' classNames='bttn-primary' text={'Submit'} />
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyMainContact;
