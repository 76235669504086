import React, { memo } from 'react';
import { Table } from 'reactstrap';
const tdWidth = {
  width: '240px',
};

interface PropsTypes {
  filteredStates: string;
  filteredCities: string;
  filteredDeclarations: string;
  filteredPrograms: string;
  selfdeclaredattributes: Array<{ attributeType: string; attributeName: string }>;
}

function BroadcastFilter(props: PropsTypes) {
  const {
    filteredStates,
    filteredCities,
    filteredDeclarations,
    filteredPrograms,
    selfdeclaredattributes,
  } = props;
  const state = (filteredStates && filteredStates.split(',')) || [];
  const city = (filteredCities && filteredCities.split(',')) || [];
  const declaration = (filteredDeclarations && filteredDeclarations.split(',')) || [];
  const programs = (filteredPrograms && filteredPrograms.split(',')) || [];

  const selfdeclaration =
    selfdeclaredattributes
      .filter(item => declaration.includes(item.attributeType))
      .map(item => item.attributeName) || [];

  return (
    <>
      <Table borderless className='tableData'>
        <tbody>
          <tr>
            <th style={tdWidth}>State</th>
            <td>{state.map((item, index) => `${item} ${index + 1 < state.length ? ',' : ''}`)}</td>
          </tr>
          <tr>
            <th>City</th>
            <td>{city.map((item, index) => `${item} ${index + 1 < city.length ? ',' : ''}`)}</td>
          </tr>
          <tr>
            <th>Self Declaration</th>
            <td>
              {selfdeclaration.map(
                (item, index) => `${item} ${index + 1 < selfdeclaration.length ? ',' : ''}`,
              )}
            </td>
          </tr>
          <tr>
            <th>Certifications</th>
            <td>
              {programs.map((item, index) => `${item} ${index + 1 < programs.length ? ',' : ''}`)}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default memo(BroadcastFilter);
