import React, { memo } from 'react';
import { Field, Formik } from 'formik';
import renderCheckbox from '../../../common/controls/inputs/checkbox';
import { DeprecatedFormikRadio } from '../../../common/controls/inputs/DeprecatedFormikRadio';
import renderSelect from '../../../common/controls/inputs/selectbox';
import renderToggleBtn from '../../../common/controls/inputs/togglebtn';
import { MemberStatus, memberTypes } from '../../../../utils/constants';
import { validate } from '../../../common/addressreduxform/validations';
import { sharedTypes } from '../../../../types/shared';

interface PropsTypes {
  auth?: {
    mt: string | undefined;
  };
  initialValues: {
    status: string | undefined;
    doNotNotify: boolean | undefined;
    planholderDisplay: string | Record<string, unknown> | undefined;
    isFreeDocument: boolean | undefined;
    timeZoneId: string | { timeZone: number } | undefined;
  };
  accountinfo: any;
  shared?: sharedTypes | undefined;
  setAccountInfoDetails?: any;
}

function OperationFunctionsForm(props: PropsTypes) {
  const { auth, initialValues, accountinfo, shared, setAccountInfoDetails } = props;
  const { mt: memberType } = auth || {};
  const { timeZoneList = [] } = shared || {};

  const { planholderTypes = [] } = accountinfo || {};

  /* useEffect(() => {
    setAccountInfoDetails({opsFormValues:initialValues});
  }, [initialValues, setAccountInfoDetails]); */

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={() => {}}
    >
      {formikProps => {
        const { handleSubmit, setFieldValue } = formikProps;

        const { status } = formikProps.values;

        return (
          <form id='opsForm' onSubmit={handleSubmit}>
            <h6 className='mb-4'>
              {memberType === memberTypes.agencyBuyer ? 'Buyer' : 'Supplier'} Status
            </h6>
            {MemberStatus.map((item, index) => (
              <Field
                name='status'
                type='radio'
                component={DeprecatedFormikRadio}
                title={item.label}
                classNames='class'
                value={item.value}
                defaultChecked={item.value === status}
                key={index}
                handleSelect={(name: string, value: any) => {
                  setFieldValue('status', value);
                  setAccountInfoDetails({
                    opsFormValues: { ...formikProps.values, status: value },
                  });
                }}
              />
            ))}
            {memberType !== memberTypes.agencyBuyer ? (
              <>
                <Field
                  name={'doNotNotify'}
                  component={renderCheckbox}
                  title={'Do Not Contact'}
                  classNames='class'
                  value={formikProps.values.doNotNotify}
                  handleChange={(name: string, value: any) => {
                    setFieldValue('doNotNotify', value);
                    setAccountInfoDetails({
                      opsFormValues: { ...formikProps.values, doNotNotify: value },
                    });
                  }}
                />
              </>
            ) : (
              <>
                <Field
                  name='planholderDisplay'
                  label='Planholder Display'
                  type='select'
                  component={renderSelect}
                  options={planholderTypes.map((item: any) => ({
                    ...item,
                    label: item.planholderDisplayDesc,
                    value: item.planholderDisplayType,
                  }))}
                  parentClass='mt-5'
                  onChange={(name: string, value: any) => {
                    setFieldValue('planholderDisplay', value);
                    setAccountInfoDetails({
                      opsFormValues: { ...formikProps.values, planholderDisplay: value },
                    });
                  }}
                />
                <Field
                  name='timeZoneId'
                  label='Timezone'
                  type='select'
                  component={renderSelect}
                  options={timeZoneList}
                  onChange={(name: string, value: any) => {
                    setFieldValue('timeZoneId', value);
                    setAccountInfoDetails({
                      opsFormValues: { ...formikProps.values, timeZoneId: value },
                    });
                  }}
                />
                <Field
                  label='Free Document Download'
                  title='Free Document Download'
                  name='isFreeDocument'
                  component={renderToggleBtn}
                  handleChange={(name: string, value: boolean) => {
                    setFieldValue('isFreeDocument', value);
                    setAccountInfoDetails({
                      opsFormValues: { ...formikProps.values, isFreeDocument: value },
                    });
                  }}
                  optional={true}
                />
              </>
            )}
          </form>
        );
      }}
    </Formik>
  );
}

export default memo(OperationFunctionsForm);
