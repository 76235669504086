import { getBidSummary } from '../../store/services';
import { track } from '../../utils/telemetry';

/**
 * Async service call to get BidSummary by `bidId` with try/catch wrapping
 */
export async function getBidSummaryFromService(bidId: number) {
  try {
    const bidSummary = await getBidSummary(bidId);
    return bidSummary;
  } catch (error: any) {
    const errorMessage = `useBidInfo -> getBidSummaryFromService() -> getBidSummary(bidId: ${bidId} ERROR:`;
    track(errorMessage, {
      error,
      method: 'POST',
      errorMessage: error.message,
    });
    console.error(`${errorMessage}: \n${error}`);
    return null;
  }
}
