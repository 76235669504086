import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes, SiblingProductPayload } from '../../types/actiontypedef';
import { nargsfnTypes } from '../../types/functions';
import { sharedTypes } from '../../types/shared';
import { SharedState } from '../reducers/shared';

export function getLocationList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_LOCATION_LIST, payload);
}

export function clearAppState(payload: actionPayloadTypes) {
  return action(actionTypes.CLEAR_APP_STATE, payload);
}

export function getStateList(payload?: actionPayloadTypes) {
  return action(actionTypes.GET_STATES_LIST, payload);
}

export function getCommoditiesList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_COMMODITIES_LISTS, payload);
}

export function getSharedBidStatus(payload: actionPayloadTypes) {
  return action(actionTypes.GET_SHARED_BID_STATUS, payload);
}

export function getShowBids(payload: actionPayloadTypes) {
  return action(actionTypes.GET_SHOW_BIDS, payload);
}

export function getCountiesList(payload?: string) {
  // are we confident this shouldn't be a number?
  return action(actionTypes.GET_COUNTIES_LIST, payload);
}

type SetSharedDetailsPayload = Partial<SharedState>;

export function setSharedDetails(payload: SetSharedDetailsPayload) {
  return action(actionTypes.SET_SHARED_DETAILS, payload);
}

export function getScrapers(payload: actionPayloadTypes) {
  return action(actionTypes.GET_SCRAPERS, payload);
}

export function getSelfDeclarations(payload: actionPayloadTypes) {
  return action(actionTypes.GET_SELF_DECLARATION, payload);
}

export function getDashboardData(payload: actionPayloadTypes) {
  return action(actionTypes.GET_DASHBOARD_DATA, payload);
}

export function getSiblingProducts(payload: SiblingProductPayload) {
  return action(actionTypes.GET_SIBLING_PRODUCTS, payload);
}

export function getRelatedBids(payload: actionPayloadTypes) {
  return action(actionTypes.GET_RELATED_BIDS, payload);
}

export function getAgenciesList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_AGENCIES_LIST, payload);
}

export function getFiscalYear(payload: actionPayloadTypes) {
  return action(actionTypes.GET_FISCAL_YEAR, payload);
}

export function getResponseDisplays(payload: actionPayloadTypes) {
  return action(actionTypes.GET_RESPONSE_DISPLAYS, payload);
}

export function getDashboardBidsList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_DASHBOARD_BIDS_LIST, payload);
}

export function getDashboardQuotesList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_DASHBOARD_QUOTES_LIST, payload);
}

export function getDashboardNetwork(payload: actionPayloadTypes) {
  return action(actionTypes.GET_DASHBOARD_NETWORK, payload);
}

export function getdashboardNotifications(payload: actionPayloadTypes) {
  return action(actionTypes.GET_DASHBOARD_NOTIFICATIONS, payload);
}

export function submitDisablenotification(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_DASHBOARD_DISABLE_NOTIFICATIONS, payload);
}

export function getTopCommodities(payload: actionPayloadTypes) {
  return action(actionTypes.GET_TOP_COMMODITIES, payload);
}

export function getThemes(payload: actionPayloadTypes = undefined) {
  return action(actionTypes.GET_THEMES, payload);
}

export function setThemes(payload: actionPayloadTypes) {
  return action(actionTypes.SET_THEMES, payload);
}

export function resetShared(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_SHARED, payload);
}

export function getOtherMemberInfo(payload: actionPayloadTypes) {
  return action(actionTypes.GET_OTHER_MEMBER_INFO, payload);
}

export function populateMetrodetails(payload: actionPayloadTypes) {
  return action(actionTypes.POPULATE_METRO_DETAILS, payload);
}

export function getTimezoneList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_TIMEZONE_LIST, payload);
}

export function getWatchedBidIds(payload: actionPayloadTypes) {
  return action(actionTypes.GET_WATCHED_BIDIDS, payload);
}

export function submitTrackEvents(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_TRACK_EVENTS, payload);
}

export function getContractInterest(payload: actionPayloadTypes) {
  return action(actionTypes.GET_CONTRACT_INTEREST, payload);
}
export function updateContractInterest(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_CONTRACT_INTEREST, payload);
}
export function getAmplitudeUserProperties(payload: actionPayloadTypes) {
  return action(actionTypes.GET_AMPLITUDE_USER_PROPERTIES, payload);
}
export function getDynamicProductPrices(payload: actionPayloadTypes = undefined) {
  return action(actionTypes.GET_DYNAMIC_PRODUCT_PRICES, payload);
}
