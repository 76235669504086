import React, { MouseEvent, ReactNode } from 'react';

import { Table } from 'reactstrap';

import { downloadAllResponseDocuments, downloadResponseDocument } from '../../../../store/services';
import { Buttons } from '../../../../components/customcontrols';
import { displayDate } from '../../../../utils/helpers';
import { downloadMessages } from '../../../../shared/constants';
import { DocItemIconClassNames, DocItemIconCode, DSDocument } from '../../../../types/document';
import { useSelectedResponseId } from '../../../../shared/hooks/useSelectedResponseId';

const tdWidth = { type: { width: '25%' }, date: { width: '18%' } };

const handleDownloadClick = (docId: number | string, bidId: number | string | null) => (
  e: MouseEvent<ReactNode>,
) => {
  e.preventDefault();
  downloadResponseDocument({ docId, id: bidId, type: 'Bid' });
};

const handleDownloadAllClick = (responseId: string | number | null) => (
  e: MouseEvent<ReactNode>,
) => {
  downloadAllResponseDocuments({ id: responseId, type: 'Response' });
};

/**
 * @todo Replace with styled-components
 */
function getIconStyleName(icon: DocItemIconCode = '') {
  return DocItemIconClassNames[icon];
}
interface DocumentsPanelProps {
  documents: DSDocument[];
  documentTitle: string;
  bidId: string | number | null;
  documentType: string;
  checkduedate?: boolean;
}

export function DocumentsPanel(props: DocumentsPanelProps) {
  const { selectedResponseId: responseId } = useSelectedResponseId();

  const { bidId, checkduedate = true, documents: data, documentTitle, documentType } = props;
  const sortedData = [...data];
  // TODO: Implement react-table with sortable columns
  let isElectronicDocAvailable = false;
  if (data && data.length > 0) {
    const elDocs = data.filter(doc => doc.icon === 'EL');
    if (elDocs && elDocs.length > 0) {
      isElectronicDocAvailable = true;
    }
  }

  if (sortedData && sortedData.length > 0) {
    // TODO: update styles with styled-components.
    return (
      <>
        <h4>{documentTitle}</h4>
        {documentTitle === 'Required Documents' &&
        checkduedate === true &&
        isElectronicDocAvailable === true ? (
          <Buttons
            classNames='bttn-primary small float-right'
            title='One-click Bid Response Download'
            text={'Download Entire Bid Package'}
            onClick={handleDownloadAllClick(responseId)}
          />
        ) : null}
        {isElectronicDocAvailable === true ? null : (
          <h6 className='float-right text-red200 clear-left'>(No document uploaded)</h6>
        )}
        <div className='innerColIndent'>
          {Number(documentType) === 1 ? (
            <ul className='list-unstyled'>
              {sortedData.map((doc: any, id) => {
                const { bidDocId, description, format, icon, statusName } = doc;
                let fileName = `${description}`;
                if (format) {
                  fileName = `${fileName}.${format.toLowerCase().trim()}`;
                }
                return (
                  <li key={id}>
                    <span
                      className={`
											${icon === 'EL' && bidDocId ? 'successTick' : ''}
											${icon === 'MN' ? 'manual' : ''}
											${icon === 'NS' || (icon === 'EL' && !bidDocId) ? 'notSubmit' : ''}
											${icon === 'None' || !icon ? 'noneDot' : ''}
									`}
                    />
                    {bidDocId ? (
                      <>
                        {checkduedate === true ? (
                          <span
                            className='staticLink underLine'
                            onClick={handleDownloadClick(bidDocId, bidId)}
                            title={`${downloadMessages.documentLinkPrefix} ${fileName}`}
                          >
                            {fileName}
                          </span>
                        ) : (
                          <span title={fileName}>{fileName}</span>
                        )}
                      </>
                    ) : (
                      <>{fileName}</>
                    )}
                    {statusName && <> ({statusName})</>}
                  </li>
                );
              })}
            </ul>
          ) : (
            <Table className='tableFixed'>
              <tbody>
                <tr>
                  <th>Filename</th>
                  <th style={tdWidth.type}>Type</th>
                  <th style={tdWidth.date}>Date Modified</th>
                </tr>
                {sortedData.map(doc => {
                  const { bidDocStatusType, datePosted, bidDocId, docFormat, title } = doc;

                  const fileName = `${title}.${docFormat?.toLowerCase().trim()}`;

                  return (
                    <tr key={bidDocId}>
                      <td className='text-truncate'>
                        <span
                          className='staticLink'
                          onClick={handleDownloadClick(bidDocId, bidId)}
                          title={`${downloadMessages.documentLinkPrefix} ${fileName}`}
                        >
                          {fileName}
                        </span>
                      </td>
                      <td>{bidDocStatusType}</td>
                      <td>{displayDate(datePosted || '')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
