import * as actionTypes from '../actionTypes';
import { Tour, TourStep } from '../../types/tours';
import { payloadTypes } from '../../types/actiontypedef';
import { TourId } from '../../utils/constants';
import { ReactNode } from 'react';
import { TourConfig } from '../../utils/tourconfig';

export interface ToursState {
  activateTour: boolean;
  closeWithMask: boolean;
  currentStep: number;
  disableFocusLock: boolean;
  disableInteraction: boolean;
  disableKeyboardNavigation: boolean | ('esc' | 'right' | 'left')[];
  internalConfig: boolean;
  internaltourConfig: TourStep[];
  lastStepNextButton: ReactNode;
  nextButton: ReactNode;
  prevButton: ReactNode;
  showButtons: boolean;
  showCloseButton: boolean;
  showNavigation: boolean;
  showNumber: boolean;
  startAt: number;
  tourConfig: TourConfig[];
  tourId: TourId | 0;
  tourList: Tour[];
  tourStart: boolean;
}

export const initialBasicTour = {
  currentStep: 0,
  internalConfig: false,
  internaltourConfig: [],
  nextButton: '',
  prevButton: '',
  startAt: 0,
  tourConfig: [],
  tourId: 0,
  tourStart: false,
};

export const initialToursState: ToursState = {
  activateTour: true,
  closeWithMask: false,
  currentStep: 0,
  disableFocusLock: true,
  disableInteraction: false,
  disableKeyboardNavigation: ['esc'],
  internalConfig: false,
  internaltourConfig: [],
  lastStepNextButton: '',
  nextButton: '',
  prevButton: '',
  showButtons: true,
  showCloseButton: true,
  showNavigation: false,
  showNumber: true,
  startAt: 0,
  tourConfig: [],
  tourId: 0,
  tourList: [],
  tourStart: false,
};

export const reducer = (state = initialToursState, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.SET_TOURS_DETAILS.ACTION:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_TOURS_CONFIGURATIONS.SUCCESS:
      state = { ...state, activateTour: payload.length > 0, tourList: payload };
      break;
    case actionTypes.GET_TOURS_CONFIGURATIONS.FAILURE:
      state = { ...state, activateTour: false, tourList: [] };
      break;
    case actionTypes.RESET_BASIC_TOURS.ACTION:
      state = { ...state, ...payload };
      break;
    case actionTypes.RESET_TOURS.ACTION:
      state = initialToursState;
      break;
    default:
      break;
  }
  return state;
};
