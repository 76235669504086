import { createActionTypes, createNoLoadActionTypes } from './utils';

export const SET_AGENCY_REGISTRATION_DETAILS = createNoLoadActionTypes(
  'SET_AGENCY_REGISTRATION_DETAILS',
);
export const CREATE_BUYER = createActionTypes('CREATE_BUYER');
export const ADD_AGENCY_BID_TYPES = createNoLoadActionTypes('ADD_AGENCY_BID_TYPES');
export const GET_ADD_AGENCY_LEGAL_AD = createNoLoadActionTypes('GET_ADD_AGENCY_LEGAL_AD');
export const SUBMIT_AGENCY_LEGAL_AD = createActionTypes('SUBMIT_AGENCY_LEGAL_AD');
export const AGENCY_ADD_PUBLICATIONS = createActionTypes('AGENCY_ADD_PUBLICATIONS');
export const AGENCY_ADD_EBID_DOCUMENTS = createActionTypes('AGENCY_ADD_EBID_DOCUMENTS');
export const AGENCY_GET_PUBLICATIONS_LIST = createNoLoadActionTypes('AGENCY_GET_PUBLICATIONS_LIST');
export const AGENCY_GET_REQUIRED_DOCS = createNoLoadActionTypes('AGENCY_GET_REQUIRED_DOCS');
export const AGENCY_GET_BIDTYPES = createNoLoadActionTypes('AGENCY_GET_BIDTYPES');
export const AGENCY_GENERAL_INFO_INITIAL_VALUES = createActionTypes(
  'AGENCY_GENERAL_INFO_INITIAL_VALUES',
);
export const RESET_AGENCY_DETAILS = createNoLoadActionTypes('RESET_AGENCY_DETAILS');
export const AGENCY_REMOVE_BIDTYPE = createNoLoadActionTypes('AGENCY_REMOVE_BIDTYPE');
export const AGENCY_REMOVE_PUBLICATION = createNoLoadActionTypes('AGENCY_REMOVE_PUBLICATION');
export const GET_AGENCY_SETTINGS = createActionTypes('GET_AGENCY_SETTINGS');
export const UPDATE_AGENCY_SETTINGS = createActionTypes('UPDATE_AGENCY_SETTINGS');
