import { createActionTypes, createNoLoadActionTypes } from './utils';

export const SET_ACTIVITIES_DETAILS = createNoLoadActionTypes('SET_ACTIVITIES_DETAILS');
export const RESET_ACTIVITIES_DETAILS = createNoLoadActionTypes('RESET_ACTIVITIES_DETAILS');
export const GET_ACTIVITIES_NOTIFICATIONS = createNoLoadActionTypes('GET_ACTIVITIES_NOTIFICATIONS');
export const GET_ACTIVITIES_NOTIFICATIONS_MEMBER = createNoLoadActionTypes(
  'GET_ACTIVITIES_NOTIFICATIONS_MEMBER',
);
export const GET_ACTIVITIES_WATCHS = createNoLoadActionTypes('GET_ACTIVITIES_WATCHS');
export const GET_ACTIVITIES_PLANHOLDERS = createNoLoadActionTypes('GET_ACTIVITIES_PLANHOLDERS');
export const GET_ACTIVITIES_EBIDDERS = createNoLoadActionTypes('GET_ACTIVITIES_EBIDDERS');
export const GET_ACTIVITIES_QUOTES = createNoLoadActionTypes('GET_ACTIVITIES_QUOTES');
export const GET_ACTIVITIES_SEARCH = createNoLoadActionTypes('GET_ACTIVITIES_SEARCH');
export const GET_ACTIVITIES_BIDREPORT = createNoLoadActionTypes('GET_ACTIVITIES_BIDREPORT');
export const EXPORT_ACTIVITIES_BIDREPORT = createActionTypes('EXPORT_ACTIVITIES_BIDREPORT');
export const GET_ACTIVITIES_QUOTEREPORT = createNoLoadActionTypes('GET_ACTIVITIES_QUOTEREPORT');
export const EXPORT_ACTIVITIES_QUOTEREPORT = createActionTypes('EXPORT_ACTIVITIES_QUOTEREPORT');
