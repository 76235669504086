import React, { useEffect } from 'react';
import * as actionCreators from '../../../store/actions';

import { connect, ConnectedProps } from 'react-redux';
import { ContractConfirm, ContractSignup } from './';

import { authTypes } from '../../../types/auth';
import { bindActionCreators } from 'redux';
import { contractManagementLabels } from '../constants';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface PropTypes extends PropsFromRedux {
  shared: { contractInterest: boolean };
  auth: authTypes;
  getContractInterest: any;
  setBreadcrumb: any;
  updateContractInterest: any;
}

function ContractManagementComponent(props: PropTypes) {
  const { shared, getContractInterest, updateContractInterest } = props;

  const { contractInterest = false } = shared;

  useEffect(() => {
    getContractInterest({});
  }, [getContractInterest]);

  useSetBreadcrumb({
    altname: contractManagementLabels.title,
    menuactive: 'contract',
    name: contractManagementLabels.title,
    page: contractManagementLabels.title,
    title: contractManagementLabels.title,
  });

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='colWrapper'>
              <h2 className='arrowWrapper'>{contractManagementLabels.title}</h2>
              {contractInterest ? (
                <ContractConfirm {...props} />
              ) : (
                <ContractSignup getContractInterest={updateContractInterest} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const connector = connect(
  (state: any) => ({ shared: state.shared, auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ContractManagement = connector(ContractManagementComponent);
