import React, { memo } from 'react';
import { BulkUploadErrorMessge } from '../../../../utils/constants';

interface PropsTypes {
  setAddBidDetails: any;
  error?: BulkUploadErrorMessge;
}

const ErrorBlock = (props: PropsTypes) => {
  const { setAddBidDetails, error } = props;
  return (
    <>
      {error ? (
        <div className='warningBox'>
          <i className='mdi mdi-close' onClick={() => setAddBidDetails({ errorType: '' })} />
          <p>
            <i className='mdi mdi-alert mr-2 mdi-24px' /> {error.message}
            {error.hyperlink ? (
              <>
                <span
                  onClick={() =>
                    setAddBidDetails({ configurationInstruction: true, errorType: '' })
                  }
                  className='underLine cursorPointer'
                >
                  “Configuring your CSV or Excel File”
                </span>
              </>
            ) : null}
            {error.linkText}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default memo(ErrorBlock);
