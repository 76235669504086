import React, { memo } from 'react';
import SearchForm from '../../../common/searchform';
import { bidssummaryParamType } from '../../../../types/biddetail';

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  onSubmit: any;
  initialValues: Record<string, unknown>;
}

function SearchSupplier(props: PropsTypes) {
  const { bidssummary, onSubmit } = props;
  const { searchFields } = bidssummary || {};

  return (
    <div className='row justify-content-center'>
      <div className='col-12 col-md-10'>
        <SearchForm {...props} onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default memo(SearchSupplier);
