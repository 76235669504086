import {
  BackgroundColor,
  BorderColor,
  BorderRadius,
  FontFamily,
  FontWeight,
  Large,
  Padding,
  TextColor,
} from '@demandstar/components/styles';
import { HTMLProps } from 'react';
import styled from 'styled-components';

import { WizardStatus } from '../../../types/wizard';

const WizardNavWidth = '30%';

//TODO: Replace how .headerBreadcrumbWrapper works. No reason to have it be fixed positioning.
export const StyledWizardPage = styled.div`
  padding-top: 7.5rem;
  width: calc(100% - 60px);
  padding-right: 35px;
  padding-left: 35px;
  margin: 0 auto;
  background: ${BackgroundColor.BaseWhite};
  max-width: 1600px;
`;

export const WizardNavigationPanel = styled.div`
  display: inline-block;
  width: ${WizardNavWidth};
  vertical-align: top;
  margin-bottom: 30px;
`;
export const WizardContentPanel = styled.div`
  display: inline-block;
  width: calc(100% - ${WizardNavWidth});
  vertical-align: top;
  margin-bottom: 30px;
  padding-left: 30px;
`;
export const WizardHeader = styled.div`
  margin-top: 45px;
  min-height: 1px;
  display: block;
  width: 100%;
`;

export const WizardDescription = styled(Large)`
  min-height: 0.5rem;
  display: inline-block;
  padding-bottom: ${Padding.Base};
`;

// Navigation

export const WizardNavWrapper = styled.div`
  display: flex;
  width: 100%;
  display: block;
`;

export const WizardItem = styled.div`
  display: block;
  overflow: visible;
  vertical-align: top;
  position: relative;

  &:last-child {
    .wizard-line {
      display: none;
      &:after {
        display: none;
      }
    }
  }
`;

const wizardNavLineWidth = '0.125rem';
const availableColor = BorderColor.Checked;
const unavailableColor = BorderColor.Wizard;
const lineAdjust = '0.3125rem';

interface WizardLineProps extends HTMLProps<HTMLDivElement> {
  nextAvailable: boolean;
}

export const WizardLine = styled.div<WizardLineProps>`
  display: block;
  width: ${wizardNavLineWidth};
  height: 100%;
  margin: 0;
  padding: 1.125rem 0 0 0;
  top: ${lineAdjust};
  position: absolute;
  &:after {
    content: '';
    width: ${wizardNavLineWidth};
    height: calc(100% - 0.125rem);
    display: inline-block;
    background: ${props => (props.nextAvailable ? availableColor : unavailableColor)};
    margin-left: 14px;
    border-radius: 1px;
  }
`;

export enum WizardIcon {
  Completed = '"\\F012C"',
  Error = '"\\F0205"',
  None = '',
}

function icon(status: WizardStatus) {
  switch (status) {
    case WizardStatus.Completed:
      return WizardIcon.Completed;
    case WizardStatus.Error:
      return WizardIcon.Error;
    default:
      return WizardIcon.None;
  }
}

function background(status: WizardStatus) {
  switch (status) {
    case WizardStatus.Completed:
      return availableColor;
    default:
      return 'none';
  }
}

function iconColor(status: WizardStatus) {
  switch (status) {
    case WizardStatus.Completed:
      return '#fff'; // Replace
    default:
      return availableColor;
  }
}

function borderColor(status: WizardStatus) {
  switch (status) {
    case WizardStatus.Unavailable:
      return unavailableColor;
    default:
      return availableColor;
  }
}
interface WizardStatusCircleProps extends HTMLProps<HTMLDivElement> {
  status: WizardStatus;
}

export const WizardStatusCircle = styled.div<WizardStatusCircleProps>`
  float: left;
  margin-right: 0.9375rem;
  margin-top: 0;

  background: ${({ status }) => background(status)};
  border: ${wizardNavLineWidth} solid ${({ status }) => borderColor(status)};
  width: 1rem;
  height: 1rem;
  border-radius: ${BorderRadius.Circular};
  text-align: center;
  padding: 0;
  margin-left: 0.45rem;
  line-height: 0.8125rem;
  font-size: 0.625rem;
  position: relative;
  top: ${lineAdjust};
  &::before {
    color: ${({ status }) => iconColor(status)};
    content: ${({ status }) => icon(status)};
    font-family: 'Material Design Icons';
    font-weight: 400;
    position: relative;
    top: 0;
    left: 0;
  }
`;

export const WizardCurrentDot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: ${BorderRadius.Circular};
  border: ${wizardNavLineWidth} solid ${availableColor};
  background: ${availableColor};
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
`;

export const WizardContent = styled.div`
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  padding-left: 2.5rem;
  font-family: ${FontFamily.Header};
  font-weight: 700;
  font-style: normal;
  font-size: 0.8125rem;
  padding-bottom: ${Padding.Base};
`;

interface WizardNavItemProps extends HTMLProps<HTMLSpanElement> {
  current?: boolean;
}

export const WizardNavItem = styled(Large)<WizardNavItemProps>`
  color: ${props => (props.current ? TextColor.Base : TextColor.Helper)};
  font-weight: ${props => (props.current ? FontWeight.Bold : FontWeight.Base)};
`;
