import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Formik } from 'formik';
import { addbidParamType } from '../../../types/addbid';
import { WizardPage } from '../../../types/wizard';
import { sharedTypes } from '../../../types/shared';
import { pageSize, TourId } from '../../../utils/constants';
import { loadingMsg } from '../../../utils/texts';
import { formattedPhoneNumber, toastFn } from '../../../utils/helpers';
import { Paging } from '../../customcontrols';
import NoResult from '../../customcontrols/noresult';
import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import AddQuotesControl from '../breadcrumb/buyer/addquotescontrol';
import QFormbuttons from '../createquotes/formbuttons';
import AddBidWizardButtons from './AddBidWizardButtons';
import { resetBasicTouractionTypes, setToursDetailsTypes } from '../../../types/tours';
import { bulkUploadConfig } from '../../../utils/tourconfig';
import { ToursState } from '../../../store/reducers/tours';

interface PropsTypes {
  handleSubmit?: any;
  currentwizard?: WizardPage;
  setAddBidDetails?: any;
  AddBidRemoveSupplimentalSuppliers?: any;
  getAddBidsupplementalsuppliers?: any;
  getAddQuoteCanComplete?: any;
  addbid?: addbidParamType;
  setBreadcrumb?: any;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  bidId?: number;
  SetAddSupplemental?: any;
  submitAddBidMoveNext?: any;
  currentBidName?: string;
  currentQuotesName?: string;
  quotecurrentwizard?: WizardPage;
  quotesId?: number | string;
  submitAddQuoteComplete?: any;
  shared?: sharedTypes;
  pagefor?: string;
  SkipNext?: any;
  getAddBidBroadcastMembers?: any;
  setSupplierBulkUpload?: any;
  tours: ToursState;
  setToursDetails: setToursDetailsTypes;
  resetBasicTour: resetBasicTouractionTypes;
}

function SupplementalSuppliers(props: PropsTypes) {
  const {
    currentwizard,
    setAddBidDetails,
    AddBidRemoveSupplimentalSuppliers,
    getAddBidsupplementalsuppliers,
    getAddQuoteCanComplete,
    addbid,
    setBreadcrumb,
    valid,
    pristine,
    submitting,
    bidId,
    SetAddSupplemental,
    submitAddBidMoveNext,
    currentBidName,
    currentQuotesName,
    quotecurrentwizard,
    quotesId,
    submitAddQuoteComplete,
    shared,
    getAddBidBroadcastMembers,
    pagefor,
    setSupplierBulkUpload,
    tours,
    setToursDetails,
    resetBasicTour,
  } = props;
  const { internalLoader } = shared || {};
  const { activateTour, tourStart, tourList = [] } = tours || {};
  const { supplementalsupplierlist, editable, broadcastinglist = [] } = addbid || {};
  const id = pagefor === 'addquotes' ? quotesId : bidId;
  const type = pagefor === 'addquotes' ? 'Quote' : 'Bid';

  useEffect(() => {
    if (id) getAddBidsupplementalsuppliers({ id, type });
  }, [id, getAddBidsupplementalsuppliers, type]);

  //FIXME: temporary fix for not getting broadcast list
  useEffect(() => {
    let getAddBidBroadcastMembersAPICALL = true;
    if (id && type && getAddBidBroadcastMembersAPICALL) {
      getAddBidBroadcastMembers({ id, type });
    }
    return () => {
      getAddBidBroadcastMembersAPICALL = false;
    };
  }, [getAddBidBroadcastMembers, id, type]);

  useEffect(() => {
    if (quotesId) getAddQuoteCanComplete({ quoteId: quotesId });
  }, [quotesId, getAddQuoteCanComplete]);

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  let breadcrumbTitle = currentwizard
    ? currentBidName
      ? `Update Bid - ${currentwizard.name}`
      : `Bid Creation - ${currentwizard.name}`
    : '';
  if (pagefor === 'addquotes') {
    breadcrumbTitle =
      currentwizard && currentQuotesName
        ? `Edit Quote - ${quotecurrentwizard ? quotecurrentwizard.name : ''}`
        : `Quote Creation - ${quotecurrentwizard ? quotecurrentwizard.name : ''}`;
  }
  let breadcrumbName = currentBidName ? currentBidName : 'Bid Creation';
  if (pagefor === 'addquotes') {
    breadcrumbName = currentQuotesName ? currentQuotesName : 'Quote Creation';
  }
  let breadcrumbPage = currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation';
  if (pagefor === 'addquotes') {
    breadcrumbPage = currentQuotesName ? 'Agency Quote Update' : 'Agency Quote Creation';
  }
  const breadcrumbMenuActive = pagefor === 'addquotes' ? 'quotes' : 'bids';
  const breadcrumbId = pagefor === 'addquotes' ? quotesId : bidId;

  useEffect(() => {
    let components = (
      <AddBidControl valid={valid} pristine={pristine} submitting={submitting} bidId={bidId} />
    );
    if (pagefor === 'addquotes') {
      components = (
        <AddQuotesControl
          valid={valid}
          pristine={pristine}
          submitting={submitting}
          quotesId={quotesId}
        />
      );
    }
    setBreadcrumb({
      component: components,
      page: breadcrumbPage,
      title: breadcrumbTitle,
      altname: breadcrumbTitle,
      name: breadcrumbName,
      id: breadcrumbId,
      menuactive: breadcrumbMenuActive,
    });
  }, [
    bidId,
    breadcrumbId,
    breadcrumbMenuActive,
    breadcrumbName,
    breadcrumbPage,
    breadcrumbTitle,
    pagefor,
    pristine,
    quotesId,
    setBreadcrumb,
    submitting,
    valid,
  ]);

  const DeleteSupplementalSupplier = (index: any, item: any) => {
    AddBidRemoveSupplimentalSuppliers({
      bidId: bidId,
      id,
      type,
      supplierId: item.mi,
      supplierAccountId: item.acid,
      index: index,
    });
  };

  // Logic for displaying pagination
  useEffect(() => {
    setCurrentPages(1);
  }, [supplementalsupplierlist]);
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const uploadListSupplierBtnFn = () => {
    if (activateTour && tourStart) {
      resetBasicTour();
    }
    setSupplierBulkUpload();
  };

  // Tour config
  let bulkUploadTour = false;
  if (
    activateTour &&
    !bulkUploadTour &&
    tourList.length > 0 &&
    !tourStart &&
    pagefor !== 'addquotes' &&
    editable === 'all'
  ) {
    bulkUploadTour = !!tourList.find(tour => tour.tourId === TourId.bulkUpload);
  }

  // Tour: useEffect functions
  useEffect(() => {
    if (bulkUploadTour) {
      const newtourList = tourList.filter(item => item.tourId !== TourId.bulkUpload) || [];
      setTimeout(() => {
        setToursDetails({
          tourStart: true,
          tourConfig: bulkUploadConfig,
          tourId: TourId.bulkUpload,
          tourList: newtourList.length > 0 ? newtourList : [],
          currentStep: 0,
          internalConfig: false,
          lastStepNextButton: <button>Got it!</button>,
          nextButton: <button>Got it!</button>,
          prevButton: <></>,
          showButtons: true,
          showCloseButton: false,
        });
      }, 1000);
    }
  }, [bulkUploadTour, setToursDetails, tourList]);

  // Tour: useEffect functions
  useEffect(() => {
    return () => {
      setTimeout(() => {
        setToursDetails({ tourStart: false, tourConfig: [], tourId: 0, currentStep: 0 });
      }, 1000);
    };
  }, [setToursDetails]);

  const onSubmitFn = (data: any) => {
    if (data) {
      if (pagefor === 'addquotes') {
        submitAddQuoteComplete({ quoteId: quotesId, pagefor });
      } else {
        if (
          broadcastinglist.length > 0 ||
          (supplementalsupplierlist && supplementalsupplierlist.length > 0)
        ) {
          submitAddBidMoveNext({ type: 'Bid', Id: bidId, stepId: 7 });
        } else {
          toastFn(
            'error',
            'You must add at least either one commodity code or one supplemental supplier.',
            'add bid supplier required',
          );
        }
      }
    }
  };

  return (
    <>
      <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              {editable === 'all' ? (
                <div className='row mb-4'>
                  <div className='col-12 d-flex justify-content-end'>
                    {pagefor !== 'addquotes' ? (
                      <span
                        className='bttn bttn-secondary small mr-3'
                        onClick={uploadListSupplierBtnFn}
                        data-tut='reactour__agencyadd_1'
                      >
                        Upload List of Suppliers
                      </span>
                    ) : null}
                    <span className='bttn bttn-secondary small' onClick={SetAddSupplemental}>
                      Add Individual Supplier
                    </span>
                  </div>
                </div>
              ) : null}
              <Table className='tableHData'>
                <thead>
                  <th>Company Name</th>
                  <th>Contact Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>City, State</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {supplementalsupplierlist && supplementalsupplierlist.length > 0 ? (
                    <>
                      {supplementalsupplierlist
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{item.supplierName}</td>
                            <td>{item.contactName}</td>
                            <td>{item.email}</td>
                            <td>{item.phone ? formattedPhoneNumber(item.phone) : ''}</td>
                            <td>
                              {item.city && item.state
                                ? `${item.city}, ${item.state}`
                                : item.city
                                ? item.city
                                : item.state
                                ? item.state
                                : null}
                            </td>
                            <td>
                              <i
                                className='mdi mdi-delete-forever-outline mdi-24px staticLink'
                                onClick={() => DeleteSupplementalSupplier(index, item)}
                              />
                            </td>
                          </tr>
                        ))
                        .slice(indexOfFirstLists, indexOfLastLists)}
                    </>
                  ) : null}
                </tbody>
              </Table>
              {supplementalsupplierlist && supplementalsupplierlist.length === 0 ? (
                <NoResult
                  message={internalLoader ? loadingMsg : 'No Supplemental Suppliers added'}
                />
              ) : null}
              <Paging
                totalRecords={supplementalsupplierlist && supplementalsupplierlist.length}
                currentPage={currentPages}
                onPagingClick={(value: number) => {
                  if (value !== currentPages) setCurrentPages(Number(value));
                }}
                limitmsg={false}
                pageLimit={listPerPages}
              />
              {pagefor === 'addquotes' ? (
                <QFormbuttons {...props} />
              ) : (
                <AddBidWizardButtons {...props} />
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(SupplementalSuppliers);
