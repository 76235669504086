import { HTMLProps } from 'react';

import styled from 'styled-components';

import { Colors } from '../../../shared/styles';
import { OnClick } from '../../../types/shared';
import { pxToRem } from '../../../utils/styleUnits';

export const BASE_ICON_HEIGHT_REM = 1.5;

/**
 * Assume px values.
 * Convert to rems
 */
export interface SvgIconProps extends Pick<HTMLProps<HTMLDivElement>, 'title'>, OnClick {
  color?: Colors;
  height?: number;
  viewBox?: string;
  width?: number;
}

/**
 * Base SVG tags
 */
export const StyledSvg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

/**
 * Clickable SVG
 */
export const SvgImageClickable = styled(StyledSvg) <OnClick>`
  cursor: pointer;
`;

/**
 * General styles
 */
export const SvgIcon = styled(SvgImageClickable) <SvgIconProps>`
  color: ${props => (props?.color ? props.color : 'currentColor')};
  height: ${props => (props?.height ? pxToRem(props.height) : BASE_ICON_HEIGHT_REM)}rem;
  width: ${props => (props?.width ? pxToRem(props.width) : BASE_ICON_HEIGHT_REM)}rem;
`;
