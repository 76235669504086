import { sortDirections } from '../../utils/constants';
import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

export const initialquotesFilters = {
  quoteName: '',
  quoteNumber: '',
  quoteStatuses: '',
  quoteYear: '',
  quotesstartDueDate: '',
  quotesendDueDate: '',
  quoteContact: '',
  quotesortBy: 'dueDate',
  quotesortOrder: sortDirections.DESC,
};

export const initialQuotesState = {
  quoteslist: [],
  quotesFilters: initialquotesFilters,
  contactList: [],
  quoteStatus: [],
  quotesinitial: true,
  quotecurrentPage: 1,
  quotesreload: false,
};

export const reducer = (state = initialQuotesState, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.SET_QUOTES_DETAILS.TRIGGER:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_QUOTES_LIST.SUCCESS:
      state = { ...state, quoteslist: payload, quotesinitial: false };
      break;
    case actionTypes.GET_QUOTES_CONTACT_LIST.SUCCESS:
      state = { ...state, contactList: payload };
      break;
    case actionTypes.GET_QUOTES_STATUS.SUCCESS:
      state = { ...state, quoteStatus: payload };
      break;
    case actionTypes.SET_QUOTES_FILTERS.TRIGGER:
      state = { ...state, quotesFilters: { ...state.quotesFilters, ...payload } };
      break;
    case actionTypes.RESET_QUOTES.TRIGGER:
      state = initialQuotesState;
      break;
    default:
      break;
  }
  return state;
};
