import React, { useEffect, useState } from 'react';

import { PaginationContainer } from './PaginateStyles';
import ReactPaginate from 'react-paginate';

interface PaginateProps {
  children?: (data: any) => React.ReactNode;
  data: any[];
  marginPagesDisplayed?: number;
  onPageChange?: (page: { selected: number }) => void;
  pageRangeDisplayed?: number;
  pageSize?: number;
  showLabel?: boolean;
}

/**
 * @description Paginates any child component's data.
 * @default {marginPagesDisplayed: 2, pageRangeDisplayed: 3, pageSize: 10, showLabel: true}
 * @example <Paginate data={[]}><Child /></Paginate>
 * @param {array=}      data - Array of data to be paginated.
 * @param {number=}     marginPagesDisplayed - optional override (The number of margin pages).
 * @param {number=}     pageRangeDisplayed - optional override (The range of pages displayed).
 * @param {number=}     pageSize - optional override (Number of items per page).
 * @param {boolean=}    showLabel - optional override (Is the label "Showing x of items displayed"?)
 */

export const Paginate: React.FunctionComponent<PaginateProps> = ({
  children,
  data,
  marginPagesDisplayed = 2,
  onPageChange = undefined,
  pageRangeDisplayed = 3,
  pageSize = 10,
  showLabel = true,
}: PaginateProps) => {
  const [pagedData, setPagedData] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(0);
  const [indexes, setIndexes] = useState({ start: 0, end: 0 });

  useEffect(() => {
    if (data.length !== totalCount) {
      resetPagination();
    }
    setTotalCount(data.length);
    const pageCount = Math.ceil(data.length / pageSize);
    setPageCount(pageCount);
    // Slice from page * pageSize to page * pageSize + pageSize
    // I forget sometimes slice is exclusive of its end item
    // Page 0 = 0*10 and 0*10+10 (0,10)
    // Page 1 = 1*10 and 1*10+10 (10,20)
    const start = activePage * pageSize;
    const end = activePage * pageSize + pageSize;
    if (end > data.length) {
      // if the page has less than pageSize number of items
      setIndexes({ start, end: data.length });
    } else {
      setIndexes({ start, end });
    }
    setPagedData(data.slice(start, end));
  }, [data, pageSize, activePage, totalCount]);

  const resetPagination = () => {
    setActivePage(0);
    setIndexes({ start: 0, end: 0 });
    setPagedData([]);
  };

  const handlePageChange = (page: { selected: number }) => {
    setActivePage(page.selected);

    /* istanbul ignore else */
    if (onPageChange !== undefined) {
      onPageChange(page);
    }
  };

  return (
    <>
      {children && children(pagedData)}

      <PaginationContainer data-testid='ds-pagination-container'>
        {showLabel && (
          <span className='pagination-label' data-testid='ds-pagination-label'>
            Showing {indexes.start + 1}-{indexes.end} of {totalCount}
          </span>
        )}

        {pageCount > 1 && (
          <div data-testid='ds-pagination-controls'>
            <ReactPaginate
              forcePage={activePage}
              onPageChange={handlePageChange}
              pageCount={pageCount}
              pageRangeDisplayed={pageRangeDisplayed}
              marginPagesDisplayed={marginPagesDisplayed}
              previousLabel='<'
              nextLabel='>'
            />
          </div>
        )}
      </PaginationContainer>
    </>
  );
};
