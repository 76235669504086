import React from 'react';
import { tollFreeNumber } from '../../../utils/texts';

const HelpText = () => {
  return (
    <>
      <small className='clear-both mt-3'>
        Need help? Email <a href='mailto:support@demandstar.com'>support@demandstar.com</a> or call{' '}
        {`${tollFreeNumber}`}
      </small>
    </>
  );
};

export default HelpText;
