import React, { memo } from 'react';
import { loadingMsg } from '../../../../utils/texts';
import { NoResult, Paging } from '../../../customcontrols';
import { Table } from 'reactstrap';
import { sharedTypes } from '../../../../types/broadcastlist';

interface propsTypes {
  internallisting?: any[];
  shared: sharedTypes;
  currentPagesSupplier: number;
  setCurrentPagesSupplier: any;
  indexOfLastListsSupplier: number;
  indexOfFirstListsSupplier: number;
  listPerPagesSupplier: number;
}

const SupplierList = (props: propsTypes) => {
  const {
    internallisting = [],
    shared,
    currentPagesSupplier,
    setCurrentPagesSupplier,
    indexOfLastListsSupplier,
    indexOfFirstListsSupplier,
    listPerPagesSupplier,
  } = props;

  const { internalLoader = false } = shared;

  return (
    <>
      <h4>Suppliers ({internallisting.length})</h4>
      <div className='innerColIndent clearfix'>
        <Table className='tableHData'>
          <tr>
            <th>Supplier</th>
            <th>City, State</th>
            {<th></th>}
          </tr>
          {internallisting && internallisting.length > 0 ? (
            <>
              {internallisting
                .map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {item.city}
                      {item.city && item.state && ', '}
                      {item.state}
                    </td>
                  </tr>
                ))
                .slice(indexOfFirstListsSupplier, indexOfLastListsSupplier)}
            </>
          ) : (
            <tr>
              <td colSpan={3}>
                <NoResult message={internalLoader ? loadingMsg : 'No Suppliers Available'} />
              </td>
            </tr>
          )}
        </Table>
        <Paging
          totalRecords={internallisting.length}
          currentPage={currentPagesSupplier}
          onPagingClick={(value: any) => {
            if (value !== currentPagesSupplier) setCurrentPagesSupplier(Number(value));
          }}
          limitmsg={false}
          pageLimit={listPerPagesSupplier}
        />
      </div>
    </>
  );
};

export default memo(SupplierList);
