import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';
import { limitedbiddetailsTypes } from '../../types/limited';

export const initialLimitedState: limitedbiddetailsTypes = {
  documents_internalloader: true,
  bidsdocuments: [],
  bidslegal: '',
  bidsplanholders: [],
  planholders_internalloader: true,
  bidsawards: [],
  bidsawards_internalloader: true,
  bidscommodities: [],
  commodities_internalloader: true,
  selfdeclaredattributes: [],
  signinmodal: false,
  wappsuccessmodal: false,
  stateslist: [],
  countieslist: [],
  timezonelist: [],
  wappagencylist: [],
  orderBidPackage: false,
  bidssummary: '',
  metrolist: {
    city: '',
    countryId: '',
    county: '',
    countyId: '',
    metroId: '',
    metroName: '',
    postalCode: '',
    state: '',
    stateId: '',
  },
  wappagencyexists: { isBlackList: false, companyExists: false, accountExists: false },
  accountrecovery: { reason: '', data: { status: false, statusCode: 0 } },
  emailrecovery: { reason: '', data: { status: false, statusCode: 0 } },
  passwordreset: { reason: '', token: '' },
  securitycode: '',
  limitedBidsList: [],
  limitedAgencyDetails: {
    agencyId: 0,
    id: '',
    agencyName: '',
    escapedAgencyName: '',
    state: '',
    stateAbbr: '',
    website: null,
    theme: '',
    position: '',
    logo: '',
    banner: '',
  },
  legalAddLoading: false,
};

export const reducer = (state = initialLimitedState, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.SET_LIMITED_DETAILS.TRIGGER:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_LIMITED_AGENCY_SUMMARY.SUCCESS:
      state = { ...state, bidsdetails: payload };
      break;
    case actionTypes.GET_LIMITED_AGENCY_DOCUMENTS.SUCCESS:
      state = { ...state, bidsdocuments: payload, documents_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_DOCUMENTS.FAILURE:
      state = { ...state, documents_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_LEGAL.SUCCESS:
      state = { ...state, bidslegal: payload };
      break;
    case actionTypes.GET_LIMITED_AGENCY_PLANHOLDERS.SUCCESS:
      state = { ...state, bidsplanholders: payload, planholders_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_PLANHOLDERS.FAILURE:
      state = { ...state, planholders_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_AWARDS.SUCCESS:
      state = { ...state, bidsawards: payload, bidsawards_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_AWARDS.FAILURE:
      state = { ...state, bidsawards_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_COMMODITIES.SUCCESS:
      state = { ...state, bidscommodities: payload, commodities_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_COMMODITIES.FAILURE:
      state = { ...state, commodities_internalloader: false };
      break;
    case actionTypes.GET_LIMITED_AGENCY_SELF_DECLARATIONS.SUCCESS:
      state = { ...state, selfdeclaredattributes: payload };
      break;
    case actionTypes.GET_LIMITED_STATES_LIST.SUCCESS:
      state = { ...state, stateslist: payload };
      break;
    case actionTypes.GET_LIMITED_COUNTIES_LIST.SUCCESS:
      state = { ...state, countieslist: payload };
      break;
    case actionTypes.GET_LIMITED_METRO_LIST.SUCCESS:
      state = { ...state, metrolist: payload };
      break;
    case actionTypes.GET_LIMITED_TIMEZONE_LIST.SUCCESS:
      state = { ...state, timezonelist: payload };
      break;
    case actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT.SUCCESS:
      state = { ...state, wappsuccessmodal: payload };
      break;
    case actionTypes.GET_LIMITED_WAPP_AGENCIES.SUCCESS:
      state = { ...state, wappagencylist: payload };
      break;
    case actionTypes.SUBMIT_LIMITED_FORGOT_PASSWORD.SUCCESS:
      state = { ...state, accountrecovery: { data: payload, reason: 'success' } };
      break;
    case actionTypes.SUBMIT_LIMITED_FORGOT_PASSWORD.FAILURE:
      state = { ...state, accountrecovery: { data: payload, reason: 'failed' } };
      break;
    case actionTypes.SUBMIT_LIMITED_FORGOT_EMAIL.SUCCESS:
      state = { ...state, emailrecovery: { data: payload, reason: 'success' } };
      break;
    case actionTypes.SUBMIT_LIMITED_FORGOT_EMAIL.FAILURE:
      state = { ...state, emailrecovery: { data: payload, reason: 'failed' } };
      break;
    case actionTypes.SUBMIT_LIMITED_RESET_PASSWORD.SUCCESS:
      state = { ...state, passwordreset: { token: payload, reason: 'success' } };
      break;
    case actionTypes.SUBMIT_LIMITED_RESET_PASSWORD.FAILURE:
      state = { ...state, passwordreset: { token: '', reason: 'failed' } };
      break;
    case actionTypes.GET_LIMITED_VERIFY_SECURITY_CODE.SUCCESS:
      state = { ...state, securitycode: payload };
      break;
    case actionTypes.GET_LIMITED_VERIFY_SECURITY_CODE.FAILURE:
      state = { ...state, securitycode: '' };
      break;
    case actionTypes.RESET_LIMITED_DETAILS.TRIGGER:
      state = initialLimitedState;
      break;
    default:
      break;
  }
  return state;
};
