// TOREFACTOR: Cut down to 499 lines.
/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilStateLoadable,
  useSetRecoilState,
} from 'recoil';
import styled from 'styled-components';

import {
  allProductsState,
  cartTotalState,
  initialNationalProduct,
  initialStateProduct,
  selectedProductsState,
} from '../../store/recoil/productState';
import {
  incompleteRegistrationState,
  recoilRegistrationDataState,
  updateIncompleteRegistration,
} from '../../store/recoil/registrationState';
import { ProductApiResponse, SubscriptionProducts } from '../../types/products';
import { agenciesByProductState } from '../../store/recoil/agencyState';
import { alertPercentage } from '../../utils/constants';
import { Colors } from '../../shared/styles';
import { RegistrationData } from '../../types/supplierregistration';

import { getAgenciesByProduct, getAgencyCounts, getParentProduct } from './helpers';
import { Buttons } from '../customcontrols';
import { compareObjectsByKey } from '../../utils';
import RegistrationInfoAlert from './callouts/RegistrationInfoAlert';
import { removeDuplicates } from '../../utils/helpers';

interface CartProps {
  isReview?: boolean;
  showDelIcon?: boolean;
}

const Div = styled.div`
  background: ${Colors.grayLight};
  left: -3.75em;
  margin: 2em -3.75em 2em 0;
  padding: 2em 2em 2em 5em;
  position: relative;
    span {
      float: right;
    }
  li {
      clear: right;
      label {
        width: 17em;
      }
      strong {
        vertical-align: text-bottom;
      }
    }
  }
`;

type StateTally = {
  fomoShown: boolean;
  statePrice: number;
  tally: number;
};

type StateInfo = { [state: string]: StateTally };

const Cart = (props: CartProps) => {
  const { isReview = false, showDelIcon = true } = props;
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const setAgenciesByProduct = useSetRecoilState<ProductApiResponse[]>(agenciesByProductState);
  const [cartTotal, setCartTotal] = useRecoilState<number>(cartTotalState);
  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);

  const [allProductsLoaded, setAllProductsLoaded] = useState([initialNationalProduct]);
  const [nationalProduct, setNationalProduct] = useState(initialNationalProduct);
  const [nationalUpgrade, setNationalUpgrade] = useState(false);
  const [selectedStateIds, setSelectedStateIds] = useState([0]);
  const [showAlert, setShowAlert] = useState<
    'county' | 'national' | 'nationalUpgrade' | 'none' | 'stateUpgrade'
  >('none');
  const [stateUpgrade, setStateUpgrade] = useState([initialStateProduct]);
  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedCounties: ProductApiResponse[] = selectedProducts.county || [];
  let selectedNational: ProductApiResponse = initialNationalProduct;
  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedStates: ProductApiResponse[] = selectedProducts.state || [];

  const [registrationDataLoadable] = useRecoilStateLoadable(recoilRegistrationDataState);
  const [registrationData, setRecoilRegistrationData] = useRecoilState<RegistrationData>(
    recoilRegistrationDataState,
  );

  /* istanbul ignore else */
  if (allProducts.state === 'hasValue') {
    if (showDelIcon || isReview) {
      selectedNational = nationalProduct;
    } else {
      selectedNational = nationalProduct;
    }
  }

  const deleteCartCounties = (productId: number) => {
    const filteredCounties = selectedProducts.county?.filter(
      county => county.productId !== productId,
    );
    setSelectedProducts({ ...selectedProducts, county: filteredCounties });
    updateRegistrationSubscriptionsState({ ...selectedProducts, county: filteredCounties });
  };

  const deleteCartStates = (productId: number) => {
    const filteredStates = selectedProducts.state?.filter(state => state.productId !== productId);
    setSelectedProducts({ ...selectedProducts, state: filteredStates });
    updateRegistrationSubscriptionsState({ ...selectedProducts, state: filteredStates });
  };

  const getAlert = () => {
    let countyAlert = false;
    if (showAlert !== 'none') {
      if (showAlert === 'national') {
        return <RegistrationInfoAlert type={showAlert} />;
      } else {
        /* istanbul ignore else */
        if (nationalUpgrade || showAlert === 'nationalUpgrade') {
          let agenciesInCart = 0;
          /* istanbul ignore else */
          if (selectedProducts.county)
            selectedProducts.county.map(
              county =>
                (agenciesInCart =
                  agenciesInCart + getAgencyCounts(allProductsLoaded, county.productId, 'CT')),
            );
          /* istanbul ignore else */
          if (selectedProducts.state)
            selectedProducts.state.map(
              state =>
                (agenciesInCart =
                  agenciesInCart + getAgencyCounts(allProductsLoaded, state.productId, 'ST')),
            );
          return (
            <RegistrationInfoAlert
              agenciesInCart={agenciesInCart}
              agenciesWithUpgrade={getAgencyCounts(allProductsLoaded, 10156, 'NA')}
              product={nationalProduct}
              type='nationalUpgrade'
            />
          );
        } else if (!nationalUpgrade && stateUpgrade[0] !== initialStateProduct) {
          const output = stateUpgrade.map(state => {
            if (selectedStates.includes(state) && !countyAlert) {
              const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.preventDefault();
                window.scrollTo(0, 0);
              };
              countyAlert = true;

              return (
                <React.Fragment key={state.productId}>
                  <Buttons
                    classNames='bttn bttn-primary m-auto d-block'
                    onClick={onClick}
                    text={'Add another state?'}
                  />
                  <br />
                  <RegistrationInfoAlert type='county' />
                </React.Fragment>
              );
            } else if (selectedStates.includes(state) && countyAlert) {
              // do not include state alert
              return;
            } else if (state) {
              return (
                <RegistrationInfoAlert key={state.productId} product={state} type={showAlert} />
              );
            } else {
              return;
            }
          });
          return <>{output}</>;
        }
      }
    } else {
      return;
    }
  };

  const updateRegistrationSubscriptionsState = useRecoilCallback(
    ({ set }) =>
      async (newSubscriptions: SubscriptionProducts) => {
        const subscriptionData: ProductApiResponse[] = [];
        /** Note: national subscription is updated differently to aviod recursion */
        /* istanbul ignore else */
        if (newSubscriptions.county)
          newSubscriptions.county.map(county => subscriptionData.push(county));
        /* istanbul ignore else */
        if (newSubscriptions.state)
          newSubscriptions.state.map(state => subscriptionData.push(state));

        const subscriptions = {
          subscribedCounties: newSubscriptions.county
            ? newSubscriptions.county.map((county: { productId: number }) => county.productId)
            : [],
          // Since National subscriptions are handled differently, simply taking what's there
          subscribedNational:
            registrationData.subscriptions && registrationData.subscriptions.subscribedNational
              ? registrationData.subscriptions.subscribedNational
              : [],
          subscribedStates: newSubscriptions.state
            ? newSubscriptions.state.map((state: { productId: number }) => state.productId)
            : [],
          subscriptionSelected: true,
        };

        /* istanbul ignore else */
        if (
          registrationData.subscriptionData !== subscriptionData ||
          registrationData.subscriptions !== subscriptions
        ) {
          setRecoilRegistrationData({
            ...registrationData,
            subscriptionData: subscriptionData,
            subscriptions: subscriptions,
          });

          try {
            const response = await updateIncompleteRegistration({
              email: registrationData.emailAddress,
              JsonData: {
                regData: {
                  currentComponent: 'ChooseSubscription',
                  registrationData: {
                    ...registrationData,
                    subscriptionData: subscriptionData,
                    subscriptions: subscriptions,
                  },
                },
              },
            });

            // Set the client-facing atom with our updated list.
            // NOTE: This assumes the service call returns the updated list.
            //       We could also re-query the full list here for an up to date result.
            set(incompleteRegistrationState, response);
          } catch (error) {
            console.error(
              `updateSubscriptions -> updateIncompleteRegistration() ERROR: \n${error}`,
            );
            return [];
          }
        }
      },
    [incompleteRegistrationState],
  );

  const checkCountyTotals = useCallback(() => {
    const tallyByState: StateInfo = {};
    // select county
    const handleCountySelection = (county: ProductApiResponse) => {
      // check for the county's state in our tallyByState object.
      /* istanbul ignore else */
      if (county.parentName && county.parentPrice) {
        let countyState = tallyByState[county.parentName];
        // increment existing county.
        if (countyState) {
          countyState.tally += county.price;
        } else {
          countyState = {
            statePrice: county.parentPrice,
            tally: county.price,
            fomoShown: false,
          };
          // add the state
          tallyByState[county.parentName] = countyState;
        }
        if ((countyState.tally / county.parentPrice) * 100 >= alertPercentage) {
          // alert full state FOMO
          countyState.fomoShown = true;
          /* istanbul ignore else */
          if (showAlert !== 'stateUpgrade') setShowAlert('stateUpgrade');
          /* istanbul ignore else */
          if (!stateUpgrade.includes(county)) {
            if (stateUpgrade[0] === initialStateProduct) {
              setStateUpgrade([getParentProduct(county, allProductsLoaded)]);
            } else {
              /* istanbul ignore else */
              if (!stateUpgrade.includes(getParentProduct(county, allProductsLoaded)))
                setStateUpgrade(stateUpgrade.concat(getParentProduct(county, allProductsLoaded)));
            }
          }
        } else {
          countyState.fomoShown = false;
        }
      }
    };
    selectedCounties.map(county => handleCountySelection(county));
  }, [allProductsLoaded, selectedCounties, showAlert, stateUpgrade]);

  /**
   * SelectedStateIds are needed for insuring upgrade messages aren't repeated
   *    when multiple counties in the same state are added to the cart
   */
  useEffect(() => {
    let needStateIds = true;
    /* istanbul ignore else */
    if (needStateIds) {
      const productIds = selectedStates.map(product => product.productId);
      setSelectedStateIds(productIds);
    }

    return () => {
      needStateIds = false;
    };
  }, [isReview, selectedStates, setSelectedStateIds]);

  /**
   * Set allProductsLoaded and nationalProduct
   */
  useEffect(() => {
    let needIncompleteRegistrationCheck = true;

    const updateSubscriptionsFromIncompleteRegistration = () => {
      /* istanbul ignore else */
      if (
        registrationData.subscriptionData &&
        selectedProducts.national === 0 &&
        selectedProducts.county &&
        selectedProducts.county.length === 0 &&
        selectedProducts.state &&
        selectedProducts.state.length === 0
      ) {
        const incompleteSubscribedCounties: ProductApiResponse[] = [];
        const incompleteSubscribedStates: ProductApiResponse[] = [];
        registrationData.subscriptionData.map((product: ProductApiResponse) => {
          switch (product.productType) {
            case 'CT':
              incompleteSubscribedCounties.push(product);
              break;
            case 'NA':
              setSelectedProducts({ ...selectedProducts, national: 10156 });
              break;
            case 'ST':
              incompleteSubscribedStates.push(product);
              break;
          }
        });
        setSelectedProducts({
          ...selectedProducts,
          county: incompleteSubscribedCounties,
          state: incompleteSubscribedStates,
        });
      }
    };

    /* istanbul ignore else */
    if (allProducts.state === 'hasValue') {
      setAllProductsLoaded(allProducts.contents);

      const national = allProducts.contents.filter(
        (national: ProductApiResponse) => national.productId === 10156,
      );
      setNationalProduct(national[0]);
      /* istanbul ignore else */
      if (needIncompleteRegistrationCheck) {
        updateSubscriptionsFromIncompleteRegistration();
      }
    }

    return () => {
      needIncompleteRegistrationCheck = false;
    };
  }, [
    allProducts,
    isReview,
    registrationData.subscriptionData,
    selectedProducts,
    setSelectedProducts,
  ]);

  /**
   * Display upgrade alerts
   */
  useEffect(() => {
    let needAlerts = true;
    if (
      selectedProducts.national === 10156 &&
      (selectedStates.length > 0 || selectedCounties.length > 0) &&
      needAlerts
    ) {
      setShowAlert('national');
    } else if (nationalUpgrade) {
      setShowAlert('nationalUpgrade');
    } else if (stateUpgrade[0] !== initialStateProduct) {
      setShowAlert('stateUpgrade');
    } else {
      setShowAlert('none');
    }

    return () => {
      needAlerts = false;
    };
  }, [
    nationalUpgrade,
    selectedCounties,
    selectedProducts.national,
    showAlert,
    selectedStates,
    stateUpgrade,
  ]);

  useEffect(() => {
    const getCartTotal = () => {
      if (selectedProducts.national === 10156) {
        setCartTotal(selectedNational.price);
      } else {
        let total = 0;
        const statePrices = selectedProducts.state?.map(state => state.price) || [];
        const countyPrices =
          selectedProducts.county
            ?.filter(county => county.parentId && !selectedStateIds.includes(county.parentId))
            .map(county => county.price) || [];
        const allPrices = statePrices?.concat(countyPrices);

        /* istanbul ignore else */
        if (allPrices.length > 0)
          total = allPrices.reduce((total, number) => {
            return total + number;
          });

        const suggestNationalUpgrade = (total / 2399) * 100 >= alertPercentage;

        /* istanbul ignore else */
        if (suggestNationalUpgrade && !nationalUpgrade) setNationalUpgrade(suggestNationalUpgrade);

        // remove duplicates from selected products
        const allCounties = selectedProducts.county;
        const uniqueCounties = removeDuplicates(allCounties, 'productId').sort(
          compareObjectsByKey('productName'),
        );
        const allStates = selectedProducts.state;
        const uniqueStates = removeDuplicates(allStates, 'productId').sort(
          compareObjectsByKey('productName'),
        );

        /* istanbul ignore else */
        if (cartTotal !== total) {
          checkCountyTotals();
          updateRegistrationSubscriptionsState(selectedProducts);
          setCartTotal(total);
          /* istanbul ignore else */
          if (allCounties !== uniqueCounties) {
            setSelectedProducts({
              ...selectedProducts,
              county: uniqueCounties,
              state: uniqueStates,
            });
          }
        } else return;
      }
    };

    let needRegistrationData = true;
    /* istanbul ignore else */
    if (needRegistrationData && registrationDataLoadable.state === 'hasValue' && !isReview) {
      getCartTotal();
      /* istanbul ignore else */
      if (registrationData !== registrationDataLoadable.contents) {
        setRecoilRegistrationData(registrationDataLoadable.contents);
      }
    }

    return () => {
      needRegistrationData = false;
    };
  }, [
    cartTotal,
    checkCountyTotals,
    isReview,
    nationalUpgrade,
    registrationData,
    registrationDataLoadable,
    selectedNational.price,
    selectedProducts,
    selectedStateIds,
    setCartTotal,
    setRecoilRegistrationData,
    setSelectedProducts,
    updateRegistrationSubscriptionsState,
  ]);

  return (
    <>
      <Div className='cart-container'>
        {(selectedProducts.national === 10156 ||
          selectedStates.length > 0 ||
          selectedCounties.length > 0) && (
          <h5>You've added the following subscriptions to your membership:</h5>
        )}
        {selectedProducts.national === 10156 ? (
          <ul className='list-unstyled cart-list'>
            <li key={1} className='d-flex'>
              <p className='flex1 cart-col'>Nationwide</p>
              <div className='reg-cart-price'>
                <p className='bold'>
                  ${selectedNational.price} / year
                  {showDelIcon && !isReview && (
                    <span className='reg-icon-box'>
                      <i
                        className='mdi mdi-close mdi-24px'
                        data-testid={'delete-' + selectedNational.productId}
                        onClick={() => setSelectedProducts({ ...selectedProducts, national: 0 })}
                      />
                    </span>
                  )}
                </p>
              </div>
            </li>
          </ul>
        ) : (
          <>
            {selectedStates.length > 0 && (
              <ul className='list-unstyled cart-list'>
                {selectedStates.map((product: ProductApiResponse, index: number) => (
                  <li key={index}>
                    <p className='cart-col'>{product.productName}</p>
                    <p
                      className='staticLink cart-col'
                      onClick={() =>
                        getAgenciesByProduct(
                          allProductsLoaded,
                          product.productId,
                          setAgenciesByProduct,
                          'ST',
                        )
                      }
                    >
                      View {getAgencyCounts(allProductsLoaded, product.productId, 'ST')}{' '}
                      {getAgencyCounts(allProductsLoaded, product.productId, 'ST') > 1
                        ? 'Agencies'
                        : 'Agency'}
                    </p>
                    <p className='bold cart-col'>
                      ${product.price} / year
                      {showDelIcon && !isReview && (
                        <span className='reg-icon-box'>
                          <i
                            className='mdi mdi-close mdi-24px'
                            data-testid={'delete-' + product.productId}
                            onClick={() => deleteCartStates(product.productId)}
                          />
                        </span>
                      )}
                    </p>
                  </li>
                ))}
              </ul>
            )}
            {selectedCounties.length > 0 && (
              <ul className='list-unstyled cart-list'>
                {/* This filters counties if their parent state is selected before listing counties in the cart */}
                {selectedCounties
                  ?.filter(
                    product => product.parentId && !selectedStateIds.includes(product.parentId),
                  )
                  .map((product: ProductApiResponse, index: number) => (
                    <li key={index}>
                      <p className='cart-col'>
                        {product.productName}, {product.parentName}
                      </p>
                      <p
                        className='staticLink cart-col'
                        onClick={() =>
                          getAgenciesByProduct(
                            allProductsLoaded,
                            product.productId,
                            setAgenciesByProduct,
                            'CT',
                          )
                        }
                      >
                        View {getAgencyCounts(allProductsLoaded, product.productId, 'CT')}{' '}
                        {getAgencyCounts(allProductsLoaded, product.productId, 'CT') > 1
                          ? 'Agencies'
                          : 'Agency'}
                      </p>
                      <p className='bold cart-col'>
                        ${product.price} / year
                        {showDelIcon && !isReview && (
                          <span className='reg-icon-box'>
                            <i
                              className='mdi mdi-close mdi-24px'
                              data-testid={'delete-' + product.productId}
                              onClick={() => deleteCartCounties(product.productId)}
                            />
                          </span>
                        )}
                      </p>
                    </li>
                  ))}
              </ul>
            )}
          </>
        )}
        <h6>
          Total to pay today:<span data-testid='cart-total'>${cartTotal}</span>
        </h6>
        {getAlert()}
      </Div>
    </>
  );
};

export default Cart;
