import React, { memo, useEffect } from 'react';
import { Formik } from 'formik';
import { initialplandetails } from '../../../store/reducers/addbid';
import { toastFn } from '../../../utils/helpers';
import { DeprecatedInput, DeprecatedRadio, TextArea } from '../../customcontrols';
import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import AddBidWizardButtons from './AddBidWizardButtons';
import { addbidParamType } from '../../../types/addbid';
import { WizardPage } from '../../../types/wizard';

interface PropsTypes {
  handleSubmit?: any;
  valid?: Record<string, unknown>;
  pristine?: Record<string, unknown>;
  addbid: addbidParamType;
  submitting?: Record<string, unknown>;
  setBreadcrumb?: any;
  currentwizard?: WizardPage;
  bidId?: number;
  setAddBidDetails?: any;
  submitAddBidUpdatePlan?: any;
  currentBidName?: string;
  SkipNext?: any;
  submitAddBidMoveNext?: any;
}

function PlanBluePrint(props: PropsTypes) {
  const {
    valid,
    pristine,
    addbid,
    submitting,
    setBreadcrumb,
    currentwizard,
    bidId,
    setAddBidDetails,
    submitAddBidUpdatePlan,
    currentBidName,
    SkipNext,
    submitAddBidMoveNext,
  } = props;
  const {
    blueprintStatusType = '',
    plandetails,
    plandistributedby = '',
    planerror = false,
    // planEmailError = false,
    editable,
    halfWayCancel,
  } = addbid || {};

  useEffect(() => {
    let updatedDistributedBy = '';
    if (!plandistributedby) {
      if (blueprintStatusType !== 'NA') {
        if (blueprintStatusType === 'AG') updatedDistributedBy = 'AG';
        else updatedDistributedBy = 'DM';
      }
      setAddBidDetails({ plandistributedby: updatedDistributedBy });
    } else if (blueprintStatusType === 'NA' && plandistributedby) {
      setAddBidDetails({ plandistributedby: '' });
    }
  }, [blueprintStatusType, plandistributedby, setAddBidDetails]);

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  useEffect(() => {
    setBreadcrumb({
      component: (
        <AddBidControl valid={valid} pristine={pristine} submitting={submitting} bidId={bidId} />
      ),
      page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
      title: currentBidName
        ? `Bid Update - ${currentwizard && currentwizard.name}`
        : `Bid Creation - ${currentwizard && currentwizard.name}`,
      altname: currentBidName
        ? `Bid Update - ${currentwizard && currentwizard.name}`
        : `Bid Creation - ${currentwizard && currentwizard.name}`,
      name: currentBidName ? currentBidName : 'Bid Creation',
      id: bidId,
      menuactive: 'Bid Creation',
    });
  }, [bidId, currentBidName, currentwizard, pristine, setBreadcrumb, submitting, valid]);

  const onChnageFn = (name: string, value: any) => {
    if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
    setAddBidDetails({ plandetails: { ...plandetails, [name]: value } });
    if (name === 'planemail') setAddBidDetails({ planEmailError: false });
  };

  const { planname = '', planemail = '', plancost = '', plannotes = '' } = plandetails || {};

  const onSubmitFn = (data: any) => {
    if (data) {
      if (
        blueprintStatusType === 'NA' ||
        (plandistributedby === 'TP' && planname) ||
        plandistributedby === 'AG' ||
        plandistributedby === 'DM'
      ) {
        let validated = true;
        const toastMsg = [];
        // if(planemail && !emailRegEx.test(planemail.toLowerCase()) && !websiteRegEx.test(planemail)){
        //   setAddBidDetails({ planEmailError: true });
        //   toastMsg.push('Provide Valid Email / Website Address');
        //   validated = false;
        // }
        if (plancost) {
          const ItemPrice = plancost
            .toString()
            .replace(/ /g, '')
            .replace('$', '')
            .replace(/,/g, '');
          if (!/^(0|[0-9]\d*)$/.test(ItemPrice) && !/^[-+]?[0-9]+\.[0-9]+$/.test(ItemPrice)) {
            setAddBidDetails({ planCostError: true });
            toastMsg.push('Provide Valid Plan Cost');

            validated = false;
          }
        }

        if (toastMsg.length > 0) {
          toastFn('error', toastMsg.join('\n'), 'add bid documents valid');
        }

        if (validated) {
          if (halfWayCancel) {
            if (editable === 'all') {
              submitAddBidUpdatePlan({ bidId });
            } else {
              SkipNext();
            }
          } else {
            submitAddBidMoveNext({ type: 'Bid', Id: bidId, stepId: 8 });
          }
        }
      } else {
        setAddBidDetails({ planerror: true });
        toastFn('error', 'Provide Required Details', 'add bid documents required');
      }
    }
  };

  return (
    <>
      <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <>
                {/* <h4>Plan (blueprint) Distribution Options</h4> */}
                <div className='innerColIndent'>
                  <DeprecatedRadio
                    name='bidplaning'
                    title='Bid has no associated plans (blueprints) - you are uploading bid specs only'
                    value='NA'
                    handleSelect={(name: string, value: string) => {
                      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
                      setAddBidDetails({ blueprintStatusType: value });
                    }}
                    defaultChecked={blueprintStatusType === 'NA'}
                  />
                  <DeprecatedRadio
                    name='bidplaning'
                    title='Plans are being distributed by Agency or 3rd Party  - your Agency will distribute the blueprints outside of DemandStar'
                    value='AG'
                    handleSelect={(name: string, value: string) => {
                      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
                      setAddBidDetails({ blueprintStatusType: value });
                    }}
                    defaultChecked={blueprintStatusType === 'AG'}
                    disabled={editable !== 'all' ? 'true' : 'false'}
                  />
                  <DeprecatedRadio
                    name='bidplaning'
                    title='Uploading electronic plan files (.plt, .pdf, .dwf)  - your bid has blueprints and you have electronic versions'
                    value='EL'
                    handleSelect={(name: string, value: string) => {
                      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
                      setAddBidDetails({ blueprintStatusType: value });
                    }}
                    defaultChecked={blueprintStatusType === 'EL'}
                    disabled={editable !== 'all' ? 'true' : 'false'}
                  />
                  <p className='mt-3'>
                    Note: Plans (blueprints) are construction drawings and other specialized
                    technical documents
                  </p>
                  <h5>Distributed By</h5>
                  <DeprecatedRadio
                    name='plandistributedby'
                    title='DemandStar'
                    value='DM'
                    handleSelect={(name: string, value: string) => {
                      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
                      setAddBidDetails({
                        plandistributedby: value,
                        plandetails: initialplandetails,
                        planerror: false,
                      });
                    }}
                    defaultChecked={plandistributedby === 'DM'}
                    disabled={editable !== 'all' || blueprintStatusType !== 'EL' ? 'true' : 'false'}
                  />
                  <DeprecatedRadio
                    name='plandistributedby'
                    title='Agency Only'
                    value='AG'
                    handleSelect={(name: string, value: string) => {
                      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
                      setAddBidDetails({
                        plandistributedby: value,
                        plandetails: {
                          ...plandetails,
                          planname: '',
                          planemail: '',
                          planerror: false,
                        },
                      });
                    }}
                    defaultChecked={plandistributedby === 'AG'}
                    disabled={editable !== 'all' || blueprintStatusType === 'NA' ? 'true' : 'false'}
                  />
                  <DeprecatedRadio
                    name='plandistributedby'
                    title='3rd Party'
                    value='TP'
                    handleSelect={(name: string, value: string) => {
                      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
                      setAddBidDetails({ plandistributedby: value });
                    }}
                    defaultChecked={plandistributedby === 'TP'}
                    disabled={editable !== 'all' || blueprintStatusType === 'NA' ? 'true' : 'false'}
                  />
                  {plandistributedby === 'TP' ? (
                    <div className='row'>
                      <div className='col-md-6'>
                        <DeprecatedInput
                          label='Name'
                          name='planname'
                          type='text'
                          classNames='class'
                          value={planname}
                          placeholder='Name'
                          handleChange={(name: string, value: string) => onChnageFn(name, value)}
                          // parentClass='w-48 d-inline-block'
                          meta={{ touched: planerror && !planname, error: 'Required' }}
                          disabled={editable !== 'all' ? 'true' : 'false'}
                        />
                      </div>
                      <div className='col-md-6'>
                        <DeprecatedInput
                          label='Contact Info'
                          // label='Email'
                          name='planemail'
                          type='text'
                          classNames='class'
                          value={planemail}
                          placeholder='Contact Info'
                          handleChange={(name: string, value: string) => onChnageFn(name, value)}
                          // parentClass='w-48 d-inline-block'
                          // meta={{ touched: (planerror && !planemail) || (planemail && planEmailError), error: planEmailError?'Invalid':'Required' }}
                          disabled={editable !== 'all' ? 'true' : 'false'}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  ) : null}
                  {plandistributedby === 'TP' || plandistributedby === 'AG' ? (
                    <div className='row'>
                      <div className='col-12 mb-3'>
                        <p>
                          Note: If Agency or 3rd Party distributing, please complete the two fields
                          below
                        </p>
                      </div>
                      <div className='col-md-12'>
                        <DeprecatedInput
                          label='Enter package cost'
                          name='plancost'
                          type='text'
                          classNames='class'
                          value={plancost !== '0.000' ? plancost : ''}
                          placeholder='Enter package cost'
                          handleChange={(name: string, value: any) => onChnageFn(name, value)}
                          parentClass='w-48'
                          //meta={{ touched: planerror && !plancost, error: 'Required' }}
                          disabled={editable !== 'all' ? 'true' : 'false'}
                          maxLength={24}
                        />
                      </div>
                      <div className='col-md-12'>
                        <TextArea
                          label='Fulfillment notes'
                          name='plannotes'
                          type='textarea'
                          classNames='class'
                          value={plannotes === 'None' ? '' : plannotes}
                          placeholder='Fulfillment notes'
                          handleChange={(name: string, value: string) => onChnageFn(name, value)}
                          parentClass='w-48'
                          // meta={{ touched: planerror && !plannotes, error: 'Required' }}
                          disabled={editable !== 'all' ? 'true' : 'false'}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
              <AddBidWizardButtons {...props} />
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(PlanBluePrint);
