export const DETAILS_LABEL = 'Details';

export const LOADING_MESSAGE_DEFAULT = 'Loading...';

export enum ControlLabels {
  ascendingSuffix = 'in Ascending order',
  confirmDeletePrefix = 'Are you sure you want to delete',
  descendingSuffix = 'in Descending order',
  sortBy = 'Sort by',
}

export const deleteMessages = {
  deletedSuccessSuffix: 'was deleted.',
  deletedErrorSuffix: 'could not be deleted.',
};

export enum BooleanText {
  'TRUE' = 'Yes',
  'FALSE' = 'No',
}
