import { FormProvider, useForm } from 'react-hook-form';
import React, { memo, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import AddressLookup, { AddressInfo } from '../common/formcomponents/AddressLookup';
import { Buttons } from '../customcontrols';
import { recoilRegistrationDataState } from '../../store/recoil/registrationState';
import { registrationComponent } from '../../utils/constants';
import { RegistrationData } from '../../types/supplierregistration';
import { cartTotalState, selectedProductsState } from '../../store/recoil/productState';
import { SubscriptionProducts } from '../../types/products';
import { isValidRequiredTrimmingSpace } from 'src/utils/helpers';

export interface CompleteProfileFormInputs {
  addressInfo: AddressInfo;
  contactPhone: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  website: string;
}

interface CompleteProfileProps {
  setRegistrationData: (data: any) => void;
}

const CompleteProfile = (props: CompleteProfileProps) => {
  const { setRegistrationData } = props;
  const [recoilRegistrationData, setRecoilRegistrationData] = useRecoilState<RegistrationData>(
    recoilRegistrationDataState,
  );
  const cartTotal = useRecoilValue<number>(cartTotalState);
  const selectedProducts = useRecoilValue<SubscriptionProducts>(selectedProductsState);

  const [profileInfo, setProfileInfo] = useState({
    contactPhone: recoilRegistrationData?.memberContactPhone?.phoneNumber || '',
    firstName: recoilRegistrationData?.account?.firstName || '',
    lastName: recoilRegistrationData?.account?.lastName || '',
    phoneNumber: recoilRegistrationData?.memberContactPhone?.phoneNumber || '',
    website: recoilRegistrationData?.member?.website || '',
  });
  const methods = useForm<CompleteProfileFormInputs>({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, errors, trigger, getValues, formState, setValue } = methods;
  const [memberTypeStatus, setMemberTypeStatus] = useState({ type: 'SS', status: 'SW' });

  const continueButtonText = cartTotal === 0 ? 'Finish registration' : 'Go to Checkout';

  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = String(event.target.value).trim();
    setProfileInfo({ ...profileInfo, [event.target.name]: fieldValue });
  };

  const submitProfile = () => {




    setRecoilRegistrationData({
      ...recoilRegistrationData,
      account: {
        ...recoilRegistrationData.account,
        userName: recoilRegistrationData.emailAddress.trim(),
        firstName: profileInfo.firstName.trim(),
        lastName: profileInfo.lastName.trim(),
        initials: `${profileInfo.firstName.trim()[0]}${profileInfo.lastName.trim()[0]}`,
      },
      member: {
        ...recoilRegistrationData.member,
        memberType: memberTypeStatus.type,
        memberStatus: memberTypeStatus.status,
        memberId: '',
        productIds:
          typeof selectedProducts.agency?.productId === 'number'
            ? [selectedProducts.agency.productId]
            : [],
        scraperDomain: '',
        shortName: recoilRegistrationData.companyName.trim(),
        website: profileInfo.website,
      },
      memberAddress: {
        ...recoilRegistrationData.memberAddress,
        address1: String(getValues('addressInfo.address1')).trim(),
        address2: String(getValues('addressInfo.address2')).trim(),
        addressType: 'MA',
        city: String(getValues('addressInfo.city')).trim(),
        county: getValues('addressInfo.county'),
        country: getValues('addressInfo.country'),
        countryId: getValues('addressInfo.countryId'),
        countyId: getValues('addressInfo.countyId'),
        countyName: getValues('addressInfo.countyName'),
        postalCode: getValues('addressInfo.postalCode'),
        stateId: getValues('addressInfo.stateId'),
        stateName: getValues('addressInfo.stateName'),
        state: getValues('addressInfo.state'),
      },
      memberContact: {
        email: recoilRegistrationData.emailAddress,
        mainContact: true,
      },
      memberContactPhone: { phoneType: 'MN', phoneNumber: profileInfo.contactPhone },
      memberPhones: [{ phoneType: 'MN', phoneNumber: profileInfo.contactPhone }],
    });
  };

  const moveBack = () => {
    setRegistrationData({
      currentComponent: registrationComponent.ChooseSubscription,
    });
  };

  const moveForward = async () => {
    await trigger();

    // I shouldn't have to do this formState.errors.length check
    // According to RHF docs, formState.isValid should be false if errors
    // are present. However, placing a debug between the trigger and this if
    // revealed that formState.isValid would be true while errors are present

    if (Object.keys(formState.errors).length === 0 && formState.isValid) {
      submitProfile();
      setRegistrationData({
        currentComponent: registrationComponent.ReviewOrder,
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (cartTotal === 0 && selectedProducts.agency?.productId === 0)
      setMemberTypeStatus({ type: 'SB', status: 'AC' });

    window.scrollTo(0, 0);
  }, [cartTotal, selectedProducts.agency]);

  /*
   * This sets the initial formState equal to recoilRegistrationData
   */
  useEffect(() => {
    if (recoilRegistrationData.memberAddress) {
      const memberAddress = recoilRegistrationData.memberAddress;
      Object.keys(memberAddress).forEach(key => {
        const typedKey = key as keyof typeof memberAddress;
        if (memberAddress[typedKey] !== undefined) {
          let fieldValue = memberAddress[typedKey];
          if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.trim();
          }
          setValue(`addressInfo.${typedKey}`, fieldValue);
        }
      });
    }
  }, [recoilRegistrationData, setValue]);

  return (
    <>
      <div className='container'>
        <div className='row regWrapper'>
          <div className='col-lg-12'>
            <div className='colWrapper subscriptionWrapper'>
              <div className='row'>
                <div className='col col-9'>
                  <h2 className='arrowWrapper'>3 of 3: Complete your profile</h2>
                  <p className='reg-intro'>
                    <span className='bold'>Your order is complete!</span> Now that you've set up
                    your subscriptions on DemandStar, tell us a little bit more about yourself.
                  </p>

                  <FormProvider {...methods}>
                    <form data-testid='complete-profile' onSubmit={handleSubmit(submitProfile)}>
                      <fieldset>
                        <h2>Your contact information</h2>
                        <div
                          className={`inputWrapper clearfix ${errors.firstName && 'errorWrapper'}`}
                        >
                          <div>
                            <label htmlFor='firstName' aria-labelledby='firstName'>
                              First Name *
                            </label>
                            <input
                              defaultValue={profileInfo.firstName}
                              id='firstName'
                              maxLength={50}
                              name='firstName'
                              onChange={inputOnChange}
                              placeholder='First Name'
                              ref={register({ 
                                required: true,
                                validate: isValidRequiredTrimmingSpace, 
                              })}
                              type='text'
                            />
                          </div>
                          <div className='errorMsg'>
                            <span>{errors.firstName && 'First Name is required'}</span>
                          </div>
                        </div>

                        <div
                          className={`inputWrapper clearfix ${errors.lastName && 'errorWrapper'}`}
                        >
                          <div>
                            <label htmlFor='lastName' aria-labelledby='lastName'>
                              Last Name *
                            </label>
                            <input
                              defaultValue={profileInfo.lastName}
                              id='lastName'
                              maxLength={50}
                              name='lastName'
                              onChange={inputOnChange}
                              placeholder='Last Name'
                              ref={register({ 
                                required: true,
                                validate: isValidRequiredTrimmingSpace,
                              })}
                              type='text'
                            />
                          </div>
                          <div className='errorMsg'>
                            <span>{errors.lastName && 'Last Name is required'}</span>
                          </div>
                        </div>

                        <div
                          className={`inputWrapper clearfix ${
                            errors.phoneNumber && 'errorWrapper'
                          }`}
                        >
                          <div>
                            <label htmlFor='phoneNumber' aria-labelledby='phoneNumber'>
                              Phone Number *
                            </label>
                            <input
                              defaultValue={profileInfo.phoneNumber}
                              id='phoneNumber'
                              maxLength={50}
                              name='phoneNumber'
                              onChange={inputOnChange}
                              placeholder='Phone Number'
                              ref={register({ 
                                required: true,
                                validate: isValidRequiredTrimmingSpace, 
                                minLength: 10,
                              })}
                              type='text'
                            />
                          </div>
                          <div className='errorMsg'>
                            <span>
                              {errors.phoneNumber && 'Ten Digit Phone Number is required'}
                            </span>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset>
                        <h2>
                          {recoilRegistrationData?.companyName?.trim()
                            ? `${recoilRegistrationData.companyName} `
                            : 'Your Company '}
                          Information
                        </h2>
                        <div
                          className={`inputWrapper clearfix ${
                            errors.contactPhone && 'errorWrapper'
                          }`}
                        >
                          <div>
                            <label htmlFor='contactPhone' aria-labelledby='contactPhone'>
                              Company Phone Number *
                            </label>
                            <input
                              defaultValue={profileInfo.contactPhone}
                              id='contactPhone'
                              maxLength={50}
                              name='contactPhone'
                              onChange={inputOnChange}
                              placeholder='Company Phone Number'
                              ref={register({ 
                                required: true,
                                validate: isValidRequiredTrimmingSpace,
                                minLength: 10,
                              })}
                              type='text'
                            />
                          </div>
                          <div className='errorMsg'>
                            <span>
                              {errors.contactPhone && 'Ten Digit Company Phone Number is required'}
                            </span>
                          </div>
                        </div>

                        <div
                          className={`inputWrapper clearfix ${errors.website && 'errorWrapper'}`}
                        >
                          <div>
                            <label htmlFor='website' aria-labelledby='website'>
                              Website
                            </label>
                            <input
                              defaultValue={profileInfo.website}
                              id='website'
                              maxLength={50}
                              name='website'
                              onChange={inputOnChange}
                              placeholder='Website'
                              ref={register({
                                pattern: {
                                  value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                                  message: 'Website is not formatted properly',
                                },
                              })}
                              type='text'
                            />
                          </div>
                          <div className='errorMsg'>
                            <span>{errors.website && errors.website.message}</span>
                          </div>
                        </div>

                        <AddressLookup />
                      </fieldset>
                    </form>
                  </FormProvider>

                  <div className='row'>
                    <div className='col'>
                      <Buttons
                        classNames='bttn bttn-secondary m-auto'
                        text={'Go Back'}
                        onClick={() => moveBack()}
                      />

                      <Buttons
                        classNames='bttn bttn-accent m-auto bttn-continue'
                        text={continueButtonText}
                        onClick={() => moveForward()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CompleteProfile);
