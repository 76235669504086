import { axiosRequestAuthenticated } from './api';
import { constants } from '../../utils/settings';
import { MemberInfoApiResponse } from '../../types';
import { Paths } from '../../utils/constants';

/**
 * Typed MemberInfo request
 * @param isCurrentMember: boolean
 */
export interface GetMemberInfoParams {
  isCurrentMember: boolean;
  memberId?: string;
}
export function getCurrentMemberInfo(
  requestParams: GetMemberInfoParams = {
    isCurrentMember: true,
    memberId: '',
  },
) {
  return axiosRequestAuthenticated<MemberInfoApiResponse>({
    baseURL: constants.api.userUrl,
    data: {
      ...requestParams,
    },
    method: 'POST',
    url: Paths.memberInfo,
  });
}
