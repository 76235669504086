import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { memberTypes } from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import { Buttons } from '../../../../customcontrols/index';
import NoResult from '../../../../customcontrols/noresult';
import { bidInfoParamType, supplierItemFields } from '../../../../../types/biddetail';
import { sharedTypes } from '../../../../../types/shared';
import { formattedPhoneNumber } from '../../../../../utils/helpers';

const tdWidth = {
  width: '180px',
};

interface PropsTypes {
  currentsupplier?: supplierItemFields;
  bidInfo: bidInfoParamType;
  removeBidPlanholderSupplier?: any;
  editBidPlanHolder?: any;
  setModelOpen?: any;
  changeAddSupplierActive?: any;
  pagefor?: string;
  currentData: supplierItemFields;
  shared?: sharedTypes;
  setBidSummaryDetails?: any;
}

function Supplierdetails(props: PropsTypes) {
  const {
    bidInfo,
    removeBidPlanholderSupplier,
    editBidPlanHolder,
    setModelOpen,
    changeAddSupplierActive,
    pagefor = 'planholders',
    currentData,
    shared,
    setBidSummaryDetails,
  } = props;
  const { PlanholderDetail_internalLoader = false } = shared || {};
  const {
    mi = '',
    supplierName = '',
    memberType: supplierType,
    fbi: forBidId,
    firstName = '',
    lastName = '',
    city = '',
    state = '',
    address1 = '',
    address2 = '',
    postalCode = '',
    selfDeclarations: declaredAttributes = '',
    uniqueRequests = '',
    agencyRequests = '',
    agencyAddedPlanholder = false,
    industries = '',
    fti = '',
    phone = '',
    email = '',
  } = currentData || {};

  const { memberId = 0, bidMemberId = 0, bidId = 0 } = bidInfo || {};

  const RemoveSupplier = () => {
    removeBidPlanholderSupplier({ supplierId: mi, bidId });
  };

  const editPlanholder = () => {
    editBidPlanHolder({ ...currentData, isEdit: true, editedMemberId: currentData.mi });
    setBidSummaryDetails({ addSupplierModalOpen: true, modalType: 'edit' });
    setModelOpen({ openType: true, modalType: 'edit' });
    changeAddSupplierActive({ addSupplierActive: 'add' });
  };

  return (
    <>
      <h4 data-testid='biddetails.planholders.tab.suppliersdetails.head'>Supplier Details</h4>
      {currentData && currentData.email ? (
        <div className='innerColIndent'>
          <Table borderless className='tableData'>
            <tbody data-testid='biddetails.planholders.tab.suppliersdetails.body'>
              <tr>
                <th style={tdWidth}>Supplier Name</th>
                <td>{supplierName}</td>
              </tr>
              <tr>
                <th>Contact Name</th>
                <td>
                  {firstName} {lastName}
                </td>
              </tr>
              {address1 && city && state && postalCode && (
                <tr>
                  <th>Address</th>
                  <td>
                    {address1} {address2}, {city}, {state} {postalCode}
                  </td>
                </tr>
              )}

              {industries ? (
                <tr>
                  <th>Industry</th>
                  <td>{industries}</td>
                </tr>
              ) : null}
              {email ? (
                <tr>
                  <th>Email</th>
                  <td>{email}</td>
                </tr>
              ) : null}
              {phone ? (
                <tr>
                  <th>Phone Number</th>
                  <td>{formattedPhoneNumber(phone)}</td>
                </tr>
              ) : null}
              {declaredAttributes ? (
                <tr>
                  <th>Self Declarations</th>
                  <td>{declaredAttributes}</td>
                </tr>
              ) : null}
              {fti ? (
                <tr>
                  <th>Federal Tax ID</th>
                  <td>{fti}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
          {memberId === bidMemberId &&
            (uniqueRequests === agencyRequests || agencyAddedPlanholder === true) && (
              <>
                {pagefor === 'planholders' && (
                  <Buttons
                    text='Remove'
                    title='Remove'
                    onClick={RemoveSupplier}
                    classNames='bttn bttn-secondary'
                  />
                )}
                {supplierType === memberTypes.agencyCreatedPlanholder &&
                  forBidId === bidId &&
                  pagefor === 'planholders' && (
                    <Buttons
                      text='Edit'
                      title='Edit'
                      onClick={editPlanholder}
                      classNames='bttn bttn-secondary float-right'
                    />
                  )}
              </>
            )}
        </div>
      ) : (
        <NoResult
          message={PlanholderDetail_internalLoader ? loadingMsg : 'No Supplier Details Available'}
        />
      )}
    </>
  );
}

export default memo(Supplierdetails);
