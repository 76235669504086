import styled from 'styled-components';

import { Colors, FontWeight, Margin, Padding } from '../../../../shared/styles';

/**
 * Header Row for NotificationRecipients
 */
export const NotificationHeaderRow = styled.div`
  display: block;
  width: 100%;
  label {
    display: inline-block;
    width: 25%;
  }
  span {
    display: inline-block;
    width: 75%;
    padding-left: ${Padding.base};
    font-weight: ${FontWeight.bold};
  }
`;

/**
 * A quarter length cell good for labels.
 */
export const NotificationLabelCell = styled.div`
  display: inline-block;
  width: 25%;
  vertical-align: top;
  font-weight: ${FontWeight.normal};
  label {
    margin-bottom: 0;
  }
  span {
    display: block;
    color: ${Colors.disabledText};
  }
`;

/**
 * The other 75%. Paired with above.
 */
export const NotificationContentCell = styled.div`
  display: inline-block;
  width: 75%;
  padding-left: ${Padding.base};
`;

/**
 * Simple row that keeps things working smoothly when toggling
 */
interface NotificationFormRowProps {
  condensed?: boolean;
  header?: boolean;
}

export const NotificationFormRow = styled.div<NotificationFormRowProps>`
  display: block;
  margin-bottom: ${props => (props.condensed ? 0 : Margin.base)};
  min-height: ${props => (props.condensed ? 0 : '34px')};
  font-weight: ${props => (props.header ? FontWeight.bold : FontWeight.normal)};
`;

/**
 * A simple cell.
 */
interface CellProps {
  width?: string;
}
export const Cell = styled.div<CellProps>`
  display: inline-block;
  width: ${props => props.width};
  padding-left: ${Padding.base};
`;

/**
 * A row used in MessagePreview component.
 */
export const MessagePreviewRow = styled.div`
  display: block;
  label {
    display: inline-block;
    width: 25%;
  }
  span,
  a {
    padding-left: ${Padding.base};
    display: inline-block;
    width: 75%;
  }
`;

/**
 * A basic section that gives a bit of extra padding.
 */
export const Section = styled.div`
  display: block;
  margin-top: ${Margin.extra};
`;
