import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';

export function getCampaignStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getCampaignStatus, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function updateCampaignFirstPopup(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateCampaignFirstPopup, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function updateCampaignNotNowPopup(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateCampaignNotNowPopup, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function updateCampaignFeedbackPopup(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateCampaignFeedbackPopup, data, {
    baseURL: constants.api.trackUrl,
  });
}
