import React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { BidDetailsLink } from '../../../common/controls/text/links/BidDetailsLink';
import { bidSummaryState } from '../../../../store/recoil/bidState';
import { hasValue } from '../../../../utils/helpers';
import { LoadableWrapper } from '../../../common/loading/LoadableWrapper';
import { NotificationRow } from './NotificationRow';
import { notifyAwardeeTexts } from '../../../../utils/texts/agency/awardBid';
import { ReviewDocsText } from './../notify-pages/notify-awardees/ReviewDocsText';
import { Section } from './../styled/AwardBidStyled';

export const AwardNotificationBidDetails = ({ requiresDocs }: { requiresDocs?: boolean }) => {
  const bidSummary = useRecoilValueLoadable(bidSummaryState);

  return (
    <Section>
      <h4>{notifyAwardeeTexts.headers.bidDetails}</h4>
      <LoadableWrapper loadable={bidSummary}>
        {hasValue(bidSummary) && bidSummary.contents && (
          <div>
            <NotificationRow label={notifyAwardeeTexts.labels.agency}>
              <span>{bidSummary.contents.agencyName}</span>
            </NotificationRow>
            <NotificationRow label={notifyAwardeeTexts.labels.bidWriter}>
              <span>{bidSummary.contents.bidWriter}</span>
            </NotificationRow>
            <NotificationRow label={notifyAwardeeTexts.labels.bidNumber}>
              <span>{bidSummary.contents.bidNumber}</span>
            </NotificationRow>
            <NotificationRow label={notifyAwardeeTexts.labels.bidName}>
              <BidDetailsLink bidId={bidSummary.contents.bidID}>
                {bidSummary.contents.bidName}
              </BidDetailsLink>
            </NotificationRow>
            {requiresDocs && (
              <NotificationRow label={notifyAwardeeTexts.labels.reviewDocs}>
                <ReviewDocsText />
              </NotificationRow>
            )}
          </div>
        )}
      </LoadableWrapper>
    </Section>
  );
};
