//TOREFACTOR - how is this different / the same as the other datepicker.tsx?

import moment, { Moment } from 'moment-timezone';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useDispatch } from 'react-redux';
import * as actionTypes from '../../../../store/actionTypes';
import { datePickerYear, invalidDateMessage, usaDateFormat } from '../../../../utils/constants';
import { deprecatedConvertUSAFormat, validateTypedDate } from '../../../../utils/helpers';

interface DatePickerProps {
  handleChange?: (name: string, date: any) => void;
  type?: any;
  disabled?: any;
  label?: string;
  meta?: any;
  optional?: any;
  input?: any;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  parentClass?: string;
  showClearDate?: any;
  showDefaultInputIcon?: any;
  isOutsideRange?: any;
  numberOfMonths?: any;
  maxLength?: any;
  classNames?: any;
  field: any;
  form: any;
}

function DatepickerField(props: DatePickerProps) {
  const {
    handleChange = () => {},
    disabled,
    label,
    // name = '',
    // value = '',
    meta = {},
    optional,
    input,
    placeholder = usaDateFormat,
    minDate = moment(),
    maxDate,
    parentClass,
    showClearDate = false,
    isOutsideRange = false,
    numberOfMonths = 1,
    field = {},
    form = {},
  } = props;
  const { name = '' } = field;
  const { setFieldValue, errors = false, touched = false } = form;
  const { showDefaultInputIcon = true } = props;
  //   const { touched, error } = meta || {};
  const [focused, setFocused] = useState(false);
  const [internalError, setInternalError] = useState('');
  //   const idGen = reduxform ? `single_date_redux_${input.name}` : `single_date_${name}`;
  const idGen = `${name}`;
  const dispatch = useDispatch();

  const returnYears = () => {
    const years = [];
    for (let i = moment().year() - datePickerYear; i <= moment().year() + datePickerYear; i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <select
            // style={{ border: '1px solid #eee' }}
            value={month.month()}
            onChange={e => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            // style={{ border: '1px solid #eee' }}
            value={month.year()}
            onChange={e => onYearSelect(month, e.target.value)}
          >
            {returnYears()}
          </select>
        </div>
      </div>
    );
  };

  const settingErrorFn = useCallback(
    (value: string) => {
      setInternalError(value);
      dispatch({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { invaliddateerror: value },
      });
    },
    [dispatch],
  );

  const onSingleDateChange = useCallback(
    (date: Moment | null | string = '') => {
      // handleChange(name, date);
      setFieldValue(name, date);

      if (moment(date, usaDateFormat).isValid()) {
        settingErrorFn('');
      } else {
        settingErrorFn(invalidDateMessage);
      }
    },
    [name, setFieldValue, settingErrorFn],
  );

  const checkDateFn = useCallback(
    (event: KeyboardEvent) => {
      const evt = event.target as HTMLInputElement;
      if (evt && !evt.value) {
        settingErrorFn('');
      }
      if (evt && evt.value && !moment(evt.value, usaDateFormat).isValid()) {
        settingErrorFn(invalidDateMessage);
      }
    },
    [settingErrorFn],
  );

  const convertDateFn = useCallback(
    event => {
      const evt = event.target;
      const finaldate =
        evt && evt.value && evt.value.length >= 6 ? deprecatedConvertUSAFormat(evt.value) : '';
      if (finaldate && validateTypedDate(finaldate, minDate, maxDate, isOutsideRange)) {
        onSingleDateChange(moment(finaldate));
      } else {
        if (evt.value) settingErrorFn(invalidDateMessage);
      }
    },
    [isOutsideRange, maxDate, minDate, onSingleDateChange, settingErrorFn],
  );

  useEffect(() => {
    const datePicker = document.getElementById(idGen);
    if (datePicker) {
      datePicker.addEventListener('keyup', checkDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('keyup', checkDateFn);
      }
    };
  }, [checkDateFn, idGen]);

  useEffect(() => {
    const datePicker = document.getElementById(idGen);
    if (datePicker) {
      datePicker.addEventListener('focusout', convertDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('focusout', convertDateFn);
      }
    };
  }, [convertDateFn, idGen]);

  const onCloseValidationFn = () => {
    setTimeout(() => {
      settingErrorFn('');
    }, 10);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { invaliddateerror: '' } });
    };
  }, [dispatch]);
  return (
    <div
      className={
        'datePickerWrapper ' +
        parentClass +
        ((touched[name] && errors[name]) || internalError ? ' errorWrapper' : '')
      }
    >
      <div title={usaDateFormat}>
        <label title={usaDateFormat}>{label}</label>
        <SingleDatePicker
          date={field.value ? field.value : null} // momentPropTypes.momentObj or null
          onDateChange={onSingleDateChange} // PropTypes.func.isRequired
          focused={focused} // PropTypes.bool
          numberOfMonths={numberOfMonths}
          onFocusChange={({ focused }: any) => setFocused(focused)} // PropTypes.func.isRequired
          id={idGen} // PropTypes.string.isRequired,
          //maxDate={maxDate?moment(maxDate):null}
          //minDate={moment(minDate)}
          disabled={disabled}
          isOutsideRange={(day: any) => {
            if (!isOutsideRange) {
              if (minDate && maxDate) {
                return day.isAfter(maxDate) || day.isBefore(minDate);
              }
              if (minDate) return day.isBefore(minDate);
            }
          }}
          showClearDate={showClearDate}
          showDefaultInputIcon={showDefaultInputIcon}
          placeholder={placeholder}
          renderMonthElement={renderMonthElement}
          hideKeyboardShortcutsPanel={true}
          customCloseIcon={<i className='mdi mdi-close' onClick={onCloseValidationFn} />}
          displayFormat={() => usaDateFormat}
          {...field}
        />
        {/* )} */}
      </div>
      {optional === true ? <span>(optional)</span> : optional ? <span>{optional}</span> : ''}
      <div className='errorMsg'>
        {(touched[name] && errors[name]) || internalError ? (
          <>{<span>{internalError ? internalError : errors[name]}</span>}</>
        ) : null}
      </div>
    </div>
  );
}

export default memo(DatepickerField);
