import { RequiredActionType } from '../types/required-action';

/**
 * Generate a unique id based upon item type and numeric id
 * @param actionType: RequiredActionType
 * @param actionId: number
 * @returns string
 */
export function getRequiredActionUniqueId(actionType: RequiredActionType, actionId: number) {
  return `${actionType}_${actionId}`;
}
