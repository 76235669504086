import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { quoteBreakdownStatus } from '../../../../../../utils/constants';
import { formatAddress, formattedPhoneNumber } from '../../../../../../utils/helpers';
import { suppliertype } from '../../../../../../types/quotedetailstypedef';
import { addbidParamType } from '../../../../../../types/addbid';
import { sharedTypes } from '../../../../../../types/shared';

const tdWidth = {
  width: '210px',
};

interface PropsTypes {
  supplier: suppliertype;
  lineitemLength: number;
  shared: sharedTypes;
  addbid: addbidParamType;
}
const Details = (props: PropsTypes) => {
  const { supplier, lineitemLength, shared, addbid } = props;
  const { activeprograms } = addbid;
  const { selfdeclaredattributes } = shared;
  const {
    supplierName = 'Not Specified',
    preparer = 'Not Specified',
    price = 'Not Specified',
    quotedCount = 0,
    address1 = '',
    address2 = '',
    city = '',
    stateAbbr = '',
    postalCode = '',
    countryName = '',
    phoneNumber = 'Not Specified',
    email = 'Not Specified',
    responseType = '',
    responseAbbr = '',
    reason = '',
  } = supplier;

  const address = formatAddress(address1, address2, city, stateAbbr, postalCode, countryName);

  const declaration =
    selfdeclaredattributes.filter(item => item.selected).map(items => items.attributeName) || [];
  const programs = activeprograms.map(item => item.label) || [];

  const Selfprogram = () => {
    return (
      <>
        {declaration.length > 0 ? (
          <tr>
            <th>Self Declarations</th>
            <td>{declaration.join(', ')}</td>
          </tr>
        ) : null}
        {programs.length > 0 ? (
          <tr>
            <th>Certifications</th>
            <td>
              {programs.map(item => (
                <>{item}, </>
              ))}
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  return (
    <>
      <h4>Supplier Details</h4>
      <div className='innerColIndent'>
        <Table borderless className='tableData'>
          <tbody>
            {quoteBreakdownStatus.includes(responseType) ? (
              <>
                <tr>
                  <th style={tdWidth}>
                    {responseType === 'NQ' ? 'Reason' : 'Response'} Submitted By
                  </th>
                  <td>{supplierName}</td>
                </tr>
                {responseType === 'NQ' ? (
                  <tr>
                    <th style={tdWidth}>Reason for Not Quoting</th>
                    <td>{reason ? reason : 'Not Specified'}</td>
                  </tr>
                ) : null}
                <tr>
                  <th>Preparer</th>
                  <td>{preparer}</td>
                </tr>
                {address ? (
                  <tr>
                    <th>Address</th>
                    <td>{address}</td>
                  </tr>
                ) : null}

                <tr>
                  <th>Phone</th>
                  <td>
                    {phoneNumber !== 'Not Specified'
                      ? formattedPhoneNumber(phoneNumber)
                      : phoneNumber}
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{email}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>${price}</td>
                </tr>
                <Selfprogram />
                <tr>
                  <th>Status Notes</th>
                  <td>{responseAbbr}</td>
                </tr>
                <tr>
                  <th>Complete / Partial</th>
                  <td>{quotedCount === lineitemLength ? 'Completed' : 'Partially Completed'}</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <th style={tdWidth}>Name</th>
                  <td>{supplierName}</td>
                </tr>
                <tr>
                  <th>Status Notes</th>
                  <td>{responseAbbr}</td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default memo(Details);
