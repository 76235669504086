import { DSLink } from '@demandstar/components/link';
import React from 'react';
import styled from 'styled-components';

import { Margin, Padding } from '../../../shared/styles';
import { Button } from '../../common/controls/buttons/Button';
import { navigation } from '../../../utils/texts/common';
import { scrollToTop } from '../../../utils/helpers';
import { SubmitButton } from '../../common/controls/buttons/SubmitButton';

const WizardButtonsWrapper = styled.div`
 display: block;
 width: 100%;
 margin-top: ${Margin.extra};
 }
`;
const WizardNextButton = styled(SubmitButton)`
float: right;
}
`;
const WizardPrevButton = styled(Button)`
float: left;
}
`;

const WizardSaveSpan = styled.span`
  float: right;
  padding: ${Padding.base};
}
`;

interface WizardButtonsProps {
  onPrevious: () => void;
  onNext: () => void;
  save?: () => void;
  disableNext?: boolean;
  altNext?: string;
  altPrev?: string;
  disablePrev?: boolean;
  disableAll?: boolean;
}

export const WizardButtons = (props: WizardButtonsProps) => {
  const {
    onPrevious,
    onNext,
    save,
    disableNext,
    disablePrev,
    disableAll,
    altNext,
    altPrev,
  } = props;
  function prev() {
    scrollToTop();
    onPrevious();
  }
  function next() {
    scrollToTop();
    onNext();
  }
  return (
    <WizardButtonsWrapper>
      <WizardPrevButton
        disabled={disablePrev || disableAll}
        title={altPrev || navigation.previousPage}
        onClick={prev}
      >
        {altPrev || navigation.previousPage}
      </WizardPrevButton>
      <WizardNextButton
        disabled={disableNext || disableAll}
        title={altNext || navigation.nextPage}
        onClick={next}
      >
        {altNext || navigation.nextPage}
      </WizardNextButton>
      {save && !disableAll && (
        <WizardSaveSpan>
          <DSLink onClick={save}>{navigation.saveAndFinishLater}</DSLink>
        </WizardSaveSpan>
      )}
    </WizardButtonsWrapper>
  );
};
