import React, { memo, useState } from 'react';
import { ModalPopUp } from '../../../../../common/modals/ModalPopUp';
import BidWriterContent from './bidwritercontent';
import { bidssummaryParamType } from '../../../../../../types/biddetail';
// import { memberTypes } from "../../../../../../utils/constants";

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  getWriterInfo?: any;
  setSharedDetails?: any;
  bidWriter: string;
}

const BidWriter = (props: PropsTypes) => {
  const { bidssummary, getWriterInfo, setSharedDetails } = props;
  const { /*writerInfo: WInfo,*/ results, writerInfo } = bidssummary || {};
  const { bidWriterAccountID, memberID, bidID: bidId } = results || {};
  // const writerInfo = WInfo.writerInfo;
  const [clicked, setClicked] = useState(false);

  const handleClick = async (e: any) => {
    e.preventDefault();
    // if (writerInfo) {
    // 	setClicked(!clicked);
    // } else {
    if (
      bidId &&
      bidWriterAccountID &&
      memberID &&
      !clicked &&
      (!writerInfo || (writerInfo && writerInfo.writerInfo.us !== bidWriterAccountID))
    ) {
      setSharedDetails({ internalLoader: true });
      getWriterInfo({ writerAccountID: bidWriterAccountID, memberID: memberID });
    }
    setClicked(!clicked);
    // }
  };

  const { bidWriter /* , auth */ } = props;
  // const { mt: membertype } = auth;
  return (
    <>
      <span className='staticLink' onClick={handleClick}>
        {bidWriter}
      </span>
      <ModalPopUp title={'Bid Writer'} closeModal={handleClick} isOpen={clicked}>
        <BidWriterContent {...props} />
      </ModalPopUp>
    </>
  );
};

export default memo(BidWriter);
