import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { limitedactionTypes } from '../../types/limited';

export function setLimitedDetails(payload: limitedactionTypes) {
  return action(actionTypes.SET_LIMITED_DETAILS, payload);
}

export function resetLimitedDetails(payload: limitedactionTypes) {
  return action(actionTypes.RESET_LIMITED_DETAILS, payload);
}

export function getLimitedAgencySummary(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_SUMMARY, payload);
}

export function getLimitedAgencyDocuments(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_DOCUMENTS, payload);
}

export function getLimitedAgencyLegal(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_LEGAL, payload);
}

export function getLimitedAgencyPlanholders(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_PLANHOLDERS, payload);
}

export function getLimitedAgencyAwards(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_AWARDS, payload);
}

export function getLimitedAgencyCommodities(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_COMMODITIES, payload);
}

export function getLimitedAgencySelfDeclarations(payload: limitedactionTypes = undefined) {
  return action(actionTypes.GET_LIMITED_AGENCY_SELF_DECLARATIONS, payload);
}

export function getLimitedStatesList(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_STATES_LIST, payload);
}

export function getLimitedCountiesList(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_COUNTIES_LIST, payload);
}

export function getLimitedTimezoneList(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_TIMEZONE_LIST, payload);
}

export function getLimitedMetroList(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_METRO_LIST, payload);
}

export function submitLimitedAddMemberAccount(payload: limitedactionTypes) {
  return action(actionTypes.SUBMIT_LIMITED_ADD_MEMBER_ACCOUNT, payload);
}

export function getLimitedWappagencies(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_WAPP_AGENCIES, payload);
}

export function submitLimitedForgotPassword(payload: limitedactionTypes) {
  return action(actionTypes.SUBMIT_LIMITED_FORGOT_PASSWORD, payload);
}

export function submitLimitedForgotEmail(payload: limitedactionTypes) {
  return action(actionTypes.SUBMIT_LIMITED_FORGOT_EMAIL, payload);
}

export function submitLimitedResetPassword(payload: limitedactionTypes) {
  return action(actionTypes.SUBMIT_LIMITED_RESET_PASSWORD, payload);
}

export function getLimitedVerifySecurityCode(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_VERIFY_SECURITY_CODE, payload);
}

export function getLimitedAgencyLanding(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_LANDING, payload);
}

export function getLimitedAgencyDetails(payload: limitedactionTypes) {
  return action(actionTypes.GET_LIMITED_AGENCY_DETAILS, payload);
}
