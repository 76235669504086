import { Loadable } from 'recoil';

export type ValueLoadable = Loadable<unknown> & {
  state: 'hasValue';
};
/**
 * to check if a a Loadable has successfully loaded. Includes type guard!
 * @param loadable
 * @returns boolean
 */
export function hasValue(loadable: Loadable<unknown>): loadable is ValueLoadable {
  return loadable.state === 'hasValue';
}
