import React, { useState } from 'react';
import { Buttons, CheckBox, DeprecatedRadio } from '../../../../customcontrols';

interface PropsTypes {
  isExport: boolean;
  handleOnClick: any;
}

function PublishTabulationSheet(props: PropsTypes) {
  const { isExport } = props;
  const [includeNonCompliant, setincludeNonCompliant] = useState(false);
  const [exportFormat, setExportFormat] = useState('PDF');

  const handleRadioClick = (name: string, value: any) => {
    setExportFormat(value);
  };

  const handleOnClick = () => {
    props.handleOnClick(includeNonCompliant, exportFormat, props.isExport);
  };

  return (
    <>
      <div>
        {!isExport && (
          <p>
            Once you have reviewed the tabulation sheet details, you may create a downloadable
            version by using Publish button.
          </p>
        )}
        <p>
          <CheckBox
            title='Include Non-Compliant Supplier details'
            handleChecked={(name: string, value: any) => {
              setincludeNonCompliant(value);
            }}
            checked={includeNonCompliant}
            value={includeNonCompliant}
          />
        </p>
        <p>
          <DeprecatedRadio
            name='fileFormat'
            title='PDF'
            value='PDF'
            type='radio'
            handleSelect={handleRadioClick}
            parentClass='d-inline-block mr-4'
            defaultChecked={exportFormat === 'PDF'}
          />
          <DeprecatedRadio
            name='fileFormat'
            title='XLSX'
            value='XLSX'
            type='radio'
            handleSelect={handleRadioClick}
            parentClass='d-inline-block'
            defaultChecked={exportFormat === 'XLSX'}
          />
        </p>
        <Buttons
          text={isExport ? 'Export' : 'Publish'}
          title={isExport ? 'Export' : 'Publish'}
          classNames='bttn bttn-secondary float-right'
          onClick={handleOnClick}
        />
      </div>
    </>
  );
}

export default PublishTabulationSheet;
