import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { formatPrice } from '../../../../utils/helpers';
import NoResult from '../../../customcontrols/noresult';
import { loadingMsg } from '../../../../utils/texts';
import { Buttons } from '../../../customcontrols';
import Configurations from '../../../../settings';
import { accountinfoType, anyParamType } from '../../../../types/paymentdetails';
import { authTypes } from '../../../../types/auth';
import { productsType } from '../../../../types/subscriptions';
import { Link } from 'react-router-dom';
import { ProductTypeEnum } from '../../../../types/products';
import { Product } from '../../../../containers/AgencySelectionContainer';

const tdWidth = {
  product: {
    width: '55%',
  },
  type: {
    width: '25%',
  },
};

interface PropsTypes {
  accountinfo?: accountinfoType;
  products: productsType;
  totalCost?: number;
  upgradeSupplier: anyParamType;
  auth?: authTypes;
  userType: string;
  naProducts: Product[];
}

const Products = (props: PropsTypes) => {
  /* eslint-disable prefer-const */
  let {
    products = [],
    totalCost = 0,
    accountinfo,
    upgradeSupplier,
    auth,
    userType,
    naProducts,
  } = props;
  /* eslint-enable prefer-const */
  const { subscription_internalLoader } = accountinfo || {};
  if (products && products.length > 0) {
    products.map(product => (totalCost = totalCost + product.price));
  }

  const { /*mt: memberType,*/ opi = '' } = auth || {};

  return (
    <div className='colWrapper'>
      <h3 className='arrowWrapper'>Subscriptions</h3>
      <div className='innerColIndent'>
        <Table className='tableHData'>
          <tr>
            <th style={tdWidth.product}>Product</th>
            <th style={tdWidth.type}>Type</th>
            <th className='text-right'>Price/Year</th>
          </tr>
          {products && products.length > 0 ? (
            <>
              {products.map((product, index) => (
                <tr key={index}>
                  <td>{product.productName}</td>
                  <td>{ProductTypeEnum[product.productType]}</td>
                  <td className='text-right'>
                    {product.price && `$${formatPrice(product.price, 2)}`}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={5}>
                <NoResult
                  message={subscription_internalLoader ? loadingMsg : 'No Subscriptions Available'}
                />
              </td>
            </tr>
          )}
        </Table>
        {products && products.length > 0 ? (
          <Table borderless>
            <tr>
              <td style={tdWidth.product} />
              <td style={tdWidth.type}>Total Yearly Cost:</td>
              <td className='text-right'>{totalCost && `$${formatPrice(totalCost, 2)}`}</td>
            </tr>
          </Table>
        ) : null}

        {opi && ['SB', 'FA', 'SS'].includes(userType) && naProducts.length === 0 ? (
          <Buttons
            classNames='bttn-primary ml-2 float-right'
            text={userType === 'SB' ? 'Upgrade to free agency' : 'Modify free agency selection'}
            title={userType === 'SB' ? 'Upgrade to free agency' : 'Modify free agency selection'}
            type='button'
            onClick={() => upgradeSupplier()}
          />
        ) : null}

        <Link
          className='bttn-primary mb-2 float-right text-center'
          title='Renew / Upgrade'
          rel='noopener noreferrer'
          // target='_blank'
          to='/subscription/renewal'
        >
          Renew / Upgrade
        </Link>
      </div>
    </div>
  );
};

export default memo(Products);
