import React, { memo, useCallback, useState } from 'react';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { Buttons, ToggleBtn } from '../../../customcontrols';

interface PropsTypes {
  data: {
    isAutoRenewalOn: boolean;
    updateAutoRenewalSubscription: any;
  };
}

const AutoRenewal = (props: PropsTypes) => {
  const { isAutoRenewalOn, updateAutoRenewalSubscription /*, onSubmit*/ } = props.data;
  const [modalOpen, setModelOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setModelOpen(!modalOpen);
  };

  const handleOnSubmit = useCallback(
    async event => {
      event.preventDefault();
      const payload = {
        state: !isAutoRenewalOn,
      };
      //
      updateAutoRenewalSubscription(payload);
      //onSubmit()
      setModelOpen(!modalOpen);
    },
    [isAutoRenewalOn, updateAutoRenewalSubscription, modalOpen, setModelOpen],
  );

  const autoRenewalContent = (
    <AutoRenewalContent
      {...props}
      isAutoRenewalOn={isAutoRenewalOn}
      onChangeAutoRenewal={handleOnSubmit}
      handleClick={handleClick}
    />
  );
  return (
    <>
      <ToggleBtn
        value={isAutoRenewalOn}
        classNames='class'
        onChange={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick(e)}
        reduxform={false}
        checked={isAutoRenewalOn}
      />

      <ModalPopUp title={'Auto Renewal'} size={'md'} isOpen={modalOpen} closeModal={handleClick}>
        {autoRenewalContent}
      </ModalPopUp>
    </>
  );
};

interface AutoRenewalProps {
  isAutoRenewalOn: boolean;
  onChangeAutoRenewal: any;
  handleClick: any;
}

const AutoRenewalContent = (props: AutoRenewalProps) => {
  const { isAutoRenewalOn, onChangeAutoRenewal, handleClick } = props;
  return (
    <>
      <form onSubmit={onChangeAutoRenewal}>
        <p>Are you sure you want to turn {!isAutoRenewalOn ? 'on' : 'off'} auto renewal?</p>
        <Buttons classNames='bttn bttn-secondary' onClick={handleClick} text='No' />
        <Buttons classNames='bttn bttn-primary float-right' type='submit' text='Yes' />
      </form>
    </>
  );
};

export default memo(AutoRenewal);
