import { atom, selector } from 'recoil';

import { AcceptedFormat, AgencyDocs } from '../../types/publications';

import { defaultAgencyDocs } from '../../shared/constants';
import { getAccountDocumentInfo } from '../services/document';
import { track } from '../../utils/telemetry';

/**
 * Bid Document Types
 * @description Make a service API call to populate a Recoil Selector.
 */
const agencyDocumentsSelector = selector<AgencyDocs>({
  key: 'agencyDocumentsSelector',
  get: async ({ get }) => {
    try {
      // API call
      // No request params: `acountId` is inherent to the authorized request.
      const response = await getAccountDocumentInfo();

      // console.log('agencyDocumentsSelector', response);
      return response;
    } catch (error: any) {
      track('agencyDocumentsSelector -> getAccountDocumentInfo() ERROR:', {
        error,
        method: 'POST',
        errorMessage: error.message,
      });
      console.error(`agencyDocumentsSelector -> getAccountDocumentInfo() ERROR: \n${error}`);
      // throw new Error(error.message);
      return defaultAgencyDocs;
    }
  },
});

export const agencyDocumentsState = atom<AgencyDocs>({
  key: 'agencyDocumentsState',
  default: agencyDocumentsSelector,
});

/**
 * Accepted Document Fromat Type objects
 * @description Depends on agencyDocumentsState.
 */
const acceptedDocumentFormatListSelector = selector<AcceptedFormat[]>({
  key: 'acceptedDocumentFormatListSelector',
  get: ({ get }) => {
    return get(agencyDocumentsState)?.acceptedFormats || [];
  },
});

export const acceptedDocumentFormatListState = atom<AcceptedFormat[]>({
  key: 'acceptedDocumentFormatListState',
  default: acceptedDocumentFormatListSelector,
});

/**
 * Accepted Document Fromat Types convertible to PDF
 * @description Depends on acceptedDocumentFormatListState, filtered by formats where `isConversion` is true.
 * @todo Refactor or use mapped types to avoid converting resulting array to `any[]`.
 */
const pdfConvertibleDocumentFormatListSelector = selector<any[]>({
  key: 'pdfConvertibleDocumentFormatListSelector',
  get: ({ get }) => {
    const acceptedDocumentFormatList = get(acceptedDocumentFormatListState);
    return acceptedDocumentFormatList
      .filter(docFormat => docFormat.isConversion)
      .map(docFormat => docFormat.docFormatType.trim().toLocaleUpperCase());
  },
});

export const pdfConvertibleDocumentFormatListState = atom<string[]>({
  key: 'pdfConvertibleDocumentFormatListState',
  default: pdfConvertibleDocumentFormatListSelector,
});

export const documentUploadFormChangedState = atom<boolean>({
  key: 'documentUploadFormChangedState',
  default: false,
});

export const documentDownloadInProgressState = atom<boolean>({
  key: 'documentDownloadInProgressState',
  default: false,
});
