import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function checkAccountExists(payload: actionPayloadTypes) {
  return action(actionTypes.CHECK_ACCOUNT_EXISTS, payload);
}

export function setRegistrationData(payload: actionPayloadTypes) {
  return action(actionTypes.SET_REGISTRATION_DATA, payload);
}

export function addInCompleteRegistrationData(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_INCOMPLETE_REGISTRATION_DATA, payload);
}

export function addMemberAccount(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_MEMBER_ACCOUNT, payload);
}

export function loadPreInCompleteRegistration(payload: { email: string }) {
  return action(actionTypes.LOAD_PRE_INCOMPLETE_REGISTRATION, payload);
}

export function claimPlanholderAccount(payload: actionPayloadTypes) {
  return action(actionTypes.CLAIM_PLANHOLDER_ACCOUNT, payload);
}
