import React from 'react';

interface PropTypes {
  title?: string;
  subtitle?: string;
  count?: any;
  subcounttitle?: string;
  subcount?: '' | undefined | number | string;
  classNames?: '' | undefined | string;
  gotoPage?: any;
  count0Text?: string;
  count0SubText?: string;
  handleClick?: (e: React.MouseEvent) => void;
}

function CardCount(props: PropTypes) {
  const {
    title = '',
    subtitle = '',
    count = '',
    subcounttitle = '',
    subcount = '',
    classNames = '',
    gotoPage,
    count0Text = '',
    count0SubText = '',
    handleClick,
  } = props;

  const pointerClass = !gotoPage ? '' : 'cursorPointer';

  return (
    <>
      {count && parseInt(count) !== 0 ? (
        <div
          onClick={gotoPage}
          className={`d-flex align-items-center flex-column bd-highlight dbCountWrapper ${pointerClass} ${classNames}`}
        >
          <h6 className='mb-auto'>
            {title}
            <i>{subtitle}</i>
          </h6>
          <span>{count}</span>
          {subcounttitle !== '' && subcounttitle.length > 0 ? (
            <div>
              {subcounttitle}
              {subcount > 0 && <span className='d-block'>{subcount}</span>}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          onClick={handleClick}
          className={
            'd-flex align-items-center bd-highlight dbCountWrapper text-center' +
            (count0SubText !== '' ? ' flex-column' : ' flex-row')
          }
        >
          <h6 className='d-block m-auto'>{count0Text}</h6>
          {count0SubText !== '' ? <p className='mb-1 d-block staticLink'>{count0SubText}</p> : null}
        </div>
      )}
    </>
  );
}

export default CardCount;
