import React, { HTMLProps } from 'react';
import styled from 'styled-components';

import { ActionColor, Colors } from '../../../../shared/styles';
import { StyledButton } from './Button';

/**
 * Compact styled Button with a default white bg
 * This size and styling appears inside views
 * for extra functionality, e.g. to open popup modals.
 */
interface StyledActionButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  onClick?: any;
  title?: string;
}
const StyledActionButton = styled(StyledButton)<StyledActionButtonProps>`
  background-color: ${Colors.white};
  border: ${ActionColor.secondary} solid 0.0625rem;
  color: ${ActionColor.secondary};
  padding: 0.35rem 1.5rem;
  height: 2.5rem;
  font-weight: 700;
  &:hover {
    background-color: ${ActionColor.secondaryHighlight};
  }
  &:active {
    background-color: ${ActionColor.secondaryHighlight};
  }
`;

export const ActionButton: React.FunctionComponent<StyledActionButtonProps> = props => (
  <StyledActionButton {...props}>{props.children}</StyledActionButton>
);
