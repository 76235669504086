import React, { memo, useEffect, useState, useCallback } from 'react';
import { Table } from 'reactstrap';
import { BidExternalStatusType, pageSize, sortDirections } from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import {
  deprecatedGetDate,
  deprecatedGetDateTimeZoneConvertedDate,
} from '../../../../../utils/helpers';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import SortingIcon from '../../../../common/sortingIcon';
import { Buttons, Paging } from '../../../../customcontrols';
import { NoResult } from '../../../../customcontrols/index';
import AddSupplier from '../../addsupplier';
import DocumentList from './documents';
import Similarsuppliers from './similarsuppliers';
import Supplierdetails from './supplierdetails';
import { authTypes } from '../../../../../types/auth';
import { bidItemParamType, bidssummaryParamType, Planholder } from '../../../../../types/biddetail';
import { sharedTypes } from '../../../../../types/shared';
import { useAmplitude } from '../../../../amplitude';

interface PropsTypes {
  bidsPlanHolders?: any;
  setBidSummaryDetails?: any;
  getPlanHoldersDetails?: any;
  getPlanholderOtherDetails?: any;
  loadBidPlanholderDocuments?: any;
  setSelfDeclarationDetail?: any;
  getSelfDeclarations?: any;
  setSelectedBidsSummaryDetails?: any;
  auth?: authTypes;
  bidssummary?: bidssummaryParamType;
  loadBidsSimilarSuppliers?: any;
  setSharedDetails?: any;
  searchPlanholder?: Array<Record<string, unknown>>;
  addSupplierActive?: string;
  exportPlanHoldersDetails?: any;
  match?: any;
  shared: sharedTypes;
  pagefor?: string;
}

function Planholders(props: PropsTypes) {
  const {
    bidsPlanHolders,
    setBidSummaryDetails,
    getPlanHoldersDetails,
    getPlanholderOtherDetails,
    loadBidPlanholderDocuments,
    setSelfDeclarationDetail,
    getSelfDeclarations,
    setSelectedBidsSummaryDetails,
    auth,
    bidssummary,
    loadBidsSimilarSuppliers,
    setSharedDetails,
    searchPlanholder = [],
    addSupplierActive = '',
    exportPlanHoldersDetails,
    pagefor = 'agency',
  } = props;

  const bidId = parseInt(props?.match?.params?.bidId);
  const { mi: memberId = 0 } = auth || {};
  const {
    results,
    selectedSuppliers = [],
    planHolders = { result: [] },
    currentsupplier,
    addSupplierModalOpen = false,
    modalType = 'add',
    planholders_internalLoader,
    trackTitle = '',
    planholdersdetail = [],
  } = bidssummary || {};
  const {
    aph: canAddPlanHolder = false,
    bidExternalStatusType = BidExternalStatusType.None,
    memberID: bidMemberId = '',
    tzfn,
    bidName,
    bidIdentifier,
  } = (results as bidItemParamType) || {};
  const { result } = planHolders || {};
  const suppliers_list = result || [];

  const { logEvent } = useAmplitude();

  const bidInfo = {
    bidId,
    memberId,
    bidMemberId,
    bidExternalStatusType,
    canAddPlanHolder,
  };
  useEffect(() => {
    if (bidId && suppliers_list.length === 0) {
      setSharedDetails({ internalLoader: true });
      bidsPlanHolders({ bidId, pagefor });
    }
  }, [
    bidId,
    bidsPlanHolders,
    loadBidsSimilarSuppliers,
    setSharedDetails,
    suppliers_list.length,
    pagefor,
  ]);

  const logPlanholderEvent = useCallback(
    (trackTitle: string, supplierInfo: Planholder) => {
      const eventProperties = {
        'Bid Name': `${bidName} ${bidIdentifier}`,
        'Planholder name': supplierInfo ? supplierInfo.supplierName : '',
        'Planholder member ID': supplierInfo ? supplierInfo.mi : '',
      };
      logEvent(trackTitle, eventProperties);
    },
    [bidName, bidIdentifier, logEvent],
  );

  useEffect(() => {
    if (currentsupplier && trackTitle) {
      logPlanholderEvent(trackTitle, currentsupplier);
      setBidSummaryDetails({ trackTitle: '' });
    }
  }, [trackTitle, currentsupplier, logPlanholderEvent, setBidSummaryDetails]);

  const Permission = ![
    BidExternalStatusType.Awarded,
    BidExternalStatusType.UnderEvaluation,
  ].includes(bidExternalStatusType);

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = Permission ? pageSize.planholders : 15;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const handleClick = () => {
    setBidSummaryDetails({ addSupplierModalOpen: !addSupplierModalOpen, modalType: 'add' });
  };

  const handleExportClick = (e: any) => {
    e.preventDefault();
    exportPlanHoldersDetails({ bidId });
  };

  // sort
  const [sortvalue, setSortvalue] = useState('supplierName');
  const [sortorder] = useState(sortDirections.ASC);

  return (
    <>
      <div className='row' data-testid='biddetails.planholders.tab.div'>
        <div className='col-12' data-testid='biddetails.planholders.tab.head.buttons'>
          <h3 className='mb-3 arrowWrapper' data-testid='biddetails.planholders.tab.head'>
            Planholders
          </h3>
          {suppliers_list && suppliers_list.length > 0 && bidMemberId === memberId && (
            <Buttons
              text='Export To Excel'
              title='Export To Excel'
              onClick={handleExportClick}
              classNames='bttn bttn-secondary small float-right ml-3'
            />
          )}
          {bidMemberId === memberId ? (
            <Buttons
              text='Add Supplier'
              title='Add Supplier'
              onClick={handleClick}
              classNames='bttn bttn-secondary small float-right'
            />
          ) : null}
        </div>
        {currentsupplier && suppliers_list && suppliers_list.length > 0 ? (
          <>
            <div className={`col-12 ${pagefor === 'supplier' ? 'col-lg-5' : 'col-lg-6'} pr-0`}>
              <div className='ulListWrapper'>
                <h4 data-testid='biddetails.planholders.tab.suppliers.heading'>
                  Supplier ({suppliers_list.length})
                </h4>
                <Table className='tableHData'>
                  <thead>
                    <tr data-testid='biddetails.planholders.tab.suppliers.head'>
                      <th>
                        Supplier
                        <SortingIcon
                          sortlist={suppliers_list}
                          initialsortorder={sortorder}
                          currentsortname={sortvalue}
                          changesortname={(value: string) => setSortvalue(value)}
                          sortname='supplierName'
                          changeSortingFn={(list: any) =>
                            setBidSummaryDetails({ planHolders: { ...planHolders, result: list } })
                          }
                        />
                      </th>
                      {pagefor !== 'supplier' ? <th>Download Date</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {suppliers_list
                      .map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            if (item.mi !== currentsupplier.mi) {
                              const selectedSupplier = planholdersdetail.find(
                                fitem => fitem && fitem.memberId === item.mi,
                              );
                              setSelectedBidsSummaryDetails({ ...item, ...selectedSupplier });
                              if (
                                pagefor !== 'supplier' &&
                                selectedSupplier &&
                                !selectedSupplier.planholderDocuments
                              )
                                getPlanholderOtherDetails({ memberId: item.mi, bidId });
                              if (pagefor === 'supplier') {
                                logPlanholderEvent(
                                  'planholders (supplier) - click Company Contact Info',
                                  item,
                                );
                              }
                            }
                          }}
                          className={`${
                            item.mi === currentsupplier.mi ? 'active' : ''
                          } cursorPointer`}
                          data-testid='biddetails.planholders.tab.suppliers.body'
                        >
                          <td>{item.supplierName}</td>
                          {pagefor !== 'supplier' ? (
                            <td>{deprecatedGetDateTimeZoneConvertedDate(item.sentDate, tzfn)}</td>
                          ) : null}
                        </tr>
                      ))
                      .slice(indexOfFirstList, indexOfLastList)}
                  </tbody>
                </Table>
                <Paging
                  totalRecords={suppliers_list.length}
                  currentPage={currentPage}
                  pagefor='planholders-supplier'
                  onPagingClick={(value: number) => {
                    if (value !== currentPage) setCurrentPage(Number(value));
                  }}
                  pageLimit={listPerPage}
                />
                {bidMemberId === memberId && (
                  <Buttons
                    text='Add Supplier'
                    title='Add Supplier'
                    onClick={handleClick}
                    classNames='bttn bttn-secondary mt-3 float-right'
                  />
                )}
              </div>
            </div>
            <div
              className={`col-12 ${
                pagefor === 'supplier' ? 'col-lg-7' : 'col-lg-6'
              } responsecontent`}
            >
              <div
                className={'leftBorder clearfix' + (pagefor === 'supplier' ? ' setFullHeight' : '')}
              >
                <Supplierdetails currentData={currentsupplier} {...props} bidInfo={bidInfo} />
                {pagefor !== 'supplier' ? (
                  <DocumentList
                    {...props}
                    planholderDocuments={currentsupplier.planholderDocuments}
                  />
                ) : null}
              </div>
              {Permission && bidMemberId === memberId && (
                <div className='pl-4 clearfix'>
                  <Similarsuppliers {...props} bidId={bidId} pagfor='similarPlanholder' />
                </div>
              )}
            </div>
          </>
        ) : (
          <NoResult
            message={
              planholders_internalLoader || (!currentsupplier && suppliers_list.length > 0)
                ? loadingMsg
                : 'No Planholders Available'
            }
          />
        )}
      </div>
      <ModalPopUp
        title={modalType === 'edit' ? 'Edit Supplier' : 'Add Supplier'}
        size={searchPlanholder.length > 0 && addSupplierActive === 'search' ? 'xl' : 'lg'}
        isOpen={addSupplierModalOpen}
        closeModal={handleClick}
      >
        <AddSupplier {...props} bidId={bidId} />
      </ModalPopUp>
    </>
  );
}

export default memo(Planholders);
