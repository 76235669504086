import React, { memo, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';
import Buttons from '../../customcontrols/button';
import OrderSummery from '../ordersummery';
import Cart from '../cart/Cart';
import CartTotal from '../cart/CartTotal';
import PaymentMethod from './paymentmethod';
import CompanyInfo from './companyinfo';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import ConfirmSubscription from '../confirmsubscription';
import Braintree from '../../common/braintree';
import { appKeys, registrationComponent } from '../../../utils/constants';
import CompanyAddressForm from '../../common/companyaddressform';
import { toastFn } from '../../../utils/helpers';
import { trackInAmplitude } from '../../../utils/telemetry';
import { tollFreeNumber } from '../../../utils/texts';

const ReviewOrder = (props: any) => {
  const {
    subscriptions,
    trackAmplitudeUserActions,
    getCountiesList,
    registration,
    shared,
    setPaymentFormDetails,
    processPayment,
    setSubscriptionDetails,
    setRegistrationData,
    auth,
    accountinfo,
    pagefor = '',
  } = props;
  const { countrieslist = [], amplitudeData = {} } = shared;
  const {
    complimentary = false,
    salesNotes = '',
    addressInitialForm = {},
    token = '',
    paymentErrorModal = false,
    subscribedStates = [],
    subscribedCounties = [],
    subscribedNational = [],
    allProducts = [],
    memberSubscriptionInfo = '',
    calculatedPrice = 0,
  } = subscriptions;
  const { opi = 0 } = auth || {};
  const { accountinfo: accountInfo, profileInfo = {} } = accountinfo || {};
  const { card = {} } = profileInfo;
  const nationalProduct = allProducts.find((item: any) => item.productType === 'NA') || {};
  let selectedAllProducts = [];
  selectedAllProducts = allProducts
    .filter(
      (item: any) =>
        subscribedStates.includes(item.productId) ||
        subscribedCounties.includes(item.productId) ||
        subscribedNational.includes(item.productId),
    )
    .map((mItem: any) => mItem.price);
  const totalCartPrice =
    selectedAllProducts.length > 0
      ? selectedAllProducts.length === 1
        ? selectedAllProducts[0]
        : selectedAllProducts.reduce((a: number, b: number) => a + b)
      : 0;

  const [showPlaceOdr, setShowPlaceOdr] = useState(false);
  const [showOdrSummery, setShowOdrSummery] = useState(false);
  const [paymentCardModal, setPaymentCardModal] = useState(false);
  const [isCheckClicked, setIscheckClicked] = useState(false);
  const header = `${appKeys.headers.paymentMethod} - Subscription - ${appKeys.name}`;
  const [editCompanyInfo, setEditCompanyInfo] = useState(false);
  const [addressTitle, setAddressTitle] = useState('Company Address');
  const [addressInitialValues, setAddressInitialValues] = useState({});

  let memberAddress = '' as any;
  let billingAddress = '' as any;
  let registrationInfo = {} as any;

  const pageFrom: string = window.location.search.replace('?upsell=', '');

  if (pagefor === 'review') {
    const { memberInfo = [] } = accountInfo || {};
    memberAddress = memberInfo.find((item: any) => item.addressType === 'MA');
    billingAddress = memberInfo.find((item: any) => item.addressType === 'BA');
    if (!memberAddress && billingAddress) {
      memberAddress = { ...billingAddress, addressType: 'MA' };
    }
    if (!billingAddress && memberAddress) {
      billingAddress = { ...memberAddress, addressType: 'BA' };
    }
  } else {
    const { registrationData = {} } = registration;
    registrationInfo = { ...registrationData };
    const { companyAddress = {}, billingAddress: billAddress = {} } = registrationData;
    memberAddress = {
      ...companyAddress,
      addressType: 'MA',
      abbreviation: companyAddress.stateName,
    };
    const Address = billAddress.city ? billAddress : companyAddress;
    billingAddress = { ...Address, addressType: 'BA', abbreviation: Address.stateName };
  }

  useEffect(() => {
    if (calculatedPrice === 0) {
      setSubscriptionDetails({ calculatedPrice: totalCartPrice });
    }
  }, [calculatedPrice, totalCartPrice, setSubscriptionDetails]);

  const placeOdrHandle = () => {
    setShowPlaceOdr(!showPlaceOdr);
  };

  const orderSmmeryHandle = () => {
    setShowPlaceOdr(false);
    setShowOdrSummery(!showOdrSummery);
  };

  const makePayment = () => {
    if (complimentary && !salesNotes) {
      toastFn('error', 'Please enter a Sales Notes');
    } else {
      placeOdrHandle();
      if (pageFrom && amplitudeData && amplitudeData.title) {
        trackAmplitudeUserActions({
          title: 'upgrade - place order',
          desc: 'Complete checkout',
          data: amplitudeData.data,
        });
      }
      processPayment({ token, salesNotes, pagefor });
    }
  };

  const updatesubscriptions = (complimentary: boolean) => {
    setSubscriptionDetails({ complimentary });
    if ((card && card.cardToken) || token || complimentary) {
      setIscheckClicked(false);
      placeOdrHandle();
      //processPayment({token:''})
    } else {
      setIscheckClicked(true);
      handlePaymentClicked();
    }
  };

  const handlePaymentClicked = () => {
    setPaymentCardModal(!paymentCardModal);
  };

  const onCardSubmit = (token: any) => {
    handlePaymentClicked();
    setSubscriptionDetails({ token: token.nonce });
    setPaymentFormDetails({ card_token: token });
    if (isCheckClicked) {
      placeOdrHandle();
    }
    if (pagefor) {
      trackInAmplitude('RegistrationAddPayment', registrationInfo);
    } else if (pageFrom && amplitudeData && amplitudeData.title) {
      trackAmplitudeUserActions({
        title: 'upgrade - add payment method',
        desc: 'Add a payment method',
        data: amplitudeData.data,
      });
    }
  };

  const companyInfoPopupHandle = (title = '') => {
    if (title) {
      setAddressTitle(title === 'MA' ? 'Company Information' : 'Billing Information');
      const initialValue = title === 'MA' ? { ...memberAddress } : { ...billingAddress };
      getCountiesList(initialValue.stateId);
      if (pagefor === 'review') {
        const countryData = countrieslist.find(
          (item: any) => item.value === initialValue.countryId,
        );
        initialValue.stateName = { label: initialValue.stateName, value: initialValue.stateId };
        initialValue.country = { label: countryData.title, value: countryData.value };
        initialValue.countyName = { label: initialValue.countyName, value: initialValue.countyId };
      }
      setSubscriptionDetails({ addressInitialForm: initialValue });
      setAddressInitialValues(initialValue);
    }

    setEditCompanyInfo(!editCompanyInfo);
  };

  const isRenewalAvail =
    memberSubscriptionInfo.isRenewalAllowed &&
    memberSubscriptionInfo.memberDetails.daysToRenew > 0 &&
    memberSubscriptionInfo.memberDetails.daysToRenew < 90;

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='colWrapper'>
              <h3 className='d-flex align-items-center'>
                <label className='flex1 mr-3 mb-0'>
                  <span className='arrowWrapper'>Review Order</span>
                </label>
                {/* <span className='bttn-secondary small subReviewBackBtn' onClick={ () => setSubscriptionDetails({currentStep:1}) }>Back</span> */}
              </h3>
              <div className='row mb-5'>
                <div className='col-md-12 col-lg-12'>
                  <h4>Payment Method</h4>
                  <div className='w-100'>
                    <PaymentMethod
                      {...props}
                      handleChangeClicked={() => {
                        setIscheckClicked(false);
                        handlePaymentClicked();
                      }}
                      alignChangeBtn='d-flex mb-5'
                    />
                  </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                  <CompanyInfo
                    title='Company Info'
                    type='MA'
                    addressData={memberAddress}
                    onClick={companyInfoPopupHandle}
                  />
                </div>
                <div className='col-md-12 col-lg-6'>
                  <CompanyInfo
                    type='BA'
                    title='Billing Address'
                    addressData={billingAddress}
                    onClick={companyInfoPopupHandle}
                  />
                  {/* <BillingAddress {...props} payment={payment} memberinfo={memberInfo} /> */}
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h4 className='lineHR'>Your Subscriptions</h4>
                  <div className='px-3'>
                    <Cart isReview={true} isRenewal={isRenewalAvail} showDelIcon={false} />
                    <hr className='lineHR' />
                    <CartTotal
                      disabled={props.disabled}
                      isRenewal={props.isRenewal}
                      isReview={true}
                      totalCartItems={selectedAllProducts.length}
                      totalCartPrice={
                        subscribedNational.length > 0
                          ? nationalProduct
                            ? nationalProduct.price
                            : 2000
                          : totalCartPrice
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='sideBarSpacing'>
              <Buttons
                type='button'
                classNames='bttn-primary w-100 mb-4'
                text='Place Order'
                onClick={() => updatesubscriptions(false)}
              />
              {opi ? (
                <Buttons
                  type='button'
                  classNames='bttn-primary w-100 mb-4'
                  text='Place Complementary Order'
                  onClick={() => updatesubscriptions(true)}
                />
              ) : null}
              <Buttons
                type='button'
                classNames='bttn-secondary w-100 mb-4'
                text='Back'
                onClick={() => {
                  if (pagefor === 'review') {
                    setSubscriptionDetails({ currentStep: 1 });
                  } else {
                    setRegistrationData({
                      currentComponent: registrationComponent.ChooseSubscription,
                    });
                  }
                }}
              />
              <p>
                By placing your order, you agree to DemandStar.com's{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://network.demandstar.com/privacy-policy/'
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://network.demandstar.com/terms-of-use/'
                >
                  Terms of Use
                </a>
                .
              </p>
              <OrderSummery {...props} />
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        title='Confirm Subscription'
        size='lg'
        isOpen={showPlaceOdr}
        closeModal={placeOdrHandle}
      >
        <ConfirmSubscription
          {...props}
          orderContinue={makePayment}
          cancelPayment={placeOdrHandle}
        />
      </ModalPopUp>
      <ModalPopUp
        title='Payment Error'
        size='lg'
        isOpen={paymentErrorModal}
        closeModal={() => setSubscriptionDetails({ paymentErrorModal: !paymentErrorModal })}
      >
        <p>
          Something went wrong and your subscription payment did not process. Please check that your
          credit card information is entered correctly and your billing address is correct.
        </p>
        <p></p>
        <p>
          If your payment still does not process, please contact DemandStar at
          support@demandstar.com or {`${tollFreeNumber}`}
        </p>
      </ModalPopUp>

      <ModalPopUp
        title={'Payment Method'}
        size='md'
        closeModal={handlePaymentClicked}
        isOpen={paymentCardModal}
      >
        <Braintree
          onSubmitPayment={onCardSubmit}
          cancelClick={handlePaymentClicked}
          buttonname={'Accept Changes'}
          title={header}
        />
      </ModalPopUp>

      <ModalPopUp
        title={addressTitle}
        size='md'
        isOpen={editCompanyInfo}
        closeModal={() => companyInfoPopupHandle()}
      >
        <CompanyAddressForm
          pagefor='reviewRegister'
          initialValues={addressInitialForm}
          companyInfoPopupHandle={companyInfoPopupHandle}
          submitBtn={true}
        />
      </ModalPopUp>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    auth: state.auth,
    shared: state.shared,
    subscriptions: state.subscriptions,
    memberinfo: state.memberinfo,
    accountinfo: state.accountinfo,
    payment: state.payment,
    registration: state.registration,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(ReviewOrder));
