import React from 'react';

import {
  BoldText,
  PanelItemWrapper,
  PanelLabel,
  PanelRow,
  PanelRowValue,
  SpanSpacerRight,
} from '../../../shared/styles';
import {
  requiredDocLabels,
  vendorDocLabels,
} from '../../../components/buyer/awardbid/add-vendor-docs/constants';

import { RequiredAction } from '../../../types/required-action';
import { requiredActionLabels } from '../../../shared/constants';

export type RequiredActionDetailsPanelViewProps = { requiredAction?: RequiredAction };

export function RequiredActionDetailsPanelView(props: RequiredActionDetailsPanelViewProps) {
  const { requiredAction } = props;

  if (!requiredAction) {
    return <PanelLabel>{requiredActionLabels.selectAnAction}</PanelLabel>;
  }

  const { agencyName, component, label, status, type } = requiredAction;

  const labelDisplay =
    type === 'requiredDocument'
      ? status !== 'Complete'
        ? requiredDocLabels.requiredActionMessageUpload
        : requiredDocLabels.requiredActionMessageUploadConplete
      : vendorDocLabels.downloadProvidedDocuments;

  const actionInstructions =
    type === 'requiredDocument' ? (
      <span>
        <SpanSpacerRight>
          {agencyName} {requiredDocLabels.messageUploadSuffix}
        </SpanSpacerRight>
        <BoldText>{label}</BoldText>
      </span>
    ) : (
      <PanelRow paddingBottom={'1rem'}>
        {`${agencyName} ${vendorDocLabels.requiredActionMessageDownloadInstructionsSuffix}`}
      </PanelRow>
    );

  return (
    <>
      <PanelLabel>{labelDisplay}</PanelLabel>
      <PanelItemWrapper>
        {actionInstructions}
        <PanelRow>
          <PanelRowValue title={type}>{component}</PanelRowValue>
        </PanelRow>
      </PanelItemWrapper>
    </>
  );
}
