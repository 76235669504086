const SecondAsMillis = 1000;
const MinuteAsMillis = 60 * SecondAsMillis;
const HourAsMillis = 60 * MinuteAsMillis;
const DayAsMillis = 24 * HourAsMillis;
const WeekAsMillis = 7 * DayAsMillis;
const MonthAsMillis = 30 * DayAsMillis;

export enum DateTimeUnits {
  day = DayAsMillis,
  hour = HourAsMillis,
  minute = MinuteAsMillis,
  month = MonthAsMillis,
  second = SecondAsMillis,
  week = WeekAsMillis,
}
