import moment from 'moment-timezone';
import React from 'react';

import { Buttons, DeprecatedInput, TextArea } from '../customcontrols/index';
import { DeprecatedReduxDatepicker } from '../customcontrols/DeprecatedReduxDatepicker';
import { Label } from 'reactstrap';
import LogoDS from '../../assets/images/logo_ds';
import { usaDateFormat } from '../../utils/constants';

function AnonymousAgency() {
  return (
    <div className='anonymousAgency'>
      <header>
        <nav className='navbar navbar-expand-lg navbar-light'>
          <LogoDS />
        </nav>
      </header>
      <div className='container-fluid '>
        <div className='row d-flex justify-content-center'>
          <div className='col-6'>
            <h2 className='lineHR mt-5'>Agency Details</h2>
            <DeprecatedInput
              label='Agency Name'
              type='text'
              classNames='class'
              placeholder={'Agency Name' || ''}
              value={'' || ''}
              maxLength={100}
            />
            <DeprecatedInput
              label='Contact Person'
              type='text'
              classNames='class'
              placeholder={'Contact Person' || ''}
              value={'' || ''}
            />
            <DeprecatedInput
              label='Email'
              type='text'
              classNames='class'
              placeholder={'Email' || ''}
              value={'' || ''}
              maxLength={100}
            />
            <DeprecatedInput
              label='Address 1'
              type='text'
              classNames='class'
              placeholder={'Address 1' || ''}
              value={'' || ''}
              maxLength={50}
            />
            <DeprecatedInput
              label='Address 2'
              type='text'
              classNames='class'
              placeholder={'Address 2' || ''}
              value={'' || ''}
              maxLength={50}
            />
            <DeprecatedInput
              label='City'
              type='text'
              classNames='class'
              placeholder={'City' || ''}
              value={'' || ''}
              maxLength={50}
            />

            <DeprecatedInput
              label='Zip'
              type='text'
              classNames='class'
              placeholder={'Zip' || ''}
              value={'' || ''}
              maxLength={30}
            />
            <DeprecatedInput
              label='Phone'
              type='text'
              classNames='class'
              placeholder={'Phone' || ''}
              value={'' || ''}
              maxLength={17}
            />
            <h2 className='lineHR mt-5'>Bids Details</h2>
            <DeprecatedInput
              label='Bid Name'
              type='text'
              classNames='class'
              placeholder={'Bid Name' || ''}
              value={'' || ''}
              maxLength={150}
            />
            <DeprecatedReduxDatepicker
              label='Due Date'
              name='expires'
              isOutsideRange={true}
              // handleChange={onInputChange}
              // value={programFilters.expires}
              optional={`(e.g. "${moment().format(usaDateFormat)}")`}
            />
            <TextArea
              label='Scope Of Work'
              classNames='class'
              handleChange={() => {}}
              placeholder={'Scope Of Work' || ''}
              value={'' || ''}
            />

            <Label>Attachments</Label>
            <input type='file' />
            <div className='d-block my-4'>
              <Buttons
                classNames='bttn-primary mb-3 float-right'
                text='Save'
                title='Save'
                type='button'
                // onClick={onSearchClick}
              />
              <Buttons
                classNames='bttn-secondary float-left'
                text='Cancel'
                title='Cancel'
                type='button'
                // onClick={onClearFilterClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnonymousAgency;
