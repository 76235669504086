import React from 'react';

import { BidSummaryLabels, navigationPaths } from '../../../../shared/constants';

import { EditButton } from '../../../../components/common/controls/buttons/EditButton';
import { useBidResponseStatus } from '../../../../shared/hooks/useBidResponseStatus';
import { useRouter } from '../../../../shared/hooks';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';

/**
 * Show an "Edit" button to navigate to the build details/edit view,
 * unless no bid has been selected
 * or the current bid has a `closed` response status.
 * @returns JSX
 *
 */
export function EditBidResponseButton() {
  // Hooks
  const { navigate } = useRouter();
  const { selectedBidId } = useSelectedBidId();
  const { bidResponseStatus } = useBidResponseStatus();

  function navigateToResponseEditView() {
    navigate(`${navigationPaths.suppliers.bids}/${selectedBidId}/response`);
  }

  return selectedBidId > 0 && bidResponseStatus !== 'closed' ? (
    <EditButton onClick={navigateToResponseEditView}>{BidSummaryLabels.edit}</EditButton>
  ) : null;
}
