import React from 'react';
import { Buttons } from '../../customcontrols';

interface propsTypes {
  countyAgencies: any;
  showAgencyModal?: any;
}

const AgencyList = (props: propsTypes) => {
  const { countyAgencies = [], showAgencyModal } = props;

  return (
    <>
      <div className='prodAgencyList clearfix'>
        <ul className='list-unstyled'>
          {countyAgencies.map((item: any, index: number) => (
            <li key={index}>{item.productName}</li>
          ))}
        </ul>
      </div>
      <div className='d-block mt-4'>
        <Buttons
          text='Close'
          classNames='bttn-primary float-right'
          onClick={() => {
            showAgencyModal();
          }}
        />
      </div>
    </>
  );
};

export default AgencyList;
