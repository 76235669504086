import React, { memo, useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { pageSize } from '../../../../utils/constants';
import { loadingMsg } from '../../../../utils/texts';
import NoResult from '../../../customcontrols/noresult';
import { Paging } from '../../../customcontrols';
import { limitedbiddetailsTypes } from '../../../../types/limited';

interface propsTypes {
  limited: limitedbiddetailsTypes;
  getLimitedAgencyPlanholders: any;
  bidId: string | number;
}

const LimitedBidsPlanholders = (props: propsTypes) => {
  const { limited, getLimitedAgencyPlanholders, bidId } = props;
  const { bidsplanholders, planholders_internalloader } = limited;
  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  useEffect(() => {
    if (bidId && bidsplanholders.length === 0) getLimitedAgencyPlanholders({ bidId });
  }, [bidId, getLimitedAgencyPlanholders, bidsplanholders.length]);

  return (
    <>
      <Table className='tableHData'>
        <thead>
          <tr data-testid='limited.bids.planholders.header'>
            <th>Name</th>
            <th>City</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {bidsplanholders.length > 0 ? (
            <>
              {bidsplanholders
                .map((data: { supplierName: string; city: string; state: string }, i: number) => (
                  <tr key={i} data-testid='limited.bids.planholders.list'>
                    <td>{data.supplierName}</td>
                    <td>{data.city}</td>
                    <td>{data.state}</td>
                  </tr>
                ))
                .slice(indexOfFirstList, indexOfLastList)}
            </>
          ) : (
            <tr>
              <td colSpan={6}>
                <NoResult
                  message={planholders_internalloader ? loadingMsg : 'No Planholders Available'}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Paging
        totalRecords={bidsplanholders.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
};

export default memo(LimitedBidsPlanholders);
