import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Record<string, unknown>, State> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    if (!sessionStorage.getItem('isloggingout')) this.setState({ hasError: true });
  }

  RedirectPage = () => {
    window.location = '/' as any;
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className='splashWrapper'>
          <Container className='errorTitle text-center'>
            <Row>
              <Col xs={12} md={12}>
                <h1>Error</h1>
                <p>
                  There was an unexpected error. Please try again. If the issue persists contact
                  support.
                </p>
                <Button
                  title='Go Home'
                  variant='outline-light'
                  className='btn btn-outline-light'
                  onClick={this.RedirectPage}
                >
                  Go Home
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
