import { Field, Formik } from 'formik';
import React, { memo } from 'react';

import { emailVal, emailValErrorMsg } from '../../../utils/constants';
import { Buttons } from '../../customcontrols';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';

const email = (value: string) => (value && !emailVal.test(value) ? emailValErrorMsg : undefined);

export const phoneNumber = (value: string) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

interface PropsTypes {
  handleSubmit?: any;
  onSubmit?: any;
  valid?: boolean;
  submitting?: boolean;
  initialValues: Record<string, unknown>;
}

const SearchForm = (props: PropsTypes) => {
  const { onSubmit, initialValues } = props;

  const onSubmitFn = (data: any) => {
    if (data) {
      onSubmit(data);
    }
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmitFn}>
      {formikProps => {
        const {
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValidating,
          validateForm,
          isValid,
          dirty,
        } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Field
              name='name'
              type='text'
              placeholder='Supplier Name'
              component={renderDeprecatedTextField}
              onChange={setFieldValue}
              label='Supplier Name'
              classNames='class'
              maxLength={80}
            />
            <Field
              name='city'
              type='text'
              placeholder='City'
              component={renderDeprecatedTextField}
              onChange={setFieldValue}
              label='City'
              classNames='class'
              maxLength={50}
            />
            <Field
              name='phoneNumber'
              type='text'
              component={renderDeprecatedTextField}
              label='Phone Number'
              placeholder='Phone Number'
              classNames='class'
              onChange={setFieldValue}
              validate={phoneNumber}
              maxLength={17}
            />
            <Field
              name='email'
              placeholder='Email'
              type='text'
              component={renderDeprecatedTextField}
              onChange={setFieldValue}
              label='Email'
              classNames='class'
              validate={email}
              maxLength={100}
            />
            <div className='d-block mt-3'>
              <Buttons
                text='Search'
                title='Search'
                type='submit'
                classNames='bttn bttn-primary float-right'
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default memo(SearchForm);
