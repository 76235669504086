import React, { memo, useEffect } from 'react';

import { bidssummaryParamType, BidWriter } from '../../../../../../types/biddetail';
import { appKeys } from '../../../../../../utils/constants';
import { formattedPhoneNumber } from '../../../../../../utils/helpers';
import { loadingMsg } from '../../../../../../utils/texts';
import { NoResult } from '../../../../../customcontrols/index';
import { sharedParamType } from '../../../../../../types/commoditycodes';
import { Table } from 'reactstrap';

const tdWidth = {
  width: '120px',
};

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  shared?: sharedParamType;
  setBidSummaryDetails?: any;
  bidWriterInfo?: BidWriter;
}

const BidWriterContent = (props: PropsTypes) => {
  const { bidssummary, shared, setBidSummaryDetails } = props;
  const { writerInfo } = bidssummary || {};
  const { internalLoader } = shared || {};

  useEffect(() => {
    return () => {
      //setBidSummaryDetails({ writerInfo: '' });
    };
  }, [setBidSummaryDetails]);

  if (writerInfo) {
    const address2 = writerInfo.memberInfo.a2 ? ', ' + writerInfo.memberInfo.a2 : '';
    const address = `${writerInfo.memberInfo.a1} ${address2} ,  ${writerInfo.memberInfo.ct} , ${writerInfo.memberInfo.st} ${writerInfo.memberInfo.pc}`;

    const title = `${appKeys.headers.bidWriter} - ${
      props.bidWriterInfo && props.bidWriterInfo.bidName
    } - ${appKeys.name}`;
    const { pe = '', p = '' } = (writerInfo && writerInfo.writerInfo) || {};

    return (
      <>
        <title>{title}</title>
        <h5 className='mb-0'>Account Information</h5>
        <hr className='mb-3 mt-1' />

        {internalLoader ? (
          <NoResult message={loadingMsg} />
        ) : (
          <>
            <Table borderless className='tableData'>
              <tr>
                <th style={tdWidth}>Name</th>
                <td>
                  {writerInfo.writerInfo.fn +
                    (writerInfo.writerInfo.ln ? ' ' + writerInfo.writerInfo.ln : '')}
                  {/* {props.bidWriter} */}
                </td>
              </tr>
              <tr>
                <th>Job Title</th>
                <td>{writerInfo.writerInfo.jt}</td>
              </tr>
              <tr>
                <th>E-mail</th>
                <td>{writerInfo.writerInfo.eml}</td>
              </tr>
              {p && (
                <tr>
                  <th>Phone No</th>
                  <td>{formattedPhoneNumber(p, pe)}</td>
                </tr>
              )}
            </Table>

            <h5 className='mb-0 mt-5'>Agency Information</h5>
            <hr className='mb- mt-1' />
            <Table borderless className='tableData'>
              <tr>
                <th style={tdWidth}>Agency</th>
                <td>{writerInfo.memberInfo.mn}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{address}</td>
              </tr>
              {writerInfo && writerInfo.memberInfo && writerInfo.memberInfo.p && (
                <tr>
                  <th>Phone No</th>
                  <td>{formattedPhoneNumber(writerInfo.memberInfo.p, writerInfo.memberInfo.pe)}</td>
                </tr>
              )}
            </Table>
          </>
        )}
      </>
    );
  } else {
    return <>{internalLoader ? <NoResult message={loadingMsg} /> : null}</>;
  }
};

export default memo(BidWriterContent);
