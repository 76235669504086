export type authTypes = {
  tk: string;
  un: string;
  us: number;
  lkd: string;
  lm: string;
  prms: string;
  eml: string;
  fn: string;
  opi: number;
  ln: string;
  mbl: string;
  mi: number;
  ms: string;
  mt: string;
  pmid: string;
  ut: string;
  mc: boolean;
  her: string;
  ml: string;
  mgrtd: boolean;
  tm: string;
  fln: string;
  validatedResult: { status: boolean; firstName: string; lastName: string };
};

export type loginpayloadTypes = {
  userName: string;
  password?: string;
  pagefor?: string;
  orderBidPackage?: any;
  pageBid?: number;
};

export enum Permission {
  Ops = '1',
  EditMemberInfo = '2',
  ManageAccounts = '3',
  ManageBids = '4',
  CreateQuotes = '5',
  ManagePrograms = '6',
  AwardQuotes = '7',
  Fullfillment = '8',
  SalesPerson = '9',
  SalesManager = '10',
  ManageReports = '11',
  DeDuplicateMembers = '12',
  GiveCredit = '13',
  RespondToBids = '14',
  RespondToQuotes = '15',
}

export enum MembershipLevel {
  BidPoster = '1',
  BidResponder = '2',
  QuotePoster = '3',
  QuoteResponder = '4',
  EBiddingResponder = '5',
}
