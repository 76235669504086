import React, { useEffect, useState, memo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/actions';
import ChooseSubscription from './choosesubscription';
import ReviewOrder from './revieworder';
import OrderConfirmation from './orderconfirmation';
import PrintReceipt from './printreceipt';
import { PrepopulatedCounties } from '../../types/subscriptions';
import { prepopulatedCountiesState } from '../../store/recoil/subscriptionState';
import { useRecoilState } from 'recoil';

/**
 * @description Prepopulated Counties is a an upsell tool where an interstitial will offer to add at least one county for the current price.
 * If the supplier chooses to upgrade their account, they will go to the Choose Subscriptions page where the the county/counties will be selected and in their cart.
 * This feature is limited to one state, since the `selectedState` on the Choose Subscriptions page is type number, but can accommodate as many counties (number[])
 * as that state has. There are some inconsistencies on the Choose Subscriptions page because of the current disconnect in the Redux state.
 * @default {state:0, counties: []} (defined in the files)
 * @type the PrepopulatedCounties type is an object with two members: {'state': number, and a 'counties': number[]}
 * @example prepopulatedCounties set using useRecoilState hook.
 * ```
 * const [prepopulatedCounties, setPrepopulatedCounties] = useRecoilState<PrepopulatedCounties>(
 *   prepopulatedCountiesState,
 * );
 *
 * <ChooseSubscription pagefor={pagefor} prepopulatedCounties={prepopulatedCounties} />
 *  ```
 */
const Subscription = (props: any) => {
  const {
    subscriptions,
    setSubscriptionDetails,
    getAccountInfo,
    getPaymentCardDetails,
    getProfileInfo,
    pagefor = '',
  } = props;
  const { currentStep = 1 } = subscriptions;

  const [prepopulatedCounties, setPrepopulatedCounties] = useRecoilState<PrepopulatedCounties>(
    prepopulatedCountiesState,
  );

  useEffect(() => {
    return () => {
      setSubscriptionDetails({
        subscribedStates: [],
        subscrbiedCountieds: [],
        subscribedNational: [],
        currentSubscribedStates: [],
        currentSubscribedCounties: [],
        currentSubscribedNational: [],
        currentStep: 1,
        calculatedPrice: 0,
        transaction: {},
        OrderNumber: '',
        memberSubscriptionInfo: '',
        selectedState: 0,
        token: '',
      });
    };
  }, [setSubscriptionDetails]);

  useEffect(() => {
    if (!pagefor) getPaymentCardDetails();
  }, [getPaymentCardDetails, pagefor]);

  useEffect(() => {
    if (!pagefor) getProfileInfo();
  }, [getProfileInfo, pagefor]);

  return (
    <>
      {currentStep === 1 ? (
        <ChooseSubscription pagefor={pagefor} />
      ) : currentStep === 2 ? (
        <ReviewOrder pagefor='review' />
      ) : currentStep === 3 ? (
        <OrderConfirmation pagefor='review' />
      ) : currentStep === 4 ? (
        <PrintReceipt />
      ) : null}
    </>
  );
};

const connector = connect(
  (state: any) => ({
    auth: state.auth,
    shared: state.shared,
    subscriptions: state.subscriptions,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Subscription));
