import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import { FieldContainer } from '../field-container/FieldContainer';
import { FieldTextProps } from '../field-text/FieldText';
import { InputTextStyled } from './InputTextStyles';

export type CommonInputTextProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'autoFocus' | 'placeholder'
> & {
  dataTestId?: string;
  ref?: ForwardedRef<HTMLInputElement>;
  label?: string;
  message?: string;
  name: string;
};

type SimpleInputTextProps = CommonInputTextProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'maxLength'> & {
    error?: never;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    value: string;
  };

export type InputTextProps = FieldTextProps | SimpleInputTextProps;

/** can be used with or without react-hook-form
 * Recommended: use FieldText instead if using react-hook-form
 * @prop {string}                    name - HTML name (must be unique)
 * @prop {string}                    value - HTML value
 * @prop {(value: string) => void}   onChange - function that triggers on change
 * @prop {string=}                   label - HTML label. Optional. Will also add additional margin at bottom.
 * @prop {string=}                   message - additional description
 * @prop {(value: string) => void=}  onBlur - function that triggers on blur
 * @prop {string=}                   placeholder - HTML placeholder
 * @example <InputText
              name='docTitle'
              value={doc.doctitle}
              onChange={(value: string) => updatePendingDocTitle(doc.id, value)}
              maxLength={100}
            ></InputText>
 * */
export const InputText = forwardRef(
  (props: InputTextProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      dataTestId,
      error,
      label,
      maxLength,
      message,
      name,
      onBlur,
      onChange,
      placeholder,
      value,
    } = props;

    // useAssert(!error?.ref || error.ref === ref, 'InputText references should match.');

    function change(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        const value = event.target.value;
        onChange(value.trimStart());
      }
    }

    function blur(event: React.FocusEvent<HTMLInputElement>) {
      if (onBlur) {
        const value = event.target.value;
        onBlur(value.trim());
      }
    }

    return (
      <FieldContainer
        error={error}
        message={message}
        label={label}
        name={name}
        dataTestId={dataTestId}
      >
        <InputTextStyled
          type='text'
          placeholder={placeholder}
          name={name}
          id={name}
          ref={ref}
          data-testid={dataTestId}
          onChange={change}
          onBlur={blur}
          value={value}
          maxLength={maxLength}
        />
      </FieldContainer>
    );
  },
);
