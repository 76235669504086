import { createActionTypes } from './utils';

export const GET_PROGRAMS = createActionTypes('GET_PROGRAMS');
export const ADD_UPDATE_PROGRAM = createActionTypes('ADD_UPDATE_PROGRAM');
export const GET_SUPPLIERS = createActionTypes('GET_SUPPLIERS');
export const RESPOND_CERT_REQUEST = createActionTypes('RESPOND_CERT_REQUEST');
export const SET_PROGRAM_FILTERS = createActionTypes('SET_PROGRAM_FILTERS');
export const CLEAR_PROGRAM_FILTERS = createActionTypes('CLEAR_PROGRAM_FILTERS');
export const SET_PROGRAM_DETAILS = createActionTypes('SET_PROGRAM_DETAILS');
export const RESET_PROGRAMS = createActionTypes('RESET_PROGRAMS');
