import styled from 'styled-components';

import { BackgroundColor, BorderColor, DeprecatedColors, TextColor } from './colors';
import { FontFamily, ParagraphFontSize } from './typography';

import { InnerColumnNarrow, InnerColumnWide } from './layout';

import { Selected } from '../../types/shared';

export const LineWithHRule = styled.div`
  overflow: hidden;
  width: 100%;

  &:after {
    border-bottom: 0.125rem solid ${BorderColor.Base};
    content: '';
    display: inline-block;
    height: 100%;
    left: 0.3125rem;
    margin-right: -100%;
    position: relative;
    top: -0.1875rem;
    width: 100%;
  }
`;

export const PanelLabel = styled(LineWithHRule)`
  font-family: ${FontFamily.Header};
  font-size: 1.26563rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  padding-bottom: 1.75rem;
`;

export const PanelItemWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

export const PanelListItem = styled.div<Selected>`
  background-color: ${BackgroundColor.BaseWhite};
  border-style: inset;
  border: ${props =>
    props.selected
      ? `0.1875rem solid ${DeprecatedColors.GrayBgHighlight}`
      : `0.0625rem solid ${DeprecatedColors.GrayLight}`};
  ${props => (props.selected ? 'border-right: none;' : '')}
  border-radius: 0.125rem;
  color: ${TextColor.Action};
  cursor: pointer;
  margin-bottom: 0.5rem;
  padding: ${props => (props.selected ? '0.5rem 2.75rem 0.5rem 0.75rem' : '0.625rem 0.875rem')};
  width: ${props => (props.selected ? 'calc( 100% + 1.7rem )' : 'inherit')};
  &:hover {
    border-color: ${DeprecatedColors.BlueGreenLight};
  }
  &:active {
    border-color: ${DeprecatedColors.BlueGreenMedium};
  }
`;

export interface PanelRowProps {
  paddingBottom?: string;
  paddingHorizontal?: string;
  paddingTop?: string;
  paddingVertical?: string;
}

export const PanelRow = styled.div<PanelRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Base};
  justify-content: flex-start;
  padding: ${({ paddingVertical }) => paddingVertical || '0'};
  ${({ paddingHorizontal }) => paddingHorizontal || '2rem'};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop};` : '')};
  ${({ paddingBottom }) => (paddingBottom ? `padding-bottom: ${paddingBottom};` : '')};
`;

export const PanelRowRight = styled(PanelRow)`
  justify-content: flex-end;
`;

interface RowColumn {
  full?: boolean;
}

export const PanelRowHeader = styled.div<RowColumn>`
  /* color: ${props => (props.full ? TextColor.Base : 'inherit')}; */
  display: flex;
  flex-basis: ${props => (props.full ? '100%' : '25%')};
  flex-grow: ${props => (props.full ? 'inherit' : 1)};
  font-family: ${FontFamily.Header};
  font-style: normal;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1.5rem;
  min-width: 7rem;
  padding: 0 0.5rem 0.75rem 0;
  width: ${props => (props.full ? '100%' : '25%')};
`;

export const PanelRowValue = styled(PanelRowHeader)`
  flex-basis: ${props => (props.full ? '100%' : '75%')};
  flex-grow: ${props => (props.full ? 'inherit' : 2)};
  font-family: ${FontFamily.Base};
  font-style: normal;
  font-weight: 400;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  width: ${props => (props.full ? '100%' : '75%')};
`;

export const DueDateRowValue = styled(PanelRowValue)`
  color: ${TextColor.Error};
`;

export const DashboardLeftColumn = styled(InnerColumnNarrow)`
  padding-right: 1.5rem;
`;

// We need to place the list above the details to show
// the selected item's border overlay.
export const LeftColumnAbove = styled(DashboardLeftColumn)`
  z-index: 1;
`;

export const DashboardRightColumn = styled(InnerColumnWide)`
  padding-left: 1.5rem;
`;

export const LoadingTextWrapper = styled.div`
  background-color: ${BackgroundColor.LoadingText};
  color: inherit;
  padding: 0.6rem 3rem;
`;

export const NotFoundText = styled.span`
  padding-left: 0.5rem;
`;

export const SolicitationTitle = styled.div`
  color: ${TextColor.Base};
  font-family: ${FontFamily.Header};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
`;

export const ActionMessage = styled.div`
  color: ${TextColor.ActionMessage};
  font-family: ${FontFamily.Base};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0 0.25rem;
  &:hover {
    color: ${TextColor.Action};
  }
`;
