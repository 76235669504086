import React, { ForwardedRef, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import { CommonInputTextProps, InputText } from './../input-text/InputText';

export type FieldTextProps = CommonInputTextProps & {
  error?: FieldError;
  onChange?: never;
  onBlur?: never;
  value?: never;
  maxLength?: never;
};

/** FieldText - text field exclusively used with react-hook-form
 * @prop {string}                   name - HTML name (must be unique)
 * @prop {Ref}                      ref - react-hook-form register()
 * @prop {FieldError}               error - react-hook-form error {formState.errors["fooName"]}
 * @prop {string=}                  label - HTML label. Optional. Will also add additional margin at bottom.
 * @prop {string=}                  message - additional description
 * @prop {string=}                  placeholder - HTML placeholder
 * @example <FieldText
              name='supplierName'
              label='Supplier Name'
              ref={register({ required: true })}
              message={"This is the name of the business that won the contract."}
              error={errors.supplierName}
            />
 * */
export const FieldText = forwardRef(
  (props: FieldTextProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { label, message, name, placeholder, dataTestId, error } = props;

    return (
      <InputText
        data-testid={dataTestId}
        error={error}
        label={label}
        message={message}
        name={name}
        placeholder={placeholder}
        ref={ref}
      />
    );
  },
);
