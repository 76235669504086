import React, { memo } from 'react';
import { Buttons } from '../../../customcontrols';
import { commodityItemsParamType } from '../../../../types/commoditycodes';

interface PropsTypes {
  CancelConfirm?: any;
  SetSelectedCommodity?: any;
  localCommodity?: any;
  commoditieslist?: commodityItemsParamType[];
}

const ConfirmCommodity = (props: PropsTypes) => {
  const { CancelConfirm, SetSelectedCommodity, localCommodity, commoditieslist = [] } = props;

  const commodityCodeCount = commoditieslist.filter(
    item =>
      item.commodityCategory === localCommodity.category.commodityCategory &&
      item.commodityCode !== '00',
  ).length;

  return (
    <>
      <p>
        You have selected commodity group which contains <b>{commodityCodeCount}</b> commodity
        codes. Are you sure want to continue?
      </p>

      <Buttons
        classNames='bttn-secondary mt-2'
        text='Cancel'
        title='Cancel'
        type='button'
        onClick={CancelConfirm}
      />
      <Buttons
        classNames='bttn-primary  mt-2 float-right'
        text='Ok'
        title='Ok'
        type='button'
        onClick={() => {
          const { value, category, name } = localCommodity;
          if (value && category && name) {
            SetSelectedCommodity(value, category, name);
            CancelConfirm();
          }
        }}
      />
    </>
  );
};

export default memo(ConfirmCommodity);
