import React, { memo, useEffect, useState } from 'react';
import PageSettingsForm from '../../../common/pagesettingsform';
import { Buttons } from '../../../customcontrols';
import CopyBox from '../../../customcontrols/copybox';
import { Prompt } from 'react-router-dom';
import { agencyLeavingAlertMessage } from '../../../../utils/texts';
import { constants } from '../../../../utils/settings';
import { agencyRegistrationParamType } from '../../../../types/agencyregistration';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import Configurations from '../../../../settings';

interface PropsTypes {
  agencyregistration: agencyRegistrationParamType;
  getAgencySettings?: any;
  updateAgencyPageSettings?: any;
  setBreadcrumb?: any;
  setAgencyRegistrationDetails?: any;
  activeTab?: string;
  history: any;
}

function AgencyPageSettings(props: PropsTypes) {
  const {
    agencyregistration,
    getAgencySettings,
    updateAgencyPageSettings,
    setBreadcrumb,
    setAgencyRegistrationDetails,
    history,
  } = props;
  const { imageTypes, escapedagencyname = '', state = '', guid = '', pagehasValues = false } =
    agencyregistration || {};
  const { networkUrl } = constants;
  const [ConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState('' as any);

  useEffect(() => {
    setBreadcrumb({
      page: 'Account Info',
      title: 'Agency Page Settings',
      altname: 'Agency Page Settings',
      name: 'Agency Page Settings',
      menuactive: '',
      component: '',
    });
  }, [setBreadcrumb]);

  useEffect(() => {
    getAgencySettings();
  }, [getAgencySettings]);

  const update = (payload?: any) => {
    updateAgencyPageSettings(payload);
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!pagehasValues || ConfirmModalVisible) {
      return true;
    } else {
      setConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const discardConfirmationPopup = () => {
    if (nextLocation) {
      history.push(nextLocation.pathname);
    }
  };

  useEffect(() => {
    return () => {
      if (pagehasValues === true) {
        setAgencyRegistrationDetails({ pagehasValues: false });
      }
    };
  }, [pagehasValues, setAgencyRegistrationDetails]);

  return (
    <>
      <Prompt when={pagehasValues} message={handleBlockedNavigation} />
      <div className='row'>
        <div className='col-md-10'>
          <h3 className='arrowWrapper'>Agency Page Settings</h3>
          <div className='innerColIndent'>
            <CopyBox
              content={`${Configurations.REACT_APP_BASE_URL}/agencies/${state}/${escapedagencyname}/procurement-opportunities/${guid}/`}
            />
          </div>
          <PageSettingsForm pagefor='agencypagesettings' {...props} />
          <Buttons
            classNames='bttn-primary my-4 float-right'
            text='Save Changes'
            type='button'
            onClick={update}
            disable={imageTypes && imageTypes.filter(item => item.error).length > 0 ? true : false}
          />
        </div>
      </div>
      <ModalPopUp
        title='Save Confirmation'
        size='md'
        isOpen={ConfirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
      >
        <p>{agencyLeavingAlertMessage}</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Discard changes'
          title='Discard changes'
          type='button'
          onClick={discardConfirmationPopup}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Save and Continue'
          title='Save and Continue'
          type='button'
          onClick={() => {
            update({ redirectPath: nextLocation.pathname });
            setConfirmModalVisible(false);
          }}
        />
      </ModalPopUp>
    </>
  );
}

export default memo(AgencyPageSettings);
