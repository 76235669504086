import { atom } from 'recoil';

import { defaultNumericId } from '../../../shared/constants';
import { nowISO } from '../../../utils/helpers';
import { RequiredDocType } from '../../../shared/hooks/award-bid/useRequiredDocumentTypes';
import { RequiredDocumentResponseGetAPIResponse } from '../../../types/required-document';

export const requiredDocumentResponsesState = atom<RequiredDocumentResponseGetAPIResponse[]>({
  key: 'requiredDocumentResponsesState',
  default: [],
});

export const requiredDocTypesState = atom<RequiredDocType[]>({
  key: 'requiredDocTypesState',
  default: [],
});

const defaultRequiredDocument: RequiredDocumentResponseGetAPIResponse = {
  awardedToMemberId: defaultNumericId,
  bidAwardRequiredDocId: defaultNumericId,
  bidAwardRequiredDocResponseId: defaultNumericId,
  bidDocId: defaultNumericId,
  bidId: defaultNumericId,
  docName: '',
  docTitle: '',
  dueDate: nowISO(),
  isDelete: false,
  respondingAccountId: defaultNumericId,
  respondingMemberId: defaultNumericId,
  responseDatetime: '',
};

/**
 * Track the selected item for editing.
 */
export const selectedRequiredDocumentState = atom<RequiredDocumentResponseGetAPIResponse>({
  key: 'selectedRequiredDocumentState',
  default: defaultRequiredDocument,
});
