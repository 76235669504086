// TOREFACTOR: These props have no type safety.
// How is this file different than the other document.tsx files?
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Table } from 'reactstrap';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../store/actions';
import { Buttons, NoResult } from '../../../../customcontrols';
import {
  appKeys,
  BidExternalStatusType,
  bidNonEditableStatus,
  memberTypes,
  sortDirections,
} from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import { ApproveDocument } from '../../../../common/createbid/modals/approvedocument';
import { displayDate, SortingFn } from '../../../../../utils/helpers';
import { DocStatus, DocTypeValue } from '../../../../../types/addbid';
import { BidSummaryState } from '../../../../../store/reducers/bidssummary';
import { ToolTip } from '../../../../common/ToolTip';
import { MembershipLevel, Permission } from '../../../../../types/auth';

const tdWidth = {
  type: {
    width: '25%',
  },
  date: {
    width: '20%',
  },
  status: {
    width: '18%',
  },
  action: {
    width: '98px',
  },
};

// TODO: TS4 - These props are all now explicit any and need to be typed better.
type PropsTypes = {
  addbid?: any;
  auth?: any;
  bidsDownloadDocumentRest?: any;
  bidsResponseDocumentDownload?: any;
  bidssummary?: any;
  documentData?: any;
  documentDataError?: any;
  documents?: any;
  getAddBidIsPermissions?: any;
  memberInfo?: any;
  page?: any;
  purchaseinfo?: any;
  purchaseInfo?: any;
  quoteSummary?: any;
  results?: any;
  setAddBidDetails?: any;
  shared?: any;
};

const Documents = (props: PropsTypes) => {
  const [errormodal, setErrormodal] = useState(false);
  const {
    documentDataError,
    documentData,
    bidsDownloadDocumentRest,
    bidsResponseDocumentDownload,
    results = {},
    purchaseInfo = {},
    auth,
    shared,
    page = 'bid',
    quoteSummary = {},
    bidssummary = {},
    purchaseinfo = {},
    getAddBidIsPermissions,
    addbid,
    setAddBidDetails,
  } = props;
  const { isPurchased = false } = purchaseinfo;
  const { quoteDocuments = [] } = quoteSummary || {};
  const { documents = [] } = (bidssummary as BidSummaryState) || {};
  const { internalLoader } = shared || {};
  const { bidID = '', bidExternalStatusType: bEST = BidExternalStatusType.None, tzfn } = results;
  const { permissions = '', approvedocumentmodal } = addbid || {};
  const { canApprove } = permissions;
  let documentsList = page === 'quotes' ? quoteDocuments : documents;
  const { mt: memberType, ml = '', prms = '' } = auth || {};
  const mlcheck = ml.split(',');
  const prmscheck = prms.split(',');

  const checkMemberType = () => {
    if (
      memberType === memberTypes.agencyCreatedPlanholder ||
      memberType === memberTypes.subscribingSupplier ||
      memberType === memberTypes.basicSupplier
    ) {
      return false;
    } else return true;
  };

  const DownloadDocumentFn = (docId: number, id: number, type: string) => {
    bidsResponseDocumentDownload({ docId, id, type });
  };

  useEffect(() => {
    if (memberType === memberTypes.agencyBuyer) {
      if (bidID) {
        getAddBidIsPermissions({ bidId: bidID });
      }
    }
  }, [bidID, getAddBidIsPermissions, memberType]);

  useEffect(() => {
    if (documentDataError && !documentData) {
      setErrormodal(true);
      bidsDownloadDocumentRest(true);
    }
  }, [bidsDownloadDocumentRest, documentData, documentDataError]);

  const handleclick = useCallback(() => {
    setErrormodal(false);
  }, []);

  const title = `${appKeys.headers.docRequest} - ${results.bidName} - ${appKeys.name}`;

  const DownloadErrorComponent = () => (
    <div>
      <title>{title}</title>
      <p>
        You are attempting to retrieve a project that has been archived. Please email
        <a href='mailto:demandstar@demandstar.com' className='mr-2 ml-2'>
          <i>
            <strong>demandstar@demandstar.com</strong>
          </i>
        </a>
        with the following information for document retrieval. You should receive your document(s)
        within 2 business days.
      </p>
      <p>1. Bid Identification Number in full</p>
      <p>2. Bid Name</p>
      <p>
        3. Specific documents you wish to retrieve. If you wish to retrieve all documents, please
        specify “all documents”.
      </p>
      <Buttons
        onClick={handleclick}
        classNames='bttn bttn-primary float-right ml-2'
        text='Continue'
        title='Continue'
      />
    </div>
  );

  const approveDocument = (doc: any) => {
    setAddBidDetails({ approvedocumentmodal: !approvedocumentmodal, approvedocumentdetails: doc });
  };

  const canPostOrManageBids =
    mlcheck.includes(MembershipLevel.BidPoster) || prmscheck.includes(Permission.ManageBids);

  documentsList =
    documentsList.length > 0
      ? SortingFn(documentsList, sortDirections.DESC, 'modifiedDate')
      : documentsList;

  return (
    <>
      <Table className='tableFixed tableHData'>
        {documentsList && documentsList.length > 0 && props.memberInfo ? (
          <tbody>
            <tr>
              <th>Filename</th>
              <th style={tdWidth.type}>Type</th>
              <th style={tdWidth.date}>Date Modified</th>
              <th style={tdWidth.status}>Status</th>
              <th style={tdWidth.action}></th>
            </tr>
            {documentsList.map((document: any) => (
              <tr key={document.bidDocID}>
                <td className='text-truncate'>
                  {page === 'quotes' ? (
                    <span
                      className='staticLink underLine'
                      title={document.fileName}
                      onClick={() =>
                        DownloadDocumentFn(document.quoteDocID, document.quoteID, 'Quote')
                      }
                    >
                      {document.fileName}
                    </span>
                  ) : isPurchased || checkMemberType() ? (
                    <>
                      <span
                        className='staticLink underLine'
                        title={document.fileName}
                        onClick={() => DownloadDocumentFn(document.bidDocID, document.bidID, 'Bid')}
                      >
                        {document.fileName}
                      </span>
                    </>
                  ) : (
                    <Link
                      className='staticLink underLine'
                      to={{
                        pathname: `${
                          memberType === memberTypes.agencyBuyer ? '/buyers' : '/suppliers'
                        }/bids/${document.bidID}/order`,
                        state: { fromBitSummary: { ...results, ...purchaseInfo } },
                      }}
                      title={document.fileName}
                    >
                      {document.fileName}
                    </Link>
                  )}
                </td>
                <td>{document.type}</td>
                <td>{displayDate(document.modifiedDate, tzfn)}</td>
                <td>{document.status}</td>
                <td>
                  {canApprove &&
                  document.statusType === DocStatus.ApprovalPending &&
                  memberType === memberTypes.agencyBuyer &&
                  !bidNonEditableStatus.includes(bEST) &&
                  canPostOrManageBids ? (
                    <ToolTip
                      text='Awarding the bid will approve this document.'
                      hideTip={document.docType !== DocTypeValue.AwardDocument}
                    >
                      <Buttons
                        text='Approve'
                        classNames='bttn-primary approve'
                        disable={document.docType === DocTypeValue.AwardDocument}
                        onClick={() => approveDocument(document)}
                      />
                    </ToolTip>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <NoResult message={internalLoader ? loadingMsg : 'No Documents Available'} />
        )}
      </Table>
      <ModalPopUp
        title={'Download Document Unavailable'}
        closeModal={handleclick}
        isOpen={errormodal}
      >
        {DownloadErrorComponent()}
      </ModalPopUp>
      <ModalPopUp
        size='md'
        title='Confirmation'
        closeModal={() => approveDocument('')}
        isOpen={approvedocumentmodal}
      >
        <ApproveDocument
          {...props}
          SetApproveDocument={approveDocument}
          bidId={bidID}
          pagefor='bidssummary'
        />
      </ModalPopUp>
    </>
  );
};

const connector = connect(
  (state: any) => ({ ...state.memberinfo, ...state.bidssummary, auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Documents);
