/**
 * Format a 10-digit string of numbers
 * according to Sophia's preferred phone number format.
 */
export function formatPhone(phoneNumber: string) {
  if (phoneNumber.length === 10) {
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
      6,
      10,
    )}`;
  }
  return phoneNumber;
}

// To get only number instead of brackets and etc....
export const formatPhoneNumber = (phoneNumber: string) => {
  // FIXME: Illegal character range
  if (phoneNumber) {
    const pattern = /[()-\s.+]/g;
    const formattedNumber = phoneNumber.replace(pattern, '');
    return formattedNumber.length > 10
      ? formattedNumber.length === 11
        ? formattedNumber.substring(1)
        : formattedNumber.substring(1, 11)
      : formattedNumber;
  }
  return phoneNumber;
};

//TODO: Rewrite this or replace this to take a number only
export const formatPrice = (price: any = 0, fixedFloat = 2) => {
  let value = '';
  if (price) {
    value = parseFloat(price)
      .toFixed(fixedFloat)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

export const formatNumber = (data = 0) => {
  let value = '0';
  if (data) {
    value = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

export const formatAddress = (
  address1: string,
  address2: string,
  city: string,
  state: Record<string, unknown> | string | number,
  postalCode: string | number,
  country: Record<string, unknown> | string | number,
) => {
  const addressArray = [];
  if (address1) addressArray.push(address1);
  if (address2) addressArray.push(address2);
  if (city) addressArray.push(city);
  const stateComplete = `${state} ${postalCode}`;

  if (state && postalCode) addressArray.push(stateComplete);
  if (country) addressArray.push(country);
  return addressArray.join(', ');
};
