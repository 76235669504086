import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function getPrograms(payload: actionPayloadTypes) {
  return action(actionTypes.GET_PROGRAMS, payload);
}

export function addUpdateProgram(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_UPDATE_PROGRAM, payload);
}

export function getSuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.GET_SUPPLIERS, payload);
}

export function respondCertRequest(payload: actionPayloadTypes) {
  return action(actionTypes.RESPOND_CERT_REQUEST, payload);
}

export function setProgramFilters(payload: actionPayloadTypes) {
  return action(actionTypes.SET_PROGRAM_FILTERS, payload);
}

export function clearProgramFilters(payload: actionPayloadTypes) {
  return action(actionTypes.CLEAR_PROGRAM_FILTERS, payload);
}

export function setProgramDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_PROGRAM_DETAILS, payload);
}

export function resetPrograms(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_PROGRAMS, payload);
}
