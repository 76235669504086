import { createNoLoadActionTypes, createActionTypes } from './utils';

export const GET_LOCATION_LIST = createNoLoadActionTypes('GET_LOCATION_LIST');
export const GET_AGENCIES_LIST = createNoLoadActionTypes('GET_AGENCIES_LIST');
export const CLEAR_APP_STATE = createNoLoadActionTypes('CLEAR_APP_STATE');
export const GET_COMMODITIES_LISTS = createNoLoadActionTypes('GET_COMMODITIES_LISTS');
export const GET_SHARED_BID_STATUS = createNoLoadActionTypes('GET_SHARED_BID_STATUS');
export const GET_SHOW_BIDS = createNoLoadActionTypes('GET_SHOW_BIDS');
export const GET_STATES_LIST = createNoLoadActionTypes('GET_STATES_LIST');
export const GET_COUNTIES_LIST = createNoLoadActionTypes('GET_COUNTIES_LIST');
export const SET_SHARED_DETAILS = createNoLoadActionTypes('SET_SHARED_DETAILS');
export const GET_SCRAPERS = createNoLoadActionTypes('GET_SCRAPERS');
export const GET_SELF_DECLARATION = createNoLoadActionTypes('GET_SELF_DECLARATION');
export const GET_DASHBOARD_DATA = createNoLoadActionTypes('GET_DASHBOARD_DATA');
export const GET_FISCAL_YEAR = createNoLoadActionTypes('GET_FISCAL_YEAR');
export const GET_RESPONSE_DISPLAYS = createNoLoadActionTypes('GET_RESPONSE_DISPLAYS');
export const GET_DASHBOARD_BIDS_LIST = createNoLoadActionTypes('GET_DASHBOARD_BIDS_LIST');
export const GET_DASHBOARD_QUOTES_LIST = createNoLoadActionTypes('GET_DASHBOARD_QUOTES_LIST');
export const GET_DASHBOARD_NETWORK = createNoLoadActionTypes('GET_DASHBOARD_NETWORK');
export const GET_DASHBOARD_NOTIFICATIONS = createNoLoadActionTypes('GET_DASHBOARD_NOTIFICATIONS');
export const SUBMIT_DASHBOARD_DISABLE_NOTIFICATIONS = createNoLoadActionTypes(
  'SUBMIT_DASHBOARD_DISABLE_NOTIFICATIONS',
);
export const GET_TOP_COMMODITIES = createNoLoadActionTypes('GET_TOP_COMMODITIES');
export const GET_THEMES = createNoLoadActionTypes('GET_THEMES');
export const SET_THEMES = createNoLoadActionTypes('SET_THEMES');
export const RESET_SHARED = createNoLoadActionTypes('RESET_SHARED');
export const GET_OTHER_MEMBER_INFO = createNoLoadActionTypes('GET_OTHER_MEMBER_INFO');
export const POPULATE_METRO_DETAILS = createNoLoadActionTypes('POPULATE_METRO_DETAILS');
export const GET_TIMEZONE_LIST = createNoLoadActionTypes('GET_TIMEZONE_LIST');
export const UPDATE_INTERNAL_LOADER = createNoLoadActionTypes('UPDATE_INTERNAL_LOADER');
export const GET_WATCHED_BIDIDS = createNoLoadActionTypes('GET_WATCHED_BIDIDS');
export const SUBMIT_TRACK_EVENTS = createNoLoadActionTypes('SUBMIT_TRACK_EVENTS');
export const GET_CONTRACT_INTEREST = createActionTypes('GET_CONTRACT_INTEREST');
export const UPDATE_CONTRACT_INTEREST = createActionTypes('UPDATE_CONTRACT_INTEREST');
export const GET_SIBLING_PRODUCTS = createNoLoadActionTypes('GET_SIBLING_PRODUCTS');
export const GET_RELATED_BIDS = createNoLoadActionTypes('GET_RELATED_BIDS');
export const GET_AMPLITUDE_USER_PROPERTIES = createNoLoadActionTypes(
  'GET_AMPLITUDE_USER_PROPERTIES',
);
export const GET_DYNAMIC_PRODUCT_PRICES = createNoLoadActionTypes('GET_DYNAMIC_PRODUCT_PRICES');
