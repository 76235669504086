export const commonLabels = {
  cancel: 'Cancel',
  confirm: 'Confirm',
  confirmDelete: 'Confirm Delete',
  confirmNavigation: 'Confirm Navigation',
  dateUnavailable: 'Date unavailable',
  delete: 'Delete',
  demandStar: 'DemandStar',
  deselect: 'Deselect',
  edit: 'Edit',
  error: 'Error',
  loading: 'Loading...',
  notFound: 'No records were found',
  save: 'Save',
  select: 'Select',
  submit: 'Submit',
  upload: 'Upload',
  viewMore: 'View more',
};

export const commonFieldNames = {
  description: 'Description',
  documents: 'Documents',
  dueDate: 'Due Date',
  status: 'Status',
  title: 'Title',
  type: 'Type',
};

export const defaultNumericId = -1;
