import { atom, useRecoilState } from 'recoil';
import { useEffect } from 'react';

import { defaultNumericId } from '../constants';
import { useDSSelector } from '../../store/reducers';

interface Auth {
  fullName: string;
  memberId: number;
}

export const authState = atom<Auth>({
  key: 'authState',
  default: {
    fullName: '',
    memberId: defaultNumericId,
  },
});

/** currently keeps fullName and memberId in sync with Redux.
 * Should be built out to have an easily accessible Auth state.
 * NOTE: This will likely be replaced entirely as we remove our dependency on Redux.
 */
export function useAuth() {
  const reduxAuth = useDSSelector(state => state.auth);
  const [auth, setAuth] = useRecoilState(authState);

  useEffect(() => {
    if (auth.fullName !== reduxAuth.fln || auth.memberId !== reduxAuth.mi) {
      setAuth({ ...auth, fullName: reduxAuth.fln, memberId: reduxAuth.mi });
    }
  }, [auth, reduxAuth.fln, reduxAuth.mi, setAuth]);

  return auth;
}
