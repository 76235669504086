import React, { memo } from 'react';

import { deprecatedCheckCountDown, deprecatedCheckTimezoneCountDown } from '../../../utils/helpers';
import history from '../../../utils/history';
import { SummaryType } from '../../../types/quotedetailstypedef';

interface PropsTypes {
  quoteId: string | number;
  summary: SummaryType;
  setQuoteSummaryDetails: any;
  notSubmitQuote: any;
  hasQuoteRespondPermission?: boolean;
}
function SupplierQuotescontrol(props: PropsTypes) {
  const { quoteId, summary, setQuoteSummaryDetails, hasQuoteRespondPermission = true } = props;
  const { statusType = '', dueDateTime, responseStatus, tzfn = '' } = summary;

  return (
    <>
      {responseStatus !== 'RS' &&
      responseStatus !== 'NQ' &&
      statusType !== 'CL' &&
      statusType === 'OP' &&
      deprecatedCheckCountDown(dueDateTime) !== 'closed' &&
      hasQuoteRespondPermission ? (
        <li title='Decline Quote'>
          <span
            className='staticLink'
            onClick={() => {
              setQuoteSummaryDetails({ declinequotereplymodal: true });
            }}
          >
            <i className='mdi mdi-cancel mr-1' /> Decline Quote
          </span>
        </li>
      ) : null}
      {statusType === 'OP' &&
      deprecatedCheckTimezoneCountDown(dueDateTime, tzfn) !== 'closed' &&
      hasQuoteRespondPermission ? (
        <li title={responseStatus === 'RS' ? 'Update Quote' : 'Respond To Quote'}>
          <span
            className='staticLink addBid'
            onClick={() => {
              history.push(`/suppliers/quotes/${quoteId}/response`);
            }}
          >
            <i className='mdi mdi-reply mr-1' />{' '}
            {responseStatus === 'RS' ? 'Update Quote' : 'Respond To Quote'}
          </span>
        </li>
      ) : null}
    </>
  );
}

export default memo(SupplierQuotescontrol);
