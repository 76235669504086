import React from 'react';

import {
  BidResponseLabels,
  BidSummaryLabels,
  RESPONSE_DETAILS_LABEL,
} from '../../../../shared/constants/bids';
import { PanelItemWrapper, PanelLabel, PanelRow, PanelRowRight } from '../../../../shared/styles';

import { BidSummaryApiResponse } from '../../../../types/bidssummary';
import { BidSummaryPanelView } from '../../../bids/management/BidSummaryPanelView';
import { ContactDetailsPanel } from './ContactDetailsPanel';
import { ContactLabels } from '../../../../shared/constants';
import { DocumentsPanel } from './DocumentsPanel';
import { ResponseHistory } from './ResponseHistory';
import { SubmitButton } from '../../../../components/common/controls/buttons/SubmitButton';

interface BidResponseDetailsPanelViewProps {
  bidStatus?: string;
  handleEditClick?(): void;
  responseDetails: BidSummaryApiResponse;
}

export function BidResponseDetailsPanelView(props: BidResponseDetailsPanelViewProps) {
  const { bidStatus, responseDetails, handleEditClick } = props;

  const {
    bidId,
    responseDocuments,
    requiredDocuments,
    responseId,
    supplementalDocuments,
  } = responseDetails;

  return (
    <>
      <PanelLabel>{RESPONSE_DETAILS_LABEL}</PanelLabel>
      <ResponseHistory {...props} responseId={responseId} />
      <PanelItemWrapper>
        <PanelRow>
          <BidSummaryPanelView data={responseDetails} />
        </PanelRow>
      </PanelItemWrapper>

      <PanelLabel>{ContactLabels.label}</PanelLabel>
      <PanelItemWrapper>
        <PanelRow>
          <ContactDetailsPanel />
        </PanelRow>
      </PanelItemWrapper>

      {requiredDocuments?.length > 0 && (
        <>
          <PanelLabel>{BidResponseLabels.agencyRequiredDocuments}</PanelLabel>
          <PanelItemWrapper>
            <PanelRow>
              <DocumentsPanel
                documents={requiredDocuments}
                bidId={bidId}
                documentTitle={''}
                documentType='1'
              />
            </PanelRow>
          </PanelItemWrapper>
        </>
      )}

      {supplementalDocuments?.length > 0 && (
        <>
          <PanelLabel>{BidResponseLabels.supplementalDocuments}</PanelLabel>
          <PanelItemWrapper>
            <PanelRow>
              <DocumentsPanel
                documents={supplementalDocuments}
                bidId={bidId}
                documentTitle={''}
                documentType='1'
              />
            </PanelRow>
          </PanelItemWrapper>
        </>
      )}

      {responseDocuments?.length > 0 && (
        <>
          <PanelLabel>{BidResponseLabels.uploadedDocuments}</PanelLabel>
          <PanelItemWrapper>
            <PanelRow>
              <DocumentsPanel
                documents={responseDocuments}
                documentType='2'
                documentTitle={''}
                bidId={bidId}
              />
            </PanelRow>
          </PanelItemWrapper>
        </>
      )}

      {bidStatus !== 'closed' && (
        <PanelItemWrapper>
          <PanelRowRight>
            <SubmitButton title={BidSummaryLabels.edit} type='button' onClick={handleEditClick}>
              {BidSummaryLabels.edit}
            </SubmitButton>
          </PanelRowRight>
        </PanelItemWrapper>
      )}
    </>
  );
}
