import Api, { axiosRequest } from './api';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';
import { ProductApiResponse } from '../../types/products';

export function getAgencies(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencies, data);
}

/**
 * Generic typed request.
 * TOREFACTOR - merge or convert preexisting getAllProducts calls.
 * @param data
 */
export function getProducts() {
  return axiosRequest<ProductApiResponse[]>({
    url: Paths.getAllProducts,
    baseURL: constants.api.userUrl,
  });
}

export function getAllProducts(data = {}) {
  return Api.getRequest(Paths.getAllProducts, {
    baseURL: constants.api.userUrl,
  });
}

export function getSubscriptionMemberDetails(data = {} as any) {
  let subscriptionUrl = Paths.getSubscriptionMemberDetails;
  if (data.memberId) {
    subscriptionUrl = `${subscriptionUrl}?memberid=${data.memberId}`;
  }
  return Api.getRequestWithAuthentication(subscriptionUrl, {
    baseURL: constants.api.userUrl,
  });
}

export function calculateSubscriptionRequest(data = {}) {
  return Api.postRequestWithAuthentication(Paths.calculateSubscriptionRequest, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function processPayment(data = {} as any) {
  if (data.pagefor === 'registration') {
    return Api.postRequest(Paths.processPayment, data, {
      baseURL: constants.api.subscriptionUrl,
    });
  } else {
    return Api.postRequestWithAuthentication(Paths.processPayment, data, {
      baseURL: constants.api.subscriptionUrl,
    });
  }
}

export function processComplementary(data = {}) {
  return Api.postRequestWithAuthentication(Paths.processComplementary, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}
