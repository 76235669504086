import React, { memo, useState } from 'react';
import { Table } from 'reactstrap';
import { authTypes } from '../../../../types/auth';
import { accountinfoType, anyParamType } from '../../../../types/paymentdetails';
import { dataParamType } from '../../../../types/subscriptions';
import { deprecatedGetDateTimeZoneConvertedDate } from '../../../../utils/helpers';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { Buttons } from '../../../customcontrols';
import AutoRenewal from './autorenewal';

const tdWidth = {
  width: '180px',
};

interface PropsTypes {
  accountinfo: accountinfoType;
  auth: authTypes;
  modifySubscription: anyParamType;
  cancelSubscriptions: anyParamType;
  data: dataParamType;
}

const Summary = (props: PropsTypes) => {
  const { accountinfo, auth, modifySubscription, cancelSubscriptions } = props;
  const {
    serviceText,
    services = [],
    daysToRenew,
    expiryDate,
    userType,
    nonFreeProducts,
    isMainContact,
    getProfileInfo,
    updateAutoRenewalSubscription,
  } = props.data;

  const { profileInfo } = accountinfo || {};
  const { card, result = '' } = profileInfo || {};
  const { isAutoRenewalOn } = card || {};
  const { opi } = auth;

  const handleCancelSubscriptionClick = (e: Event) => {
    e.preventDefault();
    setCancelModalOpen(!cancelModalOpen);
  };

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const autoRenewalData = {
    isAutoRenewalOn,
    getProfileInfo,
    updateAutoRenewalSubscription,
  };
  const hasProfile = result === 'SUCCESS';

  return (
    <>
      <div className='sideBarSpacing'>
        <h4>Subscriptions Detail</h4>
        <div className='innerColIndent'>
          <Table borderless>
            {/* <tr>
              <th style={tdWidth}>Services</th>
              <td>
                {serviceText.length > 0 ? serviceText.join() : 'None'}{' '}
                {services && services.length > 0
                  ? services[services.length - 1].subscriptionStatusType === 'AC'
                    ? ' (Active)'
                    : ' (Awaiting Payment)'
                  : null}
              </td>
            </tr> */}
            {expiryDate && (
              <tr>
                <th>Status</th>
                <td>{daysToRenew && daysToRenew > 0 ? 'Active' : 'Expired'}</td>
              </tr>
            )}
            {expiryDate && expiryDate ? (
              <tr>
                <th>Expires</th>
                <td className='text-red200'>
                  {' '}
                  {deprecatedGetDateTimeZoneConvertedDate(expiryDate, 'Eastern Time (US & Canada)')}
                </td>
              </tr>
            ) : null}
            {userType === 'SS' &&
            hasProfile &&
            nonFreeProducts.length > 0 &&
            isMainContact === true ? (
              <tr>
                <th>Auto renewal</th>
                <td>
                  <AutoRenewal data={autoRenewalData} />
                </td>
              </tr>
            ) : null}

            {opi && userType === 'SS' ? (
              <>
                <tr>
                  <td colSpan={2}>
                    <span className='staticLink' onClick={() => modifySubscription()}>
                      <i className='mdi mdi-transfer-up mr-1' /> Modify Subscription
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span
                      className='staticLink'
                      onClick={(e: any) => handleCancelSubscriptionClick(e)}
                    >
                      <i className='mdi mdi-account-remove mr-1' /> Cancel Subscription
                    </span>
                  </td>
                </tr>
              </>
            ) : null}
          </Table>
        </div>
      </div>

      <ModalPopUp
        title={'Cancel Subscription'}
        size={'md'}
        isOpen={cancelModalOpen === true}
        closeModal={() => setCancelModalOpen(false)}
        backdrop='static'
      >
        <CancelSubscription
          cancelSubscription={(e: Event) => {
            cancelSubscriptions();
            handleCancelSubscriptionClick(e);
          }}
          handleClick={handleCancelSubscriptionClick}
        />
      </ModalPopUp>
    </>
  );
};

interface CancelProps {
  cancelSubscription: anyParamType;
  handleClick: anyParamType;
}

const CancelSubscription = (props: CancelProps) => {
  const { cancelSubscription, handleClick } = props;
  return (
    <>
      <p>Are you sure you want to cancel the subscription?</p>
      <Buttons classNames='bttn bttn-secondary' onClick={handleClick} text='No' />
      <Buttons classNames='bttn bttn-primary float-right' onClick={cancelSubscription} text='Yes' />
    </>
  );
};

export default memo(Summary);
