import React, { memo } from 'react';
import { Table } from 'reactstrap';

import {
  deprecatedCheckTimezoneCountDown,
  displayDateTime,
  formattedPhoneNumber,
} from '../../../../../../utils/helpers';
import CountDownTimer from '../../../../../common/countdowntimer';
import { QuoteSummaryType } from '../../../../../../types/quotedetailstypedef';
import { SupplierResponseStatus } from '../../../../../../utils/constants';

const tdWidth = {
  width: '240px',
};

interface PropsTypes {
  quoteSummary: QuoteSummaryType;
  userpagefor: string;
}

const QuoteInformation = (props: PropsTypes) => {
  const { userpagefor, quoteSummary } = props;
  const { summary } = quoteSummary || {};
  const {
    quoteNumber = '',
    broadcastDateTime = '',
    dueDateTime = '',
    deliveryARO = '',
    shipVia = '',
    specifications = '',
    insuranceReqs = '',
    termsandConditions = '',
    buyerName = '',
    status = '',
    statusType = '',
    contactName = '',
    contactAddress1 = '',
    contactCity = '',
    contactCountryName = '',
    contactDepartment = '',
    contactEmail = '',
    contactPhone = '',
    contactPostalCode = '',
    contactStateAbbr = '',
    shipName = '',
    shipAddress1 = '',
    shipCity = '',
    shipCountryName = '',
    shipDepartment = '',
    shipPhone = '',
    shipPostalCode = '',
    shipStateAbbr = '',
    statusUpdateNotes = '',
    responseStatus = '',
    tzn = '',
    tzfn = '',
  } = summary || {};

  const contactAddress = `${contactDepartment}, ${contactAddress1}, ${contactCity}, ${contactStateAbbr} ${contactPostalCode}, ${contactCountryName}`;
  const shippingAddress = `${shipDepartment}, ${shipAddress1}, ${shipCity}, ${shipStateAbbr} ${shipPostalCode}, ${shipCountryName}`;

  return (
    <>
      <Table borderless className='tableData'>
        <tbody>
          {userpagefor === 'suppliers' ? (
            <tr>
              <th style={tdWidth}>Agency</th>
              <td>{buyerName}</td>
            </tr>
          ) : null}
          <tr>
            <th style={tdWidth}>Quote Number</th>
            <td>{quoteNumber}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Due</th>
            <td className='text-red200'>
              {userpagefor === 'suppliers' ? (
                <>{statusType === 'CA' ? status : <>{displayDateTime(dueDateTime, tzfn)}</>}</>
              ) : (
                <>{displayDateTime(dueDateTime, tzfn)} </>
              )}
            </td>
          </tr>
          {userpagefor === 'suppliers' &&
          !['closed', 'notstarted'].includes(
            deprecatedCheckTimezoneCountDown(dueDateTime, tzfn, 'supplierquote'),
          ) ? (
            <tr>
              <th style={tdWidth}></th>
              <td className='text-red200'>
                {<CountDownTimer dueDate={dueDateTime} tzfn={tzfn} />}
              </td>
            </tr>
          ) : null}
          {broadcastDateTime ? (
            <tr>
              <th>Broadcast Date</th>
              <td className='text-red200'>
                {broadcastDateTime ? displayDateTime(broadcastDateTime, tzfn) : null}
              </td>
            </tr>
          ) : statusType === 'OP' ? (
            <tr>
              <th>Broadcast Date</th>
              <td>Quote has not been broadcast</td>
            </tr>
          ) : null}
          <tr>
            <th>Days to deliver after Receipt of Order (ARO)</th>
            <td>
              {deliveryARO ? (
                <>
                  {deliveryARO} {parseInt(deliveryARO) > 1 ? 'Days' : 'Day'}
                </>
              ) : (
                'None'
              )}
            </td>
          </tr>
          <tr>
            <th>Shipping Notes</th>
            <td>{shipVia ? shipVia : 'None'}</td>
          </tr>
          <tr>
            <th>Additional Specifications</th>
            <td>{specifications ? specifications : 'None'}</td>
          </tr>
          <tr>
            <th>Insurance and Additional Requirements</th>
            <td>{insuranceReqs}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Terms and Conditions</th>
            <td>{termsandConditions}</td>
          </tr>
          {statusUpdateNotes ? (
            <tr>
              <th style={tdWidth}>Status Notes</th>
              <td>{statusUpdateNotes}</td>
            </tr>
          ) : null}
          {responseStatus ? (
            <tr>
              <th style={tdWidth}>Response Status</th>
              <td className='text-red200'>{SupplierResponseStatus[responseStatus]}</td>
            </tr>
          ) : null}
          <tr>
            <th style={tdWidth}>Contact Name</th>
            <td>{contactName}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Contact Address</th>
            <td>{contactAddress}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Contact Email</th>
            <td>{contactEmail}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Contact Phone</th>
            <td>{formattedPhoneNumber(contactPhone)}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Shipping Name</th>
            <td>{shipName}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Shipping Address</th>
            <td>{shippingAddress}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Shipping Phone</th>
            <td>{shipPhone ? formattedPhoneNumber(shipPhone) : ''}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default memo(QuoteInformation);
