import React, { useCallback, useRef } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import {
  GetVendorSpecificDocumentsAPIResponse,
  RequiredDocumentResponseGetAPIResponse,
} from '../../../types';
import {
  requiredActionIncompleteListState,
  requiredActionListState,
  requiredActionsLoadingState,
} from '../../../store/recoil/award-bid';

import { compareObjectsByKey } from '../../../utils';
import { defaultNumericId } from '../../constants';
import { getBidSummaryFromService } from '../useBidInfo';
import { getRequiredActionUniqueId } from '../../../utils/required-action';
import { getRequiredDocResponsesFromService } from './useRequiredDocumentResponses';
import { RequiredAction } from '../../../types/required-action';
import { RequiredDocumentUploadPanel } from '../../../features/bids/management/RequiredDocumentUploadPanel';
import { selectedBidIdState } from '../../../store/recoil/bidState';
import { selectedRequiredActionIdState } from '../../../store/recoil/award-bid/required-action';
import { supplierMemberIdState } from '../../../store/recoil/memberState';
import { useSetSelectedBidIdFromRoute } from '../useSelectedBidId';
import { useVendorDocuments } from './vendor-document';
import { vendorDocLabels } from '../../../components/buyer/awardbid/add-vendor-docs/constants';
import { VendorDocumentsTable } from '../../../components/buyer/awardbid/add-vendor-docs/VendorDocumentsTable';

export function useRequiredActions() {
  const bidSummaryMap = useRef<{ [bidId: number]: { bidTitle: string; agencyName: string } }>({});

  const [selectedRequiredActionId, setSelectedRequiredActionId] = useRecoilState(
    selectedRequiredActionIdState,
  );
  const requiredActionList = useRecoilValue(requiredActionListState);

  const [requiredActionsLoading, setRequiredActionsLoading] = useRecoilState(
    requiredActionsLoadingState,
  );
  const requiredActionIncompleteList = useRecoilValue(requiredActionIncompleteListState);

  useSetSelectedBidIdFromRoute();

  // - Vendor Documents
  const { getVendorDocsFromService } = useVendorDocuments();

  // - Required Documents
  const resetRequiredActions = useResetRecoilState(requiredActionListState);

  /**
   * Convert RequiredDocumentGetAPIResponse objects to client-only RequiredAction items.
   */
  const getRequiredActionsFromRequiredDocs = (
    requiredDocs: RequiredDocumentResponseGetAPIResponse[],
  ) => {
    const requiredActions: RequiredAction[] = requiredDocs.length
      ? requiredDocs.map(doc => {
          const { bidId, docTitle, dueDate } = doc;
          const uniqueId = getRequiredActionUniqueId('requiredDocument', doc.bidAwardRequiredDocId);
          const requiredAction: RequiredAction = {
            actionId: uniqueId,
            bidId: bidId,
            component: <RequiredDocumentUploadPanel requiredDocument={doc} />,
            dueDate: dueDate,
            status: doc.bidAwardRequiredDocResponseId ? 'Complete' : 'Not Started',
            label: docTitle,
            type: 'requiredDocument',
          };
          return requiredAction;
        }, [])
      : [];

    // Default sort by dueDate.
    // Sort requiredActions with 'Complete' actions appearing last.
    return requiredActions
      .sort(compareObjectsByKey('dueDate'))
      .sort(compareObjectsByKey('status', false));
  };

  /**
   * Convert GetVendorSpecificDocumentsAPIResponse objects to client-only RequiredAction items.
   */
  const getRequiredActionsFromVendorDocs = useCallback(
    (vendorDocumentList: GetVendorSpecificDocumentsAPIResponse[]) => {
      const vendorDocsByBid = vendorDocumentList.sort(compareObjectsByKey('bidId'));

      const requiredActions: RequiredAction[] = [];

      if (!vendorDocsByBid.length) {
        return requiredActions;
      }

      const bidIds = new Set<number>();

      // Add a new required action per bidId (e.g. for dashboard or list views)
      for (let i = 0; i < vendorDocsByBid.length; i++) {
        const venDoc = vendorDocsByBid[i];
        if (!bidIds.has(venDoc.bidId)) {
          bidIds.add(venDoc.bidId);

          const uniqueId = getRequiredActionUniqueId('vendorDocument', venDoc.bidId);
          const vendorDocBidId = venDoc.bidId || defaultNumericId;
          const vendorDocsComponent = <VendorDocumentsTable readOnly />;

          const vendorDocRequiredAction: RequiredAction = {
            actionId: uniqueId,
            bidId: vendorDocBidId,
            component: vendorDocsComponent,
            label: vendorDocLabels.downloadProvidedDocuments,
            status: 'Not Started',
            type: 'vendorDocument',
          };

          requiredActions.push(vendorDocRequiredAction);
        }
      }

      return requiredActions;
    },
    [],
  );

  /**
   * Find RequiredDocuments and VendorSpecificDocs and update RequiredAction Recoil state.
   */
  const refreshRequiredActions = useRecoilCallback(
    ({ set, snapshot }) => async () => {
      const supplierMemberId = await snapshot.getPromise(supplierMemberIdState);
      const selectedBidId = await snapshot.getPromise(selectedBidIdState);

      resetRequiredActions();
      setRequiredActionsLoading(true);

      // Run queries in parallel by declaring function calls before calling with await:
      const getVenDocs = getVendorDocsFromService({ bidId: selectedBidId, supplierMemberId });
      const getReqDocResponses = getRequiredDocResponsesFromService(
        selectedBidId,
        supplierMemberId,
      );

      // - Vendor Documents
      const vendorDocuments = await getVenDocs;

      // - Required Documents
      const responses = await getReqDocResponses;

      const requiredDocumentActions = getRequiredActionsFromRequiredDocs([...responses]);
      const vendorDocumentActions = getRequiredActionsFromVendorDocs([...vendorDocuments]);

      const requiredActions = vendorDocumentActions.concat(requiredDocumentActions);

      // Resolve bid title and agency name for each action.
      for (let i = 0; i < requiredActions.length; i++) {
        const action = requiredActions[i];

        // Check the cache before querying.
        const summary = bidSummaryMap.current[action.bidId];

        if (summary) {
          // Set bidSummary values on the action
          action.bidTitle = summary.bidTitle;
          action.agencyName = summary.agencyName;
        } else {
          // Look up the bidSummary.
          // TOREFACTOR: This is costly, these lookups should eventually be handled on the service.
          const bidSummary = await getBidSummaryFromService(action.bidId);

          if (bidSummary) {
            const { agencyName = '', bidName = '' } = bidSummary;

            // Set the values on the cache.
            bidSummaryMap.current[action.bidId] = { bidTitle: bidName, agencyName: agencyName };

            // Set bidSummary values on the action
            action.bidTitle = bidName;
            action.agencyName = agencyName;
          }
        }
      }
      // Set list
      set(requiredActionListState, [...requiredActions]);

      setRequiredActionsLoading(false);
    },
    [],
  );

  return {
    getRequiredActionsFromRequiredDocs,
    getRequiredActionsFromVendorDocs,
    refreshRequiredActions,
    requiredActionList,
    requiredActionIncompleteList,
    requiredActionsLoading,
    resetRequiredActions,
    selectedRequiredActionId,
    setSelectedRequiredActionId,
  };
}
