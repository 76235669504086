import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import {
  authMemberIdState,
  selectedMemberTypeState,
  supplierMemberIdState,
} from '../../store/recoil/memberState';

import { AuthState } from '../../store/reducers/auth';
import { defaultNumericId } from '../constants';
import { MemberType } from '../../types';
import { memberTypes } from '../../utils/constants';

/**
 * Helper fn to save a step when reading/writing to `authMemberIdState`.
 * @returns
 */
export function useAuthMemberId() {
  const [authMemberId, setAuthMemberId] = useRecoilState(authMemberIdState);
  function resetSelectedMemberId() {
    setAuthMemberId(defaultNumericId);
  }
  return { resetSelectedMemberId, authMemberId, setAuthMemberId };
}

/**
 * Helper fn to save a step when reading/writing to `selectedMemberIdState`,
 * @returns
 */
export function useSupplierMemberId() {
  const [supplierMemberId, setSupplierMemberId] = useRecoilState(supplierMemberIdState);
  function resetSelectedMemberId() {
    setSupplierMemberId(defaultNumericId);
  }
  return { resetSelectedMemberId, supplierMemberId, setSupplierMemberId };
}

/**
 * Helper fn to save member type in `selectedMemberTypeState`,
 * @returns
 */
export function useSelectedMemberType() {
  const [selectedMemberType, setSelectedMemberType] = useRecoilState(selectedMemberTypeState);
  function resetSelectedMemberType() {
    setSelectedMemberType('');
  }
  function selectedMemberIsAgency() {
    return selectedMemberType === memberTypes.agencyBuyer;
  }
  return {
    selectedMemberIsAgency,
    resetSelectedMemberType,
    selectedMemberType,
    setSelectedMemberType,
  };
}

/**
 * Helper fn to set member info when AuthState changes.
 * Also sets `supplierMemberIdState` value for supplier members.
 * TODO: move auth aout of redux and enforce types.
 */
export function useSetMemberInfo(auth: AuthState) {
  const { setAuthMemberId } = useAuthMemberId();
  const { setSupplierMemberId } = useSupplierMemberId();
  const { setSelectedMemberType, selectedMemberIsAgency } = useSelectedMemberType();
  useEffect(() => {
    const { mt, mi } = auth;
    if (mi) {
      setAuthMemberId(mi);
      // Set the supplierId as the authId for non-agency users.
      if (!selectedMemberIsAgency()) {
        setSupplierMemberId(mi);
      }
    }
    if (mt) {
      setSelectedMemberType(mt as MemberType);
    }
  }, [auth, selectedMemberIsAgency, setAuthMemberId, setSelectedMemberType, setSupplierMemberId]);
}
