import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../../../store/actions';
import { memberTypes } from '../../../../../../utils/constants';
import Addeditform from './addeditform';
import { ModalPopUp } from '../../../../../common/modals/ModalPopUp';
import UnblockUser from './unblockuser';
import { authTypes } from '../../../../../../types/auth';

type PropsTypes = PropsFromRedux & {
  userinfo: {
    permissions: Array<{ permissionId: number }>;
    permissionCheck: Record<string, unknown>;
  };
  userAccounts: any;
  auth: authTypes;
  syncEditError?: any;
  history: any;
  addUpdateUserAccount: any;
  setAccountInfoDetails: any;
  unblockUserAccount: any;
  form: any;
};

function Userform(props: PropsTypes) {
  const {
    history,
    addUpdateUserAccount,
    setAccountInfoDetails,
    unblockUserAccount,
    syncEditError,
  } = props;
  const { form, ...otherprops } = props;
  const userinfo = (history.location.state && history.location.state.userinfo) || '';
  const permissions = (history.location.state && history.location.state.permissions) || [];
  const memberType = (history.location.state && history.location.state.memberType) || '';

  if (userinfo && userinfo.permissions && userinfo.permissions.length > 0) {
    userinfo.permissionCheck = {};
    userinfo.permissions.forEach((item: any) => {
      userinfo.permissionCheck[item.permissionId] = true;
    });
  }

  if (userinfo && userinfo.username) {
    userinfo.username = userinfo.username.trimStart().trimEnd();
  }

  if (userinfo && userinfo.email) {
    userinfo.email = userinfo.email.trimStart().trimEnd();
  }

  const GoBackToPage = useCallback(() => {
    history.push({
      pathname: `/${memberType === memberTypes.agencyBuyer ? 'buyers' : 'suppliers'}/accountinfo`,
      state: { activeTab: '2' },
    });
  }, [history, memberType]);

  const handleUserSubmit = useCallback(
    (postData = {}, userinfo = '') => {
      addUpdateUserAccount({ postData, userinfo });
    },
    [addUpdateUserAccount],
  );

  const defaultPermissions: boolean[] = [];

  permissions.forEach((item: any) => {
    defaultPermissions[item.permissionId] = true;
  });

  const initialvalue = {
    accountId: 0,
    firstName: '',
    lastName: '',
    username: '',
    initials: '',
    jobTitle: '',
    phoneNumber: '',
    phoneExtension: '',
    notify: true,
    active: true,
    mainContact: false,
    departmentId: 0,
    email: '',
    memberId: 0,
    password: '',
    validatedEmail: false,
    permissions: [],
    permissionCheck: defaultPermissions,
    existingUserName: '',
  };

  useEffect(() => {
    setAccountInfoDetails({ existingUserInfo: userinfo });
  }, [userinfo, setAccountInfoDetails]);

  const [unblock, setUnblock] = useState(false);
  const unblockHandler = () => {
    setUnblock(!unblock);
  };

  return (
    <>
      <Addeditform
        {...otherprops}
        initialValues={
          userinfo
            ? { ...userinfo, existingUserName: userinfo.username, existingNotify: userinfo.notify }
            : initialvalue
        }
        //userinfo={userinfo}
        onSubmit={handleUserSubmit}
        GoBackToPage={GoBackToPage}
        permissions={permissions}
        unblockClick={unblockHandler}
      />
      {/* for OPS inactive user pinin*/}
      <ModalPopUp
        size={'lg'}
        title='Unblock User'
        closeModal={unblockHandler}
        isOpen={unblock}
        backdrop='static'
      >
        <UnblockUser
          {...props}
          unblockHandler={unblockHandler}
          unblockUserAccount={unblockUserAccount}
          history={history}
        />
      </ModalPopUp>
    </>
  );
}

const connector = connect(
  (state: any) => ({ ...state.accountinfo, ...state.memberinfo, auth: state.auth }),
  dispatch => bindActionCreators<any, any>({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Userform));
