import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';

import amplitude from 'amplitude-js';
import App from './App';

import { AmplitudeProvider } from '../components/amplitude';
import { constants } from '../utils/settings';
import rootSaga from '../store/sagas';
import { store } from '../store/configureStore';

store.runSaga(rootSaga);

const amplitudeInstance = amplitude.getInstance();

const Root = (props: any) => {
  if (process.env.NODE_ENV === 'production') {
    return (
      <Provider store={store as any}>
        <>
          <AmplitudeProvider
            amplitudeInstance={amplitudeInstance}
            apiKey={constants.amplitudeToken}
          >
            <StrictMode>
              <App />
            </StrictMode>
          </AmplitudeProvider>
        </>
      </Provider>
    );
  } else {
    return (
      <Provider store={store as any}>
        <AmplitudeProvider amplitudeInstance={amplitudeInstance} apiKey={constants.amplitudeToken}>
          <App />
        </AmplitudeProvider>
      </Provider>
    );
  }
};

export default Root;
