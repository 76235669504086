export const agencyPageTheme = [
  {
    label: 'Black',
    value: '#000000',
    key: 'theme',
  },
  {
    label: 'Gray',
    value: '#bcbcbc',
    key: 'theme',
  },
  {
    label: 'White',
    value: '#ffffff',
    key: 'theme',
  },
];

export const imagePositions = [
  {
    label: 'Top',
    value: 'top',
    type: 'position',
  },
  {
    label: 'Center',
    value: 'center',
    type: 'position',
  },
  {
    label: 'Bottom',
    value: 'bottom',
    type: 'position',
  },
];
