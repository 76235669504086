/* eslint-disable max-lines */
/* TOREFACTOR - Over 500 lines */
import { call, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { END, eventChannel } from 'redux-saga';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

import * as actionTypes from '../actionTypes';
import * as Api from '../services/addbid';
import * as bidApi from '../services/bids';
import * as EbidApi from '../services/ebids';
import * as quoteApi from '../services/quotes';
import * as sharedApi from '../services/shared';
import {
  addbidpayloadTypes,
  addsuppulimentalTypes,
  BidDocument,
  BidDocumentResult,
  BidType,
  DocStatus,
  DocTypeLabel,
  DocTypeValue,
  emitTypes,
  planHoldersType,
  resultsTypes,
  StatusLabel,
} from '../../types/addbid';
import {
  AddBidState,
  addbidwizardfull,
  addbidwizardfullplan,
  addbidwizardplan,
  initialaddbidwizard,
} from '../reducers/addbid';
import {
  AddToAddBidWizard,
  deprecatedGetDate,
  deprecatedGetDateTimeZoneConverted,
  formatPhoneNumber,
  generateKey,
  getOptimizedCommodityCodes,
  getTimeFromDate,
  getZoneFromDate,
  scrollToTop,
  setTimeToDate,
  setWizardStatus,
  SortingFn,
  toastFn,
} from '../../utils/helpers';
import {
  allowForwardNavStatuses,
  bidActiveEditableStatus,
  BidExternalStatusType,
  bidFullEditableStatus,
  BidInternalStatusType,
  bidSemiEditableStatus,
  documentProcessStatus,
  DocumentStatusNames,
  documentStatusNames,
  fixedPercentage,
  Paths,
  processPercentage,
  sortDirections,
  TimeZone,
  usaDateFormat,
  userEvent,
} from '../../utils/constants';
import { Awardee, BidWriter } from '../../types/biddetail';

import { accountinfoType } from '../../types/paymentdetails';
import { SetAddBidDetailsPayload, SubmitAddBidInfoPayload } from '../actions';
import { AuthState } from '../reducers/auth';
import { BidSummaryResult } from '../../types/bidssummary';
import { BidSummaryState } from '../reducers/bidssummary';
import { constants } from '../../utils/settings';
import history from '../../utils/history';
import { MemberInfoState } from '../reducers/memberinfo';
import { SharedState } from '../reducers/shared';
import { WizardPage, WizardStatus } from '../../types/wizard';

const addbidtoastID = '8ba595d1-d8a5-4645-a65e-cb57c0d5af55';

// function createUploader(files: any) {
function createUploader(files: any, uploadPath = Paths.documentUpload) {
  let emit: emitTypes;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = (updata: { total: number; loaded: number }) => {
    const { total, loaded } = updata;
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const config = {
    onUploadProgress: uploadProgressCb,
    baseURL: constants.api.documentUrl,
    uploadPath,
  };
  const uploadPromise = Api.submitAddBidDocumentUpload(files, config);
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan: any, id: string, list: BidDocument[] = []): any {
  while (true) {
    const progress = yield take(chan);
    const documentslist: BidDocument[] = list.map((item: BidDocument, index) => {
      if (item.id === id) {
        item.progress = progress;
        if (progress < 100) {
          item.uploadprocess = 'started';
        }
        if (progress === 100) {
          item.uploadprocess = 'completed';
        }
      }
      return item;
    });
    yield put({
      type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
      payload: {
        documentslist: SortingFn(documentslist, sortDirections.ASC, 'modifiedDate'),
      },
    });
  }
}

function* watchgetAddBidBidTypes() {
  yield takeLatest(actionTypes.GET_ADD_BID_BID_TYPES.TRIGGER, getAddBidBidTypes);
}

function* getAddBidBidTypes(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_BID_TYPES.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidBidTypes, action.payload);
    if (response.data.result) {
      const payload = response.data.result.map(
        (item: { bidType: string; bidTypeDesc: string }) => ({
          ...item,
          label: `${item.bidType || ''} - ${item.bidTypeDesc || ''}`,
          value: item.bidType,
        }),
      );
      yield put({ type: actionTypes.GET_ADD_BID_BID_TYPES.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_BID_TYPES.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_BID_TYPES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidBidWriters() {
  yield takeLatest(actionTypes.GET_ADD_BID_BID_WRITERS.TRIGGER, getAddBidBidWriters);
}

function* getAddBidBidWriters(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_BID_WRITERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidBidWriters, action.payload);
    if (response.data.result) {
      const payload = response.data.result.map((item: { fn: string; ln: string; us: string }) => ({
        ...item,
        label: `${item.fn || ''}  ${item.ln || ''}`,
        value: item.us,
      }));
      yield put({ type: actionTypes.GET_ADD_BID_BID_WRITERS.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_BID_WRITERS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_BID_WRITERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddBidInfo() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_INFO.TRIGGER, submitAddBidInfo);
}

function* submitAddBidInfo(action: { payload: SubmitAddBidInfoPayload }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.REQUEST, meta: action.payload });
  try {
    const addbid = yield select(state => state.addbid);
    const bidssummary = yield select(state => state.bidssummary);
    const { results = {} } = bidssummary;
    const { bidID = '' } = results;

    const {
      addbidwizard = [],
      bidId = '',
      addbidwizardpartial = false,
      // addbidresponseitems,
      // publicationslist,
      sequence = 0,
      newbidNumber = 0,
      duplicatebidmodal = false,
      addbidinfo = {},
    } = addbid || {};

    const ID = bidID || bidId;

    const values = action.payload.data;

    const bidInfoDiff = Object.keys(addbidinfo).reduce((diff: any, key: string) => {
      if ((values as any)[key] === addbidinfo[key]) return diff;
      return {
        ...diff,
        [key]: addbidinfo[key],
      };
    }, {});

    const dueDateInitial = bidInfoDiff.dueDate
      ? setTimeToDate(
          bidInfoDiff.dueDate,
          `${bidInfoDiff.dueTime ? bidInfoDiff.dueTime.value : values.dueTime.value} ${
            bidInfoDiff.dueTimeZone ? bidInfoDiff.dueTimeZone.value : values.dueTimeZone.value
          }`,
        )
      : '';
    const broadcastDateInitial = bidInfoDiff.broadcastDate
      ? moment(bidInfoDiff.broadcastDate).format(usaDateFormat)
      : '';
    const dueDate = setTimeToDate(
      values.dueDate,
      `${values.dueTime.value} ${values.dueTimeZone.value}`,
    );
    const broadcastDate = setTimeToDate(
      values.broadcastDate,
      `${values.broadcastDateTime.value} ${values.broadcastDateTimeZone.value}`,
    );
    let finalvalue = {};
    if (ID) {
      finalvalue = {
        bidId: ID,
        bidSequenceNumber: results.bidSequenceNumber,
        memberId: values.memberId,
        bidName: values.bidName && values.bidName.trim(),
        bidBond: values.bidBond && values.bidBond.trim(),
        displayBudgetToSuppliers: values.displayBudgetToSuppliers,
        projectEstimatedBudget: values.projectEstimatedBudget
          ? parseFloat(
              values.projectEstimatedBudget
                .toString()
                .replace(/ /g, '')
                .replace('$', '')
                .replace(/,/g, ''),
            )
          : '',
        bidWriterAccountId:
          values.bidWriterAccountId &&
          typeof values.bidWriterAccountId !== 'string' &&
          values.bidWriterAccountId.value,
        scopeOfWork: values.scopeOfWork && values.scopeOfWork.trim(),
        bidYear: values.bidYear && values.bidYear.value,
        bidNumber: duplicatebidmodal && newbidNumber ? newbidNumber : values.bidNumber,
        memberBidType:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.value,
        memberBidTypeDesc:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.label,
        openedText: values.openedText,
        dueDateTime: dueDate,
        broadcastDate: broadcastDate,
        onlineResponses: values.eBidding === 'yes' ? true : false,
        bidExternalStatusType: values.bidstatus.value,
        BidInternalStatusType: results.bidInternalStatusType,
      };
    } else {
      finalvalue = {
        bidId: 0,
        bidSequenceNumber: duplicatebidmodal && !newbidNumber ? sequence + 1 : 0,
        memberId: values.memberId,
        bidName: values.bidName && values.bidName.trim(),
        bidBond: values.bidBond && values.bidBond.trim(),
        displayBudgetToSuppliers: values.displayBudgetToSuppliers,
        projectEstimatedBudget: values.projectEstimatedBudget
          ? parseFloat(
              values.projectEstimatedBudget
                .toString()
                .replace(/ /g, '')
                .replace('$', '')
                .replace(/,/g, ''),
            )
          : '',
        bidWriterAccountId:
          values.bidWriterAccountId &&
          typeof values.bidWriterAccountId !== 'string' &&
          values.bidWriterAccountId.value,
        scopeOfWork: values.scopeOfWork && values.scopeOfWork.trim(),
        bidYear: values.bidYear && values.bidYear.value,
        bidNumber: duplicatebidmodal && newbidNumber ? newbidNumber : values.bidNumber,
        memberBidType:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.value,
        memberBidTypeDesc:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.label,
        openedText: values.openedText,
        dueDateTime: dueDate,
        broadcastDate: broadcastDate,
        onlineResponses: values.eBidding === 'yes' ? true : false,
        eBidding: values.eBidding === 'yes' ? true : false,
        blueprintStatusType: 'NA',
        documentHandlerType: 'DM',
        documentVendorType: 'PE',
        bidExternalStatusType: BidExternalStatusType.Upcoming,
        bidInternalStatusType: BidInternalStatusType.Pending,
      };
    }

    const response = yield call(Api.submitAddBidInfo, finalvalue);
    if (response.data.result) {
      if (dueDateInitial !== '') {
        const payloadEvent = {
          eventId: userEvent.UpdateDueDate,
          parentId: ID,
          value: dueDateInitial,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      }
      if (broadcastDateInitial !== '') {
        const payloadEvent = {
          eventId: userEvent.UpdateBroadcastDate,
          parentId: ID,
          value: broadcastDateInitial,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      }
      if (bidInfoDiff.bidstatus) {
        const payloadEvent = {
          eventId: userEvent.UpdateStatus,
          parentId: ID,
          value: bidInfoDiff.bidstatus,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
        yield call(Api.clearDashboardCache, {
          bidIds: [ID],
        });
      }
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { halfWayCancel: false },
      });
      const responsedata = values;
      const dup = response.data.result.dup;
      const newbidId = response.data.result.bidId || ID;
      if (dup) {
        if (duplicatebidmodal === true)
          toastFn('error', 'Bid number already exists', addbidtoastID);
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: {
            duplicatebidmodal: true,
            sequence: response.data.result.sequence,
            addbidinfo: responsedata,
            bidId: newbidId,
          },
        });
        yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.FAILURE });
        return;
      }
      let newwizzard = addbidwizard;
      if (values) {
        const checkexists =
          addbidwizard.filter((item: { name: string }) => item.name === 'eBidding Info') || [];
        if (values.eBidding && values.eBidding === 'yes' && checkexists.length > 0) {
          newwizzard = addbidwizard;
        } else if (values.eBidding && values.eBidding === 'yes' && checkexists.length === 0) {
          newwizzard = AddToAddBidWizard(
            addbidwizard,
            { id: 2, name: 'eBidding Info', status: WizardStatus.Unavailable },
            2,
            values.eBidding === 'yes' ? true : false,
          );
        } else if (values.eBidding && values.eBidding === 'no' && checkexists.length > 0) {
          newwizzard = AddToAddBidWizard(
            addbidwizard,
            { id: 2, name: 'eBidding Info', status: WizardStatus.Unavailable },
            2,
            false,
          );
        }
      }
      let payload: any = {
        addbidwizard: setWizardStatus(newwizzard, 2),
        addbidinfo: responsedata,
        bidId: newbidId,
        duplicatebidmodal: false,
      };
      if (addbidwizardpartial) {
        payload = {
          addbidwizardpartial: false,
          addbidinfo: responsedata,
          bidId: newbidId,
          duplicatebidmodal: false,
          halfWayCancelAllow: true,
        };
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { halfWayCancelAllow: true },
        });
        history.goBack();
      }

      if (!addbidwizardpartial && newbidId) {
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { halfWayCancelAllow: true, commodityAlert: true },
        });
        if (!bidID) history.replace(`/buyers/bids/${newbidId}/creation`);
      }
      yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.SUCCESS, payload });
      if (ID) {
        const response = yield call(bidApi.getBidsSummary, { bidId: ID });
        const results = response.data.result || {};
        yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { results } });
      }

      scrollToTop();
      toastFn('success', 'Saved', addbidtoastID);
      // Tracking API call
      if (ID) {
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: { eventId: userEvent.UpdateBidInfo, parentId: ID, value: finalvalue },
        });
      }
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsetAddBidInitialData() {
  yield takeLatest(actionTypes.SET_ADD_BID_INITIAL_DATA.TRIGGER, setAddBidInitialData);
}

function* setAddBidInitialData(action: { payload: { bidId: number | string } }): any {
  yield put({ type: actionTypes.SET_ADD_BID_INITIAL_DATA.REQUEST, meta: action.payload });
  try {
    const { bidId = '' } = action.payload;
    const bidssummary: BidSummaryState = yield select(state => state.bidssummary) || '';
    const { bidID = '' } = (bidssummary && (bidssummary.results as BidSummaryResult)) || '';
    let results: resultsTypes;
    if (Number(bidID) === Number(bidId)) {
      results = bidssummary.results as resultsTypes;
    } else {
      const response = yield call(bidApi.getBidsSummary, action.payload);
      results = response.data.result || {};
    }
    const auth: AuthState = yield select(state => state.auth) || '';
    yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { results } });
    const isConstructionResponse = yield call(Api.getAddBidIsConstruction, action.payload);
    const isConstruction: boolean =
      (isConstructionResponse.data.result && isConstructionResponse.data.result.icb) || false;

    const memberinfo: MemberInfoState = yield select(state => state.memberinfo);
    const { ci } = memberinfo;
    const stateresponse = yield call(sharedApi.getStatesList, action.payload);
    let stateslist = yield (stateresponse.data && stateresponse.data.result) || [];
    stateslist = stateslist
      .map((items: { name: string; title: string; id: number }) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }))
      .filter((item: { countryId: number }) => item.countryId === ci);
    let { bcs = 'Bid Information' } = results as resultsTypes;
    let { eBidding = '' } = results as resultsTypes;
    const { bidExternalStatusType = BidExternalStatusType.None } = results as resultsTypes;
    const addbid: AddBidState = yield select(state => state.addbid);
    const shared: SharedState = yield select(state => state.shared);
    const { bidtypes, bidwriters, ebidinfo, activeprograms = [], upcomingBidPageFor = '' } = addbid;
    let { fiscalYear } = shared;
    const { selfdeclaredattributes = [] } = shared;
    const accountinfo: accountinfoType = yield select(state => state.accountinfo);
    const { agencydocs } = accountinfo;

    if (agencydocs && agencydocs.requiredDocuments.length === 0) {
      eBidding = 'No';
    }

    let wizard = eBidding === 'No' ? initialaddbidwizard : addbidwizardfull;
    if (eBidding === 'No' && isConstruction) {
      wizard = addbidwizardplan;
    }
    if (eBidding === 'Yes' && isConstruction) {
      wizard = addbidwizardfullplan;
    }
    if (allowForwardNavStatuses.includes(bidExternalStatusType)) {
      bcs = 'Manage Documents';
    }

    if (upcomingBidPageFor) {
      bcs = upcomingBidPageFor;
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { upcomingBidPageFor: '' },
      });
    }

    const currentPage = wizard.find(item => item.name === bcs) as WizardPage;

    let currentPageId = allowForwardNavStatuses.includes(bidExternalStatusType)
      ? currentPage.id
      : currentPage.id + 1;

    if (bcs === 'Manage Documents' || upcomingBidPageFor) {
      currentPageId = currentPage.id;
    }

    if (!currentPageId) {
      currentPageId = 1;
    }
    /* TODO: Clean this up. What is happening here?
    Our goal here should be that we either:
    - get the page with an id and use that
    - fail to get a page with an id and fall back to 1 (or whatever the id of the first page is)
    I can't think of why we should ever need to add +1 or include more than one if statement.
    Leaving functionally untouched.

    We should not be keeping track of
    currentPage.id
    currentPageId
    wizardCurrentPage

    as three potentially different values. There needs to be ONE value that is correct.
    */
    let wizardCurrentPage: number;

    if (currentPage && currentPage.id) {
      wizardCurrentPage = currentPage.id;
    } else {
      wizardCurrentPage = currentPageId;
    }

    if (currentPageId > currentPage.id) {
      wizardCurrentPage = currentPageId;
    }

    const {
      commodities,
      filteredStates = '',
      filteredCities = '',
      filteredDeclarations = '',
      filteredPrograms = '',
    } = results;

    const commodityAlert = commodities.length > 0 ? false : true;

    const state = (filteredStates && filteredStates.split(',')) || [];
    const declaration = (filteredDeclarations && filteredDeclarations.split(',')) || [];
    const programs = (filteredPrograms && filteredPrograms.split(',')) || [];

    const stated =
      stateslist.length > 0 && state.length > 0
        ? stateslist.filter((item: { name: string }) => state.includes(item.name))
        : [];
    const selfdeclaration =
      selfdeclaredattributes.length > 0 && declaration.length > 0
        ? selfdeclaredattributes.filter((item: { value: string }) =>
            declaration.includes(item.value),
          )
        : [];
    const program =
      activeprograms.length > 0 && programs.length > 0
        ? activeprograms.filter((item: { value: { toString: () => any } }) =>
            programs.includes(item.value.toString()),
          )
        : [];

    const resFilter = {
      filterState: stated || [],
      filterCity: (filteredCities && filteredCities.split(',')) || [],
      filterDeclaration: selfdeclaration || [],
      filterPrograms: program || [],
    };

    const bidYear =
      fiscalYear.find((item: { value: string }) => item.value === results.fiscalYear) || '';

    if (!bidYear) {
      fiscalYear = [
        ...fiscalYear,
        { label: results.fiscalYear, value: Number(results.fiscalYear) },
      ].sort((a, b) => a.value - b.value);
      yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { fiscalYear } });
    }

    const broadcastDatetzfn =
      deprecatedGetDateTimeZoneConverted(results.broadcastDate, results.tzfn) || '';
    const dueDatetzfn = deprecatedGetDateTimeZoneConverted(results.dueDate, results.tzfn) || '';
    const addbidinfo = {
      broadcastDate: moment(broadcastDatetzfn),
      bidName: results.bidName,
      bidBond: results.bidBond,
      displayBudgetToSuppliers: results.displayBudgetToSuppliers,
      projectEstimatedBudget:
        results.projectEstimatedBudget === 0 ? null : results.projectEstimatedBudget,
      scopeOfWork: results.scopeOfWork === 'None' ? '' : results.scopeOfWork,
      openedText: results.bidStatusText,
      dueDate: moment(dueDatetzfn),
      dueTime: getTimeFromDate(dueDatetzfn),
      dueTimeZone: getZoneFromDate(dueDatetzfn),
      broadcastDateTime: getTimeFromDate(broadcastDatetzfn),
      broadcastDateTimeZone: getZoneFromDate(broadcastDatetzfn),
      bidNumber: results.bidNumber,
      bidstatus: { value: results.bidExternalStatusType, label: results.bidExternalStatus },
      bidYear: fiscalYear.find((item: { value: string }) => item.value === results.fiscalYear),
      memberBidType: (bidtypes as BidType[]).find(item => item.value === results.bidType.trimEnd()),
      bidWriterAccountId: (bidwriters as BidWriter[]).find(
        item => Number(item.value) === Number(results.bidWriterAccountID),
      ),
      eBidding: eBidding.toLowerCase(),
    };

    const prebidconference = {
      pbcMemo:
        results.pbcMemo === 'No Pre-Bid Conference Data Found'
          ? ''
          : results.pbcMemo.replace('MANDATORY ', ''),
      preBidMandatory: results.pbcMemo.includes('MANDATORY') ? true : false,
    };

    const blueprintStatusType = results.blueprintStatusType || 'EL';
    /* if (results.blueprintStatusType === 'NA') {
      blueprintStatusType = 'EL';
    } */
    const plandistributedby =
      results.distributedBy === 'DemandStar'
        ? 'DM'
        : results.distributedBy === 'Agency'
        ? 'AG'
        : 'TP';
    const plandetails = {
      planname: plandistributedby === 'TP' ? results.distributedBy : '',
      planemail: results.fulfillmentEmail || '',
      plancost: results.cost ? Number(results.cost).toFixed(3) : '',
      plannotes: results.distributionNotes,
    };

    let editable = bidFullEditableStatus.includes(bidExternalStatusType)
      ? 'all'
      : bidSemiEditableStatus.includes(bidExternalStatusType)
      ? 'semi'
      : bidActiveEditableStatus.includes(bidExternalStatusType)
      ? 'active'
      : null;

    if (auth?.opi) {
      editable = 'all';
    }

    const payload = {
      addbidwizard: setWizardStatus(wizard, currentPageId),
      wizardcurrentpage: wizardCurrentPage,
      // FIXME: let's not have wizardCurrentPage and currentPageId as two separate variables.
      // seems like we're asking for trouble.
      addbidinfo,
      editbiddata: true,
      prebidconference,
      ebidinfo: { ...ebidinfo, viewsupplierinfortabulation: results.tse },
      isConstruction,
      plandetails,
      blueprintStatusType,
      broadcastFilter: resFilter,
      stateslist,
      plandistributedby,
      commodities,
      editable,
      loadNewCommodities: true,
      commodityAlert: commodityAlert,
    };
    yield put({ type: actionTypes.SET_ADD_BID_INITIAL_DATA.SUCCESS, payload });
  } catch (error) {
    toastFn('error', 'Network Failure', addbidtoastID);
    yield put({
      type: actionTypes.SET_ADD_BID_INITIAL_DATA.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidResponseItems() {
  yield takeLatest(actionTypes.GET_ADD_BID_RESPONSE_ITEMS.TRIGGER, getAddBidResponseItems);
}

function* getAddBidResponseItems(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_RESPONSE_ITEMS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getAddBidResponseItems, action.payload);
    if (response.data.result) {
      yield put({
        type: actionTypes.GET_ADD_BID_RESPONSE_ITEMS.SUCCESS,
        payload: {
          responseItem: response.data.result.map((item: { BidResponseItemId: number }) => ({
            ...item,
            responsevalue: item.BidResponseItemId > 0 ? true : false,
          })),
        },
      });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_RESPONSE_ITEMS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_RESPONSE_ITEMS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidPublications() {
  yield takeLatest(actionTypes.GET_ADD_BID_PUBLICATIONS.TRIGGER, getAddBidPublications);
}

function* getAddBidPublications(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_PUBLICATIONS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidPublications, action.payload);
    const memberresponse = yield call(Api.getAddBidMemberPublications, action.payload);
    if (response.data.result || memberresponse.data.response) {
      const bidpublications = response.data.result.map(
        (item: {
          publication: string;
          publishDate: Date | string;
          publishDate2: Date | string;
        }) => ({
          ...item,
          title: item.publication && item.publication.trim(),
          date1: item.publishDate,
          date2: item.publishDate2,
        }),
      );
      const memberbidpublications = memberresponse.data.result.map(
        (item: { publication: string }) => ({
          ...item,
          title: item.publication && item.publication.trim(),
          date1: '',
          date2: '',
        }),
      );
      yield put({
        type: actionTypes.GET_ADD_BID_PUBLICATIONS.SUCCESS,
        payload: { bidpublications, memberbidpublications },
      });
      const savedlist = bidpublications.map((item: { title: string }) => item.title);
      const memberpub = memberbidpublications.filter(
        (item: { title: string }) => !savedlist.includes(item.title),
      );
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { publicationslist: [...Array.from(new Set([...bidpublications, ...memberpub]))] },
      });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_PUBLICATIONS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_PUBLICATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddBidUpdateBidPublications() {
  yield takeLatest(
    actionTypes.GET_ADD_BID_UPDATE_BID_PUBLICATIONS.TRIGGER,
    submitAddBidUpdateBidPublications,
  );
}

function* submitAddBidUpdateBidPublications(action: { payload: { bidId: string | number } }): any {
  yield put({
    type: actionTypes.GET_ADD_BID_UPDATE_BID_PUBLICATIONS.REQUEST,
    meta: action.payload,
  });
  try {
    const { bidId } = action.payload;
    const addbid = yield select(state => state.addbid);
    const {
      publicationslist,
      addbidwizard,
      addbidwizardpartial = false,
      wizardcurrentpage,
    } = addbid;
    const currentwizard =
      addbidwizard.find((item: { status: string }) => item.status === 'current') || {};
    const publications = publicationslist
      .filter((item: { date1: Date | string; date2: Date | string }) => item.date1 || item.date2)
      .map((items: { title: string; date1: string; date2: string }) => {
        const newitem: any = {};
        newitem.publicationName = items.title && items.title.trim();
        if (items.date1) {
          newitem.date1 = items.date1;
        }
        if (items.date2) {
          newitem.date2 = items.date2;
        }
        return newitem;
      });
    const finaldata = { bidId, publications };
    const response = yield call(Api.submitAddBidUpdateBidPublications, finaldata);
    if (response.data.result) {
      let payload;
      if (addbidwizardpartial) {
        payload = { addbidwizardpartial: false, halfWayCancelAllow: true };
        history.goBack();
      } else {
        payload = {
          addbidwizard: setWizardStatus(addbidwizard, currentwizard.id + 1),
          wizardcurrentpage:
            wizardcurrentpage > currentwizard.id ? wizardcurrentpage : currentwizard.id + 1,
        };
      }
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ADD_BID_UPDATE_BID_PUBLICATIONS.SUCCESS });
      scrollToTop();
      toastFn('success', 'Saved', addbidtoastID);
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_UPDATE_BID_PUBLICATIONS.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_UPDATE_BID_PUBLICATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchgetAddBidLegal() {
  yield takeLatest(actionTypes.GET_ADD_BID_LEGAL.TRIGGER, getAddBidLegal);
}

function* getAddBidLegal(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_LEGAL.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsLegalAd, action.payload);
    let data = response.data.result;
    if (
      data &&
      data.legalAdIntro === null &&
      data.legalAdClosing === null &&
      data.legalAdCostInfo === null &&
      data.legalAdDueDateTime === null
    ) {
      const response = yield call(Api.getBidMemberLegal, action.payload);
      data = response.data.result;
    }
    if (data) {
      const payload = {
        legalAdAdditional: data.legalAdAdditional ? data.legalAdAdditional.trim() : '',
        legalAdClosing: data.legalAdClosing ? data.legalAdClosing.trim() : '',
        legalAdCostInfo: data.legalAdCostInfo ? data.legalAdCostInfo.trim() : '',
        legalAdDueDateTime: data.legalAdDueDateTime ? data.legalAdDueDateTime.trim() : '',
        legalAdIntro: data.legalAdIntro ? data.legalAdIntro.trim() : '',
      };
      console.log('payload', payload);
      yield put({ type: actionTypes.GET_ADD_BID_LEGAL.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_LEGAL.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_LEGAL.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidActivePrograms() {
  yield takeLatest(actionTypes.GET_ADD_BID_ACTIVE_PROGRAMS.TRIGGER, getAddBidActivePrograms);
}

function* getAddBidActivePrograms(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_ACTIVE_PROGRAMS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidActivePrograms, action.payload);
    const data = response.data.result || [];
    if (data) {
      yield put({
        type: actionTypes.GET_ADD_BID_ACTIVE_PROGRAMS.SUCCESS,
        payload: data.map((item: { programName: string; programId: string | number }) => ({
          ...item,
          label: item.programName.trim(),
          value: item.programId,
        })),
      });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_ACTIVE_PROGRAMS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_ACTIVE_PROGRAMS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidBroadcastListId() {
  yield takeLatest(actionTypes.GET_ADD_BID_BROADCAST_LIST_ID.TRIGGER, getAddBidBroadcastListId);
}

function* getAddBidBroadcastListId(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_BROADCAST_LIST_ID.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidBroadcastListId, action.payload);
    const data = response.data.result || [];
    if (data) {
      yield put({
        type: actionTypes.GET_ADD_BID_BROADCAST_LIST_ID.SUCCESS,
        payload: data.broadcastListId,
      });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_BROADCAST_LIST_ID.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_BROADCAST_LIST_ID.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddBidUpdateLegal() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_UPDATE_LEGAL.TRIGGER, submitAddBidUpdateLegal);
}

function* submitAddBidUpdateLegal(action: { payload: { bidId: string | number; data: any } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_LEGAL.REQUEST, meta: action.payload });
  try {
    const { bidId, data: values = {} } = action.payload;
    const addbid = yield select(state => state.addbid);
    const { addbidwizard, addbidwizardpartial = false, legalads = {} } = addbid;
    const currentwizard =
      addbidwizard.find((item: { status: string }) => item.status === 'current') || {};
    const legalDiff = Object.keys(legalads).reduce((diff: any, key: string) => {
      if (values[key] === legalads[key]) return diff;
      return {
        ...diff,
        [key]: legalads[key],
      };
    }, {});
    const response = yield call(Api.submitAddBidUpdateLegal, { ...values, bidId });
    const data = response.data.result;
    if (data) {
      let payload;
      if (addbidwizardpartial) {
        payload = { addbidwizardpartial: false, halfWayCancelAllow: true };
        history.goBack();
      } else {
        const addbid = yield select(state => state.addbid);
        const { wizardcurrentpage } = addbid;
        payload = {
          addbidwizard: setWizardStatus(addbidwizard, currentwizard.id + 1),
          bidId: data.bidId,
          legalads: values,
          wizardcurrentpage:
            wizardcurrentpage > currentwizard.id ? wizardcurrentpage : currentwizard.id + 1,
        };
      }
      scrollToTop();
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_LEGAL.SUCCESS });
      const payloadEvent = {
        eventId: userEvent.UpdateBidLegalAd,
        parentId: bidId,
        value: legalDiff,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      toastFn('success', 'Saved', addbidtoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_LEGAL.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_UPDATE_LEGAL.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidUpdateMemo() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_UPDATE_MEMO.TRIGGER, submitAddBidUpdateMemo);
}

function* submitAddBidUpdateMemo(action: {
  payload: { bidId: string; isNotify: boolean; data: any };
}): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_MEMO.REQUEST, meta: action.payload });
  try {
    const { bidId, isNotify, data: values = {} } = action.payload;
    const addbid = yield select(state => state.addbid);
    const {
      addbidwizard,
      addbidwizardpartial = false,
      halfWayCancel = false,
      prebidconference,
    } = addbid;
    const currentwizard =
      addbidwizard.find((item: { status: string }) => item.status === 'current') || {};
    const preBidMemoDiff = Object.keys(prebidconference).reduce((diff: any, key: string) => {
      if (values[key] === prebidconference[key]) return diff;
      return {
        ...diff,
        [key]: prebidconference[key],
      };
    }, {});
    const pbcMemo =
      values.preBidMandatory === true
        ? `MANDATORY ${values.pbcMemo.trim()}`
        : values.pbcMemo.trim();
    const fields = {
      ...values,
      pbcMemo: pbcMemo,
    };
    const response = yield call(Api.submitAddBidUpdateMemo, { ...fields, bidId, isNotify });
    const data = response.data.result;

    if (data) {
      let payload;
      if (addbidwizardpartial) {
        payload = { addbidwizardpartial: false, halfWayCancelAllow: true };
        history.goBack();
      } else {
        const addbid = yield select(state => state.addbid);
        const { wizardcurrentpage } = addbid;
        payload = {
          addbidwizard: setWizardStatus(addbidwizard, currentwizard.id + 1),
          bidId: data.bidId,
          prebidconference: values,
          wizardcurrentpage:
            wizardcurrentpage > currentwizard.id ? wizardcurrentpage : currentwizard.id + 1,
        };
      }
      scrollToTop();
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_MEMO.SUCCESS });
      const payloadEvent = {
        eventId: userEvent.UpdatePBC,
        parentId: bidId,
        value: preBidMemoDiff,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      toastFn('success', 'Saved', addbidtoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_MEMO.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_UPDATE_MEMO.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchUpdateAddBidEbidInfo() {
  yield takeLatest(actionTypes.UPDATE_ADD_BID_EBID_INFO.TRIGGER, updateAddBidEbidInfo);
}

function* updateAddBidEbidInfo(action: {
  payload: { bidId: number | string; currentwizard: { id: number }; data: any };
}): any {
  yield put({ type: actionTypes.UPDATE_ADD_BID_EBID_INFO.REQUEST, meta: action.payload });
  try {
    const addbid = yield select(state => state.addbid);
    const { addbidwizard = [], addbidwizardpartial = false, wizardcurrentpage } = addbid || {};
    const values = { ...action.payload.data };
    const responseItems =
      values.addbidresponseitems
        .filter((item: { responsevalue: boolean }) => item.responsevalue === true)
        .map((item: { MemberResponseItemId: number }) => item.MemberResponseItemId) || [];

    const fields = {
      bidId: action.payload.bidId,
      responseItemList: responseItems.join(','),
      tabSheetOption: values.viewsupplierinfortabulation,
    };

    const response = yield call(Api.UpdateEbidInfo, fields);

    if (response.data.result) {
      let payload: SetAddBidDetailsPayload = {
        addbidwizard: setWizardStatus(addbidwizard, action.payload.currentwizard.id + 1),
        ebidinfo: values,
        wizardcurrentpage:
          wizardcurrentpage > action.payload.currentwizard.id
            ? wizardcurrentpage
            : action.payload.currentwizard.id + 1,
      };
      if (addbidwizardpartial) {
        payload = { addbidwizardpartial: false, halfWayCancelAllow: true };
        history.goBack();
      }

      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.UPDATE_ADD_BID_EBID_INFO.SUCCESS });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_ADD_BID_EBID_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchSubmitAddBidGetPreBuiltList() {
  yield takeLatest(
    actionTypes.SUBMIT_ADD_BID_GET_PREBUILT_LIST.TRIGGER,
    SubmitAddBidGetPreBuiltList,
  );
}

function* SubmitAddBidGetPreBuiltList(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_GET_PREBUILT_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getPreBuiltList, action.payload);
    let payload = response.data.result;
    payload = SortingFn(payload, sortDirections.ASC, 'name');
    yield put({ type: actionTypes.SUBMIT_ADD_BID_GET_PREBUILT_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_GET_PREBUILT_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchSubmitAddBidGetPreBuiltListCount() {
  yield takeLatest(actionTypes.GET_LIST_COUNT_DETAILS.TRIGGER, SubmitAddBidGetPreBuiltListCount);
}

function* SubmitAddBidGetPreBuiltListCount(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_LIST_COUNT_DETAILS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getPreBuiltListCount, action.payload);
    const addbid = yield select(state => state && state.addbid);
    const { preBuiltBroadCastList = [], filteredPreBuiltList = [] } = addbid || {};

    const payload = response.data.result;

    const finalMergedData = preBuiltBroadCastList.map((itm: { listId: number }) => {
      return {
        ...itm,
        ...payload.find((item: { listId: number }) => item.listId === itm.listId && item),
      };
    });

    const finalFilteredMergedData = filteredPreBuiltList.map((itm: { listId: number }) => {
      return {
        ...itm,
        ...payload.find((item: { listId: number }) => item.listId === itm.listId && item),
      };
    });

    yield put({
      type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
      payload: {
        preBuiltBroadCastList: finalMergedData,
        filteredPreBuiltList: finalFilteredMergedData,
      },
    });

    yield put({ type: actionTypes.GET_LIST_COUNT_DETAILS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIST_COUNT_DETAILS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidAddList() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_ADD_LIST.TRIGGER, submitAddBidAddList);
}

function* submitAddBidAddList(action: {
  payload: { id: string; replaceIfExists?: false | undefined; type: string };
}): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_ADD_LIST.REQUEST, meta: action.payload });
  try {
    const { id, replaceIfExists = false, type } = action.payload;
    const addbid = yield select(state => state.addbid);
    const commoditycodes = yield select(state => state.commoditycodes);
    const { commoditieslist = [] } = commoditycodes || {};
    const generatenewlist: Array<number | string> = getOptimizedCommodityCodes(commoditieslist);
    // eslint-disable-next-line prefer-const
    let { broadcastFilter = {}, broadcastListId = '' } = addbid;
    const {
      filterState = [],
      filterCity = [],
      filterDeclaration = [],
      filterPrograms = [],
    } = broadcastFilter;
    const { newlistname = '' } = addbid;
    if (!broadcastListId && id && type) {
      const responsebroadcast = yield call(Api.getAddBidBroadcastListId, { id, type });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: responsebroadcast.broadcastListId,
      });
      broadcastListId = responsebroadcast.broadcastListId;
    }

    const finaldata = {
      id,
      type: type,
      name: newlistname.trim(),
      broadcastListId,
      rowCount: 1000,
      replaceIfExists,
      listType: 'BidWire',
      cityList: filterCity && filterCity.length > 0 ? filterCity.join() : '',
      commodityIdList: [...Array.from(new Set([...generatenewlist]))].join(','),
      declaredAttributeList:
        filterDeclaration && filterDeclaration.length > 0
          ? filterDeclaration.map((item: { attributeType: string }) => item.attributeType).join()
          : '',
      programIdList:
        filterPrograms && filterPrograms.length > 0
          ? filterPrograms.map((item: { programId: string }) => item.programId).join()
          : '',
      stateList:
        filterState && filterState.length > 0
          ? filterState.map((item: { abbr: string }) => item.abbr).join()
          : '',
    };

    const response = yield call(Api.submitAddBidAddList, finaldata);
    const data = response.data.result;
    if (data && data.status) {
      const updatedCommoditiesList =
        commoditieslist.map((item: { isSelected: boolean; isExisting: boolean }) => {
          if (item.isSelected) {
            item.isExisting = true;
          } else {
            item.isExisting = false;
          }
          return item;
        }) || [];

      let payload;
      if (data.listId === 0) {
        payload = {
          savelistExistmodal: true,
        };
        toastFn('error', 'Name already exists', addbidtoastID);
      } else {
        delay(200);
        const supplierresponse = yield call(Api.getAddBidBroadcastMembers, { id, type });
        payload = {
          savelistmodal: false,
          newlistname: '',
          savelistExistmodal: false,
          broadcastinglist: supplierresponse.data.result || [],
          newlistmodal: false,
          commoditieslist: updatedCommoditiesList,
        };
      }
      yield put({ type: actionTypes.SUBMIT_ADD_BID_ADD_LIST.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_ADD_LIST.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_ADD_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidDeleteList() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_DELETE_LIST.TRIGGER, submitAddBidDeleteList);
}

function* submitAddBidDeleteList(action: { payload: { listId: any[] } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_DELETE_LIST.REQUEST, meta: action.payload });
  try {
    const { listId } = action.payload;
    const addbid = yield select(state => state.addbid);
    const { preBuiltBroadCastList = [], filteredPreBuiltList = [] } = addbid;
    const response = yield call(Api.submitAddBidDeleteList, action.payload);
    const data = response.data.result || {};
    if (data && data.status) {
      yield put({
        type: actionTypes.SUBMIT_ADD_BID_DELETE_LIST.SUCCESS,
        payload: {
          halfWayCancelAllow: true,
          preBuiltBroadCastList: preBuiltBroadCastList.filter(
            (item: { listId: number }) => Number(item.listId) !== Number(listId),
          ),
          filteredPreBuiltList: filteredPreBuiltList.filter(
            (item: { listId: number }) => Number(item.listId) !== Number(listId),
          ),
        },
      });
      const payloadEvent = {
        eventId: userEvent.DeleteSavedList,
        parentId: listId,
        value: false,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      toastFn('success', 'Deleted', addbidtoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DELETE_LIST.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_DELETE_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchSubmitAddBidUsePreBuiltList() {
  yield takeLatest(
    actionTypes.SUBMIT_ADD_BID_USE_PREBUILT_LIST.TRIGGER,
    submitAddBidUsePreBuiltList,
  );
}

function* submitAddBidUsePreBuiltList(action: {
  payload: { id: string; listIds: any[]; type: string };
}): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_USE_PREBUILT_LIST.REQUEST, meta: action.payload });

  try {
    const { id, listIds, type } = action.payload;
    const payload = { listIds: listIds.join(','), id, type };
    const response = yield call(Api.submitAddBidUsePrebuiltList, payload);
    const data = response.data.result || {};
    if (data && data.status) {
      const supplierresponse = yield call(Api.getAddBidBroadcastMembers, { id, type });

      if (type === 'Quote') {
        const summaryResponse = yield call(quoteApi.getQuoteSummary, { quoteId: id });
        const results = { summary: summaryResponse.data.result };
        yield put({ type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER, payload: results });
      } else {
        const summaryResponse = yield call(bidApi.getBidsSummary, { bidId: id });
        const results: any = { results: summaryResponse.data.result };
        yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: results });
        const response = yield call(bidApi.getBidCommodityByType, { bidId: id, type: 'bid' });
        yield put({
          type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
          payload: { bidCommodity: response.data.result },
        });
      }

      yield put({
        type: actionTypes.SET_ADD_BID_COMMODITY_LOAD.TRIGGER,
        payload: { loadNewCommodities: true },
      });

      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { commodityAlert: false },
      });
      yield put({
        type: actionTypes.SET_COMMODITY_CODES.TRIGGER,
        payload: {
          commoditieslist: [],
          searchedCommodityCodes: [],
          commodityCodes: '',
          commodityGroup: '',
          commodityCategory: '',
          searchText: '',
        },
      });
      yield put({
        type: actionTypes.SUBMIT_ADD_BID_USE_PREBUILT_LIST.SUCCESS,
        payload: supplierresponse.data.result || [],
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_USE_PREBUILT_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidSearchVendors() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_SEARCH_VENDORS.TRIGGER, submitAddBidSearchVendors);
}

function* submitAddBidSearchVendors(action: {
  payload: { id: string; type: string; reBroadcast: boolean };
}): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_SEARCH_VENDORS.REQUEST, meta: action.payload });
  try {
    const { id, type, reBroadcast } = action.payload;
    const addbid = yield select(state => state.addbid);
    const { broadcastFilter = {}, broadcastinglist = [] } = addbid;
    const {
      filterState = [],
      filterCity = [],
      filterDeclaration = [],
      filterPrograms = [],
    } = broadcastFilter;
    const commoditycodes = yield select(state => state.commoditycodes);
    const { commoditieslist = [] } = commoditycodes || {};
    const generatenewlist: Array<number | string> = getOptimizedCommodityCodes(commoditieslist);

    const finaldata = {
      id,
      rowCount: 1000,
      listType: type,
      cityList: filterCity && filterCity.length > 0 ? filterCity.join() : '',
      commodityIdList: [...Array.from(new Set([...generatenewlist]))].join(),
      declaredAttributeList:
        filterDeclaration && filterDeclaration.length > 0
          ? filterDeclaration
              .map(
                (item: { attributeType: string; value: string }) =>
                  item.attributeType || item.value,
              )
              .join()
          : '',
      programIdList:
        filterPrograms && filterPrograms.length > 0
          ? filterPrograms.map((item: { programId: string }) => item.programId).join()
          : '',
      stateList:
        filterState && filterState.length > 0
          ? filterState.map((item: { abbr: string }) => item.abbr).join()
          : '',
    };

    const response = yield call(Api.submitAddBidSearchVendors, finaldata);
    let data = response.data.result.map((item: any) => {
      return {
        ...item,
        broadcasted: true,
      };
    });
    if (data) {
      if (reBroadcast) {
        const updatedList = data
          .map((item: any) => {
            const existingData = broadcastinglist.filter((Bitem: any) => Bitem.mi === item.mi);
            return {
              ...item,
              broadcasted: existingData.length > 0 ? true : false,
            };
          })
          .sort((data1: any, data2: any) => {
            return data1.broadcasted - data2.broadcasted;
          });
        data = updatedList;
      }

      const payload = { broadcastListssearch: data, broadcastListssearchShow: true };

      yield put({ type: actionTypes.SUBMIT_ADD_BID_SEARCH_VENDORS.SUCCESS, payload });
      yield put({
        type: actionTypes.SET_COMMODITY_CODES.TRIGGER,
        payload: { searchedCommodityCodes: [], searchText: '' },
      });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { commodityAlert: false },
      });
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_SEARCH_VENDORS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_SEARCH_VENDORS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidSupplierCount() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_SUPPLIER_COUNT.TRIGGER, submitAddBidSupplierCount);
}

function* submitAddBidSupplierCount(action: { payload: { id: string; type: string } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_SUPPLIER_COUNT.REQUEST, meta: action.payload });
  try {
    const { id, type } = action.payload;
    const commoditycodes = yield select(state => state.commoditycodes);
    const { commoditieslist = [] } = commoditycodes || {};
    const generatenewlist: Array<number | string> = getOptimizedCommodityCodes(commoditieslist);

    const addbid = yield select(state => state.addbid);
    const { broadcastFilter = {} } = addbid;
    const {
      filterState = [],
      filterCity = [],
      filterDeclaration = [],
      filterPrograms = [],
    } = broadcastFilter;
    const finaldata = {
      id,
      rowCount: 1000,
      listType: type,
      cityList: filterCity && filterCity.length > 0 ? filterCity.join() : '',
      commodityIdList: [...Array.from(new Set([...generatenewlist]))].join(','),
      declaredAttributeList:
        filterDeclaration && filterDeclaration.length > 0
          ? filterDeclaration.map((item: { attributeType: string }) => item.attributeType).join()
          : '',
      programIdList:
        filterPrograms && filterPrograms.length > 0
          ? filterPrograms.map((item: { programId: string }) => item.programId).join()
          : '',
      stateList:
        filterState && filterState.length > 0
          ? filterState.map((item: { abbr: string }) => item.abbr).join()
          : '',
    };

    const response = yield call(Api.submitAddBidSupplierCount, finaldata);
    const data = (response.data.result && response.data.result.total) || '';
    if (data) {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_SUPPLIER_COUNT.SUCCESS, payload: data });
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_SUPPLIER_COUNT.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_SUPPLIER_COUNT.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidIsFilterStatus() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_IS_FILTER_STATUS.TRIGGER, submitAddBidIsFilterStatus);
}

function* submitAddBidIsFilterStatus(action: { payload: { id: string; type: string } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_IS_FILTER_STATUS.REQUEST, meta: action.payload });
  try {
    const { id, type } = action.payload;
    const addbid = yield select(state => state.addbid);
    const commoditycodes = yield select(state => state.commoditycodes);
    const { commoditieslist = [] } = commoditycodes || {};
    const generatenewlist: Array<number | string> = getOptimizedCommodityCodes(commoditieslist);
    // eslint-disable-next-line prefer-const
    let { broadcastFilter = {}, broadcastListId = '', broadcastinglist = [] } = addbid;
    const {
      filterState = [],
      filterCity = [],
      filterDeclaration = [],
      filterPrograms = [],
    } = broadcastFilter;
    if (!broadcastListId && id && type) {
      const responsebroadcast = yield call(Api.getAddBidBroadcastListId, { id, type });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: responsebroadcast.broadcastListId,
      });
      broadcastListId = responsebroadcast.broadcastListId;
    }
    const finalsdata = {
      id,
      broadcastListId,
      rowCount: 1000,
      listType: type,
      cityList: filterCity && filterCity.length > 0 ? filterCity.join() : '',
      commodityIdList: [...Array.from(new Set([...generatenewlist]))].join(','),
      declaredAttributeList:
        filterDeclaration && filterDeclaration.length > 0
          ? filterDeclaration.map((item: { attributeType: string }) => item.attributeType).join()
          : '',
      programIdList:
        filterPrograms && filterPrograms.length > 0
          ? filterPrograms.map((item: { programId: string }) => item.programId).join()
          : '',
      stateList:
        filterState && filterState.length > 0
          ? filterState.map((item: { abbr: string }) => item.abbr).join()
          : '',
    };

    const response = yield call(Api.submitAddBidIsFilterStatus, finalsdata);
    const data = (response.data.result && response.data.result) || '';
    if (data && data.status) {
      const updatedCommoditiesList =
        commoditieslist.map((item: { isSelected: boolean; isExisting: boolean }) => {
          if (item.isSelected) {
            item.isExisting = true;
          } else {
            item.isExisting = false;
          }
          return item;
        }) || [];

      yield delay(200);
      const supplierresponse = yield call(Api.getAddBidBroadcastMembers, {
        id,
        type: type === 'BidWire' ? 'bid' : 'quote',
      });
      const payload = {
        broadcastinglist: supplierresponse.data.result || [],
        newlistmodal: false,
        commoditieslist: updatedCommoditiesList,
        halfWayCancel: true,
      };
      yield put({ type: actionTypes.SUBMIT_ADD_BID_IS_FILTER_STATUS.SUCCESS, payload });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { commodityAlert: false },
      });
      if (type === 'BidWire') {
        const summaryResponse = yield call(bidApi.getBidsSummary, { bidId: id });
        const results = summaryResponse.data.result;
        yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { results } });
      }
      toastFn('success', 'Success', addbidtoastID);
      // Tracking API call
      if (id && type && broadcastListId && broadcastinglist.length > 0) {
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: {
            eventId:
              type === 'Bid'
                ? userEvent.UpdateBidBroadcastInfo
                : userEvent.UpdateQuoteBroadcastInfo,
            parentId: id,
            value: { name: 'Build Broadcast List', status: 'modified' },
          },
        });
      }
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_IS_FILTER_STATUS.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_IS_FILTER_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchgetAddBidDocTypes() {
  yield takeLatest(actionTypes.GET_ADD_BID_DOC_TYPES.TRIGGER, getAddBidDocTypes);
}

function* getAddBidDocTypes(action: { payload: { bidId: number } }): any {
  yield put({ type: actionTypes.GET_ADD_BID_DOC_TYPES.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getAddBidDocTypes, action.payload);
    const payload = response.data.result;
    if (payload) {
      yield put({ type: actionTypes.GET_ADD_BID_DOC_TYPES.SUCCESS, payload });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_DOC_TYPES.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchgetAddBidIsConstruction() {
  yield takeLatest(actionTypes.GET_ADD_BID_IS_CONSTRUCTION.TRIGGER, getAddBidIsConstruction);
}

function* getAddBidIsConstruction(action: {
  payload: { broadcastlistnextbtn: boolean; halfWayCancelAllow: boolean };
}): any {
  yield put({ type: actionTypes.GET_ADD_BID_IS_CONSTRUCTION.REQUEST, meta: action.payload });
  try {
    const { broadcastlistnextbtn = false, halfWayCancelAllow = false } = action.payload;
    if (halfWayCancelAllow) {
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { halfWayCancelAllow: true },
      });
      yield put({ type: actionTypes.GET_ADD_BID_IS_CONSTRUCTION.FAILURE });
      return history.goBack();
    }
    const response = yield call(Api.getAddBidIsConstruction, action.payload);
    const payload = response.data.result;
    if (payload) {
      const addbid: AddBidState = yield select(state => state.addbid);
      const { addbidwizard, addbidwizardpartial = false, wizardcurrentpage } = addbid;
      const isConstruction = payload.icb || false;

      if (addbidwizardpartial) {
        const data = { addbidwizardpartial: false, halfWayCancelAllow: true };
        yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload: data });
        yield put({ type: actionTypes.GET_ADD_BID_IS_CONSTRUCTION.SUCCESS });
        history.goBack();
      } else {
        if (broadcastlistnextbtn) {
          const currentwizard: WizardPage = (addbidwizard.find(item => item.status === 'current') ||
            {}) as WizardPage;
          let wizardvalue: any = setWizardStatus(addbidwizard, currentwizard.id + 1);
          const containsplan =
            addbidwizard.filter(
              (item: { name: string }) => item.name === 'Plan/Blueprint Information',
            ) || [];
          if (isConstruction && containsplan.length === 0) {
            wizardvalue = AddToAddBidWizard(
              addbidwizard,
              {
                id: currentwizard.id + 2,
                name: 'Plan/Blueprint Information',
                status: WizardStatus.Unavailable,
              },
              currentwizard.id + 2,
              true,
            );
          } else if (!isConstruction && containsplan.length > 0) {
            wizardvalue = AddToAddBidWizard(
              addbidwizard,
              {
                id: currentwizard.id + 2,
                name: 'Plan/Blueprint Information',
                status: WizardStatus.Unavailable,
              },
              currentwizard.id + 2,
              false,
            );
          }

          yield put({
            type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
            payload: {
              addbidwizard: setWizardStatus(wizardvalue, currentwizard.id + 1),
              wizardcurrentpage:
                wizardcurrentpage > currentwizard.id ? wizardcurrentpage : currentwizard.id + 1,
            },
          });
        }
        yield put({
          type: actionTypes.GET_ADD_BID_IS_CONSTRUCTION.SUCCESS,
          payload: isConstruction,
        });
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_IS_CONSTRUCTION.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchgetAddBidConversionTypes() {
  yield takeLatest(actionTypes.GET_ADD_BID_CONVERSION_TYPES.TRIGGER, getAddBidConversionTypes);
}

function* getAddBidConversionTypes(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_CONVERSION_TYPES.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getAddBidConversionTypes, action.payload);
    const payload = response.data.result;
    if (payload) {
      yield put({ type: actionTypes.GET_ADD_BID_CONVERSION_TYPES.SUCCESS, payload: payload });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_CONVERSION_TYPES.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidUpdatePlan() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_UPDATE_PLAN.TRIGGER, submitAddBidUpdatePlan);
}

function* submitAddBidUpdatePlan(action: { payload: { bidId: number } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_PLAN.REQUEST, meta: action.payload });

  try {
    const { bidId } = action.payload;
    const addbid = yield select(state => state.addbid);
    const {
      blueprintStatusType,
      plandistributedby,
      plandetails,
      addbidwizardpartial = false,
      addbidwizard,
      wizardcurrentpage,
    } = addbid;
    const { planname = '', planemail = '', plancost = '', plannotes = '' } = plandetails;
    const currentwizard =
      addbidwizard.find((item: { status: string }) => item.status === 'current') || {};
    const finaldata = {
      bidId,
      blueprintStatus: blueprintStatusType,
      documentHandler: plandistributedby,
      instructions: plannotes,
      charge: plancost.replace('$', ''),
      thirdPartyName: planname,
      thirdPartyEmail: planemail,
    };
    const response = yield call(Api.submitAddBidUpdatePlan, finaldata);
    const payload = response.data.result;
    if (payload) {
      let payload;
      if (addbidwizardpartial) {
        payload = { addbidwizardpartial: false, halfWayCancelAllow: true };
        history.goBack();
      } else {
        payload = {
          addbidwizard: setWizardStatus(addbidwizard, currentwizard.id + 1),
          wizardcurrentpage:
            wizardcurrentpage > currentwizard.id ? wizardcurrentpage : currentwizard.id + 1,
        };
      }
      yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_PLAN.SUCCESS, payload: payload });
      toastFn('success', 'Saved', addbidtoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_UPDATE_PLAN.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_UPDATE_PLAN.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidDocumentUpload() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPLOAD.TRIGGER, submitAddBidDocumentUpload);
}

type UploadingDoc = BidDocument & {
  bidId: number;
};

// function* submitAddBidDocumentUpload(action: { payload: { bidId: number } }): any {
// TODO: refactor without redux
function* submitAddBidDocumentUpload(action: {
  payload: { bidId: number; uploadPath?: string };
}): any {
  yield put({
    type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPLOAD.REQUEST,
    meta: action.payload,
  });

  try {
    yield put({
      type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
      payload: {
        upload_progress: true,
      },
    });
    // const { bidId } = action.payload;
    const { bidId, uploadPath } = action.payload;
    const addbid: AddBidState = yield select(state => state.addbid);
    const { documentslist } = addbid;
    const responses = [];
    const uploadingDocs: UploadingDoc[] = documentslist
      .map(doc => ({ ...doc, bidId }))
      .filter(doc => doc.bidDocId === '');

    if (uploadingDocs.length > 0) {
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: {
          documentslist: documentslist.map(item => {
            if (item.bidDocId === '') {
              item.progress = 0;
              item.uploadprocess = 'started';
            }
            return item;
          }),
        },
      });

      for (const i in uploadingDocs) {
        // if (doc.hasOwnProperty(i)) {
        if ({}.hasOwnProperty.call(uploadingDocs, i)) {
          const [uploadPromise, chan] = yield call(createUploader, uploadingDocs[i], uploadPath);
          yield fork(uploadProgressWatcher, chan, uploadingDocs[i].id, documentslist);
          const uploadrest = yield call(() => uploadPromise);
          responses.push(uploadrest);
          console.log(uploadingDocs[i].bidDocStatusName);
          if (uploadrest.data.result && uploadrest.data.result.bidDocId) {
            const newDocList = documentslist.map((item, index: number) => {
              if (item.id === uploadingDocs[i].id) {
                item.bidDocId = uploadrest.data.result.bidDocId;
                item.status = uploadrest.data.result.docStatus;
                item.statusType = uploadrest.data.result.docStatusType;
                item.uploadprocess = 'completed';
                item.requireConversion = item.docconversion;
                item.isDocumentProgress = true;
                item.procProgress = 0;
              }
              return item;
            });
            yield put({
              type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
              payload: {
                documentslist: SortingFn(newDocList, sortDirections.ASC, 'modifiedDate'),
                canFinishBid: false,
              },
            });
          }
        }
      }
    }

    yield put({
      type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
      payload: { upload_progress: false, halfWayCancel: false },
    });
    const success = responses.filter(item => item.data && item.data.result.bidDocId);

    if (success.length === uploadingDocs.length) {
      const addbid: AddBidState = yield select(state => state.addbid);
      const { documentslist } = addbid;
      const checkstatuslist = documentslist.filter(doc => doc.statusType !== DocStatus.Complete);
      if (checkstatuslist.length === 0) {
        yield put({ type: actionTypes.GET_ADD_BID_CAN_COMPLETE.TRIGGER, payload: { bidId } });
      }
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPLOAD.SUCCESS });
      toastFn('success', 'Saved', addbidtoastID);
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { processInterrupt: true } as SetAddBidDetailsPayload,
      });
      yield documentProcessChecker(true);
      yield delay(2000);
      yield updateDocumentProcess();
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPLOAD.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
    yield put({
      type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
      payload: { upload_progress: false },
    });
  } catch (error) {
    yield put({
      type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
      payload: { upload_progress: false },
    });
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPLOAD.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidDocumentDelete() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_DOCUMENT_DELETE.TRIGGER, submitAddBidDocumentDelete);
}

function* submitAddBidDocumentDelete(action: {
  payload: { bidDocId: number; bidId: number };
}): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_DELETE.REQUEST, meta: action.payload });
  try {
    const { bidDocId, bidId } = action.payload;
    const addbid = yield select(state => state.addbid);
    const { documentslist } = addbid;
    const response = yield call(Api.submitAddBidDocumentDelete, action.payload);
    const data = response.data.result || [];
    if (data && data.status) {
      const payload = documentslist.filter(
        (item: { bidDocId: number }) => item.bidDocId !== bidDocId,
      );
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { upload_progress: false },
      });
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_DELETE.SUCCESS, payload });
      delay(200);
      yield put({ type: actionTypes.GET_ADD_BID_CAN_COMPLETE.TRIGGER, payload: { bidId } });
      toastFn('success', 'Deleted', addbidtoastID);
      // Tracking API call
      if (bidId && bidDocId) {
        const finaldata = documentslist.filter(
          (item: { bidDocId: number }) => item.bidDocId === bidDocId,
        );
        finaldata.status = 'deleted';
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: {
            eventId: userEvent.UpdateQuoteDocument,
            parentId: finaldata.quoteId,
            value: finaldata,
          },
        });
      }
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_DELETE.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_DELETE.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidComplete() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_COMPLETE.TRIGGER, submitAddBidComplete);
}

function* submitAddBidComplete(action: {
  payload: { broadcastDate?: string; bidId?: number; fromPage?: string };
}): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_COMPLETE.REQUEST, meta: action.payload });
  try {
    const { bidId } = action.payload;
    const bidssummary = yield select(state => state.bidssummary);
    const { results = {} } = bidssummary;
    const { eBidding = '', broadcastDate, bidInternalStatusType, bidExternalStatusType } = results;

    if (eBidding === 'Yes') {
      const itemsresponse = yield call(Api.getAddBidResponseItems, { bidId });
      const responsecheck =
        (itemsresponse.data.result &&
          itemsresponse.data.result.filter(
            (item: { BidResponseItemId: number }) => item.BidResponseItemId > 0,
          )) ||
        [];
      if (responsecheck && responsecheck.length === 0) {
        toastFn(
          'error',
          'At least one required document type must be selected in eBidding Info',
          addbidtoastID,
        );
        return yield put({ type: actionTypes.SUBMIT_ADD_BID_COMPLETE.FAILURE });
      }
    }

    const response = yield call(Api.submitAddBidComplete, action.payload);
    const data = response.data.result || '';
    if (data && data.status) {
      const trackPayload = {
        eventId: userEvent.UpdateMemberLegalAd,
        parentId: action.payload.bidId,
        value: { bidInternalStatusType, bidExternalStatusType } as {
          bidInternalStatusType: BidInternalStatusType;
          bidExternalStatusType: BidExternalStatusType;
          broadcastDate: string | undefined;
        },
      };
      if (
        deprecatedGetDate(broadcastDate) !==
        dayjs(action.payload.broadcastDate).format(usaDateFormat)
      ) {
        trackPayload.value = { ...trackPayload.value, broadcastDate: action.payload.broadcastDate };
      }
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });

      yield put({
        type: actionTypes.SUBMIT_ADD_BID_COMPLETE.SUCCESS,
        payload: { broadcastModal: false },
      });
      delay(200);
      const summaryResponse = yield call(bidApi.getBidsSummary, { bidId });
      const results = summaryResponse.data.result;
      yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { results } });
      yield put({
        type: actionTypes.GET_BID_COMMODITYCODES.TRIGGER,
        payload: { bidId, type: 'bid' },
      });
      yield put({
        type: actionTypes.SET_BID_DETAILS.TRIGGER,
        payload: { results: [], initialRequest: true },
      });
      toastFn('success', 'Success', addbidtoastID);
    } else {
      if (data.message) {
        const finishBidErrorMsg = data.message.split(',') || [];
        const payload = { finishbiderrormodal: true, broadcastModal: false, finishBidErrorMsg };
        yield put({ type: actionTypes.SUBMIT_ADD_BID_COMPLETE.FAILURE, payload });
      }
      yield put({ type: actionTypes.SUBMIT_ADD_BID_COMPLETE.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_COMPLETE.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidAddSupplemental() {
  yield takeLatest(
    actionTypes.SUBMIT_ADD_BID_ADD_SUPPLEMENTAL.TRIGGER,
    submitAddBidAddSupplemental,
  );
}

function* submitAddBidAddSupplemental(action: addsuppulimentalTypes): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_ADD_SUPPLEMENTAL.REQUEST, meta: action.payload });
  try {
    const {
      id,
      type,
      existingsupplier = false,
      existsupplierdetails,
      allowDuplicate = false,
    } = action.payload;
    const memberinfo = yield select(state => state.memberinfo);
    const { ci } = memberinfo;
    const addbid = yield select(state => state.addbid);
    const { saveAndAdd = false } = addbid;
    let finaldata = {} as any;
    if (!existingsupplier) {
      const {
        supplierName = '',
        contactName = '',
        email = '',
        phone = '',
        state = '',
        city = '',
      } = action.payload.data;

      finaldata = {
        id,
        type,
        supplierName: supplierName.trim(),
        email: email.trim(),
        contactName: contactName.trim(),
        phone: formatPhoneNumber(phone),
        city,
        stateId: state && state.value,
        countryId: ci,
      };
    }

    if (allowDuplicate) finaldata.allowDuplicate = true;

    if (existingsupplier && !allowDuplicate) {
      const {
        mi = '',
        supplierName = '',
        email = '',
        contactName = '',
        phone = '',
        city = '',
        sti = '',
        ci = '',
        us = '',
      } = existsupplierdetails || {};
      finaldata = {
        id,
        type,
        memberId: mi,
        accountId: us,
        supplierName: supplierName.trim(),
        email: email.trim(),
        contactName: contactName.trim(),
        phone,
        city,
        stateId: sti,
        countryId: ci,
      };
    }
    const response = yield call(Api.submitAddBidAddSupplemental, finaldata);
    const data = response.data.result || '';
    if (data && data.status) {
      /* let currentvalue = values
      if (existingsupplier) {
        currentvalue = existsupplierdetails
      }
      const newvalue = {
        ...currentvalue,
        state: state && state.label,
        mi: data.memberId,
        acid: data.accountId
      }
      if (existingsupplier) {
        const { state: state1 } = existsupplierdetails as any
        newvalue.state = state1
      }
      let payload = [...supplementalsupplierlist, newvalue]
      payload = SortingFn(payload, 'ASC', 'supplierName') */

      yield put({
        type: actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS.TRIGGER,
        payload: { id, type },
      });

      yield put({ type: actionTypes.SUBMIT_ADD_BID_ADD_SUPPLEMENTAL.SUCCESS });
      if (!existingsupplier) {
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: {
            isExistsSupplier: '',
            existssupplierlist: [],
            addsupplementalmodal: saveAndAdd ? true : false,
            saveAndAdd: false,
          },
        });
      }
      toastFn('success', 'Saved', addbidtoastID);

      // Tracking API call
      if (id && type) {
        finaldata.status = 'added';
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: {
            eventId:
              type === 'Bid'
                ? userEvent.UpdateBidSupplementalSupplier
                : userEvent.UpdateQuoteSupplementalSupplier,
            parentId: id,
            value: finaldata,
          },
        });
      }
    } else if (data && data.isExists) {
      const payload = { isExistsSupplier: data.message, existssupplierlist: data.suppliers };
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.SUBMIT_ADD_BID_ADD_SUPPLEMENTAL.FAILURE });
      toastFn('error', data.message || 'Already Exists', addbidtoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_ADD_SUPPLEMENTAL.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_ADD_SUPPLEMENTAL.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchgetAddBidsupplementalsuppliers() {
  yield takeLatest(
    actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS.TRIGGER,
    getAddBidsupplementalsuppliers,
  );
}

function* getAddBidsupplementalsuppliers(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidsupplementalsuppliers, action.payload);
    const data = response.data.result || '';
    if (data) {
      const payload = data || [];
      yield put({ type: actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS.SUCCESS, payload });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchAddBidRemoveSupplimentalSuppliers() {
  yield takeLatest(
    actionTypes.ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS.TRIGGER,
    AddBidRemoveSupplimentalSuppliers,
  );
}

function* AddBidRemoveSupplimentalSuppliers(action: { payload: any }): any {
  yield put({
    type: actionTypes.ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS.REQUEST,
    meta: action.payload,
  });
  try {
    const response = yield call(Api.AddBidRemoveSupplimentalSuppliers, action.payload);
    const data = response.data.result || '';
    if (data && data.status) {
      const addbid = yield select(state => state.addbid);
      const { supplementalsupplierlist } = addbid;
      const payload = supplementalsupplierlist.filter(
        (item: string, index: number) => index !== action.payload.index,
      );
      yield put({ type: actionTypes.ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS.SUCCESS, payload });
      toastFn('success', 'Deleted', addbidtoastID);
      const { id, type, index } = action.payload;
      // Tracking API call
      if (id && type && index) {
        const finaldata = supplementalsupplierlist.find(
          (item: string, index: number) => index === action.payload.index,
        );
        finaldata.status = 'deleted';
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: {
            eventId:
              type === 'Bid'
                ? userEvent.UpdateBidSupplementalSupplier
                : userEvent.UpdateQuoteSupplementalSupplier,
            parentId: id,
            value: finaldata,
          },
        });
      }
    } else {
      yield put({ type: actionTypes.ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchsubmitAddBidMoveNext() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_MOVE_NEXT.TRIGGER, submitAddBidMoveNext);
}

function* submitAddBidMoveNext(action: { payload: { stepId: number } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_MOVE_NEXT.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.submitAddBidMoveNext, action.payload);
    const data = response.data.result || '';
    if (data && data.status) {
      let payload: any;
      const addbid = yield select(state => state.addbid);
      const { addbidwizard, addbidwizardpartial = false, wizardcurrentpage } = addbid;
      const currentwizard =
        addbidwizard.find((item: { status: string }) => item.status === 'current') || {};
      if (addbidwizardpartial) {
        payload = { addbidwizardpartial: false, halfWayCancelAllow: true };
        history.goBack();
      } else {
        payload = {
          addbidwizard: setWizardStatus(addbidwizard, currentwizard.id + 1),
          // wizardcurrentpage: wizardcurrentpage > currentwizard.id ? wizardcurrentpage : currentwizard.id + 1
        };
        if (action.payload.stepId) {
          payload.wizardcurrentpage =
            wizardcurrentpage > Number(action.payload.stepId)
              ? wizardcurrentpage
              : action.payload.stepId + 1;
        }
      }
      yield put({ type: actionTypes.SUBMIT_ADD_BID_MOVE_NEXT.SUCCESS, payload });
      toastFn('success', 'Saved', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_MOVE_NEXT.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchgetAddBidIsPermissions() {
  yield takeLatest(actionTypes.GET_ADD_BID_IS_PERMISSIONS.TRIGGER, getAddBidIsPermissions);
}

function* getAddBidIsPermissions(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_IS_PERMISSIONS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidIsPermissions, action.payload);
    const data = response.data.result || '';
    if (data) {
      yield put({ type: actionTypes.GET_ADD_BID_IS_PERMISSIONS.SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_IS_PERMISSIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddBidDocumentApprove() {
  yield takeLatest(
    actionTypes.SUBMIT_ADD_BID_DOCUMENT_APPROVE.TRIGGER,
    submitAddBidDocumentApprove,
  );
}

function* submitAddBidDocumentApprove(action: {
  payload: {
    bidId: number;
    pagefor: string;
    bidDocId: number;
  };
}): any {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_APPROVE.REQUEST, meta: action.payload });
  try {
    const { bidId, pagefor = '' } = action.payload;
    const response = yield call(Api.submitAddBidDocumentApprove, action.payload);
    const data = response.data.result || [];
    if (data && data.status) {
      delay(200);

      const addbid: AddBidState = yield select(state => state && state.addbid);
      const { documentslist = [], approvedDocuments = [] } = addbid;
      let payload: BidDocument[] = [];
      if (pagefor === 'bidssummary') {
        const docresponse = yield call(bidApi.getBidsDocuments, action.payload);
        const docsres: BidDocument[] = docresponse.data.result || [];
        payload = docsres.map(item => {
          return {
            ...item,
            doctitle: (item.fileName as string).trim(),
            doctype: item.type as DocTypeLabel,
            docfile: item.path,
            docconversion: false,
            uploadprocess: 'notstarted',
            bidDocId: item.bidDocID as number,
            docext: '',
            progress: 0,
            bidId: item.bidID as number,
            id: generateKey('doc'),
            isExist: item.isExist,
          };
        });
        yield put({
          type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER,
          payload: { documents: payload },
        });
      } else {
        const updatedApprovedDocs = [...approvedDocuments];
        payload = documentslist.map(item => {
          if (item.bidDocId === action.payload.bidDocId) {
            updatedApprovedDocs.push(item.bidDocId);
            return {
              ...item,
              statusType: DocStatus.Complete,
              status: 'Complete',
              modifiedDate: moment().tz(TimeZone.Eastern).format(),
            };
          }
          return item;
        });
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { approvedDocuments: updatedApprovedDocs },
        });
      }
      yield put({ type: actionTypes.GET_ADD_BID_CAN_COMPLETE.TRIGGER, payload: { bidId } });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: {
          approvedocumentmodal: false,
        },
      });

      payload = SortingFn(payload, sortDirections.ASC, 'modifiedDate');
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_APPROVE.SUCCESS, payload });
      toastFn('success', 'Approved', addbidtoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_APPROVE.FAILURE });
      toastFn('error', 'Failed', addbidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_APPROVE.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addbidtoastID);
  }
}

function* watchgetAddBidBroadcastMembers() {
  yield takeLatest(actionTypes.GET_ADD_BID_BROADCAST_MEMBERS.TRIGGER, getAddBidBroadcastMembers);
}

function* getAddBidBroadcastMembers(action: { payload: { id: string; type: string } }): any {
  yield put({ type: actionTypes.GET_ADD_BID_BROADCAST_MEMBERS.REQUEST, meta: action.payload });
  try {
    const { id, type } = action.payload;
    const response = yield call(Api.getAddBidBroadcastMembers, { id, type });
    const data = response.data.result || [];
    if (data) {
      const payload = { broadcastinglist: data || [] };
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ADD_BID_BROADCAST_MEMBERS.SUCCESS });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_BROADCAST_MEMBERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidBidsDocuments() {
  yield takeLatest(actionTypes.GET_ADD_BID_BIDS_DOCUMENTS.TRIGGER, getAddBidBidsDocuments);
}

function* getAddBidBidsDocuments(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_BIDS_DOCUMENTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getBidsDocuments, action.payload);
    const docsres: BidDocumentResult[] = response.data.result || [];
    if (docsres) {
      //TODO: Can we kill this dummy? What are we gaining from it?
      let documentslist: BidDocument[] = [
        {
          bidDocId: '',
          bidId: '',
          docconversion: false,
          docext: '',
          docfile: '',
          doctitle: '',
          doctype: '',
          docType: DocTypeValue.None,
          duedates: new Date(),
          duetimes: '',
          duetimezones: '',
          id: generateKey('doc'),
          isExist: false,
          modifiedDate: moment().format(),
          originalFileName: '',
          progress: 0,
          selectduedates: { label: 'No', value: 'No' },
          statusType: DocStatus.None,
          uploadprocess: 'notstarted',
        },
      ];
      if (docsres.length > 0) {
        documentslist = docsres.map(doc => {
          return {
            ...doc,
            bidDocId: doc.bidDocID,
            bidId: doc.bidID,
            docconversion: false,
            docext: '',
            docfile: doc.path,
            doctitle: doc.fileName.trim(),
            doctype: doc.type,
            docType: doc.docType,
            id: generateKey('doc'),
            isDocumentProgress: doc.statusType === DocStatus.PdfProcessPending,
            isExist: true,
            // modifiedDate: moment().format(),
            originalFileName: doc.originalFileName,
            procProgress: 0,
            progress: 0,
            uploadprocess: 'notstarted',
          };
        });
      }

      const payload = {
        documentslist: SortingFn(documentslist, sortDirections.ASC, 'modifiedDate'),
      };
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ADD_BID_BIDS_DOCUMENTS.SUCCESS });
      const inProgressDocs = documentslist.filter(
        item => item.statusType === DocStatus.PdfProcessPending,
      );
      if (inProgressDocs.length > 0) {
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { processInterrupt: true } as SetAddBidDetailsPayload,
        });
        yield documentProcessChecker(true);
        yield delay(2000);
        yield updateDocumentProcess();
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_BIDS_DOCUMENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* updateDocumentProcess(): any {
  let progressing = true;

  const bidssummary = yield select(state => state && state.bidssummary);
  const { results = {} } = bidssummary;

  const timeInterval = processPercentage;
  while (progressing === true) {
    const addbid = yield select(state => state && state.addbid);
    const { processInterrupt = false }: SetAddBidDetailsPayload = addbid;
    if (processInterrupt === true) {
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { processInterrupt: false } as SetAddBidDetailsPayload,
      });
      progressing = false;
    } else {
      const processResponse = yield documentProcessChecker() as any;
      if (processResponse === false) {
        progressing = false;
        yield put({
          type: actionTypes.GET_ADD_BID_BIDS_DOCUMENTS.TRIGGER,
          payload: { bidId: results.bidID },
        });
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { totalProcessingTime: 0 },
        });
      }
    }

    yield delay(timeInterval * 1000);
  }
}

function* documentProcessChecker(intialProcess = false): any {
  const addbid: AddBidState = yield select(state => state && state.addbid);
  const bidssummary: BidSummaryState = yield select(state => state && state.bidssummary);
  const { documentslist = [], processingInterval } = addbid || {};
  const { results = {} } = bidssummary;
  const response = yield call(Api.docProcessingStatus, {
    bidId: (results as BidSummaryResult).bidID,
  });
  const processingDocs: BidDocument[] = response.data.result;
  if (documentslist.length > 0) {
    let totaltime = 0;
    const updatedDocumentsList: BidDocument[] = documentslist.map(item => {
      const updatedDocList = processingDocs.find(itemData => itemData.bidDocID === item.bidDocId);
      if (updatedDocList) {
        if (
          [DocStatus.ProcessingError, DocStatus.ApprovalPending, DocStatus.Complete].includes(
            updatedDocList.bidDocStatusName as DocStatus,
          )
        ) {
          return {
            ...item,
            isDocumentProgress: false,
            procProgress: 0,
            statusType: updatedDocList.bidDocStatusName as DocStatus,
            status: (documentStatusNames &&
              documentStatusNames[
                updatedDocList.bidDocStatusName as DocumentStatusNames
              ]) as StatusLabel,
            bidDocStatusName: updatedDocList.bidDocStatusName,
          };
        }

        if (intialProcess) {
          if (documentProcessStatus.includes(updatedDocList.bidDocStatusName as DocStatus)) {
            totaltime =
              totaltime + (updatedDocList.remainingTime ? updatedDocList.remainingTime : 10);
          }
          return {
            ...item,
            ...updatedDocList,
            startTime: 0,
          };
        } else if (item.remainingTime !== 0) {
          //TODO: Why are we parsing these? Aren't they all already numbers?
          const percentage = Math.round(
            ((parseInt(item.startTime as any) + parseInt(processingInterval as any)) /
              parseInt(item.remainingTime as any)) *
              100,
          );
          return {
            ...item,
            bidDocStatusName: updatedDocList.bidDocStatusName,
            procProgress: percentage > fixedPercentage ? fixedPercentage : percentage,
            startTime: (item.startTime as number) + (processingInterval as number),
          };
        } else {
          const percentage = Math.round(
            (((item.startTime as number) + (processingInterval as number)) / 10) * 100,
          );
          return {
            ...item,
            bidDocStatusName: updatedDocList.bidDocStatusName,
            procProgress: percentage > fixedPercentage ? fixedPercentage : percentage,
            startTime: (item.startTime as number) + (processingInterval as number),
          };
        }
      } else {
        return item;
      }
    });

    const isDocumentsRemain = updatedDocumentsList.filter(item =>
      documentProcessStatus.includes(item.bidDocStatusName as DocStatus),
    );

    const sortedDocList: BidDocument[] = SortingFn(
      updatedDocumentsList,
      sortDirections.ASC,
      'modifiedDate',
    );
    let payload: SetAddBidDetailsPayload = { documentslist: sortedDocList };
    if (intialProcess) {
      const timeInterval = processPercentage;
      payload = {
        documentslist: sortedDocList,
        totalProcessingTime: totaltime,
        processingInterval: timeInterval,
        processInterrupt: false,
      };
    }
    yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });

    if (isDocumentsRemain.length === 0) {
      return false;
    }

    return true;
  }
  return false;
}

function* watchgetAddBidCanComplete() {
  yield takeLatest(actionTypes.GET_ADD_BID_CAN_COMPLETE.TRIGGER, getAddBidCanComplete);
}

function* getAddBidCanComplete(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_CAN_COMPLETE.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.canComplete, action.payload);

    if (response) {
      const payload = { canFinishBid: response.data.result.status };
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ADD_BID_CAN_COMPLETE.SUCCESS });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_CAN_COMPLETE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidBidStatus() {
  yield takeLatest(actionTypes.GET_ADD_BID_BID_STATUS.TRIGGER, getAddBidBidStatus);
}

function* getAddBidBidStatus(action: addbidpayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_BID_BID_STATUS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getBidStatus, action.payload);
    const data = response.data.result;
    if (data) {
      const payload = { bidStatusList: data };
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ADD_BID_BID_STATUS.SUCCESS });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_BID_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddBidChangeDue() {
  yield takeLatest(actionTypes.GET_ADD_BID_CHANGE_DUE.TRIGGER, getAddBidChangeDue);
}

function* getAddBidChangeDue(action: { payload: { bidId: string } }): any {
  yield put({ type: actionTypes.GET_ADD_BID_CHANGE_DUE.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddBidChangeDue, action.payload);
    const data = response.data.result;
    if (data && data.status) {
      const { bidId } = action.payload;
      yield put({ type: actionTypes.GET_ADD_BID_CHANGE_DUE.SUCCESS });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: {
          upload_progress: true,
          documentsoptionsmodal: false,
          awardeelist: [],
        } as SetAddBidDetailsPayload,
      });
      yield put({ type: actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPLOAD.TRIGGER, payload: { bidId } });
    } else {
      yield put({ type: actionTypes.GET_ADD_BID_CHANGE_DUE.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_CHANGE_DUE.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({
      type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
      payload: { upload_progress: false },
    });
  }
}

function* watchgetaddBidAddAwardee() {
  yield takeLatest(actionTypes.ADD_BID_ADD_AWARDEE.TRIGGER, addBidAddAwardee);
}

function* addBidAddAwardee(action: { payload: { bidId: string } }): any {
  const bidId = action.payload.bidId;
  yield put({ type: actionTypes.ADD_BID_ADD_AWARDEE.REQUEST, meta: action.payload });
  try {
    const addbid: AddBidState = yield select(state => state.addbid);
    const { awardeelist } = addbid;
    const filteredAwardeeList = awardeelist.filter(awardee => awardee.supplierName);
    const existingAwardeeList = awardeelist.filter(awardee => awardee.bidAwardId);
    const newAwardeesList = filteredAwardeeList.map(item => {
      return {
        ...item,
      };
    });
    const response = yield call(Api.addAwards, {
      bidId,
      bidAwardees: newAwardeesList,
    });
    if (response.data.result.status) {
      const payload = {
        documentsoptionsmodal: false,
      } as SetAddBidDetailsPayload;
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      document.body.classList.remove('modal-open');
      yield put({
        type: actionTypes.SET_BID_DETAILS.TRIGGER,
        payload: {
          results: [],
          initialRequest: true,
        },
      });
      yield put({ type: actionTypes.ADD_BID_ADD_AWARDEE.SUCCESS });
      const payloadEvent = {
        eventId: userEvent.AwardBid,
        parentId: action.payload.bidId,
        value: existingAwardeeList,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });

      if (bidId) {
        const response = yield call(bidApi.getBidsSummary, { bidId });
        const results = response.data.result || {};
        yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { results } });
      }
    } else {
      yield put({ type: actionTypes.ADD_BID_ADD_AWARDEE.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_BID_ADD_AWARDEE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetaddBidGetAwardees() {
  yield takeLatest(actionTypes.GET_ADD_BID_GET_AWARDEES.TRIGGER, getaddBidGetAwardees);
}

function* getaddBidGetAwardees(action: { payload: { bidId: number } }): any {
  yield put({ type: actionTypes.GET_ADD_BID_GET_AWARDEES.REQUEST, meta: action.payload });
  try {
    //const bidssummary = yield select(state => state.bidssummary)
    //const { results } = bidssummary

    const planholderResponse = yield call(bidApi.getBidsPlanHolders, action.payload);

    const ebidResponses = yield call(EbidApi.getEbidResponse, action.payload);
    const ebidResponseArray = ebidResponses.data.result.map(
      (item: { supplierMemberId: number; name: string }) => {
        return { mi: item.supplierMemberId, supplierName: item.name };
      },
    );

    let planHoldersList = planholderResponse.data.result.filter((item: { mi: number }) => {
      const filter = ebidResponseArray.filter((item2: { mi: number }) => item2.mi === item.mi);
      if (filter.length === 0) return true;
      else return false;
    });

    planHoldersList = [...ebidResponseArray, ...planHoldersList];

    if (planHoldersList.length > 0) {
      const awardeeResponse = yield call(Api.getAwardee, action.payload);

      const awardeelist: Awardee[] = awardeeResponse.data.result || [];
      let awardeeIds: number[] = [];
      if (awardeelist.length > 0) {
        awardeeIds = awardeelist.map(item => item.awardedToMemberId as number);
        planHoldersList = planHoldersList.map((item: planHoldersType) => {
          item.value = item.mi;
          item.label = item.supplierName;
          item.isSelect = awardeeIds.includes(item.mi);
          return item;
        });
      } else {
        planHoldersList = planHoldersList.map((item: planHoldersType) => {
          item.value = item.mi;
          item.label = item.supplierName;
          item.isSelect = false;
          return item;
        });
      }

      const payload = {
        planHoldersList,
        awardeelist,
        awardeeIds,
        bidders: ebidResponses.data.result,
      };

      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ADD_BID_GET_AWARDEES.SUCCESS });
    } else {
      const payload = { planHoldersList: [], awardeelist: [], awardeeIds: [] };
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ADD_BID_GET_AWARDEES.SUCCESS });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_BID_GET_AWARDEES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchDeleteBroadcastSuppliers() {
  yield takeLatest(
    actionTypes.ADD_BID_DELETE_BROADCAST_SUPPLIERS.TRIGGER,
    DeleteBroadcastSuppliers,
  );
}

function* DeleteBroadcastSuppliers(action: { payload: { blockedMemberID: number } }): any {
  yield put({ type: actionTypes.ADD_BID_DELETE_BROADCAST_SUPPLIERS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.removeBroadcastSupplier, action.payload);

    if (response.data.status) {
      const addbid = yield select(state => state.addbid);
      const {
        broadcastinglist = [],
        broadcastListssearch,
        broadcastListssearchShow = false,
      } = addbid;
      const newBroadcastList = broadcastinglist.filter(
        (item: { mi: number }) => item.mi !== action.payload.blockedMemberID,
      );
      const payload: any = { broadcastinglist: newBroadcastList };
      if (broadcastListssearchShow) {
        payload.broadcastListssearch = broadcastListssearch.filter(
          (item: { mi: number }) => item.mi !== action.payload.blockedMemberID,
        );
      }
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.ADD_BID_DELETE_BROADCAST_SUPPLIERS.SUCCESS });
      toastFn('success', 'Deleted', addbidtoastID);
    } else {
      toastFn('error', 'Failed', addbidtoastID);
      yield put({ type: actionTypes.ADD_BID_DELETE_BROADCAST_SUPPLIERS.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', addbidtoastID);
    yield put({
      type: actionTypes.ADD_BID_DELETE_BROADCAST_SUPPLIERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchAddBidCommodityFilters() {
  yield takeLatest(actionTypes.ADD_BID_SET_COMMODITY_FILTERS.TRIGGER, AddBidCommodityFilters);
}

function* AddBidCommodityFilters(action: {
  payload: { isCurrentMember: boolean; pagefor: string };
}): any {
  yield put({ type: actionTypes.ADD_BID_SET_COMMODITY_FILTERS.REQUEST, meta: action.payload });
  try {
    let filters = {};
    const memberinfo = yield select(state => state.memberinfo);
    const { ci } = memberinfo;
    const stateresponse = yield call(sharedApi.getStatesList, action.payload);
    let stateslist = yield (stateresponse.data && stateresponse.data.result) || [];
    stateslist = stateslist
      .map((items: { name: string; title: string; id: number }) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }))
      .filter((item: { countryId: number }) => item.countryId === ci);

    const addbid = yield select(state => state.addbid);
    const shared = yield select(state => state.shared);
    const { activeprograms = [] } = addbid;
    let { selfdeclaredattributes = [] } = shared;

    if (selfdeclaredattributes.length === 0) {
      try {
        const updatedPayload = {
          ...action.payload,
          IsCurrentMember: true,
        };
        const decResponse = yield call(sharedApi.getselfdeclarations, updatedPayload);
        selfdeclaredattributes = decResponse.data.result.map(
          (item: { attributeName: string; attributetype: string }) => ({
            ...item,
            label: item.attributeName,
            value: item.attributetype,
          }),
        );
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
          payload: { selfdeclaredattributes },
        });
      } catch (error) {
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.FAILURE,
          payload: { payload: action.payload, error },
        });
      }
    }

    const selfdeclarAttr =
      selfdeclaredattributes.map(
        (item: { attributeName: string; attributeType: string }, index: number) => ({
          label: item.attributeName,
          value: item.attributeType,
        }),
      ) || [];

    let data: any;

    if (action.payload.pagefor === 'addquotes') {
      const quoteSummary = yield select(state => state.quoteSummary);
      const { summary = {} } = quoteSummary;
      data = summary;
    } else {
      const bidssummary = yield select(state => state.bidssummary);
      const { results = {} } = bidssummary;
      data = results;
    }

    const {
      filteredStates = '',
      filteredDeclarations = '',
      filteredPrograms = '',
      filteredCities = '',
    } = data;

    const state = (filteredStates && filteredStates.split(',')) || [];
    const declaration = (filteredDeclarations && filteredDeclarations.split(',')) || [];
    const programs = (filteredPrograms && filteredPrograms.split(',')) || [];
    const stated =
      stateslist.length > 0 && state.length > 0
        ? stateslist.filter((item: { name: string }) => state.includes(item.name))
        : [];

    const selfdeclaration =
      selfdeclarAttr.length > 0 && declaration.length > 0
        ? selfdeclarAttr.filter((item: { value: string; attributeType: string }) =>
            declaration.includes(item.value || item.attributeType),
          )
        : [];

    const program =
      activeprograms.length > 0 && programs.length > 0
        ? activeprograms.filter((item: { value: { toString: () => any } }) =>
            programs.includes(item.value.toString()),
          )
        : [];

    filters = {
      filterState: stated || [],
      filterCity: (filteredCities && filteredCities.split(',')) || [],
      filterDeclaration: selfdeclaration || [],
      filterPrograms: program || [],
    };

    const payload = { broadcastFilter: filters };

    yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.ADD_BID_SET_COMMODITY_FILTERS.SUCCESS });
  } catch (e) {
    yield put({ type: actionTypes.ADD_BID_SET_COMMODITY_FILTERS.FAILED });
  }
}

function* watchBulkUploadDocument() {
  yield takeLatest(actionTypes.BULK_UPLOAD_DOCUMENT.TRIGGER, bulkUploadDocument);
}

function* bulkUploadDocument(action: { payload: any }): any {
  yield put({ type: actionTypes.BULK_UPLOAD_DOCUMENT.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.builkUploadDocument, action.payload);
    let payload = {};
    if (response.data.errorId && response.data.errorId !== 3)
      payload = { errorType: response.data.errorId };

    if (response.data.errorId === 3)
      payload = {
        multipleSheet: true,
        docId: response.data.result.id,
        sheets: response.data.sheets,
        selectedSheet: response.data.sheets[0],
      };

    if (response.data.result && response.data.result.estimatedTimeToFinish) {
      payload = {
        estimatedTime: response.data.result.EstimatedTimeToFinish,
        uploadProgress: true,
        progress: 0,
        docId: response.data.result.id,
      };
    }

    yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });

    yield put({ type: actionTypes.BULK_UPLOAD_DOCUMENT.SUCCESS });
    yield delay(2000);
    if (response.data.result && response.data.result.estimatedTimeToFinish) {
      yield fork(updateProgressWatcher, response.data.result.estimatedTimeToFinish);
      yield fork(fetchUploadStatus, response.data.result.id);
    }
  } catch (e) {
    yield put({ type: actionTypes.BULK_UPLOAD_DOCUMENT.FAILED });
  }
}

function* watchUpdateSuplimentalSheetUpdate() {
  yield takeLatest(
    actionTypes.UPDATE_SUPLIMENTAL_SHEET_UPDATE.TRIGGER,
    updateSuplimentalSheetUpdate,
  );
}

function* updateSuplimentalSheetUpdate(action: { payload: any }): any {
  yield put({ type: actionTypes.UPDATE_SUPLIMENTAL_SHEET_UPDATE.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.updateSheet, action.payload);

    if (response.data.errorId && response.data.errorId !== 3) {
      const payload = { errorType: response.data.errorId, multipleSheet: false };
      yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
    }

    if (response.data.result.EstimatedTimeToFinish) {
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: {
          estimatedTime: response.data.result.EstimatedTimeToFinish,
          uploadProgress: true,
          progress: 0,
          multipleSheet: false,
        },
      });
      yield fork(updateProgressWatcher, response.data.result.EstimatedTimeToFinish);
      yield fork(fetchUploadStatus, response.data.result.Id);
    }

    yield put({ type: actionTypes.UPDATE_SUPLIMENTAL_SHEET_UPDATE.SUCCESS });
  } catch (e) {
    yield put({ type: actionTypes.UPDATE_SUPLIMENTAL_SHEET_UPDATE.FAILED });
  }
}

function* updateProgressWatcher(estimatedTime: number): any {
  let startTime = 10;
  let currentUploadProcess = true;

  while (currentUploadProcess) {
    const addbid = yield select(state => state && state.addbid);
    const { uploadProgress } = addbid;
    const seconds = estimatedTime * 60;
    const percentage = Math.round((startTime / seconds) * 100);
    startTime = startTime + 1;
    let payload = {};
    if (percentage >= 100 || uploadProgress === false) {
      payload = { uploadProgress: false, progress: percentage };
      currentUploadProcess = false;
    } else {
      payload = { progress: percentage };
    }
    yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
    yield delay(500);
  }
}

function* fetchUploadStatus(docId: string): any {
  let currentUploadProcess = true;
  while (currentUploadProcess) {
    const response = yield call(Api.getsupplimentalDocUploadStatus, { id: docId });
    if (response.data.status === 'DL') {
      currentUploadProcess = false;
      const columnFields = response.data.columns.filter((item: string) => item !== 'email');
      const columnData = columnFields.map((item: string) => {
        return { column: item, data: response.data[item] };
      });
      const emailAddress = response.data.emailAddress.map((item: any) => {
        return { ...item, isIncluded: true };
      });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: {
          progress: 100,
          uploadProgress: false,
          currentScreen: 2,
          unmatchedColumns: columnFields.length,
          columns: columnFields,
          columnData,
          emailAddress,
        },
      });
    }

    if (response.data.errorId) {
      currentUploadProcess = false;
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { errorType: response.data.errorId, uploadProgress: false },
      });
    }

    yield delay(30000);
  }
}

function* watchSaveUploadedSupplimentalSupplier() {
  yield takeLatest(
    actionTypes.SAVE_UPLOADED_SUPPLIMENTAL_SUPPLIER.TRIGGER,
    saveUploadedSupplimentalSupplier,
  );
}

function* saveUploadedSupplimentalSupplier(action: {
  payload: { bidId: number; isReview: boolean; reBroadcast: boolean };
}): any {
  yield put({
    type: actionTypes.SAVE_UPLOADED_SUPPLIMENTAL_SUPPLIER.REQUEST,
    meta: action.payload,
  });
  try {
    const addbid = yield select(state => state && state.addbid);
    const { emailAddress, columnData, skippedColumns = [], docId } = addbid;
    const columnMappings = columnData
      .filter((item: any) => !skippedColumns.includes(item.column))
      .map((item: any) => {
        return {
          key: item.column,
          value: item.mapping ? item.mapping.value : '',
        };
      });
    const { isReview = false, reBroadcast = false } = action.payload;

    const payload = {
      id: docId,
      emails: emailAddress,
      columnMappings,
      isReview,
    };

    if (reBroadcast === true) {
      const addbid = yield select(state => state && state.addbid);
      const { broadcastSupplimentalSupplier = [], emailAddress = [] } = addbid;
      const shared = yield select(state => state && state.shared);
      const { stateslist = [] } = shared;
      const validSupplementalList = emailAddress.filter(
        (item: any) => item.isValid === true && item.isIncluded === true,
      );
      const supplementalSuppliersList = validSupplementalList.map((item: any) => {
        const stateData =
          stateslist.find(
            (sItem: any) => sItem.name.includes(item.state) || sItem.abbr.includes(item.state),
          ) || {};
        return {
          ...item,
          isBulkUploaded: true,
          stateId: stateData.id ? stateData.id : '',
          countryId: stateData.countryId ? stateData.countryId : '',
          supplierName: item.companyName,
        };
      });
      const newBroadcastSupplierList = [
        ...supplementalSuppliersList,
        ...broadcastSupplimentalSupplier,
      ];
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: {
          broadcastSupplimentalSupplier: newBroadcastSupplierList,
          addSupplierBulkUploadModal: false,
        },
      });
    } else {
      const response = yield call(Api.saveUploadedSupplimentalSuppliers, payload);

      if (response.data.result && response.data.result.Status === 'SU' && isReview === false) {
        yield put({
          type: actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS.TRIGGER,
          payload: { id: action.payload.bidId, type: 'Bid' },
        });
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { addSupplierBulkUploadModal: false },
        });
      } else {
        const emailAddress = response.data.emailAddress.map((item: any) => {
          return { ...item, isIncluded: true };
        });
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { currentScreen: 3, emailAddress },
        });
      }

      if (response.data.result.errorId) {
        const payload = {
          errorType: response.data.result.errorId,
          multipleSheet: false,
          currentScreen: 1,
        };
        yield put({ type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER, payload });
      }
    }
    yield put({ type: actionTypes.SAVE_UPLOADED_SUPPLIMENTAL_SUPPLIER.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_UPLOADED_SUPPLIMENTAL_SUPPLIER.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchReBroadcastToSuppliers() {
  yield takeLatest(actionTypes.REBROADCAST_BID_TO_SUPPLIERS.TRIGGER, reBroadcastToSuppliers);
}

function* reBroadcastToSuppliers(action: { payload: { id: number } }): any {
  yield put({ type: actionTypes.REBROADCAST_BID_TO_SUPPLIERS.REQUEST, meta: action.payload });
  try {
    const addbid = yield select(state => state && state.addbid);
    const { broadcastSupplimentalSupplier = [], broadcastListssearch = [] } = addbid || {};
    let isSuppliersAdded = false;
    let isCommodityAdded = false;
    // add supplemental suppliers

    const { columnData = [], skippedColumns = [], docId } = addbid;
    const columnMappings = columnData
      .filter((item: any) => !skippedColumns.includes(item.column))
      .map((item: any) => {
        return {
          key: item.column,
          value: item.mapping ? item.mapping.value : '',
        };
      });
    const bulkUploadedSuppliers = broadcastSupplimentalSupplier.filter(
      (item: any) => item.isBulkUploaded,
    );
    const bulkPayload = {
      id: docId,
      emails: bulkUploadedSuppliers,
      columnMappings,
      isReview: false,
    };
    if (bulkUploadedSuppliers.length > 0) {
      const bulkUploadresponse = yield call(Api.saveUploadedSupplimentalSuppliers, bulkPayload);
    }
    const remainingSuppliers = broadcastSupplimentalSupplier.filter(
      (item: any) => !item.isBulkUploaded,
    );

    if (remainingSuppliers.length > 0) {
      const supplimentalPayload = {
        supplementalsuppliers: remainingSuppliers,
        id: action.payload.id,
      };
      const bulkUploadResponse = yield call(Api.addBulkSupplimentalSuppliers, supplimentalPayload);
      if (!bulkUploadResponse.data.result.status) {
        toastFn(
          'error',
          'There is a problem with broadcasting to the new suppliers. Please try again',
          'supplimentalBulkUpload',
        );
        yield put({ type: actionTypes.REBROADCAST_BID_TO_SUPPLIERS.FAILURE });
      }
    }

    // rebroadcast suppliers
    const commodities = yield select(state => state && state.commoditycodes);
    const { commoditieslist = [] } = commodities;
    const generatenewlist: Array<number | string> = getOptimizedCommodityCodes(commoditieslist);

    if (generatenewlist.length > 0) {
      isCommodityAdded = true;
    }

    if (broadcastSupplimentalSupplier.length > 0) {
      isSuppliersAdded = true;
    }

    const reBroadcastPayload = {
      id: action.payload.id,
      commodityIdList: [...Array.from(new Set([...generatenewlist]))].join(','),
      //rowCount:1000,
      isSuppliersAdded,
      isCommodityAdded,
    };
    yield delay(1000);
    const response = yield call(Api.reBroadcastBid, reBroadcastPayload);
    if (response.data.result.status) {
      const broadcastedSuppliers = broadcastListssearch.filter(
        (item: any) => item.broadcasted === false,
      ).length;
      const totalSuppliers = broadcastedSuppliers + broadcastSupplimentalSupplier.length;
      toastFn(
        'success',
        `The bid will be broadcast to ${totalSuppliers} additional suppliers.`,
        'supplimentalBulkUpload',
      );
      yield put({
        type: actionTypes.GET_BID_COMMODITYCODES.TRIGGER,
        payload: { bidId: action.payload.id, type: 'bid' },
      });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { additionalBroadcastModal: false },
      });
      yield put({ type: actionTypes.REBROADCAST_BID_TO_SUPPLIERS.SUCCESS });
    } else {
      toastFn(
        'error',
        'There is a problem with broadcasting to the new suppliers. Please try again',
        'supplimentalBulkUpload',
      );
      yield put({ type: actionTypes.REBROADCAST_BID_TO_SUPPLIERS.FAILURE });
    }
  } catch (error) {
    toastFn(
      'error',
      'There is a problem with broadcasting to the new suppliers. Please try again',
      'supplimentalBulkUpload',
    );
    yield put({
      type: actionTypes.REBROADCAST_BID_TO_SUPPLIERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* addBidSaga() {
  yield fork(watchgetAddBidBidTypes);
  yield fork(watchgetAddBidBidWriters);
  yield fork(watchsubmitAddBidInfo);
  yield fork(watchsetAddBidInitialData);
  yield fork(watchgetAddBidResponseItems);
  yield fork(watchgetAddBidPublications);
  yield fork(watchsubmitAddBidUpdateBidPublications);
  yield fork(watchUpdateAddBidEbidInfo);
  yield fork(watchgetAddBidLegal);
  yield fork(watchsubmitAddBidUpdateLegal);
  yield fork(watchsubmitAddBidUpdateMemo);
  yield fork(watchsubmitAddBidAddList);
  yield fork(watchSubmitAddBidGetPreBuiltList);
  yield fork(watchsubmitAddBidDeleteList);
  yield fork(watchSubmitAddBidUsePreBuiltList);
  yield fork(watchgetAddBidActivePrograms);
  yield fork(watchgetAddBidBroadcastListId);
  yield fork(watchsubmitAddBidSearchVendors);
  yield fork(watchsubmitAddBidSupplierCount);
  yield fork(watchsubmitAddBidIsFilterStatus);
  yield fork(watchgetAddBidDocTypes);
  yield fork(watchgetAddBidIsConstruction);
  yield fork(watchgetAddBidConversionTypes);
  yield fork(watchsubmitAddBidUpdatePlan);
  yield fork(watchsubmitAddBidDocumentUpload);
  yield fork(watchsubmitAddBidDocumentDelete);
  yield fork(watchsubmitAddBidComplete);
  yield fork(watchsubmitAddBidAddSupplemental);
  yield fork(watchgetAddBidsupplementalsuppliers);
  yield fork(watchAddBidRemoveSupplimentalSuppliers);
  yield fork(watchsubmitAddBidMoveNext);
  yield fork(watchgetAddBidIsPermissions);
  yield fork(watchsubmitAddBidDocumentApprove);
  yield fork(watchgetAddBidBroadcastMembers);
  yield fork(watchgetAddBidBidsDocuments);
  yield fork(watchgetAddBidCanComplete);
  yield fork(watchgetAddBidBidStatus);
  yield fork(watchgetAddBidChangeDue);
  yield fork(watchgetaddBidAddAwardee);
  yield fork(watchgetaddBidGetAwardees);
  yield fork(watchDeleteBroadcastSuppliers);
  yield fork(watchAddBidCommodityFilters);
  yield fork(watchSubmitAddBidGetPreBuiltListCount);
  yield fork(watchBulkUploadDocument);
  yield fork(watchUpdateSuplimentalSheetUpdate);
  yield fork(watchSaveUploadedSupplimentalSupplier);
  yield fork(watchReBroadcastToSuppliers);
}
