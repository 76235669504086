import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';
import { registrationComponent } from '../../utils/constants';
export const initialRegistrationData = {
  emailAddress: '',
  companyName: '',
  acceptTerms: false,
  freeAgency: 0,
  companyAddress: {
    address1: '',
    address2: '',
    city: '',
    stateId: 0,
    stateName: '',
    countyName: '',
    countyId: 0,
    countryId: 0,
    country: '',
    postalCode: 0,
  },
  companyInfo: { contactName: '', contactPhone: 0, fax: 0, website: '' },
  mainContact: { firstName: '', lastName: '', phone: 0, email: '' },
};
const initialState = {
  currentComponent: registrationComponent.CreateAccount,
  registrationData: initialRegistrationData,
  isRegistrationCompleted: false,
  version: '',
};

export const reducer = (state = initialState, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.SET_REGISTRATION_DATA.TRIGGER:
      state = {
        ...state,
        ...action.payload,
      };
      break;
  }
  return state;
};
