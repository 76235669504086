import moment, { Moment } from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useDispatch } from 'react-redux';

import * as actionTypes from '../../store/actionTypes';
import { datePickerYear, invalidDateMessage, usaDateFormat } from '../../utils/constants';
import { deprecatedConvertUSAFormat, validateTypedDate } from '../../utils/helpers';

interface PropsTypes {
  handleChange?: (name: string, value: any) => void;
  type?: any;
  disabled?: any;
  label?: string;
  name?: string;
  value?: any;
  meta?: any;
  optional?: any;
  input?: any;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  parentClass?: string;
  reduxform?: any;
  showClearDate?: any;
  showDefaultInputIcon?: any;
  isOutsideRange?: any;
  numberOfMonths?: any;
  maxLength?: any;
  classNames?: any;
  fieldRef?: any; // pass-through for `react-hook-form`
}

/**  Deprecated:
 * Fingers crossed, we will soon we will have a brand new datepicker to replace both this,
 * and DatepickerField from datepicker.tsx .
 * */
export function DeprecatedReduxDatepicker(props: PropsTypes) {
  const {
    handleChange = () => {},
    disabled,
    label,
    name = '',
    value = '',
    meta = {},
    optional,
    input,
    placeholder = usaDateFormat,
    minDate = moment(),
    maxDate,
    parentClass,
    reduxform = false,
    showClearDate = false,
    isOutsideRange = false,
    numberOfMonths = 1,
    fieldRef,
  } = props;
  const { showDefaultInputIcon = true } = props;
  const { touched, error } = meta || {};
  const [focused, setFocused] = useState(false);
  const [internalError, setInternalError] = useState('');
  const idGen = reduxform ? `single_date_redux_${input.name}` : `single_date_${name}`;
  const dispatch = useDispatch();

  const returnYears = () => {
    const years = [];
    for (let i = moment().year() - datePickerYear; i <= moment().year() + datePickerYear; i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <select
            // style={{ border: '1px solid #eee' }}
            value={month.month()}
            onChange={e => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            // style={{ border: '1px solid #eee' }}
            value={month.year()}
            onChange={e => onYearSelect(month, e.target.value)}
          >
            {returnYears()}
          </select>
        </div>
      </div>
    );
  };

  const settingErrorFn = useCallback(
    (value: string) => {
      setInternalError(value);
      dispatch({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { invaliddateerror: value },
      });
    },
    [dispatch],
  );

  const onSingleDateChange = useCallback(
    (date: Moment | null | string = '') => {
      if (reduxform) {
        input.onChange(date);
      } else {
        handleChange(name, date);
      }
      if (moment(date, usaDateFormat).isValid()) {
        settingErrorFn('');
      } else {
        settingErrorFn(invalidDateMessage);
      }
    },
    [handleChange, input, name, reduxform, settingErrorFn],
  );

  const checkDateFn = useCallback(
    (event: KeyboardEvent) => {
      const evt = event.target as HTMLInputElement;
      if (evt && !evt.value) {
        settingErrorFn('');
      }
      if (evt && evt.value && !moment(evt.value, usaDateFormat).isValid()) {
        settingErrorFn(invalidDateMessage);
      }
    },
    [settingErrorFn],
  );

  const convertDateFn = useCallback(
    event => {
      const evt = event.target;
      const finaldate =
        evt && evt.value && evt.value.length >= 6 ? deprecatedConvertUSAFormat(evt.value) : '';
      if (finaldate && validateTypedDate(finaldate, minDate, maxDate, isOutsideRange)) {
        onSingleDateChange(moment(finaldate));
      } else {
        if (evt.value) settingErrorFn(invalidDateMessage);
      }
    },
    [isOutsideRange, maxDate, minDate, onSingleDateChange, settingErrorFn],
  );

  useEffect(() => {
    const datePicker = document.getElementById(idGen);
    if (datePicker) {
      datePicker.addEventListener('keyup', checkDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('keyup', checkDateFn);
      }
    };
  }, [checkDateFn, idGen]);

  useEffect(() => {
    const datePicker = document.getElementById(idGen);
    if (datePicker) {
      datePicker.addEventListener('focusout', convertDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('focusout', convertDateFn);
      }
    };
  }, [convertDateFn, idGen]);

  const onCloseValidationFn = () => {
    setTimeout(() => {
      settingErrorFn('');
    }, 10);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { invaliddateerror: '' } });
    };
  }, [dispatch]);

  return (
    <div
      className={
        'datePickerWrapper ' +
        parentClass +
        ((touched && error) || internalError ? ' errorWrapper' : '')
      }
      ref={fieldRef}
    >
      <div title={usaDateFormat}>
        <label title={usaDateFormat}>{label}</label>
        {reduxform ? (
          <SingleDatePicker
            {...input}
            date={input.value ? input.value : null} // momentPropTypes.momentObj or null
            onDateChange={onSingleDateChange} // PropTypes.func.isRequired
            focused={focused} // PropTypes.bool
            numberOfMonths={numberOfMonths}
            onFocusChange={({ focused }: any) => setFocused(focused)} // PropTypes.func.isRequired
            id={idGen} // PropTypes.string.isRequired,
            maxDate={maxDate ? moment(maxDate) : null}
            minDate={moment(minDate)}
            disabled={disabled}
            isOutsideRange={(day: any) => {
              if (!isOutsideRange) {
                if (minDate && maxDate) {
                  return day.isAfter(maxDate) || day.isBefore(minDate);
                }
                if (minDate) return day.isBefore(minDate);
              }
            }}
            showClearDate={disabled ? false : showClearDate}
            showDefaultInputIcon={showDefaultInputIcon}
            onClearDates={onSingleDateChange}
            placeholder={placeholder}
            renderMonthElement={renderMonthElement}
            hideKeyboardShortcutsPanel={true}
            readOnly={disabled}
            customCloseIcon={<i className='mdi mdi-close' onClick={onCloseValidationFn} />}
            displayFormat={() => usaDateFormat}
          />
        ) : (
          <SingleDatePicker
            date={value ? value : null} // momentPropTypes.momentObj or null
            onDateChange={onSingleDateChange} // PropTypes.func.isRequired
            focused={focused} // PropTypes.bool
            numberOfMonths={numberOfMonths}
            onFocusChange={({ focused }: any) => setFocused(focused)} // PropTypes.func.isRequired
            id={idGen} // PropTypes.string.isRequired,
            //maxDate={maxDate?moment(maxDate):null}
            //minDate={moment(minDate)}
            disabled={disabled}
            isOutsideRange={(day: any) => {
              if (!isOutsideRange) {
                if (minDate && maxDate) {
                  return day.isAfter(maxDate) || day.isBefore(minDate);
                }
                if (minDate) return day.isBefore(minDate);
              }
            }}
            showClearDate={showClearDate}
            showDefaultInputIcon={showDefaultInputIcon}
            placeholder={placeholder}
            renderMonthElement={renderMonthElement}
            hideKeyboardShortcutsPanel={true}
            customCloseIcon={<i className='mdi mdi-close' onClick={onCloseValidationFn} />}
            displayFormat={() => usaDateFormat}
          />
        )}
      </div>
      {optional === true ? <span>(optional)</span> : optional ? <span>{optional}</span> : ''}
      <div className='errorMsg'>
        {(touched && error) || internalError ? (
          <>{<span>{internalError ? internalError : error}</span>}</>
        ) : null}
      </div>
    </div>
  );
}
