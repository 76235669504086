import { createActionTypes, createNoLoadActionTypes } from './utils';
export const CHECK_ACCOUNT_EXISTS = createActionTypes('CHECK_ACCOUNT_EXISTS');
export const SET_REGISTRATION_DATA = createNoLoadActionTypes('SET_REGISTRATION_DATA');
export const ADD_INCOMPLETE_REGISTRATION_DATA = createNoLoadActionTypes(
  'ADD_INCOMPLETE_REGISTRATION_DATA',
);
export const ADD_MEMBER_ACCOUNT = createActionTypes('ADD_MEMBER_ACCOUNT');
export const LOAD_PRE_INCOMPLETE_REGISTRATION = createActionTypes(
  'LOAD_PRE_INCOMPLETE_REGISTRATION',
);
export const CLAIM_PLANHOLDER_ACCOUNT = createActionTypes('CLAIM_PLANHOLDER_ACCOUNT');
