import React, { PropsWithChildren } from 'react';
import { Helper } from '../../../common/controls/text/Helper';
import { Label } from '../../../common/controls/text/Label';

import {
  NotificationContentCell,
  NotificationFormRow,
  NotificationLabelCell,
} from './../styled/AwardBidStyled';

type NotificationRowProps = PropsWithChildren<{
  label: string;
  subLabel?: string;
  condensed?: boolean;
  required?: boolean;
}>;

export const NotificationRow = (props: NotificationRowProps) => {
  const { children, condensed, label, subLabel, required } = props;

  return (
    <NotificationFormRow condensed={condensed}>
      <NotificationLabelCell>
        <Label required={required}>{label}:</Label>
        {subLabel && <Helper block={true}>{subLabel}</Helper>}
      </NotificationLabelCell>
      <NotificationContentCell>{children}</NotificationContentCell>
    </NotificationFormRow>
  );
};
