import React, { memo } from 'react';
import { noresultFound } from '../../utils/texts';

interface PropsTypes {
  message: string | (string | JSX.Element)[];
  onClick?: any;
  type?: string;
  pClass?: string;
}

function NoResult(props: PropsTypes) {
  const { message = noresultFound, onClick = () => {}, pClass = '' } = props;
  return (
    <div className='container my-3 noResultWrapper' onClick={onClick}>
      <div className='row align-items-center'>
        <div className='col p-3'>
          <div
            className={'mb-0 ' + (pClass !== '' ? pClass : null)}
            data-testid='common.nomessage.text'
          >
            {message === 'Loading data' ? (
              <>
                <i className='mdi mdi-loading mdi-spin mdi-24px mr-2' />
                {message}
                <div className='spinner'>
                  <div className='bounce1' />
                  <div className='bounce2' />
                  <div className='bounce3' />
                </div>
                {/*<ContentloaderComponent type={type} />*/}
              </>
            ) : (
              <>
                <i className='mdi mdi-information-outline mr-2' /> {message}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(NoResult);
