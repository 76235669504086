import React from 'react';
import { DeprecatedRadio } from '../../../customcontrols/DeprecatedRadio';
import { addbidParamType } from '../../../../types/addbid';
import { Buttons } from '../../../customcontrols';

type PropsType = {
  addbid: addbidParamType;
  setAddBidDetails?: any;
  updateSuplimentalSheetUpdate?: any;
};

const SelectSheet = (props: PropsType) => {
  const { setAddBidDetails, updateSuplimentalSheetUpdate, addbid } = props;
  const { selectedSheet, docId } = addbid;
  const sheets = addbid.sheets as string[];
  const handleRadioClick = (name: string, value: any) => {
    setAddBidDetails({ selectedSheet: value });
  };

  return (
    <>
      <p className='mb-2 mt-3'>
        There is multiple sheets in a uploaded Excel File. Please choose a sheet which you want to
        upload
      </p>
      {sheets.map((item: string, index: number) => (
        <DeprecatedRadio
          key={index}
          title={item}
          name='selectedSheet'
          value={item}
          handleSelect={handleRadioClick}
          defaultChecked={selectedSheet === item}
        />
      ))}
      <div className='d-flex justify-content-center mt-5'>
        <Buttons
          type='button'
          text='Upload List'
          classNames='bttn-primary w-50'
          onClick={() => updateSuplimentalSheetUpdate({ sheetName: selectedSheet, id: docId })}
          disable={selectedSheet ? false : true}
        />
      </div>
    </>
  );
};

export default SelectSheet;
