import React, { useState } from 'react';
// import dswLogo from '../../../assets/images/logo_white.svg'
import { Buttons } from '../../customcontrols';
import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import imgsCdn from '../../../assets/images/imagescdn';
import { constants } from '../../../utils/settings';

const dswLogo = imgsCdn.otherImg.dsWhiteLogo;

interface PreviewImageProps {
  agencyregistration: agencyRegistrationParamType;
  togglePreviewPagePopup: () => void;
}

const PreviewImage = (props: PreviewImageProps) => {
  const {
    agencyregistration,
    togglePreviewPagePopup /* setAgencyRegistrationDetails, updateAgencyPageSettings */,
  } = props;
  const { pageSettingFields } = agencyregistration || {};

  let bannerUrlPath = '';
  let logoUrlPath = '';
  if (pageSettingFields.banner.name) {
    bannerUrlPath =
      pageSettingFields.banner.imageFrom === 'api'
        ? pageSettingFields.banner.baseUrl + pageSettingFields.banner.name
        : URL.createObjectURL(pageSettingFields.banner);
  } else {
    bannerUrlPath = constants.contentBlobUrl + 'banners/defaultbanner.jpg';
  }

  if (pageSettingFields.logo.name) {
    logoUrlPath =
      pageSettingFields.logo.imageFrom === 'api'
        ? pageSettingFields.logo.baseUrl + pageSettingFields.logo.name
        : URL.createObjectURL(pageSettingFields.logo);
  } else {
    logoUrlPath = constants.contentBlobUrl + 'logos/defaultlogo.png';
  }

  return (
    <>
      <div className='previewWrapper'>
        <div
          className='alBannerWrapper'
          style={{
            backgroundImage: `url(${bannerUrlPath})`,
            backgroundPosition:
              'center ' + pageSettingFields.imgPos
                ? pageSettingFields.imgPos.type === 'position'
                  ? pageSettingFields.imgPos.value
                  : '50% ' + pageSettingFields.imgPos.value + 'px'
                : '',
          }}
        >
          <div className='alTitleLogo'>
            <div className='container'>
              <div className='alInnerCol'>
                <div className='alLogo' style={{ background: pageSettingFields.theme.value }}>
                  <div>
                    <img src={logoUrlPath} alt='Logo' />
                  </div>
                </div>
              </div>
              <div className='poweredBy'>
                Powered by <img width='94px' src={dswLogo} alt='Logo' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-block mt-4'>
        <Buttons
          text='Close'
          classNames='bttn-primary float-right'
          onClick={() => {
            togglePreviewPagePopup();
          }}
        />
      </div>
    </>
  );
};

export default PreviewImage;
