import React, { memo } from 'react';
import { Field, Formik } from 'formik';
import { Buttons } from '../../../customcontrols';
import { toastFn } from '../../../../utils/helpers';
import { renderDeprecatedTextField } from '../../../common/controls/inputs/text';
import { refundValidation } from './validation';
import { paymentsType } from '../../../../types/paymentdetails';

interface PropsTypes {
  pristine?: boolean;
  submitting?: boolean;
  valid?: boolean;
  payment?: paymentsType;
  handleRefund?: any;
  onCancel?: any;
  handleSubmit?: any;
  initialValues: any;
}

function RefundPayment(props: PropsTypes) {
  const {
    pristine,
    submitting,
    valid,
    payment = {},
    handleRefund,
    onCancel,
    handleSubmit,
    initialValues,
  } = props;

  const onSubmitFn = (data: any) => {
    if (data) {
      if (payment && payment.paidAmount && data.refundAmount > payment.paidAmount) {
        toastFn('error', 'Refund amount is too large');
      } else {
        handleRefund(data);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={refundValidation}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const {
            values: any,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValidating,
            validateForm,
            isValid,
            dirty,
          } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <div className='mb-4'>
                {' '}
                <label>
                  Refunding to {payment ? payment.creditCardName + ' ' : ''}
                  card ending {payment ? payment.ccNumberLastFourDigits : ''}{' '}
                </label>
              </div>

              <Field
                label='Refund Amount'
                type='text'
                classNames='class'
                placeholder='Enter Refund Amount'
                name='refundAmount'
                component={renderDeprecatedTextField}
                optional={`Maximum allowed $${payment && payment.paidAmount}`}
                onChange={setFieldValue}
              />

              <Field
                label='Reason for Refund'
                type='text'
                classNames='class'
                placeholder='Enter Reason for Refund'
                name='refundNotes'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
              />
              <Buttons
                classNames='bttn-secondary mt-2'
                text='Cancel'
                title='Cancel'
                type='button'
                onClick={onCancel}
              />
              <Buttons
                classNames='bttn-primary  mt-2 float-right'
                text='Process Refund'
                title='Process Refund'
                type='submit'
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(RefundPayment);
