import React, { memo, useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable } from 'recoil';
import styled from 'styled-components';

import { Paginate } from '@demandstar/components/paginate';

import { agenciesByProductState, agencyStateCountyState } from '../../store/recoil/agencyState';
import {
  allProductsState,
  countyZeroState,
  initialCountyProduct,
  initialNationalProduct,
  selectedProductsState,
  upsellProductsState,
} from '../../store/recoil/productState';
import { Buttons, CheckBox, DeprecatedInput } from '../customcontrols';
import { PrepopulatedProducts, Product } from '../../types/subscriptions';
import { ProductApiResponse, SubscriptionProducts } from '../../types/products';
import { AgencyStateCounty } from '../../types/agencyinfo';
import { compareObjectsByKey } from '../../utils/sort';
import { prepopulatedProductsState } from '../../store/recoil/subscriptionState';
import { RegistrationUpsellCardData } from '../../types/registration';

import { getAgencyCounts } from './helpers';

interface SelectCountyProps {
  hasBundle: boolean;
  isRegistration: boolean;
  parentState: ProductApiResponse;
}

const Ul = styled.ul`
  list-style-type: none;

  li {
    label {
      display: inline-block;
      width: 20rem;
    }

    > span {
      float: right;
      margin-right: 2em;
    }
  }
`;

const SelectCounty = (props: SelectCountyProps) => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const { hasBundle, parentState } = props;

  const [checkboxZero, setCheckboxZero] = useRecoilState<boolean>(countyZeroState);

  const [agenciesByProduct, setAgenciesByProduct] = useRecoilState<ProductApiResponse[]>(
    agenciesByProductState,
  );
  const [agencyStateCounty, setAgencyStateCounty] = useRecoilState<AgencyStateCounty>(
    agencyStateCountyState,
  );
  const [prepopulatedProducts, setPrepopulatedProducts] = useRecoilState<PrepopulatedProducts>(
    prepopulatedProductsState,
  );
  const [selectedProducts, setSelectedProducts] = useRecoilState<SubscriptionProducts>(
    selectedProductsState,
  );
  const [upsellProducts, setUpsellProducts] = useRecoilState<RegistrationUpsellCardData[]>(
    upsellProductsState,
  );

  const [allProductsLoaded, setAllProductsLoaded] = useState([initialNationalProduct]);
  const [countiesList, setCountiesList] = useState([initialCountyProduct]);
  const [filteredCounties, setFilteredCounties] = useState(countiesList);
  const [parentPrice, setParentPrice] = useState(0);
  const [selectedCounties, setSelectedCounties] = useState([0]);
  const [selectedStates, setSelectedStates] = useState([0]);

  const getAgenciesForModal = (productId: number) => {
    const agenciesByProduct = allProductsLoaded.filter(
      product => product.productType === 'AG' && product.parentId === productId,
    );
    setAgenciesByProduct(agenciesByProduct.sort(compareObjectsByKey('productName')));
  };

  const handleChecked = (name: string, value: boolean) => {
    const checkedCounties: ProductApiResponse[] = selectedProducts.county || [];
    const checkingCounty: ProductApiResponse[] = countiesList.filter(
      county => county.productName === name,
    );
    // add county to the array
    if (value) {
      if (checkboxZero) setCheckboxZero(false);
      setSelectedProducts({
        ...selectedProducts,
        county: checkingCounty.concat(checkedCounties).sort(compareObjectsByKey('productName')),
      });

      // remove upsellCard
      const newUpsellProducts = upsellProducts.filter(
        (product: RegistrationUpsellCardData) => product.product !== checkingCounty[0],
      );
      setUpsellProducts(newUpsellProducts);
    }
    // remove county from the array
    else {
      const stillSelectedCounties = checkedCounties.filter(county => {
        if (county.productId !== checkingCounty[0].productId) {
          return county;
        }
      });
      if (
        stillSelectedCounties.length === 0 &&
        selectedProducts.national === 0 &&
        selectedProducts.state?.length === 0
      ) {
        setCheckboxZero(true);
      }
      setSelectedProducts({ ...selectedProducts, county: stillSelectedCounties });
    }
  };

  const handleSelectStateClick = () => {
    if (checkboxZero) setCheckboxZero(false);

    // remove upsellCard
    const newUpsellProducts = upsellProducts.filter(
      (product: RegistrationUpsellCardData) =>
        !(
          product.product.productId === parentState.productId ||
          product.product.parentId === parentState.productId
        ),
    );
    setUpsellProducts(newUpsellProducts);

    setSelectedProducts({
      ...selectedProducts,
      state: selectedProducts.state?.concat(parentState).sort(compareObjectsByKey('productName')),
    });
  };

  const searchCounty = (name: string, text: string) => {
    const filteredCountiesList = countiesList.filter((county: ProductApiResponse) =>
      county.productName.toLowerCase().includes(text.toLowerCase()),
    );
    setFilteredCounties(filteredCountiesList);
  };

  const subscribeToStateButtonText = (
    <>
      Subscribe to {parentState.productName} for ${parentPrice} / year
    </>
  );

  useEffect(() => {
    let needCounties = true;
    function getCountiesByState() {
      /* istanbul ignore else */
      if (allProducts.state === 'hasValue') {
        setAllProductsLoaded(allProducts.contents);
        const countiesByState: ProductApiResponse[] = allProducts.contents.filter(
          product => product.productType === 'CT' && product.parentName === parentState.productName,
        );
        countiesByState.sort(compareObjectsByKey('productName'));

        setParentPrice(countiesByState[0].parentPrice || 0);
        setCountiesList(countiesByState);
        setFilteredCounties(countiesByState);
      }
    }

    /* istanbul ignore else */
    if (
      needCounties &&
      allProducts.state === 'hasValue' &&
      countiesList[0].parentName !== parentState.productName
    ) {
      getCountiesByState();
    }

    function getSelectedProducts(products: ProductApiResponse[], type: Product['productType']) {
      const temp = products.map(item => item.productId);
      switch (type) {
        case 'ST':
          setSelectedStates(temp);
          break;
        case 'CT':
          if (temp.length !== selectedCounties.length || selectedCounties[0] === 0)
            setSelectedCounties(temp);
          break;
      }
    }

    /* istanbul ignore else */
    if (needCounties && selectedProducts.county) getSelectedProducts(selectedProducts.county, 'CT');
    /* istanbul ignore else */
    if (needCounties && selectedProducts.state) getSelectedProducts(selectedProducts.state, 'ST');

    return () => {
      needCounties = false;
    };
  }, [allProducts, countiesList, parentState, selectedProducts, selectedCounties]);

  return (
    <div>
      {hasBundle ? (
        <>
          <h5>Build your own Package</h5>
          <p className='no-top-padding'>
            If none of our packages match your needs, build your own package below by selecting the
            counties where you'd like to do business.
          </p>
        </>
      ) : (
        <>
          <h5>Choose your counties in {parentState.productName}</h5>
          {countiesList.length > 1 ? (
            <p className='no-top-padding'>
              There are currently {countiesList.length} {parentState.productName} counties with
              agencies publishing bids on DemandStar. Please choose which counties you'd like to
              subscribed to. You may also subscribe to all counties in {parentState.productName} and
              save!
            </p>
          ) : (
            <p className='no-top-padding'>
              There is currently {countiesList.length} {parentState.productName} county with
              agencies publishing bids on DemandStar. Please select the county to subscribe.
            </p>
          )}

          {!selectedProducts.state?.includes(parentState) && (
            <div>
              <Buttons
                classNames='bttn bttn-primary m-auto d-block'
                icon='mdi-plus'
                onClick={handleSelectStateClick}
                text={subscribeToStateButtonText}
              />
              <br />
            </div>
          )}
        </>
      )}

      <div>
        <DeprecatedInput
          handleChange={searchCounty}
          label='Search for Counties'
          name='Search'
          placeholder='Search for Counties'
          title='Search for Counties'
          type='text'
        />
      </div>
      <Ul>
        <Paginate data={filteredCounties}>
          {data =>
            data.map((county: ProductApiResponse) => (
              <li key={county.productId}>
                <CheckBox
                  checked={selectedCounties.includes(county.productId)}
                  dataTestId={county.productName}
                  handleChecked={(name: string, value: boolean) => {
                    handleChecked(name, value);
                  }}
                  indeterminate={
                    selectedProducts.national === 10156 ||
                      (county.parentId && selectedStates.includes(county.parentId))
                      ? true
                      : false
                  }
                  name={county.productName}
                  title={county.productName}
                />
                <i className='staticLink' onClick={() => getAgenciesForModal(county.productId)}>
                  View {getAgencyCounts(allProductsLoaded, county.productId, 'CT')}{' '}
                  {getAgencyCounts(allProductsLoaded, county.productId, 'CT') > 1
                    ? 'Agencies'
                    : 'Agency'}
                </i>
                <span className='reg-span'>${county.price}/year</span>
              </li>
            ))
          }
        </Paginate>
      </Ul>
    </div>
  );
};
export default memo(SelectCounty);
