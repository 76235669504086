import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';

type dataTypes = Record<string, unknown> | undefined;

/* Bids Payment api calls*/
export function getBidsPaymentCardDetails(data = {}) {
  return Api.postRequestWithAuthentication(Paths.cardinfo, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function addChargePayment(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.createcardprofile, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function changeBillingAddress(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.changebilladdress, data, {
    baseURL: constants.api.userUrl,
  });
}

export function submitebitpayment(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.purchasebid, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function createUpdateBTProfile(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.createUpdateBTProfile, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function getPaymentHistory(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.paymentHistory, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function processCreditTransaction(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.processCreditTransaction, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}
