/*
	PATH: accountinfo/certifications/list
	DESC: show list of certifications added by agency
	ACTIONS: Add, Edit, Delete
*/
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { pageSize } from '../../../../utils/constants';
import { loadingMsg } from '../../../../utils/texts';
import { AddProgram } from '../../../common/breadcrumb/buyer/programcontrol';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { Buttons, Paging } from '../../../customcontrols';
import { NoResult } from '../../../customcontrols/index';
import ProgramsAddEdit from './programsaddedit';
import { programsType } from '../../../../types/programtypedef';
import { sharedTypes } from '../../../../types/shared';
import { programsParamType } from '../../../../types/accountinfo';

const applications = [
  {
    title: 'Accepted',
    label: 'Accepted',
    value: true,
    key: 'Accepted',
  },
  {
    title: 'Not Accepted',
    label: 'Not Accepted',
    value: false,
    key: 'Not Accepted',
  },
];

const certifications = [
  {
    title: 'Required',
    label: 'Required',
    value: true,
    key: 'Required',
  },
  {
    title: 'Not Required',
    label: 'Not Required',
    value: false,
    key: 'Not Required',
  },
] as any;

const tdWidth = {
  width: '100px',
};

interface PropsTypes {
  setBreadcrumb?: any;
  getPrograms?: any;
  programs?: programsType;
  addUpdateProgram?: any;
  getSelfDeclarations?: any;
  shared?: sharedTypes;
  activeTab?: string;
  hasEditProgramPermission?: boolean;
  certifications?: any;
}

function ProgramsList1(props: PropsTypes) {
  const {
    setBreadcrumb,
    getPrograms,
    programs,
    addUpdateProgram,
    shared,
    activeTab,
    hasEditProgramPermission,
  } = props;
  const { selfdeclaredattributes = [], internalLoader } = shared || {};
  const [modal, setModal] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentModalTitle, setCurrentModalTitle] = useState('Add Certification');
  const listPerPage = pageSize.default;
  // Logic for displaying pagination
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const toggle = useCallback(
    (program?: Partial<programsParamType>) => {
      if (!modal && program) {
        const selfdecAttr = selfdeclaredattributes.map(() => false);
        if (program.attributeType) {
          const selectedAttributes = program.attributeType.split(', ');
          selfdeclaredattributes.forEach((item, index) => {
            selfdecAttr[index] = selectedAttributes.includes(item.attributeType);
          });
        }
        const temp = {
          id: program.id || 0,
          name: program.name,
          description: program.description,
          acceptApplications: applications.find(f => f.value === program.acceptApplications),
          certificationRequired: certifications.find(
            (f: any) => f.value === program.certificationRequired,
          ),
          selfdecAttr,
        };
        setInitialValues(temp);
        setCurrentModalTitle(temp.id ? 'Update Certification' : 'Add Certification');
      }
      toggleModal();
    },
    [modal, selfdeclaredattributes, toggleModal],
  );

  const handleDelete = (program: { id: number; isDeleted: boolean }) => {
    addUpdateProgram({ id: program.id, isDeleted: true });
  };

  useEffect(() => {
    getPrograms();
  }, [getPrograms]);

  useEffect(() => {
    if (activeTab === 'list') {
      setBreadcrumb({
        page: 'Agency Programs',
        altname: 'Certifications',
        title: 'Certifications',
        name: 'Certifications',
        menuactive: '',
        component: hasEditProgramPermission ? (
          <AddProgram
            submit={() => {
              toggle({});
            }}
          />
        ) : null,
      });
    }
    // eslint-disable-next-line
  }, [setBreadcrumb, toggle, activeTab]); // TOREFACTOR: Don't ignore this.

  return (
    <>
      <h3 className='arrowWrapper'>Certification List</h3>
      <div className='clearfix'>
        {programs && programs.programs.length > 0 ? (
          <Table>
            <tr>
              <th>Name</th>
              <th>Applications</th>
              <th>Required Yes / No</th>
              <th>Supplier Count</th>
              <th>Attributes</th>
              {hasEditProgramPermission && <th style={tdWidth}>Actions</th>}
            </tr>
            {programs.programs
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.acceptApplications ? 'Accepted' : 'Not Accepted'}</td>
                    <td>{item.certificationRequired ? 'Required' : 'Not Required'}</td>
                    <td>{item.certifiedMemberCount}</td>
                    <td>{item.attributeName}</td>
                    {hasEditProgramPermission && (
                      <td>
                        <i
                          className='mdi mdi-delete-forever-outline staticLink mdi-24px'
                          title='Delete'
                          onClick={() => {
                            handleDelete(item as any);
                          }}
                        />
                        <i
                          className='mdi mdi-square-edit-outline staticLink ml-3 mdi-24px'
                          title='Edit'
                          onClick={() => {
                            toggle(item);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })
              .slice(indexOfFirstList, indexOfLastList)}
          </Table>
        ) : (
          <NoResult message={internalLoader ? loadingMsg : 'No Certifications Available'} />
        )}
        <Paging
          totalRecords={programs && programs.programs.length}
          currentPage={currentPage}
          onPagingClick={(value: number) => {
            if (value !== currentPage) setCurrentPage(Number(value));
          }}
          pageLimit={listPerPage}
        />
        <ModalPopUp
          size='lg'
          title={currentModalTitle}
          closeModal={toggleModal}
          isOpen={modal}
          backdrop='static'
        >
          <ProgramsAddEdit
            initialValues={initialValues}
            certifications={certifications}
            applications={applications}
            handleCancel={toggle}
            shared={props.shared}
            addUpdateProgram={props.addUpdateProgram}
          />
        </ModalPopUp>
        {hasEditProgramPermission && (
          <Buttons
            classNames='bttn-primary my-4 float-right'
            text='Add Certification'
            title='Add Certification'
            type='button'
            onClick={() => toggle({})}
          />
        )}
      </div>
    </>
  );
}

export const ProgramsList = memo(ProgramsList1);
