import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Buttons, Paging, ScrollButton, SelectBox } from '../../../customcontrols';
import DateRangePickerComponent from '../../../customcontrols/daterangepicker';
import {
  quoteReportsActivityPaginationsOptions,
  reportbidStatus,
  reportotherbidStatus,
  reportotherquoteStatus,
  reportquoteStatus,
  usaDateFormat,
} from '../../../../utils/constants';
import { loadingMsg } from '../../../../utils/texts';
import moment from 'moment-timezone';
import { activitiesTypes, memberinfoTypes } from '../../../../types';
import NoResult from '../../../customcontrols/noresult';
import { authTypes } from '../../../../types/auth';
import { reactselectTypes } from '../../../../types/shared';
import { quotereports_quotesInitialValue } from '../../../../store/reducers/activities';
import { deprecatedConvertotherTimezonetoUTC } from '../../../../utils/helpers';

const style = {
  tdWidth: { width: '140px' },
  tdWidth1: { minWidth: '160px' },
  tdWidth2: { minWidth: '220px' },
};

interface PropTypes {
  auth: authTypes;
  activities: activitiesTypes;
  memberinfo: memberinfoTypes;
  setBreadcrumb: any;
  getQuoteActivityReport: any;
  exportQuoteActivityReport: any;
  setActivitiesDetails: any;
  memberInfo: any;
}

const QuoteActivityReport = (props: PropTypes) => {
  const {
    setBreadcrumb,
    getQuoteActivityReport,
    auth,
    activities,
    exportQuoteActivityReport,
    memberinfo,
    setActivitiesDetails,
    memberInfo,
  } = props;
  const {
    activities_quotereports,
    quotereports_status,
    quotereports_startDate,
    quotereports_endDate,
    quotereport_internalLoader,
    activities_quotereports_search,
    activities_quotereports_search_result,
    quoteReportsActivityPaginations,
  } = activities || {};
  const {
    quoteActivity = [],
    cancelledQuotes = 0,
    closedQuotes = 0,
    incompleteQuotes = 0,
    awardedQuotes = 0,
    openQuotes = 0,
  } = activities_quotereports || {};
  const {
    quoteActivity: ResultquoteActivity = [],
    cancelledQuotes: ResultcancelledQuotes = 0,
    closedQuotes: ResultclosedQuotes = 0,
    incompleteQuotes: ResultincompleteQuotes = 0,
    awardedQuotes: ResultawardedQuotes = 0,
    openQuotes: ResultopenQuotes = 0,
  } = activities_quotereports_search_result || {};
  const { mi } = auth || {};

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastLists = currentPage * quoteReportsActivityPaginations?.value;
  const indexOfFirstLists = indexOfLastLists - quoteReportsActivityPaginations?.value;

  const { tzfn } = memberinfo || {};

  useEffect(() => {
    setBreadcrumb({
      page: 'Activities',
      altname: 'Reports',
      title: 'Reports',
      name: 'Reports',
      menuactive: 'activities',
    });
  }, [setBreadcrumb]);

  useEffect(() => {
    return () => {
      setActivitiesDetails({
        activities_quotereports_search: false,
        quotereports_startDate: moment().subtract(1, 'months').format(usaDateFormat),
        quotereports_endDate: moment().format(usaDateFormat),
        quotereports_status: reportbidStatus[0],
        activities_quotereports_search_result: quotereports_quotesInitialValue,
      });
    };
  }, [setActivitiesDetails]);

  const ApiCallFn = (
    startDate: string,
    endDate: string,
    statusList: string,
    search: boolean,
    type: string,
  ) => {
    let final_value: any = {
      quotereport_internalLoader: true,
      activities_quotereports_search: search,
      activities_quotereports: quotereports_quotesInitialValue,
      activities_quotereports_search_result: quotereports_quotesInitialValue,
    };
    if (type === 'clear') {
      final_value = {
        ...final_value,
        quotereports_startDate: moment().subtract(1, 'months'),
        quotereports_endDate: moment(),
        quotereports_status: reportbidStatus[0],
      };
    }
    setActivitiesDetails(final_value);
    if (startDate) startDate = deprecatedConvertotherTimezonetoUTC(startDate, tzfn, usaDateFormat);
    if (endDate) endDate = deprecatedConvertotherTimezonetoUTC(endDate, tzfn, usaDateFormat);
    getQuoteActivityReport({ memberId: mi, startDate, endDate, statusList, search: search });
    setCurrentPage(1);
  };

  const onChangeSelectBox = (name: string, value: reactselectTypes) => {
    setActivitiesDetails({ [name]: value });
    if (value?.value === 'quoteReportsActivityPaginations') {
      setCurrentPage(1);
    }
  };

  const onInputChange = (name: string, value: React.SetStateAction<moment.Moment>) => {
    if (name === 'startDate') {
      setActivitiesDetails({ quotereports_startDate: value });
    } else {
      setActivitiesDetails({ quotereports_endDate: value });
    }
  };

  const onSearchFn = () => {
    const apistartDate = quotereports_startDate
      ? moment(quotereports_startDate).format(usaDateFormat)
      : '';
    const apiendDate = quotereports_endDate
      ? moment(quotereports_endDate).format(usaDateFormat)
      : '';
    const statusList =
      quotereports_status?.value === 'all' ? reportotherquoteStatus : quotereports_status?.value;
    ApiCallFn(apistartDate, apiendDate, statusList, true, '');
  };

  const onClearFn = () => {
    const apistartDate = moment().subtract(1, 'months').format(usaDateFormat);
    const apiendDate = moment().format(usaDateFormat);
    ApiCallFn(apistartDate, apiendDate, reportotherquoteStatus, false, 'clear');
  };

  const reports_list = activities_quotereports_search ? ResultquoteActivity : quoteActivity;
  const finalincompleteQuotes = activities_quotereports_search
    ? ResultincompleteQuotes
    : incompleteQuotes;
  const finalcancelledQuotes = activities_quotereports_search
    ? ResultcancelledQuotes
    : cancelledQuotes;
  const finalclosedQuotes = activities_quotereports_search ? ResultclosedQuotes : closedQuotes;
  const finalawardedQuotes = activities_quotereports_search ? ResultawardedQuotes : awardedQuotes;
  const finalopenQuotes = activities_quotereports_search ? ResultopenQuotes : openQuotes;

  const nodata_text = activities_quotereports_search
    ? 'No Search Result Available'
    : 'No Data Available';

  const nodata_component = (
    <tr>
      <td colSpan={12}>
        <NoResult message={quotereport_internalLoader ? loadingMsg : nodata_text} />
      </td>
    </tr>
  );

  return (
    <>
      {/* <div className='row'>
        <div className='col-lg-12' data-testid='activities.bids.reports.head'>
          <h2 className='arrowWrapper'>Quote Reports ({reports_list.length})</h2>

        </div>
      </div> */}
      <div className='row mb-3'>
        <div className='col-lg-12'>
          <div
            className='clearfix d-flex align-items-center py-3 activitiesFilter'
            data-testid='activities.bids.reports.inputs'
          >
            <SelectBox
              reactselect={true}
              label='Status'
              name='quotereports_status'
              options={reportquoteStatus}
              handleSelect={onChangeSelectBox}
              value={quotereports_status}
            />
            <DateRangePickerComponent
              label='Date Range'
              startDatename='startDate'
              endDatename='endDate'
              handleChange={onInputChange}
              isOutsideRange={true}
              startDatevalue={quotereports_startDate}
              endDatevalue={quotereports_endDate}
              showClearDate={true}
              parentClass='clearfix mr-3 mb-0'
            />
            <Buttons
              text='Search'
              title='Search'
              classNames='bttn-primary'
              onClick={onSearchFn}
              disable={!quotereports_startDate || !quotereports_endDate}
            />
            <Buttons
              classNames='bttn-secondary'
              text='Reset'
              title='Reset'
              type='button'
              onClick={onClearFn}
            />
          </div>
        </div>
      </div>
      <div className='row mb-3 countsWrapper'>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.under'>
          <h2>{finalincompleteQuotes}</h2>
          <p>Incomplete</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.total'>
          <h2>{finalopenQuotes}</h2>
          <p>Open</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.award'>
          <h2>{finalawardedQuotes}</h2>
          <p>Awarded</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.active'>
          <h2>{finalclosedQuotes}</h2>
          <p>Closed</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.upcoming'>
          <h2>{finalcancelledQuotes}</h2>
          <p>Cancelled</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='d-block clearfix mb-4' />
          <div className='bidReportScrollContainer'>
            <ScrollButton direction='left' elementId='bidReportTable'></ScrollButton>
            <div className='table-responsive' id='bidReportTable'>
              <Table className='tableHData' data-testid='activities.bids.reports.maintable.div'>
                <thead>
                  <tr data-testid='activities.bids.reports.maintable.head'>
                    <th style={style.tdWidth}>QuoteID</th>
                    <th style={style.tdWidth1}>Quote Number</th>
                    <th style={style.tdWidth2}>Quote Name</th>
                    <th style={style.tdWidth1}>Buyer Account Initials</th>
                    <th style={style.tdWidth1}>Broadcast Date</th>
                    <th style={style.tdWidth1}>Due Date</th>
                    <th style={style.tdWidth}>Status</th>
                    <th style={style.tdWidth2}>Number of Suppliers Broadcast To</th>
                    <th style={style.tdWidth}>Supplier with Certifications</th>
                    <th style={style.tdWidth}>Number of Submitted Quotes</th>
                    <th style={style.tdWidth}>Number of Line Items</th>
                    <th style={style.tdWidth2}>Total Awarded Amount</th>
                  </tr>
                </thead>
                <tbody data-testid='activities.bids.reports.main.table.body.list'>
                  {reports_list && reports_list.length > 0 ? (
                    <>
                      {reports_list
                        .map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.quoteId}</td>
                            <td>{item.quoteNo}</td>
                            <td>{item.quoteTitle}</td>
                            <td>{item.buyerAccountInitials}</td>
                            <td>
                              {item.status !== 'Incomplete' && item.broadcastDate
                                ? moment(item.broadcastDate).format(usaDateFormat)
                                : ''}
                            </td>
                            <td>
                              {item.dueDate ? moment(item.dueDate).format(usaDateFormat) : ''}
                            </td>
                            <td>{item.status}</td>
                            <td>{item.numberofBroadcasts}</td>
                            <td>{item.noOfSuppliersPossessedCertificate}</td>
                            <td>{item.numberOfResponses}</td>
                            <td>{item.numberOfLineItems}</td>
                            <td>{item.awardAmount ? `$${item.awardAmount}` : ''}</td>
                          </tr>
                        ))
                        .slice(indexOfFirstLists, indexOfLastLists)}
                    </>
                  ) : (
                    nodata_component
                  )}
                </tbody>
              </Table>
            </div>
            <ScrollButton direction='right' elementId='bidReportTable'></ScrollButton>
          </div>
          <Paging
            totalRecords={reports_list.length}
            currentPage={currentPage}
            onPagingClick={(value: number) => {
              if (value !== currentPage) setCurrentPage(Number(value));
            }}
            pageLimit={quoteReportsActivityPaginations?.value}
          />
          <div className='row mt-2'>
            <div className='col'>
              <div className='d-flex justify-content-end align-items-center'>
                Display{' '}
                <SelectBox
                  reactselect={true}
                  label='Rows'
                  name='quoteReportsActivityPaginations'
                  options={quoteReportsActivityPaginationsOptions}
                  handleSelect={onChangeSelectBox}
                  value={quoteReportsActivityPaginations}
                  parentClass='clearfix small mx-2'
                  disabled={reports_list.length === 0 ? 'true' : 'false'}
                />
                Results
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(QuoteActivityReport);
