import React, { memo, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../../controls/inputs/text';
import renderSelectField from '../../controls/inputs/selectbox';
import { Buttons, Paging } from '../../../customcontrols';
import { formValidation } from '../../../common/validation';
import { Table } from 'reactstrap';
import { pageSize } from '../../../../utils/constants';
import { addbidParamType } from '../../../../types/addbid';
import { sharedTypes } from '../../../../types/shared';
import { formattedPhoneNumber } from '../../../../utils/helpers';
import { toastFn } from '../../../../utils/helpers';

interface PropsTypes {
  handleSubmit?: any;
  addbid?: addbidParamType;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  valid?: Record<string, unknown> | undefined;
  submitAddBidAddSupplemental?: any;
  bidId?: number;
  setAddBidDetails?: any;
  quotesId?: number | string;
  getStateList?: any;
  shared?: sharedTypes;
  pagefor?: string;
  reset?: any;
  reBroadcast?: boolean;
  submitBrodcastAddBidAddSupplemental?: any;
  initialValues: any;
}

function AddSupplementalSuppliers(props: PropsTypes) {
  const {
    addbid,
    submitAddBidAddSupplemental,
    bidId,
    setAddBidDetails,
    quotesId,
    shared,
    pagefor,
    reBroadcast,
    initialValues,
  } = props;
  const {
    isExistsSupplier,
    existssupplierlist = [],
    supplementalsupplierlist = [],
    broadcastSupplimentalSupplier = [],
    addsupplementalmodal = false,
    saveAndAdd = false,
  } = addbid || {};
  const { stateslist = [] } = shared || {};
  const [existData, setExistFormData] = useState({});

  useEffect(() => {
    return () => {
      setAddBidDetails({ isExistsSupplier: '', existssupplierlist: [] });
    };
  }, [setAddBidDetails]);

  const id = pagefor === 'addquotes' ? quotesId : bidId;
  const type = pagefor === 'addquotes' ? 'Quote' : 'Bid';

  // Logic for displaying pagination
  useEffect(() => {
    setCurrentPages(1);
  }, [existssupplierlist]);
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const AddExistingSupplier = (item: any, index: any) => {
    submitAddBidAddSupplemental({
      id,
      type,
      existingsupplier: true,
      existsupplierdetails: item,
      currentIndex: index,
    });
  };

  const checksupplierAdded = (memberId: number) => {
    const searchedSupplier = supplementalsupplierlist.filter(items => items.mi === memberId) || [];
    return searchedSupplier.length > 0 ? true : false;
  };

  const addExistingSuppliers = () => {
    submitAddBidAddSupplemental({ id, type, allowDuplicate: true, data: existData });
  };

  const onSubmitFn = (data: any, form: any) => {
    if (data) {
      const listSuppliers = supplementalsupplierlist.concat(broadcastSupplimentalSupplier);
      const searchedSupplier = listSuppliers.filter(items => items.email === data.email);
      if (searchedSupplier.length === 0) {
        if (reBroadcast) {
          data.stateId = data.state.id;
          data.countryId = data.state.countryId;
          data.state = data.state.name;
          data.newBrodcast = true;
          setAddBidDetails({
            broadcastSupplimentalSupplier: [...broadcastSupplimentalSupplier, data],
            addsupplementalmodal: saveAndAdd ? true : !addsupplementalmodal,
          });
          toastFn(
            'success',
            `Added ${broadcastSupplimentalSupplier.length + 1} new supplemental suppliers.`,
            'supplimentalBulkUpload',
          );
        } else {
          submitAddBidAddSupplemental({ id, type, data });
        }
        setExistFormData(data);
        // reset();
        form.resetForm();
      } else {
        // throw new SubmissionError({ email: 'Already added' });
        // toastFn("error", "Already added", "Add supplimental supplier");
        form.setFieldError('email', 'Already added');
        // form.setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={formValidation}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const { handleSubmit, setFieldValue, isValid, dirty } = formikProps;

          return (
            <>
              <form onSubmit={handleSubmit}>
                {isExistsSupplier && existssupplierlist.length > 0 ? (
                  <>
                    <div className='headerFilter clearfix d-flex align-items-center'>
                      <div className='flex1'>
                        <h5>
                          {
                            "This supplier may already be in our system. Are any of these the supplier you're trying to add?"
                          }
                        </h5>
                      </div>
                      <span
                        onClick={() =>
                          setAddBidDetails({ isExistsSupplier: '', existssupplierlist: [] })
                        }
                        className='bttn bttn-secondary small float-right staticLink'
                      >
                        <i className='mdi mdi-arrow-left' /> Back
                      </span>
                    </div>
                    <Table className='tableHData'>
                      <thead>
                        <th>Company Name</th>
                        <th>Contact Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>City, State</th>
                        <th>Action</th>
                      </thead>
                      <tbody>
                        {existssupplierlist
                          .map((item, index) => (
                            <tr key={index}>
                              <td>{item.supplierName}</td>
                              <td>{item.contactName}</td>
                              <td>{item.email}</td>
                              <td>{item.phone ? formattedPhoneNumber(item.phone) : ''}</td>
                              <td>
                                {`${item.city ? item.city : ''}${
                                  item.city && item.state ? ',' : ''
                                } ${item.state ? item.state : ''}`}
                              </td>
                              <td>
                                {checksupplierAdded(item.mi) ? (
                                  <span>Already Added</span>
                                ) : (
                                  <span
                                    className='bttn-secondary small'
                                    onClick={() => AddExistingSupplier(item, index)}
                                  >
                                    Add
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))
                          .slice(indexOfFirstLists, indexOfLastLists)}
                      </tbody>
                    </Table>

                    <Paging
                      totalRecords={existssupplierlist.length}
                      currentPage={currentPages}
                      onPagingClick={(value: number) => {
                        if (value !== currentPages) setCurrentPages(Number(value));
                      }}
                      limitmsg={false}
                      pageLimit={listPerPages}
                    />
                    <div className='d-flex align-items-center mt-4'>
                      {/* <h5 className='m-0'> Cannot find your match, please add a new one. </h5>
							<Buttons
								classNames='bttn-primary ml-2'
								onClick={() => addExistingSuppliers()}
								text='Add'
								title='Add'
								type='button'
							/> */}
                      <Buttons
                        classNames='bttn-primary ml-auto'
                        text='None of these match my supplier'
                        title='None of these match my supplier'
                        onClick={() => addExistingSuppliers()}
                        //onClick={() => setAddBidDetails({ isExistsSupplier: '', addsupplementalmodal: false })}
                        type='button'
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Field
                      label='Email'
                      // type='text'
                      // parentClass='class'
                      placeholder='Email'
                      name='email'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      maxLength={100}
                    />
                    <Field
                      label='Company Name'
                      // type='text'
                      // parentClass='class'
                      placeholder='Company Name'
                      name='supplierName'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      optional='Optional'
                      maxLength={150}
                    />
                    <Field
                      label='Contact Name'
                      // type='text'
                      // parentClass='class'
                      placeholder='Contact Name'
                      name='contactName'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      optional='Optional'
                      maxLength={80}
                    />

                    <Field
                      label='Phone'
                      // type='text'
                      // parentClass='class'
                      placeholder='Phone'
                      name='phone'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      optional='Optional'
                      maxLength={17}
                    />
                    <Field
                      name='state'
                      label='State'
                      // type='select'
                      component={renderSelectField}
                      options={stateslist}
                      onChange={(name: string, value: any) => {
                        setFieldValue(name, value);
                      }}
                      optional='Optional'
                    />
                    <Field
                      label='City'
                      // type='text'
                      // parentClass='class'
                      placeholder='City'
                      name='city'
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      optional='Optional'
                      maxLength={50}
                    />
                    <div className='d-flex justify-content-between'>
                      <Buttons
                        classNames='bttn-primary'
                        text='Save This Supplier'
                        title='Add'
                        type='submit'
                        onClick={() => setAddBidDetails({ saveAndAdd: false })}
                        disable={!(isValid && dirty)}
                        // disable={!(dirty && isValid)}
                      />
                      <Buttons
                        classNames='bttn-primary'
                        text='Save and Add Another'
                        title='Add'
                        type='submit'
                        onClick={() => setAddBidDetails({ saveAndAdd: true })}
                        disable={!(isValid && dirty)}
                        // disable={!(dirty && isValid)}
                      />
                    </div>
                  </>
                )}
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(AddSupplementalSuppliers);
