import React, { ComponentType, useMemo, useState } from 'react';
import { Validate } from 'react-hook-form';

import { FormFieldContainer, FormFieldContainerProps } from './styled/FormFieldStyled';
import { BaseTextInput } from '../common/controls/inputs/BaseTextInput';
import { FormFieldLabels } from './FormFieldLabels';

type HTMLTextInputProps = Pick<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'minLength' | 'maxLength' | 'placeholder' | 'autoFocus' | 'disabled'
>;
export interface TextInputProps extends HTMLTextInputProps {
  dataTestId?: string;
  errorMsg?: string;
  fieldRef?: any;
  fieldWrapper?: ComponentType<FormFieldContainerProps>;
  fullWidth?: true;
  inputHeight?: string;
  inputWidth?: string;
  label?: string;
  marginBottom?: boolean;
  message?: string;
  name: string;
  onBlur?: (value: string) => void;
  onChange: ((value: string) => void) | false;
  pattern?: RegExp;
  required?: boolean;
  showError?: boolean;
  validate?: Validate;
  value: string | null;
}

/**
 * @prop {(value: string) => void}   onChange - updates the string
 * @prop {string}                    name - HTML name
 * @prop {string}                    value - HTML value
 * @prop {boolean=}                  autoFocus - HTML autofocus
 * @prop {boolean=false}             disabled - HTML disabled
 * @prop {string=}                   dataTestId - https://testing-library.com/docs/queries/bytestid/
 * @prop {string=}                   errorMsg - error message
 * @prop {string=}                   label - HTML label.
 * @prop {boolean=false}             marginBottom - to add a margin below, include this
 * @prop {number=256}                maxLength - HTML maxLength
 * @prop {string}                    message - additional description
 * @prop {number=0}                  minLength - HTML minLength
 * @prop {(value: string) => void=}  onBlur - function that triggers on blur
 * @prop {RegEx=}                    pattern - specifies a regular
 * expression that the <input> element's value is checked against
 * on form submission.
 * @prop {string=}                   placeholder - HTML placeholder
 * @prop {boolean=false}             required - HTML required
 * @prop {any}                       fieldRef - pass-through reference for `react-hook-form` field registration
 * @prop {boolean=}                  showError - used when we want to trigger
 * @prop {ReactNode}                 fieldWrapper - optional wrapping component for input layout
 * @prop {string}                    inputHeight - optional explicit height
 * @prop {string}                    inputWidth - optional explicit width
 * all errors to show, for example when completing a form.
 * */

export const TextInput = (props: TextInputProps) => {
  const {
    autoFocus,
    dataTestId,
    disabled,
    errorMsg,
    label,
    fullWidth,
    maxLength = 256,
    marginBottom = false,
    message,
    minLength = 0,
    showError,
    name,
    onBlur,
    onChange,
    placeholder,
    fieldRef,
    required,
    fieldWrapper,
    inputHeight,
    inputWidth,
  } = props;
  const value = props.value || '';

  const [touched, setTouched] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  function change(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (onChange) {
      onChange(value.trimStart());
    }
    setLocalValue(value);
  }

  function blur(event: React.FocusEvent<HTMLInputElement>) {
    setTouched(true);
    if (onBlur) {
      const value = event.target.value;
      onBlur(value.trim());
    }
  }

  const FieldContainer = fieldWrapper || FormFieldContainer;

  const hasError = useMemo(() => {
    return showError || (required && !value && touched);
  }, [required, showError, touched, value]);

  return (
    <FieldContainer marginBottom={marginBottom} fullWidth={fullWidth}>
      <FormFieldLabels
        errorMsg={errorMsg}
        name={name}
        label={label}
        required={required}
        message={message}
        showError={hasError}
        touched={touched}
        value={value}
        maxLength={maxLength}
      />
      <BaseTextInput
        aria-label={label || name}
        autoFocus={autoFocus}
        type='text'
        placeholder={placeholder}
        data-testid={dataTestId || name}
        disabled={disabled}
        error={hasError}
        onChange={change}
        onBlur={blur}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        id={name}
        value={onChange ? value : localValue}
        ref={fieldRef}
        inputHeight={inputHeight}
        inputWidth={inputWidth}
      />
    </FieldContainer>
  );
};
