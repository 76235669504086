import React, { memo, useState } from 'react';
import { Collapse, NavbarToggler } from 'reactstrap';
import LogoDS from '../../assets/images/logo_ds';
import Configurations from '../../settings';

function HeaderNoAuth() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={'headerBreadcrumbWrapper '}>
        <header>
          <nav className='navbar navbar-expand-lg navbar-light'>
            <span className='navbar-brand'>
              <LogoDS />
            </span>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <ul className='navbar-nav mt-2 mt-lg-0 float-right'>
                <li className='nav-item'>
                  <a className={`nav-link`} href={Configurations.REACT_APP_REDIRECT_HOME_URL}>
                    Bids<i>22</i>
                  </a>
                </li>
                <li>
                  {' '}
                  <a className={`nav-link`} href={Configurations.REACT_APP_REDIRECT_HOME_URL}>
                    Quotes<i>22</i>
                  </a>
                </li>
                {/* <li className="nav-item">
									<NavLink activeClassName="active" className={`nav-link`} to={'/'}>
										Create Member<i>22</i>
									</NavLink>
								</li>
								<li className="nav-item">
									<a activeClassName="active" className={`nav-link`} href={'/'}>
										Member<i>22</i>
									</a>
								</li> */}
                <li className='nav-item'>
                  <a className={`nav-link`} href={Configurations.REACT_APP_REDIRECT_HOME_URL}>
                    Reports<i>22</i>
                  </a>
                </li>
              </ul>
            </Collapse>
          </nav>
        </header>
      </div>
    </>
  );
}

export default memo(HeaderNoAuth);
