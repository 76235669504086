import React, { memo } from 'react';

function MakingAward() {
  return (
    <>
      <p>
        To make an award and to notify all Plan Holders / eBid submitters at the same time, you go
        to the upper right corner and click on “Update Bid” and pull down to “Upload Documents”.
        NOTE: If you simply choose Update Status, you can change it to Awarded but it won’t have the
        document attached that triggers notification of the plan holders and bidders. So, choose
        “Upload Documents”.{' '}
      </p>
      img
      <p>Now you have the option to “Add Awardee”</p>
      img
      <p>
        Enter the companies to which you’ve awarded the contract, with the amount (leaving out
        commas and dollar signs), then click on Continue:{' '}
      </p>
      img
      <p>
        Now, upload the Award Document (“Add Document”). An email will be sent to every plan holder
        and eBid submitter to tell them that a new document has been uploaded.{' '}
      </p>
    </>
  );
}

export default memo(MakingAward);
