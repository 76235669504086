/* eslint-disable max-lines */
import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  accountUpdateKeys,
  InternalLoaderNames,
  memberTypes,
  userEvent,
} from '../../utils/constants';
import { formatPhoneNumber, toastFn } from '../../utils/helpers';
import history from '../../utils/history';
import * as actionTypes from '../actionTypes';
import * as Api from '../services';
import * as bidsApi from '../services/bids';
import * as sharedApi from '../services/shared';
import * as agencyApi from '../services/agencyregistration';
import { sagaActionParamTypeDef } from '../../types/actiontypedef';
import { accountMemberInfo } from '../../types/bids';
import { AcceptedFormat } from '../../types/publications';

const accountinfotoastID = '0cc48f6f-c88a-4ce0-99aa-88ed777b7563';

function* watchAccountInfoAsync() {
  yield takeLatest(actionTypes.LOAD_ACCOUNT_INFO.TRIGGER as any, accountInfoAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* accountInfoAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAccountInfo);
    const payload = response.data.result || '';
    if (payload) {
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.SUCCESS, payload });
      yield put({
        type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
        payload: { billing_address_internalLoader: false, subscription_internalLoader: false },
      });
      yield put({ type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.TRIGGER });
    } else {
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.FAILURE });
      yield put({
        type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
        payload: { billing_address_internalLoader: false, subscription_internalLoader: false },
      });
    }
  } catch (Ex) {
    yield put({
      type: actionTypes.LOAD_ACCOUNT_INFO.FAILURE,
      meta: { payload: action.payload, error: Ex },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchgetAccountInfoCommodity() {
  yield takeLatest(actionTypes.LOAD_ACCOUNT_INFO_COMMODITY.TRIGGER as any, getAccountInfoCommodity);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getAccountInfoCommodity(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.LOAD_ACCOUNT_INFO_COMMODITY.REQUEST, meta: action.payload });
  yield put({
    type: actionTypes.UPDATE_INTERNAL_LOADER.ACTION,
    payload: { page: InternalLoaderNames.OtherAccountInfo, type: 'add' },
  });
  try {
    const response = yield call(Api.getOtherAccountInfo);
    const payload = response.data.result.commodityCodes || [];
    yield put({
      type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
      payload: { commodityCodes: payload },
    });
    yield put({ type: actionTypes.LOAD_ACCOUNT_INFO_COMMODITY.SUCCESS });
    yield put({
      type: actionTypes.UPDATE_INTERNAL_LOADER.ACTION,
      payload: { page: InternalLoaderNames.OtherAccountInfo, type: 'remove' },
    });
  } catch (Ex) {
    yield put({
      type: actionTypes.LOAD_ACCOUNT_INFO_COMMODITY.FAILURE,
      meta: { payload: action.payload, error: Ex },
    });
    yield put({
      type: actionTypes.UPDATE_INTERNAL_LOADER.ACTION,
      payload: { page: InternalLoaderNames.OtherAccountInfo, type: 'remove' },
    });
  }
}

function* watchProfileInfoAsync() {
  yield takeLatest(actionTypes.LOAD_PROFILE_INFO.TRIGGER as any, profileInfoAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* profileInfoAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.LOAD_PROFILE_INFO.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getProfileInfo);
    if (response.data.result) {
      yield put({ type: actionTypes.LOAD_PROFILE_INFO.SUCCESS, payload: response.data.result });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    } else {
      yield put({ type: actionTypes.LOAD_PROFILE_INFO.FAILURE, meta: { payload: response } });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    }
  } catch (Ex) {
    yield put({
      type: actionTypes.LOAD_PROFILE_INFO.FAILURE,
      payload: { error: Ex, payload: action.payload },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchUpdateMemberInfoAsync() {
  yield takeLatest(actionTypes.UPDATE_MEMBER_INFO.TRIGGER as any, updateMemberInfoAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* updateMemberInfoAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.UPDATE_MEMBER_INFO.REQUEST, meta: action.payload });

  try {
    const memberinfo = yield select(state => state.memberinfo);
    const accountinfoState = yield select(state => state.accountinfo);
    const auth = yield select(state => state.auth);
    const { opi = 0 } = auth;
    const { accountinfo, opsFormValues = {} } = accountinfoState || {};
    const addressType =
      accountinfo.memberInfo && accountinfo.memberInfo.length > 0
        ? accountinfo.memberInfo[0].addressType
        : 'BA';
    const { mi: memberId = '', mt: memberType = '' } = memberinfo || {};
    const values = { ...action.payload.formData } as any;

    let finalvalue = {
      city: values.city && values.city.trim(),
      address1: values.address1 && values.address1.trim(),
      address2: values.address2 && values.address2.trim(),
      postalCode: values.postalCode,
      faxNumber: (values.faxNumber && formatPhoneNumber(values.faxNumber)) || null,
      phoneNumber: (values.phoneNumber && formatPhoneNumber(values.phoneNumber)) || null,
      faxExtension: (values.faxExtension && formatPhoneNumber(values.faxExtension)) || null,
      phoneExtension: (values.phoneExtension && formatPhoneNumber(values.phoneExtension)) || null,
      countryId: values.country && values.country.value,
      stateId: values.state && values.state.value,
      countyId: values.county && values.county.value,
      metroId: values.metro && values.metro.value,
      memberId: memberId,
      addressType: 'MA',
      altShortName: '',
      website: '',
    };

    const { memberInfo = [] } = accountinfo;

    if (
      values.companyname &&
      memberInfo.length > 0 &&
      memberInfo[0].shortName !== values.companyname
    ) {
      finalvalue = {
        ...finalvalue,
        altShortName: values.companyname,
      };
    }

    if (values.website && memberInfo.length > 0 && memberInfo[0].website !== values.website) {
      finalvalue = {
        ...finalvalue,
        website: values.website.trim(),
      };
    }

    if (opi) {
      const opsFieldUpdate = {
        isSuspended: opsFormValues.status === 'SU' ? true : false,
        doNotNotify: opsFormValues.doNotNotify,
        planholderDisplayType: opsFormValues.planholderDisplay
          ? opsFormValues.planholderDisplay.value
          : '',
        isFreeDownload: opsFormValues.planholderDisplay ? opsFormValues.isFreeDocument : false,
        timeZoneId: opsFormValues.timeZoneId ? opsFormValues.timeZoneId.value : null,
      };
      yield call(Api.operationsAccountUpdate, opsFieldUpdate);

      const memberinfo = yield select(state => state && state.memberinfo);
      const { ms, mi } = memberinfo;

      if (ms !== opsFormValues.status) {
        const payload = {
          eventId: userEvent.MemberStatusChange,
          parentId: mi,
          value: ms,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload });
      }
      yield put({
        type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER as any,
        payload: { opsFormValues: '' },
      });
      yield put({ type: actionTypes.LOAD_MEMBER_INFO.TRIGGER, payload: { isCurrentMember: true } });
    }

    const response = yield call(Api.updateMemberAddress, finalvalue);

    yield put({ type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.TRIGGER });

    if (
      opi &&
      accountinfo.products.length === 0 &&
      memberType === memberTypes.agencyBuyer &&
      opsFormValues.status !== 'SU'
    ) {
      yield call(agencyApi.agencyFinishRegistration, {
        memberId,
        createdBy: opi,
      });
      yield put({ type: actionTypes.GET_REFRESH_TOKEN.TRIGGER });
    }

    if (response.data) {
      let memberAddressInfo =
        memberInfo.find((item: accountMemberInfo) => item.addressType === 'MA') || {};
      if (!memberAddressInfo.shortName)
        memberAddressInfo =
          memberInfo.find((item: accountMemberInfo) => item.addressType === 'BA') || {};

      const trackPayload = {
        eventId: userEvent.UpdateAddress,
        parentId: memberId,
        value: memberAddressInfo,
      };

      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });

      yield put({
        type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.ACTION,
        payload: { membersinfo: {} },
      });

      yield delay(1000);
      yield put({ type: actionTypes.LOAD_MEMBER_INFO.TRIGGER, payload: { isCurrentMember: true } });
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.TRIGGER });
      yield put({ type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.TRIGGER });
      history.push(
        `/${
          memberType === memberTypes.agencyBuyer ? 'buyers' : 'suppliers'
        }/accountinfo/memberinfo`,
      );
      yield put({
        type: actionTypes.UPDATE_MEMBER_INFO.SUCCESS,
        payload: response.data,
      });
      toastFn('success', 'Saved', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_MEMBER_INFO.FAILURE,
      payload: { Error, payload: action.payload },
    });
    toastFn('error', 'Failed', accountinfotoastID);
  }
}

function* watchUpdateMemberBillingInfoAsync() {
  yield takeLatest(
    actionTypes.UPDATE_MEMBER_BILLING_INFO.TRIGGER as any,
    updateMemberBillingInfoAsync,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* updateMemberBillingInfoAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.UPDATE_MEMBER_BILLING_INFO.REQUEST, meta: action.payload });

  try {
    const memberinfo = yield select(state => state.memberinfo);
    const { mi: memberId = '' } = memberinfo || {};
    const values = { ...action.payload.formData };
    if (values) {
      values.memberId = memberId;
      values.AddressType = 'BA';
      values.CountryId = values.country.value || null;
      values.StateId = values.state.value || null;
      values.CountyId = values.county.value || null;
      values.faxNumber = (values.faxNumber && formatPhoneNumber(values.faxNumber)) || null;
      values.phoneNumber = (values.phoneNumber && formatPhoneNumber(values.phoneNumber)) || null;
      values.faxExtension = (values.faxExtension && formatPhoneNumber(values.faxExtension)) || null;
      values.phoneExtension =
        (values.phoneExtension && formatPhoneNumber(values.phoneExtension)) || null;
    }
    const response = yield call(Api.updateMemberAddress, values);
    yield delay(3000);
    if (response.data) {
      yield put({ type: actionTypes.UPDATE_MEMBER_BILLING_INFO.SUCCESS, payload: response.data });
      const accountinfo = yield select(state => state && state.accountinfo);
      const { membersinfo = {} } = accountinfo || {};
      if (
        values.address1 !== membersinfo.address1 ||
        values.address2 !== membersinfo.address2 ||
        values.city !== membersinfo.city ||
        values.CountryId !== membersinfo.country.value ||
        values.StateId !== membersinfo.state.value ||
        values.CountyId !== membersinfo.county.value ||
        values.phoneNumber !== membersinfo.phoneNumber ||
        values.postalCode !== membersinfo.postalCode
      ) {
        const auth = yield select(state => state && state.auth);
        const { mi: memberId } = auth;
        const trackPayload = {
          eventId: userEvent.UpdateBillingAddress,
          parentId: memberId,
          value: membersinfo,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_MEMBER_INFO.FAILURE,
      meta: { payload: action.payload, error },
    });
  }
}

function* watchaddUpdateUserAccountAsync() {
  yield takeLatest(actionTypes.ADD_UPDATE_USER_ACCOUNT.TRIGGER as any, addUpdateUserAccountAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* addUpdateUserAccountAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.ADD_UPDATE_USER_ACCOUNT.REQUEST, meta: action.payload });

  let respData = null;
  try {
    const { userinfo, postData = {} } = action.payload || {};
    const memberinfo = yield select(state => state.memberinfo);
    const accountinfo = yield select(state => state.accountinfo);
    const auth = yield select(state => state.auth);
    const { mi: memberId = '', mt: memberType } = memberinfo || {};
    const { us: accId } = auth;
    const values = { ...postData };
    const accountInfoState = accountinfo.accountinfo || {};

    const { profileInfo = {} } = accountinfo;

    if (userinfo && userinfo.permissions && userinfo.permissions.length > 0) {
      userinfo.permissionCheck = {};
      userinfo.permissions.forEach((item: { permissionId: string }) => {
        userinfo.permissionCheck[item.permissionId] = true;
      });
    }

    // if (!userinfo) {
    values.memberId = memberId;
    // }

    if (values.username) {
      values.username = values.username.trimStart().trimEnd();
    }

    if (values.email) {
      values.email = values.email.trimStart().trimEnd();
    }

    values.phoneNumber = values.phoneNumber && formatPhoneNumber(values.phoneNumber);

    if (values.accountId === undefined || values.accountId === null) {
      values.accountId = 0;
    }
    let selectedPermissions = null;
    if (values.permissionCheck) {
      selectedPermissions = Object.keys(values.permissionCheck)
        .filter(item => values.permissionCheck[item])
        .join();
    }

    values.selectedPermissions = selectedPermissions;

    const isAutoRenewalOn =
      profileInfo && profileInfo.card && profileInfo.card.isAutoRenewalOn
        ? profileInfo.card.isAutoRenewalOn
        : false;
    let isPrimaryContactChanged = false;
    const productList = ['NA', 'ST', 'CT'];

    const isSubscribingSupplier =
      accountInfoState.products &&
      accountInfoState.products.filter((f: { productType: string }) =>
        productList.includes(f.productType),
      ).length > 0;
    const mainContact =
      accountinfo.userAccounts &&
      accountinfo.userAccounts.find((item: { mainContact: boolean }) => item.mainContact === true);
    if (
      values.mainContact &&
      mainContact.accountId !== values.accountId &&
      isSubscribingSupplier &&
      isAutoRenewalOn
    ) {
      isPrimaryContactChanged = true;
    }

    if (isPrimaryContactChanged) {
      try {
        const respDataResponse = yield call(Api.changePrimaryContact, {
          toAccountId: values.accountId,
        });
        respData = respDataResponse.data.result;

        const trackPayload = {
          eventId: userEvent.MainContactChange,
          parentId: values.memberId,
          value: !action.payload.mainContact,
        };

        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
      } catch (error) {
        yield put({
          type: actionTypes.ADD_UPDATE_USER_ACCOUNT.FAILURE,
          payload: { result: 'PRIMARYFAIL', Error, payload: action.payload },
        });
        toastFn('error', accountUpdateKeys['PRIMARYFAIL'], accountinfotoastID);
      }
    }

    if (values.existingNotify !== values.notify) {
      const trackPayload = {
        eventId: userEvent.ReceiveNotification,
        parentId: values.accountId,
        value: values.existingNotify,
      };

      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
    }

    if (values.username !== values.existingUserName) {
      const trackPayload = {
        eventId: userEvent.UsernameChange,
        parentId: values.accountId,
        value: values.existingUserName,
      };

      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
    }

    if (!isPrimaryContactChanged || (respData && respData.result === 'SUCCESS')) {
      try {
        const response = yield call(Api.addUpdateUserInfo, values);
        if (response.data.result) {
          const result = response.data.result ? 'SUCCESS' : 'USERNAMEEXISTS';
          yield put({
            type: actionTypes.ADD_UPDATE_USER_ACCOUNT.SUCCESS,
            payload: { result, values },
          });
          if (values.accountId && values.accountId === accId) {
            yield put({ type: actionTypes.GET_REFRESH_TOKEN.TRIGGER });
          }
          if (response.data.result.usernameExists) {
            toastFn('error', accountUpdateKeys['USERNAMEEXISTS'], accountinfotoastID);
          } else if (response.data.result.status || response.data.result.usernameChanged) {
            if (values.accountId) {
              const { existingUserInfo = {} } = accountinfo;
              const trackPayload = {
                eventId: userEvent.UpdateAccount,
                parentId: values.accountId,
                value: existingUserInfo,
              };
              yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
            }

            history.goBack();
            /* history.push({
              pathname: `/${memberType === memberTypes.agencyBuyer ? 'buyers' : 'suppliers'}/accountinfo/useraccount`,
              state: { activeTab: 'useraccount' },
            }); */
            toastFn('success', accountUpdateKeys['SUCCESS'], accountinfotoastID);
          }
        }
      } catch (error) {
        yield put({
          type: actionTypes.ADD_UPDATE_USER_ACCOUNT.FAILURE,
          payload: { result: 'FAILED', Error, payload: action.payload },
        });
        toastFn('error', accountUpdateKeys['FAILED'], accountinfotoastID);
      }
    } else {
      yield put({
        type: actionTypes.ADD_UPDATE_USER_ACCOUNT.FAILURE,
        payload: { result: 'PRIMARYFAIL', payload: respData },
      });
      toastFn('error', accountUpdateKeys['PRIMARYFAIL'], accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_UPDATE_USER_ACCOUNT.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', accountUpdateKeys['FAILED'], accountinfotoastID);
  }
}

function* watchforgotPasswordAsync() {
  yield takeLatest(actionTypes.FORGOT_PASSWORD.TRIGGER as any, forgotPasswordAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* forgotPasswordAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.FORGOT_PASSWORD.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.forgotPassword, action.payload);
    if (response.data.result) {
      if (response.data.result.otp) {
        yield put({
          type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER as any,
          payload: { otp: response.data.result.otp, recoveryPopup: false },
        });
        history.push(`/passwordreset/${response.data.result.otp}`);
      } else {
        yield put({
          type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
          payload: { forgotPasswordResult: response.data.result },
        });
        toastFn('success', 'Password recovery mail sent.', accountinfotoastID);
      }
      yield put({ type: actionTypes.FORGOT_PASSWORD.SUCCESS });
    }
  } catch (error) {
    toastFn('success', 'Password recovery mail sent.', accountinfotoastID);
    yield put({
      type: actionTypes.FORGOT_PASSWORD.FAILURE,
      payload: { payload: action.payload, error: error },
    });
  }
}

function* watchResetAccountInfoStateAsync() {
  yield takeLatest(actionTypes.RESET_ACCOUNTINFO_STATE.TRIGGER as any, resetAccountInfoStateAsync);
}

function* resetAccountInfoStateAsync(action: sagaActionParamTypeDef) {
  yield put({
    type: actionTypes.RESET_ACCOUNTINFO_STATE.ACTION,
    payload: action.payload,
  });
}

function* watchSaveSelfDeclarationsAsync() {
  yield takeLatest(actionTypes.SAVE_SELFDECLARATIONS.TRIGGER as any, saveSelfDeclarations);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* saveSelfDeclarations(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.SAVE_SELFDECLARATIONS.REQUEST, meta: action.payload });

  try {
    const mi = action.payload.memberId;
    /* let initialSelfDecArr = Object.keys(initial).filter(key => initial[key] === true);
    initialSelfDecArr = initialSelfDecArr.filter((element: string | undefined) => element !== undefined);
    const initialSelfDec = initialSelfDecArr.join(','); */
    const response = yield call(Api.saveSelfDeclarations, action.payload);
    if (response.data) {
      yield put({
        type: actionTypes.GET_SELF_DECLARATION.TRIGGER,
        payload: { isCurrentMember: true },
      });
      yield put({
        type: actionTypes.SAVE_SELFDECLARATIONS.SUCCESS,
        payload: response.data,
      });
      const payloadEvent = {
        eventId: userEvent.UpdateSelfDeclaration,
        parentId: mi,
        value: action.payload,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      toastFn('success', 'Updated', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_SELFDECLARATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchSearchProgramsAsync() {
  yield takeLatest(actionTypes.SEARCH_PROGRAMS.TRIGGER as any, searchProgramsAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* searchProgramsAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.SEARCH_PROGRAMS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.searchPrograms, action.payload);
    if (response.data.result) {
      let payload = response.data.result || [];
      if (payload.length > 0) {
        payload = payload.sort((a: any, b: any) => a.programName.localeCompare(b.programName));
      }
      yield put({ type: actionTypes.SEARCH_PROGRAMS.SUCCESS, payload });
    } else {
      yield put({
        type: actionTypes.SEARCH_PROGRAMS.FAILURE,
        meta: { payload: response.data.result },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SEARCH_PROGRAMS.FAILURE,
      meta: { payload: action.payload, error },
    });
  }
}

function* watchProgramsPageChangeAsync() {
  yield takeLatest(actionTypes.PROGRAMS_PAGE_CHANGE.TRIGGER as any, programsPageChange);
}

function* programsPageChange(action: sagaActionParamTypeDef) {
  yield put({
    type: actionTypes.PROGRAMS_PAGE_CHANGE.ACTION,
    payload: action.payload,
  });
}

function* watchAddMemberProgramCertificationAsync() {
  yield takeLatest(
    actionTypes.ADD_MEMBER_PROGRAM_CERTIFICATION.TRIGGER as any,
    addMemberProgramCertificationAsync,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* addMemberProgramCertificationAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.ADD_MEMBER_PROGRAM_CERTIFICATION.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.addMemberProgramCertification, action.payload);
    if (response.data.result) {
      yield put({
        type: actionTypes.ADD_MEMBER_PROGRAM_CERTIFICATION.SUCCESS,
        payload: {
          result: response.data.result,
          programId: action.payload.programId,
          memberId: action.payload.memberId,
        },
      });
      const value = {
        programId: action.payload.programId,
        certificationStatus: 'UN',
      };
      const payloadEvent = {
        eventId: userEvent.ApplyCertificate,
        parentId: action.payload.memberId,
        value: value,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_MEMBER_PROGRAM_CERTIFICATION.FAILURE,
      meta: { payload: action.payload, error },
    });
  }
}

function* watchGetProgramAttributesAsync() {
  yield takeLatest(actionTypes.GET_PROGRAM_ATTRIBUTES.TRIGGER as any, getProgramAttributesAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getProgramAttributesAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_PROGRAM_ATTRIBUTES.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getProgramAttributes, action.payload);
    if (response.data.result) {
      yield put({
        type: actionTypes.GET_PROGRAM_ATTRIBUTES.SUCCESS,
        payload: response.data.result,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_PROGRAM_ATTRIBUTES.FAILURE,
      meta: { payload: action.payload, error },
    });
  }
}

function* watchUpdateAutoRenewalSubscriptionAsync() {
  yield takeLatest(
    actionTypes.UPDATE_AUTO_RENEWAL_SUBSCRIPTION.TRIGGER as any,
    updateAutoRenewalSubscriptionAsync,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* updateAutoRenewalSubscriptionAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.UPDATE_AUTO_RENEWAL_SUBSCRIPTION.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.updateAutoRenewalSubscription, action.payload);
    if (response.data) {
      yield put({
        type: actionTypes.UPDATE_AUTO_RENEWAL_SUBSCRIPTION.SUCCESS,
        payload: { data: response.data, isAutoRenewalOn: action.payload.state },
      });

      const auth = yield select(state => state && state.auth);
      const { mi: memberId } = auth;

      const trackPayload = {
        eventId: userEvent.AutoRenewal,
        parentId: memberId,
        value: !action.payload.state,
      };

      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_AUTO_RENEWAL_SUBSCRIPTION.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchAddUpdateBTProfileAsync() {
  yield takeLatest(actionTypes.ADD_UPDATE_BT_PROFILE.TRIGGER as any, addUpdateBTProfileAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* addUpdateBTProfileAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.ADD_UPDATE_BT_PROFILE.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.createUpdateBTProfile, action.payload);
    const data = response.data.result || '';
    yield delay(3000);
    if (data && data === 'SUCCESS') {
      yield put({ type: actionTypes.ADD_UPDATE_BT_PROFILE.SUCCESS, payload: true });
      toastFn('success', 'Saved', accountinfotoastID);

      const auth = yield select(state => state && state.auth);
      const { mi: memberId } = auth;
      const trackPayload = {
        eventId: userEvent.UpdateCard,
        parentId: memberId,
        value: 'Updated',
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
    } else {
      toastFn('error', 'Failed', accountinfotoastID);
      yield put({ type: actionTypes.ADD_UPDATE_BT_PROFILE.FAILURE, payload: false });
    }
  } catch {
    toastFn('error', 'Network Failure', accountinfotoastID);
    yield put({ type: actionTypes.ADD_UPDATE_BT_PROFILE.FAILURE, payload: false });
  }
}

function* watchGetPaymentHistory() {
  yield takeLatest(actionTypes.GET_PAYMENT_HISTORY.TRIGGER as any, getPaymentHistoryAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getPaymentHistoryAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_PAYMENT_HISTORY.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getPaymentHistory, {});
    yield put({ type: actionTypes.GET_PAYMENT_HISTORY.SUCCESS, payload: response.data.result });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PAYMENT_HISTORY.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetUserAccountsAsync() {
  yield takeLatest(actionTypes.GET_USER_ACCOUNTS.TRIGGER as any, getUserAccountsAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getUserAccountsAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_USER_ACCOUNTS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getUserAccounts, action.payload);
    if (response.data.result) {
      yield put({ type: actionTypes.GET_USER_ACCOUNTS.SUCCESS, payload: response.data.result });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_USER_ACCOUNTS.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchGetPermissionsAsync() {
  yield takeLatest(actionTypes.GET_PERMISSIONS.TRIGGER as any, getPermissionsAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getPermissionsAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_PERMISSIONS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getPermissions as any, action.payload);
    if (response.data.result) {
      yield put({
        type: actionTypes.GET_PERMISSIONS.SUCCESS,
        payload: response.data.result,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_PERMISSIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchReactivateUserAccountAsync() {
  yield takeLatest(actionTypes.REACTIVATE_USER_ACCOUNT.TRIGGER as any, reactivateUserAccountAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* reactivateUserAccountAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.REACTIVATE_USER_ACCOUNT.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.reactivateUserAccount, action.payload.accountId);
    if (response.data.result) {
      yield put({
        type: actionTypes.REACTIVATE_USER_ACCOUNT.SUCCESS,
        payload: { ...response.data.result, accountId: action.payload.accountId },
      });

      const valueObj = { accountId: action.payload.accountId, status: action.payload.status };

      const payloadEvent = {
        eventId: userEvent.AccountStatus,
        parentId: action.payload.accountId,
        value: valueObj,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
    }
  } catch (error) {
    yield put({
      type: actionTypes.REACTIVATE_USER_ACCOUNT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}
function* watchUnblockUserAccountAsync() {
  yield takeLatest(actionTypes.UNBLOCK_USER_ACCOUNT.TRIGGER as any, unblockUserAccountAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* unblockUserAccountAsync(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.UNBLOCK_USER_ACCOUNT.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.unblockUserAccount, action.payload);
    if (response.data.result) {
      yield put({
        type: actionTypes.UNBLOCK_USER_ACCOUNT.SUCCESS,
        payload: { ...response.data.result, accountId: action.payload.accountId },
      });
      toastFn('success', 'Account Unblocked', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.UNBLOCK_USER_ACCOUNT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}
// To get response tabs
export function* watchAccountinfoMemberDetails() {
  yield takeLatest(
    actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.TRIGGER as any,
    accountInfoMemberDetails,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* accountInfoMemberDetails(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.REQUEST, meta: action.payload });

  try {
    const accountinfo = yield select(state => state.accountinfo);
    const auth = yield select(state => state.auth);
    const shared = yield select(state => state.shared);
    // eslint-disable-next-line prefer-const
    let { planholderTypes = [], membersinfo = {} } = accountinfo || {};
    const { opi = 0 } = auth || {};
    const { companyname = '' } = membersinfo;

    if (planholderTypes.length === 0 && opi) {
      const planResponse = yield call(Api.getPlanholderTypes, action.payload);
      planholderTypes = planResponse.data.result.map(
        (item: { planholderDisplayDesc: string; planholderDisplayType: string }) => ({
          ...item,
          label: item.planholderDisplayDesc,
          value: item.planholderDisplayType,
        }),
      );
    }
    let memberInfo = accountinfo.accountinfo.memberInfo || [];
    //const memberinfocompanyCondition = membersinfo && companyname.length > 0;
    if (!memberInfo || memberInfo.length === 0) {
      const response = yield call(Api.getAccountInfo);
      // let otherresponse = yield call(Api.getOtherAccountInfo)
      memberInfo = response.data.result.memberInfo || [];
      // memberInfo.commodityCodes = otherresponse.data.result || []
      if (response.data.result) {
        yield put({
          type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
          payload: { accountinfo: response.data.result },
        });
      }
    }

    const { pagefor = '' } = action.payload || {};

    if (memberInfo && memberInfo.length > 0) {
      let member =
        memberInfo.find((item: accountMemberInfo) => item.addressType === 'MA') ||
        ({} as accountMemberInfo);
      if (!member.shortName)
        member =
          memberInfo.find((item: accountMemberInfo) => item.addressType === 'BA') ||
          ({} as accountMemberInfo);

      if (pagefor === 'billing') {
        member =
          memberInfo.find((item: accountMemberInfo) => item.addressType === 'BA') ||
          ({} as accountMemberInfo);
        if (!member.shortName)
          member =
            memberInfo.find((item: accountMemberInfo) => item.addressType === 'MA') ||
            ({} as accountMemberInfo);
      }

      //const member = yield memberInfo[0] || {}
      let ntatelist = shared && shared.stateslist;
      if (!shared.stateslist || shared.stateslist.length === 0) {
        const stateresponse = yield call(sharedApi.getStatesList);
        ntatelist = (stateresponse.data && stateresponse.data.result) || [];
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER as any,
          payload: {
            stateslist: ntatelist.map((items: { name: string; title: string; id: number }) => ({
              ...items,
              label: items.name || items.title,
              value: items.id,
            })),
          },
        });
      }
      const newstatelist = ntatelist.map((items: { name: string; title: string; id: number }) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }));

      let countieslist = [];
      const state = newstatelist.find(
        (items: { name: string; title: string; id: number }) => items.id === member.stateId,
      );
      if (state) {
        let response = yield call(Api.getGeoCounties, state);
        response = response.data.result;
        countieslist = response.map((items: { name: string; title: string; id: number }) => ({
          ...items,
          label: items.name || items.title,
          value: items.id,
        }));
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER as any,
          payload: {
            countieslist,
          },
        });
      }
      const filteredCounties =
        (countieslist &&
          countieslist.length > 0 &&
          countieslist.filter(
            (county: { value: number }, i: number) => county.value === member.countyId,
          )) ||
        [];
      const [selectedCounty] = filteredCounties;

      const memberinfo = yield select(state => state.memberinfo);

      const membersinfo = {
        companyname: member.shortName,
        address1: member.address1,
        address2: member.address2,
        city: member.city,
        postalCode: member.postalCode,
        phoneNumber: member.phoneNumber,
        phoneExtension: member.phoneExtension || '',
        faxNumber: member.faxNumber || '',
        faxExtension: member.faxExtension || '',
        country:
          (shared &&
            shared.countrieslist.find(
              (items: { value: number }) => items.value === member.countryId,
            )) ||
          '',
        website: member.website || '',
        state: state || '',
        county: selectedCounty || '',
        status: memberinfo.ms,
        doNotNotify: memberinfo.dnd ? memberinfo.dnd : false,
        planholderDisplay: memberinfo.phdt
          ? planholderTypes.find(
              (item: { planholderDisplayType: string }) =>
                item.planholderDisplayType === memberinfo.phdt,
            )
          : '',
      };

      yield put({
        type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.SUCCESS,
        payload: { membersinfo },
      });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    } else {
      yield put({ type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.FAILURE, payload: 'error' });
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { internalLoader: false },
      });
    }
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({ type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.FAILURE, payload: error });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchgetaccountinfopublications() {
  yield takeLatest(
    actionTypes.GET_ACCOUNT_INFO_PUBLICATIONS.TRIGGER as any,
    getaccountinfopublications,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getaccountinfopublications(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_ACCOUNT_INFO_PUBLICATIONS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getaccountinfopublications, action.payload);
    const payload = response.data.result || [];
    payload.sort((a: any, b: any) => a.publication.localeCompare(b.publication));
    yield put({ type: actionTypes.GET_ACCOUNT_INFO_PUBLICATIONS.SUCCESS, payload });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACCOUNT_INFO_PUBLICATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchsubmitaccountinfopublicationsadd() {
  yield takeLatest(
    actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD.TRIGGER as any,
    submitaccountinfopublicationsadd,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* submitaccountinfopublicationsadd(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD.REQUEST,
    meta: action.payload,
  });

  try {
    const accountinfo = yield select(state => state.accountinfo) || {};
    const { publicationName, publicationmodal, editpublication } = accountinfo || {};
    const publicationValueBeforeUpdate = editpublication.publication;
    let publications = [
      {
        publicationId: 0,
        publication: publicationName,
      },
    ];
    if (!publicationmodal && editpublication && editpublication.publicationId) {
      publications = [
        {
          publicationId:
            editpublication && editpublication.publicationId ? editpublication.publicationId : 0,
          publication: publicationName,
        },
      ];
    }

    const response = yield call(Api.submitaccountinfopublicationsadd, { publications });
    const data = response.data.result || [];

    if (data && data.status) {
      const accountinfo = yield select(state => state.accountinfo);
      const { agencypublications } = accountinfo;
      const value = {
        publicationId: data.id,
        memberId: '',
        publication: publicationName,
      };
      let payload = agencypublications;
      if (editpublication && editpublication.publicationId) {
        payload = agencypublications.map((item: { publicationId: number; publication: string }) => {
          if (Number(item.publicationId) === Number(editpublication.publicationId)) {
            item.publicationId = data.id;
            item.publication = publicationName;
          }
          return item;
        });
      } else {
        agencypublications.unshift(value);
      }
      const message = editpublication && editpublication.publicationId ? 'Updated' : 'Added';
      yield put({ type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD.SUCCESS, payload });
      if (editpublication && editpublication.publicationId) {
        const payloadEvent = {
          eventId: userEvent.UpdatePublications,
          parentId: editpublication.publicationId,
          value: publicationValueBeforeUpdate,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      }
      toastFn('success', message, accountinfotoastID);
    } else {
      yield put({
        type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD.FAILURE,
        payload: { payload: data, Error: 'DATA ERROR' },
      });
      toastFn('success', 'Failed', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitaccountinfopublicationsremove() {
  yield takeLatest(
    actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE.TRIGGER as any,
    submitaccountinfopublicationsremove,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* submitaccountinfopublicationsremove(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE.REQUEST,
    meta: action.payload,
  });

  try {
    const { publicationId, publicationName } = action.payload;
    const publications = [
      {
        publicationId: publicationId,
        name: publicationName,
      },
    ];

    const response = yield call(Api.submitaccountinfopublicationsremove, { publications });
    const payload = response.data.result.status || '';

    if (payload) {
      const accountinfo = yield select(state => state.accountinfo);
      const { agencypublications } = accountinfo;
      const payload = agencypublications.filter(
        (item: { publicationId: number }) => Number(item.publicationId) !== Number(publicationId),
      );
      yield put({ type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE.SUCCESS, payload });
      const payloadEvent = {
        eventId: userEvent.DeletePublication,
        parentId: publicationId,
        value: publicationName,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      toastFn('success', 'Removed', accountinfotoastID);
    } else {
      yield put({
        type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE.FAILURE,
        payload: { payload: response, Error: 'Api issue' },
      });
      toastFn('success', 'Failed', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetaccountinfobidtypes() {
  yield takeLatest(actionTypes.GET_ACCOUNT_INFO_BIDTYPES.TRIGGER as any, getaccountinfobidtypes);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getaccountinfobidtypes(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_ACCOUNT_INFO_BIDTYPES.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getaccountinfobidtypes, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_ACCOUNT_INFO_BIDTYPES.SUCCESS, payload });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACCOUNT_INFO_BIDTYPES.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchsubmitaccountinfobidtypesadd() {
  yield takeLatest(
    actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD.TRIGGER as any,
    submitaccountinfobidtypesadd,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* submitaccountinfobidtypesadd(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD.REQUEST,
    meta: action.payload,
  });

  try {
    const accountinfo = yield select(state => state.accountinfo) || {};
    const { bidtypesType, bidtypesDesc } = accountinfo || {};
    const finalvalue = {
      memberId: 0,
      bidType: bidtypesType,
      description: bidtypesDesc,
    };

    const response = yield call(Api.submitaccountinfobidtypesadd, finalvalue);
    const data = response.data.result || [];

    if (data && data.status) {
      const accountinfo = yield select(state => state.accountinfo);
      const { agencybidtypes } = accountinfo;
      const value = {
        mi: 0,
        bidType: bidtypesType,
        bidTypeDesc: bidtypesDesc,
      };
      agencybidtypes.unshift(value);
      yield put({
        type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD.SUCCESS,
        payload: agencybidtypes,
      });
      toastFn('success', 'Added', accountinfotoastID);
    } else {
      yield put({
        type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD.FAILURE,
        payload: { payload: data, Error: 'Data Error' },
      });
      toastFn('success', 'Failed', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitaccountinfobidtypesremove() {
  yield takeLatest(
    actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE.TRIGGER as any,
    submitaccountinfobidtypesremove,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* submitaccountinfobidtypesremove(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE.REQUEST,
    meta: action.payload,
  });

  try {
    const { mi, bidType, bidTypeDesc, index } = action.payload;

    const finalvalue = {
      memberId: mi,
      bidType,
      description: bidTypeDesc,
    };

    const response = yield call(Api.submitaccountinfobidtypesremove, finalvalue);
    const payload = response.data.result.status || '';

    if (payload) {
      const accountinfo = yield select(state => state.accountinfo);
      const { agencybidtypes } = accountinfo;
      const payload = agencybidtypes.filter(
        (item: any, indexss: any) => Number(index) !== Number(indexss),
      );
      yield put({ type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE.SUCCESS, payload });
      const payloadEvent = {
        eventId: userEvent.UpdateBidType,
        parentId: mi,
        value: bidType,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      toastFn('success', 'Removed', accountinfotoastID);
    } else {
      yield put({
        type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE.FAILURE,
        payload: { payload: response, Error: 'Data Error' },
      });
      toastFn('success', 'Failed', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetLegalTemplatesList() {
  yield takeLatest(actionTypes.GET_TEMPLATES_LIST.TRIGGER as any, getLegalTemplatesList);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getLegalTemplatesList(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.GET_TEMPLATES_LIST.REQUEST,
    meta: action.payload,
  });

  try {
    const response = yield call(Api.getLegalTemplatesList);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_TEMPLATES_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TEMPLATES_LIST.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

function* watchUpdateNewLegalTemplate() {
  yield takeLatest(actionTypes.UPDATE_NEW_LEGAL_TEMPLATE.TRIGGER as any, updateNewLegalTemplate);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* updateNewLegalTemplate(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.UPDATE_NEW_LEGAL_TEMPLATE.REQUEST,
    meta: action.payload,
  });

  try {
    const response = yield call(Api.updateNewLegalTemplate, action.payload.fields);
    const status = response.data.result.status || false;
    if (status) {
      yield put({ type: actionTypes.LOAD_MEMBER_INFO.TRIGGER, payload: { isCurrentMember: true } });
      yield put({ type: actionTypes.GET_SAVED_LEGAL_TEMPLATE.TRIGGER });
      yield put({
        type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
        payload: { addTemplateModal: false },
      });
      yield put({ type: actionTypes.UPDATE_NEW_LEGAL_TEMPLATE.SUCCESS });
      toastFn('success', 'Updated', accountinfotoastID);

      const accountinfo = yield select(state => state && state.accountinfo);
      const { savedLegalData } = accountinfo;
      const {
        quoteIntro,
        quoteClose,
        quoteInsurance,
        legalAdAdditional,
        legalAdClosing,
        legalAdCostInfo,
        legalAdDueDateTime,
        legalAdIntro,
      } = action.payload.fields;

      if (
        savedLegalData.eQuoteClose !== quoteClose ||
        savedLegalData.eQuoteInsurance !== quoteInsurance ||
        savedLegalData.eQuoteIntro !== quoteIntro ||
        savedLegalData.legalAdAdditional !== legalAdAdditional ||
        savedLegalData.legalAdClosing !== legalAdClosing ||
        savedLegalData.legalAdCostInfo !== legalAdCostInfo ||
        savedLegalData.legalAdDueDateTime !== legalAdDueDateTime ||
        savedLegalData.legalAdIntro !== legalAdIntro
      ) {
        const auth = yield select(state => state && state.auth);
        const { mi: memberId } = auth;
        const trackPayload = {
          eventId: userEvent.UpdateMemberLegalAd,
          parentId: memberId,
          value: savedLegalData,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
      }
    } else {
      yield put({
        type: actionTypes.UPDATE_NEW_LEGAL_TEMPLATE.FAILURE,
        payload: { payload: response, Error: 'Data Error' },
      });
      toastFn('error', 'Failed', accountinfotoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_TEMPLATES_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetSavedLegalTemplate() {
  yield takeLatest(actionTypes.GET_SAVED_LEGAL_TEMPLATE.TRIGGER as any, getSavedLegalTemplate);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getSavedLegalTemplate(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.GET_SAVED_LEGAL_TEMPLATE.REQUEST,
    meta: action.payload,
  });

  try {
    const response = yield call(Api.getSavedLegal as any);
    const legalData = {
      ...response.data.result,
      quoteIntro: response.data.result.eQuoteIntro || response.data.result.quoteIntro,
      quoteClose: response.data.result.eQuoteClose || response.data.result.quoteClose,
      quoteInsurance: response.data.result.eQuoteInsurance || response.data.result.quoteInsurance,
    };
    yield put({
      type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
      payload: { savedLegalData: legalData },
    });
    yield put({ type: actionTypes.GET_SAVED_LEGAL_TEMPLATE.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SAVED_LEGAL_TEMPLATE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetaccountinforequiredDocs() {
  yield takeLatest(
    actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS.TRIGGER as any,
    getaccountinforequiredDocs,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getaccountinforequiredDocs(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getaccountinforequiredDocs, action.payload);
    const data = response.data.result || '';
    const acceptedFormats = data.acceptedFormats || [];
    const requiredDocuments = data.requiredDocuments || [];
    const convertableTypes = acceptedFormats
      .filter((item: AcceptedFormat) => item.isConversion === true)
      .map((item: AcceptedFormat) => item.docFormatType.trimEnd().trimStart().toLowerCase());
    const DocAllowedExt = data.acceptedFormats.map((item: AcceptedFormat) =>
      item.docFormatType.trimEnd().trimStart().toLowerCase(),
    );

    const payload = { requiredDocuments, acceptedFormats, convertableTypes, DocAllowedExt };
    yield put({ type: actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS.SUCCESS, payload });

    if (requiredDocuments.length === 0) {
      const addbid = yield select(state => state && state.addbid);
      const { addbidinfo } = addbid;
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { addbidinfo: { ...addbidinfo, eBidding: 'no' } },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitaccountinforequiredDocsUpdate() {
  yield takeLatest(
    actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE.TRIGGER as any,
    submitaccountinforequiredDocsUpdate,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* submitaccountinforequiredDocsUpdate(action: sagaActionParamTypeDef): any {
  yield put({
    type: actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE.REQUEST,
    meta: action.payload,
  });

  try {
    let toastmsg = 'Saved';
    const { actionfor = '' } = action.payload;
    const accountinfo = yield select(state => state.accountinfo);
    const { agencydocs, ebiddocumentPopupdata: Epopdata = '' } = accountinfo || {};
    const { requiredDocuments } = agencydocs;
    let initialValue = '';
    const ebiddocumentPopupdata =
      (Epopdata?.responseItemId ? Epopdata : action?.payload?.ebiddocumentPopupdata) || '';
    const { responseItemId = '', memberId = '' } = ebiddocumentPopupdata || {};
    let finalvalue = { requiredDocuments } as { requiredDocuments?: any; documents?: any };
    if (actionfor === 'edit' || actionfor === 'add') {
      const { description = '' } = action.payload;
      initialValue = description;
      if (actionfor === 'edit') {
        const newrequiredDocuments = requiredDocuments.map(
          (item: { responseItemId: number; description: string; memberId: number }) => {
            if (
              Number(item.responseItemId) === Number(responseItemId) &&
              Number(item.memberId) === Number(memberId)
            ) {
              item.description = description;
            }
            return item;
          },
        );
        finalvalue = {
          documents: newrequiredDocuments,
        } as any;
        toastmsg = 'Updated';
      } else {
        finalvalue = {
          documents: [
            ...requiredDocuments,
            ...[{ description, sortOrder: requiredDocuments.length + 1 }],
          ],
        } as any;
      }
    } else if (actionfor === 'reorder') {
      const reorderrequiredDocuments = requiredDocuments.map(
        (item: { sortOrder: number }, index: number) => {
          item.sortOrder = index + 1;
          return item;
        },
      );
      finalvalue = {
        documents: reorderrequiredDocuments,
      } as any;
      toastmsg = 'Order Updated';
    } else if (actionfor === 'delete') {
      const deletingdoc =
        requiredDocuments
          .filter(
            (item: { responseItemId: number; memberId: number }) =>
              Number(item.responseItemId) === Number(responseItemId) &&
              Number(item.memberId) === Number(memberId),
          )
          .map((item: Record<string, unknown>) => ({ ...item, delete: true })) || [];
      initialValue = deletingdoc[0].description;
      const nondeletedoc =
        requiredDocuments
          .filter(
            (item: { responseItemId: number; memberId: number }) =>
              Number(item.responseItemId) !== Number(responseItemId) &&
              Number(item.memberId) === Number(memberId),
          )
          .map((item: { sortOrder: number }, index: number) => {
            item.sortOrder = index + 1;
            return item;
          }) || [];
      // const deleterequiredDocuments = requiredDocuments.map(item => {
      // 	if (Number(item.responseItemId) === Number(responseItemId) && Number(item.memberId) === Number(memberId)) {
      // 		item.delete = true
      // 	}
      // 	return item
      // })
      const deleterequiredDocuments = [...nondeletedoc, ...deletingdoc];
      finalvalue = {
        documents: deleterequiredDocuments,
      } as any;
      toastmsg = 'Deleted';
    }

    const newDocuments = finalvalue.documents.filter(
      (item: { description: string }) => item.description,
    ) as any;
    finalvalue.documents = newDocuments as any;

    const response = yield call(Api.submitaccountinforequiredDocsUpdate, finalvalue);
    const data = response.data.result || '';
    if (data && data.status) {
      yield put({ type: actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE.SUCCESS });
      if (actionfor === 'edit' || actionfor === 'delete') {
        const payloadEvent = {
          eventId: userEvent.UpdateMemberResponseDocument,
          parentId: responseItemId,
          value: initialValue,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      }

      toastFn('success', toastmsg, accountinfotoastID);
      // For now
      yield put({ type: actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS.TRIGGER, payload: {} });
    } else {
      const msg = data.message
        ? data.message === 'Unable to delete document types.'
          ? 'This document is currently associated with one or more bid and cannot be deleted.'
          : data.message
        : 'Failed';
      yield put({ type: actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE.FAILURE });
      toastFn('error', msg, accountinfotoastID);
    }
  } catch (error) {
    toastFn('error', 'Failed', accountinfotoastID);
    yield put({
      type: actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetOrderInfo() {
  yield takeLatest(actionTypes.GET_ORDER_INFO.TRIGGER as any, getOrderInfo);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getOrderInfo(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_ORDER_INFO.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getOrderInfo, action.payload);
    const payload = response.data.result || '';

    if (payload) {
      const accountinfo = yield select(state => state.accountinfo);
      const { orderinfo } = accountinfo || {};
      const { memberinfo } = orderinfo || {};
      const { mi = '' } = memberinfo || {};
      payload.memberinfo = memberinfo || {};
      if (payload.memberId && Number(mi) !== Number(payload.memberId)) {
        const memberInfo = yield call(bidsApi.getMemberInfo, {
          isCurrentMember: false,
          memberId: payload.memberId,
        });
        payload.memberinfo = memberInfo.data.result;
      }
    }
    yield put({ type: actionTypes.GET_ORDER_INFO.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ORDER_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetOrdersList() {
  yield takeLatest(actionTypes.GET_ORDERS_LIST.TRIGGER as any, getOrdersList);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getOrdersList(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_ORDERS_LIST.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getOrders, action.payload);
    const payload = response.data.result || '';
    yield put({ type: actionTypes.GET_ORDERS_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ORDERS_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitRemoveCard() {
  yield takeLatest(actionTypes.SUBMIT_REMOVE_CARD.TRIGGER as any, submitRemoveCard);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* submitRemoveCard(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.SUBMIT_REMOVE_CARD.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.submitRemoveCard, action.payload);
    const payload = response.data.result || '';
    if (payload && payload.status) {
      yield put({
        type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER,
        payload: { profileInfo: {} },
      });
      yield put({ type: actionTypes.SUBMIT_REMOVE_CARD.SUCCESS });
      const auth = yield select(state => state && state.auth);
      const { mi: memberId } = auth;
      const trackPayload = {
        eventId: userEvent.RemoveCardProfile,
        parentId: memberId,
        value: 'removed',
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });
    } else {
      yield put({ type: actionTypes.SUBMIT_REMOVE_CARD.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_REMOVE_CARD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchResetPassword() {
  yield takeLatest(actionTypes.RESET_USER_PASSWORD.TRIGGER as any, resetPassword);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* resetPassword(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.RESET_USER_PASSWORD.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.submitRemoveCard, action.payload);
    const payload = response.data.result || '';
    if (payload && payload.status) {
      yield put({
        type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER as any,
        payload: { otp: '', username: '', setResetPasswordModal: false },
      });
      yield put({ type: actionTypes.RESET_USER_PASSWORD.SUCCESS });
    } else {
      yield put({ type: actionTypes.RESET_USER_PASSWORD.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.RESET_USER_PASSWORD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetPlanholderTypes() {
  yield takeLatest(actionTypes.GET_PLANHOLDER_TYPES.TRIGGER as any, GetPlanholderTypes);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* GetPlanholderTypes(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_PLANHOLDER_TYPES.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getPlanholderTypes, action.payload);
    const payload = response.data.result || '';
    if (payload) {
      const planholderTypes = payload.map(
        (item: { planholderDisplayDesc: string; planholderDisplayType: string }) => ({
          ...item,
          label: item.planholderDisplayDesc,
          value: item.planholderDisplayType,
        }),
      );
      yield put({
        type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER as any,
        payload: { planholderTypes: planholderTypes },
      });
      yield put({ type: actionTypes.GET_PLANHOLDER_TYPES.SUCCESS });
    } else {
      yield put({ type: actionTypes.GET_PLANHOLDER_TYPES.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_PLANHOLDER_TYPES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetDocAutoApprovalStatus() {
  yield takeLatest(
    actionTypes.GET_DOC_AUTO_APPROVAL_STATUS.TRIGGER as any,
    GetDocAutoApprovalStatus,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* GetDocAutoApprovalStatus(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_DOC_AUTO_APPROVAL_STATUS.REQUEST });
  try {
    const response = yield call(Api.getDocAutoApprovalStatus, action.payload);
    const payload = response.data.result;
    yield put({ type: actionTypes.GET_DOC_AUTO_APPROVAL_STATUS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DOC_AUTO_APPROVAL_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchToggleDocAutoApproval() {
  yield takeLatest(actionTypes.TOGGLE_DOC_AUTO_APPROVAL.TRIGGER as any, ToggleDocAutoApproval);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* ToggleDocAutoApproval(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.TOGGLE_DOC_AUTO_APPROVAL.REQUEST });
  try {
    const response = yield call(Api.getToggleDocAutoApproval, action.payload);
    const payload = response.data.result;
    yield put({ type: actionTypes.TOGGLE_DOC_AUTO_APPROVAL.SUCCESS, payload });
    const memberinfo = yield select(state => state && state.memberinfo);
    const { mi } = memberinfo;
    const payloadEvent = {
      eventId: userEvent.UpdateDocumentApproveStatus,
      parentId: mi,
      value: !response.data.result,
    };
    yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
  } catch (error) {
    yield put({
      type: actionTypes.TOGGLE_DOC_AUTO_APPROVAL.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetAgencyStates() {
  yield takeLatest(actionTypes.GET_SUBSCRIPTION_STATE.TRIGGER as any, getAgencyStates);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getAgencyStates(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_SUBSCRIPTION_STATE.REQUEST });
  try {
    const response = yield call(Api.getAgencyStates, action.payload);
    const agencyStates = response.data.result.map((item: { id: number; name: string }) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      };
    });
    const payload = { agencyStates };
    yield put({ type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER as any, payload });
    yield put({ type: actionTypes.GET_SUBSCRIPTION_STATE.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUBSCRIPTION_STATE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetAgencyCounties() {
  yield takeLatest(actionTypes.GET_SUBSCRIPTION_COUNTY.TRIGGER as any, getAgencyCounties);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getAgencyCounties(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_SUBSCRIPTION_COUNTY.REQUEST });
  try {
    const response = yield call(Api.getAgencyCounties, action.payload);
    const agencyCounties = response.data.result.map((item: { id: number; name: string }) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      };
    });
    const payload = { agencyCounties };
    yield put({ type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER as any, payload });
    yield put({ type: actionTypes.GET_SUBSCRIPTION_COUNTY.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUBSCRIPTION_COUNTY.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetFreeAgencies() {
  yield takeLatest(actionTypes.GET_SUBSCRIPTION_AGENCIES.TRIGGER as any, getFreeAgencies);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getFreeAgencies(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_SUBSCRIPTION_AGENCIES.REQUEST });
  try {
    const response = yield call(Api.getFreeAgencies, action.payload);
    const payload = { freeAgencies: response.data.result };
    yield put({ type: actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER as any, payload });
    yield put({ type: actionTypes.GET_SUBSCRIPTION_AGENCIES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUBSCRIPTION_AGENCIES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchUpdateSupplierFreeAgencies() {
  yield takeLatest(
    actionTypes.UPDATE_SUPPLIER_FREE_AGENCIES.TRIGGER as any,
    updateSupplierFreeAgencies,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* updateSupplierFreeAgencies(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.UPDATE_SUPPLIER_FREE_AGENCIES.REQUEST });
  try {
    const response = yield call(Api.updateSupplierFreeAgencies, action.payload);
    //const response = {data:{result:{status:true, description:'SUCCESS', errors:'coming'}}}
    if (response.data.result.status) {
      yield put({ type: actionTypes.GET_REFRESH_TOKEN.TRIGGER });
      yield delay(1000);
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.TRIGGER });
      yield put({ type: actionTypes.LOAD_PROFILE_INFO.TRIGGER });
      yield put({ type: actionTypes.UPDATE_SUPPLIER_FREE_AGENCIES.SUCCESS });
      toastFn('success', response.data.result.description, 'Add Free Agencies');
      const redirectPath =
        action.payload.pageFor === 'subscription'
          ? '/suppliers/account/subscriptions'
          : '/suppliers/dashboard';
      history.push(redirectPath);
    } else {
      yield put({ type: actionTypes.UPDATE_SUPPLIER_FREE_AGENCIES.FAILURE });
      toastFn('error', response.data.result.errors, 'Add Free Agencies');
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_SUPPLIER_FREE_AGENCIES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchModifySubscription() {
  yield takeLatest(actionTypes.MODIFY_SUBSCRIPTION.TRIGGER as any, modifySubscription);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* modifySubscription(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.MODIFY_SUBSCRIPTION.REQUEST });
  try {
    const response = yield call(Api.modifySubscription, action.payload);

    if (response.data.Status && response.data.Status === 'Success') {
      const auth = yield select(state => state && state.auth);
      const { mi: memberId } = auth;
      const trackPayload = {
        eventId: userEvent.ExtendSubscription,
        parentId: memberId,
        value: action.payload.existingExpiryDate,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });

      const payload = {
        subscriptionAmount: response.data.SubscriptionAmount,
        isReadOnly: action.payload.isreadonly,
      };
      yield put({ type: actionTypes.MODIFY_SUBSCRIPTION.SUCCESS, payload });
      yield put({ type: actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.TRIGGER });
      if (!payload.isReadOnly) {
        toastFn('success', 'Subscription modified successfully', 'Modify Subscription');
      }
    } else {
      yield put({ type: actionTypes.MODIFY_SUBSCRIPTION.FAILURE });
      toastFn('error', 'Failed', 'Modify Subscription');
    }
  } catch (error) {
    yield put({
      type: actionTypes.MODIFY_SUBSCRIPTION.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchCancelSubscription() {
  yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION.TRIGGER as any, cancelSubscription);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* cancelSubscription(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.CANCEL_SUBSCRIPTION.REQUEST });
  try {
    const response = yield call(Api.cancelSubscription, action.payload);

    if (response.data.status && response.data.status === 'success') {
      const auth = yield select(state => state && state.auth);
      const { mi: memberId } = auth;
      const trackPayload = {
        eventId: userEvent.CancelSubscription,
        parentId: memberId,
        value: 'cancelled',
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: trackPayload });

      yield put({ type: actionTypes.GET_REFRESH_TOKEN.TRIGGER });
      yield delay(1000);
      yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.TRIGGER });
      yield put({ type: actionTypes.LOAD_PROFILE_INFO.TRIGGER });
      yield put({ type: actionTypes.CANCEL_SUBSCRIPTION.SUCCESS });
      toastFn('success', 'Subscription cancelled successfully', 'Cancel Subscription');
    } else {
      yield put({ type: actionTypes.CANCEL_SUBSCRIPTION.FAILURE });
      toastFn('error', 'Failed', 'Cancel Subscription');
    }
  } catch (error) {
    yield put({
      type: actionTypes.CANCEL_SUBSCRIPTION.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchToggleSuppDocDownload() {
  yield takeLatest(actionTypes.TOGGLE_SUPP_DOC_DOWNLOAD.TRIGGER as any, toggleSuppDocDownload);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* toggleSuppDocDownload(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.TOGGLE_SUPP_DOC_DOWNLOAD.REQUEST });
  try {
    const response = yield call(Api.toggleSuppDocDownload, action.payload);
    const payload = response.data.result;
    yield put({ type: actionTypes.TOGGLE_SUPP_DOC_DOWNLOAD.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.TOGGLE_SUPP_DOC_DOWNLOAD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetSuppDocDownloadStatus() {
  yield takeLatest(actionTypes.GET_SUPP_DOC_STATUS.TRIGGER as any, getSuppDocDownloadStatus);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getSuppDocDownloadStatus(action: sagaActionParamTypeDef): any {
  yield put({ type: actionTypes.GET_SUPP_DOC_STATUS.REQUEST });
  try {
    const response = yield call(Api.getSuppDocDownloadStatus, action.payload);
    const payload = response.data.result;
    yield put({ type: actionTypes.GET_SUPP_DOC_STATUS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUPP_DOC_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* accountInfoSaga() {
  yield fork(watchAccountInfoAsync);
  yield fork(watchProfileInfoAsync);
  yield fork(watchUpdateMemberInfoAsync);
  yield fork(watchUpdateMemberBillingInfoAsync);
  yield fork(watchaddUpdateUserAccountAsync);
  yield fork(watchforgotPasswordAsync);
  yield fork(watchResetAccountInfoStateAsync);
  yield fork(watchSaveSelfDeclarationsAsync);
  yield fork(watchSearchProgramsAsync);
  yield fork(watchProgramsPageChangeAsync);
  yield fork(watchAddMemberProgramCertificationAsync);
  yield fork(watchGetProgramAttributesAsync);
  yield fork(watchUpdateAutoRenewalSubscriptionAsync);
  yield fork(watchAddUpdateBTProfileAsync);
  yield fork(watchGetPaymentHistory);
  yield fork(watchGetUserAccountsAsync);
  yield fork(watchGetPermissionsAsync);
  yield fork(watchReactivateUserAccountAsync);
  yield fork(watchUnblockUserAccountAsync);
  yield fork(watchAccountinfoMemberDetails);
  yield fork(watchgetaccountinfopublications);
  yield fork(watchsubmitaccountinfopublicationsremove);
  yield fork(watchsubmitaccountinfopublicationsadd);
  yield fork(watchgetaccountinfobidtypes);
  yield fork(watchsubmitaccountinfobidtypesadd);
  yield fork(watchsubmitaccountinfobidtypesremove);
  yield fork(watchgetaccountinforequiredDocs);
  yield fork(watchGetLegalTemplatesList);
  yield fork(watchUpdateNewLegalTemplate);
  yield fork(watchsubmitaccountinforequiredDocsUpdate);
  yield fork(watchgetSavedLegalTemplate);
  yield fork(watchgetAccountInfoCommodity);
  yield fork(watchgetOrderInfo);
  yield fork(watchgetOrdersList);
  yield fork(watchsubmitRemoveCard);
  yield fork(watchResetPassword);
  yield fork(watchGetPlanholderTypes);
  yield fork(watchToggleDocAutoApproval);
  yield fork(watchGetDocAutoApprovalStatus);
  yield fork(watchGetAgencyStates);
  yield fork(watchGetFreeAgencies);
  yield fork(watchGetAgencyCounties);
  yield fork(watchUpdateSupplierFreeAgencies);
  yield fork(watchModifySubscription);
  yield fork(watchCancelSubscription);
  yield fork(watchToggleSuppDocDownload);
  yield fork(watchGetSuppDocDownloadStatus);
}
