import moment from 'moment-timezone';
import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import {
  displayDateTime,
  getTimeIntervals,
  getTimeZone,
  setTimeToDate,
} from '../../../../../../../utils/helpers';
import { Buttons, SelectBox, TextArea } from '../../../../../../customcontrols';
import { DeprecatedReduxDatepicker } from '../../../../../../customcontrols/DeprecatedReduxDatepicker';
import { QuoteSummaryType, SummaryType } from '../../../../../../../types/quotedetailstypedef';
import { sharedTypes } from '../../../../../../../types/shared';
import { usaDateFormat } from '../../../../../../../utils/constants';
const tdWidth = {
  width: '170',
  verticalAlign: 'top',
};

interface PropsTypes {
  quoteSummary: QuoteSummaryType;
  getQuoteStatusOptions?: any;
  setQuoteSummaryDetails: any;
  quoteSummaryChangeStatus?: any;
  quoteSummaryUpdateDueDate?: any;
  data: SummaryType;
  shared: sharedTypes;
}

const ChangeStatus = (props: PropsTypes) => {
  const {
    getQuoteStatusOptions,
    setQuoteSummaryDetails,
    quoteSummaryChangeStatus,
    quoteSummaryUpdateDueDate,
    data,
    quoteSummary,
    shared,
  } = props;
  const { invaliddateerror } = shared;
  const { quoteStatus = [], modalType, lineItems } = quoteSummary;
  const {
    quoteNumber,
    quoteName,
    dueDateTime,
    quoteId,
    dueDate,
    dueTime,
    dueTimeZone,
    tzn = '',
    tzfn = '',
  } = data;

  const initialDueConfig: any = {
    duedates: new Date(dueDate),
    duetimes: dueTime,
    duetimezones: dueTimeZone,
  };

  const [changeStatus, setStatus] = useState();
  const [notes, setNotes] = useState('');

  const [singleDueDate, setSingleDueDate] = useState(initialDueConfig);

  const { duedates = '', duetimes, duetimezones } = singleDueDate;

  useEffect(() => {
    if (quoteStatus.length === 0) getQuoteStatusOptions({ quoteId });
  }, [getQuoteStatusOptions, quoteId, quoteStatus.length]);

  const onChnageFn = (name: string, value: any) => {
    if (name === 'status') setStatus(value);
    else {
      setSingleDueDate({ ...singleDueDate, [name]: value });
    }
  };

  const saveQuoteStatus = () => {
    if (modalType === 'status')
      quoteSummaryChangeStatus({ changeStatus, notes: notes.trim(), quoteId });
    else {
      const dueDateTime = setTimeToDate(duedates, `${duetimes.value} ${duetimezones.value}`);

      const initialNewDueConfig = {
        duedates: duedates,
        duetimes: duetimes.value,
        duetimezones: duetimezones.value,
      };
      setSingleDueDate(initialNewDueConfig);
      quoteSummaryUpdateDueDate({
        dueDateTime: dueDateTime,
        duedates: duedates,
        duetimes: duetimes,
        duetimezones: duetimezones,
        quoteId,
      });
    }
  };

  return (
    <>
      <div className='innerColIndent'>
        <Table className='tableHData' borderless>
          <tr>
            <th style={tdWidth}>Quote Number</th>
            <td>{quoteNumber}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Quote Name</th>
            <td>{quoteName}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Due Date</th>
            <td className='text-red200'>{displayDateTime(dueDateTime, tzfn)}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Line Items Awarded</th>
            <td>
              {lineItems.filter(item => item.awardedResponseItemId).length} of {lineItems.length}
            </td>
          </tr>
          {modalType === 'status' ? (
            <>
              <tr>
                <th>Quote Status</th>
                <td>
                  <SelectBox
                    reactselect={true}
                    label=''
                    name='status'
                    options={quoteStatus}
                    value={changeStatus}
                    handleSelect={(name: string, value: string) => onChnageFn(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>Notes</th>
                <td>
                  <TextArea
                    type='text'
                    value={notes}
                    handleChange={(name: string, value: string) => setNotes(value)}
                    classNames='class'
                  />
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <th>Due Date and time</th>
                <td>
                  <div className='row'>
                    <div className='col-lg-6 mb-2'>
                      <DeprecatedReduxDatepicker
                        label=''
                        name='duedates'
                        classNames='class'
                        minDate={moment(dueDate).isBefore(moment()) ? moment(dueDate) : moment()}
                        handleChange={(name: string, value: string) => onChnageFn(name, value)}
                        value={duedates && moment(duedates)}
                        optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <div className='timePickerWrapper'>
                        <div>
                          <SelectBox
                            reactselect={true}
                            label='Time'
                            name='duetimes'
                            options={getTimeIntervals()}
                            value={duetimes}
                            handleSelect={(name: string, value: string) => onChnageFn(name, value)}
                          />
                          <SelectBox
                            reactselect={true}
                            label=''
                            name='duetimezones'
                            options={getTimeZone()}
                            value={duetimezones}
                            handleSelect={(name: string, value: string) => onChnageFn(name, value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          )}
        </Table>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Cancel'
          title='Cancel'
          type='button'
          onClick={() => setQuoteSummaryDetails({ statusModal: false })}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Save'
          title='Save'
          type='button'
          onClick={saveQuoteStatus}
          disable={
            modalType === 'status'
              ? !changeStatus || invaliddateerror
              : !duedates || !duetimes || !duetimezones || invaliddateerror
          }
        />
      </div>
    </>
  );
};

export default memo(ChangeStatus);
