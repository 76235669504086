import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { loadingMsg } from '../../../../../utils/texts';
import { deprecatedGetDateTimeZoneConverted } from '../../../../../utils/helpers';
import NoResult from '../../../../customcontrols/noresult';
import {
  bidItemParamType,
  bidssummaryParamType,
  planholderDocumentParamType,
} from '../../../../../types/biddetail';
import { sharedTypes } from '../../../../../types/shared';

interface PropsTypes {
  currentplanholderDocuments?: planholderDocumentParamType;
  planholderDocuments?: planholderDocumentParamType[];
  shared?: sharedTypes;
  bidssummary?: bidssummaryParamType;
}

function DocumentViewHistory(props: PropsTypes) {
  const { currentplanholderDocuments, planholderDocuments = [], shared, bidssummary } = props;
  const { results } = bidssummary || {};
  const { tzfn } = (results as bidItemParamType) || {};
  const { bidDocId = '', datePosted = '', docType = '' } = currentplanholderDocuments || {};
  const { internalLoader } = shared || {};
  const history = planholderDocuments.filter(item => item.bidDocId === bidDocId);
  const status = history.filter(item => item.sentDate !== '');

  return (
    <>
      <h5>
        Posted Date: {deprecatedGetDateTimeZoneConverted(datePosted, tzfn)}, Status:{' '}
        {status ? 'Processed' : null}
      </h5>
      <h5>Type: {docType}</h5>
      {history.length > 0 ? (
        <Table className='tableHData'>
          <tr>
            <th>Distribution</th>
          </tr>
          {history.map((item, index) => (
            <tr key={index}>
              <td>
                {item.sentDate ? (
                  <>{`Distribution on ${deprecatedGetDateTimeZoneConverted(
                    item.sentDate,
                    tzfn,
                  )} via ${item.deliveryMethod}`}</>
                ) : null}
                {item.transmitDate ? (
                  <>
                    {`Notification on ${deprecatedGetDateTimeZoneConverted(
                      item.transmitDate,
                      tzfn,
                    )} via Email`}{' '}
                  </>
                ) : null}
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoResult message={internalLoader ? loadingMsg : 'No History Available'} />
      )}
    </>
  );
}

export default memo(DocumentViewHistory);
