import React from 'react';

import { emailValErrorMsg } from '../../utils/constants';
import { isValidEmail } from '../../utils/helpers/';

import { TextInput, TextInputProps } from './TextInput';

type EmailInputProps = Omit<TextInputProps, 'errorMsg'>;

export const EmailInput = (props: EmailInputProps) => {
  const errorMsg = props.value && !isValidEmail(props.value) ? emailValErrorMsg : undefined;

  const required = props.required === false ? false : true;

  const emailProps = {
    ...props,
    errorMsg,
    required,
  };

  return <TextInput {...emailProps}></TextInput>;
};
