import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';
import { SubscriptionDetails } from '../../types/subscriptions';

export function getAllProducts(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ALL_PRODUCTS, payload);
}

export function resetPaymentProcessStatus(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_PAYMENT_PROCESS_STATUS, payload);
}
export function fetchCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.FETCH_COMMODITY_CODES, payload);
}

export function getSubscriptionMemberDetails(payload: actionPayloadTypes) {
  return action(actionTypes.GET_SUBSCRIPTION_MEMBER_DETAILS, payload);
}

export function setSubscriptionDetails(payload: SubscriptionDetails) {
  return action(actionTypes.SET_SUBSCRIPTIONS_DETAILS, payload);
}

export function calculateSubscription(payload: actionPayloadTypes) {
  return action(actionTypes.CALCULATE_SUBSCRIPTION, payload);
}

export function processPayment(payload: actionPayloadTypes) {
  return action(actionTypes.PROCESS_PAYMENT, payload);
}

export function populatePostalCodeData(payload: actionPayloadTypes) {
  return action(actionTypes.POPULATE_POSTALCODE_DATA, payload);
}

export function updateSubscritpionMemberAddress(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_SUBSCRIPTION_MEMBER_ADDRESS, payload);
}
