const TRIGGER = 'TRIGGER';
const ACTION = 'ACTION';
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

/**
 * Takes a base action name and returns various action types
 * @param {string} actionName
 * @returns {Object} actionName. - TRIGGER, REQUEST, SUCCESS, FAILURE
 */
export function createActionTypes(actionName: string) {
  return [TRIGGER, ACTION, REQUEST, SUCCESS, FAILURE].reduce((acc: any, actionType: string) => {
    acc[actionType] = `${actionName}_${actionType}`;
    return acc;
  }, {});
}

/**
 * Takes a base action name and returns various action types which will not show loader
 * @param {string} actionName
 * @returns {Object} NO_LOADER_ actionName. - TRIGGER, REQUEST, SUCCESS, FAILURE
 */
export function createNoLoadActionTypes(actionName: string) {
  return [TRIGGER, ACTION, REQUEST, SUCCESS, FAILURE].reduce((acc: any, actionType: string) => {
    acc[actionType] = `NO_LOADER_${actionName}_${actionType}`;
    return acc;
  }, {});
}
