import { emailValidation } from '../../utils/helpers';
import { phoneVal } from '../../utils/constants';

export const formValidation = (value: any) => {
  const errors: any = {};
  if (!value.email) {
    errors.email = 'Required';
  } else if (emailValidation(value.email)) {
    errors.email = 'Invalid';
  }

  if (value.phone && !phoneVal.test(value.phone.trimStart().trimEnd())) {
    errors.phone = 'Invalid';
  }
  return errors;
};
