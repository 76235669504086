import { ForwardedRef, HTMLProps } from 'react';
import styled from 'styled-components';

import { BackgroundColor, BorderColor, FontFamily, ParagraphFontSize, TextColor } from '../styles';

interface InputTextStyledProps extends HTMLProps<HTMLInputElement> {
  error?: boolean;
  inputHeight?: string;
  inputWidth?: string;
  ref?: ForwardedRef<HTMLInputElement>;
}

/**
 * This is the base Input control, intended to be themed and extended
 * by specific implementations, e.g. TextInput, PasswordInput.
 */
export const InputTextStyled = styled.input<InputTextStyledProps>`
  border: 0.0625rem solid;
  border-color: ${props => (props.error ? BorderColor.Error : BorderColor.Base)};
  border-radius: 0.25rem;
  background: ${BackgroundColor.Input};
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-family: ${FontFamily.Base};
  font-weight: 400;
  font-style: normal;
  font-size: ${ParagraphFontSize.Base};
  color: ${TextColor.Base};
  text-overflow: ellipsis;
  word-spacing: normal;
  line-height: 2rem;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${TextColor.Base};
    background: ${BackgroundColor.Deactivated};
  }
  ${({ inputHeight }) => (inputHeight ? `height: ${inputHeight};` : '')}
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth};` : '')}
`;
