import React from 'react';
import { SelectBox, TagsInput } from '../../../customcontrols';
import { broadcastListTypes, sharedTypes } from '../../../../types/broadcastlist';

interface propsTypes {
  addbid: { activeprograms: Record<string, unknown>[] };
  shared: sharedTypes;
  setBroadcastListDetails: any;
  broadcastList: broadcastListTypes;
  pagefor: string;
}

function Filtersuppliearlist(props: propsTypes) {
  const { addbid, shared, setBroadcastListDetails, broadcastList } = props;
  const { broadcastFilter } = broadcastList;
  const { activeprograms = [] } = addbid || {};
  const { selfdeclaredattributes = [], stateslist = [] } = shared;
  const {
    filterState = '',
    filterCity = '',
    filterDeclaration = '',
    filterPrograms = '',
  } = broadcastFilter;

  const onChangeFn = (name: string, value: any) => {
    setBroadcastListDetails({
      broadcastFilter: { ...broadcastFilter, [name]: value },
    });
  };

  return (
    <>
      <h4 className='text-left mt-0'>Filter</h4>
      <SelectBox
        reactselect={true}
        label='State'
        name='filterState'
        options={stateslist}
        value={filterState}
        handleSelect={onChangeFn}
        mutli={true}
        clear={false}
      />
      <TagsInput
        label='City'
        name='filterCity'
        placeholder='Enter City...'
        handleChange={onChangeFn}
        value={filterCity}
      />
      <SelectBox
        reactselect={true}
        label='Self Declaration'
        name='filterDeclaration'
        options={selfdeclaredattributes.map((item: any) => ({
          ...item,
          label: item.attributeName,
          value: item.attributeType,
        }))}
        value={filterDeclaration}
        handleSelect={onChangeFn}
        mutli={true}
        clear={false}
      />
      <SelectBox
        reactselect={true}
        label='Certifications'
        name='filterPrograms'
        options={activeprograms}
        value={filterPrograms}
        handleSelect={onChangeFn}
        mutli={true}
        clear={false}
      />
    </>
  );
}

export default Filtersuppliearlist;
