import React, { useEffect } from 'react';
import { supplierRegistrationTypes } from '../../../../types/supplierregistration';
import { Buttons } from '../../../customcontrols';

import { toastFn, isValidRequiredTrimmingSpace } from '../../../../utils/helpers';
import { incompleteRegistrationState } from '../../../../store/recoil/registrationState';
import { useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Colors } from '../../../../shared/styles';
import { useAmplitude } from '../../../amplitude';

interface propsTypes {
  registration: supplierRegistrationTypes;
  checkAccountExists?: any;
  setRegistrationData?: any;
  match?: any;
}

const StyledInput = styled.input`
  border: 0.0625rem solid ${Colors.grayDarker};
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.5rem 0.5rem;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  text-overflow: ellipsis;
  word-spacing: normal;
  &:focus {
    outline: none;
  }
`;

const CreateAccountForm = (props: propsTypes) => {
  const { errors, handleSubmit, register, setValue } = useForm();
  const { logEvent, setUserProperties } = useAmplitude();

  const { checkAccountExists, setRegistrationData, registration } = props;
  const { registrationData } = registration;
  const { emailAddress = '', companyName = '', acceptTerms = false } = registrationData;
  
  const memberId = props.match && props.match.params.memberId ? props.match.params.memberId : '';

  const setRecoilRegistrationData = useSetRecoilState(incompleteRegistrationState);

  useEffect(() => {
    if(emailAddress && companyName){
      setValue('emailAddress', emailAddress);
      setValue('companyName', companyName);
    }
  }, [emailAddress, companyName, setValue]);

  const onSubmitFn = (values: any) => {
    if (acceptTerms) {
      setRecoilRegistrationData({ ...registrationData, emailAddress: values.emailAddress });
      checkAccountExists({ ...values, memberId });
      setUserProperties({
        Email: values.emailAddress,
        'Organization Name': values.companyName,
      });
      logEvent('registration (supplier) - added email', {
        Description: 'Add email and company name',
      });
    } else toastFn('error', 'Please accept DemandStar Terms of Use and Privacy Policy');
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmitFn)}>
      <div className='d-flex align-items-end flex-column h-100'>
        <div className={`clearfix w-100 ${errors.emailAddress && 'errorWrapper'}`}>
          <label htmlFor='emailAddress' aria-labelledby='emailAddress'>
            Email Address
          </label>
          <small className='formHelpText'>
            This will be your username for logging in to DemandStar
          </small>
          <StyledInput
            data-testid='registration-input-email'
            maxLength={100}
            name='emailAddress'
            ref={register({
              required: 'Email Address is required',
              pattern: {
                value: /^([A-Za-z0-9_\-.+])+@[A-Za-z0-9_\-.]+\.[a-zA-Z]{2,15}$/,
                message: 'Invalid email format',
              },
            })}
            type='email'
          />
          <div className='errorMsg'>
            <span>{errors.emailAddress && errors.emailAddress.message}</span>
          </div>
        </div>
        <br />
        <div className={`clearfix w-100 ${errors.companyName && 'errorWrapper'}`}>
          <label htmlFor='companyName' aria-labelledby='companyName'>
            Company Name
          </label>
          <StyledInput
            data-testid='registration-input-company-name'
            maxLength={150}
            name='companyName'
            ref={register({ 
              required: true,
              validate: isValidRequiredTrimmingSpace,
            })}
          />
          <div className='errorMsg'>
            <span>{errors.companyName && 'Company Name is required'}</span>
          </div>
        </div>
        <div className='clearfix w-100 text-center'>
          <Buttons
            type='submit'
            classNames='bttn-accent mt-4 mb-3'
            text='Create your DemandStar account'
            title='Next'
            onClick={() => {
              setRegistrationData({
                registrationData: { ...registrationData, acceptTerms: true },
              });
            }}
          />
          <small>
            By creating an account, you agree to DemandStar's{' '}
            <a href='https://network.demandstar.com/terms-of-use/'>Terms of Use</a> and{' '}
            <a href='https://network.demandstar.com/privacy-policy/'>Privacy Policy</a>.
          </small>
        </div>
      </div>
    </form>
  );
};

export default CreateAccountForm;
