import React, { memo, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../store/actions';

import Store from './store';

type propsTypes = PropsFromRedux;

const UpdateSubscriptions = (props: propsTypes) => {
  const { setBreadcrumb } = props;

  useEffect(() => {
    setBreadcrumb({
      page: 'Update Subscriptions',
      title: 'Update Subscriptions',
      altname: 'Update Subscriptions',
      name: 'Update Subscriptions',
    });
  }, [setBreadcrumb]);

  return (
    <>
      <Store />
    </>
  );
};

const connector = connect(null, dispatch => bindActionCreators({ ...actionCreators }, dispatch));
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(UpdateSubscriptions));
