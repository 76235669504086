import { createActionTypes, createNoLoadActionTypes } from './utils';

export const SET_ADD_BID_DETAILS = createNoLoadActionTypes('SET_ADD_BID_DETAILS');
export const GET_ADD_BID_BID_TYPES = createNoLoadActionTypes('GET_ADD_BID_BID_TYPES');
export const GET_ADD_BID_BID_WRITERS = createNoLoadActionTypes('GET_ADD_BID_BID_WRITERS');
export const SUBMIT_ADD_BID_INFO = createActionTypes('SUBMIT_ADD_BID_INFO');
export const SET_ADD_BID_INITIAL_DATA = createActionTypes('SET_ADD_BID_INITIAL_DATA');
export const GET_ADD_BID_RESPONSE_ITEMS = createNoLoadActionTypes('GET_ADD_BID_RESPONSE_ITEMS');
export const GET_ADD_BID_PUBLICATIONS = createActionTypes('GET_ADD_BID_PUBLICATIONS');
export const GET_ADD_BID_UPDATE_BID_PUBLICATIONS = createActionTypes(
  'GET_ADD_BID_UPDATE_BID_PUBLICATIONS',
);
export const GET_ADD_BID_LEGAL = createActionTypes('GET_ADD_BID_LEGAL');
export const GET_ADD_BID_ACTIVE_PROGRAMS = createNoLoadActionTypes('GET_ADD_BID_ACTIVE_PROGRAMS');
export const GET_ADD_BID_BROADCAST_LIST_ID = createNoLoadActionTypes(
  'GET_ADD_BID_BROADCAST_LIST_ID',
);
export const SUBMIT_ADD_BID_UPDATE_LEGAL = createActionTypes('SUBMIT_ADD_BID_UPDATE_LEGAL');
export const SUBMIT_ADD_BID_UPDATE_MEMO = createActionTypes('SUBMIT_ADD_BID_UPDATE_MEMO');
export const UPDATE_ADD_BID_EBID_INFO = createActionTypes('UPDATE_ADD_BID_EBID_INFO');
export const SUBMIT_ADD_BID_ADD_LIST = createActionTypes('SUBMIT_ADD_BID_ADD_LIST');
export const SUBMIT_ADD_BID_SAVE_FILTER = createActionTypes('SUBMIT_ADD_BID_SAVE_FILTER');
export const SUBMIT_ADD_BID_SEARCH_VENDORS = createActionTypes('SUBMIT_ADD_BID_SEARCH_VENDORS');
export const SUBMIT_ADD_BID_IS_FILTER_STATUS = createActionTypes('SUBMIT_ADD_BID_IS_FILTER_STATUS');
export const SUBMIT_ADD_BID_SUPPLIER_COUNT = createActionTypes('SUBMIT_ADD_BID_SUPPLIER_COUNT');
export const SUBMIT_ADD_BID_GET_PREBUILT_LIST = createActionTypes(
  'SUBMIT_ADD_BID_GET_PREBUILT_LIST',
);
export const SUBMIT_ADD_BID_DELETE_LIST = createActionTypes('SUBMIT_ADD_BID_DELETE_LIST');
export const SUBMIT_ADD_BID_SET_PREBUILT_LIST = createNoLoadActionTypes(
  'SUBMIT_ADD_BID_SET_PREBUILT_LIST',
);
export const SUBMIT_ADD_BID_USE_PREBUILT_LIST = createActionTypes(
  'SUBMIT_ADD_BID_USE_PREBUILT_LIST',
);
export const GET_ADD_BID_DOC_TYPES = createNoLoadActionTypes('GET_ADD_BID_DOC_TYPES');
export const GET_ADD_BID_IS_CONSTRUCTION = createActionTypes('GET_ADD_BID_IS_CONSTRUCTION');
export const GET_ADD_BID_IS_PERMISSIONS = createNoLoadActionTypes('GET_ADD_BID_IS_PERMISSIONS');
export const GET_ADD_BID_CONVERSION_TYPES = createNoLoadActionTypes('GET_ADD_BID_CONVERSION_TYPES');
export const SUBMIT_ADD_BID_UPDATE_PLAN = createActionTypes('SUBMIT_ADD_BID_UPDATE_PLAN');
export const SUBMIT_ADD_BID_DOCUMENT_UPLOAD = createNoLoadActionTypes(
  'SUBMIT_ADD_BID_DOCUMENT_UPLOAD',
);
export const SUBMIT_ADD_BID_DOCUMENT_DELETE = createActionTypes('SUBMIT_ADD_BID_DOCUMENT_DELETE');
export const SUBMIT_ADD_BID_DOCUMENT_APPROVE = createActionTypes('SUBMIT_ADD_BID_DOCUMENT_APPROVE');
export const SUBMIT_ADD_BID_COMPLETE = createActionTypes('SUBMIT_ADD_BID_COMPLETE');
export const SUBMIT_ADD_BID_ADD_SUPPLEMENTAL = createActionTypes('SUBMIT_ADD_BID_ADD_SUPPLEMENTAL');
export const SUBMIT_ADD_BID_MOVE_NEXT = createActionTypes('SUBMIT_ADD_BID_MOVE_NEXT');
export const GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS = createNoLoadActionTypes(
  'GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS',
);
export const ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS = createActionTypes(
  'ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS',
);
export const GET_ADD_BID_BROADCAST_MEMBERS = createActionTypes('GET_ADD_BID_BROADCAST_MEMBERS');
export const GET_ADD_BID_BIDS_DOCUMENTS = createActionTypes('GET_ADD_BID_BIDS_DOCUMENTS');
export const GET_ADD_BID_CAN_COMPLETE = createNoLoadActionTypes('GET_ADD_BID_CAN_COMPLETE');
export const GET_ADD_BID_BID_STATUS = createActionTypes('GET_ADD_BID_BID_STATUS');
export const GET_ADD_BID_CHANGE_DUE = createActionTypes('GET_ADD_BID_CHANGE_DUE');
export const ADD_BID_ADD_AWARDEE = createActionTypes('ADD_BID_ADD_AWARDEE');
export const GET_ADD_BID_GET_AWARDEES = createActionTypes('GET_ADD_BID_GET_AWARDEES');
export const ADD_BID_DELETE_BROADCAST_SUPPLIERS = createActionTypes(
  'ADD_BID_DELETE_BROADCAST_SUPPLIERS',
);
export const SET_ADD_BID_COMMODITY_LOAD = createNoLoadActionTypes('SET_ADD_BID_COMMODITY_LOAD');
export const ADD_BID_SET_COMMODITY_FILTERS = createNoLoadActionTypes(
  'ADD_BID_SET_COMMODITY_FILTERS',
);
export const RESET_ADD_BID = createNoLoadActionTypes('RESET_ADD_BID');
export const GET_LIST_COUNT_DETAILS = createNoLoadActionTypes('GET_LIST_COUNT_DETAILS');
export const BULK_UPLOAD_DOCUMENT = createActionTypes('BULK_UPLOAD_DOCUMENT');
export const UPDATE_SUPLIMENTAL_SHEET_UPDATE = createActionTypes('UPDATE_SUPLIMENTAL_SHEET_UPDATE');
export const SAVE_UPLOADED_SUPPLIMENTAL_SUPPLIER = createActionTypes(
  'SAVE_UPLOADED_SUPPLIMENTAL_SUPPLIER',
);
export const REBROADCAST_BID_TO_SUPPLIERS = createActionTypes('REBROADCAST_BID_TO_SUPPLIERS');
