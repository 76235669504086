import React, { useState } from 'react';
import { sortDirections } from '../../utils/constants';
import { SortingFn } from '../../utils/helpers';

interface SortingIconProps {
  sortlist: any[];
  sortname: string;
  currentsortname: string;
  initialsortorder: string;
  changeSortingFn: (list: any) => void;
  changesortname: (name: string) => void;
}
const SortingIcon = (props: SortingIconProps) => {
  const {
    sortlist = [],
    sortname = '',
    currentsortname = '',
    initialsortorder = '',
    changeSortingFn = () => {},
    changesortname = () => {},
  } = props;

  const [sortorder, setSortorder] = useState(initialsortorder);

  const changeSort = (sortname: string) => {
    let sortingname = currentsortname;
    if (sortname !== currentsortname) {
      changesortname(sortname);
      sortingname = sortname;
    }
    const order =
      sortorder === sortDirections.DESC
        ? sortDirections.ASC
        : sortorder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort;
    const newlist: any = SortingFn(sortlist, order, sortingname);
    changeSortingFn(newlist);
    setSortorder(order);
  };

  return (
    <>
      {sortname === currentsortname ? (
        <span
          onClick={() => changeSort(sortname)}
          className={`staticLink ml-2 mdi ${
            sortorder === sortDirections.DESC ? 'mdi-sort-descending' : 'mdi-sort-ascending'
          }`}
          title={sortorder === sortDirections.DESC ? 'Descending' : 'Ascending'}
        />
      ) : (
        <span
          onClick={() => changeSort(sortname)}
          className='mdi mdi-sort ml-2 staticLink'
          title={'Sort'}
        />
      )}
    </>
  );
};

export default SortingIcon;
