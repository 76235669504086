export enum ContactLabels {
  address1 = 'Address 1',
  address2 = 'Address 2',
  altTotalPrice = 'Alternate Bid Amount',
  city = 'City',
  contactName = 'Company Name',
  countryName = 'Country',
  label = 'Contact Information',
  notes = 'Notes',
  state = 'State',
  phoneNumber = 'Phone Number',
  phoneExtension = 'Phone Extension',
  postalCode = 'Postal Code',
  responseId = 'Response Number',
  totalPrice = 'Bid Amount',
}
