import { HTMLProps } from 'react';
import styled from 'styled-components';

import {
  FontWeight,
  InputFontSize,
  LineHeight,
  Margin,
  ParagraphFontSize,
  TextColor,
  Width,
} from '../styles';

export interface FormFieldContainerProps extends HTMLProps<HTMLInputElement> {
  dataTestId?: string;
  marginBottom?: boolean;
}
/**
 *
 */
export const StyledFormFieldContainer = styled.div<FormFieldContainerProps>`
  margin-bottom: ${props => (props.marginBottom ? Margin.Base : 0)};
  max-width: ${Width.Full};
  width: ${Width.Full};
  display: inline-block;
`;

export interface FormFieldMessageProps extends HTMLProps<HTMLSpanElement> {
  dataTestId?: string;
}
/**
 * This is the span of text that gives any additional message
 * needed for the input.
 */
export const FormFieldMessage = styled.span<FormFieldMessageProps>`
  display: block;
  font-size: ${ParagraphFontSize.Small};
  line-height: ${ParagraphFontSize.Small};
  color: ${TextColor.Helper};
  font-weight: ${FontWeight.Base};
  width: ${Width.Full};
  margin-bottom: ${Margin.Less};
`;

/**
 * This is the span of text that tells of any error.
 */
export const FormFieldError = styled(FormFieldMessage)<FormFieldMessageProps>`
  color: ${TextColor.Error};
`;
