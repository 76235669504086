import React, { useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';

const ItemTypes = {
  CARD: 'card',
};

type DragDropCardProps = {
  text?: string;
  isDragging?: boolean;
  connectDragSource: any;
  connectDropTarget: any;
  setEditEbitModal: any;
  halfWayCancel: any;
  setAccountInfoDetails: any;
  item: any;
  index: any;
  id: any;
  moveCard: any;
};

const DragDropCard = React.forwardRef((dragprops: DragDropCardProps, ref) => {
  const {
    text,
    isDragging,
    connectDragSource,
    connectDropTarget,
    setEditEbitModal,
    halfWayCancel,
    setAccountInfoDetails,
    item,
    index,
  } = dragprops;
  const elementRef = useRef(null);
  connectDragSource(elementRef);
  connectDropTarget(elementRef);
  const opacity = isDragging ? 0 : 1;
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));
  if (isDragging && !halfWayCancel) {
    setAccountInfoDetails({ halfWayCancel: true, halfWayCancelAllow: false });
  }
  return (
    <tbody>
      <tr ref={elementRef} style={{ opacity, cursor: 'move' }}>
        <td>{index + 1}</td>
        <td>{text}</td>
        <td>
          <i
            className='mdi mdi-delete-forever-outline mdi-24px staticLink float-right'
            onClick={() => setEditEbitModal(item, 'delete')}
          />
          <i
            className='mdi mdi-square-edit-outline mdi-24px staticLink mr-1 float-right'
            onClick={() => setEditEbitModal(item)}
          />
        </td>
      </tr>
    </tbody>
  );
});

export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props: { index: any; moveCard: any }, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset() as any;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props: { id: any; index: any }) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(DragDropCard),
);
