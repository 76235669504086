import React, { memo } from 'react';
import { DeprecatedInput } from '../../../customcontrols';

const UploadList = (props: any) => {
  return (
    <>
      <DeprecatedInput type='file' label='Choose a file' />
    </>
  );
};

export default memo(UploadList);
