import { useRecoilState } from 'recoil';

import { selectedResponseIdState } from '../../store/recoil/bidResponseState';

/**
 * Helper fn to save a step when reading/writing to `selectedResponseIdState`,
 * @returns
 */
export function useSelectedResponseId() {
  const [selectedResponseId, setSelectedResponseId] = useRecoilState(selectedResponseIdState);
  function resetSelectedResponseId() {
    setSelectedResponseId(-1);
  }
  return { resetSelectedResponseId, selectedResponseId, setSelectedResponseId };
}
