import React from 'react';
import { TextArea } from '../customcontrols';
import Buttons from '../customcontrols/button';

interface PropsTypes {
  subscriptions?: any;
  showBtn?: boolean;
  makePayClick?: any;
  complimentary?: boolean;
  setSubscriptionDetails?: any;
}

const OrderSummmery = (props: PropsTypes) => {
  const {
    showBtn = false,
    makePayClick,
    subscriptions,
    complimentary = false,
    setSubscriptionDetails,
  } = props;
  const {
    calculatedPrice = 0,
    currentSubscribedCounties = [],
    currentSubscribedStates = [],
    currentSubscribedNational = [],
    subscribedStates = [],
    subscribedCounties = [],
    subscribedNational = [],
  } = subscriptions;

  const oldProducts = [
    ...currentSubscribedCounties,
    ...currentSubscribedStates,
    ...currentSubscribedNational,
  ];
  const newProducts = [...subscribedStates, ...subscribedCounties, ...subscribedNational];
  let distinctProducts = newProducts.filter((item: number) => !oldProducts.includes(item));

  if (distinctProducts.length === 0) distinctProducts = [...newProducts];

  return (
    <>
      <h6>Order Summary</h6>
      <div className='d-flex firaRegular'>
        <span className='flex-grow-1'>{distinctProducts.length} subscriptions:</span>
        <span>${calculatedPrice}</span>
      </div>
      <hr className='lineHR' />
      <div className='d-flex firaRegular mb-2'>
        <span className='flex-grow-1'>Total before Tax:</span>
        <span>${calculatedPrice}</span>
      </div>
      <div className='d-flex firaRegular'>
        <span className='flex-grow-1'>Estimated Tax:</span>
        <span>$0.00</span>
      </div>
      <hr className='lineHR' />
      <div className='d-flex text-red200 firaMedium'>
        <span className='flex-grow-1'>Order Total:</span>
        <span>${calculatedPrice}</span>
      </div>
      {complimentary ? (
        <div className='d-flex'>
          <hr />
          <TextArea
            label='Sales Note'
            placeholder='Sales note here'
            parentClass='w-100 mt-3'
            name='salesNotes'
            handleChange={(name: string, value: string) =>
              setSubscriptionDetails({ salesNotes: value })
            }
          />
        </div>
      ) : null}

      {showBtn ? (
        <Buttons
          type='submit'
          classNames='bttn-primary float-right mt-4'
          text={'Make Payment'}
          onClick={makePayClick}
        />
      ) : null}
    </>
  );
};

export default OrderSummmery;
