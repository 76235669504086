import { atom, selector } from 'recoil';

import { getEBidResponseDetails, getEBidResponseList } from '../services/ebids';
import {
  GetEBidResponseDetailsParameters,
  GetEBidResponseListParameters,
  GetResponseEventsParameters,
  ResponseApiResponse,
} from '../../types/ebids';

import { EBidResponse } from '../../types/suppliers';
import { ebidResponseItemParamType } from '../../types/biddetail';
import { getResponseEventsById } from '../services';
import { selectedBidIdState } from './bidState';
import { supplierMemberIdState } from './memberState';
import { track } from '../../utils/telemetry';

export const SELECTED_RESPONSE_ID_DEFAULT = -1;

/**
 * Make a service API call to populate a Recoil Selector.
 */
export const bidResponseListSelector = selector<EBidResponse[]>({
  key: 'bidResponseListSelector',
  get: async ({ get }) => {
    const selectedMemberId = get(supplierMemberIdState);
    const selectedBidId = get(selectedBidIdState);
    const requestParams: GetEBidResponseListParameters = {
      bidId: selectedBidId,
      memberId: selectedMemberId,
    };
    try {
      // API call
      const response = await getEBidResponseList(requestParams);
      return response;
    } catch (error: any) {
      track(
        `eBidResponseListSelector -> getEBidResponseList(bidId: ${selectedBidId}, memberId: ${selectedMemberId}) ERROR:`,
        {
          error,
          method: 'GET',
          errorMessage: error.message,
        },
      );
      console.error(`eBidResponseListSelector -> getEBidResponseList() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
      throw new Error(error.message);
    }
  },
});

/**
 * Make a service API call to populate a Recoil Selector.
 * TODO: Implement dynamic updates via WebHooks or explicit component re-instantiation.
 */
export const bidResponseDetailsSelector = selector<ResponseApiResponse | null>({
  key: 'bidResponseDetailsSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);

    const requestParams: GetEBidResponseDetailsParameters = { bidId: selectedBidId };
    try {
      // Skip the service call for the default bidId value (-1)
      const response = selectedBidId > 0 ? await getEBidResponseDetails(requestParams) : null;
      return response;
    } catch (error: any) {
      track(`bidResponseDetailsSelector -> getEBidResponseDetails(${selectedBidId}) ERROR:`, {
        error,
        method: 'GET',
        errorMessage: error.message,
      });
      console.error(`eBidResponseDetailsSelector -> getEBidResponseList() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
      throw new Error(error.message);
    }
    // }
  },
});

/**
 * Initialize the eBidResponseList state with the associated API call.
 * TODO: finalize response type
 */
export const responseListState = atom<EBidResponse[]>({
  key: 'responseListState',
  default: bidResponseListSelector,
});

/**
 * Initialize the eBidResponseDetails state with the associated API call.
 */
// TODO: finalize response type
export const bidResponseDetailsState = atom<any>({
  key: 'responseDetailsState',
  default: bidResponseDetailsSelector,
});

// export const responseDetailsStatusState = selector<CountdownStatus>({
//   key: 'responseDetailsStatusState',
//   get: ({get}) => {
//     // const details = get(responseDetailsState);
//     const details = get(responseDetailsState);
//     console.log('responseDetailsSelector', details);
//     const checked = deprecatedCheckCountDown(details?.bidOpenDate);
//     console.log('responseDetailsStatusState',checked);
//     return checked;
//   },
// });

// Response Events

/**
 * Set up an ID to be set as a selector dependency
 * to kick off additional selector service calls
 * when changed by user interaction.
 */
export const selectedResponseIdState = atom<number>({
  key: 'selectedResponseIdState',
  default: SELECTED_RESPONSE_ID_DEFAULT,
});

/**
 * Make a service API call to populate a Recoil Selector.
 */
//TODO - should this type be responseEventsParamType?
export const responseEventListSelector = selector<ebidResponseItemParamType>({
  key: 'responseEventListSelector',
  get: async ({ get }) => {
    const selectedResponseId = get(selectedResponseIdState);
    const requestParams: GetResponseEventsParameters = { responseId: selectedResponseId };
    try {
      const response =
        // selectedMemberId !== SELECTED_MEMBER_ID_DEFAULT ? await getEBidResponseList(requestParams) : null;
        await getResponseEventsById(requestParams);
      return response || [];
    } catch (error: any) {
      track(`eBidResponseListSelector -> getEBidResponseList(${selectedResponseId}) ERROR:`, {
        error,
        method: 'GET',
        errorMessage: error.message,
      });
      console.error(`eBidResponseListSelector -> getEBidResponseList() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
      // return [];
      throw new Error(error.message);
    }
  },
});

export const responseEventListState = atom<any>({
  key: 'responseEventListState',
  default: responseEventListSelector,
});
