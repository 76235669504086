import React from 'react';

import { Cell, NotificationFormRow } from './../../styled/AwardBidStyled';
import { EmailInput } from '../../../../forms/EmailInput';
import { isValidEmail } from '../../../../../utils/helpers';
import { Label } from '../../../../common/controls/text/Label';
import { NewSupplier } from '../../../../../types/awardBid';
import { newUnselectedPlanholders } from '../../../../../store/recoil/award-bid';
import { TextInput } from '../../../../forms/TextInput';
import { useLocalRecoilState } from '../../../../../shared/hooks/useLocalRecoilState';

interface NewPlanholderRowProps {
  supplier: NewSupplier;
  updateRow: (supplier: NewSupplier) => void;
  deleteEmptyOnBlur: () => void;
}

const NewPlanholderRow = (props: NewPlanholderRowProps) => {
  const { supplier, updateRow, deleteEmptyOnBlur } = props;

  function editName(name: string) {
    const tempSupplier = { ...supplier };
    tempSupplier.name = name;
    updateRow(tempSupplier);
    deleteEmptyOnBlur();
  }

  function editEmail(email: string) {
    const tempSupplier = { ...supplier };
    tempSupplier.email = email;
    updateRow(tempSupplier);
    deleteEmptyOnBlur();
  }

  return (
    <NotificationFormRow>
      <Cell width='50%'>
        <TextInput
          required
          name='name'
          value={supplier.name}
          onChange={editName}
          autoFocus={true}
          fullWidth={true}
        />
      </Cell>
      <Cell width='50%'>
        <EmailInput name='email' value={supplier.email} onChange={editEmail} fullWidth={true} />
      </Cell>
    </NotificationFormRow>
  );
};

/**
 * Used to add new planholders to a bid. This component adds NewSuppliers to
 * newUnselectedPlanholders state. Then when we navigate away from this page,
 * we save new planholders and call the endpoint to update our emailInfo object
 * with new recipients.
 */
export const AddUnselectedBidders = () => {
  const [suppliers, setSuppliers] = useLocalRecoilState(newUnselectedPlanholders);

  function allRowsAreValid() {
    for (const supplier of suppliers) {
      if (!supplier.name || !isValidEmail(supplier.email)) {
        return false;
      }
    }
    return true;
  }

  function updateRow(supplier: NewSupplier, index: number) {
    const tempSuppliers = [...suppliers];
    tempSuppliers[index] = supplier;
    setSuppliers(tempSuppliers);
  }

  function addNewRow(value: string) {
    const tempSuppliers = [...suppliers];
    tempSuppliers.push({
      name: value,
      email: '',
    });
    setSuppliers(tempSuppliers);
  }
  function deleteRowIfEmpty(index: number) {
    if (!suppliers[index].name && !suppliers[index].email) {
      const tempSuppliers = [...suppliers];
      tempSuppliers.splice(index, 1);
      setSuppliers(tempSuppliers);
    }
  }

  return (
    <>
      <NotificationFormRow header>
        <Cell width='50%'>
          <Label required>Unselected Planholder</Label>
        </Cell>
        <Cell width='50%'>
          <Label required>Email</Label>
        </Cell>
      </NotificationFormRow>
      {suppliers.map((supplier, index) => {
        return (
          <NewPlanholderRow
            key={index}
            supplier={supplier}
            updateRow={(supplier: NewSupplier) => updateRow(supplier, index)}
            deleteEmptyOnBlur={() => {
              deleteRowIfEmpty(index);
            }}
          />
        );
      })}

      {allRowsAreValid() && (
        <NotificationFormRow>
          <Cell width='50%'>
            <TextInput name='name-placeholder' value={''} onChange={addNewRow} fullWidth={true} />
          </Cell>
          <Cell width='50%'>
            <TextInput
              name='email-placeholder'
              value={''}
              disabled={true}
              onChange={false}
              fullWidth={true}
            />
          </Cell>
        </NotificationFormRow>
      )}
    </>
  );
};
