import React, { memo, useState } from 'react';
import { Buttons, CheckBox, TextArea } from '../../../customcontrols/index';
import { DeprecatedReduxDatepicker } from '../../../customcontrols/DeprecatedReduxDatepicker';
import moment from 'moment-timezone';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import Braintree from '../../../common/braintree';
import { deprecatedGetDate, toastFn } from '../../../../utils/helpers';
import { anyParamType } from '../../../../types/paymentdetails';
import { cardNonceType, cardParamType } from '../../../../types/subscriptions';
import { sharedTypes } from '../../../../types/shared';
import { usaDateFormat } from '../../../../utils/constants';

interface PropsTypes {
  modifySubscription: anyParamType;
  expiryDate: Date;
  toggleModifySubscription: anyParamType;
  newSubscriptionAmount: number;
  card: cardParamType;
  resetModifySubscription: anyParamType;
  shared?: sharedTypes;
}

const ModifySubscription = (props: PropsTypes) => {
  const {
    modifySubscription,
    expiryDate,
    toggleModifySubscription,
    newSubscriptionAmount,
    card,
    resetModifySubscription,
    shared,
  } = props;
  const { invaliddateerror } = shared || {};
  const onExpirationDateChange = (value: Date) => {
    setExpirationDate(value);
    if (newSubscriptionAmount && newSubscriptionAmount >= 1) {
      resetModifySubscription();
    }
  };

  const [cardModal, setChangeCardModalStatus] = useState(false);

  const [cardNonce, setCardNonce] = useState({} as cardNonceType);

  const handeChangeCardClick = () => {
    setChangeCardModalStatus(!cardModal);
  };

  const onCardSubmit = (card: any) => {
    setCardNonce(card);
    setChangeCardModalStatus(!cardModal);
  };

  const [notes, setNotes] = useState('');

  const [isFreeCharge, setFreeCharge] = useState(false);

  const [newexpirydate, setExpirationDate] = useState(expiryDate);

  const handleModifySubscription = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = {
      subscriptionRule: 'Modify',
      accountingRule: isFreeCharge ? 'Free' : 'Charge',
      expirationDate: deprecatedGetDate(newexpirydate),
      isreadonly: false,
      notes: notes,
      nonce: cardNonce && cardNonce.nonce && cardNonce.nonce.length > 0 ? cardNonce.nonce : '',
    };
    if (notes && notes.length > 0) {
      modifySubscription(payload);
      toggleModifySubscription();
      setNotes('');
      setFreeCharge(false);
      setExpirationDate(expiryDate);
    } else {
      toastFn('error', 'Please enter sales notes', 'approvetoastid');
    }
  };

  const handleGetNewSubscriptionAmount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = {
      subscriptionRule: 'Modify',
      accountingRule: 'Charge',
      expirationDate: newexpirydate,
      isreadonly: true,
      existingExpiryDate: expiryDate,
    };
    modifySubscription(payload);
  };

  const getCardMessage = () => {
    const { details, nonce } = cardNonce || {};
    const { lastFour, cardType } = details || {};
    return `The balance amount ${newSubscriptionAmount} will be paid through ${
      nonce && nonce.length > 0 ? cardType : card.cardType
    } card ending
	${nonce && nonce.length > 0 ? lastFour : card.cardLastFour}`;
  };

  return (
    <>
      <div className='mb-4'>
        {' '}
        <label>Current Expiration Date : {moment(expiryDate).format(usaDateFormat)}</label>
      </div>

      <DeprecatedReduxDatepicker
        label='New Expiration Date'
        name='newexpirydate'
        classNames='class'
        minDate={expiryDate}
        maxDate={moment(expiryDate).add(12, 'M').format()}
        handleChange={(name: string, value: Date) => onExpirationDateChange(value)}
        value={newexpirydate && moment(newexpirydate)}
        optional={`(e.g. "${moment().format(usaDateFormat)}")`}
      />

      {newSubscriptionAmount && newSubscriptionAmount >= 1 ? (
        <>
          <div className='mb-1'>
            <label className='text-danger'>{getCardMessage()}</label>
            <Buttons
              text='Change'
              classNames='bttn-secondary small my-4 w-50'
              onClick={handeChangeCardClick}
            />
          </div>
          <div className='mb-4'>
            <CheckBox
              title='Make it complementary'
              label='Make it complementary'
              handleChecked={(name: string, value: boolean) => setFreeCharge(value)}
              checked={isFreeCharge}
            />
          </div>
          <div className='mb-3'>
            <TextArea
              label='Sales Notes'
              type='text'
              value={notes}
              handleChange={(name: string, value: string) => setNotes(value)}
              classNames='small'
            />
          </div>
        </>
      ) : null}

      <div className='d-flex justify-content-between clear-both'>
        <Buttons text='Cancel' classNames='bttn-secondary' onClick={toggleModifySubscription} />

        {newSubscriptionAmount && newSubscriptionAmount >= 1 ? (
          <Buttons
            text='Place Order'
            classNames='bttn-primary'
            onClick={handleModifySubscription}
            disable={invaliddateerror}
          />
        ) : (
          <Buttons
            text='Modify'
            classNames='bttn-primary'
            onClick={handleGetNewSubscriptionAmount}
            disable={invaliddateerror}
          />
        )}
      </div>

      <ModalPopUp
        title={'Payment Method'}
        size='md'
        closeModal={handeChangeCardClick}
        isOpen={cardModal}
      >
        <Braintree
          onSubmitPayment={onCardSubmit}
          cancelClick={handeChangeCardClick}
          buttonname='Accept'
          title='New Card Info'
        />
      </ModalPopUp>
    </>
  );
};

export default memo(ModifySubscription);
