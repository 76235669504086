import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../store/actions';
import Products from './products';
import Summary from './summary';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { AgencySelection } from './agency-selection';
import ModifySubscription from './modifySubscription';
import Configurations from '../../../../settings';
import { accountinfoType, anyParamType } from '../../../../types/paymentdetails';
import { authTypes } from '../../../../types/auth';
import { sharedTypes } from '../../../../types/shared';
import { Link } from 'react-router-dom';
import { deprecatedGetDateTimeZoneConverted } from '../../../../utils/helpers';
import { Product } from '../../../../containers/AgencySelectionContainer';

interface propsTypes extends PropsFromRedux {
  accountinfo: accountinfoType;
  history: anyParamType;
  auth: authTypes;
  getAccountInfo: any;
  getProfileInfo: any;
  setBreadcrumb: any;
  updateAutoRenewalSubscription: any;
  setAccountInfoDetails: any;
  modifySubscription: any;
  resetModifySubscription: any;
  cancelSubscription: any;
  getSubscriptionState: any;
  getSubscriptionAgencies: any;
  getSubscriptionCounties: any;
  UpdateSupplierFreeAgencies: any;
  shared: sharedTypes;
}

const Subscription = (props: propsTypes) => {
  const {
    getAccountInfo,
    getProfileInfo,
    setBreadcrumb,
    updateAutoRenewalSubscription,
    setAccountInfoDetails,
    modifySubscription,
    resetModifySubscription,
    cancelSubscription,
    accountinfo,
    history,
    auth,
  } = props;
  const { mt: memberType, mc: isMainContact = false, opi = '' } = auth;
  const profileInfo = accountinfo || {};
  const { card, result, agencySelection = false } = profileInfo;
  const {
    services = [],
    daysToRenew,
    expiryDate,
    products = [] /*contacts = []*/,
  } = accountinfo.accountinfo || {};
  const { isAutoRenewalOn } = card || {};
  const { newSubscriptionAmount } = accountinfo;
  const hasProfile = result === 'SUCCESS';

  useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);

  //let isMainContact = contacts && contacts.length && contacts[0].accountId.toString() === accountId
  const serviceText = [] as Array<string>;
  if (services && services.length > 0) {
    if (
      services.filter(service => service.membershipLevelId === 1 || service.membershipLevelId === 2)
        .length > 0
    ) {
      serviceText.push('BidWire');
    }
    if (
      services.filter(service => service.membershipLevelId === 3 || service.membershipLevelId === 4)
        .length > 0
    ) {
      serviceText.push('QuoteWire');
    }
  }

  const currentProducts = products.map(product => {
    return {
      productId: product.productId,
      productName: product.productName,
      productType: product.productType,
      price: product.price,
    };
  });

  let freeAgencies = [];
  let counties = [];
  let states = [];
  let naProducts = [] as Product[];
  let nonFreeProducts = [] as Product[];

  if (products && products.length > 0) {
    freeAgencies = products.filter(p => p.productType === 'AG');
    counties = products.filter(p => p.productType === 'CT');
    states = products.filter(p => p.productType === 'ST');
    naProducts = products.filter(p => p.productType === 'NA');
    nonFreeProducts = products.filter(p => p.productType !== 'AG');
  }

  const userType =
    counties.length > 0 || states.length > 0 || naProducts.length > 0
      ? 'SS'
      : freeAgencies.length > 0 &&
        counties.length === 0 &&
        states.length === 0 &&
        naProducts.length === 0
      ? 'FA'
      : 'SB';

  const toggleAgencySelection = useCallback(() => {
    setAccountInfoDetails({ agencySelection: !agencySelection });
  }, [setAccountInfoDetails, agencySelection]);

  const [modifySubscriptionModal, setModifySubscriptionModal] = useState(false);

  const toggleModifySubscription = () => {
    setModifySubscriptionModal(!modifySubscriptionModal);
    resetModifySubscription();
  };

  useEffect(() => {
    setBreadcrumb({
      component: (
        <>
          <li title='Renew / Upgrade'>
            <Link
              className='staticLink'
              title='Edit'
              rel='noopener noreferrer'
              // target='_blank'
              to='/subscription/renewal'
            >
              <i className='mdi mdi-autorenew mr-1' /> Renew / Upgrade
            </Link>
          </li>

          {opi ? (
            ['SB', 'FA', 'SS'].includes(userType) && naProducts.length === 0 ? (
              <li>
                <span className='staticLink' onClick={() => toggleAgencySelection()}>
                  <i className='mdi mdi-transfer-up mr-1' />{' '}
                  {userType === 'SB' ? 'Upgrade to free agency' : 'Modify free agency selection'}
                </span>
              </li>
            ) : null
          ) : naProducts.length === 0 && userType === 'SB' ? (
            <li>
              <span className='staticLink' onClick={() => toggleAgencySelection()}>
                <i className='mdi mdi-transfer-up mr-1' /> {'Add Your Free Agency'}
              </span>
            </li>
          ) : null}
        </>
      ),
      page: 'Subscriptions',
      title: 'Subscriptions',
      altname: 'Subscriptions',
      name: 'Subscriptions',
    });
  }, [
    daysToRenew,
    history,
    memberType,
    setBreadcrumb,
    opi,
    toggleAgencySelection,
    userType,
    naProducts.length,
  ]);

  const summary = {
    serviceText,
    services,
    daysToRenew,
    expiryDate,
    userType,
    hasProfile,
    nonFreeProducts,
    isAutoRenewalOn,
    isMainContact,
    memberType,
    getProfileInfo,
    updateAutoRenewalSubscription,
  };

  return (
    <>
      <div className='container'>
        {memberType === 'SB' ? (
          <div className='row justify-content-center'>
            <div className='col-12'>
              <div className='colWrapper text-center'>
                {opi ? (
                  <>
                    <div className='d-flex justify-content-center'>
                      <div className='col-6 text-center'>
                        <Link
                          className='bttn-accent mb-5 d-block mx-auto w-50'
                          title='Upgrade Subscription'
                          rel='noopener noreferrer'
                          // target='_blank'
                          to='/subscription/renewal'
                        >
                          Upgrade Subscription
                        </Link>
                        <span
                          className='bttn-accent d-block mx-auto w-50'
                          title='Upgrade to free agency'
                          onClick={toggleAgencySelection}
                        >
                          Upgrade to free agency
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='col-8 text-center mx-auto'>
                    <Link
                      className='bttn-accent mb-5 d-block mx-auto w-50'
                      title='Upgrade Subscription'
                      rel='noopener noreferrer'
                      // target='_blank'
                      to='/subscription/renewal'
                    >
                      Upgrade Subscription
                    </Link>
                    <span
                      className='bttn-accent d-block mx-auto w-50'
                      title='Upgrade to free agency'
                      onClick={toggleAgencySelection}
                    >
                      Upgrade to free agency
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='row'>
              <div className='col-8'>
                <Products
                  {...props}
                  upgradeSupplier={() => toggleAgencySelection()}
                  userType={userType}
                  products={currentProducts}
                  //summary={summary}
                  //history={history}
                  //shared={shared}
                  naProducts={naProducts}
                />
              </div>
              <div className='col-4'>
                <Summary
                  cancelSubscriptions={cancelSubscription}
                  {...props}
                  modifySubscription={() => toggleModifySubscription()}
                  data={summary}
                />
              </div>
            </div>
          </>
        )}
        {['SB', 'FA', 'SS'].includes(userType) && naProducts.length === 0 ? (
          <ModalPopUp
            size='lg'
            title={userType === 'SB' ? 'Upgrade to free agency' : 'Modify free agency selection'}
            closeModal={toggleAgencySelection}
            isOpen={agencySelection === true}
            backdrop='static'
          >
            <AgencySelection
              {...props}
              userType={opi ? 'ops' : 'supplier'}
              products={currentProducts}
              toggleAgencySelection={toggleAgencySelection}
            />
          </ModalPopUp>
        ) : null}
        {userType === 'SS' ? (
          <ModalPopUp
            size='md'
            title='Modify Subscription'
            closeModal={toggleModifySubscription}
            isOpen={modifySubscriptionModal === true}
            backdrop='static'
          >
            <ModifySubscription
              card={accountinfo.profileInfo && accountinfo.profileInfo.card}
              newSubscriptionAmount={newSubscriptionAmount}
              expiryDate={
                new Date(
                  deprecatedGetDateTimeZoneConverted(expiryDate, 'Eastern Time (US & Canada)'),
                )
              }
              modifySubscription={modifySubscription}
              toggleModifySubscription={toggleModifySubscription}
              resetModifySubscription={resetModifySubscription}
            />
          </ModalPopUp>
        ) : null}
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    accountinfo: state.accountinfo,
    auth: state.auth,
    shared: state.shared,
    subscriptions: state.subscriptions,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Subscription));
