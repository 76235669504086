import { Cell, Column } from 'react-table';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { Assert, convertResponseToRequiredDoc, isTodayOrEarlier } from '../../../../utils/helpers';
import { BoldText, SpanSpacerLeft, SpanSpacerRight } from '../../../../shared/styles';
import { commonFieldNames, commonLabels, ControlLabels } from '../../../../shared/constants';
import {
  requiredDocumentResponsesState,
  selectedRequiredDocumentState,
} from '../../../../store/recoil/award-bid';
import { TableBase, TableStyleWrapper } from '../../../common/table';
import { useRequiredDocumentResponses, useRequiredDocuments } from '../../../../shared/hooks';
import { ConfirmModal } from '../../../common/modals/ConfirmModal';
import { DatePicker } from '../../../fields/date-picker/DatePicker';
import { DeleteIconCell } from '../../../common/table/renderers/DeleteIconCell';
import { Id } from '../../../../types/shared';
import { requiredDocLabels } from '../add-vendor-docs/constants';
import { RequiredDocumentResponseGetAPIResponse } from '../../../../types';
import { TextInput } from '../../../forms';
import { useResetOnUnmount } from '../../../../shared/hooks/useResetOnUnmount';

/**
 * Table for the display of Required Documents for all vendors in the Award Bid process
 * @param props: DocumentListTableProps
 * @returns
 */
export const RequiredDocumentsTable = () => {
  // Hooks //
  const {
    refreshRequiredDocumentResponses,
    requiredDocumentResponses,
  } = useRequiredDocumentResponses();
  const { deleteRequiredDoc, updateRequiredDocs } = useRequiredDocuments();

  useResetOnUnmount(requiredDocumentResponsesState);

  /// Application state
  const [selectedRequiredDocument, setSelectedRequiredDocument] = useRecoilState(
    selectedRequiredDocumentState,
  );
  const resetSelectedRequiredDocument = useResetRecoilState(selectedRequiredDocumentState);

  // Local state //
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  // Constants //

  const columnConfig: Column<RequiredDocumentResponseGetAPIResponse>[] = [
    {
      accessor: 'docTitle',
      Header: requiredDocLabels.documentsFromSupplier,
      minWidth: 300,
      Cell: (cellData: Cell<RequiredDocumentResponseGetAPIResponse>) => {
        const requiredDoc = { ...cellData.row.original };
        return (
          <TextInput
            key={requiredDoc.bidAwardRequiredDocId}
            fullWidth
            value={requiredDoc['docTitle']}
            name={'docTitle'}
            onChange={false}
            required
            onBlur={value => {
              updateRow(requiredDoc.bidAwardRequiredDocId, 'docTitle', value);
            }}
          ></TextInput>
        );
      },
    },
    {
      accessor: 'dueDate',
      Cell: (cellData: Cell<RequiredDocumentResponseGetAPIResponse>) => {
        const requiredDoc = { ...cellData.row.original };
        return (
          <DatePicker
            name=''
            onChange={value => {
              updateRow(requiredDoc.bidAwardRequiredDocId, 'dueDate', value);
            }}
            disabledDate={isTodayOrEarlier}
            allowClear={false}
            value={requiredDoc.dueDate}
            fullWidth
          />
        );
      },
      Header: commonFieldNames.dueDate,
      minWidth: 200,
    },
    {
      Header: '',
      accessor: 'isDelete',
      Cell: DeleteIconCell<number, RequiredDocumentResponseGetAPIResponse>({
        idField: 'bidAwardRequiredDocId',
        labelField: 'docName',
        handleClick: showDeleteDocModal,
      }),
      maxWidth: 30,
      disableSortBy: true,
    },
  ];

  // Hooks //
  useEffect(() => {
    refreshRequiredDocumentResponses();
  }, [refreshRequiredDocumentResponses]);

  // Functions //
  /** Delete row */

  function hideDeleteModal() {
    setDeleteModalIsOpen(false);
    resetSelectedRequiredDocument();
  }

  function showDeleteDocModal({ id }: Id<number>) {
    const selectedDoc = requiredDocumentResponses.find(doc => {
      return doc?.bidAwardRequiredDocId === id;
    });
    Assert(
      !!selectedDoc,
      'Expected: `selectedDoc` to exist in requiredDocuments (for reqDoc - delete)',
      'src/components/buyer/awardbid/add-vendor-docs/RequiredDocumentsTable.tsx',
    );

    if (selectedDoc) {
      setSelectedRequiredDocument({ ...selectedDoc, isDelete: false });
      setDeleteModalIsOpen(true);
    } else {
      deleteReqDoc();
    }
  }

  async function deleteReqDoc() {
    if (selectedRequiredDocument) {
      const { bidAwardRequiredDocId, docTitle, dueDate, isDelete } = selectedRequiredDocument;
      await deleteRequiredDoc({
        bidAwardRequiredDocId,
        docName: '',
        docTitle,
        docDescription: '',
        dueDate,
        isDelete,
      });
    }
    hideDeleteModal();
  }

  function updateRow(id: number, field: 'docTitle' | 'dueDate', value: string | null) {
    if (value === '' && field === 'docTitle') {
      showDeleteDocModal({ id });
      return;
    }
    Assert(!!value, 'We must pass a value', 'RequiredDocumentsTable');

    const selectedDocResponse = requiredDocumentResponses.find(doc => {
      return doc.bidAwardRequiredDocId === id;
    });
    Assert(
      !!selectedDocResponse,
      'We must have a selectedDocResponse',
      'RequiredDocumentsTable.tsx',
    );
    const updatedRequiredDoc = convertResponseToRequiredDoc(selectedDocResponse);
    updatedRequiredDoc[field] = value;
    updateRequiredDocs([updatedRequiredDoc]);
  }

  function getConfirmDeleteMessageComponent() {
    const docDisplayName = selectedRequiredDocument
      ? selectedRequiredDocument['docTitle']
      : 'this document';
    return (
      <>
        <SpanSpacerRight>{ControlLabels.confirmDeletePrefix}</SpanSpacerRight>
        <BoldText>{docDisplayName}</BoldText>
        <SpanSpacerLeft>{'?'}</SpanSpacerLeft>
      </>
    );
  }

  return (
    <>
      <TableStyleWrapper>
        <TableBase columns={columnConfig} data={requiredDocumentResponses} />
      </TableStyleWrapper>

      {/* Delete Required Document confirmation modal */}
      <ConfirmModal
        confirmMessage={getConfirmDeleteMessageComponent()}
        approveAction={deleteReqDoc}
        title={`${commonLabels.confirmDelete} `}
        closeModal={hideDeleteModal}
        isOpen={deleteModalIsOpen}
        size='md'
      />
    </>
  );
};
