import React from 'react';

import { BidResponseLabels, BidSummaryLabels } from '../../../shared/constants/bids';
import {
  deprecatedCheckTimezoneCountDown,
  deprecatedGetDateTimeZoneConverted,
} from '../../../utils/helpers';
import {
  DueDateRowValue,
  PanelItemWrapper,
  PanelRow,
  PanelRowHeader,
  PanelRowValue,
} from '../../../shared/styles';

import CountDownTimer from '../../../components/common/countdowntimer';

import { BidSummaryApiResponse } from '../../../types/bidssummary';
import { commonLabels } from '../../../shared/constants';
import { Data } from '../../../types/shared';
import { displayDate } from '../../../utils/helpers';
import { StatusPill } from '../../../components/common/controls/static/StatusPill';

export function BidSummaryPanelView(props: Data<BidSummaryApiResponse>) {
  const { data } = props || null;
  const {
    agencyName,
    agencyState,
    bidIdentifier,
    bidOpenDate,
    bidName,
    bidNumber,
    dueDate,
    responseDateTime,
    responseStatus,
    tzn,
    tzfn,
  } = data;

  // TODO: Ensure that we always have a timeZone, and convert function to displayDateTime once that is merged in.

  const dueDateDisplay = dueDate
    ? tzfn
      ? `${deprecatedGetDateTimeZoneConverted(dueDate, tzfn)} ${tzn}`
      : `${displayDate(dueDate)}${tzn ? ` ${tzn}` : ''}`
    : commonLabels.dateUnavailable;

  // TODO: Ensure that we always have a timeZone, and convert function to displayDateTime once that is merged in.
  const responseDateDisplay = responseDateTime
    ? tzfn
      ? `${deprecatedGetDateTimeZoneConverted(responseDateTime, tzfn)} ${tzn}`
      : `${displayDate(responseDateTime)}${tzn ? ` ${tzn}` : ''}`
    : commonLabels.dateUnavailable;

  const CountdownDisplayComponent = !dueDate ? (
    ''
  ) : deprecatedCheckTimezoneCountDown(dueDate, tzfn) === 'closed' ? (
    <span>{BidResponseLabels.closed}</span>
  ) : (
    <CountDownTimer tzfn={tzfn} dueDate={dueDate} />
  );

  const bidNumberDisplay = bidNumber || bidIdentifier;

  return (
    <>
      <PanelItemWrapper>
        {/* Agency name */}
        <PanelRow>
          <PanelRowHeader>{BidSummaryLabels.agencyName}</PanelRowHeader>
          <PanelRowValue title={agencyName}>{agencyName}</PanelRowValue>
        </PanelRow>
        {/* Bid number */}
        <PanelRow>
          <PanelRowHeader>{BidSummaryLabels.bidNumber}</PanelRowHeader>
          <PanelRowValue title={bidNumberDisplay}>{bidNumberDisplay}</PanelRowValue>
        </PanelRow>
        {/* Bid name */}
        {bidName && (
          <PanelRow>
            <PanelRowHeader>{BidSummaryLabels.bidName}</PanelRowHeader>
            <PanelRowValue title={bidName}>{bidName}</PanelRowValue>
          </PanelRow>
        )}
        {/* Response date */}
        {responseDateTime && (
          <PanelRow>
            <PanelRowHeader>{BidSummaryLabels.responseDate}</PanelRowHeader>
            <DueDateRowValue title={responseDateDisplay}>{responseDateDisplay}</DueDateRowValue>
          </PanelRow>
        )}
        {/* Agency state */}
        {agencyState && (
          <PanelRow>
            <PanelRowHeader>{BidSummaryLabels.state}</PanelRowHeader>
            <PanelRowValue title={agencyState}>{agencyState}</PanelRowValue>
          </PanelRow>
        )}
        {/* Status */}
        {responseStatus && (
          <PanelRow>
            <PanelRowHeader>{BidSummaryLabels.status}</PanelRowHeader>
            <PanelRowValue title={responseStatus}>
              <StatusPill status={responseStatus} />
            </PanelRowValue>
          </PanelRow>
        )}
      </PanelItemWrapper>
    </>
  );
}
