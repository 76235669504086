import DropIn from 'braintree-web-drop-in-react';
import React, { memo, useState } from 'react';
import { appKeys } from '../../utils/constants';
import { Buttons } from '../customcontrols';
import { anyParamType } from '../../types/paymentdetails';

interface PropsTypes {
  onSubmitPayment: anyParamType;
  isEditPayment?: boolean;
  buttonname: string;
  title?: string;
  pagefor?: string;
  cancelClick: () => void;
}

const Braintree = (props: PropsTypes) => {
  // const [enableAccept, setEnableAccept] = useState(false as boolean)
  const [instance, setInstance] = useState('' as any);
  const {
    onSubmitPayment,
    isEditPayment = false,
    buttonname = 'Accept',
    title = '',
    pagefor = '',
    cancelClick,
  } = props;

  const message = isEditPayment
    ? 'Add a payment method'
    : 'Add a payment method to proceed with your purchase';

  const dropInOptions = {
    translations: {
      chooseAWayToPay: message,
    },
    authorization: appKeys.BTkey,
    card: {
      cardholderName: {
        required: true,
      },
    },
  } as any;

  /* if (!isEditPayment) {
				dropInOptions.paypal = {
			flow: 'checkout',
			amount: '10.00',
			currency: 'USD'
		}
		dropInOptions.venmo = { allowNewBrowserTab: false }

		dropInOptions.applePay = {
			displayName: 'Demandstar store',
			paymentRequest: {
				total: {
					label: 'Demandstar Store',
					amount: '19.99'
				}
			}
		}
	} */

  /* 	const beforesubmit = (value: any) => {
		if (value && value.type) {
			setEnableAccept(true)
		}
	} */

  const submitcard = async () => {
    const token = await instance.requestPaymentMethod();
    if (token && token.nonce) {
      onSubmitPayment(token);
    }
  };

  return (
    <>
      {title ? <title>{title}</title> : null}
      {appKeys && appKeys.BTkey ? (
        <>
          <DropIn
            options={dropInOptions}
            onInstance={instance => setInstance(instance)}
            // onPaymentMethodRequestable={beforesubmit}
          />
          <div className='clearfix'>
            {pagefor === 'actioninfo-payments' ? (
              <Buttons
                classNames='bttn bttn-secondary float-left mb-3 mt-2'
                onClick={cancelClick}
                type='button'
                text='Cancel'
                title='Cancel'
              />
            ) : null}
            <Buttons
              classNames='bttn bttn-primary float-right'
              onClick={submitcard}
              type='submit'
              text={buttonname}
              title={buttonname}
              //disable={!enableAccept}
            />
          </div>
        </>
      ) : (
        <div>
          <h1>Loading...</h1>
        </div>
      )}
    </>
  );
};

export default memo(Braintree);
