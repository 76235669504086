import { Message, Status } from '../../types/shared';
import { axiosPostAuthenticated } from './api';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';
import { RequiredDocumentResponseGetAPIResponse } from '../../types';

/**
 * Service call to get Required Documents
 * @param {number} bidId
 * @returns {RequiredDocumentGetAPIResponse}
 */
export function getRequiredDocuments(bidId: number) {
  // Switch the url and `bidId` values from a by-id call, to a general request depending on the `bidId` parameter.
  const url = bidId <= 0 ? Paths.Award.RequiredDocResponses.all : Paths.Award.RequiredDocs.get;
  const requestBidId = Math.max(bidId, 0);

  return axiosPostAuthenticated<RequiredDocumentResponseGetAPIResponse[]>({
    baseURL: constants.api.url,
    data: {
      bidId: requestBidId,
    },
    url,
  });
}

export interface RequiredDocument {
  bidAwardRequiredDocId: number;
  docName: ''; // we are not using this anywhere
  docTitle: string;
  docDescription: string;
  dueDate: string;
  isDelete: boolean;
}

export type NewRequiredDocument = Omit<RequiredDocument, 'bidAwardRequiredDocId'>;

export interface UpdateRequiredDocumentsAPIRequest {
  bidId: number;
  documents: (NewRequiredDocument | RequiredDocument | RequiredDocumentResponseGetAPIResponse)[];
}

/**
 * Service call to update Required Documents
 * @param {UpdateRequiredDocumentsAPIRequest} payload
 * @returns {Promise<unknown>}
 */
export async function updateRequiredDocuments(payload: UpdateRequiredDocumentsAPIRequest) {
  const updateResponse = await axiosPostAuthenticated<Message & Status>({
    baseURL: constants.api.url,
    data: payload,
    url: Paths.Award.RequiredDocs.update,
  });
  return updateResponse;
}
