import React, { memo, useEffect } from 'react';
import { Field, Formik } from 'formik';

import { addbidParamType } from '../../../types/addbid';
import { sharedTypes } from '../../../types/shared';
import { toastFn } from '../../../utils/helpers';
import { WizardPage } from '../../../types/wizard';

import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import renderCheckBox from '../controls/inputs/checkbox';
import { DeprecatedFormikRadio } from '../controls/inputs/DeprecatedFormikRadio';
import AddBidWizardButtons from './AddBidWizardButtons';

interface PropsTypes {
  handleSubmit?: any;
  setAddBidDetails?: any;
  updateEbidInfo?: any;
  addbid?: addbidParamType;
  shared?: sharedTypes;
  currentwizard?: WizardPage;
  bidId?: number;
  setBreadcrumb?: any;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  viewsupplierinfortabulation?: any;
  getAddBidResponseItems?: any;
  getResponseDisplays?: any;
  currentBidName?: string;
  SkipNext?: any;
  addbidebidreduxForm?: any;
  initialValues?: any;
}

function Ebidinformation(props: PropsTypes) {
  const {
    setAddBidDetails,
    updateEbidInfo,
    addbid,
    shared,
    currentwizard,
    bidId,
    setBreadcrumb,
    valid,
    pristine,
    submitting,
    getAddBidResponseItems,
    getResponseDisplays,
    currentBidName,
    SkipNext,
    addbidebidreduxForm,
    initialValues,
  } = props;
  const { bidresponseitems = [], editable } = addbid || {};
  const { responseDisplays } = shared || {};

  useEffect(() => {
    if (bidId) getAddBidResponseItems({ bidId });
  }, [bidId, getAddBidResponseItems]);

  useEffect(() => {
    if (bidId) getResponseDisplays({ bidId });
  }, [bidId, getResponseDisplays]);

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  useEffect(() => {
    if (currentwizard) {
      setBreadcrumb({
        component: (
          <AddBidControl
            valid={valid}
            pristine={pristine}
            submitting={submitting}
            bidId={bidId}
            addbidebidreduxForm={addbidebidreduxForm}
          />
        ),
        page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
        title: currentBidName
          ? `Bid Update - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        altname: currentBidName
          ? `Bid Update - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        name: currentBidName ? currentBidName : 'Bid Creation',
        id: bidId,
        menuactive: 'bids',
      });
    }
  }, [
    addbidebidreduxForm,
    bidId,
    currentBidName,
    currentwizard,
    pristine,
    setBreadcrumb,
    submitting,
    valid,
  ]);

  const onSubmitFn = (data: any) => {
    if (data) {
      const filteredResponse = data.addbidresponseitems.filter(
        (item: any) => item.responsevalue === true,
      );
      if (
        (data.addbidresponseitems.length > 0 && filteredResponse.length > 0) ||
        data.addbidresponseitems.length === 0
      ) {
        if (editable === 'all') {
          updateEbidInfo({ bidId, currentwizard, data });
        } else {
          SkipNext();
        }
      } else toastFn('error', 'Select Required Documents', 'EbidDocError');
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitFn}>
      {formikProps => {
        const {
          values: any,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValidating,
          validateForm,
          isValid,
          dirty,
        } = formikProps;

        const { addbidresponseitems = [], viewsupplierinfortabulation = '' } = formikProps.values;

        return (
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-lg-12 '>
                <div className='innerColIndent mb-4'>
                  <h5>View Supplier Info on Tabulation Sheet</h5>
                  <p>
                    At no time will the contents of documents or pricing information be available
                    before the due date.
                  </p>
                  {responseDisplays &&
                    responseDisplays.map((item, index) => {
                      return (
                        <Field
                          name='viewsupplierinfortabulation'
                          key={index}
                          title={item.label}
                          label={viewsupplierinfortabulation}
                          component={DeprecatedFormikRadio}
                          type='radio'
                          value={item.value}
                          defaultChecked={
                            Number(item.value) === Number(viewsupplierinfortabulation)
                          }
                          disabled={editable !== 'all' ? true : false}
                          handleSelect={(name: string, value: string) => {
                            setFieldValue(name, value);
                          }}
                          optional={true}
                        />
                      );
                    })}
                </div>
                <div className='innerColIndent'>
                  <h5>Required Documents</h5>
                  <ul className='list-unstyled'>
                    {bidresponseitems.map((item, index) => (
                      <li key={index} className='d-flex justify-content-between'>
                        <Field
                          name={`addbidresponseitems[${index}].responsevalue`}
                          component={renderCheckBox}
                          title={item.Description}
                          key={index}
                          disabled={editable !== 'all' ? true : false}
                          handleChange={setFieldValue}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <AddBidWizardButtons {...props} />
          </form>
        );
      }}
    </Formik>
  );
}

export default memo(Ebidinformation);
