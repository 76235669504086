import React, { memo } from 'react';
import { Buttons } from '../../../../../customcontrols';
import CommodityListItem from './commoditylistitem';
import { commoditycodesTypes } from '../../../../../../types/broadcastlist';

interface propsTypes {
  GoBackToPage: any;
  SetSelectedCommodity: any;
  commoditycodes: commoditycodesTypes;
  pagefor?: string;
  getBroadcastListSearchVendors: any;
}

function Commodityselected(props: propsTypes) {
  const {
    GoBackToPage,
    SetSelectedCommodity,
    commoditycodes,
    pagefor = '',
    getBroadcastListSearchVendors,
  } = props;
  const { commoditieslist = [] } = commoditycodes || {};

  const selectedCommodity =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => !item.isExisting && item.isSelected && item.isDisplay)) ||
    [];
  const existingCommodity =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => item.isExisting && item.isDisplay && item.isSelected)) ||
    [];

  const commodityCodes =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => item.commodityCode !== '00' && item.isSelected)) ||
    [];

  return (
    <>
      <div className='sideBarSpacing'>
        <div className='clearfix text-center'>
          <Buttons
            text='Continue'
            title='Continue'
            type='submit'
            classNames='bttn bttn-primary w-75'
            onClick={() => getBroadcastListSearchVendors({})}
            disable={commodityCodes.length === 0}
          />
          <Buttons
            text='Cancel'
            title='Cancel'
            classNames='bttn bttn-secondary w-75 my-3'
            onClick={GoBackToPage}
          />
        </div>
        <h4 className='mt-0'>
          {pagefor === 'supplierCommCode' ? 'Your Commodity Codes' : 'All Commodity Codes'}{' '}
          {pagefor === 'addbid' || pagefor === 'addquotes' || pagefor === 'supplierCommCode' ? (
            <>({selectedCommodity.length + (existingCommodity ? existingCommodity.length : 0)})</>
          ) : null}
        </h4>

        {selectedCommodity && selectedCommodity.length > 0 && pagefor !== 'supplierCommCode' ? (
          <h5>
            Just Added{' '}
            {selectedCommodity && selectedCommodity.length > 0
              ? `(${selectedCommodity.length})`
              : ''}
          </h5>
        ) : null}

        {pagefor !== 'supplierCommCode' && (
          <CommodityListItem
            SetSelectedCommodity={SetSelectedCommodity}
            listings={selectedCommodity}
          />
        )}

        <>
          {pagefor !== 'supplierCommCode' ? (
            <h5>
              Previously Selected Codes{' '}
              {existingCommodity && existingCommodity.length > 0
                ? `(${existingCommodity.length})`
                : ''}
            </h5>
          ) : null}
          <CommodityListItem
            SetSelectedCommodity={SetSelectedCommodity}
            listings={existingCommodity}
          />
        </>

        {pagefor === 'supplierCommCode' ? <hr /> : null}
        {pagefor === 'supplierCommCode' && (
          <CommodityListItem
            SetSelectedCommodity={SetSelectedCommodity}
            listings={selectedCommodity}
            assignedClass='mp-4'
          />
        )}
      </div>
    </>
  );
}

//TODO: Fix casing.
export default memo(Commodityselected);
