import { createActionTypes, createNoLoadActionTypes } from './utils';

export const SET_ADD_QUOTES_DETAILS = createNoLoadActionTypes('SET_ADD_QUOTES_DETAILS');
export const RESET_ADD_QUOTES_DETAILS = createNoLoadActionTypes('RESET_ADD_QUOTES_DETAILS');
export const SUBMIT_ADD_QUOTES_INFO = createActionTypes('SUBMIT_ADD_QUOTES_INFO');
export const SUBMIT_ADD_QUOTES_WIRE = createActionTypes('SUBMIT_ADD_QUOTES_WIRE');
export const GET_ADD_QUOTES_ITEMS = createActionTypes('GET_ADD_QUOTES_ITEMS');
export const GET_ADD_QUOTES_INFO = createNoLoadActionTypes('GET_ADD_QUOTES_INFO');
export const GET_ADD_QUOTES_WIRE = createActionTypes('GET_ADD_QUOTES_WIRE');
export const SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD = createNoLoadActionTypes(
  'SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD',
);
export const GET_ADD_QUOTES_DOCUMENTS = createNoLoadActionTypes('GET_ADD_QUOTES_DOCUMENTS');
export const SUBMIT_ADD_QUOTES_DOCUMENT_DELETE = createActionTypes(
  'SUBMIT_ADD_QUOTES_DOCUMENT_DELETE',
);
export const SUBMIT_ADD_QUOTES_MOVE_NEXT = createActionTypes('SUBMIT_ADD_QUOTES_MOVE_NEXT');
export const GET_ADD_QUOTES_COMPLETED_PAGE = createActionTypes('GET_ADD_QUOTES_COMPLETED_PAGE');
export const GET_ADD_QUOTES_CAN_COMPLETE = createNoLoadActionTypes('GET_ADD_QUOTES_CAN_COMPLETE');
export const SUBMIT_ADD_QUOTES_COMPLETE = createActionTypes('SUBMIT_ADD_QUOTES_COMPLETE');
export const GET_ADD_QUOTES_NUMBERS = createNoLoadActionTypes('GET_ADD_QUOTES_NUMBERS');
export const ADD_QUOTE_UPDATE_MEMBER_INFO = createNoLoadActionTypes('ADD_QUOTE_UPDATE_MEMBER_INFO');
export const RESET_ADD_QUOTES = createNoLoadActionTypes('RESET_ADD_QUOTES');
