export const alertPercentage = 40;

export const registrationComponent = {
  AboutCompany: 'AboutCompany',
  AccountConfirmation: 'AccountConfirmation',
  AccountInfo: 'AccountInfo',
  BillingAddress: 'BillingAddress',
  ChooseAgency: 'ChooseAgency',
  ChooseSubscription: 'ChooseSubscription',
  CommodityCode: 'CommodityCode',
  Company: 'CompanyAddress',
  CompleteProfile: 'CompleteProfile',
  CreateAccount: 'CreateAccount',
  EmailAccount: 'EmailAccount',
  MainContactInfo: 'MainContactInfo',
  ManageUserAccount: 'ManageUserAccount',
  OrderConfirmation: 'OrderConfirmation',
  Payment: 'Payment',
  PrintReceipt: 'PrintReceipt',
  ProgramResult: 'ProgramResult',
  Programs: 'Programs',
  ReviewOrder: 'ReviewOrder',
  SelfDeclaration: 'SelfDeclaration',
  UserAccount: 'UserAccount',
};

export const promptSkipPages = [
  registrationComponent.AccountConfirmation,
  registrationComponent.EmailAccount,
];
