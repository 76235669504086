import React from 'react';
import { Table } from 'reactstrap';
import { CheckBox, DeprecatedInput, SelectBox } from '../../customcontrols/index';

function ProductList() {
  return (
    <>
      <div className='headerFilter clearfix d-flex align-items-center'>
        <DeprecatedInput
          label='Product Name'
          parentClass='w-30 mt-0'
          placeholder='Search Product'
        />
        <SelectBox label='Product Type' options={[]} />
      </div>
      <Table className='tableHData'>
        <tr>
          <th>Product Name</th>
          <th>Type</th>
          <th>Group</th>
          <th>Price</th>
          <th>Active</th>
          <th></th>
        </tr>
        <tr>
          <td>Bay County</td>
          <td>State</td>
          <td>FL</td>
          <td>123</td>
          <td>
            <CheckBox checked={true} />
          </td>
          <td>
            <i className='mdi mdi-square-edit-outline staticLink' title='Edit' />
          </td>
        </tr>
        <tr>
          <td>Bay County</td>
          <td>State</td>
          <td>FL,AL</td>
          <td>123</td>
          <td>
            <CheckBox checked={true} />
          </td>
          <td>
            <i className='mdi mdi-square-edit-outline staticLink' title='Edit' />
          </td>
        </tr>
        <tr>
          <td>Bay County</td>
          <td>State</td>
          <td>FL</td>
          <td>123</td>
          <td>
            <CheckBox checked={true} />
          </td>
          <td>
            <i className='mdi mdi-square-edit-outline staticLink' title='Edit' />
          </td>
        </tr>
        <tr>
          <td>Bay County</td>
          <td>State</td>
          <td>FL</td>
          <td>123</td>
          <td>
            <CheckBox />
          </td>
          <td>
            <i className='mdi mdi-square-edit-outline staticLink' title='Edit' />
          </td>
        </tr>{' '}
      </Table>
    </>
  );
}

export default ProductList;
