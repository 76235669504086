import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import history from '../../utils/history';
import * as actionTypes from '../actionTypes';
import * as Api from '../services';
import { toastFn } from '../../utils/helpers';

const bidsPaymenttoastID = 'b03af9e5-08ef-442f-8dda-90cb7b98ca9b';

// To get card tabs
export function* watchPaymentCard() {
  yield takeLatest(actionTypes.GET_PAYMENT_CARD_DETAILS.TRIGGER, paymentCard);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* paymentCard(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_PAYMENT_CARD_DETAILS.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getBidsPaymentCardDetails);
    const responsevalue = response.data.result || '';
    const payload = {
      card: responsevalue.cardNumberMask ? 'existingcard' : 'newcard',
      cardinfo: responsevalue.cardNumberMask ? responsevalue : {},
    };
    yield put({ type: actionTypes.GET_PAYMENT_CARD_DETAILS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PAYMENT_CARD_DETAILS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

// Submit payment card(addcharge)
export function* watchEBitsAddChargePayment() {
  yield takeLatest(actionTypes.SUBMIT_EBITS_PAYMENT_CARD.TRIGGER, eBitsAddChargePayment);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* eBitsAddChargePayment(action: { payload: Record<string, unknown> | undefined }): any {
  yield put({ type: actionTypes.SUBMIT_EBITS_PAYMENT_CARD.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.addChargePayment, action.payload);
    const payload = yield (response.data && response.data.result && response.data.result.result) ||
      '';
    if (payload === 'SUCCESS') {
      yield getCardAccountInfo(payload);
    } else {
      yield getCardAccountInfo(payload);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_EBITS_PAYMENT_CARD.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* getCardAccountInfo(payload: any) {
  yield put({ type: actionTypes.GET_PAYMENT_CARD_DETAILS.TRIGGER, payload: '' });
  yield put({ type: actionTypes.SUBMIT_EBITS_PAYMENT_CARD.SUCCESS, payload });
}

// Submit change billing address
export function* watchEBitsChangeBillingAddress() {
  yield takeLatest(
    actionTypes.SUBMIT_EBITS_CHANGE_BILLING_ADDRESS.TRIGGER,
    eBitsChangeBillingAddress,
  );
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* eBitsChangeBillingAddress(action: { payload: any }): any {
  yield put({
    type: actionTypes.SUBMIT_EBITS_CHANGE_BILLING_ADDRESS.REQUEST,
    meta: action.payload,
  });

  try {
    const memberinfo = yield select(state => state.memberinfo);
    const { mi: memberId = 0 } = memberinfo || {};
    const { data: values = {} } = action.payload || {};
    const finalvalue = {
      memberId: memberId,
      stateId: (values.state && values.state.value) || 0,
      countryId: (values.country && values.country.value) || 0,
      countyId: (values.county && values.county.value) || 0,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      postalCode: values.postalCode,
      addressType: 'BA',
    };
    const response = yield call(Api.changeBillingAddress, finalvalue);
    const payload = (response.data && response.data.result && response.data.result.status) || '';
    yield put({ type: actionTypes.SUBMIT_EBITS_CHANGE_BILLING_ADDRESS.SUCCESS, payload });
    yield put({ type: actionTypes.LOAD_ACCOUNT_INFO.TRIGGER });
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_EBITS_CHANGE_BILLING_ADDRESS.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

// Submit payment
export function* watchEBitsPaymentMethod() {
  yield takeLatest(actionTypes.SUBMIT_EBITS_PAYMENT_METHOD.TRIGGER, eBitsPaymentMethod);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* eBitsPaymentMethod(action: { payload: { data: { bidId: number } | undefined } }): any {
  yield put({ type: actionTypes.SUBMIT_EBITS_PAYMENT_METHOD.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.submitebitpayment, action.payload.data);
    const payload = (response.data && response.data.result && response.data.result.status) || '';
    const bidId = (action.payload && action.payload.data && action.payload.data.bidId) || '';

    if (payload && payload === 'SUCCESS') {
      yield put({
        type: actionTypes.SET_EBIDS_DETAILS.TRIGGER,
        payload: { paymentresponse: { ...response.data.result, ...action.payload } },
      });
      history.replace(`/suppliers/bids/${bidId}/paymentthanks`);
      yield put({ type: actionTypes.LOAD_BID_SIMILARBIDS.TRIGGER, payload: { bidId: bidId } });
      yield put({ type: actionTypes.LOAD_BID_PURCHASEINFO.TRIGGER, payload: { bidId: bidId } });
      // yield put({ type: actionTypes.LOAD_BID_SUMMARY.TRIGGER, payload: { bidId: bidId } })
      yield put({ type: actionTypes.SUBMIT_EBITS_PAYMENT_METHOD.SUCCESS, payload });
    } else {
      toastFn('error', 'Failed', bidsPaymenttoastID);
      yield put({ type: actionTypes.SUBMIT_EBITS_PAYMENT_METHOD.FAILURE, payload });
    }
  } catch (error) {
    toastFn('error', 'Failed', bidsPaymenttoastID);
    yield put({
      type: actionTypes.SUBMIT_EBITS_PAYMENT_METHOD.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

export function* watchProcessCreditTransaction() {
  yield takeLatest(actionTypes.PROCESS_CREDIT_TRANSACTION.TRIGGER, processCreditTransaction);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* processCreditTransaction(action: { payload: Record<string, unknown> | undefined }): any {
  yield put({ type: actionTypes.PROCESS_CREDIT_TRANSACTION.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.processCreditTransaction, action.payload);
    const status = (response.data && response.data.result && response.data.result.status) || '';
    const error = (response.data && response.data.result && response.data.result.error) || '';
    const payload = { status, error };
    if (payload.status === 'SUCCESS') {
      yield put({ type: actionTypes.GET_PAYMENT_HISTORY.TRIGGER, payload: {} });
      yield put({ type: actionTypes.PROCESS_CREDIT_TRANSACTION.SUCCESS, payload });
    } else {
      toastFn('error', payload.error, bidsPaymenttoastID);
      yield put({ type: actionTypes.PROCESS_CREDIT_TRANSACTION.FAILURE, payload: error });
    }
  } catch (error) {
    toastFn('error', 'Failed', bidsPaymenttoastID);
    yield put({
      type: actionTypes.PROCESS_CREDIT_TRANSACTION.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

export function* paymentSaga() {
  yield fork(watchPaymentCard);
  yield fork(watchEBitsAddChargePayment);
  yield fork(watchEBitsChangeBillingAddress);
  yield fork(watchEBitsPaymentMethod);
  yield fork(watchProcessCreditTransaction);
}
