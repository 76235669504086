/**
 * Assume 16px base font size.
 * @param px
 */
export function pxToRem(px: number): number {
  return 0.0625 * px;
}

/**
 * Assume 16px base font size.
 * @param rem
 */
export function remToPx(rem: number): number {
  return 16 * rem;
}
