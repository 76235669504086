import React, { useState } from 'react';
import { Validate } from 'react-hook-form';

import { BaseTextArea } from '../common/controls/inputs/BaseTextArea';
import { FormFieldContainer } from './styled/FormFieldStyled';
import { FormFieldLabels } from './FormFieldLabels';

// TODO: Consolidate this to extend from React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>
export interface TextAreaProps {
  autoFocus?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  errorMsg?: string;
  label?: string;
  fullWidth?: true;
  marginBottom?: boolean;
  maxLength?: number;
  message?: string;
  minLength?: number;
  name: string;
  onBlur?: (value: string) => void;
  onChange: ((value: string) => void) | false;
  pattern?: RegExp;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  validate?: Validate;
  value: string | null;
}

/**
 * @prop {(value: string) => void}   onChange - updates the string
 * @prop {string}                    name - HTML name
 * @prop {string}                    value - HTML value
 * @prop {boolean=}                  autoFocus - HTML autofocus
 * @prop {boolean=false}             disabled - HTML disabled
 * @prop {string=}                   dataTestId - https://testing-library.com/docs/queries/bytestid/
 * @prop {string=}                   errorMsg - error message
 * @prop {string=}                   label - HTML label.
 * @prop {boolean=false}             marginBottom - to add a margin below, include this
 * @prop {number=256}                maxLength - HTML maxLength
 * @prop {string}                    message - additional description
 * @prop {number=0}                  minLength - HTML minLength
 * @prop {(value: string) => void=}  onBlur - function that triggers on blur
 * @prop {RegEx=}                    pattern - specifies a regular
 * expression that the <input> element's value is checked against
 * on form submission.
 * @prop {string=}                   placeholder - HTML placeholder
 * @prop {boolean=false}             required - HTML required
 * @prop {boolean=}                  showError - used when we want to trigger
 * all errors to show, for example when completing a form.
 * */

export const TextArea = (props: TextAreaProps) => {
  const {
    autoFocus,
    dataTestId,
    disabled,
    errorMsg,
    label,
    fullWidth,
    maxLength = 256,
    marginBottom = false,
    message,
    minLength = 0,
    showError,
    name,
    onBlur,
    onChange,
    placeholder,
    required,
  } = props;
  const value = props.value || '';

  const [touched, setTouched] = useState(false);

  function change(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = event.target.value;
    if (onChange) {
      onChange(value.trimStart());
    }
  }

  function blur(event: React.FocusEvent<HTMLTextAreaElement>) {
    setTouched(true);
    if (onBlur) {
      const value = event.target.value;
      onBlur(value.trim());
    }
  }

  return (
    <FormFieldContainer marginBottom={marginBottom} fullWidth={fullWidth}>
      <FormFieldLabels
        errorMsg={errorMsg}
        name={name}
        label={label}
        required={required}
        message={message}
        showError={showError}
        touched={touched}
        value={value}
        maxLength={maxLength}
      />
      <BaseTextArea
        autoFocus={autoFocus}
        placeholder={placeholder}
        data-testid={dataTestId || name}
        disabled={disabled}
        onChange={change}
        onBlur={blur}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        id={name}
        value={value}
      />
    </FormFieldContainer>
  );
};
