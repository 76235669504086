import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Paging } from '../../customcontrols';
import { pageSize } from '../../../utils/constants';

const tdWidth = { width: '30px' };

interface PropsTypes {
  data?: any;
  fields?: any;
  deletedata?: any;
  editdata?: any;
  editEnabled?: boolean;
  actions?: Array<string>;
  pagefor?: string;
}

const CommonDataTable = (props: PropsTypes) => {
  const {
    data,
    fields,
    deletedata,
    editdata,
    editEnabled = false,
    actions = ['edit', 'delete'],
    pagefor = 'add',
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  return (
    <>
      <Table className='tableHData'>
        <tr>
          {fields &&
            fields.map((item1: any, index1: number) => (
              <th key={index1} style={{ width: item1.width ? item1.width : '' }}>
                {item1.fieldlabel}
              </th>
            ))}
          <th style={tdWidth}></th>
          <th style={tdWidth} />
        </tr>
        {data &&
          data
            .map((item: any, index: number) => (
              <tr key={index}>
                {fields &&
                  fields.map((item1: any, index1: number) => (
                    <td key={index1}>
                      {item1.fieldname === 'publication'
                        ? item.publication
                        : item1.fieldname === 'bidType'
                        ? item.bidType
                        : item1.fieldname === 'bidTypeDesc'
                        ? item.bidTypeDesc
                        : item1.fieldname === 'description'
                        ? item.description
                        : item1.fieldname === 'sortOrder'
                        ? item.bidTypeDesc
                        : null}
                    </td>
                  ))}
                {editEnabled === false && pagefor === 'add' ? (
                  <>
                    {actions.includes('delete') ? (
                      <td>
                        <i
                          onClick={() => deletedata(item, index)}
                          className='mdi mdi-delete-forever-outline staticLink mdi-24px'
                          title='Remove'
                        />
                      </td>
                    ) : null}
                    {actions.includes('edit') ? (
                      <td>
                        <i
                          onClick={() => editdata(item, index)}
                          className='mdi mdi-square-edit-outline staticLink mdi-24px'
                          title='Edit'
                        />
                      </td>
                    ) : null}
                  </>
                ) : null}
              </tr>
            ))
            .slice(indexOfFirstList, indexOfLastList)}
      </Table>
      <Paging
        totalRecords={data && data.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
};

export default memo(CommonDataTable);
