import * as React from 'react';
import { Column, HeaderGroup, TableState, useSortBy, useTable } from 'react-table';

import { commonLabels, TableLabels } from '../../../shared/constants';
import { SortIconAscending, SortIconDescending } from '../icons';
import { CenteredContentWrapper } from '../../../shared/styles';

import { HeaderIconWrapper } from './styles';
import { TableCell } from './types';

const tableHeaderRowKeyPrefix = 'headerRow_';
const tableHeaderCellKeyPrefix = 'header_';
const tableDataRowKeyPrefix = 'row_';
const tableDataCellKeyPrefix = 'cell_';

/**
 * Renders a header with custom layout and sort options
 * @description react-table doesn't use cusom header renderers in the coulmn configuration
 * @param column
 * @param index
 * @returns header cell as a `<th/>`
 */
function renderHeader<D extends Record<string, unknown>>(column: HeaderGroup<D>, index: number) {
  const { canSort, getHeaderProps, getSortByToggleProps, isSorted, isSortedDesc, render } = column;
  const headerLabel = render('Header');
  const headerTitle = `${canSort ? TableLabels.sortBy : ''} ${render('Header')}`;
  return (
    <th
      {...getHeaderProps(getSortByToggleProps())}
      key={`${tableHeaderCellKeyPrefix}${index}`}
      title={headerTitle}
    >
      {headerLabel}
      {canSort ? (
        isSorted ? (
          isSortedDesc ? (
            <HeaderIconWrapper>
              <SortIconAscending />
            </HeaderIconWrapper>
          ) : (
            <HeaderIconWrapper>
              <SortIconDescending />
            </HeaderIconWrapper>
          )
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </th>
  );
}

type RequiredTableState<D extends Record<string, unknown>> = Partial<TableState<D>> &
  Pick<TableState<D>, 'sortBy'>;

type TableBaseProps<D extends Record<string, unknown>> = {
  columns: Column<D>[];
  data: TableCell[];
  initialState?: RequiredTableState<D>; // TODO: Make this required.
};

export function TableBase<D extends Record<string, unknown>>(props: TableBaseProps<D>) {
  const { columns, data, initialState } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
  );

  if (data) {
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`${tableHeaderRowKeyPrefix}${index}`}>
              {headerGroup.headers.map((column, index) => renderHeader(column, index))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {data.length ? (
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={`${tableDataRowKeyPrefix}${index}`}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td {...cell.getCellProps()} key={`${tableDataCellKeyPrefix}${index}`}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <>
              <tr>
                <td colSpan={columns.length}>
                  <CenteredContentWrapper>{commonLabels.notFound}</CenteredContentWrapper>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    );
  }
  return <span></span>;
}
