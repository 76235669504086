import { CommodityCodeType } from '../../types/commodity';

import { axiosRequestAuthenticated } from './api';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';

interface GetCommodityCodeListParams {
  idField: string;
  idValue: any;
  type: CommodityCodeType;
}

/**
 * Generic Commodity Code list request
 * @param {idField: string, idValue: any, type: CommodityCodeType }: GetCommodityCodeListParams
 * @returns typed api response: T[]
 */
export function getCommodityCodeList<T>({ idField, idValue, type }: GetCommodityCodeListParams) {
  return axiosRequestAuthenticated<T[]>({
    baseURL: constants.api.url,
    data: {
      [idField]: idValue,
      type,
    },
    method: 'POST',
    url: Paths.commodityByType,
  });
}
