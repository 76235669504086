import { emailVal, numericPhoneVal } from './../../utils/constants/validation';

export function isValidEmail(value?: string) {
  return !!(value && emailVal.test(value));
}

export function isValidPhone(value?: string) {
  return !!(value && numericPhoneVal.test(value));
}

export function isValidRequiredTrimmingSpace(value: string) {
  return !!value.trim();
}
