import { Route } from '../../types/routes';
// Routes of View Components

import AgencyRegistration from '../../components/ops/registration/index';
import AgencySummary from '../../components/ops/registration/summary';
import ManageProducts from '../../components/ops/manageproducts/index';
import UserManual from '../../components/shared/usermanual';

export const opsRoutes: Route[] = [
  {
    path: '/ops/agency/registration',
    component: AgencyRegistration,
    page: 'Agency Creation',
    breadcrumb: { Home: '' },
  },
  {
    path: '/ops/agency/registration/summary/:memberId',
    component: AgencySummary,
    page: 'Agency Creation',
    breadcrumb: { Home: '' },
  },
  {
    path: '/ops/agency/registration/:memberId/:accountId',
    component: AgencyRegistration,
    page: 'Agency Creation',
    breadcrumb: { Home: '' },
  },
  { path: '/ops/help', component: UserManual, page: 'usermanual', breadcrumb: { Home: '/' } },
  {
    path: '/ops/products',
    component: ManageProducts,
    page: 'product',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/ops/creratemember',
    component: UserManual,
    page: 'createmember',
    breadcrumb: { Home: '/' },
  },
  { path: '/ops/members', component: UserManual, page: 'members', breadcrumb: { Home: '/' } },
  { path: '/ops/reports', component: UserManual, page: 'reports', breadcrumb: { Home: '/' } },
  { path: '/ops/users', component: UserManual, page: 'users', breadcrumb: { Home: '/' } },
];
