import React, { memo } from 'react';

function CreateBroadcastBid() {
  return (
    <>
      <p>
        Now that you have set-up your account, managed your certifications and created your list of
        publications and required documents, it’s time to create and broadcast that first bid to
        reach as many suppliers as possible. This is where you will decide whether or not to solicit
        the bid as an eBid or not.{' '}
      </p>
      <p>To start, click on Add Bid in the upper right corner.</p>
      img
      <ul className='list-unstyled'>
        <li>
          Step 1:
          <p>Enter the Bid Information as asked by the first screen.</p>
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              HOT TIP:
            </b>
            <br />
            The second question/field is where you choose if it an eBid or not, simply choose Yes or
            No.
          </p>
          img
          <p>
            The rest of the process flows as indicated in the progress list in the left column – and
            you will be reminded what is optional information rather than required information.
          </p>
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              HOT TIP:
            </b>
            <br /> Once you’ve completed the steps in the Bid Creation Progress Column, you will be
            at the place to FINISH your bid and may broadcast later or be asked if you’d like to
            “Broadcast Now.”
          </p>
          img
          <p>NOTE: the bid number “46-B” will subsequently appear as: RFP-46-B-0-2020/L</p>
        </li>
        <li>
          Step 2:
          <p>On the second screen you enter information regarding the EBid. </p>
          <p>
            {' '}
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              HOT TIP:
            </b>
            <br />
            if the second screen does NOT have eBid information, then you haven’t chosen YES to eBid
            as your option, so you’ll need to go back to the first screen and make sure it is
            clicked on YES.
          </p>
          img
        </li>
        <li>
          Step 3:
          <p>Update the Legal Ad, if necessary, for the specific bid you are entering. </p>
          img
        </li>
        <li>
          Step 4:
          <p>Enter information for the PreBid Conference if you are holding one.</p>
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              HOT TIP:
            </b>
            <br /> If you are holding a “virtual” PreBid Conference, after the date and time, enter
            the URL information and instructions on how to join the online meeting.
          </p>
        </li>
        <li>
          Step 5:
          <p>
            Enter the publications, if any, and dates where you are also placing a notification.
            NOTE: we don’t submit them for you; this is simply a place to keep all your records
            together for reference and/or audit purposes.{' '}
          </p>
          img
        </li>
        <li>
          Step 6:
          <p>
            Build your Broadcast List. You may use a previously saved list, or create one using
            Commodity Codes (NIGP).{' '}
          </p>
          img
          <p>
            Click “Generate New List” to choose the Commodity Codes to apply to your solicitation,
            or, Click “Load Pre-Built List” to use a saved set of commodity codes you’ve used in the
            past for a similar solicitation.{' '}
          </p>
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              HOT TIP:
            </b>
            <br /> it’s always better to add more project codes, rather than fewer, to ensure you
            don’t miss suppliers that have under-identified themselves with the codes they selected.
          </p>
          img
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              HOT TIP:
            </b>
            <br /> If you need help in figuring out what codes to use, you may save this bid as-is,
            and research what other government agencies have used for codes when posting for a
            similar project. Simply return to your bid-in-progress and edit the codes with the
            additional information.
          </p>
          img
          <p className='bg-purple200'>
            New Feature: You can now remove vendors from your broadcast list.
          </p>
        </li>
        <li>
          Step 7:
          <p>Adding Supplemental Suppliers </p>
          <p className='bg-purple200'>
            New Feature: If the system finds a name that matches, you will be shown a list and
            prompted to choose from that list to avoid creating a new supplier account.{' '}
          </p>
          img
        </li>
        <li>
          Step 8:
          <p>Include Plan/Blueprint Information. </p>
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              HOT TIP:
            </b>
            <br /> If you leave the selection as-is, you may simply upload the plan document(s)
            along with any other Bid documents you may upload – on the next screen.
          </p>
          img
        </li>
        <li>
          Step 9:
          <p>Upload document(s) </p>
          img
          <p>
            If you are uploading a Word document that you’d like DemandStar to convert to a PDF,
            click in the box under “Convert”. It will convert to a searchable PDF. Reminder: the
            current maximum file size is 100MB
          </p>
        </li>
      </ul>
    </>
  );
}

export default memo(CreateBroadcastBid);
