import React from 'react';

function WelcomeMail() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='colWrapper'>
              <h3>
                <i className='mdi mdi-check-circle text-green200 mr-2' />
                Email Validation Completed
              </h3>
              <p>
                <b>Louise Frangoul</b>,<br />
                You have successfully validated your email address to our system
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WelcomeMail;
