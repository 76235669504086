import { FontFamily } from '@demandstar/components/styles';
import React from 'react';
import styled from 'styled-components';

export const SortControlLabel = styled.div`
  padding-right: 0.75rem;
`;

export const SortControlsWrapper = styled.div`
  height: 2.5rem;
`;

export const SortControlGroupWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: ${FontFamily.Header};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  justify-content: flex-end;
  padding: 0.1875rem 0 1rem 0;
  width: 100%;

  // Override scss for Selectbox styling. TODO: clean up and/or switch controls.
  .headerFilter {
    border: none;
    min-height: 2.5rem;
  }
`;
