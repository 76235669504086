import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React from 'react';
import Tour from 'reactour';
import { useDispatch } from 'react-redux';

import { resetBasicTour, setToursDetails } from '../../../store/actions';
import { useDSSelector } from '../../../store/reducers';

const accentColor = '#5cb7b7';
const rounded = 5;

export const TourComp = () => {
  const dispatch = useDispatch();
  const activateTour = useDSSelector(state => state.tours.activateTour);
  const tourStart = useDSSelector(state => state.tours.tourStart);
  const tourConfig = useDSSelector(state => state.tours.tourConfig);
  const disableInteraction = useDSSelector(state => state.tours.disableInteraction);
  const showNavigation = useDSSelector(state => state.tours.showNavigation);
  const closeWithMask = useDSSelector(state => state.tours.closeWithMask);
  const startAt = useDSSelector(state => state.tours.startAt);
  const showNumber = useDSSelector(state => state.tours.showNumber);
  const disableFocusLock = useDSSelector(state => state.tours.disableFocusLock);
  const disableKeyboardNavigation = useDSSelector(state => state.tours.disableKeyboardNavigation);
  const lastStepNextButton = useDSSelector(state => state.tours.lastStepNextButton);
  const prevButton = useDSSelector(state => state.tours.prevButton);
  const nextButton = useDSSelector(state => state.tours.nextButton);
  const currentStep = useDSSelector(state => state.tours.currentStep);
  const showButtons = useDSSelector(state => state.tours.showButtons);
  const showCloseButton = useDSSelector(state => state.tours.showCloseButton);
  const internalConfig = useDSSelector(state => state.tours.internalConfig);
  const internaltourConfig = useDSSelector(state => state.tours.internaltourConfig);
  const tourId = useDSSelector(state => state.tours.tourId);

  let finalprevButton = prevButton;
  let finalnextButton = nextButton;
  if (currentStep && internalConfig && internaltourConfig.length > 0) {
    const button = internaltourConfig.find(item => item.step === currentStep);
    if (button?.nextButton && button?.prevButton) {
      finalprevButton = button.prevButton;
      finalnextButton = button.nextButton;
    }
  }

  const disableBody = (target: HTMLElement | Element) => disableBodyScroll(target);
  const enableBody = (target: HTMLElement | Element) => enableBodyScroll(target);

  const closeTour = () => {
    dispatch(resetBasicTour());
  };

  const setCurrStep = (step: number) => {
    if (currentStep !== step) {
      dispatch(setToursDetails({ currentStep: step }));
    }
  };

  return (
    <Tour
      onRequestClose={closeTour}
      steps={tourConfig as any} //TODO: We don't appear to be constructing these the way React Tour wants us to.
      isOpen={activateTour && tourStart && tourConfig.length > 0 && tourId > 0}
      maskClassName='tourMask'
      className='tourInfoBox'
      rounded={rounded}
      accentColor={accentColor}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      lastStepNextButton={lastStepNextButton}
      prevButton={finalprevButton}
      nextButton={finalnextButton}
      disableInteraction={disableInteraction}
      showNavigation={showNavigation}
      closeWithMask={closeWithMask}
      startAt={startAt}
      goToStep={currentStep}
      getCurrentStep={setCurrStep}
      badgeContent={(current, total) => `${current}/${total}`}
      showNumber={showNumber}
      disableFocusLock={disableFocusLock}
      disableKeyboardNavigation={disableKeyboardNavigation}
      showButtons={showButtons}
      showCloseButton={showCloseButton}
    />
  );
};
