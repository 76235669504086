import React, { memo } from 'react';

interface PropsTypes {
  submit: any;
  cancel: any;
  valid: boolean | Record<string, unknown> | undefined;
  submitting: boolean | Record<string, unknown> | undefined;
  pristine: boolean | Record<string, unknown> | undefined;
}

const BillingEditControl = (props: PropsTypes) => {
  const { submit, cancel, valid, submitting } = props;
  return (
    <>
      <li title='Save'>
        <span className='staticLink' onClick={submit}>
          <i className='mdi mdi-content-save-outline mr-1' /> Save
        </span>
      </li>
      <li title='Cancel'>
        <span className='staticLink' onClick={cancel}>
          <i className='mdi mdi-close mr-1' />
          Cancel
        </span>
      </li>
    </>
  );
};

export default memo(BillingEditControl);
