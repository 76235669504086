import { useEffect } from 'react';

/**
 * @param {boolean} exp - a statement that we assume will be true at this point in the code.
 * @param {string} message - what we want to communicate to the dev who found the Assert error.
 * @param {string} file - optional file string for clarity (where is this assert located?)
 * @returns
 *
 * @example Assert(!!response.email, 'All eBid Responses should have an email');
 */
export function Assert(exp: boolean, message: string, file?: string): asserts exp {
  if (exp || process.env.NODE_ENV === 'production') {
    return;
  }
  // Disabled so that we can have open a confirm dialog ONLY in development / QA.
  // eslint-disable-next-line no-restricted-globals
  if (confirm(message + (file ? ' (' + file + ')' : ''))) {
    // Disabled so we can pause on this breakpoint when debugging.
    // eslint-disable-next-line no-debugger
    debugger;
  }
}
/** Assert to be used within React components (as opposed to within functions)
 * @param {boolean} exp - a statement that we assume will be true at this point in the code.
 * @param {string} message - what we want to communicate to the dev who found the Assert error.
 * @param {string} file - optional file string for clarity (where is this assert located?)
 * @returns
 *
 * @example useAssert(!!tzfn, 'Timezone should be defined', 'broadcast.tsx');
 */
export function useAssert(exp: boolean, message: string, file?: string): asserts exp {
  useEffect(() => {
    Assert(exp, message, file);
  }, [exp, file, message]);
}
