import * as actionTypes from '../actionTypes';
import { themetokenName } from '../../utils/constants';
import { payloadTypes } from '../../types/actiontypedef';
import { subscriptionTypes } from '../../types/subscription';

const initialState: subscriptionTypes = {
  products: [],
  currentStateProductId: 0,
  paymentProcessStatus: null, // TODO may be SUCCESS | FAILED | ERROR,
  nonce: null,
  cartData: [],
  readOnlyCartData: [],
  paymentModalTitle: 'Choose a way to pay',
  commodityCodes: [],
  selectedCommodityCodes: [],
  creditCardType: '',
  ccLastFourDigit: '',
  defaultCommodityGroup: '000',
  commodityCodeUpdateStatus: null,
  commoditySearchText: '',
  selectedCommodityId: null,
  orderNumber: '',
  transaction: {},
  isRenewal: false,
  daysToRenew: 0,
  getMemberRequestCompleted: false,
  subscriptionModalToggle: false,
  isPaymentMethodRequestable: false,
  renewalSource: '',
  currentExpiryDate: null,
  expiryDate: null,
  calculatedPrice: 0,
  isUpgrade: false,
  memberType: 'FS',
  opi: 0,
  isNationalSelected: false,
};

export const reducer = (state = initialState, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.RESET_PAYMENT_PROCESS_STATUS.TRIGGER:
      return {
        ...state,
        paymentProcessStatus: null,
      };
    case actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      break;
  }
  return state;
};
