import React, { useEffect } from 'react';
import { Buttons } from '../../customcontrols';
import { cartTotalState } from '../../../store/recoil/productState';
import navHistory from '../../../utils/history';
import TagManager from 'react-gtm-module';
import { useRecoilValue } from 'recoil';

const AccountConfirmation = () => {
  const cartTotal = useRecoilValue<number>(cartTotalState);

  useEffect(() => {
    // publish a conversion event to GTM in support of Google Ad Words integration
    const convType = cartTotal > 0 ? 'Paid Conversion' : 'Non-Paid Conversion';
    TagManager.dataLayer({
      dataLayer: {
        event: convType,
      },
    });
  }, [cartTotal]);

  useEffect(() => {
    // This scrolls to the top of the page on load.
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <div className='row'>
                  <div className='col-12 col-md-7'>
                    <h2 className='arrowWrapper'>Account Confirmation</h2>
                    <p>
                      You’re almost there! Click the button in the confirmation email we just sent
                      you to set your password and finish setting up your account.
                    </p>
                    <p>
                      In the meantime, watch this video that shows you how to make the most of your
                      DemandStar account.
                    </p>
                    <iframe
                      width='800'
                      height='450'
                      src='https://www.youtube.com/embed/Gev_qJPlO50'
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    />
                    <Buttons
                      classNames='bttn-accent margin-top-2rem'
                      onClick={() => navHistory.push('/')}
                      type='button'
                      text='Login'
                    />{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountConfirmation;
