import React, { memo, useState, useEffect } from 'react';
import { scrollLeft } from '../../utils/helpers';

type direction = 'left' | 'right';

interface ScrollButtonProps {
  direction: direction;
  elementId: string;
  ready?: boolean; // this is an optional prop, in case we try to evaluate the element
  // too early. check columncheck.tsx for an example of using useRef
  // to trigger a re-render when the element exists
}

function ScrollButton(props: ScrollButtonProps) {
  const scrollChange = 244;
  const scrollDuration = 200;

  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldDisplay = () => {
    const container = document.getElementById(props.elementId);
    if (container) {
      if (props.direction === 'left') {
        return container.scrollLeft > 0;
      }
      if (props.direction === 'right') {
        return container.scrollLeft + container.clientWidth < container.scrollWidth;
      }
    }
    return false;
  };

  const updateVisible = () => {
    setVisible(shouldDisplay());
  };

  const [visible, setVisible] = useState(shouldDisplay());

  useEffect(() => {
    const handleAdjustment = () => {
      updateVisible();
    };
    const container = document.getElementById(props.elementId);
    container?.addEventListener('scroll', handleAdjustment);
    window.addEventListener('resize', handleAdjustment);
    return () => {
      container?.removeEventListener('scroll', handleAdjustment);
      window.removeEventListener('resize', handleAdjustment);
    };
  });

  // This forces an update when the reference is ready
  useEffect(updateVisible, [props.ready, shouldDisplay]);

  const scrollDirection = (direction: direction) => {
    const container = document.getElementById(props.elementId);
    if (container) {
      if (direction === 'right') {
        scrollLeft(container, scrollChange, scrollDuration);
      } else {
        scrollLeft(container, scrollChange * -1, scrollDuration);
      }
    }
  };

  return (
    <>
      {visible ? (
        <button
          className='scrollButton'
          onClick={() => scrollDirection(props.direction)}
          type='button'
        >
          <i className={'mdi mdi-chevron-' + props.direction} />
        </button>
      ) : (
        <div className='scrollDummyDiv' />
      )}
    </>
  );
}

export default memo(ScrollButton);
