import React from 'react';
import styled from 'styled-components';

import { FontWeight, InputFontSize, Margin, TextColor } from '../styles/';

const StyledLabel = styled.label`
  display: inline-block;
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${Margin.None};
  color: ${TextColor.Label};
  font-size: ${InputFontSize.Label};
  line-height: ${InputFontSize.Label};
`;

// export type LabelProps = Pick<LabelHTMLAttributes<HTMLElement>,
//   'htmlFor'
//   >;

interface LabelProps {
  htmlFor: string;
  dataTestId?: string;
  children: string;
}

/** Styled and functional Label
 * @example <Label htmlFor='fooName'>
              Company Name
            </Label>
 * */
export const Label = (props: LabelProps) => {
  const { dataTestId, htmlFor, children } = props;

  return (
    <StyledLabel htmlFor={htmlFor} data-testid={dataTestId}>
      {children}
    </StyledLabel>
  );
};
