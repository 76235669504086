import React from 'react';
import styled from 'styled-components';

import {
  NotificationContentCell,
  NotificationHeaderRow,
  NotificationLabelCell,
} from './../styled/AwardBidStyled';
import { CustomNotificationRecipient } from '../../../../types/awardBid';
import { Label } from '../../../common/controls/text/Label';
import { Margin } from '../../../../shared/styles';

const NotificationContainer = styled.div`
  display: block;
  margin-bottom: ${Margin.base};
`;

interface NotificationRecipientsRowProps {
  recipient: CustomNotificationRecipient;
}
const NotificationRecipientRow = (props: NotificationRecipientsRowProps) => {
  const { recipient } = props;

  return (
    <>
      <NotificationLabelCell>{recipient.memberName}</NotificationLabelCell>
      <NotificationContentCell>
        <span>{recipient.email}</span>
      </NotificationContentCell>
    </>
  );
};

interface NotificationRecipientsProps {
  recipients: CustomNotificationRecipient[];
}

export const NotificationRecipients = (props: NotificationRecipientsProps) => {
  const { recipients } = props;

  return (
    <NotificationContainer>
      <NotificationHeaderRow>
        <Label>Recipient</Label>
        <span>Email</span>
      </NotificationHeaderRow>
      {recipients.map((recipient, index) => {
        return <NotificationRecipientRow key={index} recipient={recipient} />;
      })}
    </NotificationContainer>
  );
};
