import React, { memo } from 'react';
import { Buttons, DeprecatedInput } from '../../../customcontrols';

interface PropsTypes {
  Setsavelistmodal?: any;
  setAddBidDetails?: any;
  newlistname?: string;
  submitAddBidAddList?: any;
  bidId?: number;
  savelistExistmodal?: any;
  pagefor?: string;
  quotesId?: number | string;
}

const SaveList = (props: PropsTypes) => {
  const {
    Setsavelistmodal,
    setAddBidDetails,
    newlistname,
    submitAddBidAddList,
    bidId,
    savelistExistmodal = false,
    pagefor = '',
    quotesId = '',
  } = props;

  const SetsavelistExistmodal = () => {
    setAddBidDetails({ savelistExistmodal: !savelistExistmodal, newlistname: '' });
  };

  const type = pagefor === 'addquotes' ? 'Quote' : 'Bid';
  const id = pagefor === 'addquotes' ? quotesId : bidId;

  return (
    <>
      {savelistExistmodal ? (
        <>
          <h6 className='p-3 text-center'>
            Broadcast list name({newlistname}) already exists. Do you want to replace?
          </h6>
          <Buttons
            classNames='bttn-primary float-right mt-2'
            text='Ok'
            title='Ok'
            type='button'
            onClick={() => submitAddBidAddList({ id, replaceIfExists: true, type })}
            disable={!newlistname}
          />
          <Buttons
            classNames='bttn-secondary mt-2 '
            title='Cancel'
            text='Cancel'
            type='button'
            onClick={SetsavelistExistmodal}
          />
        </>
      ) : (
        <>
          <DeprecatedInput
            type='text'
            placeholder='List Name'
            label='List Name'
            value={newlistname}
            handleChange={(name: string, value: string) => setAddBidDetails({ newlistname: value })}
          />
          <Buttons
            classNames='bttn-primary float-right mt-2'
            text='Ok'
            title='Ok'
            type='button'
            onClick={() => submitAddBidAddList({ id, type })}
            disable={!newlistname}
          />
          <Buttons
            classNames='bttn-secondary mt-2 '
            text='Cancel'
            title='Cancel'
            type='button'
            onClick={Setsavelistmodal}
          />
        </>
      )}
    </>
  );
};

export default memo(SaveList);
