/**
 * @description An enum to manage reusable status options in a type definition throughout the application
 * @example   as a type definiton: const itemStatus: Status is the same as const itemStatus: success | error | warning
 * @example   as a value: itemStatus = Status.Success
 */
export enum Status {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}
