import React, { memo } from 'react';
import styled from 'styled-components';

import { Product } from '../../../types/subscriptions';

interface RegistrationUpsellProps {
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  header: string;
  level: Product['productType'];
  linkText: string;
  message: string;
}
const Div = styled.div`
  cursor: pointer;
`;

const RegistrationUpsell = (props: RegistrationUpsellProps) => {
  const { handleClick, header, level, linkText, message } = props;

  const borderClass = ['reg-upsell'];
  const headerClass = ['reg-upsell-header'];
  let borderColor = 'border-light-green';
  let headerBgColor = 'bg-light-green';

  switch (level) {
    case 'ST':
      borderColor = 'border-green';
      headerBgColor = 'bg-green';
      break;
    case 'NA':
      borderColor = 'border-dark-green';
      headerBgColor = 'bg-dark-green';
      break;
  }
  borderClass.push(borderColor);
  headerClass.push(headerBgColor);

  return (
    <Div className={borderClass.join(' ')} onClick={handleClick}>
      <div className={headerClass.join(' ')}>
        <h5>{header}</h5>
      </div>
      <div className='reg-upsell-body bg-white'>
        <p>{message}</p>
        <button onClick={handleClick}>{linkText}</button>
      </div>
    </Div>
  );
};

export default memo(RegistrationUpsell);
