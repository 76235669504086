import styled from 'styled-components';

import { BorderColor, BorderThickness, Padding } from '../styles';

export const DataList = styled.ul`
  align-items: baseline;
  border-bottom: ${BorderThickness.Base} solid ${BorderColor.Base};
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: space-between;
  list-style-type: none;
  padding: ${Padding.Base} ${Padding.None};

  li:first-child {
    flex-basis: 35%;
  }
`;
