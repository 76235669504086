import React, { memo, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { memberTypes, pageSize } from '../../../../utils/constants';
import { checkPaginationCallFn } from '../../../../utils/helpers';
import ActivitiesSearch from '../search';
import ActivitiesSearchresult from '../searchresult';
import ActivitiesList from '../list';
import ActivitiesStatus from '../status';
import { activitiessearchTypes, authTypes } from '../../../../types';

interface propsTypes extends activitiessearchTypes {
  activities: any;
  getActivitiesNotifications: any;
  auth: authTypes;
  setActivitiesDetails: any;
}

function NotificationBids(props: propsTypes) {
  const { activities, getActivitiesNotifications, auth, setActivitiesDetails } = props;
  const {
    activities_notifications,
    notifications_internalLoader,
    activitiesSearch,
    notificationStatus,
    activities_notifications_search,
  } = activities;
  let { /*page,*/ result: notificationslist } = activities_notifications;
  const { /* size,*/ bidsCount: total } = activities_notifications;
  const { mt = '' } = auth;
  notificationslist =
    (notificationslist.length > 0 &&
      notificationslist.filter((item: { type: string }) => item.type !== 'Account')) ||
    [];

  // Logic for displaying pagination
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const onPagingClick = (value: number) => {
    if (value !== currentPages) {
      if (checkPaginationCallFn(value, listPerPages, notificationslist, total)) {
        getActivitiesNotifications({
          page: Math.ceil(value / listPerPages) + 1,
          size: 100,
          pagination: true,
          notificationStatus,
        });
      }
      setCurrentPages(Number(value));
    }
  };

  const finalnotificationslist = notificationslist.slice(indexOfFirstLists, indexOfLastLists) || [];

  const setAcountsFns = () => {
    setActivitiesDetails({ currentnotificationcategory: 'Accounts' });
  };

  const {
    /*page,*/ result: notificationslist_search,
    /* size,*/ bidsCount: total_search,
  } = activities_notifications_search;

  const totalCount = activitiesSearch ? total_search : total;

  const onSelectChange = (name: any, value: any) => {
    setActivitiesDetails({ notificationStatus: value });
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='arrowWrapper mb-3'>
            Notification{totalCount > 1 ? 's' : ''} {totalCount ? `(${totalCount})` : null}
          </h2>
          {mt === memberTypes.agencyBuyer ? (
            <ActivitiesStatus
              {...props}
              pagefor='notificationsbid'
              onSelectChange={onSelectChange}
              status={notificationStatus}
              setCurrentPages={setCurrentPages}
            />
          ) : (
            <ButtonGroup size='md' className='float-right btnPTitle'>
              <Button color='info'>Bids & Quotes</Button>
              <Button outline color='info' title='Quotes' onClick={setAcountsFns}>
                Accounts
              </Button>
            </ButtonGroup>
          )}
        </div>
      </div>
      <div className='row'>
        <ActivitiesSearch {...props} pagefor='notificationsbid' setCurrentPages={setCurrentPages} />
      </div>
      {activitiesSearch ? (
        <ActivitiesSearchresult
          {...props}
          activitieslist={notificationslist_search}
          total={total_search}
          pagefor='notificationsbid'
        />
      ) : (
        <ActivitiesList
          {...props}
          pagefor='notificationsbid'
          InternalLoader={notifications_internalLoader}
          loadermsg='No Notifications Available'
          list={notificationslist}
          finallist={finalnotificationslist}
          total={total}
          currentPages={currentPages}
          onPagingClick={onPagingClick}
          listPerPages={listPerPages}
        />
      )}
    </>
  );
}

export default memo(NotificationBids);
