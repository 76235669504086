import React, { memo, useEffect } from 'react';
import Payment from './payment';
import { withRouter } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';
import EbidResponseControl from '../../common/breadcrumb/supplier/ebidresponsecontrol';
import { bidsSummary } from '../../../store/actions';

type PropsTypes = PropsFromRedux & {
  match?: any;
  history?: any;
  setBreadcrumb?: any;
  bidssummary?: any;
};

const BidsDownload = (props: PropsTypes) => {
  const dispatch = useDispatch();
  const bidID = props.match.params.bidId || '';
  const { history, setBreadcrumb, bidssummary } = props;
  const { results } = bidssummary;
  const { bidName = '' } = results;

  useEffect(() => {
    if (!bidID) history.goBack();
  }, [bidID, history]);

  useEffect(() => {
    if (bidID) {
      dispatch(bidsSummary({ bidId: bidID }));
    }
  }, [bidID, dispatch]);

  useEffect(() => {
    setBreadcrumb({
      component: <EbidResponseControl />,
      page: 'Supplier Ebids Download',
      title: 'Ebid Download',
      altname: 'My Ebid Download',
      name: bidName,
      id: bidID,
    });
  }, [bidID, bidName, setBreadcrumb]);

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-6'>
          <Payment {...props} />
        </div>
      </div>
    </div>
  );
};

const connector = connect(
  (state: any) => ({
    ...state.payment,
    ...state.bidssummary,
    ...{ paymentmemberInfo: state.memberinfo },
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(memo(BidsDownload)));
