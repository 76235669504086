import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { AmplitudeAwardInfo, AmplitudeBidInfo } from '../../../types/amplitude';
import { bidPlanholderListState, bidWatcherListState } from '../../../store/recoil/bidState';
import {
  mapAmplitudeAwardAmounts,
  mapAmplitudeAwardeeNames,
  mapAmplitudeCommodities,
  useAssert,
} from '../../../utils/helpers';
import { bidAwardeeListState } from '../../../store/recoil/award-bid';
import { getLoadableContents } from '../../../utils/recoil';
import { memberInfoState } from '../../../store/recoil/memberState';
import { responseListState } from '../../../store/recoil/bidResponseState';
import { useAmplitudeRelay } from '../../../components/amplitude';
import { useBid } from '../useBid';

export enum AwardBidEventName {
  SaveAwardee = 'award process - save awardee',
  AwardBid = 'award process - award bid',
  NonDemandStarSupplier = 'award process - non-demandstar supplier',
  UploadPublicDocs = 'award process - upload public documents',
}

type AwardBidEvent =
  | {
      eventName: AwardBidEventName.SaveAwardee | AwardBidEventName.AwardBid;
    }
  | {
      eventName: AwardBidEventName.NonDemandStarSupplier;
      eventProps: {
        'Awardee Name': string;
        'Email Address': string;
      };
    }
  | {
      eventName: AwardBidEventName.UploadPublicDocs;
      eventProps: {
        'Award Documents Uploaded': number;
      };
    };

type AwardBidStateEvents =
  | {
      eventName: AwardBidEventName.SaveAwardee | AwardBidEventName.AwardBid;
      eventProps: AmplitudeBidInfo & AmplitudeAwardInfo;
    }
  | {
      eventName: AwardBidEventName.NonDemandStarSupplier;
      eventProps: AmplitudeBidInfo;
    }
  | {
      eventName: AwardBidEventName.UploadPublicDocs;
      eventProps: Omit<AmplitudeBidInfo, 'Bid Name'> & Pick<AmplitudeAwardInfo, 'Award Amount'>;
    };

export function useAwardBidEvents() {
  const { relayEvent } = useAmplitudeRelay();

  const { bid } = useBid();
  const memberInfo = getLoadableContents(useRecoilValueLoadable(memberInfoState)) || {
    cu: '',
    st: '',
  };
  const planholders = getLoadableContents(useRecoilValueLoadable(bidPlanholderListState)) || [];
  const responses = getLoadableContents(useRecoilValueLoadable(responseListState)) || [];
  const watchers = getLoadableContents(useRecoilValueLoadable(bidWatcherListState)) || [];
  const awardees = getLoadableContents(useRecoilValueLoadable(bidAwardeeListState)) || [];

  const amplitudeBidInfo: AmplitudeBidInfo = {
    Agency: bid?.agencyName || '',
    'Bid Name': bid?.bidName || '',
    'Commodity Codes': mapAmplitudeCommodities(bid?.commodities),
    County: memberInfo.cu,
    State: memberInfo.st,
    'Number of Planholders': planholders.length,
    'Number of Responses': responses.length,
    'Number of Watchers': watchers.length,
  };

  const amplitudeAwardInfo: AmplitudeAwardInfo = {
    'Award Amount': mapAmplitudeAwardAmounts(awardees),
    'Awardee Name': mapAmplitudeAwardeeNames(awardees),
  };

  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // TODO: TS4 - this was also throwing an error for 'string' having no index signature for
  // Record<string, unknown> - WAS: AwardBidStateEvents[].
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const events: any[] = [
    {
      eventName: AwardBidEventName.SaveAwardee,
      eventProps: { ...amplitudeAwardInfo, ...amplitudeBidInfo },
    },
    {
      eventName: AwardBidEventName.AwardBid,
      eventProps: { ...amplitudeAwardInfo, ...amplitudeBidInfo },
    },
    {
      eventName: AwardBidEventName.NonDemandStarSupplier,
      eventProps: { ...amplitudeBidInfo },
    },
    {
      eventName: AwardBidEventName.UploadPublicDocs,
      eventProps: {
        Agency: amplitudeBidInfo['Agency'],
        'Commodity Codes': amplitudeBidInfo['Commodity Codes'],
        County: amplitudeBidInfo['County'],
        State: amplitudeBidInfo['State'],
        'Number of Planholders': amplitudeBidInfo['Number of Planholders'],
        'Number of Responses': amplitudeBidInfo['Number of Responses'],
        'Number of Watchers': amplitudeBidInfo['Number of Watchers'],
        'Award Amount': amplitudeAwardInfo['Award Amount'],
      },
    },
  ];

  return useMemo(() => {
    return {
      logEvent: (event: AwardBidEvent) => relayEvent(events, event),
    };
  }, [events, relayEvent]);
}
