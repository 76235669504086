import React, { memo } from 'react';

interface PropsTypes {
  handleEditClick: any;
  isEditBilling: boolean | undefined;
  activeTab: string | undefined;
}

function PaymentsControl(props: PropsTypes) {
  const { handleEditClick, isEditBilling = false, activeTab = '' } = props;
  return (
    <>
      {!isEditBilling && activeTab === '1' && (
        <li title='Edit'>
          <span className='staticLink' onClick={handleEditClick}>
            <i className='mdi mdi-square-edit-outline mr-1' /> Edit
          </span>
        </li>
      )}
    </>
  );
}

export default memo(PaymentsControl);
