import React from 'react';

import { Colors } from '../../../../shared/styles';
import { PillWrapper } from '../../../../shared/styles/controls';

// TODO: TS4 - Colors was deprecated during the transition to TS4 and should be
// replaced with Color from the library. In attempting to do so, they weren't
// directly interchangeable and should be verified to be the same after changing.
export interface StatusPillProps {
  backgroundColor?: Colors;
  borderColor?: Colors;
  textColor?: Colors;
  status: string;
}

/**
 * Display a pill with specified or default styling.
 * @param {StatusPillProps} props
 * @returns
 */
export function StatusPill(props: StatusPillProps) {
  const {
    backgroundColor = Colors.yellowMedium,
    borderColor = Colors.transparent,
    textColor = Colors.grayDark,
    status,
  } = props;

  return (
    <PillWrapper
      bgColor={backgroundColor}
      borderColor={borderColor}
      textColor={textColor}
      title={status}
    >
      {status}
    </PillWrapper>
  );
}
