// TODO: Delete this. Use library instead.

import { FontFamily } from '@demandstar/components/styles';
import { HTMLProps } from 'react';
import styled from 'styled-components';

import { Colors } from '../../../../shared/styles';

interface BaseTextInputProps extends HTMLProps<HTMLInputElement> {
  error?: boolean;
  inputHeight?: string;
  inputWidth?: string;
}

/**
 * This is the base Input control, intended to be themed and extended
 * by specific implementations, e.g. TextInput, PasswordInput.
 */
export const BaseTextInput = styled.input<BaseTextInputProps>`
  border: 0.0625rem solid;
  border-color: ${props => (props.error ? Colors.error : Colors.grayInputBorder)};
  border-radius: 0.25rem;
  background: ${Colors.white};
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-family: ${FontFamily.Base};
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  color: ${Colors.grayDark};
  text-overflow: ellipsis;
  word-spacing: normal;
  line-height: 2rem;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${Colors.black};
    background: ${Colors.disabled};
  }
  ${({ inputHeight }) => (inputHeight ? `height: ${inputHeight};` : '')}
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth};` : '')}
`;
