import React, { memo } from 'react';
import { Buttons } from '../../customcontrols';
import { Field, Formik } from 'formik';
import renderSelectField from '../controls/inputs/selectbox';
import { renderDeprecatedTextField } from '../controls/inputs/text';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';
import { Validations } from './validations';
import { registrationComponent } from '../../../utils/constants';

interface propsTypes {
  submitBtn?: boolean;
  initialValues?: any;
  populateMetrodetails?: any;
  updateSubscritpionMemberAddress?: any;
  subscriptions?: any;
  companyInfoPopupHandle?: any;
  addInCompleteRegistrationData?: any;
  setRegistrationData?: any;
  getCountiesList?: any;
  setSubscriptionDetails?: any;
  setSharedDetails?: any;
  pagefor?: string;
  registration?: any;
  shared?: any;
}

const CompanyAddressForm = (props: propsTypes) => {
  const {
    submitBtn = false,
    getCountiesList,
    setSharedDetails,
    setSubscriptionDetails,
    populateMetrodetails,
    companyInfoPopupHandle,
    updateSubscritpionMemberAddress,
    addInCompleteRegistrationData,
    setRegistrationData,
    subscriptions,
    registration,
    initialValues,
    pagefor = '',
    shared,
  } = props;
  const { memberSubscriptionInfo = {} } = subscriptions;
  const { registrationData = {}, memberId = '' } = registration;
  const { stateslist = [], countieslist = [], countrieslist = [] } = shared;

  const onSubmitFn = (values: any, form: any) => {
    if (pagefor === 'registration') {
      const companyAddressInfo = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        stateId: values.stateName.value,
        stateName: values.stateName,
        countyId: values.countyName.value,
        countyName: values.countyName,
        countryId: values.countryId,
        country: values.country,
        postalCode: values.postalCode,
      };
      const contactInformation = {
        contactName: values.contactName,
        contactPhone: values.contactPhone,
        fax: values.fax,
        website: values.website,
      };
      const inCompletePayload = {
        email: registrationData.emailAddress,
        JsonData: {
          regData: {
            currentComponent: registrationComponent.MainContactInfo,
            registrationData: {
              ...registrationData,
              companyAddress: { ...companyAddressInfo, addressType: 'MA' },
              companyInfo: contactInformation,
            },
          },
        },
      };
      addInCompleteRegistrationData(inCompletePayload);
      setRegistrationData({
        currentComponent: registrationComponent.MainContactInfo,
        registrationData: {
          ...registrationData,
          companyAddress: { ...companyAddressInfo, addressType: 'MA' },
          companyInfo: contactInformation,
        },
      });
    } else {
      const formData = {
        ...values,
        stateId: values.stateName.value,
        countyId: values.countyName.value,
      };
      updateSubscritpionMemberAddress({
        ...formData,
        memberId: memberSubscriptionInfo.memberDetails
          ? memberSubscriptionInfo.memberDetails.memberId
          : memberId,
        pagefor,
      });
      companyInfoPopupHandle();
    }
  };

  const searchPostalCodeValues = (postalCode: any, formData: any) => {
    if (initialValues.countryId === 1 && !isNaN(postalCode)) {
      populateMetrodetails({ zip: postalCode, formData, formPage: 'companyAddressForm' });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={Validations}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const { values, handleSubmit, isSubmitting, setFieldValue, isValidating } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              {pagefor === 'registration' ? (
                <>
                  <Field
                    label='Company Name'
                    name='contactName'
                    placeholder='Name'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    disabled={true}
                    maxLength={150}
                  />
                  <div className='row'>
                    <div className='col-6'>
                      <Field
                        label='Contact Phone'
                        name='contactPhone'
                        placeholder='Contact Phone'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={17}
                      />
                    </div>
                    <div className='col-6'>
                      <Field
                        label='Fax'
                        name='fax'
                        placeholder='Fax Number'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        optional='optional'
                        maxLength={17}
                      />
                    </div>
                  </div>
                  <Field
                    label='Website'
                    name='website'
                    placeholder='Website Address'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    optional='optional'
                    maxLength={100}
                  />
                </>
              ) : null}

              <Field
                label='Address 1'
                name='address1'
                placeholder='Address 1'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
                maxLength={50}
              />
              <Field
                label='Address 2'
                name='address2'
                placeholder='Address 2'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
                optional='optional'
                maxLength={50}
              />
              <Field
                label='Zip/Postal Code'
                name='postalCode'
                placeholder='Zip/Postal Code'
                component={renderDeprecatedTextField}
                onChange={(name: string, value: string) => {
                  setFieldValue(name, value);
                  if (value.length > 4) {
                    searchPostalCodeValues(value, { ...values, postalCode: value });
                  }
                }}
                maxLength={30}
              />

              <div className='row'>
                <div className='col-6'>
                  <Field
                    label='City'
                    name='city'
                    placeholder='City'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                    maxLength={50}
                  />
                </div>
                <div className='col-6'>
                  <Field
                    label='State'
                    name='stateName'
                    placeholder='State'
                    options={stateslist.filter(
                      (item: any) => item.countryId === initialValues.countryId,
                    )}
                    component={renderSelectField}
                    onChange={(name: string, value: any) => {
                      getCountiesList(value.value);
                      setSubscriptionDetails({
                        addressInitialForm: {
                          ...values,
                          stateName: value,
                          stateId: value.value,
                          countyName: '',
                          countyId: '',
                        },
                      });
                      setFieldValue(name, value);
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <Field
                    label='County'
                    name='countyName'
                    placeholder='County'
                    options={countieslist}
                    component={renderSelectField}
                    onChange={(name: string, value: any) => {
                      setSubscriptionDetails({
                        addressInitialForm: { ...values, countyName: value, countyId: value.value },
                      });
                      setFieldValue(name, value);
                    }}
                    optional='optional'
                  />
                </div>
                <div className='col-6'>
                  <Field
                    label='Country'
                    name='country'
                    placeholder='Country'
                    options={countrieslist}
                    component={renderSelectField}
                    onChange={(name: string, value: any) => {
                      setFieldValue(name, value);
                      setSubscriptionDetails({
                        addressInitialForm: {
                          ...values,
                          stateName: '',
                          country: value,
                          stateId: '',
                          countyName: '',
                          countyId: '',
                          countryId: value.value,
                        },
                      });
                      setSharedDetails({ countieslist: [] });
                    }}
                    maxLength={50}
                  />
                </div>
              </div>
              {submitBtn ? (
                pagefor !== 'registration' ? (
                  <Buttons
                    type='submit'
                    disable={isSubmitting || isValidating}
                    classNames='bttn-primary float-right'
                    text={'Submit'}
                  />
                ) : (
                  <div className='d-flex justify-content-between clear-both pt-3'>
                    <Buttons
                      type='button'
                      onClick={() =>
                        setRegistrationData({
                          currentComponent: registrationComponent.ChooseSubscription,
                        })
                      }
                      classNames='bttn-secondary'
                      text='Back'
                    />
                    <Buttons
                      type='submit'
                      disable={isSubmitting || isValidating}
                      classNames='bttn-primary'
                      text={pagefor === 'registration' ? 'Next' : 'Submit'}
                    />
                  </div>
                )
              ) : null}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    subscriptions: state.subscriptions,
    auth: state.auth,
    shared: state.shared,
    registration: state.registration,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(CompanyAddressForm));
