import React, { PropsWithChildren, ReactNode } from 'react';

import { WidgetSetContainer, WidgetSetTitle, WidgetWrapper } from './styles';

// `PropsWithChildren` encapsulates `children?: ReactNode;`
type WidgetSetProperties = PropsWithChildren<{
  title?: ReactNode;
}>;

/**
 * Display a set of form element widgets, grouped
 * and laid out horizontally by default.
 * @param props: WidgetSetProperties
 */
export function WidgetSet({ title, children }: WidgetSetProperties): JSX.Element {
  return (
    <WidgetSetContainer>
      {title && <WidgetSetTitle>{title}</WidgetSetTitle>}
      <WidgetWrapper>{children}</WidgetWrapper>
    </WidgetSetContainer>
  );
}
