import React, { memo, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { pageSize } from '../../../../utils/constants';
import { checkPaginationCallFn } from '../../../../utils/helpers';
import ActivitiesSearch from '../search';
import ActivitiesSearchresult from '../searchresult';
import ActivitiesList from '../list';
import { activitiessearchTypes, activitiesTypes } from '../../../../types';

interface propsTypes extends activitiessearchTypes {
  setActivitiesDetails: any;
  activities: activitiesTypes;
  getActivitiesNotificationsMember: any;
}

function NotificationAccounts(props: propsTypes) {
  const {
    setActivitiesDetails,
    activities,
    /*getActivitiesNotifications,*/
    getActivitiesNotificationsMember,
  } = props;
  const {
    activities_notifications,
    activities_notifications_member,
    notifications_internalLoader,
    notifications_member_internalLoader,
    activitiesSearch,
    activities_notifications_search,
  } = activities;
  const {
    /*page,*/ accounts: activities_notifications_list = [],
    // total: activities_notifications_total
  } = activities_notifications;
  const {
    /*page,*/ result: activities_notifications_member_list,
    /* size,*/ total: activities_notifications_member_total,
  } = activities_notifications_member;

  let notificationslist =
    [...activities_notifications_list, ...activities_notifications_member_list] || [];
  notificationslist =
    (notificationslist?.length > 0 &&
      notificationslist.sort((a, b) => b.dateOfAction.localeCompare(a.dateOfAction))) ||
    [];
  const total = activities_notifications_list.length + activities_notifications_member_total;

  // Logic for displaying pagination
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const onPagingClick = (value: number) => {
    if (value !== currentPages) {
      /*			if (checkPaginationCallFn(value, listPerPages, activities_notifications_list, activities_notifications_total)) {
				getActivitiesNotifications({ page: Math.ceil(value / listPerPages), size: 100, pagination: true })
			}*/
      /*			if (
				checkPaginationCallFn(
					value,
					listPerPages,
					activities_notifications_member_list,
					activities_notifications_member_total
				)
			) {
				getActivitiesNotificationsMember({ page: Math.ceil(value / listPerPages), size: 100, pagination: true })
			}*/
      if (checkPaginationCallFn(value, listPerPages, notificationslist, total)) {
        getActivitiesNotificationsMember({
          page: Math.ceil(value / listPerPages) + 1,
          size: 100,
          pagination: true,
        });
      }
      // getActivitiesNotificationsMember({ page: Math.ceil(value / listPerPages), size: 100, pagination: true })
      setCurrentPages(Number(value));
    }
  };

  const finalnotificationslist = notificationslist.slice(indexOfFirstLists, indexOfLastLists) || [];

  const setBidsFns = () => {
    setActivitiesDetails({ currentnotificationcategory: 'Bids' });
  };

  const {
    /*page,*/ result: notificationslist_search,
    /* size,*/ bidsCount: total_search,
  } = activities_notifications_search;

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='arrowWrapper'>
            Notification{total > 1 ? 's' : ''} ({total})
          </h2>
          <ButtonGroup size='md' className='float-right btnPTitle'>
            <Button outline color='info' title='Bids' onClick={setBidsFns}>
              Bids & Quotes
            </Button>
            <Button color='info'>Accounts</Button>
          </ButtonGroup>
        </div>
      </div>
      <div className='row'>
        <ActivitiesSearch
          {...props}
          pagefor='notificationsaccount'
          setCurrentPages={setCurrentPages}
        />
      </div>
      {activitiesSearch ? (
        <ActivitiesSearchresult
          {...props}
          activitieslist={notificationslist_search}
          total={total_search}
          pagefor='notificationsaccount'
        />
      ) : (
        <ActivitiesList
          {...props}
          pagefor='notificationsaccount'
          InternalLoader={notifications_internalLoader || notifications_member_internalLoader}
          loadermsg='No Notifications Available'
          list={notificationslist}
          finallist={finalnotificationslist}
          total={total}
          currentPages={currentPages}
          onPagingClick={onPagingClick}
          listPerPages={listPerPages}
        />
      )}
    </>
  );
}

export default memo(NotificationAccounts);
