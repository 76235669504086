import React, { memo } from 'react';
import { Table } from 'reactstrap';
const consthStyle = {
  th: {
    width: '25%',
  },
};
const RequestBidPackage = ({ results }: any) => {
  const { agencyName = '', bidExternalStatus = '', bidName = '' } = results;
  return (
    <>
      <h4>Request Bid Package</h4>
      <div className='innerColIndent'>
        <Table borderless className='tableData'>
          <tr>
            <th style={consthStyle.th}>Agency Name</th>
            <td>{agencyName}</td>
          </tr>
          <tr>
            <th>Bid Name</th>
            <td>{bidName}</td>
          </tr>
          <tr>
            <th>Bid Status</th>
            <td>
              <span className='tag status ml-0'>{bidExternalStatus}</span>
            </td>
          </tr>
        </Table>
      </div>
    </>
  );
};

export default memo(RequestBidPackage);
