import React, { HTMLProps } from 'react';
import styled from 'styled-components';

import { ActionColor } from '../../../../shared/styles';
import { Colors } from '../../../../shared/styles';
import { ButtonProps, StyledButton } from './Button';

/**
 * Styled Button with default type = 'submit'
 */
export type SubmitButtonProps = Partial<ButtonProps> & {
  dataTestId?: string;
  float?: 'left' | 'right' | 'none' | 'inherit';
};

export const StyledSubmitButton: React.FunctionComponent<SubmitButtonProps> = styled(
  StyledButton,
).attrs(props => ({
  type: props.type || 'submit',
}))`
  background-color: ${ActionColor.primary};
  border-color: ${ActionColor.primary};
  color: ${Colors.white};
  padding-left: 2rem;
  padding-right: 2rem;
  float: ${props => props.float || 'none'};
  &:hover {
    background-color: ${ActionColor.primaryHighlight};
  }
  &:active {
    background-color: ${ActionColor.primaryHighlight};
  }
`;

export const SubmitButton = (props: SubmitButtonProps) => {
  return (
    <StyledSubmitButton {...props} data-testid={props.dataTestId}>
      {props.children}
    </StyledSubmitButton>
  );
};
