import { RouteList } from '../types/routes';

// Routes of View Components
import {
  buyerRoutes,
  limitedRoutes,
  opsRoutes,
  supplierRoutes,
} from '../containers/component-routes';

export const ComponentRoutes: RouteList = {
  // Supplier Routes
  supplier: supplierRoutes,

  // Agency / Buyer Routes
  buyer: buyerRoutes,

  // OPS Routes
  ops: opsRoutes,

  // Limited Routes
  limited: limitedRoutes,
};
