// TOREFACTOR: Delete this and replace it with basicinfo.tsx (or vice-versa)

import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { deprecatedGetDateTimeZoneConverted, useAssert } from '../../../../utils/helpers';
import { biddetailsTypes } from '../../../../types/limited';
const tdWidth = {
  width: '240px',
};

interface LimitedBidsInfoProps {
  limited: {
    bidsdetails?: biddetailsTypes;
    //TODO: Should this be optional?
  };
}

const LimitedBidsInfo = (props: LimitedBidsInfoProps) => {
  const { limited } = props;
  const { bidsdetails } = limited;
  const {
    agencyName,
    bidWriter,
    bidIdentifier,
    bidTypeDescription,
    broadcastDate,
    tzfn,
    tzn,
    fiscalYear,
    dueDate,
    bidStatusText,
  } = bidsdetails || {};
  useAssert(!!tzfn, 'Timezone should be defined.', 'bids/details/info.tsx');

  return (
    <>
      <Table borderless className='tableData'>
        <tbody>
          <tr>
            <th style={tdWidth}>Agency Name</th>
            <td>{agencyName}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Bid Writer</th>
            <td>{bidWriter}</td>
          </tr>
          <tr>
            <th>Bid ID</th>
            <td>{bidIdentifier}</td>
          </tr>
          <tr>
            <th>Bid Type</th>
            <td>{bidTypeDescription}</td>
          </tr>
          {broadcastDate ? (
            <tr>
              <th>Broadcast Date</th>
              <td className='text-red200'>
                {deprecatedGetDateTimeZoneConverted(broadcastDate, tzfn)} {tzn}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Fiscal Year</th>
            <td>{fiscalYear}</td>
          </tr>
          {dueDate ? (
            <tr>
              <th>Due Date</th>
              <td className='text-red200'>
                {deprecatedGetDateTimeZoneConverted(dueDate, tzfn)} {tzn}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Bid Status Text</th>
            <td>{bidStatusText}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default memo(LimitedBidsInfo);
