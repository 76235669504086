import { atom, selector } from 'recoil';

import { BidSummaryApiResponse, BidWatcher } from '../../types/bidssummary';
import {
  getBidCommodityCodeList,
  getBidPlanholderList,
  getBidSummary,
  getBidWatcherList,
} from '../services/bids';

import { AddBidDocTypeOption } from '../../types/addbid';
import { BidCommodityCode } from '../../types/commodity';
import { BidId } from '../../types/shared';
import { defaultNumericId } from '../../shared/constants';
import { getBidDocumentTypeList } from '../services/document';
import { getWatchedBids } from '../services';
import { Planholder } from '../../types/biddetail';
import { track } from '../../utils/telemetry';

/**
 * Set up an ID to be set as a selector dependency
 * to kick off additional selector service calls
 * when changed by user interaction.
 */
export const selectedBidIdState = atom<number>({
  key: 'selectedBidIdState',
  default: defaultNumericId,
});

/**
 * Bid Summary
 * @description Make a service API call to populate a Recoil Selector.
 */

export const bidSummarySelector = selector<BidSummaryApiResponse | null>({
  key: 'bidSummarySelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    try {
      // API call
      return selectedBidId !== defaultNumericId ? await getBidSummary(selectedBidId) : null;
    } catch (error: any) {
      track(`allBidsSelector -> getBidSummary(${selectedBidId}) ERROR:`, {
        error,
        method: 'GET',
        errorMessage: error.message,
      });
      // eslint-disable-next-line no-console
      console.error(`bidSummarySelector -> getBidSummary() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
      // return {};
      throw new Error(error.message);
    }
  },
});

/**
 * Initialize the bidSummary state with the associated API call.
 */
export const bidSummaryState = atom<BidSummaryApiResponse | null>({
  key: 'bidSummaryState',
  default: bidSummarySelector,
});

/**
 * Bid Planholders
 * @description Make a service API call to populate a Recoil Selector.
 */
const bidPlanholderListSelector = selector<Planholder[]>({
  key: 'bidPlanholderListSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    // Re-run this selector whenever the selectedBidId changes.
    try {
      // API call
      const response = await getBidPlanholderList(selectedBidId);

      return response;
    } catch (error: any) {
      track(`bidPlanholderListSelector -> getPlanholderList(${selectedBidId}) ERROR:`, {
        error,
        method: 'POST',
        errorMessage: error.message,
      });
      console.error(`bidPlanholderListSelector -> getPlanholderList() ERROR: \n${error}`);
      throw new Error(error.message);
    }
  },
});

/**
 * Initialize the bidPlanholderList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const bidPlanholderListState = atom<Planholder[]>({
  key: 'bidPlanholderListState',
  default: bidPlanholderListSelector,
});

/**
 * Bid Watchers
 * @description Make a service API call to populate a Recoil Selector.
 */
const bidWatcherListSelector = selector<BidWatcher[]>({
  key: 'bidWatcherListSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    // Re-run this selector whenever the selectedBidId changes.
    if (selectedBidId !== defaultNumericId) {
      const requestParams: BidId = { bidId: selectedBidId };
      try {
        // API call
        const response = await getBidWatcherList(requestParams);

        return response;
      } catch (error: any) {
        track(`bidWatcherListSelector -> getWatcherList(${selectedBidId}) ERROR:`, {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        console.error(`bidWatcherListSelector -> getWatcherList() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    }
    return [];
  },
});

/**
 * Initialize the bidWatcherList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const bidWatcherListState = atom<BidWatcher[]>({
  key: 'bidWatcherListState',
  default: bidWatcherListSelector,
});

/**
 * Bid Commodity Codes
 * @description Make a service API call to populate a Recoil Selector.
 */
const bidCommodityCodeListSelector = selector<BidCommodityCode[]>({
  key: 'bidCommodityCodeListSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    // Re-run this selector whenever the selectedBidId changes.
    const requestParams: BidId = { bidId: selectedBidId };
    if (selectedBidId !== defaultNumericId) {
      try {
        // API call
        const response = await getBidCommodityCodeList(requestParams);

        return response;
      } catch (error: any) {
        track(`bidCommodityCodeListSelector -> getCommodityCodeList(${selectedBidId}) ERROR:`, {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        console.error(`bidCommodityCodeListSelector -> getCommodityCodeList() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    }
    return [];
  },
});

/**
 * Initialize the bidCommodityCodeList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const bidCommodityCodeListState = atom<BidCommodityCode[]>({
  key: 'bidCommodityCodeListState',
  default: bidCommodityCodeListSelector,
});

/**
 * Bid Document Types
 * @description Make a service API call to populate a Recoil Selector.
 */
const bidDocumentTypeListSelector = selector<AddBidDocTypeOption[]>({
  key: 'bidDocumentTypeListSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    // Re-run this selector whenever the selectedBidId changes.
    const requestParams: BidId = { bidId: selectedBidId };
    if (selectedBidId !== defaultNumericId) {
      try {
        // API call
        const response = await getBidDocumentTypeList(requestParams);
        return response;
      } catch (error: any) {
        track(`bidDocumentTypeListSelector -> getDocumentTypeList(${selectedBidId}) ERROR:`, {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        console.error(`bidDocumentTypeListSelector -> getDocumentTypeList() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    }
    return [];
  },
});

/**
 * Initialize the bidDocumentTypeList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const bidDocumentTypeListState = atom<AddBidDocTypeOption[]>({
  key: 'bidDocumentTypeListState',
  default: bidDocumentTypeListSelector,
});

/**
 * Watched Bids
 * @description Make a service API call to populate a Recoil Selector.
 */
const watchedBidIdListSelector = selector<string[]>({
  key: 'watchedBidIdListSelector',
  get: async ({ get }) => {
    try {
      const watchedBidIds = await getWatchedBids();
      const watchedBidIdList = watchedBidIds.split(', '); // Assumes format "388713, 389354, 389451, 389460"
      return watchedBidIdList;
    } catch (error: any) {
      track('watchedBidIdListSelector -> getWatchedBids() ERROR:', {
        error,
        method: 'POST',
        errorMessage: error.message,
      });
      console.error(`watchedBidIdListSelector -> getWatchedBids() ERROR: \n${error}`);
      throw new Error(error.message);
    }
  },
});

/**
 * Initialize the watchedBidList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const watchedBidIdListState = atom<string[]>({
  key: 'watchedBidIdListState',
  default: watchedBidIdListSelector,
});
