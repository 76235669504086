import React from 'react';
import { tollFreeNumber } from '../../utils/texts';
import { ModalPopUp } from './modals/ModalPopUp';

interface PropsTypes {
  match: any;
}

export default function DownloadErrorModal(props: PropsTypes) {
  const message = props.match.params.msg;
  const title =
    message === 'archived' || message === 'unavailable'
      ? 'Download document unavailable'
      : message === 'sizeerror'
      ? 'Document maximum size error'
      : 'Document download error';
  return (
    <ModalPopUp title={title} isOpen={true} closeModal={false}>
      {DocumentError(message, title)}
    </ModalPopUp>
  );
}

const DocumentError = (message: string, title: string) => {
  if (message === 'archived') {
    return (
      <div>
        <title>{title}</title>
        <p>
          You are attempting to retrieve a project that has been archived. Please email
          <a
            data-testid='mail.archive'
            href='mailto:demandstar@demandstar.com'
            className='mr-2 ml-2'
          >
            <i>
              <strong>demandstar@demandstar.com</strong>
            </i>
          </a>
          with the following information for document retrieval. You should receive your document(s)
          within 2 business days.
        </p>
        <p>1. Bid Identification Number in full</p>
        <p>2. Bid Name</p>
        <p>
          3. Specific documents you wish to retrieve. If you wish to retrieve all documents, please
          specify “all documents”.
        </p>
      </div>
    );
  } else if (message === 'purchase') {
    return (
      <div>
        <title>{title}</title>
        <p>This document needs to be purchased before it can be downloaded.</p>
        <p data-testid='type.purchasetext'>
          If the document is already purchased and you continue to receive this error, please
          contact DemandStar support at support@demandstar.com or {`${tollFreeNumber}`}
        </p>
      </div>
    );
  } else if (message === 'unavailable') {
    return (
      <div>
        <title>{title}</title>
        {/* <p>Download document unavailable.</p> */}
        <p>
          This document was not uploaded to the DemandStar system. Please contact the organization
          responsible for this document for a copy of it.
        </p>
      </div>
    );
  } else if (message === 'sizeerror') {
    return (
      <div>
        <title>{title}</title>
        <p>
          The files are too big to download all at once. Please download them individually by
          clicking on the file names.
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <title>{title}</title>
        <p>There was a error downloading the document. Please try again.</p>
        <p data-testid='type.defaulttext'>
          If the error persists, please contact DemandStar support at support@demandstar.com or{' '}
          {`${tollFreeNumber}`}
        </p>
      </div>
    );
  }
};
