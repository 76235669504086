import React from 'react';
import { ResponsiveLine } from '@nivo/line';

interface propsTypes {
  title?: string;
  data?: any;
  count?: number | string;
  strokecolor?: 'hsl(255, 70%, 50%)' | undefined | string;
  label?: string;
}

function Cardchart(props: propsTypes) {
  const {
    title = '',
    data = [],
    count = 0,
    strokecolor = 'hsl(255, 70%, 50%)',
    label = '',
  } = props;

  const chartData = [{ id: label, color: strokecolor, data: data }];

  return (
    <>
      <h6>{title}</h6>
      <h2>{count}</h2>
      <ResponsiveLine
        data={chartData}
        // height={200}
        margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={
          {
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'transportation',
            // legendOffset: 36,
            legendPosition: 'middle',
          } as any
        }
        axisLeft={null}
        colors={{ datum: 'color' }}
        // colors={{ scheme: 'nivo' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel='y'
        pointLabelYOffset={-12}
        useMesh={true}
        enableGridX={false}
        enableGridY={false}
        enableCrosshair={false}
        legends={[]}
        tooltip={item => {
          const { point = {} } = item;
          const { data = {} } = point as any;
          return (
            <>
              {data.x} : {data.y} {label}
            </>
          );
        }}
      />
    </>
  );
}

export default Cardchart;
