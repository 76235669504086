import React, { memo, useEffect } from 'react';
import NotificationBids from './bids';
import NotificationAccounts from './accounts';
import { activitiessearchTypes, activitiesTypes } from '../../../../types';

interface propsTypes extends activitiessearchTypes {
  setBreadcrumb: any;
  activities: activitiesTypes;
}
function Notification(props: propsTypes) {
  const { setBreadcrumb, activities } = props;
  const { currentnotificationcategory } = activities;

  useEffect(() => {
    setBreadcrumb({
      page: 'Activities',
      altname: 'Notifications',
      title: 'Notifications',
      name: 'Notifications',
      menuactive: 'activities',
    });
  }, [setBreadcrumb]);

  return (
    <>
      {currentnotificationcategory === 'Bids' && <NotificationBids {...props} />}
      {currentnotificationcategory === 'Accounts' && <NotificationAccounts {...props} />}
    </>
  );
}

export default memo(Notification);
