import React, { memo } from 'react';
import { createPortal } from 'react-dom';

import { loadingMsg } from '../../utils/texts';
import LogoDSLoader from '../../assets/images/loader';
import { useDSSelector } from '../../store/reducers';

const loaderElement = document.getElementById('loaderOverlay') as HTMLElement;

function Loader() {
  const isLoading = useDSSelector(state => state.loader.isLoading);
  const isSuspenseLoading = useDSSelector(state => state.shared.isSuspenseLoading);
  return createPortal(
    isLoading || isSuspenseLoading ? (
      <div className='overlay'>
        <div className='progressbox'>
          <div>
            <LogoDSLoader alt={loadingMsg} />
          </div>
        </div>
      </div>
    ) : null,
    loaderElement,
  );
}

export default memo(Loader);
