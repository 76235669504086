export const approveDocsWarning = 'Please approve documents before awarding bid.';
export const addAwardDocContinue = 'Continue without notifying planholders.';
export const addAwardDocCancel = 'Cancel and let me upload an Award Document.';

export const notifyAwardeeTexts = {
  headers: {
    message: 'Message',
    contactInfo: 'Contact Information For Next Steps',
    bidDetails: 'Bid Details',
  },
  labels: {
    subjectLine: 'Subject Line',
    introduction: 'Introduction',
    customMessage: 'Custom Message',
    contactName: 'Contact Name',
    contactEmail: 'Email Address',
    phoneNumber: 'Phone Number',
    jobTitle: 'Job Title',
    agency: 'Agency',
    bidWriter: 'Bid Writer',
    bidNumber: 'Bid Number',
    bidName: 'Bid Name',
    reviewDocs: 'Review Required Documents',
  },
  copy: {
    requiredDocs: [
      'has identified documents you are required to review. There may also be additional documentation you must provide. Please go to the ',
      'Bid Details Page',
      ' for more information.',
    ],
    ifYouHaveQuestions: 'If you have questions, please direct further correspondence to:',
    whatShouldRecipientDo: {
      0: 'What should',
      awardee: 'the awardee',
      nonAwardee: 'the bidder',
      colleague: 'your colleague',
      default: 'the recipient',
      1: 'do next?',
    },
  },
  notificationDefaults: {
    subject: {
      awardees: "You've been awarded a bid on DemandStar.",
      unselected: 'Your bid response was not selected.',
      colleagues: 'A bid has been awarded.',
    },
    introduction: 'Dear',
    customMessage: {
      awardees: 'Congratulations! You have been awarded the following bid.',
      unselected:
        "Thank you so much for responding to our solicitation. We're sorry to inform you that another vendor was chosen. Please keep watching this space for further opportunities.",
      colleagues: 'This bid has been awarded.',
    },
  },
};

function awardee(plural: boolean) {
  if (plural) return 'awardees';
  return 'awardee';
}

export const finalizeAwardTexts = {
  recommendationOfAward: 'Recommendation of Award',
  awarded: 'Awarded',
  recommendationCopy: [
    (): string => 'Change this bid’s status to',
    (plural: boolean): string =>
      `to notify the presumptive ${awardee(
        plural,
      )}, send documents, and request documents you want from them.`,
    (): string =>
      'Once you are ready to award this bid, return to this screen to formally award the contract.',
    (): string => 'does not notify those who were not selected.',
  ],
  awardBidCopy: [
    (): string => 'Change this bid’s status to',
    (plural: boolean): string =>
      `to notify the presumptive ${awardee(
        plural,
      )}, send documents, and request documents you want from them.`,
    (): string => 'This will also notify all unselected planholders that they were not chosen.',
  ],
};
