import React from 'react';
import { useDispatch } from 'react-redux';

import { ModalPopUp, ModalPopUpProps } from '../../modals/ModalPopUp';
import { AddQuotesState } from '../../../../store/reducers/addquotes';
import { Assert } from '../../../../utils/helpers';
import { Button } from '../../controls/buttons/Button';
import { deleteDocConfirmMsgText } from '../../../../utils/texts';
import { submitAddBidDocumentDelete } from '../../../../store/actions';
import { submitAddQuotesDocumentDelete } from '../../../../store/actions';
import { SubmitButton } from '../../controls/buttons/SubmitButton';
import { useDSSelector } from '../../../../store/reducers';

interface DeleteDocumentProps {
  type: 'quote' | 'bid';
  closeModal: () => void;
  bidId?: number;
  bidDocId?: number;
}

export const DeleteDocument = (props: DeleteDocumentProps) => {
  const { closeModal, bidId = '', type } = props;

  const deleteBidDocumentDetails = useDSSelector(state => state.addbid.deleteDocumentDetails);
  const quoteId = useDSSelector(
    state => (state.addquotes as AddQuotesState).deleteDocumentDetails?.quotesId,
  );
  const quoteDocId = useDSSelector(
    state => (state.addquotes as AddQuotesState).deleteDocumentDetails?.quoteDocId,
  );

  const bidDocId = props.bidDocId || deleteBidDocumentDetails?.bidDocId || '';

  const dispatch = useDispatch();

  function deleteDocument() {
    if (type === 'quote') {
      Assert(!!quoteDocId, 'We expect to have quoteDocId');
      Assert(!!quoteId, 'We expect to have quoteId');
      dispatch(submitAddQuotesDocumentDelete({ quoteDocId, quoteId }));
    } else {
      Assert(!!bidDocId, 'We expect to have bidDocId');
      Assert(!!bidId, 'We expect to have bidId');
      dispatch(submitAddBidDocumentDelete({ bidId, bidDocId }));
    }
  }

  return (
    <div data-testid='add-bid.document-upload.delete.modal'>
      <p>{deleteDocConfirmMsgText}</p>
      <Button
        title='Cancel'
        onClick={closeModal}
        dataTestId='add-bid.document-upload.delete.modal.cancel-btn'
      >
        Cancel
      </Button>

      <SubmitButton
        title='Delete'
        float='right'
        onClick={deleteDocument}
        dataTestId='add-bid.document-upload.delete.modal.delete-btn'
      >
        Delete
      </SubmitButton>
    </div>
  );
};

type DeleteDocumentModalProps = Omit<ModalPopUpProps, 'size' | 'title'> & DeleteDocumentProps;

export const DeleteDocumentModal = (props: DeleteDocumentModalProps) => {
  const { closeModal, bidId, type, isOpen } = props;

  return (
    <ModalPopUp size='md' title='Confirmation' closeModal={closeModal} isOpen={isOpen}>
      <DeleteDocument closeModal={closeModal} bidId={bidId} type={type} />
    </ModalPopUp>
  );
};
