import { toast } from 'react-toastify';
import { BooleanText } from '../shared/constants';
import { ToastParameters } from '../types/shared';
import { toastAutoCloseDelay } from './constants';

export function getDefaultLoadingMessage(thing: string): string {
  return `Loading ${thing}...`;
}
export function getDefaultLoadingErrorMessage(thing: string): string {
  return `There was an error loading ${thing}.`;
}
export function getDefaultNotFoundMessage(things: string): string {
  return `No ${things} were found.`;
}

/**
 * Display React Toastify
 * @description Typed, borrowed from `toastFn`
 * @param {ToastParameters} toastParams
 */
export function showToast({ reason = '', message = '', id, autoClose = false }: ToastParameters) {
  if (!id || !toast.isActive(id)) {
    if (reason) {
      (toast as any)[reason](message, {
        toastId: id,
        autoClose: autoClose ? autoClose : toastAutoCloseDelay,
      });
    } else {
      toast(message, { toastId: id, autoClose: autoClose ? autoClose : toastAutoCloseDelay });
    }
  }
}

/**
 * Display React Toastify Success Toast
 * @description Helper
 * @param {ToastParameters} toastParams
 */
export function showToastSuccess({
  message = '',
  id,
  autoClose = false,
}: Omit<ToastParameters, 'reason'>) {
  showToast({ reason: 'success', message, id, autoClose });
}

/**
 * Display React Toastify Erroe Toast
 * @description Helper
 * @param {ToastParameters} toastParams
 */
export function showToastError({
  message = '',
  id,
  autoClose = false,
}: Omit<ToastParameters, 'reason'>) {
  showToast({ reason: 'error', message, id, autoClose });
}

export function getBooleanText(testValue: boolean | string | number) {
  return testValue ? BooleanText.TRUE : BooleanText.FALSE;
}
