import React from 'react';
import { RegisterOptions } from 'react-hook-form';

import { emailMaxLength, emailVal, emailValErrorMsg } from '../utils';
import { FieldText, FieldTextProps } from '../field-text/FieldText';

type FieldEmailProps = FieldTextProps & {
  register: (registerOptions?: RegisterOptions) => any; // is there a better way to type this?
  required?: boolean;
};

/** email field with built in validation, exclusively used with react-hook-form
 * @prop {string}                   name - HTML name (must be unique)
 * @prop {register}                 register - react-hook-form `register`
 * @prop {FieldError}               error - react-hook-form error {formState.errors["fooName"]}
 * @prop {string=}                  label - HTML label. Optional. Will also add additional margin at bottom.
 * @prop {string=}                  message - additional description
 * @prop {string=}                  placeholder - HTML placeholder
 * @example <FieldEmail name='email' label='Email' error={errors.email} register={register} />
 * */
export const FieldEmail = (props: FieldEmailProps) => {
  const { label, message, name, placeholder, dataTestId, error, register, required = true } = props;

  const registerOptions: RegisterOptions = {
    required,
    pattern: {
      value: emailVal,
      message: emailValErrorMsg,
    },
    maxLength: emailMaxLength,
  };

  return (
    <FieldText
      data-testid={dataTestId}
      error={error}
      label={label}
      message={message}
      name={name}
      placeholder={placeholder}
      ref={register(registerOptions)}
    />
  );
};
