import React from 'react';

import { ErrorTextWrapper, NotFoundText } from '../../../shared/styles';
import { InfoIcon } from '../icons';

export interface ErrorViewProps {
  message?: string;
}
export function ErrorPanel(props: ErrorViewProps) {
  return (
    <ErrorTextWrapper>
      <InfoIcon width={18} height={18} />
      <NotFoundText>{props.message || 'There was an error...'}</NotFoundText>
    </ErrorTextWrapper>
  );
}
