import { memberTypes } from './member';

/**
 * Do NOT alphabetize:
 * The order of this list should be determined by product team as it coincides with placement in the dropdown menu
 **/
export const DropDownMenuItems = [
  {
    Url: '/accountinfo/memberinfo',
    Title: 'Account',
    classNames: 'mdi mdi-account',
    showTo: ['All'],
  },
  {
    Url: '/buyers/account/certifications/list',
    Title: 'Certification Center',
    classNames: 'mdi mdi-certificate',
    showTo: [memberTypes.agencyBuyer],
  },
  {
    Url: '/buyers/account/configuration/bidtypes',
    Title: 'Bid Configuration',
    classNames: 'mdi mdi-newspaper',
    showTo: [memberTypes.agencyBuyer],
  },
  /* {
    Url: '/buyers/account/templates',
    Title: 'Legal Ads',
    classNames: 'mdi mdi-gavel',
    showTo: [memberTypes.agencyBuyer],
  }, */
  {
    Url: '/suppliers/account/subscriptions',
    Title: 'Subscriptions',
    classNames: 'mdi mdi-autorenew',
    showTo: [
      memberTypes.agencyCreatedPlanholder,
      memberTypes.basicSupplier,
      memberTypes.migratedNonSubscriber,
      memberTypes.subscribingSupplier,
      memberTypes.supplementalSupplier,
      memberTypes.suspendedSupplier,
    ],
  },
  {
    Url: '/suppliers/account/payments',
    Title: 'Payments',
    classNames: 'mdi mdi-currency-usd',
    showTo: [memberTypes.subscribingSupplier, memberTypes.basicSupplier],
  },
  {
    Url: '/suppliers/account/commoditycodes',
    Title: 'Commodity Codes',
    classNames: 'mdi mdi-chart-timeline',
    showTo: [memberTypes.subscribingSupplier, memberTypes.basicSupplier],
  },
];

export const planMenus = ['Commodity Codes', 'Subscriptions'];

export interface footerMenuType {
    headerTitle: string,
    items: Array<{ 
        title:string, 
        link: string,
    }>
}

export const footerMenus: Array<footerMenuType> = [
  {
    headerTitle:'About',
    items:[
      {title:"About", link:"https://network.demandstar.com/about/"},
      {title:"News", link:"https://network.demandstar.com/news/"},
      {title:"Blogs", link:"https://network.demandstar.com/blog-2/"},
      {title:"Careers", link:"https://network.demandstar.com/careers/"},
      {title:"Pricing", link:"https://network.demandstar.com/pricing/"},
      {title:"FAQ", link:"https://network.demandstar.com/faq/"},
    ],
  },
  {
    headerTitle:'Support',
    items:[
      {title:"Agency Support", link:"https://network.demandstar.com/agency-support/"},
      {title:"Supplier Support", link:"https://network.demandstar.com/supplier-support/"},
      {title:"Contact Form", link:"https://network.demandstar.com/contact-us/"},
    ],
  },
];
