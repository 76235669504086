import * as actionTypes from '../actionTypes';
import { currentYear, generateKey } from '../../utils/helpers';
import { DeleteQuoteDocumentDetails } from '../../types/addquote';
import { payloadTypes } from '../../types/actiontypedef';
import { Selectable } from '../../types/common';
import { WizardStatus } from '../../types/wizard';

export interface AddQuotesState {
  addquotesinfo?: any;
  addquoteswire?: any;
  addquoteswizard?: any;
  canFinishQuote: false;
  contactAddress1: string;
  contactAddress2: string;
  contactCity: string;
  contactCountryId: string;
  contactDepartment: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  contactPostalCode: string;
  contactStateId: string;
  deleteDocumentDetails?: DeleteQuoteDocumentDetails;
  deliveryARO: string;
  quotedueDate: string;
  quotedueTime: Selectable<string>;
  quotedueTimeZone: Selectable<'AM' | 'PM'>;
  quoteNo: string;
  quoteTitle: string;
  quoteYear: { label: number; value: number };
  sameascontactinformation: true;
  shipAddress1: string;
  shipAddress2: string;
  shipCity: string;
  shipCountryId: string;
  shipDepartment: string;
  shipName: string;
  shipPhone: string;
  shipPostalCode: string;
  shipStateId: string;
  shipVia: string;
  wizardcurrentpage?: any;
}

export const initialAddQuotesInfo: AddQuotesState = {
  quoteTitle: '',
  quoteNo: '',
  quoteYear: {
    label: currentYear(),
    value: currentYear(),
  },
  quotedueDate: '',
  quotedueTime: { value: '11:00', label: '11:00' },
  quotedueTimeZone: { value: 'PM', label: 'PM' },
  deliveryARO: '',
  shipVia: '',
  contactName: '',
  contactDepartment: '',
  contactAddress1: '',
  contactAddress2: '',
  contactCountryId: '',
  contactStateId: '',
  contactCity: '',
  contactPostalCode: '',
  contactPhone: '',
  contactEmail: '',
  sameascontactinformation: true,
  shipName: '',
  shipDepartment: '',
  shipAddress1: '',
  shipAddress2: '',
  shipCountryId: '',
  shipStateId: '',
  shipCity: '',
  shipPostalCode: '',
  shipPhone: '',
  canFinishQuote: false,
};

export const initiallinewires = [
  { description: '', quantity: '', uom: '' },
  { description: '', quantity: '', uom: '' },
  { description: '', quantity: '', uom: '' },
];

export const initialdocumentlist = {
  doctitle: '',
  docfile: '',
  uploadprocess: 'notstarted',
  quoteDocId: '',
  docext: '',
  progress: 0,
  quoteId: '',
  doctype: 'QD',
  id: generateKey('qdoc'),
  isExist: false,
};

export const initialAddQuotesState = {
  addquoteswizard: [
    { status: WizardStatus.Current, id: 1, name: 'Quote Information' },
    { status: WizardStatus.Unavailable, id: 2, name: 'Quote Wire' },
    { status: WizardStatus.Unavailable, id: 3, name: 'Manage Documents' },
    { status: WizardStatus.Unavailable, id: 4, name: 'Build Broadcast List' },
    { status: WizardStatus.Unavailable, id: 5, name: 'Supplemental Suppliers' },
  ],
  addquotesinfo: initialAddQuotesInfo,
  addquoteswire: {
    additionalspecifications: '',
    insuranceandadditional: '',
    termsandconditions: '',
    linewires: initiallinewires,
  },
  wizardcurrentpage: 1,
  addquoteswizardpartial: false,
  documentslist: [initialdocumentlist],
  upload_progress: false,
  halfWayCancel: false,
  halfWayCancelAllow: false,
  canFinishQuote: false,
  deletedocumentmodal: false,
  deleteDocumentDetails: '',
  quotesNumbers: [],
};

export const reducer = (state = initialAddQuotesState, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.RESET_ADD_QUOTES_DETAILS.TRIGGER:
      state = {
        ...initialAddQuotesState,
        addquoteswizard: [
          { status: WizardStatus.Current, id: 1, name: 'Quote Information' },
          { status: WizardStatus.Unavailable, id: 2, name: 'Quote Wire' },
          { status: WizardStatus.Unavailable, id: 3, name: 'Manage Documents' },
          { status: WizardStatus.Unavailable, id: 4, name: 'Build Broadcast List' },
          { status: WizardStatus.Unavailable, id: 5, name: 'Supplemental Suppliers' },
        ],
      };
      break;
    case actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER:
    case actionTypes.SUBMIT_ADD_QUOTES_INFO.SUCCESS:
    case actionTypes.SUBMIT_ADD_QUOTES_MOVE_NEXT.SUCCESS:
    case actionTypes.GET_ADD_QUOTES_COMPLETED_PAGE.SUCCESS:
    case actionTypes.SUBMIT_ADD_QUOTES_WIRE.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.GET_ADD_QUOTES_ITEMS.SUCCESS:
      state = { ...state, addquoteswire: { ...state.addquoteswire, linewires: payload } };
      break;
    case actionTypes.GET_ADD_QUOTES_INFO.SUCCESS:
      state = { ...state, addquotesinfo: payload.addquotesinfo };
      break;
    case actionTypes.GET_ADD_QUOTES_WIRE.SUCCESS:
      state = { ...state, addquoteswire: { ...state.addquoteswire, ...payload } };
      break;
    case actionTypes.GET_ADD_QUOTES_DOCUMENTS.SUCCESS:
      state = { ...state, documentslist: payload };
      break;
    case actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_DELETE.SUCCESS:
      state = {
        ...state,
        documentslist: payload,
        deletedocumentmodal: false,
        deleteDocumentDetails: '',
      };
      break;
    case actionTypes.GET_ADD_QUOTES_CAN_COMPLETE.SUCCESS:
      state = { ...state, canFinishQuote: payload };
      break;
    case actionTypes.GET_ADD_QUOTES_NUMBERS.SUCCESS:
      state = { ...state, quotesNumbers: payload };
      break;
    case actionTypes.RESET_ADD_QUOTES.TRIGGER:
      state = initialAddQuotesState;
      break;
    default:
      break;
  }
  return state;
};
