import { FontFamily } from '@demandstar/components/styles';
import { HTMLProps } from 'react';
import styled from 'styled-components';

import {
  BaseColor,
  Colors,
  ErrorColor,
  FontSize,
  FontWeight,
  Margin,
  Width,
} from '../../../shared/styles';

export interface FormFieldContainerProps extends HTMLProps<HTMLInputElement> {
  marginBottom?: boolean;
  fullWidth?: boolean;
}
/**
 *
 */
export const FormFieldContainer = styled.div<FormFieldContainerProps>`
  margin-bottom: ${props => (props.marginBottom ? Margin.base : 0)};
  max-width: ${props => (props.fullWidth ? 'none' : Width.input)};
  width: ${props => (props.fullWidth ? '100%' : Width.input)};
  display: inline-block;
`;

/**
 * This is the label that optionally floats above a text input.
 */
export const FormFieldLabel = styled.label`
  color: ${BaseColor.black};
  display: inline-block;
  font-family: ${FontFamily.Header};
  font-size: 1rem;
  font-weight: ${FontWeight.bold};
  line-height: 1rem;
  margin-bottom: 0;
  width: ${Width.full};
  span {
    color: ${ErrorColor.base};
  }
`;

/**
 * This is the span of text that gives any additional message
 * needed for the input.
 */
export const FormFieldMessage = styled.span`
  display: block;
  font-size: ${FontSize.small};
  line-height: 1rem;
  color: ${Colors.grayMediumDark};
  width: ${Width.full};
  min-height: 1rem;
  min-width: 1px;
`;

/**
 * This is the span of text that tells of any error.
 */
export const FormFieldError = styled.span`
  color: ${Colors.error};
  display: block;
  font-size: 0.9rem;
  font-weight: ${FontWeight.normal};
  line-height: 0.9rem;
  margin-bottom: 0;
  min-height: 0.9rem;
  width: ${Width.full};
`;

export const FormFieldErrorStandalone = styled(FormFieldError)`
  margin-top: 0.5rem;
`;

export const FormFieldErrorStandaloneSelectBox = styled(FormFieldErrorStandalone)`
  margin-top: 1.5rem;
`;
