import React, { memo } from 'react';

interface PropsTypes {
  submit: any;
}

const Program = (props: PropsTypes) => {
  const { submit } = props;
  return (
    <>
      <li title='Add Certification'>
        <span className='staticLink' onClick={submit}>
          <i className='mdi mdi-plus mr-1' /> Add Certification
        </span>
      </li>
    </>
  );
};

export const AddProgram = memo(Program);
