import React from 'react';
import { useDispatch } from 'react-redux';
import { setSubscriptionDetails } from '../../../store/actions';
import { useDSSelector } from '../../../store/reducers';
import {
  filterDistinctArray,
  getSubscribedProductWithRespectivePrice,
} from '../../../utils/helpers';

interface CartProps {
  isReview?: boolean;
  showDelIcon?: boolean;
  isRenewal?: boolean;
}

const Cart = (props: CartProps) => {
  const dispatch = useDispatch();
  const { isReview = false, showDelIcon = true, isRenewal = false } = props;
  const existingSubscriptionDetails = useDSSelector(
    state => state.subscriptions.existingSubscriptionDetails || [],
  );
  const allProducts = useDSSelector(state => state.subscriptions.allProducts || []);
  const currentSubscribedNational = useDSSelector(
    state => state.subscriptions.currentSubscribedNational || [],
  );
  const currentSubscribedCounties = useDSSelector(
    state => state.subscriptions.currentSubscribedCounties || [],
  );
  const currentSubscribedStates = useDSSelector(
    state => state.subscriptions.currentSubscribedStates || [],
  );
  const subscribedCounties = useDSSelector(state => state.subscriptions.subscribedCounties || []);
  const subscribedNational = useDSSelector(state => state.subscriptions.subscribedNational || []);
  const subscribedStates = useDSSelector(state => state.subscriptions.subscribedStates || []);

  let selectedStates = [];
  let selectedCounties = [];
  let selectedNational = [];

  if (showDelIcon || isReview) {
    selectedStates = getSubscribedProductWithRespectivePrice(
      allProducts,
      subscribedStates,
      existingSubscriptionDetails,
      isRenewal || showDelIcon ? false : true,
    );
    selectedCounties = getSubscribedProductWithRespectivePrice(
      allProducts,
      subscribedCounties,
      existingSubscriptionDetails,
      isRenewal || showDelIcon ? false : true,
    );
    selectedNational = getSubscribedProductWithRespectivePrice(
      allProducts,
      subscribedNational,
      existingSubscriptionDetails,
      isRenewal || showDelIcon ? false : true,
    );
  } else {
    selectedStates = getSubscribedProductWithRespectivePrice(
      allProducts,
      currentSubscribedStates,
      existingSubscriptionDetails,
    );
    selectedCounties = getSubscribedProductWithRespectivePrice(
      allProducts,
      currentSubscribedCounties,
      existingSubscriptionDetails,
    );
    selectedNational = getSubscribedProductWithRespectivePrice(
      allProducts,
      currentSubscribedNational,
      existingSubscriptionDetails,
    );
  }
  selectedCounties = filterDistinctArray(selectedCounties, 'productId');

  const deleteCartCounties = (productId: number) => {
    const filteredCounties = subscribedCounties.filter((county: number) => county !== productId);
    dispatch(
      setSubscriptionDetails({
        subscribedCounties: filteredCounties,
        currentExpiryDate: '',
        expiryDate: '',
        calculatedPrice: 0,
      }),
    );
  };

  const deleteCartStates = (productId: number) => {
    const filteredStates = subscribedStates.filter((state: number) => state !== productId);
    dispatch(
      setSubscriptionDetails({
        subscribedStates: filteredStates,
        currentExpiryDate: '',
        expiryDate: '',
        calculatedPrice: 0,
      }),
    );
  };

  return (
    <>
      <div className='cartWrapper'>
        <h6>Nation ({selectedNational.length})</h6>
        <ul className='list-unstyled'>
          {selectedNational.map((item: any, index: number) => (
            <li key={index} className='d-flex'>
              <label className='flex1'>{item.productName}</label>
              <span>${item.price}/year</span>
              {showDelIcon && !isReview ? (
                <i
                  onClick={() =>
                    dispatch(
                      setSubscriptionDetails({
                        subscribedNational: [],
                        currentExpiryDate: '',
                        expiryDate: '',
                        calculatedPrice: 0,
                      }),
                    )
                  }
                  className='mdi mdi-close mdi-24px staticLink'
                />
              ) : null}
            </li>
          ))}
        </ul>
        {selectedNational.length === 0 ? (
          <>
            <h6>Counties ({selectedCounties.length})</h6>
            <ul className='list-unstyled'>
              {selectedCounties.map((item: any, index: number) => (
                <li key={index} className='d-flex'>
                  <label className='flex1'>{item.productName}</label>
                  <span>${item.price}/year</span>
                  {showDelIcon && !isReview ? (
                    <i
                      onClick={() => deleteCartCounties(item.productId)}
                      className='mdi mdi-close mdi-24px staticLink'
                    />
                  ) : null}
                </li>
              ))}
            </ul>
            <h6>States ({selectedStates.length})</h6>
            <ul className='list-unstyled'>
              {selectedStates.map((item: any, index: number) => (
                <li key={index} className='d-flex'>
                  <label className='flex1'>{item.productName}</label>
                  <span>${item.price}/year</span>
                  {showDelIcon && !isReview ? (
                    <i
                      onClick={() => deleteCartStates(item.productId)}
                      className='mdi mdi-close mdi-24px staticLink'
                    />
                  ) : null}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Cart;
