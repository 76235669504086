import { Cell, Column } from 'react-table';
import React, { useState } from 'react';

import {
  NewRequiredDocType,
  RequiredDocType,
  useRequiredDocumentTypes,
} from '../../../../shared/hooks/award-bid/useRequiredDocumentTypes';
import { PanelLabel, PanelRow, PanelRowFlexSpaceBetween } from '../../../../shared/styles';
import { TableBase, TableStyleWrapper } from '../../../common/table';
import { ActionButton } from '../../../common/controls/buttons/ActionButton';
import { Assert } from '../../../../utils/helpers';
import { commonLabels } from '../../../../shared/constants';
import { DeleteIconCell } from '../../../common/table/renderers/DeleteIconCell';
import { ErrorPanel } from '../../../common/loading';
import { Id } from '../../../../types/shared';
import { requiredDocLabels } from './constants';
import { SubmitButton } from '../../../common/controls/buttons/SubmitButton';
import { TextInput } from '../../../forms';
import { WidgetSet } from '../../../common/form';
interface AddCustomDocumentTypeFormProps {
  closeModal: () => void;
}

export function CustomDocumentTypeAddForm({ closeModal }: AddCustomDocumentTypeFormProps) {
  // Hooks
  const {
    addRequiredDocTypes,
    deleteRequiredDocType,
    requiredDocTypes,
    saveRequiredDocTypes,
    setRequiredDocTypes,
  } = useRequiredDocumentTypes();

  // Local state
  const [newCustomDocType, setNewCustomDocType] = useState<NewRequiredDocType>({
    description: '',
    title: '',
    sortOrder: 0,
    isDelete: false,
  });

  const [deletedTypes, setDeletedTypes] = useState<RequiredDocType[]>([]);

  // Form hook functions
  const customDocTableConfig: Column<RequiredDocType>[] = [
    {
      accessor: 'title',
      Header: 'Title',
      minWidth: 300,
      Cell: (cellData: Cell<RequiredDocType>) => {
        const docType = cellData.row.original;
        return (
          <TextInput
            fullWidth
            value={docType.title}
            name='docName'
            onChange={false}
            onBlur={value => {
              updateRow(docType.memberAwardRequiredDocResponseItemId, 'title', value);
            }}
          ></TextInput>
        );
      },
    },
    {
      accessor: 'description',
      Header: 'Description',
      minWidth: 300,
      Cell: (cellData: Cell<RequiredDocType>) => {
        const docType = cellData.row.original;
        return (
          <TextInput
            value={docType.description}
            name='docDescription'
            fullWidth
            onChange={false}
            onBlur={value => {
              updateRow(docType.memberAwardRequiredDocResponseItemId, 'description', value);
            }}
          ></TextInput>
        );
      },
    },
    {
      accessor: 'isDelete',
      Header: '',
      Cell: DeleteIconCell<number, RequiredDocType>({
        idField: 'memberAwardRequiredDocResponseItemId',
        labelField: 'title',
        handleClick: deleteRow,
      }),
      maxWidth: 30,
    },
  ];

  async function deleteRow({ id }: Id<number>) {
    const selectedDoc = requiredDocTypes.find(
      doc => doc.memberAwardRequiredDocResponseItemId === id,
    );
    Assert(
      !!selectedDoc,
      'Expected: `selectedDoc` to exist in vendorDocuments',
      'src/components/buyer/awardbid/add-vendor-docs/VendorDocumentsTable.tsx',
    );
    await deleteRequiredDocType(selectedDoc);
    setDeletedTypes([...deletedTypes, { ...selectedDoc }]);
  }

  function updateRow(id: number, prop: keyof RequiredDocType, value: string) {
    const tempRequiredDocTypes = [...requiredDocTypes];
    const rowIndex = requiredDocTypes.findIndex(doctype => {
      return doctype.memberAwardRequiredDocResponseItemId === id;
    });
    tempRequiredDocTypes[rowIndex] = {
      ...tempRequiredDocTypes[rowIndex],
      [prop]: value,
    };
    setRequiredDocTypes(tempRequiredDocTypes);
  }

  function handleTitleChange(title: string) {
    setNewCustomDocType({ ...newCustomDocType, title });
  }
  function handleDescriptionChange(description: string) {
    setNewCustomDocType({ ...newCustomDocType, description });
  }

  async function submitForm() {
    if (newCustomDocType.title) {
      addRequiredDocTypes([newCustomDocType]);
    }
    saveRequiredDocTypes();
    closeModal();
  }

  return (
    <>
      <PanelLabel>{requiredDocLabels.addNewDocumentType}</PanelLabel>
      <WidgetSet>
        <TextInput
          name={'documentTypeName'}
          label={requiredDocLabels.documentTypeTitle}
          onChange={handleTitleChange}
          value={newCustomDocType.title}
          required
        ></TextInput>
        <TextInput
          name={'documentTypeDescription'}
          label={requiredDocLabels.documentTypeDescription}
          onChange={handleDescriptionChange}
          value={newCustomDocType.description}
          disabled={!newCustomDocType.title}
        ></TextInput>
      </WidgetSet>
      <br></br>
      <PanelLabel>{requiredDocLabels.editDocumentTypes}</PanelLabel>

      <TableStyleWrapper>
        <TableBase columns={customDocTableConfig} data={requiredDocTypes} />
      </TableStyleWrapper>
      {deletedTypes.map(docType => {
        return (
          <ErrorPanel
            key={docType.memberAwardRequiredDocResponseItemId}
            message={`${docType.title} was deleted.`}
          />
        );
      })}
      <PanelRow paddingVertical={'0.5rem'} />

      <PanelRowFlexSpaceBetween>
        <ActionButton onClick={closeModal}>{commonLabels.cancel}</ActionButton>
        <SubmitButton onClick={submitForm}>{commonLabels.save}</SubmitButton>
      </PanelRowFlexSpaceBetween>
    </>
  );
}
