import React, { memo } from 'react';

interface PropsTypes {
  submit: any;
  cancel: any;
  valid: Record<string, unknown> | undefined;
  pristine: boolean | undefined;
  submitting: boolean | undefined;
  opi: number;
}

const AccountInfoMemberInfo = (props: PropsTypes) => {
  const { submit, cancel, valid, pristine, submitting, opi = 0 } = props;

  return (
    <>
      {!valid || opi ? (
        false
      ) : pristine || submitting ? (
        <li className='disabled-bttn'>
          <span className=''>
            <i className='mdi mdi-content-save-outline mr-1' /> Save
          </span>
        </li>
      ) : (
        <li title='Save'>
          <span className='staticLink' onClick={submit}>
            <i className='mdi mdi-content-save-outline mr-1' /> Save
          </span>
        </li>
      )}
      <li title='Cancel'>
        <span className='staticLink' onClick={cancel}>
          <i className='mdi mdi-close mr-1' />
          Cancel
        </span>
      </li>
    </>
  );
};

export default memo(AccountInfoMemberInfo);
