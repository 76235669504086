import React from 'react';
import { Table } from 'reactstrap';
import { Buttons, DeprecatedInput } from '../../../customcontrols/index';

function ProductsSummary() {
  return (
    <>
      <h3 className='arrowWrapper'>Products</h3>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <DeprecatedInput label='Search' type='search' placeholder='Search' />
          <Table className='tableHData'>
            <tr>
              <th>Product Name</th>
              <th>Group</th>
              <th>Actions</th>
            </tr>
            <tr>
              <td>United States of America</td>
              <td>Nationwide</td>
              <td>
                <i className='mdi mdi-delete-forever-outline mdi-24px staticLink' />
              </td>
            </tr>
            <tr>
              <td>Southern Region</td>
              <td>Regional</td>
              <td>
                <i className='mdi mdi-delete-forever-outline mdi-24px staticLink' />
              </td>
            </tr>
          </Table>
          <Buttons classNames='bttn bttn-primary float-right' text='Edit' />
        </div>
      </div>
    </>
  );
}

export default ProductsSummary;
