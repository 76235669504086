import { useRecoilCallback, useRecoilValue } from 'recoil';

import { deleteVendorSpecificDocument, getVendorSpecificDocuments } from '../../../store/services';
import { VendorDocumentDeleteAPIRequest, VendorDocumentGetAPIRequest } from '../../../types/';

import { selectedBidIdState } from '../../../store/recoil/bidState';
import { supplierMemberIdState } from '../../../store/recoil/memberState';
import { track } from '../../../utils/telemetry';
import { vendorDocumentListState } from '../../../store/recoil/award-bid';

export function useVendorDocuments() {
  const vendorDocumentList = useRecoilValue(vendorDocumentListState);

  async function getVendorDocsFromService({
    bidId,
    supplierMemberId,
  }: VendorDocumentGetAPIRequest) {
    try {
      const response = await getVendorSpecificDocuments({ bidId, supplierMemberId });
      return response;
    } catch (error: any) {
      const errorMessage = `useUpdateVendorDocuments -> getVendorDocuments(bidId: ${bidId}, memberId: ${supplierMemberIdState}, supplierMemberId: ${supplierMemberId}) ERROR:`;
      track(errorMessage, {
        error,
        method: 'POST',
        errorMessage: error.message,
      });
      console.error(`${errorMessage}: \n${error}`);
      return [];
    }
  }

  const refreshVendorDocuments = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const bidId = await snapshot.getPromise(selectedBidIdState);
        const supplierMemberId = await snapshot.getPromise(supplierMemberIdState);

        const freshVendorDocs = await getVendorDocsFromService({
          bidId,
          supplierMemberId,
        });
        set(vendorDocumentListState, freshVendorDocs);
      },
    [],
  );

  async function deleteVendorDocument({
    bidAwardDocId,
    memberId,
    opsAccountId,
  }: VendorDocumentDeleteAPIRequest) {
    try {
      const response = await deleteVendorSpecificDocument({
        bidAwardDocId,
        memberId,
        opsAccountId,
      });
      console.log('deleteVendorSpecificDocument', response);
      return response;
    } catch (error: any) {
      const errorMessage = `useUpdateVendorDocuments -> deleteVendorDocuments(bidAwardDocId: ${bidAwardDocId}, memberId: ${memberId}, opsAccountId: ${opsAccountId}) ERROR:`;
      track(errorMessage, {
        error,
        method: 'POST',
        errorMessage: error.message,
      });
      console.error(`${errorMessage}: \n${error}`);
    }
  }

  return {
    deleteVendorDocument,
    getVendorDocsFromService,
    refreshVendorDocuments,
    vendorDocumentList,
  };
}
