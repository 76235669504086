import React, { MouseEvent, ReactNode } from 'react';

import { defaultNumericId, documentLabels } from '../../../../shared/constants';
import { LinkText, NoLinkText } from '../styles';
import { NoOpFunction, OnClick } from '../../../../types/shared';

import { Cell } from 'react-table';
import { DocumentDownloadType } from '../../../../types/document';

/**
 * This type assumes a URL will be constructed as follows:
 * `${baseUrl}/${id}/${subUrl}?{queryString}`
 *   e.g. "suppliers/bids/6523465/details?actionId=87665"
 */
type DownloadLinkTextCellProps = OnClick & {
  bidIdField: string;
  docDownloadType: DocumentDownloadType;
  docIdField: string;
  downloadDisabled?: boolean;
  downloadIdField: string;
  labelField: string;
  renderFileName?: NoOpFunction; // Optional fileName parsing function
};

/**
 * This cell renders text styled as an anchor `<a>` element
 * with the click handler constructed from the baseUrl and id
 * and the text and title from the label.
 * The curried function allows custom params to be sent along with
 * the field values from `react-table`.
 * Disabled downloads must be set explicitly
 * @param initialProps
 */
export const DownloadLinkTextCell = (initialProps: DownloadLinkTextCellProps) => (
  props: Cell<any>,
) => {
  const {
    bidIdField,
    docDownloadType,
    downloadDisabled,
    downloadIdField,
    labelField,
    onClick,
    renderFileName,
  } = initialProps;
  const { row } = props;
  const { original } = row;
  const bidId = original[bidIdField] || '';
  const downloadId = original[downloadIdField] || defaultNumericId;
  const label = original[labelField] || '';
  const title = renderFileName ? renderFileName(original) : label;
  const titleDisplay =
    downloadId > 0
      ? `${documentLabels.download} ${title}`
      : `${title} ${documentLabels.downloadUnavailable}`;

  const handleDownloadClick = (docId: number | string) => (e: MouseEvent<ReactNode>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick && !downloadDisabled) {
      onClick({ bidId, docId, type: docDownloadType });
    }
  };

  return !downloadDisabled && downloadId > 0 ? (
    <LinkText onClick={handleDownloadClick(downloadId)} title={titleDisplay}>{`${label}`}</LinkText>
  ) : (
    <NoLinkText title={titleDisplay}>{`${label}`}</NoLinkText>
  );
};
