import React, { memo, useCallback, useEffect, useState } from 'react';
// import CompanyInfo from './companyinfo'
import BillingAddress from './billingaddress';
import PaymentMethod from './paymentmethod';
import RequestBidPackage from './requestbidpackage';
import OrderSummary from './ordersummary';
import DownloadEDoc from './downloaddoc';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';

import { appKeys } from '../../../utils/constants';
import { toastFn } from '../../../utils/helpers';
import { bidssummaryParamType } from '../../../types/biddetail';
import { PaymentType } from '../../../types/payments';
import { sharedTypes } from '../../../types/shared';

interface propsTypes extends PropsFromRedux, RouteComponentProps {
  history: any;
  setPaymentFormDetails: any;
  getPaymentCardDetails: any;
  submitEbitPaymentCard: any;
  submitEbitPaymentMethod: any;
  payment: PaymentType;
  setBreadcrumb: any;
  bidsSummary: any;
  bidssummary: bidssummaryParamType;
  match: any;
  shared: sharedTypes;
  memberinfo: any;
  form: any;
}

const ReviewOrder = (props: propsTypes) => {
  const [modalopen, setModalopen] = useState(false);
  const [checkcard, setCheckcard] = useState(false);
  const [through, setThrough] = useState('');
  const [Replacecards, setReplacecards] = useState(false);
  const {
    history,
    setPaymentFormDetails,
    getPaymentCardDetails,
    submitEbitPaymentCard,
    submitEbitPaymentMethod,
    payment,
    setBreadcrumb,
    bidsSummary,
    bidssummary,
  } = props;
  const { results } = bidssummary;
  const { cardinfo, card_token, fullName, save_payment_card = '' } = payment;
  const { bidName = '' } = results;
  const bidID = props.match.params.bidId || '';

  useEffect(() => {
    if (bidID) bidsSummary({ bidId: bidID });
  }, [bidID, bidsSummary]);

  useEffect(() => {
    const finalcheck = (card_token && card_token.nonce) || (cardinfo && cardinfo.cardNumberMask);
    setCheckcard(Boolean(finalcheck));
  }, [card_token, cardinfo]);

  useEffect(() => {
    if (!bidID) history.goBack();
  }, [bidID, history]);

  useEffect(() => {
    setBreadcrumb({
      component: '',
      page: 'Supplier Bids Payment',
      title: `${appKeys.headers.bidPurchase} - ${bidName}`,
      altname: appKeys.headers.purchase,
      name: bidName,
      id: bidID,
      menuactive: 'bids',
    });
  }, [bidID, bidName, setBreadcrumb]);

  useEffect(() => {
    getPaymentCardDetails();
  }, [getPaymentCardDetails]);

  useEffect(() => {
    return () => {
      setPaymentFormDetails({ card_token: '' });
    };
  }, [setPaymentFormDetails]);

  const handeClick = () => {
    setModalopen(!modalopen);
  };

  const onCardSubmit = useCallback(
    (card_token, Replacecards) => {
      if (!checkcard && through !== 'changecard') {
        const data = {
          bidId: bidID,
          token: card_token && card_token.nonce,
          canReplace: Replacecards,
        };
        submitEbitPaymentMethod({
          data: data,
          card_token,
          cardinfo,
          results,
          fullName,
        });
      } else {
        setPaymentFormDetails({ card_token });
        submitEbitPaymentCard({
          token: card_token && card_token.nonce,
          canReplace: Replacecards,
          state: Boolean(card_token),
        });
      }
      if (Replacecards) setReplacecards(false);
    },
    [
      bidID,
      cardinfo,
      checkcard,
      fullName,
      results,
      setPaymentFormDetails,
      submitEbitPaymentCard,
      submitEbitPaymentMethod,
      through,
    ],
  );

  useEffect(() => {
    if (save_payment_card) {
      setModalopen(false);
      setPaymentFormDetails({ save_payment_card: '' });
      toastFn('success', 'Card Accepted', 'payment info ex card save');
    }
  }, [save_payment_card, setPaymentFormDetails]);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='colWrapper clearfix'>
              <h3>Review Your Order</h3>
              <div className='row'>
                {/*<div className='col-6 pr-5'>
										<CompanyInfo />
									</div>*/}
                <div className='col-6 pr-5'>
                  <BillingAddress {...props} bidID={bidID} bidName={bidName} />
                </div>
                <div className='col-6 pl-5'>
                  <PaymentMethod
                    {...props}
                    handeClick={() => {
                      setThrough('changecard');
                      if (Replacecards) setReplacecards(false);
                      handeClick();
                    }}
                    through={through}
                    modalopen={modalopen}
                    onCardSubmit={(card_token: any) => onCardSubmit(card_token, Replacecards)}
                    checkcard={checkcard}
                    bidName={bidName}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <RequestBidPackage results={results} />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <DownloadEDoc results={results as any} />
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='sideBarSpacing clearfix'>
              <OrderSummary
                {...props}
                handeClick={() => {
                  setThrough('placeorder');
                  handeClick();
                }}
                checkcard={checkcard}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    payment: state.payment,
    bidssummary: state.bidssummary,
    shared: state.shared,
    memberinfo: state.memberinfo,
    accountinfo: state.accountinfo,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withRouter(memo(ReviewOrder)));
