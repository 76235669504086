import React from 'react';

import { approveconfirmmsgText } from '../../../../utils/texts';
import { BidDocument } from '../../../../types/addbid';
import { Buttons } from '../../../customcontrols';
import { submitAddBidDocumentApprove } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import { useDSSelector } from '../../../../store/reducers';

interface ApproveDocumentProps {
  SetApproveDocument: (doc?: any) => void;
  bidId: number;
  pagefor?: 'bidssummary';
}

export const ApproveDocument = (props: ApproveDocumentProps) => {
  const dispatch = useDispatch();
  const { SetApproveDocument, bidId, pagefor = '' } = props;
  const approvedocumentdetails = useDSSelector(state => state.addbid.approvedocumentdetails);
  const {
    bidDocId = '',
    bidDocID = '',
    fileName = '',
    docFormat = '',
    originalFileName = '',
  } = (approvedocumentdetails as BidDocument) || {};

  function approveBidDocument() {
    dispatch(
      submitAddBidDocumentApprove({
        bidId,
        bidDocId: bidDocId || bidDocID,
        pagefor,
      }),
    );
  }

  return (
    <>
      <p data-testid='addbid.documentupload.approve.modal'>{approveconfirmmsgText}</p>
      <p>
        <b>{originalFileName ? originalFileName : fileName + '.' + docFormat.toLowerCase()}</b>
      </p>
      <Buttons
        classNames='bttn-secondary mt-2'
        text='Cancel'
        title='Cancel'
        type='button'
        onClick={() => SetApproveDocument()}
        data-testid='addbid.documentupload.approve.modal.cancelbtn'
      />
      <Buttons
        classNames='bttn-primary  mt-2 float-right'
        title='Approve'
        text='Approve'
        type='button'
        onClick={approveBidDocument}
        data-testid='addbid.documentupload.approve.modal.approvebtn'
      />
    </>
  );
};
