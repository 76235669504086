import * as H from 'history';
import { connect, ConnectedProps } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as actionCreators from '../../../store/actions';
import Configurations from '../../../settings';
import LogoDS from '../../../assets/images/logo_ds';

import { dismisstoastAllFn, toastFn } from '../../../utils/helpers';
import { memberTypes, Pages } from '../../../utils/constants';

import { authTypes, Permission } from '../../../types/auth';

interface PropsTypes extends PropsFromRedux {
  auth: authTypes;
  history: H.History;
}

function Login(props: PropsTypes) {
  const [logindetails, setLogindetails] = useState({ USERNAME: '', PASSWORD: '' });
  const [initialError, setError] = useState(false);
  const { login, auth, history } = props;
  const { her: hasError, mt = '', prms } = auth;
  const { USERNAME, PASSWORD } = logindetails;
  const permissions = prms.replace(/ /g, '').split(',') as Permission[];

  useEffect(() => {
    const isExpired = sessionStorage.getItem('isloggingout');
    if (isExpired === '1') {
      toastFn('error', 'Session expired', 'Session expired');
    }
    sessionStorage.removeItem('isloggingout');
  }, []);

  useEffect(() => {
    dismisstoastAllFn();
  }, []);

  useEffect(() => {
    if (auth && mt) {
      const path = mt === memberTypes.agencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;
      history.replace(path);
    } else if (auth && auth.us && permissions.includes(Permission.Ops)) {
      history.replace('/ops/agency/registration');
    }
  }, [auth, history, mt, permissions]);

  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('light_DS');
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogindetails({ ...logindetails, [event.target.name]: event.target.value.trim() });
    if (initialError) setError(false);
  };

  const loginClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (USERNAME && PASSWORD) {
      login({ userName: USERNAME, password: PASSWORD });
    } else {
      if (!initialError) setError(true);
      !USERNAME
        ? toastFn('error', 'Please Enter Username', 'Enter Username and password')
        : toastFn('error', 'Please Enter Password', 'Enter Username and password');
    }
  };

  const GoHome = (e: { preventDefault: () => any }) => {
    e && e.preventDefault();
    window.location.href = Configurations.REACT_APP_NETWORK_URL;
  };

  return (
    <form onSubmit={loginClick}>
      <div className='container loginWrapper'>
        <div
          className='row d-flex justify-content-center align-items-center'
          style={{ height: '100vh' }}
        >
          <div className='col-10 col-md-5'>
            <span onClick={GoHome}>
              <LogoDS />
            </span>
            <div className='form-group'>
              <label>Username</label>
              <input
                type='text'
                className='form-control'
                name='USERNAME'
                placeholder='Enter Username'
                value={USERNAME}
                onChange={handleInputChange}
                maxLength={80}
              />
            </div>
            <div className='form-group'>
              <label>Password</label>
              <input
                type='password'
                className='form-control'
                name='PASSWORD'
                placeholder='Enter Password'
                value={PASSWORD}
                onChange={handleInputChange}
                maxLength={40}
              />
            </div>
            {hasError && initialError === false ? (
              <span className='error'>Username or Password is incorrect.&nbsp;</span>
            ) : null}
            <Link className='needHelp' to={'/accountrecovery'}>
              Need help logging in?
            </Link>
            <br></br>
            <span>
              If you are a new government agency,{' '}
              <a href='https://network.demandstar.com/schedule-a-demo/'>sign up here</a>.
            </span>
            <div className='container action-buttons mt-3'>
              <div className='row'>
                <div className='col'>
                  <Link className='bttn bttn-secondary m-auto d-block' to={'/registration'}>
                    Sign Up
                  </Link>
                </div>
                <div className='col'>
                  <button className='bttn bttn-primary m-auto d-block' name='login' type='submit'>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

const connector = connect(
  (state: { auth: authTypes }) => ({ auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Login));
