import React, { memo, useState } from 'react';
import { Buttons, DeprecatedInput, TextArea } from '../../customcontrols';
import history from '../../../utils/history';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';
interface PropsTypes {
  quoteSummary: QuoteSummaryType;
  DeleteModalFn: any;
  toastFn: any;
  notSubmitQuote?: any;
  pagefor: string;
}
const DeclineModal = (props: PropsTypes) => {
  const { quoteSummary, DeleteModalFn, toastFn, notSubmitQuote, pagefor } = props;

  const { summary, quotereplyinfo } = quoteSummary;
  const { quoteId } = summary;

  const { preparedBy } = quotereplyinfo;

  const [preparer, setPreperar] = useState<string>(preparedBy);
  const [reason, setReason] = useState<string>('');

  const declineQuote = (type: number) => {
    if (type === 2) setReason('');
    notSubmitQuote({ quoteId, reason, preparedBy: preparer });
    DeleteModalFn();
    if (pagefor === 'response') {
      history.goBack();
    }
    //toastFn('success', 'Your quote has been Declined', 'QUOTE_SUMMARY_NOT_SUBMIT_QUOTE.SUCCESS', 4000);
  };

  return (
    <>
      <div className='bg-sidebarGray p-3 mb-3'>
        <p>
          Please note that you will continue to receive notifications for request for quotes in the
          future.
        </p>
        <p>
          If you change your mind and decide to submit a quote, you may submit your quote by
          clicking the link in your quote notification email - as long as the Quote Due Date has not
          passed.
        </p>
      </div>
      <p>
        Some Agencies require that you provide a reason for not quotting. Please enter your reason
        for not quoting
      </p>
      <TextArea
        type='text'
        label='Reason'
        value={reason}
        handleChange={(name: string, value: string) => setReason(value)}
        classNames='class'
        remainShow={true}
        maxLength={300}
      />
      <DeprecatedInput
        type='text'
        label='Preparer'
        value={preparer}
        handleChange={(name: string, value: string) => setPreperar(value)}
        classNames='class'
      />
      <div className='clearfix text-center'>
        <Buttons
          classNames='bttn-primary  mt-2 d-block float-none mx-auto w-75'
          text='Submit With Reason'
          title='Submit Reason'
          type='button'
          disable={!reason}
          onClick={() => declineQuote(1)}
        />
        <Buttons
          classNames='bttn-primary  mt-2 d-block float-none mx-auto w-75'
          text='Submit Without Reason'
          title='Submit Reason'
          type='button'
          onClick={() => declineQuote(2)}
        />
        <Buttons
          classNames='bttn-secondary mt-2 w-75'
          text='Cancel'
          title='Cancel'
          type='button'
          onClick={DeleteModalFn}
        />
      </div>
    </>
  );
};

export default memo(DeclineModal);
