/**
 * Get the file type, uppercase
 * @param testString: string // example: 'image/jpg'
 * @returns string
 */
export function getDocFormatType(testString: string) {
  if (!testString) {
    return '';
  }
  return testString.substring(testString.lastIndexOf('.') + 1).toLocaleUpperCase();
}
