import { DSLink } from '@demandstar/components/link';
import React from 'react';

import { formatPhone, isValidPhone } from '../../../../utils/helpers/';

export interface PhoneProps {
  children: string;
}

/**
 * Wrap this around a numeric phone number and it will format
 * the phone number and turn it into a phone link (if the phone is valid).
 * Does nothing for invalid phone numbers.
 * @example <Phone>4258675309</Phone>
 */
export const Phone = (props: PhoneProps) => {
  const { children } = props;

  if (isValidPhone(children)) {
    return <DSLink href={`tel:${children}`}>{formatPhone(children)}</DSLink>;
  }

  return <>{children}</>;
};
