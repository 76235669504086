import styled from 'styled-components';

import { Colors, FontSize, FontWeight } from '../../../shared/styles';
import { DeprecatedLink } from '../../../components/common/controls/text/BaseLink';
import { FontFamily } from '@demandstar/components/styles';

export const cellStyleBase = `
  line-height: 2rem;
  color: ${Colors.grayDark};
  font-family: ${FontFamily.Header};
  font-size: 1rem;
  font-weight: normal;
  padding: 0.5rem 1rem;
  cursor: default;
`;

export const headerCellStyleBase = `
  line-height: 2rem;
  color: ${Colors.grayDark};
  font-family: ${FontFamily.Header};
  font-size: ${FontSize.base};
  font-weight: 600;
  padding: 0.25rem 1rem;
  cursor: default;
`;

const rowStyles = `
  padding: 1rem 0;
  border-width: 0 0 .0625rem;
  background: #fff;
`;

export const TableStyleWrapper = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    width: 100%;

    tr {
      ${rowStyles}
      td {
        :first-child {
          padding-left: 0;
        }
      }
      td {
        :last-child {
          padding-right: 0;
        }
      }
    }

    th {
      ${headerCellStyleBase}
    }

    td {
      ${cellStyleBase}
    }
  }
`;

/**
 * Table cell link text
 */

const linkTextAttributes = `
 flex: 1 1;
 font-size: 1.125rem;
 line-height: 1.5rem;
`;

export const NoLinkText = styled.span`
  ${linkTextAttributes}
  padding-top: 0.4rem;
  font-family: ${FontFamily.Base};
  font-weight: ${FontWeight.bold};
  color: ${Colors.grayMediumDark};
`;

export const LinkText = styled(DeprecatedLink)`
  ${linkTextAttributes}
`;

export const HeaderIconWrapper = styled.span`
  display: inline-block;
  padding-left: 0.5rem;
  color: ${Colors.action};
`;
