import { createActionTypes, createNoLoadActionTypes } from './utils';

export const SET_QUOTE_SUMMARY_DETAILS = createNoLoadActionTypes('SET_QUOTE_SUMMARY_DETAILS');
export const RESET_QUOTE_SUMMARY_DETAILS = createNoLoadActionTypes('RESET_QUOTE_SUMMARY_DETAILS');
export const QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS = createNoLoadActionTypes(
  'QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS',
);
export const QUOTE_SUMMARY_GET_QUOTE_STATUS_OPTIONS = createNoLoadActionTypes(
  'QUOTE_SUMMARY_GET_QUOTE_STATUS_OPTIONS',
);
export const LOAD_QUOTE_SUMMARY_DOCUMENTS = createNoLoadActionTypes('LOAD_QUOTE_SUMMARY_DOCUMENTS');
export const QUOTE_SUMMARY_GET_QUOTE_RESPONSES = createNoLoadActionTypes(
  'QUOTE_SUMMARY_GET_QUOTE_RESPONSES',
);
export const QUOTE_SUMMARY_GET_RESPONSE_STATUS = createNoLoadActionTypes(
  'QUOTE_SUMMARY_GET_RESPONSE_STATUS',
);
export const QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER = createNoLoadActionTypes(
  'QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER',
);
export const QUOTE_SUMMARY_GET_STATISTICS = createNoLoadActionTypes('QUOTE_SUMMARY_GET_STATISTICS');
export const QUOTE_SUMMARY_NOT_SUBMIT_QUOTE = createNoLoadActionTypes(
  'QUOTE_SUMMARY_NOT_SUBMIT_QUOTE',
);
export const GET_QUOTE_SUMMARY_QUOTE_VIEW = createNoLoadActionTypes('GET_QUOTE_SUMMARY_QUOTE_VIEW');
export const GET_QUOTE_SUMMARY_QUOTES_REPLY = createNoLoadActionTypes(
  'GET_QUOTE_SUMMARY_QUOTES_REPLY',
);
export const GET_QUOTE_COMMODITYCODES = createNoLoadActionTypes('GET_QUOTE_COMMODITYCODES');
export const QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN = createNoLoadActionTypes(
  'QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN',
);
export const QUOTE_SUMMARY_CHANGE_QUOTE_STATUS = createActionTypes(
  'QUOTE_SUMMARY_CHANGE_QUOTE_STATUS',
);
export const QUOTE_SUMMARY_CHANGE_QUOTE_DUEDATE = createActionTypes(
  'QUOTE_SUMMARY_CHANGE_QUOTE_DUEDATE',
);
export const QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO = createNoLoadActionTypes(
  'QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO',
);
export const QUOTE_SUMMARY_SEND_REWARD_MAIL = createActionTypes('QUOTE_SUMMARY_SEND_REWARD_MAIL');
export const GET_QUOTE_SUMMARY = createActionTypes('GET_QUOTE_SUMMARY');
export const QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY = createActionTypes(
  'QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY',
);
export const RESET_QUOTE_SUMMARY = createActionTypes('RESET_QUOTE_SUMMARY');
export const DELETE_LINE_ITEM = createActionTypes('DELETE_LINE_ITEM');
