import React from 'react';

import { setWizardStatus, scrollToTop } from '../../../utils/helpers';
import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import { Buttons } from '../../customcontrols';

interface RegistrationWizardButtonsProps {
  agencyregistration?: agencyRegistrationParamType;
  setAgencyRegistrationDetails?: any;
}

export const RegistrationWizardButtons = (props: RegistrationWizardButtonsProps) => {
  const { agencyregistration, setAgencyRegistrationDetails } = props;
  const { agencyRegistrationWizard = [], wizardcurrentpage = 0 } = agencyregistration || {};

  const moveToPrevious = () => {
    scrollToTop();
    setAgencyRegistrationDetails({
      agencyRegistrationWizard: setWizardStatus(
        agencyRegistrationWizard,
        wizardcurrentpage - 1 || 1,
      ),
      wizardcurrentpage: wizardcurrentpage - 1,
    });
  };

  const moveToNext = () => {
    scrollToTop();
    setAgencyRegistrationDetails({
      agencyRegistrationWizard: setWizardStatus(
        agencyRegistrationWizard,
        wizardcurrentpage + 1 || 1,
      ),
      wizardcurrentpage: wizardcurrentpage + 1,
    });
  };

  const buttonName = wizardcurrentpage === 6 ? 'Submit' : 'Next';

  return (
    <div
      className={wizardcurrentpage !== 1 ? 'd-flex justify-content-between' : 'd-block'}
      data-testid='agency.registration.form.buttons'
    >
      {wizardcurrentpage !== 1 ? (
        <Buttons
          classNames='bttn-secondary mt-5 float-left'
          text='Previous'
          onClick={moveToPrevious}
          type='button'
        />
      ) : null}
      {wizardcurrentpage === 4 ? (
        <Buttons
          classNames='bttn-primary mt-5 float-right'
          text='Next'
          title='Next'
          type='button'
          onClick={moveToNext}
        />
      ) : (
        <Buttons
          classNames='bttn-primary mt-5 float-right'
          text={buttonName}
          title={buttonName}
          type='submit'
        />
      )}
    </div>
  );
};
