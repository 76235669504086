import React, { memo } from 'react';

function AddingUser() {
  return (
    <>
      <p>Adding users is much more intuitive with DemandStar 2020 . Simply follow these steps: </p>
      <ol>
        <li>Login to your account</li>
        <li>
          {' '}
          Scroll over the upper right-hand corner, click on the little down arrow next to your name
        </li>
        <li> Click on Account</li>
        <li> Across the top you will see four tabs, click on “User Accounts”</li>
        <li>
          {' '}
          Scroll down to the bottom of the page, and click the big teal button that says “Add User”{' '}
        </li>
        <li> Fill-in the requested information</li>
        <li> Scroll to the bottom</li>
        <li>
          <strong className='highlight'> Toggle on the switch to “Receive notifications” </strong>
        </li>
        <li> Click “save changes”</li>
        <li> User-name now appears under “User Accounts”. </li>
      </ol>
    </>
  );
}

export default memo(AddingUser);
