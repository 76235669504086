import { connect, ConnectedProps } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { useRecoilStateLoadable, useRecoilValue } from 'recoil';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';

import * as actionCreators from '../../../store/actions';
import { MemberTypeName } from '../../../types/dashboard';
import { ReduxAction } from '../../../store/actions/utils';
import { pluralize } from '../../../utils/helpers';
import { SiblingProductPayload } from '../../../types/actiontypedef';
import history from '../../../utils/history';
import PurchasePlanCard, { Card, HandlerFunction } from '../PurchasePlanCard/PurchasePlanCard';
import { BidsSummaryPayload } from '../../../store/actions/bidsSummary';
import { constants } from '../../../utils/settings';
import { allProductsState } from '../../../store/recoil/productState';
import { agencyStateCountyState, productPrices } from '../../../store/recoil/agencyState';

// TYPES
type CardName =
  | 'singleBid'
  | 'freeAgency'
  | 'county'
  | 'freeSingleBid'
  | 'additionalCounty'
  | 'countyGeneric';

type Supplier = 'basic' | 'basicPay' | 'freeAgency' | 'freeAgencyPay' | 'paid' | 'paidPay';

export interface BidCard extends Card {
  name: CardName;
}

interface ReduxState<T> {
  bidssummary: {
    results: { bidName: string; bidCounty: string; memberID: number };
    purchaseInfo: { isPurchased: boolean };
  };
  memberinfo: { mtn: MemberTypeName };
  shared: { siblingProducts: T[] };
  accountinfo: { agencySelection: boolean };
}

// SORTING
// define a total order relation for card names so we can sort the cards.
// The "greatest" card will be rightmost one in the ui, and will have a
// different action button color.
const lessThanRelation: [CardName, CardName][] = [
  ['singleBid', 'freeAgency'],
  ['singleBid', 'county'],
  ['singleBid', 'freeSingleBid'],
  ['singleBid', 'additionalCounty'],
  ['freeAgency', 'county'],
  ['freeAgency', 'freeSingleBid'],
  ['freeAgency', 'additionalCounty'],
  ['freeSingleBid', 'county'],
  ['county', 'additionalCounty'],
  ['freeSingleBid', 'additionalCounty'],
];

function isLessThan(x: CardName, y: CardName): boolean {
  return !!lessThanRelation.find(rel => rel[0] === x && rel[1] === y);
}

type SortResult = -1 | 0 | 1;

function sortCardNames(x: CardName, y: CardName): SortResult {
  if (isLessThan(x, y)) return -1;
  if (isLessThan(y, x)) return 1;
  return 0;
}

function sortCards(x: BidCard, y: BidCard): SortResult {
  return sortCardNames(x.name, y.name);
}

function swapThese(testcards: BidCard[]) {
  //doesn't need to happen if there are fewer than 3 cards in the array
  if (testcards.length >= 3) {
    const lastIndex = testcards.length - 1;
    const temp = testcards[lastIndex];
    testcards[lastIndex] = testcards[1];
    testcards[1] = temp;
  }
}

export function formatCounty(county = ''): string {
  if (county.toLowerCase().includes('county')) {
    return county;
  } else {
    return `${county} County`;
  }
}

export function pricePerMonth(price: number) {
  return (price / 12).toFixed(2);
}

// DATA
export function generateCards<T>(
  county: string,
  productPrices: { countyPrice: number; statePrice: number; nationalPrice: number },
  download: HandlerFunction,
  ordersPage: HandlerFunction,
  price: number,
  toggleModal: HandlerFunction,
  siblingProducts: T[] = [],
  subscriptionPage: HandlerFunction,
): BidCard[] {
  const productCount = siblingProducts.length;
  const pluralizedGov = pluralize(siblingProducts.length, 'government', 'governments');
  const cards: BidCard[] = [
    {
      name: 'singleBid',
      title: 'Single Bid Package',
      subtitle: 'Purchase documents only for this bid and receive:',
      bullets: [
        'All documents in this single bid package',
        'As many downloads of this package as you like',
      ],
      price: 5,
      action: 'Download Bid Documents',
      handleClick: ordersPage,
    },
    {
      name: 'freeAgency',
      title: 'Free Agency Account',
      subtitle: 'Subscribe to one government agency and receive:',
      bullets: [
        'Free bid documents from that agency',
        'Instant e-mail notifications for future bid opportunities',
        'Notifications customized to your business',
      ],
      action: 'Choose your Agency',
      price: 0,
      handleClick: toggleModal,
    },
    {
      name: 'county',
      title: 'County Subscription',
      subtitle: `Subscribe to all governments in ${formatCounty(county)} and receive:`,
      bullets: [
        'This bid package for free',
        `Free access to all bid documents from any ${formatCounty(county)} government`,
        'Instant e-mail notifications for future bid opportunities',
        'Notifications customized to your business',
      ],
      action: 'Subscribe & Save',
      price: productPrices ? `${pricePerMonth(productPrices.countyPrice)}` : '',
      message: 'per month, billed annually',
      handleClick: subscriptionPage,
    },
    {
      name: 'freeSingleBid',
      title: 'Single Bid Package',
      subtitle: 'Download documents only for this bid and receive:',
      bullets: [
        'All documents in this bid package',
        'You may download these documents again at any time.',
      ],
      action: 'Free document download',
      price: 0,
      handleClick: download,
    },
    {
      name: 'additionalCounty',
      title: 'Additional County Subscription',
      subtitle: `Subscribe to all ${productCount} ${pluralizedGov} in ${formatCounty(
        county,
      )} and receive:`,
      bullets: [
        'This bid package for free',
        `Free access to all bid documents from any ${formatCounty(county)} government`,
        'Instant e-mail notifications for future bid opportunities',
        'Notifications customized to your business',
      ],
      action: 'Subscribe & Save',
      price: productPrices ? `${pricePerMonth(productPrices.countyPrice)}` : '',
      message: 'Per month, billed annually',
      handleClick: subscriptionPage,
    },
  ];

  return cards.map(card => {
    return {
      ...card,
      handleLoad: () => {
        ReactGA.event({
          category: 'BidInterstitial',
          action: `Viewed interstitial card ${card.name}`,
          label: 'View Event',
        });
      },
      handleClick: (e: React.MouseEvent) => {
        ReactGA.event({
          category: 'BidInterstitial',
          action: `Clicked interstitial card ${card.name}`,
          label: 'Click Event',
        });
        card.handleClick(e);
      },
    };
  });
}

const supplierCards: { [K in Supplier]: CardName[] } = {
  basicPay: ['singleBid', 'freeAgency', 'county'],
  freeAgency: ['freeSingleBid', 'county'],
  freeAgencyPay: ['singleBid', 'county'],
  paidPay: ['singleBid', 'additionalCounty'],
  paid: [], // skip page altogether
  basic: [], // skip page
};

export function getKey(memberTypeName: MemberTypeName, isPurchased: boolean): Supplier {
  switch (memberTypeName) {
    case 'Paid':
      return isPurchased ? 'paid' : 'paidPay';
    case 'Free Agency':
      return isPurchased ? 'freeAgency' : 'freeAgencyPay';
    case 'Basic':
      return isPurchased ? 'basic' : 'basicPay';
    default:
      return 'basic';
  }
}

type PropsFromRedux = Partial<ConnectedProps<typeof connector>>;

type Props = PropsFromRedux & {
  agencySelection?: boolean;
  bidId: string;
  bidCounty?: string;
  bidOwnerId?: number;
  bidsSummary: (payload: BidsSummaryPayload) => ReduxAction;
  county: string;
  setAccountInfoDetails?: (payload: Record<string, unknown>) => ReduxAction;
  downloadAllDocument: (payload?: Record<string, unknown>) => ReduxAction;
  getSiblingProducts: (payload: SiblingProductPayload) => ReduxAction;
  isPurchased: boolean;
  memberInfo: (payload?: Record<string, unknown>) => ReduxAction;
  memberTypeName: MemberTypeName;
  siblingProducts: Record<string, unknown>[];
  toggleModal: () => void;
  productPrices: { countyPrice: number; statePrice: number; nationalPrice: number };
  trackAmplitudeUserActions: (payload?: Record<string, unknown>) => ReduxAction;
};

function BidPlans(props: Props) {
  const {
    bidCounty,
    bidId,
    bidOwnerId,
    bidsSummary,
    county,
    downloadAllDocument,
    getSiblingProducts,
    isPurchased,
    memberTypeName,
    siblingProducts,
    toggleModal,
    trackAmplitudeUserActions,
    productPrices: prodPrices,
  } = props;

  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const agencyStateCounty = useRecoilValue(agencyStateCountyState);
  const prodMinPrice = useRecoilValue(productPrices);
  const [countyPrice, setCountyPrice] = useState(0);

  const prodMinimumPrices = prodPrices ? prodPrices : prodMinPrice;

  const key = getKey(memberTypeName, isPurchased);

  useEffect(() => {
    if (bidId) bidsSummary({ bidId: bidId, otherapis: true });
  }, [bidId, bidsSummary]);

  useEffect(() => {
    if (key === 'paidPay' && bidOwnerId) {
      getSiblingProducts({ memberId: bidOwnerId });
    }
  }, [getSiblingProducts, bidOwnerId, key]);

  useEffect(() => {
    if (allProducts.state === 'hasValue' && county) {
      let countyProduct = allProducts.contents.filter(
        product => product.productType === 'CT' && product.productName === county,
      );
      /**
       * Edge case hack:
       * - Existing page does not have the state value with the county info for the bid
       * - agencyStateCounty has the state, but it isn't set if the 'Download Bid Documents' button is clicked too quickly; this is normally not an issue
       * - State and county should be compared together because we know county name is not unique
       * - This is not fail safe, so if we have a scenario where we have multiple counties with same name, but no information on the state, it will use the first county in the array
       */
      if (countyProduct.length > 1 && agencyStateCounty.state) {
        countyProduct = countyProduct.filter(
          county => county.parentName === agencyStateCounty.state,
        );
      }
      setCountyPrice(countyProduct[0].price);
    }
  }, [agencyStateCounty, allProducts, county]);

  function download() {
    downloadAllDocument({ id: bidId, type: 'Bid' });
  }

  function ordersPage() {
    history.push({
      pathname: `/suppliers/bids/${bidId}/order`,
    });
  }

  function subscribePage() {
    trackAmplitudeUserActions({
      title: 'upgrade - click interstitial',
      desc: 'Click on County Subscription',
      data: {
        'Product Recommendation': bidCounty,
        'Source (Agency)': bidOwnerId,
        Source: `${constants.networkUrl}/suppliers/bids/${bidId}/details`,
      },
    });
    history.push({
      pathname: '/subscription/renewal',
    });
  }

  const allCards: BidCard[] = generateCards(
    county,
    prodMinimumPrices,
    download,
    ordersPage,
    countyPrice,
    () => toggleModal(),
    siblingProducts,
    subscribePage,
  );
  const cardsForUser = supplierCards[key];
  const cards = allCards.filter(card => cardsForUser.includes(card.name)).sort(sortCards);

  //move the 'greatest' card into the second position in the array
  swapThese(cards);

  return (
    <div className='row card-row'>
      {cards.map((card, idx) => (
        <PurchasePlanCard key={card.name} card={card} isFeatured={idx === 1} />
      ))}
    </div>
  );
}

const connector = connect(
  (state: ReduxState<Record<string, unknown>>) => ({
    county: state?.bidssummary?.results?.bidCounty,
    bidOwnerId: state?.bidssummary?.results?.memberID,
    bidCounty: state?.bidssummary?.results?.bidCounty,
    isPurchased: state?.bidssummary?.purchaseInfo?.isPurchased || false,
    memberTypeName: state?.memberinfo?.mtn,
    siblingProducts: state?.shared?.siblingProducts || [],
  }),
  dispatch => {
    const {
      bidsSummary,
      memberInfo,
      getSiblingProducts,
      downloadAllDocument,
      trackAmplitudeUserActions,
    } = bindActionCreators({ ...actionCreators }, dispatch);
    return {
      bidsSummary,
      memberInfo,
      getSiblingProducts,
      downloadAllDocument,
      trackAmplitudeUserActions,
    };
  },
);

export default connector(memo(BidPlans));
