import React from 'react';

import { SvgIcon, SvgIconProps } from './SvgIcon';

/**
 *
 * @param props: SvgIconProps
 * @returns
 */
export const SortIconDescending = ({
  color,
  height,
  onClick,
  viewBox = '0 0 27 27',
  width,
}: SvgIconProps) => (
  <SvgIcon height={height} onClick={onClick} viewBox={viewBox} width={width}>
    <path fill={color} d='M19 7H22L18 3L14 7H17V21H19M2 17H12V19H2M6 5V7H2V5M2 11H9V13H2V11Z' />
  </SvgIcon>
);
