import React, { memo } from 'react';

function TipsAndTricks() {
  return (
    <>
      <h5>Research Previous Awards for Pricing Qualified Suppliers </h5>
      <p>
        Go back several years. This will give you an idea on where to bid and make sure you
        don&#39;t leave any money on the table! This can give you a pretty good idea at where to
        price yourself to win this business. You don&#39;t want to underbid in 2019 by coming in at
        $50,000, and you don&#39;t want to overbid by coming in at $100,000.{' '}
      </p>
      <p>
        <b>
          <i className='mdi mdi-information-outline mdi-24px mr-1' />
          Example
        </b>
        <br />
        Every two years, the City releases a bid for grounds keeping services. In 2013, The job was
        awarded for $60,000, in 2015 the job was awarded for $70,000 And in 2017, the job was
        awarded for $75,000.
      </p>
      <hr />
      <h5>Know your Competition </h5>
      <p>
        While looking at bids, you&#39;ll start to understand who is bidding on which jobs. The
        government agencies that post bids want more competition. Especially if your business is
        minority/woman/veteran owned.{' '}
      </p>
      <hr />
      <h5>Build Templates</h5>
      <p>
        By doing the research above, you will see what language has worked in the past. You can use
        this language to build a library that you can refer to often, which helps you save time (and
        money). Remember: there are 90,000+ different local governments, but they all basically need
        the same goods and services.
      </p>
      <hr />
      <h5>Only Bid on What You Can Deliver</h5>
      <p>
        Everyone has heard &#34;you only get one chance to make a first impression&#34;. The same is
        true for your business. Also: government buyers talk to each other.
      </p>
      <hr />
      <h5>Start Small </h5>
      <p>
        Focus on winning smaller jobs when you start out. This will teach you valuable lessons that
        will help you win bigger, more valuable jobs later, setting you up for success as you build
        your business. Consider working as a subcontractor for a General Contractor or another
        supplier in the beginning while you are learning the ropes.
      </p>
      <hr />
      <h5>Mix it Up</h5>
      <p>
        Federal, state, city and even other contractors, as well as your own clients, are all
        potential places to get into government work.
      </p>
      <hr />
      <h5>Know the Rules</h5>
      <p>
        Really read and understand the bid, the process, and how to submit it. You don&#39;t want to
        spend hours putting together the world&#39;s greatest bid package, only to find out you
        didn&#39;t submit it correctly, on time, to the right place or in the wrong file format!
      </p>
      <hr />
      <h5>Build on your Successes</h5>
      <p>
        Every bid you win helps you win the next bid. Think of the score sheet included in some
        RFP&#39;s, where they will give you points based on your past experiences and other agencies
        you&#39;ve worked with.
      </p>
      <p>
        {' '}
        <b>
          <i className='mdi mdi-information-outline mdi-24px mr-1' />
          PRO TIP{' '}
        </b>
        <br />
        Price is not always the deciding factor.{' '}
      </p>
      <hr />
      <h5>Look for Trigger Events</h5>
      <p>Get a head start by looking ahead</p>
      <p>
        <b>
          {' '}
          <i className='mdi mdi-information-outline mdi-24px mr-1' />
          Example
        </b>
        <br />
        If the City releases a bid for the architectural design of a new library, you can be sure
        that there will be bids coming for related products in the coming months to build out the
        library.
      </p>
      <p>
        <b>
          {' '}
          <i className='mdi mdi-information-outline mdi-24px mr-1' />
          Example
        </b>
        <br />
        You are a lighting company and instead of waiting to see which government agencies are
        looking to buy lightbulbs, look for agencies doing energy audits or assessments. This is
        usually a trigger for agencies to use the audit findings to bring their building up to
        current energy standards.
      </p>
      <hr />
      <h5>Be Patient </h5>
      <p>
        There is a beginning date, and an end date to bidding and winning government jobs. The
        process takes time.{' '}
      </p>

      <p>
        <b>
          {' '}
          <i className='mdi mdi-information-outline mdi-24px mr-1' />
          PRO TIP{' '}
        </b>
        <br />
        Automate! Connect directly with government agencies near you and save time and money by
        getting bids delivered right to your inbox based on commodity code that you select.
      </p>
    </>
  );
}

export default memo(TipsAndTricks);
