import React from 'react';
import ProductList from './productlist';
import { Buttons } from '../../customcontrols/index';

function ManageProducts() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='colWrapper clearfix '>
              <h1 className='arrowWrapper'>Products</h1>
              <Buttons
                title='Add Product'
                text='Add Product'
                classNames='bttn-secondary small float-right'
              />
              <ProductList />
              {/* <AddEditProduct /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageProducts;
