// TOREFACTOR

import React from 'react';
interface ButtonProps {
  text: string | JSX.Element | (string | JSX.Element)[]; // let's convert this to just a children attribute someday. - DC
  classNames?: string; // also, I'd love for this to just be className. - DC
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disable?: boolean | string | undefined | Record<string, unknown>; // let's change this to 'disabled'
  type?: 'submit' | 'reset' | 'button';
  icon?: string;
  title?: string;
  id?: string;
  testid?: string;
}

// TODO: fix the remaining notes in this file. My reason for not doing so right now is just that
//       right now, my branch has a lot of new files in it, and I think things could get messy.
//       I'd rather do a big find/replace of names when I'm not in the middle of a larger project - DC

function Buttons(props: ButtonProps) {
  // and I'd like for this to be just Button - DC
  return (
    <>
      <button
        className={props.classNames}
        name={props.name}
        onClick={props.onClick}
        disabled={props.disable ? true : false}
        title={props.title}
        type={props.type || 'button'}
        id={props.id}
        data-testid={props.testid}
      >
        {props.icon && <i className={'mr-2 mdi ' + props.icon} />}
        {props.text}
      </button>
    </>
  );
}

export default Buttons;
