import React, { memo, useState } from 'react';

interface PropsTypes {
  handleChange?: any;
  parentClass?: string;
  label?: string;
  iconType?: string;
  type?: any;
  classNames?: string;
  name?: string;
  placeholder?: string;
  value?: any;
}

const Autocomplete = (props: PropsTypes) => {
  const {
    handleChange,
    parentClass = '',
    label = '',
    iconType = '',
    type = '',
    classNames = '',
    name = '',
    placeholder = '',
    value = '',
  } = props;
  const [addClass, setaddClass] = useState(false);

  const toggle = () => {
    setaddClass(!addClass);
  };

  const onInputChange = (event: any) => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  return (
    <div className={'inputWrapper clearfix ' + parentClass} onClick={toggle}>
      <div>
        <label>{label}</label>
        {iconType === 'filter' ? (
          <i className='mdi mdi-filter-outline mt-1' />
        ) : (
          <i className='mdi mdi-magnify mt-1' />
        )}
        <input
          type={type}
          className={classNames}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onInputChange}
        />
      </div>
      <div className='errorMsg'></div>
    </div>
  );
};

export default memo(Autocomplete);
