import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { convertStringToNumber } from '../../utils/string';
import { defaultNumericId } from '../constants';
import { selectedBidIdState } from '../../store/recoil/bidState';
import { useRouter } from './useRouter';

/**
 * Helper fn to save a step when reading/writing to `selectedBidIdState`,
 * @returns
 */
export function useSelectedBidId() {
  const [selectedBidId, setSelectedBidId] = useRecoilState(selectedBidIdState);
  function resetSelectedBidId() {
    setSelectedBidId(defaultNumericId);
  }
  return { resetSelectedBidId, selectedBidId, setSelectedBidId };
}

// TODO: Manually merge this when feature/award-bid is merged
/**
 * Call `useEffect`
 * to get the `bidId` value via the `useRouter`
 * and pass it to `setSelectedBidId`.
 * @returns
 */
export function useSetSelectedBidIdFromRoute() {
  const { routerParams } = useRouter();
  const { bidId } = routerParams;
  const { setSelectedBidId } = useSelectedBidId();
  useEffect(() => {
    setSelectedBidId(convertStringToNumber(bidId));
  }, [bidId, setSelectedBidId]);
}
