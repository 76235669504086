import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function getBids(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_RESULT, payload);
}

export function getBidsDetails(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_LIST_ITEMS_DATA, payload);
}

export function getBidsWatchDetails(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_LIST_ITEMS_WATCH_DATA, payload);
}

export function bidsPageChange(payload: actionPayloadTypes) {
  return action(actionTypes.BIDS_PAGE_CHANGE, payload);
}

export function bidsChangeSorting(payload: actionPayloadTypes) {
  return action(actionTypes.BID_LIST_SORTING, payload);
}

export function bidsFilterSuggest(payload: actionPayloadTypes) {
  return action(actionTypes.BID_LIST_FILTER_SUGGEST, payload);
}

export function bidListFilterChange(payload: actionPayloadTypes) {
  return action(actionTypes.BID_LIST_FILTER_CHANGE, payload);
}

export function bidListClearFilter(payload: actionPayloadTypes) {
  return action(actionTypes.BID_LIST_CLEAR_FILTER, payload);
}

export function bidListSearchClick(payload: actionPayloadTypes) {
  return action(actionTypes.BID_LIST_SET_FILTER, payload);
}

export function bidWatchClick(payload: actionPayloadTypes) {
  return action(actionTypes.BID_WATCH, payload);
}

export function bidListLoadFilter(payload: actionPayloadTypes) {
  return action(actionTypes.BID_LIST_LOAD_FILTERS, payload);
}

export function deleteBid(payload: { bidId: number | '' }) {
  // TOREFACTOR: Switch to `payload?: number`
  return action(actionTypes.DELETE_BID, payload);
}

export function setBidDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_BID_DETAILS, payload);
}

export function resetBids(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_BIDS, payload);
}

export function getConditionspending(payload: actionPayloadTypes) {
  return action(actionTypes.GET_BIDS_CONDITION_SPENDING, payload);
}
