import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function getPaymentCardDetails(payload: actionPayloadTypes) {
  return action(actionTypes.GET_PAYMENT_CARD_DETAILS, payload);
}

export function setPaymentFormDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_PAYMENT_FORM_DETAILS, payload);
}

export function submitEbitPaymentCard(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_EBITS_PAYMENT_CARD, payload);
}

export function submitEbitBillingAddress(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_EBITS_CHANGE_BILLING_ADDRESS, payload);
}

export function submitEbitPaymentMethod(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_EBITS_PAYMENT_METHOD, payload);
}

export function resetBidspayment(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_BIDS_PAYMENT, payload);
}

export function processCreditTransaction(payload: actionPayloadTypes) {
  return action(actionTypes.PROCESS_CREDIT_TRANSACTION, payload);
}
