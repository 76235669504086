import { FontFamily } from '@demandstar/components/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ActionColor, FontWeight } from '../../../../../shared/styles';

export const RouteLink = styled(Link)`
  font-family: ${FontFamily.Base};
  font-weight: ${FontWeight.bold};
  font-style: normal;
  color: ${ActionColor.secondary};
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: underline;
    color: ${ActionColor.secondaryHighlight};
  }
`;
