import * as Scroll from 'react-scroll';

/**
 * @description Use this to scroll the main window up until it reaches the top
 */
export function scrollToTop() {
  const scroll = Scroll.animateScroll;
  const options = { delay: 0, duration: 1000, smooth: 'easeOutQuart', isDynamic: true };
  scroll.scrollToTop(options);
}

/**
 * @description Opens a link in a new tab
 * @param path - relative path - use constants whenever possible
 */
export function openInNewTab(path: string) {
  window.open(path, '_blank');
}
