import React from 'react';
import { Buttons } from 'src/components/customcontrols';

export function ContractSignup(props: { getContractInterest: any }) {
  const { getContractInterest } = props;

  return (
    <>
      <h4 className='lineHR'>Coming Soon</h4>
      <div className='innerColIndent'>
        <h5>A Message from the DemandStar Product Development team</h5>
        <p>Contract Management by DemandStar is coming soon, and we need your input!</p>
        <p>
          If you are excited to help influence the design of our upcoming Contract Management tool,
          please click on the button below to sign up for our Contract Management User Group.
        </p>
        <p>
          {`We'll contact you to find out more about your requirements, and invite you to participate further surveys and
						beta tests during development.`}
        </p>
        <p>
          {`We want to thank you for using DemandStar. Your feedback and guidance helped shape the new DemandStar, and We're
						excited to continue development of new tools and products with you.`}
        </p>
        <p>
          <b>DemandStar Product Development Team</b>
          <br />
        </p>
        <div className='d-flex justify-content-center'>
          <Buttons
            onClick={() => getContractInterest({ method: 'POST' })}
            text={['I want to talk about my ', <br key='i' />, 'Contract Management needs!']}
            classNames='bttn-primary'
          />
        </div>
      </div>
    </>
  );
}
