import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { DropDownMenuItems, memberTypes, planMenus } from '../../utils/constants';
import { logOutFn, openHelpPage } from '../../utils/helpers';
import { DropdownMenu } from 'reactstrap';
import { Permission } from '../../types/auth';
import { useDSSelector } from '../../store/reducers';

const HeaderDropDown = () => {
  const auth = useDSSelector(state => state.auth);
  const { mt = '', prms = '', us = '' } = auth || {};
  const permissions = prms.replace(/ /g, '').split(',') as Permission[];

  const pointingTo =
    !mt && us && permissions.includes(Permission.Ops)
      ? '/ops'
      : mt === memberTypes.agencyBuyer
      ? '/buyers'
      : '/suppliers';

  const logOut = () => {
    const isOps = auth && !mt ? true : false;
    logOutFn(isOps);
  };

  const menuItems = DropDownMenuItems.map(item => {
    if (planMenus.includes(item.Title) && mt === memberTypes.basicSupplier) {
      return {
        ...item,
        Url: '/suppliers/plans',
      };
    } else {
      return item;
    }
  });

  return (
    <DropdownMenu>
      {menuItems.map((item, index) =>
        item.showTo.includes(mt) || item.showTo.includes('All') ? (
          <Link
            key={index}
            className='dropdown-item'
            to={item.showTo.includes('All') ? `${pointingTo}${item.Url}` : item.Url}
          >
            <i className={item.classNames} /> {item.Title}
          </Link>
        ) : null,
      )}
      {mt ? (
        <span className='staticLink dropdown-item' onClick={() => openHelpPage(mt)}>
          <i className='mdi mdi-help' />
          Help
        </span>
      ) : null}
      <span className='staticLink dropdown-item' onClick={logOut}>
        <i className='mdi mdi-logout' /> Log Out
      </span>
    </DropdownMenu>
  );
};

export default memo(HeaderDropDown);
