// TOREFACTOR

import React, { memo, useEffect, useState } from 'react';
import { Buttons } from '../../customcontrols';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { Card, CardBody, UncontrolledCollapse } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';

import CountyList from './countylist';
import StateList from './statelist';
import AgencyList from './agencylist';
import Cart from '../cart/Cart';
import CartTotal from '../cart/CartTotal';
import { memberTypes, registrationComponent } from '../../../utils/constants';
import navHistory from '../../../utils/history';
import { useRecoilState, useRecoilValue } from 'recoil';
import { prepopulatedCountiesState } from '../../../store/recoil/subscriptionState';
import { PrepopulatedCounties } from '../../../types/subscriptions';
import { agencyStateCountyState } from '../../../store/recoil/agencyState';
import { AgencyStateCounty } from '../../../types/agencyinfo';

const ChooseSubscription = (props: any) => {
  const [prepopulatedCounties, setPrepopulatedCounties] =
    useRecoilState<PrepopulatedCounties>(prepopulatedCountiesState);
  const agencyStateCounty = useRecoilValue<AgencyStateCounty>(agencyStateCountyState);
  const {
    shared,
    getSubscriptionAgencies,
    trackAmplitudeUserActions,
    setRegistrationData,
    addInCompleteRegistrationData,
    getAllProducts,
    setAccountInfoDetails,
    getSubscriptionMemberDetails,
    setSubscriptionDetails,
    calculateSubscription,
    getDynamicProductPrices,
    subscriptions,
    pagefor = '',
    registration,
    accountinfo,
  } = props;
  const { ...otherprops } = props;
  const {
    selectedState = 0,
    allProducts = [],
    products = [],
    filteredProducts = [],
    memberSubscriptionInfo = '',
    subscribedStates = [],
    subscribedCounties = [],
    subscribedCountiesGroup = [],
    subscribedNational = [],
    currentSubscribedStates = [],
    currentSubscribedCounties = [],
    currentSubscribedNational = [],
    existingSubscriptionDetails = [],
    calculatedPrice = 0,
  } = subscriptions;
  const { amplitudeData = {}, productPrices = {} } = shared || {};
  const { registrationData, isRegistrationCompleted } = registration;
  const { freeAgencies = [] } = accountinfo;
  const [confirmationPopup, toggleConfirmationPopup] = useState(false);
  let isReview = false;
  if (pagefor === 'review') {
    isReview = true;
  }
  let selectedAllProducts = [];
  const selectedProductData = allProducts.filter(
    (item: any) =>
      subscribedStates.includes(item.productId) ||
      (subscribedCounties.includes(item.productId) &&
        subscribedCountiesGroup.includes(
          `${item.productGroupId}_${item.parentId}_${item.productId}`,
        )) ||
      subscribedNational.includes(item.productId),
  );

  useEffect(() => {
    const prepopulatedAgencyCounty = allProducts.filter(
      (item: any) =>
        item.productType === 'CT' &&
        item.productName === agencyStateCounty.county &&
        item.parentName === agencyStateCounty.state,
    );

    if (prepopulatedAgencyCounty.length > 0 && prepopulatedAgencyCounty[0].parentId) {
      setPrepopulatedCounties({
        state: prepopulatedAgencyCounty[0].parentId,
        counties: [prepopulatedAgencyCounty[0].productId],
      });
    }
  }, [agencyStateCounty.county, agencyStateCounty.state, allProducts, setPrepopulatedCounties]);

  selectedAllProducts = selectedProductData.map((mItem: any) => mItem.price);
  /* const totalCartPrice =
    selectedAllProducts.length > 0
      ? selectedAllProducts.length === 1
        ? selectedAllProducts[0]
        : selectedAllProducts.reduce((a: number, b: number) => a + b)
      : 0; */
  const nationalProduct = allProducts.find((item: any) => item.productType === 'NA') || {};

  const [showAgency, setShowAgency] = useState(false);
  const [countyAgencies, setCountyAgencies] = useState([]);

  const pageFrom: string = window.location.search.replace('?upsell=', '');

  let freeAgencyName = '';
  if (pagefor === 'registration' && registrationData.freeAgency) {
    const freeAgency = freeAgencies.find(
      (item: any) =>
        item.productId === registrationData.freeAgency ||
        item.agencyId === registrationData.freeAgency,
    );
    freeAgencyName = freeAgency
      ? freeAgency.productName
        ? freeAgency.productName
        : freeAgency.agencyName
      : '';
  }

  const showAgencyModal = () => {
    setShowAgency(!showAgency);
  };

  useEffect(() => {
    if (freeAgencies.length === 0 && pagefor === 'registration') {
      getSubscriptionAgencies();
    }
  }, [freeAgencies, getSubscriptionAgencies, pagefor]);

  useEffect(() => {
    if (products.length === 0) {
      getAllProducts();
    }
  }, [getAllProducts, products.length]);

  useEffect(() => {
    getDynamicProductPrices();
  }, [getDynamicProductPrices]);

  useEffect(() => {
    if (!memberSubscriptionInfo && !pagefor && !pageFrom) {
      getSubscriptionMemberDetails();
    }
  }, [getSubscriptionMemberDetails, memberSubscriptionInfo, pageFrom, pagefor]);

  /* this is safe in its current form */
  useEffect(() => {
    if (prepopulatedCounties.state > 0) {
      setSubscriptionDetails({ selectedState: prepopulatedCounties.state });
    }
  }, [prepopulatedCounties.state, setSubscriptionDetails]);

  const openAgenciesModal = (agencies: any) => {
    setCountyAgencies(agencies);
    showAgencyModal();
  };

  const checkNationalProduct = (isChecked: any) => {
    const newnationalSelected =
      isChecked === false
        ? subscribedNational.filter((item: number) => item !== nationalProduct.productId)
        : [...subscribedNational, nationalProduct.productId];

    setSubscriptionDetails({
      subscribedNational: newnationalSelected,
      currentExpiryDate: '',
      expiryDate: '',
      calculatedPrice: 0,
    });
  };

  const upgradesubscription = (isUpgrade: boolean) => {
    calculateSubscription({ isUpgrade });
  };

  const gotoFreeAgencySelection = () => {
    if (pagefor === 'registration') {
      setRegistrationData({ currentComponent: registrationComponent.ChooseAgency });
    } else {
      setAccountInfoDetails({ agencySelection: true });
      navHistory.push('/suppliers/account/subscriptions');
    }
  };

  const toggleCollapse = (stateId: number) => {
    setSubscriptionDetails({ selectedState: stateId === selectedState ? 0 : stateId });
  };

  const skipSubscriptionOnRegistration = (subscribedData: Record<string, unknown>) => {
    const inCompletePayload = {
      email: registrationData.emailAddress,
      JsonData: {
        regData: {
          currentComponent: registrationComponent.Company,
          registrationData: { ...registrationData, subscriptions: subscribedData },
        },
      },
    };
    addInCompleteRegistrationData(inCompletePayload);
    let currentComponent = registrationComponent.Company;
    if (isRegistrationCompleted) {
      currentComponent = registrationComponent.ReviewOrder;
    }

    setRegistrationData({
      currentComponent,
      registrationData: {
        ...registrationData,
        subscriptions: subscribedData,
        subscriptionData: selectedProductData,
      },
    });
  };

  const skipFornow = (type = 'skip') => {
    if (type === 'skip') {
      setSubscriptionDetails({
        subscribedCounties: [],
        subscribedStates: [],
        subscribedNational: [],
      });
    }
    const subscribedData =
      type === 'proceed'
        ? { subscribedStates, subscribedCounties, subscribedNational, subscriptionSelected: true }
        : {};
    if (pagefor === 'registration') {
      if (type === 'skip') toggleConfirmationPopup(true);
      else skipSubscriptionOnRegistration(subscribedData);
    } else navHistory.goBack();
  };

  /** BUTTON DISPLAY CONDITIONS [ UPGRADE, RENEWAL ] **/

  // get old cart total [ oldCartTotal ]
  const totalOldProducts = [
    ...currentSubscribedStates,
    ...currentSubscribedCounties,
    ...currentSubscribedNational,
  ];
  const totalOldProductsList = allProducts.filter((item: any) =>
    totalOldProducts.includes(item.productId),
  );
  let oldCartTotal = 0;
  totalOldProductsList.map((item: any) => {
    if (item.price) {
      oldCartTotal = oldCartTotal + parseFloat(item.price);
    }
  });

  // get new cart total [ newCartTotal ]
  const totalNewProducts = [...subscribedStates, ...subscribedCounties, ...subscribedNational];
  const totalNewProductsList = allProducts.filter((item: any) =>
    totalNewProducts.includes(item.productId),
  );
  let newCartTotal = 0;
  totalNewProductsList.map((item: any) => {
    newCartTotal = newCartTotal + parseFloat(item.price);
  });

  const disabled =
    (!memberSubscriptionInfo.isRenewalAllowed && newCartTotal === 0) || newCartTotal < oldCartTotal;

  let displayUpgradeButton = memberSubscriptionInfo.isRenewalAllowed && calculatedPrice === 0;
  const displayCancelButton = memberSubscriptionInfo.isRenewalAllowed && calculatedPrice > 0;
  let displayRenewButton =
    memberSubscriptionInfo.isRenewalAllowed &&
    memberSubscriptionInfo.memberDetails.daysToRenew > 0 &&
    memberSubscriptionInfo.memberDetails.daysToRenew < 90 &&
    calculatedPrice === 0;

  const isRenewalAvail =
    memberSubscriptionInfo.isRenewalAllowed &&
    memberSubscriptionInfo.memberDetails.daysToRenew > 0 &&
    memberSubscriptionInfo.memberDetails.daysToRenew < 90;

  let displayCheckOutButton =
    !memberSubscriptionInfo.isRenewalAllowed || (calculatedPrice > 0 && !disabled);

  if (
    memberSubscriptionInfo.isRenewalAllowed &&
    memberSubscriptionInfo.memberDetails.daysToRenew > 0 &&
    memberSubscriptionInfo.memberDetails.daysToRenew < 90 &&
    currentSubscribedNational &&
    currentSubscribedNational.length > 0
  ) {
    displayUpgradeButton = false;
    displayRenewButton = true;
  }

  if (
    memberSubscriptionInfo.isRenewal &&
    currentSubscribedNational &&
    currentSubscribedNational.length === 0 &&
    subscribedNational &&
    subscribedNational.length > 0
  ) {
    displayUpgradeButton = true;
    displayRenewButton = false;
  }

  if (oldCartTotal === 0) {
    displayUpgradeButton = false;
    displayRenewButton = false;
    displayCheckOutButton = true;
  }

  selectedAllProducts = selectedProductData.map((mItem: any) => {
    const newPriceData: any = existingSubscriptionDetails.find(
      (eItem: any) => eItem && mItem && eItem.productId === mItem.productId,
    );
    return newPriceData && !displayRenewButton ? newPriceData.price : mItem.price;
  });

  const totalCartPrice =
    selectedAllProducts.length > 0
      ? selectedAllProducts.length === 1
        ? selectedAllProducts[0]
        : selectedAllProducts.reduce((a: number, b: number) => a + b)
      : 0;

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='colWrapper subscriptionWrapper'>
              <div className='row'>
                <div className='col'>
                  {(memberSubscriptionInfo.memberDetails &&
                    memberSubscriptionInfo.memberDetails.freeAgencyName) ||
                  freeAgencyName ? (
                    <p className='bg-coolgray50 py-3 px-3 firaMedium'>
                      You have chosen{' '}
                      <span className='staticTxt'>
                        {freeAgencyName
                          ? freeAgencyName
                          : memberSubscriptionInfo.memberDetails.freeAgencyName}
                      </span>{' '}
                      as your free agency. Add additional agencies below for as low as $
                      {productPrices ? productPrices.countyPrice : 0.0} a year per County. Statewide
                      and National subscriptions available.
                    </p>
                  ) : (
                    <p className='bg-coolgray50 py-3 px-3 firaMedium'>
                      You did not select a free agency, you’ll miss out on free alerts when an
                      agency posts a bid relevant to you. Go back and{' '}
                      <span className='staticLink' onClick={() => gotoFreeAgencySelection()}>
                        choose your free agency
                      </span>
                      .
                    </p>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='d-flex justify-content-center mb-4'>
                    <div
                      className='bttn-accent w-100 position-relative subNationalBtn'
                      onClick={() =>
                        checkNationalProduct(subscribedNational.length > 0 ? false : true)
                      }
                    >
                      {subscribedNational.length > 0 ? (
                        <small className='position-absolute'>
                          <span>
                            Remove from Cart <i className='mdi mdi-minus-circle' />
                          </span>
                          <br />
                          All Counties in Every State for just ${nationalProduct.price}
                        </small>
                      ) : (
                        <small className='position-absolute'>
                          <span>
                            Add to Cart <i className='mdi mdi-plus-circle' />
                          </span>
                          <br />
                          All Counties in Every State for just ${nationalProduct.price}
                        </small>
                      )}
                      <h3 className='mb-0'>Go Nationwide!</h3>
                      <p className='mb-0'>
                        Subscribe to every agency in every county of every state in the nation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <h1 className='lineHR'>Choose Your Counties</h1>
                  {(memberSubscriptionInfo.memberDetails &&
                    memberSubscriptionInfo.memberDetails.freeAgencyName) ||
                  freeAgencyName ? (
                    <p>
                      Now that you've chosen{' '}
                      {freeAgencyName
                        ? freeAgencyName
                        : memberSubscriptionInfo.memberDetails.freeAgencyName}{' '}
                      as your free agency, add more counties to grow your list of potential clients
                      and receive notifications about more bid opportunities. Each county you choose
                      will add all the agencies in that county that are on DemandStar to your
                      network.
                    </p>
                  ) : (
                    <p>
                      Add more counties to grow your list of potential clients and receive
                      notifications about more bid opportunities. Each county you choose will add
                      all the agencies in that county that are on DemandStar to your network.
                    </p>
                  )}
                  <div className='subProductWrapper row'>
                    {filteredProducts.map((item: any) => {
                      const indeterminateCondition = item.products.filter((pItem: any) =>
                        subscribedCounties.includes(pItem.productId),
                      );
                      return (
                        <>
                          {/**
                           * FIXME: This mapped component requires a key.
                           * Type out filtered product and rewrite above <> to
                           * <React.Fragment key={item.fooUniqueId}>
                           */}
                          <StateList
                            productId={item.productId}
                            selectedState={selectedState}
                            onChangeSelection={(stateId: number) => toggleCollapse(stateId)}
                            stateName={item.productName}
                            countyChecked={
                              subscribedStates.includes(item.productId) ||
                              subscribedNational.length > 0
                                ? true
                                : false
                            }
                            checkIndeterminate={
                              indeterminateCondition.length > 0 && subscribedNational.length === 0
                                ? true
                                : false
                            }
                          />
                          {selectedState === item.productId ? (
                            <UncontrolledCollapse
                              isOpen={true}
                              toggler={'#toggler'}
                              className='countyCollapse w-100 mx-2 mb-3'
                            >
                              <Card>
                                <CardBody>
                                  <CountyList
                                    data={item}
                                    agencyPopup={(agencies: any) => openAgenciesModal(agencies)}
                                    {...otherprops}
                                  />
                                </CardBody>
                              </Card>
                            </UncontrolledCollapse>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='sideBarSpacing'>
              <h4>
                My Subscriptions (
                {subscribedStates.length + subscribedCounties.length + subscribedNational.length})
              </h4>
              <Cart isReview={isReview} isRenewal={isRenewalAvail} />
              {!isReview ? (
                <>
                  <h4>
                    My Current Subscriptions (
                    {currentSubscribedStates.length +
                      currentSubscribedCounties.length +
                      currentSubscribedNational.length}
                    )
                  </h4>
                  <Cart showDelIcon={false} isRenewal={isRenewalAvail} />
                </>
              ) : null}

              <hr />
              <CartTotal
                disabled={disabled}
                isRenewal={memberSubscriptionInfo.isRenewalAllowed}
                isReview={isReview}
                totalCartItems={selectedAllProducts.length}
                totalCartPrice={
                  subscribedNational.length > 0
                    ? nationalProduct
                      ? nationalProduct.price
                      : 2000
                    : totalCartPrice
                }
              />
              {displayUpgradeButton ? (
                <Buttons
                  type='button'
                  onClick={() => upgradesubscription(true)}
                  classNames='bttn-primary w-100 mb-3'
                  text='Upgrade'
                  disable={totalNewProducts.length === 0}
                />
              ) : null}
              {displayRenewButton ? (
                <Buttons
                  type='submit'
                  onClick={() => upgradesubscription(false)}
                  classNames='bttn-primary w-100 mb-3'
                  text='Renew'
                  disable={totalNewProducts.length === 0}
                />
              ) : null}
              {displayCheckOutButton ? (
                <Buttons
                  type='button'
                  onClick={() => {
                    if (!calculatedPrice) {
                      const cartTotalPrice =
                        subscribedNational.length > 0
                          ? nationalProduct
                            ? nationalProduct.price
                            : 2000
                          : totalCartPrice;
                      setSubscriptionDetails({
                        calculatedPrice: parseFloat(cartTotalPrice).toFixed(2),
                      });
                    }
                    if (pagefor === 'registration') skipFornow('proceed');
                    else setSubscriptionDetails({ currentStep: 2 });
                    if (pageFrom && amplitudeData && amplitudeData.title) {
                      trackAmplitudeUserActions({
                        title: 'upgrade - click checkout',
                        desc: 'Add product and proceeded to checkout',
                        data: amplitudeData.data,
                      });
                    }
                  }}
                  classNames='bttn-primary w-100 mb-3'
                  text='Proceed to Checkout'
                  disable={totalCartPrice === 0 ? true : false}
                />
              ) : null}
              {displayCancelButton &&
              memberSubscriptionInfo.memberDetails &&
              memberSubscriptionInfo.memberDetails.memberType !== memberTypes.basicSupplier ? (
                <Buttons
                  type='button'
                  onClick={() => navHistory.goBack()}
                  classNames='bttn-secondary w-100'
                  text='Cancel'
                />
              ) : (pagefor === 'registration' && isRegistrationCompleted === false) ||
                pagefor !== 'registration' ? (
                <Buttons
                  type='button'
                  onClick={() => skipFornow()}
                  classNames='bttn-secondary mb-3 w-100'
                  text={
                    memberSubscriptionInfo.isRenewalAllowed &&
                    memberSubscriptionInfo.memberDetails.memberType === memberTypes.basicSupplier
                      ? 'Cancel'
                      : 'Skip for Now'
                  }
                />
              ) : null}
              {pagefor === 'registration' ? (
                <Buttons
                  type='button'
                  onClick={() =>
                    setRegistrationData({
                      currentComponent: isRegistrationCompleted
                        ? registrationComponent.AccountConfirmation
                        : registrationComponent.ChooseAgency,
                    })
                  }
                  classNames='bttn-secondary w-100'
                  text={'Back'}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        size='lg'
        title='Agency List'
        closeModal={showAgencyModal}
        isOpen={showAgency}
        //backdrop='static'
      >
        <AgencyList countyAgencies={countyAgencies} showAgencyModal={showAgencyModal} />
      </ModalPopUp>

      <ModalPopUp
        size='lg'
        title={`${freeAgencyName ? 'Notice' : 'Warning'}`}
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        {freeAgencyName ? (
          <>
            <p>
              By not subscribing to any counties or states, you risk missing out on opportunities
              from other agencies near you.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency, not just <b>{freeAgencyName}</b>, in those counties posts a new
              bid.
            </p>
          </>
        ) : (
          <>
            <p>
              To receive proactive notifications about opportunities near you, you must subscribe to
              a geographic region.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency in those counties posts a new bid.
            </p>
          </>
        )}
        <p>Make sure you never miss out on a government contract again!</p>

        <Buttons
          classNames='bttn-secondary mt-2'
          text='Skip getting notifications'
          title='Skip getting notifications'
          type='button'
          onClick={() => {
            toggleConfirmationPopup(false);
            skipSubscriptionOnRegistration({});
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='I want to subscribe to counties!'
          title='I want to subscribe to counties!'
          type='button'
          onClick={() => {
            toggleConfirmationPopup(false);
          }}
        />
      </ModalPopUp>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    auth: state.auth,
    shared: state.shared,
    subscriptions: state.subscriptions,
    accountinfo: state.accountinfo,
    registration: state.registration,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

export default connector(memo(ChooseSubscription));
