import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

export const initialStateBreadcrumb = {
  component: '',
  page: '',
  title: '',
  name: '',
  id: '',
  menuactive: '',
};

export const reducer = (state = initialStateBreadcrumb, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.SET_BREADCRUMB.TRIGGER:
      state = {
        ...state,
        ...payload,
      };
      break;
    case actionTypes.RESET_BREADCRUMB.TRIGGER:
      state = initialStateBreadcrumb;
      break;
    default:
      break;
  }
  return state;
};
