import * as React from 'react';

import { AmplitudeClient, Config } from 'amplitude-js';

import { isValidAmplitudeInstance } from './validation';

declare type Props = {
  amplitudeInstance: AmplitudeClient;
  // Pass empty "" if you're testing/development
  apiKey: string;
  // User ID to identify this session with
  userId?: string;
  config?: Config;
  children: React.ReactNode;
};

declare type AmplitudeContextType = {
  amplitudeInstance?: AmplitudeClient;
  getParentContext?(): AmplitudeContextType;
  eventProperties?: any;
};

export const AmplitudeContext = React.createContext<AmplitudeContextType>({
  eventProperties: {},
});

export function useAmplitudeContext() {
  return React.useContext(AmplitudeContext);
}

function initAmplitude(
  amplitudeInstance: AmplitudeClient,
  apiKey: string,
  userId?: string,
  config?: Config,
) {
  return () => {
    if (isValidAmplitudeInstance(amplitudeInstance)) {
      if (apiKey) {
        amplitudeInstance.init(apiKey, undefined, config);
      }
      if (userId) {
        amplitudeInstance.setUserId(userId);
      }
    }
  };
}

export function AmplitudeProvider(props: Props) {
  const { amplitudeInstance, apiKey, userId, config } = props;

  // Memoize so it's only really called if the params change
  const init = React.useMemo(() => initAmplitude(amplitudeInstance, apiKey, userId, config), [
    amplitudeInstance,
    apiKey,
    userId,
    config,
  ]);

  // We need to init such that LogOnMount is happy
  init();

  return (
    <AmplitudeContext.Provider
      value={{
        amplitudeInstance: props.amplitudeInstance,
        eventProperties: {},
      }}
    >
      {props.children}
    </AmplitudeContext.Provider>
  );
}
