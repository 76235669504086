import React, { memo, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ProductsSummary from './productsummary';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../../store/actions';

const navLinks = [
  {
    name: 'Products',
    id: '1',
    active: 'productssummary',
  } /* ,
	{
		name: '	Templates',
		id: '1',
		active: 'templates'
	} */,
];

interface ProductsViewProps {
  match?: any;
}

const ProductsView = (props: ProductsViewProps) => {
  const dispatch = useDispatch();

  const Tab = props.match.params.Tab;
  const [activeTab, setActiveTab] = useState(Tab ? Tab : 'productssummary');
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    const control = (
      <li title='Add Products'>
        <span className='staticLink addBid'>
          <i className='mdi mdi-square-edit-outline mr-1' /> Edit
        </span>
      </li>
    );

    dispatch(
      setBreadcrumb({
        component: control,
        page: 'Products',
        title: 'Products',
        name: 'Products',
        menuactive: '',
      }),
    );
  }, [dispatch]);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <Nav tabs>
              {navLinks.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === item.active,
                    })}
                    onClick={() => {
                      toggle(item.active);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='productssummary'>
                {activeTab === 'productssummary' && <ProductsSummary />}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ProductsView);
