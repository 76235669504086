import React from 'react';
import { getIn } from 'formik';

interface TextInputTypes {
  parentClass: string;
  label: string;
  optional: boolean;
  error: boolean;
  name: string;
  value: string;
  maxLength: number;
  disabled: boolean;
  classNames: string;
  placeholder: string;
  form: any;
  field: any;
  onChange: any;
  onBlur: any;
}

function Text(props: TextInputTypes) {
  const {
    onChange = () => {},
    onBlur = () => {},
    label,
    optional = false,
    disabled,
    placeholder,
    parentClass = '',
    form = {},
    field = {},
    maxLength = 256,
  } = props;

  const { name = '' } = field;

  const errors = getIn(form.errors, name);
  const touched = getIn(form.touched, name);

  const onChangeFn = (event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target;
    onChange(name, value.trimStart());
    // setFieldTouched(name, true)
  };

  const onBlurFn = (event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target;
    onBlur(name, value.trimStart());
    // setFieldTouched(name, true)
  };

  return (
    <>
      <div
        className={
          'inputWrapper clearfix ' + parentClass + (errors && touched ? ' errorWrapper' : '')
        }
      >
        <div>
          <label htmlFor={field.name} aria-labelledby={field.name}>
            {label}
          </label>
          <input
            {...field}
            type='text'
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChangeFn}
            onBlur={onBlurFn}
            maxLength={maxLength}
            id={field.name}
          />
        </div>
        {optional ? <span>(optional)</span> : optional ? <span>({optional})</span> : ''}
        <div className='errorMsg'>{errors && touched && <span>{errors}</span>}</div>
      </div>
    </>
  );
}

export const renderDeprecatedTextField = (fieldvalues: any) => <Text {...fieldvalues} />;
