import React, { memo, useState } from 'react';
import { agencyPageTheme, supportedImageFormat } from '../../utils/constants';
import { useForceUpdate } from '../../utils/helperHooks';
import { ModalPopUp } from './modals/ModalPopUp';
import { DeprecatedInput, SelectBox } from '../customcontrols';
import PreviewImage from '../ops/registration/previewimage';
import { agencyRegistrationParamType, ImageName } from '../../types/agencyregistration';

interface PageSettingsFormProps {
  setAgencyRegistrationDetails?: any;
  agencyregistration: agencyRegistrationParamType;
  pagefor?: string;
}

const PageSettingsForm = (props: PageSettingsFormProps) => {
  const forceUpdate = useForceUpdate();
  const { setAgencyRegistrationDetails, agencyregistration /* , pagefor = '' */ } = props;

  const { filetypes = [], pageSettingFields, imageTypes, validateSize = [] } =
    agencyregistration || {};

  const [onValidating, setOnValidating] = useState('');
  const [previewPagePopup, setPreviewPagePopup] = useState(false);

  const validateImageWidthHeight = (imageFile: Blob, name: ImageName) => {
    const images = new Image();
    images.onload = () => {
      if (name === 'logo' && images.width < validateSize[name].width) {
        sendImageError({ error: true, errormsg: 'Please upload image with valid width' }, name);
      } else if (
        name === 'banner' &&
        (images.width < validateSize[name].width || images.height < validateSize[name].height)
      ) {
        sendImageError(
          { error: true, errormsg: 'Please upload image with valid width and height' },
          name,
        );
      } else {
        setOnValidating('');
      }
    };
    images.onerror = () =>
      sendImageError(
        { error: true, errormsg: 'There is a error with image. please check the file format' },
        name,
      );

    const fileReader = new FileReader();
    fileReader.onload = () => (images.src = fileReader.result as string);
    fileReader.onerror = () =>
      sendImageError(
        { error: true, errormsg: 'There is a error with image. please check the file format' },
        name,
      );
    if (imageFile) {
      fileReader.readAsDataURL(imageFile);
    }
  };

  const sendImageError = (errorInfo: { error: boolean; errormsg: string }, name: ImageName) => {
    const imageTypeArray =
      imageTypes &&
      imageTypes.map(item => {
        if (item.name === name) {
          item.error = errorInfo.errormsg;
        }
        return item;
      });

    setAgencyRegistrationDetails({
      pageSettingFields: { ...pageSettingFields, [name]: {} },
      imageTypes: imageTypeArray,
    });
    forceUpdate();
    setOnValidating('');
  };

  const uploadImage = async (name: ImageName, value: string) => {
    if (value.length > 0) {
      const imageTypeArray =
        imageTypes &&
        imageTypes.map(item => {
          if (item.name === name) {
            item.error = '';
          }
          return item;
        });
      setAgencyRegistrationDetails({ imageTypes: imageTypeArray });
      setOnValidating(name);
      const file = (value && value[0]) || ({} as any);
      validateImageWidthHeight(file, name);

      const fileSize = file.size / Math.pow(1024, 1);
      const fileType = file.type.split('/')[1].toLowerCase();

      if (!supportedImageFormat.includes(fileType)) {
        sendImageError({ error: true, errormsg: 'invalid file format' }, name);
      } else if (
        file &&
        ((name === 'logo' && fileSize > validateSize[name].size) ||
          (name === 'banner' && fileSize > validateSize[name].size))
      ) {
        sendImageError(
          { error: true, errormsg: `Maximum File Size ${validateSize[name].size} KB` },
          name,
        );
      } else {
        setAgencyRegistrationDetails({
          pageSettingFields: { ...pageSettingFields, [name]: file },
          pagehasValues: true,
        });
      }
    }
  };

  const changeBackgroundPosition = (name: string, value: any) => {
    setAgencyRegistrationDetails({
      pageSettingFields: { ...pageSettingFields, imgPos: { ...value, type: 'position' } },
      pagehasValues: true,
    });
  };

  let meta = { touched: false, error: '' };

  const FileUploadBoxContent = (purpose: ImageName, accept: string[], error: string) => {
    if (error) {
      meta = { touched: true, error };
    } else {
      meta = { touched: false, error: '' };
    }

    return (
      <DeprecatedInput
        type='file'
        name={purpose}
        labelname={purpose}
        handleChange={(name: string, value: string) => uploadImage(purpose, value)}
        accept={accept}
        meta={meta}
        label='Choose a file'
        parentClass='mt-3 mb-2'
      />
    );
  };

  const togglePreviewPagePopup = () => {
    setPreviewPagePopup(!previewPagePopup);
  };

  return (
    <>
      <div className='d-block row'>
        {imageTypes &&
          imageTypes.map((item, index) => (
            <div key={index}>
              <h4>{item.label}</h4>
              <div className='col-12 col-md-9 col-lg-6'>
                <div className='innerColIndent'>
                  {item.name === 'logo' ? (
                    <SelectBox
                      label='Background Color'
                      name='theme'
                      reactselect={true}
                      options={agencyPageTheme}
                      value={pageSettingFields && pageSettingFields.theme}
                      handleSelect={(name: string, value: any) =>
                        setAgencyRegistrationDetails({
                          pageSettingFields: { ...pageSettingFields, theme: value },
                          pagehasValues: true,
                        })
                      }
                      parentClass='hideErrorMsg'
                    />
                  ) : (
                    <SelectBox
                      label='Image Position'
                      name='backgroundPosition'
                      reactselect={true}
                      options={[
                        { label: 'Top', value: 'top' },
                        { label: 'Center', value: 'center' },
                        { label: 'Bottom', value: 'bottom' },
                      ]}
                      value={
                        pageSettingFields && pageSettingFields.imgPos.type === 'position'
                          ? pageSettingFields.imgPos
                          : ''
                      }
                      handleSelect={changeBackgroundPosition}
                      parentClass='hideErrorMsg'
                    />
                  )}
                </div>
              </div>
              <div className='d-block px-5 innerColIndent mt-3'>
                {pageSettingFields &&
                pageSettingFields[`${item.name}`].name &&
                onValidating !== item.name ? (
                  <>
                    {pageSettingFields[item.name].name}
                    <i
                      className='mdi mdi-24px mdi-delete-forever-outline staticLink ml-3'
                      style={{ position: 'relative', top: '5px' }}
                      onClick={() =>
                        setAgencyRegistrationDetails({
                          pageSettingFields: { ...pageSettingFields, [item.name]: {} },
                          pagehasValues: true,
                        })
                      }
                    />
                  </>
                ) : (
                  <> {FileUploadBoxContent(item.name, filetypes, item.error)}</>
                )}
                <small className='my-3'>
                  <i className='mdi mdi-information staticTxt mr-1' />
                  {validateSize[item.name].width && validateSize[item.name].height ? (
                    <>
                      Please select a JPG/PNG image with min width 1200px and min height 400px max
                      file size 2 mb.
                    </>
                  ) : (
                    <>
                      Please select a JPG/PNG image with min width 200px and max file size 500 kb.
                    </>
                  )}
                </small>
              </div>
            </div>
          ))}
        {(pageSettingFields.logo.name || pageSettingFields.banner.name) && (
          <p
            className='staticLink bttn-secondary w-50 mt-5'
            onClick={() => setPreviewPagePopup(true)}
          >
            <i className='mdi mdi-eye' /> Preview logo and banner
          </p>
        )}
      </div>
      <ModalPopUp
        size='xxl'
        title='Preview'
        closeModal={togglePreviewPagePopup}
        isOpen={previewPagePopup}
        backdrop='static'
      >
        <PreviewImage
          agencyregistration={props.agencyregistration}
          togglePreviewPagePopup={togglePreviewPagePopup}
        />
      </ModalPopUp>
    </>
  );
};

export default memo(PageSettingsForm);
