import React from 'react';
import styled from 'styled-components';

import { SubmitButton, SubmitButtonProps } from './SubmitButton';

const StyledSubmitButton: React.FunctionComponent<SubmitButtonProps> = styled(SubmitButton)`
  font-size: 1rem;
  height: 2.25rem;
  padding: 0.25rem 0.5rem;
`;

export function SubmitButtonSmall(props: SubmitButtonProps) {
  return <StyledSubmitButton {...props} />;
}
