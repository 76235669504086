import React from 'react';
import { Buttons } from '../../customcontrols';
import navHistory from '../../../utils/history';
import { registrationComponent } from '../../../utils/constants';
import HelpText from './helptext';

const EmailAccountDetect = (props: any) => {
  const { registration, setRegistrationData, claimPlanholderAccount } = props;
  const { errorData, registrationData, planholderEmail = '', claimSent = false } = registration;

  let content = '';
  let title = '';
  let secondText = '';
  let buttonContext = true;
  if (errorData.IsBlackList) {
    content =
      'All registrations for your organizations have been placed on a temporary hold. Please contact the DemandStar support to get more information.';
    title = "Company's Registration On Hold";
    buttonContext = false;
  } else if (errorData.accountExists) {
    content =
      'It looks like this email address is already associated with an account in our system.';
    title = 'Email Account Detected';
  } else if (errorData.companyExists) {
    content = 'It looks like this company is already associated with an account in our system.';
    title = 'Company Detected';
  } else if (errorData.planholderExist) {
    title = 'Email Account Detected';
    content = `An agency previously included ${planholderEmail} as a planholder on a bid.`;
    secondText =
      "We'll need to verify your identity to register your account. Please click the button below, then click on the registration link sent to your email address to claim your account.";
    buttonContext = false;
  }

  if (claimSent === true) {
    title = 'Email Account Detected';
    content = `Please check your ${planholderEmail} email account. `;
    secondText = 'Click on the registration link we sent to that address to claim your account.';
    buttonContext = false;
  }

  const handleLogin = () => {
    navHistory.push('/');
  };

  const accountRecovery = () => {
    navHistory.push('/passwordrecovery');
  };

  const claimMyAccount = () => {
    claimPlanholderAccount({ email: planholderEmail });
  };

  const emailDetectButtons = () => {
    return (
      <div className='d-flex flex-column'>
        {buttonContext ? (
          <Buttons
            classNames='bttn-primary mb-4'
            onClick={handleLogin}
            type='button'
            text='Log in With This Account'
          />
        ) : null}

        <Buttons
          classNames='bttn-secondary mb-4'
          type='button'
          onClick={accountRecovery}
          text='I Need Help Logging in to This Account'
        />
        <Buttons
          classNames='bttn-secondary'
          type='button'
          onClick={() =>
            setRegistrationData({
              currentComponent: registrationComponent.CreateAccount,
              registrationData: { registrationData, acceptTerms: false },
            })
          }
          text='Go Back'
        />
      </div>
    );
  };

  const planholderExistButtons = () => {
    return (
      <div className='d-flex justify-content-between mt-3'>
        <Buttons
          text='Back'
          classNames='bttn bttn-secondary float-left'
          onClick={() =>
            setRegistrationData({
              currentComponent: registrationComponent.CreateAccount,
              registrationData: { registrationData, acceptTerms: false },
            })
          }
        />
        {!claimSent ? (
          <Buttons
            classNames='bttn-primary float-right'
            text='Claim my account'
            type='button'
            onClick={() => claimMyAccount()}
          />
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <div className='row'>
                  <div className='col-12 col-md-7'>
                    <h3 className='arrowWrapper'>{title}</h3>
                    <div className='minH-160'>
                      <p>{content}</p>
                      {secondText ? <p>{secondText}</p> : null}
                    </div>
                    {errorData.planholderExist ? planholderExistButtons() : emailDetectButtons()}
                  </div>
                </div>
                <HelpText />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailAccountDetect;
