import React, { InputHTMLAttributes } from 'react';

import { WidgetContainer, WidgetInputWrapper, WidgetLabel } from './styles';

import { TextInput } from '../../forms/TextInput';

interface TextInputWidgetProperties extends InputHTMLAttributes<HTMLInputElement> {
  fieldRef: any; // pass-through for react-hook-form field registration
  inputHeight?: string;
  inputWidth?: string;
  label: string;
  name: string;
  onChange: (props?: any) => void; // FIXME: This needs to be typed.
  value?: any;
  width?: string;
}

export function TextInputWidget({
  fieldRef,
  inputHeight,
  inputWidth,
  label,
  name,
  onChange,
  value = '',
  width,
}: TextInputWidgetProperties) {
  return (
    <div>
      {/* TODO: Keep overhead label for UI consistency until form redesign */}
      <WidgetLabel htmlFor={name}>{label}</WidgetLabel>
      <TextInput
        fieldRef={fieldRef}
        fieldWrapper={WidgetInputWrapper}
        inputHeight={inputHeight}
        inputWidth={inputWidth}
        label={''}
        name={name}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
