import { actionPayloadTypes } from '../../types/actiontypedef';
import * as actionTypes from '../actionTypes';
import { action } from './utils';

export function setActivitiesDetails(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SET_ACTIVITIES_DETAILS, payload);
}

export function resetActivitiesDetails(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.RESET_ACTIVITIES_DETAILS, payload);
}

export function getActivitiesNotifications(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_NOTIFICATIONS, payload);
}

export function getActivitiesNotificationsMember(
  payload: actionPayloadTypes | undefined = undefined,
) {
  return action(actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER, payload);
}

export function getActivitiesWatchs(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_WATCHS, payload);
}

export function getActivitiesPlanholders(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_PLANHOLDERS, payload);
}

export function getActivitiesEbidders(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_EBIDDERS, payload);
}

export function getActivitiesQuotes(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_QUOTES, payload);
}

export function getActivitiesSearch(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_SEARCH, payload);
}

export function getBidActivityReport(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_BIDREPORT, payload);
}

export function exportBidActivityReport(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.EXPORT_ACTIVITIES_BIDREPORT, payload);
}

export function getQuoteActivityReport(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACTIVITIES_QUOTEREPORT, payload);
}

export function exportQuoteActivityReport(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.EXPORT_ACTIVITIES_QUOTEREPORT, payload);
}
