import React, { HTMLProps } from 'react';
import { FontFamily } from '@demandstar/components/styles';
import styled from 'styled-components';

import { Colors } from '../../../../shared/styles';

interface BaseTextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  error?: boolean;
  padding?: string;
}

/**
 * This is the base TextArea input.
 */
export const BaseTextArea = styled.textarea<BaseTextAreaProps>`
  border: 0.0625rem solid;
  border-color: ${props => (props.error ? Colors.error : Colors.grayInputBorder)};
  border-radius: 0.25rem;
  background: ${Colors.white};
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '0.25rem 0.5rem')};
  font-family: ${FontFamily.Base};
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  color: ${Colors.grayDark};
  text-overflow: ellipsis;
  word-spacing: normal;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${Colors.black};
    background: ${Colors.disabled};
  }
`;
