// TOREFACTOR:

import moment from 'moment-timezone';
import React, { memo, useEffect } from 'react';
import { Buttons } from '../../../customcontrols';
import {
  deprecatedGetDateTimeZoneConverted,
  deprecatedGetTimeZoneDateTime,
  getTimeFromDate,
  getZoneFromDate,
  setTimeToDate,
  toastFn,
  useAssert,
} from '../../../../utils/helpers';
import { addbidParamType } from '../../../../types/addbid';
import { bidssummaryParamType } from '../../../../types/biddetail';
import {
  broadcastDateTimeinitital,
  broadcastDateTimeZoneinitital,
} from '../../../../store/reducers/addbid';
import history from '../../../../utils/history';
import { useForceUpdate } from '../../../../utils/helperHooks';
import { usaDateFormat, usaDateTimeFormat } from '../../../../utils/constants';

interface PropsTypes {
  addbid?: addbidParamType;
  submitAddBidComplete?: any;
  bidssummary?: bidssummaryParamType;
  setBroadcastModal?: any;
  setAddBidDetails?: any;
}

const Broadcast = (props: PropsTypes) => {
  const forceUpdate = useForceUpdate();
  const { bidssummary, submitAddBidComplete, setAddBidDetails, setBroadcastModal } = props;
  const { results } = bidssummary || {};
  const { broadcastDate = '', bidID = 0, tzfn, tzn } = results || {};
  useAssert(!!tzfn, 'Timezone should be defined', 'broadcast.tsx');

  const broadcastDatetzfn = deprecatedGetDateTimeZoneConverted(broadcastDate, tzfn);
  const broadcastDatetzfntime = broadcastDatetzfn
    ? getTimeFromDate(broadcastDatetzfn)
    : broadcastDateTimeinitital;
  const broadcastDatetzfnzone = broadcastDatetzfn
    ? getZoneFromDate(broadcastDatetzfn)
    : broadcastDateTimeZoneinitital;

  useEffect(() => {
    const updateModal = setTimeout(() => {
      forceUpdate();
    }, 60000);
    return () => {
      clearTimeout(updateModal);
    };
  }, [forceUpdate]);

  const EditbidFn = () => {
    setBroadcastModal();
    setAddBidDetails({ upcomingBidPageFor: 'Bid Information', focusbroadcasttimefield: true });
    history.push(`/buyers/bids/${bidID}/edit`);
  };

  const SubmitFinishFn = (type: string) => {
    if (type !== 'now' && moment(broadcastDatetzfn).isBefore(deprecatedGetTimeZoneDateTime(tzfn))) {
      toastFn(
        'error',
        'The broadcast date and time must be today or later',
        'broadcast date issue less than today',
      );
    } else {
      const item: { bidId: number; broadcastDate?: string } = {
        bidId: bidID,
      };
      if (type !== 'now') {
        item.broadcastDate = setTimeToDate(
          broadcastDatetzfn,
          `${broadcastDatetzfntime.value} ${broadcastDatetzfnzone.value}`,
        );
      }
      submitAddBidComplete(item);
    }
  };

  return (
    <>
      {/** TODO: Untangle the use of deprecatedGetTimeZoneDateTime here.
       * This is the only time where we pass in an empty string of the second
       * argument, and as a result, we get a moment object back instead of
       * a string. It works(?), but it's unclear and convoluted.
       */}
      {moment(broadcastDatetzfn).isBetween(
        deprecatedGetTimeZoneDateTime(tzfn),
        (deprecatedGetTimeZoneDateTime(tzfn, '') as moment.Moment)
          .endOf('day')
          .format(usaDateTimeFormat),
      ) ? (
        <>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <p>
                Your broadcast date is set for today. Do you want to broadcast this bid now, or
                would you prefer to wait until
                <b>
                  {' '}
                  {broadcastDatetzfntime.value} {broadcastDatetzfnzone.value} {tzn}
                </b>
                ?
              </p>
              <div className='d-flex justify-content-between mt-5'>
                <Buttons
                  classNames='bttn-primary '
                  text='Broadcast Now'
                  title='Broadcast Now'
                  type='button'
                  onClick={() => SubmitFinishFn('now')}
                  disable={moment(broadcastDatetzfn).isBefore(deprecatedGetTimeZoneDateTime(tzfn))}
                />
                <Buttons
                  classNames='bttn-primary ml-2 '
                  text={[
                    'Broadcast at ',
                    broadcastDatetzfntime.value,
                    ' ',
                    broadcastDatetzfnzone.value,
                  ]}
                  type='button'
                  onClick={() => SubmitFinishFn('later')}
                  disable={moment(broadcastDatetzfn).isBefore(deprecatedGetTimeZoneDateTime(tzfn))}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {moment(broadcastDatetzfn).isAfter(deprecatedGetTimeZoneDateTime(tzfn)) ? (
            <>
              <p>
                This bid is scheduled to be broadcast on{' '}
                <b>{moment(broadcastDatetzfn).format(usaDateFormat)}</b> at{' '}
                <b>
                  {broadcastDatetzfntime.value} {broadcastDatetzfnzone.value} {tzn}
                </b>
                . Is that correct?
              </p>
              <div className='d-flex justify-content-between mt-5'>
                <Buttons
                  classNames='bttn-primary'
                  text='No, I want to change the broadcast date or time'
                  title='No, I want to change the broadcast date or time'
                  type='button'
                  onClick={EditbidFn}
                />
                <Buttons
                  classNames='bttn-primary ml-3'
                  text='Yes, that is correct'
                  title='Yes, that is correct'
                  type='button'
                  onClick={() => SubmitFinishFn('later')}
                  disable={moment(broadcastDatetzfn).isSameOrBefore(
                    deprecatedGetTimeZoneDateTime(tzfn),
                  )}
                />
              </div>
            </>
          ) : (
            <>
              <p className='mb-1'>
                {moment(moment(broadcastDatetzfn).format(usaDateFormat)).isBefore(
                  deprecatedGetTimeZoneDateTime(tzfn, usaDateFormat),
                )
                  ? 'The selected broadcast date has already passed. Would you like to set a new broadcast date and time, or would you like to broadcast now?'
                  : 'The broadcast date for this bid is set for today. Would you like to schedule a time later today to post it, or broadcast it now?'}
              </p>
              <div className='d-flex justify-content-between mt-5'>
                <Buttons
                  classNames='bttn-primary'
                  text={
                    moment(moment(broadcastDatetzfn).format(usaDateFormat)).isBefore(
                      deprecatedGetTimeZoneDateTime(tzfn, usaDateFormat),
                    )
                      ? 'Schedule New Broadcast Date And Time'
                      : 'Schedule New Broadcast Time'
                  }
                  title={
                    moment(moment(broadcastDatetzfn).format(usaDateFormat)).isBefore(
                      deprecatedGetTimeZoneDateTime(tzfn, usaDateFormat),
                    )
                      ? 'Schedule New Broadcast Date And Time'
                      : 'Schedule New Broadcast Time'
                  }
                  type='button'
                  onClick={EditbidFn}
                />
                <Buttons
                  classNames='bttn-primary ml-3'
                  text='Broadcast Now'
                  title='Broadcast Now'
                  type='button'
                  onClick={() => SubmitFinishFn('now')}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default memo(Broadcast);
