import { DSLink } from '@demandstar/components/link';
import React from 'react';

import { Pages } from '../../../../../utils/constants';

export interface BidDetailsLinkProps {
  bidId: number;
  children: string;
  type?: 'limited' | 'buyers' | 'suppliers';
  newTab?: boolean;
}

const typePaths = {
  limited: Pages.limitedBids,
  buyers: Pages.buyerBids,
  suppliers: Pages.supplierBids,
};

/** Wrap this around a clickable string and it will turn it into a link to the bid Details page.
 * @example <BidDetailsLink bidId={bidId} type='buyers'>Foo</BidDetailsLink>
 */
export const BidDetailsLink = (props: BidDetailsLinkProps) => {
  const { bidId, children, newTab = true, type = 'limited' } = props;

  return (
    <DSLink href={`${typePaths[type]}/${bidId}/details`} newTab={newTab}>
      {children}
    </DSLink>
  );
};
