import React, { memo } from 'react';
import { Buttons } from '../../../customcontrols';

type propsType = {
  validEmailAddress: Array<{ email: string }>;
  invalidEmailCount: number;
  goBack: any;
  saveUploadedSupplimentalSupplier: any;
};

const ValidEmailAddresses = (props: propsType) => {
  const {
    validEmailAddress = [],
    invalidEmailCount,
    goBack,
    saveUploadedSupplimentalSupplier,
  } = props;
  return (
    <>
      {validEmailAddress.length > 0 ? (
        <>
          <h5>Valid Email Addresses ({validEmailAddress.length})</h5>
          <div className='reviewScroll'>
            <div className='clearfix row'>
              <div className='col-md-12'>
                <ul className='list-unstyled row'>
                  {validEmailAddress.map((item, index) => (
                    <li key={index} className='col-4'>
                      {item.email}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className='d-flex justify-content-center mt-3'>
        {invalidEmailCount === 0 ? (
          <Buttons
            text='Save'
            classNames='bttn-primary w-50'
            onClick={() => saveUploadedSupplimentalSupplier()}
          />
        ) : (
          <Buttons
            text='Go Back and Review a List'
            classNames='bttn-primary w-50'
            onClick={goBack}
          />
        )}
      </div>
    </>
  );
};

export default memo(ValidEmailAddresses);
