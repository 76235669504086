import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { broadcastlistactionTypes } from '../../types/broadcastlist';

export function setBroadcastListDetails(payload: broadcastlistactionTypes) {
  return action(actionTypes.SET_BROADCAST_LIST_DETAILS, payload);
}

export function submitBroadcastListAddList(payload: broadcastlistactionTypes) {
  return action(actionTypes.SUBMIT_BROADCAST_LIST_ADD_LIST, payload);
}

export function getBroadcastListGetLists(payload: broadcastlistactionTypes) {
  return action(actionTypes.GET_BROADCAST_LIST_SAVED_LISTS, payload);
}

export function getBroadcastListBroadcastMembers(payload: broadcastlistactionTypes) {
  return action(actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS, payload);
}

export function getBroadcastListSearchVendors(payload: broadcastlistactionTypes) {
  return action(actionTypes.GET_BROADCAST_LIST_SEARCH_VENDORS, payload);
}

export function submitBroadcastListDeleteList(payload: broadcastlistactionTypes) {
  return action(actionTypes.SUBMIT_BROADCAST_LIST_DELETE_LIST, payload);
}
