import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import { CheckBox, SelectBox } from '../../customcontrols';
import {
  CheckBoxWrapper,
  renderProgressBar,
  SelectBoxWrapper,
  SubmitButtonWrapper,
  UploadFormWrapper,
  UploadProgressWrapper,
} from './DocumentUploadFormStyledComponents';
import { commonLabels, documentLabels } from '../../../shared/constants';
import { DocTypeOption, DocumentUploadFormValues } from '../../../types';
import {
  documentUploadFormChangedState,
  pdfConvertibleDocumentFormatListState,
} from '../../../store/recoil/documentState';
import { FileInputWidget, TextInputWidget } from '../form';
import {
  FormFieldErrorStandalone,
  FormFieldErrorStandaloneSelectBox,
} from '../../forms/styled/FormFieldStyled';

import { FileUploadLabels } from '../../../shared/constants/bids';
import { getDocFormatType } from '../../../utils';
import { SubmitButton } from '../controls/buttons/SubmitButton';
import { useRecoilLoadableContents } from '../../../shared/hooks/useRecoilLoadableContents';
import { useSelectedMemberType } from '../../../shared/hooks/useMemberInfo';
import { WidgetSet } from '../form/WidgetSet';

const blankFile: File = new File([], '');

const textInputHeight = '3rem';
const textInputWidth = '20rem';

interface DocumentUploadFormProps {
  docReferenceId?: number;
  docTitleLabel?: string;
  docTypeLabel?: string;
  docTypeOptions?: DocTypeOption[];
  handleFormSubmit: (formValues: DocumentUploadFormValues) => void;
  presetDocumentType?: string; // Optional for explicit doc type, e.g. required doc response uploads
  submitButtonLabel?: string;
  uploadProgress: number;
}

export function DocumentUploadForm({
  docReferenceId,
  docTitleLabel,
  docTypeLabel,
  docTypeOptions,
  handleFormSubmit,
  presetDocumentType,
  submitButtonLabel = commonLabels.upload,
  uploadProgress = 0,
}: DocumentUploadFormProps) {
  // Form hook functions
  const { handleSubmit, register, errors } = useForm<DocumentUploadFormValues>();

  /// App state
  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pdfConvertibleDocFormats =
    useRecoilLoadableContents(pdfConvertibleDocumentFormatListState) || [];

  const setDocumentUploadFormStarted = useSetRecoilState(documentUploadFormChangedState);

  const { selectedMemberIsAgency } = useSelectedMemberType();

  /// Local state
  const [convertToPDF, setConvertToPDF] = useState<boolean>(false);
  const [disableConvertToPDF, setDisableConvertToPDF] = useState<boolean>(true);
  const [docTypeError, setDocTypeError] = useState<string>('');
  const [fileTitle, setFileTitle] = useState<string>('');
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>('');
  const [uploadFile, setUploadFile] = useState<File>(blankFile);
  const [uploading, setUploading] = useState(false);

  /**
   * Helper fn to check member type
   * before enabling pdf conversion by state hook
   */
  const disablePdfConversion = useCallback(
    (disabled: boolean) => {
      if (disabled || !selectedMemberIsAgency()) {
        setDisableConvertToPDF(true);
      } else {
        setDisableConvertToPDF(false);
      }
    },
    [selectedMemberIsAgency],
  );

  /// Effects

  // Default Bid Document type
  useEffect(() => {
    const defaultDocType = docTypeOptions?.length ? docTypeOptions[0].value : '';
    setSelectedDocumentType(defaultDocType);
  }, [docTypeOptions]);

  // Convert to PDF checkbox value
  useEffect(() => {
    // If there's a value for `uploadFile`, we set the `convertToPDF` value to false,
    // and disable the checkbox if the file type doesn't qualify.
    if (uploadFile.name) {
      const testFormat = getDocFormatType(uploadFile.name);
      if (pdfConvertibleDocFormats.includes(testFormat)) {
        disablePdfConversion(false);
      } else {
        disablePdfConversion(true);
        setConvertToPDF(false);
      }
    }

    // Clean-up
    return () => {
      setDisableConvertToPDF(true);
    };
  }, [disablePdfConversion, pdfConvertibleDocFormats, uploadFile]);

  /**
   * Call supplied callback with form values.
   * @param {DocumentUploadFormValues} values
   * @description Registered `react-hook-form` values are passed back to the callback.
   *              The current implementation of `react`select` doesn't register correctly,
   *              so the `documentType` value is passed explicitly.
   * @todo Remove `react-hook-form`  and process manually or upgrade / replace `react-select`.
   */
  async function onUploadFormSubmit(values: DocumentUploadFormValues) {
    // validate documentType when the Select control is exposed - TODO: implement correctly via `react-hook-form`
    if (!presetDocumentType && !selectedDocumentType) {
      setUploading(false);
      setDocTypeError(FileUploadLabels.errorRequiredDocumentType);
      return;
    }

    const submitValues = { ...values };
    submitValues.documentType = selectedDocumentType;
    submitValues.docReferenceId = docReferenceId;

    setUploading(true);
    setDocTypeError('');
    handleFormSubmit(submitValues);
  }

  function handleFileChange(changeEvent: ChangeEvent<HTMLInputElement>) {
    const file: File =
      changeEvent.target.files && changeEvent.target.files.length
        ? changeEvent.target.files[0]
        : uploadFile;
    if (file.name) {
      setUploadFile(file);
    }
  }

  const handleFileTitleChange = useCallback(
    (newValue: string) => {
      setFileTitle(newValue);
      setDocumentUploadFormStarted(true);
    },
    [setDocumentUploadFormStarted],
  );

  const handleBidDocumentTypeChange = useCallback(
    (name: string, value: DocTypeOption) => {
      setSelectedDocumentType(value.value);
      setDocumentUploadFormStarted(true);
    },
    [setDocumentUploadFormStarted],
  );

  function getSelectedDocTypeOption() {
    return docTypeOptions?.find(docTypeOption => docTypeOption.value === selectedDocumentType);
  }

  function handlePDFConversionCheckBoxToggle(name: string, value: boolean) {
    setConvertToPDF(value);
  }

  function resetForm() {
    setConvertToPDF(false);
    setDisableConvertToPDF(true);
    setFileTitle('');
    setSelectedDocumentType('');
    setUploadFile(blankFile);
    setUploading(false);
  }

  // Toggle uploader.
  if (!uploading && uploadProgress > 0 && uploadProgress < 100) {
    setUploading(true);
  }
  if (uploading && uploadProgress >= 100) {
    resetForm();
  }

  const titleLabel = docTitleLabel || FileUploadLabels.documentTitle;
  const typeLabel = docTypeLabel || FileUploadLabels.documentType;

  return (
    <UploadFormWrapper onSubmit={handleSubmit(onUploadFormSubmit)}>
      <WidgetSet>
        <TextInputWidget
          name={'documentTitle'}
          label={titleLabel}
          fieldRef={register({
            required: `${FileUploadLabels.errorRequiredDocumentTitle}`,
          })}
          onChange={handleFileTitleChange}
          value={fileTitle}
          inputHeight={textInputHeight}
          inputWidth={textInputWidth}
        ></TextInputWidget>
        {errors?.documentTitle?.message && (
          <FormFieldErrorStandalone>{errors.documentTitle.message}</FormFieldErrorStandalone>
        )}
        {!presetDocumentType && (
          <>
            <SelectBoxWrapper>
              <SelectBox
                handleSelect={handleBidDocumentTypeChange}
                label={typeLabel}
                name={'documentType'}
                options={docTypeOptions}
                reactselect
                value={getSelectedDocTypeOption()}
              />
            </SelectBoxWrapper>

            {docTypeError && (
              <FormFieldErrorStandaloneSelectBox>{`${docTypeError}`}</FormFieldErrorStandaloneSelectBox>
            )}
          </>
        )}
        {!disableConvertToPDF && (
          <CheckBoxWrapper>
            <CheckBox
              checked={convertToPDF}
              disabled={disableConvertToPDF}
              fieldRef={register}
              handleChecked={handlePDFConversionCheckBoxToggle}
              label={documentLabels.convertToPdf}
              name='convertToPDF'
              value={convertToPDF}
            />
          </CheckBoxWrapper>
        )}
        <FileInputWidget
          name={'files'}
          fileName={uploadFile.name}
          handleInputChange={handleFileChange}
          label={FileUploadLabels.chooseFile}
          fieldRef={register({
            required: `${FileUploadLabels.errorRequiredFile}`,
          })}
        ></FileInputWidget>
        {errors?.files && (
          <FormFieldErrorStandalone>{`${FileUploadLabels.errorRequiredFile}`}</FormFieldErrorStandalone>
        )}
        <UploadProgressWrapper show={uploading}>
          {renderProgressBar(uploadProgress)}
        </UploadProgressWrapper>
        <SubmitButtonWrapper>
          <SubmitButton>{submitButtonLabel}</SubmitButton>
        </SubmitButtonWrapper>
      </WidgetSet>
    </UploadFormWrapper>
  );
}
