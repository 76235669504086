import React, { memo } from 'react';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../../../common/controls/inputs/text';
import renderSelectField from '../../../common/controls/inputs/selectbox';
import renderCheckBox from '../../../common/controls/inputs/checkbox';
import { Buttons } from '../../../customcontrols';
import { sharedTypes } from '../../../../types/shared';

interface PropsTypes {
  shared?: sharedTypes;
  applications?: Array<Record<string, unknown>>;
  certifications?: Array<{ title: string; label: string; value: boolean; key: string }>;
  handleSubmit?: any;
  handleCancel?: any;
  addUpdateProgram?: any;
  initialValues: any;
}

const validation = (values: any) => {
  const errors: any = {};
  const optional = [] as any;
  const requiredFields = ['name', 'description', 'acceptApplications', 'certificationRequired'];

  const required =
    Object.keys(values)
      .filter(items => requiredFields.includes(items) && !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  required.forEach(items => {
    if (!items.value) {
      errors[items.name] = 'Required';
    }
  });
  return errors;
};

function ProgramsAddEdit(props: PropsTypes) {
  const { shared, applications, certifications, handleCancel, addUpdateProgram, initialValues } =
    props;
  const { selfdeclaredattributes } = shared || {};

  const onSubmitHandle = (values: any) => {
    addUpdateProgram({ data: values, initial: initialValues });
    handleCancel();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validation}
        onSubmit={onSubmitHandle}
      >
        {formikProps => {
          const { handleSubmit, setFieldValue } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <div className='row justify-content-center'>
                <div className='col-12 col-lg-8'>
                  <Field
                    label='Name'
                    name='name'
                    placeholder='Name'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                  />
                  <Field
                    label='Description'
                    name='description'
                    placeholder='Description'
                    component={renderDeprecatedTextField}
                    onChange={setFieldValue}
                  />
                  <Field
                    name='acceptApplications'
                    label='Supplier Applications'
                    component={renderSelectField}
                    onChange={(name: string, value: any) => {
                      setFieldValue(name, value);
                    }}
                    options={applications}
                    parentClass='my-0'
                  />
                  <Field
                    name='certificationRequired'
                    label='Supplier Certification'
                    component={renderSelectField}
                    onChange={(name: string, value: any) => {
                      setFieldValue(name, value);
                    }}
                    options={certifications}
                    parentClass='my-0'
                  />
                  {selfdeclaredattributes &&
                    selfdeclaredattributes.map((item, index) => {
                      return (
                        <Field
                          key={index}
                          name={`selfdecAttr[${index}]`}
                          component={renderCheckBox}
                          title={item.attributeName}
                          optional={true}
                          handleChange={setFieldValue}
                        />
                      );
                    })}
                </div>
                <div className='col-12 d-block my-4'>
                  <Buttons
                    classNames='bttn-secondary  float-left'
                    text='Cancel'
                    title='Cancel'
                    type='button'
                    onClick={handleCancel}
                  />
                  <Buttons
                    classNames='bttn-primary float-right'
                    text='Save'
                    title='Save'
                    type='submit'
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(ProgramsAddEdit);
