import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Field, FieldArray, Formik } from 'formik';
import { deprecatedCheckTimezoneCountDown, formatPrice, toastFn } from '../../../utils/helpers';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import renderTextarea from '../../common/controls/inputs/textarea';
import { renderDisplayField } from '../../common/formcomponents/fields';
import { Buttons } from '../../customcontrols';
import QuoteInfo from './quoteinfo';
import SupplierQuotesReplycontrol from './quotereplycontrol';
import { validate } from './validations';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';

const tdWidth = { desc: { width: '40%' }, othr: { width: '10%' }, value: { width: '50%' } };

interface propsTypes {
  quoteSummary: QuoteSummaryType;
  handleSubmit: any;
  pristine: boolean;
  submitting: boolean;
  valid: boolean;
  memberinfo: any;
  setBreadcrumb: any;
  GoBackToPage: any;
  history: any;
  setQuoteSummaryDetails: any;
  submitQuoteReply: any;
  notSubmitQuote: any;
  initialValues: any;
}

const QuotesReplyForm = (props: propsTypes) => {
  const {
    quoteSummary,
    handleSubmit,
    pristine,
    submitting,
    valid,
    memberinfo,
    setBreadcrumb,
    GoBackToPage,
    history,
    setQuoteSummaryDetails,
    submitQuoteReply,
    notSubmitQuote,
    initialValues,
  } = props;

  const { linewires, delivery, additionalSupplierInfo, preparedBy } = initialValues || {};
  const { summary, replyconfirmPage = false, replydetails } = quoteSummary;
  const { mn = '', a1 = '', a2 = '', sta = '', ct = '', ca = '', pc = '', p = '' } = memberinfo;
  const {
    quoteName = '',
    quoteId = '',
    dueDateTime,
    statusType,
    responseId,
    responseStatus,
    tzfn = '',
  } = summary;
  const [isSecondButton, setIsSecondButton] = useState(false);
  useEffect(() => {
    if (quoteId) {
      if (statusType !== 'OP' || deprecatedCheckTimezoneCountDown(dueDateTime, tzfn) === 'closed')
        history.goBack();
    }
  }, [dueDateTime, history, quoteId, statusType, tzfn]);

  useEffect(() => {
    setBreadcrumb({
      page: 'Supplier Quote Response',
      component: (
        <SupplierQuotesReplycontrol
          quoteId={quoteId}
          GoBackToPage={GoBackToPage}
          pristine={pristine}
          submitting={submitting}
          valid={valid}
          setQuoteSummaryDetails={setQuoteSummaryDetails}
          quoteSummary={quoteSummary}
          submitQuoteReply={submitQuoteReply}
          finalSubmitBreadcrumQuoteReply={() => document.getElementById('submitQuoteBtn')?.click()}
          submitBreadcrumbQuoteReply={() => document.getElementById('continueQuoteBtn')?.click()}
          submitQuoteReplyLater={() => document.getElementById('submitQuoteLaterBtn')?.click()}
        />
      ),
      altname: `Quote Response ${replydetails ? 'Confirmation' : ''}`,
      title: `${quoteName} - Quote Response ${replydetails ? 'Confirmation' : ''}`,
      name: quoteName,
      id: quoteId,
      menuactive: 'quotes',
    });
  }, [
    setBreadcrumb,
    quoteSummary,
    quoteName,
    quoteId,
    GoBackToPage,
    pristine,
    submitting,
    valid,
    setQuoteSummaryDetails,
    submitQuoteReply,
    replydetails,
  ]);

  const declineQuote = () => {
    notSubmitQuote({ quoteId });
    setQuoteSummaryDetails({ declinequotereplymodal: true });
  };

  const onSubmitFn = (data: any, form: any) => {
    if (data) {
      if (isSecondButton) {
        submitQuoteReply({ quoteId, responseId, finishlater: true, data });
      } else {
        if (!replydetails) setQuoteSummaryDetails({ replydetails: true });
      }
    }
  };

  const LineWireForm = (props: any) => {
    const { setFieldValue, values } = props;
    const { linewires = [] } = values;
    let newlineitems = linewires;

    if (replydetails) {
      newlineitems = linewires.map((item: any) => {
        return {
          ...item,
          unitPrice: parseFloat(
            item.unitPrice.toString().replace(/ /g, '').replace('$', '').replace(/,/g, ''),
          ),
          subtotal:
            parseFloat(
              item.unitPrice.toString().replace(/ /g, '').replace('$', '').replace(/,/g, ''),
            ) * parseFloat(item.quantity),
        };
      });
    }
    return (
      <>
        <Table className='tableHData'>
          <tr>
            <th style={tdWidth.desc}>Description</th>
            <th style={tdWidth.othr}>Quantity</th>
            <th style={tdWidth.othr} className='text-right'>
              Price <small>(Per UOM)</small>
            </th>
            {replydetails ? (
              <th style={tdWidth.othr} className='text-right'>
                Subtotal
              </th>
            ) : null}
            <th style={tdWidth.value}>Alternate Quote Value</th>
          </tr>
          {replydetails ? (
            <>
              {newlineitems.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.description}</td>
                  <td>
                    {item.quantity} {item.uom}
                  </td>
                  <td className='text-right'>
                    {item.unitPrice ? `$${formatPrice(item.unitPrice, 3)}` : 'Not Quoted'}
                  </td>
                  <td className='text-right'>
                    {item.subtotal ? `$${formatPrice(item.subtotal, 2)}` : 'Not Quoted'}
                  </td>
                  <td>{item.alternateNotes}</td>
                </tr>
              ))}
            </>
          ) : (
            <>
              {' '}
              {linewires.map((line: any, index: number) => (
                <tr key={index}>
                  <td>
                    <Field
                      label='Description'
                      type='text'
                      classNames='class'
                      placeholder='Description'
                      name={`linewires[${index}].description`}
                      component={renderDisplayField}
                      parentClass='tableTextarea'
                      maxLength={700}
                    />
                  </td>
                  <td>
                    <Field
                      label='Quantity'
                      type='text'
                      classNames='class'
                      placeholder='Quantity'
                      name={`linewires[${index}].quantity`}
                      component={renderDisplayField}
                      maxLength={4}
                    />{' '}
                    <Field
                      label='Unit of Measure'
                      type='text'
                      classNames='class'
                      placeholder='Unit of Measure'
                      name={`linewires[${index}].uom`}
                      component={renderDisplayField}
                      maxLength={50}
                    />
                  </td>
                  <td>
                    <Field
                      label='Price'
                      type='text'
                      step='.001'
                      classNames='class'
                      placeholder='Price'
                      name={`linewires[${index}].unitPrice`}
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      disabled={replyconfirmPage}
                    />
                  </td>
                  <td>
                    <Field
                      label='Alternate Quote Value'
                      type='text'
                      classNames='class'
                      placeholder='Alternate Quote Value'
                      name={`linewires[${index}].alternateNotes`}
                      component={renderTextarea}
                      disabled={replyconfirmPage}
                      onChange={setFieldValue}
                      parentClass='tableTextarea'
                      maxLength={255}
                      remainShow={true}
                    />
                  </td>
                </tr>
              ))}
            </>
          )}
        </Table>
        {replydetails ? (
          <Table className='tableHData'>
            <tr>
              <td width='40%' />
              <td width='10%' />
              <td width='10%' className='text-right'>
                <strong>Total </strong>
              </td>
              <td width='10%' className='text-right'>
                <strong>
                  $
                  {formatPrice(
                    newlineitems.length > 0 &&
                      newlineitems
                        .map((item: any) => (item.subtotal ? item.subtotal : 0))
                        .reduce((a: number, b: number) => a + b),
                    2,
                  )}
                </strong>
              </td>
              <td width='50%' />
            </tr>
          </Table>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const {
            values: any,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValidating,
            validateForm,
            isValid,
            dirty,
          } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <QuoteInfo summary={summary} />
              <h4>Quote Totals</h4>
              <div className='innerColIndent'>
                <LineWireForm values={formikProps.values} setFieldValue={setFieldValue} />

                <small>Subtotals will be rounded up to the nearest cent.</small>
              </div>
              <h4>Supplier Input</h4>
              <div className='innerColIndent'>
                <div className='row '>
                  <div className='col-lg-6 supplier-input'>
                    {replydetails ? (
                      <>
                        <p>Delivery: {delivery}</p>
                        <p>Additional Supplier Details: {additionalSupplierInfo}</p>
                        <p>Quote Prepared By: {preparedBy}</p>
                      </>
                    ) : (
                      <>
                        <Field
                          label='Delivery'
                          type='text'
                          classNames='class'
                          placeholder='Delivery'
                          name='delivery'
                          component={renderDeprecatedTextField}
                          onChange={setFieldValue}
                          optional='(Optional) - Input number of days to deliver after awarded'
                        />
                        <Field
                          label='Additional Supplier Details'
                          type='text'
                          classNames='class'
                          placeholder='Additional Supplier Details'
                          name='additionalSupplierInfo'
                          component={renderTextarea}
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Quote Prepared By'
                          type='text'
                          classNames='class'
                          placeholder='Quote Prepared By'
                          name='preparedBy'
                          component={renderDeprecatedTextField}
                          onChange={setFieldValue}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className='row supplier-profile'>
                  <div className='col-lg-6'>
                    <h6>{mn}</h6>
                    <p>
                      <span>
                        {a1} {a2},{ct} {sta} {ca} {pc}. {p}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className='submission-btn'>
                <div className='row'>
                  {replydetails ? (
                    <>
                      <div className='col-md-4'>
                        <Buttons
                          classNames='bttn-secondary mt-4 w-100'
                          text='Back'
                          type='button'
                          onClick={() => setQuoteSummaryDetails({ replydetails: false })}
                        />
                      </div>
                      <div className='col-md-4'>
                        <Buttons
                          classNames='bttn-primary mt-4 w-100 float-right'
                          text='Submit Quote'
                          type='button'
                          onClick={() =>
                            submitQuoteReply({
                              quoteId,
                              responseId,
                              finishlater: false,
                              data: formikProps.values,
                            })
                          }
                          id='submitQuoteBtn'
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {responseStatus === 'RS' ? null : (
                        <>
                          {responseStatus !== 'NQ' ? (
                            <div className='col-md-4'>
                              <Buttons
                                classNames='bttn-secondary mt-4 w-100'
                                text='Decline Quote'
                                title='Decline Quote'
                                onClick={() => declineQuote()}
                                type='button'
                              />
                              <small>
                                <i>
                                  Declines this quote, but you will continue to receive requests for
                                  other quotes.
                                </i>
                              </small>
                            </div>
                          ) : null}

                          <div className='col-md-4'>
                            <Buttons
                              classNames='bttn-primary mt-4 w-100'
                              text='Submit Quote Later'
                              title='Submit Quote Later'
                              type='button'
                              //disable={pristine || submitting || !valid}
                              onClick={(e: any) => {
                                setIsSecondButton(true);
                                handleSubmit(e);
                              }}
                              id='submitQuoteLaterBtn'
                            />
                            <small>
                              <i>{`Records your status as "Quote Pending". As long as the Quote Due Date has not passed, you may submit a
                      quote later by clicking the link in your notification email & returning to this screen. Any information
                      you have entered will be saved for you to finish later.`}</i>
                            </small>
                          </div>
                        </>
                      )}
                      <div className='col-md-4'>
                        <Buttons
                          classNames='bttn-primary mt-4 w-100'
                          id='continueQuoteBtn'
                          text='Continue'
                          title='Continue'
                          type='submit'
                          onClick={() => {
                            setIsSecondButton(false);
                          }}
                        />
                        <small>
                          <i>
                            Calculates totals and displays the confirmation screen so you can verify
                            your quote before final submission.
                          </i>
                        </small>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default memo(QuotesReplyForm);
