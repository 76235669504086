import { DSLink } from '@demandstar/components/link';
import React from 'react';

import { isValidEmail } from '../../../../utils/helpers/';

export interface EmailProps {
  children: string;
  address?: string;
}

/** Wrap this around an email and it will turn it into an email link (if the email is valid).
 * Does nothing for invalid emails.
 * @example <Email>foo@example.com</Email>
 * @example <Email address='foo@example.com'>Foo</Email>
 */
export const Email = (props: EmailProps) => {
  const { children, address } = props;

  if (isValidEmail(address || children)) {
    return <DSLink href={`mailto:${address || children}`}>{children}</DSLink>;
  }

  return <>{children}</>;
};
