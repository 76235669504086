import React, { memo } from 'react';
import { tollFreeNumber } from '../../../../utils/texts';

function GettingStart() {
  return (
    <>
      <p>
        DemandStar has amazing customer support available from 6 a.m. to 6 p.m. Pacific Daily (9
        a.m. to 9 p.m. Eastern). If you ever have an issue, give us a call at {`${tollFreeNumber}`}{' '}
        and we will walk you through the product and help you. You can also email us (
        <a href='mailto:support@demandstar.com'>support@demandstar.com </a>) and we will respond to
        your request in the order it is received. Note that almost all DemandStar support tickets
        are resolved within 4 hours.
      </p>
      <p>
        <b>Phone Support:</b> {`${tollFreeNumber}`}
      </p>
      <p>
        <b>Help Desk:</b> <a href='mailto:support@demandstar.com'>Support@demandstar.com </a>
      </p>
    </>
  );
}

export default memo(GettingStart);
