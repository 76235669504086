import React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { BidDetailsLink } from '../../../../common/controls/text/links/BidDetailsLink';
import { bidSummaryState } from '../../../../../store/recoil/bidState';
import { defaultNumericId } from '../../../../../shared/constants';
import { hasValue } from '../../../../../utils/helpers';
import { notifyAwardeeTexts } from '../../../../../utils/texts/agency/awardBid';

/**
 * @returns '[Agency Name] has identified documents you are required to review.
 * There may also be additional documentation you must provide.
 * Please go to the Bid Details Page [link] for more information.'
 */
export const ReviewDocsText = () => {
  const bidSummary = useRecoilValueLoadable(bidSummaryState);

  if (hasValue(bidSummary) && bidSummary.contents) {
    return (
      <span>
        {bidSummary.contents.agencyName} {notifyAwardeeTexts.copy.requiredDocs[0]}
        <BidDetailsLink bidId={bidSummary.contents.bidID || defaultNumericId}>
          {notifyAwardeeTexts.copy.requiredDocs[1]}
        </BidDetailsLink>
        {notifyAwardeeTexts.copy.requiredDocs[2]}
      </span>
    );
  }
  return null;
};
