import React, { memo, useCallback, useEffect, useState } from 'react';

export interface DocError {
  row: number;
  error: string;
  errortype?: 'format';
}
interface DocumentFileTypeErrorProps {
  documentTypeError?: DocError;
  setFileTypeModalVisible: (status: boolean) => void;
}

const DocumentFileTypeError = (props: DocumentFileTypeErrorProps) => {
  const { documentTypeError, setFileTypeModalVisible } = props;
  return (
    <>
      {documentTypeError ? (
        <tr>
          <td colSpan={5} className='text-red200 sourceSemiBold'>
            {documentTypeError.error}
            {documentTypeError.errortype === 'format' && (
              <>
                {' '}
                <span
                  className='cursorPointer underLine'
                  onClick={() => setFileTypeModalVisible(true)}
                >
                  Please use a supported file type
                </span>
              </>
            )}
          </td>
        </tr>
      ) : (
        ''
      )}
    </>
  );
};

export default memo(DocumentFileTypeError);
