import React from 'react';

import { BaseLinkA, BaseLinkSpan } from './DSLinkStyles';

export interface BaseDSLinkProps {
  children: string;
  large?: boolean;
  contrast?: boolean;
  dataTestId?: string;
}

export type HrefLinkProps = BaseDSLinkProps & {
  href: string;
  newTab?: boolean;
  onClick?: never;
};
export type OnClickLinkProps = BaseDSLinkProps & {
  href?: never;
  newTab?: never;
  onClick: () => void;
};
export type DSLinkProps = HrefLinkProps | OnClickLinkProps;

/** Wrap this around a clickable string and it will turn it into a link.
 * @example <Link href='www.google.com'>Google</Link>
 */
export const DSLink = (props: DSLinkProps) => {
  const { children, href, newTab, onClick, large, contrast, dataTestId } = props;

  if (href) {
    return (
      <BaseLinkA
        href={href}
        target={newTab ? '_blank' : '_self'}
        large={large}
        contrast={contrast}
        data-testid={dataTestId}
      >
        {children}
      </BaseLinkA>
    );
  } else {
    return (
      <BaseLinkSpan onClick={onClick} large={large} contrast={contrast} data-testid={dataTestId}>
        {children}
      </BaseLinkSpan>
    );
  }
};
