import { connect, ConnectedProps } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../store/actions';
import history from '../../utils/history';

const ASPAgency = (props: PropsFromRedux) => {
  const { getLimitedAgencyDetails, limited } = props;
  const [memberId, setMemberId] = useState('');
  const [bidId, setBidId] = useState('');
  const { limitedAgencyDetails = {} } = limited;
  const queryString = window.location.search.toLowerCase();

  useEffect(() => {
    const bidStringArray = queryString.split('bi=');
    if (bidStringArray.length > 1) {
      const InbidId =
        bidStringArray.length > 0 ? bidStringArray[bidStringArray.length - 1] : 'Not Exist';
      setBidId(InbidId);
    } else {
      const qStringArray = queryString.split('mi=');
      const InmemberId =
        qStringArray.length > 0 && queryString.includes('mi=')
          ? qStringArray[qStringArray.length - 1]
          : 'Not Exist';
      setMemberId(InmemberId);
    }
  }, [queryString]);

  useEffect(() => {
    if (bidId === 'Not Exist' && memberId === 'Not Exist') {
      history.push('login');
    } else if (bidId && bidId !== 'Not Exist') {
      history.push(`limited/bids/${bidId}/details`);
    } else if (memberId && memberId !== 'Not Exist') {
      getLimitedAgencyDetails({ memberId });
    }
  }, [bidId, memberId, getLimitedAgencyDetails]);

  useEffect(() => {
    if (limitedAgencyDetails && limitedAgencyDetails.escapedAgencyName) {
      history.push(
        `agencies/${limitedAgencyDetails.state}/${limitedAgencyDetails.escapedAgencyName}/procurement-opportunities/${limitedAgencyDetails.id}`,
      );
    }
  }, [limitedAgencyDetails, limitedAgencyDetails.escapedAgencyName]);

  return <></>;
};

const connector = connect(
  (state: any) => ({
    limited: state.limited,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(ASPAgency));
