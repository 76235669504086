import { deleteRequiredDocumentResponse } from '../../../store/services';

import { RequiredDocumentResponseDeleteAPIRequest } from '../../../types';
// import { requiredDocumentResponseState } from '../../../store/recoil/award-bid';
import { track } from '../../../utils/telemetry';

/**
 * Delete the specified RequiredDocumentResponse, then refresh the list.
 * @description The callback is returned from the hook and requires params:
 * @param {RequiredDocumentResponseDeleteAPIRequest} requestParams
 * @returns {Promise<RequiredDocumentResponseUploadAPIResponse>}
 */
export async function deleteRequiredDocumentResponseFromService({
  bidId,
  bidAwardRequiredDocResponseId,
  memberId,
}: // opsAccountId,
RequiredDocumentResponseDeleteAPIRequest) {
  try {
    const response = await deleteRequiredDocumentResponse({
      bidAwardRequiredDocResponseId,
      bidId,
      isDelete: true,
      memberId,
      // opsAccountId,
    });
    return response;
  } catch (error: any) {
    const errorMessage = `useDeleteRequiredDocumentResponse -> deleteRequiredDocumentResponse(bidAwardRequiredDocResponseId: ${bidAwardRequiredDocResponseId}, memberId: ${memberId}, bidId: ${bidId}) ERROR:`;
    track(errorMessage, {
      error,
      method: 'POST',
      errorMessage: error.message,
    });
    console.error(`${errorMessage}: \n${error}`);
  }
}
