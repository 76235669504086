import { useEffect, useState } from 'react';

import { compareObjectsByKey } from '../../utils';
import { sortDirections } from '../../utils/constants';

// Type declarations - ASC | DESC
export type SortDirection = Exclude<sortDirections, sortDirections.noSort>;

/**
 * Mapped type to convert a supplied generic list item type `T`
 * a label / value pair for use in a select control.
 */
export type SelectOption<T> = {
  label: T[keyof T] | string;
  value: keyof T;
  selected?: boolean;
  initialSortOrder?: sortDirections;
};

/**
 *  data: T[];                            // Generic typed Array of data items to be sorted
 *  onSortChange(data: T[]): void;        // Callback function to access the hook and perform the sort on the list.
 *  sortOptions: Selectable<keyof T>[];   // Generic typed Array of select options in the format of a Selectable object
 */
export type SortProps<T> = {
  data: T[];
  onSortChange(data: T[]): void;
  sortOptions: SelectOption<T>[];
};

export function useSort<T>({ data, onSortChange, sortOptions }: SortProps<T>) {
  // Local state
  const initialSelectedOption =
    sortOptions.find((item: SelectOption<T>) => item.selected === true) || sortOptions[0];
  const initialSortOrder = initialSelectedOption.initialSortOrder
    ? initialSelectedOption.initialSortOrder
    : sortDirections.ASC;
  const [sortDirection, setSortDirection] = useState(initialSortOrder);
  const initialSortKey = initialSelectedOption.value as keyof T;
  const [sortKey, setSortKey] = useState<keyof T>(initialSortKey);
  const [sortChanged, setSortChanged] = useState<boolean>(true);

  // Execute the sort and callback when local state
  // or supplied props have changed.
  useEffect(() => {
    // Create a copy before sorting, as the original array is frozen in strict mode.
    const sortedData = [...data];
    // Sort the data if the `sortChanged` flag has been set to true.
    if (sortChanged === true && sortedData?.length) {
      sortedData.sort(compareObjectsByKey(sortKey, sortDirection === sortDirections.ASC));

      if (onSortChange) {
        onSortChange(sortedData);
      }

      // Reset the `sortChanged` flag.
      setSortChanged(false);
    }
  }, [data, onSortChange, sortChanged, sortDirection, sortKey]);

  /**
   * Handle changes to the sort key.
   * @param selectedKey: keyof T
   */
  const handleKeyChange = (newSortKey: keyof T) => {
    if (newSortKey && sortKey !== newSortKey) {
      setSortChanged(true);
      setSortKey(newSortKey);
    }
  };

  /**
   * Handle changes to the sort direction.
   */
  const handleDirectionToggle = () => {
    setSortDirection(
      sortDirection === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC,
    );
    setSortChanged(true);
  };

  return {
    handleKeyChange,
    handleDirectionToggle,
    sortDirection,
    sortKey,
  };
}
