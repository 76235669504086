import React from 'react';

import { NotFoundText, NotFoundTextWrapper } from '../../../shared/styles';

import { commonLabels } from '../../../shared/constants';
import { InfoIcon } from '../icons';

export interface NotFoundPanelProps {
  message?: string;
}
export function NotFoundPanel(props: NotFoundPanelProps) {
  return (
    <NotFoundTextWrapper>
      <InfoIcon width={18} height={18} />
      <NotFoundText>{props.message || commonLabels.notFound}</NotFoundText>
    </NotFoundTextWrapper>
  );
}
