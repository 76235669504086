import { FormProvider, useForm } from 'react-hook-form';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable, useRecoilValue } from 'recoil';

import AddressLookup, { AddressInfo } from '../../common/formcomponents/AddressLookup';
import { appKeys, registrationComponent } from '../../../utils/constants';
import { ProductApiResponse, SubscriptionProducts } from '../../../types/products';
import { actionPayloadTypes } from '../../../types/actiontypedef';
import { addMemberAccount, updateMemberAddress } from '../../../store/services';
import { agenciesByProductState } from '../../../store/recoil/agencyState';
import AgencyList from '../AgencyList';
import { Buttons } from '../../customcontrols';
import Cart from '../../products/Cart';
import { DemandStarSupportEmail } from '../../../utils/texts/common/emails';
import LogoDSLoader from '../../../assets/images/loader';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import PaymentMethod from './paymentmethod';
import { processSubscriptionPayment } from '../../../store/recoil/subscriptionState';
import { recoilRegistrationDataState } from '../../../store/recoil/registrationState';
import { ReduxAction } from '../../../store/actions/utils';
import { RegistrationData } from '../../../types/supplierregistration';
import { selectedProductsState } from '../../../store/recoil/productState';
import { tollFreeNumber } from '../../../utils/texts';
import { useAmplitude } from '../../amplitude';
import { useDSSelector } from '../../../store/reducers';

export interface BillingInfoFormInputs {
  addressInfo: AddressInfo;
}

interface ReviewOrderProps {
  setRegistrationData: (payload: actionPayloadTypes) => ReduxAction;
}

const ReviewOrder = (props: ReviewOrderProps) => {
  const { setRegistrationData } = props;
  const { logEvent } = useAmplitude();
  const [registrationDataLoadable] = useRecoilStateLoadable(recoilRegistrationDataState);
  const [agenciesByProduct, setAgenciesByProduct] = useRecoilState<ProductApiResponse[]>(
    agenciesByProductState,
  );
  const [recoilRegistrationData, setRecoilRegistrationData] = useRecoilState<RegistrationData>(
    recoilRegistrationDataState,
  );
  const selectedProducts = useRecoilValue<SubscriptionProducts>(selectedProductsState);

  const registrationData = useDSSelector(state => state.registration.registrationData);

  const [paymentErrorModal, setPaymentErrorModal] = useState<boolean>(false);
  const [paymentInstance, setPaymentInstance] = useState('' as any);
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
  const [showAgencyModal, setShowAgencyModal] = useState(false);

  const header = `${appKeys.headers.paymentMethod} - Subscription - ${appKeys.name}`;

  const closeModal = () => {
    setAgenciesByProduct([]);
    setShowAgencyModal(false);
  };

  const createMemberAccount = useCallback(async () => {
    try {
      return await addMemberAccount({
        account: recoilRegistrationData.account,
        member: recoilRegistrationData.member,
        memberAddress: recoilRegistrationData.memberAddress,
        memberContact: recoilRegistrationData.memberContact,
        memberContactPhone: recoilRegistrationData.memberContactPhone,
        memberPhones: recoilRegistrationData.memberPhones,
      });
    } catch (error) {
      console.error(`createMemberAccount -> addMemberAccount() ERROR: \n${error}`);
    }
  }, [recoilRegistrationData]);

  const methods = useForm<BillingInfoFormInputs>({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { getValues, setValue, trigger, formState } = methods;

  const moveBack = () => {
    setRegistrationData({
      currentComponent: registrationComponent.CompleteProfile,
    });
  };

  const moveForward = async () => {
    await trigger();

    try {
      const token: { nonce: string } = await paymentInstance.requestPaymentMethod();

      // I shouldn't have to do this formState.errors.length check
      // According to RHF docs, formState.isValid should be false if errors
      // are present. However, placing a debug between the trigger and this if
      // revealed that formState.isValid would be true while errors are present
      // and the form would be allowed to proceed with errors

      /* istanbul ignore else */
      if (Object.keys(formState.errors).length === 0 && formState.isValid && token?.nonce) {
        onPaymentSubmit(token);
      }
    } catch (e) {
      window.scrollTo(0, 0);
    }
  };

  const updateBillingAddress = (memberId: number) => {
    const billingAddressData: AddressInfo = {
      ...recoilRegistrationData.billingAddress,
      address1: String(getValues('addressInfo.address1')).trim(),
      address2: String(getValues('addressInfo.address2')).trim(),
      addressType: 'BA',
      city: String(getValues('addressInfo.city')).trim(),
      county: getValues('addressInfo.county'),
      country: getValues('addressInfo.country'),
      countryId: getValues('addressInfo.countryId'),
      countyId: getValues('addressInfo.countyId'),
      countyName: getValues('addressInfo.countyName'),
      postalCode: getValues('addressInfo.postalCode'),
      stateId: getValues('addressInfo.stateId'),
      stateName: getValues('addressInfo.stateName'),
      state: getValues('addressInfo.state'),
    };
    updateMemberAddress({...billingAddressData, MemberId: memberId});
  };

  const onPaymentSubmit = async (token: { nonce: string }) => {
    setPaymentProcessing(true);
    const memberAccount = await createMemberAccount();
    const memberId = memberAccount?.data.result.memberId;

    updateBillingAddress(memberId);

    const payload = {
      ...recoilRegistrationData,
      freeAgency: registrationData.freeAgency,
      freeAgencyInfo: registrationData.freeAgencyInfo,
      memberId,
      pagefor: 'registration',
      selectedProducts,
      token: token.nonce,
    };

    const result = await processSubscriptionPayment(payload);

    setRecoilRegistrationData({ ...recoilRegistrationData, token: token.nonce });
    logEvent('RegistrationAddPayment', recoilRegistrationData);

    if (result.paymentErrorModal) {
      setPaymentErrorModal(true);
      setPaymentProcessing(false);
      // reset to show editable braintree interface
      // paymentInstance.clearSelectedPaymentMethod();
    } else {
      setRegistrationData({ currentComponent: registrationComponent.AccountConfirmation });
    }
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (registrationDataLoadable.state === 'hasValue') {
      setRecoilRegistrationData(registrationDataLoadable.contents);
    }
  }, [registrationDataLoadable, setRecoilRegistrationData]);

  // If the product selection equals a free account we don't need to gather
  // payment information, so we will just create the account and move on to the
  // profile completion.
  useEffect(() => {
    /* istanbul ignore else */
    if (
      selectedProducts?.national === 0 &&
      selectedProducts?.county?.length === 0 &&
      selectedProducts?.state?.length === 0
    ) {
      createMemberAccount();
      setRegistrationData({
        currentComponent: registrationComponent.AccountConfirmation,
      });
    }
  }, [createMemberAccount, selectedProducts, setRegistrationData]);

  useEffect(() => {
    let needModal = true;
    /* istanbul ignore else */
    if (agenciesByProduct.length > 0 && needModal) {
      setShowAgencyModal(true);
    }

    return () => {
      needModal = false;
    };
  }, [agenciesByProduct]);

  /*
   * This sets the initial formState equal to recoilRegistrationData
   */
  useEffect(() => {
    /* istanbul ignore else */
    if (recoilRegistrationData.memberAddress) {
      const memberAddress = recoilRegistrationData.memberAddress;
      Object.keys(memberAddress).forEach(key => {
        const typedKey = key as keyof typeof memberAddress;
        /* istanbul ignore else */
        if (memberAddress[typedKey] !== undefined) {
          let fieldValue = memberAddress[typedKey];
          if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.trim();
          }
          setValue(`addressInfo.${typedKey}`, fieldValue);
        }
      });
    }
  }, [recoilRegistrationData, setValue]);

  /**
   * This scrolls to the top of the page on load.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container'>
      <div className='row regWrapper'>
        <div className='col-lg-12'>
          <div className='colWrapper container'>
            <div className='row'>
              <div className='col col-9'>
                <h2 className='arrowWrapper'>Review and Complete Order</h2>
                <p className='reg-intro'>
                  You&apos;re almost there! Review the information on this page and enter a credit
                  card, and get ready to find new business opportunities on DemandStar.
                </p>

                <FormProvider {...methods}>
                  <div className='row mb-5'>
                    <div className='col-md-12 col-lg-12'>
                      <h4 className='no-bottom-margin'>Payment</h4>
                      <div className='w-100'>
                        <PaymentMethod setPaymentInstance={setPaymentInstance} title={header} />
                      </div>
                    </div>
                    <div className='col-md-12 col-lg-12 pt-4'>
                      <h4 className='no-bottom-margin'>Billing Address:</h4>
                      <AddressLookup />
                    </div>
                  </div>
                </FormProvider>
                <div className='row'>
                  <div className='col-12'>
                    <div className='px-6'>
                      <Cart isReview={true} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <Buttons
                      classNames='bttn bttn-secondary m-auto'
                      text={'Go Back'}
                      onClick={() => moveBack()}
                    />
                    <Buttons
                      classNames='bttn bttn-accent m-auto bttn-continue'
                      text={'Complete Order'}
                      onClick={() => moveForward()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp size='lg' title='Agency List' closeModal={closeModal} isOpen={showAgencyModal}>
        <AgencyList agencies={agenciesByProduct} />
      </ModalPopUp>
      <ModalPopUp
        title='Payment Error'
        size='lg'
        isOpen={paymentErrorModal}
        closeModal={() => {
          setPaymentErrorModal(!paymentErrorModal);
          setRegistrationData({ currentComponent: registrationComponent.AccountConfirmation });
        }}
      >
        <p>
          We created your DemandStar account, but there was a problem with your credit card, and we
          weren&apos;t able to add your subscriptions.
        </p>
        <p></p>
        <p>
          Log in to your new account, click your name in the top right and select
          &quot;Subscriptions&quot; to add your subscriptions.
        </p>
        <p></p>
        <p>
          If your payment still does not process, please contact DemandStar at <br />
          {DemandStarSupportEmail} or {tollFreeNumber}.
        </p>
        <Buttons
          classNames='bttn bttn-accent m-auto bttn-continue'
          text={'Continue'}
          onClick={() => {
            setPaymentErrorModal(!paymentErrorModal);
            setRegistrationData({ currentComponent: registrationComponent.AccountConfirmation });
          }}
        />
      </ModalPopUp>
      {paymentProcessing && (
        <div className='overlay'>
          <div className='progressbox'>
            <div>
              <LogoDSLoader alt='Processing Payment' />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ReviewOrder);
