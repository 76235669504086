import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';
import Api from './api';

export function getToursConfigurations(data = {}) {
  return Api.postRequestWithAuthentication(Paths.tourget, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function submitToursConfigurations(data = {}) {
  return Api.postRequestWithAuthentication(Paths.tourupdate, data, {
    baseURL: constants.api.trackUrl,
  });
}
