import React from 'react';
import { ProductApiResponse } from '../../types/products';
/* 
interface Agency {
  prouctId: number;
  productName: string;
}
 */
interface AgencyListProps {
  agencies: ProductApiResponse[];
}

const AgencyList = (props: AgencyListProps) => {
  const { agencies } = props;
  return (
    <div className='prodAgencyList clearfix'>
      <ul className='list-unstyled'>
        {agencies.map((agency: ProductApiResponse) => (
          <li key={agency.productId}>{agency.productName}</li>
        ))}
      </ul>
    </div>
  );
};

export default AgencyList;
