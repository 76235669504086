// TOREFACTOR: Change all 'trial's to 'trail'.

import React, { memo, useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import { bidItemParamType, bidssummaryParamType } from '../../../../types/biddetail';
import {
  deprecatedGetDate,
  deprecatedGetDateTimeZoneConverted,
  deprecatedGetDateTimeZoneConvertedDate,
  useAssert,
} from '../../../../utils/helpers';
import { DeprecatedInput, Paging, SelectBox } from '../../../customcontrols';
import { pageSize, sortDirections, usaDateFormat } from '../../../../utils/constants';
import { DeprecatedReduxDatepicker } from '../../../customcontrols/DeprecatedReduxDatepicker';
import { loadingMsg } from '../../../../utils/texts';
import NoResult from '../../../customcontrols/noresult';
import { QuoteSummaryType } from '../../../../types/quotedetailstypedef';
import { sharedTypes } from '../../../../types/shared';
import SortingIcon from '../../../common/sortingIcon';

const tdWidth = {
  initiated: {
    width: '150px',
  },
  actionType: {
    width: '145px',
  },
  date: {
    width: '180px',
  },
};

const EventArray = ['Bid Event', 'Quote Event'];

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  setFilteredAuditTrialList?: any;
  loadBidsAuditTrial?: any;
  pagefor?: string;
  quoteId?: string | number | undefined;
  bidID?: number;
  shared?: sharedTypes;
  quoteSummary?: QuoteSummaryType;
}

function Audittrail(props: PropsTypes) {
  const {
    bidssummary,
    setFilteredAuditTrialList,
    loadBidsAuditTrial,
    pagefor = '',
    quoteId,
    bidID,
    shared,
    quoteSummary,
  } = props;
  const {
    auditTrialCategories = [],
    auditTrialQuoteCategories = [],
    auditTrials,
    FilteredAuditTrialList = [],
    results,
  } = bidssummary || {};
  const { summary } = quoteSummary || {};
  const { tzfn: bidTimeZone } = (results as bidItemParamType) || {};
  // TOREFACTOR: These abbreviations should be mapped to readable names
  // BidItemParamType / BidSummaryResults need to be combined as well.
  // If you are feeling very ambitious, we should try to do away with bidsummary.results altogether.
  const { tzfn: quoteTimeZone } = summary || {};
  const { internalLoader } = shared || {};
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.auditTrail;

  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const id = pagefor === 'addquotes' ? quoteId : bidID;
  const type = pagefor === 'addquotes' ? 'Quote' : 'Bid';

  const tzfn = type === 'Quote' ? quoteTimeZone : bidTimeZone;
  useAssert(!!tzfn, 'Timezone should be defined');

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState(type === 'Bid' ? 'Bid Event' : 'Quote Event');
  const [changeDate, setChangeDate] = useState('');

  const filterResult = useCallback(
    (category, search = '', changeDate = '') => {
      let audit_trials = auditTrials ? auditTrials.result : [];
      if (category) {
        audit_trials = audit_trials.filter(item => item.category === category);
      }
      if (changeDate) {
        audit_trials = audit_trials.filter(
          item =>
            deprecatedGetDateTimeZoneConvertedDate(item.modified, tzfn) ===
            deprecatedGetDate(changeDate),
        );
      }
      if (search.trim()) {
        if (EventArray.includes(category))
          audit_trials = audit_trials.filter(item =>
            item.type.toLowerCase().includes(search.trim().toLowerCase()),
          );
        else
          audit_trials = audit_trials.filter(item =>
            item.name.toLowerCase().includes(search.trim().toLowerCase()),
          );
      }
      setFilteredAuditTrialList(audit_trials);
    },
    [auditTrials, setFilteredAuditTrialList, tzfn],
  );

  useEffect(() => {
    filterResult(category, search, changeDate);
  }, [category, changeDate, filterResult, search]);

  const changeAuditCategory = (key: any, value: string) => {
    if (type === 'Bid') {
      auditTrialCategories.map((item, index) => (auditTrialCategories[index].selected = false));
      auditTrialCategories[key].selected = true;
    } else {
      auditTrialQuoteCategories.map(
        (item, index) => (auditTrialQuoteCategories[index].selected = false),
      );
      auditTrialQuoteCategories[key].selected = true;
    }
    loadBidsAuditTrial({ id, type });
    setCurrentPage(1);
    setSearch('');
    setChangeDate('');
    setCategory(value);
    filterResult(value);
    setSortOrder(sortDirections.ASC);
    setSortValue('modified');
  };

  const onChangeDate = (key: string, value: string) => {
    setChangeDate(value);
    filterResult(category, search, value);
  };

  const filterByEditorName = (name: string, value: string) => {
    setSearch(value);
    filterResult(category, value, changeDate);
  };

  //Logic for sort
  const [sortValue, setSortValue] = useState('modified');
  const [sortOrder, setSortOrder] = useState(sortDirections.ASC);
  return (
    <>
      <div className='row auditTrailWrapper'>
        <div className='col-lg-12'>
          <h3 className='arrowWrapper' data-testid='biddetails.audittrail.head'>
            Bid Actions
          </h3>
          <div className='innerColIndent'>
            <div className='headerFilter clearfix d-flex align-items-center py-3'>
              <SelectBox
                label='Category'
                options={type === 'Bid' ? auditTrialCategories : auditTrialQuoteCategories}
                onChange={changeAuditCategory}
              />
              <DeprecatedInput
                type='search'
                placeholder={
                  EventArray.includes(category) ? 'Search by Action Type' : 'Search by Initiated By'
                }
                label='Filter'
                parentClass='mx-3'
                handleChange={filterByEditorName}
                value={search}
              />
              <DeprecatedReduxDatepicker
                label='Filter Date'
                name='filterdate'
                handleChange={onChangeDate}
                isOutsideRange={true}
                value={changeDate}
                showClearDate={true}
                optional={`(e.g. "${moment().format(usaDateFormat)}")`}
              />
            </div>
            {FilteredAuditTrialList.length > 0 ? (
              <Table responsive className='tableHData'>
                <tbody>
                  <tr data-testid='biddetails.audittrail.listhead'>
                    <th style={tdWidth.initiated}>
                      Initiated By
                      <SortingIcon
                        sortlist={FilteredAuditTrialList}
                        initialsortorder={sortOrder}
                        currentsortname={sortValue}
                        changesortname={(value: string) => setSortValue(value)}
                        sortname='name'
                        changeSortingFn={(list: any) => setFilteredAuditTrialList(list)}
                      />
                    </th>
                    {EventArray.includes(category) && (
                      <th style={tdWidth.actionType}>
                        Action Type
                        <SortingIcon
                          sortlist={FilteredAuditTrialList}
                          initialsortorder={sortOrder}
                          currentsortname={sortValue}
                          changesortname={(value: string) => setSortValue(value)}
                          sortname='type'
                          changeSortingFn={(list: any) => setFilteredAuditTrialList(list)}
                        />
                      </th>
                    )}
                    <th style={tdWidth.date}>
                      Date
                      <SortingIcon
                        sortlist={FilteredAuditTrialList}
                        initialsortorder={sortOrder}
                        currentsortname={sortValue}
                        changesortname={(value: string) => setSortValue(value)}
                        sortname='modified'
                        changeSortingFn={(list: any) => setFilteredAuditTrialList(list)}
                      />
                    </th>
                    <th>Summary</th>
                  </tr>
                  {FilteredAuditTrialList.map((item, index) => (
                    <tr key={index} data-testid='biddetails.audittrail.listbody'>
                      <td>{item.name}</td>
                      {EventArray.includes(category) && <td>{item.type}</td>}
                      <td>{deprecatedGetDateTimeZoneConverted(item.modified, tzfn)}</td>
                      <td>{item.summary}</td>
                    </tr>
                  )).slice(indexOfFirstList, indexOfLastList)}
                </tbody>
              </Table>
            ) : (
              <NoResult message={internalLoader ? loadingMsg : 'No Audits Available'} />
            )}
          </div>
        </div>
      </div>
      <Paging
        totalRecords={FilteredAuditTrialList.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
}

export default memo(Audittrail);
