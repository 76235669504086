import React, { memo, useCallback, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import {
  memberTypes,
  pageSize,
  sortDirections,
  userPermissionKeys,
} from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import { hasPermission } from '../../../../../utils/helpers';
import SortingIcon from '../../../../common/sortingIcon';
import { Buttons, DeprecatedInput, NoResult, Paging, SelectBox } from '../../../../customcontrols';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import RecoverPassword from './recoverpassword';

type PermissionType = { buyerSelectable: boolean; supplierSelectable: boolean };
type filterResultType = {
  firstName: string;
  lastName: string;
  username: string;
  jobTitle: string;
  email: string;
};
type AccountsListType = Array<{
  active: boolean;
  notifiy: boolean;
  firstName: string;
  lastName: string;
  mainContact: boolean;
  jobTitle: string;
  notify: boolean;
  username: string;
  accountId: number;
  exceededBidVisit: boolean;
}>;

const UserAccount = (props: any) => {
  const { history, memberinfo, auth, setBreadcrumb, setAccountInfoDetails } = props;
  const {
    userAccounts = [],
    permissions,
    recoveryPopup = false,
    useraccounts_internalLoader,
  } = props && props.accountinfo;
  const { mt: memberType, prms } = memberinfo;
  const accountinfo = props && props.accountinfo && props.accountinfo.accountinfo;
  const { prms: authPerms, mc: isPrimaryContact = false, opi = '' } = auth;
  const accountPermissions = prms ? prms : authPerms;

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.manageAccounts) || isPrimaryContact;

  const [filteredUserAccounts, setFilteredUserAccounts] = useState(
    [] as Array<Record<string, unknown>>,
  );
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(opi ? 'all' : 'active');

  const Addedituser = useCallback(
    (user, permissions) => {
      const userinfo = user ? user : '';
      history.push({
        pathname: `/${
          memberType === memberTypes.agencyBuyer ? 'buyers' : 'suppliers'
        }/accountinfo/useraccounts/userform`,
        state: { userinfo, permissions, accountinfo, userAccounts, memberType },
      });
    },
    [accountinfo, history, memberType, userAccounts],
  );

  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let applicablePermissions = [] as Array<Record<string, unknown>>;
  if (permissions && permissions.length > 0) {
    if (memberType === memberTypes.agencyBuyer) {
      applicablePermissions = permissions.filter(
        (permission: PermissionType) => permission.buyerSelectable,
      );
    } else if (
      memberType === memberTypes.subscribingSupplier ||
      memberType === memberTypes.basicSupplier ||
      memberType === memberTypes.agencyCreatedPlanholder ||
      memberType === memberTypes.supplementalSupplier
    ) {
      applicablePermissions = permissions.filter(
        (permission: PermissionType) => permission.supplierSelectable,
      );
    } else {
      applicablePermissions = permissions.filter(
        (permission: PermissionType) => !permission.buyerSelectable,
      );
    }
  }

  const AddEditUserFn = useCallback(() => {
    Addedituser('', applicablePermissions);
  }, [Addedituser, applicablePermissions]);

  useEffect(() => {
    const control = hasEditMemberPermission ? (
      <li>
        <span
          className='staticLink addBid'
          onClick={event => {
            event.preventDefault();
            AddEditUserFn();
          }}
        >
          <i className='mdi mdi-plus mr-1' /> Add User
        </span>
      </li>
    ) : null;
    setBreadcrumb({
      component: control,
      page: 'Account Info',
      title: 'User Accounts',
      altname: 'User Accounts',
      name: 'User Accounts',
      menuactive: '',
    });
    // eslint-disable-next-line
  }, [setBreadcrumb]); // TOREFACTOR: Don't ignore this.

  const handleRecoverPassword = (userName: string) => {
    if (opi) {
      setAccountInfoDetails({ selectedUserEmail: userName, recoveryPopup: !recoveryPopup });
    } else {
      const { forgotPassword } = props;
      forgotPassword({ userName });
    }
  };

  const setRecoveryPasswordPopup = () => {
    setAccountInfoDetails({ recoveryPopup: !recoveryPopup });
  };

  const handleReactivateUserAccount = (accountId: number, status: boolean) => {
    const { reactivateUserAccount } = props;
    reactivateUserAccount({ accountId, status });
    setTimeout(() => finalFilterResult(search, status), 1000);
  };

  const finalFilterResult = useCallback(
    (search, status) => {
      let filterResult: Array<{ active: boolean }> = userAccounts;
      if (search) {
        filterResult = userAccounts.filter(
          (item: filterResultType) =>
            (item.firstName && item.firstName.toLowerCase().includes(search.toLowerCase())) ||
            (item.lastName && item.lastName.toLowerCase().includes(search.toLowerCase())) ||
            (item.username && item.username.toLowerCase().includes(search.toLowerCase())) ||
            (item.jobTitle && item.jobTitle.toLowerCase().includes(search.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(search.toLowerCase())),
        );
      }

      if (status === 'active') {
        filterResult = filterResult.filter(item => item.active === true);
      } else if (status === 'inactive') {
        filterResult = filterResult.filter(item => item.active === false);
      }

      setFilteredUserAccounts(filterResult);
      setCurrentPage(1);
      setSortorder(sortDirections.ASC);
    },
    [userAccounts],
  );

  const filterUserAccounts = useCallback(
    (name, value) => {
      setSearch(value);
      finalFilterResult(value, status);
    },
    [finalFilterResult, status],
  );

  const changeUserStatus = (key: any, value: any) => {
    if (status !== value) {
      setStatus(value);
      statesList.map((item, index) => (statesList[index].selected = false));
      statesList[key].selected = true;
      finalFilterResult(search, value);
    }
  };

  useEffect(() => {
    if (userAccounts.length > 0) filterUserAccounts('search', '');
  }, [filterUserAccounts, userAccounts.length]);

  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const useraccountlist = filteredUserAccounts as AccountsListType;

  const activeAccounts = useraccountlist ? useraccountlist.filter(acc => acc.active) : [];

  const statuses = [
    { title: 'All', id: 0, value: 'all', key: 0, selected: opi ? true : false },
    { title: 'Active', id: 1, value: 'active', key: 1, selected: opi ? false : true },
    { title: 'Inactive', id: 2, value: 'inactive', key: 2, selected: false },
  ];

  const [statesList] = useState(statuses);

  // Sort
  const [sortvalue, setSortvalue] = useState('firstName' as string);
  const [sortorder, setSortorder] = useState(sortDirections.ASC);

  return (
    <>
      <div className='row'>
        <div className='col-md-8'>
          <h3 className='arrowWrapper'>User Accounts</h3>
          <h4>
            {status === 'all'
              ? `Current Active Accounts (${activeAccounts ? activeAccounts.length : 0}/${
                  useraccountlist.length
                })`
              : status === 'active'
              ? `Current Active Accounts (${activeAccounts ? activeAccounts.length : 0})`
              : `Current Inactive Accounts (${useraccountlist.length})`}
            {/* <span
							className='mdi mdi-plus float-right staticLink'
							onClick={() => Addedituser('', applicablePermissions)}>
							{' '}
							Add User
						</span> */}
          </h4>
          <div className='clearfix d-flex align-items-center'>
            <DeprecatedInput
              type='search'
              placeholder={'Search by Account Name, Email, Title and User name'}
              label='Filter'
              parentClass='mr-2 w-50 my-3'
              handleChange={filterUserAccounts}
              value={search}
            />
            <SelectBox
              label='Status'
              parentClass='minWidth_120'
              options={statesList}
              onChange={changeUserStatus}
            />
          </div>
          {useraccountlist && useraccountlist.length > 0 ? (
            <>
              <Table>
                <tr>
                  <th>Primary</th>
                  <th>
                    Account Name
                    <SortingIcon
                      sortlist={filteredUserAccounts}
                      initialsortorder={sortorder}
                      currentsortname={sortvalue}
                      changesortname={(value: string) => setSortvalue(value)}
                      sortname='firstName'
                      changeSortingFn={(list: Record<string, unknown>) =>
                        setAccountInfoDetails({ userAccounts: list })
                      }
                    />
                  </th>
                  <th>Title</th>
                  <th>Notified</th>
                  <th>Active</th>
                  <th />
                  <th />
                  <th />
                </tr>

                {useraccountlist ? (
                  <>
                    {useraccountlist
                      .map((account, index) => (
                        <tr key={index}>
                          <td>{account.mainContact ? <span className='successTick' /> : ' '}</td>
                          <td>{`${account.firstName} ${account.lastName}`}</td>
                          <td>{account.jobTitle}</td>
                          <td>{account.notify ? <span className='successTick' /> : ' '}</td>
                          <td>
                            {account.active ? (
                              <span className='successTick' />
                            ) : (
                              <span className='errorCross' />
                            )}
                          </td>
                          <td>
                            {account.active ? (
                              <span
                                className='staticLink'
                                onClick={() => handleRecoverPassword(account.username)}
                              >
                                Recover Password
                              </span>
                            ) : null}
                          </td>
                          <td>
                            {account.mainContact ? (
                              !account.active && opi && !account.exceededBidVisit ? (
                                <i
                                  className='mdi mdi-backup-restore staticLink mdi-24px'
                                  title='Activate User'
                                  onClick={() =>
                                    handleReactivateUserAccount(account.accountId, account.active)
                                  }
                                />
                              ) : null
                            ) : account.active ? (
                              hasEditMemberPermission ? (
                                <i
                                  className='mdi mdi-delete-forever-outline staticLink mdi-24px'
                                  title='Deactivate User'
                                  onClick={() =>
                                    handleReactivateUserAccount(account.accountId, account.active)
                                  }
                                />
                              ) : null
                            ) : account.exceededBidVisit ? null : (
                              <i
                                className='mdi mdi-backup-restore staticLink mdi-24px'
                                title='Activate User'
                                onClick={() =>
                                  handleReactivateUserAccount(account.accountId, account.active)
                                }
                              />
                            )}
                          </td>
                          <td>
                            {(hasEditMemberPermission && account.active) ||
                            (auth && opi && !account.active && account.exceededBidVisit) ? (
                              <i
                                className='mdi mdi-square-edit-outline staticLink mdi-24px'
                                title='Edit User'
                                onClick={() => Addedituser(account, applicablePermissions)}
                              />
                            ) : null}
                          </td>
                        </tr>
                      ))
                      .slice(indexOfFirstList, indexOfLastList)}
                  </>
                ) : null}
              </Table>
              <Paging
                totalRecords={useraccountlist.length}
                currentPage={currentPage}
                pagefor='planholders-supplier'
                onPagingClick={(value: number) => {
                  if (value !== currentPage) setCurrentPage(Number(value));
                }}
                pageLimit={listPerPage}
              />
            </>
          ) : (
            <NoResult
              message={useraccounts_internalLoader ? loadingMsg : 'No User Accounts Available'}
            />
          )}

          {hasEditMemberPermission && (
            <Buttons
              classNames='bttn-primary my-4 float-right'
              text='Add User'
              type='button'
              onClick={() => Addedituser('', applicablePermissions)}
            />
          )}
        </div>
      </div>

      <ModalPopUp
        size={'lg'}
        title='Reset Password'
        closeModal={setRecoveryPasswordPopup}
        isOpen={recoveryPopup}
        backdrop='static'
      >
        <RecoverPassword {...props} />
      </ModalPopUp>
    </>
  );
};

export default memo(UserAccount);
