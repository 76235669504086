import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { addbidParamType } from '../../../../types/addbid';
import { Buttons, Paging } from '../../../customcontrols';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import AddSupplementalSuppliers from '../../../common/createbid/modals/addsupplementalsuppliers';
import SupplierBulkUpload from '../../../common/createbid/supplierbulkupload';
import { formattedPhoneNumber } from '../../../../utils/helpers';
import { pageSize } from '../../../../utils/constants';

interface PropsTypes {
  addbid: addbidParamType;
  setAddBidDetails: any;
  match?: any;
  form?: any;
  getAddBidsupplementalsuppliers?: any;
  getStateList?: any;
}

const ReSupplimentalSuppliers = (props: PropsTypes) => {
  const { addbid, setAddBidDetails, getAddBidsupplementalsuppliers, getStateList } = props;
  const {
    isExistsSupplier = false,
    addsupplementalmodal = false,
    addSupplierBulkUploadModal = false,
    addsupplementalvalues,
    currentScreen = 1,
    supplementalsupplierlist = [],
    broadcastSupplimentalSupplier = [],
  } = addbid;
  const bidId = parseInt(props.match.params.bidId);
  const { form, ...otherprops } = props;

  useEffect(() => {
    if (bidId) getAddBidsupplementalsuppliers({ id: bidId, type: 'Bid' });
  }, [bidId, getAddBidsupplementalsuppliers]);

  useEffect(() => {
    setCurrentPages(1);
  }, [supplementalsupplierlist]);
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const SetAddSupplemental = () => {
    setAddBidDetails({
      addsupplementalmodal: !addsupplementalmodal,
      isExistsSupplier: '',
      existssupplierlist: [],
    });
  };

  const setSupplierBulkUpload = () => {
    setAddBidDetails({
      addSupplierBulkUploadModal: !addSupplierBulkUploadModal,
    });
  };

  const DeleteSupplementalSupplier = (email: string) => {
    const newSupplierData = broadcastSupplimentalSupplier.filter(item => item.email !== email);
    setAddBidDetails({ broadcastSupplimentalSupplier: newSupplierData });
  };

  const listSuppliers = [...broadcastSupplimentalSupplier, ...supplementalsupplierlist];

  return (
    <>
      <div className='clearfix mb-4'>
        {broadcastSupplimentalSupplier.length > 0 ? (
          <h4 className='float-left'>
            New supplemental suppliers added : {broadcastSupplimentalSupplier.length}
          </h4>
        ) : null}
        <div className='d-flex justify-content-end'>
          <span
            className='bttn bttn-secondary small mr-3'
            onClick={setSupplierBulkUpload}
            data-tut='reactour__agencyadd_1'
          >
            Upload List of Suppliers
          </span>
          <span className='bttn bttn-secondary small' onClick={SetAddSupplemental}>
            Add Individual Supplier
          </span>
        </div>
      </div>
      <Table className='tableHData'>
        <thead>
          <th>Company Name</th>
          <th>Contact Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>City, State</th>
          <th>Action</th>
        </thead>
        <tbody>
          {listSuppliers && listSuppliers.length > 0 ? (
            <>
              {listSuppliers
                .map((item, index) => (
                  <tr key={index}>
                    <td>{item.supplierName || item.companyName}</td>
                    <td>{item.contactName}</td>
                    <td>{item.email}</td>
                    <td>{item.phone ? formattedPhoneNumber(item.phone) : ''}</td>
                    <td>
                      {item.city && item.state
                        ? `${item.city}, ${item.state}`
                        : item.city
                        ? item.city
                        : item.state
                        ? item.state
                        : null}
                    </td>
                    <td>
                      {!item.mi ? (
                        <i
                          className='mdi mdi-delete-forever-outline mdi-24px staticLink'
                          onClick={() => DeleteSupplementalSupplier(item.email)}
                        />
                      ) : (
                        'Broadcast'
                      )}
                    </td>
                  </tr>
                ))
                .slice(indexOfFirstLists, indexOfLastLists)}
            </>
          ) : null}
        </tbody>
      </Table>

      <Paging
        totalRecords={listSuppliers && listSuppliers.length}
        currentPage={currentPages}
        onPagingClick={(value: number) => {
          if (value !== currentPages) setCurrentPages(Number(value));
        }}
        limitmsg={false}
        pageLimit={listPerPages}
      />

      <ModalPopUp
        size={isExistsSupplier ? 'xl' : 'md'}
        title='Add Supplemental Suppliers'
        closeModal={SetAddSupplemental}
        isOpen={addsupplementalmodal}
        backdrop='static'
      >
        <AddSupplementalSuppliers
          {...otherprops}
          initialValues={addsupplementalvalues}
          bidId={bidId}
          reBroadcast={true}
        />
      </ModalPopUp>
      <ModalPopUp
        size={'lg'}
        title={currentScreen === 3 ? 'Review List' : 'Upload List of Suppliers'}
        closeModal={setSupplierBulkUpload}
        isOpen={addSupplierBulkUploadModal}
        backdrop='static'
      >
        <SupplierBulkUpload {...props} reBroadcast={true} />
      </ModalPopUp>
    </>
  );
};

export default memo(ReSupplimentalSuppliers);
