import React from 'react';

interface Props {
  handleChange: any;
  name?: string;
  checked?: boolean;
  indeterminate?: boolean;
  value?: string | boolean;
  id?: string;
  disabled?: boolean | string;
  disabledText?: string;
  parentClass?: string;
  title?: string;
  label?: string;
  field: any;
}

function convertToString(val: any): string {
  if (typeof val === 'string') return val;
  if (val === true) return 'true';
  if (val === false) return 'false';
  return '';
}

// similar issue here--should be boolean
export function convertToBoolean(val: string | boolean | undefined): boolean {
  if (typeof val === 'boolean') return val;
  if (val === 'true') return true;
  return false;
}

const renderToggleBtn = (props: Props) => {
  const {
    label,
    id,
    value,
    indeterminate = false,
    checked,
    handleChange = () => {},
    disabled,
    parentClass,
    title,
    disabledText,
    field,
  } = props;

  const { name = '' } = field;

  return (
    <div className='switchWrapper'>
      {label}
      <label className='switch'>
        <input
          type='checkbox'
          onChange={event => {
            handleChange(field.name, event.target.checked);
          }}
          id={id}
          key={id}
          value={convertToString(field.value)}
          name={name}
          checked={field.value}
          ref={element => element && (element.indeterminate = indeterminate)}
          disabled={convertToBoolean(disabled)}
        />
        <span className='slider round' />
      </label>
    </div>
  );
};

export default renderToggleBtn;
