import React, { useEffect } from 'react';

export type HandlerFunction = (e: React.MouseEvent) => void;

export interface Card {
  title: string;
  subtitle: string;
  bullets: string[];
  action: string;
  price: number | string;
  message?: string;
  handleClick: HandlerFunction;
  handleLoad?: () => void;
}

interface PurchasePlanCardProps {
  card: Card;
  isFeatured: boolean;
}

function PurchasePlanCard({ card, isFeatured }: PurchasePlanCardProps) {
  const buttonClass = isFeatured ? 'bttn-accent' : 'bttn-primary';
  const headerClass = isFeatured ? 'header-accent' : 'header-primary';
  const cardClass = isFeatured ? 'featured-card' : '';

  useEffect(() => {
    if (card.handleLoad) card.handleLoad();
  }, [card]);

  return (
    <div className={`purchase-plan-card ${cardClass}`}>
      <div className={`header-background ${headerClass}`}>
        <h2>{card.title}</h2>
      </div>
      <div className='content-background'>
        <div className='purchase-plan-price'>
          <p className='purchase-plan-price-dollars'>{`$${card.price}`}</p>
          <p className='purchase-plan-price-message'>{card.message}</p>
        </div>

        <button onClick={card.handleClick} className={`${buttonClass} text-center action-button`}>
          {card.action}
        </button>

        <p className='subtitle'>{card.subtitle}</p>
        <ul>
          {card.bullets.map((bullet, idx) => (
            <li key={idx}>{bullet}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PurchasePlanCard;
