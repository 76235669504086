import { createActionTypes, createNoLoadActionTypes } from './utils';

export const LOGIN = createActionTypes('LOGIN');
export const SET_LOGIN = createNoLoadActionTypes('SET_LOGIN');
export const LOGIN_USING_COOKIE = createActionTypes('LOGIN_USING_COOKIE');
export const GET_REFRESH_TOKEN = createActionTypes('GET_REFRESH_TOKEN');
export const LOGOUT = createActionTypes('LOGOUT');
export const RESET_AUTH = createNoLoadActionTypes('RESET_AUTH');
export const VALIDATE_EMAIL = createActionTypes('VALIDATE_EMAIL');
export const SET_LOGIN_DETAILS = createNoLoadActionTypes('SET_LOGIN_DETAILS');
export const TRACK_AMPLITUDE_USER_ACTION = createNoLoadActionTypes('TRACK_AMPLITUDE_USER_ACTION');
