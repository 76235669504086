import {
  Buttons,
  CheckBox,
  DeprecatedInput,
  DeprecatedRadio,
  NoResult,
  SelectBox,
} from '../../../../customcontrols/index';
import React, { memo, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import {
  allProductsState,
  initialNationalProduct,
  selectedProductsState,
} from '../../../../../store/recoil/productState';
import { anyParamType } from '../../../../../types/paymentdetails';
import { getProductAgencies } from '../../../../../utils/helpers';
import { LinkText } from '../../../../common/table';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import { Paginate } from '../../../../common/paginate';
import { registrationComponent } from '../../../../../utils/constants';
import { SubscriptionProducts, ProductApiResponse } from '../../../../../types/products';
import { compareObjectsByKey } from '../../../../../utils';
import { useAmplitude } from '../../../../amplitude';

// TOREFACTOR: Add param types and remove anys
interface AgencySelectionProps {
  UpdateSupplierFreeAgencies: anyParamType;
  movetoNextPage?: any;
  pageFor?: string;
  products?: any; // TODO: This does not seem compatible with Product[]
  registration?: any;
  toggleAgencySelection: anyParamType;
  userType?: string;
}

const AgencySelection = (props: AgencySelectionProps) => {
  const {
    UpdateSupplierFreeAgencies,
    movetoNextPage,
    pageFor = 'subscription',
    products = [],
    toggleAgencySelection,
    userType,
  } = props;

  const { logEvent } = useAmplitude();
  const allProductsLoadable = useRecoilValueLoadable(allProductsState);
  const [allRecoilProductsLoaded, setAllRecoilProductsLoaded] = useState([initialNationalProduct]);

  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);
  const [agencyFilter, setAgencyFilter] = useState({ autosuggestText: '', state: '', county: '' });
  const [freeAgencies, setFreeAgencies] = useState<any[]>([]);
  const [agencyCounties, setAgencyCounties] = useState<any[]>([]);
  const [agencyStates, setAgencyStates] = useState<any[]>();
  // TODO: TS4 - this line throws an error wanting it moved into the useEffect or wrapped
  // a useMemo. I was not able to do either quickly without causing issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const finalProducts =
    allProductsLoadable.state === 'hasValue' ? allProductsLoadable.contents : [];

  const [confirmationPopup, toggleConfirmationPopup] = useState(false);
  const [NewAgenciesExist, setNewAgenciesExist] = useState(true);

  const SelectedProducts =
    pageFor === 'registration'
      ? products
      : products
          .filter((item: any) => item.productType === 'Free Agency')
          .map((item: any) => {
            return item.productId;
          });

  const [SelectedFreeAgencies, setSelectedFreeAgencies] = useState(SelectedProducts);
  const [freeAgencyName, setSelectedFreeAgencyname] = useState('');
  const [selectedAgencyInfo, setSelectedAgencyInfo] = useState({});

  useEffect(() => {
    if (SelectedProducts.length > 0 && !freeAgencyName) {
      const selectedProduct = finalProducts.find(
        (item: any) => item.productId === SelectedProducts[0],
      ) as any;
      const selectedProductName = selectedProduct ? selectedProduct.productName : '';
      setSelectedFreeAgencyname(selectedProductName);
    }
  }, [SelectedProducts, finalProducts, freeAgencyName, setSelectedFreeAgencyname]);

  const preSelectedProducts = [...SelectedProducts];

  useEffect(() => {
    if (finalProducts.length > 0) {
      const agencies = getProductAgencies(finalProducts).sort(compareObjectsByKey('productName'));
      setFreeAgencies(agencies);

      const statesList = finalProducts
        .filter(item => item.productType === 'ST')
        .map((itemData: any) => {
          return { value: itemData.productId, label: itemData.productName };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      setAgencyStates(statesList);
    }
  }, [finalProducts, setAgencyStates]);

  const getFilteredAgencies = (filters: any) => {
    const state = filters.state && filters.state.value ? filters.state.value : '';
    const county = filters.county && filters.county.value ? filters.county.value : '';
    const agencies = getProductAgencies(finalProducts, state, county, filters.autosuggestText).sort(
      compareObjectsByKey('productName'),
    );
    setFreeAgencies(agencies);
  };

  const onInputChange = (name: string, value: any) => {
    let filters = { ...agencyFilter, [name]: value } as any;
    setAgencyFilter({ ...agencyFilter, ...filters });
    if (name === 'state') {
      filters = { ...agencyFilter, [name]: value, county: '' };
      setAgencyFilter({ ...agencyFilter, ...filters, county: '' });
      const selectedState = finalProducts.find((item: any) => item.productId === value.value);
      if (selectedState) {
        const selectedStateCounties = finalProducts
          .filter(item => item.parentId === selectedState.productId)
          .map(item => {
            return { label: item.productName, value: item.productId };
          });
        setAgencyCounties(selectedStateCounties.sort((a, b) => a.label.localeCompare(b.label)));
        setAgencyFilter({ ...agencyFilter, ...agencyFilter, state: value, county: '' });
      }
    }
    getFilteredAgencies(filters);
  };

  const resetFilter = (e: React.MouseEvent) => {
    e.preventDefault();
    setAgencyFilter({ ...agencyFilter, autosuggestText: '', state: '', county: '' });
    setSelectedFreeAgencies([]);
    const agencies = getProductAgencies(finalProducts).sort(compareObjectsByKey('productName'));
    setFreeAgencies(agencies);
    setSelectedProducts({ ...selectedProducts, agency: undefined });
  };

  const SelectFreeAgencies = (
    status: string,
    agencyId: number,
    agencyName: string,
    agencyData: any,
    type = 'checkbox',
  ) => {
    if (finalProducts.length > 0) {
      let localfreeAgencies: any;
      if (type === 'checkbox') {
        localfreeAgencies = [...SelectedFreeAgencies];
        if (!status) {
          const index = localfreeAgencies.indexOf(agencyId);
          localfreeAgencies.splice(index, 1);
        } else {
          localfreeAgencies.push(agencyId);
        }
      } else {
        localfreeAgencies = [agencyId];
      }

      const agencyCounty = finalProducts.find(
        (item: any) => item.productId === agencyData.parentId,
      );
      if (agencyCounty) {
        let agencyState = finalProducts.find(
          (item: any) => item.productId === agencyCounty?.parentId,
        );
        if (agencyCounty && agencyCounty.parentId === 0) {
          agencyState = { ...agencyCounty };
        }
        const agencyInfo = {
          agencyName: agencyData.productName,
          agencyState: agencyState ? agencyState.productName : '',
          agencyCounty: agencyCounty ? agencyCounty.productName : '',
        };

        const distinctAgencies = localfreeAgencies.filter(
          (item: any) => preSelectedProducts.indexOf(item) < 0,
        );
        const RemoveddistinctAgencies = preSelectedProducts.filter(
          item => localfreeAgencies.indexOf(item) < 0,
        );
        setNewAgenciesExist(
          distinctAgencies.length > 0 || RemoveddistinctAgencies.length > 0 ? false : true,
        );
        setSelectedFreeAgencies(localfreeAgencies);
        setSelectedFreeAgencyname(agencyName);
        setSelectedAgencyInfo(agencyInfo);

        const agencyProduct = allRecoilProductsLoaded.filter(
          (product: ProductApiResponse) => product.productId === agencyId,
        );
        setSelectedProducts({ ...selectedProducts, agency: agencyProduct[0] });
      }
    }
  };

  const checkAgencyCount = () => {
    if (SelectedFreeAgencies.length === 1) {
      UpdateFreeAgencies();
    } else {
      toggleConfirmationPopup(true);
    }
  };

  const UpdateFreeAgencies = () => {
    UpdateSupplierFreeAgencies({ products: SelectedFreeAgencies.join(','), pageFor });
    setAgencyFilter({ ...agencyFilter, autosuggestText: '', state: '', county: '' });
  };

  const handlePageChange = (page: { selected: number }) => {
    if (page) {
      logEvent('registration (supplier) - click agency pagination', {
        Description:
          "User clicks any of the numbers or < >'s in the pagination component to see more agencies",
        Page: page.selected + 1, // Increases index from 0 to 1
      });
    }
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (allProductsLoadable.state === 'hasValue') {
      setAllRecoilProductsLoaded(allProductsLoadable.contents);
    }
  }, [allProductsLoadable]);

  /**
   * This scrolls to the top of the page on load.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div data-testid='agency-selection'>
      {pageFor === 'registration' ? (
        <p className='reg-intro no-bottom-margin'>
          A great way to find out about new opportunities on DemandStar is to subscribe to a
          government agency. To get started, please choose your first (free!) agency. You'll have a
          chance to sign up for more in just a moment.
        </p>
      ) : (
        <p className='reg-intro no-bottom-margin'>
          Free offer! Receive notifications whenever an agency of your choice posts new bid
          opportunities on DemandStar. To get started, please choose an agency you’d like to
          subscribe to. You’ll have a chance to sign up for more in just a moment.
        </p>
      )}
      {SelectedFreeAgencies.length > 0 && userType !== 'ops' ? (
        <p className='reg-intro no-top-padding'>
          Selected free agency : <strong>{freeAgencyName}</strong>
        </p>
      ) : null}

      <DeprecatedInput
        data-testid='agency-selection-name-filter'
        handleChange={onInputChange}
        label='Search by Agency Name'
        labelname='autosuggestText'
        name='autosuggestText'
        type='text'
        value={agencyFilter.autosuggestText}
      />

      <SelectBox
        data-testid='agency-selection-state-filter'
        handleSelect={onInputChange}
        label='State'
        name='state'
        options={agencyStates}
        parentClass='w-100 float-left clear-both'
        reactselect={true}
        value={agencyFilter.state}
      />

      <SelectBox
        data-testid='agency-selection-county-filter'
        handleSelect={onInputChange}
        label='County'
        name='county'
        options={agencyCounties}
        parentClass='w-100 float-left clear-both'
        reactselect={true}
        value={agencyFilter.county}
      />

      <div className='clear-both text-right w-100 mb-4'>
        <LinkText
          data-testid='agency-selection-reset-filter'
          className='reg-search-reset'
          href='#'
          onClick={resetFilter}
        >
          Reset Search
        </LinkText>
      </div>

      <div className='clear-both reg-agency-list'>
        <Paginate
          data={freeAgencies}
          data-testid='agency-selection-paginate'
          onPageChange={handlePageChange}
        >
          {data =>
            data.map((freeAgency: any, index: number) => {
              return userType === 'ops' ? (
                <CheckBox
                  checked={SelectedFreeAgencies.includes(freeAgency.productId)}
                  handleChecked={(name: string, value: any) => {
                    SelectFreeAgencies(
                      value,
                      freeAgency.productId,
                      freeAgency.productName,
                      freeAgency,
                    );
                  }}
                  key={index}
                  title={freeAgency.productName}
                  value={SelectedFreeAgencies.includes(freeAgency.productId)}
                />
              ) : (
                <DeprecatedRadio
                  data-testid='agency-selection-select-item'
                  defaultChecked={SelectedFreeAgencies.includes(freeAgency.productId)}
                  ID='freeagencyID'
                  handleSelect={(name: string, value: any) => {
                    SelectFreeAgencies(
                      value,
                      freeAgency.productId,
                      freeAgency.productName,
                      freeAgency,
                      'radio',
                    );
                  }}
                  key={index}
                  name='freeagency'
                  title={freeAgency.productName}
                  value={SelectedFreeAgencies.includes(freeAgency.productId)}
                />
              );
            })
          }
        </Paginate>
        {(agencyFilter.autosuggestText || agencyFilter.state || agencyFilter.county) &&
        freeAgencies.length === 0 ? (
          <NoResult message='No Agencies Available' />
        ) : null}
      </div>
      {pageFor !== 'registration' ? (
        <div className='d-flex justify-content-between clear-both pt-3'>
          <Buttons text='Cancel' classNames='bttn-secondary' onClick={toggleAgencySelection} />
          <Buttons
            classNames='bttn-primary'
            disable={NewAgenciesExist || SelectedFreeAgencies.length === 0}
            onClick={checkAgencyCount}
            text='Save'
          />
        </div>
      ) : (
        <>
          <div className='d-flex clear-both pt-3 mt-2 float-right'>
            <Buttons
              text='Skip Agency Selection'
              onClick={() => {
                setSelectedProducts({ ...selectedProducts, agency: undefined });
                setSelectedFreeAgencies([]);
                setAgencyFilter({ ...agencyFilter, autosuggestText: '', state: '', county: '' });
                movetoNextPage(registrationComponent.ChooseSubscription, []);
              }}
              classNames='bttn-as-link mt-2 mr-2'
            />
            <Buttons
              text='Next'
              classNames='bttn-accent mt-2'
              onClick={() => {
                movetoNextPage(
                  registrationComponent.ChooseSubscription,
                  SelectedFreeAgencies,
                  true,
                  freeAgencyName,
                  selectedAgencyInfo,
                );
              }}
              disable={SelectedFreeAgencies.length === 0}
            />
          </div>
        </>
      )}

      <ModalPopUp
        size='md'
        title='Confirmation'
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        <p>
          Only one free agency is allowed for a user. You have selected more than one. Are you sure
          you want to proceed?
        </p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='No'
          title='No'
          type='button'
          onClick={() => toggleConfirmationPopup(false)}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Yes'
          title='Yes'
          type='button'
          onClick={() => {
            toggleConfirmationPopup(false);
            UpdateFreeAgencies();
          }}
        />
      </ModalPopUp>
    </div>
  );
};

export default memo(AgencySelection);
