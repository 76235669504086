import { atom } from 'recoil';

import { GetVendorSpecificDocumentsAPIResponse } from '../../../types';

/**
 * Initialize the customDocumentTypeList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const vendorDocumentListState = atom<GetVendorSpecificDocumentsAPIResponse[]>({
  key: 'vendorDocumentListState',
  default: [],
});

/**
 * Track the selected item for edit/delete.
 */
export const selectedVendorDocumentState = atom<GetVendorSpecificDocumentsAPIResponse | null>({
  key: 'selectedVendorDocumentState',
  default: null,
});
