import moment from 'moment-timezone';
import { call, delay, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { scrollToTop, toastFn } from '../../utils/helpers';
import history from '../../utils/history';
import * as actionTypes from '../actionTypes';
import * as bidApi from '../services/bids';
import * as sharedApi from '../services/shared';
import { storedCommoditiestokenName, usaDateFormat } from '../../utils/constants';

const bidtoastID = '28563ab7-74fa-421b-a5e8-f813110d6464';

function* watchBidListSearchAsync() {
  yield takeEvery(actionTypes.LOAD_BID_RESULT.TRIGGER, bidListSearchAsync);
}

function getObject(obj: { [x: string]: any }) {
  Object.keys(obj).forEach(key => !obj[key] && delete obj[key]);
  return obj;
}

function* bidListSearchAsync(action: any): any {
  let filters = undefined;
  let oldFilters = undefined;
  if (action && action.payload && !action.payload.preserveFilters) {
    filters = action.payload;
    oldFilters = { ...filters };
  } else {
    filters = getObject({
      ...(yield select(state => state.bids.filters)),
    });
    oldFilters = { ...filters };
  }

  delete filters.industryText;
  delete filters.locationText;
  if (filters.startDueDate) {
    filters.startDueDate = moment(filters.startDueDate).format(usaDateFormat);
  } else {
    delete filters.startDueDate;
  }
  if (filters.endDueDate) {
    filters.endDueDate = moment(filters.endDueDate).format(usaDateFormat);
  } else {
    delete filters.endDueDate;
  }
  if (filters.industry && filters.industry.length > 0) {
    filters.industry = filters.industry.join();
  } else {
    delete filters.industry;
  }
  const showBids = [];
  if (filters.myBids) {
    showBids.push('Mine');
  }
  if (filters.bidsNotified) {
    showBids.push('Notified');
  }
  if (filters.orderedBids) {
    showBids.push('Ordered');
  }
  if (filters.watchedBids) {
    showBids.push('Watch');
  }
  if (filters.commodityMatches) {
    showBids.push('Commodity');
  }
  if (filters.ebiddingAvailable) {
    showBids.push('eBids');
  }

  if (showBids.length > 0) {
    filters.showBids = showBids.join(',');
  } else {
    filters.showBids = '';
  }

  if (filters.fiscalYear && filters.fiscalYear.value) {
    filters.fiscalYear = filters.fiscalYear.value;
  }
  if (filters.bidStatus && filters.bidStatus.value) {
    filters.bidStatus = filters.bidStatus.value;
  }

  if (filters.watchedBids) {
    const shared = yield select(state => state && state.shared);
    const { watchedBidIds = [], watchedBidIdsCall = false } = shared || {};
    if (watchedBidIdsCall === false) {
      const response = yield call(sharedApi.getWatchedBidIds, {});
      filters.watchIds = response.data.result;
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { watchedBidIds: response.data.result, watchedBidIdsCall: true },
      });
    } else {
      filters.watchIds = watchedBidIds;
    }
  }

  filters.bidIdentifier = filters.bidIdentifier && filters.bidIdentifier.trim();
  filters.bidName = filters.bidName && filters.bidName.trim();

  const sortFields = getObject({
    ...(yield select(state => state.bids.sortingDefaultFilter)),
  });

  yield put({ type: actionTypes.LOAD_BID_RESULT.REQUEST, meta: { filters } });

  const shared = yield select(state => state.shared);
  const { dashboardCommodities } = shared;
  let userCommodityCodes = [];
  if (dashboardCommodities) userCommodityCodes = dashboardCommodities || [];
  else {
    const userCommodity: any = localStorage.getItem(storedCommoditiestokenName);
    userCommodityCodes = JSON.parse(userCommodity) || [];
  }
  const commodityExists = userCommodityCodes && userCommodityCodes.length > 0 ? true : false;
  const finalFilterData =
    filters.initialRequest && filters.initialRequest === true
      ? { ...filters, commodityExists }
      : { ...filters, ...sortFields, commodityExists };

  if (finalFilterData.additionalShowBids && finalFilterData.additionalShowBids.length === 0) {
    delete finalFilterData.additionalShowBids;
  }
  try {
    if (finalFilterData.startDueDate) {
      finalFilterData.startDueDate = `${finalFilterData.startDueDate} 00:00:00`;
    }

    if (finalFilterData.endDueDate) {
      finalFilterData.endDueDate = `${finalFilterData.endDueDate} 23:59:59`;
    }

    const response = yield call(bidApi.getBids, { ...finalFilterData });
    const shared = yield select(state => state.shared);
    const bids = yield select(state => state.bids);
    const { bidStatusList = [], dashboardCommodities = [] } = shared || {};
    const { filters = {} } = bids || {};

    if (response.data.parameters.bidStatus && bidStatusList.length > 0) {
      response.data.parameters.bidStatus = bidStatusList.find(
        (item: { value: string }) => item.value === response.data.parameters.bidStatus,
      );
    } else if (response.data.parameters.bidStatus && oldFilters && oldFilters.bidStatus) {
      response.data.parameters.bidStatus = oldFilters.bidStatus;
    }
    const { commodityMatches = false } = filters || {};
    let noCommodityCodesBidsResults = false;
    if (
      response.data.result.length === 0 &&
      dashboardCommodities.length === 0 &&
      commodityMatches
    ) {
      noCommodityCodesBidsResults = true;
    }
    yield put({
      type: actionTypes.SET_BID_DETAILS.TRIGGER,
      payload: { noCommodityCodesBidsResults, bidscurrentPage: 1 },
    });
    yield put({
      type: actionTypes.LOAD_BID_RESULT.SUCCESS,
      payload: { ...response.data, preserveFilters: action.payload.preserveFilters },
    });

    let updatedFilters = {};
    const shownBids = response.data.parameters.showBids
      ? response.data.parameters.showBids.split(',')
      : '';
    updatedFilters = {
      ...filters,
      showBids: response.data.parameters.showBids,
      myBids: shownBids.includes('Mine') ? true : false,
      bidStatus: response.data.parameters.bidStatus,
    };

    let detailPayload = { labelFilters: updatedFilters } as any;
    if (!action.payload.preserveFilters) {
      detailPayload = { ...detailPayload, filters: updatedFilters };
    }
    yield put({ type: actionTypes.SET_BID_DETAILS.TRIGGER, payload: { ...detailPayload } });
    scrollToTop();
  } catch (error) {
    yield put({
      type: actionTypes.SET_BID_DETAILS.TRIGGER,
      payload: { noCommodityCodesBidsResults: false },
    });
    yield put({
      type: actionTypes.LOAD_BID_RESULT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchBidListSetFilterAsync() {
  yield takeLatest(actionTypes.BID_LIST_SET_FILTER.TRIGGER, bidListSetFilterAsync);
}

function* bidListSetFilterAsync(action: { payload: any }) {
  yield put({
    type: actionTypes.BID_LIST_SET_FILTER.ACTION,
    payload: action.payload,
    meta: action.payload,
  });
  yield call(bidListSearchAsync, {});
}

function* watchBidListFilterChange() {
  yield takeLatest(actionTypes.BID_LIST_FILTER_CHANGE.TRIGGER, bidListFilterChange);
}

function* bidListFilterChange(action: { payload: { showBids: []; showBidReset: any } }): any {
  let showBids = yield select(state => state.shared.showBids);
  if (action.payload.showBids) {
    if (showBids && showBids.length > 0) {
      showBids = showBids.map((showBid: any) => {
        showBid.selected = action.payload.showBids === showBid.key;
        return showBid;
      });
    }
  }

  if (action.payload.showBidReset) {
    if (showBids && showBids.length > 0) {
      showBids = showBids.map((showBid: any) => {
        showBid.selected = showBid.defaultSelected === true;
        return showBid;
      });
    }
  }
  yield put({
    type: actionTypes.BID_LIST_FILTER_CHANGE.ACTION,
    payload: { payload: action.payload /* , showBids */ },
    meta: action.payload,
  });
}

function* watchBidWatchAsync() {
  yield takeLatest(actionTypes.BID_WATCH.TRIGGER, BidWatchAsync);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* BidWatchAsync(action: { payload: { bidId: number; watchOn: number } }): any {
  yield put({ type: actionTypes.BID_WATCH.REQUEST, meta: action.payload });
  const results = yield select(state => state.bids.results);

  const bidIndex = results.findIndex((result: { bidId: number }) => {
    return result.bidId === action.payload.bidId;
  });

  const bid = { ...results[bidIndex] };
  bid.watchStatus = action.payload.watchOn;
  bid.watches += action.payload.watchOn ? 1 : -1;

  try {
    const response = yield call(bidApi.watchBid, action.payload);
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { watchedBidIds: response.data.watchIds, watchedBidIdsCall: true },
    });
    //yield put({ type: actionTypes.GET_WATCHED_BIDIDS.TRIGGER })
    yield put({
      type: actionTypes.BID_WATCH.SUCCESS,
      payload: Object.assign([], results, { [bidIndex]: bid }),
    });
  } catch (error) {
    yield put({ type: actionTypes.BID_WATCH.FAILURE, payload: { payload: action.payload, error } });
  }
}

function* watchDeleteBid() {
  yield takeLatest(actionTypes.DELETE_BID.TRIGGER, deleteBid);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* deleteBid(action: { payload: Record<string, unknown> | undefined }): any {
  yield put({ type: actionTypes.DELETE_BID.REQUEST, meta: action.payload });

  try {
    const response = yield call(bidApi.deleteBid, action.payload);
    const data = response.data.result || '';
    if (data && data.status) {
      // yield put({ type: actionTypes.DELETE_BID.SUCCESS, payload: { bidID: action.payload.bidID action.payload.bidID } })
      // yield put({ type: actionTypes.DELETE_BID.SUCCESS, payload: { bidID: action.payload.bidID } })
      yield put({ type: actionTypes.LOAD_BID_RESULT.TRIGGER, payload: { initialRequest: true } });
      yield put({ type: actionTypes.DELETE_BID.SUCCESS });
      history.replace('/buyers/bids');
      delay(200);
      toastFn('success', 'Deleted', bidtoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_BID.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', bidtoastID);
  }
}

function* watchgetBidsDetails() {
  yield takeLatest(actionTypes.LOAD_BID_LIST_ITEMS_DATA.TRIGGER, getBidsDetails);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getBidsDetails(action: { payload: { bidIds: string; upcomingBidIds: string } }): any {
  yield put({ type: actionTypes.LOAD_BID_LIST_ITEMS_DATA.REQUEST, meta: action.payload });

  try {
    const addbid = yield select(state => state.bids);
    const auth = yield select(state => state.auth);
    const { results, loadedBids = [] } = addbid;
    const { mt: memberType } = auth;
    const FilteredBidIds = action.payload.bidIds
      .split(',')
      .filter(bidId => !loadedBids.includes(bidId));
    const FilteredUpcomingBidIds = action.payload.upcomingBidIds
      .split(',')
      .filter(bidId => !loadedBids.includes(bidId));
    if (FilteredBidIds.length > 0) {
      const response = yield call(bidApi.bidsCounters, { bidIds: FilteredBidIds.join(',') });
      const watchersResponse = yield call(bidApi.bidsWatchersCount, {
        bidIds: FilteredBidIds.join(','),
      });

      let finishStateBids: any = [];
      if (
        action.payload.upcomingBidIds &&
        FilteredUpcomingBidIds.length > 0 &&
        memberType === 'AB'
      ) {
        const canCompleteResponse = yield call(bidApi.getBidfinishStates, {
          bidIds: FilteredUpcomingBidIds.join(','),
        });
        finishStateBids = canCompleteResponse.data.result;
      }
      const payload = response.data.result;
      const watchersPayload = watchersResponse.data.result;
      const finalMergedData = results.map((itm: { bidId: number }) => {
        return {
          ...itm,
          ...payload.find((item: { bidId: number }) => item.bidId === itm.bidId && item),
          ...watchersPayload.find((item: { bidId: number }) => item.bidId === itm.bidId && item),
          ...finishStateBids.find((item: { bidId: number }) => item.bidId === itm.bidId && item),
        };
      });

      yield put({
        type: actionTypes.SET_BID_DETAILS.TRIGGER,
        payload: { results: finalMergedData, loadedBids: loadedBids.concat(FilteredBidIds) },
      });
    }
    yield put({ type: actionTypes.LOAD_BID_LIST_ITEMS_DATA.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_LIST_ITEMS_DATA.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetBidsWatchDetails() {
  yield takeLatest(actionTypes.LOAD_BID_LIST_ITEMS_WATCH_DATA.TRIGGER, getBidsWatchDetails);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getBidsWatchDetails(action: {
  payload: { bidIds: string; upcomingBidIds: string };
}): any {
  yield put({ type: actionTypes.LOAD_BID_LIST_ITEMS_WATCH_DATA.REQUEST, meta: action.payload });

  try {
    yield delay(3000);
    const addbid = yield select(state => state.bids);
    const { results, loadedBids = [] } = addbid;
    const FilteredBidIds = action.payload.bidIds
      .split(',')
      .filter(bidId => !loadedBids.includes(bidId));
    if (FilteredBidIds.length > 0) {
      const watchersResponse = yield call(bidApi.bidsWatchersCount, {
        bidIds: FilteredBidIds.join(','),
      });
      const watchersPayload = watchersResponse.data.result;
      const finalMergedData = results.map((itm: { bidId: number }) => {
        return {
          ...itm,
          ...watchersPayload.find((item: { bidId: number }) => item.bidId === itm.bidId && item),
        };
      });

      yield put({
        type: actionTypes.SET_BID_DETAILS.TRIGGER,
        payload: { results: finalMergedData /* loadedBids: loadedBids.concat(FilteredBidIds) */ },
      });
    }
    yield put({ type: actionTypes.LOAD_BID_LIST_ITEMS_WATCH_DATA.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_BID_LIST_ITEMS_WATCH_DATA.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetConditionspending() {
  yield takeLatest(actionTypes.GET_BIDS_CONDITION_SPENDING.TRIGGER, getConditionspending);
}

// TODO: TS4 - yield call returns an implicit any, made it explicit as redux should go away
function* getConditionspending(action: { payload: Record<string, unknown> | undefined }): any {
  yield put({ type: actionTypes.GET_BIDS_CONDITION_SPENDING.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getConditionspending, action.payload);
    const data = response.data.result || [];
    yield put({ type: actionTypes.GET_BIDS_CONDITION_SPENDING.SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BIDS_CONDITION_SPENDING.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* bidListSaga() {
  yield fork(watchBidListSearchAsync);
  yield fork(watchBidListSetFilterAsync);
  yield fork(watchBidListFilterChange);
  yield fork(watchBidWatchAsync);
  yield fork(watchDeleteBid);
  yield fork(watchgetBidsDetails);
  yield fork(watchgetConditionspending);
  yield fork(watchgetBidsWatchDetails);
}
