import { actionPayloadTypes } from '../../types/actiontypedef';
import * as actionTypes from '../actionTypes';
import { action } from './utils';

export function getAccountInfo(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.LOAD_ACCOUNT_INFO, payload);
}

export function getaccountInfoCommodity(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.LOAD_ACCOUNT_INFO_COMMODITY, payload);
}

export function getProfileInfo(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.LOAD_PROFILE_INFO, payload);
}

export function updateMemberInfo(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.UPDATE_MEMBER_INFO, payload);
}

export function updateMemberBillingInfo(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.UPDATE_MEMBER_BILLING_INFO, payload);
}

export function addUpdateUserAccount(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.ADD_UPDATE_USER_ACCOUNT, payload);
}

export function forgotPassword(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.FORGOT_PASSWORD, payload);
}

export function resetAccountInfoState(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.RESET_ACCOUNTINFO_STATE, payload);
}

export function saveSelfDeclarations(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SAVE_SELFDECLARATIONS, payload);
}

export function searchPrograms(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SEARCH_PROGRAMS, payload);
}

export function programsPageChange(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.PROGRAMS_PAGE_CHANGE, payload);
}

export function addMemberProgramCertification(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.ADD_MEMBER_PROGRAM_CERTIFICATION, payload);
}

export function getProgramAttributes(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_PROGRAM_ATTRIBUTES, payload);
}

export function updateAutoRenewalSubscription(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.UPDATE_AUTO_RENEWAL_SUBSCRIPTION, payload);
}

export function addUpdateBTProfile(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.ADD_UPDATE_BT_PROFILE, payload);
}

export function getPaymentHistory(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_PAYMENT_HISTORY, payload);
}

export function getUserAccounts(payload: number | undefined = undefined) {
  return action(actionTypes.GET_USER_ACCOUNTS, payload);
}

export function getPermissions(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_PERMISSIONS, payload);
}

export function reactivateUserAccount(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.REACTIVATE_USER_ACCOUNT, payload);
}
export function unblockUserAccount(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.UNBLOCK_USER_ACCOUNT, payload);
}
export function getAcoountinfoMemberdetails(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS, payload);
}

export function setAccountInfoDetails(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SET_ACCOUNT_INFO_DETAILS, payload);
}

export function getaccountinfopublications(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACCOUNT_INFO_PUBLICATIONS, payload);
}

export function submitaccountinfopublicationsadd(
  payload: actionPayloadTypes | undefined = undefined,
) {
  return action(actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD, payload);
}
export function submitaccountinfopublicationsremove(
  payload: actionPayloadTypes | undefined = undefined,
) {
  return action(actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE, payload);
}

export function getaccountinfobidtypes(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ACCOUNT_INFO_BIDTYPES, payload);
}

export function submitaccountinfobidtypesadd(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD, payload);
}
export function submitaccountinfobidtypesremove(
  payload: actionPayloadTypes | undefined = undefined,
) {
  return action(actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE, payload);
}
export function getTemplates(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_TEMPLATES_LIST, payload);
}
export function getaccountinforequiredDocs() {
  return action(actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS);
}
export function UpdateNewTemplate(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.UPDATE_NEW_LEGAL_TEMPLATE, payload);
}
export function getSavedLegal(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_SAVED_LEGAL_TEMPLATE, payload);
}

export function submitaccountinforequiredDocsUpdate(
  payload: actionPayloadTypes | undefined = undefined,
) {
  return action(actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE, payload);
}

export function getOrderInfo(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ORDER_INFO, payload);
}

export function getOrders(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_ORDERS_LIST, payload);
}

export function setOrderFilter(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SET_ORDER_FILTERS, payload);
}

export function submitRemoveCard(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SUBMIT_REMOVE_CARD, payload);
}

export function resetPassword(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.RESET_USER_PASSWORD, payload);
}

export function getplanholderTypes(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_PLANHOLDER_TYPES, payload);
}

export function resetAccountinfo(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.RESET_ACCOUNT_INFO, payload);
}

export function toggleDocAutoApproval(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.TOGGLE_DOC_AUTO_APPROVAL, payload);
}
export function getDocAutoApprovalStatus(payload?: actionPayloadTypes | undefined) {
  // no payload
  return action(actionTypes.GET_DOC_AUTO_APPROVAL_STATUS, payload);
}
export function getSubscriptionState(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_SUBSCRIPTION_STATE, payload);
}
export function getSubscriptionCounties(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_SUBSCRIPTION_COUNTY, payload);
}
export function getSubscriptionAgencies(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_SUBSCRIPTION_AGENCIES, payload);
}
export function UpdateSupplierFreeAgencies(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.UPDATE_SUPPLIER_FREE_AGENCIES, payload);
}
export function modifySubscription(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.MODIFY_SUBSCRIPTION, payload);
}

export function resetModifySubscription(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.RESET_MODIFY_SUBSCRIPTION, payload);
}

export function cancelSubscription(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.CANCEL_SUBSCRIPTION, payload);
}

export function toggleSuppDocDownload(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.TOGGLE_SUPP_DOC_DOWNLOAD, payload);
}

export function getSuppDocDownloadStatus(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.GET_SUPP_DOC_STATUS, payload);
}
