import { AxiosRequestConfig } from 'axios';

import {
  RequiredDocumentResponseDeleteAPIRequest,
  RequiredDocumentResponseGetAPIResponse,
  RequiredDocumentResponseUploadAPIRequest,
  RequiredDocumentResponseUploadAPIResponse,
} from '../../types';

import { axiosPostAuthenticated } from './api';
import { BidId } from '../../types/shared';
import { constants } from '../../utils/settings';
import { DocumentUploadParams } from '../../types/document';
import { getDocFormatType } from '../../utils';
import { Paths } from '../../utils/constants';
import { uploadDocument } from './upload';

/**
 *
 * @param {FileUploadRequest<RequiredDocumentResponseUploadAPIRequest>} data
 * @param {RequiredDocumentResponseUploadAPIRequest} apiParameters
 * @param {(event: ProgressEvent<EventTarget>) => void} handleUploadProgress
 * @returns {Promise<RequiredDocumentResponseUploadAPIResponse>}
 */
export function uploadRequiredDocumentResponse({
  data,
  handleUploadProgress,
}: DocumentUploadParams<RequiredDocumentResponseUploadAPIRequest>) {
  const formData = new FormData();
  // File object
  formData.append('file', data.file);

  // Related request values
  formData.append('bidAwardRequiredDocId', data.bidAwardRequiredDocId?.toString() || '');
  formData.append('bidId', data.bidId.toString()); // this is a number. formData.append wants a string or Blob.
  formData.append('docFormatType', getDocFormatType(data.file?.name) || '');
  formData.append('filePath', data.filePath || '');
  formData.append('fileSize', data.fileSize?.toString() || '');
  formData.append('isDelete', data.isDelete.toString());
  formData.append('isSkip', data.isSkip.toString());
  formData.append('memberId', data.memberId?.toString() || '');
  formData.append('title', data.title || '');

  const config: AxiosRequestConfig = { onUploadProgress: handleUploadProgress };

  config.url = Paths.Award.RequiredDocResponses.upload;

  return uploadDocument<RequiredDocumentResponseUploadAPIResponse>(formData, config);
}

/**
 * Get requiredresponse- documents
 * @param {BidId}
 * @returns {RequiredDocumentResponseGetAPIResponse}
 */
export function getRequiredDocumentResponse({ bidId }: BidId) {
  const url =
    bidId <= 0 ? Paths.Award.RequiredDocResponses.all : Paths.Award.RequiredDocResponses.get;

  return axiosPostAuthenticated<RequiredDocumentResponseGetAPIResponse[]>({
    baseURL: constants.api.url,
    data: { bidId },
    url: url,
  });
}

/**
 * Delete requiredresponse- documents
 * @param {RequiredDocumentResponseDeleteAPIRequest}
 * @returns {RequiredDocumentResponseUploadAPIResponse}
 */
export function deleteRequiredDocumentResponse({
  bidAwardRequiredDocResponseId,
  bidId,
  isDelete = true,
  memberId,
}: RequiredDocumentResponseDeleteAPIRequest) {
  const formData = new FormData();
  formData.append('bidId', bidId.toString());
  formData.append('memberId', memberId.toString());
  formData.append('bidAwardRequiredDocResponseId', bidAwardRequiredDocResponseId?.toString() || '');
  formData.append('isDelete', 'true');

  return axiosPostAuthenticated<RequiredDocumentResponseUploadAPIResponse>({
    baseURL: constants.api.documentUrl,
    data: formData,
    url: Paths.Award.RequiredDocResponses.delete,
  });
}
