import React, { memo } from 'react';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../../../common/controls/inputs/text';
import { Buttons } from '../../../customcontrols';

interface ProgramCertificationApplicationProps {
  program: any;
  handleApplyProgram: any;
  handleCancelClick: any;
  initialValues: any;
}

function ProgramCertificationApplication(props: ProgramCertificationApplicationProps) {
  const { program = {}, initialValues } = props;
  const handleCancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.handleCancelClick();
  };
  const handleSubmit = (data: any) => {
    props.handleApplyProgram(data);
  };
  return (
    <>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {formikProps => {
          const {
            values: any,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValidating,
            validateForm,
            isValid,
            dirty,
          } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <h6>Buyer</h6>
              <p>{program.shortName}</p>
              <h6>Certification Name</h6>
              <p>{program.programName}</p>
              <h6>Description</h6>
              <p>{program.programDescription}</p>
              <h6>Supplier Application Statement</h6>
              <p>
                Enter any information you have which may be valuable to the agency when making their
                eligibility determination.
              </p>
              <Field
                name='substantiationNotes'
                type='text'
                component={renderDeprecatedTextField}
                label='Enter Statement'
                classNames='class'
                optional={true}
                onChange={setFieldValue}
              />
              <div className='d-block mt-3'>
                <Buttons
                  text='Save Changes'
                  type='submit'
                  classNames='bttn bttn-primary float-right'
                />
                <Buttons
                  text='Cancel'
                  classNames='bttn bttn-secondary float-left'
                  onClick={handleCancelClick}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(ProgramCertificationApplication);
