import React, { memo } from 'react';

import { BidExternalStatusType, BidInternalStatusType } from '../../../../../utils/constants';
import { deprecatedCheckCountDown, deprecatedGetBroadcastGap } from '../../../../../utils/helpers';
import { bidssummaryParamType } from '../../../../../types/biddetail';

interface PropsTypes {
  bidssummary: bidssummaryParamType;
}

const HeaderStatistics = (props: PropsTypes) => {
  const { bidssummary } = props;
  const { watchers, results, stats } = bidssummary || {};
  const { planholders, broadcasts, postBidViewers, supplimentalSuppliers } = stats;
  const { bidExternalStatusType, bidInternalStatusType, broadcastDate } = results || {};

  const isBroadcasting =
    bidExternalStatusType === BidExternalStatusType.Upcoming &&
    bidInternalStatusType !== BidInternalStatusType.Pending &&
    deprecatedCheckCountDown(broadcastDate) === 'closed' &&
    deprecatedCheckCountDown(deprecatedGetBroadcastGap(broadcastDate)) !== 'closed';
  const isBroadcast = bidExternalStatusType !== BidExternalStatusType.Upcoming;

  return (
    <div className='row countsWrapper'>
      <div className='col text-center'>
        <h2>{isBroadcast ? planholders : '...'}</h2>
        <p>Planholders</p>
      </div>
      <div className='col text-center'>
        <h2>{isBroadcast ? broadcasts : '...'}</h2>
        <p>{isBroadcasting ? 'Currently broadcasting' : 'Broadcast to'}</p>
      </div>
      <div className='col text-center'>
        <h2>{supplimentalSuppliers}</h2>
        <p>Supplemental Suppliers</p>
      </div>
      <div className='col text-center'>
        <h2>{isBroadcast ? watchers?.total || 0 : '...'}</h2>
        <p>Watchers</p>
      </div>
      <div className='col text-center'>
        <h2>{isBroadcast ? postBidViewers : '...'}</h2>
        <p>Post Bid Viewers</p>
      </div>
    </div>
  );
};

export default memo(HeaderStatistics);
