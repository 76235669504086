import React, { memo } from 'react';
import {
  activitiesBidStatusFields,
  activitiesQuoteStatusFields,
  memberTypes,
} from '../../../utils/constants';
import SelectBox from '../../customcontrols/selectbox';
import {
  activitiesNotificationsInitialValue,
  activitiesInitialValue,
} from '../../../store/reducers/activities';
import { activitiessearchTypes } from '../../../types';

interface propsTypes extends activitiessearchTypes {
  status: any;
  onSelectChange: any;
}

function ActivitiesStatus(props: propsTypes) {
  const {
    status,
    auth,
    pagefor,
    activities,
    setActivitiesDetails,
    getActivitiesNotifications,
    getActivitiesWatchs,
    getActivitiesPlanholders,
    getActivitiesEbidders,
    getActivitiesQuotes,
    getActivitiesSearch,
    getActivitiesNotificationsMember,
    setCurrentPages,
  } = props;
  const {
    searchSelect,
    search,
    notificationStatus,
    bitWatchStatus,
    planholderStatus,
    ebidproposalStatus,
    quotesStatus,
  } = activities;
  const { mt } = auth;
  let options = activitiesBidStatusFields;

  if (pagefor === 'quotes') {
    options = activitiesQuoteStatusFields;
  }

  const onStatusChange = (name: string, value: any) => {
    setCurrentPages(1);
    let final = {
      page: 1,
      size: 100,
      pagination: false,
      pagefor,
    } as any;

    if (search) {
      final = {
        ...final,
        notificationStatus,
        bitWatchStatus,
        planholderStatus,
        ebidproposalStatus,
        quotesStatus,
        search,
        searchSelect,
      };
    }

    switch (pagefor) {
      case 'notificationsbid':
        final.type = 'notification';
        final.notificationStatus = value;
        setActivitiesDetails({
          notificationStatus: value,
          notifications_internalLoader: true,
          search_internalLoader: true,
          activities_notifications: activitiesNotificationsInitialValue,
          activities_notifications_search: activitiesNotificationsInitialValue,
        });
        if (!search) getActivitiesNotifications(final);
        break;
      case 'notificationsaccount':
        final.type = 'notification';
        final.notificationStatus = value;
        setActivitiesDetails({
          notificationStatus: value,
          notifications_internalLoader: true,
          activities_notifications: activitiesNotificationsInitialValue,
        });
        if (!search) getActivitiesNotificationsMember(final);
        break;
      case 'bidwatch':
        final.type = 'watch';
        final.bitWatchStatus = value;
        setActivitiesDetails({
          bitWatchStatus: value,
          watchs_internalLoader: true,
          activities_watchs: activitiesInitialValue,
          search_internalLoader: true,
          activities_watchs_search: activitiesInitialValue,
        });
        if (!search) getActivitiesWatchs(final);
        break;
      case 'planholders':
        final.type = 'planholder';
        final.planholderStatus = value;
        setActivitiesDetails({
          planholderStatus: value,
          planholders_internalLoader: true,
          search_internalLoader: true,
          activities_planholders: activitiesInitialValue,
          activities_planholders_search: activitiesInitialValue,
        });
        if (!search) getActivitiesPlanholders(final);
        break;
      case 'ebidresponse':
        final.type = 'bidder';
        final.ebidproposalStatus = value;
        setActivitiesDetails({
          ebidproposalStatus: value,
          ebidders_internalLoader: true,
          search_internalLoader: true,
          activities_ebidders: activitiesInitialValue,
          activities_ebidders_search: activitiesInitialValue,
        });
        if (!search) getActivitiesEbidders(final);
        break;
      case 'quotes':
        final.type = 'quote';
        final.quotesStatus = value;
        setActivitiesDetails({
          quotesStatus: value,
          quotes_internalLoader: true,
          search_internalLoader: true,
          activities_quotes: activitiesInitialValue,
          activities_quotes_search: activitiesInitialValue,
        });
        if (!search) getActivitiesQuotes(final);
        break;
      default:
    }
    if (search) {
      getActivitiesSearch(final);
    }
  };

  return (
    <>
      {mt === memberTypes.agencyBuyer ? (
        <SelectBox
          reactselect={true}
          label='Status'
          parentClass='float-right btnPTitle'
          name='status'
          options={options}
          handleSelect={onStatusChange}
          value={status}
        />
      ) : null}
    </>
  );
}

export default memo(ActivitiesStatus);
