import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { memberTypes, Pages } from '../../../utils/constants';
import { authTypes } from '../../../types/broadcastlist';
import { breadcrumbTypes } from '../../../types/breadcrumb';
import { Permission } from '../../../types/auth';

type BreadcrumbItemsProps = {
  breadcrumb: breadcrumbTypes;
  breadcrumbs: any[];
  auth: authTypes;
};

const thirdlevel = ['Bids Details', 'Quote Details'];
const isThirdLevel = (itemName: string) => thirdlevel.includes(itemName);

const BreadcrumbItemsComponent = (props: BreadcrumbItemsProps) => {
  const { breadcrumb: breadcrumbData, breadcrumbs, auth } = props;
  const { name: bcName, altname } = breadcrumbData;
  const { prms = '', mt: memberType } = auth;
  const permissions = prms.replace(/ /g, '').split(',') as Permission[];

  const homepath =
    memberType === memberTypes.agencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;
  return (
    <>
      {breadcrumbs && breadcrumbs.length > 0 ? (
        <>
          <ol className='breadcrumb float-left' style={{ width: 'auto' }}>
            {breadcrumbs.map((item: { name: string; path: string }, index: number) => {
              const { name: itemName, path } = item;
              const displayName = isThirdLevel(itemName) ? bcName : itemName;

              return (auth &&
                auth.us &&
                permissions.includes(Permission.Ops) &&
                !memberType &&
                itemName !== 'Home') ||
                memberType ? (
                <li
                  className={`breadcrumb-item ${isThirdLevel(itemName) ? ' textWrapp' : ''}`}
                  key={index}
                  // aria-current={item}
                  title={displayName}
                >
                  <Link
                    to={itemName === 'Home' ? homepath : path}
                    // to={path}
                    title={displayName}
                  >
                    {index === 0 && <i className='mdi mdi-home-variant-outline mr-1' />}
                    {displayName}
                  </Link>
                </li>
              ) : null;
            })}
            <li className={'breadcrumb-item active textWrapp'} title={altname ? altname : bcName}>
              {altname ? altname : bcName}
            </li>
          </ol>
        </>
      ) : null}
    </>
  );
};

export const BreadcrumbItems = memo(BreadcrumbItemsComponent);
