import { Selectable } from '../../types/common';

export const auditTrailSortFields = {
  ChangeType: 'Change Type',
  DateandTime: 'Date and Time',
  EditorName: 'Editor Name',
};

export const auditTrailSortFields_BidView = {
  DateandTime: 'Date and Time',
  EditorName: 'Editor Name',
};

export const bidSortFields = {
  agency: 'Agency Name',
  bidIdentifier: 'Bid Identifier',
  bidName: 'Name',
  broadCastDate: 'Broadcast Date',
  city: 'Location',
  dueDate: 'Due Date',
  status: 'Status',
};

export const broadcasthistorySortFields = { name: 'Supplier', date: 'Date' };

export const ordersSortFields = {
  agencyName: 'AgencyName',
  bidName: 'BidName',
  sentDate: 'SentDate',
};

export interface Country extends Selectable<number> {
  key: string;
  selected?: boolean;
  title: string;
}

export const countrieslist: Country[] = [
  {
    key: 'usa',
    label: 'United States of America',
    title: 'United States of America',
    value: 1,
  },
  {
    key: 'canada',
    label: 'Canada',
    title: 'Canada',
    value: 5,
  },
];

export const lineItemsSortFields = { description: 'Description', quantity: 'Quantity' };

export const mybidresponsesSortFields = {
  name: 'Name',
  responseDate: 'Reminder Date',
  status: 'Status',
};

export const optionalpageedit = [
  'Build Broadcast List',
  'Manage Documents',
  'Plan/Blueprint Information',
  'Publications',
  'Supplemental Suppliers',
];

export const pageSize = {
  auditTrail: 20,
  bidResults: 10,
  bidSuppliers: 10,
  broadcastlist: 7,
  default: 10,
  paymenthistory: 10,
  planholders: 20,
  prebuiltList: 10,
  quoteResponses: 10,
  supplierslist: 20,
};

export const planholdersSortFields = { supplierName: 'Supplier', sentDate: 'Date' };

export const productFields = [{ fieldname: 'productName', fieldlabel: 'Product', width: 200 }];

export const publicationFields = [{ fieldname: 'publication', fieldlabel: 'Publication' }];

export const quoteSortFields = {
  broadcastDate: 'Broadcast Date',
  dueDate: 'Due Date',
  quoteName: 'Name',
  quoteNumber: 'Quote Number',
  status: 'Status',
};

export const radius = [
  {
    id: 0,
    title: '5 miles',
    value: '5',
    selected: false,
    key: 'radius',
  },
  {
    id: 1,
    title: '10 miles',
    value: '10',
    selected: false,
    key: 'radius',
  },
  {
    id: 2,
    title: '25 miles',
    value: '25',
    selected: false,
    key: 'radius',
  },
  {
    id: 3,
    title: '50 miles',
    value: '50',
    selected: false,
    key: 'radius',
  },
  {
    id: 4,
    title: '100 miles',
    value: '100',
    selected: false,
    key: 'radius',
  },
];

export const remindersSortFields = { subject: 'Subject', reminderDate: 'Reminder Date' };

export enum sortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
  noSort = '',
}

export const SupplierResponseStatus: any = {
  EM: 'No Response',
  NQ: 'Not Quoted',
  PB: 'Pending Broadcast',
  PD: 'Quoting But Later',
  RS: 'Response Submitted',
  VW: 'Viewed Quote',
};

export const supplierTypes = ['MS', 'PL', 'SB', 'SS', 'SU', 'SV'];

export const toastAutoCloseDelay = 4500;
