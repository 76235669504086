import React from 'react';

import { defaultNumericId } from '.';
import { getRequiredActionUniqueId } from '../../utils/required-action';
import { RequiredAction } from '../../types/required-action';

/**
 * Required Actions - List Table
 */
export const tableHeaders = {
  actionType: 'Action Type',
  dueDate: 'Due Date',
  requiredAction: 'Required Action',
  solicitationId: 'ID',
  solicitationName: 'Solicitation Name',
  status: 'Status',
};

export const requiredActionLabels = {
  headerLabels: tableHeaders,
  title: 'Required Action',
  titleAll: 'Required Actions for All Bids',
  titlePlural: 'Required Actions',
  listLabel: 'Required Actions List',
  detailsLabel: 'Required Actions Details',
  notFound: 'No Required Actions Available',
  error: 'There was an error loading Required Actions.',
  loading: 'Loading Required Actions...',
  selectAnAction: 'Select a Required Action from the list on the left',
};

export const MY_RESPONSE_LABEL = 'My Response';
export const MY_RESPONSE_LABEL_PLURAL = `${MY_RESPONSE_LABEL}s`;

export const defaultRequiredAction: RequiredAction = {
  actionId: '',
  bidId: defaultNumericId,
  bidTitle: '',
  component: <span />,
  dueDate: '',
  label: '',
  status: 'Not Started',
  type: 'requiredDocument',
};
