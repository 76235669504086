import { useEffect, useRef, useState, useCallback } from 'react';

// To Get prevProps or prevState in the Components
const usePrevious = (value: any) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(
    () => {
      ref.current = value;
    },
    [value], // Only re-run if value changes
  );
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

// To Control the rapidly calling expensive function executions
const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

// To call certain function based on some interval
const useInterval = (callback: any, delay: number) => {
  const savedCallback: any = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

// create your forceUpdate hook
const useForceUpdate = () => {
  const [value, set] = useState(true); //boolean state
  return () => set(!value); // toggle the state to force render
};

// React Idle timer
const useIdleMonitor = (
  timeout: number,
  onTimeOut: { (): void; (): any } /* warningTimeout, onWarn */,
) => {
  /*
	const warn = useCallback(() => {
		onWarn && onWarn()
	}, [onWarn])
*/

  const logout = useCallback(() => {
    onTimeOut && onTimeOut();
  }, [onTimeOut]);

  useEffect(() => {
    // let warnTimeout
    let logoutTimeout: any;

    const setTimeouts = () => {
      // warnTimeout = setTimeout(warn, warningTimeout)
      logoutTimeout = setTimeout(logout, timeout);
    };

    const clearTimeouts = () => {
      // if (warnTimeout) clearTimeout(warnTimeout)
      if (logoutTimeout) clearTimeout(logoutTimeout);
    };
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [logout, timeout /*warn, warningTimeout*/]);
};

export { usePrevious, useDebounce, useInterval, useForceUpdate, useIdleMonitor };
