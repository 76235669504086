import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { Buttons } from '../../../customcontrols';

function Store() {
  return (
    <div className='row'>
      <div className='col-3' />
      <div className='col-6'>
        <h3>Store</h3>
        <Table>
          <tr>
            <th>Product</th>
            <th>Type</th>
            <th># of Counties</th>
            <th># of Agencies</th>
            <th>Cost</th>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>State</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>Free Agency</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>Agency</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>County</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>State</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>Free Agency</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>Agency</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>
              <i className='mdi mdi-plus' />
              Product Name
            </td>
            <td>County</td>
            <td>###</td>
            <td>###</td>
            <td>$0.00</td>
          </tr>
        </Table>
      </div>
      <div className='col-3'>
        <div className='cartWrapper clearfix'>
          <div className='cartBody'>
            <h6>Total Historical Payments</h6>
            <ul className='list-unstyled'>
              <li className='cartItem'>
                <label>Subscriptions (24)</label>
                <span>$1350/yr</span>
              </li>
            </ul>

            <h6 className='mt-4'>New Subscriptions</h6>
            <ul className='list-unstyled'>
              <li className='cartItem'>
                <label>County Name 1</label>
                <i className='mdi mdi-delete-forever-outline' />
                <span>$25/yr</span>
              </li>
              <li className='cartItem'>
                <label>County Name 1</label>
                <i className='mdi mdi-delete-forever-outline' />
                <span>$25/yr</span>
              </li>
              <li className='cartItem'>
                <label>County Name 1</label>
                <i className='mdi mdi-delete-forever-outline' />
                <span>$25/yr</span>
              </li>
            </ul>
            <p className='text-right mt-2'>New Subscriptions Amount: $50/yr</p>
            <hr className='clearfix' />
            <Buttons classNames='bttn bttn-primary' text='Proceed to Checkout' />
            <small>Checkout is where you save these changes to your yearly subscriptions.</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Store);
