import styled from 'styled-components';

import { BackgroundColor, BorderColor } from './colors';

export const FullWidth = styled.div`
  width: 100%;
`;

export const TextOverflowEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;
`;

export const ContentRowWrapping = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InnerColumnNarrow = styled.div`
  display: flex;
  flex-basis: 33%;
  flex-direction: column;
  flex-grow: 1;
  width: 33%;
`;

export const InnerColumnWide = styled.div`
  display: flex;
  flex-basis: 67%;
  flex-direction: column;
  flex-grow: 2;
  width: 67%;
`;

export const HighlightedDetailsWrapper = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  border: solid 0.1875rem ${BorderColor.Highlight};
  border-radius: 0.125rem;
  height: 100%;
  margin-left: -3rem;
  margin-right: -1rem;
  padding: 0.75rem 1rem 0.75rem 3rem;
`;

export const HighlightedListWrapper = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  height: 100%;
  margin-left: -1.5rem;
  padding: 0.75rem 1.5rem 0 1.5rem;
`;
