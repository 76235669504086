import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

export const initialProgramFilters = {
  supplierName: '',
  expires: '',
  status: '',
  programId: 0,
  isSubscriber: true,
};

interface initialStateTypes {
  programs: any[];
  suppliers: any[];
  programFilters: any;
}

export const initialStatePrograms: initialStateTypes = {
  programs: [],
  suppliers: [],
  programFilters: { ...initialProgramFilters },
};

export const reducer = (state = initialStatePrograms, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.GET_PROGRAMS.SUCCESS:
      state = {
        ...state,
        programs: [...action.payload],
      };
      break;
    case actionTypes.ADD_UPDATE_PROGRAM.SUCCESS:
      state = {
        ...state,
        programs: [...action.payload],
      };
      break;
    case actionTypes.GET_SUPPLIERS.SUCCESS:
      state = {
        ...state,
        suppliers: [...action.payload],
      };
      break;
    case actionTypes.RESPOND_CERT_REQUEST.SUCCESS:
      state = {
        ...state,
      };
      break;
    case actionTypes.SET_PROGRAM_FILTERS.ACTION:
      state = {
        ...state,
        programFilters: {
          ...state.programFilters,
          ...action.payload,
        },
      };
      break;
    case actionTypes.CLEAR_PROGRAM_FILTERS.ACTION:
      state = {
        ...state,
        programFilters: {
          ...initialProgramFilters,
        },
      };
      break;
    case actionTypes.SET_PROGRAM_DETAILS.TRIGGER:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case actionTypes.RESET_PROGRAMS.TRIGGER:
      state = initialStatePrograms;
      break;
    default:
      break;
  }
  return state;
};
