import React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { ErrorPanel, LoadingPanel } from '../../../components/common/loading';
import { getDefaultLoadingErrorMessage, getDefaultLoadingMessage } from '../../../utils/message';
import { PanelLabel } from '../../../shared/styles';

import { BID_DETAILS_LABEL } from '../../../shared/constants/bids';
import { BidSummaryPanelView } from './BidSummaryPanelView';
import { bidSummaryState } from '../../../store/recoil/bidState';

export type BidSummaryPanelProps = { label?: string };

export function BidSummaryPanel({ label }: BidSummaryPanelProps) {
  // App state
  // Get the BidSummary for the current bidId.
  // Updating the selectedBidId will update this loadable in the store.
  const bidSummaryLoadable = useRecoilValueLoadable(bidSummaryState);

  function render() {
    let returnContent = null;
    // Handle Recoil State Loadable states:
    // TODO: Standardize styled components for "hasError" and/or "loading" states.
    if (bidSummaryLoadable.state === 'hasError') {
      returnContent = <ErrorPanel message={getDefaultLoadingErrorMessage(BID_DETAILS_LABEL)} />;
    }

    // TODO: track "loading" components in app state and/or port over the
    // loading star animated overlay to avoid showing multiple at the same time.
    if (bidSummaryLoadable.state === 'loading') {
      returnContent = <LoadingPanel message={getDefaultLoadingMessage(BID_DETAILS_LABEL)} />;
    }

    if (bidSummaryLoadable.state === 'hasValue' && bidSummaryLoadable?.contents) {
      const bidSummary = bidSummaryLoadable?.contents;
      const viewProps = { data: bidSummary };

      returnContent = <BidSummaryPanelView {...viewProps} />;
    }

    return (
      <>
        {label && <PanelLabel>{label}</PanelLabel>}
        {returnContent}
      </>
    );
  }

  return render();
}
