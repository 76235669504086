import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { toursactionTypes } from '../../types/tours';
import { ToursState } from '../reducers/tours';

export type SetToursDetailsPayload = Partial<ToursState>;

export function setToursDetails(payload: SetToursDetailsPayload) {
  return action(actionTypes.SET_TOURS_DETAILS, payload);
}

export function resetBasicTour() {
  return action(actionTypes.RESET_BASIC_TOURS);
}

export function resetTours(payload: toursactionTypes) {
  return action(actionTypes.RESET_TOURS, payload);
}

export function getToursConfigurations() {
  return action(actionTypes.GET_TOURS_CONFIGURATIONS);
}

export function submitToursConfigurations(payload: toursactionTypes) {
  return action(actionTypes.SUBMIT_TOURS_CONFIGURATIONS, payload);
}
