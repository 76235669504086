import React, { memo, useState } from 'react';
import { Buttons, DeprecatedInput, TextArea } from '../../../../../../customcontrols';
import BreakdownList from '../../../../../../common/quotebreakdownlist';
import { Table } from 'reactstrap';
import { LineItemType, QuoteSummaryType } from '../../../../../../../types/quotedetailstypedef';
import { formattedPhoneNumber } from '../../../../../../../utils/helpers';
const tdWidth = {
  width: '35%',
};

interface PropsTypes {
  quoteSummary?: QuoteSummaryType;
  awardModalUpdate?: any;
  sendRewardMail?: any;
  breakDownlist?: LineItemType[];
  match?: any;
}
const QuoteAwardEmail = (props: PropsTypes) => {
  const { quoteSummary, awardModalUpdate, sendRewardMail, breakDownlist = [] } = props;
  const { summary, currentSupplier /*, selectedResponseItem*/ } = quoteSummary || {};
  const { quoteId, contactEmail } = summary || {};
  const {
    responseId,
    email,
    preparer,
    supplierName = 'Not Specified',
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    postalCode = '',
    countryName = '',
    phoneNumber = '',
  } = currentSupplier;

  const onChnageFn = (name: string, value: any) => {};

  const currentPage: string = props.match.params.tab;

  const [awardnotes, setAwardNotes] = useState('');

  const sendSupplierRewardMail = () => {
    const responseItems = breakDownlist.map(item => item.responseItemId) || [];
    const fields = {
      quoteId: quoteId,
      responseId: responseId,
      responseItemIds: responseItems.join(','),
      notes: awardnotes.trim(),
      to: email,
      cc: contactEmail,
      currentPage: currentPage,
    };
    sendRewardMail(fields);
  };

  return (
    <>
      <p>
        {
          'Review the following Award Notification email, and enter Award Notes in the text box, if desired. The email will be sent to the Supplier when you click "Send This Award Email" button.'
        }
      </p>
      <h4 className='lineHR'>Email Address</h4>
      <div className='innerColIndent'>
        <div className='row'>
          <div className='col-md-12 col-lg-6'>
            <DeprecatedInput
              name='toEmail'
              label='To'
              type='text'
              classNames='class'
              value={email}
              disabled='true'
              maxLength={100}
              handleChange={(name: string, value: string) => onChnageFn(name, value)}
            />
          </div>
          <div className='col-md-12 col-lg-6'>
            <DeprecatedInput
              name='ccEmail'
              label='CC'
              type='text'
              classNames='class'
              value={summary && summary.contactEmail}
              disabled='true'
              maxLength={100}
              handleChange={(name: string, value: string) => onChnageFn(name, value)}
            />
          </div>
        </div>
      </div>
      <h4 className='lineHR'>Subject</h4>
      <div className='innerColIndent'>
        <p>{`AWARD - DemandStar.com, Quote: ${summary && summary.quoteName}, Quote No. ${
          summary && summary.quoteNumber
        }`}</p>
      </div>
      <h4 className='lineHR'>Message</h4>
      <div className='innerColIndent'>
        <Table className='tableData' borderless>
          <tr>
            <th style={tdWidth}>Attention</th>
            <td width='65%'>{preparer}</td>
          </tr>
          <tr>
            <th>Supplier Name</th>
            <td>{supplierName}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>
              {address1} {address2}
            </td>
          </tr>
          <tr>
            <th>City/State/Postal Code</th>
            <td>{`${city} ${state} ${postalCode}`}</td>
          </tr>
          <tr>
            <th>Country</th>
            <td>{countryName}</td>
          </tr>
          <tr>
            <th>Phone number</th>
            <td>{phoneNumber ? formattedPhoneNumber(phoneNumber) : ''}</td>
          </tr>
          <tr>
            <th>Agency Award Notes</th>
            <td>
              <TextArea
                type='text'
                label='Agency Award Notes (P.O Number or other award information)'
                value={awardnotes}
                handleChange={(name: string, value: string) => setAwardNotes(value)}
                classNames='class'
              />
            </td>
          </tr>
        </Table>
        {/* <p>
					<p>Attention: {preparer}</p>
					<p>{supplierName}</p>
					<p>
						{address1} {address2}
					</p>
					<p>{`${city} ${state} ${postalCode}`}</p>
					<p>{countryName}</p>
					{phoneNumber && <p>{`Phone: ${phoneNumber}`}</p>}
				</p> */}

        {/* <p>Agency Award Notes : </p>
				<TextArea
					type='text'
					label='Agency Award Notes (P.O Number or other award information)'
					value={awardnotes}
					handleChange={(name, value) => setAwardNotes(value)}
					classNames='class'
				/>
				<p>The Following Quote line items(s) is being awarded to you:</p> */}
        {/* <p>{summary.contactDepartment}</p>
				<p>{`Quote ${summary.quoteNumber}`}</p>
				<p>{summary.quoteName}</p> */}
      </div>
      <h4 className='lineHR'>Line Items</h4>
      <div className='innerColIndent'>
        <p>The Following Quote line items(s) is being awarded to you</p>
        <Table className='tableData' borderless>
          <tr>
            <th style={tdWidth}>Contact Department</th>
            <td width='65%'>{summary && summary.contactDepartment}</td>
          </tr>
          <tr>
            <th>Quote Number</th>
            <td>{summary && summary.quoteNumber}</td>
          </tr>
          <tr>
            <th>Quote Name</th>
            <td>{summary && summary.quoteName}</td>
          </tr>
        </Table>
        <BreakdownList {...props} pagefor='suppliers' type={2} />
      </div>

      <h4 className='lineHR'>Agency Contact Information</h4>
      <div className='innerColIndent'>
        <Table className='tableData' borderless>
          <tr>
            <th style={tdWidth}>Name</th>
            <td width='65%'>{summary && summary.contactName}</td>
          </tr>
          <tr>
            <th>Department</th>
            <td>{summary && summary.contactDepartment}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>{summary && summary.contactAddress1}</td>
          </tr>
          <tr>
            <th>City/State/Postal Code</th>
            <td>{`${summary && summary.contactCity} ${summary && summary.contactStateAbbr} ${
              summary && summary.contactPostalCode
            }`}</td>
          </tr>
          <tr>
            <th>Country</th>
            <td>{summary && summary.contactCountryName}</td>
          </tr>
          <tr>
            <th>Phone number</th>
            <td>
              {summary && summary.contactPhone ? formattedPhoneNumber(summary.contactPhone) : ''}
            </td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{summary && summary.contactEmail}</td>
          </tr>
        </Table>
      </div>

      {/* <p>
				<p>{summary.contactName}</p>
				<p>{summary.contactDepartment}</p>
				<p>{summary.contactAddress1}</p>
				<p>{`${summary.contactCity} ${summary.contactStateAbbr} ${summary.contactPostalCode}`}</p>
				<p>{summary.contactCountryName}</p>
				{summary.contactPhone && <p>{`Phone: ${summary.contactPhone}`}</p>}
				{summary.contactEmail && <p>{`Email: ${summary.contactEmail}`}</p>}
			</p> */}
      <Buttons
        classNames='bttn-secondary mt-2'
        text='Cancel'
        title='Cancel'
        type='button'
        onClick={awardModalUpdate}
      />
      <Buttons
        classNames='bttn-primary  mt-2 float-right'
        text='Send this Award Email'
        title='Send this Award Email'
        type='button'
        onClick={sendSupplierRewardMail}
      />
    </>
  );
};

export default memo(QuoteAwardEmail);
