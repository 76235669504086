import { Loadable } from 'recoil';

/**
 * Get the generically typed contents from a Recoil Loadable object.
 * @description This will return the `Loadable.contents` if the `Loadable.state` is `hasValue`,
 *              otherwise returns null.
 * @param {Loadable<T>} loadable
 * @returns loadable.contents: T or null
 */
export function getLoadableContents<T>(loadable: Loadable<T>) {
  return loadable.state === 'hasValue' ? loadable.contents : null;
}
