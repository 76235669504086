import React from 'react';

import { LoadingTextWrapper, NotFoundText } from '../../../shared/styles';
import { InfoIcon } from '../icons';
import { LOADING_MESSAGE_DEFAULT } from '../../../shared/constants';

export interface LoadingViewProps {
  message?: string;
}
export function LoadingPanel(props: LoadingViewProps) {
  return (
    <LoadingTextWrapper>
      <InfoIcon width={18} height={18} />
      <NotFoundText>{props.message || LOADING_MESSAGE_DEFAULT}</NotFoundText>
    </LoadingTextWrapper>
  );
}
