import { atom, selector } from 'recoil';

import { Assert } from '../../../utils/helpers';
import { Awardee } from '../../../types/biddetail';
import { bidPlanholderListState } from '../bidState';
import { responseListState } from '../bidResponseState';
import { UnselectedBidder } from '../../../types/awardBid';

/**
 * Set up an ID to be set as a selector dependency
 * to kick off additional selector service calls
 * when changed by user interaction.
 */
export const selectedBidAwardeeState = atom<Awardee | null>({
  key: 'selectedBidAwardeeState',
  default: null,
});

/**
 * Initialize the bidAwardeeList state with the associated API call.
 * @description This is the state the UI will consume.
 * TODO: implement setter to POST to API.
 */
export const bidAwardeeListState = atom<Awardee[]>({
  key: 'bidAwardeeListState',
  default: [],
});

export const unselectedBiddersSelector = selector<UnselectedBidder[]>({
  key: 'unselectedBidders',
  get: ({ get }) => {
    const planholders = get(bidPlanholderListState);
    const responses = get(responseListState);
    const awardees = get(bidAwardeeListState);

    const potentials: UnselectedBidder[] = [];

    function isDuplicate(memberId: number) {
      // is this supplier already selected?
      if (
        awardees.findIndex(awardee => {
          return awardee.awardedToMemberId === memberId;
        }) !== -1
      ) {
        return true;
      }

      // is this supplier already an option?
      if (
        potentials.findIndex(potential => {
          return potential.value === memberId;
        }) !== -1
      ) {
        return true;
      }
      return false;
    }

    // add all non-duplicate planholders
    for (const planholder of planholders) {
      if (!isDuplicate(planholder.mi)) {
        potentials.push({
          label: planholder.supplierName,
          value: planholder.mi,
        });
      }
    }

    // add all non-duplicate eBid responses
    for (const response of responses) {
      if (!isDuplicate(response.supplierMemberId)) {
        // TODO: uncomment once data is sanitized (this currently triggers several alerts in the Vendor Docs view)
        // Assert(!!response.email, 'All eBid Responses should have an email.', 'awardBidState.ts');
        potentials.push({
          label: response.name,
          value: response.supplierMemberId,
        });
        for (const account of response.accounts) {
          Assert(!!account.email, 'All eBid Responses should have an email.', 'awardBidState.ts');
        }
      }
    }
    return potentials;
  },
});
