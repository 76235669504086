import React, { memo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../store/actions';
import { AgencySelection } from '../components/shared/accountinfo/subscriptions/agency-selection';
import { State, County, FreeAgency } from '../types/paymentdetails';
import { ReduxAction } from '../store/actions/utils';
import { getProductTypeExpanded, ProductType } from '../types/products';

export interface AccountInfo {
  accountinfo: { products: Product[] };
  agencyCounties: County[];
  agencyStates: State[];
  freeAgencies: FreeAgency[];
}

interface ReduxState {
  accountinfo: AccountInfo;
  subscriptions: { products: Product[]; allProducts: Product[] };
}

interface OwnProps {
  toggleAgencySelection: (payload?: Record<string, unknown>) => ReduxAction;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  getSubscriptionState: (payload?: Record<string, unknown>) => ReduxAction;
  setAccountInfoDetails: (payload?: Record<string, unknown>) => ReduxAction;
  getSubscriptionAgencies: (payload?: Record<string, unknown>) => ReduxAction;
  getSubscriptionCounties: (payload?: Record<string, unknown>) => ReduxAction;
  UpdateSupplierFreeAgencies: (payload?: Record<string, unknown>) => ReduxAction;
  getAllProducts: (payload?: Record<string, unknown>) => ReduxAction;
};

export interface Product {
  productId: number;
  productName: string;
  productType: ProductType;
  price: number;
}

function AgencySelectionComponent(props: Props) {
  let { products = [] } = props.accountinfo.accountinfo;
  products = products.filter((p: Product) => {
    const { productId, productName, productType, price } = p;
    return {
      productId,
      productName,
      price,
      productType: getProductTypeExpanded(productType),
    };
  });

  return (
    <AgencySelection
      products={products}
      toggleAgencySelection={props.toggleAgencySelection}
      UpdateSupplierFreeAgencies={props.UpdateSupplierFreeAgencies}
      userType='supplier'
    />
  );
}

const connector = connect(
  (state: ReduxState, ownProps: OwnProps) => ({
    accountinfo: state.accountinfo,
    toggleAgencySelection: ownProps.toggleAgencySelection,
    subscriptions: state.subscriptions,
  }),
  dispatch => {
    const {
      getSubscriptionState,
      setAccountInfoDetails,
      getSubscriptionAgencies,
      getSubscriptionCounties,
      UpdateSupplierFreeAgencies,
      getAllProducts,
    } = bindActionCreators({ ...actionCreators }, dispatch);
    return {
      getSubscriptionState,
      getSubscriptionAgencies,
      getSubscriptionCounties,
      setAccountInfoDetails,
      UpdateSupplierFreeAgencies,
      getAllProducts,
    };
  },
);

export const AgencySelectionContainer = connector(memo(AgencySelectionComponent));
