import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
const tdWidth = {
  planholder: {
    width: '240px',
  },
  detail: {
    width: '340px',
  },
};
interface PropsTypes {
  exportBroadcastList: any;
  exportSupplementalSuppliers: any;
  bidId: string | number;
  stats?: {
    planholders: number;
    broadcasts: number;
    isFiltered: boolean;
    postBidViewers: number;
    supplimentalSuppliers: number;
  };
}
const Statistics = (props: PropsTypes) => {
  const { planholders, broadcasts, isFiltered, postBidViewers, supplimentalSuppliers } =
    props.stats || {};
  const { exportBroadcastList, exportSupplementalSuppliers, bidId } = props;

  const id = bidId;
  const pagetype = 'Bid';

  const handleExportClick = (e: any) => {
    e.preventDefault();
    exportBroadcastList({ id, type: pagetype });
  };

  const handleSSExportClick = (e: any) => {
    e.preventDefault();
    exportSupplementalSuppliers({ id, type: pagetype });
  };

  return (
    <Table borderless className='tableData'>
      <tbody>
        <tr>
          <th style={tdWidth.planholder}>Planholders</th>
          <td style={tdWidth.detail}>There are {planholders} Planholders for this bid.</td>
          <td />
        </tr>
        <tr>
          <th>Filtered</th>
          <td>{isFiltered ? 'Yes' : 'No'}</td>
          <td />
        </tr>
        <tr>
          <th>Broadcast List</th>
          <td>{broadcasts} Suppliers have been notified</td>
          <td>
            {broadcasts && broadcasts > 0 ? (
              <span className='staticLink' onClick={handleExportClick}>
                Export
              </span>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>Post-Bid Viewers</th>
          <td>There are {postBidViewers} Post-Bid viewers</td>
          <td />
        </tr>
        <tr>
          <th>Supplemental Suppliers</th>
          <td>{supplimentalSuppliers} Supplemental Suppliers</td>
          <td>
            {supplimentalSuppliers && supplimentalSuppliers > 0 ? (
              <span className='staticLink' onClick={handleSSExportClick}>
                Export
              </span>
            ) : null}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state: any) => {
  return {
    stats: state.bidssummary.stats,
  };
};

export default connect(mapStateToProps)(Statistics);
