/**
 * Ensure a given key name is included in a string union of keys from the supplied type.
 * @param name (key of the supplied type)
 * @returns name (typechecked against keys of the supplied type)
 */
export function getTypeSafeKey<T>(name: keyof T): keyof T {
  return name;
}

export function isDefined(test: unknown): boolean {
  return typeof test !== 'undefined';
}

/**
 * Capture and preserve types to enable type inference based upon a seed object.
 * From `https://stackoverflow.com/questions/53662208/types-from-both-keys-and-values-of-object-in-typescript`
 * @param object
 * @returns
 */
export function getTypeSafeObject<V extends string, T extends { [key in string]: V }>(
  object: T,
): T {
  return object;
}
