import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { Paging } from '../../../customcontrols';
import { pageSize } from '../../../../utils/constants';
import { bidssummaryParamType } from '../../../../types/biddetail';
import { formattedPhoneNumber } from '../../../../utils/helpers';

interface PropsTypes {
  /* changeAddSupplierActive?: any;
  loadbidaddplanholderdata?: any; */
  addNewSupplier?: any;
  bidssummary?: bidssummaryParamType;
  resetSearchPlanholder?: any;
  pagfor: string;
  match?: any;
}

function SupplierResult(props: PropsTypes) {
  const {
    /* changeAddSupplierActive,
    loadbidaddplanholderdata, */
    addNewSupplier,
    bidssummary,
    resetSearchPlanholder,
  } = props;
  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;
  const { searchFields, searchPlanholder = [] } = bidssummary || {};
  const bidId = parseInt(props?.match?.params?.bidId);

  const AddExistingPlanholder = (item: any) => {
    const contact = item.firstName + (item.lastName ? ' ' + item.lastName : null);
    const formData = {
      companyname: item.supplierName,
      city: item.city,
      phoneNumber: item.phone,
      email: item.email,
      planholderId: item.memberId,
      address1: item.address1,
      address2: item.address2,
      state: { id: item.stateId },
      country: { value: item.countryId },
      postalCode: item.postalCode,
      faxNumber: item.fax ? item.fax : '',
      contact,
      selfdecAttr: '',
      bidId,
    };

    addNewSupplier({ type: 1, formData });

    /* changeAddSupplierActive({ addSupplierActive: 'add' });
    loadbidaddplanholderdata(item); */
  };
  const resetSearch = () => {
    resetSearchPlanholder();
  };

  const supplierName = searchFields && searchFields.name ? searchFields.name : '';

  const searchFieldArray = [];

  if (searchFields && searchFields.city)
    searchFieldArray.push({ field: 'City', value: searchFields.city });
  if (searchFields && searchFields.phoneNumber)
    searchFieldArray.push({ field: 'Phone Number', value: searchFields.phoneNumber });
  if (searchFields && searchFields.email)
    searchFieldArray.push({ field: 'Email', value: searchFields.email });

  return (
    <>
      <div className='headerFilter clearfix d-flex align-items-center'>
        <div className='flex1'>
          {supplierName && (
            <div className='d-block'>
              <b>Supplier Name</b>: {supplierName}
            </div>
          )}
          <div className='d-flex'>
            {searchFieldArray.map((item, index) => (
              <label className='mr-1' key={index}>
                <b>{item.field}</b>: {item.value}{' '}
                {index !== searchFieldArray.length - 1 ? ', ' : null}
              </label>
            ))}
          </div>
        </div>
        <span onClick={resetSearch} className='bttn bttn-secondary small float-right staticLink'>
          Modify search
        </span>
      </div>
      <Table className='tableHData' responsive size='lg'>
        <tr>
          <th>Supplier Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>City, State</th>
          <th>Action</th>
        </tr>
        {searchPlanholder
          .map((item, index) => (
            <tr key={index}>
              <td>{item.supplierName}</td>
              <td>{formattedPhoneNumber(item.phone)}</td>
              <td>{item.email}</td>
              <td>
                {item.city}
                {item.city && item.state ? ',' : null} {item.state}
              </td>
              <td>
                <span className='bttn-secondary small' onClick={() => AddExistingPlanholder(item)}>
                  {/* <i className='mdi mdi-plus' /> */}
                  Add
                </span>
              </td>
            </tr>
          ))
          .slice(indexOfFirstList, indexOfLastList)}
      </Table>
      <Paging
        totalRecords={searchPlanholder.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
}

export default SupplierResult;
