export type BuildRouteLinkParams = {
  baseUrl: string;
  id?: string | number;
  subUrl?: string;
  queryString?: string;
};

export function buildRouteLink(params: BuildRouteLinkParams) {
  const { baseUrl, id, subUrl, queryString } = params;
  let route = `${baseUrl}`;
  if (id) {
    route = `${route}/${id}`;
  }
  if (subUrl) {
    route = `${route}/${subUrl}`;
  }
  if (queryString) {
    route = `${route}?${queryString}`;
  }
  return route;
}
