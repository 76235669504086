import React, { memo } from 'react';
// import helpImgs from '../../../../assets/images/help'

import imgsCdn from '../../../../assets/images/imagescdn';

const helpImgs = imgsCdn.helpImgs;

function SelfDeclarations() {
  return (
    <>
      <p>
        You are encouraged to self-declare any of the attributes that apply to your business. This
        helps suppliers find vendors that help them meet their diversity goals. It is important that
        you have these attributes identified when you are responding to bids on the DemandStar
        network. Here are the steps.
      </p>

      <ol>
        <li> Log-in to your DemandStar account. </li>
        <li> Click on your name in the upper right-hand corner</li>
        <li>
          {' '}
          Click on “Account”
          <img src={helpImgs.picture5} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Across the top, click on the tab for “Self Declarations”{' '}
          <img src={helpImgs.picture6} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Click the attributes that apply and click “Save Changes”
          <img src={helpImgs.picture7} alt='images' className='d-block mt-2 img-fluid' />
        </li>
      </ol>
    </>
  );
}

export default memo(SelfDeclarations);
