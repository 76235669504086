import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  Colors,
  ContentRowWrapper,
  DashboardRightColumn,
  HighlightedDetailsWrapper,
  HighlightedListWrapper,
  LeftColumnAbove,
} from '../../../shared/styles';
import { requiredActionLabels } from '../../../shared/constants';
import {
  requiredActionListState,
  selectedRequiredActionIdState,
} from '../../../store/recoil/award-bid';

import { ArrowTitle } from '../../../components/layout/titles';
import { BidSummaryLabels } from '../../../shared/constants/bids';
import { BidSummaryPanel } from './BidSummaryPanel';
import { getDefaultLoadingMessage } from '../../../utils/message';
import { LoadingPanel } from '../../../components/common/loading';
import { RequiredAction } from '../../../types/required-action';
import { RequiredActionDetailsPanelView } from './RequiredActionDetailsPanelView';
import { RequiredActionsListPanelView } from './RequiredActionsListPanelView';
import { selectedBidIdState } from '../../../store/recoil/bidState';
import { useRequiredActions } from '../../../shared/hooks/award-bid/useRequiredActions';
import { useResetOnUnmount } from '../../../shared/hooks/useResetOnUnmount';
import { useSetSelectedBidIdFromRoute } from '../../../shared/hooks/useSelectedBidId';

export function SupplierBidManagement() {
  /// Hooks

  // Update the selected bidId from the current url params.
  useSetSelectedBidIdFromRoute();

  const {
    refreshRequiredActions,
    requiredActionIncompleteList,
    requiredActionList,
    requiredActionsLoading,
  } = useRequiredActions();

  const [selectedRequiredActionId, setSelectedRequiredActionId] = useRecoilState(
    selectedRequiredActionIdState,
  );

  const selectedBidId = useRecoilValue(selectedBidIdState);

  useResetOnUnmount(selectedBidIdState);
  useResetOnUnmount(requiredActionListState);

  // Refresh RequiredActions only for a valid bidId.
  useEffect(() => {
    if (selectedBidId > 0) {
      refreshRequiredActions();
    }
  }, [refreshRequiredActions, selectedBidId]);

  /**
   * Update the value of the `selectedRequiredActionIdState`
   * Navigate to the bid management view.
   * @param bidId: string
   * @param actionId: string
   */
  const handleActionClick = (requiredAction: RequiredAction) => (event: MouseEvent) => {
    setSelectedRequiredActionId(requiredAction.actionId);
  };

  // TODO: Redux dispatch refreshes appear to reset results.
  // Solve this list problem in Recoil or move to direct queries.
  const requiredActionListFiltered = requiredActionList.filter(
    ({ bidId }) => bidId === selectedBidId,
  );

  const requiredActionCount = requiredActionListFiltered?.length || 0;

  const selectedRequiredAction = requiredActionListFiltered.find(
    action => action.actionId === selectedRequiredActionId,
  );

  const requiredActionCountDisplay =
    requiredActionIncompleteList.length > 0 ? `(${requiredActionIncompleteList.length})` : '';

  return (
    <>
      <ArrowTitle bgColor={Colors.orangeLight} label={requiredActionLabels.titlePlural} />
      <ContentRowWrapper>
        <>
          <LeftColumnAbove>
            <HighlightedListWrapper>
              {requiredActionsLoading ? (
                <LoadingPanel
                  message={getDefaultLoadingMessage(requiredActionLabels.titlePlural)}
                />
              ) : requiredActionCount > 0 ? (
                <RequiredActionsListPanelView
                  requiredActions={requiredActionListFiltered}
                  label={`${requiredActionLabels.titlePlural} ${requiredActionCountDisplay}`}
                  handleActionClick={handleActionClick}
                  selectedRequiredActionId={selectedRequiredAction?.actionId}
                />
              ) : (
                <LoadingPanel message={requiredActionLabels.notFound} />
              )}
            </HighlightedListWrapper>
          </LeftColumnAbove>
          <DashboardRightColumn>
            <HighlightedDetailsWrapper>
              <BidSummaryPanel label={BidSummaryLabels.title} />
              {requiredActionCount > 0 && (
                <RequiredActionDetailsPanelView requiredAction={selectedRequiredAction} />
              )}
            </HighlightedDetailsWrapper>
          </DashboardRightColumn>
        </>
      </ContentRowWrapper>
    </>
  );
}
