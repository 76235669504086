import moment from 'moment-timezone';
import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { addbidParamType } from '../../../types/addbid';
import { WizardPage } from '../../../types/wizard';
import { sharedTypes } from '../../../types/shared';
import { usaDateFormat } from '../../../utils/constants';

import { loadingMsg } from '../../../utils/texts';
import { useForceUpdate } from '../../../utils/helperHooks';
import { toastFn } from '../../../utils/helpers';
import { Buttons, CheckBox, DeprecatedInput } from '../../customcontrols/index';
import NoResult from '../../customcontrols/noresult';
import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import AddBidWizardButtons from './AddBidWizardButtons';
import { Formik } from 'formik';

const initial = { title: '', date1: moment().format(usaDateFormat), date2: '' };
const initialpub = { index: '', allow: false };

const tdWidth = { width: '100' };

interface PropsTypes {
  valid?: Record<string, unknown>;
  pristine?: Record<string, unknown>;
  submitting?: Record<string, unknown>;
  handleSubmit?: any;
  setAddBidDetails?: any;
  addbid?: addbidParamType;
  currentwizard?: WizardPage;
  bidId?: number;
  setBreadcrumb?: any;
  submitAddBidUpdateBidPublications?: any;
  getAddBidPublications?: any;
  currentBidName?: string;
  SkipNext?: any;
  shared?: sharedTypes;
  submitAddBidMoveNext?: any;
}

function Publications(props: PropsTypes) {
  const forceUpdate = useForceUpdate();
  const {
    valid,
    pristine,
    submitting,
    handleSubmit,
    setAddBidDetails,
    addbid,
    currentwizard,
    bidId,
    setBreadcrumb,
    submitAddBidUpdateBidPublications,
    getAddBidPublications,
    currentBidName,
    SkipNext,
    shared,
    submitAddBidMoveNext,
  } = props;
  const {
    publicationslist,
    editable,
    /*addpublicationmodal, */ halfWayCancel /*addbidwizardpartial = false*/,
  } = addbid || {};
  const { internalLoader } = shared || {};
  const [singlepublication, setSinglepublication] = useState(initial);
  const [editpub, setEditpub] = useState(initialpub);
  const [newPublication, setAddNewPublication] = useState(false);

  useEffect(() => {
    if (bidId) getAddBidPublications({ bidId });
  }, [getAddBidPublications, bidId]);

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  useEffect(() => {
    if (currentwizard) {
      setBreadcrumb({
        component: (
          <AddBidControl valid={valid} pristine={pristine} submitting={submitting} bidId={bidId} />
        ),
        page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
        title: currentBidName
          ? `Update Bid - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        altname: currentBidName
          ? `Update Bid - ${currentwizard.name}`
          : `Bid Creation - ${currentwizard.name}`,
        name: currentBidName ? currentBidName : 'Bid Creation',
        id: bidId,
        menuactive: 'bids',
      });
    }
  }, [bidId, currentBidName, currentwizard, pristine, setBreadcrumb, submitting, valid]);

  const SetAddPublication = () => {
    setSinglepublication(initial);
    setEditpub({ index: '', allow: false });
  };

  const addNewPublication = () => {
    SetAddPublication();
    setAddNewPublication(true);
  };

  const onChnageFn = (name: string, value: string) => {
    setSinglepublication({ ...singlepublication, [name]: value });
    if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
  };

  const { title = '' } = singlepublication;

  const AddPublication = () => {
    if (title.trim() /* && (date1 || date2) */) {
      const savedlist =
        (publicationslist && publicationslist.filter(item => item.title === title)) || [];
      if (savedlist && savedlist.length === 0) {
        setSinglepublication(initial);
        const valuelist =
          publicationslist && publicationslist.length > 0
            ? [singlepublication, ...publicationslist]
            : [singlepublication];
        setAddBidDetails({ publicationslist: valuelist });
        SetAddPublication();
        setAddNewPublication(false);
      } else {
        toastFn('error', 'Name already exists', 'save publication already exist');
      }
    } else {
      toastFn('error', 'Atleast one date required', 'save publication already exist');
    }
  };

  const EditPublication = (index: any, item: any) => {
    const { title = '', date2 = '' } = item;
    setSinglepublication({ title, date1: moment().format(usaDateFormat), date2 });
    setEditpub({ index: index, allow: true });
  };

  const SaveEditPublication = () => {
    const savedlist =
      (publicationslist &&
        publicationslist.filter(
          (item, index) => item.title === title && index !== parseInt(editpub.index),
        )) ||
      [];
    if (savedlist.length === 0) {
      const valuelist =
        publicationslist &&
        publicationslist.map((item, index) => {
          if (index === parseInt(editpub.index)) {
            item = singlepublication;
          }
          return item;
        });
      setAddBidDetails({ publicationslist: valuelist });
      SetAddPublication();
      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
    } else {
      toastFn('error', 'Name already exists', 'save publication already exist');
    }
  };

  const updatePublicationList = (status: boolean, index: any) => {
    if (publicationslist) {
      const publicationOldList = [...publicationslist];
      if (status === true) {
        publicationOldList[index].date1 = moment().format(usaDateFormat);
        publicationOldList[index].date2 = '';
      } else {
        publicationOldList[index].date1 = '';
        publicationOldList[index].date2 = '';
      }
      setAddBidDetails({ publicationslist: publicationOldList, halfWayCancel: true });
      forceUpdate();
    }
  };

  const onSubmitFn = (data: any) => {
    if (data) {
      if (halfWayCancel) {
        if (editable === 'all') {
          submitAddBidUpdateBidPublications({ bidId });
        } else {
          SkipNext();
        }
      } else {
        submitAddBidMoveNext({ type: 'Bid', Id: bidId, stepId: 5 });
      }
    }
  };

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
      {formikProps => {
        const { values: any, handleSubmit } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            {/* <h4>Publications </h4> */}
            {editable === 'all' ? (
              <span
                className='bttn-secondary small float-right'
                title='Add Publication'
                onClick={addNewPublication}
              >
                <i className='mdi mdi-plus mr-1' />
                Add Publication
              </span>
            ) : null}

            <p>Enter all publications you will use for this bid</p>
            <div className='innerColIndent'>
              <Table className='tableHData'>
                <thead>
                  <tr>
                    {/* <th ></th> */}
                    <th colSpan={2}>
                      Publication
                      {/* <span className='mdi ml-2 mdi-sort-descending staticLink' /> */}
                    </th>
                    {/* <th width='120'>Date 1</th>
                      <th width='120'>Date 2</th> */}
                    {editable === 'all' ? <th style={tdWidth}>Action</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {newPublication === true ? (
                    <tr>
                      <td colSpan={2}>
                        <DeprecatedInput
                          label='Publication Title'
                          name='title'
                          type='text'
                          classNames='class'
                          value={title}
                          placeholder='Publication Title'
                          handleChange={(name: string, value: string) => onChnageFn(name, value)}
                          maxLength={50}
                        />
                      </td>
                      <td>
                        <Buttons
                          classNames='bttn-primary small mb-1 mr-1'
                          text='Save'
                          title='Save'
                          type='button'
                          onClick={() => {
                            if (singlepublication.title.length > 0) {
                              AddPublication();
                            }
                          }}
                          disable={!title.trim()}
                        />
                        <Buttons
                          classNames='bttn-secondary small'
                          text='Cancel'
                          title='Cancel'
                          type='button'
                          onClick={() => {
                            setSinglepublication(initial);
                            setAddNewPublication(false);
                            setEditpub({ index: '', allow: false });
                          }}
                        />
                      </td>
                    </tr>
                  ) : null}

                  {publicationslist && publicationslist.length > 0 ? (
                    <>
                      {publicationslist.map((item, index) => (
                        <tr key={index}>
                          <td width='40px'>
                            <CheckBox
                              title=''
                              handleChecked={(name: string, value: any) => {
                                updatePublicationList(value, index);
                              }}
                              checked={item.date1 || item.date2 ? true : false}
                              value={item.date1 || item.date2}
                            />
                          </td>
                          <td>
                            {editpub.allow && index === parseInt(editpub.index) ? (
                              <DeprecatedInput
                                label='Publication Title'
                                name='title'
                                type='text'
                                classNames='class'
                                value={title}
                                placeholder='Publication Title'
                                handleChange={(name: string, value: any) => onChnageFn(name, value)}
                                maxLength={50}
                              />
                            ) : (
                              item.title
                            )}
                          </td>
                          {editable === 'all' ? (
                            <td>
                              {editpub.allow ? (
                                <>
                                  {index === parseInt(editpub.index) ? (
                                    <>
                                      <Buttons
                                        classNames='bttn-primary small mb-1 mr-1'
                                        text='Save'
                                        title='Save'
                                        type='button'
                                        onClick={() => {
                                          if (singlepublication.title.length > 0) {
                                            SaveEditPublication();
                                          }
                                        }}
                                        disable={!singlepublication.title.trim()}
                                      />
                                      <Buttons
                                        classNames='bttn-secondary small'
                                        text='Cancel'
                                        title='Cancel'
                                        type='button'
                                        onClick={() => {
                                          setSinglepublication(initial);
                                          setEditpub({ index: '', allow: false });
                                        }}
                                      />
                                    </>
                                  ) : null}
                                </>
                              ) : newPublication === false ? (
                                <>
                                  <span
                                    className='mdi mdi-square-edit-outline mdi-24px staticLink mr-3'
                                    title='Edit'
                                    onClick={() => EditPublication(index, item)}
                                  />
                                </>
                              ) : null}
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <NoResult
                          message={internalLoader ? loadingMsg : 'No Publications Available'}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <AddBidWizardButtons {...props} />
          </form>
        );
      }}
    </Formik>
  );
}

export default memo(Publications);
