import React, { memo } from 'react';
// import helpImgs from '../../../../assets/images/help'
import imgsCdn from '../../../../assets/images/imagescdn';

const helpImgs = imgsCdn.helpImgs;

function RespondingEBid() {
  return (
    <>
      <p>
        {' '}
        Many governments are moving toward requiring bid responses electronically. Here are the
        steps to respond to a bid Electronically.
      </p>
      <ol>
        <li>
          <img src={helpImgs.picture19} alt='images' className='d-block mt-2 img-fluid' />
          <strong>Step 1: Click on the solicitation name.</strong>
          <br />
          <br />
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              TIP:{' '}
            </b>
            <br />
            Occasionally, a government partner will require the word “EBID” in the Bid ID, and
            sometimes not. Be sure to review the requirements to make sure you respond
            appropriately.
          </p>
        </li>

        <li>
          <strong>
            Step 2: Once you are in the bid solicitation, you will see the Bid Details page, which
            is standardfor all solicitations:
          </strong>
          <p>
            <p>
              <br />
              When you are ready to submit your bid, click on “Submit eBid Proposal”
              <br />
            </p>
            <img src={helpImgs.picture20} alt='images' className='d-block mt-2 img-fluid' />
          </p>
        </li>
        <li>
          <p>
            <strong>
              {' '}
              Step 3: Enter information requested page-by-page and you can see what will come next
              via the menu bar on the left under “eBid Progress”.
            </strong>
            <br />
          </p>
          <p>If there is not a total bid amount in your submission, please put “0</p>
          <p>
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' />
              Example:{' '}
            </b>
            <br />a request for qualifications opportunity may not require a bid amount so vendors
            will input “0” under “Bid Amount”
          </p>
          <p>
            <img src={helpImgs.picture21} alt='images' className='d-block mt-2 img-fluid' />
          </p>
        </li>
        <li>
          <p>
            <strong>Step 4: After you click NEXT on the Contact Information page</strong>, you will
            be directed to enter the documents required. In this example, they only ask for one
            document, however, in others, they may ask for multiple documents that each need to
            uploaded separately.
          </p>

          <p>
            <img src={helpImgs.picture22} alt='images' className='d-block mt-2 img-fluid' />
            <b>
              <i className='mdi mdi-information-outline mdi-24px mr-1' /> TIP:{' '}
            </b>
            <br /> There is a place for you to add “Supplemental”, i.e. non-required, documents.
            <br />
            <img src={helpImgs.picture23} alt='images' className='d-block mt-2 img-fluid' />
            <img src={helpImgs.picture24} alt='images' className='d-block mt-2 img-fluid' />
          </p>
        </li>
        <li>
          <strong>Step 5: Review Your eBid Response</strong>, and if everything is correct, then
          press “Submit Response”.
          <br />
          <br />
          <img src={helpImgs.picture25} alt='images' className='d-block mt-2 img-fluid' />
        </li>
      </ol>
      <p>
        You are done! And the government to which you’ve submitted this will download your responses
        and documents and see the day and time upon which you submitted your proposal.
      </p>
    </>
  );
}

export default memo(RespondingEBid);
