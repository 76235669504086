import React, { memo, useEffect } from 'react';
/* import { Autocomplete, Sort } from '../../customcontrols'
import { bidSortFields } from '../../../utils/constants'
import Startconverstion from './startconversation'
import Messageconversation from './messageconversation' */
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import ComingSoon from './comingsoon';
import { authTypes } from '../../../types/auth';

interface propsTypes {
  setBreadcrumb?: any;
  auth: authTypes;
}

const Message = (props: propsTypes) => {
  const { setBreadcrumb, auth } = props;
  const { mt: memberType } = auth;
  /* const [currentComponent, setCurrentComponent] = useState('component1') */

  /* const switchComponent = currentComponent => {
    setCurrentComponent(currentComponent)
  } */

  /* const getComponent = currentComponent => {
    const componentList = {
      component1: <Startconverstion {...props} switchComponent={switchComponent} />,
      component2: <Messageconversation {...props} switchComponent={switchComponent} />
    }
    return componentList[currentComponent]
  } */
  useEffect(() => {
    setBreadcrumb({
      page: 'Message',
      altname: 'Message',
      title: 'Message',
      name: 'Message',
    });
  }, [setBreadcrumb]);

  return (
    <>
      <div className='container'>
        <ComingSoon memberType={memberType} />
        {/* <div className='row messagebar'>
					<div className='col-lg-4'>
						<div className='sideBarSpacing'>
							<h4>Conversation</h4>
							<div className='innerColIndent'>
								<Autocomplete
									iconType='search'
									label='Search for Location'
									placeholder='city, state, zip, or Country'
								/>

								<div className='sortlist'>
									<Sort
										sortFields={bidSortFields}
									/>
								</div>
							</div>
							<div className='Conversionwrapper'>
								<h6>Previous Conversations</h6>
								<div className='innerColIndent'>
									<div className='conversationtab'>
										<h6>Margaret Henshaw</h6>
										<em>12.14 AM</em>
										<p>City of Missouri City - Purchasing</p>
										<small>Municipal court and alarm collection services for the city of Missouri City, Texas</small>
									</div>

									<div className='conversationtab'>
										<h6>Gordon schumater</h6>
										<em>12.14 AM</em>
										<p>City of Missouri City - Purchasing</p>
										<small>Municipal court and alarm collection services for the city of Missouri City, Texas</small>
									</div>
									<div className='conversationtab'>
										<h6>Penelope Kumar</h6>
										<em>12.14 AM</em>
										<p>Floride state university</p>
										<small>Municipal court and alarm collection services for the city of Missouri City, Texas</small>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-lg-8'>
						<div className='colWrapper clearfix '>
							{getComponent(currentComponent)}

						</div>
					</div>
				</div> */}
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({ auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Message));
