import React, { memo, useEffect, useState } from 'react';
import { bidssummaryParamType, planholderDocumentParamType } from '../../../../../types/biddetail';
import DocumentViewHistory from './docviewhistory';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import NoResult from '../../../../customcontrols/noresult';
import { Paging } from '../../../../customcontrols';
import { Table } from 'reactstrap';
import { loadingMsg } from '../../../../../utils/texts';
import { pageSize } from '../../../../../utils/constants';
import { sharedTypes } from '../../../../../types/shared';

const tdWidth = {
  width: '100px',
};

interface PropsTypes {
  planholderDocuments: planholderDocumentParamType[];
  bidssummary?: bidssummaryParamType;
  setBidSummaryDetails?: any;
  shared: sharedTypes;
}

function DocumentList(props: PropsTypes) {
  const { planholderDocuments = [], bidssummary, setBidSummaryDetails, shared } = props;
  const { internalLoader = false } = shared || {};
  const { currentplanholderDocuments } = bidssummary || {};
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  // Logic for displaying pagination
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const [modalOpen, setModelOpen] = useState(false);
  const handleClick = () => {
    setModelOpen(!modalOpen);
    setBidSummaryDetails({ currentplanholderDocuments: '' });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [planholderDocuments]);

  const { title = '' } = currentplanholderDocuments || {};

  const uniqueplanholderDocuments =
    planholderDocuments.length > 0
      ? planholderDocuments.filter((thing, index, self) => {
          return index === self.findIndex(t => t.bidDocId === thing.bidDocId);
        })
      : [];

  return (
    <>
      <h4 data-testid='biddetails.planholders.tab.suppliers.documents.heading'>Documents</h4>
      {uniqueplanholderDocuments.length > 0 ? (
        <div className='innerColIndent'>
          <Table className='tableHData'>
            <tbody>
              <tr data-testid='biddetails.planholders.tab.suppliers.documents.head'>
                <th>Filename</th>
                <th>Type</th>
                <th style={tdWidth}>Action</th>
              </tr>
              {uniqueplanholderDocuments
                .map((item, index) => (
                  <tr key={index} data-testid='biddetails.planholders.tab.suppliers.documents.body'>
                    <td>{item.title}</td>
                    <td>{item.docType}</td>
                    <td
                      title='View History'
                      onClick={() => {
                        setBidSummaryDetails({ currentplanholderDocuments: item });
                        setModelOpen(true);
                      }}
                      className='staticLink'
                    >
                      View History
                    </td>
                  </tr>
                ))
                .slice(indexOfFirstList, indexOfLastList)}
            </tbody>
          </Table>
        </div>
      ) : (
        <NoResult message={internalLoader ? loadingMsg : 'No Documents Available'} />
      )}
      <Paging
        totalRecords={uniqueplanholderDocuments.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
      <ModalPopUp title={title} size={'lg'} isOpen={modalOpen} closeModal={handleClick}>
        <DocumentViewHistory
          shared={shared}
          currentplanholderDocuments={currentplanholderDocuments}
          planholderDocuments={planholderDocuments}
          bidssummary={bidssummary}
        />
      </ModalPopUp>
    </>
  );
}

export default memo(DocumentList);
