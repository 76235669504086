import React, { memo } from 'react';
import { Buttons } from '../../customcontrols';
import { setWizardStatus, scrollToTop } from '../../../utils/helpers';
import { WizardPage } from '../../../types/wizard';
import { addquoteParamType } from '../../../types/addquote';

const optionalpage = ['Manage Documents', 'Build Broadcast List', 'Supplemental Suppliers'];

interface PropsTypes {
  currentwizard?: WizardPage;
  setAddQuotesDetails?: any;
  addquotes?: addquoteParamType;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  resetAddQuotesDetails?: any;
  history?: any;
  match?: any;
}

function Formbuttons(props: PropsTypes) {
  const {
    currentwizard,
    setAddQuotesDetails,
    addquotes,
    valid,
    pristine,
    submitting,
    resetAddQuotesDetails,
    history,
  } = props;
  const { addquoteswizard = [] /* , canFinishQuote */ } = addquotes || {};
  const EditquotesId = props.match.params.bidId || '';

  const cancel = () => {
    if (EditquotesId) {
      history.push(`/buyers/quotes/${EditquotesId}/details`);
    } else {
      history.goBack();
    }
    resetAddQuotesDetails();
  };

  return (
    <div className='d-flex justify-content-between mt-5'>
      <Buttons
        classNames='bttn-secondary float-left'
        text={currentwizard && currentwizard.id > 1 ? 'Previous' : 'Cancel'}
        title={currentwizard && currentwizard.id > 1 ? 'Previous' : 'Cancel'}
        onClick={() => {
          if (currentwizard && currentwizard.id > 1) {
            scrollToTop();
            setAddQuotesDetails({
              addquoteswizard: setWizardStatus(addquoteswizard, currentwizard.id - 1 || 1),
            });
          } else {
            cancel();
          }
        }}
        type='button'
      />
      {currentwizard && currentwizard.id < addquoteswizard.length ? (
        <Buttons
          classNames='bttn-secondary float-right'
          text={
            optionalpage.includes(currentwizard.name)
              ? 'Save & Finish Later'
              : 'Save & Finish Later'
          }
          type='submit'
          title='submit'
          onClick={() => {
            setAddQuotesDetails({ addquoteswizardpartial: true });
          }}
          id='saveLaterBtn'
        />
      ) : null}
      {currentwizard && currentwizard.id < addquoteswizard.length ? (
        <Buttons
          classNames='bttn-primary'
          text='Next'
          title='Next'
          type='submit'
          onClick={() => {
            setAddQuotesDetails({ addquoteswizardpartial: false });
          }}
        />
      ) : null}
      {currentwizard && currentwizard.id === addquoteswizard.length ? (
        <>
          <Buttons
            classNames='bttn-primary'
            text='Save & Finish Later'
            title='Save & Finish Later'
            type='button'
            onClick={cancel}
          />
          <Buttons
            classNames='bttn-primary'
            text='Review'
            title='Review'
            type='button'
            onClick={cancel}
          />
          {/* {canFinishQuote ? <Buttons classNames='bttn-primary' text='Post Quote' type='submit' /> : null} */}
        </>
      ) : null}
    </div>
  );
}

export default memo(Formbuttons);
