import React, { memo, useCallback, useEffect, useState } from 'react';
import { Buttons, CheckBox, ScrollButton, SelectBox } from '../../../customcontrols';
import { addbidParamType } from '../../../../types/addbid';
import { supplimentalSupplierColumns } from '../../../../utils/constants';
import { scrollLeft } from '../../../../utils/helpers';

type propsType = {
  addbid: addbidParamType;
  setAddBidDetails: any;
  saveUploadedSupplimentalSupplier?: any;
  match?: any;
  reBroadcast?: boolean;
};

const ColumnCheck = (props: propsType) => {
  const { addbid, setAddBidDetails, saveUploadedSupplimentalSupplier, reBroadcast = false } = props;
  const bidId = props?.match?.params?.bidId;
  const {
    columnData = [],
    emailAddress = [],
    columns = [],
    skippedColumns = [],
    showSkippedColumns = false,
    leftbtndisable = true,
    rightbtndisable = false,
  } = addbid;
  const unmatchedColumns = addbid.unmatchedColumns as number;
  const [mapping, setMapping] = useState([] as Array<string>);

  const assignColumnMapping = (data: any, index: number) => {
    const columnInfo = [...columnData];
    columnInfo[index].mapping = data;
    setAddBidDetails({ columnData: columnInfo });
    const newMapData = columnInfo.filter(item => item.mapping).map(item => item.mapping.value);
    if (!mapping.includes(data.value)) {
      setMapping(newMapData);
    }
  };

  const skipColumns = (item: any, index: number) => {
    if (item.mapping) {
      const newMapData = mapping.filter(mItem => mItem !== item.mapping.value);
      setMapping(newMapData);
    }
    const columnInfo = [...columnData];
    columnInfo[index].mapping = '';
    setAddBidDetails({ skippedColumns: [...skippedColumns, item.column], columnData: columnInfo });
  };

  const resetMapping = (item: any, index: number) => {
    if (item.mapping) {
      const newMapData = mapping.filter(mItem => mItem !== item.mapping.value);
      setMapping(newMapData);
    }
    const columnInfo = [...columnData];
    columnInfo[index].mapping = '';
    setAddBidDetails({ columnData: columnInfo });
  };

  const resetAllMapping = () => {
    const columnInfo = columnData.map((item: any) => {
      return {
        ...item,
        mapping: '',
      };
    });
    setAddBidDetails({ columnData: columnInfo });
  };

  /* const [leftbtndisable, setLeftbtndisable] = useState(true)
	const [rightbtndisable, setRightbtndisable] = useState(false) */

  const setButonStatus = useCallback(
    (btn: { scrollLeft: number; scrollWidth: number; clientWidth: number }) => {
      const rightdisable =
        Math.round(btn?.scrollLeft) >= btn?.scrollWidth - btn?.clientWidth ? true : false;
      const leftdisable = btn?.scrollLeft === 0 ? true : false;
      setAddBidDetails({ leftbtndisable: leftdisable, rightbtndisable: rightdisable });
    },
    [setAddBidDetails],
  );

  const updateColumnFocus = (index: number) => {
    const scrollColumn = document.getElementById(`columnMap${index}`) as any;
    const btn = document.getElementById('bulkUploadScroll') as any;
    const rect = scrollColumn.getBoundingClientRect();
    const elemLeft = rect.left;
    // const elemRight = rect.right
    const halfWidth = Math.round(btn.scrollWidth / 2);
    const isVisible = elemLeft < halfWidth;
    if (isVisible === false) {
      scrollLeft(btn, 160, 200);
    }
  };

  const scrollColumns = useCallback(() => {
    const btn = document.getElementById('bulkUploadScroll') as any;
    setButonStatus(btn);
  }, [setButonStatus]);

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        scrollColumns();
      },
      true,
    );
  }, [scrollColumns]);

  const scrollleftrightFn = (direction: string) => {
    const btn = document.getElementById('bulkUploadScroll') as any;
    if (direction === 'right') {
      scrollLeft(btn, 244, 200);
      //btn.scrollLeft += 244
    } else {
      scrollLeft(btn, -244, 200);
      //btn.scrollLeft -= 244
    }
  };

  return (
    <>
      <h5 data-testid='addbid.column.compare.head'>
        Email is required. All other columns are optional.
      </h5>
      {columnData.length > 0 ? (
        <>
          <h6 className='d-inline-block mr-3'>
            {unmatchedColumns - (skippedColumns.length + mapping.length)} unchecked columns{' '}
            {unmatchedColumns > 0 ? '-' : ''}{' '}
            {unmatchedColumns === skippedColumns.length ? (
              <span
                className='staticLink underLine'
                onClick={() => setAddBidDetails({ skippedColumns: [] })}
              >
                Unskip all
              </span>
            ) : unmatchedColumns > 0 ? (
              <span
                className='staticLink underLine'
                onClick={() => {
                  setAddBidDetails({ skippedColumns: columns });
                  setMapping([]);
                  resetAllMapping();
                }}
              >
                Skip all
              </span>
            ) : null}
          </h6>
          {unmatchedColumns > 0 ? (
            <CheckBox
              title='Show skipped columns'
              parentClass='d-inline-block'
              handleChecked={(name: string, value: boolean) => {
                setAddBidDetails({ showSkippedColumns: value });
                setTimeout(() => {
                  scrollColumns();
                }, 200);
              }}
              //checked={showSkippedColumns}
            />
          ) : null}
        </>
      ) : null}
      <div className='d-flex skipColContainer'>
        {!leftbtndisable &&
        columnData.length - (showSkippedColumns === true ? 0 : skippedColumns.length) >= 2 ? (
          <button onClick={() => scrollleftrightFn('left')} type='button' disabled={leftbtndisable}>
            <i className='mdi mdi-chevron-left' />
          </button>
        ) : (
          <div className='dummyDiv' />
        )}

        <div id='bulkUploadScroll'>
          <div
            id='content'
            style={{ width: (columnData.length - skippedColumns.length) * 234 + 'px' }}
          >
            <div className='d-flex skipColWrapper'>
              <div className={'skipCol d-flex align-items-start flex-column '}>
                <div className='mb-auto pb-4 text-center'>Email</div>
                <h6 className='mb-0'>Sample data:</h6>
                <ul className='list-unstyled'>
                  {emailAddress
                    .map((item: any, index: number) => <li key={index}>{item.email}</li>)
                    .splice(0, 3)}
                </ul>
              </div>

              {columnData.map((item, index) => (
                <>
                  {showSkippedColumns === true || !skippedColumns.includes(item.column) ? (
                    <div
                      key={index}
                      className={
                        'skipCol d-flex align-items-start flex-column' +
                        (skippedColumns.includes(item.column) ? ' bg-white300' : '')
                      }
                      id={`columnMap${index}`}
                    >
                      <div className='mb-auto  pb-4'>
                        {item.column === 'EmailAddress' ? (
                          'Email'
                        ) : (
                          <>
                            <SelectBox
                              label='Column'
                              reactselect={true}
                              options={supplimentalSupplierColumns.filter(
                                sitem =>
                                  !mapping.includes(sitem.value) ||
                                  (item.mapping && item.mapping.value === sitem.value),
                              )}
                              value={item.mapping}
                              handleSelect={(name: string, value: string) =>
                                assignColumnMapping(value, index)
                              }
                              disabled={skippedColumns.includes(item.column) ? 'true' : 'false'}
                              parentClass='small'
                              pageFor='mapping'
                              columnFocusUpdate={() => updateColumnFocus(index)}
                            />
                            {item.mapping && item.mapping.value ? null : (
                              <i className='text-red200'>(Unmatched Column)</i>
                            )}

                            {skippedColumns.includes(item.column) ? (
                              <span
                                className='staticLink'
                                onClick={() =>
                                  setAddBidDetails({
                                    skippedColumns: skippedColumns.filter(
                                      sitem => sitem !== item.column,
                                    ),
                                  })
                                }
                              >
                                Unskip
                              </span>
                            ) : (
                              <span className='staticLink' onClick={() => skipColumns(item, index)}>
                                Skip
                              </span>
                            )}
                            {item.mapping && item.mapping.value ? (
                              <span
                                className='staticLink ml-3'
                                onClick={() => resetMapping(item, index)}
                              >
                                Reset
                              </span>
                            ) : null}
                          </>
                        )}
                      </div>

                      <h6 className='mb-0'>Sample data:</h6>
                      <ul className='list-unstyled'>
                        {item.data
                          .map((dataItem, index) => <li key={index}>{dataItem}</li>)
                          .splice(0, 3)}
                      </ul>
                    </div>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
        {!rightbtndisable &&
        columnData.length - (showSkippedColumns === true ? 0 : skippedColumns.length) >= 2 ? (
          <button
            onClick={() => scrollleftrightFn('right')}
            type='button'
            disabled={rightbtndisable}
          >
            <i className='mdi mdi-chevron-right' />
          </button>
        ) : (
          <div className='dummyDiv' />
        )}
      </div>
      <div className='d-flex justify-content-center mt-5'>
        <Buttons
          data-testid='addbid.column.compare.button'
          type='button'
          text='Save List'
          onClick={() => saveUploadedSupplimentalSupplier({ bidId, isReview: true })}
          classNames='bttn-primary'
          disable={
            mapping.length + skippedColumns.length === columns.length ||
            supplimentalSupplierColumns.length === mapping.length
              ? false
              : true
          }
        />
      </div>
    </>
  );
};

export default memo(ColumnCheck);
