import React, { memo } from 'react';
import { AccountInfo } from '../../../../types/publications';

interface PropsTypes {
  accountinfo: AccountInfo;
  AddFn: any;
  pagefor: string;
  pristine?: boolean;
  submitting?: boolean;
  valid?: boolean;
  submitaccountinforequiredDocsUpdate?: any;
}
const Publicationcontrol = (props: PropsTypes) => {
  const {
    accountinfo,
    AddFn,
    pagefor,
    pristine,
    submitting,
    valid,
    submitaccountinforequiredDocsUpdate,
  } = props;
  const { publicationmodal, editpublication, bidtypesnmodal } = accountinfo;

  return (
    <>
      {pagefor === 'publications' ? (
        <>
          {(editpublication && editpublication.publicationId) || publicationmodal ? (
            <li className='disabled-bttn'>
              <span>
                <i className='mdi mdi-plus mr-1' /> Add Publication
              </span>
            </li>
          ) : (
            <li title='Add Publication'>
              <span onClick={AddFn} className='staticLink'>
                <i className='mdi mdi-plus mr-1' /> Add Publication
              </span>
            </li>
          )}
        </>
      ) : pagefor === 'bidtypes' ? (
        <>
          {bidtypesnmodal ? (
            <li className='disabled-bttn'>
              <span>
                <i className='mdi mdi-plus mr-1' /> Add Bid Type
              </span>
            </li>
          ) : (
            <li title='Add Bid Type'>
              <span onClick={AddFn} className='staticLink'>
                <i className='mdi mdi-plus mr-1' /> Add Bid Type
              </span>
            </li>
          )}
        </>
      ) : pagefor === 'ebiddocument' ? (
        <>
          <li title='Add Bid Document'>
            <span onClick={AddFn} className='staticLink'>
              <i className='mdi mdi-plus mr-1' /> Add Bid Document
            </span>
          </li>
          {pristine || submitting || !valid ? (
            <li className='disabled-bttn'>
              <span>
                <i className='mdi mdi-content-save-outline mr-1' />
                Save
              </span>
            </li>
          ) : (
            <li title='Save'>
              <span
                className='staticLink'
                onClick={() => {
                  submitaccountinforequiredDocsUpdate();
                }}
              >
                <i className='mdi mdi-content-save-outline mr-1' /> Save
              </span>
            </li>
          )}
        </>
      ) : null}
    </>
  );
};

export default memo(Publicationcontrol);
