import React, { memo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/actions';
import { Link } from 'react-router-dom';
import { memberTypes } from '../../utils/constants';
import { authTypes } from '../../types/auth';
import { currentYear } from '../../utils/helpers';

interface propsTypes {
  auth: authTypes;
}

const Footer = (props: propsTypes) => {
  const { auth } = props;

  const { mt: memberType } = auth || {};

  const userpath = memberType === memberTypes.agencyBuyer ? 'buyers' : 'suppliers';

  return (
    <>
      <footer className='footerWrapper container-fluid'>
        <div className='row'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-12 col-md-6 text-center text-md-left'
                data-testid='copyright.footer'
              >
                <p>&copy; {currentYear()} DemandStar Corporation</p>
              </div>
              <div className='col-12 col-md-6 text-center text-md-right'>
                <ul className='list-unstyled'>
                  <li className='d-inline-block'>
                    <Link data-testid='help.footer' to={`/${userpath}/help`}>
                      Help
                    </Link>
                  </li>
                  <li className='d-inline-block'>
                    <Link data-testid='contact.footer' to={`/${userpath}/help/gettinghelp`}>
                      Contact
                    </Link>
                  </li>
                  <li className='d-inline-block'>
                    <a
                      data-testid='termsofservice.footer'
                      rel='noopener noreferrer'
                      target='_blank'
                      href='https://network.demandstar.com/terms-of-use/'
                    >
                      Terms of Use
                    </a>
                  </li>
                  <li className='d-inline-block'>
                    <a
                      data-testid='privacypolicy.footer'
                      rel='noopener noreferrer'
                      target='_blank'
                      href='https://network.demandstar.com/privacy-policy/'
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const connector = connect(
  (state: any) => ({ auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Footer));
