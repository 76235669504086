import React, { useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { BID_DETAILS_LABEL, BidResponseLabels } from '../../../../shared/constants/bids';
import { ErrorPanel, LoadingPanel } from '../../../../components/common/loading';
import { getDefaultLoadingErrorMessage, getDefaultLoadingMessage } from '../../../../utils/message';
import { ResponseHistoryView, ResponseHistoryViewProps } from './ResponseHistoryView';

import { bidSummaryState } from '../../../../store/recoil/bidState';
import { ModalPopUp } from '../../../../components/common/modals/ModalPopUp';
import { PanelRowRight } from '../../../../shared/styles';
import { responseEventListState } from '../../../../store/recoil/bidResponseState';
import { ActionButton } from '../../../../components/common/controls/buttons/ActionButton';

interface ResponseHistoryProps {
  responseId?: string;
}

export function ResponseHistory(props: ResponseHistoryProps) {
  const { responseId = '' } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const bidSummaryLoadable = useRecoilValueLoadable(bidSummaryState);

  const responseEventListLoadable = useRecoilValueLoadable(responseEventListState);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  function render() {
    let returnContent = null;
    // Handle Recoil State Loadable states:
    // TODO: Standardize styled components for "hasError" and/or "loading" states.
    if (responseEventListLoadable.state === 'hasError') {
      returnContent = <ErrorPanel message={getDefaultLoadingErrorMessage(BID_DETAILS_LABEL)} />;
    }

    // Until we manage multiple loading states better in app state, we'll hide the loading state.
    // if (responseEventListLoadable.state === 'loading') {
    //   returnContent = <LoadingPanel message={getDefaultLoadingMessage(BID_DETAILS_LABEL)} />;
    // }

    if (responseEventListLoadable.state === 'hasValue') {
      const responseEvents = responseEventListLoadable?.contents;

      // TODO: sort out how to more cleanly nest multiple `"hasValue"` conditional checks on recoil state objects
      if (bidSummaryLoadable.state === 'hasValue' && bidSummaryLoadable?.contents) {
        const bidSummary = bidSummaryLoadable?.contents;

        const responseHistoryViewProps: ResponseHistoryViewProps = {
          responseEvents,
          bidSummary,
          responseId,
        };

        returnContent = (
          <>
            <PanelRowRight>
              <ActionButton onClick={openModal} title={BidResponseLabels.viewHistory}>
                {BidResponseLabels.viewHistory}
              </ActionButton>
            </PanelRowRight>
            <ModalPopUp
              backdrop='static'
              title='History'
              size='xl'
              closeModal={closeModal}
              isOpen={modalOpen}
            >
              <ResponseHistoryView {...responseHistoryViewProps} />
            </ModalPopUp>
          </>
        );
      }
    }

    return returnContent;
  }

  return render();
}
