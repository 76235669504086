import { connect, ConnectedProps } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../store/actions';
import { Buttons } from '../../customcontrols';
import { DemandStarSupportEmail } from '../../../utils/texts/common/emails';
import { DeprecatedInput } from '../../customcontrols/input';
import { emailVal } from '../../../utils/constants';
import { limitedbiddetailsTypes } from '../../../types/limited';
import LimitedFooter from '../footer';
import LimitedHeader from '../header';
import { Link } from 'react-router-dom';

const emailRecoverySuccessMsg =
  'If this account exists in our database, you will receive an email with a reminder of your username.';
const emailRecoveryFailureMsg = 'We were unable to find an account with this information.';

interface EmailRecoveryProps extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  setLimitedDetails: any;
  submitLimitedForgotEmail: any;
}

function EmailRecovery(props: EmailRecoveryProps) {
  const [email, setEmail] = useState('');
  const [emailerror, setEmailError] = useState('');
  const { submitLimitedForgotEmail, setLimitedDetails, limited } = props;
  const { emailrecovery } = limited;
  const { reason, data } = emailrecovery;
  const { statusCode, status } = data || {};

  const EmailRecoveryFn = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (email) {
      if (emailVal.test(email.toLowerCase())) {
        submitLimitedForgotEmail({ email });
      } else {
        setEmailError('Invalid Email');
      }
    } else {
      setEmailError('Required');
    }
  };

  useEffect(() => {
    return () => {
      setLimitedDetails({ emailrecovery: { reason: '', data: { status: false, statusCode: 0 } } });
    };
  }, [setLimitedDetails]);

  return (
    <>
      <LimitedHeader {...props} pagefor='accountrecovery' />
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <div className='row'>
                  <div className='col-12'>
                    <h2 className='arrowWrapper'>Account Recovery</h2>
                  </div>
                </div>
                <div className='row d-block'>
                  {reason ? (
                    <div className='col-12'>
                      {reason === 'success' ? (
                        <>
                          {status && (statusCode === 1 || statusCode === 2 || statusCode === 3) ? (
                            <p className='mt-3'>{emailRecoverySuccessMsg}</p>
                          ) : null}
                          {!status && statusCode === 4 ? (
                            <>
                              <p>{emailRecoveryFailureMsg}</p>
                              <p>Please contact {DemandStarSupportEmail}</p>
                            </>
                          ) : null}
                          {!status && !statusCode ? (
                            <p>Network error. Please try again later.</p>
                          ) : null}
                        </>
                      ) : (
                        <p>Network error. Please try again later.</p>
                      )}
                    </div>
                  ) : (
                    <div className='col-12 col-md-7'>
                      <form>
                        <p>Please enter the email address associated with your account.</p>
                        <div className='py-2 clearfix'>
                          <DeprecatedInput
                            name='email'
                            type='text'
                            label='Enter your email address'
                            placeholder='Your email address here'
                            value={email}
                            handleChange={(name: string, value: string) => {
                              setEmailError('');
                              setEmail(value ? value.trim() : value);
                            }}
                            meta={{ touched: true, error: emailerror }}
                            maxLength={100}
                          />

                          <Link to='/passwordrecovery' className='underLine'>
                            <b>{"I don't remember my password"}</b>
                          </Link>
                          <div className='d-block clearfix mt-3'>
                            <Buttons
                              type='submit'
                              classNames='bttn-primary float-right mt-3'
                              text='Look up my account information'
                              title='Look up my account information'
                              onClick={EmailRecoveryFn}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LimitedFooter />
    </>
  );
}
const connector = connect(
  (state: any) => ({
    limited: state.limited,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(EmailRecovery));
