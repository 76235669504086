import { useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useHistory } from 'react-router-dom';

import {
  addAwardees,
  addCustomAwardNotification,
  getBidAwardeeList,
  getCustomAwardNotification,
  markBidAsRecommended,
} from '../../../store/services/awardBid';
import { Assert, hasValue, isValidEmailInfo } from '../../../utils/helpers';
import {
  AwardWizardId,
  awardWizardIdState,
  bidAwardeeListState,
  defaultEmailInfo,
  messagePreviewState,
} from '../../../store/recoil/award-bid';
import {
  bidPlanholderListState,
  bidSummaryState,
  selectedBidIdState,
} from '../../../store/recoil/bidState';
import { CustomMessageType, EmailInfo } from '../../../types/awardBid';
import { getBidPlanholderList, getBidSummary, getEBidResponseList } from '../../../store/services';
import {
  newUnselectedPlanholders,
  notifyAwardeesState,
  notifyColleaguesState,
  notifyUnselectedState,
} from '../../../store/recoil/award-bid';
import { useRequiredDocuments, useSavePlanholder, useUpdateAwardeeAndNonAwardeeEmailInfo } from '.';

import { defaultNumericId } from '../../constants';
import { memberInfoState } from '../../../store/recoil/memberState';
import { notifyAwardeeTexts } from '../../../utils/texts/agency/awardBid';
import { Pages } from '../../../utils/constants';
import { responseListState } from '../../../store/recoil/bidResponseState';
import { authState, useAuth } from '../useAuth';
import { WizardStatus } from '../../../types/wizard';

export function useAwardBidWizard() {
  const history = useHistory();
  const savePlanholder = useSavePlanholder();
  const { validRequiredDocs, saveRequiredDocs } = useRequiredDocuments();
  const updateAwardeeAndNonAwardeeEmailInfo = useUpdateAwardeeAndNonAwardeeEmailInfo();

  const [awardWizardId, setAwardWizardId] = useRecoilState(awardWizardIdState);
  const [messagePreview, setMessagePreview] = useRecoilState(messagePreviewState);

  const awardees = useRecoilValueLoadable(bidAwardeeListState);
  const hasAwardees = hasValue(awardees) && awardees.contents.length > 0;

  const [submitting, setSubmitting] = useState(false);

  const invalidAwardWizard = useMemo(() => {
    return !validRequiredDocs;
  }, [validRequiredDocs]);

  const selectAwardeeStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.SelectAwardees) {
      return WizardStatus.Current;
    }
    if (hasAwardees) {
      return WizardStatus.Completed;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const addVendorDocsStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.AddVendorDocs) {
      return WizardStatus.Current;
    }
    if (awardWizardId > AwardWizardId.AddVendorDocs) {
      return WizardStatus.Completed;
    }
    if (hasAwardees) {
      return WizardStatus.Available;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const listRequiredDocsStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.ListRequiredDocs) {
      return WizardStatus.Current;
    }
    if (awardWizardId > AwardWizardId.ListRequiredDocs) {
      return WizardStatus.Completed;
    }
    if (hasAwardees) {
      return WizardStatus.Available;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const addPublicDocsStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.AddPublicDocs) {
      return WizardStatus.Current;
    }
    if (awardWizardId > AwardWizardId.AddPublicDocs) {
      return WizardStatus.Completed;
    }
    if (hasAwardees) {
      return WizardStatus.Available;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const notifyAwardeeStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.NotifyAwardees) {
      return WizardStatus.Current;
    }
    if (awardWizardId > AwardWizardId.NotifyAwardees) {
      return WizardStatus.Completed;
    }
    if (hasAwardees) {
      return WizardStatus.Available;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const notifyInternalStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.NotifyColleagues) {
      return WizardStatus.Current;
    }
    if (awardWizardId > AwardWizardId.NotifyColleagues) {
      return WizardStatus.Completed;
    }
    if (hasAwardees) {
      return WizardStatus.Available;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const notifyUnselectedStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.NotifyUnselected) {
      return WizardStatus.Current;
    }
    if (awardWizardId > AwardWizardId.NotifyUnselected) {
      return WizardStatus.Completed;
    }
    if (hasAwardees) {
      return WizardStatus.Available;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const finalizeAwardStatus = useMemo(() => {
    if (awardWizardId === AwardWizardId.FinalizeAward) {
      return WizardStatus.Current;
    }
    if (hasAwardees) {
      return WizardStatus.Available;
    }
    return WizardStatus.Unavailable;
  }, [awardWizardId, hasAwardees]);

  const saveAwardBidWizard = useRecoilCallback(
    ({ set, snapshot }) =>
      async (navTo?: AwardWizardId, preview?: boolean) => {
        const currentWizardId = await snapshot.getPromise(awardWizardIdState);
        const bidId = await snapshot.getPromise(selectedBidIdState);
        async function saveEmailInfo(emailInfo: EmailInfo, customMessageType: CustomMessageType) {
          const {
            customMessage,
            subject,
            introduction,
            contactName,
            contactTitle,
            contactPhone,
            contactEmail,
            recipients,
          } = emailInfo;
          if (isValidEmailInfo(emailInfo)) {
            await addCustomAwardNotification({
              bidId,
              customMessageType,
              customMessage,
              subject,
              introduction,
              contactName,
              contactTitle,
              contactPhone,
              contactEmail,
              recipients,
            });
            const response = await getCustomAwardNotification({ bidId, customMessageType });
            Assert(
              !!response.contactName,
              'We expect to have a contact name from response at this point',
            );
            const emailInfo = {
              subject: response.subject || '',
              introduction: response.introduction || '',
              customMessage: response.customMessage || '',
              contactName: response.contactName || '',
              contactPhone: response.contactPhone || '',
              contactTitle: response.contactTitle || '',
              contactEmail: response.contactEmail || '',
              recipients: response.recipients,
            };
            switch (customMessageType) {
              case CustomMessageType.Awardees:
                set(notifyAwardeesState, emailInfo);
                break;
              case CustomMessageType.NonAwardees:
                set(notifyUnselectedState, emailInfo);
                break;
              case CustomMessageType.Colleagues:
                set(notifyColleaguesState, emailInfo);
                break;
            }
          }
        }

        switch (currentWizardId) {
          case AwardWizardId.SelectAwardees:
            // save awardees
            const awardees = await snapshot.getPromise(bidAwardeeListState);
            await addAwardees({
              bidId,
              bidAwardees: awardees,
            });
            const updatedAwardees = await getBidAwardeeList(bidId);
            set(bidAwardeeListState, updatedAwardees);
            updateAwardeeAndNonAwardeeEmailInfo();
            break;
          case AwardWizardId.AddVendorDocs:
            break;
          case AwardWizardId.AddPublicDocs:
            break;
          case AwardWizardId.ListRequiredDocs:
            saveRequiredDocs();
            break;
          case AwardWizardId.NotifyAwardees:
            const awardeeEmailInfo = await snapshot.getPromise(notifyAwardeesState);
            saveEmailInfo(awardeeEmailInfo, CustomMessageType.Awardees);
            break;
          case AwardWizardId.NotifyUnselected:
            const unselectedEmailInfo = await snapshot.getPromise(notifyUnselectedState);
            const suppliers = await snapshot.getPromise(newUnselectedPlanholders);
            for (const supplier of suppliers) {
              if (supplier.name && supplier.email) {
                await savePlanholder(supplier);
              }
            }
            set(newUnselectedPlanholders, []);
            await saveEmailInfo(unselectedEmailInfo, CustomMessageType.NonAwardees);
            updateAwardeeAndNonAwardeeEmailInfo();
            break;
          case AwardWizardId.NotifyColleagues:
            const colleagueEmailInfo = await snapshot.getPromise(notifyColleaguesState);
            saveEmailInfo(colleagueEmailInfo, CustomMessageType.Colleagues);
            break;
          default:
            break;
        }
        if (navTo || navTo === AwardWizardId.None) {
          set(awardWizardIdState, navTo);
          set(messagePreviewState, !!preview);
        }
        if (navTo === AwardWizardId.None) {
          history.push(`${Pages.buyerBids}/${bidId}`);
        }
      },
    [],
  );

  const refreshAwardBidWizard = useRecoilCallback(
    ({ set, snapshot }) =>
      async (bidId: number) => {
        const memberInfo = await snapshot.getPromise(memberInfoState);
        const auth = await snapshot.getPromise(authState);
        Assert(!!auth.fullName, 'fullName should be defined', 'useRefreshAwardBidWizard');
        Assert(!!memberInfo, 'memberInfo should be defined', 'useRefreshAwardBidWizard');
        if (bidId !== defaultNumericId && memberInfo?.mi) {
          // Awardees
          const awardees = await getBidAwardeeList(bidId);
          set(bidAwardeeListState, awardees);

          // Planholders
          const planholders = await getBidPlanholderList(bidId);
          set(bidPlanholderListState, planholders);

          // eBid Responses
          const responses = await getEBidResponseList({
            bidId,
            memberId: memberInfo.mi,
          });
          set(responseListState, responses);

          // Email Info
          const awardeeEmailInfo = await getCustomAwardNotification({
            bidId,
            customMessageType: CustomMessageType.Awardees,
          });
          set(
            notifyAwardeesState,
            defaultEmailInfo(
              awardeeEmailInfo,
              memberInfo,
              auth.fullName,
              notifyAwardeeTexts.notificationDefaults.subject.awardees,
              notifyAwardeeTexts.notificationDefaults.customMessage.awardees,
            ),
          );

          const unselectedEmailInfo = await getCustomAwardNotification({
            bidId,
            customMessageType: CustomMessageType.NonAwardees,
          });
          set(
            notifyUnselectedState,
            defaultEmailInfo(
              unselectedEmailInfo,
              memberInfo,
              auth.fullName,
              notifyAwardeeTexts.notificationDefaults.subject.unselected,
              notifyAwardeeTexts.notificationDefaults.customMessage.unselected,
            ),
          );

          const colleagueEmailInfo = await getCustomAwardNotification({
            bidId,
            customMessageType: CustomMessageType.Colleagues,
          });
          set(
            notifyColleaguesState,
            defaultEmailInfo(
              colleagueEmailInfo,
              memberInfo,
              auth.fullName,
              notifyAwardeeTexts.notificationDefaults.subject.colleagues,
              notifyAwardeeTexts.notificationDefaults.customMessage.colleagues,
            ),
          );
        }
      },
    [],
  );

  const recommendAward = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        setSubmitting(true);
        const selectedBidId = await snapshot.getPromise(selectedBidIdState);
        await markBidAsRecommended(selectedBidId);
        const newBidSummary = await getBidSummary(selectedBidId);
        set(bidSummaryState, newBidSummary);
        returnToBidSummary();
      },
    [],
  );

  function returnToBidSummary() {
    saveAwardBidWizard(AwardWizardId.None);
  }

  return {
    addPublicDocsStatus,
    addVendorDocsStatus,
    awardWizardId,
    finalizeAwardStatus,
    invalidAwardWizard,
    listRequiredDocsStatus,
    messagePreview,
    notifyAwardeeStatus,
    notifyInternalStatus,
    notifyUnselectedStatus,
    recommendAward,
    refreshAwardBidWizard,
    returnToBidSummary,
    saveAwardBidWizard,
    selectAwardeeStatus,
    setAwardWizardId,
    setMessagePreview,
    setSubmitting,
    submitting,
  };
}
