import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

export interface AuthState {
  tk: string;
  fln: string;
  us: number;
  lkd: boolean;
  lm: number;
  prms: string;
  eml: string;
  fn: string;
  ln: string;
  mbl: boolean;
  mi: number;
  ms: string;
  mt: string;
  pmid: number;
  ut: string;
  un: string;
  pt: string;
  opi: number;
  tm: string;
  mc: boolean;
  her: boolean;
  ml?: string;
  mgrtd?: boolean;
  validatedResult?: { status: boolean; firstName: string; lastName: string };
}

export const initialStateAuth: AuthState = {
  tk: '',
  fln: '',
  us: 0,
  lkd: false,
  lm: 0,
  prms: '',
  eml: '',
  fn: '',
  ln: '',
  mbl: false,
  mi: 0,
  ms: '',
  mt: '',
  pmid: 0,
  ut: '',
  un: '',
  pt: '',
  opi: 0,
  tm: '',
  mc: false,
  her: false,
};

export const reducer = (state = initialStateAuth, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.LOGIN.SUCCESS:
      return { ...state, ...action.payload, her: false };
    case actionTypes.GET_REFRESH_TOKEN.SUCCESS:
      return { ...state, ...action.payload, her: false };
    case actionTypes.LOGIN_USING_COOKIE.SUCCESS:
      return { ...state, her: false };
    case actionTypes.SET_LOGIN.ACTION:
      return { ...state, ...action.payload };
    case actionTypes.LOGIN.FAILURE:
      return { ...state, tk: '', her: true };
    case actionTypes.ADD_UPDATE_USER_ACCOUNT.SUCCESS:
      return {
        ...state,
        prms:
          Number(state.us) === Number(action.payload.values.accountId)
            ? action.payload.values.selectedPermissions
            : state.prms,
        mc:
          Number(state.us) === Number(action.payload.values.accountId)
            ? action.payload.values.mainContact
            : state.mc,
      };
    case actionTypes.VALIDATE_EMAIL.SUCCESS:
      return {
        ...state,
        validatedResult: {
          status: action.payload.validatedResult.firstName ? true : false,
          ...action.payload.validatedResult,
        },
      };
    case actionTypes.SET_LOGIN_DETAILS.TRIGGER:
      return { ...state, ...action.payload };
    case actionTypes.RESET_AUTH.TRIGGER:
      return initialStateAuth;
    default:
      return state;
  }
};
