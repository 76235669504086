import { useRecoilCallback, useRecoilState } from 'recoil';

import { bidAwardeeListState, selectedBidAwardeeState } from '../../store/recoil/award-bid';

import { BidId } from '../../types/shared';
import { defaultNumericId } from '../constants';
import { getBidAwardeeList } from '../../store/services';
import { selectedBidIdState } from '../../store/recoil/bidState';
import { track } from '../../utils/telemetry';

async function getBidAwardeesFromService({ bidId }: BidId) {
  if (bidId !== defaultNumericId) {
    try {
      // API call
      const response = await getBidAwardeeList(bidId);
      return response;
    } catch (error: any) {
      track(`bidAwardeeListSelector -> getAwardeeList(${bidId}) ERROR:`, {
        error,
        method: 'POST',
        errorMessage: error.message,
      });
      console.error(`bidAwardeeListSelector -> getAwardeeList() ERROR: \n${error}`);
      return [];
    }
  }
  return [];
}

/**
 * Helper fn to save a step when reading/writing to `selectedBidAwardeeState`,
 * @returns
 */
export function useBidAwardee() {
  const [bidAwardeeList, setBidAwardeeList] = useRecoilState(bidAwardeeListState);
  const [selectedBidAwardee, setSelectedBidAwardee] = useRecoilState(selectedBidAwardeeState);
  const awardedToMemberId = selectedBidAwardee?.awardedToMemberId || defaultNumericId;

  const refreshBidAwardeeList = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const bidId = await snapshot.getPromise(selectedBidIdState);
        const freshBidAwardees = await getBidAwardeesFromService({ bidId });
        set(bidAwardeeListState, freshBidAwardees);
      },
    [],
  );

  function resetSelectedBidAwardee() {
    setSelectedBidAwardee(null);
  }

  function memberIsBidAwardee(memberId: number) {
    return !!bidAwardeeList.find(({ awardedToMemberId }) => awardedToMemberId === memberId);
  }

  return {
    awardedToMemberId,
    bidAwardeeList,
    memberIsBidAwardee,
    refreshBidAwardeeList,
    resetSelectedBidAwardee,
    selectedBidAwardee,
    setBidAwardeeList,
    setSelectedBidAwardee,
  };
}
