import React, { memo } from 'react';
import { tollFreeNumber } from '../../../../../utils/texts';

function AgencyGettingHelp() {
  return (
    <>
      Our government help desk is available from 9 a.m. to 9 p.m. Eastern time and we generally
      respond to all inquiries within 2 hours. You can email us at{' '}
      <a href='mailto:support@demandstar.com'>Support@demandstar.com </a> or simply call us at{' '}
      {`${tollFreeNumber}`}. We are happy to help you anytime.
    </>
  );
}

export default memo(AgencyGettingHelp);
