import dayjs, { Dayjs } from 'dayjs';
import React, { ComponentType } from 'react';

import { FormFieldContainer, FormFieldContainerProps } from '../../forms/styled/FormFieldStyled';
import { BaseDatePicker } from '../../common/controls/inputs/BaseDatePicker';
import { Colors } from '../../../shared/styles';
import { FormFieldLabels } from '../../forms/FormFieldLabels';

export interface DatePickerProps {
  allowClear: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  errorMsg?: string;
  fieldWrapper?: ComponentType<FormFieldContainerProps>;
  fullWidth: boolean;
  inputHeight?: string;
  inputWidth?: string;
  label?: string;
  marginBottom?: boolean;
  message?: string;
  name: string;
  onBlur?: (value: string) => void;
  onChange: (value: string | null) => void;
  pattern?: RegExp;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  value: string | null;
}

/**
 * @prop {(value: string) => void}   onChange - updates the string
 * @prop {string}                    name - HTML name
 * @prop {string}                    value - HTML value
 * @prop {boolean=}                  autoFocus - HTML autofocus
 * @prop {boolean=false}             disabled - HTML disabled
 * @prop {(date: Dayjs) => boolean}  disabledDate - function that disables dates
 * @prop {string=}                   dataTestId - https://testing-library.com/docs/queries/bytestid/
 * @prop {string=}                   errorMsg - error message
 * @prop {string=}                   label - HTML label.
 * @prop {boolean=false}             marginBottom - to add a margin below, include this
 * @prop {number=256}                maxLength - HTML maxLength
 * @prop {string}                    message - additional description
 * @prop {number=0}                  minLength - HTML minLength
 * @prop {(value: string) => void=}  onBlur - function that triggers on blur
 * @prop {RegEx=}                    pattern - specifies a regular
 * expression that the <input> element's value is checked against
 * on form submission.
 * @prop {string=}                   placeholder - HTML placeholder
 * @prop {boolean=false}             required - HTML required
 * @prop {any}                       fieldRef - pass-through reference for `react-hook-form` field registration
 * @prop {boolean=}                  showError - used when we want to trigger
 * @prop {ReactNode}                 fieldWrapper - optional wrapping component for input layout
 * @prop {string}                    inputHeight - optional explicit height
 * @prop {string}                    inputWidth - optional explicit width
 * all errors to show, for example when completing a form.
 * */

export const DatePicker = (props: DatePickerProps) => {
  const {
    allowClear,
    autoFocus,
    dataTestId,
    disabled,
    disabledDate,
    errorMsg,
    fieldWrapper,
    fullWidth,
    label,
    marginBottom = false,
    message,
    name,
    onChange,
    placeholder,
    required,
    showError,
  } = props;
  const value = props.value || '';

  function change(dateString: string) {
    if (onChange && dateString) {
      onChange(dayjs(dateString).format());
    } else {
      onChange(null);
    }
  }

  // function blur(event: React.FocusEvent<HTMLInputElement>) {
  //   setTouched(true);
  //   if (onBlur) {
  //     const value = event.target.value;
  //     onBlur(value.trim());
  //   }
  // }

  // TODO: TS4 - I had to type the onChange and value properties to any's as I could not
  // quickly come up with the correct type without changing the onChange handler a bit
  // NOTE: The actual change is below in the usage of <BaseDatePicker />
  const FieldContainer = fieldWrapper || FormFieldContainer;

  return (
    <FieldContainer marginBottom={marginBottom} fullWidth={fullWidth}>
      <FormFieldLabels
        errorMsg={errorMsg}
        name={name}
        label={label}
        required={required}
        message={message}
        showError={showError}
        touched={false}
        value={value}
      />
      <BaseDatePicker
        allowClear={allowClear}
        aria-label={label || name}
        autoFocus={autoFocus}
        placeholder={placeholder}
        data-testid={dataTestId || name}
        format='l'
        disabled={disabled}
        disabledDate={disabledDate}
        onChange={
          ((value: Dayjs | null, dateString: string) => {
            change(dateString);
          }) as any
        }
        name={name}
        id={name}
        value={dayjs(value) as any}
        style={{
          border: `0.0625rem solid ${Colors.grayInputBorder}`,
          borderRadius: '0.25rem',
          padding: '0.25rem 0.5rem',
          fontSize: '1rem',
          color: `${Colors.grayDark}`,
          lineHeight: '2rem',
        }}
      />
    </FieldContainer>
  );
};
