import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { chargeType, pageSize } from '../../../../utils/constants';
import { loadingMsg } from '../../../../utils/texts';
import {
  deprecatedGetDateTimeZoneConvertedDate,
  formatPrice,
  scrollToTop,
} from '../../../../utils/helpers';
import { NoResult, Paging } from '../../../customcontrols';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import RefundPayment from './refundPayment';
import { accountinfoType, paymentsType } from '../../../../types/paymentdetails';

interface PropsTypes {
  handlePrintClick: any;
  accountinfo: accountinfoType;
  setBreadcrumb?: any;
  auth?: {
    opi: number;
  };
  processCreditTransaction?: any;
}

const PaymentHistory = (props: PropsTypes) => {
  const { accountinfo, setBreadcrumb, auth /*payment, getPaymentHistory*/ } = props;
  const { payments = [], payment_history_internalLoader } = accountinfo || {};
  // const { refundStatus } = payment
  const { opi = 0 } = auth || {};

  useEffect(() => {
    setBreadcrumb({
      component: '',
      page: 'Payments',
      title: 'Payment History',
      altname: 'Payment History',
      name: 'Payment History',
    });
  }, [setBreadcrumb]);

  const [refundModalStatus, setRefundModalStatus] = useState(false as boolean);
  const [paymentItem, setPayment] = useState({} as paymentsType);

  const handlePrintClick = (payment: paymentsType) => {
    scrollToTop();
    props.handlePrintClick(payment);
  };

  const handleRefund = (refundData: { refundAmount: number; refundNotes?: string }) => {
    scrollToTop();
    const refundPayload = {
      trackingNumber: paymentItem.trackingNumber,
      paymentAmount: refundData.refundAmount,
      notes: refundData.refundNotes,
    };
    props.processCreditTransaction(refundPayload);
    setRefundModalStatus(!refundModalStatus);
  };

  const handleRefundModalStatus = (paymentItem: paymentsType) => {
    setPayment(paymentItem);
    setRefundModalStatus(!refundModalStatus);
  };

  const initialValues = { refundAmount: null, refundNotes: '' };

  const cancelRefundModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setRefundModalStatus(!refundModalStatus);
  };

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.paymenthistory;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  return (
    <div className='row'>
      <div className='col-8'>
        <h3 className='arrowWrapper'>Payment History</h3>
        {payments && payments.length > 0 ? (
          <>
            <Table className='tableHData'>
              <tr>
                <th>Order ID</th>
                <th>Purchase Date</th>
                {/* <th>Charged Amount</th> */}
                <th>Paid Amount</th>
                <th />
              </tr>
              {payments
                .map((payment, index) => (
                  <tr key={index}>
                    <td>{payment.trackingNumber}</td>
                    <td>
                      {deprecatedGetDateTimeZoneConvertedDate(
                        payment.date,
                        'Eastern Time (US & Canada)',
                      )}
                    </td>
                    {/* {typeof payment.chargedAmount === 'number' ? <td>$ {payment.chargedAmount}</td> : <td />} */}
                    {typeof payment.paidAmount === 'number' ? (
                      <td>${formatPrice(payment.paidAmount, 2)}</td>
                    ) : (
                      <td />
                    )}
                    <td>
                      <i
                        className='mdi mdi-printer ml-3 staticLink mdi-24px'
                        onClick={() => {
                          payment.printbtn = false;
                          handlePrintClick(payment);
                        }}
                      />
                      {payment.chargeType === chargeType.bidPurchase ? (
                        <i
                          className='mdi mdi-eye ml-3 staticLink mdi-24px'
                          onClick={() => {
                            payment.printbtn = true;
                            handlePrintClick(payment);
                          }}
                        />
                      ) : null}

                      {opi && payment.paymentType === 'Credit Card' ? (
                        <i
                          className='mdi mdi-cash-refund ml-3 staticLink mdi-24px'
                          onClick={() => {
                            handleRefundModalStatus(payment);
                          }}
                        />
                      ) : null}
                    </td>
                  </tr>
                ))
                .slice(indexOfFirstList, indexOfLastList)}
            </Table>

            <ModalPopUp
              title='Refund Details'
              size='md'
              isOpen={refundModalStatus}
              closeModal={cancelRefundModal}
              backdrop='static'
            >
              <RefundPayment
                initialValues={initialValues}
                payment={paymentItem}
                handleRefund={handleRefund}
                onCancel={cancelRefundModal}
              />
            </ModalPopUp>
          </>
        ) : (
          <NoResult
            message={payment_history_internalLoader ? loadingMsg : 'No Payment History Available'}
          />
        )}
        <Paging
          totalRecords={payments.length}
          currentPage={currentPage}
          onPagingClick={(value: number) => {
            if (value !== currentPage) setCurrentPage(Number(value));
          }}
          pageLimit={listPerPage}
        />
      </div>
    </div>
  );
};

export default memo(PaymentHistory);
