import React, { FC } from 'react';

import { ArrowTitleWrapper, ArrowTitleWrapperProps } from '../../shared/styles/header';

import { FullWidth } from '../../shared/styles';

export interface ArrowTitleProps extends ArrowTitleWrapperProps {
  label: string;
}

export const ArrowTitle: FC<ArrowTitleProps> = ({ label = '', bgColor = '', textColor = '' }) => {
  return (
    <FullWidth>
      <ArrowTitleWrapper bgColor={bgColor} textColor={textColor}>
        {label}
      </ArrowTitleWrapper>
    </FullWidth>
  );
};
