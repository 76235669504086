// TOREFACTOR: Bring up to date with TextInput visual styling.

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BasicCheckbox from './BasicCheckbox';

interface DollarInputProps {
  onUpdate: (value: number | null) => void;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  disabledMsg?: string;
  label?: string;
  name?: string;
  optional?: boolean;
  placeholder?: string;
  trueValue?: number | null;
}

export const DollarInput = (props: DollarInputProps) => {
  const {
    onUpdate,
    autoFocus,
    className,
    disabled,
    disabledMsg,
    label,
    name,
    optional,
    placeholder,
    trueValue,
  } = props;

  const cleanUpDisplayPrice = useCallback((price: string) => {
    const conversion = convertPriceToDollarsAndCents(price);
    let dollars = parseInt(conversion.dollars, 10).toString(); //gets rid of preceding zeros.
    const cents = conversion.cents;

    // add commas
    for (let i = dollars.length - 3; i > 0; i = i - 3) {
      dollars = dollars.slice(0, i) + ',' + dollars.slice(i, dollars.length);
    }

    return `$${dollars}.${cents}`;
  }, []);

  const notApplicable = useMemo(() => trueValue !== 0 && !trueValue, [trueValue]);

  const initialDisplay = useMemo(() => {
    if (disabled) {
      return '';
    }
    return cleanUpDisplayPrice(trueValue ? trueValue.toString() : '');
  }, [trueValue, disabled, cleanUpDisplayPrice]);

  const [displayPrice, setDisplayPrice] = useState(initialDisplay);

  const [error, setError] = useState('');

  useEffect(() => {
    // this re-initializes the component if the component has become disabled or un-disabled.
    if (disabled || notApplicable) {
      setDisplayPrice('');
    } else {
      if (trueValue) {
        setDisplayPrice(initialDisplay);
      } else {
        if (autoFocus) {
          setDisplayPrice('$');
        } else {
          setDisplayPrice('$0.00');
        }
      }
    }
  }, [disabled, autoFocus, trueValue, initialDisplay, notApplicable]);

  function convertPriceToDollarsAndCents(
    price: string,
  ): {
    dollars: string;
    cents: string;
  } {
    price = price.replace(/[^\d.]/g, ''); // strips out all but numbers and decimal.
    let dollars = '';
    let cents = '';

    const decimalIndex = price.indexOf('.');
    if (decimalIndex === -1) {
      dollars = price;
      cents = '00';
    } else {
      dollars = price.substring(0, decimalIndex);
      cents = price.substring(decimalIndex + 1, price.length);
      cents = cents.replace(/[^\d]/g, ''); // strips out all but numbers.
      cents = cents.substring(0, 2);
      if (cents.length === 0) {
        cents = '00';
      }
      if (cents.length === 1) {
        cents += '0';
      }
    }
    if (dollars.length === 0) {
      dollars = '0';
    }
    if (dollars.length > 15) {
      // The total of all money in the world is around 80 trillion.
      // 15 digits will give us 900 trillion to work with
      // and keep us under Number.MAX_SAFE_INTEGER
      setError('You have exceeded the maximum allowed value');
      return { dollars: '0', cents: '00' };
    }

    return {
      dollars,
      cents,
    };
  }

  function getTrueValue(price: string): number {
    const { dollars, cents } = convertPriceToDollarsAndCents(price);
    return parseInt(dollars, 10) + parseInt(cents, 10) / 100;
  }

  function onBlur() {
    setError('');
    setDisplayPrice(cleanUpDisplayPrice(displayPrice));
    onUpdate(getTrueValue(displayPrice));
  }

  function toggleNA() {
    if (trueValue === null) {
      setDisplayPrice('$0.00');
      onUpdate(0);
    } else {
      setDisplayPrice('');
      onUpdate(null);
    }
  }

  function updatePrice(event: React.ChangeEvent<HTMLInputElement>) {
    setDisplayPrice(event.target.value);
  }

  return (
    <div className={'dollar-input-container'}>
      <div className={'inputWrapper clearfix ' + className + (optional ? ' optional' : '')}>
        <div>
          <label>{label}</label>
          <input
            autoFocus={autoFocus}
            className={`text-right ${className}`}
            name={name}
            type={'text'}
            placeholder={disabled && disabledMsg ? disabledMsg : notApplicable ? '--' : placeholder}
            value={displayPrice}
            onChange={updatePrice}
            disabled={disabled || notApplicable}
            onBlur={onBlur}
            maxLength={23}
          />
        </div>
        {optional === true ? <span>(optional)</span> : optional ? <span>({optional})</span> : ''}
        {error ? <span>{error}</span> : ''}
      </div>
      {disabled ? (
        ''
      ) : (
        <BasicCheckbox checked={notApplicable} title='N/A' handleChange={toggleNA} />
      )}
    </div>
  );
};
