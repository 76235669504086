// TOREFACTOR: Rename file to InitialData.tsx

import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';
import { bindActionCreators } from 'redux';
import { memberTypes } from '../utils/constants';
import { ReduxAction } from '../store/actions/utils';
import { authTypes } from '../types/auth';
import { actionPayloadTypes } from '../types/actiontypedef';

interface Propstypes {
  getTopCommodities: (payload?: actionPayloadTypes) => ReduxAction;
  getAddAgencyrequiredDocs: (payload?: actionPayloadTypes) => ReduxAction;
  getAddAgencyBidTypes: (payload?: actionPayloadTypes) => ReduxAction;
  auth: authTypes;
  getAccountInfo: (payload?: actionPayloadTypes) => ReduxAction;
  getStateList: (payload?: actionPayloadTypes) => ReduxAction;
  getFiscalYear: (payload?: actionPayloadTypes) => ReduxAction;
  memberInfo: (payload?: actionPayloadTypes) => ReduxAction;
  getSelfDeclarations: (payload?: actionPayloadTypes) => ReduxAction;
  getAmplitudeUserProperties: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountInfoCommodity: (payload?: actionPayloadTypes) => ReduxAction;
  getToursConfigurations: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountinforequiredDocs: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountinfobidtypes: (payload?: actionPayloadTypes) => ReduxAction;
  children?: Component;
}

function InitialData(props: Propstypes) {
  const {
    getTopCommodities,
    getAddAgencyrequiredDocs,
    auth,
    getAccountInfo,
    getStateList,
    getFiscalYear,
    memberInfo,
    getSelfDeclarations,
    getAmplitudeUserProperties,
    getaccountInfoCommodity,
    getToursConfigurations,
    getaccountinforequiredDocs,
    getaccountinfobidtypes,
    children,
  } = props;
  const { mi = '', mt = '', opi = '' } = auth || {};

  useEffect(() => {
    if (mt && mt !== memberTypes.agencyBuyer) getTopCommodities({ type: 'Member' });
  }, [getTopCommodities, mt]);

  useEffect(() => {
    if (mt && mt === memberTypes.agencyBuyer) getaccountinfobidtypes();
  }, [mi, getaccountinfobidtypes, mt, opi]);

  /* useEffect(() => {
    if (mt && mt === memberTypes.agencyBuyer) getAddAgencyrequiredDocs({ buyerId: mi });
  }, [mt, mi, getAddAgencyrequiredDocs]); */

  useEffect(() => {
    if (mt && mt === memberTypes.agencyBuyer) getaccountinforequiredDocs();
  }, [mt, mi, getaccountinforequiredDocs]);

  useEffect(() => {
    if (mt && mt !== memberTypes.agencyBuyer) getAmplitudeUserProperties();
  }, [mt, getAmplitudeUserProperties]);

  useEffect(() => {
    if (mt && mt !== memberTypes.agencyBuyer && mt !== memberTypes.basicSupplier)
      getaccountInfoCommodity();
  }, [mt, getaccountInfoCommodity]);

  useEffect(() => {
    let getAccountinfoApiCall = true;
    if (getAccountinfoApiCall && mt) getAccountInfo();
    return () => {
      getAccountinfoApiCall = false;
    };
  }, [getAccountInfo, mt]);

  useEffect(() => {
    if (mt) memberInfo({ isCurrentMember: true });
  }, [memberInfo, mt]);

  useEffect(() => {
    getStateList();
  }, [getStateList]);

  useEffect(() => {
    if (mt) getFiscalYear();
  }, [getFiscalYear, mt]);

  useEffect(() => {
    if (mt && mt !== memberTypes.agencyBuyer) getSelfDeclarations();
  }, [getSelfDeclarations, mt]);

  useEffect(() => {
    if (mt && !opi) getToursConfigurations();
  }, [getToursConfigurations, mt, opi]);

  return <>{children}</>;
}
const connector = connect(
  (state: any) => ({
    shared: state.shared,
    commoditycodes: state.commoditycodes,
    accountinfo: state.accountinfo,
    auth: state.auth,
    memberinfo: state.memberinfo,
    bidssummary: state.bidssummary,
    tours: state.tours,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

export default connector(InitialData);
