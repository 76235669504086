import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import classnames from 'classnames';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { navigationPaths, requiredActionLabels } from '../../../shared/constants';

import { BidResponses } from './bid-responses';
import { buildRouteLink } from '../../../utils/route';
import { MatchParams } from '../../../types';
import { RequiredActionList } from './required-actions';
import { RESPONSE_LABEL_PLURAL } from '../../../shared/constants/bids';
import { useRouter } from '../../../shared/hooks';

export type NavListItem = {
  component: ReactNode;
  name: string;
  path: string;
};

const navList: NavListItem[] = [
  {
    name: requiredActionLabels.titlePlural,
    path: navigationPaths.subPaths.requiredActions,
    component: <RequiredActionList />,
  },
  {
    name: RESPONSE_LABEL_PLURAL,
    path: navigationPaths.subPaths.myResponses,
    component: <BidResponses />,
  },
];

export function SupplierResponses(props: RouteComponentProps<MatchParams>) {
  const { history } = useRouter();

  const { match } = props;
  const activeTab = match?.params?.Tab;

  const handleNavClick = (path: string) => () => {
    const baseUrl = navigationPaths.suppliers.responses;
    const route = buildRouteLink({ baseUrl, subUrl: path });
    setTimeout(() => history.push(route), 0);
  };

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <Nav tabs>
              {navList.map((item: NavListItem, index: number) => {
                const { name, path } = item;
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({
                        active: activeTab === path,
                      })}
                      onClick={handleNavClick(path)}
                    >
                      {name}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {navList.map((item: NavListItem, index: number) => {
                const { component, path } = item;
                return (
                  <TabPane key={index} tabId={path}>
                    {activeTab === path && component}
                  </TabPane>
                );
              })}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
}
