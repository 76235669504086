import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

const paymentinfo = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  state: '',
  county: '',
  postalCode: '',
  card: '',
};

export const initialStatePayments = {
  paymentinfo,
  cardinfo: {},
  card_token: '',
  save_address_status: false,
  paymentvalidation: false,
  payment_error: '',
  error: '',
  refundStatus: '',
  refundError: '',
  save_payment_card: '',
};

export const reducer = (state = initialStatePayments, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.GET_PAYMENT_CARD_DETAILS.SUCCESS:
      state = {
        ...state,
        paymentinfo: { ...paymentinfo, card: payload.card },
        cardinfo: payload.cardinfo,
      };
      break;
    case actionTypes.GET_PAYMENT_CARD_DETAILS.FAILURE:
      state = {
        ...state,
        error: payload,
      };
      break;
    case actionTypes.SET_PAYMENT_FORM_DETAILS.TRIGGER:
      state = {
        ...state,
        ...payload,
      };
      break;
    case actionTypes.SUBMIT_EBITS_PAYMENT_CARD.SUCCESS:
      state = {
        ...state,
        save_payment_card: payload,
      };
      break;
    case actionTypes.SUBMIT_EBITS_CHANGE_BILLING_ADDRESS.SUCCESS:
      state = {
        ...state,
        save_address_status: payload,
      };
      break;
    case actionTypes.SUBMIT_EBITS_PAYMENT_METHOD.FAILURE:
      state = {
        ...state,
        payment_error: payload,
      };
      break;
    case actionTypes.RESET_BIDS_PAYMENT.TRIGGER:
      state = initialStatePayments;
      break;
    case actionTypes.PROCESS_CREDIT_TRANSACTION.SUCCESS:
    case actionTypes.PROCESS_CREDIT_TRANSACTION.FAILURE:
      state = {
        ...state,
        refundStatus: payload.status,
        refundError: payload.error,
      };
      break;
    default:
      break;
  }
  return state;
};
