// TOREFACTOR

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { prepopulatedCountiesState } from '../../../store/recoil/subscriptionState';
import { PrepopulatedCounties } from '../../../types/subscriptions';
import { alertPercentage } from '../../../utils/constants';
import { getProductAgencies, sortProductFn } from '../../../utils/helpers';
import { CheckBox, DeprecatedInput } from '../../customcontrols';
import useSubscriptionEvents from '../subscription.events';

interface CountyListProps {
  data: any;
  agencyPopup?: any;
  subscriptions?: any;
  setSubscriptionDetails?: any;
}

const CountyList = (props: CountyListProps) => {
  const prepopulatedCounties = useRecoilValue<PrepopulatedCounties>(prepopulatedCountiesState);
  const { data = {}, subscriptions, agencyPopup, setSubscriptionDetails } = props;
  const {
    allProducts = [],
    subscribedStates = [],
    subscribedCounties = [],
    subscribedNational = [],
    subscribedCountiesGroup = [],
  } = subscriptions;
  const stateAgencies = getProductAgencies(allProducts, data.productId);
  const allStateAgencies = allProducts
    .filter((f: any) => f.productType === 'AG')
    .sort(sortProductFn);
  const selectedAndPrepopulatedCounties = subscribedCounties.concat(prepopulatedCounties.counties);

  const [products, setProducts] = useState(data.products);
  const [nationalUpgrade, setnationalUpgrade] = useState(false);
  const [productTotalPrice, setProductTotalPrice] = useState(0);
  const [productAlert, setProductAlert] = useState(false);
  const [productAlertCount, setProductAlertCount] = useState(0);

  const [pageSubscribedCounties, setPageSubscribedCounties] = useState(
    selectedAndPrepopulatedCounties,
  );

  const { logEvent } = useSubscriptionEvents();

  useEffect(() => {
    const selectedProductCounties = data.products.filter((item: any) =>
      subscribedCounties.includes(item.productId),
    );

    if (
      selectedProductCounties.length !== 0 &&
      selectedProductCounties.length === data.products.length - 1
    ) {
      setProductAlert(true);
      setProductAlertCount(selectedProductCounties.length);
    }
  }, [subscribedCounties, setProductAlert, data.products]);

  const checkNotificationAlert = (counties: any, states: any) => {
    const allSelectedProducts = allProducts
      .filter((item: any) => counties.includes(item.productId) || states.includes(item.productId))
      .map((item: any) => item.price);
    const totalPrice =
      allSelectedProducts.length > 0
        ? allSelectedProducts.length === 1
          ? allSelectedProducts[0]
          : allSelectedProducts.reduce((a: number, b: number) => a + b)
        : 0;
    const nationalProduct = allProducts.find((item: any) => item.productType === 'NA') || {};
    const nationUpgrade = (totalPrice / nationalProduct.price) * 100 >= alertPercentage;
    setnationalUpgrade(nationUpgrade);
    setProductTotalPrice(totalPrice);
  };

  const changesearching = (name: string, text: string) => {
    const newProducts = data.products.filter((item: any) =>
      item.productName.toLowerCase().includes(text.toLowerCase()),
    );
    setProducts(newProducts);
  };

  const openAgencyPopup = (productId: number) => {
    const countyAgencies = getProductAgencies(allProducts, data.productId, productId);
    agencyPopup(countyAgencies);
  };

  const updateSubscriptionList = (
    productId: number,
    parentId: number,
    productGroupId: number,
    checkedState: any,
  ) => {
    const productCounties = data.products.map((item: any) => item.productId);
    let selectedCounties = [] as any;
    if (checkedState === false) {
      const isExistState = subscribedStates.filter((item: number) => item === parentId);
      if (isExistState.length > 0) {
        const filteredSubscribedState = subscribedStates.filter(
          (item: number) => item !== parentId,
        );
        selectedCounties = productCounties.filter((item: number) => item !== productId);
        let selectedSubscribedCountiesGroup = selectedCounties.map(
          (item: number) => `${productGroupId}_${parentId}_${item}`,
        );
        selectedCounties = [...subscribedCounties, ...selectedCounties];
        selectedSubscribedCountiesGroup = [
          ...subscribedCountiesGroup,
          ...selectedSubscribedCountiesGroup,
        ];
        setSubscriptionDetails({
          subscribedStates: filteredSubscribedState,
          subscribedCounties: selectedCounties,
          subscribedCountiesGroup: selectedSubscribedCountiesGroup,
          currentExpiryDate: '',
          expiryDate: '',
          calculatedPrice: 0,
        });
        checkNotificationAlert(selectedCounties, filteredSubscribedState);
        setPageSubscribedCounties(selectedCounties);
      } else {
        selectedCounties = subscribedCounties.filter((item: number) => item !== productId);
        checkNotificationAlert(selectedCounties, subscribedStates);
        setSubscriptionDetails({
          subscribedCounties: selectedCounties,
          currentExpiryDate: '',
          expiryDate: '',
          calculatedPrice: 0,
        });
      }
      const selectedCountiesList = data.products
        .filter((item: any) => selectedCounties.includes(item.productId))
        .map((item: any) => item.price);
      const selectedCountiesPrice =
        selectedCountiesList.length === 0
          ? 0
          : selectedCountiesList.length === 1
          ? selectedCountiesList[0]
          : selectedCountiesList.reduce((a: number, b: number) => a + b);
      const countiesUpgrade = (selectedCountiesPrice / data.price) * 100 >= alertPercentage;
      if (selectedCounties.length !== 0 && countiesUpgrade) {
        setProductAlert(true);
        setProductAlertCount(selectedCounties.length);
      } else setProductAlert(false);
    } else {
      const newSubscribedCounties = [...subscribedCounties, productId];
      const filteredCurrentStateCounties = data.products.filter((item: any) =>
        newSubscribedCounties.includes(item.productId),
      );
      let newCountiesList = [] as any;
      if (filteredCurrentStateCounties.length === productCounties.length) {
        newCountiesList = subscribedCounties.filter(
          (item: number) => !productCounties.includes(item),
        );
        const newStatesList = [...subscribedStates, parentId];
        setSubscriptionDetails({
          subscribedCounties: newCountiesList,
          subscribedStates: newStatesList,
          currentExpiryDate: '',
          expiryDate: '',
          calculatedPrice: 0,
        });
        checkNotificationAlert(newCountiesList, newStatesList);
      } else {
        newCountiesList = [...subscribedCounties, productId];
        checkNotificationAlert(newCountiesList, subscribedStates);
        setSubscriptionDetails({
          subscribedCounties: newCountiesList,
          currentExpiryDate: '',
          expiryDate: '',
          calculatedPrice: 0,
        });
      }
      const selectedCountiesList = data.products
        .filter((item: any) => newCountiesList.includes(item.productId))
        .map((item: any) => item.price);
      const selectedCountiesPrice =
        selectedCountiesList.length === 0
          ? 0
          : selectedCountiesList.length === 1
          ? selectedCountiesList[0]
          : selectedCountiesList.reduce((a: number, b: number) => a + b);
      const countiesUpgrade = (selectedCountiesPrice / data.price) * 100 >= alertPercentage;
      const newSubscribedCountiesGroup = [
        ...subscribedCountiesGroup,
        `${productGroupId}_${parentId}_${productId}`,
      ];
      setSubscriptionDetails({ subscribedCountiesGroup: newSubscribedCountiesGroup });
      logEvent({
        eventName: 'registration (supplier) - select product',
        eventProps: { ProductID: productId },
      });
      if (newCountiesList.length !== 0 && countiesUpgrade) {
        setProductAlert(true);
        setProductAlertCount(selectedCountiesList.length);
      } else setProductAlert(false);
    }
  };

  const updateAllCountiesSubscriptionState = () => {
    if (subscribedStates.includes(data.productId) || subscribedNational.length > 0) {
      if (subscribedNational.length === 0) {
        updateSubscriptionState(false);
      }
    } else {
      updateSubscriptionState(true);
    }
  };

  const updateSubscriptionState = (checkedState: boolean) => {
    const productCounties = data.products.map((item: any) => item.productId);
    const newSubscribedCounties = subscribedCounties.filter(
      (item: number) => !productCounties.includes(item),
    );
    let newSubscribedStates = [...subscribedStates];
    if (checkedState === false) {
      newSubscribedStates = subscribedStates.filter((item: number) => item !== data.productId);
    } else {
      newSubscribedStates = [...newSubscribedStates, data.productId];
    }
    setProductAlert(false);
    checkNotificationAlert(newSubscribedCounties, newSubscribedStates);
    setSubscriptionDetails({
      subscribedCounties: newSubscribedCounties,
      subscribedStates: newSubscribedStates,
      currentExpiryDate: '',
      expiryDate: '',
      calculatedPrice: 0,
    });
  };

  const getCurrentStateSubscribedCounties = (type = '') => {
    const updatedSubCounties = data.products.filter(
      (item: any) =>
        subscribedCounties.includes(item.productId) ||
        pageSubscribedCounties.includes(item.productId),
    );
    const currentSubCounties = updatedSubCounties.map((item: any) => item.productId);

    return type ? currentSubCounties.length : currentSubCounties;
  };

  const getCurrentStateCountiesPrice = () => {
    const updatedSubCounties = data.products.filter(
      (item: any) =>
        subscribedCounties.includes(item.productId) ||
        pageSubscribedCounties.includes(item.productId),
    );

    const currentSubCounties = updatedSubCounties.map((item: any) => item.price);
    const totalPrice =
      currentSubCounties.length === 0
        ? 0
        : currentSubCounties.length === 1
        ? currentSubCounties[0]
        : currentSubCounties.reduce((a: number, b: number) => a + b);
    return totalPrice;
  };

  const totalCurrentStateSubscribedCounties = getCurrentStateSubscribedCounties('length');
  const totalCurrentStateCountiesPrice = getCurrentStateCountiesPrice();

  const getSelectedAgenciesCount = () => {
    const thisStateSelectedCounties = getCurrentStateSubscribedCounties();
    const countyAgencies = allProducts
      .filter((f: any) => f.productType === 'AG' && thisStateSelectedCounties.includes(f.parentId))
      .map((m: any) => m.productId);
    return countyAgencies.length;
  };

  const totalSelectedAgencies = getSelectedAgenciesCount();

  useLayoutEffect(() => {
    if (prepopulatedCounties.state > 0) {
      const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
      const simulateMouseClick = (element: any) => {
        mouseClickEvents.forEach(mouseEventType =>
          element.dispatchEvent(
            new MouseEvent(mouseEventType, {
              view: window,
              bubbles: true,
              cancelable: true,
              buttons: 1,
            }),
          ),
        );
      };

      const clickPrepopulatedCounties = () => {
        const elements = document.querySelectorAll('[class="select"]:not(:checked)');
        elements.forEach(element => simulateMouseClick(element));
      };

      setTimeout(() => clickPrepopulatedCounties(), 3000);
    }
  }, [prepopulatedCounties.state]);

  return (
    <>
      <h4 className='lineHR'>Counties ({products.length})</h4>
      <div className='countyListWrapper row'>
        <div className='countyList col-md-12 col-lg-8 col-xl-8 order-2 order-md-2 order-lg-1'>
          <div className='clearfix'>
            <DeprecatedInput
              name='Search'
              title='Search for Counties'
              label='Search for Counties'
              placeholder='Search for Counties'
              type='text'
              handleChange={changesearching}
              parentClass='d-block mt-3'
              iconType='mdi mdi-magnify'
            />
          </div>
          <div className='clearfix'>
            {products.map((item: any, index: number) => (
              <div key={index} className='countyNamePrie'>
                <CheckBox
                  title={item.productName}
                  classNames={
                    pageSubscribedCounties.includes(item.productId) ? 'select' : undefined
                  }
                  disabled={subscribedNational.length > 0 ? 'true' : 'false'}
                  handleChecked={(name: string, value: any) => {
                    updateSubscriptionList(
                      item.productId,
                      item.parentId,
                      item.productGroupId,
                      value,
                    );
                  }}
                  dataTestId={item.productId}
                  checked={
                    subscribedNational.length > 0 ||
                    subscribedStates.includes(data.productId) ||
                    subscribedCounties.includes(item.productId)
                  }
                />
                <span>${item.price}/year</span>
                <i className='staticLink' onClick={() => openAgencyPopup(item.productId)}>
                  View Agencies
                </i>
              </div>
            ))}
          </div>
        </div>
        <div className='addAllCount col-md-12 col-lg-4 col-xl-4 order-1 order-md-1 order-lg-2'>
          <div className='addToCart' onClick={() => updateAllCountiesSubscriptionState()}>
            {subscribedStates.includes(data.productId) || subscribedNational.length > 0 ? (
              <p>
                Remove from Cart <i className='mdi mdi-minus-circle' />
              </p>
            ) : (
              <p>
                Add to Cart <i className='mdi mdi-plus-circle' />
              </p>
            )}

            <small>
              All {products.length} Counties in {data.productName}
            </small>
            <h5>${data.price}/year</h5>
          </div>
          {subscribedNational.length === 0 && nationalUpgrade ? (
            <div className='stateAlert alert alert-danger fade show mt-3'>
              <h5>Don’t Miss Out!</h5>
              <p>You have selected products worth of ${productTotalPrice}.</p>
              <p>
                If you upgrade to <b>National</b> you will receive all notifications from{' '}
                {allStateAgencies.length} government agencies.
              </p>
            </div>
          ) : null}
          {productAlert ? (
            <div className='stateAlert alert alert-danger fade show mt-3'>
              <h5>Don’t Miss Out!</h5>
              <p>
                You have selected {productAlertCount} counties, which include opportunities from{' '}
                {totalSelectedAgencies} government agencies.
              </p>
              <p>
                If you upgrade to the State of <b>{data.productName}</b> you will receive all
                notifications from {stateAgencies.length} government agencies.
              </p>
            </div>
          ) : null}
          <div className='d-blcok'>
            <p
              className='staticLink text-right mt-3 firaMedium'
              onClick={() => agencyPopup(stateAgencies)}
            >
              View All Agencies ({stateAgencies.length})
            </p>
            {totalCurrentStateSubscribedCounties > 0 ? (
              <>
                <p className='firaMedium clearfix'>
                  {totalCurrentStateSubscribedCounties} counties selected = $
                  {totalCurrentStateCountiesPrice}/year
                </p>
                <p>
                  This selection of {totalCurrentStateSubscribedCounties} counties includes
                  information from {totalSelectedAgencies} different government agencies.
                </p>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default CountyList;
