import { FontFamily } from '@demandstar/components/styles';
import styled from 'styled-components';

import { Colors } from './';

export interface ArrowTitleWrapperProps {
  bgColor?: string;
  textColor?: string;
}

export const ArrowTitleWrapper = styled.div<ArrowTitleWrapperProps>`
  background-color: ${props => props.bgColor || Colors.blueGreenArrowHighlight};
  clear: left;
  color: ${props => props.textColor || Colors.grayDark};
  font-family: ${FontFamily.Header};
  font-size: 1.42375rem;
  font-style: normal;
  font-weight: 600;
  left: -4.25rem;
  line-height: 2rem;
  margin-bottom: 0;
  max-height: 3.4375rem;
  max-width: fit-content;
  min-height: 3.4375rem;
  min-width: 12.5rem;
  padding: 0.75rem 2.5rem 0.5rem 4rem;
  position: relative;
  top: -1.625rem;

  &:after {
    background: linear-gradient(
      to right bottom,
      ${props => props.bgColor || Colors.blueGreenArrowHighlight} 48.5%,
      rgba(0, 0, 0, 0) 50%
    );
    width: 2rem;
    height: 50%;
    position: absolute;
    left: 100%;
    content: '';
    top: 50%;
  }
  &:before {
    width: 2rem;
    height: 50%;
    position: absolute;
    left: 100%;
    top: 0;
    content: '';
    background: linear-gradient(
      to right top,
      ${props => props.bgColor || Colors.blueGreenArrowHighlight} 48.5%,
      rgba(0, 0, 0, 0) 50%
    );
  }
`;
