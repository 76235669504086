import { SetterOrUpdater } from 'recoil';

import { compareObjectsByKey } from '../../utils';
import { getProductAgencies } from '../../utils/helpers';
import { Product } from '../../types/subscriptions';
import { ProductApiResponse } from '../../types/products';
import { RegistrationUpsellCardData } from '../../types/registration';
import { StateDropdownItem } from './SelectState';

export const getAgenciesByProduct = (
  allProducts: ProductApiResponse[],
  productId: number,
  setAgenciesByProduct: SetterOrUpdater<ProductApiResponse[]>,
  type: Product['productType'],
) => {
  let agenciesByProduct: ProductApiResponse[] = [];
  switch (type) {
    case 'ST':
      agenciesByProduct = getProductAgencies(allProducts, productId);
      break;
    case 'CT':
      agenciesByProduct = allProducts.filter(
        product => product.productType === 'AG' && product.parentId === productId,
      );
      break;
  }
  setAgenciesByProduct(agenciesByProduct.sort(compareObjectsByKey('productName')));
};

export const getAgencyCounts = (
  allProducts: ProductApiResponse[],
  productId: number,
  type: Product['productType'],
) => {
  let agencyCount;
  switch (type) {
    case 'ST':
      agencyCount = getProductAgencies(allProducts, productId);
      return agencyCount.length;
    case 'CT':
      agencyCount = allProducts.filter(product => product.parentId === productId);
      return agencyCount.length;
    case 'NA':
      agencyCount = allProducts.filter(product => product.productType === 'AG');
      return agencyCount.length;
  }
};

export const getParentProduct = (child: ProductApiResponse, allProducts: ProductApiResponse[]) => {
  const parent = allProducts.filter(product => product.productId === child?.parentId);
  return parent[0];
};

export const getProduct = (productId: number, allProducts: ProductApiResponse[]) => {
  const product = allProducts.find(product => product.productId === productId);
  return product;
};

export const getStates = (stateProducts: ProductApiResponse[]): StateDropdownItem[] => {
  return stateProducts
    .map(product => {
      const value = product.productId.toString();
      return {
        key: product.productId,
        label: product.productName,
        title: product.productName,
        value: value,
      };
    })
    .sort(compareObjectsByKey('label'));
};

export const getUpsellProducts = (
  activeProduct: number,
  allProducts: ProductApiResponse[],
  setUpsellProducts: SetterOrUpdater<RegistrationUpsellCardData[]>,
  upsellProducts: RegistrationUpsellCardData[],
  upsellType: RegistrationUpsellCardData['upsellType'],
  baseProductName?: string,
) => {
  const upsellProduct = allProducts.filter(product => product.productId === activeProduct);
  let totalAgencies = 0;

  switch (upsellProduct[0].productType) {
    /** Is 'Agency' ever an upsell product? */
    case 'AG':
      totalAgencies = getProductAgencies(allProducts, upsellProduct[0].productId).length;
      break;
    case 'CT':
      totalAgencies = getProductAgencies(
        allProducts,
        upsellProduct[0].parentId,
        upsellProduct[0].productId,
      ).length;
      break;
    case 'NA':
      totalAgencies = allProducts.filter(product => product.productType === 'AG').length;
      break;
    case 'ST':
      totalAgencies = getProductAgencies(allProducts, upsellProduct[0].productId).length;
      break;
  }

  const upsellCardData: RegistrationUpsellCardData = {
    baseProductName: baseProductName || upsellProduct[0].productName,
    product: upsellProduct[0],
    totalAgencies: totalAgencies,
    upsellType: upsellType,
  };
  let newUpsellProducts: RegistrationUpsellCardData[] = [];
  newUpsellProducts = newUpsellProducts.concat(upsellProducts);
  if (
    !newUpsellProducts.find(
      product => product.product.productId === upsellCardData.product.productId,
    )
  ) {
    newUpsellProducts.unshift(upsellCardData);
    setUpsellProducts(newUpsellProducts);
  }
};

export const isParentStateSelected = (
  selectedStates: ProductApiResponse[],
  parentCounty: ProductApiResponse,
): boolean => {
  let parentStateSelected = false;
  selectedStates.map(state => {
    if (state.productId === parentCounty.parentId) parentStateSelected = true;
  });
  return parentStateSelected;
};
