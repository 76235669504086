import React from 'react';
import { Table } from 'reactstrap';
import { Buttons, DeprecatedInput, SelectBox } from '../../customcontrols/index';

function OrderView() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='colWrapper'>
              <h3 className='arrowWrapper'>Order</h3>
              {/* <div className='row clearfix'>
								<div className='col-lg-6'>
									<DeprecatedInput label='Bid Name' placeholder='Bid Name' />
									<DeprecatedInput label='Buyer Name' placeholder='Buyer Name' />
								</div>
								<div className='col-lg-6'>
									<DeprecatedInput label='Bid Identifier' placeholder='Bid Identifier' />
									<SelectBox label='Order Status' name='orderStatus' options={[]} />
								</div>
								<div className='col-12'>
									<Buttons text='Search' classNames='bttn bttn-primary float-right mb-3' />
								</div>
							</div> */}
              <div className='row'>
                <div className='col-lg-3'>
                  <DeprecatedInput label='Bid Name' maxLength={150} placeholder='Bid Name' />
                  <DeprecatedInput label='Buyer Name' placeholder='Buyer Name' maxLength={80} />
                  <DeprecatedInput label='Bid Identifier' placeholder='Bid Identifier' />
                  <SelectBox label='Order Status' name='orderStatus' options={[]} />
                  <Buttons text='Search' classNames='bttn bttn-primary float-right mb-3' />
                </div>
                <div className='col-lg-9'>
                  <div className='table-responsive'>
                    <Table className='tableHData'>
                      <tr>
                        <th>Bid Identifier</th>
                        <th>Bid Name</th>
                        <th>Buyer Name</th>
                        <th>Sent Date</th>
                        <th>Request Date</th>
                        <th>Delivery Buyer</th>
                        <th>Status</th>
                      </tr>
                      <tr>
                        <td>ITBgd</td>
                        <td>Job</td>
                        <td>Clark</td>
                        <td>22</td>
                        <td>55</td>
                        <td>Download</td>
                        <td>Complete</td>
                      </tr>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderView;
