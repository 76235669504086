import { atom } from 'recoil';
import {
  defaultPrepopulatedCounties,
  defaultPrepopulatedProducts,
  defaultSubscriptionDetails,
  PrepopulatedCounties,
  PrepopulatedProducts,
  SubscriptionDetails,
} from '../../types/subscriptions';
import { processPayment } from '../services/subscriptions';

export const prepopulatedCountiesState = atom<PrepopulatedCounties>({
  key: 'prepopulatedCounties',
  default: defaultPrepopulatedCounties,
});

export const prepopulatedProductsState = atom<PrepopulatedProducts>({
  key: 'prepopulatedProducts',
  default: defaultPrepopulatedProducts,
});

export const subscriptionDetailsState = atom<SubscriptionDetails>({
  key: 'subscriptionDetails',
  default: defaultSubscriptionDetails,
});

export async function processSubscriptionPayment({
  memberId,
  token,
  selectedProducts,
}: any): Promise<any> {
  try {
    let products = '';

    const subscribedCounties = selectedProducts.county.map((county: any) => county.productId);
    const subscribedStates = selectedProducts.state.map((state: any) => state.productId);

    if (selectedProducts.national > 0) {
      products = selectedProducts.national;
    } else {
      products = [...subscribedCounties, ...subscribedStates].join(',');
    }

    if (selectedProducts.agency) {
      products = `${products},${selectedProducts.agency.productId}`;
    }

    const payload = {
      token: token,
      memberId: memberId,
      products,
      IsRenewalUpgrade: false,
      isUpgrade: true,
    };

    const response = await processPayment(payload);

    if (response) {
      const responseData = JSON.parse(response.data);
      let responsePayload = {};

      if (responseData.Result === 'SUCCESS') {
        responsePayload = {
          OrderNumber: responseData.OrderNumber,
          transaction: responseData.Transaction ? responseData.Transaction : {},
          currentStep: 3,
        };
      } else {
        responsePayload = {
          paymentErrorModal: true,
        };
      }

      return responsePayload;
    }
  } catch (error: any) {
    throw new Error(`Error in 'processSubscriptionPayment()': ${error.message}`);
  }
}
