import React, { memo } from 'react';
import { NoResult } from '../../../../customcontrols/index';
import { loadingMsg } from '../../../../../utils/texts';
import {
  Planholder,
  PlanholderDetailType,
  supplierItemFields,
} from '../../../../../types/biddetail';
import { sharedTypes } from '../../../../../types/shared';

interface PlanholdersProps {
  toggle: any;
  datas: Planholder[];
  planholdersdetail: Array<PlanholderDetailType>;
  shared: sharedTypes;
  bidID: string | number | null;
  bidName: string;
  getPlanHoldersDetails?: any;
  setSelectedBidsSummaryDetails?: any;
  setBidSummaryDetails?: any;
}

const Planholders = (props: PlanholdersProps) => {
  const {
    toggle,
    datas = [],
    planholdersdetail = [],
    shared,
    bidID,
    getPlanHoldersDetails,
    setSelectedBidsSummaryDetails,
    setBidSummaryDetails,
  } = props;
  const { internalLoader } = shared;

  const handleClick = () => {
    toggle('4');
    setBidSummaryDetails({ trackTitle: 'planholders (supplier) - click Bid Planholders' });
  };

  const onSupplierClick = (planholderData: any) => {
    const selectedSupplier = planholdersdetail.find(
      fitem => fitem && fitem.memberId === planholderData.mi,
    );
    setSelectedBidsSummaryDetails({ ...planholderData, ...selectedSupplier });
    handleClick();
  };

  return (
    <>
      <h4>Planholders ({datas.length})</h4>
      <div className='innerColIndent'>
        {datas.length > 0 ? (
          <>
            <ul className='list-unstyled listStyle'>
              {datas.slice(0, 5).map((planHolders, id) => (
                <li key={id}>
                  <span className='staticLink' onClick={() => onSupplierClick(planHolders)}>
                    {planHolders.supplierName}
                  </span>
                </li>
              ))}
            </ul>
            {datas.length > 3 && (
              <span
                className='float-right staticLink clearfix'
                title='View more'
                onClick={handleClick}
              >
                View more <i className='mdi mdi-launch' />
              </span>
            )}
          </>
        ) : (
          <NoResult message={internalLoader ? loadingMsg : 'No Planholders Available'} />
        )}
      </div>
    </>
  );
};

export default memo(Planholders);
