import React, { memo } from 'react';
import { Buttons } from '../../../customcontrols';

type propsType = {
  setConfigurationInstruction: any;
};

const ConfigurationText = (props: propsType) => {
  const { setConfigurationInstruction } = props;
  return (
    <>
      <h5>Configuring your CSV or Excel file</h5>
      <ul className='listStyle'>
        <li>Save each supplier on a separate row. </li>

        <li>
          {' '}
          We support the following columns: <br />
          Email, Company Name, Contact Name, Phone Number, State, City
        </li>

        <li>The columns can be in any order. Every column except Email is optional.</li>
        <li>
          Save as a CSV or Excel file and upload to DemandStar. After that, we'll ask you to help us
          identify each column.
        </li>
      </ul>

      <div className='d-flex justify-content-center mt-5'>
        <Buttons
          type='button'
          text='Go Back and Upload a List'
          onClick={setConfigurationInstruction}
          classNames='bttn-primary'
        />
      </div>
    </>
  );
};

export default memo(ConfigurationText);
