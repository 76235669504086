import styled from 'styled-components';

import { FontWeight } from '../../../../shared/styles';

/**
 * Bold label.
 */
interface LabelProps {
  required?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-weight: ${FontWeight.bold};
`;
