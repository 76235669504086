import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../../../common/controls/inputs/text';
import renderTextAreaField from '../../../common/controls/inputs/textarea';
import renderSelectField from '../../../common/controls/inputs/selectbox';
import datepickerField from '../../../common/controls/inputs/datepicker';
import { Buttons } from '../../../customcontrols';
import { supplierItemFields } from '../../../../types/biddetail';
import moment from 'moment-timezone';
import { invalidDateMessage, usaDateFormat } from '../../../../utils/constants';

import { sharedTypes } from '../../../../types/shared';

const validate = (values: any) => {
  const { status = {}, expires = '' } = values;
  const errors = {} as { expires: string };
  if (status.value === 'UN' && expires) {
    errors.expires = 'Expiry date has to be empty when status is undecided';
  }
  if (values.expires && !moment(values.expires, usaDateFormat).isValid()) {
    errors.expires = invalidDateMessage;
  }
  return errors;
};

const tdWidth = {
  width: '150px',
};

interface PropsTypes {
  respondCertRequest?: any;
  handleCancelClick?: any;
  selectedSupplier?: supplierItemFields;
  handleSubmit?: any;
  certStatus?: Array<Record<string, unknown>>;
  selfdeclaredattributes?: any;
  updateType?: any;
  shared?: sharedTypes;
  initialValues: any;
}

function ProgramApprove(props: PropsTypes) {
  const {
    respondCertRequest,
    handleCancelClick,
    selectedSupplier,
    handleSubmit,
    certStatus,
    selfdeclaredattributes,
    updateType,
    shared,
    initialValues,
  } = props;
  const { invaliddateerror } = shared || {};

  const onSubmitHandle = (value: any) => {
    respondCertRequest({ value, initialValues });
    handleCancelClick();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmitHandle}
    >
      {formikProps => {
        const {
          values,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValidating,
          validateForm,
          isValid,
          dirty,
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <>
              <Table borderless className='tableData'>
                <tr>
                  <th style={tdWidth}>Supplier</th>
                  <td>{selectedSupplier && selectedSupplier.supplierName}</td>
                </tr>
                <tr>
                  <th>Self-Declaration</th>
                  <td>
                    {selfdeclaredattributes
                      .filter(
                        (f: any) =>
                          selectedSupplier &&
                          selectedSupplier.attributes &&
                          selectedSupplier.attributes.split(', ').includes(f.attributeType),
                      )
                      .map((m: any) => m.attributeName)
                      .join(', ')}
                  </td>
                </tr>
                <tr>
                  <th>Certification</th>
                  <td>{selectedSupplier && selectedSupplier.programName}</td>
                </tr>
                <tr>
                  <th>Substantiation</th>
                  <td>{selectedSupplier && selectedSupplier.substantiation}</td>
                </tr>
              </Table>
              <Field
                name='status'
                label='Status'
                // type='select'
                component={renderSelectField}
                options={certStatus}
                onChange={(name: string, value: any) => {
                  setFieldValue(name, value);
                }}
                parentClass='my-0'
              />
              <Field
                label='Expires'
                name='expires'
                isOutsideRange={true}
                parentClass='my-0'
                component={datepickerField}
                // onChange={(e:any)=>setFieldValue('expires',e)}
                optional={`(e.g. "${moment().format(usaDateFormat)}")`}
              />
              <br />
              <Field
                name='certCode'
                label='CertCode'
                // type='text'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
                placeholder='Certification Code'
                parentClass='my-0'
              />

              <Field
                name='notes'
                label='Notes'
                // type='text'
                component={renderTextAreaField}
                onChange={setFieldValue}
                parentClass='my-0'
              />

              <div className='d-block my-4'>
                <Buttons
                  classNames='bttn-secondary  float-left'
                  text='Cancel'
                  title='Cancel'
                  type='button'
                  onClick={handleCancelClick}
                />
                <Buttons
                  classNames='bttn-primary float-right'
                  text={updateType === 1 ? 'Approve' : 'Update'}
                  type='submit'
                  disable={invaliddateerror}
                />
              </div>
            </>
          </form>
        );
      }}
    </Formik>
  );
}

export default memo(ProgramApprove);
