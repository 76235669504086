import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { limitedbiddetailsTypes } from '../../../../types/limited';

const tdWidth = {
  width: '240px',
};

interface propsTypes {
  limited: limitedbiddetailsTypes;
}

const LimitedDistributionInfo = (props: propsTypes) => {
  const { limited } = props;
  const { bidsdetails } = limited;
  const {
    bidBond,
    planBluePrint,
    eBidding,
    distributedBy,
    distributionMethod,
    distributionOptions,
    distributionNotes,
  } = bidsdetails || {};

  return (
    <Table borderless className='tableData' data-testid='limited.bids.distribution'>
      <tbody>
        <tr>
          <th style={tdWidth}>Bid Bond</th>
          <td>{bidBond ? bidBond : 'None'}</td>
        </tr>
        <tr>
          <th>Plan (blueprint)</th>
          <td>{planBluePrint}</td>
        </tr>
        <tr>
          <th>eBidding</th>
          <td>{eBidding}</td>
        </tr>
        {distributedBy ? (
          <tr>
            <th>Distributed By</th>
            <td>{distributedBy}</td>
          </tr>
        ) : null}

        <tr>
          <th>Distribution Method</th>
          <td>{distributionMethod}</td>
        </tr>
        <tr>
          <th>Distribution Options</th>
          <td>{distributionOptions}</td>
        </tr>
        <tr>
          <th>Distribution Notes</th>
          <td>{distributionNotes}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default memo(LimitedDistributionInfo);
