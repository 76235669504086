import React, { memo } from 'react';
import history from '../../../../utils/history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../store/actions';
import { addquoteParamType } from '../../../../types/addquote';

const optionalpage = ['Build Broadcast List', 'Manage Documents', 'Supplemental Suppliers'];

interface propsTypes {
  addquotes?: addquoteParamType;
  setAddQuotesDetails?: any;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  submitAddQuotesInfo?: any;
  quotesId?: string | number;
  submitAddQuotesWire?: any;
  submitAddQuotesMoveNext?: any;
  submitAddQuoteComplete?: any;
  resetAddQuotesDetails?: any;
}

const AddQuotesControl = (props: propsTypes) => {
  const {
    addquotes,
    setAddQuotesDetails,
    valid,
    pristine,
    submitting,
    submitAddQuotesInfo,
    quotesId,
    submitAddQuotesWire,
    submitAddQuotesMoveNext,
    submitAddQuoteComplete,
    resetAddQuotesDetails,
  } = props;

  const { addquoteswizard = [] /* , canFinishQuote */ } = addquotes || {};

  const currentwizard = addquoteswizard.find(item => item.status === 'current') || ({} as any);

  const cancel = () => {
    if (quotesId) {
      history.push(`/buyers/quotes/${quotesId}`);
    } else {
      history.goBack();
    }
    resetAddQuotesDetails();
  };

  const SaveFinishLater = () => {
    document.getElementById('saveLaterBtn')?.click();
  };

  return (
    <>
      {currentwizard && addquoteswizard && currentwizard.id < addquoteswizard.length ? (
        <>
          <li title='Save & Finish Later'>
            <span
              className='staticLink'
              onClick={() => {
                setAddQuotesDetails({ addquoteswizardpartial: true });
                SaveFinishLater();
              }}
            >
              <i className='mdi mdi-content-save-outline mr-1' /> Save & Finish Later
            </span>
          </li>
        </>
      ) : null}
      {currentwizard.id === addquoteswizard.length ? (
        <>
          <li title='Save & Finish Later'>
            <span className='staticLink' onClick={() => history.goBack()}>
              <i className='mdi mdi-content-save-outline mr-1' /> Save & Finish Later
            </span>
          </li>
        </>
      ) : null}
      <li title='Cancel'>
        <span className='staticLink' onClick={() => cancel()}>
          <i className='mdi mdi-close mr-1' />
          Cancel
        </span>
      </li>
    </>
  );
};

export default connect(
  (state: any) => ({ addquotes: state.addquotes }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(AddQuotesControl));
