import React, { memo, useEffect } from 'react';
import { Buttons } from '../../../customcontrols';
import { Field, Formik } from 'formik';
import { renderDeprecatedTextField } from '../../../common/controls/inputs/text';
import { ItemRequired } from '../../../../types/publications';
import { agencyEbidDocumentParamType } from '../../../../types/agencyregistration';

interface PropsTypes {
  handleSubmit?: any;
  submitaccountinforequiredDocsUpdate?: any;
  submitting?: boolean;
  ebiddocumentPopupdata?: agencyEbidDocumentParamType;
  setAccountInfoDetails?: any;
  cancelEditdocumentPopup?: any;
  initialValues: any;
}
const validate = (values: any) => {
  const errors: any = {};
  const optional: any = ['responseItemId', 'sortOrder'];
  const required: ItemRequired[] =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  const errorarr =
    required.filter(items => {
      const returnValue: boolean | string = items.value;
      return typeof returnValue === 'string' ? !returnValue.trim() : !returnValue;
    }) || [];

  errorarr.forEach(items => (errors[items.name] = 'Required'));
  return errors;
};

const EbidDocumentPopup = (props: PropsTypes) => {
  const {
    submitaccountinforequiredDocsUpdate,
    ebiddocumentPopupdata,
    setAccountInfoDetails,
    initialValues,
    cancelEditdocumentPopup,
  } = props;

  useEffect(() => {
    return () => {
      setAccountInfoDetails({ ebiddocumentPopupdata: '', ebiddocumentPopup: false });
    };
  }, [setAccountInfoDetails]);

  const onSubmitFn = (data: agencyEbidDocumentParamType) => {
    if (data) {
      let actionfor = 'add';
      if (ebiddocumentPopupdata?.responseItemId && ebiddocumentPopupdata?.memberId) {
        actionfor = 'edit';
      }
      submitaccountinforequiredDocsUpdate({ actionfor, ...data });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const { handleSubmit, setFieldValue } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                label='Document Description	'
                type='input'
                classNames='class'
                placeholder='Document Description	'
                name='description'
                component={renderDeprecatedTextField}
                onChange={setFieldValue}
                maxLength={50}
              />
              <div className='d-block mt-3'>
                <Buttons
                  text='Cancel'
                  title='Cancel'
                  type='button'
                  classNames='bttn bttn-secondary float-left'
                  onClick={cancelEditdocumentPopup}
                />
                <Buttons
                  text='Save'
                  title='Save'
                  classNames='bttn bttn-primary float-right'
                  type='submit'
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default memo(EbidDocumentPopup);
