import React, { memo, useState } from 'react';
import { addbidParamType } from '../../../../types/addbid';

import ValidEmailAddresses from './validemailaddresses';
import InvalidEmailAddresses from './invalidemailaddresses';

type propsType = {
  addbid: addbidParamType;
  saveUploadedSupplimentalSupplier?: any;
  match?: any;
  reBroadcast?: boolean;
};

const ReviewList = (props: propsType) => {
  const { addbid, saveUploadedSupplimentalSupplier, reBroadcast = false } = props;
  const bidId = props?.match?.params?.bidId;
  const { emailAddress = [] } = addbid;

  const [showValidEmailAddress, setShowValidEmailAddress] = useState(false);

  const invalidEmailAddress = emailAddress.filter(
    item => item.isValid === false && item.isIncluded === true,
  );
  const validEmailAddress = emailAddress.filter(item => item.isValid === true);

  return (
    <div className='reviewListWrapper' data-testid='addbid.review.list'>
      {showValidEmailAddress || invalidEmailAddress.length === 0 ? (
        <ValidEmailAddresses
          validEmailAddress={validEmailAddress}
          invalidEmailCount={invalidEmailAddress.length}
          goBack={() => setShowValidEmailAddress(false)}
          saveUploadedSupplimentalSupplier={() =>
            saveUploadedSupplimentalSupplier({ bidId, isReview: false, reBroadcast })
          }
        />
      ) : (
        <InvalidEmailAddresses
          validEmailAddress={validEmailAddress}
          invalidEmailAddress={invalidEmailAddress}
          showValidAddresses={() => setShowValidEmailAddress(true)}
          {...props}
        />
      )}
    </div>
  );
};

export default memo(ReviewList);
