import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import {
  RequiredDocType,
  useRequiredDocumentTypes,
} from '../../../../shared/hooks/award-bid/useRequiredDocumentTypes';

import { AddRequiredDocumentTypePanel } from './AddRequiredDocumentTypePanel';
import { requiredDocLabels } from '../add-vendor-docs/constants';
import { Selectable } from '../../../../types/common';
import { SelectBox } from '../../../customcontrols';
import { useRequiredDocuments } from '../../../../shared/hooks';

const AddRequiredDocumentSelectWrapper = styled.div`
  padding-top: 2rem;
`;

type SelectableType = RequiredDocType & Selectable<number>;

/**
 * Required Document List table with editable/deletable rows,
 * a date picker to change all row dates,
 * and a toggle button to allow addition of new Required Documents
 */
export function AddRequiredDocumentsPanel() {
  // Hooks
  const { addRequiredDoc } = useRequiredDocuments();
  const { requiredDocTypes, refreshRequiredDocTypes } = useRequiredDocumentTypes();
  const tomorrowAtNoon = dayjs().add(1, 'day').hour(12).minute(0).second(0).format();

  const selectableTypes: SelectableType[] = useMemo(
    () =>
      requiredDocTypes.map(type => {
        return {
          ...type,
          value: type.memberAwardRequiredDocResponseItemId,
          label: type.title,
        };
      }),
    [requiredDocTypes],
  );

  // useEffect Hooks

  useEffect(() => {
    refreshRequiredDocTypes();
  }, [refreshRequiredDocTypes]);

  // Functions

  async function addReqDoc(selectedDocType: SelectableType) {
    await addRequiredDoc({
      docName: '',
      docTitle: selectedDocType.title,
      docDescription: selectedDocType.description,
      isDelete: false,
      dueDate: tomorrowAtNoon,
    });
  }

  return (
    <>
      <AddRequiredDocumentSelectWrapper>
        <SelectBox
          reactselect={true}
          label={requiredDocLabels.newRequiredDocument}
          name='requiredDocTypes'
          options={selectableTypes}
          handleSelect={(name: string, value: SelectableType) => {
            addReqDoc(value);
          }}
        />
      </AddRequiredDocumentSelectWrapper>
      <AddRequiredDocumentTypePanel />
    </>
  );
}
