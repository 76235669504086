import React, { memo } from 'react';
interface PropsTypes {
  submit: any;
  cancel: any;
  disable: boolean | undefined;
}
const UseraccountControl = (props: PropsTypes) => {
  const { submit, cancel, disable } = props;

  return (
    <>
      {disable ? (
        <li className='disabled-bttn'>
          <span className=''>
            <i className='mdi mdi-content-save-outline mr-1' /> Save
          </span>
        </li>
      ) : (
        <li title='Save'>
          <span className='staticLink' onClick={submit}>
            <i className='mdi mdi-content-save-outline mr-1' /> Save
          </span>
        </li>
      )}
      <li title='Cancel'>
        <span className='staticLink' onClick={cancel}>
          <i className='mdi mdi-close mr-1' />
          Cancel
        </span>
      </li>
    </>
  );
};

export default memo(UseraccountControl);
