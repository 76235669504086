import React, { PropsWithChildren } from 'react';
import { FieldError } from 'react-hook-form';

import { FormFieldError, FormFieldMessage, StyledFormFieldContainer } from './FieldContainerStyles';
import { getErrorMessage } from '../utils';
import { Label } from '../label/Label';

type FieldContainerProps = PropsWithChildren<{
  error?: FieldError;
  label?: string;
  message?: string;
  name: string;
  dataTestId?: string;
}>;

export const FieldContainer = (props: FieldContainerProps) => {
  const { error, label, name, message, children, dataTestId } = props;

  const errorMsg = getErrorMessage(error);

  return (
    <StyledFormFieldContainer marginBottom={!!label} dataTestId={`${dataTestId}-container`}>
      <>
        {label && (
          <Label htmlFor={name} aria-labelledby={name} dataTestId={`${dataTestId}-label`}>
            {label}
          </Label>
        )}
        {errorMsg ? (
          <FormFieldError dataTestId={`${dataTestId}-error`}>{errorMsg}</FormFieldError>
        ) : message ? (
          <FormFieldMessage dataTestId={`${dataTestId}-message`}>{message}</FormFieldMessage>
        ) : (
          <></>
        )}
      </>
      {children}
    </StyledFormFieldContainer>
  );
};
