import React from 'react';
import styled from 'styled-components';

import { ButtonProps, StyledButton } from './Button';
import { SortIconAscending, SortIconDescending } from '../../icons';
import { Colors } from '../../../../shared/styles';
import { Direction } from '../../../../types/shared';
import { sortDirections } from '../../../../utils/constants';

type SortButtonProps = ButtonProps & Direction;

const IconButtonStyled: React.FunctionComponent<SortButtonProps> = styled(StyledButton)`
  background-color: ${Colors.white};
  border: solid 0.0625rem ${Colors.white};
  height: 1.5rem;
  margin-left: 0.5rem;
  padding: 0;
  &:hover {
    color: ${Colors.blueGreenMedium};
  }
  &:active {
    color: ${Colors.grayDark};
  }
`;

/**
 * Sort Button with Icon
 */
export function SortButton(props: SortButtonProps) {
  const icon = props.direction === sortDirections.ASC ? SortIconAscending : SortIconDescending;
  return <IconButtonStyled {...props} icon={icon} />;
}
