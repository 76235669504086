import React from 'react';

interface PropsTypes {
  pagefor: string;
}

function CommodityOptionalText(props: PropsTypes) {
  const { pagefor } = props;
  return (
    <>
      {pagefor === 'addquotes' ? null : (
        <>
          <p className='mb-3'>
            <b>Please add commodity codes to build your Broadcast List.</b>{' '}
          </p>
          <p>
            <b>
              This is optional, but highly encouraged. These codes are how DemandStar matches your
              bid and alerts the appropriate vendors.
            </b>
          </p>
        </>
      )}
    </>
  );
}

export default CommodityOptionalText;
