/* eslint-disable max-lines */

/* TOREFACTOR - split into multiple files. this is > 500 lines */

// NOTE: Do not add new helpers to this file
// Instead, create a page specific file and import it

import dayjs from 'dayjs';
import jwt from 'jsonwebtoken';
import moment from 'moment-timezone';
import momenttz from 'moment-timezone';
import { toast } from 'react-toastify';

import * as actionTypes from '../../store/actionTypes';

import {
  appstatetokenName,
  datePickerYear,
  DeprecatedFullTimeZone,
  limitedloginPreviousUrlName,
  loginPreviousUrlName,
  loginPreviousUrlSearch,
  memberCookieName,
  memberTypes,
  nextFiscalYear,
  OpsTokenName,
  Pages,
  previousFiscalYear,
  relogintokenName,
  sortDirections,
  storedCommoditiestokenName,
  themetokenName,
  TimeZone,
  timeZoneList,
  toastAutoCloseDelay,
  tokenCookieName,
  urlDateFormat,
  usaDateFormat,
  usaDateTimeFormat,
  validDateFormats,
} from '../constants';
import { GetLoginPathFnTypes, timeArrayTypes } from '../../types/utils';
import { WizardPage, WizardStatus } from '../../types/wizard';
import { Assert } from './testing';
import { authTypes, MembershipLevel, Permission } from '../../types/auth';
import Configurations from '../../settings';
import { CountdownStatus } from '../../types/dateTime';
import { currentYear } from './dateTime';
import { deleteCookie } from '../cookie';
import { track } from '../telemetry';
import { UploadedFile } from '../../types/addbid';

export * from './amplitude';
export * from './awardBid';
export * from './conversions';
export * from './dateTime';
export * from './formatters';
export * from './navigation';
export * from './recoil';
export * from './testing';
export * from './validation';

export interface BidStatusPartial {
  statusType: string;
  statusName: string;
}

interface BidStatus extends BidStatusPartial {
  label: string;
  value: string;
}

interface FiscalYear {
  value: string;
  label: string;
}

type BasicArray = Array<string | number | boolean>;
type EncodableVal = string | number | boolean | FiscalYear | BidStatus | BasicArray | null;
type FilterVal = moment.Moment | EncodableVal;
type Filter = Record<string, FilterVal>;

export function pluralize(count: number, singular: string, plural: string) {
  if (count === 1) {
    return singular;
  } else {
    return plural;
  }
}

export const getNativeDate = (value: string | number | Date | undefined) => {
  if (value) {
    const newDate = new Date(value);
    const year = newDate.getFullYear();
    const month = (1 + newDate.getMonth()).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  }
  return '';
};

export const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export const getTwoDigit = (number: number) => {
  return (number < 10 ? '0' : '') + number;
};

// To check two object contains variable values are same or not
export const isEquivalent = (a: { [x: string]: any }, b: { [x: string]: any }) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

// To get file extension(.pdf) from input type=file
/* TODO: Can we change this to take a single file instead of an array?
  Usually we're wrapping the file in brackets just so we can pass it in here
  And it only looks at one file anyway. Let's just send that file directly.
*/
export const getFileExtension = (files: UploadedFile[]) => {
  return files?.length ? getExtension(files[0].name) : '';
};

export function getExtension(filePath: string, upperCase = true) {
  const extension = filePath.substring(filePath.lastIndexOf('.') + 1);
  return upperCase ? extension.toUpperCase() : extension;
}

export const toISOString = (dateObj: string | number | Date) => {
  const month = new Date(dateObj).getMonth() + 1;
  const date = new Date(dateObj).getDate();
  return (
    new Date(dateObj).getFullYear() +
    '-' +
    (month >= 10 ? month : '0' + month) +
    '-' +
    (date >= 10 ? date : '0' + date)
  );
};

export const hasPermission = (permissions: string, permissionId: number) => {
  return (
    permissions && permissions.replace(/\s/g, '').split(',').map(Number).includes(permissionId)
  );
};

export const canShowPrograms = (memberType: string) => {
  return memberType === 'SS';
};

export const canShowSelfDeclarations = (memberType: string) => {
  return memberType === 'SS';
};

export const isPrimaryAccount = (memberAccounts: any[], currentAccountId: any) => {
  return (
    memberAccounts &&
    memberAccounts.length > 0 &&
    memberAccounts.find(account => account.accountId === currentAccountId).mainContact
  );
};

// To Display React Toastify
export const toastFn = (reason: any = '', message?: string, id?: any, autoClose = false) => {
  if (!toast.isActive(id)) {
    if (reason) {
      (toast as any)[reason](message, {
        toastId: id,
        autoClose: autoClose ? autoClose : toastAutoCloseDelay,
      });
    } else {
      toast(message, {
        toastId: id,
        autoClose: autoClose ? autoClose : toastAutoCloseDelay,
      } as any);
    }
  }
};

export const dismisstoastAllFn = () => toast.dismiss();

export const getReferrer = () => {
  if (document.referrer) {
    const anchor = document.createElement('a');
    anchor.href = document.referrer;
    return anchor.hostname;
  }
  return '';
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname: string, value: any, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; domain=${
      window.location.hostname === 'localhost' ? '' : '.demandstar.com'
    }; expires=${date.toUTCString()}`;
  }
  document.cookie = cname + '=' + (value || '') + expires + '; path=/';
};

// To Sort List
export const SortingFn = (list: any[] = [], order: sortDirections, value = '', type = 'text') => {
  let sortedlist = [];
  if (!value) {
    return list;
  }
  switch (order) {
    case sortDirections.DESC:
      if (type === 'text')
        sortedlist = list.sort((a, b) => {
          const aValue = a[value] ? a[value].toString() : '';
          const bValue = b[value] ? b[value].toString() : '';
          return bValue.localeCompare(aValue.toString());
        });
      else sortedlist = list.sort((a, b) => b[value] && b[value] - a[value]);
      break;
    case sortDirections.ASC:
      if (type === 'text')
        sortedlist = list.sort((a: any, b: any) => {
          const aValue = a[value] ? a[value].toString() : '';
          const bValue = b[value] ? b[value].toString() : '';
          return aValue.localeCompare(bValue);
        });
      else sortedlist = list.sort((a, b) => a[value] && a[value] - b[value]);
      break;
    default:
      sortedlist = list;
  }
  return sortedlist;
};

// To Search

// TODO: Rename this. Proposal: search?
export const Searchingfn = (list: any[] = [], searchname: any[] = [], value: any = '') => {
  let searchlist: any[] = [];
  searchlist = searchname.map(items =>
    list.filter(
      (item: any) =>
        item[items] &&
        item[items]
          .toLowerCase()
          .trimStart()
          .trimEnd()
          .search(value && value.toLowerCase().trimStart().trimEnd()) !== -1,
    ),
  );
  // searchlist = list.filter(item => item['name'].toLowerCase().search(value.toLowerCase()) !== -1)
  return [...Array.from(new Set(searchlist.flat()))];
};

// To Group Search (TODO: Rename to groupSearch)
export const GroupSearchingfn = (list: any[] = [], searchname: any[] = [], value: any[] = []) => {
  let searchlist = list;
  value.forEach((items: any, index) => {
    if (items) {
      return (searchlist = searchlist.filter(
        (item: any) =>
          item[searchname[index]]
            .toLowerCase()
            .trimStart()
            .trimEnd()
            .search(items.toLowerCase().trimStart().trimEnd()) !== -1,
      ));
    } else {
      return searchlist;
    }
  });

  return [...Array.from(new Set(searchlist.flat()))];
};

/** To set a wizard manually
 * @param  {WizardPage[]} wizard - the full array of WizardPages
 * @param  {number} pageId - the pageId you want to set to current
 */
export const setWizardStatus = (wizard: WizardPage[], pageId: number) => {
  const wiz = wizard.map(page => {
    if (page.id > pageId) {
      page.status = WizardStatus.Unavailable;
    }
    if (page.id < pageId) {
      page.status = WizardStatus.Completed;
    }
    if (page.id === pageId) {
      page.status = WizardStatus.Current;
    }
    return page;
  });
  return [...wiz];
};

//to add ebid in wizard list
export const AddToAddBidWizard = (
  list: any[],
  object: { id: any; name: string; status: string },
  position: number,
  action: boolean,
) => {
  if (object && position) {
    let wizard;
    if (action) {
      wizard = list.map(item => {
        if (item.id >= position) {
          item.id = item.id + 1;
        }
        return item;
      });
      wizard = [...wizard, object];
    } else {
      wizard = list.filter(item => item.id !== position);
      wizard = wizard.map(item => {
        if (item.id > position) {
          item.id = item.id - 1;
        }
        return item;
      });
    }
    wizard = wizard.sort((a, b) => a.id - b.id);
    const newWizard = wizard.map((item, index) => {
      return {
        ...item,
        id: index + 1,
      };
    });
    return [...Array.from(new Set(newWizard))];
  }
};

// To get and set Date
export const SetDateTime = (step: string, count: number, date: string | number | Date) => {
  const day = date ? new Date(date) : new Date();
  const nextDay = new Date(day);
  let tommorow;
  if (step === 'plus') {
    tommorow = new Date(nextDay.setDate(day.getDate() + count));
  } else if (step === 'minus') {
    tommorow = new Date(nextDay.setDate(day.getDate() - count));
  }
  return tommorow;
};

// get time intervals

export const getTimeIntervals = (interval?: number) => {
  const timeArray: timeArrayTypes = [];

  const hours = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
  const minitues = interval === 30 ? ['00', '30'] : ['00', '15', '30', '45'];

  hours.forEach(hr => {
    minitues.forEach(min => {
      const time = hr + ':' + min;
      timeArray.push({ key: time, value: time, label: time, title: time });
    });
  });
  return timeArray;
};

// TODO: Rename / replace this.
export const getTimeZone = () => {
  return [
    { key: 'AM', value: 'AM', label: 'AM', title: 'AM' },
    { key: 'PM', value: 'PM', label: 'PM', title: 'PM' },
  ];
};

export const setTimeToDate = (date: string | number | Date, hoursString: string) => {
  const selectedDate = new Date(date);
  const dateNumber = selectedDate.getDate();
  const monthNumber = selectedDate.getMonth() + 1;
  const yearNumber = selectedDate.getFullYear();
  return `${monthNumber}/${dateNumber}/${yearNumber} ${hoursString}`;
};

export const getTimeFromDate = (date: string | number | Date) => {
  date = new Date(date);
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  //var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? (hours < 10 ? '0' + hours : hours) : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes;
  return { key: strTime, value: strTime, label: strTime, title: strTime };
};

export const getZoneFromDate = (date: string | number | Date) => {
  date = new Date(date);
  const hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  return { key: ampm, value: ampm, label: ampm, title: ampm };
};

export const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};

export const sumObjectFields = (array: any[], key: string) => {
  return array.reduce((r, a) => {
    return r + a[key];
  }, 0);
};

export const changeDateToString = (inputDate: {
  getDate: () => any;
  getMonth: () => number;
  getFullYear: () => any;
}) => {
  const Day = inputDate.getDate();
  const Month = inputDate.getMonth() + 1;
  const Year = inputDate.getFullYear();
  return `${Month}/${Day}/${Year}`;
};

// array to object
export const convertArrayToObject = (
  array: any[],
  key: string | number,
  value: string | number,
) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value],
    };
  }, initialValue);
};

// Logout method
export const quietLogOutFn = () => {
  sessionStorage.setItem('isloggingout', '2');
  deleteCookie(tokenCookieName);
  deleteCookie(memberCookieName);
  deleteAspCookies();
  localStorage.removeItem(appstatetokenName);
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
  sessionStorage.removeItem(loginPreviousUrlName);
  sessionStorage.removeItem(relogintokenName);
};

// Logout method
export const logOutFn = (isOps = false) => {
  sessionStorage.setItem('isloggingout', '2');
  deleteCookie(tokenCookieName);
  deleteCookie(memberCookieName);
  if (isOps) deleteCookie(OpsTokenName);
  deleteAspCookies();
  localStorage.removeItem(appstatetokenName);
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
  sessionStorage.removeItem(loginPreviousUrlName);
  sessionStorage.removeItem(limitedloginPreviousUrlName);
  sessionStorage.removeItem(relogintokenName);
  window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
  const { REQUEST } = actionTypes.LOGOUT as any;
  track(REQUEST as any, {});
};

// Clear Storage method
export const clearStorageFn = (redirect = false, previousurl = false) => {
  sessionStorage.setItem('isloggingout', '1');
  if (redirect) {
    sessionStorage.setItem('sessionExpired', '1');
    window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
  }
  localStorage.removeItem(appstatetokenName);
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
  if (previousurl) {
    sessionStorage.removeItem(loginPreviousUrlName);
  }
  sessionStorage.removeItem(limitedloginPreviousUrlName);
  sessionStorage.removeItem(relogintokenName);
};

// Clear Storage method during login
export const clearStorageLoginFn = () => {
  //localStorage.removeItem(appstatetokenName)
  localStorage.setItem(appstatetokenName, JSON.stringify({}));
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
};

export const clearTokenCookie = () => {
  deleteCookie(tokenCookieName);
  sessionStorage.setItem('isloggingout', '1');
  sessionStorage.setItem('sessionExpired', '1');
};

export function deleteAspCookies() {
  deleteCookie('session');
  deleteCookie('LTV');
  deleteCookie('IDSERVER');
  deleteCookie('SID');
  deleteCookie('UID');

  const cookiesArr = document.cookie.split('; ');
  for (let i = 0; i < cookiesArr.length; i++) {
    const cItem = cookiesArr[i].split('=');
    if (cItem.length > 0 && cItem[0].indexOf('ASPSESSIONID') === 0) {
      deleteCookie(cItem[0]);
    }
  }
}

// Show Bids Selection

export const getShowBidsFn = (auth: authTypes) => {
  const { prms = '', ml = '', ms = '' } = auth || {};
  const levels = ml && (ml.replace(' ', '').split(',') as MembershipLevel[]);
  const permissions = prms && (prms.replace(' ', '').split(',') as Permission[]);

  const IsOps = permissions.includes(Permission.Ops);
  const IsBidPoster = levels.includes(MembershipLevel.BidPoster);
  const IsBidResponder = levels.includes(MembershipLevel.BidResponder);
  const IsPinned = false;

  const KeyAll = 'All';
  const KeyMine = 'Mine';
  const KeyNotified = 'Notified';
  const KeyOrdered = 'Ordered';
  const KeyEBids = 'EBids';
  const ValueAll = 'All bids in the system';
  const ValueMine = 'Only Show My Bids';
  const ValueNotified = 'Bids for which I have been notified';
  const ValueOrdered = 'Bids I have ordered';
  const ValueEBids = 'eBids only';

  let showBidsResult = [];

  if (IsOps && !IsPinned) {
    showBidsResult = [{ value: KeyAll, label: ValueAll, DefaultSelected: true }];
  } else {
    if (IsBidPoster) {
      showBidsResult = [
        { value: KeyMine, label: ValueMine, DefaultSelected: true },
        { value: KeyAll, label: ValueAll },
      ];
    } else {
      if (ms === 'EP') {
        showBidsResult = [
          { value: KeyNotified, label: ValueNotified, DefaultSelected: true },
          { value: KeyOrdered, label: ValueOrdered },
        ];
      } else if (IsBidResponder) {
        showBidsResult = [
          { value: KeyNotified, label: ValueNotified, DefaultSelected: true },
          { value: KeyOrdered, label: ValueOrdered },
          { value: KeyAll, label: ValueAll },
        ];
      } else {
        showBidsResult = [{ value: KeyAll, label: ValueAll, DefaultSelected: true }];
      }
    }
  }
  if (IsOps) {
    showBidsResult = [{ value: KeyEBids, label: ValueEBids }];
  }

  // TODO: have to remove below lines when we go for live
  //(!showBidsResult.Any(showBid => showBid.Key == Constants.ShowBids.KeyEBids))
  const checkEbids = showBidsResult.filter((item: any) => item.key === KeyEBids) || [];
  if (checkEbids.length === 0) {
    showBidsResult.push({ value: KeyEBids, label: ValueEBids });
  }

  return showBidsResult || [];
};

// To Get Fiscal Year to display in select box
export const GetFiscalYearsFn = () => {
  const years = [];
  for (let i = currentYear() - previousFiscalYear; i <= currentYear() + nextFiscalYear; i++) {
    years.push({ label: i, value: i });
  }
  return years;
};

export const getKeyofObject = (object: any, value: any) => {
  return Object.keys(object).find(key => object[key] === value);
};

function GetQueryStringValue(queryString: string, key: string) {
  key = key.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(queryString);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const GetLoginPathFn = (payload: GetLoginPathFnTypes) => {
  const dashboard =
    payload.mt === memberTypes.agencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;
  const user = payload.mt === memberTypes.agencyBuyer ? 'buyers' : 'suppliers';
  let path: any = dashboard;
  const returnUrl = GetQueryStringValue(window.location.href, 'RU');
  const permissions = payload.prms.replace(/ /g, '').split(',') as Permission[];
  if (payload.us && permissions.includes(Permission.Ops)) {
    path = '/ops/agency/registration';
  } else if (returnUrl && returnUrl.length > 0) {
    path = returnUrl;
  } else if (sessionStorage.getItem(limitedloginPreviousUrlName)) {
    const limited_bid_details_path = sessionStorage.getItem(limitedloginPreviousUrlName);
    path = `/${user}${limited_bid_details_path}`;
    sessionStorage.removeItem(limitedloginPreviousUrlName);
  } else if (sessionStorage.getItem(loginPreviousUrlName)) {
    path = sessionStorage.getItem(loginPreviousUrlName);

    if (sessionStorage.getItem(loginPreviousUrlSearch)) {
      path = path + sessionStorage.getItem(loginPreviousUrlSearch);
    }

    path = path.replace('/beta/', '/').replace('/app/', '/');
    if (user === 'buyers') {
      if (path.includes('/suppliers')) path = dashboard;
    } else if (user === 'suppliers') {
      if (path.includes('/buyers')) path = dashboard;
    }
    sessionStorage.removeItem(loginPreviousUrlName);
    sessionStorage.removeItem(loginPreviousUrlSearch);
  }

  return path;
};

export const formattedPhoneNumber = (phoneNumber = '', extension = '') => {
  let value = '';
  if (phoneNumber && phoneNumber.length > 6) {
    value = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
      6,
    )}`;
    if (extension) value = `(${extension}) ${value}`;
  }
  return value;
};

export const checkPaginationCallFn = (
  currentpage: number,
  listPerPages: number,
  list: string | any[],
  total: any,
) => {
  const nextindexOfLastLists = currentpage * listPerPages;
  const nextindexOfFirstLists = nextindexOfLastLists - listPerPages;
  const nextlist = list.slice(nextindexOfFirstLists + 50, nextindexOfLastLists + 50);
  return nextlist.length !== listPerPages && list.length !== total;
};

export const validateTypedDate = (
  inputDate: string,
  minDate: any = '',
  maxDate: any = '',
  isOutsideRange = false,
) => {
  const dateString = getFormattedCustomDateString(inputDate);
  const dateSplit = getFormattedCustomDateString(inputDate).split('/');
  if (dateSplit.length < 3) return false;
  else if (momenttz(dateString).format(usaDateFormat) !== dateString) {
    return false;
  } else {
    if (
      parseInt(dateSplit[0]) > 12 ||
      parseInt(dateSplit[1]) > 31 ||
      parseInt(dateSplit[2]) < momenttz().year() - datePickerYear ||
      parseInt(dateSplit[2]) > momenttz().year() + datePickerYear ||
      dateSplit[2].length !== 4
    ) {
      return false;
    } else if (!isOutsideRange) {
      const selectedDate = momenttz(dateString);

      if (minDate && maxDate) {
        return !(selectedDate.isAfter(maxDate) || selectedDate.isBefore(minDate));
      }
      if (minDate) return !selectedDate.isBefore(minDate);
    }
  }
  return true;
};

export const checkFormatted = (dateString: string) => {
  const dateSplit = dateString.split('/');
  if (dateSplit.length === 3) {
    if (dateSplit[0].length === 1) return true;
    if (dateSplit[1].length === 1) return true;
  }
  return false;
};

export const getFormattedCustomDateString = (dateString: string) => {
  const dateSplit = dateString.split('/');
  if (dateSplit.length === 3) {
    if (dateSplit[0].length === 1) dateSplit[0] = '0' + dateSplit[0];
    if (dateSplit[1].length === 1) dateSplit[1] = '0' + dateSplit[1];
  }
  return dateSplit.join('/');
};

export const validateOnTypeDate = (date: string) => {
  const splittedDate = date.split('/');

  if (splittedDate.length > 3) {
    return true;
  }

  if (splittedDate.length === 3 && splittedDate[2].length >= 4) {
    return !validateTypedDate(date);
  }

  if (splittedDate.length > 0) {
    if (parseInt(splittedDate[0]) > 12) {
      return true;
    } else if (splittedDate[1] && parseInt(splittedDate[1]) > 31) {
      return true;
    }
  }

  return false;
};

export const addDsEvent = (node: any, eventName: string, fn: (event?: any) => any): void => {
  if (node.addEventListener) {
    node.addEventListener(eventName, fn, false);
  } else if (node.attachEvent) {
    node.attachEvent('on' + eventName, fn);
  }
};

export const IsIEFn = () => {
  const windowdocument = window.document as any;
  return windowdocument?.documentMode;
};

export const getWebsiteFn = (link: string) => {
  let value = '';
  if (link) {
    if (!link.toLowerCase().includes('http')) {
      value = `http://${link}`;
    } else {
      return link;
    }
  }
  return value;
};

export const scrollLeft = (element: any, change: number, duration: number) => {
  let currentTime = 0;
  const start = element.scrollLeft;
  const increment = 20;

  const animateScroll = function () {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

//t = current time
//b = start value
//c = change in value
//d = duration
const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const sortProductFn = (a: any, b: any) => a.productName.localeCompare(b.productName);

export const filterDistinctArray = (inArray: any, fieldName: string) => {
  const newArray = [] as any;
  const distinctArray = [] as any;
  inArray.map((item: any) => {
    if (!newArray[item[fieldName]]) {
      newArray[item[fieldName]] = item;
    }
  });
  for (const i in newArray) {
    distinctArray.push(newArray[i]);
  }
  return distinctArray;
};

export function encodeFilters(filters: Filter) {
  return Object.keys(filters)
    .filter(key => {
      // showBids and showBidReset are filters we derive from user-set filters
      // so let's hide them from the url
      if (key === 'showBids' || key === 'showBidReset') return false;
      //filter empties so we don't encode an empty array
      // and insert into url
      const value = filters[key];
      if (Array.isArray(value)) {
        return value.length;
      }
      return value;
    })
    .reduce((acc, key) => {
      const value: FilterVal = filters[key];
      if (isMoment(value)) {
        acc.append(key, value.format(urlDateFormat));
      } else if (isBasicArray(value)) {
        value.forEach(v => acc.append(key, JSON.stringify(v)));
      } else if (isBidStatus(value) || isFiscalYear(value)) {
        acc.append(key, value.value);
      } else {
        acc.append(key, `${value}`);
      }
      return acc;
    }, new URLSearchParams(''))
    .toString();
}

// User-defined type guard. Helps encodeHelper
// know val is NOT a moment in the else-clause of
// it's condition, so the compiler is satisfied
function isMoment(x: FilterVal): x is moment.Moment {
  return moment.isMoment(x);
}

function isBasicArray(value: FilterVal): value is BasicArray {
  if (Array.isArray(value)) {
    if (value.length === 0) return true;
    const [first] = value;
    return ['number', 'string', 'boolean'].includes(typeof first);
  }
  return false;
}

function isBidStatus(value: FilterVal): value is BidStatus {
  if (!value) return false;
  return (
    (value as BidStatus).statusType !== undefined && (value as BidStatus).statusName !== undefined
  );
}

function isFiscalYear(value: FilterVal): value is FiscalYear {
  if (!value) return false;
  return (value as FiscalYear).label !== undefined && (value as FiscalYear).value !== undefined;
}

export function decodeParams(search: string, bidStatuses: BidStatusPartial[]): Filter {
  const params = new URLSearchParams(search);
  return Array.from(params.keys()).reduce((acc: Filter, key: string) => {
    if (key === 'industry') {
      acc[key] = decodeArray(params.getAll(key));
    } else {
      acc[key] = decodeString(key, params.get(key), bidStatuses);
    }
    return acc;
  }, {});
}

function decodeArray(values: string[]): FilterVal {
  if (!values.length) return null;
  return values.map(value => Number(value));
}

function decodeString(
  key: string,
  value: string | null,
  bidStatuses: BidStatusPartial[],
): FilterVal {
  if (!value) return null;
  switch (key) {
    case 'endDueDate':
    case 'startDueDate':
      return moment(value, urlDateFormat);
    case 'bidStatus':
      const status = bidStatuses.find(status => status.statusType === value);
      if (status) {
        return {
          statusType: value,
          value: value,
          label: status.statusName,
          statusName: status.statusName,
        };
      } else {
        return null;
      }
    case 'fiscalYear':
      return { value: value, label: value };
    case 'location':
    case 'agencyMemberId':
      return Number(value);
    default:
      return value;
  }
}

export const addUpdateMetaTag = (key: string, value: string) => {
  const heads = document.getElementsByTagName('head');
  if (heads.length > 0) {
    const metas: HTMLMetaElement[] = [].filter.call(
      heads[0].getElementsByTagName('meta'),
      (el: HTMLMetaElement) => el.name === key,
    );
    if (metas.length > 0) {
      metas[0].content = value;
    } else {
      const meta = document.createElement('meta');
      meta.name = key;
      meta.content = value;
      heads[0].appendChild(meta);
    }
  }
};

export const isExpiredToken = (token: string) => {
  const tokenData: authTypes | any = jwt.decode(token);
  if (tokenData && tokenData.exp && Date.now() > tokenData.exp * 1000) {
    return true;
  }
  return false;
};

export const getProductAgencies = (
  Products: any,
  state: number | string = '',
  county: number | string = '',
  search = '',
) => {
  let agenciesList = Products.filter((item: any) => item.productType === 'AG').sort(sortProductFn);
  if (state) {
    const parentData = Products.find((item: any) => item.productId === state);
    const countyIds = Products.filter(
      (item: any) =>
        item.productType === 'CT' &&
        item.parentId === state &&
        item.productGroupId === parentData.productGroupId,
    ).map((itemData: any) => itemData.productId);
    const countyGroupIds = Products.filter(
      (item: any) =>
        item.productType === 'CT' &&
        item.parentId === state &&
        item.productGroupId === parentData.productGroupId,
    ).map((itemData: any) => itemData.productGroupId);
    agenciesList = Products.filter(
      (f: any) =>
        f.productType === 'AG' &&
        ((f.parentType === 'ST' && f.productGroupId === parentData.productGroupId) ||
          (f.parentType === 'CT' &&
            countyIds.includes(f.parentId) &&
            countyGroupIds.includes(f.productGroupId))),
    ).sort(sortProductFn);
    if (county) {
      const countyParent = Products.find((item: any) => item.productId === county);
      agenciesList = Products.filter(
        (f: any) =>
          f.productType === 'AG' &&
          f.parentType === 'CT' &&
          f.parentId === county &&
          f.productGroupId === countyParent.productGroupId,
      ).sort(sortProductFn);
    }
  }

  agenciesList = removeDuplicates(agenciesList, 'productId');

  if (search)
    agenciesList = agenciesList.filter((item: any) =>
      item.productName.toLowerCase().includes(search.toLowerCase()),
    );
  return agenciesList;
};

export const emailValidation = (email: string) => {
  return (
    email &&
    !/^([A-Za-z0-9_\-.+])+@[A-Za-z0-9_\-.]+\.[a-zA-Z]{2,15}$/.test(
      email.toLowerCase().trimStart().trimEnd(),
    )
  );
};

export const getSubscribedProductWithRespectivePrice = (
  allProducts: any,
  currentSubscription: any,
  existingSubscription: any,
  isCheckOldPrice = true,
) => {
  const selectedSubscription = allProducts
    .filter((item: any) => currentSubscription.includes(item.productId))
    .map((sItem: any) => {
      const priceData: any = existingSubscription.find(
        (eItem: any) => eItem.productId === sItem.productId,
      );

      return {
        ...sItem,
        price: priceData && isCheckOldPrice ? priceData.price : sItem.price,
      };
    });
  return selectedSubscription;
};

export const removeDuplicates = (duplicateArray: any, key: string) => {
  const lookup = {} as any;
  duplicateArray.map((arrObj: any) => {
    lookup[arrObj[key]] = arrObj;
  });
  return Object.keys(lookup).map(key => lookup[key]);
};

export const openHelpPage = (memberType: string) => {
  if (memberType === memberTypes.agencyBuyer)
    window.open('https://network.demandstar.com/agency-support/', '_blank');
  else window.open('https://network.demandstar.com/supplier-support/', '_blank');
};

/*************************************************
 * Deprecated
 ************************************************/

/** Deprecated: use displayDate instead. (helpers/dateTime) */
export const deprecatedGetDate = (value?: dayjs.ConfigType) => {
  if (value) {
    const returnDate = dayjs(value);
    return returnDate.isValid() ? returnDate.format(usaDateFormat) : '';
  }
  return '';
};

/** Deprecated: use displayDateTime instead. (helpers/dateTime) */
export const deprecatedGetDateTime = (value: string | number | Date) => {
  if (value) {
    return dayjs(value).format('MM/DD/YYYY h:mm A');
  }
  return '';
};

/** Deprecated: use displayDateTime instead. (helpers/dateTime) */
export const deprecatedGetDateTimeZoneConverted = (
  date: string | number | Date,
  zone?: DeprecatedFullTimeZone | '',
  showSeconds = false,
  dateOnly = false,
) => {
  let convertedDate = '';
  if (date) {
    Assert(!!zone, 'The time zone should not be empty.', 'deprecatedGetDateTimeZoneConverted');
    const gmtDate = momenttz(date).format('YYYY-MM-DD HH:mm:ss');
    let zoneDifferent = timeZoneList[zone];
    const isDST = momenttz(gmtDate).tz(TimeZone.Eastern).isDST();
    zoneDifferent = isDST ? zoneDifferent + 1 : zoneDifferent;
    if (dateOnly) {
      convertedDate = momenttz(gmtDate).add(zoneDifferent, 'hours').format('MM/DD/YYYY');
    } else if (showSeconds) {
      convertedDate = momenttz(gmtDate).add(zoneDifferent, 'hours').format('MM/DD/YYYY h:mm:ss A');
    } else
      convertedDate = momenttz(gmtDate).add(zoneDifferent, 'hours').format('MM/DD/YYYY h:mm A');
  }
  return convertedDate;
};

/** Deprecated: use displayDate instead. (helpers/dateTime) */
export const deprecatedGetDateTimeZoneConvertedDate = (
  date: string | number | Date,
  zone?: DeprecatedFullTimeZone | '',
) => {
  if (date) {
    const gmtDate = momenttz(date).format('YYYY-MM-DD HH:mm:ss');
    Assert(!!zone, 'The time zone should not be empty.', 'getTimeZoneConvertedDate');
    let zoneDifferent = timeZoneList[zone] as any;
    const isDST = momenttz(gmtDate).tz(TimeZone.Eastern).isDST();
    zoneDifferent = isDST ? zoneDifferent + 1 : zoneDifferent;
    return momenttz(gmtDate).add(zoneDifferent, 'hours').format(usaDateFormat);
  }
  return '';
};

/** Deprecated: use formatAsUTC instead. (helpers/dateTime)
 * This function is used primarily to format a date in a certain way
 * to be used by an endpoint. So I am going to leave it untouched, so as not to mess with any endpoints.
 * We should be able to cut this down to one line though, and we should have no need for
 * our timeZoneList, a re-formatted date, or daylight savings.
 *
 * We should not ever change the actual time of the object as we are doing here.
 *
 * We should not even need to know the time zone, so long as we have an offset.
 */
export const deprecatedConvertotherTimezonetoUTC = (
  date: string | number | Date,
  zone: DeprecatedFullTimeZone,
  format = 'MM/DD/YYYY h:mm A',
) => {
  if (date) {
    const gmtDate = momenttz(date).format('YYYY-MM-DD HH:mm:ss');
    let zoneDifferent = timeZoneList[zone] as any;
    const isDST = momenttz(gmtDate).tz(TimeZone.Eastern).isDST();
    zoneDifferent = isDST ? zoneDifferent + 1 : zoneDifferent;
    return momenttz(gmtDate).add(Math.abs(zoneDifferent), 'hours').format(format);
  }
  return '';
};

/** Deprecated: use displayDate instead. (helpers/dateTime)*/
export const deprecatedConvertUSAFormat = (date: momenttz.MomentInput) => {
  let value = '';
  if (date && moment(date, validDateFormats).isValid()) {
    value = moment(date).format(usaDateFormat);
  }
  return value;
};

/** Deprecated: Please rewrite instead of adding this to new code.
 * - remove use of Date
 * - standardize the format
 */
export const deprecatedGetBroadcastGap = (dueDate: string | number | Date) => {
  return dayjs(new Date(dueDate)).add(1, 'hours').format('ll LTS');
};

/** Deprecated: Please rewrite instead of adding this to new code.
 * - remove use of Date
 * - standardize the format
 * - consider replacing with dayjs().isSameOrBefore()
 * - remove pagefor
 */
export const deprecatedCheckCountDown = (dueDate = '', pagefor = '') => {
  let finalvalue: CountdownStatus = '';
  const countDownDate = new Date(dueDate).getTime();
  const current = dayjs(new Date());
  const currentTime = current.tz(TimeZone.Eastern).format('ll LTS');
  const now = new Date(currentTime).getTime();
  const distance = countDownDate - now;
  finalvalue = distance < 0 ? 'closed' : '';

  if (pagefor === 'supplierquote') {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    if (days > 10) {
      finalvalue = 'notstarted';
    }
  }
  return finalvalue;
};

/** Deprecated: Please rewrite instead of adding this to new code.
 * - remove pagefor
 * - use dayJS
 * - remove Dates
 * - consider replacing with dayjs().isSameOrBefore()
 */
export const deprecatedCheckTimezoneCountDown = (
  dueDate = '',
  timezone: DeprecatedFullTimeZone | '',
  pagefor = '',
) => {
  let finalvalue = '';
  const dueDateTime = deprecatedGetDateTimeZoneConverted(dueDate, timezone);
  const countDownDate = new Date(dueDateTime).getTime();

  const current = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss');

  const currentTime = deprecatedGetDateTimeZoneConverted(current, timezone);
  const now = new Date(currentTime).getTime();
  const distance = countDownDate - now;
  finalvalue = distance < 0 ? 'closed' : '';

  if (pagefor === 'supplierquote') {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    if (days > 10) {
      finalvalue = 'notstarted';
    }
  }

  return finalvalue;
};

/** Deprecated: Please rewrite instead of adding this to new code.
 * - use dayJS
 * - remove Dates and Math
 * - consider replacing with Duration and Difference in dayjs.
 * https://day.js.org/docs/en/durations/diffing
 */
export const deprecatedCountDownTimerTimeZone = (
  dueDate: string | number | Date,
  tzfn: DeprecatedFullTimeZone | '' = '',
) => {
  const countDownDateString = deprecatedGetDateTimeZoneConverted(dueDate, tzfn, true);
  const countDownDate = moment(countDownDateString).valueOf();
  const current = moment.utc(new Date()).format('ll LTS').toString();
  const currentTime = deprecatedGetDateTimeZoneConverted(current, tzfn, true);
  const now = momenttz(currentTime).valueOf();

  const distance = countDownDate - now;
  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  const dayString =
    days > 0 ? `${getTwoDigit(days)} ${days > 1 ? 'days' : 'day'}${hours > 0 ? ',' : ''} ` : '';
  const hoursString =
    days > 0 || hours > 0 ? `${getTwoDigit(hours)} ${hours > 1 ? 'hours' : 'hour'}, ` : '';

  const minutesString =
    days > 0 || hours > 0 || minutes > 0
      ? `${getTwoDigit(minutes)} ${minutes > 1 ? 'minutes' : 'minute'}, `
      : '';

  const secondsString =
    days > 0 || hours > 0 || minutes > 0 || seconds > 0
      ? `${getTwoDigit(seconds)} ${seconds > 1 ? 'seconds' : 'second'}`
      : '';

  const countDown = `${dayString}${hoursString}${minutesString}${secondsString}` || 'Closed';

  return distance < 0 ? 'Closed' : countDown;
};

/** Deprecated: use displayToday instead. (helpers/dateTime)*/
export const deprecatedGetTimeZoneDateTime = (
  zone?: DeprecatedFullTimeZone | '',
  format = usaDateTimeFormat,
) => {
  let value: string | moment.Moment = '';
  const defaultzone = TimeZone.Eastern; // define est, pacific timezones in this format eg: TimeZone.Pacific
  if (zone) {
    const zoneDifferent = timeZoneList[zone]; // agency/supplier/bids timezone
    value = momenttz
      .utc()
      .tz(defaultzone)
      .add(5 + zoneDifferent, 'hours');
    // this is actually changing the value of date instead of displaying the correct timezone.
    // we're essentially changing the time,
    // and changing the timezone so they are both incorrect but(hopefully) in sync.
    // two wrongs make a right.
    if (format) value = value.format(format);
    // TODO: Under what circumstances would we not have a 'format'?
  }
  return value;
};

/*************************************************
 * End of deprecated functions
 * Do not add additional functions below.
 * Add all new functions to an existing helper page or add a new one.
 ************************************************/

export const guidValidation = (guid: string) => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return pattern.test(guid);
};

export const getOptimizedCommodityCodes = (commoditieslist: any) => {
  const generatenewlist = commoditieslist.filter(
    (item: { isSelected: boolean }) => item.isSelected,
  );

  const mainCategories = generatenewlist
    .filter((item: any) => {
      if (item.commodityCode === '00') {
        const allCodes = commoditieslist
          .filter(
            (items: { commodityCategory: string | number; commodityCode: string }) =>
              items.commodityCategory === item.commodityCategory && items.commodityCode !== '00',
          )
          .map((items: { commodityId: number | string }) => items.commodityId);

        const selectedCodes = generatenewlist
          .filter(
            (items: { commodityCategory: string | number; commodityCode: string }) =>
              items.commodityCategory === item.commodityCategory && items.commodityCode !== '00',
          )
          .map((items: { commodityId: number | string }) => items.commodityId);

        if (allCodes.length === selectedCodes.length) {
          return true;
        }
        return false;
      }
      return false;
    })
    .map((items: { commodityCategory: string | number; commodityId: string | number }) => ({
      commodityCategory: items.commodityCategory,
      commodityId: items.commodityId,
    }));

  const categoryIds = mainCategories.map(
    (item: { commodityCategory: string | number }) => item.commodityCategory,
  );
  const mainCategoryIds = mainCategories.map(
    (item: { commodityId: string | number }) => item.commodityId,
  );
  const finalList = generatenewlist
    .filter(
      (items: any) =>
        !categoryIds.includes(items.commodityCategory) && items.commodityCode !== '00',
    )
    .map((items: any) => items.commodityId);
  const optimizedCommodityCodes = [...mainCategoryIds, ...finalList];
  return optimizedCommodityCodes;
};
