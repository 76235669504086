import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function memberInfo(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_MEMBER_INFO, payload);
}

export function setMemberInfoDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_MEMBER_INFO_DETAILS, payload);
}

export function resetMemberinfo(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_MEMBER_INFO, payload);
}
