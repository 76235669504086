import { atom, selector } from 'recoil';

import { addInCompleteRegistrationData, getIncompleteRegistration } from '../services';
import { initialRegistrationData } from '../reducers/registration';
import { RegistrationData } from '../../types/supplierregistration';

export const incompleteRegistrationState = atom({
  key: 'incompleteRegistrationState',
  default: initialRegistrationData,
});

/**
 * Make a service API call to populate a Recoil Selector.
 */
const registrationDataSelector = selector<RegistrationData>({
  key: 'registrationDataSelector',
  get: async ({ get }) => {
    const memberId = get(incompleteRegistrationState);
    try {
      const response = await getIncompleteRegistration({ email: memberId.emailAddress });
      const data = JSON.parse(response?.data.result);

      return data.regData.registrationData || {};
    } catch (error: any) {
      throw new Error(
        `Error in 'getIncompleteRegistration() for registrationDataSelector': ${error.message}`,
      );
    }
  },
});

/**
 * Initialize recoilRegistrationData with value from reducer
 */
export const recoilRegistrationDataState = atom<RegistrationData | any>({
  key: 'recoilRegistrationDataState',
  default: registrationDataSelector,
});

/**
 * registrationInfo is an object that changes shape as the registration process continues.
 * it will be moved into Recoil when we combine subscription renewals/registration
 * For this reason, we decided to keep the `:any` though this should be updated when a strict type is determined
 */
export async function updateIncompleteRegistration(registrationInfo: any) {
  if (registrationInfo.email) {
    try {
      // API GET
      const response = (await addInCompleteRegistrationData(registrationInfo)) || { data: {} };

      // Update data
      response.data = registrationInfo;

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error in 'addInCompleteRegistrationData() for updateIncompleteRegistration': ${error.message}`,
      );
    }
  }
}
