import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Colors } from '../../shared/styles';

const ToolTipText = styled('span')({
  visibility: 'hidden',
  width: '180px', //TODO: Make this customizable / smarter.
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.blueGreenMedium}`,
  textAlign: 'center',
  borderRadius: '6px',
  padding: '0.25rem',
  position: 'absolute',
  zIndex: 1,
  bottom: '150%', //TODO: Make this customizable / smarter.
  left: '50%', //TODO: Make this customizable / smarter.
  marginLeft: '-90px', //TODO: Make this customizable / smarter.
  ':after': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: `${Colors.blueGreenMedium} transparent transparent transparent`,
  },
});

const ToolTipContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
  ':hover span': {
    visibility: 'visible',
  },
});

type ToolTipProps = PropsWithChildren<{
  text: string;
  hideTip?: boolean;
}>;

export const ToolTip = (props: ToolTipProps) => (
  <ToolTipContainer>
    {props.children}
    {props.hideTip ? '' : <ToolTipText>{props.text}</ToolTipText>}
  </ToolTipContainer>
);
