import React, { memo } from 'react';

function SetUpAccount() {
  return (
    <>
      <p>
        In the upper right corner and under your name, pull down the menu to manage your account
        information
      </p>
      img
    </>
  );
}

export default memo(SetUpAccount);
