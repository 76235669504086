import React from 'react';
// import messageCS from '../../../assets/images/messageCS.png'
import { memberTypes } from '../../../utils/constants';

import imgsCdn from '../../../assets/images/imagescdn';

const messageCS = imgsCdn.otherImg.messageCS;

function ComningSoon(props: any) {
  const { memberType } = props;

  return (
    <>
      <div className='colWrapper comingSoonWrapper'>
        <div className='row'>
          <div className='col'>
            <h1>
              Communicate Directly with{' '}
              {memberType === memberTypes.agencyBuyer ? 'Suppliers' : 'Buyers'}
            </h1>
            <p>
              Coming soon to DemandStar: a complete messaging system so you can communicate directly
              with {memberType === memberTypes.agencyBuyer ? 'Suppliers' : 'Buyers'} for a faster,
              more personal interaction that will help you make your case and provide a better
              service to your customers.
            </p>
            <ol className='listArrow'>
              <li>
                <span>
                  Convenient Search capabilities so you can find any chat you’ve got in your inbox.
                </span>
              </li>
              <li>
                <span>Keep track of all your conversations in your inbox.</span>
              </li>
              <li>
                <span>A familiar chat interface that makes it easy to hold a conversation.</span>
              </li>
            </ol>
          </div>
          <div className='col'>
            <img className='img-fluid' alt='Message' src={messageCS} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ComningSoon;
