import { axiosPostAuthenticated } from './api';
import { BidId } from '../../types/shared';
import { constants } from '../../utils/settings';
import { AddBidDocTypeOption } from '../../types/addbid';
import { Paths } from '../../utils/constants';
import { AgencyDocs } from '../../types/publications';

/**
 * Bid Document Type list request
 * @param {bidId}:BidId
 * @returns api response: DocTypeObject[]
 */
export function getBidDocumentTypeList({ bidId }: BidId) {
  return axiosPostAuthenticated<AddBidDocTypeOption[]>({
    baseURL: constants.api.url,
    data: {
      bidId,
    },
    url: Paths.doctypes,
  });
}

/**
 * Document Info request
 * @description document info, including allowed types, to determine which can be converted to PDF
 * @param {}
 * @returns api response: AgencyDocs
 */
export function getAccountDocumentInfo() {
  return axiosPostAuthenticated<AgencyDocs>({
    baseURL: constants.api.url,
    data: {},
    url: Paths.requiredDocs,
  });
}
