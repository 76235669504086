import React from 'react';

import { ContactLabels } from '../../../../shared/constants';
import { bidResponseDetailsState } from '../../../../store/recoil/bidResponseState';
import { useRecoilValueLoadable } from 'recoil';
import { getDefaultLoadingErrorMessage, getDefaultLoadingMessage } from '../../../../utils/message';
import { ErrorPanel, LoadingPanel } from '../../../../components/common/loading';
import { ContactDetailsPanelView } from './ContactDetailsPanelView';
import { memberInfoState } from '../../../../store/recoil/memberState';

/**
 * Container Cimponent:
 * Pass app state for Respons eDetails and Membe Iinfo
 * to the Presentation Component.
 * @returns
 */
export function ContactDetailsPanel() {
  const responseDetailsLoadable = useRecoilValueLoadable(bidResponseDetailsState);
  const memberInfoLoadable = useRecoilValueLoadable(memberInfoState);

  // SHow a loading message by default
  let returnContent = null;
  // Handle Recoil State Loadable states:
  // TODO: Standardize styled components for "hasError" and/or "loading" states.

  // TODO: In order to reduce logic for loadable states, as well as provide cleaner error handling,
  //   we should combine separate API calls into a single Recoil selector
  //   (e.g. `ContactState` with ResponseDetails and MemberInfoDetails)

  // if (responseDetailsLoadable.state === 'hasError') {
  if (responseDetailsLoadable.state === 'hasError' || memberInfoLoadable.state === 'hasError') {
    returnContent = <ErrorPanel message={getDefaultLoadingErrorMessage(ContactLabels.label)} />;
  }

  // if (responseDetailsLoadable.state === 'loading') {
  if (responseDetailsLoadable.state === 'loading' || memberInfoLoadable.state === 'loading') {
    returnContent = <LoadingPanel message={getDefaultLoadingMessage(ContactLabels.label)} />;
  }

  // if (responseDetailsLoadable.state === 'hasValue' && responseDetailsLoadable?.contents) {
  if (responseDetailsLoadable.state === 'hasValue' && memberInfoLoadable.state === 'hasValue') {
    const responseDetails = responseDetailsLoadable?.contents || {};

    const memberInfoDetails = memberInfoLoadable?.contents || {};

    const contactDetails = { ...responseDetails, ...memberInfoDetails };

    returnContent = <ContactDetailsPanelView data={contactDetails} />;
  }

  return returnContent;
}
