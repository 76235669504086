import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Assert, isTodayOrEarlier, nowISO } from '../../../../utils/helpers';
import { AddRequiredDocumentsPanel } from './AddRequiredDocumentsPanel';
import { DatePicker } from '../../../fields/date-picker/DatePicker';
import { requiredDocLabels } from '../add-vendor-docs/constants';
import { requiredDocumentInstructions } from './constants';
import { RequiredDocumentsTable } from './RequiredDocumentsTable';
import { useRequiredDocumentResponses } from '../../../../shared/hooks';
import { useRequiredDocumentTypes } from '../../../../shared/hooks/award-bid/useRequiredDocumentTypes';

const AllDocDescriptionWrapper = styled.div`
  padding-bottom: 2rem;
`;

const UpdateAllDatesWrapper = styled.div`
  padding-bottom: 2rem;
`;

/**
 * Required Document List table with editable/deletable rows,
 * a date picker to change all row dates,
 * and a toggle button to allow addition of new Required Documents
 */
export function RequiredDocumentsPanel() {
  // Local state
  const [defaultDate, setDefaultDate] = useState(nowISO());

  // Hooks
  const {
    requiredDocumentResponses: requiredDocResponses,
    setRequiredDocumentResponses,
  } = useRequiredDocumentResponses();
  const { refreshRequiredDocTypes } = useRequiredDocumentTypes();

  // useEffect Hooks

  useEffect(() => {
    refreshRequiredDocTypes();
  }, [refreshRequiredDocTypes]);

  // Functions

  /** Update all requiredDocs with the general DatePicker
   * @param {string} value - the selected date in ISO
   */
  function changeAllDueDates(value: string | null) {
    setDefaultDate(value || '');
    Assert(!!value, 'dueDate should be valid', 'RequiredDocumentsPanel');
    const updatedRequiredDocs = [...requiredDocResponses].map(reqDoc => {
      return { ...reqDoc, dueDate: value };
    });
    setRequiredDocumentResponses(updatedRequiredDocs);
  }

  return (
    <>
      <AllDocDescriptionWrapper>{requiredDocumentInstructions}</AllDocDescriptionWrapper>

      <UpdateAllDatesWrapper>
        <DatePicker
          label={requiredDocLabels.updateAllDueDates}
          disabledDate={isTodayOrEarlier}
          onChange={changeAllDueDates}
          allowClear={false}
          fullWidth={true}
          name={'update-all-dates'}
          value={defaultDate}
        />
      </UpdateAllDatesWrapper>

      <RequiredDocumentsTable />

      <AddRequiredDocumentsPanel />
    </>
  );
}
