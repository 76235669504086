import { useRecoilCallback, useRecoilState } from 'recoil';

import { getRequiredDocumentResponse } from '../../../store/services';
import { requiredDocumentResponsesState } from '../../../store/recoil/award-bid';
import { selectedBidIdState } from '../../../store/recoil/bidState';
import { supplierMemberIdState } from '../../../store/recoil/memberState';
import { track } from '../../../utils/telemetry';
import { useSetSelectedBidIdFromRoute } from '../useSelectedBidId';

/**
 * Async service call to get RequiredDocumentResponses with try/catch wrapping
 */
export async function getRequiredDocResponsesFromService(bidId: number, supplierMemberId: number) {
  try {
    const responses = await getRequiredDocumentResponse({ bidId });

    // Sanitize for docs with docTitle values.
    // TODO: This is a temporary catch for bad data,
    // enforce validation on docType form and doc upload form as well as the service.
    const validResponses = responses.filter(response => response?.docTitle !== '');

    if (supplierMemberId <= 0) {
      return validResponses;
    }

    // Filter by supplied `supplierMemberId`
    const filteredResponses = validResponses.filter(response => {
      return response.awardedToMemberId === supplierMemberId;
    });

    return filteredResponses;
  } catch (error) {
    const errorMessage = `useRequiredDocumentResponses -> getRequiredDocResponsesFromService(bidId: ${bidId}, memberId: ${supplierMemberId}) ERROR:`;
    track(errorMessage, {
      error,
      method: 'POST',
      errorMessage: (error as any).message,
    });
    console.error(`${errorMessage}: \n${error}`);
    return [];
  }
}

/**
 * A hook used to gather and manipulate RequiredDocResponses.
 */
export function useRequiredDocumentResponses() {
  useSetSelectedBidIdFromRoute();

  const [requiredDocumentResponses, setRequiredDocumentResponses] = useRecoilState(
    requiredDocumentResponsesState,
  );

  /**
   * Find RequiredDocumentResponses and update RequiredDocument Recoil state by `bidAwardRequiredDocId`.
   */
  const refreshRequiredDocumentResponses = useRecoilCallback(
    ({ set, snapshot }) => async () => {
      const bidId = await snapshot.getPromise(selectedBidIdState);
      const supplierMemberId = await snapshot.getPromise(supplierMemberIdState);

      const responses = await getRequiredDocResponsesFromService(bidId, supplierMemberId);

      // Update Recoil state
      set(requiredDocumentResponsesState, responses);
    },
    [],
  );

  return {
    refreshRequiredDocumentResponses,
    requiredDocumentResponses,
    setRequiredDocumentResponses,
  };
}
