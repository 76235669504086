// TOREFACTOR: Can we consolidate this and the other checkbox.tsx and BasicCheckbox?
import React, { memo } from 'react';
import BasicCheckbox from './BasicCheckbox';

interface CheckBoxProps {
  checked?: boolean;
  classNames?: string;
  dataTestId?: string;
  defaultChecked?: boolean;
  disabled?: boolean | string;
  disabledText?: string;
  fieldRef?: any; // optional pass-through ref for `react-hook-form`
  handleChecked?: (name: string, value: any) => void;
  ID?: string;
  indeterminate?: boolean;
  input?: any;
  label?: string | JSX.Element;
  name?: string;
  parentClass?: string;
  reduxform?: boolean;
  title?: string;
  value?: string | boolean;

  // I think all these we can get rid of,
  // but that will require some exploration for the MANY Checkboxes that use these props.
  meta?: any;
  options?: any;
  addBidForm?: any;
  type?: any;
  handleSelect?: any;
}

function CheckBox(props: CheckBoxProps) {
  const {
    classNames,
    handleChecked,
    title,
    input,
    reduxform = false,
    name,
    disabled,
    indeterminate = false,
    value,
    ID,
    checked,
    parentClass,
    defaultChecked = false,
    disabledText,
    label,
    fieldRef,
    dataTestId,
  } = props;

  const onChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.checked;
    if (handleChecked) {
      handleChecked(name, value);
    }
  };

  if (!reduxform) {
    return (
      <BasicCheckbox
        classNames={classNames}
        handleChange={onChange}
        id={ID}
        disabled={disabled}
        indeterminate={indeterminate}
        value={value}
        name={name}
        label={label}
        checked={checked}
        aria-checked={checked}
        title={title}
        disabledText={disabledText}
        fieldRef={fieldRef}
        dataTestId={dataTestId}
      />
    );
  } else {
    const component = defaultChecked ? (
      <input
        {...input}
        type='checkbox'
        checked={defaultChecked}
        disabled={disabled === 'true' ? true : false}
        data-testid={dataTestId}
      />
    ) : (
      <input
        {...input}
        type='checkbox'
        disabled={disabled === 'true' ? true : false}
        data-testid={dataTestId}
      />
    );

    return (
      <label
        htmlFor={name}
        aria-labelledby={name}
        className={'checkControl ' + (parentClass ? parentClass : '')}
      >
        {component}
        {title}
        <span className='checkmark' />
        {indeterminate && <span className='indeterminate' />}
      </label>
    );
  }
}

export default memo(CheckBox);
