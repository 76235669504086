import styled from 'styled-components';

import { FontFamily, ParagraphFontSize } from './../styles';
import { calculateRem } from '../utils/conversions';

const choicePx = 20;

export const choiceRem = calculateRem(choicePx);
export const choicePaddingRem = calculateRem(choicePx + 10);
export const halfChoiceRem = calculateRem(choicePx / 2);

interface ChoiceLabelProps {
  deactivated?: boolean;
}

/**
 * ChoiceLabel is used for radio and checkboxes labels.
 */
export const ChoiceLabel = styled.label<ChoiceLabelProps>`
  display: block;
  position: relative;
  padding: 0 0 0 ${choicePaddingRem};
  margin: ${choiceRem} ${halfChoiceRem} 0 0;
  cursor: ${({ deactivated }) => (deactivated ? 'not-allowed' : 'pointer')};
  line-height: ${choiceRem};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Base};
`;
