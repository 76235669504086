import React, { memo } from 'react';

function Reminders() {
  return (
    <>
      <p>To ensure your bid is properly scheduled be sure to: </p>
      <ul>
        <li>Approve Documents (or make sure they say “Complete”)</li>
        <li>“Finish Bid” </li>
      </ul>
      <p>
        The bid will be placed in the queue to broadcast on the appropriate date, or you can click
        on “broadcast now” and your bid will placed in the queue after a 5-Minute hold and emailed
        within the hour.{' '}
      </p>
      <h5>BID / eBID UNDER EVALUATION</h5>
      <p>
        Once your solicitation closes for submissions (due date/time), your bid status automatically
        becomes “Under Evaluation”.{' '}
      </p>
      <p>You can review your posted solicitations by finding them via the Dashboard page: </p>
      img
      <p>Or, by reviewing all your bids on the Bids page – and clicking on the Bid name: </p>
      img
      <p>
        This takes you to the Bid DETAIL page, where you will now see TABs at the top that provide
        you with information, including eBid Responses, if you required eBidding:{' '}
      </p>
      img
      <p>
        On the eBid Responses Tab, you will see all the vendors who submitted proposals, as well as
        be able to access the documents they submitted. NOTE: you can do this for your own bids, but
        will not be able to see/open bid submissions for other government agencies’ solicitations.{' '}
        <b>
          Similarly, no one else except those in your account may see responses to your
          solicitations, nor any accompanying documents.
        </b>{' '}
      </p>
      img
      <p>
        As you click on any of the suppliers who submitted an eBid, you will see their documents and
        can download them from there. They are available to you online for 45 days, after which they
        are archived – and still available, upon request, from DemandStar. Reminder: No one else,
        except you and your colleagues can see the documents that suppliers have submitted for your
        bid.
      </p>
      <p>
        You may ADD RESPONSES, for any submission that did not come through DemandStar (if you
        allowed them to respond outside of the eBid process).
      </p>
      <p>
        [You may have chosen to have them eBid or not; and even if you chose eBid, you can in your
        instructions tell them that they may, alternatively, send in hard copies. You won’t be able
        to upload their submitted documents into the Tabulation, but yes, you can put in more
        information on other bidders into the tabulation sheet (“Add Responses”) that DemandStar
        compiles. Make sure they are already set up as Plan Holders to have them show up in the
        pull-down list.]
      </p>
      img
    </>
  );
}

export default memo(Reminders);
