import React, { useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { DSRadio } from '@demandstar/components/radio/DSRadio';
import styled from 'styled-components';

import { AwardWizardId, newUnselectedPlanholders } from '../../../../../store/recoil/award-bid';
import { hasValue, isValidEmailInfo } from '../../../../../utils/helpers';
import { AddUnselectedBidders } from './AddUnselectedBidders';
import { AwardNotificationBidDetails } from './../AwardNotificationBidDetails';
import { BidEmailPreview } from './../BidEmailPreview';
import { CustomMessageType } from '../../../../../types/awardBid';
import { EditEmailInfo } from './../EditEmailInfo';
import { LoadableWrapper } from '../../../../common/loading/LoadableWrapper';
import { MessagePreview } from './../MessagePreview';
import { notifyUnselectedState } from '../../../../../store/recoil/award-bid';
import { useAwardBidWizard } from '../../../../../shared/hooks';
import { WizardButtons } from '../../../../customcontrols/wizard/WizardButtons';

// TODO: Replace this one we have some standards for our grid.
const HalfColumn = styled.div`
  display: inline-block;
  width: 50%;
`;

export const NotifyUnselectedBidders = (): JSX.Element => {
  // Hooks
  const { saveAwardBidWizard, returnToBidSummary, messagePreview, setMessagePreview } =
    useAwardBidWizard();

  // Recoil
  const emailInfo = useRecoilValueLoadable(notifyUnselectedState);
  const newPlanholders = useRecoilValue(newUnselectedPlanholders);

  // Local
  const [showRecipients, setShowRecipients] = useState(false);

  const allPotentialPlanholders = useMemo(() => {
    if (hasValue(emailInfo)) {
      return [
        ...emailInfo.contents.recipients,
        ...newPlanholders.map(planholder => {
          return {
            email: planholder.email,
            contactName: planholder.name,
            memberName: planholder.name,
          };
        }),
      ];
    }
    return [];
  }, [emailInfo, newPlanholders]);

  const hasPotentialUnselected = !!allPotentialPlanholders.length;

  function onPrevious() {
    if (messagePreview) {
      setMessagePreview(false);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyAwardees, true);
    }
  }

  /**
   * Shows a preview if on the first page AND there are unselected bidders.
   * If there are no unselected bidders, or we're already on the preview,
   * it will take you to NotifyColleagues.
   */
  function onNext() {
    if (messagePreview || !hasPotentialUnselected) {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues);
    } else {
      setMessagePreview(true);
    }
  }

  return (
    <div>
      {messagePreview ? (
        <LoadableWrapper loadable={emailInfo}>
          {hasValue(emailInfo) && (
            <MessagePreview to={allPotentialPlanholders} subject={emailInfo.contents.subject}>
              <BidEmailPreview
                emailInfo={emailInfo.contents}
                addressee={
                  allPotentialPlanholders.length === 1
                    ? allPotentialPlanholders[0].contactName ||
                      allPotentialPlanholders[0].memberName ||
                      ''
                    : '{{COMPANY_NAME}}'
                }
              />
            </MessagePreview>
          )}
        </LoadableWrapper>
      ) : (
        <>
          Did any suppliers submit responses outside of DemandStar?
          <div>
            <HalfColumn>
              <DSRadio
                name='yes'
                checked={showRecipients}
                onSelect={() => setShowRecipients(true)}
                label='Yes'
              />
            </HalfColumn>
            <HalfColumn>
              <DSRadio
                name='no'
                checked={!showRecipients}
                onSelect={() => setShowRecipients(false)}
                label='No'
              />
            </HalfColumn>
          </div>
          {showRecipients && <AddUnselectedBidders />}
          <EditEmailInfo
            emailInfoState={notifyUnselectedState}
            customMessageType={CustomMessageType.NonAwardees}
          />
          <AwardNotificationBidDetails />
        </>
      )}

      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={messagePreview || !hasPotentialUnselected ? undefined : 'Preview'}
        save={returnToBidSummary}
        disableNext={!hasValue(emailInfo) || !isValidEmailInfo(emailInfo.contents)}
      />
    </div>
  );
};
