import React, { memo } from 'react';
import Dropdown from './dropdown';

interface PropsTypes {
  parentClass?: string;
  label?: string;
  options?: any;
  iconType?: string;
  onIndustryClear?: any;
  clearIcon?: any;
  onComboChange?: any;
  placeholder?: any;
  name?: any;
  value?: any;
  onChange?: any;
  isAutoPopulate?: any;
  isMultiSelect?: any;
  titleHelper?: any;
}

function ComboBox(props: PropsTypes) {
  const { parentClass, label, options, ...rest } = props;
  const onComboChange = (id: string, key: string, item: string) => {
    props.onComboChange(item);
  };

  const getIcon = () => {
    const { iconType, onIndustryClear, clearIcon } = props;
    if (iconType) {
      if (iconType === 'filter') {
        if (!clearIcon) {
          return <i className='mdi mdi-filter-outline mt-1' />;
        } else {
          return (
            <>
              {/*<i className='mdi mdi-filter-outline mt-1' />*/}
              <i className='mdi mdi-close mt-1' onClick={onIndustryClear} />
            </>
          );
        }
      } else {
        return <i className='mdi mdi-magnify mt-1' />;
      }
    }
  };
  return (
    <div
      className={'comboWrapper clearfix ' + parentClass + (!props.clearIcon ? '' : ' clearIcon')}
    >
      <div>
        <label title={label}>{label}</label>
        {getIcon()}
        <Dropdown title='Select' list={options} resetThenSet={onComboChange} {...rest} />
      </div>
      <div className='errorMsg' />
    </div>
  );
}

export default memo(ComboBox);
