import React, { useEffect } from 'react';
import NoResult from '../../customcontrols/noresult';
import { noresultFound } from '../../../utils/texts';
import { anyParamType } from '../../../types/paymentdetails';
import { incompleteconditionslistParamType } from '../../../types/bids';

interface PropsTypes {
  incompletebidid: string;
  getConditionspending?: anyParamType;
  incompleteconditionslist?: incompleteconditionslistParamType[];
  setBidDetails?: anyParamType;
  handleOnClick: any;
}

export function Incompletemodal(props: PropsTypes) {
  const {
    incompletebidid,
    getConditionspending,
    incompleteconditionslist = [],
    setBidDetails,
  } = props;

  useEffect(() => {
    if (incompletebidid) {
      getConditionspending({ bidId: incompletebidid });
    }
  }, [getConditionspending, incompletebidid]);

  useEffect(() => {
    return () => {
      setBidDetails({ incompleteconditionslist: [] });
    };
  }, [setBidDetails]);

  return (
    <div>
      <ul>
        {incompleteconditionslist.length > 0 ? (
          <>
            {incompleteconditionslist.map((list, index) => (
              <li className='text-red200' key={index}>
                {list.error}
              </li>
            ))}
          </>
        ) : (
          <NoResult message={noresultFound} />
        )}
      </ul>
    </div>
  );
}
