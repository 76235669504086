import React from 'react';
import { CheckBox } from '../../customcontrols';

interface PropsTypes {
  checkDisabled?: string;
  checkIndeterminate?: boolean;
  stateName: string;
  countyChecked?: boolean;
  productId?: number;
  updateSubscriptionState?: any;
  onChangeSelection?: any;
  selectedState?: number;
}

const StateList = (props: PropsTypes) => {
  const {
    updateSubscriptionState,
    onChangeSelection,
    selectedState,
    checkDisabled = 'true',
    checkIndeterminate = false,
    stateName,
    countyChecked = false,
    productId = '',
  } = props;

  return (
    <>
      <div
        id={selectedState ? `toggler` : ''}
        onClick={() => onChangeSelection(productId)}
        className={`subStateList col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 p-0 ${
          selectedState === productId ? 'selectedProduct' : null
        }`}
      >
        <div className='d-flex align-items-center'>
          <label className='countyName mb-0 flex1' data-testid={stateName}>
            {stateName}
          </label>
          <CheckBox
            title=''
            parentClass='mb-0'
            disabled={`${checkDisabled}`}
            indeterminate={checkIndeterminate}
            checked={countyChecked}
            //dataTestid={stateName}
          />
        </div>
      </div>
    </>
  );
};

export default StateList;
