import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { deprecatedGetDate, toastFn } from '../../utils/helpers';
import * as actionTypes from '../actionTypes';
import * as programApi from '../services/programs';
import { userEvent } from '../../utils/constants';
const programstoastID = '73bdbb2e-5306-4111-a4cf-6ce0b6a9e208';

function* watchGetProgramsAsync() {
  yield takeLatest(actionTypes.GET_PROGRAMS.TRIGGER, getProgramsAsync);
}

function* getProgramsAsync(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_PROGRAMS.REQUEST, meta: action.payload });
  try {
    const response = yield call(programApi.getPrograms, action.payload);
    yield put({ type: actionTypes.GET_PROGRAMS.SUCCESS, payload: response.data.result });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PROGRAMS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchAddUpdateProgramsAsync() {
  yield takeLatest(actionTypes.ADD_UPDATE_PROGRAM.TRIGGER, AddUpdateProgramsAsync);
}

function* AddUpdateProgramsAsync(action: { payload: any }): any {
  yield put({ type: actionTypes.ADD_UPDATE_PROGRAM.REQUEST, meta: action.payload });

  try {
    const { data: values = {}, initial = {} } = action.payload || {};
    const { payload } = action;
    const { selfdeclaredattributes } = yield select(state => state.shared);
    let seldDeclareArray = [];
    if (payload.data && payload.data.selfdecAttr) {
      seldDeclareArray = selfdeclaredattributes.map(
        (item: { attributeType: string }, index: number) => {
          return { attribute: item.attributeType, selected: payload.data.selfdecAttr[index] };
        },
      );
    }
    let postData = {};
    if (payload.isDeleted) {
      postData = {
        id: payload.id,
        isDeleted: payload.isDeleted,
      };
    } else {
      postData = {
        id: payload.data.id,
        name: payload.data.name,
        description: payload.data.description,
        acceptApplications: payload.data.acceptApplications.value,
        certificationRequired: payload.data.certificationRequired.value,
        attributeType: seldDeclareArray
          .filter((f: { selected: boolean }) => f.selected)
          .map((m: { attribute: string }) => m.attribute)
          .join(','),
      };
    }

    const response = yield call(programApi.addUpdateProgram, postData);
    const { programs } = yield select(state => state.programs);
    const program = { ...postData, id: response.data.result.status };
    const programList = payload.isDeleted
      ? [...programs.filter((f: { id: number }) => f.id !== payload.id)]
      : [...programs.filter((f: { id: number }) => f.id !== program.id), program];
    yield put({
      type: actionTypes.ADD_UPDATE_PROGRAM.SUCCESS,
      payload: programList,
    });

    if (payload.isDeleted) {
      const deletedItem = programs.filter((f: { id: number }) => f.id === payload.id);
      const deletedItemName = deletedItem.length > 0 ? deletedItem[0].name : '';
      const payloadEvent = {
        eventId: userEvent.DeleteCertificate,
        parentId: payload.id,
        value: deletedItemName,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
    } else {
      if (payload.data.id) {
        let seldDeclareArrayDiff = [];
        const diff = Object.keys(initial).reduce((diff: any, key: string) => {
          if (values[key] === initial[key]) return diff;
          return {
            ...diff,
            [key]: initial[key],
          };
        }, {});
        if (diff.selfdecAttr) {
          seldDeclareArrayDiff = selfdeclaredattributes.map(
            (item: { attributeType: string }, index: number) => {
              return { attribute: item.attributeType, selected: diff.selfdecAttr[index] };
            },
          );
          diff.selfdecAttr = seldDeclareArrayDiff
            .filter((f: { selected: boolean }) => f.selected)
            .map((m: { attribute: string }) => m.attribute)
            .join(',');
        }
        const payloadEvent = {
          eventId: userEvent.EditCertificate,
          parentId: payload.data.id,
          value: diff,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      }
    }
    toastFn(
      'success',
      payload.isDeleted
        ? 'Certification has been Deleted'
        : payload.data.id
        ? 'Certification has been updated'
        : 'Certification has been added',
      programstoastID,
    );
    yield put({
      type: actionTypes.GET_PROGRAMS.TRIGGER,
    });
  } catch (error) {
    yield put({
      type: actionTypes.ADD_UPDATE_PROGRAM.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', programstoastID);
  }
}

function* watchGetSuppliersAsync() {
  yield takeLatest(actionTypes.GET_SUPPLIERS.TRIGGER, getSuppliersAsync);
}

function* getSuppliersAsync(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_SUPPLIERS.REQUEST, meta: action.payload });
  let postData = yield select(state => state.programs.programFilters);
  postData = {
    ...postData,
    programId: typeof postData.programId === 'object' ? postData.programId.value : undefined,
    status: typeof postData.status === 'object' ? postData.status.value : undefined,
  };

  try {
    const response = yield call(programApi.getSuppliers, postData);
    yield put({ type: actionTypes.GET_SUPPLIERS.SUCCESS, payload: response.data.result });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUPPLIERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchRespondCertRequestAsync() {
  yield takeLatest(actionTypes.RESPOND_CERT_REQUEST.TRIGGER, RespondCertRequestAsync);
}

function* RespondCertRequestAsync(action: { payload: any }) {
  yield put({ type: actionTypes.RESPOND_CERT_REQUEST.REQUEST, meta: action.payload });

  try {
    const { value: values = {}, initial = {} } = action.payload;
    const postData = {
      supplierId: values.supplierId,
      programId: values.programId,
      status: values.status.value,
      expires: deprecatedGetDate(values.expires),
      certCode: values.certCode,
      notes: values.notes,
    };
    yield call(programApi.respondCertRequest, postData);
    yield put({
      type: actionTypes.RESPOND_CERT_REQUEST.SUCCESS,
      payload: postData,
    });
    const diff = Object.keys(initial).reduce((diff: any, key: string) => {
      if (values[key] === initial[key]) return diff;
      return {
        ...diff,
        [key]: initial[key],
      };
    }, {});
    const payloadEvent = {
      eventId: userEvent.ApproveCertificate,
      parentId: values.supplierId,
      value: diff,
    };
    yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
    yield put({
      type: actionTypes.GET_SUPPLIERS.TRIGGER,
    });
  } catch (error) {
    yield put({
      type: actionTypes.RESPOND_CERT_REQUEST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchSetProgramFiltersAsync() {
  yield takeLatest(actionTypes.SET_PROGRAM_FILTERS.TRIGGER, setProgramFiltersAsync);
}

function* setProgramFiltersAsync(action: { payload: any }) {
  try {
    yield put({ type: actionTypes.SET_PROGRAM_FILTERS.ACTION, payload: action.payload });
  } catch (error) {
    yield put({
      type: actionTypes.SET_PROGRAM_FILTERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchClearProgramFiltersAsync() {
  yield takeLatest(actionTypes.CLEAR_PROGRAM_FILTERS.TRIGGER, clearProgramFiltersAsync);
}

function* clearProgramFiltersAsync(action: { payload: any }) {
  try {
    yield put({ type: actionTypes.CLEAR_PROGRAM_FILTERS.ACTION, payload: action.payload });
    yield put({ type: actionTypes.GET_SUPPLIERS.TRIGGER });
  } catch (error) {
    yield put({
      type: actionTypes.CLEAR_PROGRAM_FILTERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* programsSaga() {
  yield fork(watchGetProgramsAsync);
  yield fork(watchAddUpdateProgramsAsync);
  yield fork(watchGetSuppliersAsync);
  yield fork(watchRespondCertRequestAsync);
  yield fork(watchSetProgramFiltersAsync);
  yield fork(watchClearProgramFiltersAsync);
}
