import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { BidExternalStatusType, BidInternalStatusType } from '../../../utils/constants';
import { scrollToTop, setWizardStatus } from '../../../utils/helpers';
import {
  useSelectedBidId,
  useSetSelectedBidIdFromRoute,
} from '../../../shared/hooks/useSelectedBidId';
import { BidSummaryResult } from '../../../types/bidssummary';
import { Buttons } from '../../customcontrols';
import { setAddBidDetails } from '../../../store/actions/addbid';
import { useDSSelector } from '../../../store/reducers';
import { useHistory } from 'react-router-dom';
import { WizardPage } from '../../../types/wizard';

/* This is an old file.
Hopefully soon, we will have done an overhaul on how we do all of our wizards.
In the meantime, do not add this component to any new wizards.

*/

interface AddBidWizardButtonsProps {
  currentwizard?: WizardPage; // should this be required?! (Yes, but that will take a lot of doing, and I'm focusing on other parts of the code right now.)
}

function AddBidWizardButtons(props: AddBidWizardButtonsProps) {
  useSetSelectedBidIdFromRoute();

  const dispatch = useDispatch();

  const history = useHistory();
  const { selectedBidId } = useSelectedBidId();

  const { currentwizard } = props;

  const addbidwizard = useDSSelector(state => state.addbid.addbidwizard);
  const results = useDSSelector(state => state.bidssummary.results || {});
  const {
    bidExternalStatusType = BidExternalStatusType.None,
    bidInternalStatusType = BidInternalStatusType.None,
  } = results as BidSummaryResult;

  const savebtnText =
    selectedBidId &&
    (bidExternalStatusType !== BidExternalStatusType.Upcoming ||
      bidInternalStatusType !== BidInternalStatusType.Pending)
      ? 'Save & Exit'
      : 'Save & Finish Later';

  const cancel = () => {
    if (history) {
      if (selectedBidId) {
        history.push(`/buyers/bids/${selectedBidId}`);
      } else {
        history.goBack();
      }
    }
  };

  return (
    <div className='d-flex justify-content-between'>
      <Buttons
        classNames='bttn-secondary mt-5 float-left'
        text={currentwizard && currentwizard.id > 1 ? 'Previous' : 'Cancel'}
        onClick={() => {
          if (currentwizard && currentwizard.id > 1) {
            scrollToTop();
            dispatch(
              setAddBidDetails({
                addbidwizard: setWizardStatus(addbidwizard, currentwizard.id - 1 || 1),
              }),
            );
          } else {
            cancel();
          }
        }}
        type='button'
      />

      {currentwizard && addbidwizard && currentwizard.id < addbidwizard.length ? (
        <Buttons
          classNames='bttn-secondary mt-5 float-right'
          text={savebtnText}
          title={savebtnText}
          id='saveFinishLaterBtn'
          type='submit'
          onClick={() => {
            dispatch(setAddBidDetails({ addbidwizardpartial: true }));
          }}
        />
      ) : null}
      {currentwizard && addbidwizard && currentwizard.id < addbidwizard.length ? (
        <Buttons classNames='bttn-primary mt-5' text='Next' title='Next' type='submit' />
      ) : null}
      {currentwizard && addbidwizard && currentwizard.id >= addbidwizard.length ? (
        <>
          {currentwizard.id === addbidwizard.length ? (
            <>
              <Buttons
                classNames='bttn-secondary mt-5 float-left'
                text='Review Details'
                title='Review Details'
                onClick={cancel}
                type='button'
              />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default memo(AddBidWizardButtons);
