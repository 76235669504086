import React, { memo, useEffect, useState } from 'react';
import { Buttons, Paging, ScrollButton, SelectBox } from '../../../customcontrols';
import {
  reportbidStatus,
  reportOptions,
  reportotherbidStatus,
  reportotherquoteStatus,
  usaDateFormat,
} from '../../../../utils/constants';
import { reactselectTypes } from '../../../../types/shared';
import { authTypes } from '../../../../types/auth';
import { activitiesTypes, memberinfoTypes } from '../../../../types';
import BidActivityReport from './bidactivityreport';
import QuoteActivityReport from './quoteactivityreport';
import moment from 'moment-timezone';
import { deprecatedGetTimeZoneDateTime } from '../../../../utils/helpers';

interface PropTypes {
  auth: authTypes;
  activities: activitiesTypes;
  memberinfo: memberinfoTypes;
  setBreadcrumb: any;
  getBidActivityReport: any;
  exportBidActivityReport: any;
  getQuoteActivityReport: any;
  exportQuoteActivityReport: any;
  setActivitiesDetails: any;
  memberInfo: any;
}

const ActivityReport = (props: PropTypes) => {
  const { activities, exportBidActivityReport, exportQuoteActivityReport, memberinfo } = props;

  const {
    bidreports_status,
    bidreports_startDate,
    bidreports_endDate,
    quotereports_status,
    quotereports_startDate,
    quotereports_endDate,
  } = activities || {};
  const { mn = '', tzfn } = memberinfo || {};

  // Report Selection
  const [selectedReport, setSelectedReport] = useState(reportOptions[0]);

  const onChangeSelectReport = (name: string, value: reactselectTypes) => {
    setSelectedReport(value);
  };

  const handleExportClick = () => {
    if (selectedReport.value === 'bids') {
      exportBidActivityReport({
        startDate: bidreports_startDate ? moment(bidreports_startDate).format(usaDateFormat) : null,
        endDate: bidreports_endDate ? moment(bidreports_endDate).format(usaDateFormat) : null,
        statusList:
          bidreports_status?.value === 'all' ? reportotherbidStatus : bidreports_status?.value,
        agencyName: mn,
        reportRunTime: deprecatedGetTimeZoneDateTime(tzfn),
      });
    } else {
      exportQuoteActivityReport({
        startDate: quotereports_startDate
          ? moment(quotereports_startDate).format(usaDateFormat)
          : null,
        endDate: quotereports_endDate ? moment(quotereports_endDate).format(usaDateFormat) : null,
        statusList:
          quotereports_status?.value === 'all'
            ? reportotherquoteStatus
            : quotereports_status?.value,
        agencyName: mn,
        reportRunTime: deprecatedGetTimeZoneDateTime(tzfn),
      });
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-12' data-testid='activities.bids.reports.head'>
          <h2 className='arrowWrapper'>Solicitation Reports</h2>
          <Buttons
            title='Export to Excel'
            text='Export to Excel'
            classNames='bttn-primary float-right btnPTitle'
            onClick={handleExportClick}
            //disable={reports_list.length === 0}
          />
        </div>
        <div className='row ml-1 mb-3'>
          <div className='col-lg-12'>
            <div className='clearfix d-flex align-items-center py-3 activitiesFilter'>
              <SelectBox
                reactselect={true}
                label='Reports For'
                name='selectedReport'
                options={reportOptions}
                handleSelect={onChangeSelectReport}
                value={selectedReport}
              />
            </div>
          </div>
        </div>
      </div>
      {selectedReport.value === 'bids' ? (
        <BidActivityReport {...props} />
      ) : (
        <QuoteActivityReport {...props} />
      )}
    </>
  );
};

export default memo(ActivityReport);
