import React from 'react';
import { Table } from 'reactstrap';

import { bidItemParamType } from '../../../types/biddetail';
import CountDownTimer from '../../common/countdowntimer';
import { displayDateTime } from '../../../utils/helpers';

interface EBidResponseProps {
  progressvalue: any;
  results: bidItemParamType;
}

const tdWidth = {
  width: '130px',
};

export const EBidResponse = (props: EBidResponseProps) => {
  const {
    agencyName = '',
    bidIdentifier = '',
    bidName = '',
    dueDate = '',
    tzfn = '',
  } = props.results;

  return (
    <div className='innerColIndent'>
      <Table borderless className='tableData mb-5' data-testid='supplier.ebids.response.table'>
        <tbody>
          <tr>
            <th style={tdWidth}>Agency Name</th>
            <td>
              <span className='staticLink'>{agencyName}</span>
            </td>
          </tr>
          <tr>
            <th>Bid Number</th>
            <td>
              <span className='staticLink'>{bidIdentifier}</span>
            </td>
          </tr>
          <tr>
            <th>Bid Due Date</th>
            <td className='text-red200'>{displayDateTime(dueDate, tzfn)}</td>
          </tr>
          <tr>
            <th>Countdown to Bid Due Date</th>
            <td className='text-red200'>
              <CountDownTimer tzfn={tzfn} dueDate={dueDate} />
            </td>
          </tr>
          <tr>
            <th>Bid Name</th>
            <td>{bidName}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
