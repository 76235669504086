import React, { memo, useEffect, useMemo, useState } from 'react';
import AddressFrom from './addressform';
import { getCountiesList } from '../../../store/actions/shared';
import { sharedTypes } from '../../../types/shared';
import { useDispatch } from 'react-redux';
import { useForceUpdate } from '../../../utils/helperHooks';

interface PropsTypes {
  shared?: sharedTypes;
  initialValues?: any;
  onSubmit?: any;
  onSubmitNext?: any;
  breadcrumbs?: any;
  pagevaluessaveandtextname?: string;
  pagevaluespagefor?: string;
  pagevaluescancel?: any;
  pagevaluescancelname?: string;
  pagevaluessavename?: string;
  pagevaluesbreadcrumb?: string;
  pagevaluespage?: string;
  pagevaluestitle?: string;
  pagevaluesaltname?: string;
  pagevaluesname?: string;
  pageinfo?: string;
  pagevaluesmenuactive?: string;
  pagefor?: string;
  setSharedDetails?: any;
}

function AddressReduxForm(props: PropsTypes) {
  const dispatch = useDispatch();
  const { shared, initialValues, setSharedDetails } = props || {};
  const { countrieslist = [], countieslist = [], sharedFormInitialValues = {}, formPage = '' } =
    shared || {};
  const [forminitialValues, setFormInitialValues] = useState(initialValues);
  const [loadedData, setLoadedData] = useState(false);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!loadedData) setFormInitialValues({ ...initialValues });
  }, [initialValues, loadedData]);

  const initialGeoState = useMemo(() => {
    return initialValues?.state?.value;
  }, [initialValues]);

  useEffect(() => {
    if (initialGeoState) {
      dispatch(getCountiesList(initialGeoState));
    }
  }, [dispatch, initialGeoState]);

  useEffect(() => {
    if (formPage && formPage === 'addressreduxform') {
      setLoadedData(true);
      forceUpdate();
      setFormInitialValues(sharedFormInitialValues);
      setSharedDetails({ formPage: '' });
    }
  }, [forceUpdate, formPage, setFormInitialValues, setSharedDetails, sharedFormInitialValues]);

  return (
    <AddressFrom
      {...props}
      initialValues={forminitialValues}
      countrieslist={countrieslist}
      countieslist={countieslist}
    />
  );
}

export default memo(AddressReduxForm);
