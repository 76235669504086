import React, { memo } from 'react';

function CreateBroadcastQuote() {
  return (
    <>
      <p>
        The Quote broadcast function is for below-threshold projects and products. Although you may
        not be required to post below-threshold projects, it may be useful to you to easily reach
        more suppliers for competitive quotes
      </p>
      <p>
        From Dashboard, in upper right corner, click on “Add Quote” or you can do the same thing
        after moving to the Quotes page:{' '}
      </p>
      img
      <p>
        “ADD QUOTE” is only used for under threshold opportunities that you do not want to make
        publicly available and searchable in DemandStar to all suppliers and vendors (vendors who
        you did not specifically send the quote will not be able to see the quote). You may upload
        documents for this function, but suppliers will NOT be responding by attaching documents,
        instead, they will fill in a form that you set up.
      </p>
      <ul className='list-unstyled'>
        <li>
          Step 1:
          <p>Fill in the requested information </p>
          img
        </li>
        <li>
          Step 2:
          <p>Fill in second page of required information. </p>
          img
        </li>
        <li>
          Step 3:
          <p>
            If you are uploading a document for the suppliers to read prior to them filling out
            their quote, fill in the required information for the document, choose the file, and
            “Upload”.{' '}
          </p>
          img
        </li>
        <li>
          Step 4:
          <p>
            Build your broadcast list by selecting the most appropriate commodity codes for your
            quote. Use the search to enter in a keyword that best applies to your quote and
            commodity codes will appear that match your keyword.
          </p>
          img
          <p>
            You will then see the suppliers that match the codes you entered. You may remove
            suppliers to whom you do not wish to send the quote solicitation.{' '}
          </p>
          img
        </li>
        <li>
          Step 5:
          <p>
            Include “Supplemental Suppliers” if you want to add specific companies that didn’t show
            up from the commodity code match. NOTE: when you enter their names, if the system finds
            ones that seem to match, you will be shown a list and prompted to choose from that list
            to avoid creating a new supplier account for the company.
          </p>
          img
        </li>
      </ul>
    </>
  );
}

export default memo(CreateBroadcastQuote);
