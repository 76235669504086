import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { loadingMsg } from '../../../utils/texts';
import { formatPrice } from '../../../utils/helpers';
import NoResult from '../../customcontrols/noresult';
import { LineItemType } from '../../../types/quotedetailstypedef';
import { sharedTypes } from '../../../types/shared';

const tdWidth = { width: '45%' };

interface PropsTypes {
  lineItems: LineItemType[];
  memberId: number;
  userpagefor: string;
  statusType: string;
  responseStatus: string;
  pageTitle?: string;
  awardShow: boolean;
  match?: any;
  shared: sharedTypes;
}
const LineItems = (props: PropsTypes) => {
  const {
    lineItems,
    memberId,
    userpagefor,
    statusType,
    responseStatus,
    pageTitle = 'Line Items',
    awardShow = false,
    shared,
  } = props;
  const { internalLoader = false } = shared;
  let lineItemslist = lineItems;
  if (userpagefor === 'suppliers') {
    lineItemslist = lineItems.map(item => {
      return {
        ...item,
        unitPrice: parseFloat(item.unitPrice),
        subtotal: parseFloat(item.unitPrice) * parseFloat(item.quantity),
        awardedSubTotal:
          item.awardedUnitPrice && item.awardedQty
            ? parseFloat(item.awardedUnitPrice) * parseFloat(item.awardedQty)
            : 0.0,
      };
    });
  }

  let TotalQuoteSum =
    (lineItemslist.length > 0 &&
      lineItemslist
        .map(item =>
          pageTitle !== 'Awarded Response'
            ? item.subtotal
              ? item.subtotal
              : 0
            : item.awardedSubTotal
            ? item.awardedSubTotal
            : 0,
        )
        .reduce((a, b) => a + b)) ||
    '';
  TotalQuoteSum = TotalQuoteSum > 0 ? formatPrice(TotalQuoteSum, 2) : '';

  return (
    <>
      <h4>
        {pageTitle} {pageTitle !== 'Awarded Response' ? `(${lineItems.length})` : null}
      </h4>
      {lineItemslist.length > 0 ? (
        <div className='innerColIndent'>
          <Table responsive className='tableHData'>
            <tr>
              <th style={tdWidth}>Description</th>
              <th>Quantity</th>
              <th className='text-right'>Price</th>
              {userpagefor === 'suppliers' ? <th className='text-right'>Sub Total</th> : null}
              {userpagefor === 'suppliers' ? (
                <th>{awardShow === true ? 'Awarded To' : 'Alternate Quote Value'}</th>
              ) : (
                <th>Award Status</th>
              )}
            </tr>
            {lineItems.map((item, index) => {
              const price =
                pageTitle !== 'Awarded Response' ? item.unitPrice : item.awardedUnitPrice;
              const quantity = pageTitle !== 'Awarded Response' ? item.quantity : item.awardedQty;
              const subtotal =
                pageTitle !== 'Awarded Response' ? item.subtotal : item.awardedSubTotal;
              return (
                <tr key={index}>
                  <td>{item.description}</td>
                  <td>
                    {quantity} {item.uom}
                  </td>
                  {userpagefor === 'suppliers' ? (
                    <>
                      <td className='text-right'>
                        {statusType ? (
                          <span className='text-danger'>{statusType}</span>
                        ) : (
                          `${
                            price
                              ? '$' + formatPrice(price, 3)
                              : responseStatus === 'RS' || responseStatus === 'PD'
                              ? 'Not Quoted'
                              : ''
                          }`
                        )}
                      </td>
                      <td className='text-right'>{`${
                        price
                          ? '$' + formatPrice(quantity * price, 2)
                          : responseStatus === 'RS' || responseStatus === 'PD'
                          ? 'Not Quoted'
                          : ''
                      }`}</td>
                      <td>{awardShow ? item.awardedToSupplier : item.altDescription}</td>
                    </>
                  ) : (
                    <>
                      <td className='text-right'>
                        {item.responseStatus === 'RS'
                          ? `$${item.unitPrice ? formatPrice(item.unitPrice, 4) : 'Not Quoted'}`
                          : null}
                      </td>
                      <td className='text-right'>{`${
                        item.unitPrice
                          ? '$' + formatPrice(item.quantity * item.unitPrice, 2)
                          : 'Not Quoted'
                      }`}</td>
                      <td>
                        {item.responseStatus === 'RS' ? (
                          !item.awardedToId ? null : item.awardedToId === memberId ? (
                            <i className='tag status ml-0'>Awarded</i>
                          ) : (
                            'Awarded to Another Supplier'
                          )
                        ) : null}
                      </td>
                    </>
                  )}
                  <td className='text-right'>
                    {userpagefor === 'suppliers'
                      ? `${subtotal ? '$' + formatPrice(subtotal, 4) : ''}`
                      : null}
                  </td>
                </tr>
              );
            })}
            {TotalQuoteSum ? (
              <tr>
                <td></td>
                <td></td>

                <td className='text-right'>
                  <strong>Total</strong>
                </td>
                <td className='text-right'>
                  <strong>${TotalQuoteSum}</strong>
                </td>
                <td></td>
              </tr>
            ) : (
              ''
            )}
          </Table>
          <small>Subtotals will be rounded up to the nearest cent.</small>
        </div>
      ) : (
        <NoResult message={internalLoader ? loadingMsg : 'No Items Available'} />
      )}
    </>
  );
};

export default memo(LineItems);
