import React, { memo } from 'react';
import { Link } from 'react-router-dom';

interface UpsellCardProps {
  message: JSX.Element;
  linkMessage: string;
  isLast?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

function UpsellCard(props: UpsellCardProps) {
  const { message, linkMessage, isLast, handleClick } = props;

  const wrapperClasses = ['dbCountWrapper', 'row'];

  if (!isLast) {
    wrapperClasses.push('mb-4');
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className='upsell-wrapper'>
        {message}
        <span
          onClick={handleClick}
          className='staticLink bttn-accent paddingBtnSpace d-block mx-auto'
        >
          {linkMessage}
        </span>
      </div>
    </div>
  );
}

export default memo(UpsellCard);
