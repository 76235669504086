import React, { memo } from 'react';
import { Buttons } from '../../../../customcontrols';
import { connect, ConnectedProps } from 'react-redux';
import { toastFn } from '../../../../../utils/helpers';
import Configurations from '../../../../../settings';
import NavHistory from '../../../../../utils/history';
import { Link } from 'react-router-dom';

interface PropsTypes extends PropsFromRedux {
  setAccountInfoDetails: (payload: { recoveryPopup?: boolean }) => void | undefined;
  submitLimitedForgotPassword?: any;
  accountinfo: {
    otp: string;
    selectedUserEmail: string;
  };
  history?: any;
  forgotPassword?: any;
}

function RecoverPassword(props: PropsTypes) {
  const {
    setAccountInfoDetails,
    submitLimitedForgotPassword,
    forgotPassword,
    accountinfo,
    history,
  } = props;
  const { otp = '117517', selectedUserEmail = '' } = accountinfo || {};

  const handleCancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setAccountInfoDetails({ recoveryPopup: false });
  };

  /* const copytoClipBoard = () => {
    navigator.clipboard.writeText(otp);
    toastFn('info', 'Copied', 'CLIPBOARD_COPY');
  };
  const copyURLtoClipBoard = () => {
    navigator.clipboard.writeText(`${Configurations.REACT_APP_PASSWORD_RESET_URL}/${otp}`);
    toastFn('info', 'Copied', 'CLIPBOARD_COPY');
  };
 */
  return (
    <>
      <p className='mb-2'> This will reset the user's password.</p>
      <p>
        Do you want to change the password for them, or email them a link to reset it themselves?
      </p>
      <div className='d-flex justify-content-between mt-3'>
        <Buttons
          text='Cancel'
          classNames='bttn bttn-secondary float-left'
          onClick={handleCancelClick}
        />
        <Buttons
          text='Change password for them'
          classNames='bttn bttn-primary float-left'
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            forgotPassword({ username: selectedUserEmail, resetBy: 'ops' });
          }}
        />
        <Buttons
          classNames='bttn-primary float-right'
          text='Email password reset link'
          type='button'
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            setAccountInfoDetails({ recoveryPopup: false });
            submitLimitedForgotPassword({ username: selectedUserEmail, resetBy: 'ops' });
          }}
        />
      </div>
    </>
  );
}

const connector = connect(state => state);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(RecoverPassword));
