import React from 'react';

import { buildRouteLink } from '../../../../utils/route';
import { Cell } from 'react-table';
import { NoOpFunction } from '../../../../types/shared';
import { RouteLink } from '../../controls/text/links/RouteLink';

/**
 * This type assumes a URL will be constructed as follows:
 * `${baseUrl}/${id}/${subUrl}?{queryString}`
 *   e.g. "suppliers/bids/6523465/details?actionId=87665"
 */
export type LinkTextCellProps<IdType> = {
  baseUrl?: string;
  subUrl?: string;
  idField: string;
  labelField: string;
  handleClick?: NoOpFunction; // Optional additional click-handler
  queryField?: string;
  renderLabelPrefix?: NoOpFunction; // Optional non-link label text
};

/**
 * This cell renders a `react-roouter-dom` `<Link>` element
 * with the `to` (href) constructed from the baseUrl and id
 * and the text and title from the label.
 * The curried function allows custom params to be sent along with
 * the field values from `react-table`.
 * @param initialProps
 */
export function LinkTextCell<IdType = number | string>(initialProps: LinkTextCellProps<IdType>) {
  // `props` are typed as `any` from `react-table`
  return (props: Cell<any>) => {
    const {
      baseUrl = '',
      subUrl = '',
      handleClick,
      idField,
      labelField,
      queryField,
      renderLabelPrefix,
    } = initialProps;
    const { row } = props;
    const { original } = row;
    const idValue = original[idField];

    const queryString = queryField && `${queryField}=${original[queryField || '']}`;
    const route = buildRouteLink({ baseUrl, id: idValue, subUrl, queryString });

    const labelPrefix = renderLabelPrefix ? renderLabelPrefix(original) : '';
    const label = original[labelField] || '';
    const labelDisplay = labelPrefix + label;

    /**
     * Optional click handler for functionality beyond routing,
     * e.g. setting app state.
     * Passes the `original` row data object back to the callback.
     */
    const handleLinkClick = () => {
      if (handleClick) {
        handleClick(original);
      }
    };

    return (
      <RouteLink
        to={`${route}`}
        onClick={handleLinkClick}
        title={`${labelDisplay}`}
      >{`${labelDisplay}`}</RouteLink>
    );
  };
}
