import React, { Component } from 'react';

export interface DropdownItem {
  // I know this is not especially useful in its current form, but it's a start. - DC
  title?: string;
  selected?: boolean;
  id?: any;
  key?: any;
  value?: any;
  [key: string]: any;
}

interface DropdownProps {
  title?: string;
  listOpen?: any;
  onChange?: any;
  isMultiSelect?: boolean;
  resetThenSet?: any;
  list: DropdownItem[];
  type?: any;
  classNames?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  isAutoPopulate?: boolean;
  input?: any;
  reduxform?: any;
}

interface State {
  listOpen: boolean;
  headerTitle: string;
  inputRef: any;
}

class Dropdown extends Component<DropdownProps, State> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: this.props.title,
    } as any;
    this.inputRef = React.createRef() as any;
    this.close = this.close.bind(this);
  }

  componentDidUpdate() {
    const { listOpen } = this.state;
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener('click', this.close);
      } else {
        window.removeEventListener('click', this.close);
      }
    }, 0);
  }

  static getDerivedStateFromProps(nextProps: any) {
    const nextPropsList = nextProps.list.filter((a: any) => {
      return a.selected;
    });
    const count = nextPropsList.length;
    if (count === 0) {
      return { headerTitle: nextProps.isAutoPopulate ? '' : nextProps.title };
    } else if (count === 1) {
      return { headerTitle: `${nextPropsList[0].title}` };
    } else if (count > 1) {
      return { headerTitle: `${count} ${nextProps.titleHelper}` };
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
  }

  close(timeOut: any) {
    this.clearInputOnClose();
    this.setState({
      listOpen: false,
    });
  }

  toggleList() {
    this.clearInputOnClose();
    this.setState(prevState => ({
      listOpen: !prevState.listOpen,
    }));
  }

  clearInputOnClose() {
    const { onChange } = this.props;
    if (
      onChange &&
      this.inputRef.current &&
      this.inputRef.current.value &&
      this.inputRef.current.value.length > 0
    ) {
      onChange(this.inputRef.current.name, '');
    }
  }

  onInputChange = (event: any) => {
    this.props.onChange(event.target.name, event.target.value);
  };

  selectItem(id: any, stateKey: any, item: any) {
    if (!this.props.isMultiSelect) {
      this.setState(
        {
          listOpen: false,
        },
        this.props.resetThenSet(id, stateKey, item),
      );
    } else {
      this.props.resetThenSet(id, stateKey, item);
    }
  }

  render() {
    const {
      list,
      // onChange,
      type,
      classNames,
      name,
      placeholder,
      value,
      // isMultiSelect,
      isAutoPopulate,
      // isIncludeTitle,
      // reduxform,
      input,
      // children
    } = this.props;
    // const firstItem = list[0]
    const { listOpen, headerTitle } = this.state;

    return (
      <div className='dd-wrapper' onClick={() => this.toggleList()}>
        <>
          <div className='dd-header'>
            {/* {!isAutoPopulate && listOpen
            ? <FontAwesome name="angle-up" size="2x" />
            : <FontAwesome name="angle-down" size="2x" />
          } */}
            {isAutoPopulate ? (
              !listOpen ? (
                <input
                  type={type}
                  className={classNames}
                  name={name}
                  placeholder={placeholder}
                  value={value || headerTitle}
                  readOnly={true}
                />
              ) : (
                <input
                  ref={this.inputRef}
                  type={type}
                  className={classNames}
                  name={name}
                  placeholder={placeholder}
                  value={value}
                  onChange={this.onInputChange}
                />
              )
            ) : (
              <>
                <div className='dd-header-title'>{headerTitle}</div>
                {listOpen ? (
                  <span aria-hidden='true' className='fa fa-angle-up fa-2x' />
                ) : (
                  <span aria-hidden='true' className='fa fa-angle-down fa-2x' />
                )}
              </>
            )}
          </div>
          {listOpen && (
            <ul className='dd-list list-unstyled' onClick={e => e.stopPropagation()}>
              {list.map(item => (
                <li
                  className={`dd-list-item ${item.selected ? 'selected' : ''}`}
                  key={item.id}
                  onClick={() => this.selectItem(item.id, item.key, item)}
                >
                  {item.selected && <span aria-hidden='true' className='mdi mdi-check-bold' />}
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </>
      </div>
    );
  }
}

export default Dropdown;
