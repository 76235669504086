import React, { ReactNode, useState } from 'react';
import { DSRadio } from '@demandstar/components/radio/DSRadio';
import styled from 'styled-components';

import {
  AwardWizardId,
  bidAwardeeListState,
  notifyColleaguesState,
} from '../../../../store/recoil/award-bid';
import { BoldText, Padding } from '../../../../shared/styles';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { AwardWizardComponentProps } from '../AwardBid';
import { finalizeAwardTexts } from '../../../../utils/texts/agency/awardBid';
import { hasValue } from '../../../../utils/helpers';
import { useAwardBidWizard } from '../../../../shared/hooks';
import { useBid } from 'src/shared/hooks/useBid';
import { WizardButtons } from '../../../customcontrols/wizard/WizardButtons';

enum FinalizeOption {
  None,
  RecommendationOfAward,
  AwardBid,
}

type FinalizeAwardProps = Pick<AwardWizardComponentProps, 'completeWizard'>;

const LeftColumn = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: text-top;
  padding-right: ${Padding.extra};
`;

const RightColumn = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: text-top;
  padding-left: ${Padding.extra};
`;

export const FinalizeAward = (props: FinalizeAwardProps): JSX.Element => {
  // Hooks
  const { saveAwardBidWizard, returnToBidSummary, submitting, recommendAward } =
    useAwardBidWizard();
  const { bid } = useBid();

  // Recoil
  const emailInfo = useRecoilValueLoadable(notifyColleaguesState);
  const hasColleagues = !!(hasValue(emailInfo) && emailInfo.contents.recipients.length);
  const bidName = bid?.bidName;
  const bidNumber = bid?.bidNumber;
  const awardees = useRecoilValue(bidAwardeeListState);
  const pluralAwardees = awardees.length > 1;

  // Props
  const { completeWizard } = props;

  // Local State
  const [finalizeOption, setFinalizeOption] = useState<FinalizeOption>(FinalizeOption.None);

  function onPrevious() {
    if (hasColleagues) {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues, true);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues, false);
    }
  }

  function onNext() {
    if (finalizeOption === FinalizeOption.AwardBid) {
      completeWizard();
    } else {
      recommendAward();
    }
  }

  return (
    <div>
      <div>
        <LeftColumn>
          <p>
            {finalizeAwardTexts.recommendationCopy[0](pluralAwardees)}{' '}
            <BoldText>{finalizeAwardTexts.recommendationOfAward}</BoldText>{' '}
            {finalizeAwardTexts.recommendationCopy[1](pluralAwardees)}
          </p>
          <p>{finalizeAwardTexts.recommendationCopy[2](pluralAwardees)}</p>
          <p>
            <BoldText>{finalizeAwardTexts.recommendationOfAward}</BoldText>{' '}
            {finalizeAwardTexts.recommendationCopy[3](pluralAwardees)}
          </p>
        </LeftColumn>
        <RightColumn>
          <p>
            {finalizeAwardTexts.awardBidCopy[0](pluralAwardees)}{' '}
            <BoldText>{finalizeAwardTexts.awarded}</BoldText>{' '}
            {finalizeAwardTexts.awardBidCopy[1](pluralAwardees)}
          </p>
          <p>{finalizeAwardTexts.awardBidCopy[2](pluralAwardees)}</p>
        </RightColumn>
      </div>
      <div>
        <LeftColumn>
          <DSRadio
            name='recommendAward'
            checked={finalizeOption === FinalizeOption.RecommendationOfAward}
            onSelect={() => setFinalizeOption(FinalizeOption.RecommendationOfAward)}
            label={finalizeAwardTexts.recommendationOfAward}
          />
        </LeftColumn>
        <RightColumn>
          <DSRadio
            name='awardBid'
            checked={finalizeOption === FinalizeOption.AwardBid}
            onSelect={() => setFinalizeOption(FinalizeOption.AwardBid)}
            label={`Award ${bidNumber}: ${bidName}`}
          />
        </RightColumn>
      </div>
      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={
          finalizeOption === FinalizeOption.RecommendationOfAward ? 'Recommend Award' : 'Award Bid'
        }
        disableNext={submitting || finalizeOption === FinalizeOption.None}
        disablePrev={submitting}
        save={returnToBidSummary}
      ></WizardButtons>
    </div>
  );
};
