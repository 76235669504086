import { Cell } from 'react-table';
import React from 'react';
import styled from 'styled-components';

import { displayDate } from '../../../../utils/helpers';

const Wrapper = styled.div`
  padding-top: 0.25rem;
`;

export interface DateCellProps<T> {
  dateField: keyof T;
}

/**
 * This cell renders a formatted date for the given value.
 * @param value
 */
export function DateCell<T>(initialProps: DateCellProps<T>) {
  return (props: Cell<any>) => {
    const { dateField } = initialProps;
    const { row } = props;
    const { original } = row;
    const dateValue = original[dateField] || '';
    const formattedDate = displayDate(dateValue);
    return <Wrapper>{formattedDate}</Wrapper>;
  };
}
