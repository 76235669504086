import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function getEbitsFileFormats(payload: actionPayloadTypes) {
  return action(actionTypes.GET_EBIDS_FILE_FORMATS, payload);
}

export function getEbitsResponseDetails(payload: actionPayloadTypes) {
  return action(actionTypes.GET_EBIDS_RESPONSE_DETAILS, payload);
}

export function setEbidDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_EBIDS_DETAILS, payload);
}

export function setEbitPage(payload: actionPayloadTypes) {
  return action(actionTypes.CHANGE_EBID_PAGE, payload);
}

export function setRequiredDocs(payload: actionPayloadTypes) {
  return action(actionTypes.SET_REQUIRED_DOCS, payload);
}

export function submitEbidResponseDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_EBIDS_RESPONSE_DETAILS, payload);
}

export function submitEbitsFiles(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_EBIDS_FILES_DOCS, payload);
}

interface GetBidsEBidResponsePayload {
  bidId: number;
  memberId: number;
}

export function getbidsEbidResponse(payload: GetBidsEBidResponsePayload) {
  return action(actionTypes.GET_EBID_RESPONSE, payload);
}

export function setBidsResponseData(payload: actionPayloadTypes) {
  return action(actionTypes.SET_EBID_SUMMARY, payload);
}

export function setSelectedBidsResponseData(payload: actionPayloadTypes) {
  return action(actionTypes.SET_SELECTED_EBID_SUMMARY, payload);
}

export function resetEBids(payload?: actionPayloadTypes) {
  return action(actionTypes.RESET_EBIDS, payload);
}
