import DropIn from 'braintree-web-drop-in-react';
import React, { memo, useEffect, useState } from 'react';
import { appKeys } from '../../../../utils/constants';
import AddressReduxForm from '../../../common/addressreduxform';
import Configurations from '../../../../settings';

interface PropsTypes {
  handleCancelClick?: any;
  getAcoountinfoMemberdetails?: any;
  setAccountInfoDetails?: any;
  updateMemberBillingInfo?: any;
  accountinfo?: {
    membersinfo?: { website: string };
    paymentvals?: {
      activeTab: string;
      isEditBilling: boolean;
      isPrintReceipt: boolean;
      membersinfo: { website: string };
      paymentInfo: Record<string, unknown>;
    };
  };
  addUpdateBTProfile?: any;
  cancelEditPayment?: any;
  UpdateCardDetails?: any;
}

function EditBillingInfo(props: PropsTypes) {
  const clientToken = Configurations && Configurations.REACT_APP_BTKey;
  const [instance, setInstance] = useState('' as any);
  const {
    // resetAccountInfoState,
    handleCancelClick,
    getAcoountinfoMemberdetails,
    // updateMemberBillingInfoResult,
    setAccountInfoDetails,
    updateMemberBillingInfo,
    accountinfo,
    addUpdateBTProfile,
  } = props;
  const { membersinfo } = accountinfo || {};
  // Redux form fn and check validation and go to next page fn
  const onsubmit = async (formData = {}) => {
    if (instance && instance.isPaymentMethodRequestable()) {
      const token = await instance.requestPaymentMethod();
      if (token && token.nonce) {
        addUpdateBTProfile({ nonce: token.nonce });
      }
    }
    updateMemberBillingInfo({ formData });
    setAccountInfoDetails({ reloadBillingInfo: true });
  };

  useEffect(() => {
    getAcoountinfoMemberdetails({ pagefor: 'billing' });
  }, [getAcoountinfoMemberdetails]);

  const dropInOptions = {
    translations: {
      chooseAWayToPay: 'Add a payment method',
    },
    authorization: appKeys.BTkey,
    card: {
      cardholderName: {
        required: true,
      },
    },
  } as any;

  const { website, ...initialValues } = membersinfo || {};

  return (
    <>
      <h3 className='arrowWrapper'>Edit Billing Info</h3>
      <div className='row'>
        <div className='col-5'>
          <h4 className='mt-2'>Card Information</h4>
          {clientToken ? (
            <div className='braintreeWrapper'>
              <DropIn options={dropInOptions} onInstance={instance => setInstance(instance)} />
            </div>
          ) : (
            <div>
              <h1>Loading...</h1>
            </div>
          )}
          <span className='clearfix' />
          <h4 className='my-5'>Billing Address</h4>
          <AddressReduxForm
            {...props}
            initialValues={initialValues}
            onSubmit={onsubmit}
            pagevaluespagefor='actioninfo-payments'
            pagevaluescancel={handleCancelClick} // memoized with useCallback
            pagevaluescancelname='Cancel'
            pagevaluessavename='Save'
            pagevaluespage='Payments'
            pagevaluestitle='Payments'
            pagevaluesaltname='Payments'
            pagevaluesname='Payments'
            pagevaluesmenuactive='bids'
          />
        </div>
      </div>
    </>
  );
}

export default memo(EditBillingInfo);
