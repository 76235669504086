import React from 'react';

interface BasicCheckboxProps {
  checked?: boolean;
  classNames?: string;
  dataTestId?: string;
  disabled?: boolean | string;
  disabledText?: string;
  fieldRef?: any; // optional pass-through ref for `react-hook-form`
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  indeterminate?: boolean;
  label?: string | JSX.Element;
  name?: string;
  parentClass?: string;
  title?: string;
  value?: string | boolean;
}

// per spec value should be string, but we use boolean
// all over the place and rely on react or the browser
// to convert it for us. Let's abstract that away for
// now and eventually remove this when we can fix the
// many erroneous instances
function convertToString(val: any): string {
  if (typeof val === 'string') return val;
  if (val === true) return 'true';
  if (val === false) return 'false';
  return '';
}

// similar issue here--should be boolean
export function convertToBoolean(val: string | boolean | undefined): boolean {
  if (typeof val === 'boolean') return val;
  if (val === 'true') return true;
  return false;
}

function BasicCheckbox(props: BasicCheckboxProps) {
  const {
    checked,
    classNames,
    dataTestId,
    disabled,
    disabledText,
    fieldRef,
    handleChange,
    id,
    indeterminate = false,
    label,
    name,
    parentClass,
    title,
    value,
  } = props;

  return (
    <label
      htmlFor={id}
      aria-labelledby={id}
      className={'checkControl ' + (parentClass ? parentClass : '')}
    >
      <span className='title'>{title}</span>
      <input
        type='checkbox'
        onChange={handleChange}
        className={classNames}
        id={id}
        key={id}
        value={convertToString(value)}
        name={name}
        checked={checked}
        ref={fieldRef ? fieldRef : element => element && (element.indeterminate = indeterminate)}
        disabled={convertToBoolean(disabled)}
        data-testid={dataTestId}
      />
      <span className='checkmark' title={disabled ? disabledText : ''} />
      {indeterminate && <span className='indeterminate' />}
      {label}
    </label>
  );
}

export default BasicCheckbox;
