import { track } from './telemetry';

interface TelemetryAction {
  meta: Record<string, unknown>;
  payload: Record<string, unknown>;
  type: string;
}

function hasTelemetryKey(action: TelemetryAction): boolean {
  return Object.keys(action).lastIndexOf('meta') > -1;
}

function canLogTelemetry(action: TelemetryAction) {
  if (action.type) {
    const matches = /(.*)_(TRIGGER)$/.exec(action.type);
    const failure = /(.*)_(FAILURE)$/.exec(action.type);

    if (
      !matches &&
      !failure /* || (!failure && (noLoader || !action.payload || !action.payload.hasOwnProperty())) */
    )
      return false;
    return true;
  }
  return false;
}

export function telemetryMiddleware() {
  return (next: (action: TelemetryAction) => void) =>
    (action: TelemetryAction): void => {
      if (!action.type.includes('@@redux-form')) {
        if (hasTelemetryKey(action)) {
          track(action.type, action.meta);
        } else if (canLogTelemetry(action)) {
          track(action.type, action.payload);
        }
      }
      return next(action);
    };
}
