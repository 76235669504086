import React from 'react';

import { getBooleanText } from '../../../../utils/message';

export interface BooleanCellProps {
  booleanField: string;
}

/**
 * This cell renders a 'Yes' or 'No' string for the given value.
 * @param value
 */
export const BooleanTextCell = (initialProps: BooleanCellProps) => (props: any) => {
  //FIXME: Type any above.
  const { booleanField } = initialProps;
  const { row } = props;
  const { original } = row;
  const booleanValue = !!original[booleanField] || false;
  const formattedBoolean = getBooleanText(booleanValue);
  return <>{formattedBoolean}</>;
};
