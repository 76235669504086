import React from 'react';

import { BaseRadio } from './DSRadioStyles';
import { ChoiceLabel } from '../assets/ChoiceStyles';
import { InvisibleInput } from '../assets/CommonStyles';

type RadioValue = string | number;

// TODO: Make more accessible.
// TODO: Create DSRadioGroup to be used in react-hook-form forms.
// TODO: Create optional clear function to allow users to
//        click on the selected radio and clear it.

export interface DSRadioProps<Value> {
  checked: boolean;
  label: string;
  name: string;
  onSelect: (value?: Value) => void;
  // Optional
  deactivated?: boolean;
  id?: string;
  value?: Value;
}

/**
 * @prop {boolean} checked - the radio is selected
 * @prop {string} label - the description of the radio
 * @prop {(value?: Value) => void} onSelect - updates the value
 * @prop {boolean=} deactivated - prevents onSelect from firing
 */

export const DSRadio = (props: DSRadioProps<RadioValue>): JSX.Element => {
  const { checked, deactivated, id, name, onSelect, value, label } = props;

  function onClick() {
    if (!deactivated) {
      onSelect(value);
    }
  }

  return (
    <ChoiceLabel deactivated={deactivated}>
      {label}
      <InvisibleInput
        readOnly={true}
        type='radio'
        onClick={onClick}
        id={id}
        value={value}
        name={name}
        checked={checked}
      />
      <BaseRadio checked={checked} deactivated={deactivated} className='base-radio' />
    </ChoiceLabel>
  );
};
