import React, { memo } from 'react';

function SetUpPublication() {
  return (
    <>
      <p>
        Setting up your ongoing publications information in the new DemandStar is easy. To get
        started simply pull down the account management menu to “Publication Info.”{' '}
      </p>
      <p>
        This is where you will list the PUBLICATIONS you use to advertise, the types of
        SOLICITATIONS you will broadcast, and all the possible documents your agency might request
        suppliers to submit ELECTRONICALLY. Here are the steps for each
      </p>
      <h5>Publications Tab</h5>
      <p>
        set your document approval process and enter where else you may publish your solicitation
        notices.{' '}
      </p>
      <p>
        <b>
          <i className='mdi mdi-information-outline mdi-24px mr-1' />
          HOT TIP
        </b>
        <br />
        Choose whether your team can have documents approved automatically by moving the slide bar
        to the right (ON).{' '}
      </p>
      img
      <h5>Bid Types Tab</h5>
      <p>
        Now, simply set your Bid types so that they will show in the pull-down menu when you are
        entering your bid.
      </p>
      img
      <h5>EBidding Information </h5>
      <p>
        Simply enter the names of required documents for any bid for which requires an electronic
        response.{' '}
      </p>
      img
      <p>
        Enter the types of documents you might regularly ask a vendor to submit – either as one
        complete PDF file (less than 100MB) Or, as detailed list. If you use a detailed list,
        responding suppliers will upload each document individually pursuant to the list of
        documents you specified (see below example).
      </p>
      img<p>OR a full list of document types: </p>
      img
    </>
  );
}

export default memo(SetUpPublication);
