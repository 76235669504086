// TOREFACTOR
// - fix all wrongcased names, for example: getaccountinforequiredDocs

import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  AddBidDocTypeOption,
  BidDocument,
  DocStatus,
  DocTypeLabel,
  DocTypeValue,
} from '../../types/addbid';
import { AwardBidEventName, useAwardBidEvents } from '../../shared/hooks/amplitude/awardBid.events';
import {
  bidsSummary,
  getaccountinforequiredDocs,
  getAddBidBidsDocuments,
  getAddBidDocTypes,
  getAddBidIsPermissions,
  setAddBidDetails,
  submitAddBidDocumentUpload,
} from '../../store/actions';
import { Buttons, NoResult } from '../customcontrols';
import { generateKey, toastFn } from '../../utils/helpers';
import AcceptedFileFormats from '../common/acceptedfileformats';
import { ApproveDocument } from '../common/createbid/modals/approvedocument';
import { BidExternalStatusType } from '../../utils/constants';
import { BidSummaryResult } from '../../types/bidssummary';
import { ConfirmModal } from './modals/ConfirmModal';
import { DeleteDocumentModal } from './createbid/modals/DeleteDocumentModal';
import { documentLabels } from '../../shared/constants';
import DocumentsOptions from '../common/createbid/modals/documentsoptions';
import { DocumentUploadTableRow } from './DocumentUploadTableRow';
import { ModalPopUp } from './modals/ModalPopUp';
import { TimeZone } from '../../utils/constants';
import { useDSSelector } from '../../store/reducers';

interface DocumentUploadTableProps {
  bidId: number;
  addLabel?: string;
}

// TODO: Worth looking at how we want to do tables site-wide.
const divStyle = {
  td1: { width: '25%' },
  td2: {
    width: '20%',
  },
  td3: {
    width: '15%',
  },
  td4: {
    width: '6%',
  },
  td5: {
    width: '40px',
  },
};

export const DocumentUploadTable = (props: DocumentUploadTableProps) => {
  const dispatch = useDispatch();
  const { logEvent } = useAwardBidEvents();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { bidId, addLabel } = props;

  const addMoreDocsButtonLabel = addLabel || documentLabels.addMoreDocuments;

  const [showErrors, setShowErrors] = useState(false);

  // DOCUMENTS
  const documents = useDSSelector(state => state.addbid.documentslist || []);
  const uploadableDocs = documents.filter(doc => !doc.bidDocId) || [];
  // this adds bidDocuments to the state for us to manipulate.
  useEffect(() => {
    if (bidId > 0) {
      dispatch(getAddBidBidsDocuments({ bidId }));
    }
  }, [dispatch, bidId]);

  // PERMISSIONS
  const canAdd = useDSSelector(state => state.addbid.permissions.canAdd);
  const isMainContact = useDSSelector(state => state.auth.mc);
  // this useEffect gets our permissions
  useEffect(() => {
    if (bidId > 0) {
      dispatch(getAddBidIsPermissions({ bidId }));
    }
  }, [dispatch, bidId]);

  // DOC TYPES
  const doctypes = useDSSelector(state => state.addbid.doctypes);
  // this gets our doctypes if we don't have them already
  useEffect(() => {
    if (!doctypes || !doctypes.length) {
      dispatch(getAddBidDocTypes({ bidId }));
    }
  }, [bidId, dispatch, doctypes]);

  // UPLOAD PROGRESS
  const uploadProgress = useDSSelector(state => state.addbid.upload_progress);

  const documentUploadToastId = '5c972335-a2d5-4380-880e-ab29135712db';

  const bidExternalStatusType = useDSSelector(
    state =>
      (state.bidssummary.results as BidSummaryResult).bidExternalStatusType ||
      BidExternalStatusType.None,
  );

  /** This updates the state with account information.
   * Specifically, it gets us DocAllowedExt, convertableTypes [sic]
   * It might be better to do this once when the app first starts up,
   * but I'm following precedent for now. */

  useEffect(() => {
    // TODO: Replace this with Recoil state. (src/store/recoil/memberState.ts)
    dispatch(getaccountinforequiredDocs());
  }, [dispatch]);

  useEffect(() => {
    /** gets our bidSummary results,
     * which we need in order to accurately represent our progress.*/
    if (bidId > 0) {
      dispatch(bidsSummary({ bidId, otherapis: true }));
    }
  }, [bidId, dispatch]);

  function addNewDocumentRow() {
    const list = documents
      .filter(doc => !doc.bidDocId)
      .filter(doc => !doc.docfile || !doc.doctitle.trim() || !doc.doctype);
    if (list.length > 0) {
      setShowErrors(true);
      toastFn('error', 'Please Complete Previous', documentUploadToastId);
    } else {
      setShowErrors(false);
      const newDocList = [...documents];
      newDocList.push({
        bidDocId: '',
        bidId: '',
        docconversion: false,
        docext: '',
        docfile: '',
        doctitle: '',
        doctype: '',
        docType: DocTypeValue.None,
        id: generateKey('doc'),
        isExist: false,
        modifiedDate: dayjs().tz(TimeZone.Eastern).format(),
        progress: 0,
        statusType: DocStatus.None,
        uploadprocess: 'notstarted',
      });
      dispatch(setAddBidDetails({ documentslist: newDocList, halfWayCancel: true }));
    }
  }

  //// Save and Upload All Pending Documents
  function uploadDocuments() {
    if (uploadableDocs.length > 0) {
      const checkValidation = uploadableDocs.filter(
        doc => !doc.doctitle.trim() || !doc.docfile || !doc.doctype,
      );
      if (checkValidation.length === 0) {
        const addendums = uploadableDocs.filter(doc => doc.docType === DocTypeValue.Addendum);
        const awardDocCount = uploadableDocs.filter(
          doc => doc.docType === DocTypeValue.AwardDocument,
        ).length;
        dispatch(setAddBidDetails({ newDocAdded: true }));

        if (addendums.length > 0 && bidExternalStatusType === BidExternalStatusType.Active) {
          toggleDocumentOptionModal();
        } else {
          if (awardDocCount) {
            logUploadAmplitude(awardDocCount);
          }
          dispatch(submitAddBidDocumentUpload({ bidId }));
        }
        setShowErrors(false);
      } else {
        setShowErrors(true);
        toastFn('error', 'Please fill all required fields', documentUploadToastId);
      }
    }
  }

  function logUploadAmplitude(awardDocCount: number) {
    logEvent({
      eventName: AwardBidEventName.UploadPublicDocs,
      eventProps: {
        'Award Documents Uploaded': awardDocCount,
      },
    });
  }

  // MODALS
  const approvedocumentmodal = useDSSelector(state => state.addbid.approvedocumentmodal || false);
  const deletedocumentmodal = useDSSelector(state => state.addbid.deletedocumentmodal || false);
  const documentOptionsModal = useDSSelector(state => state.addbid.documentsoptionsmodal);
  const [fileTypeModalVisible, setFileTypeModalVisible] = useState(false);

  //// Approve
  function toggleApproveDocModal(doc?: BidDocument) {
    dispatch(
      setAddBidDetails({
        approvedocumentmodal: !approvedocumentmodal,
        approvedocumentdetails: doc,
      }),
    );
  }

  //// Delete
  const [nonDeleteAlert, setNonDeleteAlert] = useState(false);
  const [nonDeleteDocumentType, setNonDeleteDocumentType] = useState<DocTypeLabel>('');

  function toggleDeleteDocModal(doc?: BidDocument) {
    if (!doc) {
      return dispatch(
        setAddBidDetails({
          deletedocumentmodal: false,
          deleteDocumentDetails: undefined,
        }),
      );
    }
    const docType: DocTypeValue = doc
      ? doc.docType || (doc.doctype as AddBidDocTypeOption).value
      : DocTypeValue.None;
    const existType = doctypes?.filter(type => type.value === docType) || [];
    if (existType.length === 0) {
      setNonDeleteAlert(true);
      setNonDeleteDocumentType(doc ? doc.type || (doc.doctype as AddBidDocTypeOption).label : '');
    } else {
      dispatch(
        setAddBidDetails({
          deletedocumentmodal: !deletedocumentmodal,
          deleteDocumentDetails: doc,
        }),
      );
    }
  }

  //// Document Options
  function toggleDocumentOptionModal() {
    dispatch(
      setAddBidDetails({
        documentsoptionsmodal: !documentOptionsModal,
      }),
    );
  }

  function toggleNonDeleteAlert() {
    setNonDeleteAlert(!nonDeleteAlert);
  }

  return (
    <>
      <Table className='tableHData'>
        <thead>
          <tr data-testid='addbid.documentupload.documents.list.head'>
            <th style={divStyle.td1}>Title</th>
            <th style={divStyle.td2}>Type</th>
            <th style={divStyle.td3}>Status</th>
            <th style={divStyle.td4}>Convert to PDF</th>
            <th style={divStyle.td5}></th>
          </tr>
        </thead>
        <tbody data-testid='addbid.documentupload.documents.list.body'>
          {documents.length > 0 ? (
            <>
              {documents.map((doc, index) => (
                <DocumentUploadTableRow
                  key={index}
                  doc={doc}
                  index={index}
                  bidId={bidId}
                  setNonDeleteAlert={setNonDeleteAlert}
                  setNonDeleteDocumentType={setNonDeleteDocumentType}
                  setFileTypeModalVisible={setFileTypeModalVisible}
                  documentUploadToastId={documentUploadToastId}
                  showErrors={showErrors}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={5}>
                <NoResult message={'No Documents Available'} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className='clearfix mb-3'>
        {canAdd || isMainContact ? (
          <Buttons
            classNames='bttn-secondary small float-right ml-3'
            text={addMoreDocsButtonLabel}
            title={addMoreDocsButtonLabel}
            type='button'
            onClick={addNewDocumentRow}
            disable={uploadProgress}
          />
        ) : null}
      </div>
      <div className='clearfix mb-3'>
        {canAdd || isMainContact ? (
          <Buttons
            classNames='bttn-primary float-right'
            text={uploadableDocs.length > 1 ? 'Save and Upload All' : 'Save and Upload'}
            type='button'
            onClick={uploadDocuments}
            disable={uploadProgress || uploadableDocs.length === 0}
          />
        ) : null}
      </div>
      {/* TODO: Replace both of these with regular ConfirmModal dialog boxes. */}
      <ModalPopUp
        size='md'
        title='Confirmation'
        closeModal={() => toggleApproveDocModal()}
        isOpen={approvedocumentmodal}
      >
        <ApproveDocument bidId={bidId} SetApproveDocument={toggleApproveDocModal} />
      </ModalPopUp>
      <DeleteDocumentModal
        closeModal={() => toggleDeleteDocModal()}
        /** Do not replace with `closeModal={toggleDeleteDocModal}`.
         * The function wants to pass in nothing instead of a ClickEvent*/
        isOpen={deletedocumentmodal}
        bidId={bidId}
        type='bid'
      />
      <ModalPopUp
        title='Accepted file formats'
        size='xl'
        isOpen={fileTypeModalVisible}
        closeModal={() => setFileTypeModalVisible(false)}
      >
        <AcceptedFileFormats closePopup={() => setFileTypeModalVisible(false)} />
      </ModalPopUp>
      <ConfirmModal
        confirmMessage={`To avoid confusing suppliers, ${nonDeleteDocumentType} documents cannot be deleted once your bid has been broadcast. If you need to make updates to this document, please upload an Addendum. This will trigger a notification to suppliers that a new document has been uploaded.`}
        approveAction={toggleNonDeleteAlert}
        onlyApprove={true}
        title='Alert Message'
        closeModal={toggleNonDeleteAlert}
        isOpen={nonDeleteAlert}
      />
      <ModalPopUp
        size='md'
        title={'Change Due Date'}
        closeModal={toggleDocumentOptionModal}
        isOpen={documentOptionsModal}
        backdrop='static'
      >
        <DocumentsOptions bidId={bidId} />
      </ModalPopUp>
    </>
  );
};
