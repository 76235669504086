import { Modal, ModalBody } from 'reactstrap';
import React, { PropsWithChildren } from 'react';

type ModalSize = 'md' | 'lg' | 'xl' | 'xxl';

export type ModalPopUpProps = PropsWithChildren<{
  closeModal: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | (() => void) | false;
  isOpen: boolean;
  className?: 'selectBroadcast' | 'popUpSpacing';
  // Refrain from adding className variants to our modals.
  size?: ModalSize;
  title: string;
  backdrop?: boolean | 'static';
}>;

/**
 * @prop {boolean | 'static'}  backdrop - Includes a modal-backdrop element.
 *                              Alternatively, specify static for a backdrop
 *                              which doesn't close the modal on click.
 * @prop {string}              className - to be deprecated
 * @prop {function}            closeModal - function that closes the modal.
 * @prop {boolean}             isOpen - whether or not the modal is open.
 * @prop {ModalSize='lg'}      size - the size of the modal.
 * @prop {string}              title - title text of the modal.
 * */

export const ModalPopUp = (props: ModalPopUpProps) => {
  const {
    closeModal,
    isOpen = false,
    className = '',
    size = 'lg',
    title = '',
    children,
    backdrop = true,
  } = props;

  const toggleModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (closeModal) {
      closeModal(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className={className}
      size={size}
      backdrop={backdrop}
    >
      <div className='modal-header'>
        <h3 className='modal-title mr-5'>{title}</h3>
        {closeModal ? (
          <button
            title='Close'
            onClick={toggleModal}
            type='button'
            className='close'
            aria-label='Close'
          >
            <span aria-hidden='true'>×</span>
          </button>
        ) : (
          ''
        )}
      </div>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};
