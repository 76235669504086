import { createActionTypes, createNoLoadActionTypes } from './utils';

export const SET_BROADCAST_LIST_DETAILS = createNoLoadActionTypes('SET_BROADCAST_LIST_DETAILS');
export const SUBMIT_BROADCAST_LIST_ADD_LIST = createActionTypes('SUBMIT_BROADCAST_LIST_ADD_LIST');
export const GET_BROADCAST_LIST_SAVED_LISTS = createActionTypes('GET_BROADCAST_LIST_SAVED_LISTS');
export const GET_BROADCAST_LIST_BROADCAST_MEMBERS = createActionTypes(
  'GET_BROADCAST_LIST_BROADCAST_MEMBERS',
);
export const GET_BROADCAST_LIST_SEARCH_VENDORS = createActionTypes(
  'GET_BROADCAST_LIST_SEARCH_VENDORS',
);
export const SUBMIT_BROADCAST_LIST_DELETE_LIST = createActionTypes(
  'SUBMIT_BROADCAST_LIST_DELETE_LIST',
);
