import React, { memo, useCallback } from 'react';
import { Table } from 'reactstrap';
import { Buttons, NoResult } from '../../customcontrols';
import { RegistrationWizardButtons } from './RegistrationWizardButtons';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragDropCard from '../../customcontrols/dragdropcard';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import EbidDocumentPopup from '../../buyer/account/publications/ebiddocumentpopup';
import { WizardPage } from '../../../types/wizard';
import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import { useDispatch } from 'react-redux';

const divStyle = {
  td1: {
    width: '25%',
  },
  td2: {
    width: '50%',
  },
};

interface EBidDocumentsProps {
  setAgencyRegistrationDetails?: any;
  agencyAddEbidDocuments?: any;
  agencyregistration?: agencyRegistrationParamType;
  currentwizard?: WizardPage;
  match?: any;
}

function EBidDocuments(props: EBidDocumentsProps) {
  const { setAgencyRegistrationDetails, agencyregistration } = props;

  const { agencyEbidDocuments = [], addDocumentModal = false, ebiddocumentPopupdata } =
    agencyregistration || {};

  const setEditEbidModal = useCallback(
    (item, actionfor = '') => {
      const finalvalue = item ? item : '';
      if (actionfor === 'delete') {
        const nondeletedoc = agencyEbidDocuments
          .filter(items => Number(items.sortOrder) !== Number(finalvalue.sortOrder))
          .map((items, index) => {
            items.sortOrder = index + 1;
            return items;
          });
        setAgencyRegistrationDetails({ agencyEbidDocuments: nondeletedoc });
      } else {
        setAgencyRegistrationDetails({ addDocumentModal: true, ebiddocumentPopupdata: finalvalue });
      }
    },
    [agencyEbidDocuments, setAgencyRegistrationDetails],
  );

  const submitaccountinforequiredDocsUpdate = (data: any) => {
    let newrequiredDocuments = [];
    if (data.sortOrder) {
      newrequiredDocuments = agencyEbidDocuments.map(item => {
        if (Number(item.sortOrder) === Number(data.sortOrder)) {
          item.description = data.description;
        }
        return item;
      });
    } else {
      newrequiredDocuments = [
        ...agencyEbidDocuments,
        ...[{ description: data.description, sortOrder: agencyEbidDocuments.length + 1 }],
      ];
    }

    setAgencyRegistrationDetails({
      agencyEbidDocuments: newrequiredDocuments,
      addDocumentModal: false,
    });
  };

  const moveCard = (dragIndex: any, hoverIndex: any) => {
    const dragCard = agencyEbidDocuments[dragIndex];
    setAgencyRegistrationDetails({
      agencyEbidDocuments: update(agencyEbidDocuments, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
      ebiddocumentreorder: true,
    });
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h2 className='lineHR'>eBidding Documents</h2>

          <Table className='tableHData'>
            <tr>
              <th style={divStyle.td1}>
                Display Order{' '}
                <span className='mdi ml-2 staticLink mdi-sort-descending' title='Ascending' />
              </th>
              <th style={divStyle.td2}>Document Description</th>
              <th style={divStyle.td1} />
            </tr>
            {agencyEbidDocuments.length > 0 ? (
              <DndProvider backend={HTML5Backend}>
                {agencyEbidDocuments.map((item, i) => (
                  <DragDropCard
                    key={item.id}
                    index={i}
                    id={item.id}
                    text={item.description}
                    moveCard={moveCard}
                    item={item}
                    setEditEbitModal={setEditEbidModal}
                    halfWayCancel={true}
                    setAccountInfoDetails={setAgencyRegistrationDetails}
                  />
                ))}
              </DndProvider>
            ) : (
              <tr>
                <th colSpan={6}>
                  <NoResult message={'No Required Documents Added'} />
                </th>
              </tr>
            )}
          </Table>
          {agencyEbidDocuments.length > 1 ? (
            <p>
              <i className='mdi mdi-information staticTxt mr-1' />
              <span className='text-red200'>
                {'Please use "Drag & Drop" to reorder required documents.'}
              </span>
            </p>
          ) : null}
          <div className='clearfix'>
            <Buttons
              classNames='bttn-secondary my-4 float-right'
              text='Add'
              title='Add'
              type='button'
              onClick={() => setAgencyRegistrationDetails({ addDocumentModal: true })}
            />
          </div>
        </div>
      </div>
      <RegistrationWizardButtons {...props} />
      <ModalPopUp
        title={
          ebiddocumentPopupdata?.description ? 'Edit Required Documents' : 'Add Required Documents'
        }
        size='md'
        isOpen={addDocumentModal}
        closeModal={() => setAgencyRegistrationDetails({ addDocumentModal: !addDocumentModal })}
      >
        <EbidDocumentPopup
          {...props}
          initialValues={{
            description:
              ebiddocumentPopupdata && ebiddocumentPopupdata.description
                ? ebiddocumentPopupdata.description
                : '',
            sortOrder:
              ebiddocumentPopupdata && ebiddocumentPopupdata.sortOrder
                ? ebiddocumentPopupdata.sortOrder
                : '',
            responseItemId:
              ebiddocumentPopupdata && ebiddocumentPopupdata.responseItemId
                ? ebiddocumentPopupdata.responseItemId
                : '',
          }}
          ebiddocumentPopupdata={ebiddocumentPopupdata}
          setAccountInfoDetails={setAgencyRegistrationDetails}
          submitaccountinforequiredDocsUpdate={submitaccountinforequiredDocsUpdate}
          cancelEditdocumentPopup={() =>
            setAgencyRegistrationDetails({ ebiddocumentPopupdata: '', addDocumentModal: false })
          }
        />
      </ModalPopUp>
    </>
  );
}

export default memo(EBidDocuments);
