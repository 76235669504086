import React, { memo, useEffect } from 'react';
import { addbidParamType } from '../../../../types/addbid';
import BroadcastBtns from './broadcastbtns';
import ReBroadcastList from './rebroadcastlist';
import ReSupplimentSuppliers from './resupplimentalsuppliers';

interface PropsTypes {
  addbid: addbidParamType;
  setAddBidDetails: any;
  reBroadcastBidToSuppliers: any;
  resetCommodityCodes: any;
}

const AdditionalBroadcast = (props: PropsTypes) => {
  const { addbid, setAddBidDetails, resetCommodityCodes } = props;
  const { currentBroadcastPage = 'broadcastlist' } = addbid;

  useEffect(() => {
    return () => {
      setAddBidDetails({
        broadcastSupplimentalSupplier: [],
        currentBroadcastPage: 'broadcastlist',
        broadcastListssearchShow: false,
        broadcastListssearch: [],
      });
      resetCommodityCodes();
    };
  }, [setAddBidDetails, resetCommodityCodes]);

  return (
    <>
      {currentBroadcastPage === 'broadcastlist' ? (
        <ReBroadcastList {...props} />
      ) : (
        <ReSupplimentSuppliers {...props} />
      )}
      <BroadcastBtns {...props} />
    </>
  );
};

export default memo(AdditionalBroadcast);
