import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/actions';
import history from '../../utils/history';
import { Buttons } from '../customcontrols';

const SupplierRestrict = (props: any) => {
  const { getQuoteSummary, quoteSummary } = props;
  const { summary = {} } = quoteSummary || {};
  const quoteID = props.match.params.quoteId;

  useEffect(() => {
    if (quoteID && !summary.buyerName) getQuoteSummary({ quoteID });
  }, [quoteID, summary, getQuoteSummary]);

  const RedirectPage = () => {
    history.push('/subscription/renewal');
  };

  return (
    <div className='container text-center'>
      <div className='row d-flex justify-content-center'>
        <div className='col-12'>
          <div className='colWrapper'>
            <>
              <p>
                You are trying to access {summary.buyerName}'s quote: {summary.quoteName}.
              </p>
              <p>In order to access Quotes on DemandStar, you need to upgrade your account. </p>
              <p>
                You can either upgrade to a free Subscriber account, which will get you bid
                notifications from a single agency, or a Paid account which gets you notifications
                from an entire county of governments.{' '}
              </p>
              <Buttons
                type='button'
                text='Get access to Quotes'
                classNames='bttn-primary'
                onClick={RedirectPage}
              />
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const connector = connect(
  (state: any) => ({ quoteSummary: state.quoteSummary }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

export default connector(memo(SupplierRestrict));
