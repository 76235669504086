// TODO - move doc related type declarations here.

import {
  AddBidDocTypeOption,
  DocStatus,
  DocTypeLabel,
  DocTypeValue,
  StatusLabel,
  UploadedFile,
} from './addbid';

import { FileParam, NoOpFunction } from './shared';
import { Selectable } from './common';

export type DocUploadStatus = 'notstarted' | 'started' | 'completed';
export type DocIcon = 'pdf.gif' | 'txt.gif'; // TODO: replace gif assets
export type DocFormat = 'PDF ' | 'PLT ' | 'TXT '; // TODO - trim and confirm match against back end schemas
export type DocMimeType = 'application/pdf' | 'text/plain';
export type DocTypeOption<Label = string, Value = string> = { label: Label; value: Value };

// Temporary explanation of codes
// TODO: Refine this to cover al 2 letter codes (or better still, replace codes?).
// TODO: replace with styled-components
export const DocItemIconClassNames = {
  EL: 'successTick',
  MN: 'manual',
  NS: 'notSubmit',
  None: 'noneDot',
  '': 'noneDot',
};

export type DocItemIconCode = keyof typeof DocItemIconClassNames;

/**
 * NOTE: this is initially based on
 * `src/types/biddetail.ts` - `documentItemParamType` and `BidDocument`
 * but intended to be expanded more generally.
 */
export type DSDocument = {
  bidDocId: number | string; // duplicate
  bidDocID?: number;
  bidDocStatusName?: DocStatus; //duplicate //TODO: Remove this.
  bidDocStatusType: string;
  bidId?: number | ''; // duplicate
  bidID?: number;
  datePosted?: string;
  dc?: boolean;
  description: string;
  docconversion: boolean;
  docext: string;
  docfile?: UploadedFile | string; // let's have this be the full file always
  docFormat?: DocFormat | string; // obviously, it would be great for these to be trimmed already.
  doctitle: string;
  doctype: DocTypeLabel | AddBidDocTypeOption | ''; //this is the monstrosity. avoid it.
  docType: DocTypeValue | string;
  duedates?: Date | string; // TODO: rename, camelCase (`dueDate`), default to string?
  duetimes?: '';
  duetimezones?: '';
  fileName: string;
  FileName: string;
  format?: string;
  icon?: DocIcon | string;
  id: string;
  isDocumentProgress?: boolean; // TODO - rename or merge with other redundant attributes.
  isExist: boolean; // TODO - rename
  mimeType?: DocMimeType | string;
  modifiedDate: string;
  ModifiedDate: string;
  originalFileName?: string;
  path?: string; // duplicate
  procProgress?: number; // TODO - merge/replace with another status attribute?
  progress?: number; //duplicate
  quoteDocId: number | string;
  quoteDocID: number | string; // TODO: sort out usages and move to one case, consistent with service.
  quoteID: string;
  quoteStep: string;
  remainingTime?: number;
  requireConversion?: boolean; // duplicate
  selectduedates?: Selectable<string>; //{ label: 'No'; value: 'No' };
  sortOrder: string;
  startTime?: number;
  Status: string;
  status?: StatusLabel | string; // TODO - rename to be more xplicit, vs upload status, progress, etc
  statusName: string;
  statusType: DocStatus | string;
  title: string;
  Type: string;
  type?: DocTypeLabel | string; // this is what we use for the full description string.
  uploadprocess: DocUploadStatus;
};

// TODO: expand to accommodate other types and/or merge with other doc type declarations.
export type DocumentDownloadType = 'Bid' | 'Quote' | 'Response' | 'Vendor';

export type DocumentDownloadIdParams = {
  docId?: number | string; // Optional on the service for some calls, e.g. downloadAll
  // TODO: - determine whether only `number` can be enforced for id.
  id?: number | string | null; // The service expects an `id` value, however this arg changes per doc type (`bidId`, `responseId`, et al).
  type: DocumentDownloadType;
};

export type DocumentDownloadIdKey = 'docId' | 'id' | 'type';

export type DocumentDownloadRequestParams = {
  baseUrl: string;
  docIdParams: DocumentDownloadIdParams;
  handleDownloadComplete?: NoOpFunction;
  requestUrl: string;
};

export type FileUploadRequest<T> = T & FileParam;

export type DocumentUploadParams<RequestType> = {
  data: FileUploadRequest<RequestType>;
  handleUploadProgress: (event: ProgressEvent) => void;
};

// TODO: standardize upload values and move to configuration/utility.
export type DocumentUploadFormValues = {
  files: File[];
  documentTitle: string;
  docFormatType: string;
  docReferenceId?: number;
  documentType: string;
  convertToPDF: boolean;
};

export enum FileDeleteStatusMessage {
  Success = 'Deleted',
  Error = 'Error',
}
