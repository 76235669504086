import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';
import { AgencyRegistrationState } from '../reducers/agencyregistration';

type SetAgencyRegistrationDetailsPayload = Partial<AgencyRegistrationState>;

export function setAgencyRegistrationDetails(payload: SetAgencyRegistrationDetailsPayload) {
  return action(actionTypes.SET_AGENCY_REGISTRATION_DETAILS, payload);
}

export function createBuyer(payload: actionPayloadTypes) {
  return action(actionTypes.CREATE_BUYER, payload);
}

export function addAgencyBidTypes(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_AGENCY_BID_TYPES, payload);
}

export function getAddAgencyLegal(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_AGENCY_LEGAL_AD, payload);
}

export function submitAddAgencyLegal(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_AGENCY_LEGAL_AD, payload);
}

export function addAgencyPublicationsList(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_ADD_PUBLICATIONS, payload);
}

export function agencyAddEbidDocuments(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_ADD_EBID_DOCUMENTS, payload);
}

export function getAddAgencyPublications(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_GET_PUBLICATIONS_LIST, payload);
}
export function getAddAgencyrequiredDocs(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_GET_REQUIRED_DOCS, payload);
}
export function getAddAgencyBidTypes(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_GET_BIDTYPES, payload);
}
export function getGeneralInfoIntialValues(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_GENERAL_INFO_INITIAL_VALUES, payload);
}
export function resetAllFormValues(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_AGENCY_DETAILS, payload);
}
export function deleteBidTypes(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_REMOVE_BIDTYPE, payload);
}
export function deletePublication(payload: actionPayloadTypes) {
  return action(actionTypes.AGENCY_REMOVE_PUBLICATION, payload);
}
export function getAgencySettings(payload: actionPayloadTypes) {
  return action(actionTypes.GET_AGENCY_SETTINGS, payload);
}
export function updateAgencyPageSettings(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_AGENCY_SETTINGS, payload);
}
