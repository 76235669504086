import React from 'react';
import history from '../../../../utils/history';
import { anyParamType } from '../../../../types/paymentdetails';
import { authTypes, MembershipLevel, Permission } from '../../../../types/auth';

interface PropsTypes {
  setAddBidDetails: anyParamType;
  setBidSummaryDetails: anyParamType;
  /* trackAmplitudeUserActions?: anyParamType; */
  auth: authTypes;
}

function Homecontrol(props: PropsTypes) {
  const { setAddBidDetails, setBidSummaryDetails, /* trackAmplitudeUserActions, */ auth } = props;
  const { ml = '', prms = '' } = auth;
  const mlcheck = ml.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = prms.replace(/ /g, '').split(',') as Permission[];

  return (
    <>
      {mlcheck.includes(MembershipLevel.BidPoster) || prmscheck.includes(Permission.ManageBids) ? (
        <li title='Add Bid' data-tut='reactour__agency_1'>
          <span
            className='staticLink addBid'
            onClick={event => {
              event.preventDefault();
              setAddBidDetails({ ResetState: true, broadcastListId: '' });
              setBidSummaryDetails({ results: {} });
              //trackAmplitudeUserActions({title:'Add bid - button', desc:'add bid button clicked from bid search'});
              history.push('/buyers/bid/creation');
            }}
          >
            <i className='mdi mdi-plus mr-1' /> Add Bid
          </span>
        </li>
      ) : null}
    </>
  );
}

export default Homecontrol;
