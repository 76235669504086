import moment from 'moment-timezone';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useDispatch } from 'react-redux';
import * as actionTypes from '../../store/actionTypes';
import {
  datePickerYear,
  invalidDateMessage,
  invalidGreaterDateMessage,
  usaDateFormat,
} from '../../utils/constants';
import { deprecatedConvertUSAFormat, validateTypedDate } from '../../utils/helpers';
import 'react-dates/initialize';

interface PropsTypes {
  handleChange?: any;
  type?: string;
  disabled?: any;
  label?: string;
  // name?: string
  startDatename?: string;
  endDatename?: string;
  startDatevalue?: any;
  endDatevalue?: any;
  meta?: any;
  optional?: any;
  input?: any;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  parentClass?: string;
  reduxform?: boolean;
  showClearDate?: boolean;
  showDefaultInputIcon?: any;
  isOutsideRange?: boolean;
  numberOfMonths?: number;
  classNames?: string;
}

function DateRangePickerComponent(props: PropsTypes) {
  const {
    handleChange = () => {},
    disabled,
    label,
    // name = '',
    startDatevalue = '',
    endDatevalue = '',
    meta = {},
    optional,
    input,
    placeholder = 'mm/dd/yyyy',
    minDate = moment(),
    maxDate,
    parentClass,
    reduxform = false,
    showClearDate = false,
    isOutsideRange = false,
    startDatename = '',
    endDatename = '',
  } = props;
  let { showDefaultInputIcon = true } = props;
  const { touched, error } = meta || {};
  const [focusedInput, setFocusedInput] = useState(null);
  const [internalError, setInternalError] = useState('');
  const startDateId = reduxform
    ? `multi_date_redux_${input.startDatename}`
    : `multi_date_${startDatename}`;
  const endDateId = reduxform
    ? `multi_date_redux_${input.endDatename}`
    : `multi_date_${endDatename}`;
  const dispatch = useDispatch();

  const returnYears = () => {
    const years = [];
    for (let i = moment().year() - datePickerYear; i <= moment().year() + datePickerYear; i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
            {moment.months().map((label, value) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
            {returnYears()}
          </select>
        </div>
      </div>
    );
  };

  const settingErrorFn = useCallback(
    (value: string) => {
      setInternalError(value);
      dispatch({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { invaliddateerror: value },
      });
    },
    [dispatch],
  );

  const onMultiDateChange = useCallback(
    ({ startDate, endDate }: any) => {
      if (reduxform) {
        input.onChange(startDate);
      } else {
        handleChange(startDatename, startDate);
        handleChange(endDatename, endDate);
      }

      if (startDate && !moment(startDate, usaDateFormat).isValid()) {
        settingErrorFn(invalidDateMessage);
      } else if (endDate && !moment(endDate, usaDateFormat).isValid()) {
        settingErrorFn(invalidDateMessage);
      } else {
        settingErrorFn('');
      }
      if (
        endDate &&
        startDate &&
        moment(moment(endDate).format(usaDateFormat)).isBefore(
          moment(startDate).format(usaDateFormat),
        )
      ) {
        settingErrorFn(invalidGreaterDateMessage);
      }
    },
    [endDatename, handleChange, input, reduxform, settingErrorFn, startDatename],
  );

  const onDateChange = useCallback(
    (name, value) => {
      if (reduxform) {
        input.onChange(value);
      } else {
        handleChange(name, value);
      }
      if (value && !moment(value, usaDateFormat).isValid()) {
        settingErrorFn(invalidDateMessage);
      } else {
        settingErrorFn('');
      }
      let startDateval = startDatevalue;
      let endDateval = value;
      if (name === startDatename) {
        startDateval = value;
        endDateval = endDatevalue;
      }
      if (
        endDateval &&
        startDateval &&
        moment(moment(endDateval).format(usaDateFormat)).isBefore(
          moment(startDateval).format(usaDateFormat),
        )
      ) {
        settingErrorFn(invalidGreaterDateMessage);
      }
    },
    [endDatevalue, handleChange, input, reduxform, settingErrorFn, startDatename, startDatevalue],
  );

  const checktDateFn = useCallback(
    (event: KeyboardEvent) => {
      const evt = event.target as HTMLInputElement;
      if (evt && !evt.value) {
        settingErrorFn('');
      }
      if (evt && evt.value && !moment(evt.value, usaDateFormat).isValid()) {
        settingErrorFn(invalidDateMessage);
      }
      if (evt?.value?.length < 10 && evt?.value?.length > 0) {
        settingErrorFn(invalidDateMessage);
      }
      if (evt && evt.value && moment(evt.value, usaDateFormat).isValid()) {
        let startDateval = startDatevalue;
        let endDateval = evt.value;
        if (evt.name === startDatename) {
          startDateval = evt.value;
          endDateval = endDatevalue;
        }
        if (
          endDateval &&
          startDateval &&
          moment(moment(endDateval).format(usaDateFormat)).isBefore(
            moment(startDateval).format(usaDateFormat),
          )
        ) {
          settingErrorFn(invalidGreaterDateMessage);
        }
      }
    },
    [endDatevalue, settingErrorFn, startDatename, startDatevalue],
  );

  const convertStartDateFn = useCallback(
    event => {
      const evt = event.target;
      const finaldate =
        evt && evt.value && evt.value.length >= 6 ? deprecatedConvertUSAFormat(evt.value) : '';
      if (finaldate && validateTypedDate(finaldate, minDate, maxDate, isOutsideRange)) {
        onDateChange(startDatename, moment(finaldate));
      } else {
        if (evt.value) settingErrorFn(invalidDateMessage);
      }
    },
    [isOutsideRange, maxDate, minDate, onDateChange, startDatename, settingErrorFn],
  );

  const convertEndDateFn = useCallback(
    event => {
      const evt = event.target;
      const finaldate =
        evt && evt.value && evt.value.length >= 6 ? deprecatedConvertUSAFormat(evt.value) : '';
      if (finaldate && validateTypedDate(finaldate, minDate, maxDate, isOutsideRange)) {
        onDateChange(endDatename, moment(finaldate));
      } else {
        if (evt.value) settingErrorFn(invalidDateMessage);
      }
    },
    [isOutsideRange, maxDate, minDate, onDateChange, endDatename, settingErrorFn],
  );

  useEffect(() => {
    const datePicker = document.getElementById(startDateId);
    if (datePicker) {
      datePicker.addEventListener('keyup', checktDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('keyup', checktDateFn);
      }
    };
  }, [checktDateFn, startDateId]);

  useEffect(() => {
    const datePicker = document.getElementById(endDateId);
    if (datePicker) {
      datePicker.addEventListener('keyup', checktDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('keyup', checktDateFn);
      }
    };
  }, [checktDateFn, endDateId]);

  useEffect(() => {
    const datePicker = document.getElementById(startDateId);
    if (datePicker) {
      datePicker.addEventListener('focusout', convertStartDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('focusout', convertStartDateFn);
      }
    };
  }, [convertStartDateFn, startDateId]);

  useEffect(() => {
    const datePicker = document.getElementById(endDateId);
    if (datePicker) {
      datePicker.addEventListener('focusout', convertEndDateFn);
    }
    return () => {
      if (datePicker) {
        datePicker.removeEventListener('focusout', convertEndDateFn);
      }
    };
  }, [convertEndDateFn, endDateId]);

  const onCloseValidationFn = () => {
    setTimeout(() => {
      settingErrorFn('');
    }, 10);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { invaliddateerror: '' } });
    };
  }, [dispatch]);

  if (startDatevalue || endDatevalue) {
    showDefaultInputIcon = false;
  }

  return (
    <div
      className={
        'datePickerWrapper ' +
        parentClass +
        ((touched && error) || internalError ? ' errorWrapper' : '')
      }
    >
      <div title={usaDateFormat}>
        <label title={usaDateFormat}>{label}</label>
        {reduxform ? (
          <DateRangePicker
            {...input}
            startDate={startDatevalue ? startDatevalue : null} // momentPropTypes.momentObj or null,
            endDate={endDatevalue ? endDatevalue : null} // momentPropTypes.momentObj or null,
            startDateId={startDateId} // PropTypes.string.isRequired,
            endDateId={endDateId} // PropTypes.string.isRequired,
            onDatesChange={onMultiDateChange} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
            numberOfMonths={2}
            disabled={disabled}
            isOutsideRange={(day: any) => {
              if (!isOutsideRange) {
                if (minDate && maxDate) {
                  return day.isAfter(maxDate) || day.isBefore(minDate);
                }
                if (minDate) return day.isBefore(minDate);
              }
            }}
            showClearDates={showClearDate}
            displayFormat={usaDateFormat}
            showDefaultInputIcon={showDefaultInputIcon}
            placeholder={placeholder}
            renderMonthElement={renderMonthElement}
            hideKeyboardShortcutsPanel={true}
            customCloseIcon={<i className='mdi mdi-close' onClick={onCloseValidationFn} />}
            minimumNights={0}
            startDatePlaceholderText={usaDateFormat}
            endDatePlaceholderText={usaDateFormat}
            // small={true}
          />
        ) : (
          <DateRangePicker
            startDate={startDatevalue ? startDatevalue : null} // momentPropTypes.momentObj or null,
            endDate={endDatevalue ? endDatevalue : null} // momentPropTypes.momentObj or null,
            startDateId={startDateId} // PropTypes.string.isRequired,
            endDateId={endDateId} // PropTypes.string.isRequired,
            onDatesChange={onMultiDateChange} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
            numberOfMonths={2}
            disabled={disabled}
            isOutsideRange={(day: any) => {
              if (!isOutsideRange) {
                if (minDate && maxDate) {
                  return day.isAfter(maxDate) || day.isBefore(minDate);
                }
                if (minDate) return day.isBefore(minDate);
              }
            }}
            showClearDates={showClearDate}
            showDefaultInputIcon={showDefaultInputIcon}
            // placeholder={placeholder}
            displayFormat={usaDateFormat}
            renderMonthElement={renderMonthElement}
            hideKeyboardShortcutsPanel={true}
            customCloseIcon={<i className='mdi mdi-close' onClick={onCloseValidationFn} />}
            minimumNights={0}
            startDatePlaceholderText={usaDateFormat}
            endDatePlaceholderText={usaDateFormat}
            // small={true}
          />
        )}
      </div>
      {optional === true ? <span>(optional)</span> : optional ? <span>{optional}</span> : ''}
      <div className='errorMsg'>
        {(touched && error) || internalError ? (
          <>{<span>{internalError ? internalError : error}</span>}</>
        ) : null}
      </div>
    </div>
  );
}

export default memo(DateRangePickerComponent);
