import React, { memo, useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/actions';
import { memberTypes, userPermissionKeys } from '../../../utils/constants';
import { hasPermission, toastFn } from '../../../utils/helpers';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import DeclineModal from './declinemodal';
import QuotesReplyForm from './replyinformation';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';
import { authTypes } from '../../../types/auth';

interface propsTypes extends PropsFromRedux {
  history: any;
  quoteSummary: QuoteSummaryType;
  setQuoteSummaryDetails: any;
  auth: authTypes;
  getQuotesReply: any;
  match: any;
  form: any;
  handleSubmit: any;
  pristine: boolean;
  submitting: boolean;
  valid: boolean;
  memberinfo: any;
  setBreadcrumb: any;
  submitQuoteReply: any;
  notSubmitQuote: any;
  memberInfo: any;
}

const Quotesreply = (props: propsTypes) => {
  const { history, quoteSummary, memberInfo, setQuoteSummaryDetails, getQuotesReply, auth } = props;
  const quoteId = parseInt(props.match.params.quoteId);
  const { summary, quotereplyinfo, declinequotereplymodal = false } = quoteSummary;
  const { quoteId: QID, responseStatus } = summary;
  const { mt: memberType, prms: accountPermissions = '', mc: isPrimaryContact = false } = auth;
  const hasQuoteRespondPermission =
    hasPermission(accountPermissions, userPermissionKeys.respondQuotes) || isPrimaryContact;

  useEffect(() => {
    if (memberType === memberTypes.basicSupplier) history.replace('/');
  }, [history, memberType]);

  const GoBackToPage = useCallback(() => {
    history.push(`/suppliers/quotes/${quoteId}`);
  }, [history, quoteId]);

  useEffect(() => {
    if (!hasQuoteRespondPermission) GoBackToPage();
  }, [GoBackToPage, hasQuoteRespondPermission]);

  useEffect(() => {
    return () => {
      setQuoteSummaryDetails({
        quotereplyinfo: {
          delivery: '',
          additionalSupplierInfo: '',
          preparedBy: '',
          linewires: [],
        },
        declinequotereplymodal: false,
      });
    };
  }, [setQuoteSummaryDetails]);

  useEffect(() => {
    if (quoteId) getQuotesReply({ quoteId });
  }, [getQuotesReply, quoteId]);

  useEffect(() => {
    if (quoteId && QID) {
      if (['AW', 'CL', 'CA'].includes(responseStatus)) {
        history.replace('/');
      }
    }
  }, [QID, history, quoteId, responseStatus]);

  const DeleteModalFn = () => {
    setQuoteSummaryDetails({ declinequotereplymodal: !declinequotereplymodal });
    //history.goBack()
    //toastFn('success', 'Your quote has been Declined', 'QUOTE_SUMMARY_NOT_SUBMIT_QUOTE.SUCCESS', 4000)
  };

  return (
    <>
      <div className='container'>
        <div className='row detailViewWrapper ml-0'>
          <div className='col-12'>
            <div className='colWrapper clearfix'>
              <div className='row'>
                <QuotesReplyForm
                  {...props}
                  initialValues={quotereplyinfo}
                  GoBackToPage={GoBackToPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        title='Reason For Not Quoting'
        closeModal={DeleteModalFn}
        isOpen={declinequotereplymodal}
        size='md'
      >
        <DeclineModal
          {...props}
          pagefor='response'
          DeleteModalFn={DeleteModalFn}
          toastFn={toastFn}
        />
      </ModalPopUp>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    quoteSummary: state.quoteSummary,
    auth: state.auth,
    memberinfo: state.memberinfo,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(Quotesreply));
