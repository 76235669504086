import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';

export function getPreBuiltBroadcastLists(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getList, data, {
    baseURL: constants.api.url,
  });
}

export function AddBroadcastList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.addList, data, {
    baseURL: constants.api.url,
  });
}

export function getBroadcastMembers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.broadcastMembersBL, data, {
    baseURL: constants.api.url,
  });
}

export function SearchVendorsBroadcastList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.searchVendorsBL, data, {
    baseURL: constants.api.url,
  });
}

export function getSelectedCommoditiesFiltersforBL(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getBLFilters, data, {
    baseURL: constants.api.url,
  });
}
