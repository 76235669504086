// TOREFACTOR: This is mounting and unmounting a LOT.

import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Collapse, DropdownToggle, NavbarToggler, UncontrolledDropdown } from 'reactstrap';
import LogoDS from '../../assets/images/logo_ds';
import {
  appstatetokenName,
  idleTimeOut,
  memberTypes,
  relogintokenName,
  tokenCookieName,
  TourId,
} from '../../utils/constants';
import { Breadcrumb } from '../common/breadcrumb';
import { NavLink } from 'react-router-dom';
import { addDsEvent, clearStorageFn, openHelpPage } from '../../utils/helpers';
import HeaderDropDown from '../common/headerdropdown';
import history from '../../utils/history';
import { useIdleMonitor } from '../../utils/helperHooks';
import Configurations from '../../settings';
import { getCookie } from '../../utils/cookie';
import { navigationPaths } from '../../shared/constants';
import { RESPONSE_LABEL_PLURAL } from '../../shared/constants/bids';
import { useSetMemberInfo } from '../../shared/hooks/useMemberInfo';
import { useDSSelector } from '../../store/reducers';
import { getRefreshToken, getUserAccounts, setSharedDetails } from '../../store/actions';

interface HeaderProps {
  match?: any;
}

const Header = (props: HeaderProps) => {
  const dispatch = useDispatch();

  const auth = useDSSelector(state => state.auth);
  const menuactive = useDSSelector(state => state.breadcrumb?.menuactive) || '';
  const userAccounts = useDSSelector(state => state.accountinfo?.userAccounts) || [];
  const tourStart = useDSSelector(state => state.tours?.tourStart);
  const tourId = useDSSelector(state => state.tours?.tourId);
  const currentDSTheme = useDSSelector(state => state.shared?.currentDSTheme) || 'light_DS';

  const { fln: fullName, mt: memberType, opi = '', tk = '', un = '', us, mi } = auth || {};
  const userpath = memberType === memberTypes.agencyBuyer ? '/buyers' : '/suppliers';

  useEffect(() => {
    if (sessionStorage.getItem(relogintokenName) && auth && tk && un) {
      sessionStorage.removeItem(relogintokenName);
      dispatch(getRefreshToken());
    }
  }, [dispatch, tk, un, auth]);

  useEffect(() => {
    if (opi && mi) dispatch(getUserAccounts(mi));
  }, [dispatch, mi, opi]);

  // Set the selectedMemberId and selectedMemberType once a valid auth is found.
  useSetMemberInfo(auth);

  useEffect(() => {
    const CheckLocalStorage = setTimeout(() => {
      const l_storage = localStorage.getItem(appstatetokenName) || '';
      if (!l_storage) {
        clearStorageFn(true, true);
      }
    }, 10000);
    return () => clearTimeout(CheckLocalStorage);
  }, []);

  useEffect(() => {
    addDsEvent(window, 'storage', (event: any) => {
      if (event.key === appstatetokenName && !event.newValue) {
        clearStorageFn(true, true);
      }
    });
  }, []);

  useEffect(() => {
    if (auth && (!memberType || opi)) {
      const CheckOpsLocalStorage = setInterval(() => {
        const tokenValue = getCookie(tokenCookieName);
        if (!tokenValue) {
          const regEx = /^\/(buyers|suppliers)\/(bids)\/[0-9]+/;
          const regExLimited = /^\/(limited)\/(bids)\/[0-9]+/;
          if (
            regEx.test(history.location.pathname) ||
            regExLimited.test(history.location.pathname)
          ) {
            const limitedBidId = parseInt(props.match.params.bidId) || '';
            clearStorageFn(false, false);
            window.location.href = `/app/limited/bids/${limitedBidId}/details`;
          } else clearStorageFn(true, true);
        }
      }, 1000);
      return () => clearInterval(CheckOpsLocalStorage);
    }
  }, [auth, memberType, opi, props.match]);

  useEffect(() => {
    dispatch(setSharedDetails({ currentDSTheme: 'light_DS' }));
  }, [dispatch]);

  useEffect(() => {
    if (currentDSTheme) {
      document.body.className = '';
      document.body.classList.add(currentDSTheme);
    }
  }, [currentDSTheme]);

  const ApplyClassForSubFn = useCallback(() => {
    const currentpathname = window.location.pathname || '';
    const maintag: any = document.getElementById('root');
    const path = currentpathname.split('/');
    if (maintag) {
      if (path.includes('bid') || path.includes('bids')) {
        maintag.className = 'bidThem';
      } else if (path.includes('quotes') || path.includes('quote')) {
        maintag.className = 'quotesThem';
      } else {
        maintag.className = 'defaultThem';
      }
    }
  }, []);

  useEffect(() => {
    ApplyClassForSubFn();
  }, [ApplyClassForSubFn]);

  const GoHome = (e: { preventDefault: () => any }) => {
    e && e.preventDefault();
    if (auth) {
      const RedirectURL = `${userpath}/dashboard`;
      history.push(RedirectURL);
    } else {
      window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useIdleMonitor(idleTimeOut, () => window.location.reload());

  const currentAccount = userAccounts.find((item: { accountId: number }) => item.accountId === us);

  const tourPosition: any =
    tourStart && tourId === TourId.bidSearch ? { position: 'relative' } : {};

  return (
    <>
      {auth && opi ? (
        <>
          {currentAccount && !currentAccount.active ? (
            <div className='opsAlertWrapper bg-yellow200 text-grayD200'>
              Pinned by OPS - This account is inactive.
            </div>
          ) : (
            <div className='opsAlertWrapper bg-red200' data-testid='layout.header.ops.heading'>
              Pinned by OPS
            </div>
          )}
        </>
      ) : null}
      <div className={'headerBreadcrumbWrapper ' + (auth && opi ? 'ops' : '')} style={tourPosition}>
        <header>
          <nav className='navbar navbar-expand-lg navbar-light'>
            <span className='navbar-brand staticLink' onClick={GoHome}>
              <LogoDS />
            </span>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              {auth && auth.mt ? (
                <>
                  <ul className='navbar-nav mt-2 mt-lg-0 float-right'>
                    <li className='nav-item'>
                      <NavLink
                        activeClassName='active'
                        className={`nav-link ${menuactive === 'dashboard' ? 'active' : ''}`}
                        to={`${userpath}/dashboard`}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        activeClassName='active'
                        className={`nav-link ${menuactive === 'bids' ? 'active' : ''}`}
                        to={`${userpath}/bids`}
                      >
                        Bids
                      </NavLink>
                    </li>
                    {memberType !== memberTypes.basicSupplier && (
                      <li className='nav-item'>
                        <NavLink
                          activeClassName='active'
                          className={`nav-link ${menuactive === 'quotes' ? 'active' : ''}`}
                          to={`${userpath}/quotes`}
                        >
                          Quotes
                        </NavLink>
                      </li>
                    )}
                    <li className='nav-item'>
                      <NavLink
                        activeClassName='active'
                        className={`nav-link ${menuactive === 'activities' ? 'active' : ''}`}
                        to={`${userpath}/activities/notifications`}
                      >
                        Activities
                      </NavLink>
                    </li>
                    {/* <li className='nav-item'>
                      <NavLink
                        activeClassName='active'
                        className={`nav-link ${menuactive === 'message' ? 'active' : ''}`}
                        to={`${userpath}/messages`}>
                        <span>Coming Soon!</span>
												Messages
                      </NavLink>
                    </li> */}
                    {memberType !== memberTypes.agencyBuyer ? (
                      <li className='nav-item'>
                        <NavLink
                          activeClassName='active'
                          className={`nav-link ${
                            menuactive === navigationPaths.subPaths.responses ? 'active' : ''
                          }`}
                          to={`${userpath}/${navigationPaths.subPaths.responses}/${navigationPaths.subPaths.requiredActions}`}
                        >
                          {RESPONSE_LABEL_PLURAL}
                        </NavLink>
                      </li>
                    ) : (
                      <>
                        <li className='nav-item'>
                          <NavLink
                            activeClassName='active'
                            className={`nav-link ${menuactive === 'responses' ? 'active' : ''}`}
                            to='/buyers/research'
                          >
                            Research
                          </NavLink>
                        </li>
                        {/* <li className='nav-item contract'>
                          <NavLink
                            activeClassName='active'
                            className={`nav-link ${menuactive === 'contract' ? 'active' : ''}`}
                            to='/buyers/contractmanagement'>
														Contract Management
                          </NavLink>
                        </li> */}
                      </>
                    )}
                  </ul>
                </>
              ) : (
                <>
                  <ul className='navbar-nav mt-2 mt-lg-0 float-right'>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${menuactive === 'bids' ? 'active' : ''}`}
                        href={`${Configurations.REACT_APP_OPS_URL}/bids`}
                      >
                        Bids
                      </a>
                    </li>
                    <li>
                      {' '}
                      <a
                        className={`nav-link ${menuactive === 'quotes' ? 'active' : ''}`}
                        href={`${Configurations.REACT_APP_OPS_URL}/quotes`}
                      >
                        Quotes
                      </a>
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        className={`nav-link ${menuactive === 'createmember' ? 'active' : ''}`}
                        to={'/ops/agency/registration'}
                      >
                        Create Member
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${menuactive === 'member' ? 'active' : ''}`}
                        href={`${Configurations.REACT_APP_OPS_URL}/member`}
                      >
                        Member
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${menuactive === 'reports' ? 'active' : ''}`}
                        href={`${Configurations.REACT_APP_OPS_URL}/reports`}
                      >
                        Reports
                      </a>
                    </li>
                  </ul>
                </>
              )}
            </Collapse>
            {/* {auth && auth.mt ? (
              <UncontrolledDropdown size='sm' className='mr-lg-4 dropDownWrapper themeDD'>
                <DropdownToggle title='Theme'>
                  <i className='mdi mdi-format-color-fill mdi-24px' />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    tag='a'
                    active={currentDSTheme === 'light_DS'}
                    onClick={() => changeThemeFn('light_DS')}>
										Light Theme
                  </DropdownItem>
                  <DropdownItem tag='a' active={currentDSTheme === 'dark_DS'} onClick={() => changeThemeFn('dark_DS')}>
										Dark Theme
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null} */}
            <span
              className='helpLink staticLink cursorPointer'
              title='Help'
              onClick={() => openHelpPage(memberType)}
            >
              <i className='mdi mdi-help-circle-outline mdi-24px mr-4' />
            </span>
            <UncontrolledDropdown size='sm' className='dropDownWrapper'>
              <DropdownToggle title={fullName} className='btn btn-link dropdown-toggle'>
                {fullName}
              </DropdownToggle>
              <HeaderDropDown />
            </UncontrolledDropdown>
          </nav>
        </header>
        <Breadcrumb history={history} />
      </div>
    </>
  );
};

// TODO: TS4 - I removed auth from Breadcrumb as it appears to be injected by Redux.
// The breadcrumb component then was complaining about needing history passed in so I added it.

export default memo(Header);
