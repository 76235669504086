import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { deprecatedGetDate, scrollToTop, toastFn } from '../../utils/helpers';
import history from '../../utils/history';
import * as actionTypes from '../actionTypes';
import * as quoteApi from '../services/quotes';
import { quotespayloadTypes } from '../../types/quotes';

const quotestoastID = 'a5cc6cc0-8665-4ae4-ae6c-843dc2fda28d';

function* watchgetQuotesList() {
  yield takeLatest(actionTypes.GET_QUOTES_LIST.TRIGGER, getQuotesList);
}

function* getQuotesList(action: { payload: { types: string } }): any {
  yield put({ type: actionTypes.GET_QUOTES_LIST.REQUEST, meta: action.payload });
  try {
    const { types = 'initial' } = action.payload;
    const quotes = yield select(state => state.quotes);
    const { quotesFilters } = quotes;
    const {
      quoteName = '',
      quoteNumber = '',
      quoteStatuses = '',
      quoteYear = '',
      startDueDate = '',
      endDueDate = '',
      quoteContact = '',
      quotesortBy,
      quotesortOrder,
    } = quotesFilters;
    const finalvalue: any = {};
    if (types === 'search') {
      if (startDueDate)
        finalvalue.startDueDate = startDueDate && `${deprecatedGetDate(startDueDate)} 00:00:00`;
      if (endDueDate)
        finalvalue.endDueDate = endDueDate && `${deprecatedGetDate(endDueDate)} 23:59:59`;
      if (quoteYear) finalvalue.fiscalYear = quoteYear && quoteYear.value;
      if (quoteContact)
        finalvalue.contactName = quoteContact && quoteContact.value && quoteContact.value.trim();
      if (quoteStatuses) finalvalue.quoteStatus = quoteStatuses && quoteStatuses.value;
      if (quoteName) finalvalue.quoteName = quoteName && quoteName.trim();
      if (quoteNumber) finalvalue.quoteNumber = quoteNumber && quoteNumber.trim();
      if (quotesortBy) finalvalue.sortBy = quotesortBy;
      if (quotesortOrder) finalvalue.sortOrder = quotesortOrder;
    }
    const response = yield call(quoteApi.getQuotes, finalvalue);
    const lists = response.data.result || [];
    yield put({ type: actionTypes.GET_QUOTES_LIST.SUCCESS, payload: lists });
    scrollToTop();
  } catch (error) {
    yield put({
      type: actionTypes.GET_QUOTES_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteGetContactList() {
  yield takeLatest(actionTypes.GET_QUOTES_CONTACT_LIST.TRIGGER, quoteGetContactList);
}

function* quoteGetContactList(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_QUOTES_CONTACT_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(quoteApi.contactList, action.payload);
    const list = response.data.result || [];
    let payload = [];
    if (list.length > 0) {
      payload = list.map((item: { contactName: string }) => ({
        ...item,
        label: item.contactName,
        value: item.contactName,
      }));
    }
    yield put({ type: actionTypes.GET_QUOTES_CONTACT_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_QUOTES_CONTACT_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetQuoteStatus() {
  yield takeLatest(actionTypes.GET_QUOTES_STATUS.TRIGGER, getQuoteStatus);
}

function* getQuoteStatus(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_QUOTES_STATUS.REQUEST, meta: action.payload });

  try {
    const response = yield call(quoteApi.getQuoteStatus, action.payload);
    const list = response.data.result || [];
    let payload = [];
    if (list.length > 0) {
      payload = list.map((item: { statusName: string; statusType: string }) => ({
        ...item,
        label: item.statusName,
        value: item.statusType,
      }));
    }
    yield put({ type: actionTypes.GET_QUOTES_STATUS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_QUOTES_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteSummaryDeleteQuote() {
  yield takeLatest(actionTypes.QUOTE_SUMMARY_DELETE_QUOTE.TRIGGER, QuoteSummaryDeleteQuote);
}

function* QuoteSummaryDeleteQuote(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_DELETE_QUOTE.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.DeleteQuote, action.payload);

    const data = response.data.result || '';
    if (data && data.status) {
      yield put({ type: actionTypes.QUOTE_SUMMARY_DELETE_QUOTE.SUCCESS });
      delay(100);
      yield put({ type: actionTypes.SET_QUOTES_DETAILS.TRIGGER, payload: { quotesreload: true } });
      history.replace('/buyers/quotes');
      toastFn('success', 'Deleted', quotestoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_DELETE_QUOTE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* quoteListSaga() {
  yield fork(watchgetQuotesList);
  yield fork(watchQuoteGetContactList);
  yield fork(watchgetQuoteStatus);
  yield fork(watchQuoteSummaryDeleteQuote);
}
