import React, { memo, useState } from 'react';
import { BidsList, NoResult, Paging } from '../../customcontrols';
import { pageSize } from '../../../utils/constants';
import { loadingMsg } from '../../../utils/texts';
import { bidssummaryParamType } from '../../../types/biddetail';
import { sharedTypes } from '../../../types/shared';

interface PropsTypes {
  bidssummary: bidssummaryParamType;
  shared: sharedTypes;
}

const SimilarBids = (props: PropsTypes) => {
  const { bidssummary, shared } = props;

  const { similarBids } = bidssummary;
  const { internalLoader = false } = shared;

  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  return (
    <>
      <h3 className='arrowWrapper'>Similar Bids ({similarBids.length})</h3>
      <div className='row'>
        <div className='col-lg-12 similarBidsTab'>
          {similarBids.length > 0 ? (
            <>
              {similarBids
                .map((bids, id) => <BidsList key={id} {...bids} pagefor='similarbids' />)
                .slice(indexOfFirstList, indexOfLastList)}
            </>
          ) : (
            <NoResult message={internalLoader ? loadingMsg : 'No Similar Bids Available'} />
          )}
          <Paging
            totalRecords={similarBids.length}
            currentPage={currentPage}
            pagefor='planholders-supplier'
            onPagingClick={(value: number) => {
              if (value !== currentPage) setCurrentPage(Number(value));
            }}
            pageLimit={listPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default memo(SimilarBids);
