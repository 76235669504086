import React from 'react';

import Buttons from '../../customcontrols/button';

interface CompanyInfoProps {
  title: string;
  addressData: any;
  onClick: any;
  type: string;
}

function CompanyInfo(props: CompanyInfoProps) {
  const { title, addressData = {}, onClick, type } = props;
  return (
    <>
      <h4>{title}</h4>
      <div className='innerColIndent'>
        {addressData ? (
          <p>
            {addressData.shortName}
            <br />
            {addressData.address1} {addressData.address2 ? `,${addressData.address2}` : ''} <br />
            {addressData.city},{' '}
            {addressData.countyName
              ? `${
                  addressData.countyName.label
                    ? addressData.countyName.label
                    : addressData.countyName
                },`
              : ''}{' '}
            {addressData.abbreviation.name
              ? addressData.abbreviation.name
              : addressData.abbreviation.label
              ? addressData.abbreviation.label
              : addressData.abbreviation}
            , {addressData.postalCode}
          </p>
        ) : (
          'Same as Shipping Address'
        )}

        <Buttons
          type='submit'
          classNames='bttn-secondary small float-right'
          text='Change'
          onClick={() => onClick(type)}
        />
      </div>
    </>
  );
}

export default CompanyInfo;
