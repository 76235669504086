import dayjs from 'dayjs';
import React from 'react';

import { Colors } from '../../../../shared/styles';

import { DateTimeUnits } from '../../../../shared/constants';
import { DueDate } from '../../../../types/shared';
import { displayDate } from '../../../../utils/helpers';
import { PillWrapper } from '../../../../shared/styles/controls';
import { RequiredActionLabels } from '../../../../shared/constants/bids';

export function DueDatePill(props: DueDate) {
  const currentDateTimestamp = dayjs();
  const dueDateTimestamp = dayjs(props.dueDate);

  let bgColor = Colors.white;
  let textColor = Colors.grayDark;
  let labelPrefix = RequiredActionLabels.dueDatePrefix;
  let labelTitlePrefix = RequiredActionLabels.dueDateTitlePrefix;

  const timeRemaining = dueDateTimestamp.diff(currentDateTimestamp);

  // Overdue
  if (timeRemaining < 0) {
    bgColor = Colors.error;
    textColor = Colors.white;
    labelPrefix = RequiredActionLabels.overduePrefix;
    labelTitlePrefix = RequiredActionLabels.overduePrefix;
  }

  // Within 1 week
  else if (timeRemaining < DateTimeUnits.week) {
    bgColor = Colors.yellowMedium;
  }

  // Within 1 month
  else if (timeRemaining < DateTimeUnits.month) {
    bgColor = Colors.greenMedium;
    textColor = Colors.white;
  }

  const formattedDate = displayDate(dueDateTimestamp);

  return (
    <PillWrapper
      bgColor={bgColor}
      textColor={textColor}
      title={`${labelTitlePrefix} ${formattedDate}`}
    >
      {`${labelPrefix} ${formattedDate}`}
    </PillWrapper>
  );
}
