import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';

//----- API calls-------//

export function submitAddQuotesInfo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.addQuoteInfo, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddQuotesWire(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateWire, data, {
    baseURL: constants.api.url,
  });
}

export function getAddQuotesItems(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteItems, data, {
    baseURL: constants.api.url,
  });
}

export function getAddQuotesDocuments(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quotesdocuments, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddQuotesDocumentDelete(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quotesdocumentdelete, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddQuotesMoveNext(data = {}) {
  return Api.postRequestWithAuthentication(Paths.movenext, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddQuotesDocumentUpload(data: any = {}, config = {}) {
  const formData = new FormData();
  formData.append('quoteId', data && data.quoteId);
  formData.append('documentTitle', data && data.doctitle);
  formData.append('documentType', data && data.doctype);
  formData.append('docFormatType', data && data.docext);
  formData.append('uploadFile', data && data.docfile);
  return Api.postRequestWithAuthentication(Paths.quotesdocumentupload, formData, config);
}

export function getAddQuoteCanComplete(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quoteCanComplete, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddQuoteComplete(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quotescomplete, data, {
    baseURL: constants.api.url,
  });
}

export function getAddQuoteNumbers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.quotesnumbers, data, {
    baseURL: constants.api.url,
  });
}

//----- API calls-------//
