import React from 'react';

import ExclamationPoint from '../assets/icons/exclaim.svg';
import { Status } from '../constants';

interface AlertProps {
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
  header: string;
  linkText?: string;
  message: string;
  type: Status;
}

/**
 * @description Alert component
 * @param {fn=}     handleClick - optional function to handle click event
 * @param {string}  header - header text
 * @param {string}  linkText - optional link text
 * @param {string}  message - message text
 * @param {enum}    type - 'warning' | 'error' | 'success'
 */

export const Alert = (props: AlertProps) => {
  const { handleClick, header, linkText, message, type } = props;

  let iconboxColor;
  let bodyColor;

  switch (type) {
    case Status.Success:
      iconboxColor = 'bg-green';
      bodyColor = 'bg-light-green';
      break;
    case Status.Error:
      iconboxColor = 'bg-dark-red';
      bodyColor = 'bg-lighter-red';
      break;
    default:
      iconboxColor = 'bg-yellow';
      bodyColor = 'bg-light-yellow';
  }

  return (
    <div className='row reg-alert'>
      <div className={`col-1 alert-iconbox ${iconboxColor}`}>
        <div className='icon-container'>
          <img src={ExclamationPoint} />
        </div>
      </div>
      <div className={`col-11 alert-body ${bodyColor}`}>
        <h5>{header}</h5>
        <p>{message}</p>
        {linkText ? <button onClick={handleClick}>{linkText}</button> : null}
      </div>
    </div>
  );
};

export default Alert;
