export const refundValidation = (values: any = {}) => {
  const errors = {} as any;
  const required =
    Object.keys(values).map(items => {
      const returnValue = values[items];
      return {
        name: items,
        value: typeof returnValue === 'string' ? values[items].trim() : values[items],
      };
    }) || [];

  const errorarr = required.filter(items => !items.value) || [];

  errorarr.forEach(items => (errors[items.name] = 'Required'));
  if (
    values.refundAmount &&
    !/^([0-9]\d*)$/.test(values.refundAmount) &&
    !/^[-+]?[0-9]+\.[0-9]+$/.test(values.refundAmount)
  ) {
    const refundPaymentAmount = values.refundAmount
      .toString()
      .replace(/ /g, '')
      .replace('$', '')
      .replace(/,/g, '');
    if (
      !/^(0|[0-9]\d*)$/.test(refundPaymentAmount) &&
      !/^[-+]?[0-9]+\.[0-9]+$/.test(refundPaymentAmount)
    ) {
      errors.refundAmount = 'Invalid';
    }
  }

  return errors;
};
