/** DEPRECATED
 * Use Radio component instead.
 **/

import React from 'react';

interface DeprecatedRadioProps {
  defaultChecked: boolean;
  input?: any;
  type?: unknown;
  classNames?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean | 'true' | 'false';
  title?: string;
  reduxform?: boolean;
  handleSelect: (name: string, value: string) => void;
  ID?: string;
  value?: any;
  subtitle?: string;
  parentClass?: string;
}

export const DeprecatedRadio = (props: DeprecatedRadioProps) => {
  const {
    defaultChecked = false,
    input,
    type,
    classNames,
    name,
    placeholder,
    disabled,
    title = '',
    reduxform = false,
    handleSelect = () => {},
    ID,
    value,
    subtitle = '',
    parentClass = '',
  } = props;

  const handleSelectFn = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    handleSelect(name, value);
  };

  const disable = disabled === true || disabled === 'true' ? true : false;

  return (
    <label className={'radioControl ' + parentClass}>
      {title}
      <p>{subtitle}</p>
      {input && input.name === 'viewsupplierinfortabulation' ? (
        <input
          {...input}
          type={type}
          className={classNames}
          name={name}
          placeholder={placeholder}
          disabled={disable}
          checked={defaultChecked}
          defaultChecked={defaultChecked}
        />
      ) : reduxform ? (
        <input
          {...input}
          type={type}
          className={classNames}
          name={name}
          placeholder={placeholder}
          disabled={disable}
          defaultChecked={defaultChecked}
        />
      ) : (
        <input
          type='radio'
          onClick={handleSelectFn}
          id={ID}
          key={ID}
          value={value}
          name={name}
          checked={defaultChecked}
          defaultChecked={defaultChecked}
          disabled={disable}
        />
      )}
      <span className='checkmark' />
    </label>
  );
};
