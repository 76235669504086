import styled from 'styled-components';

import { choiceRem, halfChoiceRem } from './../assets/ChoiceStyles';

import { BackgroundColor, BorderColor } from '../styles';
interface BaseRadioProps {
  checked: boolean;
  deactivated?: boolean;
}

export const BaseRadio = styled.span<BaseRadioProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${choiceRem};
  width: ${choiceRem};
  border: 2px solid
    ${({ checked, deactivated }) =>
      checked && !deactivated ? BorderColor.Checked : BorderColor.Base};
  background-color: ${({ deactivated }) =>
    deactivated ? BackgroundColor.Deactivated : BackgroundColor.BaseWhite};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    top: 3px;
    left: 3px;
    width: ${halfChoiceRem};
    height: ${halfChoiceRem};
    border-radius: 50%;
    background: ${({ deactivated }) => (deactivated ? BorderColor.Base : BorderColor.Checked)};
  }
`;
