import React, { memo } from 'react';

import { SelectBox } from '.';
import { sortDirections } from '../../utils/constants';
import { anyParamType } from '../../types/paymentdetails';

interface PropsTypes {
  sortFields?: any;
  selectedFieldName?: string;
  onChange?: anyParamType;
  sortIcon?: string;
  onSortClick?: anyParamType;
  parentClass?: string;
}

function Sort(props: PropsTypes) {
  const {
    sortFields,
    selectedFieldName,
    onChange,
    sortIcon,
    onSortClick /*sortBy, sortOrder*/,
    parentClass,
  } = props;

  const getSortByOptions = (sortFields: any, selectedFieldName: string | undefined) => {
    const test = Object.keys(sortFields).map((fieldName: string, index) => ({
      id: index,
      title: sortFields[fieldName],
      value: fieldName,
      selected: fieldName === selectedFieldName ? true : false,
      key: 'sortby',
    }));
    return test;
  };

  return (
    <div className={'sortWrapper ' + parentClass}>
      <SelectBox
        label='Sort By'
        options={getSortByOptions(sortFields, selectedFieldName)}
        onChange={onChange}
      />
      <span
        className={
          'mdi ml-3' +
          (sortIcon === sortDirections.ASC ? ' mdi-sort-descending' : ' mdi-sort-ascending')
        }
        onClick={onSortClick}
        title={sortIcon === sortDirections.ASC ? 'Ascending' : 'Descending'}
      />
    </div>
  );
}

export default memo(Sort);
