import moment from 'moment-timezone';

import {
  integerVal,
  invalidDateMessage,
  phoneVal,
  usaDateFormat,
} from '../../../../utils/constants';
import { emailValidation } from '../../../../utils/helpers';

export const validate = (values = {} as any, props: any) => {
  const { addquotes } = props;
  const { quotesNumbers } = addquotes;
  const checkquotesNumbers = quotesNumbers.map((item: any) => item.quoteNumber) || [];

  const errors = {} as any;

  let optional = [
    'deliveryARO',
    'shipVia',
    'contactAddress2',
    'sameascontactinformation',
    'shipAddress2',
    'additionalspecifications',
    'insuranceandadditional',
    'termsandconditions',
    'canFinishQuote',
  ];

  if (values.sameascontactinformation === true) {
    optional = [
      ...optional,
      'shipAddress1',
      'shipCity',
      'shipCountryId',
      'shipDepartment',
      'shipName',
      'shipPhone',
      'shipPostalCode',
      'shipStateId',
    ];
  }

  const numbers = ['quantity'];
  const checknumber =
    Object.keys(values)
      .filter(items => numbers.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];
  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];
  const errorarr =
    required.filter(items => {
      const returnValue = items.value;
      return typeof returnValue === 'string' ? !returnValue.trim() : !returnValue;
    }) || [];
  errorarr.forEach(items => (errors[items.name] = 'Required'));
  const errornumarr =
    checknumber.filter(items => items.value && !integerVal.test(items.value)) || [];
  errornumarr.forEach(items => (errors[items.name] = 'Invalid'));

  if (
    values.quoteNo &&
    checkquotesNumbers.length > 0 &&
    checkquotesNumbers.includes(values.quoteNo)
  ) {
    errors.quoteNo = 'Already Exists';
  }

  if (values.deliveryARO && !/^([1-9]\d*)$/.test(values.deliveryARO)) {
    errors.deliveryARO = 'Invalid';
  }
  if (values.quoteNo && values.quoteNo.length > 10) {
    errors.quoteNo = 'Invalid';
  }
  if (values.contactPhone && !phoneVal.test(values.contactPhone)) {
    errors.contactPhone = 'Invalid';
  }
  if (values.shipPhone && !phoneVal.test(values.shipPhone)) {
    errors.shipPhone = 'Invalid';
  }

  if (emailValidation(values.contactEmail)) {
    errors.contactEmail = 'Invalid';
  }
  if (
    values.contactCountryId &&
    values.contactCountryId.value === 1 &&
    values.contactPostalCode &&
    !/(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/.test(values.contactPostalCode)
  ) {
    errors.contactPostalCode = 'Invalid';
  }
  if (
    values.contactCountryId &&
    values.contactCountryId.value === 5 &&
    values.contactPostalCode &&
    !/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(values.contactPostalCode)
  ) {
    errors.contactPostalCode = 'Invalid';
  }
  if (
    values.shipCountryId &&
    values.shipCountryId.value === 1 &&
    values.postalCode &&
    !/(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/.test(values.postalCode)
  ) {
    errors.postalCode = 'Invalid';
  }
  if (
    values.shipCountryId &&
    values.shipCountryId.value === 5 &&
    values.shipPostalCode &&
    !/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(values.shipPostalCode)
  ) {
    errors.shipPostalCode = 'Invalid';
  }
  const linewiresErrors = [] as any;
  values.linewires &&
    values.linewires.length &&
    values.linewires.forEach((item: any, index: number) => {
      const Errors = {} as any;
      if (index === 0 && (!item || !item.description.trim())) {
        Errors.description = 'Required';
        errors[`linewires[${index}].description`] = 'Required';
      }
      if (index === 0 && (!item || !item.quantity)) {
        Errors.quantity = 'Required';
        errors[`linewires[${index}].quantity`] = 'Required';
      }
      if (index > 0 && item.description && item.description.trim() && !item.quantity) {
        Errors.quantity = 'Required';
        errors[`linewires[${index}].quantity`] = 'Required';
      }
      /*
		if (index === 0 && (!item || !item.uom)) {
			Errors.uom = 'Required'
		}*/

      if (item.quantity && item.quantity !== 0 && !/^([0-9]\d*)$/.test(item.quantity)) {
        Errors.quantity = 'Invalid';
        errors[`linewires[${index}].quantity`] = 'Invalid';
      }
      if (Object.keys(Errors).length > 0) linewiresErrors[index] = Errors;
      return Errors;
    });
  if (linewiresErrors.length) {
    errors.linewires = linewiresErrors;
  }
  if (values.additionalspecifications && values.additionalspecifications.length > 700) {
    errors.additionalspecifications = 'Max length exceed';
  }
  if (values.insuranceandadditional && values.insuranceandadditional.length > 700) {
    errors.insuranceandadditional = 'Max length exceed';
  }
  if (values.termsandconditions && values.termsandconditions.length > 4096) {
    errors.termsandconditions = 'Max length exceed';
  }
  if (values.quotedueDate && !moment(values.quotedueDate, usaDateFormat).isValid()) {
    errors.quotedueDate = invalidDateMessage;
  }
  return errors;
};
