import DropIn from 'braintree-web-drop-in-react';
import React, { memo } from 'react';
import { appKeys } from '../../../utils/constants';

interface PaymentMethodProps {
  isEditPayment?: boolean;
  setPaymentInstance: (instance: unknown) => void;
  title?: string;
}

const PaymentMethod = ({
  isEditPayment = false,
  setPaymentInstance,
  title = '',
}: PaymentMethodProps) => {
  const message = isEditPayment
    ? 'Add a payment method'
    : 'Add a payment method to proceed with your purchase';

  const dropInOptions = {
    translations: {
      chooseAWayToPay: message,
    },
    authorization: appKeys.BTkey,
    card: {
      cardholderName: {
        required: true,
      },
    },
  } as any;

  return (
    <>
      {title ? <title>{title}</title> : null}
      {appKeys && appKeys.BTkey ? (
        <>
          <DropIn options={dropInOptions} onInstance={instance => setPaymentInstance(instance)} />
        </>
      ) : (
        <div>
          <h1>Loading...</h1>
        </div>
      )}
    </>
  );
};

export default memo(PaymentMethod);
