import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function setAddQuotesDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_ADD_QUOTES_DETAILS, payload);
}

export function resetAddQuotesDetails(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_ADD_QUOTES_DETAILS, payload);
}

export function submitAddQuotesInfo(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_QUOTES_INFO, payload);
}

export function submitAddQuotesWire(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_QUOTES_WIRE, payload);
}

export function getAddQuotesItems(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_QUOTES_ITEMS, payload);
}

export function getAddQuotesInfo(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_QUOTES_INFO, payload);
}

export function addQuotegetMemberInfo(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_QUOTE_UPDATE_MEMBER_INFO, payload);
}

export function getAddQuotesWire(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_QUOTES_WIRE, payload);
}

export function submitAddQuotesDocumentUpload(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD, payload);
}

export function getAddQuotesDocuments(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_QUOTES_DOCUMENTS, payload);
}

interface SubmitAddQuotesDocumentDeletePayload {
  quoteDocId: number;
  quoteId: number;
}

export function submitAddQuotesDocumentDelete(payload: SubmitAddQuotesDocumentDeletePayload) {
  return action(actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_DELETE, payload);
}

export function submitAddQuotesMoveNext(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_QUOTES_MOVE_NEXT, payload);
}

export function getAddQuotesCompletedPage(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_QUOTES_COMPLETED_PAGE, payload);
}

export function getAddQuoteCanComplete(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_QUOTES_CAN_COMPLETE, payload);
}

export function submitAddQuoteComplete(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_QUOTES_COMPLETE, payload);
}

export function getAddQuoteNumbers(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_QUOTES_NUMBERS, payload);
}

export function resetAddquotes(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_ADD_QUOTES, payload);
}
