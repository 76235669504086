// TOREFACTOR

import moment from 'moment-timezone';
import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import {
  deprecatedGetDate,
  getTimeIntervals,
  getTimeZone,
  setTimeToDate,
  toastFn,
} from '../../../../utils/helpers';
import { Buttons, SelectBox } from '../../../customcontrols';
import { DeprecatedReduxDatepicker } from '../../../customcontrols/DeprecatedReduxDatepicker';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { usaDateFormat } from '../../../../utils/constants';

import { confirmbroadcastdocumentsText } from '../../../../utils/texts';
import { useDispatch } from 'react-redux';
import {
  getAddBidChangeDue,
  getaddBidGetAwardees,
  setAddBidDetails,
  submitAddBidDocumentUpload,
  submitAddBidInfo,
  submitBidAwardees,
} from '../../../../store/actions';
import { useDSSelector } from '../../../../store/reducers';

const initialSingleDoc = {
  selectduedates: { label: 'No', value: 'No' },
  duedates: new Date() as any,
  duetimes: '' as any,
  duetimezones: '' as any,
};

const divStyle = {
  td1: {
    width: '170px',
    valign: 'top',
  },
  td2: {
    width: '60%',
  },
  td3: {
    width: '35%',
  },
  td4: {
    width: '5%',
  },
};

interface PropsTypes {
  bidId: number;
  page?: 'bidinfo';
}

function DocumentsOptions(props: PropsTypes) {
  const dispatch = useDispatch();
  const { bidId, page } = props;
  const addbidinfo = useDSSelector(state => state.addbid.addbidinfo);
  const addAwardConfirmPopup = useDSSelector(state => state.addbid.addAwardConfirmPopup || false);

  const invaliddateerror = useDSSelector(state => state.shared.invaliddateerror);
  const [docValidation, setDocValidation] = useState(false);
  const [singleDocument, setSingleDocument] = useState(initialSingleDoc);
  const { dueDate, dueTime, dueTimeZone } = addbidinfo || {};

  useEffect(() => {
    if (bidId) dispatch(getaddBidGetAwardees({ bidId }));
  }, [bidId, dispatch]);

  const onChange = (name: string, value: any) => {
    if (name === 'selectduedates') {
      setSingleDocument({
        selectduedates: value,
        duedates: dueDate,
        duetimes: dueTime,
        duetimezones: dueTimeZone,
      });
    } else {
      setSingleDocument({ ...singleDocument, [name]: value });
    }
    if (docValidation) {
      setDocValidation(false);
    }
  };
  const { duedates = '', selectduedates, duetimes, duetimezones } = singleDocument;

  const continueDoc = () => {
    dispatch(setAddBidDetails({ documentsoptionsmodal: false }));
    dispatch(submitAddBidDocumentUpload({ bidId }));
  };

  const cancelBroadcastListModal = () => {
    dispatch(setAddBidDetails({ addAwardConfirmPopup: false }));
  };

  const approveAction = () => {
    cancelBroadcastListModal();
    dispatch(submitBidAwardees({ bidId }));
    if (page === 'bidinfo') dispatch(submitAddBidInfo({ data: addbidinfo }));
  };

  const ContimeDocumentFn = () => {
    if (selectduedates.value === 'Yes' && duedates && duetimes && duetimezones) {
      const dueDate = setTimeToDate(duedates, `${duetimes.value} ${duetimezones.value}`);
      dispatch(getAddBidChangeDue({ bidId, dueDate }));
    } else if (selectduedates.value === 'No') {
      continueDoc();
    } else {
      setDocValidation(true);
      toastFn(
        'error',
        'Please fill all required fields',
        'document upload fill all required fields no data 6594',
      );
    }
  };

  return (
    <>
      <div className=''>
        <Table className='tableData' borderless>
          <tr>
            <th style={divStyle.td1}>Current Due Date:</th>
            <td>
              {' '}
              {deprecatedGetDate(dueDate)} {dueTime && dueTime.key} {dueTimeZone && dueTimeZone.key}
            </td>
          </tr>
          <tr>
            <th style={divStyle.td1}>Change Date</th>
            <td>
              <SelectBox
                reactselect={true}
                name='selectduedates'
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                value={selectduedates}
                handleSelect={(name: string, value: string) => onChange(name, value)}
                meta={{ touched: docValidation && !selectduedates, error: 'Required' }}
              />
            </td>
          </tr>
          {selectduedates && selectduedates.value === 'Yes' ? (
            <tr>
              <th style={divStyle.td1}>Date & Time</th>
              <td>
                <DeprecatedReduxDatepicker
                  label='Due Date'
                  name='duedates'
                  classNames='class'
                  minDate={moment()}
                  handleChange={(name: string, value: moment.Moment) => onChange(name, value)}
                  value={moment(duedates)}
                  meta={{ touched: docValidation && !duedates, error: 'Required' }}
                  optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                  parentClass='clearfix'
                />
                <div className='timePickerWrapper clear-both mt-2'>
                  <div>
                    <SelectBox
                      reactselect={true}
                      label='Time'
                      name='duetimes'
                      options={getTimeIntervals()}
                      value={duetimes}
                      handleSelect={(name: string, value: string) => onChange(name, value)}
                      meta={{ touched: docValidation && !duetimes, error: 'Required' }}
                    />
                    <SelectBox
                      reactselect={true}
                      label=''
                      name='duetimezones'
                      options={getTimeZone()}
                      value={duetimezones}
                      handleSelect={(name: string, value: string) => onChange(name, value)}
                      meta={{ touched: docValidation && !duetimezones, error: 'Required' }}
                    />
                  </div>
                </div>
              </td>
            </tr>
          ) : null}
        </Table>
        <Buttons
          classNames='bttn-primary w-100 clearfix mt-3'
          text='Continue'
          title='Continue'
          type='button'
          onClick={ContimeDocumentFn}
          disable={
            (selectduedates.value === 'Yes' && (!duedates || !duetimes || !duetimezones)) ||
            invaliddateerror
          }
        />
      </div>
      <ConfirmModal
        confirmMessage={confirmbroadcastdocumentsText}
        approveAction={approveAction}
        closeModal={cancelBroadcastListModal}
        onlyApprove={true}
        title='Confirm Changes'
        isOpen={addAwardConfirmPopup}
      />
    </>
  );
}

export default memo(DocumentsOptions);
