/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
export function dynamicSort(property: any) {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a: { [x: string]: any }, b: { [x: string]: any }) {
    if (typeof a[property] === 'string') {
      if (Number(sortOrder) === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    } else {
      if (Number(sortOrder) === -1) {
        return a[property] === b[property] ? 0 : a[property] ? 1 : -1;
      } else {
        return a[property] === b[property] ? 0 : a[property] ? -1 : 1;
      }
    }
  };
}

(window as any).dynamicSort = dynamicSort;

//Function to download Files
export function downloadFile(response: any, filename = 'Document') {
  const fileType = response.data.type;
  const contentDisposition = response.headers['content-disposition']
    ? response.headers['content-disposition']
    : '';
  if (contentDisposition !== '') {
    const strNames = contentDisposition.split(';');
    const strName = strNames.find((cont: string) => cont.trim().indexOf('filename=') >= 0);

    filename = strName
      ? strName.replace('filename=', '').replace(/\./g, ' ').replace(/"/g, '').trim()
      : filename;
  }

  const file = new Blob([response.data], { type: fileType });

  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);

  const link = document.createElement('a');
  link.href = fileURL;
  link.download = filename;
  if (
    // TODO: TS4 - msSaveOrOpenBlob does not exist on windows.navigator
    (window.navigator && (window.navigator as any).msSaveOrOpenBlob) ||
    window.navigator.userAgent.indexOf('Firefox') !== -1
  ) {
    let fileextension = 'unknown';
    const newvalue = acceptedfileformates.find(item => item.mimeType.trimEnd() === fileType) || '';
    if (newvalue && newvalue.docFormat) {
      fileextension = newvalue.docFormat.toLowerCase().trimEnd();
    }
    link.setAttribute('download', `${filename}.${fileextension}`);
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    //window.open(fileURL)
    window.URL.revokeObjectURL(fileURL);
  }, 10000);
}

const acceptedfileformates = [
  {
    docFormat: 'PDF',
    mimeType: 'application/pdf',
    description: 'Adobe Acrobat (*.PDF )',
  },
  { docFormat: 'DWG', mimeType: 'image/vnd.dwg', description: 'AutoCAD Drawing (*.DWG )' },
  {
    docFormat: 'DWF',
    mimeType: 'drawing/x-dwf',
    description: 'AutoCAD Drawing Web Format (*.DWF )',
  },
  { docFormat: 'GIF', mimeType: 'image/gif', description: 'GIF Image (*.GIF )' },
  { docFormat: 'JPG', mimeType: 'image/jpeg', description: 'JPEG Image (*.JPG )' },
  {
    docFormat: 'XLS',
    mimeType: 'application/vnd.ms-excel',
    description: 'Microsoft Excel (*.XLS )',
  },
  {
    docFormat: 'XLSX',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    description: 'Microsoft Excel (*.XLSX)',
  },
  {
    docFormat: 'PPT',
    mimeType: 'application/ppt',
    description: 'Microsoft PowerPoint (*.PPT )',
  },
  {
    docFormat: 'DOC',
    mimeType: 'application/msword',
    description: 'Microsoft Word (*.DOC )',
  },
  {
    docFormat: 'DOCX',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    description: 'Microsoft Word (*.DOCX)',
  },
  { docFormat: 'TXT', mimeType: 'text/plain', description: 'Plain Text (*.TXT )' },
  { docFormat: 'PLT', mimeType: 'image/plt', description: 'Plot file (*.PLT )' },
  { docFormat: 'RTF', mimeType: 'application/rtf', description: 'Rich Text Format (*.RTF )' },
  { docFormat: 'TIF', mimeType: 'image/tiff', description: 'TIFF Image (*.TIF )' },
  { docFormat: 'WPD', mimeType: 'application/wordperfect', description: 'WordPerfect (*.WPD )' },
  { docFormat: 'ZIP', mimeType: 'application/zip', description: 'ZIP Compressed Archive (*.ZIP )' },
  {
    docFormat: 'PPTX',
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    description: 'Microsoft PowerPoint',
  },
];
