import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function setQuotesDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_QUOTES_DETAILS, payload);
}

export function getQuotesList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_QUOTES_LIST, payload);
}

export function getContactList(payload: actionPayloadTypes) {
  return action(actionTypes.GET_QUOTES_CONTACT_LIST, payload);
}

export function getQuoteStatus(payload: actionPayloadTypes) {
  return action(actionTypes.GET_QUOTES_STATUS, payload);
}

export function QuoteSummaryDeleteQuote(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_DELETE_QUOTE, payload);
}

export function setQuotesFilter(payload: actionPayloadTypes) {
  return action(actionTypes.SET_QUOTES_FILTERS, payload);
}

export function resetQuotes(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_QUOTES, payload);
}
