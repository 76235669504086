import React, { useState } from 'react';
import ViewHistory from '../../../buyer/biddetail/tabs/ebidresponse/viewhistory';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { Buttons } from '../../../customcontrols/index';

interface PropsTypes {
  responseId?: string;
  buyers?: boolean;
}

function ViewResponseHistory(props: PropsTypes) {
  const { responseId = '' } = props;
  const [modalOpen, setModelOpen] = useState(false);

  const handleClick = () => {
    setModelOpen(!modalOpen);
  };

  return (
    <>
      <Buttons
        onClick={handleClick}
        text='View History'
        classNames='bttn-secondary small float-right'
      />
      <ModalPopUp title='History' size='xl' isOpen={modalOpen} closeModal={handleClick}>
        <ViewHistory {...props} responseId={responseId} />
      </ModalPopUp>
    </>
  );
}

export default ViewResponseHistory;
