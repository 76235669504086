//TODO: Delete this. Use library instead.

enum Color {
  // Primary Action
  Purple = '#593e87',
  PurpleDark = '#372458',

  // Secondary Action
  TealLight = '#c3d4d6',
  Teal = '#3395a4',
  TealDark = '#1f6a85',

  // Reds
  Red = '#c7362d',
  Vermilion = '#dc3826',

  // Base
  White = '#FFFFFF',
  Black = '#111111',
}

export const BaseColor = {
  white: Color.White,
  black: Color.Black,
};

export const ActionColor = {
  primary: Color.Purple,
  primaryHighlight: Color.PurpleDark,
  secondary: Color.TealDark,
  secondaryHighlight: Color.TealLight,
};

export const ErrorColor = {
  base: Color.Red,
  highlight: Color.Vermilion,
};

// TODO: Establish a two tiered Color variable system. On one tier, we have all our colors and their hex codes.
// On the next, we have the uses of the color. The uses should not reference a hex code directly,
// but one of the tier one labels for the hexes.

export enum Colors {
  action = '#1f6a85', // blueGreenDark?
  black = '#111111',
  blueGreenArrowHighlight = '#d2dfe1',
  blueGreenLight = '#c3d4d6',
  blueGreenMedium = '#3395a4',
  complete = '#609B58', // dark green
  disabled = '#f5f5f5', // gray
  disabledBackground = '#f5f5f5',
  disabledText = '#7a7a7a',
  error = '#c7362d', // red
  grayBgHighlight = '#EAEAEA',
  grayDark = '#111111',
  grayDarker = '#7a7a7a',
  grayInputBorder = '#b3b3b3',
  grayLight = '#f5f5f5',
  grayMedium = '#dddddd',
  grayMediumDark = '#404040',
  grayMediumLight = '#eaeaea',
  grayTableRowBorder = '#e5e5e5',
  greenMedium = '#3f9421',
  orangeLight = '#ffb84a',
  orangeMedium = '#ff9f0e',
  primaryAction = '#593E87',
  redText = '#dc3826',
  transparent = 'transparent',
  white = '#ffffff',
  yellowMedium = '#f8df7b',
}
