import React, { memo, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { appKeys } from '../../../utils/constants';
import * as actionCreators from '../../../store/actions';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReduxAction } from '../../../store/actions/utils';
import { AgencySelectionContainer } from '../../../containers/AgencySelectionContainer';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import BidPlans from '../BidPlans/BidPlans';
import { BasicUserPlans } from '../BasicUserPlans/BasicUserPlans';
import { SetBreadcrumbPayload } from '../../../store/actions';

type PropsFromRedux = Partial<ConnectedProps<typeof connector>>;

type Props = PropsFromRedux &
  RouteComponentProps & {
    setBreadcrumb: (payload: SetBreadcrumbPayload) => ReduxAction;
    setAccountInfoDetails: (payload?: Record<string, unknown>) => ReduxAction;
    getDynamicProductPrices: (payload?: Record<string, unknown>) => ReduxAction;
    agencySelection: boolean;
    productPrices: { countyPrice: number; statePrice: number; nationalPrice: number };
  };

interface ReduxState {
  accountinfo: { agencySelection: boolean };
  shared: { productPrices: { countyPrice: number; statePrice: number; nationalPrice: number } };
}

export function Plans(props: Props) {
  const {
    setBreadcrumb,
    setAccountInfoDetails,
    getDynamicProductPrices,
    agencySelection,
    location: { search },
    productPrices,
  } = props;

  const bidId: string | null = new URLSearchParams(search).get('bidId');

  useEffect(() => {
    setBreadcrumb({
      component: '',
      page: 'Supplier Plans',
      title: `${appKeys.headers.bidPlans}`,
      altname: appKeys.headers.plans,
      name: 'Supplier Plans',
    });
  }, [setBreadcrumb]);

  useEffect(() => {
    getDynamicProductPrices();
  }, [getDynamicProductPrices]);

  const toggleModal = useCallback(() => {
    return setAccountInfoDetails({ agencySelection: !agencySelection });
  }, [setAccountInfoDetails, agencySelection]);

  return (
    <div className='container' id='purchase-plan'>
      <div className='row detailViewWrapper'>
        <div className='col-12'>
          <div className='colWrapper clearfix'>
            {bidId ? (
              <BidPlans bidId={bidId} productPrices={productPrices} toggleModal={toggleModal} />
            ) : (
              <BasicUserPlans productPrices={productPrices} toggleModal={toggleModal} />
            )}
          </div>
        </div>
      </div>
      <ModalPopUp
        size='lg'
        title='Upgrade to free agency'
        closeModal={toggleModal}
        isOpen={agencySelection === true}
        backdrop='static'
      >
        <AgencySelectionContainer toggleAgencySelection={toggleModal} />
      </ModalPopUp>
    </div>
  );
}

const connector = connect(
  (state: ReduxState) => ({
    agencySelection: state?.accountinfo?.agencySelection || false,
    productPrices: state?.shared?.productPrices || false,
  }),
  dispatch => {
    const { setBreadcrumb, setAccountInfoDetails, getDynamicProductPrices } = bindActionCreators(
      { ...actionCreators },
      dispatch,
    );
    return { setBreadcrumb, setAccountInfoDetails, getDynamicProductPrices };
  },
);

export default connector(withRouter(memo(Plans)));
