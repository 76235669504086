import React, { memo, useState } from 'react';
import { Table } from 'reactstrap';
import { lineItemsSortFields, pageSize, sortDirections } from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import { SortingFn } from '../../../../../utils/helpers';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import QuoteBreakdown from '../../../../common/quotebreakdown';
import { Paging, Sort } from '../../../../customcontrols';
import NoResult from '../../../../customcontrols/noresult';
import Supplierdetails from './details/supplierdetails';
import { LineItemType, QuoteSummaryType } from '../../../../../types/quotedetailstypedef';
import { addbidParamType } from '../../../../../types/addbid';
import { sharedTypes } from '../../../../../types/shared';

const tdWidth = {
  width: '180px',
};
interface PropsTypes {
  setQuoteSummaryDetails: any;
  quoteSummary: QuoteSummaryType;
  getQuotelineItemSuppliers?: any;
  userpagefor?: string;
  deleteLineItem?: any;
  quoteId: any;
  shared: sharedTypes;
  addbid: addbidParamType;
}
const LineItems = (props: PropsTypes) => {
  const { setQuoteSummaryDetails, quoteSummary, getQuotelineItemSuppliers, deleteLineItem } = props;
  const {
    lineItems = [],
    currentLineItem,
    lineItemSuppliers,
    lineitemsdetailsmodal,
    lineitems_internalLoader,
    summary,
    currentPage = 1,
  } = quoteSummary || {};
  const { quoteId, statusType } = summary || {};

  const listPerPage = pageSize.quoteResponses;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const [sortvalue, setSortvalue] = useState('description');
  const [sortorder, setSortorder] = useState(sortDirections.DESC);

  const changeSortSelectBox = (key: string, value: string) => {
    if (value !== sortvalue) {
      setSortvalue(value);
      const type = value === 'quantity' ? 'number' : 'text';
      setQuoteSummaryDetails({ lineItems: SortingFn(lineItems, sortorder, value, type) });
    }
  };

  const changeSortOrder = () => {
    const sort =
      sortorder === sortDirections.DESC
        ? sortDirections.ASC
        : sortorder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort;
    setSortorder(
      sortorder === sortDirections.DESC
        ? sortDirections.ASC
        : sortorder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort,
    );
    const type = sortvalue === 'quantity' ? 'number' : 'text';
    setQuoteSummaryDetails({ lineItems: SortingFn(lineItems, sort, sortvalue, type) });
  };

  const setCurrentLineitem = (item: LineItemType, index: any) => {
    getQuotelineItemSuppliers({ quoteItemId: item.quoteItemId, quoteId: item.quoteId });
    setQuoteSummaryDetails({ currentLineItem: item });
  };

  const deleteCurrentLineItem = () => {
    deleteLineItem({ quoteItemId: currentLineItem.quoteItemId, quoteId, quoteStatus: statusType });
  };

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h3 className='mb-3 arrowWrapper'>Line Items</h3>
        </div>
        <div className='col-lg-4'>
          <div className='ulListWrapper'>
            <h4>Line Items ({lineItems.length})</h4>
            <header className='headerFilter d-flex align-items-center clearfix pl-0'>
              <Sort
                sortFields={lineItemsSortFields}
                selectedFieldName={sortvalue}
                onChange={changeSortSelectBox}
                onSortClick={changeSortOrder}
                sortIcon={sortorder}
                parentClass='flex1'
              />
            </header>
            {lineItems.length > 0 ? (
              <>
                <ul className='list-unstyled clearfix'>
                  {lineItems
                    .map((item, index) => (
                      <li
                        key={index}
                        className={currentLineItem.quoteItemId === item.quoteItemId ? 'active' : ''}
                        onClick={() => setCurrentLineitem(item, index)}
                      >
                        <h6 className='mw-100 text-truncate'>{item.description}</h6>
                        <span>
                          <i>Quantity :</i> {item.quantity} {item.uom}
                        </span>
                        {item.deliveryARO && (
                          <span>
                            <i>Delivery (days ARO) :</i> {item.deliveryARO}
                          </span>
                        )}
                      </li>
                    ))
                    .slice(indexOfFirstList, indexOfLastList)}
                </ul>
                <Paging
                  totalRecords={lineItems.length}
                  currentPage={currentPage}
                  pagefor='lineitems'
                  onPagingClick={(value: number) => {
                    if (value !== currentPage)
                      setQuoteSummaryDetails({ currentPage: Number(value) });
                  }}
                  pageLimit={listPerPage}
                />
              </>
            ) : (
              <NoResult
                message={lineitems_internalLoader ? loadingMsg : 'No Line Items Available'}
              />
            )}
          </div>
        </div>
        <div className='col-lg-8'>
          {' '}
          <>
            <h4>Line Item Details</h4>
            {lineItems.length > 0 ? (
              <>
                <Table borderless className='tableData'>
                  <tbody>
                    <tr>
                      <th style={tdWidth}>Line Item</th>
                      <td>{currentLineItem.description}</td>
                    </tr>
                    <tr>
                      <th style={tdWidth}>Quantity</th>
                      <td>
                        {currentLineItem.quantity} {currentLineItem.uom}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {lineItemSuppliers.length === 0 &&
                lineItems.length > 1 &&
                ['OP', 'IN'].includes(statusType) ? (
                  <button onClick={deleteCurrentLineItem} className='bttn bttn-secondary'>
                    Remove
                  </button>
                ) : null}
                <QuoteBreakdown pagefor='lineitems' {...props} breakDownlist={lineItemSuppliers} />
              </>
            ) : (
              <NoResult
                message={lineitems_internalLoader ? loadingMsg : 'No Line Items Available'}
              />
            )}
          </>
        </div>
      </div>
      <ModalPopUp
        size='xl'
        title='Supplier Details'
        closeModal={() => setQuoteSummaryDetails({ lineitemsdetailsmodal: false })}
        isOpen={lineitemsdetailsmodal}
        className='popUpSpacing'
        // TODO: what does this class name do? consider using a style component
        // and removing from here and from ModalPopUp.
      >
        {lineitemsdetailsmodal ? <Supplierdetails {...props} /> : ''}
      </ModalPopUp>
    </>
  );
};

export default memo(LineItems);
