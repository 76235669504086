import React, { memo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/actions';
import Buttons from '../customcontrols/button';
import OrderSummery from './ordersummery';
import Cart from './cart/Cart';
import CartTotal from './cart/CartTotal';
import navHistory from '../../utils/history';
import { registrationComponent } from '../../utils/constants';

const OrderConfirmation = (props: any) => {
  const {
    subscriptions,
    setSubscriptionDetails,
    setRegistrationData,
    registration,
    pagefor = '',
  } = props;
  const {
    complimentary = false,
    OrderNumber = 0,
    allProducts = [],
    subscribedStates = [],
    subscribedCounties = [],
    subscribedNational = [],
    memberSubscriptionInfo = '',
  } = subscriptions;

  const nationalProduct = allProducts.find((item: any) => item.productType === 'NA') || {};

  let selectedAllProducts = [];
  selectedAllProducts = allProducts
    .filter(
      (item: any) =>
        subscribedStates.includes(item.productId) ||
        subscribedCounties.includes(item.productId) ||
        subscribedNational.includes(item.productId),
    )
    .map((mItem: any) => mItem.price);
  const totalCartPrice =
    selectedAllProducts.length > 0
      ? selectedAllProducts.length === 1
        ? selectedAllProducts[0]
        : selectedAllProducts.reduce((a: number, b: number) => a + b)
      : 0;

  const { registrationData = {} } = registration || {};

  const viewReceiptHandle = () => {
    if (pagefor === 'registration') {
      setRegistrationData({ currentComponent: registrationComponent.PrintReceipt });
    } else setSubscriptionDetails({ currentStep: 4 });
  };

  const isRenewalAvail =
    memberSubscriptionInfo.isRenewalAllowed &&
    memberSubscriptionInfo.memberDetails.daysToRenew > 0 &&
    memberSubscriptionInfo.memberDetails.daysToRenew < 90;

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='colWrapper'>
              <h2 className='arrowWrapper'>Order Confirmation</h2>
              <div className='row mb-4'>
                <div className='col-12'>
                  <h4>Thank you for your order.</h4>
                  <p>Your order number is: {OrderNumber}</p>
                  {pagefor === 'registration' ? (
                    <p>
                      You will receive a confirmation email shortly at the email address we have
                      associated with your account:{' '}
                      <a className='staticLink' href={`mailto:${registrationData.emailAddress}`}>
                        {registrationData.emailAddress}
                      </a>
                    </p>
                  ) : (
                    <p>Your subscriptions will automatically renew on this day every year.</p>
                  )}

                  {/* <p className='text-red200'>Kindly logout and login again to view upgraded/renewed subscription features.</p> */}
                </div>
                <div className='col-12'>
                  <Buttons
                    type='button'
                    classNames='bttn-primary '
                    text='Select Your Commodity Codes'
                    onClick={() => navHistory.push('/suppliers/account/commoditycodes')}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h4 className='lineHR'>Your Subscriptions</h4>
                  <div className='px-3'>
                    <Cart isReview={true} isRenewal={isRenewalAvail} showDelIcon={false} />
                    <hr className='lineHR' />
                    <CartTotal
                      disabled={props.disabled}
                      isRenewal={props.isRenewal}
                      isReview={true}
                      totalCartItems={selectedAllProducts.length}
                      totalCartPrice={
                        subscribedNational.length > 0
                          ? nationalProduct
                            ? nationalProduct.price
                            : 2000
                          : totalCartPrice
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='sideBarSpacing'>
              <h4>Your Order Has Been Placed</h4>
              <OrderSummery {...props} />
              <Buttons
                type='button'
                classNames='bttn-primary w-100 my-4'
                text='View Your Receipt'
                onClick={viewReceiptHandle}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    auth: state.auth,
    shared: state.shared,
    subscriptions: state.subscriptions,
    memberinfo: state.memberinfo,
    accountinfo: state.accountinfo,
    payment: state.payment,
    registration: state.registration,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(OrderConfirmation));
