import { atom } from 'recoil';

/*************************************************
 * Wizard State
 ************************************************/

export enum AwardWizardId {
  None,
  SelectAwardees,
  AddVendorDocs,
  ListRequiredDocs,
  AddPublicDocs,
  NotifyAwardees,
  NotifyUnselected,
  NotifyColleagues,
  FinalizeAward,
}

/**
 * This is a potential selector for starting the award bid process on the correct page.
 * Not yet implemented. Don't want to introduce last minute bugs.
 */

// const awardWizardIdStateSelector = selector<AwardWizardId>({
//   key: 'awardWizardIdStateSelector',
//   get: async ({ get }) => {
//     const awardees = get(bidAwardeeListState);
//     if (awardees.length) {
//       return AwardWizardId.AddVendorDocs;
//     }
//     return AwardWizardId.SelectAwardees;
//   },
// });

export const awardWizardIdState = atom<AwardWizardId>({
  key: 'awardWizardIdState',
  default: AwardWizardId.SelectAwardees,
});

export const messagePreviewState = atom<boolean>({
  key: 'messagePreviewState',
  default: false,
});
