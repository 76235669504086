import React from 'react';
import { Table } from 'reactstrap';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';

const tdWidth = { width: '240px' };

interface PropsTypes {
  quoteSummary: QuoteSummaryType;
}
function Quotereplyinfo(props: PropsTypes) {
  const { quoteSummary } = props;
  const { quotereplyinfo } = quoteSummary;
  const { delivery = '', additionalSupplierInfo = '', preparedBy = ' ' } = quotereplyinfo;
  return (
    <Table borderless className='tableData'>
      <tbody>
        <tr>
          <th style={tdWidth}>Delivery</th>
          <td>{delivery}</td>
        </tr>
        <tr>
          <th style={tdWidth}>Additional Supplier Details</th>
          <td>{additionalSupplierInfo}</td>
        </tr>
        <tr>
          <th style={tdWidth}>Quote Prepared By</th>
          <td>{preparedBy}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default Quotereplyinfo;
