import * as actionTypes from '../actionTypes';
import { ebidTypes } from '../../types/ebidcampaign';
import { payloadTypes } from '../../types/actiontypedef';
import { WizardStatus } from '../../types/wizard';

export const initialStateEBidscampaign: ebidTypes = {
  campaignstatus: false,
  ebidCampaignWizard: [
    { status: WizardStatus.Current, id: 1, name: 'Campaign Intro' },
    { status: WizardStatus.Unavailable, id: 2, name: 'Not Now' },
    { status: WizardStatus.Unavailable, id: 3, name: 'Comments' },
    { status: WizardStatus.Unavailable, id: 4, name: 'Thanks' },
  ],
};

export const reducer = (state = initialStateEBidscampaign, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.LOAD_CAMPAIGN_STATUS.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.SET_CAMPAIGN_DETAILS.TRIGGER:
      state = { ...state, ...payload };
      break;
    default:
      break;
  }
  return state;
};
