import React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { AwardWizardId, bidAwardeeListState } from '../../../../../store/recoil/award-bid';
import { hasValue, isValidEmailInfo, useAssert } from '../../../../../utils/helpers';
import { MessagePreviewRow, Section } from './../../styled/AwardBidStyled';
import { AwardNotificationBidDetails } from './../AwardNotificationBidDetails';
import { BidEmailPreview } from './../../notify-pages/BidEmailPreview';
import { bidSummaryState } from '../../../../../store/recoil/bidState';
import { CustomMessageType } from '../../../../../types/awardBid';
import { EditEmailInfo } from './../EditEmailInfo';
import { Email } from '../../../../common/controls/text/Email';
import { Label } from '../../../../common/controls/text/Label';
import { LoadableWrapper } from '../../../../../components/common/loading/LoadableWrapper';
import { MessagePreview } from './../MessagePreview';
import { NotificationRecipients } from './../NotificationRecipients';
import { notifyAwardeesState } from '../../../../../store/recoil/award-bid';
import { notifyAwardeeTexts } from '../../../../../utils/texts/agency/awardBid';
import { Phone } from '../../../../common/controls/text/Phone';
import { ReviewDocsText } from './ReviewDocsText';
import { useAwardBidWizard } from '../../../../../shared/hooks/award-bid';
import { WizardButtons } from '../../../../customcontrols/wizard/WizardButtons';

export const NotifyAwardees = () => {
  const { saveAwardBidWizard, returnToBidSummary, messagePreview } = useAwardBidWizard();
  const emailInfo = useRecoilValueLoadable(notifyAwardeesState);

  const bidSummary = useRecoilValueLoadable(bidSummaryState);

  const awardees = useRecoilValueLoadable(bidAwardeeListState);
  useAssert(hasValue(awardees), 'Expected: to have awardees loaded on this page');

  function onPrevious() {
    if (messagePreview) {
      saveAwardBidWizard(AwardWizardId.NotifyAwardees);
    } else {
      saveAwardBidWizard(AwardWizardId.AddPublicDocs);
    }
  }

  function onNext() {
    if (messagePreview) {
      saveAwardBidWizard(AwardWizardId.NotifyUnselected);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyAwardees, true);
    }
  }

  return (
    <>
      {messagePreview ? (
        <LoadableWrapper loadable={emailInfo}>
          {hasValue(emailInfo) && (
            <MessagePreview to={emailInfo.contents.recipients} subject={emailInfo.contents.subject}>
              <BidEmailPreview
                emailInfo={emailInfo.contents}
                addressee={
                  awardees.contents.length === 1
                    ? awardees.contents[0].supplierName
                    : '{{COMPANY_NAME}}'
                }
              />
              <br />
              <p>{notifyAwardeeTexts.copy.ifYouHaveQuestions}</p>
              <Label>{emailInfo.contents.contactName}</Label>
              <MessagePreviewRow>
                <Label>{notifyAwardeeTexts.labels.contactEmail}:</Label>
                <Email>{emailInfo.contents.contactEmail}</Email>
              </MessagePreviewRow>
              <MessagePreviewRow>
                <Label>{notifyAwardeeTexts.labels.phoneNumber}:</Label>
                <Phone>{emailInfo.contents.contactPhone}</Phone>
              </MessagePreviewRow>
              <MessagePreviewRow>
                <Label>{notifyAwardeeTexts.labels.jobTitle}:</Label>
                <span>{emailInfo.contents.contactTitle}</span>
              </MessagePreviewRow>
              {hasValue(bidSummary) && (
                <Section>
                  <ReviewDocsText />
                </Section>
              )}
            </MessagePreview>
          )}
        </LoadableWrapper>
      ) : (
        <>
          {hasValue(emailInfo) && (
            <NotificationRecipients recipients={emailInfo.contents.recipients} />
          )}
          <EditEmailInfo
            emailInfoState={notifyAwardeesState}
            includeContactInfo={true}
            customMessageType={CustomMessageType.Awardees}
          />
          <AwardNotificationBidDetails requiresDocs={true} />
        </>
      )}
      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={messagePreview ? undefined : 'Preview'}
        save={returnToBidSummary}
        disableNext={!hasValue(emailInfo) || !isValidEmailInfo(emailInfo.contents)}
      ></WizardButtons>
    </>
  );
};
