import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

export const initialStateQuoteSummary = {
  summary: '',
  suppliers: [],
  lineItems: [],
  documents: [],
  currentLineItem: '',
  breakDownlist: [],
  responsesStatus: [],
  lineItemSuppliers: [],
  statistics: '',
  lineitemsdetailsmodal: false,
  quotereplyinfo: {
    delivery: '',
    additionalSupplierInfo: '',
    preparedBy: '',
    linewires: [],
  },
  currentSupplier: '',
  quotetotalslineItems: [],
  finishlater: false,
  replydetails: false,
  quotesView: {},
  selectedbreakdownlist: [],
  quoteDocuments: [],
  getcurrentsupplierAPICALL: true,
  lineitems_internalLoader: true,
  lineitems_supplier_internalLoader: true,
  response_internalloader: true,
};

export const reducer = (state = initialStateQuoteSummary, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.RESET_QUOTE_SUMMARY_DETAILS.TRIGGER:
      state = { ...initialStateQuoteSummary, currentSupplier: '', suppliers: [] };
      break;
    case actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER:
    case actionTypes.GET_QUOTE_SUMMARY_QUOTES_REPLY.SUCCESS:
    case actionTypes.GET_QUOTE_SUMMARY.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS.SUCCESS:
      state = {
        ...state,
        lineItems: payload,
        currentLineItem: payload.length > 0 ? payload[0] : '',
        lineitems_internalLoader: true,
      };
      break;
    case actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS.FAILURE:
      state = {
        ...state,
        lineitems_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_QUOTE_SUMMARY_DOCUMENTS.SUCCESS:
      state = {
        ...state,
        documents: payload,
        quoteDocuments: payload,
        lineitems_supplier_internalLoader: false,
      };
      break;
    case actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.SUCCESS:
      state = { ...state, lineItemSuppliers: payload, lineitems_supplier_internalLoader: false };
      break;
    case actionTypes.GET_QUOTE_SUMMARY_QUOTE_VIEW.SUCCESS:
      state = { ...state, quotesView: payload };
      break;
    case actionTypes.QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY.SUCCESS:
      state = { ...state, finishlater: false, replydetails: false };
      break;
    case actionTypes.RESET_QUOTE_SUMMARY.TRIGGER:
      state = initialStateQuoteSummary;
      break;
    default:
      break;
  }
  return state;
};
