import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../../shared/styles';

import { RequiredDocumentStatus } from '../../../../types';
import { StatusPill } from '../../controls/static/StatusPill';

const Wrapper = styled.div``;

/**
 * This cell renders a StatusPill based upon
 * the presence of the `bidAwardRequiredDocResponseId` field value
 * for the given document.
 * @param value
 */
export const RequiredDocumentStatusCell = (props: any) => {
  let bgColor: Colors = Colors.yellowMedium;
  let statusValue: RequiredDocumentStatus = 'Requested';
  let textColor: Colors = Colors.black;
  if (props?.row?.original?.bidAwardRequiredDocResponseId) {
    bgColor = Colors.complete;
    statusValue = 'Received';
    textColor = Colors.white;
  }
  return (
    <Wrapper>
      <StatusPill status={statusValue} backgroundColor={bgColor} textColor={textColor} />
    </Wrapper>
  );
};
