import { createActionTypes, createNoLoadActionTypes } from './utils';

export const CHANGE_EBID_PAGE = createActionTypes('CHANGE_EBID_PAGE');
export const SET_REQUIRED_DOCS = createNoLoadActionTypes('SET_REQUIRED_DOCS');
export const GET_EBIDS_FILE_FORMATS = createNoLoadActionTypes('GET_EBIDS_FILE_FORMATS');
export const GET_EBIDS_RESPONSE_DETAILS = createActionTypes('GET_EBIDS_RESPONSE_DETAILS');
export const SUBMIT_EBIDS_RESPONSE_DETAILS = createActionTypes('SUBMIT_EBIDS_RESPONSE_DETAILS');
export const SET_EBIDS_DETAILS = createActionTypes('SET_EBIDS_DETAILS');
export const SUBMIT_EBIDS_FILES_DOCS = createNoLoadActionTypes('SUBMIT_EBIDS_FILES_DOCS');
export const GET_EBID_RESPONSE = createNoLoadActionTypes('GET_EBID_RESPONSE');
export const SET_EBID_SUMMARY = createActionTypes('SET_EBID_SUMMARY');
export const SET_SELECTED_EBID_SUMMARY = createActionTypes('SET_SELECTED_EBID_SUMMARY');
export const RESET_EBIDS = createNoLoadActionTypes('RESET_EBIDS');
