import React, { memo } from 'react';
function AgencyAddUser() {
  return (
    <>
      <p>To Add Users, click on “Account”, then select the tab that says “User Accounts”: </p>
      img
      <p>Here you add users, reset passwords and set permission levels for each person. </p>
    </>
  );
}

export default memo(AgencyAddUser);
