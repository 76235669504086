import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { Column } from 'react-table';

import { Assert, getExtension } from '../../../../utils/helpers';
import { BoldText, SpanSpacerLeft, SpanSpacerRight } from '../../../../shared/styles';
import { BooleanTextCell, DateCell } from '../../../common/table/renderers';
import { commonLabels, ControlLabels, deleteMessages } from '../../../../shared/constants';
import { DocumentDownloadType, FileDeleteStatusMessage } from '../../../../types/document';
import { Id, ReadOnly } from '../../../../types/shared';
import { isDefined, noOpFunction } from '../../../../utils';
import { showToastError, showToastSuccess } from '../../../../utils/message';
import { TableBase, TableRowSelectionProps, TableStyleWrapper } from '../../../common/table';
import { useSelectedMemberType, useSupplierMemberId } from '../../../../shared/hooks/useMemberInfo';

import { ConfirmModal } from '../../../common/modals/ConfirmModal';
import { DeleteIconCell } from '../../../common/table/renderers/DeleteIconCell';
import { documentDownloadInProgressState } from '../../../../store/recoil/documentState';
import { DownloadLinkTextCell } from '../../../common/table/renderers/DownloadLinkTextCell';
import { downloadResponseDocument } from '../../../../store/services';
import { GetVendorSpecificDocumentsAPIResponse } from '../../../../types';
import { selectedVendorDocumentState } from '../../../../store/recoil/award-bid';
import { track } from '../../../../utils/telemetry';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';
import { useVendorDocuments } from '../../../../shared/hooks/award-bid/vendor-document';
import { vendorDocumentTableHeaders } from './constants';

/**
 * Table for the display of Vendor-specific documents in the Award Bid process
 * @param props: VendorDocumentsTableProps
 * @returns
 */
export function VendorDocumentsTable({ readOnly }: ReadOnly) {
  /// Hooks ///
  const { supplierMemberId } = useSupplierMemberId();
  const { selectedBidId } = useSelectedBidId();
  const { selectedMemberIsAgency } = useSelectedMemberType();

  /// Application state ///

  // - Vendor Documents
  const { deleteVendorDocument, refreshVendorDocuments, vendorDocumentList } = useVendorDocuments();

  // - Selected Vendor Document
  const [selectedVendorDocument, setSelectedVendorDocument] = useRecoilState(
    selectedVendorDocumentState,
  );
  const resetSelectedVendorDocument = useResetRecoilState(selectedVendorDocumentState);

  const [documentDownloading, setDocumentDownloading] = useRecoilState(
    documentDownloadInProgressState,
  );

  /**
   * Render cell text based upon row data.
   * @param original: parse the actual downaload file name by combining the `fileName` and extension from the `path`.
   * @returns
   */
  const renderFileName = useCallback((original: GetVendorSpecificDocumentsAPIResponse) => {
    const fileName = original.fileName;
    const extension = getExtension(original.path);
    return `${fileName}.${extension}`;
  }, []);

  useEffect(() => {
    refreshVendorDocuments();
  }, [refreshVendorDocuments, supplierMemberId, selectedBidId]);

  /// Local state
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  /// Column configurations
  const getVendorDocumentsColumns = ({
    handleDownloadClick,
  }: TableRowSelectionProps): Column<GetVendorSpecificDocumentsAPIResponse>[] => {
    const handleDownloadLinkClick = handleDownloadClick || noOpFunction;
    const columns: Column<GetVendorSpecificDocumentsAPIResponse>[] = [
      {
        Header: vendorDocumentTableHeaders.fileName,
        accessor: 'fileName',
        Cell: DownloadLinkTextCell({
          bidIdField: 'bidId',
          docDownloadType: 'Vendor',
          docIdField: 'bidAwardDocId',
          downloadDisabled: documentDownloading,
          downloadIdField: 'bidAwardDocId',
          onClick: handleDownloadLinkClick,
          labelField: 'fileName',
          renderFileName: renderFileName,
        }),
        minWidth: 300,
      },
      {
        Header: vendorDocumentTableHeaders.type,
        accessor: 'agencyDocType',
      },
      {
        Header: vendorDocumentTableHeaders.modifiedDate,
        accessor: 'modifiedDate',
        Cell: DateCell({
          dateField: 'modifiedDate',
        }),
        minWidth: 300,
      },
    ];

    // Show PDF conversion for Agencies only.
    if (selectedMemberIsAgency()) {
      columns.push({
        accessor: 'requireConversion',
        Cell: BooleanTextCell({
          booleanField: 'requireConversion',
        }),
        Header: vendorDocumentTableHeaders.requireConversion,
        width: 30,
      });
    }

    return columns;
  };

  const getVendorDocumentsColumnsEditable = ({
    handleDeleteRowClick,
    handleDownloadClick,
    readOnly,
  }: TableRowSelectionProps): Column<GetVendorSpecificDocumentsAPIResponse>[] => {
    const handleDeleteClick = handleDeleteRowClick || noOpFunction;
    const vendorDocColumns = getVendorDocumentsColumns({ handleDownloadClick, readOnly });
    if (readOnly) {
      return vendorDocColumns;
    }
    return [
      ...vendorDocColumns,
      {
        Header: '',
        accessor: 'bidAwardDocId',
        Cell: DeleteIconCell({
          idField: 'bidAwardDocId',
          labelField: 'fileName',
          handleClick: handleDeleteClick,
        }),
        maxWidth: 30,
        disableSortBy: true,
      },
    ];
  };

  /// Delete row
  const hideDeleteModal = () => {
    setDeleteModalIsOpen(false);
    resetSelectedVendorDocument();
  };

  const handleDeleteRowClick = ({ id }: Id<number>) => {
    const selectedDoc = vendorDocumentList.find(doc => doc.bidAwardDocId === id);
    Assert(
      isDefined(selectedDoc),
      'Expected: `selectedDoc` to exist in vendorDocumentList',
      'src/components/buyer/awardbid/add-vendor-docs/VendorDocumentsTable.tsx',
    );
    if (selectedDoc) {
      setSelectedVendorDocument(selectedDoc);
      setDeleteModalIsOpen(true);
    }
  };

  const handleDownloadLinkClick = async ({
    bidId,
    docId,
    type,
  }: {
    bidId: number;
    docId: number;
    type: DocumentDownloadType;
  }) => {
    const selectedDoc = vendorDocumentList.find(doc => doc.bidAwardDocId === docId);
    Assert(
      isDefined(selectedDoc),
      'Expected: `selectedDoc` to exist in vendorDocumentList',
      'src/components/buyer/awardbid/add-vendor-docs/VendorDocumentsTable.tsx',
    );
    if (!documentDownloading) {
      setDocumentDownloading(true);
      await downloadResponseDocument({ docId, id: bidId, type });
      setDocumentDownloading(false);
    }
  };

  /**
   * Make a service call to delete a row and refresh the list.
   * TODO: move this callback to the container component (AddVendorDocs)?
   */
  async function deleteRow() {
    if (selectedVendorDocument?.bidAwardDocId) {
      const deleteResponse = await deleteVendorDocument({
        bidAwardDocId: selectedVendorDocument.bidAwardDocId,
        memberId: supplierMemberId,
      });

      // Refresh the list if the upload succeeds.
      if (deleteResponse?.Message === FileDeleteStatusMessage.Success) {
        try {
          await refreshVendorDocuments();

          // Show success Toast
          showToastSuccess({
            message: `"${selectedVendorDocument.fileName}" ${deleteMessages.deletedSuccessSuffix}`,
            autoClose: 3000,
          });
        } catch (error: any) {
          track('VendorDocumentsTable -> deleteRow -> refreshVendorDocuments() ERROR:', {
            error,
            errorMessage: error.message,
          });
          console.error(
            `VendorDocumentsTable -> deleteRow -> refreshVendorDocuments() ERROR: \n${error}`,
          );
        }
      } else {
        // Show error Toast
        showToastError({
          message: `"${selectedVendorDocument.fileName}" ${deleteMessages.deletedErrorSuffix}`,
          autoClose: 3000,
        });
      }
    }
    hideDeleteModal();
  }

  // react-table configuration
  const columnConfig = getVendorDocumentsColumnsEditable({
    handleDeleteRowClick: handleDeleteRowClick,
    handleDownloadClick: handleDownloadLinkClick,
    readOnly,
    updateRow: noOpFunction,
  });

  function getConfirmMessageComponent() {
    const docDisplayTitle = selectedVendorDocument
      ? `${selectedVendorDocument.fileName}`
      : 'this document';
    return (
      <>
        <SpanSpacerRight>{ControlLabels.confirmDeletePrefix}</SpanSpacerRight>
        <BoldText>{docDisplayTitle}</BoldText>
        <SpanSpacerLeft>{'?'}</SpanSpacerLeft>
      </>
    );
  }

  return (
    <>
      <TableStyleWrapper>
        <TableBase columns={columnConfig} data={vendorDocumentList} />
      </TableStyleWrapper>

      {/* Delete Vendor Document confirmation modal */}
      <ConfirmModal
        confirmMessage={getConfirmMessageComponent()}
        approveAction={deleteRow}
        title={`${commonLabels.confirmDelete} `}
        closeModal={hideDeleteModal}
        isOpen={deleteModalIsOpen}
      />
    </>
  );
}
