import React from 'react';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';
interface PropsTypes {
  quoteSummary: QuoteSummaryType;
  setQuoteSummaryDetails: any;
  quoteId: number | string;
  GoBackToPage: any;
  submitting: boolean;
  valid: boolean;
  submitQuoteReply: any;
  submitBreadcrumbQuoteReply: any;
  submitQuoteReplyLater: any;
  finalSubmitBreadcrumQuoteReply: any;
  pristine: boolean;
}
function SupplierQuotesReplycontrol(props: PropsTypes) {
  const {
    quoteId,
    GoBackToPage,
    // pristine,
    submitting,
    valid,
    setQuoteSummaryDetails,
    quoteSummary,
    submitQuoteReply,
    submitBreadcrumbQuoteReply,
    finalSubmitBreadcrumQuoteReply,
    submitQuoteReplyLater,
  } = props;
  const { summary, replydetails } = quoteSummary;
  const { statusType = '', responseId, responseStatus } = summary;

  return (
    <>
      <li title='Cancel'>
        <span className='staticLink' onClick={GoBackToPage}>
          <i className='mdi mdi-close mr-1' /> Cancel
        </span>
      </li>
      {statusType === 'OP' ? (
        <>
          {replydetails ? (
            <>
              <li title='Back'>
                <span
                  className='staticLink'
                  onClick={() => {
                    setQuoteSummaryDetails({ replydetails: false });
                  }}
                >
                  <i className='mdi mdi-arrow-left mr-1' /> Back
                </span>
              </li>
              <li title='Submit Quote'>
                <span className='staticLink' onClick={() => finalSubmitBreadcrumQuoteReply()}>
                  <i className='mdi mdi-check mr-1' /> Submit Quote
                </span>
              </li>
            </>
          ) : (
            <>
              {responseStatus === 'RS' ? null : (
                <>
                  {responseStatus !== 'NQ' ? (
                    <li title='Decline Quote'>
                      <span
                        className='staticLink'
                        onClick={() => {
                          setQuoteSummaryDetails({ declinequotereplymodal: true });
                        }}
                      >
                        <i className='mdi mdi-cancel mr-1' /> Decline Quote
                      </span>
                    </li>
                  ) : null}

                  <li title='Submit Quote Later'>
                    <span
                      className='staticLink'
                      onClick={() =>
                        submitQuoteReplyLater({ quoteId, responseId, finishlater: true })
                      }
                    >
                      <i className='mdi mdi-content-save-outline mr-1' /> Submit Quote Later
                    </span>
                  </li>
                </>
              )}

              <li title='Continue'>
                <span
                  className='staticLink'
                  onClick={() => {
                    submitBreadcrumbQuoteReply();
                  }}
                >
                  <i className='mdi mdi-check mr-1' /> Continue
                </span>
              </li>
            </>
          )}
        </>
      ) : null}
    </>
  );
}

export default SupplierQuotesReplycontrol;
