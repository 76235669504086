import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { LoadingPanel } from '../../../../components/common/loading';
import SortingIcon from '../../../../components/common/sortingIcon';
import { Paging } from '../../../../components/customcontrols';
import { BidResponseLabels } from '../../../../shared/constants/bids';
import { bidItemParamType, responseEventsParamType } from '../../../../types/biddetail';
import { pageSize, sortDirections } from '../../../../utils/constants';
import { displayDateTime, useAssert } from '../../../../utils/helpers';
import { getDefaultNotFoundMessage } from '../../../../utils/message';

const tdWidth = {
  date: {
    width: '220px',
  },
  author: {
    width: '21%',
  },
  event: {
    width: '24%',
  },
  detail: {
    width: '33%',
  },
};

export interface ResponseHistoryViewProps {
  responseEvents: responseEventsParamType[];
  bidSummary?: bidItemParamType;
  responseId: string;
}

// TODO: implement as a react-table with pagination and sorting.

export function ResponseHistoryView(props: ResponseHistoryViewProps) {
  const { responseEvents: bidResponseEvents, bidSummary } = props;
  const { tzfn, tzn } = bidSummary || {};
  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;
  useAssert(!!tzfn, 'Timezone should be defined', 'ResponseHistoryView.tsx');
  useEffect(() => {
    setCurrentPage(1);
    setSortorder(sortDirections.ASC);
  }, []);

  //Logic for sort
  const [sortvalue, setSortvalue] = useState('dtCreated');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);

  return (
    <>
      <Table className='tableFixed'>
        <thead>
          <tr>
            <th style={tdWidth.date}>
              Date
              <SortingIcon
                sortlist={bidResponseEvents}
                initialsortorder={sortorder}
                currentsortname={sortvalue}
                changesortname={(value: string) => setSortvalue(value)}
                sortname='dtCreated'
                changeSortingFn={() => null}
                // changeSortingFn={(list: any) => setBidSummaryDetails({ bidResponseEvents: list })}
              />
            </th>
            <th style={tdWidth.author}>
              Author
              <SortingIcon
                sortlist={bidResponseEvents}
                initialsortorder={sortorder}
                currentsortname={sortvalue}
                changesortname={(value: string) => setSortvalue(value)}
                sortname='author'
                // changeSortingFn={(list: any) => setBidSummaryDetails({ bidResponseEvents: list })}
                changeSortingFn={() => null}
              />
            </th>
            <th style={tdWidth.event}>
              Event
              <SortingIcon
                sortlist={bidResponseEvents}
                initialsortorder={sortorder}
                currentsortname={sortvalue}
                changesortname={(value: string) => setSortvalue(value)}
                sortname='responseEventName'
                changeSortingFn={() => null}
                // changeSortingFn={(list: any) => setBidSummaryDetails({ bidResponseEvents: list })}
              />
            </th>
            <th style={tdWidth.detail}>Detail</th>
          </tr>
        </thead>
        <tbody>
          {bidResponseEvents.length > 0 ? (
            <>
              {bidResponseEvents
                .map((item, index) => (
                  <tr key={index}>
                    <td>{item.dtCreated && displayDateTime(item.dtCreated, tzfn)}</td>
                    <td>{item.author}</td>
                    <td>{item.responseEventName}</td>
                    <td>{item.description}</td>
                  </tr>
                ))
                .slice(indexOfFirstList, indexOfLastList)}
            </>
          ) : (
            <tr>
              <td colSpan={8}>
                <LoadingPanel
                  message={getDefaultNotFoundMessage(`${BidResponseLabels.viewHistory} records`)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Paging
        totalRecords={bidResponseEvents.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
}
