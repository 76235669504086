import moment from 'moment-timezone';

import { invalidDateMessage, phoneVal, usaDateFormat } from '../../../utils/constants';
import { emailValidation } from '../../../utils/helpers';

export const generalInformationValidate = (values = {} as any) => {
  const errors = {} as any;

  const optional = ['address2', 'website'];

  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => {
        const returnValue = values[items];
        return {
          name: items,
          value: typeof returnValue === 'string' ? values[items].trim() : values[items],
        };
      }) || [];

  const errorarr = required.filter(items => !items.value) || [];
  errorarr.forEach(items => (errors[items.name] = 'Required'));

  if (emailValidation(values.loginusername)) {
    errors.loginusername = 'Invalid';
  }

  if (emailValidation(values.contactEmail)) {
    errors.contactEmail = 'Invalid';
  }

  if (values.contactPhone && !phoneVal.test(values.contactPhone)) {
    errors.contactPhone = 'Invalid';
  }

  if (values.buyerPhone && !phoneVal.test(values.buyerPhone)) {
    errors.buyerPhone = 'Invalid';
  }

  if (
    values.website &&
    !/^((http|https|HTTP|HTTPS):\/\/)?(WWW.|www.)?(?!.*(http|https|HTTP|HTTPS|WWW.|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
      values.website.trimStart().trimEnd().toLowerCase(),
    )
  ) {
    errors.website = 'Invalid';
  }

  if (values.initialDate && !moment(values.initialDate, usaDateFormat).isValid()) {
    errors.initialDate = invalidDateMessage;
  }

  return errors;
};

export const legalAdValidate = (values = {} as any) => {
  const errors = {} as any;
  const optional = [] as any;

  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => {
        const returnValue = values[items];
        return {
          name: items,
          value: typeof returnValue === 'string' ? values[items].trim() : values[items],
        };
      }) || [];

  const errorarr = required.filter(items => !items.value) || [];
  errorarr.forEach(items => (errors[items.name] = 'Required'));
  return errors;
};
