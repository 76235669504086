import React, { ComponentType, HTMLProps, memo, ReactNode, useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable } from 'recoil';
import styled from 'styled-components';

import {
  allProductsState,
  countyZeroState,
  initialCountyProduct,
  selectedProductsState,
  upsellProductsState,
} from '../../store/recoil/productState';
import { BundleCategory, productBundles } from './bundles';
import { ProductApiResponse, SubscriptionProducts } from '../../types/products';

import { CheckBox } from '../customcontrols';
import { compareObjectsByKey } from '../../utils/sort';
import { findArrayItemByKey } from '../../utils';
import { getProduct } from './helpers';
import { RegistrationUpsellCardData } from '../../types/registration';
import { removeDuplicates } from '../../utils/helpers';

import { ContentRowWrapping } from '@demandstar/components/styles';

interface SelectBundleProps {
  isRegistration: boolean;
  parentState: ProductApiResponse;
}

interface BundleDisplay {
  items: ProductApiResponse[];
  name: string;
  price: number;
  mapComponent?: ComponentType<HTMLProps<HTMLSpanElement>>;
}

const initialBundleDisplay = {
  items: [initialCountyProduct],
  name: 'Test',
  price: 0,
};

const Ul = styled.ul`
  list-style-type: none;

  li {
    padding: 0.5rem 0;

    label {
      display: inline-block;
      width: 20rem;
    }

    > span {
      float: right;
      margin-right: 2em;
    }
  }
`;

const Col = styled.div`
  clear: both;
  display: flex;
  min-height: 132px;

  Ul {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  Ul li > span {
    float: none;
    margin-right: 2em;
  }
`;

const SelectBundleComponent = (props: SelectBundleProps) => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const { parentState } = props;

  const [checkboxZero, setCheckboxZero] = useRecoilState<boolean>(countyZeroState);
  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);
  const [upsellProducts, setUpsellProducts] =
    useRecoilState<RegistrationUpsellCardData[]>(upsellProductsState);

  const [selectedBundleCategory, setSelectedBundleCategory] = useState<BundleCategory>();
  const [availableBundles, setAvailableBundles] = useState<BundleDisplay[]>([initialBundleDisplay]);
  const [selectedBundles, setSelectedBundles] = useState<BundleDisplay[]>([initialBundleDisplay]);
  const [totalSavings, setTotalSavings] = useState(0);

  const bundleCategoryKey = parentState.productName;

  function handleBundleSelection(name: string, value: boolean) {
    const selectedBundle =
      findArrayItemByKey<BundleDisplay>(availableBundles, 'name', name) || initialBundleDisplay;
    const checkedCounties = selectedProducts.county || [];

    // add
    if (value) {
      /* istanbul ignore else */
      if (checkboxZero) setCheckboxZero(false);
      setSelectedBundles([...selectedBundles, selectedBundle]);

      const allCounties = selectedBundle.items.concat(checkedCounties);
      const uniqueCounties: ProductApiResponse[] = removeDuplicates(allCounties, 'productId').sort(
        compareObjectsByKey('productName'),
      );
      setSelectedProducts({
        ...selectedProducts,
        county: uniqueCounties,
      });

      removeCountyUpsellCard(uniqueCounties);
    }
    /**
     * NOTE: if Cart and county check boxes have not been touched, this will remove all counties associated
     * with the bundle otherwise, it does not affect counties in cart
     */
    // remove selectedBundle
    else {
      const stillSelectedBundles = selectedBundles.filter(bundle => bundle !== selectedBundle);

      setSelectedBundles(stillSelectedBundles);

      const remainingCounties = checkedCounties.filter(
        county => !selectedBundle.items?.find(item => item.productId === county.productId),
      );
      /* istanbul ignore else */
      if (
        remainingCounties.length === 0 &&
        selectedProducts.national === 0 &&
        selectedProducts.state?.length === 0
      ) {
        setCheckboxZero(true);
      }
      setSelectedProducts({
        ...selectedProducts,
        county: remainingCounties,
      });
    }
  }

  const handleMapComponentClick = (name: string) => () => {
    const isBundleSelected = findArrayItemByKey<BundleDisplay>(selectedBundles, 'name', name);
    handleBundleSelection(name, !isBundleSelected);
  };

  const handleCheckedState = (name: string, value: boolean) => {
    if (value) {
      /* istanbul ignore else */
      if (checkboxZero) setCheckboxZero(false);

      // remove upsellCard
      const newUpsellProducts = upsellProducts.filter(
        (product: RegistrationUpsellCardData) =>
          !(
            product.product.productId === parentState.productId ||
            product.product.parentId === parentState.productId
          ),
      );
      setUpsellProducts(newUpsellProducts);
      setSelectedProducts({
        ...selectedProducts,
        state: selectedProducts.state?.concat(parentState).sort(compareObjectsByKey('productName')),
      });
    } else {
      const allSelectedStates = selectedProducts.state;
      const remainingStates = allSelectedStates?.filter(state => {
        if (state.productId !== parentState.productId) {
          return state;
        }
      });

      /* istanbul ignore else */
      if (
        remainingStates &&
        remainingStates.length === 0 &&
        selectedProducts.national === 0 &&
        selectedProducts.county?.length === 0
      ) {
        setCheckboxZero(true);
      }
      setSelectedProducts({
        ...selectedProducts,
        state: remainingStates,
      });
    }
  };

  const removeCountyUpsellCard = (counties: ProductApiResponse[]) => {
    const countyUpsellCard = upsellProducts.filter(product => product.product.productType === 'CT');
    /* istanbul ignore else */
    if (countyUpsellCard.length > 0) {
      const included = counties.includes(countyUpsellCard[0].product);
      /* istanbul ignore else */
      if (included) {
        const newUpsellProducts = upsellProducts.filter(product => product !== countyUpsellCard[0]);
        setUpsellProducts(newUpsellProducts);
      }
    }
  };

  useEffect(() => {
    let allBundlesPrice = 0;

    /* istanbul ignore else */
    if (allProducts.state === 'hasValue') {
      const bundleCategory = productBundles.find(
        bundleCategory => bundleCategoryKey === bundleCategory.title,
      );

      if (!bundleCategory) {
        return;
      }

      setSelectedBundleCategory(bundleCategory);

      const bundleDisplayList: BundleDisplay[] = [];

      (bundleCategory.items || []).forEach(bundle => {
        const products: ProductApiResponse[] = (bundle.idList || []).map(
          productId => getProduct(productId, allProducts.contents) || initialCountyProduct,
        );

        const totalPrice = products.reduce((a = 0, b) => {
          return a + (b?.price || 0);
        }, 0);

        allBundlesPrice += totalPrice;

        const newBundle: BundleDisplay = {
          items: products || initialBundleDisplay.items,
          name: bundle.title,
          price: totalPrice,
          mapComponent: bundle.mapComponent || 'span',
        };

        bundleDisplayList.push(newBundle);
      });

      setAvailableBundles(bundleDisplayList);

      setTotalSavings(allBundlesPrice - parentState.price);
    }
  }, [allProducts, bundleCategoryKey, parentState.price]);

  const mapComponents: ReactNode[] = [];
  const MapContainer = selectedBundleCategory?.mapContainer || 'span';

  return (
    <div>
      <h5>Choose a {bundleCategoryKey} Regional Package</h5>
      <p className='no-top-padding'>
        There are {availableBundles.length} packages available for {bundleCategoryKey}. Each of
        these packages contains access to all the governments on DemandStar in that region.
      </p>
      <p className='no-top-padding'>
        Select the region that matches where you do business, or purchase a {bundleCategoryKey}{' '}
        State subscription to buy access to all of them.
      </p>
      <ContentRowWrapping>
        <Col>
          <Ul>
            {/** The first bundle is a state subscription, not a combination of counties */}
            <li key={bundleCategoryKey}>
              <CheckBox
                checked={selectedProducts.state?.includes(parentState)}
                dataTestId={'All-' + bundleCategoryKey}
                handleChecked={(name: string, value: boolean) => {
                  handleCheckedState(name, value);
                }}
                name={'All of ' + bundleCategoryKey}
                title={'All of ' + bundleCategoryKey}
              />
              <span className='reg-span'>
                ${parentState.price}/year (save ${totalSavings}!)
              </span>
            </li>
            {availableBundles.map(bundleDisplay => {
              // Set an optional map component
              const MapComponent = bundleDisplay.mapComponent || 'span';

              mapComponents.push(
                <MapComponent onClick={handleMapComponentClick(bundleDisplay.name)} />,
              );

              return (
                <li key={bundleDisplay.name}>
                  <CheckBox
                    checked={selectedBundles.includes(bundleDisplay)}
                    dataTestId={bundleDisplay.name}
                    handleChecked={(name: string, value: boolean) => {
                      handleBundleSelection(name, value);
                    }}
                    name={bundleDisplay.name}
                    title={bundleDisplay.name}
                  />
                  <span className='reg-span'>${bundleDisplay.price}/year</span>
                  {/* <MapComponent onClick={handleMapComponentClick(bundleDisplay.name)} /> */}
                </li>
              );
            })}
          </Ul>
        </Col>
        <MapContainer>{mapComponents}</MapContainer>
      </ContentRowWrapping>
    </div>
  );
};

export const SelectBundle = memo(SelectBundleComponent);
