import React from 'react';

import { limitedbiddetailsTypes, limitedBidListItem } from '../../../types/limited';
import Configurations from '../../../settings';

interface LimitedBidListProps {
  limited: limitedbiddetailsTypes;
}

export const LimitedBidList = (props: LimitedBidListProps) => {
  const { limited } = props;
  const { limitedBidsList = [] } = limited;

  return (
    <>
      {limitedBidsList.map((item: limitedBidListItem, index: number) => (
        <div className='listGroupWrapper clearfix' key={index}>
          <h5 className='mw-100 text-truncate' title=''>
            <a
              className='mw-75 text-truncate'
              rel='noopener noreferrer'
              href={`${Configurations.REACT_APP_BASE_URL}/limited/bids/${item.bidId}/details`}
              target='_blank'
            >
              {item.bidName}
            </a>
            <span className=''>{item.status}</span>
          </h5>
          <p>
            {item.agency}, {item.city}, {item.state}
          </p>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <i>ID:</i> {item.bidIdentifier}
            </li>
            <li className='list-inline-item'>
              <i>Due:</i> {item.dueDate}
            </li>
            <li className='list-inline-item'>
              <i>Broadcast:</i> {item.broadCastDate}
            </li>
            <li className='list-inline-item'>
              <i>#Planholders:</i> {item.planholders}
            </li>
          </ul>
        </div>
      ))}
    </>
  );
};
