import React, { memo, useEffect, useMemo } from 'react';
import { TabPane } from 'reactstrap';
import { useRecoilState } from 'recoil';

import { addbidParamType, resultsTypes } from '../../../../../types/addbid';
import {
  BidExternalStatusType,
  BidInternalStatusType,
  memberTypes,
} from '../../../../../utils/constants';
import {
  bidItemParamType,
  bidssummaryParamType,
  documentItemParamType,
  ebidItemParamType,
  purchaseInfoParamType,
} from '../../../../../types/biddetail';
import { Buttons, NoResult } from '../../../../customcontrols/index';
import {
  deprecatedCheckTimezoneCountDown,
  deprecatedGetBroadcastGap,
  deprecatedGetDate,
  displayDateTime,
} from '../../../../../utils/helpers';

import Awardees from './awardees';
import BasicInfo from './basicinfo';
import BidSummaryControlsInner from '../../../../common/breadcrumb/common/bidSummaryControlsInner';
import BroadcastFilter from './broadcastfilter';
import CommodityExpand from '../../../../common/commodityexpand';
import CountDownTimer from '../../../../common/countdowntimer';
import DistributionInfo from './distributioninfo';
import Documents from './documents';
import EbidInfo from './ebidinfo';
import ExpandCollapse from '../../../../common/expandcollapse';
import HeaderStatistics from './headerstatistics';
import history from '../../../../../utils/history';
import LegalAd from './legaladview';
import Statistics from './statistics';

import { AgencyStateCounty } from '../../../../../types/agencyinfo';
import { agencyStateCountyState } from '../../../../../store/recoil/agencyState';
import { authTypes } from '../../../../../types/auth';
import { commodityItemsParamType } from '../../../../../types/commoditycodes';
import { documentLabels } from '../../../../../shared/constants';
import { MemberTypeName } from '../../../../../types/dashboard';
import { sharedTypes } from '../../../../../types/shared';
import { shouldAllowDirectDownload } from '../../../../common/breadcrumb/common/bidSummaryControlsInner';
import { useAuthMemberId } from '../../../../../shared/hooks/useMemberInfo';
import { useBidAwardee } from '../../../../../shared/hooks/useBidAwardee';
import { useDSSelector } from '../../../../../store/reducers';
import { useSetSelectedBidIdFromRoute } from '../../../../../shared/hooks/useSelectedBidId';

interface BidInfoProps {
  data: bidItemParamType;
  getSelfDeclarations: any;
  shared: sharedTypes;
  exportBroadcastList: any;
  exportSupplementalSuppliers: any;
  orderBidPackage: any;
  reloadPageDetails: () => void;
  forceUpdateAfterTimerCompletion: () => void;
  purchaseInfo: purchaseInfoParamType;
  bidssummary: bidssummaryParamType;
  auth: authTypes;
  bidsBidWatch: any;
  downloadAllDocument: any;
  setBidSummaryDetails: any;
  bidsSummary: any;
  bidWriterInfo: Record<string, unknown>;
  addbid: addbidParamType;
  setAddBidDetails: any;
  eBids?: ebidItemParamType;
  memberTypeName?: MemberTypeName;
  memberId?: number;
}

const BidInfoComponent = (props: BidInfoProps) => {
  const [agencyStateCounty, setAgencyStateCounty] =
    useRecoilState<AgencyStateCounty>(agencyStateCountyState);

  // Get the list of BidAwardees to determine whether the member is an awardee for this bid.
  useSetSelectedBidIdFromRoute();
  const { authMemberId } = useAuthMemberId();
  const { memberIsBidAwardee } = useBidAwardee();

  const currentMemberIsAwardee = useMemo(() => {
    return memberIsBidAwardee(authMemberId);
  }, [memberIsBidAwardee, authMemberId]);

  const {
    bidssummary,
    data,
    getSelfDeclarations,
    auth,
    shared,
    exportBroadcastList,
    exportSupplementalSuppliers,
    downloadAllDocument,
    reloadPageDetails,
    addbid,
    forceUpdateAfterTimerCompletion,
    setAddBidDetails,
    memberTypeName,
  } = props;
  const { canFinishBid = false, documentslist = [], broadcastModal = false } = addbid;
  const {
    awardees,
    broadcastShow = false,
    documents,
    planHolders,
    purchaseInfo,
    results,
    stats,
  } = bidssummary || {};
  const { isPurchased } = purchaseInfo;
  const pendingDocuments: documentItemParamType[] =
    documents !== undefined
      ? documents.filter((doc: { status: string }) => doc.status !== 'Complete')
      : [];
  const { mt: memberType, mi } = auth || {};
  const { selfdeclaredattributes = [] } = shared || {};
  const { memberID, bidID: bidId = '', publications = [] } = results || {};

  const planholderIds: number[] = planHolders?.ids || [];
  const allowDirectDownload = shouldAllowDirectDownload(
    planholderIds,
    memberTypeName as MemberTypeName,
    !!isPurchased,
    memberID,
    currentMemberIsAwardee,
  );

  useEffect(() => {
    if (bidId && selfdeclaredattributes.length === 0) getSelfDeclarations();
  }, [getSelfDeclarations, bidId, selfdeclaredattributes.length]);

  const {
    bidIdentifier,
    bidTypeDescription,
    commodities,
    agencyName,
    bidName,
    bidWriter,
    fiscalYear,
    bidStatusText,
    broadcastDate = '',
    dueDate,
    scopeOfWork,
    bidBond,
    planBluePrint,
    distributionOptions,
    distributionMethod,
    distributionNotes,
    distributedBy,
    pbcMemo,
    eBidding,
    bidExternalStatusType,
    bidInternalStatusType,
    displayBudgetToSuppliers,
    projectEstimatedBudget,
    bidWriterJobTitle,
    bidWriterEmail,
    address1,
    address2,
    city,
    state,
    postalCode,
    phone,
    fax,
    bidID,
    filtered,
    statusTheme,
    tzn,
    tzfn,
    cost,
    fulfillmentEmail,
  } = data || {};

  const bidExternalStatusProp: string = useDSSelector(
    state => (state.bidssummary.results as resultsTypes).bidExternalStatus,
  );

  const basicInfo = {
    bidWriter,
    fiscalYear,
    broadcastDate,
    dueDate,
    bidID,
    bidTypeDescription,
    agencyName,
    bidStatusText,
    bidIdentifier,
    filtered,
    tzn,
    tzfn,
  } as bidItemParamType;

  const bidWriterInfo = {
    bidWriter,
    bidWriterJobTitle,
    bidWriterEmail,
    address1,
    address2,
    city,
    state,
    postalCode,
    agencyName,
    phone,
    fax,
    bidName,
  } as bidItemParamType;

  const distributionInfo = {
    bidBond,
    planBluePrint,
    distributionOptions,
    distributionMethod,
    distributionNotes,
    distributedBy,
    eBidding,
    displayBudgetToSuppliers,
    projectEstimatedBudget,
    bidID,
    cost,
  } as bidItemParamType;

  const checkMemberType = () => {
    if (
      memberType === memberTypes.agencyCreatedPlanholder ||
      memberType === memberTypes.subscribingSupplier ||
      memberType === memberTypes.basicSupplier
    ) {
      return false;
    } else return true;
  };

  const {
    filteredCities = '',
    filteredDeclarations = '',
    filteredPrograms = '',
    filteredStates = '',
  } = props.data || {};

  const shouldDisplayCountDown =
    mi === memberID &&
    broadcastDate &&
    broadcastShow &&
    bidInternalStatusType !== BidInternalStatusType.Pending &&
    bidExternalStatusType === BidExternalStatusType.Upcoming;

  function setAgencyPrepopulateData(memberID: number) {
    if (bidssummary.writerInfo) {
      setAgencyStateCounty({
        county: bidssummary.writerInfo.memberInfo.cu,
        memberId: memberID,
        state: bidssummary.writerInfo.memberInfo.st,
      });
    }
  }

  let statusBarTheme = 'tag ' as string;

  if (statusTheme === 'RED' || bidExternalStatusType === BidExternalStatusType.Upcoming) {
    statusBarTheme = `${statusBarTheme}detail-draft`;
  } else if (bidExternalStatusType === BidExternalStatusType.Cancelled) {
    statusBarTheme = `${statusBarTheme}status cancelledbg`;
  } else if (bidExternalStatusType === BidExternalStatusType.Awarded) {
    statusBarTheme = `${statusBarTheme}status awardedbg`;
  } else {
    statusBarTheme = `${statusBarTheme}status`;
  }

  return (
    <>
      <TabPane tabId='1'>
        <h3 className='d-flex align-items-sm-start align-items-md-center'>
          <label className='flex1 mr-5 mb-0'>
            <span className='arrowWrapper'>{bidName}</span>
          </label>
          <i className={statusBarTheme}>{bidExternalStatusProp}</i>
        </h3>
        {memberType !== memberTypes.agencyBuyer && <BidSummaryControlsInner {...props} />}
        {mi === memberID &&
        bidInternalStatusType === BidInternalStatusType.Pending &&
        documentslist.length ? (
          <>
            {pendingDocuments.length === 0 && (
              <>
                {documentslist.length > 0 &&
                bidExternalStatusType === BidExternalStatusType.Upcoming &&
                bidInternalStatusType === BidInternalStatusType.Pending &&
                canFinishBid === true ? (
                  <div className='alertBox'>
                    <b>
                      Your bid has not been published yet.{' '}
                      <i
                        className='underLine'
                        onClick={() => setAddBidDetails({ broadcastModal: !broadcastModal })}
                      >
                        Please click the Finish Bid button to broadcast this bid.{' '}
                      </i>
                    </b>
                  </div>
                ) : (
                  <div className='alertBox'>
                    <b>Your bid has not been published yet.</b>
                  </div>
                )}
              </>
            )}
            {pendingDocuments.length > 0 ? (
              <div className='alertBox'>
                <b>You must approve documents before finalizing bid.</b>
              </div>
            ) : null}
          </>
        ) : null}
        {shouldDisplayCountDown &&
        deprecatedCheckTimezoneCountDown(broadcastDate, tzfn) !== 'closed' ? (
          <CountDownTimer
            dueDate={broadcastDate}
            tzfn={tzfn}
            component={(timer: any) => (
              <div className='alertBox'>
                Your bid is set to broadcast on {displayDateTime(broadcastDate, tzfn)}. You can make
                edits to your bid within <span>{timer}</span>. After that your bid will be broadcast
                within 20 minutes.
              </div>
            )}
            custom={true}
            timerCompletedAction={forceUpdateAfterTimerCompletion}
          />
        ) : null}
        {shouldDisplayCountDown &&
        deprecatedCheckTimezoneCountDown(broadcastDate, tzfn) === 'closed' &&
        deprecatedCheckTimezoneCountDown(deprecatedGetBroadcastGap(broadcastDate), tzfn) !==
          'closed' ? (
          <CountDownTimer
            dueDate={deprecatedGetBroadcastGap(broadcastDate)}
            tzfn={tzfn}
            component={() => (
              <div className='alertBox'>Your bid will be broadcast within 20 minutes.</div>
            )}
            custom={true}
            timerCompletedAction={reloadPageDetails}
          />
        ) : null}
        {checkMemberType() && stats ? (
          <>{memberID === mi ? <HeaderStatistics {...props} /> : null} </>
        ) : null}
        <h4>Bid Details</h4>
        <div className='innerColIndent'>
          <BasicInfo {...props} data={basicInfo} bidWriterInfo={bidWriterInfo} />
        </div>
        {basicInfo &&
        basicInfo.filtered === 'Yes' &&
        (filteredStates ||
          filteredCities ||
          filteredDeclarations ||
          filteredPrograms ||
          selfdeclaredattributes) ? (
          <>
            <h4>Broadcast List Filtered</h4>
            <div className='innerColIndent'>
              <BroadcastFilter
                filteredStates={filteredStates}
                filteredCities={filteredCities}
                filteredDeclarations={filteredDeclarations}
                filteredPrograms={filteredPrograms}
                selfdeclaredattributes={selfdeclaredattributes}
              />
            </div>
          </>
        ) : null}
        <Awardees awardees={awardees} bidExternalStatusType={bidExternalStatusType} />
        <h4>Scope of Work</h4>
        <div className='innerColIndent'>
          <ExpandCollapse data={scopeOfWork} innerhtml={true} shared={shared} />
        </div>
        <span className='clearfix' />
        <h4>Documents</h4>
        <div className='innerColIndent'>
          {(purchaseInfo || checkMemberType()) && documents && documents.length > 0 ? (
            <>
              <Buttons
                text={documentLabels.downloadAllDocuments}
                classNames='bttn-primary callAction downloadDocs'
                icon='mdi-download'
                onClick={() => {
                  if (allowDirectDownload || memberType === memberTypes.agencyBuyer) {
                    downloadAllDocument({ id: bidId, type: 'Bid' });
                  } else {
                    setAgencyPrepopulateData(memberID);
                    history.push(`/suppliers/plans?bidId=${bidId}`);
                  }
                }}
              />
              <div className='table-responsive'>
                <Documents {...props} documents={documents} purchaseinfo={purchaseInfo} />
              </div>
            </>
          ) : (
            <NoResult message={'No Documents Available'} />
          )}
        </div>

        {memberType === memberTypes.agencyBuyer &&
        (eBidding === 'Yes' || eBidding === 'ViewEdit') ? (
          <>
            <h4>eBidding Info</h4>
            <div className='innerColIndent'>
              <EbidInfo {...props} />
            </div>
          </>
        ) : null}

        <h4>Distribution Info</h4>
        <div className='innerColIndent'>
          <DistributionInfo {...props} data={distributionInfo} />
          {distributionInfo.cost > 0 ||
          fulfillmentEmail ||
          (data.distributionNotes && data.distributionNotes !== 'None') ? (
            <LegalAd
              {...props}
              pageFor='DistributionInfo'
              title='View More Distribution Information'
            />
          ) : null}
        </div>
        <h4>Publications</h4>
        <div className='innerColIndent'>
          {publications.map((pub, id) => (
            <p key={id}>
              {pub.publication} {deprecatedGetDate(pub.publishDate)}
            </p>
          ))}
          <LegalAd {...props} title='View Legal Ad' />
        </div>

        <h4>Pre-Bid Conference</h4>
        <div className='innerColIndent'>
          {pbcMemo ? (
            pbcMemo.split('\n').map((memo, id) => <p key={id}>{memo}</p>)
          ) : (
            <NoResult message='No Pre-Bid Conference Available' />
          )}
        </div>
        {checkMemberType() && stats ? (
          <>
            <h4>Statistics</h4>
            <div className='innerColIndent'>
              <Statistics
                exportBroadcastList={exportBroadcastList}
                exportSupplementalSuppliers={exportSupplementalSuppliers}
                bidId={bidId}
              />
            </div>
          </>
        ) : null}
        <h4>Commodity Code </h4>
        <div className='innerColIndent clearfix'>
          <CommodityExpand shared={shared} commodities={commodities as commodityItemsParamType[]} />
        </div>
      </TabPane>
    </>
  );
};

export const BidInfo = memo(BidInfoComponent);
