import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';

export const initialState = {
  commoditieslist: [],
  searchedCommodityCodes: [],
  commodityCodes: '',
  commodityGroup: '',
  commodityCategory: '',
  searchText: '',
  halfWayCancel: false,
  halfWayCancelAllow: false,
};

export const reducer = (state = initialState, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.LOAD_COMMODITY_CODES.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.LOAD_COMMODITY_CODES.FAILURE:
      state = { ...state, ...payload };
      break;
    case actionTypes.UPDATE_COMMODITY_CODES.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.UPDATE_COMMODITY_CODES.FAILURE:
      state = { ...state, ...payload };
      break;
    case actionTypes.SEARCH_COMMODITY_CODES.SUCCESS:
      state = { ...state, ...payload };
      break;
    case actionTypes.SEARCH_COMMODITY_CODES.FAILURE:
      state = { ...state, ...payload };
      break;
    case actionTypes.SET_COMMODITY_CODES.TRIGGER:
      state = { ...state, ...payload };
      break;
    case actionTypes.RESET_COMMODITY_CODES.TRIGGER:
      state = initialState;
      break;
    default:
      break;
  }
  return state;
};
