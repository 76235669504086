/**
 * Finds a generically typed item `ItemType` of an Array
 * whose supplied `idField` value matches the `testValue`.
 * @param array: T[]
 * @param field: keyof T
 * @param testValue: any
 * @returns item: ItemType
 */
export function findArrayItemByKey<T>(
  array: T[],
  field: keyof T,
  testValue: unknown,
) {
  return array.find(item => item[field] === testValue);
}
