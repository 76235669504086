import React, { useState } from 'react';

import { DocStatus, DocTypeValue } from '../../../types/addbid';

import { agencyTexts } from '../../../utils/texts';
import { AwardWizardComponentProps } from './AwardBid';
import { AwardWizardId } from '../../../store/recoil/award-bid';
import { ConfirmModal } from '../../common/modals/ConfirmModal';
import { defaultNumericId } from '../../../shared/constants';
import { DocumentUploadTable } from '../../common/DocumentUploadTable';
import { useAwardBidWizard } from '../../../shared/hooks';
import { useDSSelector } from '../../../store/reducers';
import { WizardButtons } from '../../customcontrols/wizard/WizardButtons';

type AddPublicDocsProps = Pick<AwardWizardComponentProps, 'selectedBidId'>;

export const AddPublicDocs = (props: AddPublicDocsProps) => {
  const { saveAwardBidWizard, returnToBidSummary } = useAwardBidWizard();

  const { selectedBidId: bidId = defaultNumericId } = props;
  const documentList = useDSSelector(state => state.addbid.documentslist);
  const yetToProcessDocs = !!documentList.filter(doc =>
    [DocStatus.ProcessPending, DocStatus.PdfProcessPending].includes(doc.statusType),
  ).length;
  const yetToApproveDocs = !!documentList.filter(
    doc =>
      doc.statusType === DocStatus.ApprovalPending && doc.docType !== DocTypeValue.AwardDocument,
  ).length;

  const hasAwardDoc = !!documentList.filter(doc => doc.docType === DocTypeValue.AwardDocument)
    .length;

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  function checkNextPage() {
    if (yetToApproveDocs || !hasAwardDoc) {
      toggleConfirmModal();
    } else {
      nextPage();
    }
  }

  function nextPage() {
    saveAwardBidWizard(AwardWizardId.NotifyAwardees);
  }

  function toggleConfirmModal() {
    setConfirmModalVisible(!confirmModalVisible);
  }

  return (
    <>
      <DocumentUploadTable bidId={bidId} />

      <WizardButtons
        onPrevious={() => {
          saveAwardBidWizard(AwardWizardId.ListRequiredDocs);
        }}
        onNext={checkNextPage}
        save={returnToBidSummary}
        disableNext={yetToProcessDocs}
      />
      <ConfirmModal
        isOpen={confirmModalVisible && yetToApproveDocs}
        title='Confirmation'
        confirmMessage={agencyTexts.awardBid.approveDocsWarning}
        approveAction={toggleConfirmModal}
        approveText={agencyTexts.documents.approveDocsButton}
        closeModal={toggleConfirmModal}
        onlyApprove={true}
      />
      <ConfirmModal
        isOpen={confirmModalVisible && !yetToApproveDocs}
        title='Warning'
        confirmMessage={
          <>
            <p>
              You have not uploaded any files with the type: <strong>Award Document</strong>.
              Planholders will only be notified of the change in bid status if an Award Document is
              uploaded.
            </p>
            <p>
              Would you like to continue without uploading an award document and notifying users?
            </p>
          </>
        }
        approveAction={nextPage}
        approveText={agencyTexts.awardBid.addAwardDocContinue}
        cancelText={agencyTexts.awardBid.addAwardDocCancel}
        closeModal={toggleConfirmModal}
      />
    </>
  );
};
